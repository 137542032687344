package services.http

import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.previews.ResolvedProjectPreview
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.rest.RestResponse
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProjectQueryRequest(
  val processStatesToFilter: List<@Serializable(with = LizergyProcessStatesSerializer::class) LizergyProcessStates>,
  val processStatesToHide: List<@Serializable(with = LizergyProcessStatesSerializer::class) LizergyProcessStates>,
  val filteredByCompanyCode: CompanyCode?,
  val filteredByMaintainer: UserLoginName?,
  val filteredByEditor: UserLoginName?,
  val filterValueProject: String?,
  val filterValueAddress: String?,
  val filterByProjectState: @Serializable(with = LizergyProcessStatesSerializer::class) LizergyProcessStates?,
  val indexOfFirstVisibleProject: Int,
  val indexOfLastVisibleProject: Int,
  val sorting: ProjectQuerySorting,
  val projectQueryComponent: ProjectQueryComponent?,
  val includeArchived: Boolean,
)

@Serializable
enum class ProjectQuerySortedBy {
  Unsorted,
  SortedAscending,
  SortedDescending,
}

@Serializable
enum class ProjectQuerySortedColumn {
  ProjectColumn,
  CustomerColumn,
  EditorColumn,
  ProcessStateColumn,
  InfoColumn,
  SelectedForAccountingColumn,
  AccountingTable,
}

@Serializable
data class ProjectQuerySorting(
  val sortedBy: ProjectQuerySortedBy,
  val sortedColumn: ProjectQuerySortedColumn?,
)


@Serializable
sealed interface ProjectQueryResponse : RestResponse<ProjectQueryData> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): RestResponse.SuccessResponse<ProjectQueryData> {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: ProjectQueryData) : ProjectQueryResponse, RestResponse.SuccessResponse<ProjectQueryData>

  @Serializable
  @SerialName("failure")
  data object Failure : ProjectQueryResponse, RestResponse.FailureResponse<ProjectQueryData>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: ProjectQueryData): ProjectQueryResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): ProjectQueryResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: ProjectQueryData?): ProjectQueryResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}

@Serializable
data class ProjectQueryData(
  val visibleProjectPreviews: List<ResolvedProjectPreview>,
  val numberOfTotalFilteredProjects: Int,
  val numberOfTotalRelevantProjects: Int,
)
