package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.configuration.quote.economics.YearIndex
import it.neckar.open.unit.number.NegativeOrZero
import kotlinx.serialization.Serializable

/**
 * The total cash flow - including financing information
 */
@Serializable
data class CashFlow(
  /**
   * "Einspeisevergütung"
   */
  val feedInCompensation: Money,

  /**
   * "Ersparnis Eigenverbrauch"
   */
  val savingsOwnConsumption: Money,

  /**
   * How much does it cost to operate
   * "Betriebskosten"
   */
  val operatingCosts: Money,

  /**
   * Information about the financing
   */
  val financingInformation: FinancingInformation,

  ) {

  /**
   * Returns the cash flow from operations
   * "Mittel aus laufender Geschäftstätigkeit" - Summe derjenigen Ein- und Auszahlungen, die durch die betriebliche Tätigkeit entstehen
   * Näherung für "EBITDA" (Earnings before interest, taxes, depreciation and amortization)
   */
  val cashFlowFromOperations: Money
    get() {
      return feedInCompensation + savingsOwnConsumption - operatingCosts
    }

  /**
   * Cash flow from financing
   * "Cashflow aus Finanzierungstätigkeit": Summe der Ein- und Auszahlungen, die die Kapitalbeschaffung und -rückzahlung des Unternehmens
   */
  val cashFlowFromFinancingActivities: @NegativeOrZero Money
    get() {
      return -financingInformation.interest - financingInformation.repayment
    }

  /**
   * Total cash flow
   */
  val cashFlow: Money
    get() {
      return cashFlowFromOperations + cashFlowFromFinancingActivities
    }

  /**
   * All spending (from operation and financing activities)
   */
  val totalSpending: Money
    get() {
      return operatingCosts + financingInformation.spending
    }

  class Calculator(
    /**
     * The base price for the operating costs
     */
    var operatingCosts: IncreasingMoney
  ) {

    fun calculate(
      yearIndex: YearIndex,
      tariffInformation: TariffInformation,
      energyInformation: EnergyInformation,
      financingInformation: FinancingInformation
    ): CashFlow {

      return CashFlow(
        feedInCompensation = energyInformation.feedIn * tariffInformation.feedInPrice,
        savingsOwnConsumption = energyInformation.ownConsumption * tariffInformation.ownConsumptionPrice,
        operatingCosts = operatingCosts.calculateValue(yearIndex.index),
        financingInformation = financingInformation
      )
    }
  }
}
