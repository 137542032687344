package it.neckar.lizergy.model.configuration

import it.neckar.customer.company.CompanyProfile
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedAssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedFacilityConfiguration
import it.neckar.lizergy.model.configuration.energy.AmountOfEnergy
import it.neckar.lizergy.model.configuration.energy.BatteryCapacity
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.CalculatedPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.InterpolatedSelfSufficiencyCalculator
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.PowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModulesReport
import it.neckar.lizergy.model.configuration.quote.builder.MetalTilesCalculator
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedWallboxSelection
import it.neckar.lizergy.model.configuration.quote.economics.FeedInPrices
import it.neckar.lizergy.model.configuration.quote.economics.YearlyCostInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.PricePerKWh
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.validation.NeedsValidation
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.lizergy.model.validation.TitleMessage
import it.neckar.lizergy.model.validation.ValidationProblem
import it.neckar.lizergy.model.validation.ValidationProblems
import it.neckar.open.kotlin.lang.ifBlank
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.km
import it.neckar.open.unit.time.pa

/**
 * A [PhotovoltaicsConfiguration] with additional values used by the planner
 * Contains resolved data instead of references by ID
 */
interface PlannerConfiguration : PhotovoltaicsConfiguration, NeedsValidation {

  val moduleLayouts: ResolvedModuleLayouts

  override val wallboxSelection: ResolvedWallboxSelection

  override val yearlyCosts: YearlyCostInformation

  override val assemblyConfiguration: ResolvedAssemblyConfiguration

  override val facilityConfiguration: ResolvedFacilityConfiguration

  override val electricityWorkConfiguration: ResolvedElectricityWorkConfiguration

  override val earningsDistribution: ResolvedEarningsDistribution

  val sellingCompanyInformation: PlannerCompanyInformation
  val monitoringCompanyInformation: PlannerCompanyInformation


  /**
   * Returns an automatically generated name for this quote
   * based on [PowerRating] and [BatteryConfiguration] storage
   * or a manual label, should one be set
   */
  val configurationName: String
    get() = label.ifBlank {
      buildString {
        append("PV-Anlage mit ${totalPowerRating.formatKiloWattPeak()}")
        facilityConfiguration.batteryConfiguration?.let {
          append(", Batterie ${it.totalStorage.format()}")
        }
      }
    }

  val sellingCompanyProfile: CompanyProfile
    get() = sellingCompanyInformation.companyProfile

  /**
   * The total [PowerRating] of all selected modules for this [PlannerConfiguration]
   *  modified by the PerformanceFactor for each corresponding [ResolvedModuleLayout]
   */
  val actualTotalPowerRating: PowerRating
    get() = moduleLayouts.actualPowerRating

  /**
   * The total (hypothetical) [PowerRating] of all selected modules for this [PlannerConfiguration]
   * Attention: Use [actualTotalPowerRating] for the actual power rating for calculations
   */
  val totalPowerRating: PowerRating
    get() = moduleLayouts.totalPowerRating

  /**
   * The total number of all modules (may contain different modules)
   */
  val totalNumberOfModules: Int
    get() = moduleLayouts.totalNumberOfModules

  val calculatedOfModulesKreuzverbund: Int
    get() = moduleLayouts.numberOfModulesKreuzverbund

  val numberOfModulesKreuzverbund: Int
    get() = assemblyConfiguration.numberOfModulesKreuzverbund ?: calculatedOfModulesKreuzverbund

  val calculatedPowerConsumptionDistribution: CalculatedPowerConsumptionDistribution
    get() = InterpolatedSelfSufficiencyCalculator.calculatePowerConsumptionDistribution(
      totalAnnualProduction = totalAnnualProduction,
      batteryCapacity = facilityConfiguration.batteryConfiguration?.totalCapacity ?: BatteryCapacity.Zero,
      annualElectricityConsumption = powerUsageScenario.yearlyPowerConsumptionValue,
    ) ?: CalculatedPowerConsumptionDistribution(0.0, 0.0)

  /**
   * Returns the power consumption distribution.
   * Returns the *manual* value, if there is one set, the calculated value otherwise
   */
  val powerConsumptionDistribution: PowerConsumptionDistribution
    get() = this.manualPowerConsumptionDistribution ?: calculatedPowerConsumptionDistribution

  /**
   * Calculates the actual, annual, yearly production for all roofs.
   */
  val totalAnnualProduction: @pa AmountOfEnergy
    get() = location.specificAnnualProduction * moduleLayouts.actualPowerRating

  /**
   * The amount of energy provided by the pv system that is consumed
   */
  val powerConsumptionProvidedByPv: @pa AmountOfEnergy
    get() = powerUsageScenario.yearlyPowerConsumptionValue * powerConsumptionDistribution.selfProducedPercentage

  /**
   * The percentage of the [totalAnnualProduction] that is consumed
   */
  val ownUsage: @pct Double
    get() = powerConsumptionProvidedByPv / totalAnnualProduction

  fun invalidManagerCombination(): Boolean? {
    return facilityConfiguration.invalidManagerCombination(electricityWorkConfiguration)
  }

  // Metalldachziegel (for all roofs)
  // First collect for all roofs
  val metalTilesCalculator: MetalTilesCalculator
    get() = MetalTilesCalculator().apply {
      moduleLayouts.fastForEach { moduleLayout ->
        val roof = moduleLayout.roof
        if (roof.roofTile != null && roof.metalRoofTileConstructionType != null) {
          addForRoof(
            roof = roof,
            roofTile = roof.roofTile,
            modulesCount = moduleLayout.modulesCount,
            metalRoofTileConstructionType = roof.metalRoofTileConstructionType,
          )
        }
      }
    }

  val numberOfModulesWithSchwarzeKlemmen: Int
    get() = moduleLayouts.validElements.filter {
      it.roof.schwarzeKlemmen
    }.sumOf { it.modulesCount }

  val guaranteedFeedInPrice: PricePerKWh
    get() = pricesTrendScenario.guaranteedFeedInPrice ?: FeedInPrices[totalPowerRating]

  /**
   * Creates the modules report
   */
  val modulesReport: ResolvedModulesReport
    get() = moduleLayouts.modulesReport

  val shippingDistance: @km Int?
    get() = shippingDistanceManual ?: shippingDistanceCalculated


  override val validationProblems: ValidationProblems
    get() = ValidationProblems(
      titleMessages = listOf(
        TitleMessage(null) { "Angebot $configurationName sieht gut aus!" },
        TitleMessage(ProblemType.Error) { "Die folgenden $it Felder müssen noch im Angebot $configurationName ausgefüllt werden:" },
        TitleMessage(ProblemType.Warning) { "Die folgenden $it Felder sollten im Angebot $configurationName überprüft werden:" },
      ),
      ownProblems = listOf(
        ValidationProblem("Entfernung zum Objekt") { if (shippingDistance == null) ProblemType.Error else null },
        ValidationProblem("Anzahl Überspannungsschutz") { if (facilityConfiguration.numberUeberspannungsSchutz == null || facilityConfiguration.numberUeberspannungsSchutz == 0) ProblemType.Error else null },
        ValidationProblem("Anlagenbetreiber oder Geburtstag fehlt") { if (facilityOperator1.isValid().not()) ProblemType.Warning else null },

        // Warnings
        ValidationProblem("Standort ist unvollständig") { if (location.address.isPlausible.not()) ProblemType.Warning else null },
      ),
      childProblems = listOf(moduleLayouts.validationProblems),
    )


  operator fun get(moduleLayoutId: ModuleLayoutId): ResolvedModuleLayout {
    return moduleLayouts[moduleLayoutId]
  }

  fun scaffoldingAreas(): Map<ModuleLayoutId?, Int> {
    return assemblyConfiguration.scaffoldingArea?.associateBy { null } ?: moduleLayouts.scaffoldingAreas()
  }

}
