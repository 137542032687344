package services

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuid4
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.request.*
import it.neckar.comments.Comment
import it.neckar.common.auth.LoginResponse
import it.neckar.common.redux.dispatch
import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.ktor.client.auth.clearJwsToken
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.assemblyPortfolio.ResolvedAssemblyPortfolio
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.PlannerConfiguration
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.components.ExistingBHKWFacilityConfiguration
import it.neckar.lizergy.model.configuration.components.ExistingPVFacilityConfiguration
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfigurations
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshots
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.ProjectConfiguration
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.project.previews.AccountingProjectPreview
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyBasementProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry.BlueprintAcquisitionProcessStates
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry.ConfigurationProcessStates
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.lizergy.model.stumps.AdvanceInvoice
import it.neckar.lizergy.model.stumps.AssemblyBasement
import it.neckar.lizergy.model.stumps.AssemblyRoof
import it.neckar.lizergy.model.stumps.Documentation
import it.neckar.lizergy.model.stumps.FinalAccount
import it.neckar.lizergy.model.stumps.FinishingUp
import it.neckar.lizergy.model.stumps.GridAssessment
import it.neckar.lizergy.model.stumps.OrderSpecialMaterial
import it.neckar.lizergy.model.stumps.StartupOperations
import it.neckar.lizergy.model.stumps.SwitchMeterBox
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.open.collections.fastForEach
import it.neckar.open.time.nowMillis
import it.neckar.processStatesClient.SendProcessStatesTuple
import it.neckar.user.UserLoginName
import kotlinx.coroutines.*
import serialized.ModuleLayoutsConfiguration
import serialized.SerializedModuleLayout
import serialized.unResolve
import services.auth.http.AddNewCompanyResponse
import services.auth.http.AddNewUserResponse
import services.auth.http.ChangePasswordResponse
import services.auth.http.ChangeUserInfoResponse
import services.auth.http.ChangedCompanyInfoResponse
import services.auth.http.DeletedUserResponse
import services.http.AccountingProjectQueryResponse
import services.http.AccountingQuerySelection
import services.http.AllAccountingProjectPreviewsAsCSVResponse
import services.http.PlannerUiServices
import services.http.ProjectCountQueryResponse
import services.http.ProjectCountRequestForPhase
import services.http.ProjectQueryResponse
import services.http.ProjectQuerySorting
import services.storage.http.SendResolvedProjectRequest
import store.actions.AddProjectAction
import store.actions.AddProjectsAction
import store.actions.AddUserAction
import store.actions.ArchiveProjectAction
import store.actions.CommentAddedAction
import store.actions.CompanyAddedAction
import store.actions.DeleteUserAction
import store.actions.LoggedInAction
import store.actions.LogoutAction
import store.actions.LogoutReason
import store.actions.ProcessStateUpdatedAction
import store.actions.ProcessStatesAddedAction
import store.actions.UpdateAssemblyBasementAction
import store.actions.UpdateAssemblyPortfolioAction
import store.actions.UpdateCompanyAction
import store.actions.UpdateGridAssessmentAction
import store.actions.UpdateProjectAction
import store.actions.UpdateQuoteConfigurationAction
import store.actions.UpdateUserAction

/**
 * Contains the planner workflows.
 *
 * Each workflow:
 * - executes one action - usually involving remote access
 * - updates the state - if necessary
 * - does *NOT* show any notifications / messages
 *
 * Each function is a suspend function if only a single task is executed.
 * If other coroutines are started, the [coroutineScope] is provided as first parameter.
 *
 *
 * Some/most of the methods are "optimistic". They return immediately after updating the store and schedule the updates on the server.
 * Other methods wait for the server response - they are marked as suspend.
 */
class PlannerWorkflow {

  /**
   * Tries to log in a user
   */
  suspend fun login(loginName: UserLoginName, password: String): LoginResponse {
    return PlannerUiServices.authenticationService.login(loginName, password).also { loginResponse ->
      logger.debug("Logged in: $loginResponse")
      when (loginResponse) {
        is LoginResponse.Success -> {
          val data = loginResponse.data
          dispatch(LoggedInAction(data.loginName, password, data.jwsTokens))
        }

        LoginResponse.Failure -> {}
      }
    }
  }

  fun logout(logoutReason: LogoutReason) {
    dispatch(LogoutAction(logoutReason))

    //Force clearing the token
    PlannerUiServices.httpClientWithAuth.clearJwsToken()
  }

  /**
   * Adds a new user
   */
  suspend fun addNewUser(newUser: UserInformation, password: String): AddNewUserResponse {
    return PlannerUiServices.authenticationService.addNewUser(newUser, password).also {
      when (it) {
        is AddNewUserResponse.Success -> dispatch(AddUserAction(newUser))
        is AddNewUserResponse.Failure -> {}
      }
    }
  }

  suspend fun changedUserInfoResult(updatedUserInformation: UserInformation): ChangeUserInfoResponse {
    return PlannerUiServices.authenticationService.changeUserInfo(updatedUserInformation).also {
      when (it) {
        is ChangeUserInfoResponse.Success -> dispatch(UpdateUserAction(updatedUserInformation))
        is ChangeUserInfoResponse.Failure -> {}
      }
    }
  }

  suspend fun deleteUser(user: UserInformation): DeletedUserResponse {
    return PlannerUiServices.authenticationService.deleteUser(user).also {
      when (it) {
        is DeletedUserResponse.Success -> dispatch(DeleteUserAction(user))
        is DeletedUserResponse.Failure -> {}
      }
    }
  }

  suspend fun changePassword(loginName: UserLoginName, newPassword: String): ChangePasswordResponse {
    return PlannerUiServices.authenticationService.changePassword(loginName, /*oldPassword,*/ newPassword)
  }

  suspend fun addNewCompany(newCompany: PlannerCompanyInformation): AddNewCompanyResponse {
    return PlannerUiServices.authenticationService.addNewCompany(newCompany).also {
      when (it) {
        is AddNewCompanyResponse.Success -> dispatch(CompanyAddedAction(newCompany))
        is AddNewCompanyResponse.Failure -> {}
      }
    }
  }

  suspend fun changedCompanyInfo(updatedCompanyInformation: PlannerCompanyInformation): ChangedCompanyInfoResponse {
    return PlannerUiServices.authenticationService.changeCompanyInfo(updatedCompanyInformation).also {
      when (it) {
        is ChangedCompanyInfoResponse.Success -> dispatch(UpdateCompanyAction(updatedCompanyInformation))
        is ChangedCompanyInfoResponse.Failure -> {}
      }
    }
  }

  /**
   * Saves the [QuoteConfigurations]
   */
  private fun CoroutineScope.saveQuoteConfigurations(projectId: PhotovoltaicsProjectId, quoteConfigurations: QuoteConfigurations) {
    //TODO is this valid(?!)
    quoteConfigurations.fastForEach { resolvedConfiguration ->
      launch {
        PlannerUiServices.projectQueryService.sendQuoteConfiguration(projectId, resolvedConfiguration)
      }
    }
  }

  /**
   * Creates a new [ResolvedProject]
   * Writing to the server is scheduled later.
   */
  fun createProjectForCustomerOptimistic(
    coroutineScope: CoroutineScope,
    customer: Customer,
    maintainer: UserInformation,
    blueprint: ResolvedBlueprint,
    loggedInUser: UserInformation,
  ): ResolvedProject {
    return ResolvedProject(
      projectId = blueprint.projectId,
      projectName = "",
      projectDescription = "",
      sellingCompanyInformation = blueprint.sellingCompanyInformation,
      customer = customer,
      maintainerInformation = maintainer,
      blueprint = blueprint,
      quoteConfigurations = QuoteConfigurations.empty,
      orderSpecialMaterial = OrderSpecialMaterial(uuid4(), blueprint.projectId),
      gridAssessment = GridAssessment(uuid4(), blueprint.projectId),
      assemblyPortfolio = ResolvedAssemblyPortfolio.getEmpty(blueprint.projectId, uuid4()),
      advanceInvoice = AdvanceInvoice(uuid4(), blueprint.projectId),
      assemblyRoof = AssemblyRoof(uuid4(), blueprint.projectId),
      assemblyBasement = AssemblyBasement(uuid4(), blueprint.projectId),
      switchMeterBox = SwitchMeterBox(uuid4(), blueprint.projectId),
      startupOperations = StartupOperations(uuid4(), blueprint.projectId),
      finishingUp = FinishingUp(uuid4(), blueprint.projectId),
      finalAccount = FinalAccount(uuid4(), blueprint.projectId),
      documentation = Documentation(uuid4(), blueprint.projectId),
      verification = null,
      archiveReasons = ArchiveReasons.getEmpty(),
      creationTime = nowMillis(),
    ).also { project ->
      createNewProject(coroutineScope, project)
      val processStatesForComponents = processStatesTuplesForProject(project, maintainer, loggedInUser)
      addProcessStates(coroutineScope = coroutineScope, processStateForComponents = processStatesForComponents)
    }
  }

  /**
   * Creates a new [ResolvedProject] from a given [PlannerConfiguration]
   * The created Project is a sample project
   */
  fun createSampleProjects(
    coroutineScope: CoroutineScope,
    loggedInUser: UserInformation,
    resolvedProjects: List<ResolvedProject>,
  ): List<ResolvedProject> {
    createNewProjects(coroutineScope, resolvedProjects)
    val processStatesForComponents = processStatesTuplesForProjects(resolvedProjects, loggedInUser)
    addProcessStates(coroutineScope = coroutineScope, processStateForComponents = processStatesForComponents)
    return resolvedProjects
  }

  fun createRandomSampleProjects(
    coroutineScope: CoroutineScope,
    loggedInUser: UserInformation,
    resolvedProjects: List<ResolvedProject>,
  ): List<ResolvedProject> {
    createNewProjects(coroutineScope, resolvedProjects)
    val processStatesForComponents = processStatesTuplesForProjects(resolvedProjects, loggedInUser)
    addProcessStates(coroutineScope = coroutineScope, processStateForComponents = processStatesForComponents)
    return resolvedProjects
  }

  private fun createNewProject(coroutineScope: CoroutineScope, newProject: ResolvedProject) {
    //Add the project immediately
    dispatch(AddProjectAction(newProject))

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendResolvedProject(newProject)
    }
  }

  private fun createNewProjects(coroutineScope: CoroutineScope, newProjects: List<ResolvedProject>) {
    //Add the project immediately
    dispatch(AddProjectsAction(newProjects))

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendMultipleResolvedProjects(newProjects.map { SendResolvedProjectRequest(it) })
    }
  }

  /**
   * Saves the [ResolvedProject] - updates the state optimistically
   */
  fun saveProject(coroutineScope: CoroutineScope, updatedProject: ResolvedProject) {
    //Update the project immediately
    dispatch(UpdateProjectAction(updatedProject.projectId) {
      updatedProject
    })

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendProject(updatedProject)
    }
  }

  fun archiveProjectOptimistic(coroutineScope: CoroutineScope, projectId: PhotovoltaicsProjectId, archiveReasons: ArchiveReasons, loggedInUser: UserLoginName) {
    dispatch(ArchiveProjectAction(projectId, archiveReasons, loggedInUser))

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.archiveProject(projectId, archiveReasons)
    }
  }

  suspend fun queryProjectPreviews(
    processStatesToFilter: List<LizergyProcessStates>,
    processStatesToHide: List<LizergyProcessStates>,
    filteredByCompanyCode: CompanyCode?,
    filteredByMaintainer: UserLoginName?,
    filteredByEditor: UserLoginName?,
    filterValueProject: String?,
    filterValueAddress: String?,
    filterByProjectState: LizergyProcessStates?,
    indexOfFirstVisibleProject: Int,
    indexOfLastVisibleProject: Int,
    sorting: ProjectQuerySorting,
    projectQueryComponent: ProjectQueryComponent?,
    includeArchived: Boolean,
  ): ProjectQueryResponse {
    return PlannerUiServices.projectQueryService.queryProjectPreviews(
      processStatesToFilter = processStatesToFilter,
      processStatesToHide = processStatesToHide,
      filteredByCompanyCode = filteredByCompanyCode,
      filteredByMaintainer = filteredByMaintainer,
      filteredByEditor = filteredByEditor,
      filterValueProject = filterValueProject,
      filterValueAddress = filterValueAddress,
      filterByProjectState = filterByProjectState,
      indexOfFirstVisibleProject = indexOfFirstVisibleProject,
      indexOfLastVisibleProject = indexOfLastVisibleProject,
      sorting = sorting,
      projectQueryComponent = projectQueryComponent,
      includeArchived = includeArchived,
    )
  }

  suspend fun countProjectPreviews(
    requestedProjectCountsForPhases: List<ProjectCountRequestForPhase>,
    loggedInUser: UserLoginName,
  ): ProjectCountQueryResponse {
    return PlannerUiServices.projectQueryService.countProjectPreviews(
      requestedProjectCountsForPhases = requestedProjectCountsForPhases,
      loggedInUser = loggedInUser,
    )
  }

  suspend fun accountingProjectPreviews(
    processStatesToFilter: List<LizergyProcessStates>,
    processStatesToHide: List<LizergyProcessStates>,
    accountingQuerySelection: AccountingQuerySelection,
    filteredByCompanyCode: CompanyCode?,
    filteredByMaintainer: UserLoginName?,
    filteredByEditor: UserLoginName?,
    filterValueProject: String?,
    filterValueAddress: String?,
    filterByProjectState: LizergyProcessStates?,
    indexOfFirstVisibleProject: Int,
    indexOfLastVisibleProject: Int,
    sorting: ProjectQuerySorting,
    projectQueryComponent: ProjectQueryComponent?,
    howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements,
    loggedInUser: UserLoginName,
  ): AccountingProjectQueryResponse {
    return PlannerUiServices.projectQueryService.accountingProjectPreviews(
      processStatesToFilter = processStatesToFilter,
      processStatesToHide = processStatesToHide,
      accountingQuerySelection = accountingQuerySelection,
      filteredByCompanyCode = filteredByCompanyCode,
      filteredByMaintainer = filteredByMaintainer,
      filteredByEditor = filteredByEditor,
      filterValueProject = filterValueProject,
      filterValueAddress = filterValueAddress,
      filterByProjectState = filterByProjectState,
      indexOfFirstVisibleProject = indexOfFirstVisibleProject,
      indexOfLastVisibleProject = indexOfLastVisibleProject,
      sorting = sorting,
      projectQueryComponent = projectQueryComponent,
      howDoYouLikeYourQuoteElements = howDoYouLikeYourQuoteElements,
      loggedInUser = loggedInUser,
    )
  }

  suspend fun allAccountingProjectPreviewsAsCSV(): AllAccountingProjectPreviewsAsCSVResponse {
    return PlannerUiServices.projectQueryService.allAccountingProjectPreviewsAsCSV()
  }

  /**
   * Saves the [ResolvedBlueprint]
   */
  fun saveBlueprint(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint) {
    //Update the [Blueprint] immediately
    dispatch(UpdateProjectAction(blueprint.projectId) {
      this.copy(blueprint = blueprint)
    })

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendBlueprint(blueprint)
    }
  }

  fun saveVerification(coroutineScope: CoroutineScope, project: ResolvedProject, verification: Verification) {
    dispatch(UpdateProjectAction(project.projectId) {
      copy(verification = verification)
    })

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendVerification(project.projectId, verification)
    }
  }

  fun deleteVerification(coroutineScope: CoroutineScope, project: ResolvedProject) {
    dispatch(UpdateProjectAction(project.projectId) {
      copy(verification = null)
    })

    val newVerification = project.verification?.copy(lifeCycleState = LifeCycleState.EndOfLife) ?: return

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendVerification(project.projectId, newVerification)
    }
  }

  /**
   * Converts a [ResolvedBlueprint] to [QuoteConfigurations]
   */
  fun resolveBlueprintToQuoteConfigurations(
    coroutineScope: CoroutineScope,
    blueprint: ResolvedBlueprint,
    assignedTo: UserLoginName,
    belongsTo: CompanyCode,
    availableProducts: AvailableProducts,
    priceList: PriceList,
    companyResolver: CompanyResolver,
    loggedInUser: UserInformation,
  ): QuoteConfigurations {
    val newQuoteConfigurations = blueprint.toQuoteConfigurations(loggedInUser = loggedInUser.loginName, availableProducts = availableProducts, priceList = priceList, companyResolver = companyResolver)

    dispatch(UpdateProjectAction(blueprint.projectId) {
      copy(quoteConfigurations = quoteConfigurations.withAdded(newQuoteConfigurations.elements))
    })

    coroutineScope.saveQuoteConfigurations(blueprint.projectId, newQuoteConfigurations)

    newQuoteConfigurations.elements.fastForEach {
      addProcessState(coroutineScope, it.uuid, ConfigurationProcessStates.New.toProcessStateEntry(assignedTo = assignedTo, belongsTo = belongsTo, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser.loginName))
    }
    addProcessState(coroutineScope, blueprint.uuid, BlueprintProcessStateEntry.BlueprintProcessStates.Done.toProcessStateEntry(assignedTo = assignedTo, belongsTo = belongsTo, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser.loginName))

    return newQuoteConfigurations
  }


  /**
   * Adds a new [QuoteConfiguration] to the given [ResolvedProject]
   */
  fun addQuoteConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, availableProducts: AvailableProducts, priceList: PriceList, companyResolver: CompanyResolver, loggedInUser: UserInformation): QuoteConfiguration {
    val newQuoteConfiguration = QuoteConfiguration.getEmpty(
      sellingCompany = project.sellingCompanyInformation,
      monitoringCompany = companyResolver[project.sellingCompanyInformation.companyProfile.relevantParentCompanyCode()],
      editor = loggedInUser,
      resolvedModuleLayouts = ResolvedModuleLayouts.createDefault(availableProducts),
      currentQuoteSnapshot = null,
      availableProducts = availableProducts,
      priceList = priceList,
      loggedInUser = loggedInUser.loginName,
    )

    val updatedElements = project.quoteConfigurations.withAdded(newQuoteConfiguration)

    dispatch(UpdateProjectAction(project.projectId) {
      copy(quoteConfigurations = updatedElements)
    })

    coroutineScope.launch {
      sendQuoteConfiguration(project, newQuoteConfiguration)
      addProcessState(coroutineScope, newQuoteConfiguration.uuid, ConfigurationProcessStates.New.toProcessStateEntry(assignedTo = loggedInUser.loginName, belongsTo = loggedInUser.company.companyCode, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser.loginName))
    }

    return newQuoteConfiguration
  }

  /**
   * Duplicates the given [QuoteConfiguration]
   */
  fun duplicateQuoteConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration, loggedInUser: UserInformation): QuoteConfiguration {
    val mapOfOldToNewUuids: MutableMap<Uuid, Uuid> = mutableMapOf()
    return quoteConfiguration.duplicate(mapOfOldToNewUuids).also { newQuoteConfiguration ->
      val updatedElements = project.quoteConfigurations.withAdded(newQuoteConfiguration)

      dispatch(UpdateProjectAction(project.projectId) {
        copy(quoteConfigurations = updatedElements)
      })

      coroutineScope.launch {
        sendQuoteConfiguration(project, newQuoteConfiguration)
      }
      coroutineScope.launch {
        duplicateComments(mapOfOldToNewUuids)
      }

      addProcessState(coroutineScope, newQuoteConfiguration.uuid, ConfigurationProcessStates.New.toProcessStateEntry(assignedTo = loggedInUser.loginName, belongsTo = loggedInUser.company.companyCode, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser.loginName))
    }
  }

  fun removeQuoteConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration) {
    val removedQuoteConfiguration = quoteConfiguration.copy(lifeCycleState = LifeCycleState.EndOfLife)
    val updatedElements = project.quoteConfigurations.withUpdated(removedQuoteConfiguration)

    dispatch(UpdateProjectAction(project.projectId) {
      copy(quoteConfigurations = updatedElements)
    })

    coroutineScope.launch {
      sendConfiguration(project, removedQuoteConfiguration)
      removedQuoteConfiguration.currentQuoteSnapshot?.let {
        removeQuoteSnapshot(coroutineScope, project.projectId, removedQuoteConfiguration, it)
      }
    }
  }

  fun saveQuoteConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration) {
    val oldConfiguration = project.quoteConfigurations[quoteConfiguration.configurationId]
    if (quoteConfiguration == oldConfiguration) return

    val updatedQuoteConfigurations = project.quoteConfigurations.withUpdated(quoteConfiguration)

    dispatch(UpdateProjectAction(project.projectId) {
      copy(quoteConfigurations = updatedQuoteConfigurations)
    })

    coroutineScope.launch {
      sendConfiguration(project, quoteConfiguration)
    }
  }

  suspend fun sendConfiguration(project: ProjectConfiguration, configuration: PlannerConfiguration) {
    PlannerUiServices.projectQueryService.sendConfiguration(project.projectId, configuration)
  }

  suspend fun sendQuoteConfiguration(project: ProjectConfiguration, quoteConfiguration: QuoteConfiguration) {
    PlannerUiServices.projectQueryService.sendQuoteConfiguration(project.projectId, quoteConfiguration)
  }

  /**
   * Adds a new [ResolvedModuleLayout] to the [ResolvedBlueprint]
   */
  fun addModuleLayout(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint, availableProducts: AvailableProducts): ResolvedModuleLayout {
    return ResolvedModuleLayout.createDefault(
      moduleType = availableProducts.availableModules().first(),
      roofType = availableProducts.availableRoofTypes().first(),
    ).also { moduleLayout ->
      addModuleLayout(coroutineScope, blueprint, moduleLayout)
    }
  }

  /**
   * Adds a new [ResolvedModuleLayout] to the [QuoteConfiguration]
   */
  fun addModuleLayout(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration, availableProducts: AvailableProducts): ResolvedModuleLayout {
    return ResolvedModuleLayout.createDefault(
      moduleType = availableProducts.availableModules().first(),
      roofType = availableProducts.availableRoofTypes().first(),
    ).also { moduleLayout ->
      addModuleLayout(coroutineScope, project, quoteConfiguration, moduleLayout)
    }
  }

  /**
   * Duplicates the given [ResolvedModuleLayout] for the [ResolvedBlueprint]
   * Sets the opposite orientation for the [ResolvedRoof]
   */
  fun duplicateModuleLayout(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint, moduleLayout: ResolvedModuleLayout): ResolvedModuleLayout {
    val mapOfOldToNewUuids: MutableMap<Uuid, Uuid> = mutableMapOf()
    return moduleLayout.duplicate(true, mapOfOldToNewUuids).also { newModuleLayout ->
      addModuleLayout(coroutineScope, blueprint, newModuleLayout)
      coroutineScope.launch {
        duplicateComments(mapOfOldToNewUuids)
      }
    }
  }

  /**
   * Duplicates the given [ResolvedModuleLayout] for the [QuoteConfiguration]
   * Sets the opposite orientation for the [ResolvedRoof]
   */
  fun duplicateModuleLayout(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration, moduleLayout: ResolvedModuleLayout): ResolvedModuleLayout {
    val mapOfOldToNewUuids: MutableMap<Uuid, Uuid> = mutableMapOf()
    return moduleLayout.duplicate(true, mapOfOldToNewUuids).also { newModuleLayout ->
      addModuleLayout(coroutineScope, project, quoteConfiguration, newModuleLayout)
      coroutineScope.launch {
        duplicateComments(mapOfOldToNewUuids)
      }
    }
  }

  /**
   * Adds and saves a [ResolvedModuleLayout] for the [ResolvedBlueprint]
   */
  private fun addModuleLayout(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint, moduleLayout: ResolvedModuleLayout) {
    val updatedRoofsConfiguration = blueprint.moduleLayouts.withAdded(moduleLayout)
    val updatedBlueprint = blueprint.copy(moduleLayouts = updatedRoofsConfiguration)

    dispatch(UpdateProjectAction(blueprint.projectId) {
      copy(blueprint = updatedBlueprint)
    })

    saveBlueprint(coroutineScope, updatedBlueprint)
  }

  /**
   * Adds and saves a [ResolvedModuleLayout] for the [QuoteConfiguration]
   */
  private fun addModuleLayout(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration, moduleLayout: ResolvedModuleLayout) {
    val updatedModuleLayouts = quoteConfiguration.moduleLayouts.withAdded(moduleLayout)

    dispatch(UpdateQuoteConfigurationAction(project.projectId, quoteConfiguration.configurationId) {
      copy(moduleLayouts = updatedModuleLayouts)
    })

    sendModuleLayouts(coroutineScope, project, quoteConfiguration, updatedModuleLayouts)
  }

  fun saveModuleLayout(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint, moduleLayout: ResolvedModuleLayout) {
    val updatedRoofs = blueprint.moduleLayouts.withUpdated(moduleLayout)
    val updatedBlueprint = blueprint.copy(moduleLayouts = updatedRoofs)

    dispatch(UpdateProjectAction(blueprint.projectId) {
      copy(blueprint = updatedBlueprint)
    })

    saveBlueprint(coroutineScope, updatedBlueprint)
  }

  fun saveModuleLayout(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration, moduleLayout: ResolvedModuleLayout) {
    val updatedModuleLayouts = quoteConfiguration.moduleLayouts.withUpdated(moduleLayout)

    dispatch(UpdateQuoteConfigurationAction(project.projectId, quoteConfiguration.configurationId) {
      copy(moduleLayouts = updatedModuleLayouts)
    })

    sendModuleLayouts(coroutineScope, project, quoteConfiguration, updatedModuleLayouts)
  }

  /**
   * Removes the [ResolvedModuleLayout] from the project for the [ResolvedBlueprint]
   */
  fun removeModuleLayout(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint, moduleLayout: ResolvedModuleLayout) {
    /**
     * Mark this [SerializedModuleLayout] as deleted
     */
    val updatedModuleLayouts = blueprint.moduleLayouts.withUpdated(moduleLayout.copy(lifeCycleState = LifeCycleState.EndOfLife))
    val updatedBlueprint = blueprint.copy(moduleLayouts = updatedModuleLayouts)

    /**
     * *ONLY* ever call one `dispatch` action per render call
     * as such, only one call in this function
     */
    dispatch(UpdateProjectAction(blueprint.projectId) {
      copy(blueprint = updatedBlueprint)
    })

    saveBlueprint(coroutineScope, updatedBlueprint)
  }

  /**
   * Removes the [ResolvedModuleLayout] from the project for the [QuoteConfiguration]
   */
  fun removeModuleLayout(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration, moduleLayout: ResolvedModuleLayout) {
    /**
     * Mark this [ResolvedModuleLayout] as deleted
     */
    val updatedModuleLayouts = quoteConfiguration.moduleLayouts.withUpdated(moduleLayout.copy(lifeCycleState = LifeCycleState.EndOfLife))

    /**
     * *ONLY* ever call one `dispatch` action per render call
     * as such, only one call in this function
     */
    dispatch(UpdateQuoteConfigurationAction(project.projectId, quoteConfiguration.configurationId) {
      copy(moduleLayouts = updatedModuleLayouts)
    })

    sendModuleLayouts(coroutineScope, project, quoteConfiguration, updatedModuleLayouts)
  }

  /**
   * Helper method that handles updates to the [ModuleLayoutsConfiguration] for a [PhotovoltaicsConfiguration]
   */
  private fun sendModuleLayouts(coroutineScope: CoroutineScope, project: ProjectConfiguration, configuration: PhotovoltaicsConfiguration, moduleLayouts: ModuleLayoutsConfiguration) {
    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendModuleLayouts(project.projectId, configuration.configurationId, moduleLayouts)
    }
  }

  private fun sendModuleLayouts(coroutineScope: CoroutineScope, project: ResolvedProject, configuration: PhotovoltaicsConfiguration, resolvedModuleLayouts: ResolvedModuleLayouts) {
    sendModuleLayouts(coroutineScope, project, configuration, resolvedModuleLayouts.unResolve())
  }


  /**
   * Adds a [ConfigurationItem] to the [ResolvedBlueprint]
   */
  fun addAdditionalPositionToBlueprint(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint): ConfigurationItem {
    val additionalConfigurationItem = ConfigurationItem.getEmpty(ValueAddedTax.nineteenPercent)

    val updatedAdditionalPositions = blueprint.additionalPositions.withAdded(additionalConfigurationItem)
    saveBlueprint(coroutineScope = coroutineScope, blueprint = blueprint.copy(additionalPositions = updatedAdditionalPositions))

    return additionalConfigurationItem
  }

  /**
   * Adds a [ConfigurationItem] to the [QuoteConfiguration]
   */
  fun addAdditionalPositionToConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration): ConfigurationItem {
    val additionalConfigurationItem = ConfigurationItem.getEmpty(ValueAddedTax.nineteenPercent)

    val updatedAdditionalPositions = quoteConfiguration.additionalPositions.withAdded(additionalConfigurationItem)
    saveQuoteConfiguration(
      coroutineScope,
      project = project,
      quoteConfiguration.copy(additionalPositions = updatedAdditionalPositions),
    )

    return additionalConfigurationItem
  }

  /**
   * Removes the given additional [ConfigurationItem] from the quote [ResolvedBlueprint]
   */
  fun removeAdditionalPositionFromBlueprint(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint, toDelete: ConfigurationItem) {
    val updatedAdditionalPositions = blueprint.additionalPositions.withRemoved(toDelete)
    saveBlueprint(coroutineScope = coroutineScope, blueprint = blueprint.copy(additionalPositions = updatedAdditionalPositions))
  }

  /**
   * Removes the given additional [ConfigurationItem] from the quote [QuoteConfiguration]
   */
  fun removeAdditionalPositionFromConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration, toDelete: ConfigurationItem) {
    val updatedAdditionalPositions = quoteConfiguration.additionalPositions.withRemoved(toDelete)
    saveQuoteConfiguration(
      coroutineScope,
      project = project,
      quoteConfiguration.copy(additionalPositions = updatedAdditionalPositions),
    )
  }


  fun addExistingPVFacilityToBlueprint(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint): ExistingPVFacilityConfiguration {
    val existingPVFacilityConfiguration = ExistingPVFacilityConfiguration.getEmpty()

    val updatedExistingFacilitiesConfiguration = blueprint.existingFacilitiesConfiguration.withAdded(existingPVFacilityConfiguration)
    saveBlueprint(coroutineScope = coroutineScope, blueprint = blueprint.copy(existingFacilitiesConfiguration = updatedExistingFacilitiesConfiguration))

    return existingPVFacilityConfiguration
  }

  fun addExistingBHKWFacilityToBlueprint(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint): ExistingBHKWFacilityConfiguration {
    val existingBHKWFacilityConfiguration = ExistingBHKWFacilityConfiguration.getEmpty()

    val updatedExistingFacilitiesConfiguration = blueprint.existingFacilitiesConfiguration.withAdded(existingBHKWFacilityConfiguration)
    saveBlueprint(coroutineScope = coroutineScope, blueprint = blueprint.copy(existingFacilitiesConfiguration = updatedExistingFacilitiesConfiguration))

    return existingBHKWFacilityConfiguration
  }

  fun addExistingPVFacilityToConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration): ExistingPVFacilityConfiguration {
    val updatedExistingFacilitiesConfiguration = ExistingPVFacilityConfiguration.getEmpty()

    val updatedAdditionalPositions = quoteConfiguration.existingFacilitiesConfiguration.withAdded(updatedExistingFacilitiesConfiguration)
    saveQuoteConfiguration(
      coroutineScope,
      project = project,
      quoteConfiguration.copy(existingFacilitiesConfiguration = updatedAdditionalPositions),
    )

    return updatedExistingFacilitiesConfiguration
  }

  fun addExistingBHKWFacilityToConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration): ExistingBHKWFacilityConfiguration {
    val updatedExistingFacilitiesConfiguration = ExistingBHKWFacilityConfiguration.getEmpty()

    val updatedAdditionalPositions = quoteConfiguration.existingFacilitiesConfiguration.withAdded(updatedExistingFacilitiesConfiguration)
    saveQuoteConfiguration(
      coroutineScope,
      project = project,
      quoteConfiguration.copy(existingFacilitiesConfiguration = updatedAdditionalPositions),
    )

    return updatedExistingFacilitiesConfiguration
  }

  fun removeExistingPVFacilityFromBlueprint(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint, toDelete: ExistingPVFacilityConfiguration) {
    val updatedExistingFacilitiesConfiguration = blueprint.existingFacilitiesConfiguration.withRemoved(toDelete)
    saveBlueprint(coroutineScope = coroutineScope, blueprint = blueprint.copy(existingFacilitiesConfiguration = updatedExistingFacilitiesConfiguration))
  }

  fun removeExistingBHKWFacilityFromBlueprint(coroutineScope: CoroutineScope, blueprint: ResolvedBlueprint, toDelete: ExistingBHKWFacilityConfiguration) {
    val updatedExistingFacilitiesConfiguration = blueprint.existingFacilitiesConfiguration.withRemoved(toDelete)
    saveBlueprint(coroutineScope = coroutineScope, blueprint = blueprint.copy(existingFacilitiesConfiguration = updatedExistingFacilitiesConfiguration))
  }

  fun removeExistingPVFacilityFromConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration, toDelete: ExistingPVFacilityConfiguration) {
    val updatedExistingFacilitiesConfiguration = quoteConfiguration.existingFacilitiesConfiguration.withRemoved(toDelete)
    saveQuoteConfiguration(
      coroutineScope,
      project = project,
      quoteConfiguration.copy(existingFacilitiesConfiguration = updatedExistingFacilitiesConfiguration),
    )
  }

  fun removeExistingBHKWFacilityFromConfiguration(coroutineScope: CoroutineScope, project: ResolvedProject, quoteConfiguration: QuoteConfiguration, toDelete: ExistingBHKWFacilityConfiguration) {
    val updatedExistingFacilitiesConfiguration = quoteConfiguration.existingFacilitiesConfiguration.withRemoved(toDelete)
    saveQuoteConfiguration(
      coroutineScope,
      project = project,
      quoteConfiguration.copy(existingFacilitiesConfiguration = updatedExistingFacilitiesConfiguration),
    )
  }


  /**
   * Adds and saves the [QuoteSnapshot] to the [QuoteConfiguration]
   */
  fun addQuoteSnapshot(
    coroutineScope: CoroutineScope,
    projectId: PhotovoltaicsProjectId,
    quoteConfiguration: QuoteConfiguration,
    quoteSnapshot: QuoteSnapshot,
  ): QuoteSnapshot {
    dispatch(UpdateQuoteConfigurationAction(projectId, quoteConfiguration.configurationId) {
      copy(currentQuoteSnapshot = quoteSnapshot)
    })

    coroutineScope.launch {
      sendQuoteSnapshot(projectId, quoteConfiguration.configurationId, quoteSnapshot)
    }

    return quoteSnapshot
  }

  /**
   * Removes the [QuoteSnapshot] from the [QuoteConfiguration]
   */
  fun removeQuoteSnapshot(coroutineScope: CoroutineScope, projectId: PhotovoltaicsProjectId, quoteConfiguration: QuoteConfiguration, toRemove: QuoteSnapshot) {
    dispatch(UpdateQuoteConfigurationAction(projectId, quoteConfiguration.configurationId) {
      copy(currentQuoteSnapshot = null)
    })

    val updatedQuoteSnapshot = toRemove.copy(lifeCycleState = LifeCycleState.EndOfLife)
    coroutineScope.launch {
      sendQuoteSnapshot(projectId, quoteConfiguration.configurationId, updatedQuoteSnapshot)
    }
  }

  /**
   * Helper method that handles updates to the [QuoteSnapshots]
   */
  suspend fun sendQuoteSnapshot(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshot: QuoteSnapshot) {
    PlannerUiServices.projectQueryService.sendQuoteSnapshots(projectId, configurationId, quoteSnapshot)
  }


  /**
   * Saves the [AssemblyBasement] - updates the state optimistically
   */
  fun saveAssemblyBasement(coroutineScope: CoroutineScope, updatedAssemblyBasement: AssemblyBasement) {
    //Update the project immediately
    dispatch(UpdateAssemblyBasementAction(updatedAssemblyBasement.projectId, updatedAssemblyBasement))

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendAssemblyBasement(updatedAssemblyBasement.projectId, updatedAssemblyBasement)
    }
  }

  /**
   * Saves the [GridAssessment] - updates the state optimistically
   */
  fun saveGridAssessment(coroutineScope: CoroutineScope, updatedGridAssessment: GridAssessment) {
    //Update the project immediately
    dispatch(UpdateGridAssessmentAction(updatedGridAssessment.projectId, updatedGridAssessment))

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendGridAssessment(updatedGridAssessment.projectId, updatedGridAssessment)
    }
  }

  /**
   * Saves the [ResolvedAssemblyPortfolio] - updates the state optimistically
   */
  fun saveAssemblyPortfolio(coroutineScope: CoroutineScope, updatedAssemblyPortfolio: ResolvedAssemblyPortfolio) {
    //Update the project immediately
    dispatch(UpdateAssemblyPortfolioAction(updatedAssemblyPortfolio.projectId, updatedAssemblyPortfolio))

    coroutineScope.launch {
      PlannerUiServices.projectQueryService.sendAssemblyPortfolio(updatedAssemblyPortfolio.projectId, updatedAssemblyPortfolio)
    }
  }


  fun addComment(coroutineScope: CoroutineScope, commentFor: Uuid, newComment: Comment) {
    dispatch(CommentAddedAction(commentFor, newComment))

    coroutineScope.launch {
      sendComment(commentFor, newComment)
    }
  }

  suspend fun sendComment(commentFor: Uuid, newComment: Comment) {
    PlannerUiServices.commentsService.sendComment(commentFor, newComment)
  }

  suspend fun duplicateComments(mapOfOldToNewUuids: Map<Uuid, Uuid>) {
    PlannerUiServices.commentsService.duplicateComments(mapOfOldToNewUuids)
  }

  fun addProcessState(coroutineScope: CoroutineScope, processStateFor: Uuid, newProcessState: LizergyProcessStateEntry) {
    dispatch(ProcessStateUpdatedAction(processStateFor, newProcessState))

    coroutineScope.launch {
      sendProcessState(processStateFor, newProcessState)
    }
  }

  fun addProcessStates(coroutineScope: CoroutineScope, processStateForComponents: List<SendProcessStatesTuple>) {
    dispatch(ProcessStatesAddedAction(processStateForComponents))

    // TODO: add new route for multiple states
    coroutineScope.launch {
      sendProcessStates(processStateForComponents)
    }
  }

  fun archiveProcessState(coroutineScope: CoroutineScope, processStateFor: Uuid, processStateToArchive: LizergyProcessStateEntry) {
    val updatedProcessStateEntry = when (processStateToArchive) {
      is AdvanceInvoiceProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is AssemblyBasementPreparationProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is AssemblyBasementProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is AssemblyPortfolioProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is AssemblyRoofPreparationProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is AssemblyRoofProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is BlueprintAcquisitionProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is BlueprintProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is ConfigurationProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is DocumentationProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is FinalAccountProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is FinishingUpProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is GridAssessmentProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is OrderSpecialMaterialProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is PresentationProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is ProjectProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is QuoteConfirmationProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is QuoteOfferProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is StartupOperationsProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
      is SwitchMeterBoxProcessStateEntry -> processStateToArchive.copy(lifeCycleState = LifeCycleState.EndOfLife)
    }

    dispatch(ProcessStateUpdatedAction(processStateFor, updatedProcessStateEntry))

    coroutineScope.launch {
      sendProcessState(processStateFor, updatedProcessStateEntry)
    }
  }

  suspend fun sendProcessState(processStateFor: Uuid, newProcessState: LizergyProcessStateEntry) {
    PlannerUiServices.processStatesService.sendProcessState(processStateFor, newProcessState)
  }

  suspend fun sendProcessStates(processStateForComponents: List<SendProcessStatesTuple>) {
    PlannerUiServices.processStatesService.sendMultipleProcessStates(processStateForComponents)
  }

  suspend fun duplicateProcessStates(mapOfOldToNewUuids: Map<Uuid, Uuid>) {
    PlannerUiServices.processStatesService.duplicateProcessStates(mapOfOldToNewUuids)
  }

  private fun processStatesTuplesForProjects(resolvedProjects: List<ResolvedProject>, loggedInUser: UserInformation): List<SendProcessStatesTuple> {
    return resolvedProjects.flatMap { resolvedProject -> processStatesTuplesForProject(resolvedProject, loggedInUser, loggedInUser) }
  }

  private fun processStatesTuplesForProject(resolvedProject: ResolvedProject, maintainer: UserInformation, loggedInUser: UserInformation): List<SendProcessStatesTuple> {
    return buildList {
      add(
        SendProcessStatesTuple(
          processStateFor = resolvedProject.uuid,
          processStateEntry = ProjectProcessStates.BeingEdited.toProcessStateEntry(user = maintainer, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser),
        )
      )
      add(
        SendProcessStatesTuple(
          processStateFor = resolvedProject.blueprintId,
          processStateEntry = BlueprintAcquisitionProcessStates.Empty.toProcessStateEntry(user = maintainer, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser),
        )
      )
      addAll(
        resolvedProject.quoteConfigurations.elements.map { configuration ->
          SendProcessStatesTuple(
            processStateFor = configuration.uuid,
            processStateEntry = ConfigurationProcessStates.New.toProcessStateEntry(user = maintainer, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser),
          )
        }
      )
    }
  }

  fun saveEarningsDistribution(coroutineScope: CoroutineScope, projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, earningsDistribution: ResolvedEarningsDistribution) {
    coroutineScope.launch {
      sendEarningsDistribution(projectId, configurationId, earningsDistribution)
    }
  }

  private suspend fun sendEarningsDistribution(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, earningsDistribution: ResolvedEarningsDistribution) {
    PlannerUiServices.projectQueryService.sendEarningsDistribution(projectId, configurationId, earningsDistribution)
  }

  fun saveManualQuoteElements(coroutineScope: CoroutineScope, projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, manualQuoteElements: ManualQuoteElements) {
    coroutineScope.launch {
      sendManualQuoteElements(projectId, configurationId, manualQuoteElements)
    }
  }

  private suspend fun sendManualQuoteElements(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, manualQuoteElements: ManualQuoteElements) {
    PlannerUiServices.projectQueryService.sendManualQuoteElements(projectId, configurationId, manualQuoteElements)
  }


  companion object {
    val logger: Logger = LoggerFactory.getLogger("services.PlannerWorkflow")
  }
}

