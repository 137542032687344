package components.project.customer.company

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Form for a customer's company
 */
fun RBuilder.companyForm(
  companyName: StateInstance<String>,
  editableStatus: EditableStatus,
): Unit = child(companyForm) {
  attrs {
    this.companyName = companyName
    this.editableStatus = editableStatus
  }
}

val companyForm: FC<CompanyFormProps> = fc("companyForm") { props ->
  val companyName = props::companyName.getNotNull()
  val editableStatus = props::editableStatus.safeGet()

  div("form-floating") {
    floatingInputField(
      valueAndSetter = companyName,
      fieldName = "organization",
      title = "Firmenname",
      editableStatus = editableStatus,
    ) {
    }
  }
}

external interface CompanyFormProps : Props {
  var companyName: StateInstance<String>
  var editableStatus: EditableStatus
}
