package components.project.configuration.location

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.location.LocationInformation
import react.*
import react.dom.*

/**
 *
 */
fun RBuilder.locationPreview(locationInformation: LocationInformation): Unit = child(LocationPreview) {
  attrs {
    this.locationInformation = locationInformation
  }
}

val LocationPreview: FC<LocationPreviewProps> = fc("LocationPreview") { props ->
  val locationInformation = props::locationInformation.safeGet()

  p {
    +locationInformation.address.singleLine()
  }

  locationInformation.coordinates?.let { mapCoordinatesInformation ->
    p {
      +mapCoordinatesInformation.format()
    }
  }

  p {
    "Production: ${locationInformation.specificAnnualProduction.format()}"
  }
}

external interface LocationPreviewProps : Props {
  var locationInformation: LocationInformation
}
