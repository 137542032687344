package it.neckar.problem

import kotlinx.serialization.Serializable

/**
 * The problem
 */
@Serializable
sealed interface Problem {
  /**
   * The message of the problem
   */
  val fallbackMessage: String?

  /**
   * URI to the concrete instance of the specific problem
   */
  val instanceUri: String?

  /**
   * The optional stack trace elements
   */
  val stackTrace: String?
}
