package components.company.pricelist

import it.neckar.react.common.router.*
import react.*
import react.dom.*
import react.router.*
import router.useDocumentTitle
import store.hooks.useRequireCompanyForLoggedInUser


val PriceListFromUrl: FC<Props> = fc("PriceListFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Preisliste")


  div {

    h2 {
      +"Preisliste"
    }

    div("mt-5") {
      tabButtonsPane {

        tabButton(PriceListTabs.modules, "Module")
        tabButton(PriceListTabs.inverters, "Wechselrichter")
        tabButton(PriceListTabs.batteries, "Batteriespeicher-Systeme")
        tabButton(PriceListTabs.roofTypes, "Dachtypen")
        tabButton(PriceListTabs.roofIsolation, "Aufdachdämmung")
        tabButton(PriceListTabs.metalRoofTiles, "Metalldachziegel")
        tabButton(PriceListTabs.facilityAndAssembly, "Anlage & Montage")
        tabButton(PriceListTabs.independenceManagers, "Ersatzstromsysteme")
        tabButton(PriceListTabs.wallBoxes, "Wallbox")
        tabButton(PriceListTabs.monitoring, "Monitoring")
        tabButton(PriceListTabs.miscellaneous, "Sonstiges")

      }
    }

    div("mt-5") {
      Outlet {}
    }

  }
}

object PriceListTabs {
  val modules: NavigateUrl = NavigateUrl("modules")
  val inverters: NavigateUrl = NavigateUrl("inverters")
  val batteries: NavigateUrl = NavigateUrl("batteries")
  val roofTypes: NavigateUrl = NavigateUrl("roofTypes")
  val roofIsolation: NavigateUrl = NavigateUrl("roofIsolation")
  val metalRoofTiles: NavigateUrl = NavigateUrl("metalRoofTiles")
  val facilityAndAssembly: NavigateUrl = NavigateUrl("facilityAndAssembly")
  val independenceManagers: NavigateUrl = NavigateUrl("independenceManagers")
  val wallBoxes: NavigateUrl = NavigateUrl("wallBoxes")
  val monitoring: NavigateUrl = NavigateUrl("monitoring")
  val miscellaneous: NavigateUrl = NavigateUrl("miscellaneous")
}
