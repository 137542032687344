package it.neckar.projects.pvplanner

import it.neckar.projects.common.Port

/**
 * The Port object contains all ports relevant to the PV planner.
 *
 * This file is also copied to the `planner-model` project using the `project-configuration plugin`.
 *
 *
 * ATTENTION: This file is linked from buildSrc.
 *
 *
 * Old documentation:
 *
 * The Port object contains all ports relevant to the PV planner. If the ports must be changed, two additional files must be altered:
 *  - internal/closed/lizergy/deployment/config/nginx/sites-available/pv.neckar.it.conf
 *    -> under services change the port number
 *  - In the build.gradle.kts files of the services
 *    -> example for auth-service: internal/closed/lizergy/services/auth-service/build.gradle.kts
 * */
object PvPlannerPorts {
  /**
   * The ports for the planner UI.
   * Only as documentation
   */
  object PlannerUi {
    /**
     * Running with dev-server by calling `gradle jsRun`
     * See: `internal/closed/lizergy/planner-ui/webpack.config.d/dev-server-config.js`
     */
    val devServer: Port = Port(9080)

    /**
     * When executing from the docker container.
     *
     * Exposed `docker-compose.yml`
     */
    val docker: Port = Port(8080)
  }

  val euScienceHubService: Port = Port(8087)
  val pdfReportService: Port = Port(8088)
  val geoInformationService: Port = Port(8089)
  val storageService: Port = Port(8090)
  val authService: Port = Port(8091)
  val pdfPreviewService: Port = Port(8093)
  val projectQueryService: Port = Port(8094)
  val processStateService: Port = Port(8098)
  val commentsService: Port = Port(8099)
}
