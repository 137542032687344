package it.neckar.comments

import com.benasher44.uuid.Uuid

data class AllCommentsForComponents(val allCommentsForComponents: List<CommentsForComponent>) {
  private val map = allCommentsForComponents.associateBy {
    it.commentsFor
  }

 private operator fun get(uuid: Uuid): CommentsForComponent {
   return map[uuid] ?: CommentsForComponent(uuid, emptyList())
 }

  fun getOrNull(uuid: Uuid): CommentsForComponent? {
    return map[uuid]
  }

  fun withNewComment(commentFor: Uuid, newComment: Comment): AllCommentsForComponents {
    return withUpdatedCommentsForComponent(get(commentFor).withUpdatedComment(newComment))
  }

  fun withNewCommentsForComponent(newCommentsForComponent: CommentsForComponent): AllCommentsForComponents {
    return copy(allCommentsForComponents = allCommentsForComponents + newCommentsForComponent)
  }

  fun withUpdatedCommentsForComponent(updatedCommentsForComponent: CommentsForComponent): AllCommentsForComponents {
    val updatedUserLoginName = allCommentsForComponents.indexOfFirst {
      it.commentsFor == updatedCommentsForComponent.commentsFor
    }

    if (updatedUserLoginName < 0) return withNewCommentsForComponent(updatedCommentsForComponent)

    val newCommentsForComponent = this.allCommentsForComponents.toMutableList()
    newCommentsForComponent[updatedUserLoginName] = updatedCommentsForComponent

    return copy(allCommentsForComponents = newCommentsForComponent)
  }

  companion object {
    val empty: AllCommentsForComponents = AllCommentsForComponents(emptyList())
  }
}
