@file:Suppress("DuplicatedCode")

package components.project.configuration.facility.battery

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType
import it.neckar.lizergy.model.configuration.quote.builder.BasicBatteryInverter
import it.neckar.lizergy.model.configuration.quote.builder.HybridInverter
import it.neckar.open.kotlin.lang.ifNull
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*

/**
 * Form for batteryStorage related stuff
 */
fun RBuilder.batteryStorageForm(
  batteryConfigurationSelection: StateInstance<BatteryConfiguration?>,
  availableBatteryConfigurations: List<BatteryConfiguration>,
  recommendedBatteryConfiguration: BatteryConfiguration?,
  selectedIndependenceManager: IndependenceManagerType?,
  editableStatus: EditableStatus,
): Unit = child(BatteryStorageForm) {
  attrs {
    this.batteryConfigurationSelection = batteryConfigurationSelection
    this.availableBatteryConfigurations = availableBatteryConfigurations
    this.recommendedBatteryConfiguration = recommendedBatteryConfiguration
    this.selectedIndependenceManager = selectedIndependenceManager
    this.editableStatus = editableStatus
  }
}

val BatteryStorageForm: FC<BatteryStorageFormProps> = fc("BatteryStorageForm") { props ->
  val batteryConfigurationSelection = props::batteryConfigurationSelection.getNotNull()

  val availableBatteryConfigurations = props::availableBatteryConfigurations.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val recommendedBatteryConfiguration = props::recommendedBatteryConfiguration.safeGet()
  val selectedIndependenceManager = props::selectedIndependenceManager.safeGet()

  val batteryConfigurationAtEndOfLife = batteryConfigurationSelection.value?.lifeCycleState?.isEndOfLife() == true
  val initialInverter = batteryConfigurationSelection.value?.inverterType

  val batterySelection = useState(batteryConfigurationSelection.value?.battery)
  val extensionSelection = useState(batteryConfigurationSelection.value?.extension)
  val batteryInverterSelection = useState(initialInverter as? BasicBatteryInverter)
  val hybridInverterSelection = useState(initialInverter as? HybridInverter)

  val availableBatteries = availableBatteryConfigurations.map { it.battery }.distinct()
  val availableExtensions = useMemo(availableBatteryConfigurations, batterySelection.value) {
    availableBatteryConfigurations.mapNotNull { it.extension }.distinct().filter { batteryStorageExtensionType ->
      availableBatteryConfigurations.any { it.battery == batterySelection.value && it.extension == batteryStorageExtensionType }
    }
  }

  val availableInverterTypes = useMemo(availableBatteryConfigurations, batterySelection.value) {
    availableBatteryConfigurations.map { it.inverterType }.distinct().filter { inverterType ->
      availableBatteryConfigurations.any { it.battery == batterySelection.value && it.inverterType == inverterType }
    }
  }
  val availableBatteryInverters = availableInverterTypes.filterIsInstance<BasicBatteryInverter>()
  val availableHybridInverters = availableInverterTypes.filterIsInstance<HybridInverter>()

  val selectedBatteryInverter = batteryInverterSelection.value
  val selectedHybridInverter = hybridInverterSelection.value

  val inverterSelection = selectedBatteryInverter ?: selectedHybridInverter
  val invalidManagerCombination = selectedIndependenceManager?.invalidBatteryInverter(inverterSelection) == true


  useMemo(batterySelection.value, extensionSelection.value, selectedBatteryInverter, selectedHybridInverter) {
    if (availableExtensions.contains(extensionSelection.value).not()) extensionSelection.setter(null)
    if (availableBatteryInverters.contains(selectedBatteryInverter).not()) batteryInverterSelection.setter(null)
    if (availableHybridInverters.contains(selectedHybridInverter).not()) hybridInverterSelection.setter(null)

    if (selectedBatteryInverter == null && selectedHybridInverter == null) {
      if (availableHybridInverters.isNotEmpty()) hybridInverterSelection.setter(availableHybridInverters.first())
      else if (availableBatteryInverters.isNotEmpty()) batteryInverterSelection.setter(availableBatteryInverters.first())
    } else if (selectedBatteryInverter != null && selectedHybridInverter != null) {
      batteryInverterSelection.setter(null)
    }
  }

  useMemo(batteryConfigurationSelection.value, batterySelection.value, extensionSelection.value, inverterSelection) {
    if (batterySelection.value != batteryConfigurationSelection.value?.battery || extensionSelection.value != batteryConfigurationSelection.value?.extension || inverterSelection != initialInverter) {
      batteryConfigurationSelection.setter(availableBatteryConfigurations.firstOrNull {
        it.battery == batterySelection.value && it.extension == extensionSelection.value && it.inverterType == inverterSelection
      })
    }
  }


  div("row gy-0") {
    p {
      span("badge") {
        when (batteryConfigurationSelection.value) {
          recommendedBatteryConfiguration -> addClass("bg-primary")
          null -> addClass("bg-danger")
          else -> addClass("bg-info")
        }
        +(batteryConfigurationSelection.value?.format() ?: "Keine Batterie")
      }
    }

    div("col-12") {
      div {
        floatingSelectNullable(
          valueAndSetter = hybridInverterSelection,
          idProvider = { it?.id?.format() ?: "noHybridInverter" },
          formatter = { it?.format() ?: "-" },
          availableOptionsWithoutNull = availableHybridInverters,
          fieldName = "hybridInverter",
          title = "Hybridwechselrichter",
          additionalOnChange = { batteryInverterSelection.setter(null) },
          editableStatus = editableStatus.and(availableHybridInverters.isNotEmpty()),
        ) {
          attrs {
            mergedBelow()
          }
        }

        invalidIf {
          batteryConfigurationAtEndOfLife || invalidManagerCombination
        }
      }
    }
    div("col-12") {
      div {
        floatingSelectNullable(
          valueAndSetter = batteryInverterSelection,
          idProvider = { it?.id?.format() ?: "noBatteryInverter" },
          formatter = { it?.format() ?: "-" },
          availableOptionsWithoutNull = availableBatteryInverters,
          fieldName = "batteryInverter",
          title = "Batterie-Wechselrichter",
          additionalOnChange = { hybridInverterSelection.setter(null) },
          editableStatus = editableStatus.and(availableBatteryInverters.isNotEmpty()),
        ) {
          attrs {
            mergedAbove()
          }
        }

        invalidIf {
          batteryConfigurationAtEndOfLife || invalidManagerCombination
        }
      }
    }

    div("col-12 mt-2") {
      div {
        floatingSelectNullable(
          valueAndSetter = batterySelection,
          idProvider = { it?.id?.format() ?: "noBattery" },
          formatter = { it?.description ?: "-" },
          availableOptionsWithoutNull = availableBatteries,
          fieldName = "battery",
          title = "Batterie",
          editableStatus = editableStatus,
        ) {
          attrs {
            mergedBelow()
          }
        }

        invalidIf { batteryConfigurationAtEndOfLife }
      }
    }
    div("col-12") {
      div {
        floatingSelectNullable(
          valueAndSetter = extensionSelection,
          idProvider = { it?.id?.format() ?: "noExtentsion" },
          formatter = { it?.description ?: "-" },
          availableOptionsWithoutNull = availableExtensions,
          fieldName = "extension",
          title = "Batterie-Erweiterung",
          editableStatus = editableStatus.and(availableExtensions.isNotEmpty()),
        ) {
          attrs {
            mergedAbove()
          }
        }

        invalidIf { batteryConfigurationAtEndOfLife }
      }
    }

    div("col-12 form-text") {
      if (batteryConfigurationAtEndOfLife) {
        p("text-danger") {
          +"Die ausgewählte Batterie ist nicht mehr verfügbar!"
        }
      }
      if (invalidManagerCombination) {
        p("text-danger") {
          +"Achtung es wurde der Lizergy Independence Manager ausgewählt. Bitte nur LVL und LVS Batterien auswählen!"
        }
      }

      if (recommendedBatteryConfiguration != null && batteryConfigurationSelection.value != recommendedBatteryConfiguration) {
        p {
          +"Empfohlene Batterie: ${recommendedBatteryConfiguration.battery.description.ifNull("-")}"
        }
        p {
          if (recommendedBatteryConfiguration.extension != null) {
            +"zusätzlich: ${recommendedBatteryConfiguration.extension?.description}"
          }
        }

        button(classes = "btn btn-outline-primary") {
          attrs {
            disabled = editableStatus == ReadOnly
            onClickFunction = {
              batterySelection.setter(recommendedBatteryConfiguration.battery)
              when (val recommendedInverterType = recommendedBatteryConfiguration.inverterType) {
                is BasicBatteryInverter -> batteryInverterSelection.setter(recommendedInverterType)
                is HybridInverter -> hybridInverterSelection.setter(recommendedInverterType)
              }
              batteryConfigurationSelection.setter(recommendedBatteryConfiguration)
            }
          }

          +"Übernehmen"
        }
      }
    }

    div("col-12 form-text mt-3") {
      +"Gesamtspeicherkapazität: ${batteryConfigurationSelection.value?.totalStorage?.format() ?: "-"}"
    }
  }
}

external interface BatteryStorageFormProps : Props {
  var batteryConfigurationSelection: StateInstance<BatteryConfiguration?>
  var availableBatteryConfigurations: List<BatteryConfiguration>
  var recommendedBatteryConfiguration: BatteryConfiguration?
  var selectedIndependenceManager: IndependenceManagerType?
  var editableStatus: EditableStatus
}
