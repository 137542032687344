@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout.roof

import com.benasher44.uuid.Uuid
import it.neckar.geometry.Size
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile.RoofTileId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType.RoofTypeId
import it.neckar.lizergy.model.configuration.quote.builder.MetalTilesCalculator
import it.neckar.lizergy.model.validation.NeedsValidation
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.lizergy.model.validation.TitleMessage
import it.neckar.lizergy.model.validation.ValidationProblem
import it.neckar.lizergy.model.validation.ValidationProblems
import it.neckar.open.unit.other.deg
import it.neckar.open.unit.si.cm
import it.neckar.open.unit.si.mm
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Information about a roof.
 *
 * Does *not* contain the modules for the roof.
 */
@Serializable
data class ResolvedRoof(
  /**
   * ID that identifies a roof information (*not* the instance of the data object)
   */
  override val id: RoofId,

  /**
   * The size of the roof
   */
  override val size: @mm Size?,

  /**
   * The type of the roof
   */
  val roofType: RoofType,

  /**
   * The inclination of the roof in degrees
   */
  override val inclination: @deg RoofInclination?,

  /**
   * The orientation of the roof in degrees relative to south
   */
  override val orientation: RoofOrientation?,

  /**
   * The type of the roof tiles
   */
  val roofTile: RoofTile?,
  override val roofTileOverride: String?,
  val metalRoofTileConstructionType: ResolvedMetalRoofTileConstructionType?,

  override val isolationThickness: @cm Int?,

  override val sparrenabstand: @cm Int?,
  override val verstaerktesSchienensystemToggle: Boolean,

  override val scaffoldingArea: Int,
  override val schwarzeKlemmen: Boolean,

  /**
   * Marks if this [ResolvedRoof] has been deleted
   * Instead of deleting, mark this value as Deleted
   * When listing [ResolvedRoof]s, consider this value
   */
  override val lifeCycleState: LifeCycleState,

  ) : Roof, NeedsValidation {

  override val roofTypeId: RoofTypeId
    get() = roofType.id

  override val roofTileId: RoofTileId?
    get() = roofTile?.id

  val roofTileTypeDescription: String?
    get() = roofTileOverride ?: roofTile?.description

  override val validationProblems: ValidationProblems
    get() = ValidationProblems(
      titleMessages = listOf(
        TitleMessage(null) { "Dachfläche $roofName sieht gut aus!" },
        TitleMessage(ProblemType.Error) { "Die folgenden $it Felder müssen noch in in der Dachfläche $roofName ausgefüllt werden:" },
        TitleMessage(ProblemType.Warning) { "Die folgenden $it Felder sollten in der Dachfläche $roofName überprüft werden:" },
      ),
      ownProblems = listOf(
        ValidationProblem("Neigung für Dach [${roofName}] fehlt") { if (inclination == null) ProblemType.Warning else null },
        ValidationProblem("Ausrichtung für Dach [${roofName}] fehlt") { if (orientation == null) ProblemType.Warning else null },
        ValidationProblem("Ziegelart für Dach [${roofName}] is \"Unbekannt\" und es fehlt eine Manuelle Ziegelart") { if (roofType.metalRoofTileCompatability.compatible && roofTileId == null && roofTileOverride == null) ProblemType.Warning else null },
        ValidationProblem("Achtung: Verfügbarkeit der Farbe der Metalldachziegel für Dach [${roofName}] muss geprüft werden") { if (metalRoofTileConstructionType?.color != null) ProblemType.Warning else null },
        ValidationProblem("Sparrenabstand für Dach [${roofName}] fehlt") { if (isSparrenabstandInvalid()) ProblemType.Warning else null },
        ValidationProblem("Dachdämmung für Dach [${roofName}] fehlt") { if (isIsolationThicknessInvalid()) ProblemType.Warning else null },
      )
    )


  fun getMetalTilesCalculator(modulesCount: Int): MetalTilesCalculator {
    return MetalTilesCalculator().apply {
      if (roofTile != null && metalRoofTileConstructionType != null) {
        addForRoof(
          roof = this@ResolvedRoof,
          metalRoofTile = roofTile.metalRoofTile,
          modulesCount = modulesCount,
          metalRoofTileConstructionType = metalRoofTileConstructionType,
        )
      }
    }
  }

  fun isSparrenabstandInvalid(): Boolean {
    return sparrenabstand == null && roofType.requiresSparrenabstand
  }

  fun isIsolationThicknessInvalid(): Boolean {
    return isolationThickness == null && roofType.withIsolation
  }

  fun duplicate(markAsCopy: Boolean, mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedRoof {
    val newId = RoofId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(
      id = newId,
      orientation = if (markAsCopy) orientation?.opposite() else orientation,
    )
  }


  companion object {
    /**
     * Creates a new roof information with default values
     */
    fun createDefault(
      id: RoofId = RoofId.random(),
      roofType: RoofType,
      roofTile: RoofTile? = null,
      defaultRoofSize: Size? = null,
      defaultInclination: RoofInclination? = null,
      defaultOrientation: RoofOrientation? = null,
    ): ResolvedRoof {
      return ResolvedRoof(
        id = id,
        roofType = roofType,
        roofTile = roofTile,
        size = defaultRoofSize,
        inclination = defaultInclination,
        orientation = defaultOrientation,
        roofTileOverride = null,
        metalRoofTileConstructionType = null,
        isolationThickness = null,
        sparrenabstand = null,
        verstaerktesSchienensystemToggle = true,
        scaffoldingArea = 0,
        schwarzeKlemmen = false,
        lifeCycleState = LifeCycleState.Active,
      )
    }

  }

}
