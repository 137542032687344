@file:UseSerializers(UuidSerializer::class)

package serialized

import it.neckar.geometry.Size
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.moduleLayout.PvModuleTypeMaterial
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileConstructionType
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofInclination
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofOrientation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile.RoofTileId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType.RoofTypeId
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.open.unit.other.deg
import it.neckar.open.unit.si.cm
import it.neckar.open.unit.si.mm
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Information about a roof.
 *
 * Does *not* contain the modules for the roof.
 */
@Serializable
data class SerializedRoof(
  /**
   * ID that identifies a roof information (*not* the instance of the data object)
   */
  override val id: RoofId,

  /**
   * The size of the roof
   */
  override val size: @mm Size?,

  /**
   * The type of the roof
   */
  override val roofTypeId: RoofTypeId,

  /**
   * The inclination of the roof in degrees
   */
  override val inclination: @deg RoofInclination?,

  /**
   * The orientation of the roof in degrees relative to south
   */
  override val orientation: RoofOrientation?,

  /**
   * The type of the roof tiles
   */
  override val roofTileId: RoofTileId?,
  override val roofTileOverride: String? = null,
  val metalRoofTileConstructionType: MetalRoofTileConstructionType? = null,

  override val isolationThickness: @cm Int? = null,

  override val sparrenabstand: @cm Int? = null,
  override val verstaerktesSchienensystemToggle: Boolean = true,

  override val scaffoldingArea: Int = 0,
  override val schwarzeKlemmen: Boolean = false,

  /**
   * Marks if this [SerializedRoof] has been deleted
   * Instead of deleting, mark this value as Deleted
   * When listing [SerializedRoof]s, consider this value
   */
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : Roof {

  fun getModuleLayoutFrom(blackModules: Boolean, availableProducts: AvailableProducts): SerializedModuleLayout {
    return SerializedModuleLayout.createDefault(
      moduleType = availableProducts.availableModules().first {
        when (it.material) {
          PvModuleTypeMaterial.Normal -> true
          PvModuleTypeMaterial.GlassGlass -> blackModules
        }
      },
      roof = this,
    )
  }

  fun resolve(productResolver: ProductResolver): ResolvedRoof {
    return ResolvedRoof(
      id = id,
      size = size,
      roofType = productResolver[roofTypeId],
      inclination = inclination,
      orientation = orientation,
      roofTile = roofTileId?.let { productResolver[it] },
      roofTileOverride = roofTileOverride,
      metalRoofTileConstructionType = metalRoofTileConstructionType?.resolve(productResolver),
      isolationThickness = isolationThickness,
      sparrenabstand = sparrenabstand,
      verstaerktesSchienensystemToggle = verstaerktesSchienensystemToggle,
      scaffoldingArea = scaffoldingArea,
      schwarzeKlemmen = schwarzeKlemmen,
      lifeCycleState = lifeCycleState,
    )
  }

  fun duplicate(): SerializedRoof {
    return copy(id = RoofId.random())
  }

  companion object {
    /**
     * Creates a new roof information with default values
     */
    fun createDefault(
      id: RoofId = RoofId.random(),
      roofType: RoofType,
      roofTileId: RoofTileId? = null,
      defaultRoofSize: Size? = null,
      defaultInclination: RoofInclination? = null,
      defaultOrientation: RoofOrientation? = null,
    ): SerializedRoof {
      return SerializedRoof(
        id = id,
        roofTypeId = roofType.id,
        roofTileId = roofTileId,
        size = defaultRoofSize,
        inclination = defaultInclination,
        orientation = defaultOrientation,
      )
    }

  }

}


fun ResolvedRoof.unResolve(): SerializedRoof {
  return SerializedRoof(
    id = id,
    size = size,
    roofTypeId = roofType.id,
    inclination = inclination,
    orientation = orientation,
    roofTileId = roofTile?.id,
    roofTileOverride = roofTileOverride,
    metalRoofTileConstructionType = metalRoofTileConstructionType?.unResolve(),
    isolationThickness = isolationThickness,
    sparrenabstand = sparrenabstand,
    verstaerktesSchienensystemToggle = verstaerktesSchienensystemToggle,
    scaffoldingArea = scaffoldingArea,
    schwarzeKlemmen = schwarzeKlemmen,
    lifeCycleState = lifeCycleState,
  )
}
