package components.project

import components.project.configuration.additional.einspeiseArtForm
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.open.unit.si.ms
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Form for an editor
 */
fun RBuilder.inquiryInformationForm(
  zaehlerNummer: StateInstance<String>,
  flurstueckNummer: StateInstance<String>,
  facilityOperator1Name: StateInstance<String?>,
  facilityOperator1Birthday: StateInstance<@ms Double?>,
  facilityOperator2Name: StateInstance<String?>,
  facilityOperator2Birthday: StateInstance<@ms Double?>,
  einspeiseart: StateInstance<Einspeiseart>,
  editableStatus: EditableStatus,
): Unit = child(InquiryInformationForm) {
  attrs {
    this.zaehlerNummer = zaehlerNummer
    this.flurstueckNummer = flurstueckNummer
    this.facilityOperator1Name = facilityOperator1Name
    this.facilityOperator1Birthday = facilityOperator1Birthday
    this.facilityOperator2Name = facilityOperator2Name
    this.facilityOperator2Birthday = facilityOperator2Birthday
    this.einspeiseart = einspeiseart
    this.editableStatus = editableStatus
  }
}

val InquiryInformationForm: FC<InquiryInformationFormProps> = fc("InquiryInformationForm") { props ->
  val zaehlerNummer = props::zaehlerNummer.getNotNull()
  val flurstueckNummer = props::flurstueckNummer.getNotNull()
  val facilityOperator1Name = props::facilityOperator1Name.getNotNull()
  val facilityOperator1Birthday = props::facilityOperator1Birthday.getNotNull()
  val facilityOperator2Name = props::facilityOperator2Name.getNotNull()
  val facilityOperator2Birthday = props::facilityOperator2Birthday.getNotNull()
  val einspeiseart = props::einspeiseart.getNotNull()

  val editableStatus = props::editableStatus.safeGet()


  div("row my-3") {
    div("col-md-6") {
      floatingInputField(
        valueAndSetter = zaehlerNummer,
        fieldName = "zaehlerNummer",
        title = "Zählernummer",
      )
    }

    div("col-md-6") {
      floatingInputField(
        valueAndSetter = flurstueckNummer,
        fieldName = "flurstueckNummer",
        title = "Flurstücknummer",
      )
    }
  }

  div("row") {
    attrs {
      mergedBelow()
    }

    div("col-md-6") {
      nullableFloatingInputField(
        valueAndSetter = facilityOperator1Name,
        fieldName = "facilityOperator1Name",
        title = "Anlagenbetreiber 1",
      ) {
        attrs {
          mergedBelow()
        }
      }
    }

    div("col-md-6 my-auto") {
      div("align-middle") {
        datePickerNullable(dateState = facilityOperator1Birthday)
      }
    }

  }

  div("row") {
    attrs {
      mergedAbove()
    }

    div("col-md-6") {
      nullableFloatingInputField(
        valueAndSetter = facilityOperator2Name,
        fieldName = "facilityOperator2Name",
        title = "Anlagenbetreiber 2",
      ) {
        attrs {
          mergedAbove()
        }
      }
    }

    div("col-md-6 my-auto") {
      div("align-middle") {
        datePickerNullable(dateState = facilityOperator2Birthday)
      }
    }

  }

  div("my-3") {
    einspeiseArtForm(einspeiseart, editableStatus)
  }

}

external interface InquiryInformationFormProps : Props {
  var zaehlerNummer: StateInstance<String>
  var flurstueckNummer: StateInstance<String>
  var facilityOperator1Name: StateInstance<String?>
  var facilityOperator1Birthday: StateInstance<@ms Double?>
  var facilityOperator2Name: StateInstance<String?>
  var facilityOperator2Birthday: StateInstance<@ms Double?>
  var einspeiseart: StateInstance<Einspeiseart>
  var editableStatus: EditableStatus
}
