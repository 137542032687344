package serialized

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio.StringSelection
import it.neckar.lizergy.model.assemblyPortfolio.ResolvedAssemblyPortfolio
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.Voltage
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlin.time.Duration

@Serializable
data class SerializedAssemblyPortfolio(
  @Serializable(with = UuidSerializer::class) override val uuid: Uuid,
  override val projectId: PhotovoltaicsProjectId,

  override val projektLeiter: UserLoginName? = null,
  override val pvMontageDurch: CompanyCode? = null,
  override val ansprechpartnerDach: UserLoginName? = null,
  override val elektroMontageDurch: CompanyCode? = null,
  override val ansprechpartnerElektro: UserLoginName? = null,

  override val locationFirstName: String? = null,
  override val locationLastName: String? = null,
  override val locationPhone: String? = null,
  override val locationCellphone: String? = null,
  override val locationEmail: String? = null,

  override val anlagenPasswort: String? = null,
  override val einspeiseart: Einspeiseart? = null,
  override val wartungsvertrag: Boolean? = null,
  override val geruestVorhanden: String? = null,

  override val pvLeistung: PowerRating? = null,
  override val leerlaufspannung: Voltage? = null,
  override val verteilerNetzbetreiberName: String? = null,

  override val kalkulierteStunden: Duration? = null,
  override val ueberspannungsschutz: String? = null,
  override val messkonzept: String? = null,

  override val bleibenderZaehler: String? = null,
  override val zusammenZuLegendeZaehlerNummern: List<String>? = null,

  override val slsEinbauen: Boolean? = null,
  override val unterverteilerEinbauen: Boolean? = null,
  override val erdspiessSetzen: Boolean? = null,
  override val waermepumpenAnbindung: Boolean? = null,
  override val internetAnschluss: Boolean? = null,

  override val assemblyPortfolioModuleLayouts: List<AssemblyPortfolio.Layout> = emptyList(),

  override val wechselrichter: StringSelection = StringSelection.empty(),

  override val anzahlOptimierer: Int? = null,
  override val optimierer: String? = null,

  override val speicher: String? = null,

  override val ladestationen: AssemblyPortfolio.CustomWallboxSelection<AssemblyPortfolio.Ladestation> = AssemblyPortfolio.CustomWallboxSelection.empty(),
  override val heizstab: AssemblyPortfolio.HeaterRod = AssemblyPortfolio.HeaterRod.getEmpty(),

  override val ersatzstrom: String? = null,
  override val sunnyHomeManagerOderMeter: String? = null,

  override val firstEingespeist: Boolean = false,
  override val oberleitungZuIsolieren: Boolean? = null,
  override val satAnlageZuVersetzen: Boolean? = null,
  override val antenneEntfernen: Boolean? = null,

  override val besonderheiten: String? = null,
  override val bestandsanlagen: String? = null,
  override val sonstigeArbeiten: String? = null,

  ) : AssemblyPortfolio {
  fun resolve(userResolver: UserResolver, companyResolver: CompanyResolver): ResolvedAssemblyPortfolio {
    return ResolvedAssemblyPortfolio(
      uuid = uuid,
      projectId = projectId,
      projektLeiterInformation = projektLeiter?.let { userResolver[it] },
      pvMontageDurchInformation = pvMontageDurch?.let { companyResolver[it] },
      ansprechpartnerDachInformation = ansprechpartnerDach?.let { userResolver[it] },
      elektroMontageDurchInformation = elektroMontageDurch?.let { companyResolver[it] },
      ansprechpartnerElektroInformation = ansprechpartnerElektro?.let { userResolver[it] },
      locationFirstName = locationFirstName,
      locationLastName = locationLastName,
      locationPhone = locationPhone,
      locationCellphone = locationCellphone,
      locationEmail = locationEmail,
      anlagenPasswort = anlagenPasswort,
      einspeiseart = einspeiseart,
      wartungsvertrag = wartungsvertrag,
      geruestVorhanden = geruestVorhanden,
      pvLeistung = pvLeistung,
      leerlaufspannung = leerlaufspannung,
      verteilerNetzbetreiberName = verteilerNetzbetreiberName,
      kalkulierteStunden = kalkulierteStunden,
      ueberspannungsschutz = ueberspannungsschutz,
      messkonzept = messkonzept,
      bleibenderZaehler = bleibenderZaehler,
      zusammenZuLegendeZaehlerNummern = zusammenZuLegendeZaehlerNummern,
      slsEinbauen = slsEinbauen,
      unterverteilerEinbauen = unterverteilerEinbauen,
      erdspiessSetzen = erdspiessSetzen,
      waermepumpenAnbindung = waermepumpenAnbindung,
      internetAnschluss = internetAnschluss,
      assemblyPortfolioModuleLayouts = assemblyPortfolioModuleLayouts,
      wechselrichter = wechselrichter,
      anzahlOptimierer = anzahlOptimierer,
      optimierer = optimierer,
      speicher = speicher,
      ladestationen = ladestationen,
      heizstab = heizstab,
      ersatzstrom = ersatzstrom,
      sunnyHomeManagerOderMeter = sunnyHomeManagerOderMeter,
      firstEingespeist = firstEingespeist,
      oberleitungZuIsolieren = oberleitungZuIsolieren,
      satAnlageZuVersetzen = satAnlageZuVersetzen,
      antenneEntfernen = antenneEntfernen,
      besonderheiten = besonderheiten,
      bestandsanlagen = bestandsanlagen,
      sonstigeArbeiten = sonstigeArbeiten,
    )
  }
}

fun ResolvedAssemblyPortfolio.unResolve(): SerializedAssemblyPortfolio {
  return SerializedAssemblyPortfolio(
    uuid = uuid,
    projectId = projectId,
    projektLeiter = projektLeiter,
    pvMontageDurch = pvMontageDurch,
    ansprechpartnerDach = ansprechpartnerDach,
    elektroMontageDurch = elektroMontageDurch,
    ansprechpartnerElektro = ansprechpartnerElektro,
    locationFirstName = locationFirstName,
    locationLastName = locationLastName,
    locationPhone = locationPhone,
    locationCellphone = locationCellphone,
    locationEmail = locationEmail,
    anlagenPasswort = anlagenPasswort,
    einspeiseart = einspeiseart,
    wartungsvertrag = wartungsvertrag,
    geruestVorhanden = geruestVorhanden,
    pvLeistung = pvLeistung,
    leerlaufspannung = leerlaufspannung,
    verteilerNetzbetreiberName = verteilerNetzbetreiberName,
    kalkulierteStunden = kalkulierteStunden,
    ueberspannungsschutz = ueberspannungsschutz,
    messkonzept = messkonzept,
    bleibenderZaehler = bleibenderZaehler,
    zusammenZuLegendeZaehlerNummern = zusammenZuLegendeZaehlerNummern,
    slsEinbauen = slsEinbauen,
    unterverteilerEinbauen = unterverteilerEinbauen,
    erdspiessSetzen = erdspiessSetzen,
    waermepumpenAnbindung = waermepumpenAnbindung,
    internetAnschluss = internetAnschluss,
    assemblyPortfolioModuleLayouts = assemblyPortfolioModuleLayouts,
    wechselrichter = wechselrichter,
    anzahlOptimierer = anzahlOptimierer,
    optimierer = optimierer,
    speicher = speicher,
    ladestationen = ladestationen,
    heizstab = heizstab,
    ersatzstrom = ersatzstrom,
    sunnyHomeManagerOderMeter = sunnyHomeManagerOderMeter,
    firstEingespeist = firstEingespeist,
    oberleitungZuIsolieren = oberleitungZuIsolieren,
    satAnlageZuVersetzen = satAnlageZuVersetzen,
    antenneEntfernen = antenneEntfernen,
    besonderheiten = besonderheiten,
    bestandsanlagen = bestandsanlagen,
    sonstigeArbeiten = sonstigeArbeiten,
  )
}
