package components.company.user

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.CompanyProfile
import it.neckar.lizergy.model.company.user.AccessRights
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteProcessStates
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteUsers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditConfigurations
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditCustomers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditEarnings
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditModuleLayouts
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherCompanies
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherUsers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOwnCompany
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.VerifyProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewAccounting
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarnings
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsBureaucracyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsDistributionBlueprintRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsDistributionQuoteRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsElectricityWorkRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsReferrerRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsRoofAssemblyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsScaffoldingAssemblyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsTechnicalPlanningElectricRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsTechnicalPlanningRoofRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewPriceList
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewProfits
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewProjectCount
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewQuoteDump
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.react.common.*
import it.neckar.user.UserLoginName
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import router.useCompanyFromUrl
import router.useDocumentTitle
import services.UiActions
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser

/**
 * Change password form
 */
val AddNewUserForCompanyFromUrl: FC<Props> = fc("AddNewUserForCompanyFromUrl") {
  val companyCode = useCompanyFromUrl().companyProfile
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Neuen Nutzer anlegen")

  AddNewUserForm {
    attrs {
      this.companyProfile = companyCode
    }
  }
}

val AddNewUserForm: FC<AddNewUserFormProps> = fc("AddNewUserForm") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val companyProfile = props::companyProfile.safeGet()

  val loggedInUserAccessRights = loggedInUser.accessRights

  val userName = useState("")
  val password1 = useState("")
  val password2 = useState("")
  val editorName = useState("")

  val forCompany = useState(companyProfile)

  val editCustomers = if (loggedInUserAccessRights.canAccess(EditCustomers)) useState(true) else null
  val editProjects = if (loggedInUserAccessRights.canAccess(EditProjects)) useState(true) else null
  val editConfigurations = if (loggedInUserAccessRights.canAccess(EditConfigurations)) useState(true) else null
  val editModuleLayouts = if (loggedInUserAccessRights.canAccess(EditModuleLayouts)) useState(true) else null
  val editOtherUsers = if (loggedInUserAccessRights.canAccess(EditOtherUsers)) useState(true) else null
  val editOwnCompany = if (loggedInUserAccessRights.canAccess(EditOwnCompany)) useState(true) else null
  val editOtherCompanies = if (loggedInUserAccessRights.canAccess(EditOtherCompanies)) useState(true) else null
  val deleteProjects = if (loggedInUserAccessRights.canAccess(DeleteProjects)) useState(true) else null
  val verifyProjects = if (loggedInUserAccessRights.canAccess(VerifyProjects)) useState(true) else null
  val deleteUsers = if (loggedInUserAccessRights.canAccess(DeleteUsers)) useState(true) else null
  val viewPriceList = if (loggedInUserAccessRights.canAccess(ViewPriceList)) useState(true) else null
  val viewProjectCount = if (loggedInUserAccessRights.canAccess(ViewProjectCount)) useState(true) else null
  val deleteProcessStates = if (loggedInUserAccessRights.canAccess(DeleteProcessStates)) useState(true) else null
  val viewAccounting = if (loggedInUserAccessRights.canAccess(ViewAccounting)) useState(true) else null
  val viewQuoteDump = if (loggedInUserAccessRights.canAccess(ViewQuoteDump)) useState(true) else null
  val viewProfits = if (loggedInUserAccessRights.canAccess(ViewProfits)) useState(true) else null
  val viewEarnings = if (loggedInUserAccessRights.canAccess(ViewEarnings)) useState(true) else null
  val editEarnings = if (loggedInUserAccessRights.canAccess(EditEarnings)) useState(true) else null

  val viewEarningsReferrerRow = if (loggedInUserAccessRights.canAccess(ViewEarningsReferrerRow)) useState(true) else null
  val viewEarningsDistributionBlueprintRow = if (loggedInUserAccessRights.canAccess(ViewEarningsDistributionBlueprintRow)) useState(true) else null
  val viewEarningsDistributionQuoteRow = if (loggedInUserAccessRights.canAccess(ViewEarningsDistributionQuoteRow)) useState(true) else null
  val viewEarningsTechnicalPlanningRoofRow = if (loggedInUserAccessRights.canAccess(ViewEarningsTechnicalPlanningRoofRow)) useState(true) else null
  val viewEarningsTechnicalPlanningElectricRow = if (loggedInUserAccessRights.canAccess(ViewEarningsTechnicalPlanningElectricRow)) useState(true) else null
  val viewEarningsRoofAssemblyRow = if (loggedInUserAccessRights.canAccess(ViewEarningsRoofAssemblyRow)) useState(true) else null
  val viewEarningsScaffoldingAssemblyRow = if (loggedInUserAccessRights.canAccess(ViewEarningsScaffoldingAssemblyRow)) useState(true) else null
  val viewEarningsElectricityWorkRow = if (loggedInUserAccessRights.canAccess(ViewEarningsElectricityWorkRow)) useState(true) else null
  val viewEarningsBureaucracyRow = if (loggedInUserAccessRights.canAccess(ViewEarningsBureaucracyRow)) useState(true) else null

  val (busy, setBusy) = useState(false)


  val accessRights = AccessRights(
    buildSet {
      if (editCustomers?.value == true) add(EditCustomers)
      if (editProjects?.value == true) add(EditProjects)
      if (editConfigurations?.value == true) add(EditConfigurations)
      if (editModuleLayouts?.value == true) add(EditModuleLayouts)
      if (editOtherUsers?.value == true) add(EditOtherUsers)
      if (editOwnCompany?.value == true) add(EditOwnCompany)
      if (editOtherCompanies?.value == true) add(EditOtherCompanies)
      if (deleteProjects?.value == true) add(DeleteProjects)
      if (verifyProjects?.value == true) add(VerifyProjects)
      if (deleteUsers?.value == true) add(DeleteUsers)
      if (viewPriceList?.value == true) add(ViewPriceList)
      if (viewProjectCount?.value == true) add(ViewProjectCount)
      if (deleteProcessStates?.value == true) add(DeleteProcessStates)
      if (viewAccounting?.value == true) add(ViewAccounting)
      if (viewQuoteDump?.value == true) add(ViewQuoteDump)
      if (viewProfits?.value == true) add(ViewProfits)
      if (viewEarnings?.value == true) add(ViewEarnings)
      if (editEarnings?.value == true) add(EditEarnings)
      if (viewEarningsReferrerRow?.value == true) add(ViewEarningsReferrerRow)
      if (viewEarningsDistributionBlueprintRow?.value == true) add(ViewEarningsDistributionBlueprintRow)
      if (viewEarningsDistributionQuoteRow?.value == true) add(ViewEarningsDistributionQuoteRow)
      if (viewEarningsTechnicalPlanningRoofRow?.value == true) add(ViewEarningsTechnicalPlanningRoofRow)
      if (viewEarningsTechnicalPlanningElectricRow?.value == true) add(ViewEarningsTechnicalPlanningElectricRow)
      if (viewEarningsRoofAssemblyRow?.value == true) add(ViewEarningsRoofAssemblyRow)
      if (viewEarningsScaffoldingAssemblyRow?.value == true) add(ViewEarningsScaffoldingAssemblyRow)
      if (viewEarningsElectricityWorkRow?.value == true) add(ViewEarningsElectricityWorkRow)
      if (viewEarningsBureaucracyRow?.value == true) add(ViewEarningsBureaucracyRow)
    }
  )

  val plausible = userName.value.isNotBlank() && password1.value.isNotEmpty() && password1.value == password2.value && editorName.value.isNotBlank()

  val addNewUserAction = useCallback(plausible, userName.value, password1.value, password2.value, editorName.value, accessRights, forCompany.value) {
    if (plausible.not()) return@useCallback

    launchAndNotify {
      setBusy(true)
      UiActions.addNewUser(
        newUser = UserInformation(
          loginName = UserLoginName(userName.value),
          editorName = editorName.value,
          accessRights = accessRights,
          company = forCompany.value,
        ),
        password = password1.value,
      )
      setBusy(false)
    }
  }

  div {

    onEnter(addNewUserAction)

    h2 {
      +"Neuen Nutzer anlegen"
    }

    userForm(
      userName = userName,
      editorName = editorName,
      companyProfile = forCompany,
      editCustomers = editCustomers,
      editProjects = editProjects,
      editConfigurations = editConfigurations,
      editModuleLayouts = editModuleLayouts,
      editOtherUsers = editOtherUsers,
      editOwnCompany = editOwnCompany,
      editOtherCompanies = editOtherCompanies,
      deleteProjects = deleteProjects,
      verifyProjects = verifyProjects,
      deleteUsers = deleteUsers,
      viewPriceList = viewPriceList,
      viewProjectCount = viewProjectCount,
      deleteProcessStates = deleteProcessStates,
      viewAccounting = viewAccounting,
      viewQuoteDump = viewQuoteDump,
      viewProfits = viewProfits,
      viewEarnings = viewEarnings,
      editEarnings = editEarnings,

      viewEarningsTechnicalPlanningRoofRow = viewEarningsTechnicalPlanningRoofRow,
      viewEarningsTechnicalPlanningElectricRow = viewEarningsTechnicalPlanningElectricRow,
      viewEarningsElectricityWorkRow = viewEarningsElectricityWorkRow,
      viewEarningsDistributionBlueprintRow = viewEarningsDistributionBlueprintRow,
      viewEarningsDistributionQuoteRow = viewEarningsDistributionQuoteRow,
      viewEarningsBureaucracyRow = viewEarningsBureaucracyRow,
      viewEarningsRoofAssemblyRow = viewEarningsRoofAssemblyRow,
      viewEarningsScaffoldingAssemblyRow = viewEarningsScaffoldingAssemblyRow,
      viewEarningsReferrerRow = viewEarningsReferrerRow,

      existingUser = null,
    )

    passwordForm(
      fieldName = "Passwort",
      newPassword1 = password1,
      newPassword2 = password2,
    )

    div("my-3") {
      button(classes = "btn btn-primary w-100 btn-lg", type = ButtonType.button) {
        +"Anlegen"

        attrs {
          disabled = plausible.not() || busy
          onClickFunction = { addNewUserAction() }
        }
      }
    }

  }

}

external interface AddNewUserFormProps : Props {
  var companyProfile: CompanyProfile
}
