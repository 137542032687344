package components.project.configuration.additional

import components.project.freezableAddButton
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.ExistingBHKWFacilityConfiguration
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration
import it.neckar.lizergy.model.configuration.components.ExistingPVFacilityConfiguration
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

val ExistingFacilitiesOverview: FC<ExistingFacilitiesOverviewProps> = fc("ExistingFacilitiesOverview") { props ->
  val existingFacilitiesConfigurationToSave = props::existingFacilitiesConfigurationToSave.getNotNull()

  val addPVAction = props::addPVAction.safeGet()
  val addBHKWAction = props::addBHKWAction.safeGet()
  val editPVAction = props::editPVAction.safeGet()
  val editBHKWAction = props::editBHKWAction.safeGet()
  val deletePVAction = props::deletePVAction.safeGet()
  val deleteBHKWAction = props::deleteBHKWAction.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val originalExistingFacilityConfiguration = existingFacilitiesConfigurationToSave.value
  val bilderTypenschildToSave = useState(originalExistingFacilityConfiguration.bilderTypenschild)
  val datenAbgelegtToSave = useState(originalExistingFacilityConfiguration.datenAbgelegt)


  useMemo(
    bilderTypenschildToSave.value,
    datenAbgelegtToSave.value,
  ) {
    existingFacilitiesConfigurationToSave.setter(
      originalExistingFacilityConfiguration.copy(
        bilderTypenschild = bilderTypenschildToSave.value,
        datenAbgelegt = datenAbgelegtToSave.value,
      )
    )
  }


  div {

    h2("my-5") {
      +"Bestandsanlagen PV"

      freezableAddButton(editableStatus) {
        addPVAction()
      }
    }

    div("row") {
      existingFacilitiesConfigurationToSave.value.existingPVFacilities.fastForEach { existingPVFacilityConfiguration ->
        div("col-md-6") {
          key = existingPVFacilityConfiguration.uuid.toString()
          existingPVFacilityPreview(
            existingPVFacilityConfiguration = existingPVFacilityConfiguration,
            editableStatus = editableStatus,
            editAction = editPVAction,
            deleteAction = deletePVAction,
          )
        }
      }
    }

    h2("my-5") {
      +"Bestandsanlagen BHKW"

      freezableAddButton(editableStatus) {
        addBHKWAction()
      }
    }

    div("row") {
      existingFacilitiesConfigurationToSave.value.existingBHKWFacilities.fastForEach { existingBHKWFacilityConfiguration ->
        div("col-md-6") {
          key = existingBHKWFacilityConfiguration.uuid.toString()
          existingBHKWFacilityPreview(
            existingBHKWFacilityConfiguration = existingBHKWFacilityConfiguration,
            editableStatus = editableStatus,
            editAction = editBHKWAction,
            deleteAction = deleteBHKWAction,
          )
        }
      }
    }

    div("my-3") {
      div {
        checkbox(
          valueAndSetter = bilderTypenschildToSave,
          fieldName = "bilderTypenschild",
          title = "Bilder Typenschild",
          editableStatus = editableStatus,
        )
      }
      div {
        checkbox(
          valueAndSetter = datenAbgelegtToSave,
          fieldName = "datenAbgelegt",
          title = "Daten abgelegt",
          editableStatus = editableStatus,
        )
      }
    }

  }

}


external interface ExistingFacilitiesOverviewProps : Props {
  var existingFacilitiesConfigurationToSave: StateInstance<ExistingFacilitiesConfiguration>
  var addPVAction: () -> Unit
  var addBHKWAction: () -> Unit
  var editPVAction: (existingFacility: ExistingPVFacilityConfiguration) -> Unit
  var editBHKWAction: (existingFacility: ExistingBHKWFacilityConfiguration) -> Unit
  var deletePVAction: (existingFacility: ExistingPVFacilityConfiguration) -> Unit
  var deleteBHKWAction: (existingFacility: ExistingBHKWFacilityConfiguration) -> Unit
  var editableStatus: EditableStatus
}
