package components.project.configuration.layout.moduleLayout

import it.neckar.open.unit.si.mm
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Form for module insets
 */
fun RBuilder.moduleLayoutInsetsForm(
  suggestedRoofInsetsLeft: StateInstance<@mm Int>,
  suggestedRoofInsetsRight: StateInstance<@mm Int>,
  suggestedRoofInsetsTop: StateInstance<@mm Int>,
  suggestedRoofInsetsBottom: StateInstance<@mm Int>,
  editableStatus: EditableStatus,
): Unit = child(moduleLayoutInsetsForm) {
  attrs {
    this.suggestedRoofInsetsLeft = suggestedRoofInsetsLeft
    this.suggestedRoofInsetsRight = suggestedRoofInsetsRight
    this.suggestedRoofInsetsTop = suggestedRoofInsetsTop
    this.suggestedRoofInsetsBottom = suggestedRoofInsetsBottom
    this.editableStatus = editableStatus
  }
}

val moduleLayoutInsetsForm: FC<ModuleLayoutInsetsFormProps> = fc("moduleLayoutInsetsForm") { props ->
  val suggestedRoofInsetsLeft = props::suggestedRoofInsetsLeft.getNotNull()
  val suggestedRoofInsetsRight = props::suggestedRoofInsetsRight.getNotNull()
  val suggestedRoofInsetsTop = props::suggestedRoofInsetsTop.getNotNull()
  val suggestedRoofInsetsBottom = props::suggestedRoofInsetsBottom.getNotNull()

  val editableStatus = props::editableStatus.safeGet()

  div("row gx-0 mt-3") {
    div("col-sm-6") {
      h5 { +"Mindestabstände Dachrand" }
    }
  }

  div("row gx-0") {
    div("col-sm-3") {
      floatingIntInputField(
        valueAndSetter = suggestedRoofInsetsLeft,
        fieldName = "suggestedRoofInsetsLeft",
        title = "Links (mm)",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedRight()
        }
      }
    }
    div("col-sm-3") {
      floatingIntInputField(
        valueAndSetter = suggestedRoofInsetsRight,
        fieldName = "suggestedRoofInsetsRight",
        title = "Rechts (mm)",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedLeft()
          mergedRight()
        }
      }
    }

    div("col-sm-3") {
      floatingIntInputField(
        valueAndSetter = suggestedRoofInsetsTop,
        fieldName = "suggestedRoofInsetsTop",
        title = "Oben (mm)",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedRight()
          mergedLeft()
        }
      }
    }

    div("col-sm-3") {
      floatingIntInputField(
        valueAndSetter = suggestedRoofInsetsBottom,
        fieldName = "suggestedRoofInsetsBottom",
        title = "Unten (mm)",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedLeft()
        }
      }
    }
  }
}

external interface ModuleLayoutInsetsFormProps : Props {
  var suggestedRoofInsetsLeft: StateInstance<@mm Int>
  var suggestedRoofInsetsRight: StateInstance<@mm Int>
  var suggestedRoofInsetsTop: StateInstance<@mm Int>
  var suggestedRoofInsetsBottom: StateInstance<@mm Int>
  var editableStatus: EditableStatus
}

