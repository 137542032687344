package services.http

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.PlannerClientDeploymentZone
import it.neckar.lizergy.PlannerServiceDeploymentZone
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot.QuoteSnapshotId
import it.neckar.lizergy.model.http.PlannerApiPathConstants
import it.neckar.lizergy.model.http.PlannerApiPathConstants.Auth
import it.neckar.lizergy.model.http.PlannerApiPathConstants.Comments
import it.neckar.lizergy.model.http.PlannerApiPathConstants.ProcessStates
import it.neckar.lizergy.model.http.PlannerApiPathConstants.ProjectQuery
import it.neckar.lizergy.model.http.PlannerApiPathConstants.Storage
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.open.http.Url
import it.neckar.projects.common.Port
import it.neckar.projects.pvplanner.PvPlannerPorts
import it.neckar.projects.pvplanner.PvPlannerService

/**
 * Calculates the urls for the services.
 *
 * Does include the hostnames and ports.
 *
 * Uses [PlannerApiPathConstants] to calculate the paths.
 */
class PlannerUrlSupport internal constructor(
  /**
   * The usage type
   */
  val usage: Usage,

  /**
   * Creates a URL for a relative path
   */
  val createUrl: (service: PvPlannerService, relativePath: Url.Relative, portForDev: Port) -> Url.RelativeAppender<*>,
) {

  /**
   * Urls to the authentication service
   */
  val auth: AuthServiceUrls = AuthServiceUrls()

  inner class AuthServiceUrls : ServiceUrls {
    override val service: PvPlannerService = PvPlannerService.AuthService

    override val portForDev: Port = PvPlannerPorts.authService
    override val heartbeat: Url = createUrl(service, Auth.heartbeatUrl, portForDev)
    override val baseUrl: Url = createUrl(service, Auth.baseUrl, portForDev)

    val login: Url = createUrl(service, Auth.loginUrl, portForDev)
    val refreshAccessToken: Url = createUrl(service, Auth.refreshAccessToken, portForDev)

    val changePassword: Url = createUrl(service, Auth.changePasswordUrl, portForDev)
    val addNewUser: Url = createUrl(service, Auth.addNewUserUrl, portForDev)
    val changeUserInfo: Url = createUrl(service, Auth.changeUserInfoUrl, portForDev)
    val deleteUser: Url = createUrl(service, Auth.deleteUserUrl, portForDev)
    val addNewCompany: Url = createUrl(service, Auth.addNewCompanyUrl, portForDev)
    val changeCompanyInfo: Url = createUrl(service, Auth.changeCompanyInfoUrl, portForDev)
    val listUsersAndCompanies: Url = createUrl(service, Auth.listUsersAndCompaniesUrl, portForDev)

  }

  val pdf: PdfServiceUrls = PdfServiceUrls()

  inner class PdfServiceUrls : ServiceUrls {
    override val service: PvPlannerService = PvPlannerService.PdfReportService

    override val portForDev: Port = PvPlannerPorts.pdfReportService
    override val heartbeat: Url = createUrl(service, PlannerApiPathConstants.PdfReport.heartbeatUrl, portForDev)
    override val baseUrl: Url = createUrl(service, PlannerApiPathConstants.PdfReport.baseUrl, portForDev)

    fun quoteOfferForCurrent(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, PlannerApiPathConstants.PdfReport.quoteOfferForCurrent(projectId, configurationId), portForDev)
    }

    fun quoteOfferForSnapshot(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshotId: QuoteSnapshotId): Url.RelativeAppender<*> {
      return createUrl(service, PlannerApiPathConstants.PdfReport.quoteOfferForSnapshot(projectId, configurationId, quoteSnapshotId), portForDev)
    }

    fun orderConfirmationForSnapshot(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshotId: QuoteSnapshotId): Url.RelativeAppender<*> {
      return createUrl(service, PlannerApiPathConstants.PdfReport.orderConfirmationForSnapshot(projectId, configurationId, quoteSnapshotId), portForDev)
    }

    fun assemblyPortfolio(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, PlannerApiPathConstants.PdfReport.assemblyPortfolio(projectId, configurationId), portForDev)
    }
  }

  val pdfPreviewService: PdfPreviewServiceUrls = PdfPreviewServiceUrls()

  inner class PdfPreviewServiceUrls : ServiceUrls {
    override val service: PvPlannerService = PvPlannerService.PdfPreviewService

    override val portForDev: Port = PvPlannerPorts.pdfPreviewService
    override val heartbeat: Url = createUrl(service, PlannerApiPathConstants.PdfPreview.heartbeatUrl, portForDev)
    override val baseUrl: Url = createUrl(service, PlannerApiPathConstants.PdfPreview.baseUrl, portForDev)

    /**
     * Creates the URL for an offer preview based on an existing quote snapshot
     */
    fun quoteOfferPreview(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshotId: QuoteSnapshotId): Url.RelativeAppender<*> {
      return createUrl(service, PlannerApiPathConstants.PdfPreview.quoteOfferForSnapshot(projectId, configurationId, quoteSnapshotId), portForDev)
    }

    /**
     * Creates the URL for a confirmation preview based on a configuration - without a [QuoteSnapshot]. Returns the values for "current"
     */
    fun quoteOfferPreviewCurrent(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, PlannerApiPathConstants.PdfPreview.quoteOfferForCurrent(projectId, configurationId), portForDev)
    }

    fun quoteConfirmationPreview(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshotId: QuoteSnapshotId): Url.RelativeAppender<*> {
      return createUrl(service, PlannerApiPathConstants.PdfPreview.orderConfirmationForSnapshot(projectId, configurationId, quoteSnapshotId), portForDev)
    }

    fun assemblyPortfolio(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, PlannerApiPathConstants.PdfPreview.assemblyPortfolio(projectId, configurationId), portForDev)
    }
  }


  val projectQuery: ProjectQueryServiceUrls = ProjectQueryServiceUrls()

  inner class ProjectQueryServiceUrls : ServiceUrls {
    override val service: PvPlannerService = PvPlannerService.ProjectQueryService

    override val portForDev: Port = PvPlannerPorts.projectQueryService
    override val heartbeat: Url = createUrl(service, ProjectQuery.heartbeatUrl, portForDev)
    override val baseUrl: Url = createUrl(service, ProjectQuery.baseUrl, portForDev)

    val projectUrl: Url = createUrl(service, ProjectQuery.projectPreviewsUrl, portForDev)

    val projectPreviewsUrl: Url = createUrl(service, ProjectQuery.projectPreviewsUrl, portForDev)
    val projectCountsUrl: Url = createUrl(service, ProjectQuery.projectCountsUrl, portForDev)
    val accountingProjectPreviewsUrl: Url = createUrl(service, ProjectQuery.accountingProjectPreviewsUrl, portForDev)
    val allAccountingProjectPreviewsAsCSVUrl: Url = createUrl(service, ProjectQuery.allAccountingProjectPreviewsAsCSVUrl, portForDev)


    fun staticPriceList(): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.staticPriceList, portForDev)
    }

    /**
     * Returns the projects including preview values.
     * Returns all projects for *all* companies the user has access to
     */
    fun projects(): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.projectsUrl, portForDev)
    }

    fun accountingProjects(): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.accountingProjectsUrl, portForDev)
    }

    fun projectsSearch(): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.projectsSearchUrl, portForDev)
    }

    /**
     * The base URL for a project
     */
    fun project(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.projectUrl(projectId), portForDev)
    }

    fun resolvedProject(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.resolvedProjectUrl(projectId), portForDev)
    }

    fun multipleResolvedProjects(): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.multipleResolvedProjectsUrl, portForDev)
    }

    fun archiveProjectUrl(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.archiveProjectUrl(projectId), portForDev)
    }

    fun blueprint(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.blueprint
    }

    fun verifications(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.verifications
    }

    fun orderSpecialMaterial(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.orderSpecialMaterial
    }

    fun gridAssessment(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.gridAssessment
    }

    fun assemblyPortfolio(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.assemblyPortfolio
    }

    fun advanceInvoice(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.advanceInvoice
    }

    fun assemblyRoof(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.assemblyRoof
    }

    fun assemblyBasement(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.assemblyBasement
    }

    fun switchMeterBox(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.switchMeterBox
    }

    fun startupOperations(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.startupOperations
    }

    fun finishingUp(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.finishingUp
    }

    fun finalAccount(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.finalAccount
    }

    fun documentation(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.documentation
    }


    /**
     * Returns all configurations for a project
     */
    fun configurationsForProject(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.configurationsForProjectUrl(projectId), portForDev)
    }

    fun configuration(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.configurationUrl(projectId, configurationId), portForDev)
    }

    fun quoteConfiguration(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.quoteConfigurationUrl(projectId, configurationId), portForDev)
    }

    fun moduleLayouts(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return configuration(projectId, configurationId) + Storage.moduleLayouts
    }

    fun quoteSnapshots(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return configuration(projectId, configurationId) + Storage.quoteSnapshots
    }

    /**
     * Creates the url to a specific quote snapshot
     */
    fun quoteSnapshot(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, snapshotId: QuoteSnapshotId): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.quoteSnapshotUrl(projectId, configurationId, snapshotId), portForDev)
    }

    fun earningsDistribution(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.earningsDistributionUrl(projectId, configurationId), portForDev)
    }

    fun manualQuoteElements(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, ProjectQuery.manualQuoteElementsUrl(projectId, configurationId), portForDev)
    }

  }


  val geoInformation: GeoInformationServiceUrls = GeoInformationServiceUrls()

  inner class GeoInformationServiceUrls : ServiceUrls {
    override val service: PvPlannerService = PvPlannerService.GeoInformationService


    override val portForDev: Port = PvPlannerPorts.geoInformationService
    override val heartbeat: Url = createUrl(service, PlannerApiPathConstants.GeoInformation.heartbeatUrl, portForDev)
    override val baseUrl: Url = createUrl(service, PlannerApiPathConstants.GeoInformation.baseUrl, portForDev)

    val geoCodeServiceUrl: Url = createUrl(service, PlannerApiPathConstants.GeoInformation.geoCodeUrl, portForDev)

    /**
     * URL for the geoInformation service OSRM
     */
    val geoInformationServiceUrlOSRM: Url = createUrl(service, PlannerApiPathConstants.GeoInformation.geoRouteUrl, portForDev)
  }

  val storage: StorageServiceUrls = StorageServiceUrls()

  /**
   * URLs for the storage service
   */
  inner class StorageServiceUrls : ServiceUrls {
    override val service: PvPlannerService = PvPlannerService.StorageService

    override val portForDev: Port = PvPlannerPorts.storageService
    override val heartbeat: Url = createUrl(service, Storage.heartbeatUrl, portForDev)
    override val baseUrl: Url = createUrl(service, Storage.baseUrl, portForDev)

    fun projects(): Url.RelativeAppender<*> {
      return createUrl(service, Storage.projectsUrl, portForDev)
    }

    /**
     * The base URL for a project
     */
    fun project(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return createUrl(service, Storage.projectUrl(projectId), portForDev)
    }

    fun resolvedProject(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return createUrl(service, Storage.resolvedProjectUrl(projectId), portForDev)
    }

    fun multipleResolvedProjects(): Url.RelativeAppender<*> {
      return createUrl(service, Storage.multipleResolvedProjectsUrl, portForDev)
    }

    fun archiveProjectUrl(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return createUrl(service, Storage.archiveProjectUrl(projectId), portForDev)
    }

    fun blueprint(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.blueprint
    }

    fun verifications(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.verifications
    }

    fun orderSpecialMaterial(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.orderSpecialMaterial
    }

    fun gridAssessment(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.gridAssessment
    }

    fun assemblyPortfolio(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.assemblyPortfolio
    }

    fun advanceInvoice(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.advanceInvoice
    }

    fun assemblyRoof(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.assemblyRoof
    }

    fun assemblyBasement(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.assemblyBasement
    }

    fun switchMeterBox(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.switchMeterBox
    }

    fun startupOperations(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.startupOperations
    }

    fun finishingUp(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.finishingUp
    }

    fun finalAccount(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.finalAccount
    }

    fun documentation(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return project(projectId) + Storage.documentation
    }


    /**
     * Returns all configurations for a project
     */
    fun configurationsForProject(projectId: PhotovoltaicsProjectId): Url.RelativeAppender<*> {
      return createUrl(service, Storage.configurationsForProjectUrl(projectId), portForDev)
    }

    fun configuration(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, Storage.configurationUrl(projectId, configurationId), portForDev)
    }

    fun quoteConfiguration(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, Storage.quoteConfigurationUrl(projectId, configurationId), portForDev)
    }

    fun moduleLayouts(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return configuration(projectId, configurationId) + Storage.moduleLayouts
    }

    fun quoteSnapshots(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return configuration(projectId, configurationId) + Storage.quoteSnapshots
    }

    /**
     * Creates the url to a specific quote snapshot
     */
    fun quoteSnapshot(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, snapshotId: QuoteSnapshotId): Url.RelativeAppender<*> {
      return createUrl(service, Storage.quoteSnapshotUrl(projectId, configurationId, snapshotId), portForDev)
    }

    fun earningsDistribution(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, Storage.earningsDistributionUrl(projectId, configurationId), portForDev)
    }

    fun manualQuoteElements(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.RelativeAppender<*> {
      return createUrl(service, Storage.manualQuoteElementsUrl(projectId, configurationId), portForDev)
    }

  }

  val processStates: ProcessStatesServiceUrls = ProcessStatesServiceUrls()

  inner class ProcessStatesServiceUrls : ServiceUrls {
    override val service: PvPlannerService = PvPlannerService.ProcessStatesService

    override val portForDev: Port = PvPlannerPorts.processStateService
    override val heartbeat: Url = createUrl(service, ProcessStates.heartbeatUrl, portForDev)
    override val baseUrl: Url = createUrl(service, ProcessStates.baseUrl, portForDev)

    fun processStates(uuid: Uuid): Url.RelativeAppender<*> {
      return createUrl(service, ProcessStates.processStatesForComponentUrl(uuid), portForDev)
    }

    fun multipleProcessStates(): Url.RelativeAppender<*> {
      return createUrl(service, ProcessStates.addMultipleProcessStates, portForDev)
    }

    fun duplicateProcessStates(): Url.RelativeAppender<*> {
      return createUrl(service, ProcessStates.duplicateProcessStatesUrl, portForDev)
    }

    fun allProcessStates(): Url.RelativeAppender<*> {
      return createUrl(service, ProcessStates.allProcessStatesUrl, portForDev)
    }
  }

  val comments: CommentsServiceUrls = CommentsServiceUrls()

  inner class CommentsServiceUrls : ServiceUrls {
    override val service: PvPlannerService = PvPlannerService.CommentsService

    override val portForDev: Port = PvPlannerPorts.commentsService
    override val heartbeat: Url = createUrl(service, Comments.heartbeatUrl, portForDev)
    override val baseUrl: Url = createUrl(service, Comments.baseUrl, portForDev)


    fun comments(uuid: Uuid): Url.RelativeAppender<*> {
      return createUrl(service, Comments.commentsForComponentUrl(uuid), portForDev)
    }

    fun duplicateComments(): Url.RelativeAppender<*> {
      return createUrl(service, Comments.duplicateCommentsUrl, portForDev)
    }
  }

  /**
   * Returns the service urls
   */
  fun getForService(service: PvPlannerService): ServiceUrls {
    return when (service) {
      PvPlannerService.AuthService -> auth
      PvPlannerService.CommentsService -> comments
      PvPlannerService.GeoInformationService -> geoInformation
      PvPlannerService.PdfPreviewService -> pdfPreviewService
      PvPlannerService.PdfReportService -> pdf
      PvPlannerService.ProcessStatesService -> processStates
      PvPlannerService.ProjectQueryService -> projectQuery
      PvPlannerService.StorageService -> storage
      else -> TODO("Not available for $service")
    }
  }

  companion object {
    fun forClient(
      /**
       * The current deployment zone
       */
      deploymentZone: PlannerClientDeploymentZone,

      /**
       * Creates a URL for a relative path.
       * Should only be provided for tests
       */
      createUrl: (relativePath: Url.Relative, portForDev: Port) -> Url.RelativeAppender<*> = { relativePath, portForDev ->
        calculateBaseUrlForUsageInClient(deploymentZone, portForDev) + relativePath
      },

      ): PlannerUrlSupport {
      return PlannerUrlSupport(Usage.WebClient) { service, relativePath, portForDev ->
        createUrl(relativePath, portForDev)
      }
    }

    fun forService(
      /**
       * The current deployment zone
       */
      deploymentZone: PlannerServiceDeploymentZone,
      createUrl: (service: PvPlannerService, relativePath: Url.Relative, portForDev: Port) -> Url.RelativeAppender<*> = { service, relativePath, portForDev ->
        calculateBaseUrlForUsageInService(deploymentZone, service, portForDev) + relativePath
      },
    ): PlannerUrlSupport {
      return PlannerUrlSupport(Usage.Service) { service: PvPlannerService, relativePath: Url.Relative, portForDev: Port ->
        createUrl(service, relativePath, portForDev)
      }
    }

    /**
     * Calculates the base URL (hostname + port)
     */
    fun calculateBaseUrl(usage: Usage, deploymentZone: PlannerClientDeploymentZone?, serviceDeploymentZone: PlannerServiceDeploymentZone?, service: PvPlannerService, portForDev: Port): Url.RelativeAppender<*> {
      return when (usage) {
        Usage.WebClient -> calculateBaseUrlForUsageInClient(requireNotNull(deploymentZone), portForDev)
        Usage.Service -> calculateBaseUrlForUsageInService(requireNotNull(serviceDeploymentZone), service, portForDev)
      }
    }

    /**
     * Returns the base URL - depending on the environment (dev or production)
     *
     * Returns an absolute URL with a trailing "/"
     */
    fun calculateBaseUrlForUsageInClient(
      deploymentZone: PlannerClientDeploymentZone,
      portForDev: Port,
    ): Url.RelativeAppender<*> {
      return when (deploymentZone) {
        /**
         * Use the local development services - with different ports
         */
        PlannerClientDeploymentZone.LocalDevelopmentDocker, PlannerClientDeploymentZone.LocalDevelopment -> Url.absolute("http://localhost:$portForDev/")

        /**
         * Uses nginx to proxy the requests to the correct service
         */
        PlannerClientDeploymentZone.Production, PlannerClientDeploymentZone.Schaufenster, PlannerClientDeploymentZone.Spielwiese -> Url.root

      }.also {
        require(it.value.endsWith("/")) {
          "baseURI $it does not end with '/' but should!"
        }
      }
    }

    /**
     * Creates the URLs for usage in services (to access other services)
     */
    @Suppress("HttpUrlsUsage")
    fun calculateBaseUrlForUsageInService(
      deploymentZone: PlannerServiceDeploymentZone,
      service: PvPlannerService,
      portForDev: Port,
    ): Url.Absolute {
      return when (deploymentZone) {
        PlannerServiceDeploymentZone.LocalDevelopment -> Url.absolute("http://localhost:$portForDev")
        PlannerServiceDeploymentZone.Docker -> Url.absolute("http://${service.serviceName}:$portForDev")
      }
    }
  }

  /**
   * The usage type of the [PlannerUrlSupport]
   */
  enum class Usage {
    /**
     * For usage in the web client (react)
     */
    WebClient,

    /**
     * For usage in the microservices (on the server)
     */
    Service
  }

  interface ServiceUrls {
    val service: PvPlannerService

    /**
     * The port used for dev connections
     */
    val portForDev: Port

    /**
     * The URL for the heartbeat
     */
    val heartbeat: Url

    /**
     * The base url for the service
     */
    val baseUrl: Url
  }
}
