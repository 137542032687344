package components.company.pricelist.tables

import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.kotlin.lang.ifNull
import it.neckar.open.kotlin.lang.toCheckboxChar
import it.neckar.open.time.formatHourAndMinutes
import kotlinx.html.ThScope
import plannerI18nConfiguration
import react.*
import react.dom.*
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectPriceList
import style.toStyleClass

val WallBoxesPriceTable: FC<Props> = fc("WallBoxesPriceTable") {
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()

  val availableWallBoxes = availableProducts.availableWallBoxes(null)
  val wallBoxPrices = priceList.wallBoxPrices
  val wallboxInstallationPrice = priceList.wallboxInstallationPrice


  div("col") {
    h2 {
      +"Wallbox"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          th(ThScope.col) {
            +"Details"
          }
          th(ThScope.col, "text-center") {
            +"Intelligente Wallbox"
          }
          th(ThScope.col, "text-end") {
            +"Ladekraft"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
          th(ThScope.col, "text-center") {
            +"In Gebrauch"
          }
        }
      }

      tbody {

        tr {
          key = "installation"

          th(ThScope.row) {
            +"1"
          }
          td {
            +"Montage Ladestation"
          }
          td {
          }

          td("text-end") {
          }

          td("text-center") {
          }

          td("text-end") {
            +wallboxInstallationPrice.materialPrice.inputPrice.format().ifNull("-")
          }
          td("text-end") {
            +wallboxInstallationPrice.materialPrice.sellingPrice.format().ifNull("-")
          }
          td("text-end") {
            wallboxInstallationPrice.materialPrice.profit.format().ifNull("-")
          }

          td("text-center") {
          }
        }

        tr {
          key = "installationWork"

          th(ThScope.row) {
          }
          td {
            +"Arbeitszeit:"
          }

          td("text-end") {
          }

          td("text-center") {
          }

          td("text-end") {
            +wallboxInstallationPrice.workingTime.formatHourAndMinutes().ifNull("-")
          }

          td("text-end") {
            +wallboxInstallationPrice.workingTimes.priceWithProfit.inputPrice.format().ifNull("-")
          }
          td("text-end") {
            +wallboxInstallationPrice.workingTimes.priceWithProfit.sellingPrice.format().ifNull("-")
          }
          td("text-end") {
            +wallboxInstallationPrice.workingTimes.priceWithProfit.profit.format().ifNull("-")
          }

          td("text-center") {
          }
        }

        tr {
          key = "installationTotal"

          th(ThScope.row) {
          }
          td {
            b { +"Gesamt:" }
          }

          td("text-end") {
          }

          td("text-end") {
          }

          td("text-center") {
          }

          td("text-end") {
            b { +wallboxInstallationPrice.inputPrice.format().ifNull("-") }
          }
          td("text-end") {
            b { +wallboxInstallationPrice.sellingPrice.format().ifNull("-") }
          }
          td("text-end") {
            b { +wallboxInstallationPrice.profit.format().ifNull("-") }
          }

          td("text-center") {
          }
        }

        availableWallBoxes.fastForEachIndexed { wallboxIndex, wallbox ->
          tr(classes = wallbox.lifeCycleState.toStyleClass()) {
            key = wallbox.uuid.toString()

            th(ThScope.row) {
              +(wallboxIndex + 2).toString()
            }
            td {
              +wallbox.description
            }
            td {
              +(wallbox.details.orEmpty())
            }

            td("text-center") {
              +wallbox.isSmartWallBox.toCheckboxChar()
            }

            td("text-end") {
              +wallbox.chargingCapacity.capacity().format(plannerI18nConfiguration)
            }

            td("text-end") {
              +wallBoxPrices[wallbox.uuid].inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +wallBoxPrices[wallbox.uuid].sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +wallBoxPrices[wallbox.uuid].profit.format().ifNull("-")
            }

            td("text-center") {
              +wallbox.lifeCycleState.format()
            }
          }
        }

      }
    }
  }
}
