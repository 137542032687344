package components.project.configuration.location

import com.meistercharts.maps.MapCoordinates
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import kotlinx.html.id
import react.*
import react.dom.*

/**
 *
 */
fun RBuilder.locationMap(googleMapsCoordinatesLink: String?): Unit = child(LocationMap) {
  attrs {
    this.googleMapsCoordinatesLink = googleMapsCoordinatesLink
  }
}

fun RBuilder.locationMap(mapCoordinates: MapCoordinates?): Unit = child(LocationMap) {
  val googleMapsCoordinatesLink = mapCoordinates?.let { coordinates ->
    "https://www.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}&t=k&z=17&ie=UTF8&iwloc=&output=embed"
  }

  locationMap(googleMapsCoordinatesLink)
}

val LocationMap: FC<LocationMapProps> = fc("LocationMap") { props ->
  val googleMapsCoordinatesLink = props::googleMapsCoordinatesLink.safeGet()


  div {
    if (googleMapsCoordinatesLink == null) {
      +"Karte momentan nicht verfügbar."
    }
  }

  div("collapse") {
    attrs {
      id = "collapse_gmap"
      addClassIf("show") {
        googleMapsCoordinatesLink != null
      }
    }

    div("gmap_canvas map-container") {
      div {
        iframe(classes = "map") {
          attrs {
            width = "100%"
            height = "450"
            id = "gmap_canvas"
            src = googleMapsCoordinatesLink ?: "https://maps.google.com/maps?q=lizergy&t=&z=17&ie=UTF8&iwloc=&output=embed"
          }
        }
      }

    }

  }
}

external interface LocationMapProps : Props {
  var googleMapsCoordinatesLink: String?
}
