package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lizergy.model.project.ResolvedProject
import store.PlannerClientState

data class ProjectLoadedAction(val project: ResolvedProject) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    val updatedProjects = resolvedProjects.withUpdated(project)

    return this.copy(resolvedProjects = updatedProjects)
  }
}
