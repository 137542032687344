package serialized

import it.neckar.lizergy.model.configuration.components.AssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.AssemblyConfiguration.AssemblyConfigurationId
import it.neckar.lizergy.model.configuration.components.AssemblyStatus
import it.neckar.lizergy.model.configuration.components.ResolvedAssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.ScaffoldingArea
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty.AssemblyDifficultyId
import it.neckar.lizergy.model.price.ProductResolver
import kotlinx.serialization.Serializable

@Serializable
data class SerializedAssemblyConfiguration(
  override val id: AssemblyConfigurationId = AssemblyConfigurationId.random(),
  val assemblyDifficultyId: AssemblyDifficultyId,
  override val numberOfModulesKreuzverbund: Int? = null,
  override val dachstaenderIsolierung: Boolean = false,
  override val moveSatelliteDish: Boolean = false,
  override val removeAntenna: Boolean = false,
  override val externerBlitzschutzVorhanden: Boolean = false,
  /**
   * If the system will be assembled by the customer
   */
  override val assemblyStatus: AssemblyStatus = ScaffoldingArea(),
) : AssemblyConfiguration {

  fun resolve(productResolver: ProductResolver): ResolvedAssemblyConfiguration {
    return ResolvedAssemblyConfiguration(
      id = id,
      assemblyDifficulty = productResolver[assemblyDifficultyId],
      numberOfModulesKreuzverbund = numberOfModulesKreuzverbund,
      dachstaenderIsolierung = dachstaenderIsolierung,
      moveSatelliteDish = moveSatelliteDish,
      removeAntenna = removeAntenna,
      externerBlitzschutzVorhanden = externerBlitzschutzVorhanden,
      assemblyStatus = assemblyStatus,
    )
  }

  fun duplicate(): SerializedAssemblyConfiguration {
    return copy(id = AssemblyConfigurationId.random())
  }

  companion object {
    fun getEmpty(assemblyDifficultyId: AssemblyDifficultyId): SerializedAssemblyConfiguration {
      return SerializedAssemblyConfiguration(assemblyDifficultyId = assemblyDifficultyId)
    }
  }

}


fun ResolvedAssemblyConfiguration.unResolve(): SerializedAssemblyConfiguration {
  return SerializedAssemblyConfiguration(
    id = id,
    assemblyDifficultyId = assemblyDifficulty.id,
    numberOfModulesKreuzverbund = numberOfModulesKreuzverbund,
    dachstaenderIsolierung = dachstaenderIsolierung,
    moveSatelliteDish = moveSatelliteDish,
    removeAntenna = removeAntenna,
    externerBlitzschutzVorhanden = externerBlitzschutzVorhanden,
    assemblyStatus = assemblyStatus,
  )
}
