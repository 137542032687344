package components.project.configuration.profitability

import components.form.commentSection
import components.project.configuration.profitability.EarningsTableRow.Companion.allRowAccessRights
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.CompanyCode
import it.neckar.editHistory.PositionEdit
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.sum
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditEarnings
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewAccounting
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.income.IncomePercentage
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.lizergy.model.income.IncomePercentages
import it.neckar.lizergy.model.price.AccountingStatus
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.formatting.dateFormat
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.Companion.toEditableStatus
import kotlinx.html.ThScope
import plannerI18nConfiguration
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUsersAndCompanies

val EarningsOverview: FC<EarningsOverviewProps> = fc("EarningsOverview") { props ->
  val companyResolver = useSelectCompanyResolver()
  val loggedInUser = useRequireLoggedInUser()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val quoteElements = quoteConfiguration.quoteElements
  val incomePercentages = props::incomePercentages.safeGet()

  val earningsDistribution = quoteConfiguration.earningsDistribution
  val editableStatus = project.isProjectEditable()

  val earningElements: List<EarningsTableRow> = incomePercentages.mapIndexed { index, incomePercentage ->
    val resolvedCompanyEarningsEntry = earningsDistribution.all[index]
    val profit = resolvedCompanyEarningsEntry.manualEarnings ?: IncomePercentageCategory.entries.map { category ->
      val profitBase = quoteConfiguration.manualQuoteElements.manualSumsForTags[category]?.currentValue ?: quoteElements.netPricesForVats(LizergyCalculationCategories.Query.Profits.get(category)).total
      profitBase.times(incomePercentage.getPercentage(category))
    }.sum()

    EarningsTableRow(
      label = incomePercentage.description,
      calculatedProfit = profit,
      manualProfit = resolvedCompanyEarningsEntry.manualEarningsHistory.currentEdit,
      accessRight = allRowAccessRights[index]
    )
  }

  val softwareIncomePercentage: IncomePercentage = if (earningsDistribution.includesPartnerCompanies) IncomePercentages.softwareWithPartner else IncomePercentages.softwareWithoutPartner

  val neckarITEarnings = earningsDistribution.neckarITAccountingStatus.manualEarnings ?: IncomePercentageCategory.entries.map { category ->
    val profitBase = quoteConfiguration.manualQuoteElements.manualSumsForTags[category]?.currentValue ?: quoteElements.netPricesForVats(LizergyCalculationCategories.Query.Profits.get(category)).total
    profitBase.times(softwareIncomePercentage.getPercentage(category))
  }.sum()

  val tippgeberCompany = useState(earningsDistribution.tippgeber.company)
  val tippgeberAccounting = useState(earningsDistribution.tippgeber.accountingStatus)
  val vertriebProjekterfassungCompany = useState(earningsDistribution.vertriebProjekterfassung.company)
  val vertriebProjekterfassungAccounting = useState(earningsDistribution.vertriebProjekterfassung.accountingStatus)
  val vertriebAngebotsvorstellungCompany = useState(earningsDistribution.vertriebAngebotsvorstellung.company)
  val vertriebAngebotsvorstellungAccounting = useState(earningsDistribution.vertriebAngebotsvorstellung.accountingStatus)
  val technischePlanungDachCompany = useState(earningsDistribution.technischePlanungDach.company)
  val technischePlanungDachAccounting = useState(earningsDistribution.technischePlanungDach.accountingStatus)
  val technischePlanungElektrikCompany = useState(earningsDistribution.technischePlanungElektrik.company)
  val technischePlanungElektrikAccounting = useState(earningsDistribution.technischePlanungElektrik.accountingStatus)
  val montageDachCompany = useState(earningsDistribution.montageDach.company)
  val montageDachAccounting = useState(earningsDistribution.montageDach.accountingStatus)
  val montageGeruestCompany = useState(earningsDistribution.montageGeruest.company)
  val montageGeruestAccounting = useState(earningsDistribution.montageGeruest.accountingStatus)
  val elektroInstallationCompany = useState(earningsDistribution.elektroInstallation.company)
  val elektroInstallationAccounting = useState(earningsDistribution.elektroInstallation.accountingStatus)
  val netzvoranfrageCompany = useState(earningsDistribution.netzvoranfrage.company)
  val netzvoranfrageAccounting = useState(earningsDistribution.netzvoranfrage.accountingStatus)
  val neckarITAccounting = useState(earningsDistribution.neckarITAccountingStatus.accountingStatus)


  useMemo(tippgeberCompany.value) {
    if (tippgeberCompany.value == earningsDistribution.tippgeber.company) return@useMemo
    tippgeberAccounting.setter(AccountingStatus.Pending)
  }
  useMemo(vertriebProjekterfassungCompany.value) {
    if (vertriebProjekterfassungCompany.value == earningsDistribution.vertriebProjekterfassung.company) return@useMemo
    vertriebProjekterfassungAccounting.setter(AccountingStatus.Pending)
  }
  useMemo(vertriebAngebotsvorstellungCompany.value) {
    if (vertriebAngebotsvorstellungCompany.value == earningsDistribution.vertriebAngebotsvorstellung.company) return@useMemo
    vertriebAngebotsvorstellungAccounting.setter(AccountingStatus.Pending)
  }
  useMemo(technischePlanungDachCompany.value) {
    if (technischePlanungDachCompany.value == earningsDistribution.technischePlanungDach.company) return@useMemo
    technischePlanungDachAccounting.setter(AccountingStatus.Pending)
  }
  useMemo(technischePlanungElektrikCompany.value) {
    if (technischePlanungElektrikCompany.value == earningsDistribution.technischePlanungElektrik.company) return@useMemo
    technischePlanungElektrikAccounting.setter(AccountingStatus.Pending)
  }
  useMemo(montageDachCompany.value) {
    if (montageDachCompany.value == earningsDistribution.montageDach.company) return@useMemo
    montageDachAccounting.setter(AccountingStatus.Pending)
  }
  useMemo(montageGeruestCompany.value) {
    if (montageGeruestCompany.value == earningsDistribution.montageGeruest.company) return@useMemo
    montageGeruestAccounting.setter(AccountingStatus.Pending)
  }
  useMemo(elektroInstallationCompany.value) {
    if (elektroInstallationCompany.value == earningsDistribution.elektroInstallation.company) return@useMemo
    elektroInstallationAccounting.setter(AccountingStatus.Pending)
  }
  useMemo(netzvoranfrageCompany.value) {
    if (netzvoranfrageCompany.value == earningsDistribution.netzvoranfrage.company) return@useMemo
    netzvoranfrageAccounting.setter(AccountingStatus.Pending)
  }


  val tippgeberToSave = useMemo(tippgeberCompany.value, tippgeberAccounting.value) {
    earningsDistribution.tippgeber.copy(
      companyProfile = companyResolver[tippgeberCompany.value].companyProfile,
      accountingStatusHistory = earningsDistribution.tippgeber.accountingStatusHistory.with(tippgeberAccounting.value, loggedInUser.loginName),
    )
  }
  val vertriebProjekterfassungToSave = useMemo(vertriebProjekterfassungCompany.value, vertriebProjekterfassungAccounting.value) {
    earningsDistribution.vertriebProjekterfassung.copy(
      companyProfile = companyResolver[vertriebProjekterfassungCompany.value].companyProfile,
      accountingStatusHistory = earningsDistribution.vertriebProjekterfassung.accountingStatusHistory.with(vertriebProjekterfassungAccounting.value, loggedInUser.loginName)
    )
  }
  val vertriebAngebotsvorstellungToSave = useMemo(vertriebAngebotsvorstellungCompany.value, vertriebAngebotsvorstellungAccounting.value) {
    earningsDistribution.vertriebAngebotsvorstellung.copy(
      companyProfile = companyResolver[vertriebAngebotsvorstellungCompany.value].companyProfile,
      accountingStatusHistory = earningsDistribution.vertriebAngebotsvorstellung.accountingStatusHistory.with(vertriebAngebotsvorstellungAccounting.value, loggedInUser.loginName)
    )
  }
  val technischePlanungDachToSave = useMemo(technischePlanungDachCompany.value, technischePlanungDachAccounting.value) {
    earningsDistribution.technischePlanungDach.copy(
      companyProfile = companyResolver[technischePlanungDachCompany.value].companyProfile,
      accountingStatusHistory = earningsDistribution.technischePlanungDach.accountingStatusHistory.with(technischePlanungDachAccounting.value, loggedInUser.loginName),
    )
  }
  val technischePlanungElektrikToSave = useMemo(technischePlanungElektrikCompany.value, technischePlanungElektrikAccounting.value) {
    earningsDistribution.technischePlanungElektrik.copy(
      companyProfile = companyResolver[technischePlanungElektrikCompany.value].companyProfile,
      accountingStatusHistory = earningsDistribution.technischePlanungElektrik.accountingStatusHistory.with(technischePlanungElektrikAccounting.value, loggedInUser.loginName)
    )
  }
  val montageDachToSave = useMemo(montageDachCompany.value, montageDachAccounting.value) {
    earningsDistribution.montageDach.copy(
      companyProfile = companyResolver[montageDachCompany.value].companyProfile,
      accountingStatusHistory = earningsDistribution.montageDach.accountingStatusHistory.with(montageDachAccounting.value, loggedInUser.loginName),
    )
  }
  val montageGeruestToSave = useMemo(montageGeruestCompany.value, montageGeruestAccounting.value) {
    earningsDistribution.montageGeruest.copy(
      companyProfile = companyResolver[montageGeruestCompany.value].companyProfile,
      accountingStatusHistory = earningsDistribution.montageGeruest.accountingStatusHistory.with(montageGeruestAccounting.value, loggedInUser.loginName),
    )
  }
  val elektroInstallationToSave = useMemo(elektroInstallationCompany.value, elektroInstallationAccounting.value) {
    earningsDistribution.elektroInstallation.copy(
      companyProfile = companyResolver[elektroInstallationCompany.value].companyProfile,
      accountingStatusHistory = earningsDistribution.elektroInstallation.accountingStatusHistory.with(elektroInstallationAccounting.value, loggedInUser.loginName),
    )
  }
  val netzvoranfrageToSave = useMemo(netzvoranfrageCompany.value, netzvoranfrageAccounting.value) {
    earningsDistribution.netzvoranfrage.copy(
      companyProfile = companyResolver[netzvoranfrageCompany.value].companyProfile,
      accountingStatusHistory = earningsDistribution.netzvoranfrage.accountingStatusHistory.with(netzvoranfrageAccounting.value, loggedInUser.loginName),
    )
  }
  val neckarITAccountingToSave = useMemo(neckarITAccounting.value) {
    earningsDistribution.neckarITAccountingStatus.copy(accountingStatusHistory = earningsDistribution.neckarITAccountingStatus.accountingStatusHistory.with(neckarITAccounting.value, loggedInUser.loginName))
  }

  useMemo(
    tippgeberToSave,
    vertriebProjekterfassungToSave,
    vertriebAngebotsvorstellungToSave,
    technischePlanungDachToSave,
    technischePlanungElektrikToSave,
    montageDachToSave,
    montageGeruestToSave,
    elektroInstallationToSave,
    netzvoranfrageToSave,
    neckarITAccountingToSave,
  ) {
    if (
      tippgeberToSave == earningsDistribution.tippgeber &&
      vertriebProjekterfassungToSave == earningsDistribution.vertriebProjekterfassung &&
      vertriebAngebotsvorstellungToSave == earningsDistribution.vertriebAngebotsvorstellung &&
      technischePlanungDachToSave == earningsDistribution.technischePlanungDach &&
      technischePlanungElektrikToSave == earningsDistribution.technischePlanungElektrik &&
      montageDachToSave == earningsDistribution.montageDach &&
      montageGeruestToSave == earningsDistribution.montageGeruest &&
      elektroInstallationToSave == earningsDistribution.elektroInstallation &&
      netzvoranfrageToSave == earningsDistribution.netzvoranfrage &&
      neckarITAccountingToSave == earningsDistribution.neckarITAccountingStatus
    ) {
      return@useMemo
    }

    UiActions.saveQuoteConfiguration(
      project = project,
      quoteConfiguration.copy(
        earningsDistribution = earningsDistribution.copy(
          tippgeber = tippgeberToSave,
          vertriebProjekterfassung = vertriebProjekterfassungToSave,
          vertriebAngebotsvorstellung = vertriebAngebotsvorstellungToSave,
          technischePlanungDach = technischePlanungDachToSave,
          technischePlanungElektrik = technischePlanungElektrikToSave,
          montageDach = montageDachToSave,
          montageGeruest = montageGeruestToSave,
          elektroInstallation = elektroInstallationToSave,
          netzvoranfrage = netzvoranfrageToSave,
          neckarITAccountingStatus = neckarITAccountingToSave,
        ),
      )
    )
  }


  EarningsTable {
    attrs {
      this.earningsTableRows = earningElements
      this.neckarITEarnings = neckarITEarnings
      this.tippgeberCompany = tippgeberCompany
      this.tippgeberAccounting = tippgeberAccounting
      this.vertriebProjekterfassungCompany = vertriebProjekterfassungCompany
      this.vertriebProjekterfassungAccounting = vertriebProjekterfassungAccounting
      this.vertriebAngebotsvorstellungCompany = vertriebAngebotsvorstellungCompany
      this.vertriebAngebotsvorstellungAccounting = vertriebAngebotsvorstellungAccounting
      this.technischePlanungDachCompany = technischePlanungDachCompany
      this.technischePlanungDachAccounting = technischePlanungDachAccounting
      this.technischePlanungElektrikCompany = technischePlanungElektrikCompany
      this.technischePlanungElektrikAccounting = technischePlanungElektrikAccounting
      this.montageDachCompany = montageDachCompany
      this.montageDachAccounting = montageDachAccounting
      this.montageGeruestCompany = montageGeruestCompany
      this.montageGeruestAccounting = montageGeruestAccounting
      this.elektroInstallationCompany = elektroInstallationCompany
      this.elektroInstallationAccounting = elektroInstallationAccounting
      this.netzvoranfrageCompany = netzvoranfrageCompany
      this.netzvoranfrageAccounting = netzvoranfrageAccounting
      this.neckarITAccounting = neckarITAccounting
      this.forMainCompany = project.sellingCompany
      this.editableStatus = editableStatus
    }
  }

  commentSection(earningsDistribution)
}

/**
 * Renders a table that displays the profit for each of the roles
 */
val EarningsTable: FC<EarningsTableProps> = fc("EarningsTable") { props ->
  // Get the logged-in user to check access rights
  val loggedInUser = useRequireLoggedInUser()

  // Each entry represents the income percentages for a single role
  val earningsTableRows = props::earningsTableRows.safeGet()
  val neckarITEarnings = props::neckarITEarnings.safeGet()

  val tippgeberCompany = props::tippgeberCompany.getNotNull()
  val tippgeberAccounting = props::tippgeberAccounting.getNotNull()
  val vertriebProjekterfassungCompany = props::vertriebProjekterfassungCompany.getNotNull()
  val vertriebProjekterfassungAccounting = props::vertriebProjekterfassungAccounting.getNotNull()
  val vertriebAngebotsvorstellungCompany = props::vertriebAngebotsvorstellungCompany.getNotNull()
  val vertriebAngebotsvorstellungAccounting = props::vertriebAngebotsvorstellungAccounting.getNotNull()
  val technischePlanungDachCompany = props::technischePlanungDachCompany.getNotNull()
  val technischePlanungDachAccounting = props::technischePlanungDachAccounting.getNotNull()
  val technischePlanungElektrikCompany = props::technischePlanungElektrikCompany.getNotNull()
  val technischePlanungElektrikAccounting = props::technischePlanungElektrikAccounting.getNotNull()
  val montageDachCompany = props::montageDachCompany.getNotNull()
  val montageDachAccounting = props::montageDachAccounting.getNotNull()
  val montageGeruestCompany = props::montageGeruestCompany.getNotNull()
  val montageGeruestAccounting = props::montageGeruestAccounting.getNotNull()
  val elektroInstallationCompany = props::elektroInstallationCompany.getNotNull()
  val elektroInstallationAccounting = props::elektroInstallationAccounting.getNotNull()
  val netzvoranfrageCompany = props::netzvoranfrageCompany.getNotNull()
  val netzvoranfrageAccounting = props::netzvoranfrageAccounting.getNotNull()
  val neckarITAccounting = props::neckarITAccounting.getNotNull()

  val forMainCompany = props::forMainCompany.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  fun companyStateInstanceForIndex(index: Int): StateInstance<CompanyCode> {
    return when (index) {
      0 -> tippgeberCompany
      1 -> vertriebProjekterfassungCompany
      2 -> vertriebAngebotsvorstellungCompany
      3 -> technischePlanungDachCompany
      4 -> technischePlanungElektrikCompany
      5 -> montageDachCompany
      6 -> montageGeruestCompany
      7 -> elektroInstallationCompany
      8 -> netzvoranfrageCompany
      else -> throw IllegalArgumentException("No company state instance for index $index")
    }
  }

  fun accountingStateInstanceForIndex(index: Int): StateInstance<AccountingStatus> {
    return when (index) {
      0 -> tippgeberAccounting
      1 -> vertriebProjekterfassungAccounting
      2 -> vertriebAngebotsvorstellungAccounting
      3 -> technischePlanungDachAccounting
      4 -> technischePlanungElektrikAccounting
      5 -> montageDachAccounting
      6 -> montageGeruestAccounting
      7 -> elektroInstallationAccounting
      8 -> netzvoranfrageAccounting
      else -> throw IllegalArgumentException("No accounting state instance for index $index")
    }
  }


  table("table table-responsive") {
    thead {
      tr {
        th(ThScope.col, "text-start") {
          +"Rolle"
        }
        th(ThScope.col, "text-end") {
          +"Verdienst"
        }
        if (loggedInUser.accessRights.canAccess(ViewAccounting)) {
          th(ThScope.col, "text-start") {
            +"Abgerechnet"
          }
        }
      }
    }
    tbody {
      /**
       * if the user cannot access the role of the earning
       * element don't display the line
       */

      earningsTableRows.fastForEachIndexed { index, it ->
        if (loggedInUser.accessRights.canAccess(it.accessRight)) {
          EarningsTableLine {
            attrs {
              this.responsibleCompanyState = companyStateInstanceForIndex(index)
              this.accountingStatusState = accountingStateInstanceForIndex(index)
              this.label = it.label
              this.calculatedProfit = it.calculatedProfit
              this.manualProfit = it.manualProfit
              this.forMainCompany = forMainCompany
              this.editableStatus = editableStatus
            }
          }
        }
      }

      if (loggedInUser.accessRights.canAccess(ViewAccounting) && neckarITEarnings != null) {
        tr {

          td("text-start") {
            +"Neckar IT"
          }

          td("text-end") {
            +neckarITEarnings.format()
          }

          td("text-start") {
            checkbox(
              value = neckarITAccounting.value == AccountingStatus.Accounted,
              onChange = { neckarITAccounting.setter(if (it) AccountingStatus.Accounted else AccountingStatus.Pending) },
              fieldName = "neckarITAccounting",
              title = "Neckar IT abgerechnet",
              editableStatus = editableStatus,
            )
          }

        }
      }

    }

  }

}

/**
 * Single row of the Table for one Role
 */
val EarningsTableLine: FC<EarningsTableLineProps> = fc("EarningsTableLine") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val loggedInCompany = useRequireCompanyForLoggedInUser()
  val userAndCompanies = useSelectUsersAndCompanies()

  val responsibleCompanyState = props::responsibleCompanyState.getNotNull()
  val accountingStatusState = props::accountingStatusState.getNotNull()
  val checkboxLabel = props::label.safeGet()
  val calculatedProfit = props::calculatedProfit.safeGet()
  val manualProfit = props::manualProfit.safeGet()
  val forMainCompany = props::forMainCompany.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val allCompanies = userAndCompanies.activeCompanies(loggedInUser)
  val multipleCompaniesLoaded = allCompanies.size > 1

  val allCompaniesByCode = allCompanies.associateBy { it.companyCode }

  val canEdit = loggedInUser.accessRights.canAccess(EditEarnings).toEditableStatus().and(editableStatus)


  tr {

    td("text-start") {
      if (multipleCompaniesLoaded) {
        floatingSelect(
          valueAndSetter = responsibleCompanyState,
          idProvider = { it.value },
          formatter = { allCompaniesByCode[it]?.name ?: it.value },
          availableOptions = allCompanies.map { it.companyCode },
          fieldName = "companyFilter$checkboxLabel",
          title = checkboxLabel,
          editableStatus = canEdit,
        )
      } else {
        checkbox(
          value = responsibleCompanyState.value == loggedInCompany.companyCode,
          onChange = { responsibleCompanyState.setter(if (it) loggedInCompany.companyCode else forMainCompany) },
          fieldName = checkboxLabel,
          title = checkboxLabel,
          editableStatus = canEdit.and(accountingStatusState.value == AccountingStatus.Pending),
        )
      }
    }

    td("text-end") {
      if (manualProfit.value != null) {
        span("badge bg-warning me-2") {
          +"Bearbeitet von ${manualProfit.loginName?.let { userAndCompanies[it].editorName } ?: "-"} am ${dateFormat.format(manualProfit.editTime, plannerI18nConfiguration)}"
        }
      }
      +(manualProfit.value ?: calculatedProfit).format()
    }

    if (loggedInUser.accessRights.canAccess(ViewAccounting)) {
      td("text-start") {
        if (responsibleCompanyState.value != forMainCompany) {
          checkbox(
            value = accountingStatusState.value == AccountingStatus.Accounted,
            onChange = { accountingStatusState.setter(if (it) AccountingStatus.Accounted else AccountingStatus.Pending) },
            fieldName = "${checkboxLabel}Accounting",
            title = "$checkboxLabel abgerechnet",
            editableStatus = canEdit,
          )
        }
      }
    }

  }

}

fun <K> MutableMap<K, Money>.putAndAdd(key: K?, newValue: Money) {
  if (key == null) return
  val oldTotal = get(key)
  val newTotal = oldTotal?.let { it + newValue } ?: newValue
  put(key, newTotal)
}

external interface EarningsOverviewProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
  var incomePercentages: List<IncomePercentage>
}

external interface EarningsTableProps : Props {
  var earningsTableRows: List<EarningsTableRow>
  var neckarITEarnings: Money?
  var tippgeberCompany: StateInstance<CompanyCode>
  var tippgeberAccounting: StateInstance<AccountingStatus>
  var vertriebProjekterfassungCompany: StateInstance<CompanyCode>
  var vertriebProjekterfassungAccounting: StateInstance<AccountingStatus>
  var vertriebAngebotsvorstellungCompany: StateInstance<CompanyCode>
  var vertriebAngebotsvorstellungAccounting: StateInstance<AccountingStatus>
  var technischePlanungDachCompany: StateInstance<CompanyCode>
  var technischePlanungDachAccounting: StateInstance<AccountingStatus>
  var technischePlanungElektrikCompany: StateInstance<CompanyCode>
  var technischePlanungElektrikAccounting: StateInstance<AccountingStatus>
  var montageDachCompany: StateInstance<CompanyCode>
  var montageDachAccounting: StateInstance<AccountingStatus>
  var montageGeruestCompany: StateInstance<CompanyCode>
  var montageGeruestAccounting: StateInstance<AccountingStatus>
  var elektroInstallationCompany: StateInstance<CompanyCode>
  var elektroInstallationAccounting: StateInstance<AccountingStatus>
  var netzvoranfrageCompany: StateInstance<CompanyCode>
  var netzvoranfrageAccounting: StateInstance<AccountingStatus>
  var neckarITAccounting: StateInstance<AccountingStatus>
  var forMainCompany: CompanyCode
  var editableStatus: EditableStatus
}

external interface EarningsTableLineProps : Props {
  var responsibleCompanyState: StateInstance<CompanyCode>
  var accountingStatusState: StateInstance<AccountingStatus>
  var label: String
  var calculatedProfit: Money
  var manualProfit: PositionEdit<Money?>
  var forMainCompany: CompanyCode
  var editableStatus: EditableStatus
}
