package it.neckar.financial.quote.flat

import it.neckar.financial.quote.CalculationRelevanceQuery
import it.neckar.financial.quote.QuoteElement
import it.neckar.financial.quote.QuoteElements
import it.neckar.financial.quote.QuoteItem
import it.neckar.financial.quote.QuoteSection
import it.neckar.open.collections.fastForEachIndexed

/**
 * Represents a quote as simple list.
 * Should not be instantiated directly, instead create a [QuoteElements] and convert it.
 */
data class FlatQuoteElements(
  /**
   * The quote lines. Each line represents a section or item
   */
  val lines: List<FlatQuoteLine>,
) {
  /**
   * Dumps the flat quote to a string
   */
  fun dump(): String {
    return lines.joinToString("\n") {
      it.dump()
    }
  }
}

/**
 * Converts this quote to a flat quote - with a single list
 */
fun QuoteElements.flat(
  /**
   * Which items / sections are visible
   */
  visibilityQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.onlyPublicSkipZero,
  /**
   * Which items / sections are used to calculate the sum
   */
  sumQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.defaultForSum,

  /**
   * How to calculate the sum - for optional elements.
   */
  sumIncludingOptionalQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.allIncludingOptional,
): FlatQuoteElements {
  val lines: MutableList<FlatQuoteLine> = mutableListOf()

  /**
   * Recursive function that converts the children
   */
  fun List<QuoteElement>.convertChildren(indentation: FlatQuoteIndentation): List<FlatQuoteLine> {
    val myLines = mutableListOf<FlatQuoteLine>()

    filter {
      visibilityQuery.matches(it)
    }.fastForEachIndexed { index, child ->
      //Add a line for this child itself
      val childIndentation = indentation.child(index)
      myLines.add(child.toFlat(childIndentation, sumQuery, sumIncludingOptionalQuery))

      if (child is QuoteSection) {
        //Add the children of the child
        myLines += child.children.convertChildren(childIndentation)
      }
    }

    return myLines
  }

  lines.addAll(this.sections.convertChildren(FlatQuoteIndentation.root))
  return FlatQuoteElements(lines)
}

/**
 * Converts a single quote item to a flat quote line
 */
private fun QuoteElement.toFlat(indentation: FlatQuoteIndentation, sumQuery: CalculationRelevanceQuery, sumIncludingOptionalQuery: CalculationRelevanceQuery): FlatQuoteLine {
  return FlatQuoteLine(
    indentation = indentation,
    headline = headline,
    details = details,
    amount = amount,
    sum = sums(sumQuery).net,
    sumIncludingOptional = sums(sumIncludingOptionalQuery).net,
    optionality = optionality,
    visibility = visibility,
    relevance = relevanceOrNull(),
    valueAddedTax = when (this) {
      is QuoteItem -> listOf(valueAddedTax)
      is QuoteSection -> sums(sumQuery).vats
    },
    isSumPosition = when (this) {
      is QuoteItem -> false
      is QuoteSection -> true
    },
  )
}
