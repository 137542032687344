package it.neckar.common.redux

/**
 * The type of change
 */
enum class ChangeType {
  /**
   * A minor change - e.g. adding/removing a single char to a string
   */
  MinorChange,

  /**
   * A major change - e.g. adding/removing an element to/from a list
   *
   * Major changes will create a major "snapshot" in the undo history
   */
  MajorChange,

  /**
   * A change that resets the history - e.g. page navigation or any other change that should not be undoable
   */
  HistoryReset,
}
