@file:UseSerializers(UuidSerializer::class)

package it.neckar.customer

import com.benasher44.uuid.Uuid
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.ifBlank
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers


/**
 * Information about the customer
 */
@Serializable
data class Customer(
  val id: CustomerId,

  val firstName: String,
  val lastName: String,

  /**
   * The address of the customer. *Not* the address of the location of the pv system
   */
  val address: Address = Address.empty,

  val phone: String? = null,
  val cellphone: String? = null,
  val email: String? = null,

  /**
   * The customer's company (Optional)
   */
  val company: String? = null,

  val customerWish: String? = null,

  ) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  /**
   * Returns the complete name
   */
  val completeName: String
    get() = "$firstName $lastName"

  val hasPlausibleName: Boolean
    get() = (firstName.isNotBlank() && lastName.isNotBlank()) || hasPlausibleCompanyName

  /**
   * Returns true if the customer is plausible (contains values for all relevant properties)
   */
  val isPlausible: Boolean
    get() = hasPlausibleName && address.isPlausible

  val hasPlausibleCompanyName: Boolean
    get() = company?.isNotBlank() == true

  @Suppress("UNUSED_PARAMETER")
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    return "$completeName, ${address.singleLine()}"
  }

  /**
   * Returns the company name - if there is one.
   * Else returns the complete name of the customer
   */
  fun companyOrCompleteName(): String {
    return company.ifBlank {
      completeName
    }
  }

  /**
   * Returns the company - if there is one.
   * Else returns the last name of the customer
   */
  fun companyOrLastName(): String {
    return company.ifBlank {
      lastName
    }
  }

  companion object {

    fun demo(uuid: Uuid = randomUuid4()): Customer = Customer(
      id = CustomerId(uuid),
      firstName = "Peter",
      lastName = "Müller",
    )

    fun demoWithAddress(uuid: Uuid = randomUuid4()): Customer = Customer(
      id = CustomerId(uuid),
      firstName = "Lisa",
      lastName = "Müller",
      email = "Lisa@testcompany.de",
      phone = "+49 000 000",
      address = Address.NeckarIT,
      customerWish = "Anfrage PV-Anlage"
    )

    fun empty(uuid: Uuid = randomUuid4()): Customer = Customer(
      id = CustomerId(uuid),
      firstName = "",
      lastName = "",
    )
    fun demoWithRandomAddress(uuid: Uuid = randomUuid4()): Customer = Customer(
      id = CustomerId(uuid),
      firstName = "Peter",
      lastName = "Müller",
      address = Address.randomAddresses.random(),
    )
    fun random(): Customer {
      val randomCustomers = listOf(
        Customer(id = CustomerId.random(), firstName = "Peter", "Mustermann", address = Address.testCompanyAddress),
        Customer(id = CustomerId.random(), firstName = "Lisa", "Musterfrau", address = Address.demo)
      )
      return randomCustomers.random()
    }
  }

  /**
   * An id for a customer
   */
  @Serializable
  data class CustomerId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): CustomerId {
        return CustomerId(randomUuid4())
      }
    }
  }
}
