package components.project.configuration.powerusage

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.configuration.isConfigurationEditable
import components.project.configuration.pricestrend.EditPricesTrend
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.economics.FeedInPrices
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct100
import it.neckar.open.unit.si.kWh
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import kotlin.math.roundToInt

val EditConfigurationPowerUsageFromUrl: FC<Props> = fc("EditConfigurationPowerUsageFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Strom", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    EditConfigurationPowerUsage {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val EditConfigurationPowerUsage: FC<QuoteConfigurationTabProps> = fc("EditConfigurationPowerUsage") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val powerUsageScenarioToSave = useState(quoteConfiguration.powerUsageScenario)
  val manualPowerConsumptionToSave = useState((quoteConfiguration.manualPowerConsumptionDistribution?.selfProducedPercentage?.times(100))?.roundToInt())

  val pricesTrendScenarioToSave = useState(quoteConfiguration.pricesTrendScenario)

  val configurationToSave = useMemo(
    powerUsageScenarioToSave.value,
    manualPowerConsumptionToSave.value,
    pricesTrendScenarioToSave.value,
  ) {
    quoteConfiguration.copy(
      powerUsageScenario = powerUsageScenarioToSave.value,
      manualPowerConsumptionDistribution = manualPowerConsumptionToSave.value?.let {
        ManualPowerConsumptionDistribution(it.toDouble().percent)
      },
      pricesTrendScenario = pricesTrendScenarioToSave.value,
    )
  }


  useMemo(configurationToSave) {
    if (editableStatus == Editable) {
      UiActions.saveQuoteConfiguration(project, configurationToSave)
    }
  }


  div("row my-5") {

    div("mb-5") {
      h3("mt-0 mb-2") {
        +"Bemerkungen Wirtschaftlichkeit"
      }
      project.blueprint.profitabilityAnnotation?.let {
        p { +it }
      }
      commentSection(quoteConfiguration.powerUsageScenario)
    }

    div {

      EditPowerUsage {
        attrs {
          this.powerUsageScenarioToSave = powerUsageScenarioToSave
          this.manualPowerConsumptionToSave = manualPowerConsumptionToSave
          this.quoteConfiguration = quoteConfiguration
          this.editableStatus = editableStatus
        }
      }

      EditPricesTrend {
        attrs {
          this.pricesTrendScenarioToSave = pricesTrendScenarioToSave
          this.fallbackGuaranteedFeedInPrice = FeedInPrices[quoteConfiguration.totalPowerRating].cents
          this.editableStatus = editableStatus
        }
      }

    }

  }

}

val EditPowerUsage: FC<EditPowerUsageProps> = fc("EditPowerUsage") { props ->
  val powerUsageProps = props::powerUsageScenarioToSave.getNotNull()
  val editableStatus = props::editableStatus.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val powerUsageScenario = powerUsageProps.value

  val manualPowerConsumptionToSave = props::manualPowerConsumptionToSave.getNotNull()

  @kWh val yearlyPowerConsumptionState = useState(powerUsageScenario.yearlyPowerConsumption.kWh.roundToInt())
  @kWh val waermepumpeConsumptionState = useState(powerUsageScenario.waermepumpeConsumption.kWh.roundToInt())
  @kWh val electricCarUsageState = useState(powerUsageScenario.electricCarUsage.roundToInt())


  useMemo(yearlyPowerConsumptionState.value, waermepumpeConsumptionState.value, electricCarUsageState.value) {
    powerUsageProps.setter(
      powerUsageScenario.copy(
        yearlyPowerConsumption = it.neckar.lizergy.model.configuration.energy.AmountOfEnergy.kWh(yearlyPowerConsumptionState.value.toDouble()),
        waermepumpeConsumption = it.neckar.lizergy.model.configuration.energy.AmountOfEnergy.kWh(waermepumpeConsumptionState.value.toDouble()),
        electricCarUsage = electricCarUsageState.value.toDouble(),
      )
    )
  }


  powerUsageForm(
    quoteConfiguration = quoteConfiguration,
    yearlyPowerConsumptionState = yearlyPowerConsumptionState,
    waermepumpeConsumptionState = waermepumpeConsumptionState,
    electricCarUsageState = electricCarUsageState,
    manualPowerConsumptionState = manualPowerConsumptionToSave,
    editableStatus = editableStatus,
  )

}


external interface EditPowerUsageProps : Props {
  var powerUsageScenarioToSave: StateInstance<PowerUsageScenario>
  var manualPowerConsumptionToSave: StateInstance<@pct100 Int?>
  var quoteConfiguration: QuoteConfiguration?
  var editableStatus: EditableStatus
}
