@file:UseSerializers(UuidSerializer::class)

package it.neckar.comments

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.onlyActive
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class CommentsForComponent(
  val commentsFor: Uuid,
  private val comments: List<Comment> = emptyList(),
) {
  val activeComments: List<Comment>
    get() = comments.onlyActive()

  fun isEmpty(): Boolean {
    return activeComments.isEmpty()
  }

  fun withNewComment(newComment: Comment): CommentsForComponent {
    return copy(comments = comments + newComment)
  }

  fun withUpdatedComment(updatedComment: Comment): CommentsForComponent {
    val updatedUserLoginName = comments.indexOfFirst {
      it.id == updatedComment.id
    }

    if (updatedUserLoginName < 0) return withNewComment(updatedComment)

    val newCommentsForComponent = this.comments.toMutableList()
    newCommentsForComponent[updatedUserLoginName] = updatedComment

    return copy(comments = newCommentsForComponent)
  }
}
