@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.price


import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lifeCycle.only
import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Provides information for a given type (e.g. the price or a percentage)
 */
//With Kotlin 1.5.30 enabling the plugin generated serializer results in an exception when building lizergy/ui
@Serializable
data class InfoForType<T, V>(
  /**
   * The available object
   */
  val available: List<T>,

  /**
   * The map that contains the values
   */
  val values: Map<Uuid, V>,
) where T : HasUuid, T : HasLifeCycle {
  init {
    require(available.size == values.size) {
      "Size mismatch: ${available.size} vs ${values.size}"
    }

    available.fastForEach {
      requireNotNull(values[it.uuid]) {
        "No value found for $it"
      }
    }
  }

  fun forEach(action: (element: T, value: V) -> Unit) {
    available.fastForEach { element ->
      action(element, value(element))
    }
  }

  /**
   * Returns the available elements with the provided (optional) life cycle state.
   * If null is provided for [LifeCycleState], all elements are returned
   */
  fun available(selector:((LifeCycleState)-> Boolean)?): List<T> {
    return available.only(selector)
  }

  /**
   * Returns the value for the given element
   */
  fun value(element: T): V {
    return value(element.uuid)
  }

  operator fun get(element: T): V {
    return value(element)
  }

  /**
   * Returns the value for the element with the given uuid
   */
  fun value(uuid: Uuid): V {
    return values[uuid] ?: throw IllegalArgumentException("No value found for <$uuid>")
  }

  operator fun get(uuid: Uuid): V {
    return value(uuid)
  }

  fun valueOrNull(element: T): V? {
    return valueOrNull(element.uuid)
  }

  fun valueOrNull(uuid: Uuid): V? {
    return values[uuid]
  }

  /**
   * Creates a new info with the given transformation
   * @param VNew the new value that will be saved in the new [InfoForType]
   */
  fun <VNew> map(transformation: (T, V) -> VNew): InfoForType<T, VNew> {
    val original = this

    return InfoForType {
      original.available.fastForEach { element ->
        add(element, transformation(element, value(element)))
      }
    }
  }

  /**
   * Creates a new info merged with another info object
   * @param VOther the (input) value from the other info for type
   * @param VNew the new value that will be saved in the new [InfoForType]
   *
   */
  fun <VOther, VNew> merge(other: InfoForType<T, VOther>, transformation: (T, V, VOther) -> VNew): InfoForType<T, VNew> {
    val original = this

    require(original.available == other.available) {
      "Require same input for this and other. But was different: [${original.available}] -- [${other.available}]"
    }

    return InfoForType {
      original.available.fastForEachIndexed { index, originalElement ->
        val otherElement = other.available[index]

        require(originalElement == otherElement) {
          "Elements must be the same at index $index. $originalElement vs $otherElement"
        }

        add(
          originalElement, transformation(
            originalElement,
            value(originalElement),
            other.value(otherElement)
          )
        )
      }
    }
  }


  companion object {
    /**
     * Creates a new InfoForType object from the given elements map
     */
    operator fun <T, V> invoke(elements: Map<T, V>): InfoForType<T, V> where T : HasUuid, T : HasLifeCycle {
      return InfoForType(
        elements.keys.toList(),
        elements.mapKeys { it.key.uuid }
      )
    }

    /**
     * Creates a new instance using the builder
     */
    operator fun <T, V> invoke(initializer: InfoForTypeBuilder<T, V>.() -> Unit): InfoForType<T, V> where T : HasUuid, T : HasLifeCycle {
      val builder = InfoForTypeBuilder<T, V>()
      builder.initializer()
      return builder.build()
    }

    /**
     * Returns an empty element
     */
    fun <T, V> empty(): InfoForType<T, V> where T : HasUuid, T : HasLifeCycle {
      return InfoForType(emptyList(), emptyMap())
    }
  }
}

class InfoForTypeBuilder<T, V> where T : HasUuid, T : HasLifeCycle {
  val entries: MutableMap<T, V> = mutableMapOf()

  fun add(type: T, value: V) {
    this.entries[type] = value
  }

  fun build(): InfoForType<T, V> {
    val available = entries.keys.toList()
    val value: Map<Uuid, V> = entries.mapKeys { it.key.uuid }

    return InfoForType(available, value)
  }
}

