package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.geometry.Coordinates
import it.neckar.geometry.RightTriangle
import it.neckar.geometry.RightTriangleType
import it.neckar.geometry.Shape
import it.neckar.geometry.Size
import it.neckar.open.observable.ObservableObject
import it.neckar.open.observable.ObservableString
import it.neckar.open.unit.si.mm

/**
 * Represents an area that is not usable
 */
class UnusableArea(
  location: @RoofRelative Coordinates = Coordinates.origin,

  /**
   * The size of the module (in cm)
   */
  size: @mm Size,

  rightTriangleType: RightTriangleType? = null,
) : Resizable {

  val locationProperty: ObservableObject<@RoofRelative Coordinates> = ObservableObject(location)
  override var location: @RoofRelative Coordinates by locationProperty

  val sizeProperty: ObservableObject<Size> = ObservableObject(size)
  override var size: Size by sizeProperty

  val rightTriangleTypeProperty: ObservableObject<RightTriangleType?> = ObservableObject(rightTriangleType)
  var rightTriangleType: RightTriangleType? by rightTriangleTypeProperty

  /**
   * The description of the unusable area
   */
  val descriptionProperty: ObservableString = ObservableString("Unnamed")
  var description: String by descriptionProperty

  override val minimumSize: Size
    get() = Companion.minimumSize

  /**
   * Returns the bounds for this unusable area
   */
  override fun bounds(): @RoofRelative @mm Shape {
    return rightTriangleType?.let { RightTriangle(location, size, it) } ?: super.bounds()
  }

  companion object {
    /**
     * The minimum size of an unusable area
     */
    val minimumSize: Size = Size(200.0, 200.0)
  }
}
