package components.project.blueprint

import components.form.commentSection
import components.project.BlueprintTabProps
import components.project.configuration.powerusage.EditPowerUsage
import components.project.configuration.pricestrend.EditPricesTrend
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.quote.economics.FeedInPrices
import it.neckar.open.kotlin.lang.percent
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser
import kotlin.math.roundToInt

val EditBlueprintProfitabilityFromUrl: FC<Props> = fc("EditBlueprintProfitabilityFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Wirtschaftlichkeitsberechnung - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    EditBlueprintProfitability {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val EditBlueprintProfitability: FC<BlueprintTabProps> = fc("EditBlueprintProfitability") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val blueprint = project.blueprint
  val editableStatus = project.isProjectEditable()

  val powerUsageScenarioToSave = useState(blueprint.powerUsageScenario)
  val manualPowerConsumptionToSave = useState((blueprint.manualPowerConsumptionDistribution?.selfProducedPercentage?.times(100))?.roundToInt())

  val pricesTrendScenarioToSave = useState(blueprint.pricesTrendScenario)

  val okAction = {
    navigate(RouterUrls.blueprint(project).facility)
  }


  useMemo(powerUsageScenarioToSave.value, manualPowerConsumptionToSave.value, pricesTrendScenarioToSave.value) {
    blueprint.copy(
      powerUsageScenario = powerUsageScenarioToSave.value,
      manualPowerConsumptionDistribution = manualPowerConsumptionToSave.value?.let {
        ManualPowerConsumptionDistribution(it.toDouble().percent)
      },
      pricesTrendScenario = pricesTrendScenarioToSave.value,
    ).also {
      UiActions.saveBlueprint(it)
    }
  }


  div("my-4") {
    onEnter { okAction() }

    div("row") {

      div("col-md-6") {
        EditPowerUsage {
          attrs {
            this.powerUsageScenarioToSave = powerUsageScenarioToSave
            this.manualPowerConsumptionToSave = manualPowerConsumptionToSave
            this.quoteConfiguration = null
            this.editableStatus = editableStatus
          }
        }
      }

      div("col-md-6") {
        EditPricesTrend {
          attrs {
            this.pricesTrendScenarioToSave = pricesTrendScenarioToSave
            this.fallbackGuaranteedFeedInPrice = FeedInPrices[blueprint.totalPowerRating].cents
            this.editableStatus = editableStatus
          }
        }
      }

    }

    div("my-5") {
      div("my-5") {
        h3("mb-3") {
          +"Bemerkungen Wirtschaftlichkeit"
        }
        project.blueprint.profitabilityAnnotation?.let {
          p { +it }
        }
        commentSection(blueprint.powerUsageScenario)
      }
    }

    wizardFormButtons(
      backwardAction = { navigate(RouterUrls.blueprint(project).moduleLayouts) },
      forwardAction = { okAction() },
    )
  }

}
