package components.project.blueprint

import components.form.commentSection
import components.project.BlueprintTabProps
import components.project.configuration.additional.AdditionalPositionsOverview
import components.project.configuration.additional.ExistingFacilitiesOverview
import components.project.configuration.discount.EditDiscount
import components.project.configuration.legalnotice.EditLegalNotice
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct100
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser

val BlueprintAdditionalOverviewFromUrl: FC<Props> = fc("BlueprintAdditionalOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Zusätzliches - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    BlueprintAdditionalOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val BlueprintAdditionalOverview: FC<BlueprintTabProps> = fc("BlueprintAdditionalOverview") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val blueprint = project.blueprint

  val editableStatus = project.isProjectEditable()

  val legalNoticeAdditionalLineToSave = useState(blueprint.legalNoticeAdditionalLine.orEmpty())
  val legalNotesToSave = useState(blueprint.legalNotes)

  @pct100 val discountPercentageToSave = useState(blueprint.discountPercentage * 100.0)

  val okAction = {
    navigate(RouterUrls.blueprint(project).finish)
  }


  useMemo(legalNoticeAdditionalLineToSave.value, legalNotesToSave.value, discountPercentageToSave.value) {
    blueprint.copy(
      legalNoticeAdditionalLine = legalNoticeAdditionalLineToSave.value.nullIfBlank(),
      legalNotes = legalNotesToSave.value,
      discountPercentage = discountPercentageToSave.value.percent.coerceAtLeast(0.0),
    ).also {
      UiActions.saveBlueprint(it)
    }
  }


  div("my-5") {
    onEnter { okAction() }

    div("my-4") {
      BlueprintAdditionalPositions {
        attrs {
          this.project = project
        }
      }
    }

    div("my-5") {
      EditLegalNotice {
        attrs {
          this.legalNoticeAdditionalLineToSave = legalNoticeAdditionalLineToSave
          this.legalNotesToSave = legalNotesToSave
          this.scaffoldingSupplied = blueprint.scaffoldingSupplied
          this.editableStatus = editableStatus
        }
      }
    }

    div("row my-5") {
      EditDiscount {
        attrs {
          this.discountPercentageToSave = discountPercentageToSave
          this.subTotal = null
          this.editableStatus = editableStatus
        }
      }
    }

    div("my-5") {
      h3("mb-3") {
        +"Bemerkungen Zusätzliches"
      }
      project.blueprint.additionalAnnotation?.let {
        p { +it }
      }
      commentSection(blueprint.additionalPositions)
    }

    wizardFormButtons(
      backwardAction = { navigate(RouterUrls.blueprint(project).wallBox) },
      forwardAction = { okAction() },
    )
  }

}

val BlueprintAdditionalPositions: FC<BlueprintTabProps> = fc("BlueprintAdditionalPositions") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val blueprint = project::blueprint.safeGet()

  val editableStatus = project.isProjectEditable()

  val existingFacilityConfigurationToSave = useState(blueprint.existingFacilitiesConfiguration)

  val blueprintToSave = useMemo(
    existingFacilityConfigurationToSave.value,
  ) {
    blueprint.copy(
      existingFacilitiesConfiguration = existingFacilityConfigurationToSave.value,
    )
  }


  useMemo(blueprint.existingFacilitiesConfiguration) {
    existingFacilityConfigurationToSave.setter(blueprint.existingFacilitiesConfiguration)
  }

  useMemo(blueprintToSave) {
    UiActions.saveBlueprint(blueprintToSave)
  }


  AdditionalPositionsOverview {
    attrs {
      this.additionalPositions = blueprint.additionalPositions
      this.editableStatus = editableStatus

      this.addAction = {
        UiActions.addAdditionalPositionToBlueprint(blueprint).also { additionalPosition ->
          navigate(RouterUrls.blueprint(project).additionalPosition(additionalPosition.id))
        }
      }
      this.editAction = { additionalPosition ->
        navigate(RouterUrls.blueprint(project).additionalPosition(additionalPosition.id))
      }
      this.deleteAction = { additionalPosition ->
        UiActions.removeAdditionalPositionFromBlueprint(blueprint, additionalPosition)
      }
    }
  }

  ExistingFacilitiesOverview {
    attrs {
      this.existingFacilitiesConfigurationToSave = existingFacilityConfigurationToSave
      this.editableStatus = editableStatus

      this.addPVAction = {
        UiActions.addExistingPVFacilityToBlueprint(blueprint).also { existingFacility ->
          navigate(RouterUrls.blueprint(project).existingPVFacility(existingFacility.uuid))
        }
      }
      this.addBHKWAction = {
        UiActions.addExistingBHKWFacilityToBlueprint(blueprint).also { existingFacility ->
          navigate(RouterUrls.blueprint(project).existingBHKWFacility(existingFacility.uuid))
        }
      }
      this.editPVAction = { existingFacility ->
        navigate(RouterUrls.blueprint(project).existingPVFacility(existingFacility.uuid))
      }
      this.editBHKWAction = { existingFacility ->
        navigate(RouterUrls.blueprint(project).existingBHKWFacility(existingFacility.uuid))
      }
      this.deletePVAction = { existingFacility ->
        UiActions.removeExistingPVFacilityFromBlueprint(blueprint, existingFacility)
      }
      this.deleteBHKWAction = { existingFacility ->
        UiActions.removeExistingBHKWFacilityFromBlueprint(blueprint, existingFacility)
      }

    }
  }

}
