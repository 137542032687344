package components.project.configuration

import components.form.commentSection
import components.project.OLDsimpleProcessStateLabel
import components.project.configuration.location.locationPreview
import components.project.configuration.profitability.profitabilityPercentage
import components.project.inquiryInformationPreview
import components.project.isProjectEditable
import components.project.processStatePillWithDateAndEditor
import components.project.validationTable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.company.user.AccessRights
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry.PresentationProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry.QuoteConfirmationProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry.QuoteOfferProcessStates
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.formatting.format
import it.neckar.open.kotlin.lang.ifNull
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import kotlinx.html.title
import react.*
import react.dom.*
import react.router.*
import router.PathConstants
import router.RouterUrls
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser

/**
 * Shows the overview for *one* quote
 */
val ConfigurationOverviewFromUrl: FC<Props> = fc("ConfigurationFromUrlOverview") {
  val configurationId = useConfigurationIdFromUrl()
  val project = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { project?.getDisplayName(it) }, project?.get(configurationId)?.configurationName)

  busyIfNull(project) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]

    ConfigurationOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
      }
    }
  }
}

val ConfigurationOverview: FC<ConfigurationOverviewProps> = fc("ConfigurationOverview") { props ->
  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
  val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
  val moduleLayouts = quoteConfiguration.moduleLayouts
  val editableStatus = project.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())


  div("row") {

    div("my-3") {
      busyIfNull(processStatesForConfiguration) { loadedProcessStates ->
        val processStateEntry = loadedProcessStates.current()
        if (processStateEntry != null) {
          processStatePillWithDateAndEditor(processStateEntry)
        } else {
          this@div.OLDsimpleProcessStateLabel(quoteConfiguration.processState?.currentEdit, quoteConfiguration.currentQuoteSnapshot?.quote?.quoteNumber)
        }
      }
    }

    div("col-12") {
      h1 {
        attrs {
          this.title = quoteConfiguration.uuid.toString()
        }

        +quoteConfiguration.configurationName

        editButton {
          navigate(RouterUrls.project(project).configuration(quoteConfiguration).description)
        }
      }
    }

    div("col-12 my-3") {
      p {
        +quoteConfiguration.description.ifNull("-")
      }
    }

    div("col-sm-6") {
      profitabilityPercentage(quoteConfiguration)
    }

  }

  div("row my-3") {

    div("col-md-6") {
      h2 {
        +"Standort"

        editButton {
          navigate(RouterUrls.project(project).configuration(quoteConfiguration).location)
        }
      }

      locationPreview(quoteConfiguration.location)

      p("mt-3") {
        if (quoteConfiguration.shippingDistance != null) {
          +"Entfernung: ${(quoteConfiguration.shippingDistance ?: return@p).format()} km"
        } else {
          +"Keine Entfernung hinterlegt!"
          attrs { addClass("invalid") }
        }
      }
    }

    div("col-md-6") {
      h2 {
        +"Anlagenbetreiber"

        editButton {
          navigate(RouterUrls.project(project).configuration(quoteConfiguration).electricity)
        }
      }

      inquiryInformationPreview(quoteConfiguration)
    }

  }

  div("mt-5") {
    tabButtonsPane {

      tabButton(NavigateUrl.empty, "Phase")

      tabButton(NavigateUrl(PathConstants.moduleLayouts), "Modulflächen")

      if (moduleLayouts.validElements.isNotEmpty()) {
        tabButton(RouterUrls.project(project).configuration(quoteConfiguration).moduleStrings(moduleLayouts.first()), "Stringplanung")
      }

      tabButton(NavigateUrl(PathConstants.facility), "Anlage & Montage")

      tabButton(NavigateUrl(PathConstants.powerUsage), "Strom")

      tabButton(NavigateUrl(PathConstants.electricityWorkEffort), "Zählerschrankarbeiten")

      tabButton(NavigateUrl(PathConstants.wallbox), "E-Auto")

      tabButton(NavigateUrl(PathConstants.additional), "Zusätzliches")

      if (loggedInUser.accessRights.canAccess(AccessRights.AccessRight.ViewQuoteDump)) {
        tabButton(NavigateUrl(PathConstants.quotePreview), "Rechnungsvorschau")
      }

      tabButton(NavigateUrl(PathConstants.economics), "Wirtschaftlichkeit")

      if (loggedInUser.accessRights.canAccess(AccessRights.AccessRight.ViewProfits)) {
        tabButton(NavigateUrl(PathConstants.profit), "Deckungsbeitrag")
      }

      if (loggedInUser.accessRights.canAccess(AccessRights.AccessRight.ViewEarnings)) {
        tabButton(NavigateUrl(PathConstants.earnings), "Verdienst")
      }

      tabButton(NavigateUrl(PathConstants.annotations), "Bemerkungen")

    }
  }

  div("mt-5") {
    Outlet {}
  }

  div("my-5") {
    validationTable(quoteConfiguration.validationProblems)
  }

  div("my-3") {
    h3("mb-2") {
      +"Bemerkungen Angebot"
    }
    commentSection(quoteConfiguration)
  }

  div("row my-5") {
    if (editableStatus == EditableStatus.Editable) {
      div("btn-group col my-2") {
        quoteOfferPreviewButton(project, quoteConfiguration)
      }
    } else {
      quoteDownloadButtons(project, quoteConfiguration)
    }
  }

}

fun LizergyProcessStates?.isConfigurationEditable(): EditableStatus {
  return when (this) {
    is PresentationProcessStates, is QuoteOfferProcessStates, QuoteConfirmationProcessStates.Sent, QuoteConfirmationProcessStates.Rejected -> EditableStatus.ReadOnly
    else -> EditableStatus.Editable
  }
}


external interface ConfigurationOverviewProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
}
