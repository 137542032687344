package it.neckar.lizergy.model.project.previews

import kotlinx.serialization.Serializable

@Serializable
enum class ProjectQueryComponent {
  Project,
  Blueprint,
  CurrentConfiguration,
  CurrentQuote,
  OrderSpecialMaterial,
  GridAssessment,
  AssemblyPortfolio,
  ScheduleAssembly,
  AdvanceInvoice,
  PrepareMaterial,
  AssemblyRoof,
  AssemblyBasement,
  SwitchMeterBox,
  StartupOperations,
  FinishingUp,
  FinalAccount,
  Documentation,
  Verification,
}

@Serializable
enum class ProjectQueryForUser {
  AssignedEditor,
  AssignedMaintainer,
}
