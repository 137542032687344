package it.neckar.lizergy.model.location

/**
 * Describes the precision of the location information
 */
enum class LocationPrecision {
  /**
   * Found the exact location at the given address
   */
  Exact,

  /**
   * Estimated result - e.g. no house number found
   */
  Estimated,

  /**
   * The precision is bad - probably not usable
   */
  Bad,
}
