package com.meistercharts.charts.lizergy.roofPlanning

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.annotations.Window
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.paintable.ObjectFit
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.canvas.saved
import com.meistercharts.color.Color
import com.meistercharts.color.RgbaColorProvider
import com.meistercharts.range.ValueRange
import com.meistercharts.resources.LocalResourcePaintable
import it.neckar.geometry.Direction
import it.neckar.geometry.Size
import it.neckar.open.http.Url
import it.neckar.open.kotlin.lang.fastFor
import it.neckar.open.unit.si.mm
import kotlin.math.roundToInt

/**
 * Paints the roof
 */
class RoofBackgroundLayer(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractLayer() {

  constructor(
    roofSize: () -> @mm Size,
    additionalConfiguration: Configuration.() -> Unit = {},
  ): this(Configuration(roofSize), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  override val type: LayerType = LayerType.Background

  private val layout = Layout()

  override fun layout(paintingContext: LayerPaintingContext) {
    super.layout(paintingContext)
    layout.calculateLayout(paintingContext)
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc

    //Paint the roof background
    gc.fill(configuration.roofBackgroundColor)
    gc.fillRect(
      layout.roofOriginWindowX,
      layout.roofOriginWindowY,
      layout.roofWidthZoomed,
      layout.roofHeightZoomed
    )

    //paint the roof tiles
    layout.roofTilesCountHorizontal.fastFor { xIndex ->
      @Window val roofTileLocationX = layout.roofTileLocationX(xIndex)

      layout.roofTilesCountVertical.fastFor { yIndex ->
        @Window val roofTileLocationY = layout.roofTileLocationY(yIndex)

        gc.saved {
          configuration.roofTilePaintable.paintInBoundingBox(
            paintingContext,
            roofTileLocationX,
            roofTileLocationY,
            Direction.TopLeft,
            0.0,
            0.0,
            layout.roofTileWidthZoomed,
            layout.roofTileHeightZoomed,
            objectFit = ObjectFit.Fill
          ) //TODO remove object fit if possible
        }
      }
    }
  }

  inner class Layout {
    var roofOriginWindowX: @Window Double = 0.0
    var roofOriginWindowY: @Window Double = 0.0
    var roofWidthZoomed: @Zoomed Double = 0.0
    var roofHeightZoomed: @Zoomed Double = 0.0

    var roofTilesCountHorizontal: Int = 0
    var roofTilesCountVertical: Int = 0
    var roofTileWidthZoomed: @Zoomed Double = 0.0
    var roofTileHeightZoomed: @Zoomed Double = 0.0

    fun calculateLayout(paintingContext: LayerPaintingContext) {
      val gc = paintingContext.gc
      val chartCalculator = paintingContext.chartCalculator

      @mm val roofSize = configuration.roofSize()

      val valueRangeX = ValueRange.linear(0.0, roofSize.width)
      val valueRangeY = ValueRange.linear(0.0, roofSize.height)

      //Roof related calculations
      roofOriginWindowX = chartCalculator.domainRelative2windowX(0.0)
      roofOriginWindowY = chartCalculator.domainRelative2windowY(0.0)

      roofWidthZoomed = chartCalculator.domainDelta2zoomedX(roofSize.width, valueRangeX)
      roofHeightZoomed = chartCalculator.domainDelta2zoomedY(roofSize.height, valueRangeY)


      //Calculate the number of roof tiles - round to the nearest int (only show full tiles)
      roofTilesCountHorizontal = (roofSize.width / configuration.roofTileSize.width).roundToInt()
      roofTilesCountVertical = (roofSize.height / configuration.roofTileSize.height).roundToInt()
      roofTileWidthZoomed = roofWidthZoomed / roofTilesCountHorizontal
      roofTileHeightZoomed = roofHeightZoomed / roofTilesCountVertical
    }

    fun roofTileLocationX(xIndex: Int): @Window Double {
      return layout.roofOriginWindowX + xIndex * layout.roofTileWidthZoomed
    }

    fun roofTileLocationY(yIndex: Int): @Window Double {
      return roofOriginWindowY + yIndex * roofTileHeightZoomed
    }
  }

  @ConfigurationDsl
  class Configuration(
    var roofSize: () -> @mm Size,
  ) {
    var roofBackgroundColor: RgbaColorProvider = Color.darkred

    /**
     * The paintable for one roof tile
     */
    var roofTilePaintable: Paintable = LocalResourcePaintable(Url.relative("solar/roofTile-red.png"), Size(374.0, 476.0))

    fun useRedRoofTiles() {
      roofTilePaintable = roofTilePaintableRed
    }

    fun useDarkRoofTiles() {
      roofTilePaintable = roofTilePaintableDark
    }

    /**
     * The size of one roof tile
     */
    var roofTileSize: @mm Size = Size.of(330.0, 420.0)

    companion object {
      /**
       * Predefined paintable for dark tiles
       */
      var roofTilePaintableDark: Paintable = LocalResourcePaintable(Url.relative("solar/roofTile-dark.png"), Size(374.0, 476.0))

      /**
       * Predefined paintable for red tiles
       */
      var roofTilePaintableRed: Paintable = LocalResourcePaintable(Url.relative("solar/roofTile-red.png"), Size(374.0, 476.0))
    }
  }
}

