package components

import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import kotlinx.coroutines.*
import react.*
import react.dom.*
import router.toHome
import router.useDocumentTitle
import services.UiActions
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectPriceList
import kotlin.time.Duration.Companion.milliseconds

val CreateSampleProjectsPage: FC<Props> = fc("CreateSampleProjects") {
  val loggedInUser = useRequireLoggedInUser()
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()

  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val navigate = useNavigateUrl()

  val projectsToCreateCount = useState(3)

  useDocumentTitle(companyName, "Beispielprojekte Anlegen")

  h1 { +"Beispielprojekte Anlegen" }

  div(classes = "container") {

    div("row") {
      div("col-6") {
        h4 {
          +"Zufällige Beispiele erstellen"
        }
      }
    }

    div("row") {
      div("col-2") {
        floatingIntInputField(
          valueAndSetter = projectsToCreateCount,
          fieldName = "sample-projects-count",
          title = "Anzahl der Beispielprojekte",
        ) {
          attrs {
            step = "1"
          }
        }
      }

      div("col-1 d-flex align-items-center") {
        actionButton(
          icon = ButtonIcon.centered(FontAwesomeIcons.add),
          action = suspend {
            delay(500.milliseconds)
            UiActions.createRandomSampleProject(
              loggedInUser = loggedInUser,
              availableProducts = availableProducts,
              priceList = priceList,
              count = projectsToCreateCount.value
            )

            navigate.toHome()
          },
        ) {
        }
      }
    }

    div("row mt-5") {
      div("col-6") {
        h4 {
          +"Realistische Projekte erstellen (e2e)"
        }

        actionButton(
          icon = ButtonIcon.centered(FontAwesomeIcons.add),
          action = suspend {
            delay(500.milliseconds)
            UiActions.createSampleProjects(
              loggedInUser = loggedInUser,
              availableProducts = availableProducts,
              priceList = priceList,
            )

            navigate.toHome()
          },
        ) {
        }
      }
    }
  }
}
