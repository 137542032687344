package components.project

import components.project.LocalStorageKeys.companyCode
import components.project.LocalStorageKeys.editor
import components.project.LocalStorageKeys.maintainer
import it.neckar.commons.kotlin.js.LocalStorageKey
import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.commons.kotlin.js.LocalStorageSupport
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.company.user.AccessRights
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.previews.ResolvedProjectPreview
import it.neckar.lizergy.model.project.previews.ServerProjectPreview
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry.AdvanceInvoiceProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementProcessStateEntry.AssemblyBasementProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry.AssemblyPortfolioProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry.AssemblyRoofProcessStates
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry.BlueprintAcquisitionProcessStates
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry.BlueprintProcessStates
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry.ConfigurationProcessStates
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry.DocumentationProcessStates
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry.FinalAccountProcessStates
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry.FinishingUpProcessStates
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry.GridAssessmentProcessStates
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry.OrderSpecialMaterialProcessStates
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry.PresentationProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry.QuoteConfirmationProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry.QuoteOfferProcessStates
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry.StartupOperationsProcessStates
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry.SwitchMeterBoxProcessStates
import it.neckar.open.kotlin.lang.divFloor
import it.neckar.processStates.ProcessStatePhase
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.router.*
import it.neckar.react.common.table.*
import it.neckar.user.UserLoginName
import kotlinx.html.TD
import kotlinx.html.title
import react.*
import react.dom.*
import router.RouterUrls
import router.toBlueprint
import router.toProject
import router.useDocumentTitle
import services.UiActions
import services.http.ProjectQuerySortedBy
import services.http.ProjectQuerySortedColumn
import services.http.ProjectQuerySorting
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver

val ProjectsOverviewFromUrl: FC<Props> = fc("ProjectsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Alle Projekte")

  h1("mb-3") { +"Alle Projekte" }

  ProjectsOverview {
    attrs {
      this.defaultFilteredBy = null
      this.processStatesToFilter = LizergyProcessStates.allProcessStates
      this.processStatesToHide = listOf(ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = null
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = true
      this.keyPrefix = LocalStorageKeyPrefix("FromStore")
    }
  }
}

internal val ProjectsOverview: FC<ProjectsOverview4Props> = fc("ProjectsOverview") { props ->
  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val companyResolver = useSelectCompanyResolver()
  val processStates = useLoadProcessStates()

  // prefix for local storage keys: every Page controls its own keys
  val keyPrefix = props::keyPrefix.safeGet()

  val forCompany: CompanyCode = LocalStorageSupport.loadFromLocalStorage(
    key = LocalStorageKey("${keyPrefix}${companyCode}"),
    serializer = CompanyCode.serializer(),
  ) ?: loggedInUser.company.companyCode
  val processStatesToFilter = props::processStatesToFilter.safeGet()
  val processStatesToHide = props::processStatesToHide.safeGet()
  val projectQueryComponent = props::projectQueryComponent.safeGet()
  val sortedBy = props::sortedBy.safeGet()
  val includeArchived = props::includeArchived.safeGet()

  val filteredByCompanyCode: StateInstance<CompanyCode?> = useState(forCompany)

  // If the value is null, all projects shall be shown.
  val filteredByMaintainerName: StateInstance<UserLoginName?> = useState(
    LocalStorageSupport.loadFromLocalStorage(
      key = LocalStorageKey("${keyPrefix}${maintainer}"), serializer = UserLoginName.serializer()
    )
  )
  val filteredByEditorName: StateInstance<UserLoginName?> = useState(
    LocalStorageSupport.loadFromLocalStorage(
      key = LocalStorageKey("${keyPrefix}${editor}"), serializer = UserLoginName.serializer()
    )
  )

  val filteredByProjectState: StateInstance<LizergyProcessStates?> = useState(null) //default: show all projects - but not archived!

  val filterInputStateProject = useState("")
  val filterInputStateAddress = useState("")

  val sortedByFunction = useState(
    SortedByFunction(
      sortedBy = sortedBy,
      tableDataColumn = ResolvedProjectPreviewsTable.getProcessStateColumn(projectQueryComponent),
    )
  )

  val pageSize: StateInstance<Int?> = useState(15)
  val currentPage = useState(0)

  val visibleProjects: StateInstance<List<ResolvedProjectPreview>?> = useState(null)
  val numberOfFilteredProjects: StateInstance<Int?> = useState(null)
  val numberOfRelevantProjects: StateInstance<Int?> = useState(null)


  useEffect(
    processStates,
    processStatesToFilter,
    processStatesToHide,
    filteredByCompanyCode.value,
    filteredByMaintainerName.value,
    filteredByEditorName.value,
    filterInputStateProject.value,
    filterInputStateAddress.value,
    filteredByProjectState.value,
    sortedByFunction.value,
    pageSize.value,
    currentPage.value,
  ) {
    val sorting = ProjectQuerySorting(
      sortedBy = when (sortedByFunction.value.sortedBy) {
        SortedBy.Unsorted -> ProjectQuerySortedBy.Unsorted
        SortedBy.SortedAscending -> ProjectQuerySortedBy.SortedAscending
        SortedBy.SortedDescending -> ProjectQuerySortedBy.SortedDescending
      },
      sortedColumn = when (sortedByFunction.value.sortedColumn.id) {
        "project" -> ProjectQuerySortedColumn.ProjectColumn
        "customer" -> ProjectQuerySortedColumn.CustomerColumn
        "editor" -> ProjectQuerySortedColumn.EditorColumn
        "processState" -> ProjectQuerySortedColumn.ProcessStateColumn
        "info" -> ProjectQuerySortedColumn.InfoColumn
        else -> throw IllegalStateException("Unknown sortedColumn: ${sortedByFunction.value.sortedColumn.id}")
      },
    )

    visibleProjects.setter(null)
    numberOfFilteredProjects.setter(null)
    numberOfRelevantProjects.setter(null)

    UiActions.queryProjectPreviews(
      processStatesToFilter = processStatesToFilter,
      processStatesToHide = processStatesToHide,
      filteredByCompanyCode = filteredByCompanyCode.value,
      filteredByMaintainer = filteredByMaintainerName.value,
      filteredByEditor = filteredByEditorName.value,
      filterValueProject = filterInputStateProject.value,
      filterValueAddress = filterInputStateAddress.value,
      filterByProjectState = filteredByProjectState.value,
      indexOfFirstVisibleProject = pageSize.value?.let { currentPage.value * it } ?: 0,
      indexOfLastVisibleProject = pageSize.value?.let { (currentPage.value + 1) * it } ?: Int.MAX_VALUE,
      sorting = sorting,
      projectQueryComponent = projectQueryComponent,
      includeArchived = includeArchived,
    ) { projectQuery ->
      val projectQueryData = projectQuery?.data
      visibleProjects.setter(projectQueryData?.visibleProjectPreviews)
      numberOfFilteredProjects.setter(projectQueryData?.numberOfTotalFilteredProjects)
      numberOfRelevantProjects.setter(projectQueryData?.numberOfTotalRelevantProjects)
      currentPage.setter(currentPage.value.coerceIn(0, projectQueryData?.numberOfTotalFilteredProjects?.divFloor(pageSize.value ?: 1)))
    }
  }


  div {

    projectsFiltering(
      filteredByCompanyCode = filteredByCompanyCode,
      filteredByMaintainerName = filteredByMaintainerName,
      filteredByEditorName = filteredByEditorName,
      filteredByProjectState = filteredByProjectState,
      processStatesToFilter = processStatesToFilter,
      filterInputStateProject = filterInputStateProject,
      filterInputStateAddress = filterInputStateAddress,
      localStorageKeyPrefix = keyPrefix,
    )


    val editAction: (ServerProjectPreview) -> Unit = { projectPreview ->
      projectQueryComponent?.let {
        when (projectQueryComponent) {
          ProjectQueryComponent.Project -> navigate.toProject(projectPreview)
          ProjectQueryComponent.Blueprint -> navigate.toBlueprint(projectPreview)
          ProjectQueryComponent.CurrentConfiguration -> navigate(RouterUrls.project(projectPreview).configurations)
          ProjectQueryComponent.CurrentQuote -> navigate(RouterUrls.project(projectPreview).configurationUrl(projectPreview.currentConfigurationPreview?.configurationId))
          ProjectQueryComponent.OrderSpecialMaterial -> navigate(RouterUrls.project(projectPreview).orderSpecialMaterial)
          ProjectQueryComponent.GridAssessment -> navigate(RouterUrls.project(projectPreview).gridAssessment)
          ProjectQueryComponent.AssemblyPortfolio -> navigate(RouterUrls.project(projectPreview).assemblyPortfolio)
          ProjectQueryComponent.ScheduleAssembly -> navigate(RouterUrls.project(projectPreview).scheduleAssembly)
          ProjectQueryComponent.AdvanceInvoice -> navigate(RouterUrls.project(projectPreview).advanceInvoice)
          ProjectQueryComponent.PrepareMaterial -> navigate(RouterUrls.project(projectPreview).prepareAssembly)
          ProjectQueryComponent.AssemblyRoof -> navigate(RouterUrls.project(projectPreview).assemblyRoof)
          ProjectQueryComponent.AssemblyBasement -> navigate(RouterUrls.project(projectPreview).assemblyBasement)
          ProjectQueryComponent.SwitchMeterBox -> navigate(RouterUrls.project(projectPreview).switchMeterBox)
          ProjectQueryComponent.StartupOperations -> navigate(RouterUrls.project(projectPreview).startupOperations)
          ProjectQueryComponent.FinishingUp -> navigate(RouterUrls.project(projectPreview).finishingUp)
          ProjectQueryComponent.FinalAccount -> navigate(RouterUrls.project(projectPreview).finalAccount)
          ProjectQueryComponent.Documentation -> navigate(RouterUrls.project(projectPreview).documentation)
          ProjectQueryComponent.Verification -> navigate(RouterUrls.project(projectPreview).verification)
        }
      } ?: projectPreview.mostAdvancedProcessStateEntry?.processState?.let { processState ->
        when (processState) {
          is AdvanceInvoiceProcessStates -> navigate(RouterUrls.project(projectPreview).advanceInvoice)
          is AssemblyBasementPreparationProcessStates, is AssemblyRoofPreparationProcessStates -> navigate(RouterUrls.project(projectPreview).prepareAssembly)
          is AssemblyBasementProcessStates -> navigate(RouterUrls.project(projectPreview).assemblyBasement)
          is AssemblyPortfolioProcessStates -> navigate(RouterUrls.project(projectPreview).assemblyPortfolio)
          is AssemblyRoofProcessStates -> navigate(RouterUrls.project(projectPreview).assemblyRoof)
          is BlueprintAcquisitionProcessStates, is BlueprintProcessStates -> navigate.toBlueprint(projectPreview)
          is ConfigurationProcessStates -> navigate(RouterUrls.project(projectPreview).configurations)
          is DocumentationProcessStates -> navigate(RouterUrls.project(projectPreview).documentation)
          is FinalAccountProcessStates -> navigate(RouterUrls.project(projectPreview).finalAccount)
          is FinishingUpProcessStates -> navigate(RouterUrls.project(projectPreview).finishingUp)
          is GridAssessmentProcessStates -> navigate(RouterUrls.project(projectPreview).gridAssessment)
          is OrderSpecialMaterialProcessStates -> navigate(RouterUrls.project(projectPreview).orderSpecialMaterial)
          is PresentationProcessStates, is QuoteOfferProcessStates -> navigate(RouterUrls.project(projectPreview).configurationUrl(projectPreview.currentConfigurationPreview?.configurationId))
          is QuoteConfirmationProcessStates -> navigate(RouterUrls.project(projectPreview).configurationUrl(projectPreview.currentConfigurationPreview?.configurationId))
          is ProjectProcessStates -> throw IllegalStateException("dataProcessStateEntry is not supposed to be ProjectProcessState")
          is StartupOperationsProcessStates -> navigate(RouterUrls.project(projectPreview).startupOperations)
          is SwitchMeterBoxProcessStates -> navigate(RouterUrls.project(projectPreview).switchMeterBox)
        }
      } ?: navigate.toProject(projectPreview)

    }

    busyIfNull(visibleProjects.value) { loadedProjectPreviews ->
      remoteTableWithPagination(
        entries = loadedProjectPreviews,
        columns = ResolvedProjectPreviewsTable.defaultColumns(projectQueryComponent = projectQueryComponent, loggedInUser = loggedInUser, userResolver = userResolver, companyResolver = companyResolver, editAction = editAction),
        pageSize = pageSize,
        currentPage = currentPage,
        numberOfTotalFilteredProjects = numberOfFilteredProjects.value ?: 0,
        sortedByFunction = sortedByFunction,
      )
    }

    div("mt-1") {
      span("form-text") {
        +"${numberOfFilteredProjects.value ?: "-"} von ${numberOfRelevantProjects.value ?: "-"} Projekten ausgewählt"
      }
    }

  }

}


object ResolvedProjectPreviewsTable {

  fun <T : ServerProjectPreview> editButtonFirstColumn(editAction: (project: T) -> Unit): TableHeaderColumn<T> {
    return TableHeaderColumn(
      id = "editButton",
    ) { projectPreview ->
      {
        attrs {
          addClass("mt-3")
        }
        editButton {
          editAction(projectPreview)
        }
      }
    }
  }

  fun <T : ServerProjectPreview> getProjectColumn(companyResolver: CompanyResolver): TableDataColumn<T> {
    return TableDataColumn(
      id = "project",
      title = "Projekt",
      sortFunction = compareBy { it.displayName },
    ) { projectPreview ->
      {
        projectColumnDetails(projectPreview, companyResolver)
      }
    }
  }

  fun <T : ServerProjectPreview> getCustomerColumn(): TableDataColumn<T> {
    return TableDataColumn(
      id = "customer",
      title = "Kunde:in",
      sortFunction = compareBy { it.customer.completeName },
    ) { projectPreview ->
      {
        +projectPreview.customer.completeName
        if (projectPreview.customer.address != projectPreview.location.address) {
          p("form-text") { +projectPreview.customer.address.singleLine() }
        }
        (projectPreview.customer.phone ?: projectPreview.customer.email)?.let { contactInfo ->
          p("form-text") { +contactInfo }
        }
      }
    }
  }

  fun <T : ServerProjectPreview> getEditorColumn(projectQueryComponent: ProjectQueryComponent?, userResolver: UserResolver): TableDataColumn<T> {
    return TableDataColumn(
      id = "editor",
      title = "Bearbeiter",
      sortFunction = compareBy { projectPreview -> projectPreview.editorForQueryComponent(projectQueryComponent)?.let { userResolver[it].editorName } ?: projectPreview.maintainerInformation.editorName },
    ) { projectPreview ->
      {
        (projectPreview.editorForQueryComponent(projectQueryComponent)?.let { userResolver[it].editorName })?.let { +it }
        p("form-text") {
          +projectPreview.maintainerInformation.editorName
        }
      }
    }
  }

  fun <T : ServerProjectPreview> getProcessStateColumn(projectQueryComponent: ProjectQueryComponent?): TableDataColumn<T> {
    return TableDataColumn(
      id = "processState",
      title = "Projektstatus",
      sortFunction = compareBy { projectPreview ->
        val relevantEntry = if (projectPreview.processStateEntry?.processState?.inPhase == ProcessStatePhase.Paused) {
          projectPreview.processStateEntry
        } else {
          projectPreview.processStateEntryForComponent(projectQueryComponent = projectQueryComponent)
        }
        relevantEntry?.assignedAt
      },
    ) { projectPreview ->
      {
        val relevantEntry = if (projectPreview.processStateEntry?.processState?.inPhase == ProcessStatePhase.Paused) {
          projectPreview.processStateEntry
        } else {
          projectPreview.processStateEntryForComponent(projectQueryComponent = projectQueryComponent)
        }
        if (relevantEntry != null) {
          processStatePillWithDate(relevantEntry)
        }
      }
    }
  }

  fun <T : ServerProjectPreview> getMostRecentProcessStateColumn(): TableDataColumn<T> {
    return TableDataColumn(
      id = "processState",
      title = "Projektstatus",
      sortFunction = compareBy { projectPreview ->
        val relevantEntry = if (projectPreview.processStateEntry?.processState?.inPhase == ProcessStatePhase.Paused) projectPreview.processStateEntry else projectPreview.mostAdvancedProcessStateEntry
        relevantEntry?.assignedAt
      },
    ) { projectPreview ->
      {
        val relevantEntry = if (projectPreview.processStateEntry?.processState?.inPhase == ProcessStatePhase.Paused) projectPreview.processStateEntry else projectPreview.mostAdvancedProcessStateEntry
        if (relevantEntry != null) {
          processStatePillWithDate(relevantEntry)
        }
      }
    }
  }

  fun <T : ServerProjectPreview> getInfoColumn(): TableDataColumn<T> {
    return TableDataColumn(
      id = "info",
      title = "",
      sortFunction = compareBy { it.worstProblem },
    ) { projectPreview ->
      {
        attrs {
          addClass("align-middle")
        }
        validationIcon(projectPreview.worstProblem, projectPreview.formattedProblems)
      }
    }
  }

  fun <T : ServerProjectPreview> getDeleteProjectColumn(): TableDataColumn<T> {
    return TableDataColumn(
      id = "deleteProjectColumn",
    ) { projectPreview ->
      {
        attrs {
          addClass("align-middle")
        }
        if (projectPreview.processStateEntry?.processState?.inPhase == ProcessStatePhase.Paused) {
          unArchiveModal(projectPreview)
        } else {
          archiveModal(projectPreview)
        }
      }
    }
  }

  fun <T : ServerProjectPreview> defaultColumns(projectQueryComponent: ProjectQueryComponent?, loggedInUser: UserInformation, userResolver: UserResolver, companyResolver: CompanyResolver, editAction: (project: T) -> Unit): List<TableColumn<T>> {
    return buildList {
      add(editButtonFirstColumn(editAction))
      add(getProjectColumn(companyResolver))
      add(getCustomerColumn())
      add(getEditorColumn(projectQueryComponent, userResolver))
      add(getProcessStateColumn(projectQueryComponent))
      add(getInfoColumn())
      if (loggedInUser.accessRights.canAccess(AccessRights.AccessRight.DeleteProjects)) add(getDeleteProjectColumn())
    }
  }

}


external interface ProjectsOverview4Props : Props {
  var processStatesToFilter: List<LizergyProcessStates>
  var processStatesToHide: List<LizergyProcessStates>
  var projectQueryComponent: ProjectQueryComponent?
  var defaultFilteredBy: DefaultFilteredBy?
  var sortedBy: SortedBy
  var includeArchived: Boolean
  var keyPrefix: LocalStorageKeyPrefix
}


fun <T : ServerProjectPreview> RDOMBuilder<TD>.projectColumnDetails(projectPreview: T, companyResolver: CompanyResolver) {
  span {
    attrs {
      title = projectPreview.projectId.format()
    }
    +projectPreview.displayName
  }

  val currentConfiguration = projectPreview.currentConfigurationPreview
  currentConfiguration?.earningsDistribution?.montageDach?.company?.let { montageDachCompany ->
    if (montageDachCompany != projectPreview.sellingCompany) {
      span("badge rounded-pill bg-info ms-2") {
        attrs {
          title = "Dachmontage von ${companyResolver[montageDachCompany].name} durchgeführt"
        }
        i(FontAwesomeIcons.solarPanel) {}
      }
    }
  }
  currentConfiguration?.earningsDistribution?.elektroInstallation?.company?.let { elektroInstallationCompany ->
    if (elektroInstallationCompany != projectPreview.sellingCompany) {
      span("badge rounded-pill bg-warning ms-2") {
        attrs {
          title = "Elektroinstallation Haus von ${companyResolver[elektroInstallationCompany].name} durchgeführt"
        }
        i(FontAwesomeIcons.bolt) {}
      }
    }
  }
  if (currentConfiguration?.eigenmontage == true) {
    span("badge rounded-pill bg-dark ms-2") {
      attrs {
        title = "Eigenmontage"
      }
      i(FontAwesomeIcons.screwDriverWrench) {}
    }
  }
  if (projectPreview.restArbeiten.isNotEmpty) {
    span("badge rounded-pill bg-danger ms-2") {
      attrs {
        title = "Restarbeiten"
      }
      i(FontAwesomeIcons.personDigging) {}
    }
  }

  if (currentConfiguration != null) {
    p("form-text") { +currentConfiguration.configurationName }
  }
  p("form-text") { +projectPreview.location.address.singleLine() }
}
