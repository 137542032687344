package services.auth.http

import it.neckar.lizergy.model.company.user.UserInformation
import kotlinx.serialization.Serializable

/**
 * The request to change a user's information
 */
@Serializable
data class ChangeUserInfoRequest(val userInformation: UserInformation)
