package components.project.configuration.layout

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.configuration.isConfigurationEditable
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.react.common.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

val ModuleStringsFromUrl: FC<Props> = fc("ModuleStringsFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Stringplanung", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    ModuleStringsOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val ModuleStringsOverview: FC<QuoteConfigurationTabProps> = fc("ModuleStringsOverview") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val moduleLayout = quoteConfiguration.moduleLayouts


  div("mb-5") {
    h3("mb-2") {
      +"Bemerkungen Dachflächen"
    }
    project.blueprint.roofsAnnotation?.let {
      p { +it }
    }
    commentSection(quoteConfiguration.moduleLayouts)
  }

  div("row mt-5") {
    div("col-lg-8") {
      p("form-text") {
        +"Die genaue Planung der Strings ist noch unter Konstruktion."
      }

      //div {
      //  tabButtonsPane {
      //    moduleLayout.fastForEach { moduleLayout ->
      //      tabButton(NavigateUrl(moduleLayout.id.encodeForUrl()), moduleLayout.layoutName)
      //    }
      //  }
      //}
      //
      //div("mt-5") {
      //  Outlet {}
      //}
    }

    val facilityConfiguration = quoteConfiguration.facilityConfiguration.forTheseRoofs(moduleLayout.validElements.map { it.roof.id })
    val inverterSelection = facilityConfiguration.inverterSelection

    val totalNumberOfModules = quoteConfiguration.moduleLayouts.totalNumberOfModules
    val numberOfOptimalModules = facilityConfiguration.numberOfOptimalModules


    div("col-lg-4") {
      div {
        h2("mb-3") { +"Wechselrichter" }

        p("form-text") {
          +"Module: $totalNumberOfModules | Leistung: ${quoteConfiguration.moduleLayouts.totalPowerRating.formatKiloWattPeak()}"
        }
        p("form-text") {
          addClassIf("text-success", numberOfOptimalModules == totalNumberOfModules)
          addClassIf("text-warning", numberOfOptimalModules < totalNumberOfModules)
          addClassIf("text-danger", numberOfOptimalModules > totalNumberOfModules)

          +"Verstringt: ${numberOfOptimalModules} Module"
        }

        facilityConfiguration.inverterConfigurations.fastForEach { inverterConfiguration ->
          div("mb-5") {
            val inverter = inverterConfiguration.inverter
            h3 {
              +buildString {
                append(inverter.description)
                if (inverter is Inverter && inverterSelection[inverter] > 1) append(" ${inverterConfiguration.inverterIndex + 1}")
              }
            }

            inverterConfiguration.mppInputConfigurations.fastForEach { mppInputConfiguration ->
              div("my-3") {
                h4("mb-2") { +"MPP-Eingang ${mppInputConfiguration.inputIndex + 1} (${'A' + mppInputConfiguration.inputIndex})" }

                mppInputConfiguration.stringConfigurations.fastForEachIndexed { index, stringConfiguration ->
                  EditInverterConfiguration {
                    attrs {
                      this.project = project
                      this.quoteConfiguration = quoteConfiguration
                      this.stringConfiguration = stringConfiguration
                      this.inverter = inverter
                      this.inverterIndex = inverterConfiguration.inverterIndex
                      this.inputIndex = mppInputConfiguration.inputIndex
                      this.multipleStrings = mppInputConfiguration.stringConfigurations.size > 1
                      this.first = index == 0
                      this.last = index == mppInputConfiguration.stringConfigurations.size - 1
                      this.editableStatus = editableStatus
                    }
                  }
                }

                if (mppInputConfiguration.hasLegalOptimalModuleCount.not()) {
                  p("form-text text-danger") {
                    +"Pro MPP-Eingang müssen alle Strings die gleiche Anzahl an Modulen haben oder leer sein."
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
