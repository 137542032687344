package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.ktor.client.plugin.stats.CompletedRequestDescriptor
import it.neckar.ktor.client.plugin.stats.PendingRequestDescriptor
import it.neckar.ktor.client.plugin.stats.PendingRequestsState
import store.PlannerClientState


/**
 * Add a request to the 'Started' list
 */
data class AddPendingRequest(val request: PendingRequestDescriptor) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(pendingRequestsState = pendingRequestsState.withPending(request))
  }
}

/**
 * Add a request to the 'Completed' list
 */
data class AddCompletedRequest(val request: CompletedRequestDescriptor) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(pendingRequestsState = pendingRequestsState.withCompleted(request))
  }
}

/**
 * Checks if all requests have been completed and clears the request state
 */
class CheckForCompletion : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    if (pendingRequestsState.hasPendingRequests()) {
      return this //do nothing
    }

    //return empty request state
    return this.copy(pendingRequestsState = PendingRequestsState.empty)
  }
}

