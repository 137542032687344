package components.company.pricelist.tables

import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.kotlin.lang.ifNull
import it.neckar.open.time.formatHourAndMinutes
import it.neckar.open.time.formatMinutes
import kotlinx.html.ThScope
import plannerI18nConfiguration
import react.*
import react.dom.*
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectPriceList
import style.toStyleClass

val FacilityAndAssemblyPriceTable: FC<Props> = fc("FacilityAndAssemblyPriceTable") {
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()

  val availableAssemblyDifficulties = availableProducts.availableAssemblyDifficulties(null)
  val assemblyDifficultyWorkingTimes = priceList.assemblyDifficultyWorkingTimes
  val assemblyDifficulties = priceList.assemblyDifficultyPrices

  val availableHeaterRods = availableProducts.availableHeaterRods(null)
  val heaterRodPrices = priceList.heaterRodPrices


  div("col") {

    h2 {
      +"Heizstäbe"
    }

    table("table table-responsive table-striped") {

      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          td("text-center") {
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
          th(ThScope.col, "text-center") {
            +"In Gebrauch"
          }
        }
      }

      tbody {
        availableHeaterRods.fastForEachIndexed { heaterRodIndex, heaterRod ->

          tr(classes = heaterRod.lifeCycleState.toStyleClass()) {
            key = heaterRod.uuid.toString()

            th(ThScope.row) {
              +(heaterRodIndex + 1).toString()
            }
            td {
              +heaterRod.format(plannerI18nConfiguration)
            }
            td("text-center") {
            }

            td("text-end") {
              +heaterRodPrices[heaterRod.uuid].inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +heaterRodPrices[heaterRod.uuid].sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +heaterRodPrices[heaterRod.uuid].profit.format().ifNull("-")
            }

            td("text-center") {
              +heaterRod.lifeCycleState.format()
            }
          }

          tr(classes = heaterRod.lifeCycleState.toStyleClass()) {
            td {
            }
            th(ThScope.row) {
              +"Arbeitszeit:"
            }
            td("text-end") {
              +heaterRodPrices[heaterRod.uuid].workingTimes.workingTime.formatHourAndMinutes().ifNull("-")
            }

            td("text-end") {
              +heaterRodPrices[heaterRod.uuid].workingTimes.priceWithProfit.inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +heaterRodPrices[heaterRod.uuid].workingTimes.priceWithProfit.sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +heaterRodPrices[heaterRod.uuid].workingTimes.priceWithProfit.profit.format().ifNull("-")
            }

            td("text-center") {
            }
          }

          tr(classes = heaterRod.lifeCycleState.toStyleClass()) {
            td {
            }
            th(ThScope.row) {
              b { +"Gesamt:" }
            }
            td("text-center") {
            }

            td("text-end") {
              b { +heaterRodPrices[heaterRod.uuid].inputPrice.format().ifNull("-") }
            }
            td("text-end") {
              b { +heaterRodPrices[heaterRod.uuid].sellingPrice.format().ifNull("-") }
            }
            td("text-end") {
              b { +heaterRodPrices[heaterRod.uuid].profit.format().ifNull("-") }
            }

            td("text-center") {
            }
          }

        }
      }

    }

  }


  div("col") {

    h2 {
      +"Montagekosten nach Schwierigkeit (pro Modul)"
    }

    table("table table-responsive table-striped") {

      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Schwierigkeit"
          }
          th(ThScope.col, "text-end") {
            +"Arbeitsdauer"
          }
          th(ThScope.col, "text-end") {
            +"Kosten pro Modul"
          }
          th(ThScope.col, "text-end") {
            +"Preis pro Modul"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
        }
      }

      tbody {
        availableAssemblyDifficulties.fastForEachIndexed { assemblyDifficultyTypeIndex, assemblyDifficulty ->
          tr {
            key = assemblyDifficulty.uuid.toString()

            th(ThScope.row) {
              +(assemblyDifficultyTypeIndex + 1).toString()
            }
            td {
              +assemblyDifficulty.description
            }

            td("text-end") {
              +assemblyDifficultyWorkingTimes[assemblyDifficulty.uuid].formatMinutes().ifNull("-")
            }

            td("text-end") {
              +assemblyDifficulties[assemblyDifficulty.uuid].inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +assemblyDifficulties[assemblyDifficulty.uuid].sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +assemblyDifficulties[assemblyDifficulty.uuid].profit.format().ifNull("-")
            }
          }
        }
      }

    }

  }

}
