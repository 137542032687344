package serialized

import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.onlyActive
import it.neckar.lizergy.model.assemblyPortfolio.ResolvedAssemblyPortfolio
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.quote.QuoteConfigurations
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.ProjectConfiguration
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.project.getBelongsToCompanyCodes
import it.neckar.lizergy.model.stumps.AdvanceInvoice
import it.neckar.lizergy.model.stumps.AssemblyBasement
import it.neckar.lizergy.model.stumps.AssemblyRoof
import it.neckar.lizergy.model.stumps.Documentation
import it.neckar.lizergy.model.stumps.FinalAccount
import it.neckar.lizergy.model.stumps.FinishingUp
import it.neckar.lizergy.model.stumps.GridAssessment
import it.neckar.lizergy.model.stumps.OrderSpecialMaterial
import it.neckar.lizergy.model.stumps.StartupOperations
import it.neckar.lizergy.model.stumps.SwitchMeterBox
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import kotlinx.serialization.Serializable

/**
 * Represents a [ProjectConfiguration].
 *
 * This is a lightweight object - that has no customizable properties.
 * Project related values are assigned using the [PhotovoltaicsProjectId]
 */
@Serializable
data class SerializedProject(
  /**
   * The project id
   */
  override val projectId: PhotovoltaicsProjectId,

  override val projectName: String,

  val projectDescription: String,

  override val customer: Customer,

  override val maintainer: UserLoginName,

  /**
   * The company that "owns" that project
   */
  override val sellingCompany: CompanyCode,

  override val archiveReasons: ArchiveReasons = ArchiveReasons.getEmpty(),

  val creationTime: @ms Double = nowMillis(),

  ) : ProjectConfiguration {

  override val belongsToCompanies: Set<CompanyCode>
    get() = buildSet {
      add(sellingCompany)
    }

  fun resolve(
    blueprint: ResolvedBlueprint,
    quoteConfigurations: QuoteConfigurations,
    orderSpecialMaterial: OrderSpecialMaterial,
    gridAssessment: GridAssessment,
    assemblyPortfolio: ResolvedAssemblyPortfolio,
    advanceInvoice: AdvanceInvoice,
    assemblyRoof: AssemblyRoof,
    assemblyBasement: AssemblyBasement,
    switchMeterBox: SwitchMeterBox,
    startupOperations: StartupOperations,
    finishingUp: FinishingUp,
    finalAccount: FinalAccount,
    documentation: Documentation,
    verification: Verification?,
    userResolver: UserResolver,
    companyResolver: CompanyResolver,
  ): ResolvedProject {
    return ResolvedProject(
      projectId = projectId,
      projectName = projectName,
      projectDescription = projectDescription,
      sellingCompanyInformation = companyResolver[sellingCompany],
      customer = customer,
      maintainerInformation = userResolver[maintainer],
      blueprint = blueprint,
      quoteConfigurations = quoteConfigurations,
      orderSpecialMaterial = orderSpecialMaterial,
      gridAssessment = gridAssessment,
      assemblyPortfolio = assemblyPortfolio,
      advanceInvoice = advanceInvoice,
      assemblyRoof = assemblyRoof,
      assemblyBasement = assemblyBasement,
      switchMeterBox = switchMeterBox,
      startupOperations = startupOperations,
      finishingUp = finishingUp,
      finalAccount = finalAccount,
      documentation = documentation,
      verification = verification,
      archiveReasons = archiveReasons,
      creationTime = creationTime,
    )
  }

  fun toProjectPreview(
    blueprint: SerializedBlueprint,
    configurations: List<SerializedConfigurationPreview>,
    orderSpecialMaterial: OrderSpecialMaterial,
    gridAssessment: GridAssessment,
    assemblyPortfolio: SerializedAssemblyPortfolio,
    advanceInvoice: AdvanceInvoice,
    assemblyRoof: AssemblyRoof,
    assemblyBasement: AssemblyBasement,
    switchMeterBox: SwitchMeterBox,
    startupOperations: StartupOperations,
    finishingUp: FinishingUp,
    finalAccount: FinalAccount,
    documentation: Documentation,
    verification: Verification?,
  ): SerializedProjectPreview {
    return SerializedProjectPreview(
      projectId = projectId,
      projectName = projectName,
      sellingCompany = sellingCompany,
      customer = customer,
      maintainer = maintainer,
      acquisitionDate = blueprint.acquisitionDate,
      blueprintPreview = blueprint.toBlueprintPreview(),
      configurationPreviews = configurations,
      orderSpecialMaterialId = orderSpecialMaterial.uuid,
      gridAssessmentId = gridAssessment.uuid,
      assemblyPortfolioId = assemblyPortfolio.uuid,
      advanceInvoiceId = advanceInvoice.uuid,
      assemblyRoofId = assemblyRoof.uuid,
      assemblyBasementId = assemblyBasement.uuid,
      switchMeterBoxId = switchMeterBox.uuid,
      startupOperationsId = startupOperations.uuid,
      finishingUpId = finishingUp.uuid,
      finalAccountId = finalAccount.uuid,
      documentationId = documentation.uuid,
      verification = verification,
      restArbeiten = assemblyBasement.restArbeiten,
      belongsToCompanies = getBelongsToCompanyCodes(blueprint, configurations),
      archiveReasons = archiveReasons,
    )
  }

  fun toAccountingProjectPreview(
    blueprint: SerializedBlueprint,
    configurations: List<SerializedPhotovoltaicsConfiguration>,
    moduleLayouts: Map<PhotovoltaicsConfigurationId, ModuleLayoutsConfiguration>,
    quoteSnapshots: Map<PhotovoltaicsConfigurationId, List<QuoteSnapshot>>,
    orderSpecialMaterial: OrderSpecialMaterial,
    gridAssessment: GridAssessment,
    assemblyPortfolio: SerializedAssemblyPortfolio,
    advanceInvoice: AdvanceInvoice,
    assemblyRoof: AssemblyRoof,
    assemblyBasement: AssemblyBasement,
    switchMeterBox: SwitchMeterBox,
    startupOperations: StartupOperations,
    finishingUp: FinishingUp,
    finalAccount: FinalAccount,
    documentation: Documentation,
    verification: Verification?,
    availableProducts: AvailableProducts,
    productResolver: ProductResolver,
    priceList: PriceList,
  ): AccountingSerializedProjectPreview {
    return AccountingSerializedProjectPreview(
      projectId = projectId,
      projectName = projectName,
      sellingCompany = sellingCompany,
      customer = customer,
      maintainer = maintainer,
      acquisitionDate = blueprint.acquisitionDate,
      blueprintPreview = blueprint.toBlueprintPreview(),
      configurationPreviews = configurations.onlyActive().map { configuration ->
        configuration.toAccountingConfigurationPreview(
          moduleLayouts = moduleLayouts[configuration.configurationId] ?: ModuleLayoutsConfiguration.getEmpty(),
          quoteSnapshots = quoteSnapshots[configuration.configurationId]?.onlyActive(),
          availableProducts = availableProducts,
          productResolver = productResolver,
          priceList = priceList,
        )
      },
      orderSpecialMaterialId = orderSpecialMaterial.uuid,
      gridAssessmentId = gridAssessment.uuid,
      assemblyPortfolioId = assemblyPortfolio.uuid,
      advanceInvoiceId = advanceInvoice.uuid,
      assemblyRoofId = assemblyRoof.uuid,
      assemblyBasementId = assemblyBasement.uuid,
      switchMeterBoxId = switchMeterBox.uuid,
      startupOperationsId = startupOperations.uuid,
      finishingUpId = finishingUp.uuid,
      finalAccountId = finalAccount.uuid,
      documentationId = documentation.uuid,
      verification = verification,
      restArbeiten = assemblyBasement.restArbeiten,
      belongsToCompanies = getBelongsToCompanyCodes(blueprint, configurations),
      archiveReasons = archiveReasons,
    )
  }

}


fun ResolvedProject.unResolve(): SerializedProject {
  return SerializedProject(
    projectId = projectId,
    projectName = projectName,
    projectDescription = projectDescription,
    customer = customer,
    maintainer = maintainer,
    sellingCompany = sellingCompany,
    creationTime = creationTime,
    archiveReasons = archiveReasons,
  )
}
