package store

import it.neckar.common.redux.Dispatch
import it.neckar.react.common.redux.*
import it.neckar.lizergy.PlannerClientDeploymentZone
import store.AppStateLocalStorage.connectWithLocalStorage

/**
 * The global store that holds the app state.
 *
 * * Do *NOT* use directly from within react components. Instead, use the `useSelector()` hook.
 *
 * Call [initializeStore] to initialize the store at the start of the application
 */
lateinit var store: PlannerStore

/**
 * Initializes the store.
 */
fun initializeStore(deploymentZone: PlannerClientDeploymentZone) {
  store = UndoStore.create(PlannerClientState(deploymentZone = deploymentZone)).also { store ->
    //Load the values from local storage - automatically saves data to local storage
    store.connectWithLocalStorage()

    store.subscribe {
      println("Store updated. Update counter: ${it.state.updateCounter}")
    }
  }

  //Register the global dispatch action
  Dispatch.registerDispatchAction { stateAction ->
    store.dispatch(stateAction)
  }
}

/**
 * The type for the store of the planner
 */
typealias PlannerStore = UndoStore<PlannerClientState>
