package it.neckar.lizergy.model

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.open.collections.fastForEach
import it.neckar.uuid.HasUuid
import kotlinx.serialization.Transient

/**
 * Abstract base class that holds several elements
 */
interface ElementsCollection<T> where  T : HasUuid, T : HasLifeCycle {
  /**
   * The elements
   */
  val elements: List<T>

  val validElements: List<T>

  /**
   * Returns the number of elements
   */
  val size: Int

  /**
   *
   */
  @Transient
  private val byId
    get() = elements.associateBy {
      it.uuid
    }

  /**
   * Returns the element with the given id
   */
  operator fun get(uuid: Uuid): T {
    return byId[uuid] ?: throw IllegalArgumentException(
      """No element found for uuid <$uuid>. Available elements: ${elements.joinToString(",") { "${it::class} ${it.uuid}" }}"""
    )
  }

  operator fun get(index: Int): T {
    return validElements[index]
  }

  fun getOrNull(uuid: Uuid): T? {
    return byId[uuid]
  }

  /**
   * Returns the first element.
   * Throws an [NoSuchElementException] if [elements] is empty.
   */
  fun first(): T {
    return validElements.first()
  }

  /**
   * Returns a new elements object with the added additional element
   */
  fun withAdded(additionalElement: T): ElementsCollection<T>

  fun withAdded(additionalElements: List<T>): ElementsCollection<T>

  /**
   * Returns a new instance with the one roof updated
   */
  fun withUpdated(updatedElement: T): ElementsCollection<T>

  fun withUpdated(updatedElements: List<T>): ElementsCollection<T>

  fun withRemoved(removedElement: T): ElementsCollection<T>

  fun fastForEach(function: (T) -> Unit) {
    validElements.fastForEach { function(it) }
  }

  fun map(function: (T) -> T): ElementsCollection<T>

  fun find(function: (T) -> Boolean): T? {
    return elements.find { function(it) }
  }

}
