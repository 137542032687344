package it.neckar.lizergy.model.configuration.quote.economics

import it.neckar.open.formatting.formatAsPercentage
import it.neckar.open.unit.other.pct
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Information about the financing type
 */
@Deprecated("Financing type is no longer used, because the distinction between investment and financing is no longer seen as important")
@Serializable
sealed interface FinancingType

/**
 * "Eigenfinanziert"
 */
@Serializable
@SerialName("EquityFinanced")
data object EquityFinanced : FinancingType

@Serializable
data class Leveraged(
  /**
   * The interest rate
   */
  val interestRate: @pct Double,
) : FinancingType {
  override fun toString(): String {
    return "Leveraged(${interestRate.formatAsPercentage()}"
  }
}
