package it.neckar.financial.quote

import com.benasher44.uuid.Uuid
import it.neckar.commons.tags.Tag
import it.neckar.commons.tags.Tags
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents an item that has a concrete price ("leaf" of the tree)
 */
@Serializable
@SerialName("QuoteItem")
data class QuoteItem(
  @Serializable(with = UuidSerializer::class) override val uuid: Uuid,

  /**
   * The (bold) headline
   */
  override val headline: String,
  /**
   * The details - might be a long text describing in detail
   */
  override val details: String?,

  /**
   * The price for *one* item.
   * Is multiplied with [amount]
   */
  val priceForOneElement: PriceWithProfit,

  /**
   * The amount
   */
  override val amount: Amount,

  override val visibility: Visibility,

  /**
   * The tags this item is relevant for.
   *
   * Can be used to calculate sums / discounts etc.
   */
  val relevance: Tags,

  /**
   * If an item is optional
   */
  override val optionality: Optionality = Optionality.Mandatory,

  /**
   * VAT percentage ("Mehrwertsteuer-Satz")
   */
  val valueAddedTax: ValueAddedTax = ValueAddedTax.nineteenPercent,

  ) : QuoteElement {

  constructor(
    headline: String,
    details: String?,
    priceForOneElement: PriceWithProfit,
    amount: Amount,
    visibility: Visibility,
    relevance: Tags,
    optionality: Optionality = Optionality.Mandatory,
  ) : this(
    uuid = randomUuid4(),
    headline = headline,
    details = details,
    priceForOneElement = priceForOneElement,
    amount = amount,
    visibility = visibility,
    relevance = relevance,
    optionality = optionality,
  )

  init {
    //The old default value for an empty quote item is no longer allowed
    if (headline == "<-empty->") {
      throw IllegalStateException("Invalid headline: [$headline]")
    }
  }

  override fun relevanceOrNull(): Tags {
    return relevance
  }

  override fun sums(sumQuery: CalculationRelevanceQuery): SumsForVATs {
    return if (sumQuery.matches(this)) {
      SumsForVATs(mapOf(valueAddedTax to totalPrice()))
    } else {
      SumsForVATs.empty
    }
  }

  /**
   * Calculates the total price for the provided amount
   */
  fun totalPrice(): PriceWithProfit {
    return priceForOneElement * amount
  }

  fun isRelevantFor(tag: Tag): Boolean = this.relevance.contains(tag)


  companion object {
    /**
     * Empty quote item - visibility is public
     */
    fun createEmpty(uuid: Uuid = randomUuid4()): QuoteItem = QuoteItem(uuid, "", "", PriceWithProfit.Zero, Amount(1), Visibility.Public, Tags.empty, Optionality.Optional)
  }

}
