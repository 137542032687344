package it.neckar.rest.pagination

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents a page index
 */
@Serializable
@JvmInline
value class PageIndex(
  val value: Int,
) {
  companion object {
    /**
     * The first page
     */
    val Zero: PageIndex = PageIndex(0)
  }
}
