package it.neckar.lizergy.model.project.process.state

import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry.BlueprintAcquisitionProcessStates.Empty
import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import it.neckar.processStates.HasDueDate
import it.neckar.processStates.ProcessStateEntryId
import it.neckar.processStates.ProcessStatePhase
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("BlueprintAcquisitionProcessStateEntry")
data class BlueprintAcquisitionProcessStateEntry(
  override val id: ProcessStateEntryId,
  override val processState: BlueprintAcquisitionProcessStates,
  override val assignedTo: UserLoginName,
  override val belongsTo: CompanyCode,
  override val dueDate: @ms @Serializable(with = DoubleAsIsoDateTimeSerializer::class) Double?,
  override val assignedAt: @ms @Serializable(with = DoubleAsIsoDateTimeSerializer::class) Double,
  override val assignedBy: UserLoginName,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : LizergyProcessStateEntry, HasDueDate {

  override val availableOptions: List<BlueprintAcquisitionProcessStates>
    get() = BlueprintAcquisitionProcessStates.entries

  override fun format(i18nConfiguration: I18nConfiguration): String {
    return when (processState) {
      Empty -> processState.formatForScheduling(dueDate, i18nConfiguration)
    }
  }

  @Serializable
  @SerialName("BlueprintAcquisitionProcessStates")
  enum class BlueprintAcquisitionProcessStates : LizergyProcessStates {
    Empty,

    ;

    override val inPhase: ProcessStatePhase
      get() = when (this) {
        Empty -> ProcessStatePhase.Untouched
      }

    override fun format(i18nConfiguration: I18nConfiguration): String {
      return when (this) {
        Empty -> "PE"
      }
    }

    override fun formatDate(date: Double, i18nConfiguration: I18nConfiguration): String {
      return "seit ${dateFormat.format(date, i18nConfiguration)}"
    }
  }
}
