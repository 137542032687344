package store.actions

import com.benasher44.uuid.Uuid
import it.neckar.comments.Comment
import it.neckar.common.redux.ChangeType
import store.PlannerClientState

data class CommentAddedAction(val commentFor: Uuid, val newComment: Comment) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(
      allCommentsForComponents = allCommentsForComponents.withNewComment(commentFor, newComment),
    )
  }
}
