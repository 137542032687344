package components.login

import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faSpinner
import it.neckar.react.common.form.*
import it.neckar.user.UserLoginName
import kotlinx.html.ButtonType
import kotlinx.html.InputType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import services.UiActions

/**
 * Login form
 */
val LoginForm: FC<Props> = fc("LoginForm") {
  val usernameState = useState("")
  val passwordState = useState("")

  val (busy, setBusy) = useState(false)

  val plausible = useMemo(usernameState.value, passwordState.value) {
    isPlausible(usernameState.value, passwordState.value)
  }

  val logInAction: () -> Unit = useCallback(usernameState.value, passwordState.value) {
    launchAndNotify {
      setBusy(true)
      UiActions.login(UserLoginName(usernameState.value.trim()), passwordState.value.trim())
      setBusy(false)
    }
  }


  div("login-form") {

    onEnter(logInAction)

    h1 {
      +"Login"
    }

    div("form-floating") {
      floatingInputField(
        valueAndSetter = usernameState,
        fieldName = "username",
        title = "Benutzername",
      ) {
        attrs {
          mergedBelow()
        }
      }
    }

    div("form-floating mb-3") {
      floatingInputField(
        valueAndSetter = passwordState,
        fieldName = "password",
        title = "Passwort",
      ) {
        attrs {
          type = InputType.password
          mergedAbove()
        }
      }
    }

    button(classes = "btn btn-primary w-100 btn-lg", type = ButtonType.button) {
      attrs {
        addClassIf("disabled") { plausible.not() }
        //disabled = plausible.not() || busy
        onClickFunction = { logInAction() }
      }

      if (busy.not()) {
        +"Login"
      } else {
        faSpinner()
      }
    }
  }
}

private fun isPlausible(username: String, password: String): Boolean {
  return username.isNotBlank() && password.isNotBlank()
}
