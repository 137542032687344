package it.neckar.lizergy.model.configuration.quote.builder

import com.meistercharts.charts.lizergy.roofPlanning.PerRoof
import io.ktor.util.*
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedMetalRoofTileConstructionType
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import it.neckar.open.kotlin.lang.ceil

/**
 * Calculates the number of required metal tiles for several roofs
 */
class MetalTilesCalculator {
  private val metalRoofTileEntries: MutableMap<ResolvedRoof, MetalRoofTileEntry> = mutableMapOf()

  /**
   * Add metal tiles for the given roof
   */
  @PerRoof
  fun addForRoof(
    roof: ResolvedRoof,

    roofTile: RoofTile,
    /**
     * The modules count for the roof
     */
    modulesCount: @PerRoof Int,

    metalRoofTileConstructionType: ResolvedMetalRoofTileConstructionType?,
  ) {
    addForRoof(roof, roofTile.metalRoofTile, modulesCount, metalRoofTileConstructionType)
  }

  @PerRoof
  fun addForRoof(
    roof: ResolvedRoof,

    metalRoofTile: MetalRoofTile,
    /**
     * The modules count for the roof
     */
    modulesCount: @PerRoof Int,

    metalRoofTileConstructionType: ResolvedMetalRoofTileConstructionType?,
  ) {
    val metalRoofTileEntry = MetalRoofTileEntry(metalRoofTile, metalRoofTileConstructionType).also { metalRoofTileEntry ->
      metalRoofTileEntry.addForModules(modulesCount)
    }
    metalRoofTileEntries[roof] = metalRoofTileEntry
  }

  /**
   * Returns the entries (that are not empty)
   */
  fun getEntries(): Map<ResolvedRoof, MetalRoofTileEntry> {
    return metalRoofTileEntries.filter {
      it.value.isNotEmpty
    }
  }


  data class MetalRoofTileEntry(
    val matchingMetalTile: MetalRoofTile,
    val metalRoofTileConstructionType: ResolvedMetalRoofTileConstructionType?,
  ) {

    /**
     * The total modules count we have to add
     */
    var totalModulesCount: Int = 0
      private set

    val isNotEmpty: Boolean
      get() = totalModulesCount > 0

    val amount: Double
      get() = (totalModulesCount * 3.5).ceil()

    internal fun addForModules(modulesCount: @PerRoof Int) {
      this.totalModulesCount += modulesCount
    }

    val descriptionWithoutColor: String
      get() = "${matchingMetalTile.description} verzinkt"

    val descriptionWithColor: String
      get() = metalRoofTileConstructionType?.color?.let { "${matchingMetalTile.description} ${it.name.toLowerCasePreservingASCIIRules()}" } ?: descriptionWithoutColor

  }
}
