package components.project.configuration.annotations

import components.form.commentSection
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.ElectricityWorkConfiguration.ElectricityWorkConfigurationId
import it.neckar.lizergy.model.configuration.components.FacilityConfiguration.FacilityConfigurationId
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario.PowerUsageScenarioId
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.WallboxSelection.WallboxSelectionId
import it.neckar.lizergy.model.project.ResolvedProject
import react.*
import react.dom.*

val AnnotationsList: FC<AnnotationsListProps> = fc("AnnotationsList") { props ->
  val project = props::project.safeGet()
  val moduleLayouts = props::moduleLayouts.safeGet()
  val powerUsageScenarioId = props::powerUsageScenarioId.safeGet()
  val facilityConfigurationId = props::facilityConfigurationId.safeGet()
  val wallboxConfigurationId = props::wallboxSelectionId.safeGet()
  val electricityWorkConfigurationId = props::electricityWorkConfigurationId.safeGet()
  val additionalPositions = props::additionalPositions.safeGet()


  div("col-lg-6 my-3") {
    h3("mb-3") {
      +"Bemerkungen Projekt"
    }
    project.blueprint.annotation?.let {
      p { +it }
    }
    commentSection(project)
  }

  div("col-lg-6 my-3") {
    h3("mb-3") {
      +"Bemerkungen Dachflächen"
    }
    project.blueprint.roofsAnnotation?.let {
      p { +it }
    }
    commentSection(moduleLayouts)
  }
  moduleLayouts.fastForEach { moduleLayout ->
    //div("col-md-6 my-3") {
    //  h4("mb-2") {
    //    +"Bemerkungen ${moduleLayout.layoutName}"
    //  }
    //  commentSection(moduleLayout)
    //}

    div("col-lg-6 my-3") {
      h4("mb-2") {
        +"Bemerkungen ${moduleLayout.roof.roofName}"
      }
      project.blueprint.roofAnnotations.firstOrNull { it.id.uuid == moduleLayout.id.uuid }?.let {
        it.annotation?.let { p { +it } }
      }
      commentSection(moduleLayout.roof)
    }
  }

  hr {}

  div("col-lg-6 my-3") {
    h3("mb-3") {
      +"Bemerkungen Wirtschaftlichkeit"
    }
    project.blueprint.profitabilityAnnotation?.let {
      p { +it }
    }
    commentSection(powerUsageScenarioId.uuid)
  }

  div("col-lg-6 my-3") {
    h3("mb-3") {
      +"Bemerkungen Anlage & Montage"
    }
    project.blueprint.facilityAnnotation?.let {
      p { +it }
    }
    commentSection(facilityConfigurationId.uuid)
  }

  div("col-lg-6 my-3") {
    h3("mb-3") {
      +"Bemerkungen E-Auto"
    }
    project.blueprint.wallboxAnnotation?.let {
      p { +it }
    }
    commentSection(wallboxConfigurationId.uuid)
  }

  div("col-lg-6 my-3") {
    h3("mb-3") {
      +"Bemerkungen Zählerschrank"
    }
    project.blueprint.electricityWorkAnnotation?.let {
      p { +it }
    }
    commentSection(electricityWorkConfigurationId.uuid)
  }

  div("col-lg-6 my-3") {
    h3("mb-3") {
      +"Bemerkungen Zusätzliches"
    }
    project.blueprint.additionalAnnotation?.let {
      p { +it }
    }
    commentSection(additionalPositions)
  }

}

external interface AnnotationsListProps : Props {
  var project: ResolvedProject
  var moduleLayouts: ResolvedModuleLayouts
  var powerUsageScenarioId: PowerUsageScenarioId
  var facilityConfigurationId: FacilityConfigurationId
  var wallboxSelectionId: WallboxSelectionId
  var electricityWorkConfigurationId: ElectricityWorkConfigurationId
  var additionalPositions: ConfigurationItemsConfiguration
}
