package components.form

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Form for a label and a longer description
 */
fun RBuilder.descriptionForm(
  label: StateInstance<String>,
  description: StateInstance<String>,
  labelPlaceholder: String,
  editableStatus: EditableStatus,
): Unit = child(DescriptionForm) {
  attrs {
    this.label = label
    this.description = description
    this.labelPlaceholder = labelPlaceholder
    this.editableStatus = editableStatus
  }
}

val DescriptionForm: FC<ProjectDescriptionFormProps> = fc("DescriptionForm") { props ->
  val label = props::label.getNotNull()
  val description = props::description.getNotNull()
  val labelPlaceholder = props::labelPlaceholder.safeGet()

  val editableStatus = props::editableStatus.safeGet()


  div {
    div("form-floating") {
      floatingInputField(
        valueAndSetter = label,
        fieldName = "label",
        title = labelPlaceholder,
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
        }
      }
    }

    div("form-floating") {
      floatingInputArea(
        valueAndSetter = description,
        fieldName = "description",
        title = "Beschreibung",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
        }
      }
    }
  }
}

external interface ProjectDescriptionFormProps : Props {
  var label: StateInstance<String>
  var description: StateInstance<String>
  var labelPlaceholder: String
  var editableStatus: EditableStatus
}
