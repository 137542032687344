@file:Suppress("DuplicatedCode")

package components.project.configuration.facility

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.configuration.configurationItemForm
import components.project.configuration.facility.assembly.EditConfigurationAssembly
import components.project.configuration.facility.battery.batteryStorageForm
import components.project.configuration.isConfigurationEditable
import components.project.configuration.yearlycosts.EditConfigurationYearlyCosts
import components.project.isProjectEditable
import components.project.validBatteryConfigurations
import components.project.validInverterTypes
import i18n.EnumTranslator
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.commons.tags.Tags
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.financial.currency.euro
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType
import it.neckar.lizergy.model.configuration.components.ResolvedFacilityConfiguration
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.price.Prices
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.other.pct100
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectPriceList

val EditConfigurationFacilityFromUrl: FC<Props> = fc("EditConfigurationFacilityFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Anlage & Montage", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    EditConfigurationFacility {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val EditConfigurationFacility: FC<QuoteConfigurationTabProps> = fc("EditConfigurationFacility") { props ->
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val facilityConfiguration = quoteConfiguration.facilityConfiguration

  val facilityConfigurationToSave = useState(facilityConfiguration)
  val assemblyConfigurationToSave = useState(quoteConfiguration.assemblyConfiguration)

  val yearlyCosts = quoteConfiguration.yearlyCosts
  val operatingCostsToSave: StateInstance<@EUR Double?> = useState(yearlyCosts.operatingCosts?.euros)
  val yearlyIncreaseToSave: StateInstance<@pct100 Double> = useState(yearlyCosts.operatingCostsIncreasePercentage * 100)


  val configurationToSave = useMemo(
    assemblyConfigurationToSave.value,
    facilityConfigurationToSave.value,
    operatingCostsToSave.value,
    yearlyIncreaseToSave.value,
  ) {
    quoteConfiguration.copy(
      assemblyConfiguration = assemblyConfigurationToSave.value,
      facilityConfiguration = facilityConfigurationToSave.value,
      yearlyCosts = quoteConfiguration.yearlyCosts.copy(
        operatingCosts = operatingCostsToSave.value?.euro,
        operatingCostsIncreasePercentage = yearlyIncreaseToSave.value.percent,
      ),
    )
  }

  useMemo(configurationToSave) {
    if (editableStatus == Editable) {
      UiActions.saveQuoteConfiguration(project, configurationToSave)
    }
  }


  div("row my-5") {

    div("col-lg-6") {

      EditFacility {
        attrs {
          this.facilityConfigurationToSave = facilityConfigurationToSave
          this.inverterPrices = priceList.inverterPrices
          this.availableInverters = availableProducts.validInverterTypes(facilityConfiguration.inverterSelection.types)
          this.independenceManager = quoteConfiguration.electricityWorkConfiguration.independenceManager
          this.resolvedModuleLayouts = quoteConfiguration.moduleLayouts
          this.totalPowerRating = quoteConfiguration.totalPowerRating
          this.editableStatus = editableStatus
        }
      }

      EditBatteryConfiguration {
        attrs {
          this.facilityConfigurationToSave = facilityConfigurationToSave
          this.recommendedBatteryPhotovoltaicsConfiguration = quoteConfiguration.recommendedBatteryConfiguration
          this.availableBatteryConfigurations = availableProducts.validBatteryConfigurations(facilityConfiguration.batteryConfiguration)
          this.independenceManager = quoteConfiguration.electricityWorkConfiguration.independenceManager
          this.powerUsageScenario = quoteConfiguration.powerUsageScenario
          this.batterySelection = project.blueprint.battery
          this.editableStatus = editableStatus
        }
      }

      EditFacilityMisc {
        attrs {
          this.facilityConfigurationToSave = facilityConfigurationToSave
          this.editableStatus = editableStatus
        }
      }

      EditConfigurationAssembly {
        attrs {
          this.quoteConfiguration = quoteConfiguration
          this.assemblyConfigurationToSave = assemblyConfigurationToSave
          this.availableAssemblyDifficulties = availableProducts.availableAssemblyDifficulties()
          this.priceList = priceList
          this.editableStatus = editableStatus
        }
      }

      EditConfigurationYearlyCosts {
        attrs {
          this.operatingCostsToSave = operatingCostsToSave
          this.yearlyIncreaseToSave = yearlyIncreaseToSave
          this.calculatedOperatingCosts = quoteConfiguration.operatingCosts.euros
          this.editableStatus = editableStatus
        }
      }

    }

    div("col-lg-6") {
      h3("mt-0 mb-2") {
        +"Bemerkungen Anlage & Montage"
      }
      project.blueprint.facilityAnnotation?.let {
        p { +it }
      }
      commentSection(facilityConfiguration)
    }

  }

}

val EditFacility: FC<EditFacilityProps> = fc("EditFacility") { props ->
  val facilityConfigurationToSave = props::facilityConfigurationToSave.getNotNull()

  val inverterPrices = props::inverterPrices.safeGet()
  val availableInverters = props::availableInverters.safeGet()

  val independenceManager = props::independenceManager.safeGet()
  val resolvedModuleLayouts = props::resolvedModuleLayouts.safeGet()
  val totalPowerRating = props::totalPowerRating.safeGet()

  val editableStatus = props::editableStatus.safeGet()

  val facilityConfiguration = facilityConfigurationToSave.value

  val numberOfOptimizersToSave = useState(facilityConfiguration.numberOfOptimizers)
  val kabelwegZugschlagLengthToSave = useState(facilityConfiguration.kabelwegZugschlagLength)
  val numberUeberspannungsSchutzToSave = useState(facilityConfiguration.numberUeberspannungsSchutz)

  val inverterSelectionToSave = useState(facilityConfiguration.inverterSelection)


  useMemo(
    numberOfOptimizersToSave.value,
    kabelwegZugschlagLengthToSave.value,
    numberUeberspannungsSchutzToSave.value,
    inverterSelectionToSave.value,
  ) {
    facilityConfiguration.copy(
      numberOfOptimizers = numberOfOptimizersToSave.value,
      kabelwegZugschlagLength = kabelwegZugschlagLengthToSave.value,
      numberUeberspannungsSchutz = numberUeberspannungsSchutzToSave.value?.let { if (it == 0) null else it },
    ).let {
      if (inverterSelectionToSave.value != facilityConfiguration.inverterSelection) {
        facilityConfigurationToSave.setter(facilityConfiguration.copy(inverterSelection = inverterSelectionToSave.value).recalculateInverterConfigurations())
      } else {
        facilityConfigurationToSave.setter(it)
      }
    }
  }


  facilityForm(
    availableInverters = availableInverters,
    numberOfOptimizers = numberOfOptimizersToSave,
    kabelwegZugschlagLength = kabelwegZugschlagLengthToSave,
    numberUeberspannungsSchutz = numberUeberspannungsSchutzToSave,
    inverterSelectionState = inverterSelectionToSave,
    inverterTypesPrices = inverterPrices,
    selectedIndependenceManager = independenceManager,
    resolvedModuleLayouts = resolvedModuleLayouts,
    totalPowerRating = totalPowerRating,
    editableStatus = editableStatus,
  )

}

val EditBatteryConfiguration: FC<EditBatteryConfigurationProps> = fc("EditBatteryConfiguration") { props ->
  val facilityConfigurationToSave = props::facilityConfigurationToSave.getNotNull()

  val recommendedBatteryPhotovoltaicsConfiguration = props::recommendedBatteryPhotovoltaicsConfiguration.safeGet()
  val lizergyIndependenceManager = props::independenceManager.safeGet()

  val availableBatteryConfigurations = props::availableBatteryConfigurations.safeGet()
  val powerUsageScenario = props::powerUsageScenario.safeGet()
  val batterySelection = props::batterySelection.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val batteryConfigurationToSave = useState(facilityConfigurationToSave.value.batteryConfiguration)


  useMemo(batteryConfigurationToSave.value) {
    if (batteryConfigurationToSave.value != facilityConfigurationToSave.value.batteryConfiguration) {
      facilityConfigurationToSave.setter(facilityConfigurationToSave.value.copy(batteryConfiguration = batteryConfigurationToSave.value).recalculateInverterConfigurations())
    }
  }


  div("my-5") {

    h2 {
      +"Batterie-Speicher"
    }

    p("form-text mt-0 mb-3") {
      +"In der Projekterfassung ausgewählt: ${EnumTranslator.ConfiguredOptionality(batterySelection)}"
    }

    batteryStorageForm(
      batteryConfigurationSelection = batteryConfigurationToSave,
      availableBatteryConfigurations = availableBatteryConfigurations,
      recommendedBatteryConfiguration = recommendedBatteryPhotovoltaicsConfiguration,
      selectedIndependenceManager = lizergyIndependenceManager,
      editableStatus = editableStatus,
    )

    val houseEnergyConsumption = powerUsageScenario.yearlyPowerConsumption + powerUsageScenario.waermepumpeConsumption
    val carEnergyConsumption = (PowerUsageScenario.electricCarConsumptionPerKilometer * powerUsageScenario.electricCarUsage)
    val totalEnergyConsumption = houseEnergyConsumption + carEnergyConsumption
    p("form-text") {
      +"Stromverbrauch: ${totalEnergyConsumption.format(plannerI18nConfiguration)} (Jahresverbrauch & Wärmepumpe: ${houseEnergyConsumption.format(plannerI18nConfiguration)} + E-Auto: ${carEnergyConsumption.format(plannerI18nConfiguration)})"
    }

  }

}

val EditFacilityMisc: FC<EditFacilityMiscProps> = fc("EditFacilityMisc") { props ->
  val facilityConfigurationToSave = props::facilityConfigurationToSave.getNotNull()

  val editableStatus = props::editableStatus.safeGet()

  val facilityConfiguration = facilityConfigurationToSave.value
  val facilityWorkEffort = facilityConfiguration.facilityWorkEffort

  val facilityWorkEffortHeadline: StateInstance<String> = useState(facilityWorkEffort.headline)
  val facilityWorkEffortDetails: StateInstance<String> = useState(facilityWorkEffort.details)
  val facilityWorkEffortPrice: StateInstance<@EUR Double> = useState(facilityWorkEffort.priceForOneElement.sellingPrice.euros)
  val facilityWorkEffortAmount: StateInstance<Double> = useState(facilityWorkEffort.amount)
  val facilityWorkEffortRelevance: StateInstance<Tags> = useState(facilityWorkEffort.relevance)
  val facilityWorkEffortValueAddedTax: StateInstance<@pct Double> = useState(facilityWorkEffort.valueAddedTax.vat)

  val heaterRodToSave = useState(facilityConfiguration.heaterRod)
  val waermepumpenanbindungToSave = useState(facilityConfiguration.waermepumpenanbindung)

  val onlineMonitoringToSave = useState(facilityConfiguration.onlineMonitoring)
  val integrateInverterIntoNetworkToSave = useState(facilityConfiguration.integrateInverterIntoNetwork)
  val erdspiessSetzenToSave = useState(facilityConfiguration.erdspiessSetzen)


  val facilityWorkEffortToSave = useMemo(facilityWorkEffortHeadline.value, facilityWorkEffortDetails.value, facilityWorkEffortPrice.value, facilityWorkEffortAmount.value, facilityWorkEffortRelevance.value, facilityWorkEffortValueAddedTax.value) {
    facilityWorkEffort.copy(
      headline = facilityWorkEffortHeadline.value,
      details = facilityWorkEffortDetails.value,
      priceForOneElement = PriceWithProfit.noProfit(facilityWorkEffortPrice.value.euro),
      amount = facilityWorkEffortAmount.value.toDouble(),
      relevance = facilityWorkEffortRelevance.value,
      valueAddedTax = ValueAddedTax(facilityWorkEffortValueAddedTax.value)
    )
  }

  useMemo(
    facilityWorkEffortToSave,
    heaterRodToSave.value,
    waermepumpenanbindungToSave.value,
    onlineMonitoringToSave.value,
    integrateInverterIntoNetworkToSave.value,
    erdspiessSetzenToSave.value,
  ) {
    facilityConfigurationToSave.setter(
      facilityConfiguration.copy(
        facilityWorkEffort = facilityWorkEffortToSave,
        waermepumpenanbindung = waermepumpenanbindungToSave.value,
        onlineMonitoring = onlineMonitoringToSave.value,
        integrateInverterIntoNetwork = integrateInverterIntoNetworkToSave.value,
        erdspiessSetzen = erdspiessSetzenToSave.value,
        heaterRod = heaterRodToSave.value,
      )
    )
  }


  div("my-5") {

    div("my-3") {
      configurationItemForm(
        headline = facilityWorkEffortHeadline,
        details = facilityWorkEffortDetails,
        price = facilityWorkEffortPrice,
        amount = facilityWorkEffortAmount,
        relevance = facilityWorkEffortRelevance,
        valueAddedTax = facilityWorkEffortValueAddedTax,
        editableStatus = editableStatus,
      )
    }

    miscFacilityForm(
      heaterRodToSave = heaterRodToSave,
      waermepumpenanbindungToSave = waermepumpenanbindungToSave,
      onlineMonitoringToSave = onlineMonitoringToSave,
      integrateInverterIntoNetworkToSave = integrateInverterIntoNetworkToSave,
      erdspiessSetzenToSave = erdspiessSetzenToSave,
      editableStatus = editableStatus,
    )

  }

}


external interface EditFacilityProps : Props {
  var facilityConfigurationToSave: StateInstance<ResolvedFacilityConfiguration>
  var inverterPrices: Prices<Inverter>
  var availableInverters: List<Inverter>
  var independenceManager: IndependenceManagerType?
  var resolvedModuleLayouts: ResolvedModuleLayouts?
  var totalPowerRating: PowerRating?
  var editableStatus: EditableStatus
}

external interface EditBatteryConfigurationProps : Props {
  var facilityConfigurationToSave: StateInstance<ResolvedFacilityConfiguration>
  var recommendedBatteryPhotovoltaicsConfiguration: BatteryConfiguration?
  var availableBatteryConfigurations: List<BatteryConfiguration>
  var independenceManager: IndependenceManagerType?
  var powerUsageScenario: PowerUsageScenario
  var batterySelection: ConfiguredOptionality
  var editableStatus: EditableStatus
}

external interface EditFacilityMiscProps : Props {
  var facilityConfigurationToSave: StateInstance<ResolvedFacilityConfiguration>
  var editableStatus: EditableStatus
}
