package it.neckar.financial.quote

import kotlinx.serialization.Serializable

/**
 *
 */
@Serializable
data class QuoteLegalNotice(
  /**
   * An optional additional line
   */
  val additionalLines: List<String>
) {
  companion object {
    val empty: QuoteLegalNotice = QuoteLegalNotice(emptyList())
  }
}
