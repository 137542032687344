package services.auth.http

import it.neckar.lizergy.model.company.PlannerCompanyInformation
import kotlinx.serialization.Serializable

/**
 * The request to change a company's information
 */
@Serializable
data class ChangeCompanyInfoRequest(
  val companyInformation: PlannerCompanyInformation,
)
