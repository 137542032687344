package it.neckar.lizergy.model.configuration.energy

import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.configuration.quote.economics.simple.PricePerKWh
import it.neckar.open.formatting.decimalFormat
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.kWh
import it.neckar.open.unit.si.km
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents an amount of energy
 */
@Serializable
@JvmInline
value class AmountOfEnergy(
  val kWh: @kWh Double,
) : Comparable<AmountOfEnergy> {

  constructor(kWh: @kWh Int) : this(
    kWh.toDouble()
  )

  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "${decimalFormat.format(kWh, i18nConfiguration)}${whitespaceConfig.smallSpace}kWh"
  }

  override operator fun compareTo(other: AmountOfEnergy): Int {
    return this.kWh.compareTo(other.kWh)
  }

  operator fun minus(other: AmountOfEnergy): AmountOfEnergy {
    return AmountOfEnergy(this.kWh - other.kWh)
  }

  operator fun plus(other: AmountOfEnergy): AmountOfEnergy {
    return AmountOfEnergy(this.kWh + other.kWh)
  }

  operator fun div(other: AmountOfEnergy): @pct Double {
    return this.kWh / other.kWh
  }

  operator fun div(divisor: Double): AmountOfEnergy {
    return AmountOfEnergy(this.kWh / divisor)
  }

  operator fun times(factor: @pct Double): AmountOfEnergy {
    return AmountOfEnergy(kWh * factor)
  }

  operator fun times(factor: @km Int): AmountOfEnergy {
    return AmountOfEnergy(kWh * factor)
  }

  /**
   * Multiplies this value with an amount of energy
   */
  operator fun times(pricePerUnit: PricePerKWh): Money {
    return pricePerUnit * this
  }

  /**
   * Multiplies
   */
  operator fun times(price: Money): Money {
    return Money.euros(price.euros * kWh)
  }

  override fun toString(): String {
    return format(I18nConfiguration.US, WhitespaceConfig.Spaces)
  }

  operator fun times(performanceFactor: PerformanceFactor): AmountOfEnergy {
    return AmountOfEnergy(this.kWh * performanceFactor.percentage)
  }

  companion object {
    val Zero: AmountOfEnergy = AmountOfEnergy(0.0)

    fun kWh(kWh: @kWh Double): AmountOfEnergy {
      return AmountOfEnergy(kWh)
    }
  }
}

/**
 * Multiplies
 */
inline operator fun Money.times(amountOfEnergy: AmountOfEnergy): Money {
  return amountOfEnergy * this
}

/**
 * Calculates the sum of multiple amount of energy objects
 */
fun Iterable<AmountOfEnergy>.sum(): AmountOfEnergy {
  return AmountOfEnergy(sumOf { it.kWh })
}

/**
 * Converts this double into an Amount of energy
 */
val Double.kWh: AmountOfEnergy
  get() {
    return AmountOfEnergy.kWh(this)
  }
