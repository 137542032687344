package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedProject
import store.PlannerClientState

/**
 * Updates a single project.
 * Does *not* navigate to the project
 */
data class UpdateProjectAction(val projectId: PhotovoltaicsProjectId, val updateAction: ResolvedProject.() -> ResolvedProject) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    println("Updating project <${projectId}>")

    val resolvedProject = this.resolvedProjects[projectId]
    val updatedProject = resolvedProject.updateAction()

    val updatedProjects = this.resolvedProjects.withUpdated(updatedProject)

    return this.copy(resolvedProjects = updatedProjects)
  }
}
