package components.project.customer

import components.project.address.addressForm
import components.project.customer.company.companyForm
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Form for a customer
 */
fun RBuilder.customerForm(
  firstName: StateInstance<String>,
  lastName: StateInstance<String>,

  addressStreet: StateInstance<String>,
  addressHouseNumber: StateInstance<String>,
  addressZipCode: StateInstance<String>,
  addressCity: StateInstance<String>,

  phone: StateInstance<String>,
  cellphone: StateInstance<String>,
  email: StateInstance<String>,

  companyName: StateInstance<String>,

  editableStatus: EditableStatus,

  ): Unit = child(CustomerForm) {
  attrs {
    this.firstName = firstName
    this.lastName = lastName

    this.addressStreet = addressStreet
    this.addressHouseNumber = addressHouseNumber
    this.addressZipCode = addressZipCode
    this.addressCity = addressCity

    this.phone = phone
    this.cellphone = cellphone
    this.email = email

    this.companyName = companyName

    this.editableStatus = editableStatus
  }
}

val CustomerForm: FC<CustomerFormProps> = fc("customerForm") { props ->
  val firstName = props::firstName.getNotNull()
  val lastName = props::lastName.getNotNull()
  val addressStreet = props::addressStreet.getNotNull()
  val addressHouseNumber = props::addressHouseNumber.getNotNull()
  val addressZipCode = props::addressZipCode.getNotNull()
  val addressCity = props::addressCity.getNotNull()
  val phone = props::phone.getNotNull()
  val cellphone = props::cellphone.getNotNull()
  val email = props::email.getNotNull()
  val companyName = props::companyName.getNotNull()

  val editableStatus = props::editableStatus.safeGet()


  div("row gx-0") {

    div("col-md-6") {
      floatingInputField(
        valueAndSetter = firstName,
        fieldName = "firstName",
        title = "Vorname",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedRight()
          mergedBelow()
        }
      }
    }

    div("col-md-6") {
      floatingInputField(
        valueAndSetter = lastName,
        fieldName = "lastName",
        title = "Nachname",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedLeft()
          mergedBelow()
        }
      }
    }

  }

  div("row g-0") {

    div("col-md-6") {
      floatingInputField(
        valueAndSetter = phone,
        fieldName = "tel",
        title = "Telephon",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedRight()
          mergedAbove()
          mergedBelow()
        }
      }
    }
    div("col-md-6") {
      floatingInputField(
        valueAndSetter = cellphone,
        fieldName = "celltel",
        title = "Handy",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedLeft()
          mergedAbove()
          mergedBelow()
        }
      }
    }

    div("col-md-12") {
      floatingInputField(
        valueAndSetter = email,
        fieldName = "email",
        title = "Email",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedRight()
          mergedAbove()
        }
      }
    }

  }


  h2 {
    +"Firma"
  }

  companyForm(
    companyName = companyName,
    editableStatus = editableStatus,
  )


  h2 {
    +"Rechnungsaddresse"
  }

  addressForm(
    street = addressStreet,
    houseNumber = addressHouseNumber,
    zipCode = addressZipCode,
    city = addressCity,
    editableStatus = editableStatus,
  )

}

external interface CustomerFormProps : Props {
  var firstName: StateInstance<String>
  var lastName: StateInstance<String>

  var addressStreet: StateInstance<String>
  var addressHouseNumber: StateInstance<String>
  var addressZipCode: StateInstance<String>
  var addressCity: StateInstance<String>

  var phone: StateInstance<String>
  var cellphone: StateInstance<String>
  var email: StateInstance<String>

  var companyName: StateInstance<String>

  var editableStatus: EditableStatus
}
