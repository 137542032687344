package services.http

import it.neckar.ktor.client.JwtTokenClientHandler
import it.neckar.common.redux.dispatch
import it.neckar.rest.jwt.JwtTokenPair
import store.LoggedInState
import store.PlannerStore
import store.actions.JwtTokenPairRefreshed
import store.actions.LogoutAction
import store.actions.LogoutReason

/**
 * Support for JWS tokens for PV Planner
 */
//TODO necessary???
class PlannerUiJwtTokenClientHandler(val store: PlannerStore) : JwtTokenClientHandler {
  override suspend fun loadTokens(): JwtTokenPair? {
    val loggedInState = store.state.loginState as? LoggedInState
    return loggedInState?.tokenPair //return null if not logged
  }

  override fun storeNewTokens(tokenPair: JwtTokenPair) {
    //Save the refreshed tokens in the store
    dispatch(JwtTokenPairRefreshed(tokenPair))
  }

  override fun logout() {
    dispatch(LogoutAction(LogoutReason.TokenRefreshFailed))
  }
}
