package router

import it.neckar.react.common.router.*

object RouterVars {
  val companyCode: RouterVar = RouterVar("companyCode")
  val loginName: RouterVar = RouterVar("loginName")
  val projectId: RouterVar = RouterVar("projectId")
  val configurationId: RouterVar = RouterVar("configurationId")
  val additionalPositionId: RouterVar = RouterVar("additionalPositionId")
  val bestandsAnlagenPVId: RouterVar = RouterVar("bestandsAnlagenPVId")
  val bestandsAnlagenBHKWId: RouterVar = RouterVar("bestandsAnlagenBHKWId")
  val moduleLayoutId: RouterVar = RouterVar("moduleLayoutId")
}
