package it.neckar.lizergy.model.project

import it.neckar.editHistory.PositionEdit
import it.neckar.editHistory.PositionEditHistory

@Deprecated("In the process of being reworked")
interface HasProcessState {

  @Deprecated("Being replaced by new Process State Service")
  val processState: PositionEditHistory<OLDProcessState>?

  @Deprecated("Being replaced by new Process State Service")
  val processStateEdit: PositionEdit<OLDProcessState>?
    get() = processState?.currentEdit
}
