package it.neckar.react.common.form

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import kotlinx.html.BUTTON
import kotlinx.html.role
import react.*
import react.dom.*

/**
 * Adds cancel/ok buttons for a form
 */
fun RBuilder.formButtons(
  cancelIcon: ButtonIcon? = null,
  cancelText: String = "Abbrechen",
  okIcon: ButtonIcon? = ButtonIcon.left(
    icon = FontAwesomeIcons.save,
  ),
  okText: String = "Speichern",

  cancelConfig: ((RDOMBuilder<BUTTON>) -> Unit)? = null,
  okConfig: ((RDOMBuilder<BUTTON>) -> Unit)? = null,
  cancelAction: () -> Unit,
  okAction: () -> Unit,
): Unit = child(FormButtons) {
  attrs {
    this.cancelIcon = cancelIcon
    this.cancelText = cancelText
    this.okIcon = okIcon
    this.okText = okText

    this.cancelConfig = cancelConfig
    this.okConfig = okConfig
    this.cancelAction = cancelAction
    this.okAction = okAction
  }
}

/**
 * Creates wizard buttons ("backward"/"forward")
 */
fun RBuilder.wizardFormButtons(
  backwardIcon: ButtonIcon? = ButtonIcon.left(
    icon = FontAwesomeIcons.arrowLeft,
  ),
  backwardText: String = "Zurück",

  forwardText: String = "Weiter",
  forwardIcon: ButtonIcon? = ButtonIcon.right(
    icon = FontAwesomeIcons.arrowRight,
  ),

  backwardConfig: ((RDOMBuilder<BUTTON>) -> Unit)? = null,
  backwardAction: () -> Unit,

  forwardConfig: ((RDOMBuilder<BUTTON>) -> Unit)? = null,
  forwardAction: () -> Unit,

  ): Unit = child(FormButtons) {
  attrs {
    this.cancelIcon = backwardIcon
    this.cancelText = backwardText
    this.okIcon = forwardIcon
    this.okText = forwardText

    this.cancelConfig = backwardConfig
    this.okConfig = forwardConfig
    this.cancelAction = backwardAction
    this.okAction = forwardAction
  }
}

/**
 * Represents two buttons side by side.
 * Left one to "cancel", right one to "ok"
 */
val FormButtons: FC<FormButtonsProps> = fc("FormButtons") { props ->
  val cancelIcon = props::cancelIcon.safeGet()
  val cancelText = props::cancelText.safeGet()
  val okIcon: ButtonIcon? = props::okIcon.safeGet()
  val okText = props::okText.safeGet()

  val cancelConfig = props::cancelConfig.safeGet()
  val okConfig = props::okConfig.safeGet()
  val cancelAction = props::cancelAction.safeGet()
  val okAction: () -> Unit = props::okAction.safeGet()

  div(classes = "btn-group mt-3 ") {
    attrs {
      role = "group"
      ariaLabel = "Form Buttons"
    }

    button2(
      classes = "btn btn-secondary",
      label = cancelText,
      icon = cancelIcon,
      action = cancelAction,
    ) {
      cancelConfig?.invoke(this)
    }

    button2(
      classes = "btn btn-primary",
      label = okText,
      icon = okIcon,
      action = okAction,
    ) {
      okConfig?.invoke(this)
    }
  }
}


external interface FormButtonsProps : Props {
  var cancelIcon: ButtonIcon?
  var cancelText: String
  var okIcon: ButtonIcon?
  var okText: String

  var cancelConfig: ((RDOMBuilder<BUTTON>) -> Unit)?
  var okConfig: ((RDOMBuilder<BUTTON>) -> Unit)?

  //ATTENTION: Defining this a suspend function runs into strange kotlin compiler errors
  //https://youtrack.jetbrains.com/issue/KT-63000/TypeError-when-defining-suspend-function-in-react-Props
  var cancelAction: () -> Unit

  //ATTENTION: Defining this a suspend function runs into strange kotlin compiler errors
  var okAction: () -> Unit
}
