package it.neckar.lizergy.model.price

import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable

@Serializable
data class OptionalDiscountPrices(
  val dachhakenSetzenDiscount: @pct Double,
  val schienenMontageDiscount: @pct Double,
  val kabelZiehenUndModuleMontierenDiscount: @pct Double,
  val dachhakenGestelltDiscount: @pct Double,
  val dachhakenGestelltRoofDiscount: @pct Double,
) {

  companion object {
    /**
     * Some values are multiplied by 80 percent
     * the discount for the roof assembly is limited by lizergy to be capped at 80 percent
     * for readability and ease of communication, [dachhakenSetzenDiscount], [schienenMontageDiscount], and [kabelZiehenUndModuleMontierenDiscount]
     *  add up to 100% together (and are then multiplied by 80 percent)
     */
    val lizergyDefault: OptionalDiscountPrices = OptionalDiscountPrices(
      dachhakenSetzenDiscount = 45.percent * 80.percent,
      schienenMontageDiscount = 10.percent * 80.percent,
      kabelZiehenUndModuleMontierenDiscount = 45.percent * 80.percent,
      dachhakenGestelltDiscount = 45.percent * 80.percent,
      dachhakenGestelltRoofDiscount = 43.percent,
    )
  }
}
