package components.project.configuration.layout

import components.form.commentSection
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import react.router.dom.*
import router.RouterUrls
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useSelectAvailableProducts

val BlueprintRoofsOverviewFromUrl: FC<Props> = fc("BlueprintRoofsOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Dachflächen - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    BlueprintRoofsOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val BlueprintRoofsOverview: FC<BlueprintRoofsOverviewProps> = fc("BlueprintRoofsOverview") { props ->
  val navigate = useNavigateUrl()
  val availableProducts = useSelectAvailableProducts()

  val project = props::project.safeGet()
  val blueprint = project.blueprint

  val editableStatus = project.isProjectEditable()

  val dachmasseAbgelegt = useState(blueprint.dachmasseAbgelegt)

  val okAction = {
    navigate(RouterUrls.blueprint(project).profitability)
  }


  useMemo(dachmasseAbgelegt.value) {
    blueprint.copy(dachmasseAbgelegt = dachmasseAbgelegt.value).also {
      UiActions.saveBlueprint(it)
    }
  }


  div("my-4") {

    RoofsOverview {
      attrs {
        this.project = project

        this.addAction = {
          UiActions.addModuleLayout(blueprint, availableProducts).also { newModuleLayout ->
            navigate(RouterUrls.blueprint(project).moduleLayout(newModuleLayout.id))
          }
        }
        this.editAction = { moduleLayout ->
          navigate(RouterUrls.blueprint(project).moduleLayout(moduleLayout.id))
        }
        this.copyAction = { moduleLayout ->
          UiActions.duplicateModuleLayout(blueprint, moduleLayout).also { newModuleLayout ->
            navigate(RouterUrls.blueprint(project).moduleLayout(newModuleLayout.id))
          }
        }
        this.deleteAction = { moduleLayout ->
          UiActions.removeModuleLayout(blueprint, moduleLayout)
        }

      }
    }

    div("my-3") {
      checkbox(
        valueAndSetter = dachmasseAbgelegt,
        fieldName = "dachmasseAbgelegt",
        title = "Dachmaße organisiert/abgelegt*",
        editableStatus = editableStatus,
        labelConfig = {
          attrs {
            addClass("fw-bold")
          }
        },
      )
    }

    div("my-5") {
      h3("mb-3") {
        +"Bemerkungen Dachflächen"
      }
      project.blueprint.roofsAnnotation?.let {
        p { +it }
      }
      commentSection(blueprint.moduleLayouts)
    }

    wizardFormButtons(
      backwardAction = { navigate(RouterUrls.blueprint(project).index) },
      forwardAction = { okAction() },
    )
  }

}


val RoofsOverview: FC<RoofsOverviewProps> = fc("RoofsOverview") { props ->
  val project = props::project.safeGet()
  val addAction = props::addAction.safeGet()
  val editAction = props::editAction.safeGet()
  val copyAction = props::copyAction.safeGet()
  val deleteAction = props::deleteAction.safeGet()

  val blueprint = project.blueprint
  val resolvedModuleLayouts = blueprint.moduleLayouts


  h1("mb-4") {
    +"Dachflächen"

    actionButtonWithConfirmationModal(
      icon = FontAwesomeIcons.add,
      modalTitle = "Neue Dachfläche anlegen?",
    ) {
      addAction()
    }
  }

  div("row row-cols-1 row-cols-md-2 g-4") {
    resolvedModuleLayouts.validElements.fastForEach { moduleLayout ->

      div("col") {
        div("card h-100") {
          div("card-body") {
            h2("card-title mt-0 mb-3") {
              Link {
                +(moduleLayout.description ?: "${moduleLayout.roof.roofName}${moduleLayout.roof.areaFormatted()?.let { " $it" }.orEmpty()}")

                attrs {
                  toUrl = RouterUrls.blueprint(project).moduleLayout(moduleLayout)
                }
              }

              div(classes = "btn-group") {

                editButton {
                  editAction(moduleLayout)
                }

                actionButtonWithConfirmationModal(
                  icon = FontAwesomeIcons.copy,
                  modalTitle = "Soll die Dachfläche dupliziert werden?",
                  modalContent = {
                    +"Die Dachausrichtung wird hierbei um 180° gedreht."
                  }
                ) {
                  copyAction(moduleLayout)
                }

                actionButtonWithConfirmationModal(
                  icon = FontAwesomeIcons.trash,
                  modalTitle = "Soll das Dach gelöscht werden?",
                ) {
                  deleteAction(moduleLayout)
                }

              }
            }

            moduleLayoutPreview(moduleLayout)
          }
        }
      }
    }

  }

}


external interface BlueprintRoofsOverviewProps : Props {
  var project: ResolvedProject
}

external interface RoofsOverviewProps : Props {
  var project: ResolvedProject
  var addAction: () -> Unit
  var editAction: (resolvedModuleLayoutToSave: ResolvedModuleLayout) -> Unit
  var copyAction: (resolvedModuleLayoutToSave: ResolvedModuleLayout) -> Unit
  var deleteAction: (resolvedModuleLayoutToSave: ResolvedModuleLayout) -> Unit
}
