package it.neckar.processStates

import com.benasher44.uuid.Uuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

/**
 * An id for a project
 */
@Serializable
data class ProcessStateEntryId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

  override fun toString(): String {
    return uuid.toString()
  }

  fun format(): String {
    return uuid.toString()
  }

  companion object {
    /**
     * Creates a new [ProcessStateEntryId] with a random [Uuid]
     */
    fun random(): ProcessStateEntryId {
      return ProcessStateEntryId(randomUuid4())
    }
  }
}
