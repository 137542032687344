package it.neckar.lizergy.model.price.lizergy

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.lizergy.roofPlanning.ModuleSize
import com.meistercharts.charts.lizergy.roofPlanning.PerModule
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.MoneyAndTime
import it.neckar.financial.currency.PricePerHour
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.PriceWithProfitAndWorkingTime
import it.neckar.financial.currency.WorkingTimesWithPrice
import it.neckar.financial.currency.euro
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration.BatteryConfigurationId
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType.IndependenceManagerId
import it.neckar.lizergy.model.configuration.components.LizergyIndependenceManagerType
import it.neckar.lizergy.model.configuration.components.OtherIndependenceManagerType
import it.neckar.lizergy.model.configuration.energy.BatteryCapacity
import it.neckar.lizergy.model.configuration.energy.Capacity
import it.neckar.lizergy.model.configuration.energy.ampere
import it.neckar.lizergy.model.configuration.energy.kWh
import it.neckar.lizergy.model.configuration.energy.volts
import it.neckar.lizergy.model.configuration.energy.wattPeak
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule.PvModuleId
import it.neckar.lizergy.model.configuration.moduleLayout.PvModuleTypeMaterial
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor.MetalRoofTileColorId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileCompatability
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType.RoofTypeId
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.lizergy.model.configuration.quote.builder.BasicBatteryInverter
import it.neckar.lizergy.model.configuration.quote.builder.BatteryInverter
import it.neckar.lizergy.model.configuration.quote.builder.BatteryStorageExtensionType
import it.neckar.lizergy.model.configuration.quote.builder.BatteryStorageExtensionType.BatteryStorageExtensionTypeId
import it.neckar.lizergy.model.configuration.quote.builder.BatteryStorageType
import it.neckar.lizergy.model.configuration.quote.builder.BatteryStorageType.BatteryStorageTypeId
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod.HeaterRodId
import it.neckar.lizergy.model.configuration.quote.builder.HybridInverter
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.InverterType
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox.WallboxId
import it.neckar.lizergy.model.price.InfoForType
import it.neckar.lizergy.model.price.InfoForTypeBuilder
import it.neckar.lizergy.model.price.MonitoringPrices
import it.neckar.lizergy.model.price.OptionalDiscountPrices
import it.neckar.lizergy.model.price.PriceList.PriceListId
import it.neckar.lizergy.model.price.Prices
import it.neckar.lizergy.model.price.RoofIsolationPrices
import it.neckar.lizergy.model.price.StaticPriceList
import it.neckar.lizergy.model.price.lizergy.LizergyPriceListFactory.BatteryAndInverterType
import it.neckar.lizergy.model.price.lizergy.LizergyPriceListFactory.batteryStorageExtensionInputPrices
import it.neckar.lizergy.model.price.lizergy.LizergyPriceListFactory.surCharges
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.time.h
import it.neckar.uuid.HasUuid
import it.neckar.uuid.xor
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours

/**
 * Factory that calculates a price list
 */
@Suppress("DuplicatedCode")
object LizergyPriceListFactory {

  /**
   * The price per working hour
   */
  val workingHourPrice: PricePerHour = PricePerHour.euros(75.0)

  const val workingHoursPerDay: @h Double = 7.0

  val surCharges: SurCharges = SurCharges(
    modulesSurCharge = 40.percent,
    inverterSurCharge = 40.percent,
    batteryStorageSurCharge = 30.percent,
    wallBoxesSurCharge = 40.percent,
    metalRoofTilesSurCharge = 40.percent,
    substructureSurCharge = 40.percent,
    constructionSurCharge = 40.percent,
    waermepumpenSurCharge = 100.percent,
    energyMeterSurCharge = 50.percent,
    schienenSurCharge = 50.percent,
    klemmenSurCharge = 200.percent,
    specialSurCharge = 100.percent,
    blackModulesSurCharge = 50.percent,
    optimizerSurCharge = 40.percent,
    aufschlagKreuzverbundSurCharge = 30.percent,
  )

  /**
   * The module prices
   */
  @Reselling
  val moduleInputPrices: InfoForType<PvModule, Money> = InfoForType {
    add(
      PvModule(
        id = PvModuleId(uuidFrom("605d9b36-b8e4-43db-86ee-7419549a74cc")),
        description = "Jinko 445 Wp",
        powerRating = 445.wattPeak,
        offLoadVoltage = 39.59.volts,
        shortCircuitCurrent = 13.93.ampere,
        size = ModuleSize(1762, 1134)
      ), 60.00.euro
    )
    add(
      PvModule(
        id = PvModuleId(uuidFrom("83d8f360-388f-44a5-95b3-bb15cfc65c96")),
        description = "Jolywood 440 Wp Glas-Glas-Modul",
        powerRating = 440.wattPeak,
        offLoadVoltage = 38.60.volts,
        shortCircuitCurrent = 14.25.ampere,
        size = ModuleSize(1762, 1134),
        material = PvModuleTypeMaterial.GlassGlass
      ), 60.00.euro
    )
    add(
      PvModule(
        id = PvModuleId(uuidFrom("db79e842-07ac-4292-80e3-863e29ed52db")),
        description = "IBC MonoSol 440",
        powerRating = 440.wattPeak,
        offLoadVoltage = 39.38.volts,
        shortCircuitCurrent = 13.86.ampere,
        size = ModuleSize(1762, 1134)
      ), 80.00.euro
    )
    add(
      PvModule(
        id = PvModuleId(uuidFrom("9048af98-2975-495b-bdf9-e8d65ac17517")),
        description = "Jolywood JW-HD120N-380 Glas-Glas-Modul",
        powerRating = 380.wattPeak,
        offLoadVoltage = 0.volts,
        shortCircuitCurrent = 0.ampere,
        size = ModuleSize(1773, 1046),
        material = PvModuleTypeMaterial.GlassGlass
      ), 136.84.euro
    )
    add(
      PvModule(
        id = PvModuleId(uuidFrom("67a3e375-590c-4296-9aad-a1ce62f59362")),
        description = "Meyer Burger White 390",
        powerRating = 390.wattPeak,
        offLoadVoltage = 0.volts,
        shortCircuitCurrent = 0.ampere,
        size = ModuleSize(1767, 1041),
        warranty = 25
      ), 130.00.euro
    )
    add(
      PvModule(
        id = PvModuleId(uuidFrom("9048af98-2975-495b-bdf9-e8d65ac17517")),
        description = "Jolywood JW-HD120N-380 Glas-Glas-Modul",
        powerRating = 380.wattPeak,
        offLoadVoltage = 0.volts,
        shortCircuitCurrent = 0.ampere,
        size = ModuleSize(1773, 1046),
        material = PvModuleTypeMaterial.GlassGlass
      ), 50.0.euro
    )

    // Deprecated elements
    add(
      PvModule(
        id = PvModuleId(uuidFrom("3ff3f053-8454-448d-bb30-193a4a47124e")),
        description = "Jolywood JW-HD108N-425 Glas-Glas-Modul",
        powerRating = 425.wattPeak,
        offLoadVoltage = 38.10.volts,
        shortCircuitCurrent = 14.05.ampere,
        size = ModuleSize(1722, 1134),
        material = PvModuleTypeMaterial.GlassGlass,
        lifeCycleState = LifeCycleState.EndOfLife
      ), 80.00.euro
    )
    add(PvModule(id = PvModuleId(uuidFrom("8bb19b31-370d-4d78-8761-c8c7f3f0a549")), description = "Jinko 440 Wp", powerRating = 440.wattPeak, offLoadVoltage = 0.volts, shortCircuitCurrent = 0.ampere, size = ModuleSize(1762, 1134), lifeCycleState = LifeCycleState.EndOfLife), 70.00.euro)
    add(PvModule(id = PvModuleId(uuidFrom("3dacc951-b1f7-485a-9378-24ac477aded8")), description = "Jinko 425 Wp", powerRating = 425.wattPeak, offLoadVoltage = 0.volts, shortCircuitCurrent = 0.ampere, size = ModuleSize(1722, 1134), lifeCycleState = LifeCycleState.EndOfLife), 65.00.euro)
    add(PvModule(id = PvModuleId(uuidFrom("a9e6a229-9331-4f04-83f7-eb8d38159115")), description = "IBC MonoSol 425", powerRating = 425.wattPeak, offLoadVoltage = 38.30.volts, shortCircuitCurrent = 14.15.ampere, size = ModuleSize(1722, 1134), lifeCycleState = LifeCycleState.EndOfLife), 75.00.euro)
    add(PvModule(PvModuleId(uuidFrom("9d09ecc6-d332-453e-a95a-e9fe253be436")), "IBC MonoSol 415", 415.wattPeak, offLoadVoltage = 0.volts, shortCircuitCurrent = 0.ampere, ModuleSize(1722, 1134), lifeCycleState = LifeCycleState.EndOfLife), 147.35.euro)
    add(PvModule(PvModuleId(uuidFrom("a516b516-0ef1-4df7-af4b-0c93b73ad2cd")), "IBC MonoSol 405", 405.wattPeak, offLoadVoltage = 0.volts, shortCircuitCurrent = 0.ampere, ModuleSize(1722, 1133), lifeCycleState = LifeCycleState.EndOfLife), 139.69.euro)
    add(PvModule(PvModuleId(uuidFrom("90e6470f-1a67-4317-add4-8146b921e60e")), "JinkoSolar MM400V", 400.wattPeak, offLoadVoltage = 0.volts, shortCircuitCurrent = 0.ampere, ModuleSize(1722, 1134), lifeCycleState = LifeCycleState.EndOfLife), 120.00.euro)
    add(PvModule(PvModuleId(uuidFrom("1f2df20c-12a1-4535-a706-5499456117fe")), "IBC MonoSol 375", 375.wattPeak, offLoadVoltage = 0.volts, shortCircuitCurrent = 0.ampere, ModuleSize(1755, 1038), lifeCycleState = LifeCycleState.EndOfLife), 114.70.euro)
    add(PvModule(PvModuleId(uuidFrom("50a01ab1-d535-44a7-b90d-dec067c15647")), "IBC MonoSol 370", 370.wattPeak, offLoadVoltage = 0.volts, shortCircuitCurrent = 0.ampere, ModuleSize(1755, 1038), lifeCycleState = LifeCycleState.EndOfLife), 114.70.euro)
    add(PvModule(PvModuleId(uuidFrom("0df2b036-5e02-4aab-8e89-ed220f23b729")), "IBC MonoSol 340", 340.wattPeak, offLoadVoltage = 0.volts, shortCircuitCurrent = 0.ampere, ModuleSize(1684, 1002), lifeCycleState = LifeCycleState.EndOfLife), 88.40.euro)
    add(PvModule(PvModuleId(uuidFrom("e664e770-95dc-4448-b304-13854e8df7ca")), "URE 335", 335.wattPeak, offLoadVoltage = 0.volts, shortCircuitCurrent = 0.ampere, ModuleSize(1675, 992), lifeCycleState = LifeCycleState.EndOfLife), 82.50.euro)
  }


  val inverterSB1_5: Inverter = Inverter(InverterId(uuidFrom("0a112d6c-7900-48e5-ae57-708385fda88d")), "SB 1.5", Capacity(1.5), InverterType.Phase.SinglePhase, listOf(1))
  val inverterSB2_0: Inverter = Inverter(InverterId(uuidFrom("bfdf60d4-2ef0-48f8-9c2c-3471bdd81091")), "SB 2.0", Capacity(2.0), InverterType.Phase.SinglePhase, listOf(1))
  val inverterSB2_5: Inverter = Inverter(InverterId(uuidFrom("688b5738-8792-4ed8-8f8e-7522deb22e5c")), "SB 2.5", Capacity(2.5), InverterType.Phase.SinglePhase, listOf(1))
  val inverterSB3_0: Inverter = Inverter(InverterId(uuidFrom("ba37d13b-b9ca-402a-9aac-2841ea2b2cfd")), "SB 3.0", Capacity(3.0), InverterType.Phase.SinglePhase, listOf(1, 1))
  val inverterSB3_6: Inverter = Inverter(InverterId(uuidFrom("e490928f-12bc-48c8-8349-840aa37c6c50")), "SB 3.6", Capacity(3.7), InverterType.Phase.SinglePhase, listOf(1, 1))
  val inverterSB4_0: Inverter = Inverter(InverterId(uuidFrom("893d43fa-cf77-4802-b70c-38c5645ceff8")), "SB 4.0", Capacity(4.0), InverterType.Phase.SinglePhase, listOf(1, 1))
  val inverterSB5_0: Inverter = Inverter(InverterId(uuidFrom("679f6e60-ff8b-44f0-963c-d92d0048a64b")), "SB 5.0", Capacity(4.6), InverterType.Phase.SinglePhase, listOf(1, 1))

  /**
   * The prices for all available inverters
   */
  @Reselling
  val inverterInputPrices: InfoForType<Inverter, Money> = InfoForType {
    add(inverterSB1_5, 566.56.euro)
    add(inverterSB2_0, 671.30.euro)
    add(inverterSB2_5, 763.81.euro)
    add(inverterSB3_0, 854.31.euro)
    add(inverterSB3_6, 910.01.euro)
    add(inverterSB4_0, 954.45.euro)
    add(inverterSB5_0, 1_023.89.euro)
    add(Inverter(InverterId(uuidFrom("6ed9bc4e-abdc-4e78-9e19-440a6105a57b")), "STP 6.0", Capacity(6.0), InverterType.Phase.ThreePhase, listOf(1, 1)), 1_373.15.euro)
    add(Inverter(InverterId(uuidFrom("4f1d76d5-c197-4928-8d1e-fed158faf023")), "STP 8.0", Capacity(8.0), InverterType.Phase.ThreePhase, listOf(2, 1)), 1_647.26.euro)
    add(Inverter(InverterId(uuidFrom("00b97d10-f526-4766-bf5b-8275746acc87")), "STP 10.0", Capacity(10.0), InverterType.Phase.ThreePhase, listOf(2, 1)), 1_851.53.euro)
    add(Inverter(InverterId(uuidFrom("1734f783-0415-4d74-9f63-d02e9b0481c8")), "Sunny Tripower X 12", Capacity(12.0), InverterType.Phase.ThreePhase, listOf(2, 2, 2)), 2_134.33.euro)
    add(Inverter(InverterId(uuidFrom("42fdbe80-a775-4dd0-9276-7bd832403f6c")), "Sunny Tripower X 15", Capacity(15.0), InverterType.Phase.ThreePhase, listOf(2, 2, 2)), 2_278.41.euro)
    add(Inverter(InverterId(uuidFrom("b01007aa-1358-4879-ac0d-50fd08a67be0")), "Sunny Tripower X 20", Capacity(20.0), InverterType.Phase.ThreePhase, listOf(2, 2, 2)), 2_433.34.euro)
    add(Inverter(InverterId(uuidFrom("1c2d17f2-1eff-4ad8-aae3-97e565477bcc")), "Sunny Tripower X 25", Capacity(25.0), InverterType.Phase.ThreePhase, listOf(2, 2, 2)), 2_546.82.euro)

    add(Inverter(InverterId(uuidFrom("fb2cdf5a-e5ec-4c74-b8e2-1db6fb560a73")), "Core 1", Capacity(50.0), InverterType.Phase.ThreePhase, listOf(2, 2, 2, 2, 2, 2), LifeCycleState.EndOfLife), 4_491.62.euro)
    add(Inverter(InverterId(uuidFrom("5fcb366b-55b0-4ba7-b6b3-0dbab54a44a8")), "Core 2", Capacity(110.0), InverterType.Phase.ThreePhase, listOf(2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2), LifeCycleState.EndOfLife), 5_172.35.euro)
    add(Inverter(InverterId(uuidFrom("3c3e2a32-29e1-4f3b-8768-2499ae3002a3")), "STP 15.000TL", Capacity.Zero, InverterType.Phase.SinglePhase, listOf(), LifeCycleState.EndOfLife), 1798.25.euro)
    add(Inverter(InverterId(uuidFrom("98318900-b022-47e7-9cf1-8b37614dd981")), "STP 20.000TL", Capacity.Zero, InverterType.Phase.SinglePhase, listOf(), LifeCycleState.EndOfLife), 1939.05.euro)
    add(Inverter(InverterId(uuidFrom("07dac19c-178a-40d6-ad72-b036b8363b82")), "STP 25.000TL", Capacity.Zero, InverterType.Phase.SinglePhase, listOf(), LifeCycleState.EndOfLife), 1962.99.euro)
  }

  val hybridInverterSTP5_0: HybridInverter = HybridInverter(
    id = InverterId(uuidFrom("89721dd3-750c-43b9-ae60-1202f0ce047b")),
    description = "STP 5.0 Smart Energy",
    capacity = Capacity(5.0),
    dischargeCapacity = Capacity(6),
    phase = InverterType.Phase.ThreePhase,
    numberOfStringsForMppTrackerInput = listOf(1, 1),
  )
  val hybridInverterSTP6_0: HybridInverter = HybridInverter(
    id = InverterId(uuidFrom("1d5e5e6f-6342-4227-a56f-260899faeead")),
    description = "STP 6.0 Smart Energy",
    capacity = Capacity(6.0),
    dischargeCapacity = Capacity(7.2),
    phase = InverterType.Phase.ThreePhase,
    numberOfStringsForMppTrackerInput = listOf(1, 1),
  )
  val hybridInverterSTP8_0: HybridInverter = HybridInverter(
    id = InverterId(uuidFrom("1687a74f-93c0-4310-b118-bd2b986dc749")),
    description = "STP 8.0 Smart Energy",
    capacity = Capacity(8.0),
    dischargeCapacity = Capacity(10.6),
    phase = InverterType.Phase.ThreePhase,
    numberOfStringsForMppTrackerInput = listOf(1, 1),
  )
  val hybridInverterSTP10_0: HybridInverter = HybridInverter(
    id = InverterId(uuidFrom("2bc34719-6da6-4f28-b812-86b2bb59f9c8")),
    description = "STP 10.0 Smart Energy",
    capacity = Capacity(10.0),
    dischargeCapacity = Capacity(10.6),
    phase = InverterType.Phase.ThreePhase,
    numberOfStringsForMppTrackerInput = listOf(1, 2),
  )

  @Reselling
  val hybridInverterInputPrices: InfoForType<HybridInverter, Money> = InfoForType {
    add(hybridInverterSTP5_0, 566.56.euro)
    add(hybridInverterSTP6_0, 671.30.euro)
    add(hybridInverterSTP8_0, 763.81.euro)
    add(hybridInverterSTP10_0, 854.31.euro)
  }

  val batteryInverterSBStorage3_7: BasicBatteryInverter = BasicBatteryInverter(InverterId(uuidFrom("e9b0ac69-e84d-43ee-ac6d-ab8fab799d10")), "SB Storage 3.7", Capacity(3.68), InverterType.Phase.SinglePhase)
  val batteryInverterSBStorage5_0: BasicBatteryInverter = BasicBatteryInverter(InverterId(uuidFrom("12313ed4-357c-44dd-8ad7-c75a79bb5e8f")), "SB Storage 5.0", Capacity(4.6), InverterType.Phase.SinglePhase)

  val batteryInverterSunnyIsland4_4: BasicBatteryInverter = BasicBatteryInverter(InverterId(uuidFrom("4800ef9f-45d0-4148-8671-b250b4768d2d")), "SunnyIsland 4.4", Capacity(3.3), InverterType.Phase.SinglePhase)
  val batteryInverterSunnyIsland6_0: BasicBatteryInverter = BasicBatteryInverter(InverterId(uuidFrom("e17bcc46-3ed7-4b67-a03d-d1be3b50e79e")), "SunnyIsland 6.0", Capacity(4.6), InverterType.Phase.SinglePhase)

  @Reselling
  val batteryInverterInputPrices: InfoForType<BasicBatteryInverter, Money> = InfoForType {
    add(batteryInverterSBStorage3_7, 566.56.euro)
    add(batteryInverterSBStorage5_0, 671.30.euro)
    add(batteryInverterSunnyIsland4_4, 763.81.euro)
    add(batteryInverterSunnyIsland6_0, 854.31.euro)
  }


  val batteryBYDHVM8_3: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("c8d60e51-e9e9-45e0-b0f7-ef69a271db15")),
    description = "BYD Battery-Box Premium HVM 8.3",
    storageCapacity = BatteryCapacity(8.3.kWh),
    numberOfBatteryModules = 3,
    compatibleWithIndependenceManager = false,
  )
  val batteryBYDHVM11_0: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("d0d550cc-407f-4ae0-bdf3-5d0967e6beda")),
    description = "BYD Battery-Box Premium HVM 11.0",
    storageCapacity = BatteryCapacity(11.0.kWh),
    numberOfBatteryModules = 4,
    compatibleWithIndependenceManager = false,
  )
  val batteryBYDHVM13_8: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("a96666b9-294a-4c57-bb03-07f8f1765eb6")),
    description = "BYD Battery-Box Premium HVM 13.8",
    storageCapacity = BatteryCapacity(13.8.kWh),
    numberOfBatteryModules = 5,
    compatibleWithIndependenceManager = false,
  )
  val batteryBYDHVM16_6: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("17e189c2-daa4-4723-ac4f-31bc171de266")),
    description = "BYD Battery-Box Premium HVM 16.6",
    storageCapacity = BatteryCapacity(16.6.kWh),
    numberOfBatteryModules = 6,
    compatibleWithIndependenceManager = false,
  )
  val batteryBYDHVM19_3: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("1dd27bac-a2b5-4f80-ad17-1927bf37d80a")),
    description = "BYD Battery-Box Premium HVM 19.3",
    storageCapacity = BatteryCapacity(19.3.kWh),
    numberOfBatteryModules = 7,
    compatibleWithIndependenceManager = false,
  )
  val batteryBYDHVM22_1: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("43ccd0be-5f38-47e0-953d-c8e77d21f1dd")),
    description = "BYD Battery-Box Premium HVM 22.1",
    storageCapacity = BatteryCapacity(22.1.kWh),
    numberOfBatteryModules = 8,
    compatibleWithIndependenceManager = false,
  )

  val batteryBYDHVS5_1: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("d24ae12d-148f-4210-9cc5-8daef825d847")),
    description = "BYD Battery-Box Premium HVS 5.1",
    storageCapacity = BatteryCapacity(5.1.kWh),
    numberOfBatteryModules = 2,
    compatibleWithIndependenceManager = false,
  )
  val batteryBYDHVS7_7: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("7f9716a4-3800-464d-87af-298f2c3ff38c")),
    description = "BYD Battery-Box Premium HVS 7.7",
    storageCapacity = BatteryCapacity(7.7.kWh),
    numberOfBatteryModules = 3,
    compatibleWithIndependenceManager = false
  )
  val batteryBYDHVS10_2: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("4b2a84c8-edb6-415c-b5b5-447d25d22ee1")),
    description = "BYD Battery-Box Premium HVS 10.2",
    storageCapacity = BatteryCapacity(10.2.kWh),
    numberOfBatteryModules = 4,
    compatibleWithIndependenceManager = false
  )

  val batteryBYDLVS4_0: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("5a852ffb-4847-4516-83ce-c1318df04871")),
    description = "BYD Battery-Box Premium LVS M 4.0",
    storageCapacity = BatteryCapacity(4.0.kWh),
    numberOfBatteryModules = 1,
    compatibleWithIndependenceManager = true
  )
  val batteryBYDLVS8_0: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("84b974d8-d444-4f45-93d1-656fa6734176")),
    description = "BYD Battery-Box Premium LVS M 8.0",
    storageCapacity = BatteryCapacity(8.0.kWh),
    numberOfBatteryModules = 2,
    compatibleWithIndependenceManager = true
  )
  val batteryBYDLVS12_0: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("b6b6c8c2-5437-4860-a17c-456d9485132c")),
    description = "BYD Battery-Box Premium LVS M 12.0",
    storageCapacity = BatteryCapacity(12.0.kWh),
    numberOfBatteryModules = 3,
    compatibleWithIndependenceManager = true
  )
  val batteryBYDLVS16_0: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("08e80824-e220-4914-b555-e457f69a4dcd")),
    description = "BYD Battery-Box Premium LVS M 16.0",
    storageCapacity = BatteryCapacity(16.0.kWh),
    numberOfBatteryModules = 4,
    compatibleWithIndependenceManager = true
  )
  val batteryBYDLVS20_0: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("6059e3bb-48a8-40a2-8792-28e507ea84fd")),
    description = "BYD Battery-Box Premium LVS M 20.0",
    storageCapacity = BatteryCapacity(20.0.kWh),
    numberOfBatteryModules = 5,
    compatibleWithIndependenceManager = true
  )
  val batteryBYDLVS24_0: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("958f30b5-8a0a-4fb3-af92-38dad27f9401")),
    description = "BYD Battery-Box Premium LVS M 24.0",
    storageCapacity = BatteryCapacity(24.0.kWh),
    numberOfBatteryModules = 6,
    compatibleWithIndependenceManager = true
  )

  val batteryBYDLVL15_4: BatteryStorageType = BatteryStorageType(
    id = BatteryStorageTypeId(uuidFrom("9d99907e-7565-4153-9b42-c290a20d75d4")),
    description = "BYD Battery-Box Premium LVL M 15.4",
    storageCapacity = BatteryCapacity(15.4.kWh),
    numberOfBatteryModules = 1,
    compatibleWithIndependenceManager = true
  )

  sealed interface BatteryAndInverterType : HasUuid, HasLifeCycle {
    val battery: BatteryStorageType
    val inverter: BatteryInverter

    override val lifeCycleState: LifeCycleState
      get() = battery.lifeCycleState
  }

  data class BatteryAndInverter(override val uuid: Uuid, override val battery: BatteryStorageType, override val inverter: BasicBatteryInverter) : BatteryAndInverterType
  data class BatteryAndHybridInverter(override val uuid: Uuid, override val battery: BatteryStorageType, override val inverter: HybridInverter) : BatteryAndInverterType

  val batteryInputPrices: InfoForTypeBuilder<BatteryAndInverterType, Money> = InfoForTypeBuilder()
  val batteryWorkingTimes: InfoForTypeBuilder<BatteryAndInverterType, Duration> = InfoForTypeBuilder()

  val batteryExtensionInputPrices: InfoForTypeBuilder<BatteryStorageExtensionType, Money> = InfoForTypeBuilder()
  val batteryExtensionWorkingTimes: InfoForTypeBuilder<BatteryStorageExtensionType, Duration> = InfoForTypeBuilder()

  /**
   * Adds the values for this battery type to the builders
   */
  fun BatteryAndInverterType.add(price: Money, workingTime: Duration) {
    batteryInputPrices.add(this, price)
    batteryWorkingTimes.add(this, workingTime)
  }

  fun BatteryStorageExtensionType.add(price: Money, workingTime: Duration) {
    batteryExtensionInputPrices.add(this, price)
    batteryExtensionWorkingTimes.add(this, workingTime)
  }

  /**
   * The battery storage prices
   */
  @Reselling
  val batteryStorageInputPrices: InfoForType<BatteryAndInverterType, Money>

  /**
   * The time required to install the battery storages
   */
  @Reselling
  val batteryStorageWorkingTimes: InfoForType<BatteryAndInverterType, Duration>

  /**
   * The battery storage extension prices
   */
  @Reselling
  val batteryStorageExtensionInputPrices: InfoForType<BatteryStorageExtensionType, Money>

  /**
   * The time required to install the battery storage extensions
   */
  @Reselling
  val batteryStorageExtensionWorkingTimes: InfoForType<BatteryStorageExtensionType, Duration>


  init {
    val batteryPrices = InfoForTypeBuilder<BatteryAndInverterType, Money>()
    val batteryTimes = InfoForTypeBuilder<BatteryAndInverterType, Duration>()

    val batteryExtensionPrices = InfoForTypeBuilder<BatteryStorageExtensionType, Money>()
    val batteryExtensionTimes = InfoForTypeBuilder<BatteryStorageExtensionType, Duration>()

    /**
     * Adds the values for this battery type to the builders
     */
    fun BatteryAndInverterType.add(price: Money, workingTime: Duration) {
      batteryPrices.add(this, price)
      batteryTimes.add(this, workingTime)
    }

    BatteryAndInverter(uuidFrom("b6dcb8e2-3dcb-4fee-8982-9417fb1406c9"), batteryBYDHVM8_3, batteryInverterSBStorage3_7).add(5_376.54.euro, 11.hours)
    BatteryAndInverter(uuidFrom("b9a5ed14-f4db-481e-b954-79057b584f73"), batteryBYDHVM11_0, batteryInverterSBStorage3_7).add(6_387.60.euro, 11.5.hours)
    BatteryAndInverter(uuidFrom("8c39f1d8-1be0-457b-84dd-d951fc5d9d56"), batteryBYDHVM13_8, batteryInverterSBStorage3_7).add(7_398.66.euro, 12.hours)
    BatteryAndInverter(uuidFrom("5b2f2f27-481c-4ed2-85a0-f6e6094c5fc4"), batteryBYDHVM16_6, batteryInverterSBStorage5_0).add(8_598.49.euro, 12.5.hours)
    BatteryAndInverter(uuidFrom("a994f80e-3e27-4b38-9414-dc88b483b451"), batteryBYDHVM19_3, batteryInverterSBStorage5_0).add(9_609.55.euro, 13.hours)
    BatteryAndInverter(uuidFrom("103c4d78-370f-4dbc-89a9-bce6dfc5911b"), batteryBYDHVM22_1, batteryInverterSBStorage5_0).add(10_620.61.euro, 13.5.hours)

    BatteryAndInverter(uuidFrom("c899cca0-ab79-4031-b2f5-4a035d91cf20"), batteryBYDHVS5_1, batteryInverterSBStorage3_7).add(4_343.83.euro, 11.hours)
    BatteryAndInverter(uuidFrom("e8b44daa-1ee2-4572-91b7-e7748f7318da"), batteryBYDHVS7_7, batteryInverterSBStorage3_7).add(5_344.07.euro, 11.5.hours)
    BatteryAndInverter(uuidFrom("ff986a96-9833-45d8-b0d2-2f121e2edd06"), batteryBYDHVS10_2, batteryInverterSBStorage3_7).add(6_344.31.euro, 12.hours)

    BatteryAndInverter(uuidFrom("db5fe601-2cf0-4e7a-a79d-95592a1e3a08"), batteryBYDLVS4_0, batteryInverterSunnyIsland4_4).add(4_162.58.euro, 11.hours)
    BatteryAndInverter(uuidFrom("3b06a6c8-5ef4-434d-ab8e-4f847c7cba10"), batteryBYDLVS8_0, batteryInverterSunnyIsland4_4).add(5_469.75.euro, 11.5.hours)
    BatteryAndInverter(uuidFrom("f98ed12c-0854-4159-be4c-76d324b25275"), batteryBYDLVS12_0, batteryInverterSunnyIsland4_4).add(6_776.91.euro, 12.hours)
    BatteryAndInverter(uuidFrom("dc20dc09-2dd9-420a-bfd2-06239a61e363"), batteryBYDLVS16_0, batteryInverterSunnyIsland6_0).add(8_468.53.euro, 12.5.hours)
    BatteryAndInverter(uuidFrom("6842adf1-429c-431d-b887-caff2c397c43"), batteryBYDLVS20_0, batteryInverterSunnyIsland6_0).add(9_775.69.euro, 13.hours)
    BatteryAndInverter(uuidFrom("9600e925-14d3-4f17-b301-d256e4cad26f"), batteryBYDLVS24_0, batteryInverterSunnyIsland6_0).add(11_082.85.euro, 13.5.hours)

    BatteryAndInverter(uuidFrom("4386a537-4487-4190-ab67-bbecb4304e92"), batteryBYDLVL15_4, batteryInverterSunnyIsland4_4).add(7_283.18.euro, 12.5.hours)
    BatteryAndInverter(uuidFrom("5bc2439f-4a3b-4ef1-b71e-4fd4b4280d31"), batteryBYDLVL15_4, batteryInverterSunnyIsland6_0).add(7_667.63.euro, 12.5.hours)

    //Deprecated elements
    BatteryAndInverter(
      uuidFrom("0ac29d33-9b89-4090-81f9-18a82bddacc1"),
      BatteryStorageType(BatteryStorageTypeId(uuidFrom("0ac29d33-9b89-4090-81f9-18a82bddacc1")), "LG Chem 6.5", BatteryCapacity(6.5.kWh), compatibleWithIndependenceManager = false, numberOfBatteryModules = 0, lifeCycleState = LifeCycleState.EndOfLife),
      batteryInverterSunnyIsland4_4,
    ).add(4995.0.euro, 12.hours)
    BatteryAndInverter(
      uuidFrom("4c8174c5-d60a-4563-b4d5-e8870df656df"),
      BatteryStorageType(BatteryStorageTypeId(uuidFrom("4c8174c5-d60a-4563-b4d5-e8870df656df")), "LG Chem 10.0", BatteryCapacity(9.8.kWh), compatibleWithIndependenceManager = false, numberOfBatteryModules = 0, lifeCycleState = LifeCycleState.EndOfLife),
      batteryInverterSunnyIsland4_4,
    ).add(5782.0.euro, 12.hours)


    //With Hybrid-Inverter
    BatteryAndHybridInverter(uuidFrom("a5446780-407e-4b74-a3c1-7cffa85d347e"), batteryBYDHVM11_0, hybridInverterSTP10_0).add(7_383.61.euro, 11.hours)
    BatteryAndHybridInverter(uuidFrom("c07fb792-7085-4197-9c42-9302b5beef80"), batteryBYDHVM13_8, hybridInverterSTP10_0).add(8_394.67.euro, 11.5.hours)
    BatteryAndHybridInverter(uuidFrom("28bf7a60-9ae0-4e59-a3d3-111ad06e6e4a"), batteryBYDHVM16_6, hybridInverterSTP10_0).add(9_405.73.euro, 12.hours)
    BatteryAndHybridInverter(uuidFrom("269ed5d9-c93f-4870-b649-b796b694355a"), batteryBYDHVM19_3, hybridInverterSTP10_0).add(10_416.79.euro, 12.5.hours)
    BatteryAndHybridInverter(uuidFrom("0f64a643-3046-497f-8cc8-045ecdb72231"), batteryBYDHVM22_1, hybridInverterSTP10_0).add(11_427.85.euro, 13.hours)

    BatteryAndHybridInverter(uuidFrom("c25cc797-79b9-4736-95c2-4843efc98b6b"), batteryBYDHVS5_1, hybridInverterSTP10_0).add(5_339.85.euro, 10.hours)
    BatteryAndHybridInverter(uuidFrom("09343475-370c-487b-9a76-b5e9696bf3b2"), batteryBYDHVS7_7, hybridInverterSTP10_0).add(6_340.08.euro, 10.5.hours)
    BatteryAndHybridInverter(uuidFrom("b163b428-77b6-43ab-981d-970b476400d0"), batteryBYDHVS10_2, hybridInverterSTP10_0).add(7_340.32.euro, 11.hours)

    BatteryAndHybridInverter(uuidFrom("0689be97-c23e-400d-927d-0a9e6e2e238f"), batteryBYDHVM11_0, hybridInverterSTP8_0).add(7_115.86.euro, 11.hours)
    BatteryAndHybridInverter(uuidFrom("425e3c14-d9fa-4cc4-a817-53ae2872610c"), batteryBYDHVM13_8, hybridInverterSTP8_0).add(8_126.92.euro, 11.5.hours)
    BatteryAndHybridInverter(uuidFrom("1e3d860e-7363-407d-b7e8-fd06ec9bc4f9"), batteryBYDHVM16_6, hybridInverterSTP8_0).add(9_137.98.euro, 12.hours)
    BatteryAndHybridInverter(uuidFrom("113ac50b-627a-483a-a596-8a8d206b0218"), batteryBYDHVM19_3, hybridInverterSTP8_0).add(10_149.04.euro, 12.5.hours)
    BatteryAndHybridInverter(uuidFrom("5bb5e396-6270-4cd6-b912-e2db696e6f3c"), batteryBYDHVM22_1, hybridInverterSTP8_0).add(11_160.10.euro, 13.hours)

    BatteryAndHybridInverter(uuidFrom("ab7f7b3e-aad7-4d3d-ac12-a3f6999ca0c9"), batteryBYDHVS5_1, hybridInverterSTP8_0).add(5_072.09.euro, 10.hours)
    BatteryAndHybridInverter(uuidFrom("227b1733-de61-405e-854b-05aa88ef7387"), batteryBYDHVS7_7, hybridInverterSTP8_0).add(6_072.33.euro, 10.5.hours)
    BatteryAndHybridInverter(uuidFrom("7c080b8e-5274-483c-bc1c-c62e16c4632b"), batteryBYDHVS10_2, hybridInverterSTP8_0).add(7_072.57.euro, 11.hours)

    BatteryAndHybridInverter(uuidFrom("2ef27eeb-dfd1-460b-9cdd-38a6cebba5ed"), batteryBYDHVM11_0, hybridInverterSTP6_0).add(6_853.62.euro, 11.hours)
    BatteryAndHybridInverter(uuidFrom("ae022d2d-b069-45a6-945e-15f85037965a"), batteryBYDHVM13_8, hybridInverterSTP6_0).add(7_864.68.euro, 11.5.hours)
    BatteryAndHybridInverter(uuidFrom("23603efb-681b-489f-ad34-44c2ab03b0bf"), batteryBYDHVM16_6, hybridInverterSTP6_0).add(8_875.74.euro, 12.hours)
    BatteryAndHybridInverter(uuidFrom("639b0553-b408-4235-acda-c5c961b2a0b6"), batteryBYDHVM19_3, hybridInverterSTP6_0).add(9_886.80.euro, 12.5.hours)
    BatteryAndHybridInverter(uuidFrom("f52af45d-4293-4d17-85b1-92afc63fec2f"), batteryBYDHVM22_1, hybridInverterSTP6_0).add(10_897.86.euro, 13.hours)

    BatteryAndHybridInverter(uuidFrom("9ddf079e-6ec8-4898-9afa-47c7968a4528"), batteryBYDHVS5_1, hybridInverterSTP6_0).add(4_809.85.euro, 10.hours)
    BatteryAndHybridInverter(uuidFrom("b7e8ce1f-552b-48e0-a49e-97f01e223f3c"), batteryBYDHVS7_7, hybridInverterSTP6_0).add(5_810.08.euro, 10.5.hours)
    BatteryAndHybridInverter(uuidFrom("95f67df0-564f-4505-bd49-f5d7513fcb09"), batteryBYDHVS10_2, hybridInverterSTP6_0).add(6_810.32.euro, 11.hours)

    BatteryAndHybridInverter(uuidFrom("48a07589-00e9-4ccc-862e-3d5b40fad3fa"), batteryBYDHVM11_0, hybridInverterSTP5_0).add(6_720.14.euro, 11.hours)
    BatteryAndHybridInverter(uuidFrom("15c4fa2b-bc90-40fd-90b8-3f9c56f9dbec"), batteryBYDHVM13_8, hybridInverterSTP5_0).add(7_731.20.euro, 11.5.hours)
    BatteryAndHybridInverter(uuidFrom("f603fab5-72de-49da-8e40-72f47bfc2895"), batteryBYDHVM16_6, hybridInverterSTP5_0).add(8_742.26.euro, 12.hours)
    BatteryAndHybridInverter(uuidFrom("09eaade3-5f73-402f-a184-8cbdf5df7050"), batteryBYDHVM19_3, hybridInverterSTP5_0).add(9_753.32.euro, 12.5.hours)
    BatteryAndHybridInverter(uuidFrom("fe6f14db-d68c-4384-ba19-2879a685fb29"), batteryBYDHVM22_1, hybridInverterSTP5_0).add(10_764.38.euro, 13.hours)

    BatteryAndHybridInverter(uuidFrom("fd7a9089-2401-4c7d-9194-e3adae441529"), batteryBYDHVS5_1, hybridInverterSTP5_0).add(4_676.38.euro, 10.hours)
    BatteryAndHybridInverter(uuidFrom("fe493b7e-58d7-4401-aab3-cf3046de5196"), batteryBYDHVS7_7, hybridInverterSTP5_0).add(5_676.61.euro, 10.5.hours)
    BatteryAndHybridInverter(uuidFrom("676c3fc2-2359-4175-91b2-0d76aa94d5e1"), batteryBYDHVS10_2, hybridInverterSTP5_0).add(6_676.85.euro, 11.hours)

    batteryStorageInputPrices = batteryPrices.build()
    batteryStorageWorkingTimes = batteryTimes.build()


    fun BatteryStorageExtensionType.add(price: Money, workingTime: Duration) {
      batteryExtensionPrices.add(this, price)
      batteryExtensionTimes.add(this, workingTime)
    }

    //Add the extensions
    BatteryStorageExtensionType(
      id = BatteryStorageExtensionTypeId(uuidFrom("e6b80a46-c1d1-49d1-95db-977ac6ab38c9")),
      description = "BYD Battery-Box Premium LVL M 15.4 mit Parallelschalt-Set",
      storageCapacity = BatteryCapacity(15.4.kWh),
      numberOfBatteryModules = 1,
      extensionFor = setOf(batteryBYDLVL15_4)
    ).add(5_388.25.euro, 4.hours)

    batteryStorageExtensionInputPrices = batteryExtensionPrices.build()
    batteryStorageExtensionWorkingTimes = batteryExtensionTimes.build()
  }


  val ziegelDach: RoofType = RoofType(RoofTypeId(uuidFrom("bff1602e-3e42-47d9-abee-d031fa35dcfa")), "Ziegeldach", metalRoofTileCompatability = MetalRoofTileCompatability.Supported, withIsolation = true, requiresSparrenabstand = true, hasSparrenabstand = true)
  val biberschwanzDach: RoofType = RoofType(RoofTypeId(uuidFrom("8f8114b8-9edb-4038-80a8-3778e3d7d634")), "Biberschwanz", metalRoofTileCompatability = MetalRoofTileCompatability.Required, withIsolation = false, requiresSparrenabstand = true, hasSparrenabstand = true)

  /**
   * The price for the sub structure per module - depending on the roof type.
   * Only material!
   */
  @Reselling
  val substructurePerRoofTypeInputPrices: InfoForType<RoofType, @PerModule Money> = InfoForType {
    add(ziegelDach, 64.93.euro)
    add(RoofType(RoofTypeId(uuidFrom("17e5b6ee-7874-4f58-aafb-e1b94667440b")), "Trapez-Blechdach", metalRoofTileCompatability = MetalRoofTileCompatability.NotSupported, withIsolation = false, requiresSparrenabstand = true, hasSparrenabstand = false), 42.77.euro)
    add(RoofType(RoofTypeId(uuidFrom("ff8e8d98-95aa-4b93-a4d2-4dc77e396381")), "Stehfalzdach", metalRoofTileCompatability = MetalRoofTileCompatability.NotSupported, withIsolation = false, requiresSparrenabstand = true, hasSparrenabstand = false), 51.95.euro)
    add(RoofType(RoofTypeId(uuidFrom("777d940f-0634-44c4-889d-37ecf9799393")), "Stehfalzdach Kupfer", metalRoofTileCompatability = MetalRoofTileCompatability.NotSupported, withIsolation = false, requiresSparrenabstand = true, hasSparrenabstand = false), 61.75.euro)
    add(RoofType(RoofTypeId(uuidFrom("a3cd6525-0b97-4a27-870e-fd4de3d52881")), "Flachdach", metalRoofTileCompatability = MetalRoofTileCompatability.NotSupported, withIsolation = false, requiresSparrenabstand = false, hasSparrenabstand = true), 47.08.euro)
    add(RoofType(RoofTypeId(uuidFrom("64e5702d-cb25-45ea-9938-c00cb1841060")), "Unterkonstruktion mit Stockschrauben", metalRoofTileCompatability = MetalRoofTileCompatability.NotSupported, withIsolation = false, requiresSparrenabstand = true, hasSparrenabstand = true), 44.94.euro)
    add(biberschwanzDach, 118.66.euro)
    add(RoofType(RoofTypeId(uuidFrom("599cbc85-69e0-4d60-aaa8-a7fd24db6e11")), "Kalzipdach", metalRoofTileCompatability = MetalRoofTileCompatability.NotSupported, withIsolation = false, requiresSparrenabstand = true, hasSparrenabstand = false), 72.87.euro)

    add(RoofType(RoofTypeId(uuidFrom("f27b5543-e92d-49af-80cf-07cde7cea743")), "Blechstehfalzdach Kupfer", metalRoofTileCompatability = MetalRoofTileCompatability.NotSupported, withIsolation = false, requiresSparrenabstand = true, lifeCycleState = LifeCycleState.EndOfLife), 62.50.euro)
    add(RoofType(RoofTypeId(uuidFrom("7864a0d4-dc88-43b4-8c43-89671e749ace")), "Flachdach Bitumen", metalRoofTileCompatability = MetalRoofTileCompatability.NotSupported, withIsolation = false, requiresSparrenabstand = false, lifeCycleState = LifeCycleState.EndOfLife), 47.08.euro)
  }

  @Reselling
  @PerModule
  val roofIsolationThicknessPrices: RoofIsolationPrices = RoofIsolationPrices(
    inputPriceBelow4cm = 0.0.euro,
    inputPriceBelow6cm = 0.87.euro,
    inputPriceBelow10cm = 6.71.euro,
    inputPriceBelow14cm = 5.37.euro,
    inputPriceBelow16cm = 8.52.euro,
    inputPriceBelow18cm = 8.87.euro,
    inputPriceBelow20cm = 10.39.euro,
    inputPriceBelow22cm = 11.91.euro,
    inputPriceAbove22cm = 26.90.euro,
    surCharge = surCharges.constructionSurCharge,
  )

  val wallboxInstallationPriceBase: PriceWithProfit = PriceWithProfit.surCharge(375.00.euro, 40.percent)
  val wallboxInstallationPriceWorkingTime: Duration = 5.hours
  val wallboxInstallationPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(wallboxInstallationPriceBase, wallboxInstallationPriceWorkingTime, workingHourPrice)

  /**
   *
   */
  @Reselling
  val wallBoxInputPrices: InfoForType<Wallbox, Money> = InfoForType {
    add(
      Wallbox(
        id = WallboxId(uuidFrom("c7592ca3-5f88-4b7f-8e80-2198c623363c")),
        description = "Ladestation SMA EV Charger 22kW T2 5m",
        details = "Dreiphasige AC-Ladestation für schnelles und PV- optimiertes Laden mit einer konfigurierbaren Ladeleistung von 1,3 bis 22 kW von Elektrofahrzeugen. Inklusive 5 m Ladekabel mit Typ 2 Stecker.\n" +
            "Nur in Kombination mit dem Sunny Home Manager 2.0 möglich.",
        chargingCapacity = Wallbox.ChargingCapacity.KW22,
        cableLength = Wallbox.CableLength.Meters5,
        type = Wallbox.Type.Smart,
      ), 1_000.00.euro
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("9fa805b2-786c-44d5-a790-b553be7861cf")),
        description = "Ladestation SMA EV Charger 22kW T2 7,5m",
        details = "Dreiphasige AC-Ladestation für schnelles und PV- optimiertes Laden mit einer konfigurierbaren Ladeleistung von 1,3 bis 22 kW von Elektrofahrzeugen. Inklusive 7,5 m Ladekabel mit Typ 2 Stecker.\n" +
            "Nur in Kombination mit dem Sunny Home Manager 2.0 möglich.",
        chargingCapacity = Wallbox.ChargingCapacity.KW22,
        cableLength = Wallbox.CableLength.Meters75,
        type = Wallbox.Type.Smart,
      ), 1_324.16.euro
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("fe4553f5-4a78-4f99-9c5e-db0f482ee5e4")),
        description = "Ladestation SMA EV Charger 22kW T2 10m",
        details = "Dreiphasige AC-Ladestation für schnelles und PV- optimiertes Laden mit einer konfigurierbaren Ladeleistung von 1,3 bis 22 kW von Elektrofahrzeugen. Inklusive 10 m Ladekabel mit Typ 2 Stecker.\n" +
            "Nur in Kombination mit dem Sunny Home Manager 2.0 möglich.",
        chargingCapacity = Wallbox.ChargingCapacity.KW22,
        cableLength = Wallbox.CableLength.Meters10,
        type = Wallbox.Type.Smart,
      ), 1_381.78.euro
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("e896ecde-2a54-4381-a570-1bebae33d872")),
        description = "Ladestation SMA EV Charger 7,4kW T2 5m",
        details = "Einphasige AC-Ladestation für schnelles und PV- optimiertes Laden mit einer konfigurierbaren Ladeleistung von 1,3 bis 7,4 kW von Elektrofahrzeugen. Inklusive 5 m Ladekabel mit Typ 2 Stecker.\n" +
            "Nur in Kombination mit dem Sunny Home Manager 2.0 möglich.",
        chargingCapacity = Wallbox.ChargingCapacity.KW74,
        cableLength = Wallbox.CableLength.Meters5,
        type = Wallbox.Type.Smart,
      ), 955.60.euro
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("1d624a50-1eb4-4ec6-93f4-d15371dfefcb")),
        description = "Ladestation SMA EV Charger 7,4kW T2 7,5m",
        details = "Einphasige AC-Ladestation für schnelles und PV- optimiertes Laden mit einer konfigurierbaren Ladeleistung von 1,3 bis 7,4 kW von Elektrofahrzeugen. Inklusive 7,5 m Ladekabel mit Typ 2 Stecker.\n" +
            "Nur in Kombination mit dem Sunny Home Manager 2.0 möglich.",
        chargingCapacity = Wallbox.ChargingCapacity.KW74,
        cableLength = Wallbox.CableLength.Meters75,
        type = Wallbox.Type.Smart,
      ), 1_019.19.euro
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("c3672491-4a5d-41a9-bde2-e257d36114cd")),
        description = "Ladestation SMA EV Charger 7,4kW T2 10m",
        details = "Einphasige AC-Ladestation für schnelles und PV- optimiertes Laden mit einer konfigurierbaren Ladeleistung von 1,3 bis 7,4 kW von Elektrofahrzeugen. Inklusive 10 m Ladekabel mit Typ 2 Stecker.\n" +
            "Nur in Kombination mit dem Sunny Home Manager 2.0 möglich.",
        chargingCapacity = Wallbox.ChargingCapacity.KW74,
        cableLength = Wallbox.CableLength.Meters10,
        type = Wallbox.Type.Smart,
      ), 1_053.48.euro
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("a333d548-dc50-4d32-995a-7644f7820089")),
        description = "Ladestation Heidelberg Energy Control 11kW T2 5m",
        details = "Dreiphasige Wallbox mit einer maximalen Ladeleistung von 11kW inklusive einem 5 m Ladekabel mit Typ 2 Stecker.",
        chargingCapacity = Wallbox.ChargingCapacity.KW11,
        cableLength = Wallbox.CableLength.Meters5
      ), 456.99.euro
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("4f169ee0-ce8e-49f6-97e6-ce473d893627")),
        description = "Ladestation Heidelberg Energy Control 11kW T2 7,5m",
        details = "Dreiphasige Wallbox mit einer maximalen Ladeleistung von 11kW inklusive einem 7,5 m Ladekabel mit Typ 2 Stecker.",
        chargingCapacity = Wallbox.ChargingCapacity.KW11,
        cableLength = Wallbox.CableLength.Meters75
      ), 506.99.euro
    )

    add(
      Wallbox(
        id = WallboxId(uuidFrom("9f68e393-aede-4ee6-be67-bef63f2312f8")),
        description = "Ladestation ABL eMH1  11kW T2 6,35m",
        details = "Dreiphasige Wallbox mit einer maximalen Ladeleistung von 11kW inklusive einem 6,35 m Ladekabel mit Typ 2 Stecker.",
        chargingCapacity = Wallbox.ChargingCapacity.KW11,
        cableLength = Wallbox.CableLength.Meters5,
        lifeCycleState = LifeCycleState.EndOfLife,
      ),
      461.99.euro,
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("1e5a1dd8-ce2c-4790-adef-83f5886c42c7")),
        description = "Heidelberg Energy Control",
        details = null,
        chargingCapacity = Wallbox.ChargingCapacity.KW11,
        cableLength = Wallbox.CableLength.Meters5,
        lifeCycleState = LifeCycleState.EndOfLife,
      ),
      650.00.euro,
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("1f981fe2-0ea0-4494-9b2d-ce4916ec3eaa")),
        description = "ABL eMH1",
        details = null,
        chargingCapacity = Wallbox.ChargingCapacity.KW11,
        cableLength = Wallbox.CableLength.Meters5,
        lifeCycleState = LifeCycleState.EndOfLife,
      ),
      650.00.euro,
    )
    add(
      Wallbox(
        id = WallboxId(uuidFrom("9e7de884-85de-47f6-a29c-8d628c54d391")),
        description = "SMA EV Charger",
        details = "Dreiphasige AC-Ladestation für schnelles und PV-optimiertes Laden",
        chargingCapacity = Wallbox.ChargingCapacity.KW22,
        cableLength = Wallbox.CableLength.Meters5,
        type = Wallbox.Type.Smart,
        lifeCycleState = LifeCycleState.EndOfLife,
      ),
      1180.00.euro,
    )
  }

  /**
   * The price per module depending on the difficulty of the assembly
   */
  val assemblyDifficultyWorkingTimes: InfoForType<AssemblyDifficulty, Duration> = InfoForType {
    add(PriceListInfo.assemblyDifficultyEasy, 1.3.hours)
    add(PriceListInfo.assemblyDifficultyMedium, 1.8666.hours)
    add(PriceListInfo.assemblyDifficultyHard, 2.38.hours)
  }

  /**
   * The prices for the metal roof tiles
   */
  @Reselling
  val metalRoofTileInputPrices: InfoForType<MetalRoofTile, Money> = LizergyAvailableRoofTilesFactory.createPrices()
  val aufschlagColoredMetalPlatesInputsPrices: InfoForType<MetalRoofTileColor, Money> = InfoForType {
    add(MetalRoofTileColor(MetalRoofTileColorId(uuidFrom("7e6f27f0-988d-421c-b452-9dac46d59fca")), "Rot"), 4.42.euro)
    add(MetalRoofTileColor(MetalRoofTileColorId(uuidFrom("94098bd7-b47e-45b7-9383-5bb20500615b")), "Braun"), 4.42.euro)
    add(MetalRoofTileColor(MetalRoofTileColorId(uuidFrom("38299ce0-b3f9-437e-8b13-89d04ff757e8")), "Schwarz-Grau"), 4.42.euro)
  }

  @Reselling
  val monitoringSellingPrices: MonitoringPrices = MonitoringPrices(
    priceBelow10kWp = PriceWithProfit(50.00.euro, 115.00.euro),
    priceBelow20kWp = PriceWithProfit(50.00.euro, 138.00.euro),
    priceBelow30kWp = PriceWithProfit(50.00.euro, 161.00.euro),
    priceElse = PriceWithProfit(50.00.euro, 230.00.euro),
    additionalCostsWithBattery = PriceWithProfit(50.00.euro, 92.00.euro)
  )

  val scaffoldingWorkingTime: Duration = 0.17.hours
  val scaffoldingPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(PriceWithProfit.Zero, scaffoldingWorkingTime, workingHourPrice)

  val sitePreparationInputPrice: Money = 690.00.euro
  val sitePreparationPrice: PriceWithProfit = PriceWithProfit.noProfit(sitePreparationInputPrice)

  val dachStaenderIsolierungInputPrice: PriceWithProfit = PriceWithProfit.noProfit(350.00.euro)
  val dachStaenderIsolierungWorkingTime: Duration = 1.hours
  val dachStaenderIsolierungPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(dachStaenderIsolierungInputPrice, dachStaenderIsolierungWorkingTime, workingHourPrice)

  @Reselling
  val ueberSpannungsSchutzInputPrice: PriceWithProfit = PriceWithProfit.surCharge(81.00.euro, surCharges.constructionSurCharge)
  val ueberSpannungsSchutzWorkingTime: Duration = 0.66.hours
  val ueberSpannungsSchutzPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(ueberSpannungsSchutzInputPrice, ueberSpannungsSchutzWorkingTime, workingHourPrice)

  val ueberSpannungsSchutzExtraWennExternerBlitzschutzVorhandenInputPrice: PriceWithProfit = PriceWithProfit.surCharge(121.00.euro, surCharges.constructionSurCharge)
  val ueberSpannungsSchutzExtraWennExternerBlitzschutzVorhandenWorkingTIme: Duration = 0.66.hours
  val ueberSpannungsSchutzExtraWennExternerBlitzschutzVorhandenPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(ueberSpannungsSchutzExtraWennExternerBlitzschutzVorhandenInputPrice, ueberSpannungsSchutzExtraWennExternerBlitzschutzVorhandenWorkingTIme, workingHourPrice)

  val moveSatelliteDishInputPrice: PriceWithProfit = PriceWithProfit.surCharge(50.00.euro, surCharges.constructionSurCharge)
  val moveSatelliteDishWorkingTime: Duration = 5.hours
  val moveSatelliteDishPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(moveSatelliteDishInputPrice, moveSatelliteDishWorkingTime, workingHourPrice)

  val removeAntennaWorkingTime: Duration = 1.5.hours
  val removeAntennaPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(PriceWithProfit.Zero, removeAntennaWorkingTime, workingHourPrice)

  val integrateInverterIntoNetworkInputPrice: PriceWithProfit = PriceWithProfit.surCharge(50.00.euro, surCharges.constructionSurCharge)
  val integrateInverterIntoNetworkWorkingTime: Duration = 2.5.hours
  val integrateInverterIntoNetworkPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(integrateInverterIntoNetworkInputPrice, integrateInverterIntoNetworkWorkingTime, workingHourPrice)

  val perKilometerTravelledInputPrice: Money = 1.30.euro
  val perKilometerTravelledPrice: PriceWithProfit = PriceWithProfit.surCharge(perKilometerTravelledInputPrice, surCharges.constructionSurCharge)

  @Reselling
  val optimizerInputPrice: PriceWithProfit = PriceWithProfit.surCharge(48.00.euro, surCharges.constructionSurCharge)
  val optimizerWorkingTime: Duration = 0.1.hours
  val optimizerPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(optimizerInputPrice, optimizerWorkingTime, workingHourPrice)

  val zuschlagKabelwegInputPrice: PriceWithProfit = PriceWithProfit.surCharge(5.00.euro, surCharges.constructionSurCharge)
  val zuschlagKabelwegWorkingTime: Duration = 0.15.hours
  val zuschlagKabelwegPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(zuschlagKabelwegInputPrice, zuschlagKabelwegWorkingTime, workingHourPrice)

  @PerModule
  val aufschlagKreuzverbundInputPrice: PriceWithProfit = PriceWithProfit.surCharge(25.39.euro, surCharges.constructionSurCharge)
  val aufschlagKreuzverbundWorkingTime: Duration = 0.1.hours
  val aufschlagKreuzverbundPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(aufschlagKreuzverbundInputPrice, aufschlagKreuzverbundWorkingTime, workingHourPrice)

  /**
   * The starting up price
   */
  val startingUpInputPrice: PriceWithProfit = PriceWithProfit.surCharge(350.0.euro, surCharges.constructionSurCharge)
  val startingUpWorkingTime: Duration = 10.hours
  val startingUpPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(startingUpInputPrice, startingUpWorkingTime, workingHourPrice)

  val sunnyHomeManagerInputPrice: PriceWithProfit = PriceWithProfit.surCharge(558.94.euro, surCharges.constructionSurCharge)
  val sunnyHomeManagerWorkingTime: Duration = 3.hours
  val sunnyHomeManagerPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(sunnyHomeManagerInputPrice, sunnyHomeManagerWorkingTime, workingHourPrice)

  /**
   * The prices for all available inverters
   */
  @Reselling
  val heaterRodInputPrices: InfoForType<HeaterRod, MoneyAndTime> = InfoForType {
    add(
      HeaterRod(
        id = HeaterRodId(uuidFrom("56db844d-f77a-4dc5-b841-348c9ce5b747")),
        description = "Heizstab myPV AC ELWA-2",
        heatingCapacity = HeaterRod.HeatingCapacity.KW3_5,
        length = 46.0,
        diameter = 1.5,
      ),
      MoneyAndTime(630.72.euro, 3.hours),
    )
    add(
      HeaterRod(
        id = HeaterRodId(uuidFrom("223e4b8b-6b2d-4524-ad23-a4da2d39fbef")),
        description = "Heizstab myPV AC elwa-e",
        heatingCapacity = HeaterRod.HeatingCapacity.KW3,
        length = 45.0,
        diameter = 1.5,
        lifeCycleState = LifeCycleState.EndOfLife,
      ),
      MoneyAndTime(560.38.euro, 3.hours),
    )
  }

  val waermepumpenanbindungInputPrice: PriceWithProfit = PriceWithProfit.surCharge(273.26.euro, surCharges.waermepumpenSurCharge)
  val waermepumpenanbindungWorkingTime: Duration = 6.hours
  val waermepumpenanbindungPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(waermepumpenanbindungInputPrice, waermepumpenanbindungWorkingTime, workingHourPrice)

  val smaEnergyMeterInputPrice: PriceWithProfit = PriceWithProfit.surCharge(264.87.euro, surCharges.energyMeterSurCharge)
  val smaEnergyMeterWorkingTime: Duration = 2.hours
  val smaEnergyMeterPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(smaEnergyMeterInputPrice, smaEnergyMeterWorkingTime, workingHourPrice)

  val erdspiessSetzenInputPrice: PriceWithProfit = PriceWithProfit.surCharge(132.07.euro, surCharges.energyMeterSurCharge)
  val erdspiessSetzenWorkingTime: Duration = 4.hours
  val erdspiessSetzenPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(erdspiessSetzenInputPrice, erdspiessSetzenWorkingTime, workingHourPrice)

  val slsNachruestenInputPrice: PriceWithProfit = PriceWithProfit.surCharge(85.00.euro, surCharges.constructionSurCharge)
  val slsNachruestenWorkingTime: Duration = 3.hours
  val slsNachruestenPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(slsNachruestenInputPrice, slsNachruestenWorkingTime, workingHourPrice)

  val neuerZaehlerschrankInputPrice: PriceWithProfit = PriceWithProfit.surCharge(2_180.00.euro, surCharges.constructionSurCharge)
  val neuerZaehlerschrankWorkingTime: Duration = 16.hours
  val neuerZaehlerschrankPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(neuerZaehlerschrankInputPrice, neuerZaehlerschrankWorkingTime, workingHourPrice)

  val zaehlerschrankZusammenlegenInputPrice: PriceWithProfit = PriceWithProfit.surCharge(50.00.euro, surCharges.constructionSurCharge)
  val zaehlerschrankZusammenlegenWorkingTime: Duration = 3.hours
  val zaehlerschrankZusammenlegenPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(zaehlerschrankZusammenlegenInputPrice, zaehlerschrankZusammenlegenWorkingTime, workingHourPrice)

  val einbauUnterverteilerInputPrice: PriceWithProfit = PriceWithProfit.surCharge(150.00.euro, surCharges.constructionSurCharge)
  val einbauUnterverteilerWorkingTime: Duration = 4.hours
  val einbauUnterverteilerPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(einbauUnterverteilerInputPrice, einbauUnterverteilerWorkingTime, workingHourPrice)

  val einbauDigitalerZaehlerInputPrice: PriceWithProfit = PriceWithProfit.surCharge(113.42.euro, surCharges.constructionSurCharge)
  val einbauDigitalerZaehlerWorkingTime: Duration = 1.5.hours
  val einbauDigitalerZaehlerPrice: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(einbauDigitalerZaehlerInputPrice, einbauDigitalerZaehlerWorkingTime, workingHourPrice)

  @PerModule
  val aufpreisSchienensystemInputPrice: Money = 10.99.euro
  val aufpreisSchienensystemPrice: PriceWithProfit = PriceWithProfit.surCharge(aufpreisSchienensystemInputPrice, surCharges.energyMeterSurCharge)

  @PerModule
  val aufpreisSchwarzeKlemmeInputPrice: Money = 1.82.euro
  val aufpreisSchwarzeKlemmePrice: PriceWithProfit = PriceWithProfit.surCharge(aufpreisSchwarzeKlemmeInputPrice, surCharges.klemmenSurCharge)

  val blitzschutzIntegrierenPrice: PriceWithProfit = PriceWithProfit.surCharge(900.00.euro, surCharges.constructionSurCharge)

  @Reselling
  val independenceManagerTypeInputPricesWithHigherSurCharge: InfoForType<IndependenceManagerType, MoneyAndTime> = InfoForType {
    add(
      LizergyIndependenceManagerType(
        id = IndependenceManagerId(uuidFrom("d69fcc30-8d04-442c-8fff-83cef60f7905")),
        description = "Ersatzstromsystem in Kombination mit SMA Smart Energy Serie (Hybrid-Wechselrichter)",
        details = """
          Das Ersatzstromsystem baut mit Hilfe der PV-Anlage und des Batteriespeichers eine Stromversorgung für ausgewählte Verbraucher auf. Es beinhaltet keine USV (Unterbrechungsfreie Stromversorgung) und muss im Ersatzstromfall manuell über einen im Zählerplatz liegenden Umschalter eingeschalten werden. Die Auslegung der PV-Anlage muss ggf. angepasst werden. Nach Auftragsbestätigung kundenseitig und vor Zusage seitens Lizergy muss die elektrische Anlage vor Ort auf Machbarkeit durch einen unserer Mitarbeiter überprüft werden. Durch die Prüfung ihrer Installation auf Ersatzstromfähigkeit ergeben sich ggf. notwendig werdende Änderungen, diese werden nach tatsächlichem Aufwand abgerechnet. Ggf. fallen dadurch Mehrkosten an.
        """.trimIndent(),
        requiredInverterTypes = listOf(setOf(hybridInverterSTP5_0, hybridInverterSTP6_0, hybridInverterSTP8_0, hybridInverterSTP10_0)),
      ), MoneyAndTime(480.00.euro, 16.hours)
    )
  }

  @Reselling
  val independenceManagerTypeInputPrices: InfoForType<IndependenceManagerType, MoneyAndTime> = InfoForType {
    add(
      LizergyIndependenceManagerType(
        id = IndependenceManagerId(uuidFrom("eb446b84-f940-4204-8696-51d31633f420")),
        description = "Ersatzstromsystem in Kombination mit SMA SunnyIsland Batterie-Wechselrichtern",
        details = """
          Das Ersatzstromsystem baut mit Hilfe der PV-Anlage und des Batteriespeichers eine Stromversorgung auf (nur für Wechselstromverbraucher). Es beinhaltet keine USV (Unterbrechungsfreie Stromversorgung). Die Auslegung der PV-Anlage muss ggf. angepasst werden. Nach Auftragsbestätigung kundenseitig und vor Zusage seitens Lizergy muss die elektrische Anlage vor Ort auf Machbarkeit durch einen Elektromeister überprüft werden. Durch die Prüfung ihrer Installation auf Ersatzstromfähigkeit ergeben sich ggf. notwendig werdende Änderungen, diese werden nach tatsächlichem Aufwand abgerechnet. Ggf. fallen dadurch Mehrkosten an.
        """.trimIndent(),
        requiredInverterTypes = buildList {
          //Requires normal inverter...
          add(setOf(inverterSB1_5, inverterSB2_0, inverterSB2_5, inverterSB3_0, inverterSB3_6, inverterSB4_0, inverterSB5_0))
          //...and battery inverter
          add(setOf(batteryInverterSunnyIsland4_4, batteryInverterSunnyIsland6_0))
        },
      ), MoneyAndTime(1_100.00.euro, 12.hours)
    )
    add(
      OtherIndependenceManagerType(
        id = IndependenceManagerId(uuidFrom("757adbe7-f53d-4441-8e21-e34eafae83b4")),
        description = "Ersatzstromsystem für einzelne Verbraucher mit Smart Energy Serie",
        details = """
          Das Ersatzstromsystem baut automatisiert mit Hilfe der PV-Anlage und des Batteriespeichers eine Stromversorgung für bis zu 3 Verbraucher auf. Es beinhaltet keine USV (Unterbrechungsfreie Stromversorgung). Die Auslegung der PV-Anlage muss ggf. angepasst werden.
        """.trimIndent(),
        requiredInverterTypes = listOf(setOf(hybridInverterSTP5_0, hybridInverterSTP6_0, hybridInverterSTP8_0, hybridInverterSTP10_0)),
      ), MoneyAndTime(321.00.euro, 6.hours)
    )
    add(
      OtherIndependenceManagerType(
        id = IndependenceManagerId(uuidFrom("498903c9-81bf-4d91-91b0-4c911ad389eb")),
        description = "Steckdose für Ersatzstromsystem mit Sunny Boy Storage",
        details = """
          Nur in Kombination mit einen Sunny Boy Storage möglich. Über eine Steckdose wird mit Hilfe  des Batteriespeichers ein Ersatzstromsystem (nur für Wechselstromverbraucher) aufgebaut. Die Steckdose führt nur Spannung bis der Batteriespeicher leer ist. Der Batteriespeicher wird im Ersatzstromfall nicht nachgeladen. Es beinhaltet keine USV (Unterbrechungsfreie Stromversorgung).
        """.trimIndent(),
        requiredInverterTypes = listOf(setOf(batteryInverterSBStorage3_7, batteryInverterSBStorage5_0)),
      ), MoneyAndTime(50.00.euro, 3.hours)
    )
  }

  /**
   * Calculates the price list
   */
  fun createPriceList(): StaticPriceList {

    val moduleSellingPrices: Prices<PvModule> = moduleInputPrices.map { _, inputPrice ->
      PriceWithProfit.surCharge(inputPrice, surCharges.modulesSurCharge)
    }

    val inverterSellingPrices: InfoForType<Inverter, PriceWithProfit> = inverterInputPrices.map { _, inputPrice ->
      PriceWithProfit.surCharge(inputPrice, surCharges.inverterSurCharge)
    }
    val hybridInverterSellingPrices: InfoForType<HybridInverter, PriceWithProfit> = hybridInverterInputPrices.map { _, inputPrice ->
      PriceWithProfit.surCharge(inputPrice, surCharges.inverterSurCharge)
    }
    val batteryInverterSellingPrices: InfoForType<BasicBatteryInverter, PriceWithProfit> = batteryInverterInputPrices.map { _, inputPrice ->
      PriceWithProfit.surCharge(inputPrice, surCharges.inverterSurCharge)
    }

    val batteryStorageWorkingTimePrices: InfoForType<BatteryAndInverterType, WorkingTimesWithPrice> = batteryStorageWorkingTimes.map { _, workingTime ->
      WorkingTimesWithPrice(workingTime, workingHourPrice)
    }

    val batteryStorageExtensionWorkingTimePrices: InfoForType<BatteryStorageExtensionType, WorkingTimesWithPrice> = batteryStorageExtensionWorkingTimes.map { _, workingTime ->
      WorkingTimesWithPrice(workingTime, workingHourPrice)
    }

    /**
     * Returns the extensions for the given battery storage type id
     */
    fun availableBatteryStorageExtensionsFor(battery: BatteryStorageType): List<BatteryStorageExtensionType> {
      return batteryStorageExtensionInputPrices.available.filter { batteryStorageExtensionType ->
        batteryStorageExtensionType.extensionFor.contains(battery)
      }
    }


    //Create the battery storage material prices
    val batteryConfigurationPrices: InfoForType<BatteryConfiguration, PriceWithProfitAndWorkingTime> = createBatteryConfigurationPrices(batteryStorageInputPrices, batteryStorageWorkingTimePrices, batteryStorageExtensionWorkingTimePrices)


    @PerModule
    val substructurePerRoofTypeSellingPrices = substructurePerRoofTypeInputPrices.map { _, inputPrice ->
      PriceWithProfit.surCharge(inputPrice, surCharges.substructureSurCharge)
    }

    val assemblyDifficultySellingPrices = assemblyDifficultyWorkingTimes.map { _, duration ->
      workingHourPrice * duration
    }

    val heaterRodSellingPrices: InfoForType<HeaterRod, PriceWithProfitAndWorkingTime> = heaterRodInputPrices.map { _, inputMoneyAndTime ->
      val materialPrice = PriceWithProfit.surCharge(inputMoneyAndTime.money, surCharges.constructionSurCharge)
      PriceWithProfitAndWorkingTime(materialPrice, inputMoneyAndTime.time, workingHourPrice)
    }

    val wallBoxesSellingPrices = wallBoxInputPrices.map { _, inputPrice ->
      PriceWithProfit.surCharge(inputPrice, surCharges.wallBoxesSurCharge)
    }

    val metalRoofTilesSellingPrices = metalRoofTileInputPrices.map { _, inputPrice ->
      PriceWithProfit.surCharge(inputPrice, surCharges.metalRoofTilesSurCharge)
    }

    val aufschlagColoredMetalPlatesSellingPrices = aufschlagColoredMetalPlatesInputsPrices.map { _, inputPrice ->
      PriceWithProfit.surCharge(inputPrice, surCharges.metalRoofTilesSurCharge)
    }

    val independenceManagerTypeSellingPrices: InfoForType<IndependenceManagerType, PriceWithProfitAndWorkingTime> = InfoForType {
      independenceManagerTypeInputPricesWithHigherSurCharge.forEach { independenceManagerType, inputMoneyAndTime ->
        val materialPrice = PriceWithProfit.surCharge(inputMoneyAndTime.money, surCharges.specialSurCharge)
        add(independenceManagerType, PriceWithProfitAndWorkingTime(materialPrice, inputMoneyAndTime.time, workingHourPrice))
      }
      independenceManagerTypeInputPrices.forEach { independenceManagerType, inputMoneyAndTime ->
        val materialPrice = PriceWithProfit.surCharge(inputMoneyAndTime.money, surCharges.inverterSurCharge)
        add(independenceManagerType, PriceWithProfitAndWorkingTime(materialPrice, inputMoneyAndTime.time, workingHourPrice))
      }
    }

    return StaticPriceList(
      priceListId = PriceListId(uuidFrom(string = "cf7c0880-3fd9-43e9-b28e-70564af0dbc6")),
      modulePrices = moduleSellingPrices,
      inverterPrices = inverterSellingPrices,
      hybridInverterPrices = hybridInverterSellingPrices,
      batteryInverterPrices = batteryInverterSellingPrices,
      batteryConfigurationPrices = batteryConfigurationPrices,
      assemblyCostsPerModule = substructurePerRoofTypeSellingPrices,
      metalRoofTilePrices = metalRoofTilesSellingPrices,
      aufschlagColoredMetalPlatesPrices = aufschlagColoredMetalPlatesSellingPrices,
      assemblyDifficultyPrices = assemblyDifficultySellingPrices,
      wallboxInstallationPrice = wallboxInstallationPrice,
      wallBoxPrices = wallBoxesSellingPrices,
      monitoringPrices = monitoringSellingPrices,
      scaffolding = scaffoldingPrice,
      optionalDiscountPrices = OptionalDiscountPrices.lizergyDefault,
      dachStaenderIsolierung = dachStaenderIsolierungPrice,
      ueberSpannungsSchutz = ueberSpannungsSchutzPrice,
      ueberSpannungsSchutzaWennExternerBlitzschutzVorhanden = ueberSpannungsSchutzExtraWennExternerBlitzschutzVorhandenPrice,
      blitzschutzIntegrieren = blitzschutzIntegrierenPrice,
      moveSatelliteDish = moveSatelliteDishPrice,
      removeAntenna = removeAntennaPrice,
      workingHoursPerDay = workingHoursPerDay,
      perKilometerTravelled = perKilometerTravelledPrice,
      optimizerPrice = optimizerPrice,
      zuschlagKabelweg = zuschlagKabelwegPrice,
      aufschlagKreuzverbund = aufschlagKreuzverbundPrice,
      startingUpPrice = startingUpPrice,
      assemblyDifficultyWorkingTimes = assemblyDifficultyWorkingTimes,
      availableRoofTiles = LizergyAvailableRoofTilesFactory.create(),
      sitePreparation = sitePreparationPrice,
      integrateInverterIntoNetwork = integrateInverterIntoNetworkPrice,
      sunnyHomeManager = sunnyHomeManagerPrice,
      heaterRodPrices = heaterRodSellingPrices,
      waermepumpenanbindung = waermepumpenanbindungPrice,
      smaEnergyMeter = smaEnergyMeterPrice,
      erdspiessSetzen = erdspiessSetzenPrice,
      slsNachruesten = slsNachruestenPrice,
      neuerZaehlerschrank = neuerZaehlerschrankPrice,
      zaehlerschrankZusammenlegen = zaehlerschrankZusammenlegenPrice,
      einbauUnterverteiler = einbauUnterverteilerPrice,
      roofIsolationThicknessPrices = roofIsolationThicknessPrices,
      aufpreisSchienensystemPrice = aufpreisSchienensystemPrice,
      aufpreisSchwarzeKlemmePrice = aufpreisSchwarzeKlemmePrice,
      einbauDigitalerZaehlerPrice = einbauDigitalerZaehlerPrice,
      independenceManagerTypePrices = independenceManagerTypeSellingPrices,
    )
  }

}


fun createBatteryConfigurationPrices(
  batteryStorageInputPrices: InfoForType<BatteryAndInverterType, Money>,
  batteryStorageWorkingTimePrices: InfoForType<BatteryAndInverterType, WorkingTimesWithPrice>,
  batteryStorageExtensionWorkingTimePrices: InfoForType<BatteryStorageExtensionType, WorkingTimesWithPrice>,
): InfoForType<BatteryConfiguration, PriceWithProfitAndWorkingTime> {
  return InfoForType(
    batteryStorageInputPrices.available.flatMap { batteryAndInverter ->
      //The base price
      val priceForBatteryStorage = PriceWithProfit.surCharge(batteryStorageInputPrices.value(batteryAndInverter), surCharges.batteryStorageSurCharge)
      val workForBatteryStorage = batteryStorageWorkingTimePrices.value(batteryAndInverter)
      val priceAndWorkForBatteryStorage = PriceWithProfitAndWorkingTime(priceForBatteryStorage, workForBatteryStorage)

      //Calculate the UUID for the configuration *without* any extension
      val newId = BatteryConfigurationId(batteryAndInverter.uuid.xor(351351351351351135L, 651238907123409871L))

      //Only the battery - without any extension
      sequenceOf(BatteryConfiguration(id = newId, battery = batteryAndInverter.battery, extension = null, inverterType = batteryAndInverter.inverter) to priceAndWorkForBatteryStorage)
        .plus(
          //Find the extensions
          availableBatteryStorageExtensionsFor(batteryAndInverter.battery).map { extensionType ->
            //Create a combination with each possible extension

            val extensionPrice = PriceWithProfit.surCharge(batteryStorageExtensionInputPrices.value(extensionType), surCharges.batteryStorageSurCharge)
            val extensionWork = batteryStorageExtensionWorkingTimePrices.value(extensionType)
            val priceAndWorkForExtension = PriceWithProfitAndWorkingTime(extensionPrice, extensionWork)

            //Combine both UUIDs to get a (hopefully) unique UUID that can be reproduced
            val combinedId = BatteryConfigurationId(batteryAndInverter.uuid.xor(extensionType.uuid))
            BatteryConfiguration(id = combinedId, battery = batteryAndInverter.battery, extension = extensionType, inverterType = batteryAndInverter.inverter) to (priceAndWorkForBatteryStorage + priceAndWorkForExtension)
          }
        )
    }.toMap()
  )
}

private fun availableBatteryStorageExtensionsFor(battery: BatteryStorageType): List<BatteryStorageExtensionType> {
  return batteryStorageExtensionInputPrices.available.filter { batteryStorageExtensionType ->
    batteryStorageExtensionType.extensionFor.contains(battery)
  }
}
