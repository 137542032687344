package components.project.configuration.facility.assembly

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.price.PriceList
import it.neckar.open.formatting.formatAsPercentage
import it.neckar.open.unit.si.m2
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleExclamation
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.role
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.*

enum class ScaffoldingStatus {
  ConstructionHandledByLizergy,
  OnlyScaffoldingProvided,
  SelfAssembled,
}

/**
 * Form for assembly related stuff
 */
fun RBuilder.assemblyForm(
  dachstaenderIsolierung: StateInstance<Boolean>,
  moveSatelliteDish: StateInstance<Boolean>,
  removeAntenna: StateInstance<Boolean>,

  willBeSelfAssembled: StateInstance<ScaffoldingStatus>,
  dachhakenSetzen: StateInstance<Boolean>,
  schienenMontage: StateInstance<Boolean>,
  kabelZiehenUndModuleMontieren: StateInstance<Boolean>,
  dachhakenGestellt: StateInstance<Boolean>,
  externerBlitzschutzVorhanden: StateInstance<Boolean>,

  scaffoldingArea: StateInstance<@m2 Int?>,
  scaffoldingAreaFromRoofs: @m2 Int,

  priceList: PriceList,

  editableStatus: EditableStatus,

  ): Unit = child(AssemblyForm) {
  attrs {
    this.dachstaenderIsolierung = dachstaenderIsolierung
    this.moveSatelliteDish = moveSatelliteDish
    this.removeAntenna = removeAntenna

    this.willBeSelfAssembled = willBeSelfAssembled
    this.dachhakenSetzen = dachhakenSetzen
    this.schienenMontage = schienenMontage
    this.kabelZiehenUndModuleMontieren = kabelZiehenUndModuleMontieren
    this.dachhakenGestellt = dachhakenGestellt
    this.externerBlitzschutzVorhanden = externerBlitzschutzVorhanden

    this.scaffoldingArea = scaffoldingArea
    this.scaffoldingAreaFromRoofs = scaffoldingAreaFromRoofs

    this.priceList = priceList

    this.editableStatus = editableStatus
  }
}

val AssemblyForm: FC<AssemblyFormProps> = fc("AssemblyForm") { props ->
  val schienenMontage = props::schienenMontage.getNotNull()
  val dachhakenSetzen = props::dachhakenSetzen.getNotNull()
  val dachhakenGestellt = props::dachhakenGestellt.getNotNull()
  val willBeSelfAssembled = props::willBeSelfAssembled.getNotNull()
  val scaffoldingArea = props::scaffoldingArea.getNotNull()
  val scaffoldingAreaFromRoofs = props::scaffoldingAreaFromRoofs.getNotNull()
  val kabelZiehenUndModuleMontieren = props::kabelZiehenUndModuleMontieren.getNotNull()

  val dachstaenderIsolierung = props::dachstaenderIsolierung.getNotNull()
  val moveSatelliteDish = props::moveSatelliteDish.getNotNull()
  val removeAntenna = props::removeAntenna.getNotNull()
  val externerBlitzschutzVorhanden = props::externerBlitzschutzVorhanden.getNotNull()

  val priceList = props::priceList.safeGet()

  val editableStatus = props::editableStatus.safeGet()

  val optionalDiscountPrices = priceList.optionalDiscountPrices


  div("row gy-2") {

    div("col-md-6 btn-group-vertical my-4") {
      attrs {
        role = "group"

        div("row") {

          div("col-1 align-items-center") {
            input(classes = "form-check-input my-4", type = InputType.radio, name = "btnradio") {
              attrs {
                id = "btnradio0"
                defaultChecked = willBeSelfAssembled.value == ScaffoldingStatus.ConstructionHandledByLizergy
                disabled = editableStatus == ReadOnly

                onChangeFunction = {
                  val inputElement = it.target as HTMLInputElement
                  if (inputElement.checked) willBeSelfAssembled.setter(ScaffoldingStatus.ConstructionHandledByLizergy)
                }
              }
            }
          }
          div("col-10 ms-0") {
            nullableFloatingIntInputField(
              valueAndSetter = scaffoldingArea,
              fieldName = "scaffoldingArea",
              title = buildString {
                append("Gerüstgröße")
                if (scaffoldingArea.value == null) append(" $scaffoldingAreaFromRoofs")
                append(" (m²)")
              },
              editableStatus = editableStatus,
            ) {
              attrs {
                disabled = willBeSelfAssembled.value != ScaffoldingStatus.ConstructionHandledByLizergy
              }
            }
          }

        }

        span {
          input(type = InputType.radio, classes = "form-check-input", name = "btnradio") {
            attrs {
              id = "btnradio1"
              defaultChecked = willBeSelfAssembled.value == ScaffoldingStatus.OnlyScaffoldingProvided
              disabled = editableStatus == ReadOnly

              onChangeFunction = {
                val inputElement = it.target as HTMLInputElement
                if (inputElement.checked) willBeSelfAssembled.setter(ScaffoldingStatus.OnlyScaffoldingProvided)
              }
            }
          }
          label("form-check-label ms-2") {
            attrs {
              htmlForFixed = "btnradio1"
            }

            +"Gerüst gestellt"
          }
        }

        span {
          input(type = InputType.radio, classes = "form-check-input", name = "btnradio") {
            attrs {
              id = "btnradio2"
              defaultChecked = willBeSelfAssembled.value == ScaffoldingStatus.SelfAssembled
              disabled = editableStatus == ReadOnly

              onChangeFunction = {
                val inputElement = it.target as HTMLInputElement
                if (inputElement.checked) willBeSelfAssembled.setter(ScaffoldingStatus.SelfAssembled)
              }
            }
          }
          label("form-check-label ms-2") {
            attrs {
              htmlForFixed = "btnradio2"
            }

            +"Eigenleistung"
          }
        }

      }
    }

    div("collapse ms-3") {
      attrs {
        id = "willBeSelfAssembledCollapse"

        addClassIf("show") {
          willBeSelfAssembled.value == ScaffoldingStatus.SelfAssembled
        }
      }

      div("row") {

        div("col-sm-6") {
          checkbox(
            valueAndSetter = dachhakenSetzen,
            fieldName = "eigenleistungDachhakenMontage",
            title = "Eigenleistung Unterkonstruktion",
            editableStatus = editableStatus,
          ) {
            attrs {
              disabled = dachhakenGestellt.value && dachhakenSetzen.value.not()
            }
          }
        }

        div("col-sm-6") {
          p("form-text") {
            +"${optionalDiscountPrices.dachhakenSetzenDiscount.formatAsPercentage()} der Montage- & Fahrtkosten"
          }
        }

      }

      div("row") {

        div("col-sm-6") {
          checkbox(
            valueAndSetter = schienenMontage,
            fieldName = "eigenleistungSchienenMontage",
            title = "Eigenleistung Schienen",
            editableStatus = editableStatus,
          )
        }

        div("col-sm-6") {
          p("form-text") {
            +"${optionalDiscountPrices.schienenMontageDiscount.formatAsPercentage()} der Montage- & Fahrtkosten"
          }
        }

      }

      div("row") {

        div("col-sm-6") {
          checkbox(
            valueAndSetter = kabelZiehenUndModuleMontieren,
            fieldName = "eigenleistungKabelUndModuleMontage",
            title = "Eigenleistung Kabel/Module",
            editableStatus = editableStatus,
          )
        }

        div("col-sm-6") {
          p("form-text") {
            +"${optionalDiscountPrices.kabelZiehenUndModuleMontierenDiscount.formatAsPercentage()} der Montage- & Fahrtkosten"
          }
        }

      }

      div("row mt-2") {

        div("col-sm-6") {
          checkbox(
            valueAndSetter = dachhakenGestellt,
            fieldName = "dachhakenVorhanden",
            title = "Dachhaken vorhanden",
            editableStatus = editableStatus,
          ) {
            attrs {
              disabled = dachhakenSetzen.value && dachhakenGestellt.value.not()
            }
          }
        }

        div("col-sm-6") {
          p("form-text") {
            +"${optionalDiscountPrices.dachhakenGestelltDiscount.formatAsPercentage()} der Montage- & Fahrtkosten"
          }
          p("form-text") {
            +"${optionalDiscountPrices.dachhakenGestelltRoofDiscount.formatAsPercentage()} der Kosten des Dachtyps"
          }
          p("text-warning") {
            span("pe-2") { faCircleExclamation() }
            +"ACHTUNG: Nur auf Ziegel und Biberschwanzdach"
          }
        }

      }

    }


    div("row mt-2") {
      div("col-sm-6") {
        div {
          div("mb-3") {
            h4("mb-2") {
              +"Dacharbeiten"
            }
            div {
              checkbox(
                valueAndSetter = dachstaenderIsolierung,
                fieldName = "dachstaenderIsolierung",
                title = "Dachständerisolierung",
                editableStatus = editableStatus,
              )
            }
            div {
              checkbox(
                valueAndSetter = moveSatelliteDish,
                fieldName = "moveSatelliteDish",
                title = "Satellitenschüssel versetzen",
                editableStatus = editableStatus,
              )
            }
            div {
              checkbox(
                valueAndSetter = removeAntenna,
                fieldName = "removeAntenna",
                title = "Antenne abbauen",
                editableStatus = editableStatus,
              )
            }
            div {
              checkbox(
                valueAndSetter = externerBlitzschutzVorhanden,
                fieldName = "externerBlitzschutzVorhanden",
                title = "Externer Blitzschutz vorhanden",
                editableStatus = editableStatus,
              )
            }
          }
        }
      }
    }

  }
}

external interface AssemblyFormProps : Props {
  var dachstaenderIsolierung: StateInstance<Boolean>
  var moveSatelliteDish: StateInstance<Boolean>
  var removeAntenna: StateInstance<Boolean>

  var willBeSelfAssembled: StateInstance<ScaffoldingStatus>
  var dachhakenSetzen: StateInstance<Boolean>
  var schienenMontage: StateInstance<Boolean>
  var kabelZiehenUndModuleMontieren: StateInstance<Boolean>
  var dachhakenGestellt: StateInstance<Boolean>
  var externerBlitzschutzVorhanden: StateInstance<Boolean>

  var scaffoldingArea: StateInstance<@m2 Int?>
  var scaffoldingAreaFromRoofs: @m2 Int

  var priceList: PriceList

  var editableStatus: EditableStatus
}
