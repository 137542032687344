package components.project.configuration.additional

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.InverterDescription
import it.neckar.open.unit.other.kWp
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

val EditInverterDescription: FC<EditInverterDescriptionProps> = fc("EditInverterDescription") { props ->
  val inverterDescriptionsToSave = props::inverterDescriptionsToSave.getNotNull()
  val indexInList = props::indexInList.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val originalExistingFacilityConfiguration = inverterDescriptionsToSave.value.getOrNull(indexInList)
  @kWp val powerToSave = useState(originalExistingFacilityConfiguration?.power ?: 0.0)
  val typeToSave = useState(originalExistingFacilityConfiguration?.type.orEmpty())


  useMemo(
    powerToSave.value,
    typeToSave.value,
  ) {
    val newInverterDescription = originalExistingFacilityConfiguration?.copy(
      power = powerToSave.value,
      type = typeToSave.value,
    )
    val newInverterDescriptions = inverterDescriptionsToSave.value.toMutableList()
    if (newInverterDescription != null && indexInList < newInverterDescriptions.size) newInverterDescriptions[indexInList] = newInverterDescription

    inverterDescriptionsToSave.setter(newInverterDescriptions)
  }


  div("row gx-0") {

    div("col-6") {
      floatingDoubleInputField(
        valueAndSetter = powerToSave,
        fieldName = "powerToSave",
        title = "WR ${indexInList + 1} kW-Leistung",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedRight()
        }
      }
    }

    div("col-6") {
      floatingInputField(
        valueAndSetter = typeToSave,
        fieldName = "typeToSave",
        title = "WR ${indexInList + 1} Typ",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedLeft()
        }
      }
    }

  }

}


external interface EditInverterDescriptionProps : Props {
  var inverterDescriptionsToSave: StateInstance<List<InverterDescription>>
  var indexInList: Int
  var editableStatus: EditableStatus
}

