@file:Suppress("DuplicatedCode")

package components.project.configuration.additional

import components.form.commentSection
import components.project.formButtons
import components.project.freezableFormButtons
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.ExistingPVFacilityConfiguration
import it.neckar.lizergy.model.configuration.components.InverterDescription
import it.neckar.lizergy.model.configuration.energy.kiloWattPeak
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.kotlin.lang.fastFor
import it.neckar.open.unit.other.kWp
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import kotlinx.browser.window
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useExistingPVFacilityIdFromUrl
import router.useLoadResolvedProjectFromUrl
import services.UiActions

val EditBlueprintExistingPVFacilityFromUrl: FC<Props> = fc("EditBlueprintExistingPVFacilityFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val existingPVFacilityId = useExistingPVFacilityIdFromUrl()

  busyIfNull(project) {
    val existingFacilityConfiguration = it.blueprint.existingFacilitiesConfiguration[existingPVFacilityId]

    EditBlueprintExistingPVFacility {
      attrs {
        this.project = it
        this.existingFacilityConfiguration = existingFacilityConfiguration
      }
    }
  }
}

val EditConfigurationExistingPVFacilityFromUrl: FC<Props> = fc("EditConfigurationExistingPVFacilityFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val existingPVFacilityId = useExistingPVFacilityIdFromUrl()

  busyIfNull(project) {
    val quoteConfiguration = it[configurationId]
    val existingFacilityConfiguration = quoteConfiguration.existingFacilitiesConfiguration[existingPVFacilityId]

    EditConfigurationExistingPVFacility {
      attrs {
        this.project = it
        this.quoteConfiguration = quoteConfiguration
        this.existingFacilityConfiguration = existingFacilityConfiguration
        this.editableStatus = EditableStatus.Editable
      }
    }
  }
}

val EditBlueprintExistingPVFacility: FC<EditBlueprintExistingPVFacilityProps> = fc("EditBlueprintExistingPVFacility") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val blueprint = project::blueprint.safeGet()
  val existingFacilityConfiguration = props::existingFacilityConfiguration.safeGet()

  val existingFacilityConfigurationToSave = useState(existingFacilityConfiguration)

  val blueprintToSave = useMemo(blueprint, existingFacilityConfigurationToSave.value) {
    blueprint.copy(
      existingFacilitiesConfiguration = blueprint.existingFacilitiesConfiguration.withReplaced(existingFacilityConfigurationToSave.value),
    )
  }

  val okAction = {
    UiActions.saveBlueprint(blueprintToSave)
    navigate.back()
  }


  div {
    onEnter(okAction)

    EditExistingPVFacility {
      attrs {
        this.existingFacilityConfigurationToSave = existingFacilityConfigurationToSave
        this.editableStatus = EditableStatus.Editable
      }
    }
  }

  formButtons(okAction)
}

val EditConfigurationExistingPVFacility: FC<EditConfigurationExistingPVFacilityProps> = fc("EditConfigurationExistingPVFacility") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val existingFacilityConfiguration = props::existingFacilityConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val existingFacilityConfigurationToSave = useState(existingFacilityConfiguration)

  val configurationToSave = useMemo(quoteConfiguration, existingFacilityConfigurationToSave.value) {
    quoteConfiguration.copy(
      existingFacilitiesConfiguration = quoteConfiguration.existingFacilitiesConfiguration.withReplaced(existingFacilityConfigurationToSave.value),
    )
  }

  val okAction = {
    UiActions.saveQuoteConfiguration(project, configurationToSave)
    navigate.back()
  }


  div {
    if (editableStatus == EditableStatus.Editable) {
      onEnter(okAction)
    }

    EditExistingPVFacility {
      attrs {
        this.existingFacilityConfigurationToSave = existingFacilityConfigurationToSave
        this.editableStatus = editableStatus
      }
    }
  }

  freezableFormButtons(editableStatus, okAction)
}


val EditExistingPVFacility: FC<EditExistingPVFacilityProps> = fc("EditExistingPVFacility") { props ->
  useEffectOnce {
    window.scrollTo(0.0, 1000.0)
  }

  val existingFacilityConfigurationToSave = props::existingFacilityConfigurationToSave.getNotNull()
  val editableStatus = props::editableStatus.safeGet()

  val originalExistingFacilityConfiguration = existingFacilityConfigurationToSave.value
  val zaehlernummerToSave = useState(originalExistingFacilityConfiguration.zaehlernummer)
  @kWp val powerRatingToSave = useState(originalExistingFacilityConfiguration.powerRating.kiloWattPeak)
  val einspeiseartToSave = useState(originalExistingFacilityConfiguration.einspeiseart)
  val integrateIntoFacilityToSave = useState(originalExistingFacilityConfiguration.integrateIntoFacility)
  val existingInvertersToSave = useState(originalExistingFacilityConfiguration.existingInverters)
  val amountOfExistingInverters = useState(originalExistingFacilityConfiguration.existingInverters.size)


  useMemo(amountOfExistingInverters.value) {
    existingInvertersToSave.setter(existingInvertersToSave.value.let { existingInverters ->
      if (amountOfExistingInverters.value > existingInverters.size) {
        existingInverters + InverterDescription()
      } else {
        existingInverters.subList(0, amountOfExistingInverters.value)
      }
    })
  }

  useMemo(
    zaehlernummerToSave.value,
    powerRatingToSave.value,
    einspeiseartToSave.value,
    integrateIntoFacilityToSave.value,
    amountOfExistingInverters.value,
    existingInvertersToSave.value,
  ) {
    existingFacilityConfigurationToSave.setter(
      originalExistingFacilityConfiguration.copy(
        zaehlernummer = zaehlernummerToSave.value,
        powerRating = powerRatingToSave.value.kiloWattPeak,
        einspeiseart = einspeiseartToSave.value,
        integrateIntoFacility = integrateIntoFacilityToSave.value,
        existingInverters = existingInvertersToSave.value,
      )
    )
  }


  div("row") {

    h2("mb-3") { +"Bestandsanlage PV" }

    div("col-md-6") {

      div("my-3") {

        floatingInputField(
          valueAndSetter = zaehlernummerToSave,
          fieldName = "zaehlernummer",
          title = "Zählernummer Bestandsanlage",
          editableStatus = editableStatus,
        ) {
          attrs {
            mergedBelow()
          }
        }

        floatingDoubleInputField(
          valueAndSetter = powerRatingToSave,
          fieldName = "powerRating",
          title = "kWp Bestandsanlage",
          editableStatus = editableStatus,
        ) {
          attrs {
            mergedAbove()
          }
        }

      }

      div("my-4") {

        floatingIntInputField(
          value = amountOfExistingInverters.value,
          onChange = { amountOfExistingInverters.setter(it.coerceAtLeast(0)) },
          fieldName = "amountOfExistingInverters",
          title = "Anzahl Bestandswechselrichter",
          editableStatus = editableStatus,
        )

        div("my-3") {
          amountOfExistingInverters.value.fastFor { indexInList ->
            EditInverterDescription {
              attrs {
                this.inverterDescriptionsToSave = existingInvertersToSave
                this.indexInList = indexInList
                this.editableStatus = editableStatus
              }
            }
          }
        }

      }

      div("my-3") {
        einspeiseArtForm(einspeiseartToSave, editableStatus)
      }

      div("my-3") {
        checkbox(
          valueAndSetter = integrateIntoFacilityToSave,
          fieldName = "integrateIntoFacility",
          title = "Einbindung in neue Anlage (Nur für Bestandsanlagen mit Eigenverbrauch)",
          editableStatus = editableStatus,
        )
      }

    }

    div("col-md-6") {
      commentSection(originalExistingFacilityConfiguration)
    }

  }

}


external interface EditBlueprintExistingPVFacilityProps : Props {
  var project: ResolvedProject
  var existingFacilityConfiguration: ExistingPVFacilityConfiguration
}

external interface EditConfigurationExistingPVFacilityProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
  var existingFacilityConfiguration: ExistingPVFacilityConfiguration
  var editableStatus: EditableStatus
}

external interface EditExistingPVFacilityProps : Props {
  var existingFacilityConfigurationToSave: StateInstance<ExistingPVFacilityConfiguration>
  var editableStatus: EditableStatus
}

