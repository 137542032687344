package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.user.UserLoginName
import store.PlannerClientState

/**
 * Updates a single project.
 * Does *not* navigate to the project
 */
data class ArchiveProjectAction(val projectId: PhotovoltaicsProjectId, val archiveReasons: ArchiveReasons, val loginName: UserLoginName) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    println("Archiving project <${projectId}>")

    val updatedResolvedProject = this.resolvedProjects.getOrNull(projectId)?.copy(archiveReasons = archiveReasons)

    val updatedProjects = updatedResolvedProject?.let { this.resolvedProjects.withUpdated(it) } ?: this.resolvedProjects

    return this.copy(resolvedProjects = updatedProjects)
  }
}
