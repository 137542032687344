@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.energy.power

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.OverridablePosition
import it.neckar.lizergy.model.configuration.energy.AmountOfEnergy
import it.neckar.lizergy.model.configuration.energy.kWh
import it.neckar.open.unit.si.km
import it.neckar.open.unit.time.pa
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * How much power is used
 */
@Serializable
data class PowerUsageScenario(

  val id: PowerUsageScenarioId = PowerUsageScenarioId.random(),

  /**
   * How much power is consumed per year ("Jährlicher Stromverbrauch") - total
   */
  val yearlyPowerConsumption: @pa AmountOfEnergy,

  val waermepumpeConsumption: @pa AmountOfEnergy = AmountOfEnergy.Zero,

  val electricCarUsage: @km Double = 0.0,

  override val override: AmountOfEnergy? = null,

  ) : HasUuid, OverridablePosition<@pa AmountOfEnergy> {

  override val uuid: Uuid
    get() = id.uuid

  override val position: AmountOfEnergy
    get() = yearlyPowerConsumption + waermepumpeConsumption + electricCarConsumptionPerKilometer * electricCarUsage

  val yearlyPowerConsumptionValue: AmountOfEnergy
    get() = value


  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): PowerUsageScenario {
    val newId = PowerUsageScenarioId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(id = newId)
  }


  @Serializable
  data class PowerUsageScenarioId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): PowerUsageScenarioId {
        return PowerUsageScenarioId(randomUuid4())
      }
    }
  }


  companion object {
    val electricCarConsumptionPerKilometer: @pa AmountOfEnergy = AmountOfEnergy(0.2)

    /**
     * Creates a new power usage scenario
     */
    fun typical(): PowerUsageScenario {
      return PowerUsageScenario(yearlyPowerConsumption = 3500.0.kWh)
    }

    fun demo(): PowerUsageScenario {
      return PowerUsageScenario(yearlyPowerConsumption = 16000.0.kWh)
    }
  }
}
