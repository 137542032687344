package components.project.configuration.wallbox

import components.form.elementsSelectionForm
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedWallboxSelection
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.price.Prices
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faEdit
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import react.*
import react.dom.*

/**
 * Form for wallbox related stuff
 */
fun RBuilder.wallboxForm(
  wallboxSelectionState: StateInstance<ResolvedWallboxSelection>,
  wallboxPrices: Prices<Wallbox>,
  availableWallboxes: List<Wallbox>,
  editableStatus: EditableStatus,
): Unit = child(WallboxForm) {
  attrs {
    this.wallboxSelectionState = wallboxSelectionState
    this.wallboxPrices = wallboxPrices
    this.availableWallboxes = availableWallboxes
    this.editableStatus = editableStatus
  }
}

val WallboxForm: FC<WallboxFormProps> = fc("WallboxForm") { props ->
  val wallboxSelectionState = props::wallboxSelectionState.getNotNull()

  val availableWallboxTypes = props::availableWallboxes.safeGet()
  val wallboxPrices = props::wallboxPrices.safeGet()
  val editableStatus = props::editableStatus.safeGet()


  div(classes = "d-flex flex-row") {

    floatingReadOnlyInputField(
      value = wallboxSelectionState.value.format(),
      fieldName = "wallboxSelection",
      title = "Ladestation",
      editableStatus = editableStatus,
      divConfig = {
        attrs {
          addClass("flex-grow-1")
        }
      }
    )

    val idOfModal = "selectWallboxSelectionModal"
    modalButton(idOfModal, classes = "btn btn-link") {
      faEdit()

      attrs {
        disabled = editableStatus == ReadOnly
      }
    }

    modal(
      id = idOfModal,
      title = "Ladestationen",
      size = ModalSize.Large,
    ) {

      elementsSelectionForm(
        selectedValue = wallboxSelectionState.value,
        onChange = { option, amount ->
          val updatedSelection = wallboxSelectionState.value.with(option, amount)
          wallboxSelectionState.setter(updatedSelection)
        },
        availableOptions = availableWallboxTypes,
        formatter = { wallboxType ->
          "${wallboxType.description} [${wallboxPrices.value(wallboxType).sellingPrice.format()}]"
        },
      )
    }

  }

}


external interface WallboxFormProps : Props {
  var wallboxSelectionState: StateInstance<ResolvedWallboxSelection>
  var wallboxPrices: Prices<Wallbox>
  var availableWallboxes: List<Wallbox>
  var editableStatus: EditableStatus
}
