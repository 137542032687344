package it.neckar.comments

import com.benasher44.uuid.Uuid
import it.neckar.editHistory.PositionEdit
import it.neckar.editHistory.PositionEditHistory
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

@Serializable
data class Comment(
  val id: CommentId = CommentId.random(),
  val comment: PositionEditHistory<String>,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : HasUuid, HasLifeCycle {
  constructor(newComment: String, userLoginName: UserLoginName) : this(comment = PositionEditHistory(newEntry = newComment, loginName = userLoginName))

  override val uuid: Uuid
    get() = id.uuid

  val currentEdit: PositionEdit<String>
    get() = comment.currentEdit

  val hasBeenEdited: Boolean
    get() = comment.allEdits.size > 1

  /**
   * An id for a project
   */
  @Serializable
  data class CommentId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): CommentId {
        return CommentId(randomUuid4())
      }
    }
  }
}
