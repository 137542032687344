package components.sales

import components.project.HomepageProps
import components.project.homepageButton
import components.project.homepageButtonForPhase
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.previews.ProjectQueryForUser
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry.BlueprintAcquisitionProcessStates
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry.BlueprintProcessStates
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry.ConfigurationProcessStates
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry.PresentationProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry.QuoteConfirmationProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry.QuoteOfferProcessStates
import it.neckar.lizergy.model.project.process.state.onlyActive
import it.neckar.lizergy.model.project.process.state.onlyUnfinished
import react.*
import react.dom.*
import router.RouterUrls
import services.http.ProjectCountRequestForPhase

val SalesHomepage: FC<HomepageProps> = fc("SalesHomepage") { props ->
  val projectCountsForPhases = props::projectCountsForPhases.safeGet()

  div {

    h3("mb-3") {
      +"Vertrieb"
    }

    div("row row-cols-1 gy-2") {

      homepageButton(RouterUrls.newProject) {
        b { +"Kundenerfassung" }
      }

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.blueprints,
        title = "Projekterfassung",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.Blueprint),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.quotes,
        title = "Angebot",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.CurrentConfiguration),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.presentations,
        title = "Projektvorstellung",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.CurrentQuote),
      )

    }

  }
}

val SalesCountRequest: List<ProjectCountRequestForPhase> = listOf(
  ProjectCountRequestForPhase(
    relevantProcessStates = BlueprintAcquisitionProcessStates.entries.onlyUnfinished() + BlueprintProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.Blueprint,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = ConfigurationProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.CurrentConfiguration,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = PresentationProcessStates.entries + QuoteOfferProcessStates.entries.onlyActive() + QuoteConfirmationProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.CurrentQuote,
    projectQueryForUser = ProjectQueryForUser.AssignedMaintainer,
  ),
)
