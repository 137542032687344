package it.neckar.lizergy.model.income

import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable

/**
 * Contains the percentages that are distributed
 */
object IncomePercentages {
  /**
   * "Tippgeber"
   * Possible values: Partner / Lizergy
   */
  val tippgeber: IncomePercentage = IncomePercentage(
    "Tippgeber",
    profit1 = 1.0.percent,
    profit2Battery = 1.0.percent,
    profit3Scaffolding = 0.0.percent,
    profit4ElectricityMaterial = 1.0.percent,
    profit5Assembly = 1.0.percent,
    profit6ElectricityWork = 1.0.percent,
  )

  /**
   * "Vertrieb Projekterfassung"
   * "enthält Projekterfassung vor Ort beim Kunde"
   * Possible values: Partner / Lizergy
   */
  val vertriebProjekterfassung: IncomePercentage = IncomePercentage(
    "Vertrieb Projekterfassung",
    profit1 = 2.0.percent,
    profit2Battery = 2.0.percent,
    profit3Scaffolding = 0.0.percent,
    profit4ElectricityMaterial = 2.0.percent,
    profit5Assembly = 2.0.percent,
    profit6ElectricityWork = 2.0.percent,
  )

  val vertriebAngebotsvorstellung: IncomePercentage = IncomePercentage(
    "Vertrieb Angebotsvorstellung",
    profit1 = 2.0.percent,
    profit2Battery = 2.0.percent,
    profit3Scaffolding = 0.0.percent,
    profit4ElectricityMaterial = 2.0.percent,
    profit5Assembly = 2.0.percent,
    profit6ElectricityWork = 2.0.percent,
  )

  /**
   * "Technische Planung Dach"
   * Possible values: Partner / Lizergy
   */
  val technischePlanungDach: IncomePercentage = IncomePercentage(
    "Technische Planung Dach",
    profit1 = 2.0.percent,
    profit2Battery = 0.0.percent,
    profit3Scaffolding = 2.0.percent,
    profit4ElectricityMaterial = 0.0.percent,
    profit5Assembly = 2.0.percent,
    profit6ElectricityWork = 0.0.percent,
  )

  val technischePlanungElektrik: IncomePercentage = IncomePercentage(
    "Technische Planung Elektrik",
    profit1 = 2.0.percent,
    profit2Battery = 1.0.percent,
    profit3Scaffolding = 0.0.percent,
    profit4ElectricityMaterial = 2.0.percent,
    profit5Assembly = 0.0.percent,
    profit6ElectricityWork = 2.0.percent,
  )

  /**
   * "Montage PV Anlage Dach DC Seite + Montagelohn - wenn ein Gerüst im Angebot enthalten ist, werden 90 % der Gesamtkosten vom Gerüst dem Dachteam zugeschlagen"
   * Possible values: Partner / Lizergy
   */
  val montageDach: IncomePercentage = IncomePercentage(
    "Montage PV Dach",
    profit1 = 2.0.percent,
    profit2Battery = 0.0.percent,
    profit3Scaffolding = 0.0.percent,
    profit4ElectricityMaterial = 0.0.percent,
    profit5Assembly = (220.0 / 3.0).percent, // 73.33...
    profit6ElectricityWork = 0.0.percent,
  )

  /**
   * "Montage PV Anlage Dach DC Seite + Montagelohn - wenn ein Gerüst im Angebot enthalten ist, werden 90 % der Gesamtkosten vom Gerüst dem Dachteam zugeschlagen"
   * Possible values: Partner / Lizergy
   */
  val montageGeruest: IncomePercentage = IncomePercentage(
    "Montage PV Gerüst",
    profit1 = 0.0.percent,
    profit2Battery = 0.0.percent,
    profit3Scaffolding = 90.0.percent,
    profit4ElectricityMaterial = 0.0.percent,
    profit5Assembly = 0.0.percent,
    profit6ElectricityWork = 0.0.percent,
  )

  /**
   * "Elektroinstallation Haus + Montagelohn inklusive Fertigmeldung und Netzvoranfrage"
   * "Bekommt vom Gerüst keinen prozentualen Anteil"
   * Possible values: Partner / Lizergy
   */
  val elektroInstallation: IncomePercentage = IncomePercentage(
    "Elektroinstallation Haus",
    profit1 = 0.5.percent,
    profit2Battery = 0.5.percent,
    profit3Scaffolding = 0.0.percent,
    profit4ElectricityMaterial = 10.0.percent,
    profit5Assembly = 0.0.percent,
    profit6ElectricityWork = (260.0 / 3.0).percent, // 86.66...
  )

  /**
   * Netzvoranfrage und Fertigmeldung (wird automatisch der Elektriker eingetragen, kann aber von Lizergy geändert werden, dann erhält der Elektriker nur 1 Prozent anteilig vom Material)
   *
   * Possible values: Elektriker / Lizergy
   */
  val netzvoranfrage: IncomePercentage = IncomePercentage(
    "Netzvoranfrage und Fertigmeldung",
    profit1 = 2.0.percent,
    profit2Battery = 2.0.percent,
    profit3Scaffolding = 0.0.percent,
    profit4ElectricityMaterial = 0.0.percent,
    profit5Assembly = 0.0.percent,
    profit6ElectricityWork = 0.0.percent,
  )

  /**
   * Can not be configured
   */
  val softwareWithPartner: IncomePercentage = IncomePercentage(
    "Software 2%",
    profit1 = 2.0.percent,
    profit2Battery = 2.0.percent,
    profit3Scaffolding = 2.0.percent,
    profit4ElectricityMaterial = 2.0.percent,
    profit5Assembly = 2.0.percent,
    profit6ElectricityWork = 2.0.percent,
  )

  val softwareWithoutPartner: IncomePercentage = IncomePercentage(
    "Software 1%",
    profit1 = 1.0.percent,
    profit2Battery = 1.0.percent,
    profit3Scaffolding = 1.0.percent,
    profit4ElectricityMaterial = 1.0.percent,
    profit5Assembly = 1.0.percent,
    profit6ElectricityWork = 1.0.percent,
  )


  /**
   * Contains all percentages
   */
  val all: List<IncomePercentage> = listOf(tippgeber, vertriebProjekterfassung, vertriebAngebotsvorstellung, technischePlanungDach, technischePlanungElektrik, montageDach, montageGeruest, elektroInstallation, netzvoranfrage, softwareWithPartner, softwareWithoutPartner)

  /**
   * Contains all percentages but without Software
   * used in the Earnings Tab
   */
  val allWithoutSoftware: List<IncomePercentage> = all - softwareWithPartner - softwareWithoutPartner

}


@Serializable
data class IncomePercentage(

  val description: String,
  /**
   * The percentage for the base work.
   * This one is used for the remaining stuff.
   */
  val profit1: @pct Double,

  /**
   * The percentage for the battery storage
   */
  val profit2Battery: @pct Double,

  /**
   * The percentage for the scaffolding
   */
  val profit3Scaffolding: @pct Double,

  /**
   * The percentage for the electricity material
   */
  val profit4ElectricityMaterial: @pct Double,

  /**
   * THe percentage for the assembly (roof)
   */
  val profit5Assembly: @pct Double,

  /**
   * The percentage for the electricity work
   */
  val profit6ElectricityWork: @pct Double,

  ) {
  /**
   * Returns the percentage for the given category
   */
  fun getPercentage(category: IncomePercentageCategory): @pct Double {
    return when (category) {
      IncomePercentageCategory.Profit1 -> profit1
      IncomePercentageCategory.Profit2Battery -> profit2Battery
      IncomePercentageCategory.Profit3Scaffolding -> profit3Scaffolding
      IncomePercentageCategory.Profit4ElectricityMaterial -> profit4ElectricityMaterial
      IncomePercentageCategory.Profit5Assembly -> profit5Assembly
      IncomePercentageCategory.Profit6ElectricityWork -> profit6ElectricityWork
    }
  }
}

enum class IncomePercentageCategory(val description: String) {
  Profit1("P1 PV-Anlage Allgemein"),
  Profit2Battery("P2 Batterie Material"),
  Profit3Scaffolding("P3 Gerüst"),
  Profit4ElectricityMaterial("P4 Elektro-Installation Material"),
  Profit5Assembly("P5 Arbeitszeit PV-Dach"),
  Profit6ElectricityWork("P6 Elektro-Installation Arbeitszeit"),
}
