package components

import components.nav.CreateSampleProjectsNavItem
import components.nav.ExperimentsNavItem
import components.nav.LogoutNavItem
import components.project.searchbar.ProjectSearchButton
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherUsers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOwnCompany
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewAccounting
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewPriceList
import it.neckar.lizergy.version.LizergyVersions
import it.neckar.react.common.FontAwesome.faPhone
import it.neckar.react.common.loading.*
import it.neckar.react.common.router.*
import kotlinx.html.ButtonType
import kotlinx.html.id
import react.*
import react.dom.*
import react.router.dom.*
import router.RouterUrls
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectPendingRequestsProgress
import store.useSelector
import web.cssom.ClassName

/**
 * Contains the application header
 */
val AppHeader: FC<Props> = fc("AppHeader") {
  val loggedInUser = useRequireLoggedInUser()
  val loggedInCompany = useRequireCompanyForLoggedInUser()

  val deploymentZone = useSelector {
    deploymentZone
  }

  div("fixed-top header bg-white border-bottom") {

    val progress = useSelectPendingRequestsProgress()
    div("row") {
      if (progress > 0.0) {
        bootstrapProgressBarCustomColor("", progress, "bg-primary", ProgressBarTyp.HEADER_PROGRESS_BAR)
      }
    }

    nav("py-2 fixed-top") {

      div("row") {

        OnlineStateBanner {}
        /*logo*/
        Link {
          img(alt = "company logo", src = "/img/logo/${loggedInCompany.companyProfile.companyCode}.png", classes = "img-fluid logo") {}
          attrs {
            className = ClassName("width-200px align-middle text-center")
            toUrl = RouterUrls.home
          }
        }

        div("col") {
            /*d-xl-none ensures that the hamburger button is hidden if the breakpoint of the current window is xl*/
            div("row d-xl-none flex-nowrap") {
              div("col-sm-1") {
                attrs {
                  id = "appHeaderHamburgerCol"
                }

                /*hamburger menu button*/
                button(classes = "btn btn-link px-0 text-primary align-items-center text-decoration-none", type = ButtonType.button) {
                  i(classes = "fas fa-bars fs-3 text-primary iconSidebar") {
                    attrs {
                      attrs["data-bs-toggle"] = "offcanvas"
                      attrs["data-bs-target"] = "#offcanvas"
                    }
                  }
                  attrs {
                    attrs["data-bs-toggle"] = "offcanvas"
                    attrs["data-bs-target"] = "#offcanvas"
                    attrs["role"] = "button"
                    attrs["aria-expanded"] = "false"
                  }
                }
              }

              div("col text-primary text-end") {
                small {
                  +LizergyVersions.gitVersionAsStringVerbose
                }
              }
            }

            //Visible in wide screen mode
            div("row mt-0") {
              div("col-sm-1 d-none d-xl-block") {
                ProjectSearchButton {}
              }

              div("col-sm-5 d-none d-xl-block align-middle") {
                if (deploymentZone.isProduction().not()) {
                  div("text-danger") {
                    +"Bitte nutzen Sie die "
                    a(href = "https://pv.neckar.it/") {
                      +"offizielle Seite"
                    }
                    +" für Projekte."
                  }
                }
              }

              div("col-sm-6 d-none d-xl-block") {
                div("text-end text-primary") {
                  faPhone()
                  +" Support- & Feedback-Hotline: 07473 959 49 60"
                }
              }
            }
        }
      }

      div("col offcanvas offcanvas-start sidebar-responsive") {

        attrs {
          attrs["tabIndex"] = "-1"
          attrs["id"] = "offcanvas"
          attrs["data-bs-keyboard"] = "false"
          attrs["data-bs-backdrop"] = "false"
        }

        div("offcanvas-header bg-primary") {

          h5("offcanvas-title text-white fw-bold") {
            attrs["id"] = "offcanvas"
            +"Lizergy"
          }

          button(type = ButtonType.button, classes = "btn-close text-reset bg-white") {
            attrs["data-bs-dismiss"] = "offcanvas"
            attrs["aria-label"] = "Close"

          }
        }

        div("offcanvas-body pl-2 d-flex") {
          /*offcanvas body*/

          ul("nav nav-pills flex-column") {
            attrs["id"] = "menu"/*home in offcanvas body*/
            li("nav-item") {
              attrs["data-bs-dismiss"] = "offcanvas"
              attrs["aria-label"] = "Close"
              Link {
                i(classes = "fas fa-home fs-3 iconSidebar") {}
                span("ms-1") { +"Home" }

                attrs {
                  className = ClassName("nav-link px-0")
                  toUrl = RouterUrls.home
                }
              }

            }

            /*Projects in offcanvas body*/
            li("nav-item ") {
              attrs["data-bs-dismiss"] = "offcanvas"
              attrs["aria-label"] = "Close"
              Link {
                i(classes = "fas fa-pencil-ruler fs-3 iconSidebar") {}
                span("ms-1") { +"Alle Projekte" }

                attrs {
                  className = ClassName("nav-link px-0")
                  toUrl = RouterUrls.OverviewTableRouterUrls.projects
                }
              }
            }

            /*Price list in offcanvas body*/
            li("nav-item") {
              attrs["data-bs-dismiss"] = "offcanvas"
              attrs["aria-label"] = "Close"
              Link {
                i(classes = "fas fa-list fs-3 iconSidebar") {}
                span("ms-1") { +"Preisliste" }

                attrs {
                  className = ClassName("nav-link px-0")
                  toUrl = RouterUrls.priceList

                  restrictedUI(loggedInUser) { it.accessRights.canAccess(ViewPriceList) }
                }
              }
            }

            /*configurations in offcanvas body*/
            li("nav-item") {
              attrs["data-bs-dismiss"] = "offcanvas"
              attrs["aria-label"] = "Close"
              Link {
                i(classes = "fas fa-pen-to-square fs-3 iconSidebar") {}
                span("ms-1") { +"Angebote" }

                attrs {
                  className = ClassName("nav-link px-0")
                  toUrl = RouterUrls.OverviewTableRouterUrls.quotes
                }
              }

            }

            /*profile in offcanvas body*/
            li("nav-item") {
              attrs["data-bs-dismiss"] = "offcanvas"
              attrs["aria-label"] = "Close"
              Link {
                i(classes = "fas fa-person fs-3 iconSidebar") {}
                span("ms-1") { +"Profil" }

                attrs {
                  className = ClassName("nav-link px-0")
                  toUrl = RouterUrls.admin.users().user(loggedInUser)
                }
              }

            }

            /*company overview in offcanvas body*/
            li("nav-item") {
              attrs["data-bs-dismiss"] = "offcanvas"
              attrs["aria-label"] = "Close"
              Link {
                i(classes = "fas fa-building fs-3 iconSidebar") {}
                span("ms-1") { +"Firmenübersicht" }

                attrs {
                  className = ClassName("nav-link px-0")
                  toUrl = RouterUrls.admin.companies().index

                  restrictedUI(loggedInUser) { it.accessRights.canAccess(EditOtherUsers, EditOwnCompany) }
                }
              }
            }

            /*Accounting in offcanvas body*/
            li("nav-item") {
              attrs["data-bs-dismiss"] = "offcanvas"
              attrs["aria-label"] = "Close"
              Link {
                i(classes = "fas fa-coins fs-3 iconSidebar") {}
                span("ms-1") { +"Abrechnung" }

                attrs {
                  className = ClassName("nav-link px-0")
                  toUrl = RouterUrls.admin.accounting

                  restrictedUI(loggedInUser) { it.accessRights.canAccess(ViewAccounting) }
                }
              }
            }

          }

          /*lower block*/
          div("position-fixed bottom-0") {
            div("d-flex") {
              ul(classes = "nav nav-pills flex-column mb-0") {

                if (deploymentZone.isProduction().not()) {
                  CreateSampleProjectsNavItem {}
                  ExperimentsNavItem {}
                }

                LogoutNavItem {}
              }
            }
          }
        }
      }
    }


  }


}
