package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.open.formatting.format
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.unit.si.kW
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

@Serializable
data class ExistingPVFacilityConfiguration(
  val id: ExistingPVFacilityConfigurationId = ExistingPVFacilityConfigurationId.random(),
  val zaehlernummer: String = "",
  val powerRating: PowerRating = PowerRating.Zero,
  val existingInverters: List<InverterDescription> = emptyList(),
  val einspeiseart: Einspeiseart = Einspeiseart.Ueberschuss,
  val integrateIntoFacility: Boolean = false,
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  fun format(): String {
    return "Bestehende PV-Anlage: $zaehlernummer [${powerRating.formatKiloWattPeak()}]"
  }

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ExistingPVFacilityConfiguration {
    val newId = ExistingPVFacilityConfigurationId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(id = newId)
  }

  /**
   * An id for a [ExistingFacilitiesConfiguration]
   */
  @Serializable
  data class ExistingPVFacilityConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ExistingPVFacilityConfigurationId {
        return ExistingPVFacilityConfigurationId(randomUuid4())
      }
    }
  }

  companion object {
    fun getEmpty(id: ExistingPVFacilityConfigurationId = ExistingPVFacilityConfigurationId.random()): ExistingPVFacilityConfiguration {
      return ExistingPVFacilityConfiguration(id = id)
    }
  }

}


@Serializable
data class InverterDescription(
  val type: String = "",
  val power: @kW Double = 0.0,
) {
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    return "$type [${power.format(numberOfDecimals = 2, i18nConfiguration = i18nConfiguration)} kW]"
  }
}
