package serialized

import it.neckar.customer.company.CompanyCode
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.income.IncomePercentage
import it.neckar.lizergy.model.income.IncomePercentages
import it.neckar.lizergy.model.price.AccountingStatus
import it.neckar.lizergy.model.price.EarningsDistribution
import it.neckar.lizergy.model.price.EarningsDistribution.EarningsDistributionId
import it.neckar.lizergy.model.price.EarningsEntry
import it.neckar.lizergy.model.price.ResolvedCompanyEarningsEntry
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.price.ResolvedNeckarITEarningsEntry
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SerializedEarningsDistribution(
  override val id: EarningsDistributionId = EarningsDistributionId.random(),
  override val tippgeber: SerializedCompanyEarningsEntry,
  override val vertriebProjekterfassung: SerializedCompanyEarningsEntry,
  override val vertriebAngebotsvorstellung: SerializedCompanyEarningsEntry,
  override val technischePlanungDach: SerializedCompanyEarningsEntry,
  override val technischePlanungElektrik: SerializedCompanyEarningsEntry,
  override val montageDach: SerializedCompanyEarningsEntry,
  override val montageGeruest: SerializedCompanyEarningsEntry,
  override val elektroInstallation: SerializedCompanyEarningsEntry,
  override val netzvoranfrage: SerializedCompanyEarningsEntry,
  override val neckarITAccountingStatus: SerializedNeckarITEntry,
  override val selectedForAccounting: Boolean = false,
) : EarningsDistribution {

  override val all: List<SerializedCompanyEarningsEntry>
    get() = listOf(
      tippgeber,
      vertriebProjekterfassung,
      vertriebAngebotsvorstellung,
      technischePlanungDach,
      technischePlanungElektrik,
      montageDach,
      montageGeruest,
      elektroInstallation,
      netzvoranfrage,
    )

  override val allIncludingSoftware: List<SerializedEarningsEntry>
    get() = all + neckarITAccountingStatus

  fun resolve(companyResolver: CompanyResolver): ResolvedEarningsDistribution {
    return ResolvedEarningsDistribution(
      id = id,
      tippgeber = tippgeber.resolve(incomePercentage = IncomePercentages.tippgeber, companyResolver = companyResolver),
      vertriebProjekterfassung = vertriebProjekterfassung.resolve(incomePercentage = IncomePercentages.vertriebProjekterfassung, companyResolver = companyResolver),
      vertriebAngebotsvorstellung = vertriebAngebotsvorstellung.resolve(incomePercentage = IncomePercentages.vertriebAngebotsvorstellung, companyResolver = companyResolver),
      technischePlanungDach = technischePlanungDach.resolve(incomePercentage = IncomePercentages.technischePlanungDach, companyResolver = companyResolver),
      technischePlanungElektrik = technischePlanungElektrik.resolve(incomePercentage = IncomePercentages.technischePlanungElektrik, companyResolver = companyResolver),
      montageDach = montageDach.resolve(incomePercentage = IncomePercentages.montageDach, companyResolver = companyResolver),
      montageGeruest = montageGeruest.resolve(incomePercentage = IncomePercentages.montageGeruest, companyResolver = companyResolver),
      elektroInstallation = elektroInstallation.resolve(incomePercentage = IncomePercentages.elektroInstallation, companyResolver = companyResolver),
      netzvoranfrage = netzvoranfrage.resolve(incomePercentage = IncomePercentages.netzvoranfrage, companyResolver = companyResolver),
      neckarITAccountingStatus = neckarITAccountingStatus.resolve(),
      selectedForAccounting = selectedForAccounting,
    )
  }

  companion object {
    fun getEmpty(defaultCompany: CompanyCode, loggedInUser: UserLoginName): SerializedEarningsDistribution {
      return SerializedEarningsDistribution(
        tippgeber = SerializedCompanyEarningsEntry(company = defaultCompany, manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
        vertriebProjekterfassung = SerializedCompanyEarningsEntry(company = defaultCompany, manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
        vertriebAngebotsvorstellung = SerializedCompanyEarningsEntry(company = defaultCompany, manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
        technischePlanungDach = SerializedCompanyEarningsEntry(company = defaultCompany, manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
        technischePlanungElektrik = SerializedCompanyEarningsEntry(company = defaultCompany, manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
        montageDach = SerializedCompanyEarningsEntry(company = defaultCompany, manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
        montageGeruest = SerializedCompanyEarningsEntry(company = defaultCompany, manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
        elektroInstallation = SerializedCompanyEarningsEntry(company = defaultCompany, manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
        netzvoranfrage = SerializedCompanyEarningsEntry(company = defaultCompany, manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
        neckarITAccountingStatus = SerializedNeckarITEntry(manualEarningsHistory = PositionEditHistory(null, loggedInUser), accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser)),
      )
    }
  }
}

@Serializable
sealed interface SerializedEarningsEntry : EarningsEntry

@Serializable
@SerialName("Company")
data class SerializedCompanyEarningsEntry(
  override val company: CompanyCode,
  override val manualEarningsHistory: PositionEditHistory<Money?>,
  override val accountingStatusHistory: PositionEditHistory<AccountingStatus>,
) : SerializedEarningsEntry {
  fun resolve(incomePercentage: IncomePercentage, companyResolver: CompanyResolver): ResolvedCompanyEarningsEntry {
    return ResolvedCompanyEarningsEntry(
      companyProfile = companyResolver[company].companyProfile,
      manualEarningsHistory = manualEarningsHistory,
      accountingStatusHistory = accountingStatusHistory,
      incomePercentage = incomePercentage,
    )
  }
}

@Serializable
@SerialName("NeckarIT")
data class SerializedNeckarITEntry(
  override val manualEarningsHistory: PositionEditHistory<Money?>,
  override val accountingStatusHistory: PositionEditHistory<AccountingStatus>,
) : SerializedEarningsEntry {
  override val company: CompanyCode
    get() = CompanyCode.NeckarIT

  fun resolve(): ResolvedNeckarITEarningsEntry {
    return ResolvedNeckarITEarningsEntry(
      manualEarningsHistory = manualEarningsHistory,
      accountingStatusHistory = accountingStatusHistory,
    )
  }
}

fun ResolvedEarningsDistribution.unResolve(): SerializedEarningsDistribution {
  return SerializedEarningsDistribution(
    id = id,
    tippgeber = tippgeber.unResolve(),
    vertriebProjekterfassung = vertriebProjekterfassung.unResolve(),
    vertriebAngebotsvorstellung = vertriebAngebotsvorstellung.unResolve(),
    technischePlanungDach = technischePlanungDach.unResolve(),
    technischePlanungElektrik = technischePlanungElektrik.unResolve(),
    montageDach = montageDach.unResolve(),
    montageGeruest = montageGeruest.unResolve(),
    elektroInstallation = elektroInstallation.unResolve(),
    netzvoranfrage = netzvoranfrage.unResolve(),
    neckarITAccountingStatus = neckarITAccountingStatus.unResolve(),
    selectedForAccounting = selectedForAccounting,
  )
}


fun ResolvedNeckarITEarningsEntry.unResolve(): SerializedNeckarITEntry {
  return SerializedNeckarITEntry(
    manualEarningsHistory = manualEarningsHistory,
    accountingStatusHistory = accountingStatusHistory,
  )
}

fun ResolvedCompanyEarningsEntry.unResolve(): SerializedCompanyEarningsEntry {
  return SerializedCompanyEarningsEntry(
    company = company,
    manualEarningsHistory = manualEarningsHistory,
    accountingStatusHistory = accountingStatusHistory,
  )
}
