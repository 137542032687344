package it.neckar.financial.currency

import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable

/**
 * Contains information about a selling price and profit
 */
@Serializable
data class PriceWithProfit(
  /**
   * The input price ("real" cost).
   *
   * This is the price:
   * * one is paying when buying by a third party.
   * * the calculatory, direct cost when producing/creating/working
   */
  val inputPrice: Money,
  /**
   * The selling price
   */
  val sellingPrice: Money,

  ) {

  /**
   * The profit
   */
  val profit: Money
    get() = sellingPrice - inputPrice

  val greaterThanZero: Boolean
    get() = sellingPrice > Money.Zero

  val lessThanZero: Boolean
    get() = sellingPrice < Money.Zero

  operator fun plus(other: PriceWithProfit): PriceWithProfit {
    return PriceWithProfit(this.inputPrice + other.inputPrice, this.sellingPrice + other.sellingPrice)
  }

  operator fun minus(other: PriceWithProfit): PriceWithProfit {
    return PriceWithProfit(this.inputPrice - other.inputPrice, this.sellingPrice - other.sellingPrice)
  }

  operator fun times(factor: Double): PriceWithProfit {
    return PriceWithProfit(this.inputPrice * factor, this.sellingPrice * factor)
  }

  operator fun times(factor: Int): PriceWithProfit {
    return PriceWithProfit(this.inputPrice * factor, this.sellingPrice * factor)
  }

  operator fun div(other: Double): @pct PriceWithProfit {
    return PriceWithProfit(this.inputPrice / other, this.sellingPrice / other)
  }

  operator fun unaryMinus(): PriceWithProfit {
    return PriceWithProfit(-inputPrice, -sellingPrice)
  }


  fun dump(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    /**
     * return without non-breaking space because it is not necessary for testing
     * */
    return "${inputPrice.format(i18nConfiguration, WhitespaceConfig.Spaces)} / ${sellingPrice.format(i18nConfiguration, WhitespaceConfig.Spaces)}"
  }


  companion object {

    val Zero: PriceWithProfit = PriceWithProfit(Money.Zero, Money.Zero)

    operator fun invoke(inputEuros: @EUR Double, sellingEuros: @EUR Double): PriceWithProfit {
      return PriceWithProfit(inputEuros.euro, sellingEuros.euro)
    }

    /**
     * Creates a new instance *without* any profit
     */
    fun noProfit(selling: Money): PriceWithProfit {
      return PriceWithProfit(selling, selling)
    }

    /**
     * Creates a price with profit from an input price and a surcharge percentage
     */
    fun surCharge(inputPrice: Money, surCharge: @pct Double): PriceWithProfit {
      return PriceWithProfit(
        inputPrice = inputPrice,
        sellingPrice = inputPrice * (100.percent + surCharge)
      )
    }

  }
}


/**
 * Calculates the sum
 */
fun Iterable<PriceWithProfit>.sum(): PriceWithProfit {
  var inputPriceSum: Money = Money.Zero
  var sellingPriceSum: Money = Money.Zero

  this.forEach {
    inputPriceSum += it.inputPrice
    sellingPriceSum += it.sellingPrice
  }

  return PriceWithProfit(inputPriceSum, sellingPriceSum)
}
