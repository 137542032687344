package it.neckar.lizergy.model.configuration.components

import it.neckar.open.kotlin.lang.toCheckboxChar
import it.neckar.open.unit.si.m2
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed interface AssemblyStatus {
  val scaffoldingArea: @m2 Int?
    get() = null

  val dachhakenSetzen: Boolean?
    get() = null
  val schienenMontage: Boolean?
    get() = null
  val kabelZiehenUndModuleMontieren: Boolean?
    get() = null
  val dachhakenGestellt: Boolean?
    get() = null

  val isBeingSelfAssembled: Boolean
    get() = when (this) {
      is ScaffoldingArea, ScaffoldingProvided -> false
      is SelfAssemblyConfiguration -> dachhakenSetzen || schienenMontage || kabelZiehenUndModuleMontieren
    }
}

@Serializable
@SerialName("ScaffoldingArea")
data class ScaffoldingArea(override val scaffoldingArea: @m2 Int? = null) : AssemblyStatus

@Serializable
@SerialName("ScaffoldingProvided")
data object ScaffoldingProvided : AssemblyStatus

@Serializable
@SerialName("SelfAssemblyConfiguration")
data class SelfAssemblyConfiguration(
  override val dachhakenSetzen: Boolean,
  override val schienenMontage: Boolean,
  override val kabelZiehenUndModuleMontieren: Boolean,
  override val dachhakenGestellt: Boolean,
) : AssemblyStatus {

  fun isEmpty(): Boolean {
    return (dachhakenGestellt || schienenMontage || kabelZiehenUndModuleMontieren || dachhakenGestellt).not()
  }

  fun format(): String {
    return buildString {
      if (dachhakenSetzen) append("Dachhaken setzen ${true.toCheckboxChar()}\n")
      if (schienenMontage) append("Schienen montieren ${true.toCheckboxChar()}\n")
      if (kabelZiehenUndModuleMontieren) append("Kabel ziehen und Module montieren ${true.toCheckboxChar()}\n")
      if (dachhakenGestellt) append("Dachhaken gestellt ${true.toCheckboxChar()}\n")
    }
  }

  companion object {
    val empty: SelfAssemblyConfiguration = SelfAssemblyConfiguration(
      dachhakenSetzen = true,
      schienenMontage = true,
      kabelZiehenUndModuleMontieren = true,
      dachhakenGestellt = false,
    )
  }
}
