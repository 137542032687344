package it.neckar.react.common.router

import it.neckar.open.collections.fastForEach
import it.neckar.react.common.annotations.*
import kotlinext.js.getOwnPropertyNames
import react.*
import react.dom.*
import react.router.*
import react.router.dom.*
import remix.run.router.generatePath


/**
 * Creates a breadcrumb bar
 */
val BreadcrumbBar: FC<BreadcrumbBarProps> = fc("breadcrumbBar") { props ->
  check(useInRouterContext()) {
    "Must only be used in router context"
  }

  val navigation = requireNotNull(props.navigation) { "attrs.navigation required" }
  val matchingNavigationElement = navigation.useFindBestRoute()

  val location = useLocation()
  checkNotNull(matchingNavigationElement) {
    "No navigation element found for ${location.pathname}"
  }

  //Render the breadcrumb
  nav {
    ol("breadcrumb") {

      BreadcrumbElements {
        attrs {
          this.navigationElement = matchingNavigationElement
        }
      }
    }
  }
}

external interface BreadcrumbBarProps : Props {
  var navigation: NavigationRoot?
}


/**
 * Renders the breadcrumb elements
 */
val BreadcrumbElements: FC<BreadcrumbElementsProps> = fc("breadcrumbElements") { props ->
  props.navigationElement.getChain(false).fastForEach { navigationElement ->
    BreadCrumbElement {
      attrs {
        this.navigationElement = navigationElement
      }
    }
  }
}

external interface BreadcrumbElementsProps : Props {
  var navigationElement: NavigationElement
}

/**
 * Creates a single breadcrumb element ([li] containing a [NavLink])
 */
val BreadCrumbElement: FC<BreadCrumbElementProps> = fc("breadCrumbElement") { props ->
  val navigationElement = requireNotNull(props.navigationElement) { "props.navigationElement required" }
  val breadcrumbInfo = navigationElement.breadcrumbInfo

  val linkContent = breadcrumbInfo?.linkContent ?: {
    span {
      //No breadcrumb info - we use the path fragment
      +navigationElement.pathFragment
    }
  }


  li("breadcrumb-item") {
    NavLink {
      linkContent()

      attrs {
        to = generatePath(navigationElement.completePath(), useParams())
        end = true
      }
    }
  }
}

external interface BreadCrumbElementProps : Props {
  var navigationElement: NavigationElement?
}


/**
 * Find the best navigation element for the current route.
 *
 * ATTENTION: We have to use filter + iterate over *all* routes to keep the number of hooks constant
 */
@UsesHooks
fun NavigationRoot.useFindBestRoute(): NavigationElement? {
  @Suppress("SimplifiableCallChain")
  val matches = useMatches()
  return matches.lastOrNull()?.let { match ->
    var pathname = match.pathname
    match.params.getOwnPropertyNames().fastForEach { param ->
      val paramValue = match.params[param] ?: throw NoSuchElementException("No parameter found for key $param")
      if (paramValue.isNotEmpty()) {
        pathname = pathname.replace(paramValue, ":$param")
      }
    }

    this.allRoutes.lastOrNull { navigationElement ->
      val completePath = navigationElement.completePath()
      completePath == pathname || completePath.removeSuffix("/*") == pathname ||
        (completePath.endsWith("/*") && completePath.removeSuffix("/*") == pathname.substringBeforeLast("/"))
    }
  }
}
