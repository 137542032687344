package it.neckar.lizergy.model.configuration.quote.builder

import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.MppInputConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.StringConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import kotlinx.serialization.Serializable

@Serializable
data class ResolvedInverterConfiguration(
  val inverter: ConfigurableInverter,
  override val inverterIndex: Int,
  override val mppInputConfigurations: List<MppInputConfiguration>,
) : InverterConfiguration {

  override val inverterId: InverterId
    get() = inverter.id

  fun updateStringConfigurationFor(inputIndex: Int, stringIndex: Int, update: StringConfiguration.() -> StringConfiguration): ResolvedInverterConfiguration {
    val updatedMppInputConfigurations = mppInputConfigurations.map { mppInputConfiguration ->
      if (mppInputConfiguration.inputIndex == inputIndex) {
        val updatedStringConfigurations = mppInputConfiguration.stringConfigurations.map { stringConfiguration ->
          if (stringConfiguration.stringIndex == stringIndex) stringConfiguration.update() else stringConfiguration
        }
        mppInputConfiguration.copy(stringConfigurations = updatedStringConfigurations)
      } else {
        mppInputConfiguration
      }
    }
    return copy(mppInputConfigurations = updatedMppInputConfigurations)
  }

  fun forTheseRoofs(roofs: List<RoofId>): ResolvedInverterConfiguration {
    return copy(mppInputConfigurations = mppInputConfigurations.map { it.forTheseRoofs(roofs) })
  }

  fun format(): String {
    return buildString {
      append("Wechselrichter ${inverter.description} (${inverterIndex + 1})")
      mppInputConfigurations.forEach { mppInputConfiguration ->
        append("\n")
        append(mppInputConfiguration.format())
      }
    }
  }

  companion object {
    operator fun invoke(inverter: ConfigurableInverter, inverterIndex: Int): ResolvedInverterConfiguration {
      val mppInputConfigurations = inverter.mppInputs.map { mppInput ->
        MppInputConfiguration(mppInput.inputIndex, mppInput.strings.map { stringMppInput ->
          StringConfiguration(stringIndex = stringMppInput.stringIndex, optimalModuleCount = null, modulesStrings = null)
        })
      }
      return ResolvedInverterConfiguration(inverter = inverter, inverterIndex = inverterIndex, mppInputConfigurations = mppInputConfigurations)
    }
  }
}
