package services

import com.benasher44.uuid.Uuid
import it.neckar.comments.client.FetchCommentsForComponentResponse
import it.neckar.common.featureflags.FeatureFlag
import it.neckar.common.featureflags.FeatureFlagsSupport
import it.neckar.common.redux.dispatch
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.processStates.ProcessStatesForComponent
import it.neckar.processStatesClient.FetchProcessStatesForComponentResponse
import it.neckar.processStatesClient.FetchProcessStatesResponse
import it.neckar.react.common.*
import kotlinx.coroutines.*
import services.http.PlannerUiServices
import services.storage.LoadEssentialsResponse
import services.storage.http.FetchProjectResponse
import store.Essentials
import store.actions.AllProcessStatesLoadedAction
import store.actions.CommentsForComponentLoadedAction
import store.actions.EssentialsLoadedAction
import store.actions.ProcessStatesForComponentLoadedAction
import store.actions.ProjectLoadedAction
import store.store

/**
 * Loads values from the server *and* stores them in the store
 */
object Loading {

  object State {
    var essentials: Boolean = false

    /**
     * The [Uuid]s processStates are loaded for
     */
    var processStates: Boolean = false

    /**
     * Contains the project IDs that are currently loaded
     */
    val projects: MutableSet<PhotovoltaicsProjectId> = mutableSetOf()

    /**
     * The [Uuid]s comments are loaded for
     */
    val comments: MutableSet<Uuid> = mutableSetOf()
  }

  /**
   * Loads the essentials
   */
  fun essentialsAsync() {
    if (State.essentials) {
      logger.info("Skip loading essentials - already loading")
      return
    }

    State.essentials = true

    return launchAndNotify("Grundinformationen geladen") {
      try {
        essentials()
      } finally {
        State.essentials = false
      }
    }
  }

  /**
   * The essentials are loaded
   */
  suspend fun essentials(): LoadEssentialsResponse {
    if (FeatureFlagsSupport.flags.contains(FeatureFlag.forceFailEssentialsLoading)) return LoadEssentialsResponse.failure()

    return PlannerUiServices.essentialsClientService.loadEssentials().also {
      when (it) {
        is LoadEssentialsResponse.Success -> {
          dispatch(
            EssentialsLoadedAction(
              Essentials(
                usersAndCompanies = it.data.usersAndCompanies,
                availableProducts = it.data.availableProducts,
                productResolver = it.data.productResolver,
                priceList = it.data.priceList,
              )
            )
          )
        }

        LoadEssentialsResponse.Failure -> {}
      }
    }
  }


  fun resolvedProjectAsync(projectId: PhotovoltaicsProjectId, availableProducts: AvailableProducts, productResolver: ProductResolver, priceList: PriceList, companyResolver: CompanyResolver, userResolver: UserResolver) {
    if (State.projects.contains(projectId)) {
      logger.debug("Skip loading project $projectId - already loading")
      return
    }
    State.projects.add(projectId)

    launchAndNotify("Projekt erfolgreich geladen") {
      try {
        resolvedProject(projectId, availableProducts, productResolver, priceList, companyResolver, userResolver)
      } finally {
        State.projects.remove(projectId)
      }
    }
  }

  suspend fun resolvedProject(projectId: PhotovoltaicsProjectId, availableProducts: AvailableProducts, productResolver: ProductResolver, priceList: PriceList, companyResolver: CompanyResolver, userResolver: UserResolver): FetchProjectResponse {
    return withContext(Dispatchers.Default) {
      async { PlannerUiServices.projectQueryService.fetchProject(projectId) }.await().also { fetchProjectResult ->
        when (fetchProjectResult) {
          is FetchProjectResponse.Success -> dispatch(ProjectLoadedAction(fetchProjectResult.data.toResolvedProject(availableProducts, productResolver, priceList, userResolver, companyResolver)))
          FetchProjectResponse.Failure -> {}
        }
      }
    }
  }

  //fun processStatesForComponentAsync(uuid: Uuid) {
  //  if (State.processStates) {
  //    logger.debug("Skip loading processStates for $uuid - already loading")
  //    return
  //  }
  //
  //  State.processStates = true
  //
  //  launchAndNotify("ProcessStates für <$uuid> geladen") {
  //    try {
  //      processStatesForComponent(uuid)
  //    } finally {
  //      State.processStates = false
  //    }
  //  }
  //}

  suspend fun processStatesForComponent(uuid: Uuid): FetchProcessStatesForComponentResponse {
    return withContext(Dispatchers.Default) {
      async { PlannerUiServices.processStatesService.fetchProcessStatesForComponent(uuid) }.await().also {
        when (it) {
          is FetchProcessStatesForComponentResponse.Success -> dispatch(ProcessStatesForComponentLoadedAction(it.data.unsafeCast<ProcessStatesForComponent>()))
          FetchProcessStatesForComponentResponse.Failure -> {}
        }
      }
    }
  }

  fun processStatesAsync() {
    logger.info("processStatesAsync called")
    if (State.processStates) {
      logger.debug("Skip loading processStates - already loading")
      return
    }

    State.processStates = true

    launchAndNotify("ProcessStates geladen") {
      try {
        processStates()
      } finally {
        State.processStates = false
      }
    }
  }

  suspend fun processStates() {
    return withContext(Dispatchers.Default) {
      PlannerUiServices.processStatesService.fetchAllProcessStates().also {
        when (it) {
          is FetchProcessStatesResponse.Success -> dispatch(AllProcessStatesLoadedAction(it.data))
          FetchProcessStatesResponse.Failure -> {}
        }
      }
    }
  }

  fun commentsForComponentAsync(uuid: Uuid) {
    if (State.comments.contains(uuid)) {
      logger.debug("Skip loading comments for $uuid - already loading")
      return
    }
    State.comments.add(uuid)

    launchAndNotify {
      try {
        commentsForComponent(uuid)
      } finally {
        State.comments.remove(uuid)
      }
    }
  }

  suspend fun commentsForComponent(uuid: Uuid): FetchCommentsForComponentResponse {
    return withContext(Dispatchers.Default) {
      async { PlannerUiServices.commentsService.fetchCommentsForComponent(uuid) }.await().also {
        when (it) {
          is FetchCommentsForComponentResponse.Success -> dispatch(CommentsForComponentLoadedAction(it.data))
          FetchCommentsForComponentResponse.Failure -> {}
        }
      }
    }
  }

  /**
   * Loads only if necessary. If the values already exist, they are returned immediately
   */
  object IfNecessary {
    fun essentials() {
      val state = store.state

      //Already loaded, return
      if (state.essentials != null) return

      essentialsAsync()
    }

    fun resolvedProject(projectId: PhotovoltaicsProjectId, availableProducts: AvailableProducts, productResolver: ProductResolver, priceList: PriceList, companyResolver: CompanyResolver, userResolver: UserResolver) {
      val state = store.state

      //Already loaded, return
      if (state.resolvedProjects.getOrNull(projectId) != null) return

      resolvedProjectAsync(projectId, availableProducts, productResolver, priceList, companyResolver, userResolver)
    }

    //fun processStatesForComponent(uuid: Uuid) {
    //  val state = store.state
    //
    //  //Already loaded, return
    //  if (state.allProcessStatesForComponents?.getOrNull(uuid) != null) return
    //
    //  processStatesForComponentAsync(uuid)
    //}

    fun allProcessStates() {
      val state = store.state

      //Already loaded, return
      if (state.allProcessStatesForComponents != null) return

      processStatesAsync()
    }

    fun commentsForComponent(uuid: Uuid) {
      val state = store.state

      //Already loaded, return
      if (state.allCommentsForComponents.getOrNull(uuid) != null) return

      commentsForComponentAsync(uuid)
    }

  }

  private val logger: Logger = LoggerFactory.getLogger("services.Loading")
}
