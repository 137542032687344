package components.company

import components.restrictedUI
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteUsers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherUsers
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faAddUser
import it.neckar.react.common.button.*
import it.neckar.react.common.router.*
import it.neckar.react.common.table.*
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import router.RouterUrls
import services.UiActions
import store.hooks.useRequireLoggedInUser

val UsersOverview: FC<UsersOverviewProps> = fc("UsersOverview") { props ->
  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()

  val companyCode: CompanyCode = props::companyCode.safeGet()
  val usersForCompanyCode = props::usersForCompanyCode.safeGet()

  val editorColumn = TableDataColumn<UserInformation>(
    id = "editor",
    title = "Bearbeiter",
    sortFunction = compareBy { it.editorName },
  ) { user ->
    { +user.editorName }
  }

  val sortedByFunction = useState(SortedByFunction(sortedBy = SortedBy.Unsorted, tableDataColumn = editorColumn))

  val columns: List<TableColumn<UserInformation>> = buildList {

    add(TableHeaderColumn(
      id = "editButton",
    ) { user ->
      {
        if (loggedInUser.loginName == user.loginName || loggedInUser.accessRights.canAccess(EditOtherUsers)) {
          editButton {
            navigate(RouterUrls.admin.users().user(user))
          }
        }
      }
    })

    add(editorColumn)

    add(TableDataColumn(
      id = "accessRights",
      title = "Berechtigungen",
      sortFunction = compareBy { it.accessRights.format() },
    ) { user ->
      { +user.accessRights.format() }
    })

    if (loggedInUser.accessRights.canAccess(DeleteUsers)) {
      add(
        TableDataColumn(
          id = "deleteButton",
        ) { user ->
          {
            actionButtonWithConfirmationModal(
              icon = FontAwesomeIcons.trash,
              modalTitle = "Soll dieser Nutzer gelöscht werden?",
            ) { UiActions.deleteUser(user) }
          }
        })
    }

  }


  div {

    button(classes = "btn btn-sm btn-primary float-end", type = ButtonType.button) {
      attrs {
        restrictedUI(loggedInUser) { it.accessRights.canAccess(EditOtherUsers) }
        onClickFunction = { navigate(RouterUrls.admin.companies().company(companyCode).newUser) }

        span("pe-2") {
          faAddUser()
        }
        +"Neuer Nutzer"
      }
    }

    div("responsive-table") {
      table(
        columns = columns,
        entries = usersForCompanyCode,
        sortedByFunction = sortedByFunction,
      )
    }
  }
}

external interface UsersOverviewProps : Props {
  var companyCode: CompanyCode
  var usersForCompanyCode: List<UserInformation>
}
