package it.neckar.projects.common

/**
 * Represents a service
 */
interface ServiceDescriptor : RelationshipParticipant {

  /**
   * The name of the service - as defined in the docker-compose.yml
   */
  val serviceName: String

  /**
   * The API prefix of the service.
   * This is used to create the URL to the service.
   *
   * Usually, the first part is "api/"
   */
  val apiPrefix: String

  /**
   * The development state of the service
   */
  val developmentState: DevelopmentState

  /**
   * The port of the service
   */
  val port: Port

  /**
   * Returns the [serviceName]
   */
  override val participantName: String
    get() = serviceName

  override val description: String?
    get() = null

  /**
   * Returns the docker compose service name (kebab case)
   */
  fun dockerComposeServiceName(): String {
    return serviceName.toKebabCase()
  }

  enum class DevelopmentState {
    /**
     * The service is not yet implemented
     */
    NotImplemented,

    /**
     * This service is active during development
     */
    InDevelopment,

    /**
     * The service is implemented and deployed in production
     */
    InProduction,
  }
}


/**
 * Converts a camel case string to kebab case
 */
fun String.toKebabCase(): String {
  return this.replace(Regex("([a-z])([A-Z])")) {
    "${it.groupValues[1]}-${it.groupValues[2]}"
  }.lowercase()
}
