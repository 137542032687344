package it.neckar.comments.client

import it.neckar.comments.CommentsForComponent
import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Response for
 */
@Serializable
sealed interface FetchCommentsForComponentResponse : RestResponse<CommentsForComponent> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: CommentsForComponent) : FetchCommentsForComponentResponse, RestResponse.SuccessResponse<CommentsForComponent>

  @Serializable
  @SerialName("failure")
  data object Failure : FetchCommentsForComponentResponse, RestResponse.FailureResponse<CommentsForComponent>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: CommentsForComponent): FetchCommentsForComponentResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): FetchCommentsForComponentResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: CommentsForComponent?): FetchCommentsForComponentResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}
