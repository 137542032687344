package components.company

import it.neckar.customer.Address
import it.neckar.customer.company.BankInformation
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyLegalInformation
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.ContactInformation
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.customer.company.PartnerCompanyProfile
import it.neckar.customer.company.TestCompanyProfile
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.react.common.*
import it.neckar.react.common.router.*
import it.neckar.user.UserLoginName
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import router.RouterUrls
import services.UiActions
import store.hooks.useRequireCompanyForLoggedInUser

/**
 * Change password form
 */
val AddNewCompanyForm: FC<AddNewCompanyFormProps> = fc("AddNewCompanyForm") {
  val loggedInCompany = useRequireCompanyForLoggedInUser()
  val navigate = useNavigateUrl()

  val companyCode = useState("")
  val companyName = useState("")

  val street = useState("")
  val houseNumber = useState("")
  val zipCode = useState("")
  val city = useState("")

  val mail = useState("")
  val phone = useState("")
  val fax = useState("")
  val url = useState("")

  val bankName = useState("")
  val iban = useState("")
  val bic = useState("")

  val director = useState("")
  val registerCourt = useState("")
  val taxId = useState("")
  val registrationNumber = useState("")

  val companyType = useState(CompanyTypeEnum.Partner)

  val address = useMemo(street.value, houseNumber.value, zipCode.value, city.value) {
    Address(
      street = street.value,
      houseNumber = houseNumber.value,
      zipCode = zipCode.value,
      city = city.value,
    )
  }

  val contactInformation = useMemo(mail.value, phone.value, fax.value, url.value) {
    ContactInformation(
      mail = mail.value,
      phone = phone.value,
      fax = fax.value,
      url = url.value,
    )
  }

  val bankInformation = useMemo(bankName.value, iban.value, bic.value) {
    BankInformation(
      name = bankName.value,
      iban = iban.value,
      bic = bic.value,
    )
  }

  val legalInformation = useMemo(director.value, registerCourt.value, taxId.value, registrationNumber.value) {
    CompanyLegalInformation(
      director = director.value,
      registerCourt = registerCourt.value,
      taxId = taxId.value,
      registrationNumber = registrationNumber.value,
    )
  }

  val plausible = companyCode.value.isNotBlank() && companyCode.value.contains(" ").not() && companyName.value.isNotBlank() && address.isPlausible

  val (busy, setBusy) = useState(false)
  val addNewCompanyAction = useCallback(plausible, companyCode.value, companyName.value, address, contactInformation, bankInformation, legalInformation, companyType) {
    if (plausible.not()) return@useCallback

    //Always use the own company as parent company
    val relevantParentCompany = loggedInCompany.companyProfile.relevantParentCompanyCode()

    val newCompanyCode = CompanyCode(companyCode.value)

    val newCompanyProfile: CompanyProfile = when (companyType.value) {
      CompanyTypeEnum.Main -> MainCompanyProfile(newCompanyCode)
      CompanyTypeEnum.Partner -> PartnerCompanyProfile(newCompanyCode, relevantParentCompany)
      CompanyTypeEnum.Test -> TestCompanyProfile(newCompanyCode)
    }

    launchAndNotify {
      setBusy(true)
      UiActions.addNewCompany(
        newCompany = PlannerCompanyInformation(
          companyProfile = newCompanyProfile,
          name = companyName.value,
          address = address,
          contactInformation = contactInformation,
          bankInformation = bankInformation,
          legalInformation = legalInformation,
          defaultUser = UserLoginName(newCompanyCode.value + "-admin"),
        )
      ).let { newCompanyCode ->
        if (newCompanyCode != null) {
          navigate(RouterUrls.admin.companies().company(newCompanyCode).index)
        }
      }
      setBusy(false)
    }
  }

  div {

    onEnter(addNewCompanyAction)

    h2 {
      +"Neue Firma anlegen"
    }

    companyForm(
      companyCode = companyCode,
      companyName = companyName,
      companyType = companyType,
      defaultUser = null,
      street = street,
      houseNumber = houseNumber,
      zipCode = zipCode,
      city = city,
      mail = mail,
      phone = phone,
      fax = fax,
      url = url,
      bankName = bankName,
      iban = iban,
      bic = bic,
      director = director,
      registerCourt = registerCourt,
      taxId = taxId,
      registrationNumber = registrationNumber,
      existingCompany = null,
    )

    div("my-3") {
      button(classes = "btn btn-primary w-100 btn-lg", type = ButtonType.button) {
        +"Anlegen"

        attrs {
          disabled = plausible.not() || busy
          onClickFunction = { addNewCompanyAction() }
        }
      }
    }

  }

}

external interface AddNewCompanyFormProps : Props
