package services.auth.http

import it.neckar.lizergy.model.company.PlannerCompanyInformation
import kotlinx.serialization.Serializable

/**
 * The request to add a new user
 */
@Serializable
data class AddNewCompanyRequest(
  val companyInformation: PlannerCompanyInformation,
)
