@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout.roof

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

enum class MetalRoofTileCompatability {
  NotSupported,
  Supported,
  Required,

  ;

  val compatible: Boolean
    get() = when (this) {
      NotSupported -> false
      Supported -> true
      Required -> true
    }

  fun format(): String {
    return when (this) {
      NotSupported -> "-"
      Supported -> "Ja"
      Required -> "Benötigt"
    }
  }

}

/**
 * The roof type
 */
@Serializable
data class RoofType(
  /**
   * The ID that identifies the type
   */
  val id: RoofTypeId,

  val description: String,

  val metalRoofTileCompatability: MetalRoofTileCompatability,

  val withIsolation: Boolean = false,

  val requiresSparrenabstand: Boolean = true,

  val hasSparrenabstand: Boolean = true,

  val aerofixRequired: Boolean = false,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  /**
   * An id for a roof
   */
  @Serializable
  data class RoofTypeId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): RoofTypeId {
        return RoofTypeId(randomUuid4())
      }
    }
  }

}
