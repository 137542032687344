package components.sales

import components.project.HomepageProps
import components.project.homepageButtonForPhase
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.previews.ProjectQueryForUser
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry.AdvanceInvoiceProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry.AssemblyPortfolioProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry.GridAssessmentProcessStates
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry.OrderSpecialMaterialProcessStates
import it.neckar.lizergy.model.project.process.state.onlyUnfinished
import react.*
import react.dom.*
import router.RouterUrls
import services.http.ProjectCountRequestForPhase

val PlanningHomepage: FC<HomepageProps> = fc("PlanningHomepage") { props ->
  val projectCountsForPhases = props::projectCountsForPhases.safeGet()

  div {

    h3("mb-3") {
      +"Arbeitsvorbereitung"
    }

    div("row row-cols-1 gy-2") {

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.orderSpecialMaterial,
        title = "Sondermaterial Bestellung",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.OrderSpecialMaterial),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.gridAssessment,
        title = "Netzvoranfrage",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.GridAssessment),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.assemblyPortfolio,
        title = "Montagemappe erstellen",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.AssemblyPortfolio),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.scheduleAssembly,
        title = "Montagetermin einplanen",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.ScheduleAssembly),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.advanceInvoice,
        title = "Anzahlungsrechnung",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.AdvanceInvoice),
      )

    }

  }
}

val PlanningCountRequest: List<ProjectCountRequestForPhase> = listOf(
  ProjectCountRequestForPhase(
    relevantProcessStates = OrderSpecialMaterialProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.OrderSpecialMaterial,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = GridAssessmentProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.GridAssessment,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = AssemblyPortfolioProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.AssemblyPortfolio,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = listOf(AssemblyRoofPreparationProcessStates.Missing, AssemblyBasementPreparationProcessStates.Missing),
    projectQueryComponent = ProjectQueryComponent.ScheduleAssembly,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = AdvanceInvoiceProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.AdvanceInvoice,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
)
