package it.neckar.lizergy.model.company.user

import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditConfigurations
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditCustomers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditModuleLayouts
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOwnCompany
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarnings
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsDistributionBlueprintRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsElectricityWorkRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsReferrerRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsRoofAssemblyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsScaffoldingAssemblyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsTechnicalPlanningRoofRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewPriceList
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewQuoteDump
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import kotlinx.serialization.Serializable

/**
 * The access rights of user
 */
@Serializable
data class AccessRights(
  val rights: Set<AccessRight>,
) {

  fun canAccess(vararg rightsToCheck: AccessRight): Boolean {
    return rightsToCheck.any { rights.contains(it) }
  }

  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    return when (this.rights) {
      admin.rights -> "Administrator"
      editor.rights -> "Bearbeiter"
      editorPlus.rights -> "Bearbeiter+"
      distributionPool.rights -> "Vertriebspool"

      partnerCarpenter.rights -> "Handwerkspartner Dach"
      partnerCarpenterEditor.rights -> "Handwerkspartner Dach Bearbeiter"

      partnerElectrician.rights -> "Handwerkspartner Elektrik"
      partnerElectricianEditor.rights -> "Handwerkspartner Elektrik Bearbeiter"

      none.rights -> "Keine Berechtigungen"
      else -> "Benutzerdefiniert ${rights.format(i18nConfiguration)}"
    }
  }

  private fun Set<AccessRight>.format(i18nConfiguration: I18nConfiguration): String {
    return joinToString(", ", "[ ", " ]") {
      it.format(i18nConfiguration)
    }
  }

  companion object {
    val allAccessRights: Set<AccessRight> = AccessRight.entries.toSet()

    val admin: AccessRights = AccessRights(allAccessRights)
    val none: AccessRights = AccessRights(emptySet())

    val editor: AccessRights = AccessRights(setOf(EditCustomers, EditProjects, EditConfigurations, EditModuleLayouts, ViewPriceList, ViewQuoteDump))
    val editorPlus: AccessRights = AccessRights(setOf(EditCustomers, EditProjects, DeleteProjects, EditConfigurations, EditModuleLayouts, ViewPriceList, ViewQuoteDump))
    val distributionPool: AccessRights = AccessRights(setOf(EditCustomers, EditProjects, EditConfigurations))

    val partnerCarpenter: AccessRights = AccessRights(setOf(EditCustomers, EditProjects, EditOwnCompany, ViewEarnings, ViewEarningsReferrerRow, ViewEarningsDistributionBlueprintRow, ViewEarningsTechnicalPlanningRoofRow, ViewEarningsRoofAssemblyRow, ViewEarningsScaffoldingAssemblyRow))
    val partnerCarpenterEditor: AccessRights = AccessRights(setOf(EditCustomers, EditProjects, ViewEarnings, ViewEarningsReferrerRow, ViewEarningsDistributionBlueprintRow, ViewEarningsTechnicalPlanningRoofRow, ViewEarningsRoofAssemblyRow, ViewEarningsScaffoldingAssemblyRow))
    val partnerElectrician: AccessRights = AccessRights(setOf(EditCustomers, EditProjects, EditOwnCompany, ViewEarnings, ViewEarningsReferrerRow, ViewEarningsDistributionBlueprintRow, ViewEarningsTechnicalPlanningRoofRow, ViewEarningsElectricityWorkRow))
    val partnerElectricianEditor: AccessRights = AccessRights(setOf(EditCustomers, EditProjects, ViewEarnings, ViewEarningsReferrerRow, ViewEarningsDistributionBlueprintRow, ViewEarningsTechnicalPlanningRoofRow, ViewEarningsElectricityWorkRow))
  }


  enum class AccessRight {
    EditCustomers,
    EditProjects,
    EditConfigurations,
    EditModuleLayouts,
    DeleteProjects,
    VerifyProjects,

    EditOtherUsers,
    EditOwnCompany,

    /**
     * Allowed to edit other (partner) companies
     */
    EditOtherCompanies,
    DeleteUsers,

    ViewPriceList,
    ViewProjectCount,
    DeleteProcessStates,
    ViewAccounting,
    ViewQuoteDump,
    ViewProfits,
    ViewEarnings,
    EditEarnings,

    /**
     * Access Role for every Row in the Earnings-Tab Table
     */
    ViewEarningsReferrerRow,
    ViewEarningsDistributionBlueprintRow,
    ViewEarningsDistributionQuoteRow,
    ViewEarningsTechnicalPlanningRoofRow,
    ViewEarningsTechnicalPlanningElectricRow,
    ViewEarningsRoofAssemblyRow,
    ViewEarningsScaffoldingAssemblyRow,
    ViewEarningsElectricityWorkRow,
    ViewEarningsBureaucracyRow

    ;

    @Suppress("UNUSED_PARAMETER")
    fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
      return when (this) {
        EditCustomers -> "Kunden bearbeiten"
        EditProjects -> "Projekte bearbeiten"
        EditConfigurations -> "Angebote bearbeiten"
        EditModuleLayouts -> "Modulbelegungen bearbeiten"
        DeleteProjects -> "Projekte löschen"
        VerifyProjects -> "Projekte prüfen"

        EditOtherUsers -> "Andere Nutzer ändern"
        EditOwnCompany -> "Eigene Firma ändern"
        EditOtherCompanies -> "Andere Firmen ändern"
        DeleteUsers -> "Benutzer löschen"

        ViewPriceList -> "Preisliste einsehen"
        ViewProjectCount -> "Projektanzahl auf Homepage einsehen"
        DeleteProcessStates -> "Projektzustände löschen"
        ViewAccounting -> "Abrechnung einsehen"

        ViewQuoteDump -> "Rechnungsdetails einsehen"
        ViewProfits -> "Deckungsbeitrag einsehen"
        ViewEarnings -> "Verdienst einsehen"
        EditEarnings -> "Verdienst bearbeiten"

        ViewEarningsReferrerRow -> "Tippgeber einsehen"
        ViewEarningsDistributionBlueprintRow -> "Vertrieb Projekterfassung einsehen"
        ViewEarningsDistributionQuoteRow -> "Vertrieb Angebotsvorstellung einsehen"
        ViewEarningsTechnicalPlanningRoofRow -> "Technische Planung Dach einsehen"
        ViewEarningsTechnicalPlanningElectricRow -> "Technische Planung Elektrik einsehen"
        ViewEarningsRoofAssemblyRow -> "Montage PV Dach einsehen"
        ViewEarningsScaffoldingAssemblyRow -> "Montage PV Gerüst einsehen"
        ViewEarningsElectricityWorkRow -> "Elektroinstallation Haus einsehen"
        ViewEarningsBureaucracyRow -> "Netzvoranfrage und Fertigmeldung einsehen"
      }
    }
  }
}
