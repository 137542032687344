package it.neckar.lizergy.model.configuration.moduleLayout.roof

import kotlinx.serialization.Serializable

@Serializable
data class ConfigurationAnnotation<T>(
  val id: T,
  val annotation: String?,
)

operator fun <T> List<ConfigurationAnnotation<T>>.get(id: T): String? {
  return firstOrNull {
    it.id == id
  }?.annotation
}

fun <T> List<ConfigurationAnnotation<T>>.withAdded(newAnnotation: ConfigurationAnnotation<T>): List<ConfigurationAnnotation<T>> {
  return toMutableList() + newAnnotation
}

fun <T> List<ConfigurationAnnotation<T>>.withUpdated(updatedAnnotation: ConfigurationAnnotation<T>): List<ConfigurationAnnotation<T>> {
  return this.toMutableList()
    .also { mutableList ->
      //find the index of the existing element
      val index = indexOfFirst {
        it.id == updatedAnnotation.id
      }

      if (index < 0) {
        return withAdded(updatedAnnotation)
      }

      mutableList[index] = updatedAnnotation
    }
}
