package it.neckar.lizergy.model.configuration.moduleLayout.roof

/**
 * Represents on of 8 cardinal directions
 */
enum class CardinalDirection(val labelGerman: String) {
  North("Nord"),
  NorthEast("Nord-Ost"),
  East("Ost"),
  SouthEast("Süd-Ost"),
  South("Süd"),
  SouthWest("Süd-West"),
  West("West"),
  NorthWest("Nord-West"),
}
