@file:UseSerializers(UuidSerializer::class)

package serialized

import it.neckar.lizergy.model.ElementsSelection
import it.neckar.lizergy.model.ElementsSelectionEntry
import it.neckar.lizergy.model.configuration.quote.builder.InverterSelection
import it.neckar.lizergy.model.configuration.quote.builder.InverterSelection.InverterSelectionId
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents the selection of multiple inverters of multiple [InverterId]s
 */
@Serializable
data class InverterIdSelection(
  override val id: InverterSelectionId = InverterSelectionId.random(),
  override val entries: List<ElementsSelectionEntry<InverterId>>,
) : InverterSelection, ElementsSelection<InverterId> {

  override fun InverterId.format(): String {
    return format()
  }

  fun resolve(productResolver: ProductResolver): ResolvedInverterSelection {
    return ResolvedInverterSelection(
      id = id,
      entries = entriesNonEmpty.map { ElementsSelectionEntry(productResolver[it.element], it.amount) },
    )
  }


  companion object {
    fun getEmpty(id: InverterSelectionId = InverterSelectionId.random()): InverterIdSelection {
      return InverterIdSelection(id = id, entries = emptyList())
    }
  }
}


fun ResolvedInverterSelection.unResolve(): InverterIdSelection {
  return InverterIdSelection(
    id = id,
    entries = entriesNonEmpty.map { ElementsSelectionEntry(it.element.id, it.amount) },
  )
}
