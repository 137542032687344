@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.energy.Capacity
import it.neckar.open.formatting.format
import it.neckar.open.formatting.prettyFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.unit.other.`in`
import it.neckar.open.unit.si.cm
import it.neckar.open.unit.si.kW
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class HeaterRod(
  val id: HeaterRodId,

  val description: String,

  val heatingCapacity: HeatingCapacity,

  val length: @cm Double,

  val diameter: @`in` Double,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  fun format(i18nConfiguration: I18nConfiguration): String {
    return "$description, ${heatingCapacity.capacity().format()}, ${length.format(0, i18nConfiguration = i18nConfiguration)}cm Länge, ${diameter.prettyFormat(numberOfDecimals = 1, i18nConfiguration = i18nConfiguration)} Zoll"
  }

  enum class HeatingCapacity {
    KW3,
    KW3_5,
    KW11,
    KW22,

    ;

    fun capacity(): @kW Capacity {
      return when (this) {
        KW3 -> Capacity(3.0)
        KW3_5 -> Capacity(3.5)
        KW11 -> Capacity(11.0)
        KW22 -> Capacity(22.0)
      }
    }
  }

  /**
   * An id for an inverter type
   */
  @Serializable
  data class HeaterRodId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): HeaterRodId {
        return HeaterRodId(randomUuid4())
      }
    }
  }
}
