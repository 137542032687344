package services.auth.http

import it.neckar.customer.company.CompanyCode
import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


/**
 * The response to change the password for a user
 */
/**
 * Response for
 */
@Serializable
sealed interface AddNewCompanyResponse : RestResponse<CompanyCode> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: CompanyCode) : AddNewCompanyResponse, RestResponse.SuccessResponse<CompanyCode>

  @Serializable
  @SerialName("failure")
  data class Failure(
    val errorType: ErrorType,
  ) : AddNewCompanyResponse, RestResponse.FailureResponse<CompanyCode> {

    enum class ErrorType {
      CompanyAlreadyExists,
      FailToFetch,
    }
  }

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: CompanyCode): AddNewCompanyResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(errorType: Failure.ErrorType): AddNewCompanyResponse = Failure(errorType)
  }
}
