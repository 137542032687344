package components

import it.neckar.react.common.FontAwesome.faInformationCircle
import it.neckar.react.common.error.*
import react.*
import react.dom.*

val ClientVersionMismatchPage: FC<Props> = fc("ClientVersionMismatch") { props ->
  div {
    main {
      div("container p-4") {

        ErrorPageHeader {
          attrs {
            showReload = false
          }
        }

        div("my-3") {
          h3 {
            span("pe-2") { faInformationCircle() }
            +"Es ist eine neue Version des PV-Planners verfügbar"
          }
          div(classes = "pt-3") {
            p {
              +"Bitte laden Sie die Seite mit "
              b { +"Strg + F5" }
              +" neu um die neue Version zu nutzen!"
            }
          }

          //Fireworks below!
          div(classes = "pyro") {
            div(classes = "before") {}
            div(classes = "after") {}
          }

        }
      }
    }
  }
}
