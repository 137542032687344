package components

import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.react.common.*
import kotlinx.html.CommonAttributeGroupFacade
import react.router.dom.*

fun CommonAttributeGroupFacade.restrictedUI(loggedInUser: UserInformation, accessRequirement: (UserInformation) -> Boolean) {
  if (accessRequirement(loggedInUser).not()) {
    addClass("d-none")
  }
}

fun LinkProps.restrictedUI(loggedInUser: UserInformation, accessRequirement: (UserInformation) -> Boolean) {
  if (accessRequirement(loggedInUser).not()) {
    addClass("d-none")
  }
}

