package it.neckar.lizergy.model.price

import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.income.IncomePercentageCategory
import kotlinx.serialization.Serializable

@Serializable
data class ManualQuoteElements(val manualSumsForTags: Map<IncomePercentageCategory, PositionEditHistory<Money?>>) {
  companion object {
    val empty: ManualQuoteElements = ManualQuoteElements(emptyMap())
  }
}
