package store

import it.neckar.heartbeat.HeartbeatState

/**
 * Represents the online state for this app
 */
enum class OnlineState {
  /**
   * Initial state - no information available yet
   */
  Unknown,

  /**
   * (Some) services are not available
   */
  Offline,

  /**
   * Online - but refresh of this app is required
   */
  RefreshRequired,

  /**
   * We are online and the app is up-to-date
   */
  Online,
}

/**
 * Converts a (common) [HeartbeatState] to a planner specific [OnlineState]
 */
fun HeartbeatState.toOnlineState(): OnlineState {
  return when (this) {
    is HeartbeatState.Alive -> OnlineState.Online
    is HeartbeatState.Dead.VersionMismatch -> OnlineState.RefreshRequired
    is HeartbeatState.Dead -> OnlineState.Offline
  }
}
