package components.project.configuration.additional

import components.project.freezableEditButton
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.ExistingBHKWFacilityConfiguration
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import kotlinx.html.title
import plannerI18nConfiguration
import react.*
import react.dom.*

/**
 *
 */
fun RBuilder.existingBHKWFacilityPreview(
  existingBHKWFacilityConfiguration: ExistingBHKWFacilityConfiguration,
  editableStatus: EditableStatus,
  editAction: (additionalPosition: ExistingBHKWFacilityConfiguration) -> Unit,
  deleteAction: (additionalPosition: ExistingBHKWFacilityConfiguration) -> Unit,
): Unit = child(ExistingBHKWFacilityPreview) {
  attrs {
    this.existingBHKWFacilityConfiguration = existingBHKWFacilityConfiguration
    this.editableStatus = editableStatus
    this.editAction = editAction
    this.deleteAction = deleteAction
  }
}

val ExistingBHKWFacilityPreview: FC<ExistingBHKWFacilityPreviewProps> = fc("ExistingBHKWFacilityPreview") { props ->
  val existingPVFacilityConfiguration = props::existingBHKWFacilityConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()
  val editAction = props::editAction.safeGet()
  val deleteAction = props::deleteAction.safeGet()


  div {
    attrs {
      if (editableStatus == ReadOnly) {
        title = "Dieses Angebot kann nicht weiter bearbeitet werden!"
      }
    }

    h4("card-title mt-0 mb-3") {
      +"Bestandsanlage BHKW"

      freezableEditButton(editableStatus) {
        editAction(existingPVFacilityConfiguration)
      }

      if (editableStatus == Editable) {
        actionButtonWithConfirmationModal(
          icon = FontAwesomeIcons.trash,
          modalTitle = "Soll die Bestandsanalge BHKW gelöscht werden?",
        ) { deleteAction(existingPVFacilityConfiguration) }
      }
    }

    p {
      +"Zählernummer ${existingPVFacilityConfiguration.zaehlernummer}"
    }
    p {
      +"Einspeiseart: ${existingPVFacilityConfiguration.einspeiseart.format(plannerI18nConfiguration)}"
    }

  }
}

external interface ExistingBHKWFacilityPreviewProps : Props {
  var existingBHKWFacilityConfiguration: ExistingBHKWFacilityConfiguration
  var editableStatus: EditableStatus
  var editAction: (additionalPosition: ExistingBHKWFacilityConfiguration) -> Unit
  var deleteAction: (additionalPosition: ExistingBHKWFacilityConfiguration) -> Unit
}
