package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.Module
import com.meistercharts.charts.lizergy.roofPlanning.ModuleArea
import com.meistercharts.charts.lizergy.roofPlanning.ModulePlacement
import com.meistercharts.charts.lizergy.roofPlanning.Modules
import kotlinx.serialization.Serializable

/**
 * Contains information about modules
 */
@Serializable
data class ModulesInformation(
  val moduleInformations: List<ModuleInformation>,
) {

  /**
   * The total number of modules regardless of their visibility
   */
  val modulesCount: Int
    get() = moduleInformations.size

  /**
   * Convert back to "live" modules
   */
  fun toModules(moduleArea: ModuleArea): List<Module> {
    return buildList {
      moduleInformations.map {
        Module(
          size = moduleArea.modulesSize,
          modulePlacement = ModulePlacement(moduleIndex = it.moduleIndex, moduleArea = moduleArea)
        ).also { module ->
          module.deleted = it.deleted
        }
      }.let {
        addAll(it)
      }
    }
  }
}

fun Modules.toRestApiModel(): ModulesInformation {
  return ModulesInformation(
    this.modulesWithGridLayout.map {
      it.modulePlacement.toRestApiModel()
    }
  )
}
