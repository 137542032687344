package com.meistercharts.charts.lizergy.roofPlanning

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuid4
import com.meistercharts.model.Insets
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Size
import it.neckar.open.collections.fastForEach
import it.neckar.open.observable.ObservableObject
import it.neckar.open.unit.si.mm

/**
 * Represents a model for a roof planning
 */
class PvRoofPlanningModel {
  val roofSizeProperty: @mm ObservableObject<Size> = ObservableObject(Size(12_000, 8_000))

  /**
   * The roof size
   */
  var roofSize: @mm Size by roofSizeProperty

  /**
   * The property for [modulesSize] - the size of the modules.
   *
   * ATTENTION: If this size is changed, all modules must be updated (or the layout recalculated)
   */
  val modulesSizeProperty: @mm ObservableObject<ModuleSize> = ObservableObject(ModuleSize(1700, 1000))

  /**
   * @see modulesSizeProperty
   */
  var modulesSize: @mm ModuleSize by modulesSizeProperty

  /**
   * The property for the [suggestedRoofInsets] - the suggested margins for the roof planning
   *
   * ATTENTION: Changes require a refresh
   */
  val suggestedRoofInsetsProperty: @mm ObservableObject<Insets> = ObservableObject(Insets.of(100.0, 100.0, 100.0, 100.0))

  /**
   * @see suggestedRoofInsetsProperty
   */
  var suggestedRoofInsets: @mm Insets by suggestedRoofInsetsProperty

  /**
   * The unusable areas
   */
  val unusableAreas: UnusableAreas = UnusableAreas()

  val moduleAreas: ModuleAreas = ModuleAreas(emptyList())


  fun addModuleArea(
    uuid: Uuid = uuid4(),
    modulesSize: ModuleSize = modulesSizeProperty.get(),
    location: Coordinates = Coordinates(suggestedRoofInsets.left * 4, suggestedRoofInsets.bottom * 4),
    size: Size = Size(roofSize.width - suggestedRoofInsets.offsetWidth * 4, roofSize.height - suggestedRoofInsets.offsetHeight * 4),
    orientation: ModuleOrientation = ModuleOrientation.Vertical,
    gap: @mm Double = 20.0,
  ): ModuleArea {
    return ModuleArea(
      uuid = uuid,
      initialLocation = location,
      initialSize = size,
      modulesSize = modulesSize,
      initialOrientation = orientation,
      gap = gap,
      unusableAreas = unusableAreas,
    ).also {
      moduleAreas.add(it)
    }
  }

  fun addUnusableArea(newUnusableArea: UnusableArea) {
    unusableAreas.add(newUnusableArea)
    updateModuleAreas(modulesSize)
  }

  fun removeUnusableArea(unusableArea: UnusableArea) {
    unusableAreas.remove(unusableArea)
    updateModuleAreas(modulesSize)
  }

  /**
   * The [Modules]
   */
  fun modules(): Modules {
    val modules = Modules()
    moduleAreas.moduleAreas.fastForEach { it: ModuleArea ->
      modules.addAll(it.modules.modules)
    }
    return modules
  }

  fun removeModule(module: Module) {
    moduleAreas.moduleAreas.fastForEach {
      it.modules.remove(module)
    }
  }

  fun removeModules(modules: Iterable<Module>) {
    moduleAreas.moduleAreas.fastForEach {
      it.modules.removeAll(modules)
    }
  }

  fun removeModules(predicate: (Module) -> Boolean) {
    moduleAreas.moduleAreas.fastForEach {
      it.modules.removeAll(predicate)
    }
  }

  /**
   * Helper method to fill the grid with modules
   */
  fun updateModuleAreas(newModuleSize: ModuleSize) {
    moduleAreas.moduleAreas.fastForEach { moduleArea ->
      moduleArea.fillGrid(newModuleSize)
    }
  }

  fun deleteAllAreas() {
    moduleAreas.moduleAreas.fastForEach { moduleArea ->
      moduleArea.clear()
    }
    moduleAreas.clear()
  }

  fun deleteAllUnusableAreas() {
    unusableAreas.clear()
    updateModuleAreas(modulesSize)
  }
}
