package components.project.blueprint

import com.meistercharts.maps.MapCoordinates
import com.meistercharts.maps.RouteMapCoordinates
import components.form.commentSection
import components.project.configuration.location.EditLocation
import components.project.customer.EditCustomer
import components.project.editProcessStateEntry
import components.project.editor.editorForm
import components.project.isProjectEditable
import components.project.processStatesList
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.Address
import it.neckar.customer.Customer
import it.neckar.lizergy.model.location.MapCoordinatesInformation
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry.BlueprintAcquisitionProcessStates
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry.BlueprintProcessStates
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.open.formatting.dateFormat
import it.neckar.open.kotlin.lang.toIntCeil
import it.neckar.open.unit.si.km
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver

val BlueprintCustomerFromUrl: FC<Props> = fc("BlueprintCustomerFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) })

  busyIfNull(resolvedProject) { loadedProject ->
    BlueprintCustomer {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val BlueprintCustomer: FC<BlueprintCustomerProps> = fc("BlueprintCustomer") { props ->
  val navigate = useNavigateUrl()
  val userResolver = useSelectUserResolver()
  val companyResolver = useSelectCompanyResolver()

  val loggedInCompany = useRequireCompanyForLoggedInUser()

  val project = props::project.safeGet()
  val blueprint = project.blueprint
  val location = blueprint.location

  val editableStatus = project.isProjectEditable()

  val processStateEntries = useLoadProcessStatesForComponent(blueprint.uuid)
  val currentProcessStateEntry = processStateEntries?.current()

  val advancedTabs = if (currentProcessStateEntry is BlueprintProcessStateEntry) {
    EditableStatus.Editable
  } else {
    EditableStatus.ReadOnly
  }

  val nextProcessStateForBlueprint = if (currentProcessStateEntry is BlueprintAcquisitionProcessStateEntry && currentProcessStateEntry.dueDate != null) {
    BlueprintProcessStates.BeingEdited
  } else null

  val customerToSave = useState(project.customer)

  val addressToSave = useState(location.address)
  val coordinatesToSave = useState(location.coordinates)

  val shippingStartCoordinated = useState(MapCoordinates.lizergy)
  val manualShippingDistanceToSave = useState(blueprint.shippingDistanceManual)
  val calculatedShippingDistanceToSave = useState(blueprint.shippingDistanceCalculated)

  val maintainer = project.maintainer

  val forCompanyState = useState(blueprint.sellingCompany)
  val loginNameState = useState(maintainer)

  val maintainerToSave = useMemo(loginNameState.value) {
    userResolver[loginNameState.value]
  }

  val timeState = useState(
    blueprint.processState?.allEdits?.lastOrNull {
      it.value == OLDProcessState.ProjectScheduled
    }?.editTime
  )
  val acquisitionTimeState = useState(blueprint.acquisitionDate)

  val okAction = {
    navigate(RouterUrls.blueprint(project).moduleLayouts)
  }


  useMemo(customerToSave.value, maintainerToSave) {
    project.copy(
      customer = customerToSave.value,
      maintainerInformation = maintainerToSave,
    ).also {
      UiActions.saveProject(it)
    }
  }

  useMemo(forCompanyState.value, addressToSave.value, coordinatesToSave.value, manualShippingDistanceToSave.value, calculatedShippingDistanceToSave.value, timeState.value, acquisitionTimeState.value) {
    blueprint.copy(
      sellingCompanyInformation = companyResolver[forCompanyState.value],
      location = location.copy(
        address = addressToSave.value,
        coordinates = coordinatesToSave.value,
      ),
      shippingDistanceManual = manualShippingDistanceToSave.value,
      shippingDistanceCalculated = calculatedShippingDistanceToSave.value,
      acquisitionDate = acquisitionTimeState.value,
    ).also { blueprintToSave ->
      UiActions.saveBlueprint(blueprintToSave)
    }
  }

  useEffect(loggedInCompany) {
    UiActions.coordinatesForAddress(loggedInCompany.address) {
      shippingStartCoordinated.setter(it?.coords ?: MapCoordinates.lizergy)
    }
  }

  useEffect(shippingStartCoordinated.value, coordinatesToSave.value) {
    coordinatesToSave.value?.let { locationCoords ->
      UiActions.routeInformationForCoordinates(
        RouteMapCoordinates(
          startMapCoordinates = shippingStartCoordinated.value,
          destinationMapCoordinates = locationCoords.coords,
        )
      ) { routeInformation ->
        if (routeInformation != null) {
          calculatedShippingDistanceToSave.setter((routeInformation.distance / 1000.0).toIntCeil())
        }
      }
    }
  }


  div("row my-4") {
    if (advancedTabs == EditableStatus.Editable) onEnter { okAction() }

    EditBlueprintCustomer {
      attrs {
        this.project = project
        this.customerToSave = customerToSave
        this.addressToSave = addressToSave
        this.coordinatesToSave = coordinatesToSave
        this.manualShippingDistanceToSave = manualShippingDistanceToSave
        this.calculatedShippingDistanceToSave = calculatedShippingDistanceToSave
        this.timeState = timeState
        this.acquisitionTimeState = acquisitionTimeState
        this.customerAddress = project.customer.address
      }
    }

    div("col-6 my-4") {
      editorForm(
        editorTitle = "Ansprechpartner",
        forCompany = forCompanyState,
        loginName = loginNameState,
        editableStatus = editableStatus,
      )
    }

    div("col-md-6 my-4") {
      editProcessStateEntry(
        processStateFor = blueprint,
        availableProcessStates = currentProcessStateEntry?.availableOptions ?: BlueprintAcquisitionProcessStates.entries,
        nextProcessState = nextProcessStateForBlueprint,
        editableStatus = project.isProjectEditable(),
      )

      processStatesList(blueprint)
    }

    div("my-3") {
      h3("mb-3") {
        +"Bemerkungen Projekt"
      }
      blueprint.annotation?.let {
        p { +it }
      }
      commentSection(project)
    }

    if (advancedTabs == EditableStatus.Editable) {
      wizardFormButtons(
        backwardAction = { navigate(RouterUrls.OverviewTableRouterUrls.blueprints) },
        forwardAction = { okAction() },
      )
    }
  }

}


val EditBlueprintCustomer: FC<EditBlueprintCustomerProps> = fc("EditBlueprintCustomer") { props ->
  val project = props::project.safeGet()
  val customerAddress = props::customerAddress.safeGet()

  val customerToSave = props::customerToSave.getNotNull()
  val addressToSave = props::addressToSave.getNotNull()
  val manualShippingDistanceToSave = props::manualShippingDistanceToSave.getNotNull()
  val calculatedShippingDistanceToSave = props::calculatedShippingDistanceToSave.getNotNull()
  val acquisitionTimeState = props::acquisitionTimeState.getNotNull()

  val coordinatesToSave = props::coordinatesToSave.getNotNull()
  val timeState = props::timeState.getNotNull()

  val editableStatus = project.isProjectEditable()


  div("row gx-5") {
    div("col-lg-6") {
      EditCustomer {
        attrs {
          this.customerToSave = customerToSave
        }
      }
    }

    div("col-lg-6") {
      EditLocation {
        attrs {
          this.addressToSave = addressToSave
          this.coordinatesToSave = coordinatesToSave
          this.manualShippingDistanceToSave = manualShippingDistanceToSave
          this.calculatedShippingDistanceToSave = calculatedShippingDistanceToSave
          this.customerAddress = customerAddress
          this.editableStatus = editableStatus
        }
      }
    }
  }

  div("row my-3") {

    div("col-6") {
      h2("mb-4") {
        +"Erfassungsdatum"
      }
      datePicker(acquisitionTimeState)
    }

    timeState.value?.let {
      div("col-6") {
        h2("mb-4") {
          +"Termin Projekterfassung"
        }
        p {
          +"Alter Termin: ${dateFormat.format(it, plannerI18nConfiguration)}"
        }
      }
    }

  }

}


external interface BlueprintCustomerProps : Props {
  var project: ResolvedProject
}

external interface EditBlueprintCustomerProps : Props {
  var project: ResolvedProject
  var customerToSave: StateInstance<Customer>
  var addressToSave: StateInstance<Address>
  var coordinatesToSave: StateInstance<MapCoordinatesInformation?>
  var manualShippingDistanceToSave: StateInstance<@km Int?>
  var calculatedShippingDistanceToSave: StateInstance<@km Int?>
  var timeState: StateInstance<Double?>
  var acquisitionTimeState: StateInstance<Double>
  var customerAddress: Address
}
