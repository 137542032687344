package components.project

import components.form.commentSection
import components.project.configuration.ConfigurationPreviewWithComments
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry.AdvanceInvoiceProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementProcessStateEntry.AssemblyBasementProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry.AssemblyPortfolioProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry.AssemblyRoofProcessStates
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry.DocumentationProcessStates
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry.FinalAccountProcessStates
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry.FinishingUpProcessStates
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry.GridAssessmentProcessStates
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry.OrderSpecialMaterialProcessStates
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry.StartupOperationsProcessStates
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry.SwitchMeterBoxProcessStates
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.lizergy.model.stumps.RestArbeiten
import it.neckar.open.time.nowMillis
import it.neckar.processStates.HasDueDate
import it.neckar.processStatesClient.SendProcessStatesTuple
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleExclamation
import it.neckar.react.common.form.*
import react.*
import react.dom.*
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser

val AdvanceInvoiceProjectStumpOverviewFromUrl: FC<Props> = fc("AdvanceInvoiceProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Anzahlungsrechnung - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    AdvanceInvoiceProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val AdvanceInvoiceProjectStumpOverview: FC<AdvanceInvoiceProjectStumpOverviewProps> = fc("AdvanceInvoiceProjectStumpOverview") { props ->
  val project = props::project.safeGet()
  val advanceInvoice = project.advanceInvoice
  val assemblyBasement = project.assemblyBasement
  val assemblyRoof = project.assemblyRoof


  div("row my-5") {
    div("col-md-6") {
      editProcessStateEntry(advanceInvoice, AdvanceInvoiceProcessStates.entries, editableStatus = project.isProjectEditable())
      processStatesList(advanceInvoice)
    }
    div("col-md-6") { commentSection(advanceInvoice) }

    div("col-md-6") {
      h5 { +"Montage Keller" }
      processStatesList(assemblyBasement)
      h5 { +"Montage Dach" }
      processStatesList(assemblyRoof)
    }
  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface AdvanceInvoiceProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}


val AssemblyBasementProjectStumpOverviewFromUrl: FC<Props> = fc("AssemblyBasementProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Montage Keller - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    AssemblyBasementProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val AssemblyBasementProjectStumpOverview: FC<AssemblyBasementProjectStumpOverviewProps> = fc("AssemblyBasementProjectStumpOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val processStatesResolver = useLoadProcessStates()

  val project = props::project.safeGet()
  val assemblyBasement = project.assemblyBasement
  val assemblyRoof = project.assemblyRoof
  val switchMeterBox = project.switchMeterBox
  val startupOperations = project.startupOperations

  val assemblyBasementProcessStateEntry = useLoadProcessStatesForComponent(assemblyBasement.uuid)?.current()
  val assemblyRoofProcessState = useLoadProcessStatesForComponent(assemblyRoof.uuid)?.current()?.processState
  val switchMeterBoxProcessState = useLoadProcessStatesForComponent(switchMeterBox.uuid)?.current()?.processState
  val startupOperationsProcessState = useLoadProcessStatesForComponent(startupOperations.uuid)?.current()?.processState


  useMemo(assemblyRoofProcessState, assemblyBasementProcessStateEntry?.processState) {
    val automaticProcessStates = buildList {
      if (assemblyRoofProcessState == AssemblyRoofProcessStates.Done && assemblyBasementProcessStateEntry?.processState == AssemblyBasementProcessStates.Done) {

        if (switchMeterBoxProcessState == null) {
          add(SendProcessStatesTuple(switchMeterBox.uuid, SwitchMeterBoxProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

        if (startupOperationsProcessState == null) {
          add(SendProcessStatesTuple(startupOperations.uuid, StartupOperationsProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

      }
    }

    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }


  div("row my-5") {
    val advanceInvoice = project.advanceInvoice
    val advanceInvoiceProcessState = useLoadProcessStatesForComponent(advanceInvoice.uuid)?.current()?.processState

    if (advanceInvoiceProcessState != AdvanceInvoiceProcessStates.Paid) {
      p("text-warning mb-3") {
        span("pe-2") { faCircleExclamation() }
        span { +"Anzahlungsrechnung wurde noch nicht gezahlt!" }
      }
    }

    div("col-md-6") {
      AssemblyBasementOverview {
        attrs {
          this.project = project
        }
      }
      processStatesList(assemblyBasement)

      if (assemblyRoofProcessState == AssemblyRoofProcessStates.Done && assemblyBasementProcessStateEntry?.processState == AssemblyBasementProcessStates.Done) {
        if (switchMeterBoxProcessState == null || switchMeterBoxProcessState == SwitchMeterBoxProcessStates.Missing) {
          editProcessStateEntry(processStateFor = switchMeterBox, availableProcessStates = listOf(SwitchMeterBoxProcessStates.Missing), editableStatus = project.isProjectEditable())
          processStatesList(switchMeterBox)
        }

        if (startupOperationsProcessState == null || startupOperationsProcessState == StartupOperationsProcessStates.Missing) {
          editProcessStateEntry(processStateFor = startupOperations, availableProcessStates = listOf(StartupOperationsProcessStates.Missing), editableStatus = project.isProjectEditable())
          processStatesList(startupOperations)
        }
      }
    }
    div("col-md-6") { commentSection(assemblyBasement) }
  }

  EditAssemblyBasementRestarbeiten {
    attrs {
      this.project = project
      this.showOnlyRelevant = false
    }
  }

  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface AssemblyBasementProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}

private val AssemblyBasementOverview: FC<AssemblyBasementOverviewProps> = fc("AssemblyBasementOverview") { props ->
  val project = props::project.safeGet()
  val assemblyBasement = project.assemblyBasement

  val assemblyBasementProcessStateEntry = useLoadProcessStatesForComponent(assemblyBasement.uuid)?.current()

  val nextAssemblyBasementProcessState = if (
    assemblyBasementProcessStateEntry?.processState == AssemblyBasementPreparationProcessStates.Done &&
    assemblyBasementProcessStateEntry is AssemblyBasementPreparationProcessStateEntry && assemblyBasementProcessStateEntry.dueDate != null
  ) AssemblyBasementProcessStates.InProgress else null

  editProcessStateEntry(
    processStateFor = assemblyBasement,
    availableProcessStates = assemblyBasementProcessStateEntry?.availableOptions ?: AssemblyBasementPreparationProcessStates.entries,
    nextProcessState = nextAssemblyBasementProcessState,
    editableStatus = project.isProjectEditable(),
  )
}

external interface AssemblyBasementOverviewProps : Props {
  var project: ResolvedProject
}

private val EditAssemblyBasementRestarbeiten: FC<EditAssemblyBasementRestarbeitenProps> = fc("EditAssemblyBasementRestarbeiten") { props ->
  val project = props::project.safeGet()
  val showOnlyRelevant = props::showOnlyRelevant.safeGet()
  val assemblyBasement = project.assemblyBasement

  val restArbeiten = assemblyBasement.restArbeiten
  val heizstab = useState(restArbeiten.heizstab)
  val waermepumpe = useState(restArbeiten.waermepumpe)
  val batterie = useState(restArbeiten.batterie)
  val wallbox = useState(restArbeiten.wallbox)
  val internetanbindung = useState(restArbeiten.internetanbindung)
  val sonstiges = useState(restArbeiten.sonstiges)

  val restArbeitenToSave = useMemo(
    heizstab.value,
    waermepumpe.value,
    batterie.value,
    wallbox.value,
    internetanbindung.value,
    sonstiges.value,
  ) {
    RestArbeiten(
      heizstab = heizstab.value,
      waermepumpe = waermepumpe.value,
      batterie = batterie.value,
      wallbox = wallbox.value,
      internetanbindung = internetanbindung.value,
      sonstiges = sonstiges.value,
    )
  }


  useMemo(restArbeitenToSave) {
    val assemblyBasementToSave = assemblyBasement.copy(restArbeiten = restArbeitenToSave)
    UiActions.saveAssemblyBasement(assemblyBasementToSave)
  }


  if (showOnlyRelevant == false || restArbeitenToSave != RestArbeiten.empty) {
    div("my-4") {
      h4("my-3") { +"Restarbeiten" }

      if (showOnlyRelevant == false || heizstab.value) {
        div {
        checkbox(
          valueAndSetter = heizstab,
          fieldName = "heizstab",
          title = "Heizstab fehlt",
        )
        }
      }
      if (showOnlyRelevant == false || waermepumpe.value) {
        div {
        checkbox(
          valueAndSetter = waermepumpe,
          fieldName = "waermepumpe",
          title = "Wärmepumpe fehlt",
        )
        }
      }
      if (showOnlyRelevant == false || batterie.value) {
        div {
        checkbox(
          valueAndSetter = batterie,
          fieldName = "batterie",
          title = "Batterie fehlt",
        )
        }
      }
      if (showOnlyRelevant == false || wallbox.value) {
        div {
        checkbox(
          valueAndSetter = wallbox,
          fieldName = "wallbox",
          title = "Wallbox fehlt",
        )
        }
      }
      if (showOnlyRelevant == false || internetanbindung.value) {
        div {
        checkbox(
          valueAndSetter = internetanbindung,
          fieldName = "internetanbindung",
          title = "Internetanbindung fehlt",
        )
        }
      }
      if (showOnlyRelevant == false || sonstiges.value.isNotBlank()) {
        div("my-3") {
          inputArea(
            valueAndSetter = sonstiges,
            fieldName = "sonstiges",
            title = "Sonstiges",
          )
        }
      }
    }
  }
}

external interface EditAssemblyBasementRestarbeitenProps : Props {
  var project: ResolvedProject
  var showOnlyRelevant: Boolean?
}


val AssemblyRoofProjectStumpOverviewFromUrl: FC<Props> = fc("AssemblyRoofProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Montage Dach - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    AssemblyRoofProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val AssemblyRoofProjectStumpOverview: FC<AssemblyRoofProjectStumpOverviewProps> = fc("AssemblyRoofProjectStumpOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val project = props::project.safeGet()
  val assemblyRoof = project.assemblyRoof
  val assemblyBasement = project.assemblyBasement
  val switchMeterBox = project.switchMeterBox
  val startupOperations = project.startupOperations

  val assemblyRoofProcessStateEntry = useLoadProcessStatesForComponent(assemblyRoof.uuid)?.current()
  val assemblyBasementProcessState = useLoadProcessStatesForComponent(assemblyBasement.uuid)?.current()?.processState
  val switchMeterBoxProcessState = useLoadProcessStatesForComponent(switchMeterBox.uuid)?.current()?.processState
  val startupOperationsProcessState = useLoadProcessStatesForComponent(startupOperations.uuid)?.current()?.processState


  useMemo(assemblyBasementProcessState, assemblyRoofProcessStateEntry?.processState) {
    val automaticProcessStates = buildList {
      if (assemblyBasementProcessState == AssemblyBasementProcessStates.Done && assemblyRoofProcessStateEntry?.processState == AssemblyRoofProcessStates.Done) {

        if (switchMeterBoxProcessState == null) {
          add(SendProcessStatesTuple(switchMeterBox.uuid, SwitchMeterBoxProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

        if (startupOperationsProcessState == null) {
          add(SendProcessStatesTuple(startupOperations.uuid, StartupOperationsProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

      }
    }

    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }


  div("row my-5") {
    val advanceInvoice = project.advanceInvoice
    val advanceInvoiceProcessState = useLoadProcessStatesForComponent(advanceInvoice.uuid)?.current()?.processState

    if (advanceInvoiceProcessState != AdvanceInvoiceProcessStates.Paid) {
      p("text-warning mb-3") {
        span("pe-2") { faCircleExclamation() }
        span { +"Anzahlungsrechnung wurde noch nicht gezahlt!" }
      }
    }

    div("col-md-6") {
      AssemblyRoofOverview {
        attrs {
          this.project = project
        }
      }
      processStatesList(assemblyRoof)

      if (assemblyBasementProcessState == AssemblyBasementProcessStates.Done && assemblyRoofProcessStateEntry?.processState == AssemblyRoofProcessStates.Done) {
        if (switchMeterBoxProcessState == null || switchMeterBoxProcessState == SwitchMeterBoxProcessStates.Missing) {
          editProcessStateEntry(processStateFor = switchMeterBox, availableProcessStates = listOf(SwitchMeterBoxProcessStates.Missing), editableStatus = project.isProjectEditable())
          processStatesList(switchMeterBox)
        }

        if (startupOperationsProcessState == null || startupOperationsProcessState == StartupOperationsProcessStates.Missing) {
          editProcessStateEntry(processStateFor = startupOperations, availableProcessStates = listOf(StartupOperationsProcessStates.Missing), editableStatus = project.isProjectEditable())
          processStatesList(startupOperations)
        }
      }
    }
    div("col-md-6") { commentSection(assemblyRoof) }
  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface AssemblyRoofProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}

private val AssemblyRoofOverview: FC<AssemblyRoofOverviewProps> = fc("AssemblyRoofOverview") { props ->
  val project = props::project.safeGet()
  val assemblyRoof = project.assemblyRoof

  val assemblyRoofProcessStateEntry = useLoadProcessStatesForComponent(assemblyRoof.uuid)?.current()

  val nextAssemblyRoofProcessState = if (
    assemblyRoofProcessStateEntry?.processState == AssemblyRoofPreparationProcessStates.Done &&
    assemblyRoofProcessStateEntry is AssemblyRoofPreparationProcessStateEntry && assemblyRoofProcessStateEntry.dueDate != null
  ) AssemblyRoofProcessStates.InProgress else null

  editProcessStateEntry(
    processStateFor = assemblyRoof,
    availableProcessStates = assemblyRoofProcessStateEntry?.availableOptions ?: AssemblyRoofPreparationProcessStates.entries,
    nextProcessState = nextAssemblyRoofProcessState,
    editableStatus = project.isProjectEditable(),
  )
}

external interface AssemblyRoofOverviewProps : Props {
  var project: ResolvedProject
}


val DocumentationProjectStumpOverviewFromUrl: FC<Props> = fc("DocumentationProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Dokumentation - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    DocumentationProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val DocumentationProjectStumpOverview: FC<DocumentationProjectStumpOverviewProps> = fc("DocumentationProjectStumpOverview") { props ->
  val project = props::project.safeGet()
  val documentation = project.documentation

  div("row my-5") {
    div("col-md-6") {
      editProcessStateEntry(documentation, DocumentationProcessStates.entries, editableStatus = project.isProjectEditable())
      processStatesList(documentation)
    }
    div("col-md-6") { commentSection(documentation) }
  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface DocumentationProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}


val FinalAccountProjectStumpOverviewFromUrl: FC<Props> = fc("FinalAccountProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Abschlussrechnung - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    FinalAccountProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val FinalAccountProjectStumpOverview: FC<FinalAccountProjectStumpOverviewProps> = fc("FinalAccountProjectStumpOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val project = props::project.safeGet()
  val finalAccount = project.finalAccount
  val documentation = project.documentation

  val finalAccountProcessState = useLoadProcessStatesForComponent(finalAccount.uuid)?.current()?.processState
  val documentationProcessState = useLoadProcessStatesForComponent(documentation.uuid)?.current()?.processState


  useMemo(finalAccountProcessState) {
    val automaticProcessStates = buildList {
      if (finalAccountProcessState == FinalAccountProcessStates.Paid) {

        if (documentationProcessState == null) {
          add(SendProcessStatesTuple(documentation.uuid, DocumentationProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

      }
    }

    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }


  div("row my-5") {
    div("col-md-6") {
      editProcessStateEntry(processStateFor = finalAccount, availableProcessStates = FinalAccountProcessStates.entries, editableStatus = project.isProjectEditable())
      processStatesList(finalAccount)

      if (
        finalAccountProcessState == FinalAccountProcessStates.Paid &&
        (documentationProcessState == null || documentationProcessState == DocumentationProcessStates.Missing)
      ) {
        editProcessStateEntry(processStateFor = documentation, availableProcessStates = listOf(DocumentationProcessStates.Missing), editableStatus = project.isProjectEditable())
        processStatesList(documentation)
      }
    }
    div("col-md-6") { commentSection(finalAccount) }
  }

  EditAssemblyBasementRestarbeiten {
    attrs {
      this.project = project
      this.showOnlyRelevant = true
    }
  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface FinalAccountProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}


val FinishingUpProjectStumpOverviewFromUrl: FC<Props> = fc("FinishingUpProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Fertigmeldung - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    FinishingUpProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val FinishingUpProjectStumpOverview: FC<FinishingUpProjectStumpOverviewProps> = fc("FinishingUpProjectStumpOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val project = props::project.safeGet()
  val finishingUp = project.finishingUp
  val finalAccount = project.finalAccount

  val finishingUpProcessState = useLoadProcessStatesForComponent(finishingUp.uuid)?.current()?.processState
  val finalAccountProcessState = useLoadProcessStatesForComponent(finalAccount.uuid)?.current()?.processState


  useMemo(finishingUpProcessState) {
    val automaticProcessStates = buildList {
      if (finishingUpProcessState == FinishingUpProcessStates.Done) {

        if (finalAccountProcessState == null) {
          add(SendProcessStatesTuple(finalAccount.uuid, FinalAccountProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

      }
    }

    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }


  div("row my-5") {
    div("col-md-6") {
      editProcessStateEntry(processStateFor = finishingUp, availableProcessStates = FinishingUpProcessStates.entries, editableStatus = project.isProjectEditable())
      processStatesList(finishingUp)

      if (
        finishingUpProcessState == FinishingUpProcessStates.Done &&
        (finalAccountProcessState == null || finalAccountProcessState == FinalAccountProcessStates.Missing)
      ) {
        editProcessStateEntry(processStateFor = finalAccount, availableProcessStates = listOf(FinalAccountProcessStates.Missing), editableStatus = project.isProjectEditable())
        processStatesList(finalAccount)
      }
    }
    div("col-md-6") { commentSection(finishingUp) }
  }

  EditAssemblyBasementRestarbeiten {
    attrs {
      this.project = project
      this.showOnlyRelevant = true
    }
  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface FinishingUpProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}


val OrderSpecialMaterialProjectStumpOverviewFromUrl: FC<Props> = fc("OrderSpecialMaterialProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Sondermaterial Bestellung - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    OrderSpecialMaterialProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val OrderSpecialMaterialProjectStumpOverview: FC<OrderSpecialMaterialProjectStumpOverviewProps> = fc("OrderSpecialMaterialProjectStumpOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val project = props::project.safeGet()
  val orderSpecialMaterial = project.orderSpecialMaterial
  val gridAssessment = project.gridAssessment
  val assemblyPortfolio = project.assemblyPortfolio

  val gridAssessmentProcessState = useLoadProcessStatesForComponent(gridAssessment.uuid)?.current()?.processState
  val orderSpecialMaterialProcessState = useLoadProcessStatesForComponent(orderSpecialMaterial.uuid)?.current()?.processState
  val assemblyPortfolioProcessState = useLoadProcessStatesForComponent(assemblyPortfolio.uuid)?.current()?.processState


  useMemo(gridAssessmentProcessState, orderSpecialMaterialProcessState) {
    val automaticProcessStates = buildList {
      if (gridAssessmentProcessState == GridAssessmentProcessStates.Accepted && orderSpecialMaterialProcessState == OrderSpecialMaterialProcessStates.Done) {

        if (assemblyPortfolioProcessState == null) {
          add(SendProcessStatesTuple(assemblyPortfolio.uuid, AssemblyPortfolioProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

      }
    }

    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }


  div("row my-5") {
    div("col-md-6") {
      editProcessStateEntry(orderSpecialMaterial, OrderSpecialMaterialProcessStates.entries, editableStatus = project.isProjectEditable())
      processStatesList(orderSpecialMaterial)

      if (
        gridAssessmentProcessState == GridAssessmentProcessStates.Accepted && orderSpecialMaterialProcessState == OrderSpecialMaterialProcessStates.Done &&
        (assemblyPortfolioProcessState == null || assemblyPortfolioProcessState == AssemblyPortfolioProcessStates.Missing)
      ) {
        editProcessStateEntry(processStateFor = assemblyPortfolio, availableProcessStates = listOf(AssemblyPortfolioProcessStates.Missing), editableStatus = project.isProjectEditable())
        processStatesList(assemblyPortfolio)
      }
    }
    div("col-md-6") { commentSection(orderSpecialMaterial) }
  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface OrderSpecialMaterialProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}


val PrepareAssemblyProjectStumpOverviewFromUrl: FC<Props> = fc("PrepareAssemblyProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Montage Vorbereitung - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    PrepareAssemblyProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val PrepareAssemblyProjectStumpOverview: FC<PrepareAssemblyProjectStumpOverviewProps> = fc("PrepareAssemblyProjectStumpOverview") { props ->
  val project = props::project.safeGet()
  val assemblyRoof = project.assemblyRoof
  val assemblyBasement = project.assemblyBasement

  val assemblyRoofProcessState = useLoadProcessStatesForComponent(assemblyRoof.uuid)?.current()
  val assemblyBasementProcessState = useLoadProcessStatesForComponent(assemblyBasement.uuid)?.current()


  div("row my-5") {
    val advanceInvoice = project.advanceInvoice
    val advanceInvoiceProcessState = useLoadProcessStatesForComponent(advanceInvoice.uuid)?.current()?.processState

    if (advanceInvoiceProcessState != AdvanceInvoiceProcessStates.Paid) {
      p("text-warning mb-3") {
        span("pe-2") { faCircleExclamation() }
        span { +"Anzahlungsrechnung wurde noch nicht gezahlt!" }
      }
    }

    div("col-md-6") {
      if (assemblyRoofProcessState?.processState == null || assemblyRoofProcessState.processState is AssemblyRoofPreparationProcessStates) {
        AssemblyRoofOverview {
          attrs {
            this.project = project
          }
        }
      }
      processStatesList(assemblyRoof)
    }
    div("col-md-6") { commentSection(assemblyRoof) }

    div("col-md-6") {
      if (assemblyBasementProcessState?.processState == null || assemblyBasementProcessState.processState is AssemblyBasementPreparationProcessStates) {
        AssemblyBasementOverview {
          attrs {
            this.project = project
          }
        }
      }
      processStatesList(assemblyBasement)
    }
    div("col-md-6") { commentSection(assemblyBasement) }

  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface PrepareAssemblyProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}


val ScheduleAssemblyProjectStumpOverviewFromUrl: FC<Props> = fc("ScheduleAssemblyProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Montagetermin einplanen - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    ScheduleAssemblyProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val ScheduleAssemblyProjectStumpOverview: FC<ScheduleAssemblyProjectStumpOverviewProps> = fc("ScheduleAssemblyProjectStumpOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val project = props::project.safeGet()
  val assemblyBasement = project.assemblyBasement
  val assemblyRoof = project.assemblyRoof
  val advanceInvoice = project.advanceInvoice

  val assemblyBasementProcessState = useLoadProcessStatesForComponent(assemblyBasement.uuid)?.current()
  val assemblyRoofProcessState = useLoadProcessStatesForComponent(assemblyRoof.uuid)?.current()
  val advanceInvoiceProcessState = useLoadProcessStatesForComponent(advanceInvoice.uuid)?.current()?.processState


  useMemo(assemblyRoofProcessState) {
    val automaticProcessStates = buildList {
      if (assemblyRoofProcessState?.processState == AssemblyRoofPreparationProcessStates.Missing && assemblyRoofProcessState is HasDueDate && assemblyRoofProcessState.dueDate != null) {

        if (advanceInvoiceProcessState == null) {
          add(SendProcessStatesTuple(advanceInvoice.uuid, AdvanceInvoiceProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

      }
    }

    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }


  div("row my-5") {
    val advanceInvoice = project.advanceInvoice
    val advanceInvoiceProcessState = useLoadProcessStatesForComponent(advanceInvoice.uuid)?.current()?.processState

    if (advanceInvoiceProcessState != AdvanceInvoiceProcessStates.Paid) {
      p("text-warning mb-3") {
        span("pe-2") { faCircleExclamation() }
        span { +"Anzahlungsrechnung wurde noch nicht gezahlt!" }
      }
    }

    div("col-md-6") {
      if (assemblyRoofProcessState?.processState == null || assemblyRoofProcessState.processState == AssemblyRoofPreparationProcessStates.Missing) {
        editProcessStateEntry(processStateFor = assemblyRoof, availableProcessStates = listOf(AssemblyRoofPreparationProcessStates.Missing), editableStatus = project.isProjectEditable())
      }
      processStatesList(assemblyRoof)
    }
    div("col-md-6") { commentSection(assemblyRoof) }

    div("col-md-6") {
      if (assemblyBasementProcessState?.processState == null || assemblyBasementProcessState.processState == AssemblyBasementPreparationProcessStates.Missing) {
        editProcessStateEntry(processStateFor = assemblyBasement, availableProcessStates = listOf(AssemblyBasementPreparationProcessStates.Missing), editableStatus = project.isProjectEditable())
      }
      processStatesList(assemblyBasement)
    }
    div("col-md-6") { commentSection(assemblyBasement) }

    div("col-md-6") {
      if (
        assemblyRoofProcessState?.processState == AssemblyRoofPreparationProcessStates.Missing && assemblyRoofProcessState is HasDueDate && assemblyRoofProcessState.dueDate != null &&
        (advanceInvoiceProcessState == null || advanceInvoiceProcessState == AdvanceInvoiceProcessStates.Missing)
      ) {
        editProcessStateEntry(processStateFor = advanceInvoice, availableProcessStates = listOf(AdvanceInvoiceProcessStates.Missing), editableStatus = project.isProjectEditable())
        processStatesList(advanceInvoice)
      }
    }

  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface ScheduleAssemblyProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}


val StartupOperationsProjectStumpOverviewFromUrl: FC<Props> = fc("StartupOperationsProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Inbetriebnahme und Kundeneinweisung - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    StartupOperationsProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val StartupOperationsProjectStumpOverview: FC<StartupOperationsProjectStumpOverviewProps> = fc("StartupOperationsProjectStumpOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val project = props::project.safeGet()
  val startupOperations = project.startupOperations
  val switchMeterBox = project.switchMeterBox
  val finishingUp = project.finishingUp

  val startupOperationsProcessState = useLoadProcessStatesForComponent(startupOperations.uuid)?.current()?.processState
  val switchMeterBoxProcessState = useLoadProcessStatesForComponent(switchMeterBox.uuid)?.current()?.processState
  val finishingUpProcessState = useLoadProcessStatesForComponent(finishingUp.uuid)?.current()?.processState


  useMemo(startupOperationsProcessState, switchMeterBoxProcessState) {
    val automaticProcessStates = buildList {
      if (startupOperationsProcessState == StartupOperationsProcessStates.Done && switchMeterBoxProcessState == SwitchMeterBoxProcessStates.Done) {

        if (finishingUpProcessState == null) {
          add(SendProcessStatesTuple(finishingUp.uuid, FinishingUpProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

      }
    }

    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }


  div("row my-5") {
    div("col-md-6") {
      editProcessStateEntry(processStateFor = startupOperations, availableProcessStates = StartupOperationsProcessStates.entries, editableStatus = project.isProjectEditable())
      processStatesList(startupOperations)

      if (
        startupOperationsProcessState == StartupOperationsProcessStates.Done && switchMeterBoxProcessState == SwitchMeterBoxProcessStates.Done &&
        (finishingUpProcessState == null || finishingUpProcessState == FinishingUpProcessStates.Missing)
      ) {
        editProcessStateEntry(processStateFor = finishingUp, availableProcessStates = listOf(FinishingUpProcessStates.Missing), editableStatus = project.isProjectEditable())
        processStatesList(finishingUp)
      }
    }
    div("col-md-6") { commentSection(startupOperations) }
  }

  EditAssemblyBasementRestarbeiten {
    attrs {
      this.project = project
      this.showOnlyRelevant = true
    }
  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface StartupOperationsProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}


val SwitchMeterBoxProjectStumpOverviewFromUrl: FC<Props> = fc("SwitchMeterBoxProjectStumpOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Zählertausch - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    SwitchMeterBoxProjectStumpOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val SwitchMeterBoxProjectStumpOverview: FC<SwitchMeterBoxProjectStumpOverviewProps> = fc("SwitchMeterBoxProjectStumpOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val project = props::project.safeGet()
  val switchMeterBox = project.switchMeterBox
  val startupOperations = project.startupOperations
  val finishingUp = project.finishingUp

  val switchMeterBoxProcessState = useLoadProcessStatesForComponent(switchMeterBox.uuid)?.current()?.processState
  val startupOperationsProcessState = useLoadProcessStatesForComponent(startupOperations.uuid)?.current()?.processState
  val finishingUpProcessState = useLoadProcessStatesForComponent(finishingUp.uuid)?.current()?.processState


  useMemo(switchMeterBoxProcessState, startupOperationsProcessState) {
    val automaticProcessStates = buildList {
      if (switchMeterBoxProcessState == SwitchMeterBoxProcessStates.Done && startupOperationsProcessState == StartupOperationsProcessStates.Done) {

        if (finishingUpProcessState == null) {
          add(SendProcessStatesTuple(finishingUp.uuid, FinishingUpProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

      }
    }

    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }


  div("row my-5") {
    div("col-md-6") {
      editProcessStateEntry(switchMeterBox, SwitchMeterBoxProcessStates.entries, editableStatus = project.isProjectEditable())
      processStatesList(switchMeterBox)

      if (
        switchMeterBoxProcessState == SwitchMeterBoxProcessStates.Done && startupOperationsProcessState == StartupOperationsProcessStates.Done &&
        (finishingUpProcessState == null || finishingUpProcessState == FinishingUpProcessStates.Missing)
      ) {
        editProcessStateEntry(processStateFor = finishingUp, availableProcessStates = listOf(FinishingUpProcessStates.Missing), editableStatus = project.isProjectEditable())
        processStatesList(finishingUp)
      }
    }
    div("col-md-6") { commentSection(switchMeterBox) }
  }

  EditAssemblyBasementRestarbeiten {
    attrs {
      this.project = project
      this.showOnlyRelevant = true
    }
  }

  val processStatesResolver = useLoadProcessStates()
  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface SwitchMeterBoxProjectStumpOverviewProps : Props {
  var project: ResolvedProject
}
