package it.neckar.react.common.portal

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import react.*


/**
 * Creates a portal to the modal container.
 */
fun RBuilder.createPortal(
  portalContainerId: String,
  portalContent: RElementBuilder<*>.() -> Unit,
) {
  child(createPortal) {
    attrs {
      this.portalContainerId = portalContainerId
    }

    portalContent(this)
  }
}

/**
 * Creates a portal to a container in the DOM.
 */
val createPortal: FC<CreatePortalProps> = fc("createPortal") { props ->
  val portalContainerId = props::portalContainerId.safeGet()

  //The container
  val portalContainer: web.dom.Element = web.dom.document.getElementById(portalContainerId) ?: throw IllegalStateException("No container with ID '$portalContainerId' found")

  react.dom.createPortal(createElement<ModalProps> {
    props.children()
  }, container = portalContainer).also { child(it) }
}

external interface CreatePortalProps : PropsWithChildren {
  /**
   * The ID of the portal container. This element must exist in the DOM.
   * This is usually a div with a well known ID (e.g. 'modalsContainer')
   */
  var portalContainerId: String
}


/**
 * Creates a portal to the modal container.
 */
fun RBuilder.createPortalForModal(
  portalContent: RElementBuilder<*>.() -> Unit,
) {
  createPortal(WellKnownIds.ModalsContainerId, portalContent)
}
