package it.neckar.lizergy.model.project.process.state

import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import it.neckar.processStates.ProcessStateEntryId
import it.neckar.processStates.ProcessStatePhase
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("QuoteOfferProcessStateEntry")
data class QuoteOfferProcessStateEntry(
  override val id: ProcessStateEntryId,
  override val processState: QuoteOfferProcessStates,
  override val assignedTo: UserLoginName,
  override val belongsTo: CompanyCode,
  override val assignedAt: @ms @Serializable(with = DoubleAsIsoDateTimeSerializer::class) Double,
  override val assignedBy: UserLoginName,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : LizergyProcessStateEntry {

  override val availableOptions: List<QuoteOfferProcessStates>
    get() = QuoteOfferProcessStates.entries

  override fun format(i18nConfiguration: I18nConfiguration): String {
    return processState.format(i18nConfiguration)
  }

  @Serializable
  @SerialName("QuoteOfferProcessStates")
  enum class QuoteOfferProcessStates : LizergyProcessStates {
    Sent,
    Accepted,
    Rejected,

    ;

    override val inPhase: ProcessStatePhase
      get() = when (this) {
        Sent -> ProcessStatePhase.Waiting
        Accepted -> ProcessStatePhase.Done
        Rejected -> ProcessStatePhase.Rejected
      }

    override fun format(i18nConfiguration: I18nConfiguration): String {
      return when (this) {
        Sent -> "AN versendet"
        Accepted -> "AN angenommen"
        Rejected -> "AN abgelehnt"
      }
    }

    override fun formatDate(date: Double, i18nConfiguration: I18nConfiguration): String {
      return "am ${dateFormat.format(date, i18nConfiguration)}"
    }
  }
}
