package router

import MainStage
import components.CreateSampleProjectsPage
import components.EditFeatureFlags
import components.accounting.AccountingOverview
import components.accounting.AccountingOverview2
import components.accounting.AccountingOverview3
import components.assemblyPortfolio.AssemblyPortfolioOverviewFromUrl
import components.company.AddNewCompanyForm
import components.company.CompaniesAndUsersOverviewFromUrl
import components.company.EditCompanyFromUrl
import components.company.pricelist.PriceListFromUrl
import components.company.pricelist.tables.BatteryConfigurationPriceTable
import components.company.pricelist.tables.FacilityAndAssemblyPriceTable
import components.company.pricelist.tables.IndependenceManagerPriceTable
import components.company.pricelist.tables.InverterPriceTable
import components.company.pricelist.tables.MetalRoofTilesPriceTable
import components.company.pricelist.tables.MiscellaneousPriceTable
import components.company.pricelist.tables.ModulesPriceTable
import components.company.pricelist.tables.MonitoringPriceTable
import components.company.pricelist.tables.RoofIsolationPriceTable
import components.company.pricelist.tables.RoofTypePriceTable
import components.company.pricelist.tables.WallBoxesPriceTable
import components.company.user.AddNewUserForCompanyFromUrl
import components.company.user.UserOverviewFromUrl
import components.diagnosis.DiagnosisOverview
import components.gridAssessment.GridAssessmentOverviewFromUrl
import components.project.AdvanceInvoiceProjectStumpOverviewFromUrl
import components.project.AssemblyBasementProjectStumpOverviewFromUrl
import components.project.AssemblyRoofProjectStumpOverviewFromUrl
import components.project.DocumentationProjectStumpOverviewFromUrl
import components.project.EditProjectDetailsFromUrl
import components.project.FinalAccountProjectStumpOverviewFromUrl
import components.project.FinishingUpProjectStumpOverviewFromUrl
import components.project.HomepageFromUrl
import components.project.OrderSpecialMaterialProjectStumpOverviewFromUrl
import components.project.PrepareAssemblyProjectStumpOverviewFromUrl
import components.project.ProjectOverviewFromUrl
import components.project.ProjectsOverviewFromUrl
import components.project.ScheduleAssemblyProjectStumpOverviewFromUrl
import components.project.StartupOperationsProjectStumpOverviewFromUrl
import components.project.SwitchMeterBoxProjectStumpOverviewFromUrl
import components.project.VerificationsOverviewFromUrl
import components.project.blueprint.BlueprintAdditionalOverviewFromUrl
import components.project.blueprint.BlueprintCustomerFromUrl
import components.project.blueprint.BlueprintOverviewFromUrl
import components.project.blueprint.EditBlueprintElectricityWorkEffortFromUrl
import components.project.blueprint.EditBlueprintFacilityFromUrl
import components.project.blueprint.EditBlueprintFinishFromUrl
import components.project.blueprint.EditBlueprintProfitabilityFromUrl
import components.project.blueprint.EditBlueprintRoofFromUrl
import components.project.blueprint.EditBlueprintWallboxFromUrl
import components.project.configuration.ConfigurationOverviewFromUrl
import components.project.configuration.ConfigurationsOverviewFromUrl
import components.project.configuration.additional.ConfigurationAdditionalOverviewFromUrl
import components.project.configuration.additional.EditBlueprintAdditionalPositionFromUrl
import components.project.configuration.additional.EditBlueprintExistingBHKWFacilityFromUrl
import components.project.configuration.additional.EditBlueprintExistingPVFacilityFromUrl
import components.project.configuration.additional.EditConfigurationAdditionalPositionFromUrl
import components.project.configuration.additional.EditConfigurationElectricityWorkEffortFromUrl
import components.project.configuration.additional.EditConfigurationExistingBHKWFacilityFromUrl
import components.project.configuration.additional.EditConfigurationExistingPVFacilityFromUrl
import components.project.configuration.description.EditConfigurationDescriptionFromUrl
import components.project.configuration.facility.EditConfigurationFacilityFromUrl
import components.project.configuration.layout.BlueprintRoofsOverviewFromUrl
import components.project.configuration.layout.ConfigurationModuleLayoutsOverviewFromUrl
import components.project.configuration.layout.EditConfigurationModuleLayoutFromUrl
import components.project.configuration.layout.EditModuleStringsFromUrl
import components.project.configuration.layout.ModuleStringsFromUrl
import components.project.configuration.location.EditConfigurationLocationFromUrl
import components.project.configuration.powerusage.EditConfigurationPowerUsageFromUrl
import components.project.configuration.powerusage.EditInquiryInformationFromUrl
import components.project.configuration.tabs.AnnotationsOverviewFromUrl
import components.project.configuration.tabs.ConfigurationEarningsOverviewFromUrl
import components.project.configuration.tabs.EconomicsOverviewFromUrl
import components.project.configuration.tabs.EditConfigurationProcessStateFromUrl
import components.project.configuration.tabs.ProfitOverviewFromUrl
import components.project.configuration.tabs.QuotePositionsOverviewFromUrl
import components.project.configuration.wallbox.EditConfigurationWallboxFromUrl
import components.project.customer.CreateProject
import it.neckar.events.KeyCode
import it.neckar.events.KeyStroke
import it.neckar.events.ModifierCombination
import it.neckar.react.common.*
import it.neckar.react.common.router.*
import react.*

val Navigation: NavigationRoot = buildNavigation {
  route("") {
    element = { MainStage.create() }

    keyStroke = KeyStroke(KeyCode('H'), ModifierCombination.CtrlShiftAlt)
    keyStroke = KeyStroke(KeyCode.Home)

    index { element = { HomepageFromUrl.create() } }
    breadcrumb {
      icon = FontAwesomeIcons.home
    }

    // Overview over *all* projects
    route(PathConstants.OverviewTablePathConstants.projects) {
      index { element = { ProjectsOverviewFromUrl.create() } }
      breadcrumb {
        label = "Projekte"
      }

      overviewTableRouting()

      route(PathConstants.new) {
        element = { CreateProject.create() }
        keyStroke = KeyStroke(KeyCode('N'), ModifierCombination.CtrlShiftAlt)
        keyStroke = KeyStroke(KeyCode.Digit1, ModifierCombination.Ctrl)
        breadcrumb {
          label = "Neues Projekt"
        }
      }

      route(RouterVars.projectId) {
        projectRouting()
      }

    }

    route(PathConstants.priceList) {
      priceListRouting()
    }

    route(PathConstants.featureFlags) {
      element = { EditFeatureFlags.create() }
      breadcrumb {
        label = "Experimente"
      }
    }

    route(PathConstants.createSampleProjects) {
      element = { CreateSampleProjectsPage.create() }
      breadcrumb {
        label = "Beispielpojekte"
      }
    }

    route(PathConstants.admin) {
      breadcrumb {
        label = "Administrator"
      }

      adminRouting()
    }

    route(PathConstants.diagnosis) {
      element = { DiagnosisOverview.create() }
      keyStroke = KeyStroke(KeyCode('D'), ModifierCombination.CtrlShiftAlt)
      breadcrumb {
        label = "Diagnose"
      }
    }
  }
}

internal fun NavigationElement.Builder.priceListRouting() {
  element = { PriceListFromUrl.create() }

  index { element = { ModulesPriceTable.create() } }
  breadcrumb {
    label = "Preisliste"
  }

  route("modules") {
    element = { ModulesPriceTable.create() }
    breadcrumb {
      label = "Module"
    }
  }

  route("inverters") {
    element = { InverterPriceTable.create() }
    breadcrumb {
      label = "Wechselrichter"
    }
  }

  route("batteries") {
    element = { BatteryConfigurationPriceTable.create() }
    breadcrumb {
      label = "Batteriespeicher-Systeme"
    }
  }

  route("roofTypes") {
    element = { RoofTypePriceTable.create() }
    breadcrumb {
      label = "Dachtypen"
    }
  }

  route("roofIsolation") {
    element = { RoofIsolationPriceTable.create() }
    breadcrumb {
      label = "Aufdachdämmung"
    }
  }

  route("metalRoofTiles") {
    element = { MetalRoofTilesPriceTable.create() }
    breadcrumb {
      label = "Metalldachziegel"
    }
  }

  route("facilityAndAssembly") {
    element = { FacilityAndAssemblyPriceTable.create() }
    breadcrumb {
      label = "Anlage & Montage"
    }
  }

  route("independenceManagers") {
    element = { IndependenceManagerPriceTable.create() }
    breadcrumb {
      label = "Ersatzstromsysteme"
    }
  }

  route("wallBoxes") {
    element = { WallBoxesPriceTable.create() }
    breadcrumb {
      label = "Wallbox"
    }
  }

  route("monitoring") {
    element = { MonitoringPriceTable.create() }
    breadcrumb {
      label = "Monitoring"
    }
  }

  route("miscellaneous") {
    element = { MiscellaneousPriceTable.create() }
    breadcrumb {
      label = "Sonstiges"
    }
  }
}

internal fun NavigationElement.Builder.projectRouting() {
  element = { ProjectOverviewFromUrl.create() }
  breadcrumb {
    linkContent = { ProjectNameFromUrl {} }
  }

  index {
    element = { EditProjectDetailsFromUrl.create() }
    breadcrumb {
      label = "Projektdetails"
    }
  }

  route(PathConstants.blueprint) {
    blueprintRouting()
  }

  route(PathConstants.configurations) {
    index { element = { ConfigurationsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Angebote"
    }

    route(RouterVars.configurationId) {
      configurationRouting()
    }
  }

  route(PathConstants.orderSpecialMaterial) {
    element = { OrderSpecialMaterialProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Sondermaterial Bestellung"
    }
  }

  route(PathConstants.gridAssessment) {
    element = { GridAssessmentOverviewFromUrl.create() }
    breadcrumb {
      label = "Netzvoranfrage"
    }
  }

  route(PathConstants.assemblyPortfolio) {
    element = { AssemblyPortfolioOverviewFromUrl.create() }
    breadcrumb {
      label = "Montagemappe erstellen"
    }
  }

  route(PathConstants.scheduleAssembly) {
    element = { ScheduleAssemblyProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Montagetermin einplanen"
    }
  }

  route(PathConstants.advanceInvoice) {
    element = { AdvanceInvoiceProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Anzahlungsrechnung"
    }
  }

  route(PathConstants.prepareAssembly) {
    element = { PrepareAssemblyProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Montage Vorbereitung"
    }
  }

  route(PathConstants.assemblyRoof) {
    element = { AssemblyRoofProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Montage Dach"
    }
  }

  route(PathConstants.assemblyBasement) {
    element = { AssemblyBasementProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Montage Keller"
    }
  }

  route(PathConstants.switchMeterBox) {
    element = { SwitchMeterBoxProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Zählertausch"
    }
  }

  route(PathConstants.startupOperations) {
    element = { StartupOperationsProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Inbetriebnahme und Kundeneinweisung"
    }
  }

  route(PathConstants.finishingUp) {
    element = { FinishingUpProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Fertigmeldung"
    }
  }

  route(PathConstants.finalAccount) {
    element = { FinalAccountProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Abschlussrechnung"
    }
  }

  route(PathConstants.documentation) {
    element = { DocumentationProjectStumpOverviewFromUrl.create() }
    breadcrumb {
      label = "Dokumentation"
    }
  }

  route(PathConstants.verification) {
    element = { VerificationsOverviewFromUrl.create() }
    breadcrumb {
      label = "Überprüfung"
    }
  }
}


internal fun NavigationElement.Builder.blueprintRouting() {
  element = { BlueprintOverviewFromUrl.create() }
  breadcrumb {
    label = "Projekterfassung"
  }

  index {
    element = { BlueprintCustomerFromUrl.create() }
    breadcrumb {
      label = "Kunde und Standort"
    }
  }

  route(PathConstants.moduleLayouts) {
    index { element = { BlueprintRoofsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Dachflächen"
    }

    route(RouterVars.moduleLayoutId) {
      element = { EditBlueprintRoofFromUrl.create() }
      breadcrumb {
        linkContent = { BlueprintRoofLabelFromUrl {} }
      }
    }
  }

  route(PathConstants.profitability) {
    element = { EditBlueprintProfitabilityFromUrl.create() }
    breadcrumb {
      label = "Wirtschaftlichkeitsberechnung"
    }
  }

  route(PathConstants.facility) {
    element = { EditBlueprintFacilityFromUrl.create() }
    breadcrumb {
      label = "Anlage & Montage"
    }
  }

  route(PathConstants.electricityWorkEffort) {
    element = { EditBlueprintElectricityWorkEffortFromUrl.create() }
    breadcrumb {
      label = "Zählerschrankarbeiten"
    }
  }

  route(PathConstants.wallbox) {
    element = { EditBlueprintWallboxFromUrl.create() }
    breadcrumb {
      label = "Zählerschrankarbeiten"
    }
  }

  route(PathConstants.additional) {
    index { element = { BlueprintAdditionalOverviewFromUrl.create() } }
    breadcrumb {
      label = "Zusätzliches"
    }

    route(PathConstants.additionalPositions + "/" + RouterVars.additionalPositionId.toPathString()) {
      element = { EditBlueprintAdditionalPositionFromUrl.create() }
      breadcrumb {
        linkContent = { BlueprintAdditionalPositionLabelFromUrl {} }
      }
    }

    route(PathConstants.bestandsAnlagenPV + "/" + RouterVars.bestandsAnlagenPVId.toPathString()) {
      element = { EditBlueprintExistingPVFacilityFromUrl.create() }
      breadcrumb {
        label = "Bestandsanlage PV"
      }
    }

    route(PathConstants.bestandsAnlagenBHKW + "/" + RouterVars.bestandsAnlagenBHKWId.toPathString()) {
      element = { EditBlueprintExistingBHKWFacilityFromUrl.create() }
      breadcrumb {
        label = "Bestandsanlage BHKW"
      }
    }
  }

  route(PathConstants.finish) {
    element = { EditBlueprintFinishFromUrl.create() }
    breadcrumb {
      label = "Abschluss"
    }
  }

}

/**
 * ATTENTION: isPresentation does not make any sense!!!
 */
internal fun NavigationElement.Builder.configurationRouting() {
  element = { ConfigurationOverviewFromUrl.create() }
  breadcrumb {
    linkContent = { ConfigurationLabelFromUrl {} }
  }

  index {
    element = { EditConfigurationProcessStateFromUrl.create() }
    breadcrumb {
      label = "Angebotszustand"
    }
  }

  route(PathConstants.description) {
    index { element = { EditConfigurationDescriptionFromUrl.create() } }
    breadcrumb {
      label = "Angebotsbeschreibung"
    }
  }

  route(PathConstants.location) {
    element = { EditConfigurationLocationFromUrl.create() }
    breadcrumb {
      label = "Standort"
    }
  }

  route(PathConstants.electricity) {
    element = { EditInquiryInformationFromUrl.create() }
    breadcrumb {
      label = "Strom"
    }
  }

  route(PathConstants.moduleLayouts) {
    index { element = { ConfigurationModuleLayoutsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Dachflächen"
    }

    route(RouterVars.moduleLayoutId) {
      element = { EditConfigurationModuleLayoutFromUrl.create() }
      breadcrumb {
        linkContent = { ConfigurationModuleLayoutLabelFromUrl {} }
      }
    }
  }

  route(PathConstants.moduleStrings) {
    element = { ModuleStringsFromUrl.create() }

    index { element = { ModuleStringsFromUrl.create() } }
    breadcrumb {
      label = "Stringplanung"
    }

    route(RouterVars.moduleLayoutId) {
      element = { EditModuleStringsFromUrl.create() }
      breadcrumb {
        linkContent = { ConfigurationModuleLayoutLabelFromUrl {} }
      }
    }
  }

  route(PathConstants.facility) {
    index { element = { EditConfigurationFacilityFromUrl.create() } }
    breadcrumb {
      label = "Anlage & Montage"
    }
  }

  route(PathConstants.powerUsage) {
    index { element = { EditConfigurationPowerUsageFromUrl.create() } }
    breadcrumb {
      label = "Strom"
    }
  }

  route(PathConstants.electricityWorkEffort) {
    index { element = { EditConfigurationElectricityWorkEffortFromUrl.create() } }
    breadcrumb {
      label = "Zählerschrankarbeiten"
    }
  }

  route(PathConstants.wallbox) {
    index { element = { EditConfigurationWallboxFromUrl.create() } }
    breadcrumb {
      label = "E-Auto"
    }
  }

  route(PathConstants.additional) {
    index { element = { ConfigurationAdditionalOverviewFromUrl.create() } }
    breadcrumb {
      label = "Zusätzliches"
    }

    route(PathConstants.additionalPositions + "/" + RouterVars.additionalPositionId.toPathString()) {
      element = { EditConfigurationAdditionalPositionFromUrl.create() }
      breadcrumb {
        linkContent = { ConfigurationAdditionalPositionLabelFromUrl {} }
      }
    }

    route(PathConstants.bestandsAnlagenPV + "/" + RouterVars.bestandsAnlagenPVId.toPathString()) {
      element = { EditConfigurationExistingPVFacilityFromUrl.create() }
      breadcrumb {
        label = "Bestandsanlage PV"
      }
    }

    route(PathConstants.bestandsAnlagenBHKW + "/" + RouterVars.bestandsAnlagenBHKWId.toPathString()) {
      element = { EditConfigurationExistingBHKWFacilityFromUrl.create() }
      breadcrumb {
        label = "Bestandsanlage BHKW"
      }
    }
  }

  route(PathConstants.quotePreview) {
    index { element = { QuotePositionsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Rechnungsvorschau"
    }
  }

  route(PathConstants.economics) {
    index { element = { EconomicsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Wirtschaftlichkeit"
    }
  }

  route(PathConstants.profit) {
    index { element = { ProfitOverviewFromUrl.create() } }
    breadcrumb {
      label = "Deckungsbeitrag"
    }
  }

  route(PathConstants.earnings) {
    index { element = { ConfigurationEarningsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Verdienst"
    }
  }

  route(PathConstants.annotations) {
    index { element = { AnnotationsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Bemerkungen"
    }
  }

}


internal fun NavigationElement.Builder.adminRouting() {

  route(PathConstants.accounting) {
    element = { AccountingOverview3.create() }

    index { element = { AccountingOverview.create() } }
    breadcrumb {
      label = "Abrechnungsübersicht"
    }

    route(PathConstants.accounting) {
      index { element = { AccountingOverview2.create() } }
      breadcrumb {
        label = "Abrechnung"
      }
    }
  }

  route(PathConstants.companies) {
    index { element = { CompaniesAndUsersOverviewFromUrl.create() } }
    breadcrumb {
      label = "Firmen"
    }

    route(PathConstants.newCompany) {
      element = { AddNewCompanyForm.create() } //TODO add wrapper component!
      breadcrumb {
        label = "Neue Firma"
      }
    }

    //Uses a var! Should come last!
    route(RouterVars.companyCode) {
      index { element = { EditCompanyFromUrl.create() } }
      breadcrumb {
        linkContent = { CompanyNameFromUrl {} }
      }

      route(PathConstants.newUser) {
        element = { AddNewUserForCompanyFromUrl.create() } //TODO add wrapper component!
        breadcrumb {
          label = "Neuer Benutzer"
        }
      }

    }
  }

  route(PathConstants.users) {
    breadcrumb {
      label = "Benutzer"
    }

    route(RouterVars.loginName) {
      element = { UserOverviewFromUrl.create() }
      breadcrumb {
        linkContent = { EditorNameFromUrl {} }
      }
    }
  }

}
