@file:Suppress("DuplicatedCode")

package components.project.configuration

import i18n.EnumTranslator
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.commons.tags.Tag
import it.neckar.commons.tags.Tags
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.open.formatting.formatAsPercentage
import it.neckar.open.kotlin.lang.enumEntries
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Adds a form for a quote position
 */
fun RBuilder.configurationItemForm(
  headline: StateInstance<String>,
  details: StateInstance<String>,
  price: StateInstance<@EUR Double>,
  amount: StateInstance<Double>,
  /**
   * If the state is set to null, it will not be configurable
   */
  relevance: StateInstance<Tags>? = null,
  valueAddedTax: StateInstance<@pct Double>,
  editableStatus: EditableStatus,
): Unit = child(ConfigurationItemForm) {
  attrs {
    this.headline = headline
    this.details = details
    this.price = price
    this.amount = amount
    this.relevance = relevance
    this.valueAddedTax = valueAddedTax
    this.editableStatus = editableStatus
  }
}

val ConfigurationItemForm: FC<ConfigurationItemFormProps> = fc("ConfigurationItemForm") { props ->
  val headline = props::headline.getNotNull()
  val details = props::details.getNotNull()
  val price = props::price.getNotNull()
  val amount = props::amount.getNotNull()
  val relevance = props::relevance.safeGet()
  val valueAddedTax = props::valueAddedTax.getNotNull()
  val editableStatus = props::editableStatus.safeGet()

  val tagState = useState(
    when (relevance?.value?.tags?.firstOrNull { it != LizergyCalculationCategories.EconomicsCalculation }) {
      LizergyCalculationCategories.BatteryStorage -> IncomePercentageCategory.Profit2Battery
      LizergyCalculationCategories.Scaffolding -> IncomePercentageCategory.Profit3Scaffolding
      LizergyCalculationCategories.ElectricityMaterial -> IncomePercentageCategory.Profit4ElectricityMaterial
      LizergyCalculationCategories.Assembly -> IncomePercentageCategory.Profit5Assembly
      LizergyCalculationCategories.ElectricityWork -> IncomePercentageCategory.Profit6ElectricityWork
      else -> IncomePercentageCategory.Profit1
    }
  )
  val relevanceForEconomicsCalculationState = useState(relevance?.value.let { RelevanceForEconomicsCalculation.from(it) })


  useMemo(tagState.value, relevanceForEconomicsCalculationState.value) {
    relevance?.setter?.invoke(
      Tags(
        buildList {
          when (tagState.value) {
            IncomePercentageCategory.Profit2Battery -> add(LizergyCalculationCategories.BatteryStorage)
            IncomePercentageCategory.Profit3Scaffolding -> add(LizergyCalculationCategories.Scaffolding)
            IncomePercentageCategory.Profit4ElectricityMaterial -> add(LizergyCalculationCategories.ElectricityMaterial)
            IncomePercentageCategory.Profit5Assembly -> add(LizergyCalculationCategories.Assembly)
            IncomePercentageCategory.Profit6ElectricityWork -> add(LizergyCalculationCategories.ElectricityWork)
            else -> {}
          }
          relevanceForEconomicsCalculationState.value.asTag()?.let { add(it) }
        }.toSet()
      )
    )
  }


  floatingInputField(
    valueAndSetter = headline,
    fieldName = "headline",
    title = "Beschreibung",
    editableStatus = editableStatus,
  ) {
    attrs {
      mergedBelow()
    }
  }

  floatingInputArea(
    valueAndSetter = details,
    fieldName = "details",
    title = "Details",
    editableStatus = editableStatus,
  ) {
    attrs {
      mergedBelow()
      mergedAbove()
    }
  }

  floatingDoubleInputField(
    valueAndSetter = price,
    fieldName = "price",
    title = "Einzelpreis",
    editableStatus = editableStatus,
  ) {
    attrs {
      mergedBelow()
      mergedAbove()
    }
  }

  floatingDoubleInputField(
    valueAndSetter = amount,
    fieldName = "amount",
    title = "Menge",
    numberConstraint = ZeroOrPositive,
    editableStatus = editableStatus,
  ) {
    attrs {
      mergedBelow()
      mergedAbove()
    }
  }

  floatingSelect(
    valueAndSetter = valueAddedTax,
    idProvider = {
      when (it) {
        0.0 -> "zero"
        else -> "nineteen"
      }
    },
    formatter = { it.formatAsPercentage(0) },
    fieldName = "valueAddedTax",
    title = "MwSt.",
    availableOptions = listOf(0.percent, 19.percent),
    editableStatus = editableStatus,
  ) {
    attrs {
      mergedAbove()
    }
  }

  if (relevance != null) {

    div("mt-3") {
      floatingSelectEnum(
        valueAndSetter = tagState,
        fieldName = "relevance",
        title = "Relevanz für Wirtschaftlichkeit",
        formatter = IncomePercentageCategory::description,
        availableOptions = IncomePercentageCategory.entries,
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
        }
      }
    }

    div("mb-3") {
      floatingSelectEnum(
        valueAndSetter = relevanceForEconomicsCalculationState,
        fieldName = "relevance",
        title = "Relevanz für Wirtschaftlichkeit",
        formatter = EnumTranslator.RelevanceForEconomicsCalculation,
        availableOptions = enumEntries(),
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
        }
      }
    }

  }

}


external interface ConfigurationItemFormProps : Props {
  var headline: StateInstance<String>
  var details: StateInstance<String>
  var price: StateInstance<@EUR Double>
  var amount: StateInstance<Double>
  var relevance: StateInstance<Tags>?
  var valueAddedTax: StateInstance<@pct Double>
  var editableStatus: EditableStatus
}

/**
 * Helper enum that allows editing of the relevance for economics calculations
 */
enum class RelevanceForEconomicsCalculation {
  Relevant,
  NotRelevant,

  ;

  fun asTag(): Tag? {
    return when (this) {
      Relevant -> LizergyCalculationCategories.EconomicsCalculation
      NotRelevant -> null
    }
  }

  companion object {
    /**
     * Checks if the given relevance contains [LizergyCalculationCategories.EconomicsCalculation]
     */
    fun from(tags: Tags?): RelevanceForEconomicsCalculation {
      if (tags?.contains(LizergyCalculationCategories.EconomicsCalculation) == true) {
        return Relevant
      }

      return NotRelevant
    }
  }
}
