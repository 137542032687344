package it.neckar.customer.company

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder


/**
 * A company code.
 * Must not contain spaces or special chars
 */
@Serializable(with = CompanyCodeSerializer::class)
data class CompanyCode(val value: String) {
  companion object {
    /**
     * The company code for NeckarIT
     */
    val NeckarIT: CompanyCode = CompanyCode("neckarIT")

    /**
     * The company code for lizergy
     */
    val Lizergy: CompanyCode = CompanyCode("lizergy")

    /**
     * Demo Company - for demo purposes
     */
    val DemoCompany: CompanyCode = CompanyCode("demoCompany")

    /**
     * Test Company - only for tests
     */
    val TestCompany: CompanyCode = CompanyCode("testCompany")
  }

  override fun toString(): String {
    return value
  }
}

object CompanyCodeSerializer : KSerializer<CompanyCode> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("CompanyCode", PrimitiveKind.STRING)

  override fun serialize(encoder: Encoder, value: CompanyCode) {
    encoder.encodeString(value.value)
  }

  override fun deserialize(decoder: Decoder): CompanyCode {
    return CompanyCode(decoder.decodeString())
  }
}
