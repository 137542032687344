package components.company.user

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.user.UserLoginName
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import services.UiActions

/**
 * Change password form
 */

val ChangePasswordForm: FC<ChangePasswordFormProps> = fc("ChangePasswordForm") { props ->

  val loginName = props::loginName.safeGet()

  //val oldPassword = useState("")
  val newPassword1 = useState("")
  val newPassword2 = useState("")

  val passwordPlausible = /*oldPassword.value.isNotBlank() &&*/ newPassword1.value.isNotBlank() && newPassword1.value == newPassword2.value

  val (busy, setBusy) = useState(false)


  val okAction = useCallback(passwordPlausible, /*oldPassword.value,*/ newPassword1.value, newPassword2.value) {
    if (passwordPlausible.not()) return@useCallback

    launchAndNotify {
      setBusy(true)
      UiActions.changePassword(loginName, /*oldPassword.value,*/ newPassword1.value.trim(), newPassword2.value.trim())
      setBusy(false)
    }
  }


  div {

    onEnter(okAction)

    h3 {
      +"Passwort"
    }

    /*div("mb-2 mt-3") {
      floatingInputField(
        valueAndSetter = oldPassword,
        fieldName = "oldPassword",
        title = "Altes Passwort",
      ) {
        attrs {
          type = InputType.password
        }
      }
    }*/

    passwordForm(
      fieldName = "Neues Passwort",
      newPassword1 = newPassword1,
      newPassword2 = newPassword2,
    )

    div("my-3") {

      button(classes = "btn btn-primary w-100 btn-lg", type = ButtonType.button) {
        +"Passwort ändern"

        attrs {
          disabled = passwordPlausible.not() || busy
          onClickFunction = { okAction() }
        }
      }
    }

  }

}

external interface ChangePasswordFormProps : Props {
  var loginName: UserLoginName
}
