package it.neckar.lizergy.model.company

import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.customer.company.PartnerCompanyProfile
import it.neckar.customer.company.ResolvedCompanyTree
import it.neckar.customer.company.SerializedCompanyTree
import it.neckar.lifeCycle.onlyActive
import it.neckar.lizergy.model.company.user.AccessRights
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.open.unit.number.MayBeEmpty
import it.neckar.user.UserLoginName
import kotlinx.serialization.Serializable

/**
 * Contains all users and companies that are available for the current user
 */
@Serializable
data class UsersAndCompanies(
  private val users: List<UserInformation>,
  private val companies: List<PlannerCompanyInformation>,
) : CompanyResolver, UserResolver {

  //TODO introduce maps for faster access

  private val companiesUsersMap: Map<CompanyCode, List<UserInformation>>
    get() = users.groupBy { it.company.companyCode }

  /**
   * Returns the [UserInformation] for the given [UserLoginName]
   * throws an [IllegalStateException] if no user is found for the given [UserLoginName]
   */
  override operator fun get(loginName: UserLoginName): UserInformation {
    return users.find { it.loginName == loginName } ?: if (loginName == UserLoginName.NeckarIT) UserInformation.NeckarIT else UserInformation.createStubUser(loginName)
  }

  override fun get(loginName: String): UserInformation {
    return users.find { it.loginName.value.lowercase() == loginName } ?: if (loginName == UserLoginName.NeckarIT.value.lowercase()) UserInformation.NeckarIT else UserInformation.createStubUser(loginName)
  }

  /**
   * Returns the [PlannerCompanyInformation] for the given [CompanyCode].
   */
  override operator fun get(companyCode: CompanyCode): PlannerCompanyInformation {
    return companies.find { it.companyCode == companyCode } ?: if (companyCode == CompanyCode.NeckarIT) PlannerCompanyInformation.NeckarIT else PlannerCompanyInformation.createStubCompany(companyCode)
  }


  /**
   * Returns all active companies for the logged-in user.
   *
   * Either its own company or all companies if the user has the right to edit other companies
   */
  fun activeCompanies(loggedInUser: UserInformation): List<PlannerCompanyInformation> {
    val canAccessOtherCompanies = loggedInUser.accessRights.canAccess(AccessRights.AccessRight.EditOtherCompanies)

    return companies.onlyActive().filter { canAccessOtherCompanies || it.companyProfile == loggedInUser.company }
      .sortedBy { it.name }
      .partition { it.companyCode == loggedInUser.company.companyCode }.let { it.first + it.second }
  }

  fun companyInformationToCompanyTree(company: PlannerCompanyInformation, loggedInUser: UserInformation): ResolvedCompanyTree {
    val parentCompany = if (company.companyProfile is PartnerCompanyProfile) get(company.companyProfile.parentCompany) else null
    val childCompanies = if (company.companyProfile is MainCompanyProfile) activeCompanies(loggedInUser).filter {
      it.companyProfile is PartnerCompanyProfile && it.companyProfile.parentCompany == company.companyCode
    }.toSet() else null

    return ResolvedCompanyTree(
      company = company,
      parentCompany = parentCompany,
      childCompanies = childCompanies,
    )
  }

  fun companyInformationToCompanyTree(companyCode: CompanyCode, loggedInUser: UserInformation): ResolvedCompanyTree {
    return companyInformationToCompanyTree(get(companyCode), loggedInUser)
  }

  fun activeCompaniesAsTrees(loggedInUser: UserInformation): List<ResolvedCompanyTree> {
    val canAccessOtherCompanies = loggedInUser.accessRights.canAccess(AccessRights.AccessRight.EditOtherCompanies)

    return companies.onlyActive().filter { canAccessOtherCompanies || it.companyProfile == loggedInUser.company }.map { company ->
      companyInformationToCompanyTree(company, loggedInUser)
    }
  }

  /**
   * Returns the active users visible for the logged-in user.
   */
  fun activeUsers(loggedInUser: UserInformation): List<UserInformation> {
    //TODO attention: exponential complexity! Simplify or cache!

    val activeCompanies = activeCompanies(loggedInUser)
    return users.onlyActive().filter {
      activeCompanies.any { it.companyProfile == loggedInUser.company }
    }
  }

  /**
   * Returns all users for the provided company profile
   */
  fun usersForCompany(companyProfile: CompanyProfile): @MayBeEmpty List<UserInformation> {
    return usersForCompany(companyProfile.companyCode)
  }

  /**
   * Returns all users for the provided company code
   * Attention: List might be empty
   */
  fun usersForCompany(companyCode: CompanyCode): @MayBeEmpty List<UserInformation> {
    return companiesUsersMap[companyCode]?.onlyActive()?.sortedBy { it.editorName } ?: emptyList()
  }

  /**
   * Returns the default user for the company.
   * Returns the first user if no default user is set
   */
  fun defaultUserForCompany(companyProfile: CompanyProfile): UserInformation {
    return get(get(companyProfile).defaultUser)
  }

  /**
   * Returns the default user for the company
   */
  fun defaultUserForCompany(companyCode: CompanyCode): UserInformation {
    return get(get(companyCode).defaultUser)
  }

  fun withNewUser(newUser: UserInformation): UsersAndCompanies {
    return copy(users = users + newUser)
  }

  fun withUpdatedUser(updatedUser: UserInformation): UsersAndCompanies {
    val updatedUserLoginName = users.indexOfFirst {
      it.loginName == updatedUser.loginName
    }

    val newUsers = this.users.toMutableList()
    newUsers[updatedUserLoginName] = updatedUser

    return copy(users = newUsers)
  }

  fun withNewCompany(newCompany: PlannerCompanyInformation): UsersAndCompanies {
    return copy(companies = companies + newCompany)
  }

  fun withUpdatedCompany(updatedCompany: PlannerCompanyInformation): UsersAndCompanies {
    val updatedCompanyCode = companies.indexOfFirst {
      it.companyProfile == updatedCompany.companyProfile
    }

    val newCompanies = this.companies.toMutableList()
    newCompanies[updatedCompanyCode] = updatedCompany

    return copy(companies = newCompanies)
  }

  companion object {
    val empty: UsersAndCompanies = UsersAndCompanies(emptyList(), emptyList())
  }

}


fun SerializedCompanyTree.resolve(companyResolver: CompanyResolver): ResolvedCompanyTree {
  return ResolvedCompanyTree(
    company = companyResolver[this.companyCode],
    parentCompany = this.parentCompanyCode?.let { companyResolver[it] },
    childCompanies = this.childCompanyCodes?.map { companyResolver[it] }?.toSet(),
  )
}

fun ResolvedCompanyTree.unResolve(): SerializedCompanyTree {
  return SerializedCompanyTree(
    companyCode = this.companyCode,
    parentCompanyCode = this.parentCompanyCode,
    childCompanyCodes = this.childCompanyCodes,
  )
}

