package serialized

import it.neckar.lizergy.model.configuration.moduleLayout.ModulesReport
import it.neckar.lizergy.model.configuration.moduleLayout.ModulesReport.ModuleCount
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModulesReport
import it.neckar.lizergy.model.price.ProductResolver
import kotlinx.serialization.Serializable

/**
 * Contains the total number of different modules
 */
@Serializable
data class SerializedModulesReport(
  override val countByModuleIds: List<ModuleCount>,
) : ModulesReport {

  fun resolve(productResolver: ProductResolver): ResolvedModulesReport {
    return ResolvedModulesReport(countByModuleIds.map { moduleCount -> moduleCount.resolve(productResolver) })
  }

  companion object {
    /**
     * Creates a modules report for the given layouts
     */
    fun create(moduleLayouts: List<SerializedModuleLayout>): SerializedModulesReport {
      val groupedByModuleType = moduleLayouts.groupBy { moduleLayout ->
        moduleLayout.moduleTypeId
      }

      val countByModules = groupedByModuleType.map { entry ->
        ModuleCount(
          moduleId = entry.key,
          count = entry.value.sumOf { moduleLayout -> moduleLayout.modulesCount }
        )
      }

      return SerializedModulesReport(countByModules)
    }
  }
}

fun ResolvedModulesReport.unResolve(): SerializedModulesReport {
  return SerializedModulesReport(countByModuleIds)
}
