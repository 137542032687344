package components.project.configuration

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.quote.CalculationRelevanceQuery
import it.neckar.financial.quote.Optionality
import it.neckar.financial.quote.QuoteElements
import it.neckar.financial.quote.Visibility
import it.neckar.financial.quote.flat.flat
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.configuration.quote.builder.formatForTable
import it.neckar.open.collections.fastForEach
import it.neckar.open.formatting.formatAsPercentage
import it.neckar.open.formatting.percentageFormat
import it.neckar.react.common.FontAwesome.faCircleCheck
import it.neckar.react.common.FontAwesome.faCircleExclamation
import it.neckar.react.common.FontAwesome.faCircleQuestion
import it.neckar.react.common.FontAwesome.faEye
import it.neckar.react.common.FontAwesome.faEyeSlash
import it.neckar.react.common.FontAwesome.faMinus
import kotlinx.html.ThScope
import kotlinx.serialization.Serializable
import plannerI18nConfiguration
import react.*
import react.dom.*

/**
 * Shows the dump of the quote positions (as pre)
 */
fun RBuilder.quotePositionsDump(quoteElements: QuoteElements): Unit = child(QuotePositionsDump) {
  attrs {
    this.quoteElements = quoteElements
  }
}

val QuotePositionsDump: FC<QuotePositionsDumpProps> = fc("quotePositionDetails") { props ->
  val quoteElements = props::quoteElements.safeGet()

  div("card card-body") {
    h4 {
      +"Details"
    }
    pre {
      +quoteElements.dump(CalculationRelevanceQuery.allIncludingOptional, CalculationRelevanceQuery.allIncludingOptional)
    }
  }

}

external interface QuotePositionsDumpProps : Props {
  var quoteElements: QuoteElements
}


/**
 * Visualizes the quote positions as a table
 */
val QuotePositionsTable: FC<QuotePositionsTableProps> = fc("QuotePositionsTable") { props ->
  val quoteElements = props::quoteElements.safeGet()
  val query: CalculationRelevanceQuery = props::query.safeGet() ?: CalculationRelevanceQuery.allVisibleOnlyMandatory

  val mode = props::mode.safeGet() ?: QuotePositionsTableMode.Simplified
  val detailedModeActive = mode == QuotePositionsTableMode.Detailed

  val flatElements = quoteElements.flat(visibilityQuery = query)


  table("table table-responsive align-middle") {
    thead {
      tr {
        if (detailedModeActive) {
          th(ThScope.col) {
            +""
          }
        }
        th(ThScope.col) {
          +"#"
        }
        th(ThScope.col) {
          +"Optional"
        }
        th(ThScope.col) {
          +"Beschreibung"
        }

        if (detailedModeActive) {
          th(ThScope.col, "text-center") {
            +"Wirtschaftlichkeit"
          }

          th(ThScope.col, "text-center") {
            +"Deckungsbeitrag"
          }
        }

        th(ThScope.col, "text-end") {
          +"Menge"
        }
        th(scope = ThScope.col, "text-end") {
          +"Preis"
        }
        th(ThScope.col, "text-end") {
          +"Gesamt"
        }

        if (mode != QuotePositionsTableMode.Simplified) {
          th(ThScope.col, "text-end") {
            +"MwSt."
          }
        }

      }
    }

    tbody {
      flatElements.lines.fastForEach { line ->
        val visibility = line.visibility
        val visibilityClass = when (visibility) {
          Visibility.Public -> ""
          Visibility.Internal -> "visibility-internal"
        }

        tr {
          if (detailedModeActive) {
            td(visibilityClass) {
              when (visibility) {
                Visibility.Public -> faEye()
                Visibility.Internal -> faEyeSlash()
              }
            }
          }

          td(visibilityClass) {
            +line.indentation.format
          }

          td("$visibilityClass text-center") {
            when (line.optionality) {
              Optionality.Mandatory -> faCircleExclamation()
              Optionality.Optional -> faCircleQuestion()
            }
          }

          td(visibilityClass) {
            b { +line.headline }

            line.details?.let { details ->
              details.lines().fastForEach {
                p("small") {
                  +it
                }
              }
            }
          }

          if (detailedModeActive) {
            td("$visibilityClass text-center") {
              if (line.isSumPosition.not()) {
                if (line.relevance?.contains(LizergyCalculationCategories.EconomicsCalculation) == true) {
                  faCircleCheck()
                } else {
                  faMinus()
                }
              }
            }

            td("$visibilityClass text-center") {
              if (line.isSumPosition.not()) {
                +line.formatForTable(plannerI18nConfiguration)
              }
            }
          }

          td("$visibilityClass text-end") {
            +(line.amount?.format().orEmpty())
          }
          td("$visibilityClass text-end") {
            +(line.priceForOneElement?.sellingPrice?.format().orEmpty())
          }

          td("$visibilityClass text-end") {
            +line.sumIncludingOptional.sellingPrice.format(plannerI18nConfiguration)
          }

          if (mode != QuotePositionsTableMode.Simplified) {
            td("$visibilityClass text-end") {
              +line.valueAddedTax.joinToString(" / ") { it.vat.formatAsPercentage(0, i18nConfiguration = plannerI18nConfiguration) }
            }
          }

        }
      }

      //Total
      val subTotals = quoteElements.subTotalsForVATs()
      val discounts = quoteElements.discountsForVATs()
      val netPrices = quoteElements.netPricesForVats()
      val vatPrices = quoteElements.vatPrices()
      val grossPrices = quoteElements.grossPricesForVats()

      tr("visibility-internal") {
        td {
          +"Rechnungssumme"
          attrs {
            colSpan = "4"
          }
        }
        td("text-end") {
          attrs {
            colSpan = "99"
          }
          +subTotals.net.sellingPrice.format(plannerI18nConfiguration)
        }
      }

      tr("visibility-internal") {
        td {
          +"Rabatt (${percentageFormat.format(quoteElements.discountPercentage)})"
          attrs {
            colSpan = "4"
          }
        }
        td("text-end") {
          attrs {
            colSpan = "99"
          }
          +discounts.total.format(plannerI18nConfiguration)
        }
      }

      tr {
        td {
          +"Nettosumme"
          attrs {
            colSpan = "4"
          }
        }
        td("text-end") {
          attrs {
            colSpan = "99"
          }
          +netPrices.total.format(plannerI18nConfiguration)
        }
      }

      vatPrices.forEach {
        tr("visibility-internal") {
          td {
            +it.first.format(plannerI18nConfiguration)
            attrs {
              colSpan = "4"
            }
          }
          td("text-end") {
            attrs {
              colSpan = "99"
            }
            +it.second.format(plannerI18nConfiguration)
          }
        }
      }

      tr("fw-bold") {
        td {
          +"Gesamtsumme"
          attrs {
            colSpan = "4"
          }
        }
        td("text-end") {
          attrs {
            colSpan = "99"
          }
          +grossPrices.total.format(plannerI18nConfiguration)
        }
      }

    }
  }

}

@Serializable
enum class QuotePositionsTableMode {
  /**
   * Simple table - ready for the customer
   */
  Simplified,

  /**
   * More details - only for internal usage
   */
  Detailed,
}

external interface QuotePositionsTableProps : Props {
  var quoteElements: QuoteElements

  var query: CalculationRelevanceQuery?
  var mode: QuotePositionsTableMode?
}
