package it.neckar.react.common.table

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.kotlin.lang.divCeil
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 *
 */
fun <T> RBuilder.remoteTableWithPagination(
  tableClasses: String = "table table-hover table-responsive",
  entries: List<T>,
  columns: List<TableColumn<T>>,
  pageSize: StateInstance<Int?>,
  currentPage: StateInstance<Int>,
  numberOfTotalFilteredProjects: Int,
  sortedByFunction: StateInstance<SortedByFunction<T>>,
  handler: (RemoteTableProps) -> Unit = {},
): Unit = child(RemoteTableWithPagination) {
  attrs {
    this.tableClasses = tableClasses
    this.entries = entries.unsafeCast<List<Any>>()
    this.columns = columns.unsafeCast<List<TableColumn<Any>>>()
    this.pageSize = pageSize
    this.currentPage = currentPage
    this.numberOfTotalFilteredProjects = numberOfTotalFilteredProjects
    this.sortedByFunction = sortedByFunction.unsafeCast<StateInstance<SortedByFunction<Any>>>()
    this.handler = handler.unsafeCast<(RemoteTableProps) -> Unit>()
  }
}

val RemoteTableWithPagination: FC<RemoteTableWithPaginationProps> = fc("RemoteTableWithPagination") { props ->
  val tableClasses = props::tableClasses.safeGet()
  val entries = props::entries.safeGet()
  val columns = props::columns.safeGet()
  val pageSize = props::pageSize.getNotNull()
  val currentPage = props::currentPage.getNotNull()
  val numberOfTotalFilteredProjects = props::numberOfTotalFilteredProjects.getNotNull()
  val sortedByFunction = props::sortedByFunction.getNotNull()
  val handler = props::handler.safeGet()

  val entryPagesCount = pageSize.value?.let { numberOfTotalFilteredProjects.divCeil(it) } ?: 1


  remoteTable(
    tableClasses = tableClasses,
    columns = columns,
    entries = entries,
    sortedByFunction = sortedByFunction,
    handler = handler,
  )

  div("row") {
    div("col-2") {
      floatingSelectNullable(
        valueAndSetter = pageSize,
        idProvider = { int -> int?.toString() ?: "allProjects" },
        formatter = { int -> int?.toString() ?: "ALLE - $numberOfTotalFilteredProjects" },
        availableOptionsWithoutNull = listOf(5, 10, 15, 20, 25, 100),
        fieldName = "pageSizeField",
        title = "Projektanzahl",
      )
    }

    div("col-10") {
      pagination(currentPage = currentPage, entryPagesCount = entryPagesCount)
    }
  }

}

external interface RemoteTableWithPaginationProps : Props {
  var tableClasses: String
  var entries: List<Any>
  var columns: List<TableColumn<Any>>
  var pageSize: StateInstance<Int?>
  var currentPage: StateInstance<Int>
  var numberOfTotalFilteredProjects: Int
  var sortedByFunction: StateInstance<SortedByFunction<Any>>
  var handler: (RemoteTableProps) -> Unit
}
