package components.company.pricelist.tables

import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.kotlin.lang.ifNull
import it.neckar.open.kotlin.lang.toCheckboxChar
import kotlinx.html.ThScope
import react.*
import react.dom.*
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectPriceList
import style.toStyleClass

val RoofTypePriceTable: FC<Props> = fc("RoofTypePriceTable") {
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()

  val available = availableProducts.availableRoofTypes(null)
  val prices = priceList.assemblyCostsPerModule


  div("col") {
    h2 {
      +"Materialkosten abhängig vom Dachtyp (pro Modul)"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Dachtyp"
          }
          th(ThScope.col, "text-center") {
            +"Kompatibel mir Metalldachplatten"
          }
          th(ThScope.col, "text-center") {
            +"Mit Abdachdämmung"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
          th(ThScope.col, "text-center") {
            +"In Gebrauch"
          }
        }
      }

      tbody {
        available.fastForEachIndexed { roofTypeTypeIndex, roofTypeType ->
          tr(classes = roofTypeType.lifeCycleState.toStyleClass()) {
            key = roofTypeType.uuid.toString()

            th(ThScope.row) {
              +"${roofTypeTypeIndex + 1}"
            }
            td {
              +roofTypeType.description
            }

            td("text-center") {
              +roofTypeType.metalRoofTileCompatability.format()
            }
            td("text-center") {
              +roofTypeType.withIsolation.toCheckboxChar()
            }

            td("text-end") {
              +prices[roofTypeType.uuid].inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +prices[roofTypeType.uuid].sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +prices[roofTypeType.uuid].profit.format().ifNull("-")
            }

            td("text-center") {
              +roofTypeType.lifeCycleState.format()
            }
          }
        }
      }
    }
  }
}
