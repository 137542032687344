package components.company.pricelist.tables

import it.neckar.open.collections.fastForEachIndexed
import kotlinx.html.ThScope
import react.*
import react.dom.*
import store.hooks.useSelectPriceList

val MonitoringPriceTable: FC<Props> = fc("MonitoringPriceTable") {
  val priceList = useSelectPriceList()

  val monitoringPrices = priceList.monitoringPrices


  div("col") {
    h2 {
      +"Monitoring Preise"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
        }
      }

      tbody {
        monitoringPrices.asList().fastForEachIndexed { monitoringIndex, monitoringPrice ->
          val type = when (monitoringIndex) {
            0 -> "Unter 10 kWp"
            1 -> "Unter 20 kWp"
            2 -> "Unter 30 kWp"
            else -> "Über 30 kWp"
          }

          tr {
            th(ThScope.row) {
              +(monitoringIndex * 2 + 1).toString()
            }
            td {
              +type
            }

            td("text-end") {
              +monitoringPrice.inputPrice.format()
            }
            td("text-end") {
              +monitoringPrice.sellingPrice.format()
            }
            td("text-end") {
              +monitoringPrice.profit.format()
            }
          }

          tr {
            th(ThScope.row) {
              +(monitoringIndex * 2 + 2).toString()
            }
            td {
              +"$type mit Batterie"
            }

            td("text-end") {
              +(monitoringPrice.inputPrice + monitoringPrices.additionalCostsWithBattery.inputPrice).format()
            }
            td("text-end") {
              +(monitoringPrice.sellingPrice + monitoringPrices.additionalCostsWithBattery.sellingPrice).format()
            }
            td("text-end") {
              +(monitoringPrice.profit + monitoringPrices.additionalCostsWithBattery.profit).format()
            }
          }
        }
      }
    }
  }
}
