package components.project

import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry.BlueprintAcquisitionProcessStates
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry.BlueprintProcessStates
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry.ConfigurationProcessStates
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry.PresentationProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry.QuoteConfirmationProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry.QuoteOfferProcessStates
import it.neckar.react.common.table.*
import react.*
import react.dom.*
import router.useDocumentTitle
import store.hooks.useRequireCompanyForLoggedInUser

val BlueprintsOverviewFromUrl: FC<Props> = fc("BlueprintsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = BlueprintAcquisitionProcessStates.entries + BlueprintProcessStates.entries

  useDocumentTitle(companyName, "Projekterfassungen")

  h1("mb-3") {
    +"Projekterfassungen"
  }

  ProjectsOverview {
    attrs {
      this.defaultFilteredBy = DefaultFilteredBy.Maintainer
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = listOf(BlueprintProcessStates.Done, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.Blueprint
      this.sortedBy = SortedBy.SortedAscending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("BluePrint")
    }
  }
}

val QuotesOverviewFromUrl: FC<Props> = fc("QuotesOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = ConfigurationProcessStates.entries

  useDocumentTitle(companyName, "Angebote")

  h1("mb-3") {
    +"Angebote"
  }

  ProjectsOverview {
    attrs {
      this.defaultFilteredBy = DefaultFilteredBy.Editor
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = listOf(ConfigurationProcessStates.Archived, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.CurrentConfiguration
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("Quotes")
    }
  }
}

val PresentationsOverviewFromUrl: FC<Props> = fc("PresentationsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = PresentationProcessStates.entries + QuoteOfferProcessStates.entries + QuoteConfirmationProcessStates.entries

  useDocumentTitle(companyName, "Projektvorstellungen")

  h1("mb-3") {
    +"Projektvorstellungen"
  }

  ProjectsOverview {
    attrs {
      this.defaultFilteredBy = DefaultFilteredBy.Maintainer
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = listOf(QuoteConfirmationProcessStates.Accepted, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.CurrentQuote
      this.sortedBy = SortedBy.SortedAscending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("Presentation")
    }
  }
}
