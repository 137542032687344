package components.assemblyPortfolio

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.ElementsSelectionEntry
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio
import it.neckar.lizergy.model.configuration.energy.kW
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.open.time.formatMinutes
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import plannerI18nConfiguration
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useSelectPriceList
import kotlin.time.Duration.Companion.minutes

internal val KomponentenOverview: FC<KomponentenOverviewProps> = fc("KomponentenOverview") { props ->
  val priceList = useSelectPriceList()

  val project = props::project.safeGet()
  val configuration = props::currentConfiguration.safeGet()
  val assemblyPortfolio = project.assemblyPortfolio

  val wechselrichter = useState(assemblyPortfolio.wechselrichter)
  val anzahlOptimierer = useState(assemblyPortfolio.anzahlOptimierer)
  val optimierer = useState(assemblyPortfolio.optimierer)
  val speicher = useState(assemblyPortfolio.speicher)
  val ladestationen = useState(assemblyPortfolio.ladestationen)

  val heizstabName = useState(assemblyPortfolio.heizstab.name)
  val heizstabLeistung = useState(assemblyPortfolio.heizstab.leistung?.kW)
  val heizstabMontageZeitInklusive = useState(assemblyPortfolio.heizstab.montageZeitInklusive)
  val heizstabMontageZeit = useState(assemblyPortfolio.heizstab.montageZeit?.inWholeMinutes?.toInt())

  val ersatzstrom = useState(assemblyPortfolio.ersatzstrom)
  val sunnyHomeManagerOderMeter = useState(assemblyPortfolio.sunnyHomeManagerOderMeter)

  val besonderheiten = useState(assemblyPortfolio.besonderheiten.orEmpty())
  val bestandsanlagen = useState(assemblyPortfolio.bestandsanlagen.orEmpty())
  val sonstigeArbeiten = useState(assemblyPortfolio.sonstigeArbeiten.orEmpty())


  useMemo(
    wechselrichter.value,
    anzahlOptimierer.value,
    optimierer.value,
    speicher.value,
    ladestationen.value,
    heizstabName.value,
    heizstabLeistung.value,
    heizstabMontageZeitInklusive.value,
    heizstabMontageZeit.value,
    ersatzstrom.value,
    sunnyHomeManagerOderMeter.value,
    besonderheiten.value,
    bestandsanlagen.value,
    sonstigeArbeiten.value,
  ) {
    if (
      wechselrichter.value != assemblyPortfolio.wechselrichter ||
      anzahlOptimierer.value != assemblyPortfolio.anzahlOptimierer ||
      optimierer.value != assemblyPortfolio.optimierer ||
      speicher.value != assemblyPortfolio.speicher ||
      ladestationen.value != assemblyPortfolio.ladestationen ||
      heizstabName.value != assemblyPortfolio.heizstab.name ||
      heizstabLeistung.value != assemblyPortfolio.heizstab.leistung?.kW ||
      heizstabMontageZeitInklusive.value != assemblyPortfolio.heizstab.montageZeitInklusive ||
      heizstabMontageZeit.value != assemblyPortfolio.heizstab.montageZeit?.inWholeMinutes?.toInt() ||
      ersatzstrom.value != assemblyPortfolio.ersatzstrom ||
      sunnyHomeManagerOderMeter.value != assemblyPortfolio.sunnyHomeManagerOderMeter ||
      besonderheiten.value != assemblyPortfolio.besonderheiten ||
      bestandsanlagen.value != assemblyPortfolio.bestandsanlagen ||
      sonstigeArbeiten.value != assemblyPortfolio.sonstigeArbeiten
    ) {
      val heizstabToSave = assemblyPortfolio.heizstab.copy(
        name = heizstabName.value.nullIfBlank(),
        leistung = heizstabLeistung.value?.kW,
        montageZeitInklusive = heizstabMontageZeitInklusive.value,
        montageZeit = heizstabMontageZeit.value?.minutes,
      )

      UiActions.saveAssemblyPortfolio(
        assemblyPortfolio.copy(
          wechselrichter = wechselrichter.value,
          anzahlOptimierer = anzahlOptimierer.value,
          optimierer = optimierer.value,
          speicher = speicher.value.nullIfBlank(),
          ladestationen = ladestationen.value,
          heizstab = heizstabToSave,
          ersatzstrom = ersatzstrom.value.nullIfBlank(),
          sunnyHomeManagerOderMeter = sunnyHomeManagerOderMeter.value.nullIfBlank(),
          besonderheiten = besonderheiten.value.nullIfBlank(),
          bestandsanlagen = bestandsanlagen.value.nullIfBlank(),
          sonstigeArbeiten = sonstigeArbeiten.value.nullIfBlank(),
        )
      )
    }
  }


  div("row my-4") {
    div("col-lg-6") {
      h3("my-3") { +"Komponenten" }

      div("my-3") {
        configuration.facilityConfiguration.inverterSelection.entriesNonEmpty.fastForEachIndexed { index, inverterSelection ->
          div("my-2") {
            h4("mb-2") { +"Wechselrichter ${index + 1}" }
            KomponentenInverterOverview {
              attrs {
                this.project = project
                this.inverterSelection = inverterSelection
                this.elementIndex = index
              }
            }
          }
        }
      }

      div("my-3") {
        div("row gx-0") {
          div("col-lg-6") {
            nullableFloatingIntInputField(
              valueAndSetter = anzahlOptimierer,
              fieldName = "anzahlOptimierer",
              title = "${configuration.facilityConfiguration.numberOfOptimizers} Stück",
            ) {
              attrs {
                mergedRight()
              }
            }
          }
          div("col-lg-6") {
            nullableFloatingInputField(
              valueAndSetter = optimierer,
              fieldName = "optimierer",
              title = "Optimierer",
            ) {
              attrs {
                mergedLeft()
              }
            }
          }
        }
      }

      nullableFloatingInputField(
        valueAndSetter = speicher,
        fieldName = "speicher",
        title = configuration.facilityConfiguration.batteryConfiguration?.format() ?: "Kein Batteriespeicher",
      )

      div("my-3") {
        configuration.wallboxSelection.entriesNonEmpty.fastForEachIndexed { index, wallboxSelection ->
          div("my-2") {
            h4("mb-2") { +"Ladestation ${index + 1}" }
            KomponentenWallboxOverview {
              attrs {
                this.project = project
                this.wallboxSelection = wallboxSelection
                this.drosselung = configuration.wallboxSelection.throttled
              }
            }
          }
        }
      }

      div("row my-3 gx-0") {
        div("col-lg-6") {
          nullableFloatingInputField(
            valueAndSetter = heizstabName,
            fieldName = "heizstabName",
            title = configuration.facilityConfiguration.heaterRod?.description ?: "Kein Heizstab",
          ) {
            attrs {
              mergedRight()
            }
          }
        }
        div("col-lg-6") {
          nullableFloatingDoubleInputField(
            valueAndSetter = heizstabLeistung,
            fieldName = "heizstabLeistung",
            title = configuration.facilityConfiguration.heaterRod?.heatingCapacity?.capacity()?.format(plannerI18nConfiguration) ?: "Keine Leistung",
          ) {
            attrs {
              mergedLeft()
              mergedBelow()
            }
          }
        }
        div("col-lg-6 my-auto") {
          checkbox(
            valueAndSetter = heizstabMontageZeitInklusive,
            fieldName = "heizstabMontageZeitInklusive",
            title = "Montagezeit in der AB incl.",
          )
        }
        div("col-lg-6") {
          nullableFloatingIntInputField(
            valueAndSetter = heizstabMontageZeit,
            fieldName = "heizstabMontageZeit",
            title = configuration.facilityConfiguration.heaterRod?.let { priceList[it].workingTime.formatMinutes() } ?: "Keine Montagezeit",
          ) {
            attrs {
              mergedAbove()
            }
          }
        }
      }

      div("my-3") {
        nullableFloatingInputField(
          valueAndSetter = ersatzstrom,
          fieldName = "ersatzstrom",
          title = configuration.electricityWorkConfiguration.independenceManager?.description ?: "Kein Ersatzstrom",
        ) {
          attrs {
            mergedBelow()
          }
        }
        val string = buildList {
          if (configuration.facilityConfiguration.batteryConfiguration != null || configuration.electricityWorkConfiguration.sunnyHomeManager) add("Sunny Home Manager 2.0")
          if (configuration.electricityWorkConfiguration.smaEnergyMeter) add("SMA-E-Meter")
        }.joinToString(" + ").nullIfBlank()
        nullableFloatingInputField(
          valueAndSetter = sunnyHomeManagerOderMeter,
          fieldName = "sunnyHomeManagerOderMeter",
          title = string ?: "Kein Managementsystem",
        ) {
          attrs {
            mergedAbove()
          }
        }
      }

      div("my-4") {
        h5 { +"Besonderheiten" }
        inputArea(
          valueAndSetter = besonderheiten,
          fieldName = "besonderheiten",
          title = "Besonderheiten",
          placeHolder = configuration.besonderheitenForAssemblyPortfolio,
        ) {
          attrs {
            addClasses("form-control height-500px")
          }
        }
        button(type = ButtonType.button, classes = "btn btn-primary mt-2") {
          attrs {
            onClickFunction = {
              besonderheiten.setter(configuration.besonderheitenForAssemblyPortfolio)
            }
          }
          +"Platzhalter als Text übernehmen"
        }
      }

      div("my-4") {
        h5 { +"Bestandsanlagen" }
        inputArea(
          valueAndSetter = bestandsanlagen,
          fieldName = "bestandsanlagen",
          title = "Bestandsanlagen",
          placeHolder = configuration.bestandsanlagesForAssemblyPortfolio,
        ) {
          attrs {
            addClasses("form-control height-250px")
          }
        }
        button(type = ButtonType.button, classes = "btn btn-primary mt-2") {
          attrs {
            onClickFunction = {
              bestandsanlagen.setter(configuration.bestandsanlagesForAssemblyPortfolio)
            }
          }
          +"Platzhalter als Text übernehmen"
        }
      }

      div("my-4") {
        h5 { +"Sonstige Arbeiten" }
        inputArea(
          valueAndSetter = sonstigeArbeiten,
          fieldName = "sonstigeArbeiten",
          title = "Sonstige Arbeiten",
          placeHolder = configuration.sonstigesForAssemblyPortfolio,
        ) {
          attrs {
            addClasses("form-control height-500px")
          }
        }
        button(type = ButtonType.button, classes = "btn btn-primary mt-2") {
          attrs {
            onClickFunction = {
              sonstigeArbeiten.setter(configuration.sonstigesForAssemblyPortfolio)
            }
          }
          +"Platzhalter als Text übernehmen"
        }
      }
    }
  }
}

external interface KomponentenOverviewProps : Props {
  var project: ResolvedProject
  var currentConfiguration: QuoteConfiguration
}


private val KomponentenInverterOverview: FC<KomponentenInverterOverviewProps> = fc("KomponentenInverterOverview") { props ->
  val project = props::project.safeGet()
  val inverterSelection = props::inverterSelection.safeGet()
  val elementIndex = props::elementIndex.safeGet()
  val assemblyPortfolio = project.assemblyPortfolio
  val correspondingInverter = inverterSelection.element
  val wechselrichterSelection = assemblyPortfolio.wechselrichter.entries.getOrNull(elementIndex)
    ?: ElementsSelectionEntry(null, inverterSelection.amount)

  val amount = useState(wechselrichterSelection.amount)
  val wechselrichter = useState(wechselrichterSelection.element)


  useMemo(amount.value, wechselrichter.value) {
    if (amount.value == wechselrichterSelection.amount && wechselrichter.value == wechselrichterSelection.element) return@useMemo
    UiActions.saveAssemblyPortfolio(
      assemblyPortfolio.copy(
        wechselrichter = assemblyPortfolio.wechselrichter.copy(
          entries = assemblyPortfolio.wechselrichter.entries.toMutableList().apply {
            val newInverterEntry = wechselrichterSelection.copy(amount = amount.value, element = wechselrichter.value)
            if (assemblyPortfolio.wechselrichter.entries.getOrNull(elementIndex) != null) this[elementIndex] = newInverterEntry
            else add(newInverterEntry)
          }
        )
      )
    )
  }


  div("row gx-0") {
    div("col-lg-6") {
      floatingIntInputField(
        valueAndSetter = amount,
        fieldName = "amount-${correspondingInverter.uuid}",
        title = "${inverterSelection.amount} Stück",
      ) {
        attrs {
          mergedRight()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingInputField(
        valueAndSetter = wechselrichter,
        fieldName = "wechselrichter-${correspondingInverter.uuid}",
        title = correspondingInverter.description,
      ) {
        attrs {
          mergedLeft()
        }
      }
    }
  }
}

external interface KomponentenInverterOverviewProps : Props {
  var project: ResolvedProject
  var inverterSelection: ElementsSelectionEntry<Inverter>
  var elementIndex: Int
}


private val KomponentenWallboxOverview: FC<KomponentenWallboxOverviewProps> = fc("KomponentenWallboxOverview") { props ->
  val priceList = useSelectPriceList()

  val project = props::project.safeGet()
  val wallboxSelection = props::wallboxSelection.safeGet()
  val drosselung = props::drosselung.safeGet()
  val assemblyPortfolio = project.assemblyPortfolio
  val correspondingWallbox = wallboxSelection.element
  val ladestationSelection = assemblyPortfolio.ladestationen.entries.firstOrNull { it.element.id == correspondingWallbox.id }
    ?: ElementsSelectionEntry(AssemblyPortfolio.Ladestation.getEmpty(correspondingWallbox.id, drosselung == true), wallboxSelection.amount)
  val ladestation = ladestationSelection.element

  val amount = useState(ladestationSelection.amount)
  val name = useState(ladestation.name)
  val leistung = useState(ladestation.leistung?.kW)
  val kabellaenge = useState(ladestation.kabellaenge)
  val montageZeitInklusive = useState(ladestation.montageZeitInklusive)
  val montageZeit = useState(ladestation.montageZeit?.inWholeMinutes?.toInt())


  useMemo(
    amount.value,
    name.value,
    leistung.value,
    kabellaenge.value,
    montageZeitInklusive.value,
    montageZeit.value,
  ) {
    if (
      amount.value == ladestationSelection.amount &&
      name.value == ladestation.name &&
      leistung.value == ladestation.leistung?.kW &&
      kabellaenge.value == ladestation.kabellaenge &&
      montageZeitInklusive.value == ladestation.montageZeitInklusive &&
      montageZeit.value == ladestation.montageZeit?.inWholeMinutes?.toInt()
    ) return@useMemo

    val newLadestationEntry = ElementsSelectionEntry(
      element = ladestation.copy(
        name = name.value.nullIfBlank(),
        leistung = leistung.value?.kW,
        kabellaenge = kabellaenge.value,
        montageZeitInklusive = montageZeitInklusive.value,
        montageZeit = montageZeit.value?.minutes,
      ),
      amount = amount.value,
    )

    val newLadestationEntries = if (assemblyPortfolio.ladestationen.entries.firstOrNull { it.element.id == correspondingWallbox.id } != null) {
      assemblyPortfolio.ladestationen.entries.map { ladestationEntry ->
        if (ladestationEntry.element.id == correspondingWallbox.id) newLadestationEntry else ladestationEntry
      }
    } else {
      assemblyPortfolio.ladestationen.entries + newLadestationEntry
    }

    UiActions.saveAssemblyPortfolio(
      assemblyPortfolio.copy(ladestationen = assemblyPortfolio.ladestationen.copy(entries = newLadestationEntries))
    )
  }


  div("row gx-0") {
    div("col-lg-6") {
      floatingIntInputField(
        valueAndSetter = amount,
        fieldName = "amount-${ladestation.uuid}",
        title = "${wallboxSelection.amount} Stück",
      ) {
        attrs {
          mergedRight()
          mergedBelow()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingInputField(
        valueAndSetter = name,
        fieldName = "name-${ladestation.uuid}",
        title = correspondingWallbox.description,
      ) {
        attrs {
          mergedLeft()
          mergedBelow()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingDoubleInputField(
        valueAndSetter = leistung,
        fieldName = "leistung-${ladestation.uuid}",
        title = correspondingWallbox.chargingCapacity.capacity().format(plannerI18nConfiguration),
      ) {
        attrs {
          mergedRight()
          mergedAbove()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingDoubleInputField(
        valueAndSetter = kabellaenge,
        fieldName = "kabellaenge-${ladestation.uuid}",
        title = correspondingWallbox.cableLength.format(plannerI18nConfiguration),
      ) {
        attrs {
          mergedLeft()
          mergedAbove()
          mergedBelow()
        }
      }
    }
    div("col-lg-6 my-auto") {
      checkbox(
        valueAndSetter = montageZeitInklusive,
        fieldName = "montageZeitInklusive-${ladestation.uuid}",
        title = "Montagezeit in der AB incl.",
      )
    }
    div("col-lg-6") {
      nullableFloatingIntInputField(
        valueAndSetter = montageZeit,
        fieldName = "montageZeit-${ladestation.uuid}",
        title = priceList.wallboxInstallationPrice.workingTime.formatMinutes(),
      ) {
        attrs {
          mergedAbove()
        }
      }
    }
  }
}

external interface KomponentenWallboxOverviewProps : Props {
  var project: ResolvedProject
  var wallboxSelection: ElementsSelectionEntry<Wallbox>
  var drosselung: Boolean?
}
