package it.neckar.lizergy.model.configuration.energy

import it.neckar.open.formatting.percentageFormat
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents the performance factor for a roof.
 * The factor depends on the inclination and orientation of the roof
 */
@Serializable
@JvmInline
value class PerformanceFactor(val percentage: @pct Double) {
  operator fun minus(subtrahend: PerformanceFactor): @pct PerformanceFactor {
    return PerformanceFactor(this.percentage - subtrahend.percentage)
  }

  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return percentageFormat.format(percentage, i18nConfiguration = i18nConfiguration, whitespaceConfig)
  }

}
