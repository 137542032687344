package components.project.dashboard

import components.project.processStatePill
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.other.pct100
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleQuestion
import it.neckar.uuid.HasUuid
import kotlinx.css.*
import kotlinx.html.classes
import kotlinx.html.title
import plannerI18nConfiguration
import react.*
import react.dom.*
import react.router.*
import react.router.dom.*
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUserResolver
import styled.*

fun RBuilder.timelineItem(
  title: String,
  component: HasUuid?,
  linkDestination: String,
  itemWidth: @pct100 Double,
  mostAdvancedProcessStateEntry: LizergyProcessStateEntry?,
  processStatesFilter: ((LizergyProcessStateEntry) -> Boolean)? = null,
): Unit = child(TimelineItem) {
  attrs {
    this.title = title
    this.component = component
    this.linkDestination = linkDestination
    this.itemWidth = itemWidth
    this.mostAdvancedProcessStateEntry = mostAdvancedProcessStateEntry
    this.processStatesFilter = processStatesFilter
  }
}

fun RBuilder.timelineItem(
  title: String,
  component: HasUuid?,
  linkDestination: String,
  itemCount: Int,
  mostAdvancedProcessStateEntry: LizergyProcessStateEntry?,
  processStatesFilter: ((LizergyProcessStateEntry) -> Boolean)? = null,
) {
  timelineItem(
    title = title,
    component = component,
    linkDestination = linkDestination,
    itemWidth = 100.0 / itemCount.toDouble(),
    mostAdvancedProcessStateEntry = mostAdvancedProcessStateEntry,
    processStatesFilter = processStatesFilter,
  )
}

val TimelineItem: FC<TimelineItemProps> = fc("TimelineItem") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val location = useLocation()

  val title = props::title.safeGet()
  val component = props::component.safeGet()
  val linkDestination = props::linkDestination.safeGet()
  @pct100 val itemWidth = props::itemWidth.safeGet()
  val mostAdvancedProcessStateEntry = props::mostAdvancedProcessStateEntry.safeGet()
  val processStatesFilter = props::processStatesFilter.safeGet()

  val processStatesForComponent = useLoadProcessStatesForComponent(component?.uuid)
  val currentProcessStateForComponent = processStatesForComponent?.current() ?: when (component) {

    is ResolvedBlueprint -> component.processState?.currentValue?.toNewProcessState()?.toProcessStateEntry(
      user = component.editorInformation ?: loggedInUser,
      dueDate = component.processState?.lastEditedTime,
      assignedAt = nowMillis(),
      assignedBy = loggedInUser,
    )

    is QuoteConfiguration -> component.processState?.currentValue?.toNewProcessState()?.toProcessStateEntry(
      user = component.editorInformation ?: loggedInUser,
      dueDate = null,
      assignedAt = nowMillis(),
      assignedBy = loggedInUser,
    )

    else -> null

  }

  val currentRelevantProcessStateEntry = processStatesForComponent?.filter { processStatesFilter?.invoke(it) ?: true }?.current()
  val currentProcessStateEntry = if (currentRelevantProcessStateEntry == null || (currentProcessStateForComponent != null && currentProcessStateForComponent >= currentRelevantProcessStateEntry)) currentProcessStateForComponent else null


  styledLi {
    attrs {
      classes = setOf("list-inline-item", "items-list")
    }
    css {
      width = itemWidth.pct
    }
    div("event-date") {
      NavLink {
        attrs {
          to = linkDestination

          if (mostAdvancedProcessStateEntry != null && currentProcessStateEntry != null && currentProcessStateEntry >= mostAdvancedProcessStateEntry) {
            addClass("btn")
            addClass("fw-bold")

            if (location.pathname.contains(linkDestination)) {
              addClass("btn-primary")
            } else {
              addClass("btn-info")
            }
          } else if (location.pathname.contains(linkDestination)) {
            addClass("btn")
            addClass("fw-bold")

            if (currentProcessStateEntry == null) {
              addClass("btn-dark")
            } else {
              addClass("btn-secondary")
            }
          } else {
            if (currentProcessStateEntry == null) {
              addClass("form-text")
            } else {
              addClass("fw-bold")
            }
          }
        }

        +title
      }
    }

    if (currentProcessStateEntry != null) {
      p {
        attrs {
          this.title = "${currentProcessStateEntry.processState.formatDate(currentProcessStateEntry.assignedAt, plannerI18nConfiguration)} [${userResolver[currentProcessStateEntry.assignedTo].editorName}]"
        }

        processStatePill(currentProcessStateEntry)
      }
    } else {
      p("form-text") { faCircleQuestion() }
    }
  }
}

fun RBuilder.emptyTimelineItem(itemCount: Int) {
  emptyTimelineItem(100.0 / itemCount.toDouble())
}

fun RBuilder.emptyTimelineItem(itemWidth: @pct100 Double) {
  styledLi {
    attrs {
      classes = setOf("list-inline-item")
    }
    css {
      width = itemWidth.pct
    }
  }
}


external interface TimelineItemProps : Props {
  var title: String
  var component: HasUuid?
  var linkDestination: String
  var itemWidth: @pct100 Double
  var mostAdvancedProcessStateEntry: LizergyProcessStateEntry?
  var processStatesFilter: ((LizergyProcessStateEntry) -> Boolean)?
}
