package it.neckar.ktor.client

import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.request.*
import io.ktor.http.*
import it.neckar.rest.jwt.JwtToken
import it.neckar.rest.jwt.JwtTokenPair
import it.neckar.rest.jwt.appendJwtTokenBearer


/**
 * Applies the bearer token from the provided JwsToken
 */
fun HttpMessageBuilder.bearerAuth(token: JwtToken) {
  bearerAuth(token.token)
}

/**
 * Converts a JWS token pair to Ktor BearerTokens
 */
fun JwtTokenPair?.toBearerTokens(): BearerTokens? {
  if (this == null) {
    return null
  }
  return BearerTokens(
    accessToken = this.accessToken.token,
    refreshToken = this.refreshToken.token,
  )
}

/**
 * Appends the JWT token as a header.
 *
 * Attention: Use the `io.ktor.server.auth.Authentication` plugin if possible!
 */
fun HttpRequestBuilder.appendJwtTokenBearer(jwtToken: JwtToken) {
  headers {
    this.appendJwtTokenBearer(jwtToken)
  }
}
