package components.assemblyPortfolio

import components.gridAssessment.entryPreview
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.formatting.dateFormat
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import react.router.dom.*
import router.RouterUrls
import services.UiActions

internal val KundendatenOverview: FC<KundendatenOverviewProps> = fc("KundendatenOverview") { props ->
  val project = props::project.safeGet()
  val configuration = props::currentConfiguration.safeGet()
  val assemblyPortfolio = project.assemblyPortfolio
  val customer = project.customer

  val phone = useState(assemblyPortfolio.locationPhone)
  val cellphone = useState(assemblyPortfolio.locationCellphone)
  val email = useState(assemblyPortfolio.locationEmail)


  useMemo(phone.value, cellphone.value, email.value) {
    if (
      phone.value != assemblyPortfolio.locationPhone ||
      cellphone.value != assemblyPortfolio.locationCellphone ||
      email.value != assemblyPortfolio.locationEmail
    ) {
      UiActions.saveAssemblyPortfolio(
        assemblyPortfolio.copy(
          locationPhone = phone.value.nullIfBlank(),
          locationCellphone = cellphone.value.nullIfBlank(),
          locationEmail = email.value.nullIfBlank(),
        )
      )
    }
  }


  div("row my-4") {
    div("col-lg-6") {
      h3("my-3") { +"Kundendaten" }

      entryPreview("Telefon Montageort") {
        nullableFloatingInputField(
          valueAndSetter = phone,
          fieldName = "phone",
          title = customer.phone.orEmpty(),
        )
      }

      entryPreview("Handy Montageort") {
        nullableFloatingInputField(
          valueAndSetter = cellphone,
          fieldName = "cellphone",
          title = customer.cellphone.orEmpty(),
        )
      }

      entryPreview("E-Mail Montageort") {
        nullableFloatingInputField(
          valueAndSetter = email,
          fieldName = "email",
          title = customer.email.orEmpty(),
        )
      }

      div("mt-3") {
        entryPreview("Anlagenbetreiber 1") {
          linkToConfiguration(project, configuration, buildString {
            append(configuration.facilityOperator1.name ?: "-")
            append(" / ")
            append(configuration.facilityOperator1.birthday?.let { dateFormat.format(it, plannerI18nConfiguration) } ?: "-")
          })
        }
        entryPreview("Anlagenbetreiber 2") {
          linkToConfiguration(project, configuration, buildString {
            append(configuration.facilityOperator2.name ?: "-")
            append(" / ")
            append(configuration.facilityOperator2.birthday?.let { dateFormat.format(it, plannerI18nConfiguration) } ?: "-")
          })
        }
      }
    }
  }
}

fun RBuilder.linkToConfiguration(project: ResolvedProject, configuration: QuoteConfiguration, linkContent: String) {
  Link {
    attrs {
      toUrl = RouterUrls.project(project).configuration(configuration).index
    }

    +linkContent
  }
}

external interface KundendatenOverviewProps : Props {
  var project: ResolvedProject
  var currentConfiguration: QuoteConfiguration
}
