package components.project.configuration


import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.economics.simple.SimpleProfitabilityTable
import it.neckar.open.collections.fastForEach
import it.neckar.open.formatting.percentageFormat
import react.*
import react.dom.*


val ProfitabilityTable: FC<EconomicsTableProps> = fc("EconomicsTable") { props ->

  val profitabilityTable = props::profitabilityTable.safeGet()

  table("table") {
    tbody("small") {

      tr("whitespace-nbsp") {
        th {
          +"Jahr"
        }
        profitabilityTable.columns.fastForEach { columnYear ->
          td("text-end") {
            +columnYear.year.toString()
          }
        }
      }

      tr("whitespace-nbsp") {
        th {
          p { +"Erzeugte Energie in kWh" }
          p { +"Eingespeiste Energie in kWh " }
          p { +"Selbstverbrauchte Energie in kWh" }
        }
        profitabilityTable.columns.fastForEach { columnEnergy ->
          td("text-end") {
            p {
              +columnEnergy.energyInformation.production.format()
            }
            p {
              +columnEnergy.energyInformation.feedIn.format()
            }
            p {
              +columnEnergy.energyInformation.ownConsumption.format()
            }
          }
        }
      }

      tr("whitespace-nbsp") {
        th {
          p { +"+ Einspeisevergütung in €" }
          p { +"+ Eingesparte Stromkosten in €" }
          p { +"- Betriebskosten in €"}
          p { +"Cash flow from Operations" }
        }
        profitabilityTable.columns.fastForEach { columnCashFlow ->
          td("text-end") {
            p {
              + columnCashFlow.cashFlow.feedInCompensation.format()
            }
            p {
              + columnCashFlow.cashFlow.savingsOwnConsumption.format()
            }
            p {
              + columnCashFlow.cashFlow.operatingCosts.format()
            }
            p {
              + columnCashFlow.cashFlow.cashFlowFromOperations.format()
            }
          }
        }
      }

      tr("whitespace-nbsp") {
        th {
          p { +"Zinszahlungen in €" }
          p { +"- Tilgung in €" }
          p { +"Cashflow aus Finanzierungstätigkeit in €" }
        }
        profitabilityTable.columns.fastForEach { columnCashFlow ->
          td("text-end") {
            p {
              +columnCashFlow.cashFlow.financingInformation.interest.format()
            }
            p {
              +columnCashFlow.cashFlow.financingInformation.repayment.format()
            }
            p {
              +columnCashFlow.cashFlow.cashFlowFromFinancingActivities.format()
            }
          }
        }
      }

      tr("whitespace-nbsp") {
        th {
          p {+"Cashflow (= mehr im Geldbeutel) in €"}
        }
        profitabilityTable.columns.fastForEach { columnCashFlow ->
          td("text-end") {
            p {
              +columnCashFlow.cashFlow.cashFlow.format()
            }
          }
        }
      }

      tr("whitespace-nbsp") {
        th {
          p {+"Restschuld in €"}
          p {+"Amortisation in %"}
          p {+"Kumulierter Cashflow in €"}
        }
        profitabilityTable.columns.fastForEach { col ->
          td("text-end") {
            p {
              +col.cashFlow.financingInformation.residualDebtAfterRepayment.format()
            }
            p {
              +percentageFormat.format(col.amortisationInformation.amortisationPercentage(col.cashFlow.financingInformation))
            }
            p {
              +col.amortisationInformation.cashFlowCumulated.format()
            }
          }
        }
      }
    }
  }
}

external interface EconomicsTableProps : Props {
  var profitabilityTable: SimpleProfitabilityTable
}
