package it.neckar.lizergy.model.configuration.components

import it.neckar.open.unit.si.ms
import kotlinx.serialization.Serializable

@Serializable
data class FacilityOperatorInformation(
  val name: String? = null,
  val birthday: @ms Double? = null,
) {
  fun isValid(): Boolean {
    return name != null && birthday != null
  }
}
