package it.neckar.processStates

/**
 * The phase of a process state
 */
enum class ProcessStatePhase {

  Paused,

  Rejected,

  /**
   * Currently untouched - nothing has been done yet.
   */
  Untouched,

  /**
   * The element is currently in progress (work is being done)
   */
  InProgress,

  Waiting,

  /**
   * The element is done
   */
  Done,

  ;

  val isFinished: Boolean
    get() = this == Paused || this == Done

  val isRejected: Boolean
    get() = this == Paused || this == Rejected
}
