package it.neckar.lizergy.model.assemblyPortfolio

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuid4
import com.benasher44.uuid.uuidFrom
import it.neckar.customer.Address
import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.ElementsSelectionEntry
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio.AssemblyPortfolioHeaterRod
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio.AssemblyPortfolioModuleLayout
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio.AssemblyPortfolioWallbox
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio.StringSelection
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.components.ScaffoldingArea
import it.neckar.lizergy.model.configuration.components.ScaffoldingProvided
import it.neckar.lizergy.model.configuration.components.SelfAssemblyConfiguration
import it.neckar.lizergy.model.configuration.energy.Capacity
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.Voltage
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedColoredMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedColoredOnlyWhereVisibleMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedNormalMetalRoofTile
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.stumps.EinstellungWechselrichter
import it.neckar.open.formatting.format
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.time.formatHourAndMinutes
import it.neckar.open.unit.si.cm
import it.neckar.open.unit.si.m
import it.neckar.open.unit.si.ms
import it.neckar.processStates.ProcessStateEntry
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes

@Serializable
data class AssemblyPortfolioForPDF(
  @Serializable(with = UuidSerializer::class) override val uuid: Uuid,
  override val projectId: PhotovoltaicsProjectId,
  val projektName: String,

  @Serializable(with = DoubleAsIsoDateTimeSerializer::class) val auftragsDatum: @ms Double?,
  val kundenberater: UserInformation,
  val ersteller: UserInformation?,
  val projektLeiterInformation: UserInformation?,
  val pvMontageDurchInformation: PlannerCompanyInformation,
  val ansprechpartnerDachInformation: UserInformation?,
  val elektroMontageDurchInformation: PlannerCompanyInformation,
  val ansprechpartnerElektroInformation: UserInformation?,

  val customer: Customer,

  override val locationFirstName: String,
  override val locationLastName: String,
  val location: Address,
  override val locationPhone: String,
  override val locationCellphone: String,
  override val locationEmail: String,

  @Serializable(with = DoubleAsIsoDateTimeSerializer::class) val geburtstag: @ms Double?,

  override val anlagenPasswort: String,
  override val einspeiseart: Einspeiseart,
  val einstellungWechselrichter: EinstellungWechselrichter,
  override val wartungsvertrag: Boolean,
  override val geruestVorhanden: String,

  override val pvLeistung: PowerRating,
  override val leerlaufspannung: Voltage,
  override val verteilerNetzbetreiberName: String?,

  override val kalkulierteStunden: Duration,
  override val ueberspannungsschutz: String,
  override val messkonzept: String,

  override val bleibenderZaehler: String,
  override val zusammenZuLegendeZaehlerNummern: List<String>,

  override val slsEinbauen: Boolean,
  override val unterverteilerEinbauen: Boolean,
  override val erdspiessSetzen: Boolean,
  override val waermepumpenAnbindung: Boolean,
  override val internetAnschluss: Boolean,

  override val assemblyPortfolioModuleLayouts: List<Layout>,

  override val wechselrichter: StringSelection,

  override val anzahlOptimierer: Int,
  override val optimierer: String,

  override val speicher: String,

  override val ladestationen: AssemblyPortfolio.CustomWallboxSelection<Ladestation>,
  override val heizstab: HeaterRod,

  override val ersatzstrom: String,
  override val sunnyHomeManagerOderMeter: String,

  override val firstEingespeist: Boolean,
  override val oberleitungZuIsolieren: Boolean,
  override val satAnlageZuVersetzen: Boolean,
  override val antenneEntfernen: Boolean,

  override val besonderheiten: String,
  override val bestandsanlagen: String,
  override val sonstigeArbeiten: String,

  ) : AssemblyPortfolio {

  override val projektLeiter: UserLoginName?
    get() = projektLeiterInformation?.loginName

  override val pvMontageDurch: CompanyCode
    get() = pvMontageDurchInformation.companyCode

  override val ansprechpartnerDach: UserLoginName?
    get() = ansprechpartnerDachInformation?.loginName

  override val elektroMontageDurch: CompanyCode
    get() = elektroMontageDurchInformation.companyCode

  override val ansprechpartnerElektro: UserLoginName?
    get() = ansprechpartnerElektroInformation?.loginName

  val locationFullName: String
    get() = "$locationFirstName $locationLastName"

  val moduleSelection: StringSelection
    get() = StringSelection(assemblyPortfolioModuleLayouts.groupBy { it.module }
      .map { (module, layouts) -> ElementsSelectionEntry(module, layouts.sumOf { it.anzahlModule }) })

  val blechZiegelSelection: StringSelection
    get() = StringSelection(assemblyPortfolioModuleLayouts.groupBy { it.blechZiegel }
      .map { (blechZiegel, layouts) -> ElementsSelectionEntry(blechZiegel, layouts.sumOf { it.anzahlBlechZiegel ?: 0 }) })

  @Serializable
  data class Layout(
    override val id: ModuleLayout.ModuleLayoutId,
    override val name: String,
    override val anzahlModule: Int,
    override val module: String,
    override val montageSystem: String,
    override val montageSystemSuffix: String,
    override val anzahlBlechZiegel: Int?,
    override val blechZiegel: String?,
    override val moduleImKreuzverbund: Int,
    override val aufdachdaemmung: @cm Int?,
    override val verstaerkteSchienen: Boolean,
    override val schwarzeKlemmen: Boolean,
  ) : AssemblyPortfolioModuleLayout

  @Serializable
  data class Ladestation(
    override val id: Wallbox.WallboxId,
    override val name: String,
    override val leistung: Capacity,
    override val drosselung: Boolean,
    override val kabellaenge: @m Double,
    override val montageZeitInklusive: Boolean,
    override val montageZeit: Duration,
  ) : AssemblyPortfolioWallbox {
    fun format(): String {
      return buildString {
        append(name)
        append(" / ")
        append(leistung.format())
        if (drosselung) {
          append(" ")
          append("(Drosselung 11kW)")
        }
        append(" / ")
        append("${kabellaenge.format(maximumFractionDigits = 2)}m DC Kabel")
        if (montageZeitInklusive) {
          append(" / ")
          append("Montagezeit in der AB")
        }
        append(" / ")
        append("Montagezeit ${montageZeit.formatHourAndMinutes()}")
      }
    }
  }

  @Serializable
  data class HeaterRod(
    override val name: String,
    override val leistung: Capacity?,
    override val montageZeitInklusive: Boolean,
    override val montageZeit: Duration?,
  ) : AssemblyPortfolioHeaterRod {
    fun format(): String {
      if (name.isEmpty() || leistung == null) return ""
      return "$name / ${leistung.format()} / ${if (montageZeitInklusive) "/ Montagezeit in der AB " else ""}/ Montagezeit ${montageZeit?.formatHourAndMinutes().orEmpty()}"
    }
  }


  companion object {
    fun getMock(uuid: Uuid = uuid4()): AssemblyPortfolioForPDF {
      return AssemblyPortfolioForPDF(
        uuid = uuid,
        projectId = PhotovoltaicsProjectId.random(),
        projektName = "Projektname",
        auftragsDatum = 0.0,
        kundenberater = UserInformation.NeckarIT,
        ersteller = UserInformation.NeckarIT,
        projektLeiterInformation = UserInformation.NeckarIT,
        pvMontageDurchInformation = PlannerCompanyInformation.NeckarIT,
        ansprechpartnerDachInformation = UserInformation.NeckarIT,
        elektroMontageDurchInformation = PlannerCompanyInformation.NeckarIT,
        ansprechpartnerElektroInformation = UserInformation.NeckarIT,
        customer = Customer.demoWithAddress(uuidFrom("870b1205-9122-4390-a8a6-9a64af6954b4")),
        locationFirstName = "Max",
        locationLastName = "Mustermann",
        location = Address.NeckarIT,
        locationPhone = "012 3456 78",
        locationCellphone = "012 3456 78",
        locationEmail = "max@mustermann.de",
        geburtstag = 0.0,
        anlagenPasswort = "Anlagen Passwort",
        einspeiseart = Einspeiseart.Ueberschuss,
        einstellungWechselrichter = EinstellungWechselrichter.FRE_RSE,
        wartungsvertrag = true,
        geruestVorhanden = "Ja, von FIRMA",
        pvLeistung = PowerRating.wattPeak(12_400.0),
        leerlaufspannung = Voltage.voltage(60.0),
        verteilerNetzbetreiberName = "Muster Stadtwerke",
        kalkulierteStunden = 20.0.hours,
        ueberspannungsschutz = "Typ I & II",
        messkonzept = "Messkonzept",
        bleibenderZaehler = "Zähler 123456789",
        zusammenZuLegendeZaehlerNummern = listOf("123465789", "987654321"),
        slsEinbauen = true,
        unterverteilerEinbauen = true,
        erdspiessSetzen = true,
        waermepumpenAnbindung = true,
        internetAnschluss = true,
        assemblyPortfolioModuleLayouts = listOf(
          Layout(
            id = ModuleLayout.ModuleLayoutId(uuidFrom("e1c51da9-4dc6-409f-82d3-5d5f3ce9dc09")),
            name = "Layout 1", montageSystem = "DaMontageSystem", montageSystemSuffix = "SUFF",
            anzahlModule = 30,
            module = "Modultyp",
            anzahlBlechZiegel = 16,
            blechZiegel = "Blaue Blechziegel",
            moduleImKreuzverbund = 7,
            aufdachdaemmung = 12,
            verstaerkteSchienen = true,
            schwarzeKlemmen = true,
          )
        ),
        wechselrichter = StringSelection(
          listOf(
            ElementsSelectionEntry("Wechselrichter 1", 2),
            ElementsSelectionEntry("Wechselrichter 2", 1)
          )
        ),
        anzahlOptimierer = 3,
        optimierer = "Optimierer",
        speicher = "Batteriekasten 2000 / 4.4 SI",
        ladestationen = AssemblyPortfolio.CustomWallboxSelection(
          listOf(
            ElementsSelectionEntry(
              Ladestation(
                id = Wallbox.WallboxId(uuidFrom("a69160d2-67da-4414-8bc4-6269205770cc")),
                name = "Ldestation A",
                leistung = Capacity.kW(11.0),
                drosselung = true,
                kabellaenge = 5.0,
                montageZeitInklusive = true,
                montageZeit = 90.minutes,
              ),
              1
            )
          )
        ),
        heizstab = HeaterRod("Heizstab 1", Capacity.kW(4.3), true, 25.minutes),
        ersatzstrom = "Ersatzstrom",
        sunnyHomeManagerOderMeter = "Sunny Home Manager OM",
        firstEingespeist = true,
        oberleitungZuIsolieren = true,
        satAnlageZuVersetzen = true,
        antenneEntfernen = true,
        besonderheiten = """
          Besonderheiten:
          - Besonderheit 1
          - Besonderheit 2
          - Besonderheit 3
        """.trimIndent(),
        bestandsanlagen = """
          Bestandsanlagen:
          - Bestandsanlage 1
          - Bestandsanlage 2
          - Bestandsanlage 3
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus euismod, fermentum nunc nec, ultricies nunc. Nullam nec nunc nec nunc.
        """.trimIndent(),
        sonstigeArbeiten = """
          Sonstige Arbeiten:
          - Sonstige Arbeit 1
          - Sonstige Arbeit 2
          - Sonstige Arbeit 3
        """.trimIndent(),
      )
    }
  }
}


fun ResolvedProject.createAssemblyPortfolioForPDF(
  forConfiguration: QuoteConfiguration,
  currentConfigurationProcessState: ProcessStateEntry?,
  currentAssemblyPortfolioProcessState: ProcessStateEntry?,
  userResolver: UserResolver,
  companyResolver: CompanyResolver,
): AssemblyPortfolioForPDF {
  val priceList = forConfiguration.priceList

  fun ResolvedModuleLayout.toPDFLayout(): AssemblyPortfolioForPDF.Layout {
    val moduleLayout = assemblyPortfolio.assemblyPortfolioModuleLayouts.firstOrNull { it.id == id }
    val anzahlBlechziegel = moduleLayout?.anzahlBlechZiegel ?: roof.metalRoofTileConstructionType?.amount
    val blechZiegel = moduleLayout?.blechZiegel ?: when (val metalRoofTileConstructionType = roof.metalRoofTileConstructionType) {
      ResolvedNormalMetalRoofTile, is ResolvedColoredMetalRoofTile -> metalRoofTileConstructionType.format(roof.roofTile?.metalRoofTile?.description)
      is ResolvedColoredOnlyWhereVisibleMetalRoofTile -> "${metalRoofTileConstructionType.formatWithoutAmount(roof.roofTile?.metalRoofTile?.description)} - ${anzahlBlechziegel?.let { metalRoofTileCount - it } ?: "Keine"} Blechziegel verzinkt"
      null -> null
    }
    return AssemblyPortfolioForPDF.Layout(
      id = id,
      name = moduleLayout?.name ?: layoutName,
      anzahlModule = moduleLayout?.anzahlModule ?: modulesCount,
      module = moduleLayout?.module ?: moduleType.description,
      montageSystem = moduleLayout?.montageSystem ?: roof.roofType.description,
      montageSystemSuffix = moduleLayout?.montageSystemSuffix.orEmpty(),
      anzahlBlechZiegel = anzahlBlechziegel ?: if (blechZiegel != null) metalRoofTileCount else null,
      blechZiegel = blechZiegel,
      moduleImKreuzverbund = moduleLayout?.moduleImKreuzverbund ?: horizontalModulesCount,
      aufdachdaemmung = moduleLayout?.aufdachdaemmung ?: roof.isolationThickness,
      verstaerkteSchienen = moduleLayout?.verstaerkteSchienen ?: roof.verstaerktesSchienensystem,
      schwarzeKlemmen = moduleLayout?.schwarzeKlemmen ?: roof.schwarzeKlemmen,
    )
  }

  fun ElementsSelectionEntry<Inverter>.toPDFInverter(index: Int): ElementsSelectionEntry<String> {
    val inverter = assemblyPortfolio.wechselrichter.entries.getOrNull(index)
    return ElementsSelectionEntry(inverter?.element ?: element.format(), inverter?.amount ?: amount)
  }

  fun ElementsSelectionEntry<Wallbox>.toPDFLadestation(): ElementsSelectionEntry<AssemblyPortfolioForPDF.Ladestation> {
    val relevantEntry = assemblyPortfolio.ladestationen.entries.firstOrNull { it.element.id == element.id }
    val relevantWallbox = relevantEntry?.element
    return ElementsSelectionEntry(
      AssemblyPortfolioForPDF.Ladestation(
        id = element.id,
        name = relevantWallbox?.name ?: element.description,
        leistung = relevantWallbox?.leistung ?: element.chargingCapacity.capacity(),
        drosselung = forConfiguration.wallboxSelection.throttled,
        kabellaenge = relevantWallbox?.kabellaenge ?: element.cableLength.length(),
        montageZeitInklusive = relevantWallbox?.montageZeitInklusive ?: true,
        montageZeit = relevantWallbox?.montageZeit ?: priceList.wallboxInstallationPrice.workingTime,
      ),
      relevantEntry?.amount ?: amount
    )
  }

  fun AssemblyPortfolio.HeaterRod.toPDFHeaterRod(): AssemblyPortfolioForPDF.HeaterRod {
    val heaterRod = forConfiguration.facilityConfiguration.heaterRod
    return AssemblyPortfolioForPDF.HeaterRod(
      name = name ?: heaterRod?.description.orEmpty(),
      leistung = leistung ?: heaterRod?.heatingCapacity?.capacity(),
      montageZeitInklusive = montageZeitInklusive,
      montageZeit = montageZeit ?: heaterRod?.let { priceList[heaterRod].workingTime },
    )
  }

  val sunnyHomeManagerOderMeter = buildList {
    if (forConfiguration.facilityConfiguration.batteryConfiguration != null || forConfiguration.electricityWorkConfiguration.sunnyHomeManager) add("Sunny Home Manager 2.0")
    if (forConfiguration.electricityWorkConfiguration.smaEnergyMeter) add("SMA-E-Meter")
  }.joinToString(" + ").nullIfBlank()

  val ueberspannungsSchutzType = buildString {
    append("Überspannungsschutz DC ")
    if (forConfiguration.assemblyConfiguration.externerBlitzschutzVorhanden) {
      append("Typ 1 & ")
    }
    append("Typ 2")
    if (forConfiguration.assemblyConfiguration.externerBlitzschutzVorhanden) {
      append(" [16mm²]")
    } else {
      append(" [6mm²]")
    }
  }

  return AssemblyPortfolioForPDF(
    uuid = uuid,
    projectId = projectId,
    projektName = forConfiguration.configurationName,
    auftragsDatum = currentConfigurationProcessState?.assignedAt,
    kundenberater = maintainerInformation,
    ersteller = currentAssemblyPortfolioProcessState?.assignedTo?.let { userResolver[it] },
    projektLeiterInformation = assemblyPortfolio.projektLeiterInformation,
    pvMontageDurchInformation = assemblyPortfolio.pvMontageDurchInformation ?: companyResolver[forConfiguration.earningsDistribution.montageDach.company],
    ansprechpartnerDachInformation = assemblyPortfolio.ansprechpartnerDachInformation,
    elektroMontageDurchInformation = assemblyPortfolio.elektroMontageDurchInformation ?: companyResolver[forConfiguration.earningsDistribution.elektroInstallation.company],
    ansprechpartnerElektroInformation = assemblyPortfolio.ansprechpartnerElektroInformation,
    customer = customer,
    locationFirstName = assemblyPortfolio.locationFirstName ?: customer.firstName,
    locationLastName = assemblyPortfolio.locationLastName ?: customer.lastName,
    location = forConfiguration.location.address,
    locationPhone = assemblyPortfolio.locationPhone ?: customer.phone.orEmpty(),
    locationCellphone = assemblyPortfolio.locationCellphone ?: customer.cellphone.orEmpty(),
    locationEmail = assemblyPortfolio.locationEmail ?: customer.email.orEmpty(),
    geburtstag = forConfiguration.facilityOperator1.birthday,
    anlagenPasswort = assemblyPortfolio.anlagenPasswort ?: "",
    einspeiseart = assemblyPortfolio.einspeiseart ?: forConfiguration.einspeiseart,
    einstellungWechselrichter = gridAssessment.einstellungWechselrichter,
    wartungsvertrag = assemblyPortfolio.wartungsvertrag ?: (forConfiguration.facilityConfiguration.onlineMonitoring == ConfiguredOptionality.Selected),
    geruestVorhanden = assemblyPortfolio.geruestVorhanden ?: forConfiguration.formatGeruest(companyResolver),
    pvLeistung = assemblyPortfolio.pvLeistung ?: forConfiguration.kiloWattPeak,
    leerlaufspannung = assemblyPortfolio.leerlaufspannung ?: forConfiguration.moduleLayouts.totalOffLoadVoltage,
    verteilerNetzbetreiberName = gridAssessment.verteilerNetzbetreiberName,
    kalkulierteStunden = assemblyPortfolio.kalkulierteStunden ?: (forConfiguration.calculatedAssemblyTime + forConfiguration.calculatedScaffoldingAssemblyTime),
    ueberspannungsschutz = assemblyPortfolio.ueberspannungsschutz ?: ueberspannungsSchutzType,
    messkonzept = assemblyPortfolio.messkonzept.orEmpty(),
    bleibenderZaehler = assemblyPortfolio.bleibenderZaehler ?: forConfiguration.zaehlerNummer,
    zusammenZuLegendeZaehlerNummern = assemblyPortfolio.zusammenZuLegendeZaehlerNummern ?: forConfiguration.electricityWorkConfiguration.zusammenZuLegendeZaehlerNummern,
    slsEinbauen = assemblyPortfolio.slsEinbauen ?: forConfiguration.electricityWorkConfiguration.slsNachruesten,
    unterverteilerEinbauen = assemblyPortfolio.unterverteilerEinbauen ?: forConfiguration.electricityWorkConfiguration.einbauUnterverteiler,
    erdspiessSetzen = assemblyPortfolio.erdspiessSetzen ?: forConfiguration.facilityConfiguration.erdspiessSetzen,
    waermepumpenAnbindung = assemblyPortfolio.waermepumpenAnbindung ?: forConfiguration.facilityConfiguration.waermepumpenanbindung,
    internetAnschluss = assemblyPortfolio.internetAnschluss ?: (forConfiguration.facilityConfiguration.integrateInverterIntoNetwork == ConfiguredOptionality.Selected),
    assemblyPortfolioModuleLayouts = forConfiguration.moduleLayouts.validElements.map { it.toPDFLayout() },
    wechselrichter = StringSelection(forConfiguration.facilityConfiguration.inverterSelection.entriesNonEmpty.mapIndexed { index, entry -> entry.toPDFInverter(index) }),
    anzahlOptimierer = assemblyPortfolio.anzahlOptimierer ?: forConfiguration.facilityConfiguration.numberOfOptimizers,
    optimierer = assemblyPortfolio.optimierer.orEmpty(),
    speicher = assemblyPortfolio.speicher ?: forConfiguration.facilityConfiguration.batteryConfiguration?.format().orEmpty(),
    ladestationen = AssemblyPortfolio.CustomWallboxSelection(forConfiguration.wallboxSelection.entriesNonEmpty.map { it.toPDFLadestation() }),
    heizstab = assemblyPortfolio.heizstab.toPDFHeaterRod(),
    ersatzstrom = assemblyPortfolio.ersatzstrom ?: forConfiguration.electricityWorkConfiguration.independenceManager?.description.orEmpty(),
    sunnyHomeManagerOderMeter = assemblyPortfolio.sunnyHomeManagerOderMeter ?: sunnyHomeManagerOderMeter ?: "Nichts",
    firstEingespeist = assemblyPortfolio.firstEingespeist,
    oberleitungZuIsolieren = assemblyPortfolio.oberleitungZuIsolieren ?: forConfiguration.assemblyConfiguration.dachstaenderIsolierung,
    satAnlageZuVersetzen = assemblyPortfolio.satAnlageZuVersetzen ?: forConfiguration.assemblyConfiguration.moveSatelliteDish,
    antenneEntfernen = assemblyPortfolio.antenneEntfernen ?: forConfiguration.assemblyConfiguration.removeAntenna,
    besonderheiten = assemblyPortfolio.besonderheiten ?: forConfiguration.besonderheitenForAssemblyPortfolio,
    bestandsanlagen = assemblyPortfolio.bestandsanlagen ?: forConfiguration.bestandsanlagesForAssemblyPortfolio,
    sonstigeArbeiten = assemblyPortfolio.sonstigeArbeiten ?: forConfiguration.sonstigesForAssemblyPortfolio,
  )
}

fun QuoteConfiguration.formatGeruest(companyResolver: CompanyResolver): String {
  return when (val assemblyStatus = assemblyConfiguration.assemblyStatus) {
    is ScaffoldingArea -> assemblyStatus.scaffoldingArea.let { if (it != null && it > 0) "$it m²" else "Nein" }
    ScaffoldingProvided -> "Ja, durch ${companyResolver[earningsDistribution.montageGeruest.company].name}"
    is SelfAssemblyConfiguration -> "Ja, bauseits"
  }
}
