package components.project.customer

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.Address
import it.neckar.customer.Customer
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.kotlin.lang.nullIfEmpty
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser

/**
 * A form that edits the customer detail of a project
 */
val EditProjectCustomerFromUrl: FC<Props> = fc("EditProjectCustomerFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Kunde:in", allProcessStatesForComponents?.let { project?.getDisplayName(it) })

  busyIfNull(project) { loadedProject ->
    EditProjectCustomer {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val EditProjectCustomer: FC<EditProjectCustomerProps> = fc("EditProjectCustomer") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()

  val customerToSave = useState(project.customer)

  val projectToSave = useMemo(project, customerToSave.value) {
    project.copy(
      customer = customerToSave.value,
    )
  }

  val okAction = {
    UiActions.saveProject(projectToSave)
    navigate.back()
  }


  div("my-4") {
    onEnter(okAction)

    EditCustomer {
      attrs {
        this.customerToSave = customerToSave
      }
    }

    formButtons(
      cancelAction = { navigate.back() },
      okAction = { okAction() },
    )
  }

}

val EditCustomer: FC<EditCustomerProps> = fc("EditCustomer") { props ->
  val customerToSave = props::customerToSave.getNotNull()

  val customer = customerToSave.value

  val address = customer.address

  val firstName = useState(customer.firstName)
  val lastName = useState(customer.lastName)

  val addressStreet = useState(address.street)
  val addressHouseNumber = useState(address.houseNumber)
  val addressZipCode = useState(address.zipCode)
  val addressCity = useState(address.city)

  val phone = useState(customer.phone.orEmpty())
  val cellphone = useState(customer.cellphone.orEmpty())
  val email = useState(customer.email.orEmpty())

  val companyName = useState(customer.company.orEmpty())
  val customerWish = useState(customer.customerWish.orEmpty())

  val addressToSave = useMemo(addressStreet.value, addressHouseNumber.value, addressZipCode.value, addressCity.value) {
    Address(addressStreet.value, addressHouseNumber.value, addressZipCode.value, addressCity.value)
  }


  useMemo(firstName.value, lastName.value, addressToSave, phone.value, cellphone.value, email.value, companyName.value, customerWish.value) {
    customerToSave.setter(
      Customer(
        id = customer.id,
        firstName = firstName.value,
        lastName = lastName.value,
        address = addressToSave,
        phone = phone.value.nullIfEmpty(),
        cellphone = cellphone.value.nullIfEmpty(),
        email = email.value.nullIfEmpty(),
        company = companyName.value.nullIfEmpty(),
        customerWish = customerWish.value.nullIfEmpty(),
      )
    )
  }


  div {

    h1 {
      +"Kunde:in"
    }

    customerForm(
      firstName = firstName,
      lastName = lastName,
      addressStreet = addressStreet,
      addressHouseNumber = addressHouseNumber,
      addressZipCode = addressZipCode,
      addressCity = addressCity,
      phone = phone,
      cellphone = cellphone,
      email = email,
      companyName = companyName,
      editableStatus = Editable,
    )

    div("my-5") {
      floatingInputArea(
        valueAndSetter = customerWish,
        fieldName = "customerWish",
        title = "Kundenwunsch Zusammenfassung",
      ) {
        attrs {
          addClass("height-300px")
        }
      }
    }

  }
}


external interface EditProjectCustomerProps : Props {
  var project: ResolvedProject
}

external interface EditCustomerProps : Props {
  var customerToSave: StateInstance<Customer>
}

