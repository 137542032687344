@file: UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * "Schwierigkeit der Montage"
 */
@Serializable
data class AssemblyDifficulty(
  val id: AssemblyDifficultyId,

  val description: String,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  /**
   * An id for an assembly difficulty
   */
  @Serializable
  data class AssemblyDifficultyId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): AssemblyDifficultyId {
        return AssemblyDifficultyId(randomUuid4())
      }
    }
  }
}
