@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.energy.Capacity
import it.neckar.open.formatting.intFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.unit.si.kW
import it.neckar.open.unit.si.m
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents a type of wallbox
 */
@Serializable
data class Wallbox(
  val id: WallboxId,

  val description: String,

  val details: String?,
  /**
   * The charging power rating
   */
  val chargingCapacity: ChargingCapacity,

  val cableLength: CableLength,

  val type: Type = Type.Normal,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  val isSmartWallBox: Boolean
    get() = type == Type.Smart

  /**
   * Returns true if "Netzvoranfrage" is required
   */
  val requiresInquiry: Boolean
    get() = chargingCapacity.capacity().kW > 11.0

  fun format(i18nConfiguration: I18nConfiguration): String {
    return "$description, ${chargingCapacity.capacity().format(i18nConfiguration)}, ${cableLength.length()} m"
  }

  enum class Type {
    Normal,
    Smart,
  }

  enum class ChargingCapacity {
    KW74,
    KW11,
    KW22,

    ;

    fun capacity(): @kW Capacity {
      return when (this) {
        KW74 -> Capacity(7.4)
        KW11 -> Capacity(11.0)
        KW22 -> Capacity(22.0)
      }
    }
  }

  enum class CableLength {
    Meters5,
    Meters75,
    Meters10,

    ;

    fun length(): @m Double {
      return when (this) {
        Meters5 -> 5.0
        Meters75 -> 7.5
        Meters10 -> 10.0
      }
    }

    fun format(i18nConfiguration: I18nConfiguration): String {
      return "${intFormat.format(length(), i18nConfiguration)} m"
    }
  }

  /**
   * An id for a wallbox type
   */
  @Serializable
  data class WallboxId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): WallboxId {
        return WallboxId(randomUuid4())
      }
    }
  }
}
