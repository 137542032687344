package it.neckar.financial.quote

import it.neckar.open.collections.fastForEach
import it.neckar.open.kotlin.lang.percent
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.financial.currency.sum
import kotlinx.serialization.Serializable

@Serializable
data class SumsForVATs(private val map: Map<ValueAddedTax, PriceWithProfit>) {
  constructor(sumsForVATs: List<SumsForVATs>) : this(mutableMapOf<ValueAddedTax, PriceWithProfit>().apply {
    sumsForVATs.fastForEach { sumsForVATs ->
      sumsForVATs.map.forEach {
        put(it.key, getOrElse(it.key) { PriceWithProfit.Zero } + it.value)
      }
    }
  })

  val net: PriceWithProfit
    get() = map.values.sum()

  val vats: List<ValueAddedTax>
    get() = map.keys.sortedBy { it.vat }

  val prices: List<PriceWithProfit>
    get() = map.values.toList()

  operator fun get(valueAddedTax: ValueAddedTax): PriceWithProfit {
    return map[valueAddedTax] ?: PriceWithProfit.Zero
  }

  fun toGrossPrices(): PricesForVATs {
    return mapToMoney { it.key to (it.value.sellingPrice * (it.key + 100.percent)) }
  }

  fun getVatPrices(): PricesForVATs {
    return mapToMoney { it.key to (it.value.sellingPrice * it.key) }
  }

  fun mapNet(function: (Map.Entry<ValueAddedTax, PriceWithProfit>) -> Pair<ValueAddedTax, PriceWithProfit>): SumsForVATs {
    return SumsForVATs(map.map { function(it) }.toMap())
  }

  fun mapToMoney(function: (Map.Entry<ValueAddedTax, PriceWithProfit>) -> Pair<ValueAddedTax, Money>): PricesForVATs {
    return PricesForVATs(map.map { function(it) }.toMap())
  }

  fun mapNetValues(function: (PriceWithProfit) -> PriceWithProfit): SumsForVATs {
    return SumsForVATs(map.mapValues { function(it.value) }.toMap())
  }

  fun mapNetValuesToMoney(function: (PriceWithProfit) -> Money): PricesForVATs {
    return PricesForVATs(map.mapValues { function(it.value) }.toMap())
  }

  fun forEachNet(function: (Pair<ValueAddedTax, PriceWithProfit>) -> Unit) {
    map.toList().sortedBy { it.first.vat }.fastForEach { function(it) }
  }

  fun forEachNetValue(function: (PriceWithProfit) -> Unit) {
    map.toList().sortedBy { it.first.vat }.fastForEach { function(it.second) }
  }

  fun allNet(function: (Map.Entry<ValueAddedTax, PriceWithProfit>) -> Boolean): Boolean {
    return map.all(function)
  }

  fun allNetValues(function: (PriceWithProfit) -> Boolean): Boolean {
    return map.all { function(it.value) }
  }


  companion object {
    val empty: SumsForVATs = SumsForVATs(emptyMap())
  }

}
