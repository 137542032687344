package components.assemblyPortfolio

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUsersAndCompanies

internal val PlandatenOverview: FC<PlandatenOverviewProps> = fc("PlandatenOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val companyResolver = useSelectCompanyResolver()
  val usersAndCompanies = useSelectUsersAndCompanies()

  val project = props::project.safeGet()
  val configuration = props::currentConfiguration.safeGet()
  val assemblyPortfolio = project.assemblyPortfolio
  val montageDachCompany = companyResolver[configuration.earningsDistribution.montageDach.company]
  val elektroInstallationCompany = companyResolver[configuration.earningsDistribution.elektroInstallation.company]

  val projektLeiter = useState(assemblyPortfolio.projektLeiterInformation)
  val pvMontageDurchInformation = useState(assemblyPortfolio.pvMontageDurchInformation ?: montageDachCompany)
  val ansprechpartnerDachInformation = useState(assemblyPortfolio.ansprechpartnerDachInformation)
  val elektroMontageDurchInformation = useState(assemblyPortfolio.elektroMontageDurchInformation ?: elektroInstallationCompany)
  val ansprechpartnerElektroInformation = useState(assemblyPortfolio.ansprechpartnerElektroInformation)


  useMemo(
    projektLeiter.value,
    pvMontageDurchInformation.value,
    ansprechpartnerDachInformation.value,
    elektroMontageDurchInformation.value,
    ansprechpartnerElektroInformation.value
  ) {
    if (projektLeiter.value != assemblyPortfolio.projektLeiterInformation ||
      pvMontageDurchInformation.value != assemblyPortfolio.pvMontageDurchInformation ||
      ansprechpartnerDachInformation.value != assemblyPortfolio.ansprechpartnerDachInformation ||
      elektroMontageDurchInformation.value != assemblyPortfolio.elektroMontageDurchInformation ||
      ansprechpartnerElektroInformation.value != assemblyPortfolio.ansprechpartnerElektroInformation
    ) {
      UiActions.saveAssemblyPortfolio(
        assemblyPortfolio.copy(
          projektLeiterInformation = projektLeiter.value,
          pvMontageDurchInformation = if (pvMontageDurchInformation.value != montageDachCompany) pvMontageDurchInformation.value else null,
          ansprechpartnerDachInformation = ansprechpartnerDachInformation.value,
          elektroMontageDurchInformation = if (elektroMontageDurchInformation.value != elektroInstallationCompany) elektroMontageDurchInformation.value else null,
          ansprechpartnerElektroInformation = ansprechpartnerElektroInformation.value,
        )
      )
    }
  }


  div("row my-4") {
    div("col-lg-6") {
      h3("my-3") { +"Plandaten" }

      div("mb-3") {
        floatingSelectNullable(
          valueAndSetter = projektLeiter,
          idProvider = { it?.loginName?.value ?: "NONE" },
          formatter = { it?.editorName ?: "-" },
          fieldName = "projektLeiter",
          title = "Projektleiter",
          availableOptionsWithoutNull = usersAndCompanies.usersForCompany(project.sellingCompany),
        )
      }

      div("row gx-0") {
        div("col-lg-6") {
          floatingSelect(
            valueAndSetter = pvMontageDurchInformation,
            idProvider = { it.companyCode.value },
            formatter = { it.name },
            fieldName = "pvMontageDurch",
            title = "PV-Montage",
            availableOptions = usersAndCompanies.activeCompanies(loggedInUser),
          ) {
            attrs {
              mergedRight()
              mergedBelow()
            }
          }
        }
        div("col-lg-6") {
          floatingSelectNullable(
            valueAndSetter = ansprechpartnerDachInformation,
            idProvider = { it?.loginName?.value ?: "NONE" },
            formatter = { it?.editorName ?: "-" },
            fieldName = "ansprechpartnerDach",
            title = "Ansprechpartner Dach",
            availableOptionsWithoutNull = usersAndCompanies.usersForCompany(pvMontageDurchInformation.value.companyCode),
          ) {
            attrs {
              mergedLeft()
              mergedBelow()
            }
          }
        }
        div("col-lg-6") {
          floatingSelect(
            valueAndSetter = elektroMontageDurchInformation,
            idProvider = { it.companyCode.value },
            formatter = { it.name },
            fieldName = "elektroMontageDurch",
            title = "Elektromontage",
            availableOptions = usersAndCompanies.activeCompanies(loggedInUser),
          ) {
            attrs {
              mergedRight()
              mergedAbove()
            }
          }
        }
        div("col-lg-6") {
          floatingSelectNullable(
            valueAndSetter = ansprechpartnerElektroInformation,
            idProvider = { it?.loginName?.value ?: "NONE" },
            formatter = { it?.editorName ?: "-" },
            fieldName = "ansprechpartnerElektro",
            title = "Ansprechpartner Elektro",
            availableOptionsWithoutNull = usersAndCompanies.usersForCompany(elektroMontageDurchInformation.value.companyCode),
          ) {
            attrs {
              mergedLeft()
              mergedAbove()
            }
          }
        }
      }
    }
  }
}

external interface PlandatenOverviewProps : Props {
  var project: ResolvedProject
  var currentConfiguration: QuoteConfiguration
}
