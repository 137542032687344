package it.neckar.lizergy.model.stumps

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable

@Serializable
data class Netzbetreiber(
  @Serializable(with = UuidSerializer::class) override val uuid: Uuid,
  val name: String,
) : HasUuid {
  companion object {
    val availableLizergyNetzbetreiber: List<Netzbetreiber> = listOf(
      Netzbetreiber(uuidFrom("ff6e018a-f941-4c5d-9066-4ad252108e3b"), "NetzeBW"),
      Netzbetreiber(uuidFrom("8579beac-f16b-4804-9326-e92c28444c2d"), "Stadtwerke Freudenstadt"),
      Netzbetreiber(uuidFrom("d2983c14-5c0c-4c59-a55d-abc6d0ec1039"), "E-Werk Mittelbaden"),
      Netzbetreiber(uuidFrom("e75f2f69-7bd4-44e3-891b-81a01414e917"), "ED Netze"),
      Netzbetreiber(uuidFrom("b9d023ce-3a1b-45fb-b0c6-ff79bdf5e9cb"), "Gemeindewerke Baiersbronn"),
      Netzbetreiber(uuidFrom("0d711349-4a00-4ef0-b9c2-8fe2f960d521"), "Stadtwerke Tübingen"),
      Netzbetreiber(uuidFrom("3299ebc7-4a1e-4ec0-9f00-f81edac6babb"), "Energieversorgung Rottenburg"),
      Netzbetreiber(uuidFrom("22571a3c-e1c7-48f0-893f-44b0d12c8c44"), "Energieversorgung Trossingen"),
      Netzbetreiber(uuidFrom("6c716f69-a9e2-4afd-8ed7-ed6c22966bf3"), "FairNetz"),
      Netzbetreiber(uuidFrom("8cda0479-63e7-4754-a5fc-47da6ebf7d5e"), "Stadtwerke VS"),
      Netzbetreiber(uuidFrom("6d8998e8-4a76-46f0-92cd-1f8cd80ba718"), "Netze Mittelbaden"),
      Netzbetreiber(uuidFrom("a7c96272-d2ad-4f95-a3d4-a7ef069d5df7"), "NHF Netze Heilbronn"),
      Netzbetreiber(uuidFrom("52186393-51be-4628-aa40-d8984464def3"), "Stadtwerke Altensteig (SWA)"),
      Netzbetreiber(uuidFrom("d34a8617-37d9-4597-b9f8-0448af4cb59d"), "Stadtwerke Bad-Wildbad"),
      Netzbetreiber(uuidFrom("789f2baf-8074-454c-b9a7-5fb2fb7bbc1d"), "Stadtwerke Balingen"),
      Netzbetreiber(uuidFrom("1ff1389e-0fb7-4195-b448-8647b25bd024"), "Albstadtwerke"),
      Netzbetreiber(uuidFrom("b285a166-4e5d-4912-9ec7-ba8506045127"), "Stadtwerke Mössingen"),
      Netzbetreiber(uuidFrom("918a1f53-9cac-4659-912a-81981244a523"), "Stadtwerke Nürtingen"),
      Netzbetreiber(uuidFrom("0cd99e0e-3896-42b7-8e51-e27acb021ac9"), "Stadtwerke Pforzheim"),
      Netzbetreiber(uuidFrom("d41ac952-55e2-49cc-b6ed-e179f7b3f2cb"), "Stadtwerke Radolfzell"),
      Netzbetreiber(uuidFrom("df5c53e0-c69b-4605-8f6c-8b0aa207fe1e"), "Stadtwerke Sindelfingen"),
      Netzbetreiber(uuidFrom("8c42cdcc-9d13-465a-80d6-a4c7b062bc9a"), "Stadtwerke Neu-Ulm"),
      Netzbetreiber(uuidFrom("b27c2e23-b31f-418b-af6f-40df1b8f4306"), "Stromgesellschaft Hechingen"),
      Netzbetreiber(uuidFrom("1bc0a42f-6ccd-4d89-8310-10906f01f14f"), "Stromversorgung Sulz"),
      Netzbetreiber(uuidFrom("c4d73826-d7ca-4f01-9f46-7f5bfcb4a740"), "Stuttgart Netze"),
      Netzbetreiber(uuidFrom("1180c5fc-4c95-41ec-94bc-ee8f6a2e8914"), "Syna"),
      Netzbetreiber(uuidFrom("238e95b0-4f0a-46fa-aa4d-825c709e6522"), "Thüga Netze"),
    )
  }
}
