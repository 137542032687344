package components.company.user

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.company.user.UserInformation
import react.*
import react.dom.*
import router.useDocumentTitle
import router.useUserFromUrl
import store.hooks.useRequireCompanyForLoggedInUser

/**
 * Change password form
 */
val UserOverviewFromUrl: FC<Props> = fc("UserOverviewFromUrl") {
  val userInformation = useUserFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Nutzerübersicht ${userInformation.editorName}")

  UserOverview {
    attrs {
      this.user = userInformation
    }
  }
}

val UserOverview: FC<UserOverviewProps> = fc("UserOverview") { props ->
  val user = props::user.safeGet()


  div("row") {

    div("col-6") {
      ChangeUserInfoForm {
        attrs {
          userToEdit = user
        }
      }
    }

    div("col-6") {
      ChangePasswordForm {
        attrs {
          loginName = user.loginName
        }
      }
    }

  }
}

external interface UserOverviewProps : Props {
  var user: UserInformation
}
