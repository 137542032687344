package services.storage.http

import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.stumps.AdvanceInvoice
import it.neckar.lizergy.model.stumps.AssemblyBasement
import it.neckar.lizergy.model.stumps.AssemblyRoof
import it.neckar.lizergy.model.stumps.Documentation
import it.neckar.lizergy.model.stumps.FinalAccount
import it.neckar.lizergy.model.stumps.FinishingUp
import it.neckar.lizergy.model.stumps.GridAssessment
import it.neckar.lizergy.model.stumps.OrderSpecialMaterial
import it.neckar.lizergy.model.stumps.StartupOperations
import it.neckar.lizergy.model.stumps.SwitchMeterBox
import kotlinx.serialization.Serializable
import serialized.ModuleLayoutsConfiguration
import serialized.SerializedAssemblyPortfolio
import serialized.SerializedBlueprint
import serialized.SerializedPhotovoltaicsConfiguration
import serialized.SerializedProject
import serialized.unResolve

@Serializable
data class SendResolvedProjectRequest(
  val photovoltaicsProject: SerializedProject,
  val blueprint: SerializedBlueprint,
  val configurations: List<SentConfiguration>,
  val orderSpecialMaterial: OrderSpecialMaterial,
  val gridAssessment: GridAssessment,
  val assemblyPortfolio: SerializedAssemblyPortfolio,
  val advanceInvoice: AdvanceInvoice,
  val assemblyRoof: AssemblyRoof,
  val assemblyBasement: AssemblyBasement,
  val switchMeterBox: SwitchMeterBox,
  val startupOperations: StartupOperations,
  val finishingUp: FinishingUp,
  val finalAccount: FinalAccount,
  val documentation: Documentation,
  val verification: Verification?,
) {
  constructor(resolvedProject: ResolvedProject) : this(
    photovoltaicsProject = resolvedProject.unResolve(),
    blueprint = resolvedProject.blueprint.unResolve(),
    configurations = resolvedProject.quoteConfigurations.elements.map {
      SentConfiguration(
        configuration = it.unResolve(),
        moduleLayouts = it.moduleLayouts.unResolve(),
        currentQuoteSnapshot = it.currentQuoteSnapshot,
      )
    },
    orderSpecialMaterial = resolvedProject.orderSpecialMaterial,
    gridAssessment = resolvedProject.gridAssessment,
    assemblyPortfolio = resolvedProject.assemblyPortfolio.unResolve(),
    advanceInvoice = resolvedProject.advanceInvoice,
    assemblyRoof = resolvedProject.assemblyRoof,
    assemblyBasement = resolvedProject.assemblyBasement,
    switchMeterBox = resolvedProject.switchMeterBox,
    startupOperations = resolvedProject.startupOperations,
    finishingUp = resolvedProject.finishingUp,
    finalAccount = resolvedProject.finalAccount,
    documentation = resolvedProject.documentation,
    verification = resolvedProject.verification,
  )

  @Serializable
  data class SentConfiguration(
    val configuration: SerializedPhotovoltaicsConfiguration,
    val moduleLayouts: ModuleLayoutsConfiguration,
    val currentQuoteSnapshot: QuoteSnapshot?,
  )
}
