package it.neckar.lizergy.model.assemblyPortfolio

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuid4
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio.StringSelection
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.Voltage
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlin.time.Duration

@Serializable
data class ResolvedAssemblyPortfolio(
  @Serializable(with = UuidSerializer::class) override val uuid: Uuid,
  override val projectId: PhotovoltaicsProjectId,

  val projektLeiterInformation: UserInformation?,
  val pvMontageDurchInformation: PlannerCompanyInformation?,
  val ansprechpartnerDachInformation: UserInformation?,
  val elektroMontageDurchInformation: PlannerCompanyInformation?,
  val ansprechpartnerElektroInformation: UserInformation?,

  override val locationFirstName: String?,
  override val locationLastName: String?,
  override val locationPhone: String?,
  override val locationCellphone: String?,
  override val locationEmail: String?,

  override val anlagenPasswort: String?,
  override val einspeiseart: Einspeiseart?,
  override val wartungsvertrag: Boolean?,
  override val geruestVorhanden: String?,

  override val pvLeistung: PowerRating?,
  override val leerlaufspannung: Voltage?,
  override val verteilerNetzbetreiberName: String?,

  override val kalkulierteStunden: Duration?,
  override val ueberspannungsschutz: String?,
  override val messkonzept: String?,

  override val bleibenderZaehler: String?,
  override val zusammenZuLegendeZaehlerNummern: List<String>?,

  override val slsEinbauen: Boolean?,
  override val unterverteilerEinbauen: Boolean?,
  override val erdspiessSetzen: Boolean?,
  override val waermepumpenAnbindung: Boolean?,
  override val internetAnschluss: Boolean?,

  override val assemblyPortfolioModuleLayouts: List<AssemblyPortfolio.Layout>,

  override val wechselrichter: StringSelection,

  override val anzahlOptimierer: Int?,
  override val optimierer: String?,

  override val speicher: String?,

  override val ladestationen: AssemblyPortfolio.CustomWallboxSelection<AssemblyPortfolio.Ladestation>,
  override val heizstab: AssemblyPortfolio.HeaterRod,

  override val ersatzstrom: String?,
  override val sunnyHomeManagerOderMeter: String?,

  override val firstEingespeist: Boolean,
  override val oberleitungZuIsolieren: Boolean?,
  override val satAnlageZuVersetzen: Boolean?,
  override val antenneEntfernen: Boolean?,

  override val besonderheiten: String?,
  override val bestandsanlagen: String?,
  override val sonstigeArbeiten: String?,

  ) : AssemblyPortfolio {

  override val projektLeiter: UserLoginName?
    get() = projektLeiterInformation?.loginName

  override val pvMontageDurch: CompanyCode?
    get() = pvMontageDurchInformation?.companyCode

  override val ansprechpartnerDach: UserLoginName?
    get() = ansprechpartnerDachInformation?.loginName

  override val elektroMontageDurch: CompanyCode?
    get() = elektroMontageDurchInformation?.companyCode

  override val ansprechpartnerElektro: UserLoginName?
    get() = ansprechpartnerElektroInformation?.loginName

  companion object {
    fun getEmpty(forProject: PhotovoltaicsProjectId, uuid: Uuid = uuid4()): ResolvedAssemblyPortfolio {
      return ResolvedAssemblyPortfolio(
        uuid = uuid,
        projectId = forProject,
        projektLeiterInformation = null,
        ansprechpartnerDachInformation = null,
        pvMontageDurchInformation = null,
        ansprechpartnerElektroInformation = null,
        elektroMontageDurchInformation = null,
        locationFirstName = null,
        locationLastName = null,
        locationPhone = null,
        locationCellphone = null,
        locationEmail = null,
        anlagenPasswort = null,
        einspeiseart = null,
        wartungsvertrag = null,
        geruestVorhanden = null,
        pvLeistung = null,
        leerlaufspannung = null,
        verteilerNetzbetreiberName = null,
        kalkulierteStunden = null,
        ueberspannungsschutz = null,
        messkonzept = null,
        bleibenderZaehler = null,
        zusammenZuLegendeZaehlerNummern = null,
        slsEinbauen = null,
        unterverteilerEinbauen = null,
        erdspiessSetzen = null,
        waermepumpenAnbindung = null,
        internetAnschluss = null,
        assemblyPortfolioModuleLayouts = emptyList(),
        wechselrichter = StringSelection.empty(),
        anzahlOptimierer = null,
        optimierer = null,
        speicher = null,
        ladestationen = AssemblyPortfolio.CustomWallboxSelection.empty(),
        heizstab = AssemblyPortfolio.HeaterRod.getEmpty(),
        ersatzstrom = null,
        sunnyHomeManagerOderMeter = null,
        firstEingespeist = false,
        oberleitungZuIsolieren = null,
        satAnlageZuVersetzen = null,
        antenneEntfernen = null,
        besonderheiten = null,
        bestandsanlagen = null,
        sonstigeArbeiten = null,
      )
    }
  }
}
