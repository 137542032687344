package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.processStates.AllProcessStatesForComponents
import it.neckar.processStates.ProcessStatesForComponent
import store.PlannerClientState

data class AllProcessStatesLoadedAction(val loadedProcessStates: List<ProcessStatesForComponent>) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(allProcessStatesForComponents = AllProcessStatesForComponents(this@AllProcessStatesLoadedAction.loadedProcessStates))
  }
}
