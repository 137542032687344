package serialized

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.ElementsSelection
import it.neckar.lizergy.model.ElementsSelectionEntry
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedWallboxSelection
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox.WallboxId
import it.neckar.lizergy.model.configuration.quote.builder.WallboxSelection
import it.neckar.lizergy.model.configuration.quote.builder.WallboxSelection.WallboxSelectionId
import it.neckar.lizergy.model.price.ProductResolver
import kotlinx.serialization.Serializable

/**
 * Represents the selection of multiple [WallboxId]s
 */
@Serializable
data class WallboxIdSelection(
  override val id: WallboxSelectionId = WallboxSelectionId.random(),
  override val entries: List<ElementsSelectionEntry<WallboxId>>,
  override val throttled: Boolean = false,
) : WallboxSelection, ElementsSelection<WallboxId> {

  override val uuid: Uuid
    get() = id.uuid

  override fun WallboxId.format(): String {
    return this.format()
  }

  /**
   * Creates a new [WallboxIdSelection] with the updated amount for the given [WallboxId]
   */
  fun with(wallboxId: WallboxId, amount: Int): WallboxIdSelection {
    val entriesMap = entries.associateBy {
      it.element
    }.toMutableMap()

    if (amount > 0) {
      entriesMap[wallboxId] = ElementsSelectionEntry(wallboxId, amount)
    } else {
      entriesMap.remove(wallboxId)
    }

    return copy(entries = entriesMap.values.toList())
  }

  fun resolve(productResolver: ProductResolver): ResolvedWallboxSelection {
    return ResolvedWallboxSelection(
      id = id,
      entries = entriesNonEmpty.map {
        ElementsSelectionEntry(productResolver[it.element], it.amount)
      },
      throttled = throttled,
    )
  }

  fun duplicate(): WallboxIdSelection {
    return copy(id = WallboxSelectionId.random())
  }


  companion object {
    fun getEmpty(id: WallboxSelectionId = WallboxSelectionId.random()): WallboxIdSelection {
      return WallboxIdSelection(id = id, entries = emptyList(), throttled = false)
    }
  }

}


fun ResolvedWallboxSelection.unResolve(): WallboxIdSelection {
  return WallboxIdSelection(
    id = id,
    entries = entriesNonEmpty.map {
      ElementsSelectionEntry(it.element.id, it.amount)
    },
    throttled = throttled,
  )
}
