package it.neckar.financial.currency

import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.time.h
import kotlinx.serialization.Serializable
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours

@Serializable
data class PriceWithProfitAndWorkingTime(

  val materialPrice: PriceWithProfit,

  val workingTimes: WorkingTimesWithPrice,

  ) {

  /**
   * The profit
   */
  val profit: Money
    get() = sellingPrice - inputPrice

  val greaterThanZero: Boolean
    get() = sellingPrice > Money.Zero

  val lessThanZero: Boolean
    get() = sellingPrice < Money.Zero

  val priceWithProfit: PriceWithProfit
    get() = (materialPrice + workingTimes.priceWithProfit)

  val inputPrice: Money
    get() = priceWithProfit.inputPrice

  val sellingPrice: Money
    get() = priceWithProfit.sellingPrice

  val workingTime: Duration
    get() = workingTimes.workingTime

  val workingHourPrice: PricePerHour
    get() = workingTimes.workingHourPrice


  operator fun plus(other: PriceWithProfit): PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(materialPrice + other, workingTimes)
  }

  operator fun plus(other: PriceWithProfitAndWorkingTime): PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(materialPrice + other.materialPrice, workingTimes + other.workingTimes)
  }

  operator fun minus(other: PriceWithProfit): PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(materialPrice - other, workingTimes)
  }

  operator fun minus(other: PriceWithProfitAndWorkingTime): PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(materialPrice - other.materialPrice, workingTimes - other.workingTimes)
  }

  operator fun times(factor: Double): PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(materialPrice * factor, workingTimes)
  }

  operator fun times(factor: Int): PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(materialPrice * factor, workingTimes)
  }

  operator fun div(other: Double): @pct PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(materialPrice / other, workingTimes)
  }

  operator fun unaryMinus(): PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(-materialPrice, -workingTimes)
  }


  operator fun plus(other: Duration): PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(materialPrice, workingTimes + other)
  }

  operator fun minus(other: Duration): PriceWithProfitAndWorkingTime {
    return PriceWithProfitAndWorkingTime(materialPrice, workingTimes - other)
  }


  fun dump(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    /**
     * return without non-breaking space because it is not necessary for testing
     * */
    return "${inputPrice.format(i18nConfiguration, WhitespaceConfig.Spaces)} / ${sellingPrice.format(i18nConfiguration, WhitespaceConfig.Spaces)}"
  }


  companion object {

    val Zero: PriceWithProfitAndWorkingTime = PriceWithProfitAndWorkingTime(PriceWithProfit.Zero, WorkingTimesWithPrice.Zero)

    operator fun invoke(inputEuros: @EUR Double, sellingEuros: @EUR Double, workingTimeHours: @h Double, workingHourPriceEuros: @EUR Double): PriceWithProfitAndWorkingTime {
      return PriceWithProfitAndWorkingTime(PriceWithProfit(inputEuros.euro, sellingEuros.euro), WorkingTimesWithPrice(workingTimeHours.hours, PricePerHour(workingHourPriceEuros.euro)))
    }

    operator fun invoke(materialPrice: PriceWithProfit, workingTimeHours: @h Double, workingHourPriceEuros: @EUR Double): PriceWithProfitAndWorkingTime {
      return PriceWithProfitAndWorkingTime(materialPrice, WorkingTimesWithPrice(workingTimeHours.hours, PricePerHour(workingHourPriceEuros.euro)))
    }

    operator fun invoke(materialPrice: PriceWithProfit, workingTimeHours: @h Duration, workingHourPriceEuros: PricePerHour): PriceWithProfitAndWorkingTime {
      return PriceWithProfitAndWorkingTime(materialPrice, WorkingTimesWithPrice(workingTimeHours, workingHourPriceEuros))
    }

    operator fun invoke(inputEuros: @EUR Double, sellingEuros: @EUR Double, workingTimes: WorkingTimesWithPrice): PriceWithProfitAndWorkingTime {
      return PriceWithProfitAndWorkingTime(PriceWithProfit(inputEuros.euro, sellingEuros.euro), workingTimes)
    }

  }

}
