package components.project.blueprint

import components.project.configuration.annotations.AnnotationsList
import components.project.editProcessStateEntry
import components.project.inquiryInformationForm
import components.project.isProjectEditable
import components.project.processStatesList
import components.project.validationTable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry.BlueprintAcquisitionProcessStates
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry.BlueprintProcessStates
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectPriceList

val EditBlueprintFinishFromUrl: FC<Props> = fc("EditBlueprintFinishFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Abschluss - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    EditBlueprintFinish {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val EditBlueprintFinish: FC<EditBlueprintFinishProps> = fc("EditBlueprintFinish") { props ->
  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()
  val companyResolver = useSelectCompanyResolver()

  val project = props::project.safeGet()

  val blueprint = project.blueprint
  val editableStatus = project.isProjectEditable()

  val zaehlerNummerToSave = useState(blueprint.zaehlerNummer)
  val flurstueckNummerToSave = useState(blueprint.flurstueckNummer)
  val facilityOperator1Name = useState(blueprint.facilityOperator1.name)
  val facilityOperator1Birthday = useState(blueprint.facilityOperator1.birthday)
  val facilityOperator2Name = useState(blueprint.facilityOperator2.name)
  val facilityOperator2Birthday = useState(blueprint.facilityOperator2.birthday)
  val einspeiseart = useState(blueprint.einspeiseart)
  val lagePlanAngefragtToSave = useState(blueprint.lagePlanAngefragt)

  val imagesDachToSave = useState(blueprint.imagesDach)
  val imagesGeruestflaechenToSave = useState(blueprint.imagesGeruestflaechen)
  val imagesKellerToSave = useState(blueprint.imagesKeller)
  val imagesZaehlerschrankToSave = useState(blueprint.imagesZaehlerschrank)
  val imagesErdungToSave = useState(blueprint.imagesErdung)
  val neubauToSave = useState(blueprint.neubau)

  val processStateEntries = useLoadProcessStatesForComponent(blueprint.uuid)
  val currentProcessStateEntry = processStateEntries?.current()

  val nextProcessStateForBlueprint = if (currentProcessStateEntry is BlueprintAcquisitionProcessStateEntry && currentProcessStateEntry.dueDate != null) {
    BlueprintProcessStates.BeingEdited
  } else null

  val hasErrors = project.validationProblems.hasErrors() || blueprint.validationProblems.hasErrors()

  val okAction = {
    if (hasErrors.not()) {
      UiActions.resolveBlueprintToConfigurations(
        project = project,
        assignedTo = currentProcessStateEntry?.assignedTo ?: loggedInUser.loginName,
        belongsTo = currentProcessStateEntry?.belongsTo ?: loggedInUser.company.companyCode,
        availableProducts = availableProducts,
        priceList = priceList,
        companyResolver = companyResolver,
        loggedInUser = loggedInUser,
      )
      navigate.invoke(RouterUrls.project(project).configurations)
    }
  }


  useMemo(
    zaehlerNummerToSave.value,
    facilityOperator1Name.value,
    facilityOperator1Birthday.value,
    facilityOperator2Name.value,
    facilityOperator2Birthday.value,
    einspeiseart.value,
    lagePlanAngefragtToSave.value,
    imagesDachToSave.value,
    imagesGeruestflaechenToSave.value,
    imagesKellerToSave.value,
    imagesZaehlerschrankToSave.value,
    imagesErdungToSave.value,
    neubauToSave.value,
  ) {
    blueprint.copy(
      zaehlerNummer = zaehlerNummerToSave.value,
      facilityOperator1 = FacilityOperatorInformation(facilityOperator1Name.value, facilityOperator1Birthday.value),
      facilityOperator2 = FacilityOperatorInformation(facilityOperator2Name.value, facilityOperator2Birthday.value),
      einspeiseart = einspeiseart.value,
      lagePlanAngefragt = lagePlanAngefragtToSave.value,
      imagesDach = imagesDachToSave.value,
      imagesGeruestflaechen = imagesGeruestflaechenToSave.value,
      imagesKeller = imagesKellerToSave.value,
      imagesZaehlerschrank = imagesZaehlerschrankToSave.value,
      imagesErdung = imagesErdungToSave.value,
      neubau = neubauToSave.value,
    ).also {
      UiActions.saveBlueprint(it)
    }
  }


  div("my-4") {
    h1 {
      +"Abschluss"
    }

    div("my-3") {
      inquiryInformationForm(
        zaehlerNummer = zaehlerNummerToSave,
        flurstueckNummer = flurstueckNummerToSave,
        facilityOperator1Name = facilityOperator1Name,
        facilityOperator1Birthday = facilityOperator1Birthday,
        facilityOperator2Name = facilityOperator2Name,
        facilityOperator2Birthday = facilityOperator2Birthday,
        einspeiseart = einspeiseart,
        editableStatus = editableStatus,
      )
    }

    div("my-3") {
      checkbox(
        valueAndSetter = lagePlanAngefragtToSave,
        fieldName = "lagePlanAngefragt",
        title = "Lageplan angefragt/abgelegt*",
        editableStatus = editableStatus,
        labelConfig = {
          attrs {
            addClass("fw-bold")
          }
        },
      )
    }

    div("my-5") {
      h2("my-3") {
        +"Bilder"
      }

      imagesForm(
        imagesDach = imagesDachToSave,
        imagesGeruestflaechen = imagesGeruestflaechenToSave,
        imagesKeller = imagesKellerToSave,
        imagesZaehlerschrank = imagesZaehlerschrankToSave,
        imagesErdung = imagesErdungToSave,
        neubau = neubauToSave,
      )
    }

    div("my-5") {

      AnnotationsList {
        attrs {
          this.project = project
          this.moduleLayouts = blueprint.moduleLayouts
          this.powerUsageScenarioId = blueprint.powerUsageScenario.id
          this.facilityConfigurationId = blueprint.facilityConfiguration.id
          this.wallboxSelectionId = blueprint.wallboxConfiguration.id
          this.electricityWorkConfigurationId = blueprint.electricityWorkConfiguration.id
          this.additionalPositions = blueprint.additionalPositions
        }
      }

    }

    div("col-6 my-4") {
      editProcessStateEntry(
        processStateFor = blueprint,
        availableProcessStates = currentProcessStateEntry?.availableOptions ?: BlueprintAcquisitionProcessStates.entries,
        nextProcessState = nextProcessStateForBlueprint,
        editableStatus = project.isProjectEditable(),
      )

      processStatesList(blueprint)
    }

    formButtons(
      cancelIcon = ButtonIcon.left(FontAwesomeIcons.arrowLeft),
      cancelText = "Zurück",
      cancelAction = { navigate(RouterUrls.blueprint(project).additional) },

      okText = "Speichern & Abschließen",
      okConfig = {
        it.addClassIf("disabled") { hasErrors }
      },
      okAction = { okAction() },
    )

    div("my-5") {
      validationTable(project.validationProblems)
    }

    div("my-5") {
      validationTable(blueprint.validationProblems)
    }

  }

}

external interface EditBlueprintFinishProps : Props {
  var project: ResolvedProject
}
