package it.neckar.lizergy.model.location

import com.meistercharts.maps.MapCoordinates
import it.neckar.customer.Address
import it.neckar.lizergy.model.configuration.energy.SpecificAnnualProduction
import kotlinx.serialization.Serializable

/**
 * Information about the location
 */
@Serializable
data class LocationInformation(
  /**
   * The address
   */
  val address: Address,
  /**
   * The coordinates (should correspond to the address)
   */
  val coordinates: MapCoordinatesInformation?,

  /**
   * The specific annual production at the given location
   */
  val specificAnnualProduction: SpecificAnnualProduction,
) {

  companion object {
    val empty: LocationInformation = LocationInformation(
      address = Address.empty,
      coordinates = null,
      specificAnnualProduction = SpecificAnnualProduction.LizergyDefault
    )

    val demo: LocationInformation = LocationInformation(
      address = Address.demo,
      coordinates = null,
      specificAnnualProduction = SpecificAnnualProduction.LizergyDefault
    )


    /**
     * The location of Neckar IT
     */
    val neckarIT: LocationInformation = LocationInformation(
      address = Address.NeckarIT,
      coordinates = MapCoordinates.neckarIt.exact(),
      specificAnnualProduction = SpecificAnnualProduction.Moessingen
    )
  }
}
