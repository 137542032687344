package services.http

import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.get
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot.QuoteSnapshotId
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId

class PdfReportClientService(val httpClient: HttpClient, val urlSupport: PlannerUrlSupport) {
  /**
   * Downloads the quote
   */
  suspend fun downloadQuoteOfferPdfForSnapshot(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    snapshotId: QuoteSnapshotId,
  ): ByteArray {
    return httpClient
      .get(urlSupport.pdf.quoteOfferForSnapshot(projectId, configurationId, snapshotId)) {
        featureFlagsHeader()
        accept(ContentType.Application.Pdf)
      }.readBytes()
  }

  suspend fun downloadQuoteOfferPdfForCurrent(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
  ): ByteArray {
    return httpClient
      .get(urlSupport.pdf.quoteOfferForCurrent(projectId, configurationId)) {
        featureFlagsHeader()
        accept(ContentType.Application.Pdf)
      }.readBytes()
  }

  suspend fun downloadOrderConfirmationPdf(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    snapshotId: QuoteSnapshotId,
  ): ByteArray {
    return httpClient
      .get(urlSupport.pdf.orderConfirmationForSnapshot(projectId, configurationId, snapshotId)) {
        featureFlagsHeader()
        accept(ContentType.Application.Pdf)
      }.readBytes()
  }

  suspend fun downloadAssemblyPortfolioPdf(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
  ): ByteArray {
    return httpClient
      .get(urlSupport.pdf.assemblyPortfolio(projectId, configurationId)) {
        featureFlagsHeader()
        accept(ContentType.Application.Pdf)
      }.readBytes()
  }

}
