package components.accounting

import components.project.LocalStorageKeys
import components.project.ResolvedProjectPreviewsTable
import components.project.projectColumnDetails
import components.project.projectsFiltering
import it.neckar.commons.kotlin.js.LocalStorageKey
import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.commons.kotlin.js.LocalStorageSupport
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.customer.company.NeckarITCompanyProfile
import it.neckar.customer.company.PartnerCompanyProfile
import it.neckar.customer.company.TestCompanyProfile
import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.price.AccountingStatus
import it.neckar.lizergy.model.price.ResolvedEarningsEntry
import it.neckar.lizergy.model.project.previews.AccountingProjectPreview
import it.neckar.lizergy.model.project.previews.AccountingResolvedConfigurationPreview
import it.neckar.lizergy.model.project.previews.AccountingResolvedProjectPreview
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.open.collections.fastForEach
import it.neckar.open.kotlin.lang.divFloor
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleCheck
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import it.neckar.react.common.table.*
import it.neckar.user.UserLoginName
import kotlinx.html.TD
import plannerI18nConfiguration
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import services.UiActions
import services.http.AccountingQuerySelection
import services.http.ProjectQuerySortedBy
import services.http.ProjectQuerySortedColumn
import services.http.ProjectQuerySorting
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver

val AccountingOverview: FC<Props> = fc("AccountingOverview") {
  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()
  val companyResolver = useSelectCompanyResolver()
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Abrechnung")

  val keyPrefix = LocalStorageKeyPrefix("Accounting")

  val filteredByCompanyCode: StateInstance<CompanyCode?> = useState(null)

  // If the value is null, all projects shall be shown.
  val filteredByMaintainerName: StateInstance<UserLoginName?> = useState(
    LocalStorageSupport.loadFromLocalStorage(
      key = LocalStorageKey("${keyPrefix}${LocalStorageKeys.maintainer}"),
      serializer = UserLoginName.serializer()
    )
  )

  // If the value is null, all projects shall be shown.
  val filteredByEditorName: StateInstance<UserLoginName?> = useState(
    LocalStorageSupport.loadFromLocalStorage(
      key = LocalStorageKey("${keyPrefix}${LocalStorageKeys.maintainer}"),
      serializer = UserLoginName.serializer()
    )
  )

  val filteredByProjectState: StateInstance<LizergyProcessStates?> = useState(null) //default: show all projects - but not archived!

  val filterInputStateProject = useState("")
  val filterInputStateAddress = useState("")

  val sortedByFunction = useState(
    SortedByFunction(
      sortedBy = SortedBy.SortedDescending,
      tableDataColumn = ResolvedProjectPreviewsTable.getProcessStateColumn<AccountingResolvedProjectPreview>(null),
    )
  )

  val pageSize: StateInstance<Int?> = useState(15)
  val currentPage = useState(0)

  val visibleProjects: StateInstance<List<AccountingResolvedProjectPreview>?> = useState(null)
  val numberOfFilteredProjects: StateInstance<Int?> = useState(null)
  val numberOfRelevantProjects: StateInstance<Int?> = useState(null)

  val accountingQuerySelection = useState(AccountingQuerySelection.PendingForAccounting)
  val howDoYouLikeYourQuoteElements = useState(AccountingProjectPreview.QuoteElements.Frozen)


  useEffect(
    filteredByCompanyCode.value,
    filteredByMaintainerName.value,
    filteredByEditorName.value,
    filterInputStateProject.value,
    filterInputStateAddress.value,
    filteredByProjectState.value,
    sortedByFunction.value,
    pageSize.value,
    currentPage.value,
    accountingQuerySelection.value,
    howDoYouLikeYourQuoteElements.value,
  ) {
    val sorting = ProjectQuerySorting(
      sortedBy = when (sortedByFunction.value.sortedBy) {
        SortedBy.Unsorted -> ProjectQuerySortedBy.Unsorted
        SortedBy.SortedAscending -> ProjectQuerySortedBy.SortedAscending
        SortedBy.SortedDescending -> ProjectQuerySortedBy.SortedDescending
      },
      sortedColumn = when (sortedByFunction.value.sortedColumn.id) {
        "selectedForAccounting" -> ProjectQuerySortedColumn.SelectedForAccountingColumn
        "project" -> ProjectQuerySortedColumn.ProjectColumn
        "accountingTable" -> ProjectQuerySortedColumn.AccountingTable
        "processState" -> ProjectQuerySortedColumn.ProcessStateColumn
        else -> throw IllegalStateException("Unknown sortedColumn: ${sortedByFunction.value.sortedColumn.id}")
      },
    )

    visibleProjects.setter(null)
    numberOfFilteredProjects.setter(null)
    numberOfRelevantProjects.setter(null)

    UiActions.accountingQueryProjectPreviews(
      processStatesToFilter = LizergyProcessStates.allAccountingProcessStates,
      processStatesToHide = listOf(ProjectProcessStates.Archived, ProjectProcessStates.Paused),
      accountingQuerySelection = accountingQuerySelection.value,
      filteredByCompanyCode = filteredByCompanyCode.value,
      filteredByMaintainer = filteredByMaintainerName.value,
      filteredByEditor = filteredByEditorName.value,
      filterValueProject = filterInputStateProject.value,
      filterValueAddress = filterInputStateAddress.value,
      filterByProjectState = filteredByProjectState.value,
      indexOfFirstVisibleProject = pageSize.value?.let { currentPage.value * it } ?: 0,
      indexOfLastVisibleProject = pageSize.value?.let { (currentPage.value + 1) * it } ?: Int.MAX_VALUE,
      sorting = sorting,
      projectQueryComponent = null,
      howDoYouLikeYourQuoteElements = howDoYouLikeYourQuoteElements.value,
      loggedInUser = loggedInUser.loginName,
    ) { projectQuery ->
      val queryInformation = projectQuery?.data
      visibleProjects.setter(queryInformation?.visibleProjectPreviews)
      numberOfFilteredProjects.setter(queryInformation?.numberOfTotalFilteredProjects)
      numberOfRelevantProjects.setter(queryInformation?.numberOfTotalRelevantProjects)
      currentPage.setter(currentPage.value.coerceIn(0, queryInformation?.numberOfTotalFilteredProjects?.divFloor(pageSize.value ?: 1)))
    }

    // TODO: Properly integrate this into the UI
    UiActions.allAccountingProjectPreviewsAsCSV { projectQuery ->
      println(projectQuery?.data)
    }
  }


  div {

    h2 {
      +"Abrechnung"
    }

    div {

      projectsFiltering(
        filteredByCompanyCode = filteredByCompanyCode,
        filteredByMaintainerName = filteredByMaintainerName,
        filteredByEditorName = filteredByEditorName,
        filteredByProjectState = filteredByProjectState,
        filterInputStateProject = filterInputStateProject,
        filterInputStateAddress = filterInputStateAddress,
        processStatesToFilter = LizergyProcessStates.allAccountingProcessStates,
        localStorageKeyPrefix = keyPrefix,
      )

      div("row g-2 mt-2 mb-3") {
        div("col") {
          floatingSelectEnum(
            valueAndSetter = accountingQuerySelection,
            formatter = {
              when (it) {
                AccountingQuerySelection.All -> "ALLE"
                AccountingQuerySelection.PendingForAccounting -> "Ausstehend"
                AccountingQuerySelection.SelectedForAccounting -> "Gebucht"
                AccountingQuerySelection.Accounted -> "Abgerechnet"
              }
            },
            availableOptions = AccountingQuerySelection.entries,
            fieldName = "accountingQuerySelection",
            title = "Projektauswahl",
          )
        }

        div("col") {
          floatingSelectEnum(
            valueAndSetter = howDoYouLikeYourQuoteElements,
            formatter = {
              when (it) {
                AccountingProjectPreview.QuoteElements.Frozen -> "Nur eingefrorene Preise"
                AccountingProjectPreview.QuoteElements.UpToDate -> "Nur aktuelle Preise"
                AccountingProjectPreview.QuoteElements.Whatever -> "Aktuelle Preise, falls Eingefrorene nicht vorhanden"
              }
            },
            availableOptions = AccountingProjectPreview.QuoteElements.entries,
            fieldName = "howDoYouLikeYourQuoteElements",
            title = "Preise",
          )
        }
      }

      busyIfNull(visibleProjects.value) { loadedProjectPreviews ->
        remoteTableWithPagination(
          tableClasses = "table table-striped table-hover table-responsive",
          entries = loadedProjectPreviews,
          columns = buildList {
            add(ResolvedProjectPreviewsTable.editButtonFirstColumn { navigate(RouterUrls.project(it).configuration((it.currentConfigurationPreview ?: return@editButtonFirstColumn).configurationId).earnings) })
            add(selectedForAccountingColumn())
            add(getAccountingProjectColumn(companyResolver))
            add(earningsTable(companyResolver, AccountingProjectPreview.QuoteElements.Whatever))
            add(ResolvedProjectPreviewsTable.getMostRecentProcessStateColumn())
          },
          pageSize = pageSize,
          currentPage = currentPage,
          numberOfTotalFilteredProjects = numberOfFilteredProjects.value ?: 0,
          sortedByFunction = sortedByFunction,
        )
      }

      div("mt-1") {
        span("form-text") {
          +"${numberOfFilteredProjects.value ?: "-"} von ${numberOfRelevantProjects.value ?: "-"} Projekten ausgewählt"
        }
      }

    }

  }

}


data class EarningsTableEntries(
  val companyProfile: CompanyProfile,
  val configurationPreview: AccountingResolvedConfigurationPreview,
  val earningsCompanyEntries: List<ResolvedEarningsEntry>,
) {
  val earningsCompanyEntriesByCompany: Map<CompanyCode, List<ResolvedEarningsEntry>>
    get() = earningsCompanyEntries.groupBy { it.company }

  val relevantEarningsCompanyEntries: List<ResolvedEarningsEntry>
    get() = when (companyProfile) {
      is MainCompanyProfile -> emptyList()
      NeckarITCompanyProfile, is PartnerCompanyProfile -> earningsCompanyEntriesByCompany[companyProfile.companyCode] ?: emptyList()
      is TestCompanyProfile -> emptyList()
    }

  val openEarningsCompanyEntries: List<ResolvedEarningsEntry>
    get() = relevantEarningsCompanyEntries.filter { it.accountingStatus == AccountingStatus.Pending }

  fun relevantEarnings(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return when (companyProfile) {
      is MainCompanyProfile -> configurationPreview.getEarningsForMainCompany(howDoYouLikeYourQuoteElements)
      is PartnerCompanyProfile, is TestCompanyProfile -> configurationPreview.getEarningsForCompany(companyProfile.companyCode, howDoYouLikeYourQuoteElements)
      NeckarITCompanyProfile -> configurationPreview.getNeckarITEarnings(howDoYouLikeYourQuoteElements)
    }
  }
}


fun selectedForAccountingColumn(): TableHeaderColumn<AccountingResolvedProjectPreview> {
  return TableHeaderColumn(
    id = "selectedForAccounting",
    title = "Gebucht",
    sortFunction = compareBy { it.currentConfigurationPreview?.earningsDistribution?.selectedForAccounting },
  ) { projectPreview ->
    {
      attrs {
        addClass("mt-3")
        addClass("text-center")
      }

      SelectedForAccountingColumn {
        attrs {
          this.projectPreview = projectPreview
        }
      }
    }
  }
}

val SelectedForAccountingColumn: FC<SelectedForAccountingColumnProps> = fc("SelectedForAccountingColumn") { props ->
  val projectPreview = props::projectPreview.safeGet()
  val configurationPreview = projectPreview.currentConfigurationPreview
  val earningsDistribution = configurationPreview?.earningsDistribution

  val selectedForAccounting = useState(earningsDistribution?.selectedForAccounting == true)

  if (earningsDistribution != null) {
    checkbox(
      value = selectedForAccounting.value,
      onChange = {
        selectedForAccounting.setter(it)
        UiActions.saveEarningsDistribution(projectId = projectPreview.projectId, configurationId = configurationPreview.configurationId, earningsDistribution = earningsDistribution.copy(selectedForAccounting = it))
      },
      fieldName = "${projectPreview.projectId}-selectedForAccounting",
      title = "",
    )
  }
}

external interface SelectedForAccountingColumnProps : Props {
  var projectPreview: AccountingResolvedProjectPreview
}

fun getAccountingProjectColumn(companyResolver: CompanyResolver): TableDataColumn<AccountingResolvedProjectPreview> {
  return TableDataColumn(
    id = "project",
    title = "Projekt",
    sortFunction = compareBy { it.displayName },
  ) { projectPreview ->
    {
      accountingProjectColumnDetails(projectPreview, companyResolver)
    }
  }
}

fun RDOMBuilder<TD>.accountingProjectColumnDetails(projectPreview: AccountingResolvedProjectPreview, companyResolver: CompanyResolver) {
  projectColumnDetails(projectPreview, companyResolver)

  if (projectPreview.customer.address != projectPreview.location.address) {
    p("form-text") { +projectPreview.customer.address.singleLine() }
  }
  projectPreview.currentConfigurationPreview?.let { configurationPreview ->
    p("form-text") { +"Module: ${configurationPreview.modulesReport.countByModules.joinToString(", ") { "${it.count} × ${it.moduleType.description}" }}" }
    configurationPreview.batteryConfiguration?.let { batteryConfiguration ->
      p("form-text") { +"Batterie: ${batteryConfiguration.format()}" }
    }
    p("form-text") { +"Wechselrichter: ${configurationPreview.inverterSelection.format(plannerI18nConfiguration)}" }
  }
  p("form-text") { +"Ansprechpartner: ${projectPreview.maintainerInformation.editorName}" }
}

fun earningsTable(companyResolver: CompanyResolver, howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): TableDataColumn<AccountingResolvedProjectPreview> {
  return TableDataColumn(
    id = "accountingTable",
    title = "Abrechnung",
    sortFunction = compareBy { it.currentConfigurationPreview?.getNetPrice(howDoYouLikeYourQuoteElements) },
  ) { projectPreview ->
    val currentConfigurationPreview = projectPreview.currentConfigurationPreview
    {
      if (currentConfigurationPreview != null) {
        val earningsDistribution = currentConfigurationPreview.earningsDistribution
        val accountingTableEntriesList = earningsDistribution.allIncludingSoftware.groupBy { it.companyProfile }.map {
          EarningsTableEntries(
            companyProfile = it.key,
            configurationPreview = currentConfigurationPreview,
            earningsCompanyEntries = it.value,
          )
        }.sortedByDescending { it.companyProfile }

        accountingTableEntriesList.fastForEach { accountingTableEntries ->
          val companyInformation = companyResolver[accountingTableEntries.companyProfile]
          val earningsCompanyEntries = accountingTableEntries.relevantEarningsCompanyEntries
          val openEarningsCompanyEntries = accountingTableEntries.openEarningsCompanyEntries
          val accountedEarningsCompanyEntries = earningsCompanyEntries.size - openEarningsCompanyEntries.size

          div("mb-1") {
            div("row") {
              div("col") {
                b("form-text") { +companyInformation.name }
                span("ms-2") {
                  attrs {
                    addClass("text-center")
                    addClass("align-middle")

                    if (openEarningsCompanyEntries.isEmpty()) {
                      addClass("text-success")
                    } else if (accountedEarningsCompanyEntries > 0) {
                      addClass("text-warning")
                    } else {
                      addClass("text-danger")
                    }
                  }

                  if (openEarningsCompanyEntries.isEmpty()) {
                    faCircleCheck()
                  } else {
                    b { +"${accountedEarningsCompanyEntries}/${earningsCompanyEntries.size}" }
                  }
                }
              }
              div("col form-text text-end") {
                +accountingTableEntries.relevantEarnings(howDoYouLikeYourQuoteElements).format(plannerI18nConfiguration)
              }
            }
          }
        }

        hr {}

        div("row") {
          div("col my-auto") { b { +"Gesamt" } }
          div("col text-end") {
            b { +currentConfigurationPreview.getNetPrice(howDoYouLikeYourQuoteElements).format(plannerI18nConfiguration) }
          }
        }
      }
    }
  }
}
