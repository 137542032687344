package it.neckar.lizergy.model.configuration.moduleLayout.roof

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.components.ConfigurationItem.ConfigurationItemId
import it.neckar.open.collections.fastForEach
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import it.neckar.uuid.withAdded
import it.neckar.uuid.withRemoved
import it.neckar.uuid.withReplaced
import kotlinx.serialization.Serializable

@Serializable
data class ConfigurationItemsConfiguration(
  val id: ConfigurationItemsConfigurationId = ConfigurationItemsConfigurationId.random(),
  val configurationItems: List<ConfigurationItem>,
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  operator fun get(itemId: Uuid): ConfigurationItem {
    return configurationItems.first { quoteItem ->
      quoteItem.uuid == itemId
    }
  }

  operator fun get(itemId: ConfigurationItemId): ConfigurationItem {
    return get(itemId.uuid)
  }

  fun fastForEach(function: (ConfigurationItem) -> Unit) {
    configurationItems.fastForEach { function(it) }
  }

  fun withAdded(additionalItem: ConfigurationItem): ConfigurationItemsConfiguration {
    val updatedRoofs = configurationItems.withAdded(additionalItem)
    return copy(configurationItems = updatedRoofs)
  }

  fun withReplaced(updatedItem: ConfigurationItem): ConfigurationItemsConfiguration {
    return copy(configurationItems = configurationItems.withReplaced(updatedItem))
  }

  fun withRemoved(itemToRemove: ConfigurationItem): ConfigurationItemsConfiguration {
    return copy(configurationItems = configurationItems.withRemoved(itemToRemove))
  }

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ConfigurationItemsConfiguration {
    val newId = ConfigurationItemsConfigurationId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(
      id = newId,
      configurationItems = configurationItems.map { it.duplicate(mapOfOldToNewUuids) },
    )
  }


  @Serializable
  data class ConfigurationItemsConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ConfigurationItemsConfigurationId {
        return ConfigurationItemsConfigurationId(randomUuid4())
      }
    }
  }


  companion object {
    fun getEmpty(): ConfigurationItemsConfiguration {
      return ConfigurationItemsConfiguration(configurationItems = emptyList())
    }
  }
}
