package style

import it.neckar.lifeCycle.LifeCycleState


/**
 * Returns the style class for a lifecycle state
 */
fun LifeCycleState.toStyleClass(): String {
  return when (this) {
    LifeCycleState.Active -> ""
    LifeCycleState.NearEndOfLive -> "near-end-of-life"
    LifeCycleState.EndOfLife -> "end-of-life"
  }

}
