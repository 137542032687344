@file:Suppress("DuplicatedCode")

package components.company.user

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.AccessRights
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.Companion.toEditableStatus
import react.*
import react.dom.*
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUsersAndCompanies

/**
 * Change password form
 */

fun RBuilder.userForm(
  userName: StateInstance<String>,
  editorName: StateInstance<String>,

  companyProfile: StateInstance<CompanyProfile>,

  editCustomers: StateInstance<Boolean>?,
  editProjects: StateInstance<Boolean>?,
  editConfigurations: StateInstance<Boolean>?,
  editModuleLayouts: StateInstance<Boolean>?,
  editOtherUsers: StateInstance<Boolean>?,
  editOwnCompany: StateInstance<Boolean>?,
  editOtherCompanies: StateInstance<Boolean>?,
  deleteProjects: StateInstance<Boolean>?,
  verifyProjects: StateInstance<Boolean>?,
  deleteUsers: StateInstance<Boolean>?,
  viewPriceList: StateInstance<Boolean>?,
  viewProjectCount: StateInstance<Boolean>?,
  deleteProcessStates: StateInstance<Boolean>?,
  viewAccounting: StateInstance<Boolean>?,
  viewProfits: StateInstance<Boolean>?,
  viewQuoteDump: StateInstance<Boolean>?,
  viewEarnings: StateInstance<Boolean>?,
  editEarnings: StateInstance<Boolean>?,

  viewEarningsReferrerRow: StateInstance<Boolean>?,
  viewEarningsDistributionBlueprintRow: StateInstance<Boolean>?,
  viewEarningsDistributionQuoteRow: StateInstance<Boolean>?,
  viewEarningsTechnicalPlanningRoofRow: StateInstance<Boolean>?,
  viewEarningsTechnicalPlanningElectricRow: StateInstance<Boolean>?,
  viewEarningsRoofAssemblyRow: StateInstance<Boolean>?,
  viewEarningsScaffoldingAssemblyRow: StateInstance<Boolean>?,
  viewEarningsElectricityWorkRow: StateInstance<Boolean>?,
  viewEarningsBureaucracyRow: StateInstance<Boolean>?,

  existingUser: UserInformation?,
): Unit = child(UserForm) {
  attrs {
    this.userName = userName
    this.editorName = editorName

    this.companyProfile = companyProfile

    this.editCustomers = editCustomers
    this.editProjects = editProjects
    this.editConfigurations = editConfigurations
    this.editModuleLayouts = editModuleLayouts
    this.editOtherUsers = editOtherUsers
    this.editOwnCompany = editOwnCompany
    this.editOtherCompanies = editOtherCompanies
    this.deleteProjects = deleteProjects
    this.verifyProjects = verifyProjects
    this.deleteUsers = deleteUsers
    this.viewPriceList = viewPriceList
    this.viewProjectCount = viewProjectCount
    this.deleteProcessStates = deleteProcessStates
    this.viewAccounting = viewAccounting
    this.viewQuoteDump = viewQuoteDump
    this.viewProfits = viewProfits
    this.viewEarnings = viewEarnings
    this.editEarnings = editEarnings

    this.viewEarningsReferrerRow = viewEarningsReferrerRow
    this.viewEarningsDistributionBlueprintRow = viewEarningsDistributionBlueprintRow
    this.viewEarningsDistributionQuoteRow = viewEarningsDistributionQuoteRow
    this.viewEarningsTechnicalPlanningRoofRow = viewEarningsTechnicalPlanningRoofRow
    this.viewEarningsTechnicalPlanningElectricRow = viewEarningsTechnicalPlanningElectricRow
    this.viewEarningsRoofAssemblyRow = viewEarningsRoofAssemblyRow
    this.viewEarningsScaffoldingAssemblyRow = viewEarningsScaffoldingAssemblyRow
    this.viewEarningsElectricityWorkRow = viewEarningsElectricityWorkRow
    this.viewEarningsBureaucracyRow = viewEarningsBureaucracyRow

    this.existingUser = existingUser
  }
}

val UserForm: FC<UserFormProps> = fc("UserForm") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val usersAndCompanies = useSelectUsersAndCompanies()

  val userName = props::userName.getNotNull()
  val editorName = props::editorName.getNotNull()

  val companyProfile = props::companyProfile.getNotNull()

  val editCustomers = props::editCustomers.safeGet()
  val editProjects = props::editProjects.safeGet()
  val editConfigurations = props::editConfigurations.safeGet()
  val editModuleLayouts = props::editModuleLayouts.safeGet()
  val editOtherUsers = props::editOtherUsers.safeGet()
  val editOwnCompany = props::editOwnCompany.safeGet()
  val editOtherCompanies = props::editOtherCompanies.safeGet()
  val deleteProjects = props::deleteProjects.safeGet()
  val verifyProjects = props::verifyProjects.safeGet()
  val deleteUsers = props::deleteUsers.safeGet()
  val viewPriceList = props::viewPriceList.safeGet()
  val viewProjectCount = props::viewProjectCount.safeGet()
  val deleteProcessStates = props::deleteProcessStates.safeGet()
  val viewAccounting = props::viewAccounting.safeGet()
  val viewProfits = props::viewProfits.safeGet()
  val viewQuoteDump = props::viewQuoteDump.safeGet()
  val viewEarnings = props::viewEarnings.safeGet()
  val editEarnings = props::editEarnings.safeGet()

  val viewEarningsReferrerRow = props::viewEarningsReferrerRow.safeGet()
  val viewEarningsDistributionBlueprintRow = props::viewEarningsDistributionBlueprintRow.safeGet()
  val viewEarningsDistributionQuoteRow = props::viewEarningsDistributionQuoteRow.safeGet()
  val viewEarningsTechnicalPlanningRoofRow = props::viewEarningsTechnicalPlanningRoofRow.safeGet()
  val viewEarningsTechnicalPlanningElectricRow = props::viewEarningsTechnicalPlanningElectricRow.safeGet()
  val viewEarningsRoofAssemblyRow = props::viewEarningsRoofAssemblyRow.safeGet()
  val viewEarningsScaffoldingAssemblyRow = props::viewEarningsScaffoldingAssemblyRow.safeGet()
  val viewEarningsElectricityWorkRow = props::viewEarningsElectricityWorkRow.safeGet()
  val viewEarningsBureaucracyRow = props::viewEarningsBureaucracyRow.safeGet()

  val existingUser = props::existingUser.safeGet()

  val canEditAccessRights = if (existingUser?.loginName == loggedInUser.loginName) EditableStatus.ReadOnly else EditableStatus.Editable

  val allCompanies = usersAndCompanies.activeCompanies(loggedInUser)
  val allCompaniesByCode: Map<CompanyCode, PlannerCompanyInformation> = allCompanies.associateBy { it.companyCode }


  div {
    div("my-3") {
      floatingInputField(
        valueAndSetter = userName,
        fieldName = "name",
        title = "Anmeldename",
        editableStatus = if (existingUser == null) EditableStatus.Editable else EditableStatus.ReadOnly,
      ) {
        attrs {
          mergedBelow()
        }
      }


      floatingInputField(
        valueAndSetter = editorName,
        fieldName = "userName",
        title = "Bearbeitername",
      ) {
        attrs {
          mergedAbove()
        }
      }
    }


    div("my-3") {
      floatingSelect(
        valueAndSetter = companyProfile,
        idProvider = { it.companyCode.value },
        formatter = { allCompaniesByCode[it.companyCode]?.name ?: it.companyCode.value },
        availableOptions = allCompanies.map { it.companyProfile },
        fieldName = "company",
        title = "Firma",
        editableStatus = loggedInUser.accessRights.canAccess(AccessRights.AccessRight.EditOtherCompanies).toEditableStatus(),
      )
    }


    h3 {
      +"Berechtigungen"
    }

    accessRightsForm(
      editCustomers = editCustomers,
      editProjects = editProjects,
      editConfigurations = editConfigurations,
      editModuleLayouts = editModuleLayouts,
      editOtherUsers = editOtherUsers,
      editOwnCompany = editOwnCompany,
      editOtherCompanies = editOtherCompanies,
      viewPriceList = viewPriceList,
      viewProjectCount = viewProjectCount,
      deleteProcessStates = deleteProcessStates,
      viewAccounting = viewAccounting,
      deleteProjects = deleteProjects,
      verifyProjects = verifyProjects,
      deleteUsers = deleteUsers,
      viewQuoteDump = viewQuoteDump,
      viewProfits = viewProfits,
      viewEarnings = viewEarnings,
      editEarnings = editEarnings,

      viewEarningsReferrerRow = viewEarningsReferrerRow,
      viewEarningsRoofAssemblyRow = viewEarningsRoofAssemblyRow,
      viewEarningsScaffoldingAssemblyRow = viewEarningsScaffoldingAssemblyRow,
      viewEarningsBureaucracyRow = viewEarningsBureaucracyRow,
      viewEarningsDistributionBlueprintRow = viewEarningsDistributionBlueprintRow,
      viewEarningsDistributionQuoteRow = viewEarningsDistributionQuoteRow,
      viewEarningsElectricityWorkRow = viewEarningsElectricityWorkRow,
      viewEarningsTechnicalPlanningRoofRow = viewEarningsTechnicalPlanningRoofRow,
      viewEarningsTechnicalPlanningElectricRow = viewEarningsTechnicalPlanningElectricRow,
      canEditAccessRights = canEditAccessRights,
    )

  }

}

external interface UserFormProps : Props {
  var userName: StateInstance<String>
  var editorName: StateInstance<String>

  var companyProfile: StateInstance<CompanyProfile>

  var editCustomers: StateInstance<Boolean>?
  var editProjects: StateInstance<Boolean>?
  var editConfigurations: StateInstance<Boolean>?
  var editModuleLayouts: StateInstance<Boolean>?
  var editOtherUsers: StateInstance<Boolean>?
  var editOwnCompany: StateInstance<Boolean>?
  var editOtherCompanies: StateInstance<Boolean>?
  var deleteProjects: StateInstance<Boolean>?
  var verifyProjects: StateInstance<Boolean>?
  var deleteUsers: StateInstance<Boolean>?
  var viewPriceList: StateInstance<Boolean>?
  var viewProjectCount: StateInstance<Boolean>?
  var deleteProcessStates: StateInstance<Boolean>?
  var viewAccounting: StateInstance<Boolean>?
  var viewQuoteDump: StateInstance<Boolean>?
  var viewProfits: StateInstance<Boolean>?
  var viewEarnings: StateInstance<Boolean>?
  var editEarnings: StateInstance<Boolean>?

  var viewEarningsReferrerRow: StateInstance<Boolean>?
  var viewEarningsDistributionBlueprintRow: StateInstance<Boolean>?
  var viewEarningsDistributionQuoteRow: StateInstance<Boolean>?
  var viewEarningsTechnicalPlanningRoofRow: StateInstance<Boolean>?
  var viewEarningsTechnicalPlanningElectricRow: StateInstance<Boolean>?
  var viewEarningsRoofAssemblyRow: StateInstance<Boolean>?
  var viewEarningsScaffoldingAssemblyRow: StateInstance<Boolean>?
  var viewEarningsElectricityWorkRow: StateInstance<Boolean>?
  var viewEarningsBureaucracyRow: StateInstance<Boolean>?

  var existingUser: UserInformation?
}
