package it.neckar.lizergy.model.stumps

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable

@Serializable
data class AssemblyBasement(
  @Serializable(with = UuidSerializer::class) override val uuid: Uuid,
  val projectId: PhotovoltaicsProjectId,
  val restArbeiten: RestArbeiten = RestArbeiten.empty,
) : HasUuid

@Serializable
data class RestArbeiten(
  val heizstab: Boolean = false,
  val waermepumpe: Boolean = false,
  val batterie: Boolean = false,
  val wallbox: Boolean = false,
  val internetanbindung: Boolean = false,
  val sonstiges: String = "",
) {
  val isNotEmpty: Boolean
    get() = heizstab || waermepumpe || batterie || wallbox || internetanbindung || sonstiges.isNotBlank()

  companion object {
    val empty: RestArbeiten
      get() = RestArbeiten()
  }
}
