package it.neckar.financial.quote

/**
 * This enum can be used for options that *can* be selected.
 */
enum class ConfiguredOptionality {
  /**
   * The option has been selected - will be added to the quote
   */
  Selected,

  /**
   * The option has *not* been selected - will *not* be added to the quote
   */
  NotSelected,

  /**
   * The configuration is marked as optional. Will be added to the quote - but as optional.
   */
  Optional,
}

fun ConfiguredOptionality.toCheckboxChar(): String {
  return when (this) {
    ConfiguredOptionality.Selected -> "\u2611"
    ConfiguredOptionality.NotSelected -> "\u2610"
    ConfiguredOptionality.Optional -> "?"
  }
}
