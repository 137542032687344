package components.project.configuration.tabs

import components.project.QuoteConfigurationTabProps
import components.project.configuration.isConfigurationEditable
import components.project.configuration.profitability.EarningsOverview
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.income.IncomePercentages
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.react.common.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

val ConfigurationEarningsOverviewFromUrl: FC<Props> = fc("ConfigurationEarningsOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Verdienst", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    ConfigurationEarningsOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val ConfigurationEarningsOverview: FC<QuoteConfigurationTabProps> = fc("EarningsOverview") { props ->
  val resolvedProject = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  div("my-3") {
    EarningsOverview {
      attrs {
        this.project = resolvedProject
        this.quoteConfiguration = quoteConfiguration
        this.incomePercentages = IncomePercentages.allWithoutSoftware // ignore Software Role
      }
    }
  }

}
