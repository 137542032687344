@file:Suppress("DuplicatedCode")

package components.company.user

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.customer.company.NeckarITCompanyProfile
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteProcessStates
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteUsers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditConfigurations
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditCustomers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditEarnings
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditModuleLayouts
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherCompanies
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherUsers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOwnCompany
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.VerifyProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewAccounting
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarnings
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsBureaucracyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsDistributionBlueprintRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsDistributionQuoteRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsElectricityWorkRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsReferrerRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsRoofAssemblyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsScaffoldingAssemblyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsTechnicalPlanningElectricRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsTechnicalPlanningRoofRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewPriceList
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewProfits
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewProjectCount
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewQuoteDump
import it.neckar.react.common.form.*
import react.*
import react.dom.*
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver

/**
 * Change password form
 */

fun RBuilder.accessRightsForm(
  editCustomers: StateInstance<Boolean>?,
  editProjects: StateInstance<Boolean>?,
  editConfigurations: StateInstance<Boolean>?,
  editModuleLayouts: StateInstance<Boolean>?,
  editOtherUsers: StateInstance<Boolean>?,
  editOwnCompany: StateInstance<Boolean>?,
  editOtherCompanies: StateInstance<Boolean>?,
  deleteProjects: StateInstance<Boolean>?,
  verifyProjects: StateInstance<Boolean>?,
  deleteUsers: StateInstance<Boolean>?,
  viewPriceList: StateInstance<Boolean>?,
  viewProjectCount: StateInstance<Boolean>?,
  deleteProcessStates: StateInstance<Boolean>?,
  viewAccounting: StateInstance<Boolean>?,
  viewQuoteDump: StateInstance<Boolean>?,
  viewProfits: StateInstance<Boolean>?,
  viewEarnings: StateInstance<Boolean>?,
  editEarnings: StateInstance<Boolean>?,

  viewEarningsReferrerRow: StateInstance<Boolean>?,
  viewEarningsDistributionBlueprintRow: StateInstance<Boolean>?,
  viewEarningsDistributionQuoteRow: StateInstance<Boolean>?,
  viewEarningsTechnicalPlanningRoofRow: StateInstance<Boolean>?,
  viewEarningsTechnicalPlanningElectricRow: StateInstance<Boolean>?,
  viewEarningsRoofAssemblyRow: StateInstance<Boolean>?,
  viewEarningsScaffoldingAssemblyRow: StateInstance<Boolean>?,
  viewEarningsElectricityWorkRow: StateInstance<Boolean>?,
  viewEarningsBureaucracyRow: StateInstance<Boolean>?,

  canEditAccessRights: EditableStatus,
): Unit = child(AccessRightsForm) {
  attrs {
    this.editCustomers = editCustomers
    this.editProjects = editProjects
    this.editConfigurations = editConfigurations
    this.editModuleLayouts = editModuleLayouts
    this.editOtherUsers = editOtherUsers
    this.editOwnCompany = editOwnCompany
    this.editOtherCompanies = editOtherCompanies
    this.deleteProjects = deleteProjects
    this.verifyProjects = verifyProjects
    this.deleteUsers = deleteUsers
    this.viewPriceList = viewPriceList
    this.viewProjectCount = viewProjectCount
    this.deleteProcessStates = deleteProcessStates
    this.viewAccounting = viewAccounting
    this.viewQuoteDump = viewQuoteDump
    this.viewProfits = viewProfits
    this.viewEarnings = viewEarnings
    this.editEarnings = editEarnings

    this.viewEarningsReferrerRow = viewEarningsReferrerRow
    this.viewEarningsDistributionBlueprintRow = viewEarningsDistributionBlueprintRow
    this.viewEarningsDistributionQuoteRow = viewEarningsDistributionQuoteRow
    this.viewEarningsTechnicalPlanningRoofRow = viewEarningsTechnicalPlanningRoofRow
    this.viewEarningsTechnicalPlanningElectricRow = viewEarningsTechnicalPlanningElectricRow
    this.viewEarningsRoofAssemblyRow = viewEarningsRoofAssemblyRow
    this.viewEarningsScaffoldingAssemblyRow = viewEarningsScaffoldingAssemblyRow
    this.viewEarningsElectricityWorkRow = viewEarningsElectricityWorkRow
    this.viewEarningsBureaucracyRow = viewEarningsBureaucracyRow

    this.canEditAccessRights = canEditAccessRights
  }
}

val AccessRightsForm: FC<AccessRightsFormProps> = fc("AccessRightsForm") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val companyResolver = useSelectCompanyResolver()

  val editCustomers = props::editCustomers.safeGet()
  val editProjects = props::editProjects.safeGet()
  val editConfigurations = props::editConfigurations.safeGet()
  val editModuleLayouts = props::editModuleLayouts.safeGet()
  val editOtherUsers = props::editOtherUsers.safeGet()
  val editOwnCompany = props::editOwnCompany.safeGet()
  val editOtherCompanies = props::editOtherCompanies.safeGet()
  val deleteProjects = props::deleteProjects.safeGet()
  val verifyProjects = props::verifyProjects.safeGet()
  val deleteUsers = props::deleteUsers.safeGet()
  val viewPriceList = props::viewPriceList.safeGet()
  val viewProjectCount = props::viewProjectCount.safeGet()
  val deleteProcessStates = props::deleteProcessStates.safeGet()
  val viewAccounting = props::viewAccounting.safeGet()
  val viewQuoteDump = props::viewQuoteDump.safeGet()
  val viewProfits = props::viewProfits.safeGet()
  val viewEarnings = props::viewEarnings.safeGet()
  val editEarnings = props::editEarnings.safeGet()

  val viewEarningsReferrerRow = props::viewEarningsReferrerRow.safeGet()
  val viewEarningsDistributionBlueprintRow = props::viewEarningsDistributionBlueprintRow.safeGet()
  val viewEarningsDistributionQuoteRow = props::viewEarningsDistributionQuoteRow.safeGet()
  val viewEarningsTechnicalPlanningRoofRow = props::viewEarningsTechnicalPlanningRoofRow.safeGet()
  val viewEarningsTechnicalPlanningElectricRow = props::viewEarningsTechnicalPlanningElectricRow.safeGet()
  val viewEarningsRoofAssemblyRow = props::viewEarningsRoofAssemblyRow.safeGet()
  val viewEarningsScaffoldingAssemblyRow = props::viewEarningsScaffoldingAssemblyRow.safeGet()
  val viewEarningsElectricityWorkRow = props::viewEarningsElectricityWorkRow.safeGet()
  val viewEarningsBureaucracyRow = props::viewEarningsBureaucracyRow.safeGet()

  val canEditAccessRights = props::canEditAccessRights.safeGet()

  val loggedInUserCompanyProfile = companyResolver[loggedInUser.company].companyProfile
  val loggedInUserIsAdmin = (loggedInUserCompanyProfile is NeckarITCompanyProfile || loggedInUserCompanyProfile is MainCompanyProfile) && loggedInUser.accessRights.canAccess(EditOwnCompany)
  val editableStatus = canEditAccessRights.and(loggedInUserIsAdmin)

  div("my-3") {

    div("d-flex flex-wrap") {

      editCustomers?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = EditCustomers.format(),
            title = EditCustomers.format(),
            editableStatus = editableStatus,
          )
        }
      }

      editProjects?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = EditProjects.format(),
            title = EditProjects.format(),
            editableStatus = editableStatus,
          )
        }
      }

      editConfigurations?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = EditConfigurations.format(),
            title = EditConfigurations.format(),
            editableStatus = editableStatus,
          )
        }
      }

      editModuleLayouts?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = EditModuleLayouts.format(),
            title = EditModuleLayouts.format(),
            editableStatus = editableStatus,
          )
        }
      }

    }

    div("d-flex flex-wrap") {

      editOtherUsers?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = EditOtherUsers.format(),
            title = EditOtherUsers.format(),
            editableStatus = editableStatus,
          )
        }
      }

      editOwnCompany?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = EditOwnCompany.format(),
            title = EditOwnCompany.format(),
            editableStatus = editableStatus,
          )
        }
      }

      editOtherCompanies?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = EditOtherCompanies.format(),
            title = EditOtherCompanies.format(),
            editableStatus = editableStatus,
          )
        }
      }

    }

    div("d-flex flex-wrap") {

      deleteProjects?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = DeleteProjects.format(),
            title = DeleteProjects.format(),
            editableStatus = editableStatus,
          )
        }
      }

      deleteProjects?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = VerifyProjects.format(),
            title = VerifyProjects.format(),
            editableStatus = editableStatus,
          )
        }
      }

      deleteUsers?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = DeleteUsers.format(),
            title = DeleteUsers.format(),
            editableStatus = editableStatus,
          )
        }
      }

    }

    div("d-flex flex-wrap") {

      viewPriceList?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewPriceList.format(),
            title = ViewPriceList.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewProjectCount?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewProjectCount.format(),
            title = ViewProjectCount.format(),
            editableStatus = editableStatus,
          )
        }
      }

      deleteProcessStates?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = DeleteProcessStates.format(),
            title = DeleteProcessStates.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewAccounting?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewAccounting.format(),
            title = ViewAccounting.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewQuoteDump?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewQuoteDump.format(),
            title = ViewQuoteDump.format(),
            editableStatus = editableStatus,
          )
        }
      }


      viewProfits?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewProfits.format(),
            title = ViewProfits.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewEarnings?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarnings.format(),
            title = ViewEarnings.format(),
            editableStatus = editableStatus,
          )
        }
      }

      editEarnings?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = EditEarnings.format(),
            title = EditEarnings.format(),
            editableStatus = editableStatus,
          )
        }
      }

    }
    hr { }
    div("d-flex flex-wrap") {
      viewEarningsReferrerRow?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarningsReferrerRow.format(),
            title = ViewEarningsReferrerRow.format(),
            editableStatus = editableStatus,
          )
        }
      }


      viewEarningsDistributionBlueprintRow?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarningsDistributionBlueprintRow.format(),
            title = ViewEarningsDistributionBlueprintRow.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewEarningsDistributionQuoteRow?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarningsDistributionQuoteRow.format(),
            title = ViewEarningsDistributionQuoteRow.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewEarningsRoofAssemblyRow?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarningsRoofAssemblyRow.format(),
            title = ViewEarningsRoofAssemblyRow.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewEarningsScaffoldingAssemblyRow?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarningsScaffoldingAssemblyRow.format(),
            title = ViewEarningsScaffoldingAssemblyRow.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewEarningsElectricityWorkRow?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarningsElectricityWorkRow.format(),
            title = ViewEarningsElectricityWorkRow.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewEarningsTechnicalPlanningRoofRow?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarningsTechnicalPlanningRoofRow.format(),
            title = ViewEarningsTechnicalPlanningRoofRow.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewEarningsTechnicalPlanningElectricRow?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarningsTechnicalPlanningElectricRow.format(),
            title = ViewEarningsTechnicalPlanningElectricRow.format(),
            editableStatus = editableStatus,
          )
        }
      }

      viewEarningsBureaucracyRow?.let {
        div("p-2") {
          checkbox(
            valueAndSetter = it,
            fieldName = ViewEarningsBureaucracyRow.format(),
            title = ViewEarningsBureaucracyRow.format(),
            editableStatus = editableStatus,
          )
        }
      }
    }

  }

}

external interface AccessRightsFormProps : Props {
  var editCustomers: StateInstance<Boolean>?
  var editProjects: StateInstance<Boolean>?
  var editConfigurations: StateInstance<Boolean>?
  var editModuleLayouts: StateInstance<Boolean>?
  var editOtherUsers: StateInstance<Boolean>?
  var editOwnCompany: StateInstance<Boolean>?
  var editOtherCompanies: StateInstance<Boolean>?
  var deleteProjects: StateInstance<Boolean>?
  var verifyProjects: StateInstance<Boolean>?
  var deleteUsers: StateInstance<Boolean>?
  var viewPriceList: StateInstance<Boolean>?
  var viewProjectCount: StateInstance<Boolean>?
  var deleteProcessStates: StateInstance<Boolean>?
  var viewAccounting: StateInstance<Boolean>?
  var viewQuoteDump: StateInstance<Boolean>?
  var viewProfits: StateInstance<Boolean>?
  var viewEarnings: StateInstance<Boolean>?
  var editEarnings: StateInstance<Boolean>?

  var viewEarningsReferrerRow: StateInstance<Boolean>?
  var viewEarningsDistributionBlueprintRow: StateInstance<Boolean>?
  var viewEarningsDistributionQuoteRow: StateInstance<Boolean>?
  var viewEarningsTechnicalPlanningRoofRow: StateInstance<Boolean>?
  var viewEarningsTechnicalPlanningElectricRow: StateInstance<Boolean>?
  var viewEarningsRoofAssemblyRow: StateInstance<Boolean>?
  var viewEarningsScaffoldingAssemblyRow: StateInstance<Boolean>?
  var viewEarningsElectricityWorkRow: StateInstance<Boolean>?
  var viewEarningsBureaucracyRow: StateInstance<Boolean>?

  var canEditAccessRights: EditableStatus
}
