package serialized

import kotlinx.serialization.Serializable

/**
 * Contains the serialized search for projects result
 */
@Serializable
data class SerializedSearchForProjectsResult(
  /**
   * The projects that match the search string
   */
  val projects: List<SerializedProjectPreview>,
) {


  companion object {
    /**
     * Does not contain any values
     */
    val empty: SerializedSearchForProjectsResult = SerializedSearchForProjectsResult(emptyList())
  }
}
