package it.neckar.lizergy.model.configuration.quote.economics

import it.neckar.financial.currency.Money
import it.neckar.open.unit.number.Positive
import it.neckar.open.unit.other.pct
import kotlin.math.pow

/**
 * Calculates a fixed rate
 */
class FixedRateMortgageCalculator(
  /**
   * "Kreditsumme"
   */
  val loanAmount: Money,
  /**
   * "Zinssatz"
   */
  val interestRate: @pct @Positive Double,

  /**
   * "Laufzeit in Jahren"
   */
  val termInYears: Int
) {

  /**
   * (1 + interest rate)
   */
  private val q: Double = 1 + interestRate

  init {
    require(interestRate > 0.0) {
      "Positive interest rate required but was <$interestRate>"
    }
  }

  /**
   * Calculates the yearly payment
   * (https://de.wikipedia.org/wiki/Annuit%C3%A4tendarlehen)
   */
  fun yearlyPayment(): Money {
    val upper = q.pow(termInYears)
    val lower = q.pow(termInYears) - 1
    return loanAmount * upper * interestRate / lower
  }

  /**
   * The interest for the given year
   */
  fun yearlyInterest(yearIndex: YearIndex): Money {
    return loanAmount * (q.pow(termInYears) - q.pow(yearIndex.index)) / (q.pow(termInYears) - 1) * interestRate
  }

  /**
   * The repayment for the given year
   */
  fun yearlyRepayment(yearIndex: YearIndex): Money {
    return loanAmount * (q - 1) / (q.pow(termInYears) - 1) * q.pow(yearIndex.index)
  }

  fun residualDebtAtStartOfYear(yearIndex: YearIndex): Money {
    if (yearIndex == YearIndex.base) {
      return loanAmount
    }

    return residualDebtAfterRepayment(yearIndex - 1)
  }

  /**
   * The residual debt after repayment (at the end of the year)
   */
  fun residualDebtAfterRepayment(yearIndex: YearIndex): Money {
    return loanAmount * (q.pow(termInYears) - q.pow(yearIndex.indexBase1)) / (q.pow(termInYears) - 1)
  }

  /**
   * Returns the monthly payment (interest and
   */
  fun monthlyPayment(): Money {
    return yearlyPayment() / 12.0
  }
}
