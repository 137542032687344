@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.ModuleSize
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.energy.Current
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.Voltage
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents a PV module type
 */
@Serializable
data class PvModule(
  val id: PvModuleId,

  /**
   * The name / label of the module
   */
  val description: String,

  /**
   * The power rating for this module
   */
  val powerRating: PowerRating,
  /**
   * The open circuit voltage
   */
  val offLoadVoltage: Voltage = Voltage(0.0),
  /**
   * The short circuit current
   */
  val shortCircuitCurrent: Current = Current(0.0),

  /**
   * The size of the module
   */
  val size: ModuleSize,

  val material: PvModuleTypeMaterial = PvModuleTypeMaterial.Normal,

  val warranty: Int = 30,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  /**
   * An id for a PvModuleType
   */
  @Serializable
  data class PvModuleId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): PvModuleId {
        return PvModuleId(randomUuid4())
      }
    }
  }
}

enum class PvModuleTypeMaterial {
  Normal,
  GlassGlass,
}
