package components.project.searchbar

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.processStates.ProcessStatesResolver
import it.neckar.react.common.*
import it.neckar.react.common.coroutines.*
import it.neckar.react.common.form.*
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.*
import react.*
import react.dom.*
import serialized.SerializedProjectPreview
import services.http.ProjectSearchSupport

private val logger: Logger = LoggerFactory.getLogger("components.project.searchbar.ProjectSearch")


val ProjectSearch: FC<ProjectSearchProps> = fc("ProjectSearch") { props ->
  val navigateAction = props::selectProjectAction.safeGet()
  val processStatesResolver = props::processStatesResolver.safeGet()

  val coroutineScope = useCoroutineScope()
  val searchString = useState("")

  /**
   * Contains the found projects - from the server
   */
  val foundProjects = useState(emptyList<SerializedProjectPreview>())


  //Create the project support once
  val projectSearchSupport = useMemo {
    ProjectSearchSupport().also {
      //Handle the search results
      coroutineScope.launch {
        it.searchResults.collectLatest { result ->
          foundProjects.setter(result.projects)
        }
      }
    }
  }

  FloatingInputField {
    attrs {
      value = searchString.value
      onChange = { newValue ->
        logger.debug("searchString changed to: ${searchString.value}")
        searchString.setter(newValue)
        projectSearchSupport.setSearchTerm(searchString.value)
      }
      fieldName = "projectSearch"
      title = "Projekt-Suche"
    }
  }

  div {
    ul("list-group") {
      foundProjects.value.forEach { projectPreview: SerializedProjectPreview ->
        li("list-group-item") {
          +projectPreview.getDisplayName(processStatesResolver)

          attrs {
            onClick = {
              navigateAction(projectPreview)
            }
          }
        }
      }
    }
  }
}

external interface ProjectSearchProps : Props {
  /**
   * Is called if the user clicks on a project.
   * Usually navigates to the provided project.
   */
  var processStatesResolver: ProcessStatesResolver
  var selectProjectAction: (project: SerializedProjectPreview) -> Unit
}
