package it.neckar.rest.pagination

import it.neckar.open.kotlin.lang.divCeil
import kotlinx.serialization.Serializable

/**
 * Represents the pagination information
 */
@Serializable
data class PaginationInfo(
  /**
   * The index of the current page
   */
  val pageIndex: PageIndex,

  /**
   * The max size of (all) pages
   */
  val pageSize: PageSize,

  /**
   * The number of pages
   */
  val pageCount: Int,

  /**
   * The filtered items count.
   * [pageSize] and [pageCount] are calculated based on this value.
   *
   * This is the total number of elements that are returned by the query (for all pages).
   * This is *not* the number of elements in this page.
   */
  val filteredElementsCount: Int,

  /**
   * The total number of elements.
   * Including filtered elements.
   */
  val totalElementsCount: Int,
) {

  /**
   * Returns the elements for the given page from the given list.
   */
  fun <T> selectElements(elements: List<T>): List<T> {
    if (pageSize == PageSize.Unlimited) {
      return elements
    }

    val start = pageIndex.value * pageSize.value
    val end = start + pageSize.value

    // If the start is greater than the size of the list, the page does not contain any elements
    if (start >= elements.size) {
      return emptyList()
    }

    return elements.subList(start, end.coerceAtMost(elements.size))
  }

  companion object {
    val empty: PaginationInfo = PaginationInfo(
      pageIndex = PageIndex.Zero,
      pageSize = PageSize.Unlimited,
      pageCount = 0,
      filteredElementsCount = 0,
      totalElementsCount = 0,
    )

    /**
     * Creates a new [PaginationInfo] instance.
     */
    fun singlePage(elements: List<*>, totalElementsCount: Int = elements.size): PaginationInfo {
      return singlePage(elements.size, totalElementsCount)
    }

    /**
     * Creates a pagination info for the given elements count.
     * Contains a single page with unlimited page size
     */
    fun singlePage(elementsCount: Int, totalElementsCount: Int = elementsCount): PaginationInfo {
      return PaginationInfo(
        pageIndex = PageIndex.Zero,
        pageSize = PageSize.Unlimited,
        pageCount = 1,
        filteredElementsCount = elementsCount,
        totalElementsCount = totalElementsCount,
      )
    }

    /**
     * Creates a [PaginationInfo] for the given elements, page index and page size.
     */
    fun create(filteredElementsCount: Int, pageIndex: PageIndex, pageSize: PageSize?, totalElementsCount: Int): PaginationInfo {
      if (pageSize == null) return singlePage(filteredElementsCount, totalElementsCount)

      return PaginationInfo(
        pageIndex = pageIndex,
        pageSize = pageSize,
        pageCount = filteredElementsCount.divCeil(pageSize.value),
        filteredElementsCount = filteredElementsCount,
        totalElementsCount = totalElementsCount,
      )
    }
  }
}
