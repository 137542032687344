package components.project.configuration.tabs

import components.project.QuoteConfigurationTabProps
import components.project.configuration.ProfitabilityTable
import components.project.configuration.isConfigurationEditable
import components.project.configuration.profitability.profitabilityPercentage
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.quote.CalculationRelevanceQuery
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.formatting.formatAsPercentage
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

val EconomicsOverviewFromUrl: FC<Props> = fc("EconomicsOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Wirtschaftlichkeit", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    EconomicsOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val EconomicsOverview: FC<QuoteConfigurationTabProps> = fc("EconomicsOverview") { props ->
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val quoteElements = if (editableStatus == EditableStatus.Editable) quoteConfiguration.calculatedQuoteElements else quoteConfiguration.quoteElements
  val economicsReport = quoteConfiguration.economicsReport


  div("col") {

    div("mb-3") {
      h3 {
        +"für PV-Anlage"
      }
      p {
        val net = quoteElements.netPricesForVats(sumQuery = LizergyCalculationCategories.Query.EconomicsCalculationRelevance).total
        val gross = quoteElements.grossPricesForVats(sumQuery = LizergyCalculationCategories.Query.EconomicsCalculationRelevance)
        +"PV: ${net.format(plannerI18nConfiguration)} | ${gross.total.format(plannerI18nConfiguration)}"
      }

      profitabilityPercentage(quoteConfiguration)

      div("col") {
        +"jährliche Rendite: "
        span {
          +economicsReport.calculativeProfitPerYear().formatAsPercentage()
        }
      }
    }

    div("mb-3") {
      h3 {
        +"Inklusive zusätzlicher Arbeiten"
      }
      p {
        val net = quoteElements.netPricesForVats(sumQuery = CalculationRelevanceQuery.defaultForSum).total
        val gross = quoteElements.grossPricesForVats(sumQuery = CalculationRelevanceQuery.defaultForSum)
        +"Gesamt: ${net.format(plannerI18nConfiguration)} | ${gross.total.format(plannerI18nConfiguration)}"
      }

    }

    div("mb-3") {
      h3 {
        +"Entwicklung:"
      }

    }
    div("mb-3") {
      div("table-responsive") {
        ProfitabilityTable {
          attrs {
            this.profitabilityTable = economicsReport.profitabilityTable
          }
        }
      }
    }

  }

}
