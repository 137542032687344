package services.http

import com.meistercharts.maps.RouteMapCoordinates
import io.ktor.client.*
import io.ktor.client.call.*
import it.neckar.customer.Address
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.postJson
import it.neckar.ktor.client.safeTryFetch
import it.neckar.lizergy.model.location.GeocodingRequest
import it.neckar.lizergy.model.location.GeocodingResponse
import it.neckar.lizergy.model.location.RouteInformationRequest
import it.neckar.lizergy.model.location.RouteInformationResponse

/**
 * Stores / retrieves information using REST
 */
class GeoInformationClientService(
  val httpClient: HttpClient,
  val urlSupport: PlannerUrlSupport,
) {

  suspend fun lookupCoordinates(address: Address): GeocodingResponse {
    return safeTryFetch(GeocodingResponse.failure()) {
      httpClient
        .postJson(urlSupport.geoInformation.geoCodeServiceUrl, GeocodingRequest(address)) {
          featureFlagsHeader()
        }.body()
    }
  }

  suspend fun lookupRoute(routeMapCoordinates: RouteMapCoordinates): RouteInformationResponse {
    return safeTryFetch(RouteInformationResponse.failure()) {
      httpClient
        .postJson(urlSupport.geoInformation.geoInformationServiceUrlOSRM, RouteInformationRequest(routeMapCoordinates)) {
          featureFlagsHeader()
        }.body()
    }
  }

}
