package components

import it.neckar.open.collections.fastForEach
import it.neckar.projects.pvplanner.PvPlannerService
import it.neckar.react.common.*
import react.*
import react.dom.*
import store.OnlineState
import store.useSelector

/**
 *
 */
val OnlineStateBanner: FC<Props> = fc("OnlineStateBanner") {
  val onlineState = useSelector { onlineState }

  when {
    onlineState.allOnlineOrUnknown() -> {
      //do nothing
    }

    onlineState.refreshRequired() -> {
      //Should not occur
      p {
        +"Refresh required!"
      }
    }

    else -> {
      notificationBanner("Server nicht erreichbar!", BannerType.Danger, BannerPlacement.Top) {
        p {
          +"Bitte stellen Sie sicher, dass Sie mit dem Internet verbunden sind."
        }
        p {
          +"Änderungen an Projekten können ohne eine aktive Verbindung nicht gespeichert werden!"
        }

        h4 {
          +"Detaillierter Status"
        }

        div("row") {
          div("col-sm-4") {
            table("table") {
              thead {
                tr {
                  th { +"Service" }
                  th { +"Status" }
                }
              }

              tbody {

                PvPlannerService.essentialEntries.fastForEach {
                  val onlineStateForService = onlineState[it]

                  val className = when (onlineStateForService) {
                    OnlineState.Offline -> "table-danger"
                    else -> ""
                  }

                  if (onlineStateForService == OnlineState.Offline) {
                    tr(className) {
                      td {
                        +it.name
                      }
                      td {
                        i(classes = "fa-solid fa-exclamation") {}

                        small("ms-2") {
                          +onlineStateForService.toString()
                        }
                      }
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }
}
