package it.neckar.financial.quote

import com.benasher44.uuid.Uuid
import it.neckar.commons.tags.Tags
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A section within a quote that contains further [QuoteElement]s
 */
@Serializable
@SerialName("QuoteSection")
data class QuoteSection(
  @Serializable(with = UuidSerializer::class) override val uuid: Uuid,

  /**
   * The headline
   */
  override val headline: String,

  override val amount: Amount? = null,

  override val visibility: Visibility,

  override val details: String? = null,

  /**
   * The children.
   * All children must share the same visibility!
   */
  val children: List<QuoteElement>,

  ) : QuoteElement {

  constructor(
    headline: String,
    amount: Amount? = null,
    visibility: Visibility,
    details: String? = null,
    children: List<QuoteElement>,
  ) : this(
    uuid = randomUuid4(),
    headline = headline,
    amount = amount,
    visibility = visibility,
    details = details,
    children = children,
  )

  init {
    require(headline.isNotBlank()) {
      "Headline must not be blank"
    }

    require(
      children.asSequence()
        .distinctBy { it.visibility }
        .count() <= 1
    ) {
      "Only children with the same visibility are supported! But was:\n${children.joinToString("\n") { "- ${it.visibility.name}: ${it.headline}" }}"
    }
  }

  override val optionality: Optionality
    get() = if (children.all { it.optionality == Optionality.Optional }) Optionality.Optional else Optionality.Mandatory

  override fun sums(sumQuery: CalculationRelevanceQuery): SumsForVATs {
    return SumsForVATs(children.map { quoteElement -> quoteElement.sums(sumQuery) })
  }

  override fun relevanceOrNull(): Tags? {
    return null
  }

}
