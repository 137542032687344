import components.AppHeader
import components.AppSidebar
import it.neckar.react.common.router.*
import it.neckar.react.common.router.key.*
import react.*
import react.dom.*
import react.router.*
import router.Navigation
import store.hooks.useRequireLoggedIn

/**
 * Handler for key strokes for navigation
 */
val navigationKeyStrokeHandler: NavigationKeyStrokeHandler = Navigation.generateKeyHandler()

/**
 * The main stage for Planner UI
 */
val MainStage: FC<Props> = fc("MainStage") {
  useRequireLoggedIn()

  KeyboardNavigation {
    attrs {
      handler = navigationKeyStrokeHandler
    }
  }

  div("container-fluid") {

    div("row min-vh-100") {
      /*
      min-vh-100 ensures that the entire height of the viewport is available in the row
      */
      div("col d-flex flex-column") {

        div("row header") { /* contains the header*/
          AppHeader {}
        }

        div("row flex-grow-1") {
          /*
          * flex-grow-1 ensures that the row uses all the available height regardless of content.
          * */
          div("col sidebar") { /*column which contains the sidebar*/
            /*d-none d-xl-block ensures that the column is hidden if the window size has a smaller breakpoint than xl*/
            AppSidebar {}
          }/*column which contains the container of the outlet*/

          div("col") {
            div("col-sm-12 main-content") {
              div("container") {
                main {

                  //Show the breadcrumb at the top
                  BreadcrumbBar {
                    attrs {
                      navigation = Navigation
                    }
                  }

                  //The content that is filled by the router
                  Outlet {}

                }
              }
            }
          }

        }
      }
    }
  }
}

