package components.project.searchbar

import it.neckar.react.common.*
import it.neckar.react.common.portal.*
import react.*

/**
 * Shows a search field button that opens a popup
 */
val ProjectSearchButton: FC<ProjectSearchFieldProps> = fc("ProjectSearchField") { props ->
  val idOfModal = "projectSearchModal"
  modalButton(idOfModal, FontAwesomeIcons.search, "btn btn-secondary") {
  }

  createPortalForModal {
    ProjectSearchModal {
      attrs {
        this.idOfModal = idOfModal
      }
    }
  }
}


external interface ProjectSearchFieldProps : Props {
}
