package components.sales

import components.project.HomepageProps
import components.project.homepageButtonForPhase
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.previews.ProjectQueryForUser
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry.DocumentationProcessStates
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry.FinalAccountProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.lizergy.model.project.process.state.onlyUnfinished
import react.*
import react.dom.*
import router.RouterUrls
import services.http.ProjectCountRequestForPhase
import store.hooks.useRequireLoggedInUser

val ClosureHomepage: FC<HomepageProps> = fc("ClosureHomepage") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val projectCountsForPhases = props::projectCountsForPhases.safeGet()


  div {

    h3("mb-3") {
      +"Projektabschluss"
    }

    div("row row-cols-1 gy-2") {
      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.finalAccount,
        title = "Abschlussrechnung",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.FinalAccount),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.documentation,
        title = "Dokumentation",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.Documentation),
      )

      if (loggedInUser.accessRights.canAccess(AccessRight.VerifyProjects)) {
        homepageButtonForPhase(
          url = RouterUrls.OverviewTableRouterUrls.verification,
          title = "Überprüfung",
          projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.Verification),
        )
      }
    }

  }
}

val ClosureCountRequest: List<ProjectCountRequestForPhase> = listOf(
  ProjectCountRequestForPhase(
    relevantProcessStates = FinalAccountProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.FinalAccount,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = DocumentationProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.Documentation,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = listOf(DocumentationProcessStates.Done, ProjectProcessStates.Archived),
    projectQueryComponent = ProjectQueryComponent.Verification,
    projectQueryForUser = ProjectQueryForUser.AssignedMaintainer,
  ),
)
