package it.neckar.lizergy.model.project

import com.benasher44.uuid.Uuid
import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.financial.quote.QuoteElements
import it.neckar.lizergy.model.assemblyPortfolio.ResolvedAssemblyPortfolio
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfigurations
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.lizergy.model.stumps.AdvanceInvoice
import it.neckar.lizergy.model.stumps.AssemblyBasement
import it.neckar.lizergy.model.stumps.AssemblyRoof
import it.neckar.lizergy.model.stumps.Documentation
import it.neckar.lizergy.model.stumps.FinalAccount
import it.neckar.lizergy.model.stumps.FinishingUp
import it.neckar.lizergy.model.stumps.GridAssessment
import it.neckar.lizergy.model.stumps.OrderSpecialMaterial
import it.neckar.lizergy.model.stumps.StartupOperations
import it.neckar.lizergy.model.stumps.SwitchMeterBox
import it.neckar.lizergy.model.validation.NeedsValidation
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.lizergy.model.validation.TitleMessage
import it.neckar.lizergy.model.validation.ValidationProblem
import it.neckar.lizergy.model.validation.ValidationProblems
import it.neckar.open.collections.fastForEach
import it.neckar.open.unit.si.ms
import it.neckar.processStates.ProcessStatePhase
import it.neckar.processStates.ProcessStatesResolver
import it.neckar.processStates.current
import it.neckar.user.UserLoginName

/**
 * Represents a UI project - containing all properties.
 *
 * Attention: Most of the parts are only optional and will be loaded lazily/later.
 *
 * All values that are null are not yet loaded!
 */
data class ResolvedProject(

  override val projectId: PhotovoltaicsProjectId,

  override val projectName: String,

  val projectDescription: String,

  val sellingCompanyInformation: PlannerCompanyInformation,

  override val customer: Customer,

  val maintainerInformation: UserInformation,

  val blueprint: ResolvedBlueprint,

  val quoteConfigurations: QuoteConfigurations,

  val orderSpecialMaterial: OrderSpecialMaterial,
  val gridAssessment: GridAssessment,
  val assemblyPortfolio: ResolvedAssemblyPortfolio,
  val advanceInvoice: AdvanceInvoice,
  val assemblyRoof: AssemblyRoof,
  val assemblyBasement: AssemblyBasement,
  val switchMeterBox: SwitchMeterBox,
  val startupOperations: StartupOperations,
  val finishingUp: FinishingUp,
  val finalAccount: FinalAccount,
  val documentation: Documentation,

  override val verification: Verification?,

  override val archiveReasons: ArchiveReasons,

  val creationTime: @ms Double,

  ) : PhotovoltaicsProject, BelongsToCompanyProfile, NeedsValidation {

  override val sellingCompanyProfile: CompanyProfile
    get() = sellingCompanyInformation.companyProfile

  override val sellingCompany: CompanyCode
    get() = sellingCompanyProfile.companyCode

  override val belongsToCompanies: Set<CompanyCode>
    get() = getBelongsToCompanyCodes(blueprint, quoteConfigurations.elements)

  override val maintainer: UserLoginName
    get() = maintainerInformation.loginName

  override val acquisitionDate: Double
    get() = blueprint.acquisitionDate

  @Deprecated("Being replaced by new Process State Service")
  val processState: PositionEditHistory<OLDProcessState>?
    get() = blueprint.processState?.plus(quoteConfigurations.validElements.maxByOrNull { it.processState?.currentValue ?: OLDProcessState.ProjectCreated }?.processState)

  fun getDisplayName(processStatesResolver: ProcessStatesResolver): String {
    return displayName(getLocation(processStatesResolver))
  }

  fun belongsToUser(userLoginName: UserLoginName, processStatesResolver: ProcessStatesResolver): Boolean {
    return belongsToMaintainer(userLoginName) || belongsToEditor(userLoginName, processStatesResolver)
  }

  @Deprecated("Replaced by new Process State Service")
  fun belongsToEditor(userLoginName: UserLoginName, processStatesResolver: ProcessStatesResolver): Boolean {
    return userLoginName == getEditor(processStatesResolver)
  }

  fun getCurrentQuoteConfiguration(processStatesResolver: ProcessStatesResolver): QuoteConfiguration? {
    var currentQuoteConfiguration: QuoteConfiguration? = null

    var currentProcessState: LizergyProcessStateEntry? = null
    quoteConfigurations.validElements.fastForEach { quoteConfiguration ->
      val processStateForConfiguration = (processStatesResolver.getOrNull(quoteConfiguration.uuid)?.validProcessStateEntries)?.current() as? LizergyProcessStateEntry
      currentProcessState.let {
        if (processStateForConfiguration != null && (it == null || processStateForConfiguration > it)) {
          currentQuoteConfiguration = quoteConfiguration
          currentProcessState = processStateForConfiguration
        }
      }
    }

    if (currentQuoteConfiguration == null) {
      var currentOLDProcessState: OLDProcessState? = null
      quoteConfigurations.validElements.fastForEach { quoteConfiguration ->
        val OLDprocessStateForConfiguration = quoteConfiguration.processState?.currentValue
        currentOLDProcessState.let {
          if (OLDprocessStateForConfiguration != null && (it == null || OLDprocessStateForConfiguration > it)) {
            currentQuoteConfiguration = quoteConfiguration
            currentOLDProcessState = OLDprocessStateForConfiguration
          }
        }
      }
    }

    return currentQuoteConfiguration
  }

  fun getLocation(processStatesResolver: ProcessStatesResolver): LocationInformation {
    return getCurrentQuoteConfiguration(processStatesResolver)?.location ?: blueprint.location
  }

  @Deprecated("Replaced by new Process State Service")
  fun getEditor(processStatesResolver: ProcessStatesResolver): UserLoginName? {
    return getCurrentQuoteConfiguration(processStatesResolver)?.editor ?: blueprint.editor
  }

  fun getQuoteElements(processStatesResolver: ProcessStatesResolver): QuoteElements? {
    return getCurrentQuoteConfiguration(processStatesResolver)?.quoteElements
  }

  fun getNetPrices(category: IncomePercentageCategory?, processStatesResolver: ProcessStatesResolver): Money? {
    val quoteElements = getQuoteElements(processStatesResolver) ?: return null
    if (category == null) return quoteElements.netPricesForVats().total
    return quoteElements.netPricesForVats(LizergyCalculationCategories.Query.Profits.get(category)).total
  }

  fun getEarningsDistribution(processStatesResolver: ProcessStatesResolver): ResolvedEarningsDistribution? {
    return getCurrentQuoteConfiguration(processStatesResolver)?.earningsDistribution
  }

  override val blueprintId: Uuid
    get() = blueprint.uuid
  override val configurationIds: List<Uuid>
    get() = quoteConfigurations.elements.map { it.uuid }
  override val orderSpecialMaterialId: Uuid
    get() = orderSpecialMaterial.uuid
  override val gridAssessmentId: Uuid
    get() = gridAssessment.uuid
  override val assemblyPortfolioId: Uuid
    get() = assemblyPortfolio.uuid
  override val advanceInvoiceId: Uuid
    get() = advanceInvoice.uuid
  override val assemblyRoofId: Uuid
    get() = assemblyRoof.uuid
  override val assemblyBasementId: Uuid
    get() = assemblyBasement.uuid
  override val switchMeterBoxId: Uuid
    get() = switchMeterBox.uuid
  override val startupOperationsId: Uuid
    get() = startupOperations.uuid
  override val finishingUpId: Uuid
    get() = finishingUp.uuid
  override val finalAccountId: Uuid
    get() = finalAccount.uuid
  override val documentationId: Uuid
    get() = documentation.uuid


  operator fun get(configurationId: PhotovoltaicsConfigurationId): QuoteConfiguration {
    return quoteConfigurations.find { it.configurationId == configurationId } ?: throw IllegalStateException("No QuoteConfiguration found for Id <$configurationId>")
  }

  fun isEveryOtherQuoteArchived(quoteConfiguration: QuoteConfiguration, processStatesResolver: ProcessStatesResolver): Boolean {
    val allOtherQuoteConfigurations = quoteConfigurations.withRemoved(quoteConfiguration).validElements
    return allOtherQuoteConfigurations.all { configuration ->
      val currentProcessStateForConfiguration = processStatesResolver[configuration.uuid].validProcessStateEntries.current()?.processState ?: configuration.processStateEdit?.value?.toNewProcessState()
      currentProcessStateForConfiguration == null || currentProcessStateForConfiguration.inPhase == ProcessStatePhase.Paused || currentProcessStateForConfiguration.inPhase == ProcessStatePhase.Rejected
    }
  }


  override val validationProblems: ValidationProblems
    get() = ValidationProblems(
      titleMessages = listOf(
        TitleMessage(null) { "Projekt sieht gut aus!" },
        TitleMessage(ProblemType.Error) { "Die folgenden $it Felder müssen noch ausgefüllt werden:" },
        TitleMessage(ProblemType.Warning) { "Die folgenden $it Felder sollten überprüft werden:" },
      ),
      ownProblems = listOf(
        ValidationProblem("Kundenname") { if (customer.hasPlausibleName.not()) ProblemType.Error else null },
        ValidationProblem("Rechnungsadresse") { if (customer.address.isPlausible.not()) ProblemType.Error else null },
      ),
    )

  fun getValidationProblems(configurationId: PhotovoltaicsConfigurationId?): ValidationProblems {
    val specificConfigurationProblems = configurationId?.let { quoteConfigurations.find { quoteConfiguration -> quoteConfiguration.configurationId == it } }?.let { listOf(it.validationProblems) }
    return validationProblems.copy(
      childProblems = specificConfigurationProblems ?: buildList {
        quoteConfigurations.fastForEach { add(it.validationProblems) }
      },
    )
  }

  fun getRelevantValidationProblems(): ValidationProblems {
    return if (quoteConfigurations.elements.isEmpty()) {
      blueprint.validationProblems
    } else {
      getValidationProblems(null)
    }
  }


  companion object {

    operator fun invoke(
      projectId: PhotovoltaicsProjectId,
      projectName: String,
      projectDescription: String,
      sellingCompany: PlannerCompanyInformation,
      customer: Customer,
      maintainer: UserInformation,
      blueprint: ResolvedBlueprint,
      quoteConfigurations: QuoteConfigurations,
      orderSpecialMaterial: OrderSpecialMaterial,
      gridAssessment: GridAssessment,
      assemblyPortfolio: ResolvedAssemblyPortfolio,
      advanceInvoice: AdvanceInvoice,
      assemblyRoof: AssemblyRoof,
      assemblyBasement: AssemblyBasement,
      switchMeterBox: SwitchMeterBox,
      startupOperations: StartupOperations,
      finishingUp: FinishingUp,
      finalAccount: FinalAccount,
      documentation: Documentation,
      verification: Verification,
      archiveReasons: ArchiveReasons,
      creationTime: @ms Double,
    ): ResolvedProject {
      return ResolvedProject(
        projectId = projectId,
        projectName = projectName,
        projectDescription = projectDescription,
        sellingCompanyInformation = sellingCompany,
        customer = customer,
        maintainerInformation = maintainer,
        blueprint = blueprint,
        quoteConfigurations = quoteConfigurations,
        orderSpecialMaterial = orderSpecialMaterial,
        gridAssessment = gridAssessment,
        assemblyPortfolio = assemblyPortfolio,
        advanceInvoice = advanceInvoice,
        assemblyRoof = assemblyRoof,
        assemblyBasement = assemblyBasement,
        switchMeterBox = switchMeterBox,
        startupOperations = startupOperations,
        finishingUp = finishingUp,
        finalAccount = finalAccount,
        documentation = documentation,
        verification = verification,
        archiveReasons = archiveReasons,
        creationTime = creationTime,
      )
    }

  }

}


fun ProjectConfiguration.getBelongsToCompanyCodes(blueprint: Blueprint, configurations: List<BelongsToCompany>): Set<CompanyCode> {
  return buildSet {
    add(sellingCompany)
    addAll(blueprint.belongsToCompanies)
    configurations.fastForEach {
      addAll(it.belongsToCompanies)
    }
  }
}
