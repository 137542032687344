package it.neckar.ktor.client

import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import it.neckar.rest.ClientId
import it.neckar.rest.tracking.XClientId


/**
 * Adds the Client ID to the request
 */
fun HttpClientConfig<*>.installClientIdHeader(clientId: ClientId = ClientId.random()) {
  install(DefaultRequest) {
    headers {
      append(HttpHeaders.XClientId, clientId.toString())
    }
  }
}
