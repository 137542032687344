@file:UseSerializers(UuidSerializer::class)

package it.neckar.customer.company

import it.neckar.customer.Address
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.UseSerializers

/**
 * Information about a company
 */
interface CompanyInformation : HasLifeCycle {
  /**
   * The code for the company
   */
  val companyProfile: CompanyProfile

  /**
   * The name of the company
   */
  val name: String

  /**
   * The address of the company
   */
  val address: Address

  /**
   * Contact information for the company
   */
  val contactInformation: ContactInformation

  /**
   * The bank information for the company
   */
  val bankInformation: BankInformation

  val legalInformation: CompanyLegalInformation

  /**
   * Returns the simple name of the company. This name can be used in the Quote when referencing the company.
   */
  val simpleName: String
    get() = name.removeSuffix("GmbH").trim()

  /**
   * Returns one line containing the name and basic address
   */
  fun oneLiner(delimiter: String = " * "): String {
    return "$name$delimiter${address.streetWithHouseNumber}$delimiter${address.cityWithZipCode}"
  }

  /**
   * Returns true if this is a partner company
   */
  fun isPartnerCompany(): Boolean {
    return companyProfile.isPartnerCompany()
  }

  /**
   * Returns true if this is a partner company of the provided main company code
   */
  fun isPartnerFor(mainCompanyCode: CompanyCode): Boolean {
    companyProfile.let {
      return it.isPartnerCompany() && it.parentCompany == mainCompanyCode
    }
  }
}
