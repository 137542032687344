@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.ElementsSelection
import it.neckar.lizergy.model.ElementsSelectionEntry
import it.neckar.lizergy.model.configuration.quote.builder.InverterSelection.InverterSelectionId
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents the selection of multiple inverters of multiple [Inverter]s
 */
@Serializable
data class ResolvedInverterSelection(
  override val id: InverterSelectionId,
  override val entries: List<ElementsSelectionEntry<Inverter>>,
) : InverterSelection, ElementsSelection<Inverter> {

  override fun Inverter.format(): String {
    return this.format()
  }

  /**
   * Creates a new [ResolvedInverterSelection] with the updated amount for the given [Inverter]
   */
  fun with(inverter: Inverter, amount: Int): ResolvedInverterSelection {
    val entriesMap = entries.associateBy {
      it.element
    }.toMutableMap()

    if (amount > 0) {
      entriesMap[inverter] = ElementsSelectionEntry(inverter, amount)
    } else {
      entriesMap.remove(inverter)
    }

    return ResolvedInverterSelection(id = id, entries = entriesMap.values.toList())
  }

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedInverterSelection {
    val newId = InverterSelectionId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(id = newId)
  }


  companion object {
    fun getEmpty(id: InverterSelectionId = InverterSelectionId.random()): ResolvedInverterSelection {
      return ResolvedInverterSelection(id = id, entries = emptyList())
    }
  }

}
