package it.neckar.lizergy.model.price.lizergy

import com.benasher44.uuid.uuidFrom
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.euro
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTile.MetalRoofTileId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile.RoofTileId
import it.neckar.lizergy.model.price.AvailableRoofTiles
import it.neckar.lizergy.model.price.InfoForType

/**
 * Creates the available roof tiles
 */
@Suppress("ObjectPropertyName", "SpellCheckingInspection", "NonAsciiCharacters")
object LizergyAvailableRoofTilesFactory {

  /**
   * All supported "Metalldachziegel"
   * This code snippet can be generated using a test
   */
  //val metalldachplatteSonderanfertigung: MetalRoofTileType = MetalRoofTileType(uuidFrom("d36083f4-bfe0-4989-87a8-ac879416f7bc"), "Metalldachplatte Sonderanfertigung")
  val metalldachplatteTypGrande290: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("d60514fa-7ade-4e3b-a128-ea2e4df85769")), "Metalldachplatte Typ Grande 290")
  val metalldachplatteTypGrande312: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("e7feb73e-d399-4d17-b8d9-01aaca0c31de")), "Metalldachplatte Typ Grande 312")
  val metalldachplatteTypTon270: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("ceacfb7e-20fa-4a5b-8cf7-69fefa1a6c61")), "Metalldachplatte Typ Ton 270")
  val metalldachplatteTypExTon260: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("00aa758d-ba31-4118-a7ef-2b5d3d38570b")), "Metalldachplatte Typ Ex Ton 260")
  val metalldachplatteTypGrande300: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("bef5c8b6-b190-49e0-b18c-9628657b1d57")), "Metalldachplatte Typ Grande 300")

  //val metalldachplatteTypGrande323: MetalRoofTileType = MetalRoofTileType(uuidFrom("a0b108cb-8609-4e42-a850-621dc75c7ae9"), "Metalldachplatte Typ Grande 323")
  val metalldachplatteTypTon280: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("c77743e6-7da9-427c-bff7-370604b4e6b4")), "Metalldachplatte Typ Ton 280")
  val metalldachplatteTypTon265: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("1178857a-bfd2-420b-a0ea-21266c05c6e1")), "Metalldachplatte Typ Ton 265")
  val metalldachplatteTypVario: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("0680a844-7393-4e7c-9a41-2c5cb1d2e1a8")), "Metalldachplatte Typ Vario")
  val metalldachplatteTypTon240: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("54d84797-9869-408e-9fc7-187142390d21")), "Metalldachplatte Typ Ton 240")
  val metalldachplatteTypTon261: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("e4cf1ed4-21c6-43be-8bd4-303f8122e22f")), "Metalldachplatte Typ Ton 261")
  val metalldachplatteTypGrande310Kurz: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("6bbfda19-77b3-49a9-a260-b5ee1324b2fa")), "Metalldachplatte Typ Grande310 kurz")
  val metalldachplatteTypBeton: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("661fe6df-9155-490a-8f5b-6cfe79bf1bf2")), "Metalldachplatte Typ Beton")

  //val metalldachplatteTypEXAT330: MetalRoofTileType = MetalRoofTileType(uuidFrom("4705b18c-f75e-4977-a0d2-2280902a567b"), "Metalldachplatte Typ EX AT 330")
  val metalldachplatteTypBetonBig: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("69bbe410-753d-48c2-b030-b2006efffc8d")), "Metalldachplatte Typ Beton big")
  val metalldachplatteTypTGL330: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("e4acc86d-da5f-4fa4-b024-1281100d36cd")), "Metalldachplatte Typ TGL 330")
  val metalldachplatteTypTon251: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("f3cde4a2-0dfa-4339-b455-b9f748cf8980")), "Metalldachplatte Typ Ton 251")
  val metalldachplatteTypTon260: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("cdcea4d2-d1fb-4ef1-a6a1-1347d253fbea")), "Metalldachplatte Typ Ton 260")
  val metalldachplatteTypTon250: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("47d5e107-c9d5-4dfc-a017-06ef3733f69a")), "Metalldachplatte Typ Ton 250")
  val metalldachplatteTypGrande28M58Verzinkt: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("4e9bc7ac-972c-4bbf-952c-9af6a42265b7")), "Metalldachplatte Typ Grande28M58")
  val metalldachplatteTypGrandeL360: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("a2624fcc-a229-4b4b-976d-6aa2230047b3")), "Metalldachplatte Typ Grande L 360")
  val metalldachplatteTypTon230: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("6144a1b5-df72-46ef-bd36-00b72cb80425")), "Metalldachplatte Typ Ton 230")
  val metalldachplatteTypTon220: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("e4db0881-8cb5-403b-92ef-689697bee929")), "Metalldachplatte Typ Ton 220")

  //val metalldachplatteTypGrande310Lang: MetalRoofTileType = MetalRoofTileType(uuidFrom("7d6538ed-34ef-4a82-a491-b372fac81c51"), "Metalldachplatte Typ Grande310 Lang")
  val metalldachplatteTypGrande280: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("416f9556-56cf-4859-929a-8c99a7c6f48f")), "Metalldachplatte Typ Grande 280")
  val metalldachplatteTypExAT260: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("641e20af-d168-46fa-81b8-1fe73e82fc38")), "Metalldachplatte Typ Ex AT 260")
  val metalldachplatteTypExtra270: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("6343da09-4fd5-4d5a-a0f4-23069c9ffac9")), "Metalldachplatte Typ Extra 270")
  val metalldachplatteTypTon260Z: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("96979297-98ee-4db3-8613-04407905a995")), "Metalldachplatte Typ Ton260 Z")
  val metalldachplatteTypRK255: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("6c36d4bd-63e5-4a4a-9c19-d0a621403bb1")), "Metalldachplatte Typ RK255")
  val metalldachplatteTypGrande313: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("416319fd-bd3f-4148-a7af-d6628d05ff61")), "Metalldachplatte Typ Grande 313")
  val metalldachplatteTypTon269: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("2775c67a-f595-4364-829d-77943b9899b6")), "Metalldachplatte Typ Ton 269")
  val metalldachplatteTypGrande330: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("4f92de9d-e1ff-4dde-b119-726060e45cce")), "Metalldachplatte Typ Grande 330")

  val metalldachplatteTypVario190: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("0f017ae6-27df-418c-8349-274bed27f3cc")), "Metalldachplatte Typ Vario190", LifeCycleState.EndOfLife)
  val metalldachplattenTypHofa: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("4a47552c-468c-4d66-b675-7a7bd8f31695")), "Metalldachplatte Typ Hofa", LifeCycleState.EndOfLife)
  val metalldachplatteTypExtra295: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("55976a1e-b2a0-4f11-9197-d151e07ad130")), "Metalldachplatte Typ Extra 295", LifeCycleState.EndOfLife)
  val metalldachplatteTypSchiefer250x380mmFürStockschraube: MetalRoofTile = MetalRoofTile(MetalRoofTileId(uuidFrom("f57a75cb-7bfe-465a-a789-d678873fe542")), "Metalldachplatte Typ Schiefer 250x 380mm für Stockschraube", LifeCycleState.EndOfLife)

  /**
   * Available roof tiles
   */
  //val achat10VBraas: RoofTileType = RoofTileType(uuidFrom("a6ce8173-6c41-4802-9257-0123460464f4"), "Achat 10V - Braas", metalldachplatteSonderanfertigung)
  val actua10Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("2b3f13d9-3521-4bfd-8c9d-775e6bb1fa3c")), "Actua 10 - Wienerberger", metalldachplatteTypGrande290)
  val alegraZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("73889c35-cbf2-4b6e-a343-702a2b55af63")), "Alegra - ZZ Wancor", metalldachplatteTypGrande312)
  val alegra10Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("83eb6c0a-5f39-4a0c-96b2-09d8b6e39eed")), "Alegra 10 - Wienerberger", metalldachplatteTypGrande312)
  val alegra12BogenWienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("c314c292-9960-4348-8380-a9d04f824463")), "Alegra 12 (Bogen) - Wienerberger", metalldachplatteTypTon270)
  val alegra15Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("5e465592-d70f-4836-9953-2962a3f451fa")), "Alegra 15** - Wienerberger", metalldachplatteTypExTon260)
  val arealJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("db8f9c73-104d-4131-8581-7268ff162b88")), "Areal - Jacobi Walther", metalldachplatteTypGrande312)
  val artesoZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("0ceddad4-4e11-435d-a42b-1b8ebd81c520")), "Arteso - ZZ Wancor", metalldachplatteTypGrande300)

  //val balanceCreaton: RoofTileType = RoofTileType(uuidFrom("de476b28-91cb-4bed-9e8a-737ab4a319d9"), "Balance - Creaton", metalldachplatteTypGrande323)
  val bariRöben: RoofTile = RoofTile(RoofTileId(uuidFrom("b2cd06ff-c840-4755-90fa-7e683658e28f")), "Bari - Röben", metalldachplatteTypTon280)
  val bergamoRöben: RoofTile = RoofTile(RoofTileId(uuidFrom("583ee80a-aac7-4a50-8799-35d232d4553e")), "Bergamo - Röben", metalldachplatteTypTon265)
  val biber190x400: RoofTile = RoofTile(RoofTileId(uuidFrom("892f1d14-aa54-45ac-9e4e-edb91089e677")), "Biber 190x400", metalldachplatteTypVario190)
  val biberfalzRundschnittTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("68819b44-161a-4a60-a2db-dfc53270948b")), "Biberfalz Rundschnitt - Tondach", metalldachplattenTypHofa)
  val biberschwanzZiegelErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("ef501294-42d8-47d4-be5f-6736d91925e8")), "Biberschwanz-Ziegel - Erlus", metalldachplatteTypVario)
  val biberschwanzziegelJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("51d88d3a-a3ba-4c1c-9007-ca86d05d1ff8")), "Biberschwanzziegel - Jacobi Walther", metalldachplatteTypVario)
  val biberschwanzziegelWienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("17031b05-da80-499e-a981-52a9ae81b0b9")), "Biberschwanzziegel - Wienerberger", metalldachplatteTypVario)
  val biberschwanzziegelKlassikCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("03ca4420-de92-40a7-9160-10c48035836d")), "Biberschwanzziegel Klassik - Creaton", metalldachplatteTypVario)

  //val biberTascheEckigTondach: RoofTileType = RoofTileType(uuidFrom("53de6025-75f2-43b6-ae37-93d368c3af96"), "Biber Tasche eckig - Tondach", metalldachplatteSonderanfertigung)
  //val biberTascheGerundetTondach: RoofTileType = RoofTileType(uuidFrom("ab7d9b96-5a27-4a66-9d2b-b4cc4a532ef6"), "Biber Tasche gerundet - Tondach", metalldachplatteSonderanfertigung)
  val bM72Rapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("8977e407-c4ef-49a5-8b34-f151d244c4ee")), "BM 72 - Rapperswil", metalldachplatteTypTon240)
  val bornanSchiebeziegelMorandiRapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("fd1a2817-433d-4d8f-a557-6c9786af1843")), "Bornan Schiebeziegel - Morandi Rapperswil", metalldachplatteTypTon261)
  val bornholmRöben: RoofTile = RoofTile(RoofTileId(uuidFrom("0284b492-0146-4684-bef2-dee3c6449c84")), "Bornholm - Röben", metalldachplatteTypGrande310Kurz)
  val bramacDolomitBramac: RoofTile = RoofTile(RoofTileId(uuidFrom("2c341294-abc2-4cc4-937e-742e2b7a264e")), "Bramac Dolomit - Bramac", metalldachplatteTypBeton)
  val bramacDonauBramac: RoofTile = RoofTile(RoofTileId(uuidFrom("02644632-db98-487b-8c62-f2049c05b062")), "Bramac Donau - Bramac", metalldachplatteTypBeton)

  //val bramacMarkantBramac: RoofTileType = RoofTileType(uuidFrom("d2a538af-f1f7-419c-9859-c963fea739a9"), "Bramac Markant - Bramac", metalldachplatteTypEXAT330)
  val bramacMAXBramac: RoofTile = RoofTile(RoofTileId(uuidFrom("fcc90364-ccb5-43c8-b79d-2a547fbe8c9c")), "Bramac MAX - Bramac", metalldachplatteTypBetonBig)
  val bramacMonteroBramac: RoofTile = RoofTile(RoofTileId(uuidFrom("326a6d5d-d32e-46c1-a6c1-e1d1e5e7da08")), "Bramac Montero - Bramac", metalldachplatteTypBeton)
  val bramacTegalitBramac: RoofTile = RoofTile(RoofTileId(uuidFrom("c5215937-f112-4e3a-ba3a-13844071bfb1")), "Bramac Tegalit - Bramac", metalldachplatteTypTGL330)
  val campaZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("71d03d39-8a3b-4efb-849d-a36daafd6aa0")), "Campa - ZZ Wancor", metalldachplatteTypTon251)
  val cantusCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("3c7188a0-f840-44cc-8a20-d7e4b1393208")), "Cantus - Creaton", metalldachplatteTypTon265)
  val cosmo12Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("136ecce4-8406-4187-9746-0c9892ff00b6")), "Cosmo 12 - Wienerberger", metalldachplatteTypTon260)
  val cosmo15Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("dcc88b13-a6ee-4b28-8a9e-5b282c51df15")), "Cosmo 15 - Wienerberger", metalldachplatteTypTon240)
  val d10GigantJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("629b80c6-bfd4-44a1-9d50-829f4280dace")), "D 10 Gigant - Jacobi Walther", metalldachplatteTypGrande300)
  val d13D13ÜNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("5dbaac86-f2d2-462c-9aef-9bda460de0c2")), "D13 / D13 Ü - Nelskamp", metalldachplatteTypTon251)
  val d15ÜNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("1d39ea56-8d7b-47fe-8d3c-af4273f276eb")), "D 15 Ü - Nelskamp", metalldachplatteTypTon250)
  val dominoCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("02551521-2ae6-4de0-a47b-1f56cca7c98a")), "Domino - Creaton", metalldachplatteTypTon251)
  val doppelSBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("6eabd837-4289-4f53-a730-5b2fe0311e5a")), "Doppel-S - Braas", metalldachplatteTypBeton)
  val e58Erlus: RoofTile = RoofTile(RoofTileId(uuidFrom("6c8162da-4322-4fbf-a3ba-a3c1b87c99ce")), "E58 - Erlus", metalldachplatteTypTon260)
  val e58MAXErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("0ef57b9b-15e6-45f6-a1af-44be53e0776d")), "E58 MAX - Erlus", metalldachplatteTypGrande28M58Verzinkt)
  val e58PLUSErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("6438313c-ca3e-4841-a8e5-8b189e931c26")), "E58 PLUS - Erlus", metalldachplatteTypGrandeL360)
  val e58SErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("f13a8956-44e3-4e41-9107-29bf4ac717e4")), "E58 S - Erlus", metalldachplatteTypTon260)
  val e58SLErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("26cea14e-f467-4e5a-89e7-de18f7eb7036")), "E58 SL - Erlus", metalldachplatteTypGrande28M58Verzinkt)
  val eleganzCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("3e5fb74a-9344-4065-9304-380ca3356d1e")), "Eleganz - Creaton", metalldachplatteTypTon230)
  val elsassRöben: RoofTile = RoofTile(RoofTileId(uuidFrom("6f15ea8f-b3cf-476e-8a55-277717f1d24a")), "Elsass - Röben", metalldachplatteTypTon240)
  val europaEternit: RoofTile = RoofTile(RoofTileId(uuidFrom("06a0b515-1817-4028-b968-4e0c0dd131b0")), "Europa - Eternit", metalldachplatteTypBeton)
  val f10ÜNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("d030ed7c-64e2-4c67-98b3-2f6a727c12f6")), "F10 Ü - Nelskamp", metalldachplatteTypGrande290)
  val f10ÜNibraNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("f9882612-a043-441d-adc1-64a69f294f2f")), "F10 Ü - Nibra/Nelskamp", metalldachplatteTypGrande290)
  val f12ÜNordNibraNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("6934afb5-6ad0-4feb-bf04-0e9a013009ac")), "F12 Ü Nord - Nibra/Nelskamp", metalldachplatteTypGrande290)
  val f12ÜSüdNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("17917296-63bb-4fdf-a350-66da7f88ae23")), "F12 Ü Süd ** - Nelskamp", metalldachplatteTypGrande290)
  val f13ClassicNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("d2c8cf88-36a6-42bd-9057-a7ec37460f4a")), "F 13 Classic - Nelskamp", metalldachplatteTypTon260)
  val f14Nelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("181783ed-ac86-47d4-aef7-8e33f232475d")), "F 14 - Nelskamp", metalldachplatteTypTon260)
  val f15Nelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("a27852eb-ecab-4f08-8697-681a208b833b")), "F 15 - Nelskamp", metalldachplatteTypTon260)
  val falzziegelErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("11f368ce-bb70-4975-b964-bcb4997718a5")), "Falzziegel - Erlus", metalldachplatteTypTon220)
  val famaZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("2ea873f9-095c-42c6-8b32-e40cfe7a3577")), "Fama - ZZ Wancor", metalldachplatteTypTon251)
  val fidelioTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("d90ade26-1e0b-4f0a-9802-f6f705d74bbf")), "Fidelio - Tondach", metalldachplatteTypTon265)
  val figaroTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("45e5c4ac-3aad-4e2a-a537-6727ada6cf1d")), "Figaro - Tondach", metalldachplatteTypTon265)
  val figaroDELUXETondach: RoofTile = RoofTile(RoofTileId(uuidFrom("f5c57751-40ba-4500-bc8c-7eefce6c9313")), "Figaro DELUXE - Tondach", metalldachplatteTypTon240)
  val finkenbergerPfanneNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("450e4078-6693-4856-9877-483e6ec5d766")), "Finkenberger Pfanne - Nelskamp", metalldachplatteTypBeton)
  val flachdachpfanneBerchtold: RoofTile = RoofTile(RoofTileId(uuidFrom("1f5e3a26-08cc-4baf-b43d-bb7323028efd")), "Flachdachpfanne - Berchtold", metalldachplatteTypTon250)
  val flandernRöben: RoofTile = RoofTile(RoofTileId(uuidFrom("2541c1d6-8b9d-494a-bdfe-087b32810c76")), "Flandern - Röben", metalldachplatteTypTon260)

  //val flandernPLUSRöben: RoofTileType = RoofTileType(uuidFrom("07aabe63-c450-4234-ae74-f212cd4e6212"), "Flandern PLUS - Röben", metalldachplatteTypGrande310Lang)
  val formaErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("ff0ebe3d-036c-4c0f-835f-dacd08ca93d9")), "Forma - Erlus", metalldachplatteTypGrande290)
  val frankfurterPfanneBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("3637b428-b14b-41b6-8ef2-ddc9dd98ad00")), "Frankfurter Pfanne - Braas", metalldachplatteTypBeton)
  val fS03Rapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("8b90d91c-2a33-4df6-b7b8-50efdeadf43c")), "FS 03 - Rapperswil", metalldachplatteTypTon251)
  val futuraCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("4a70f1c3-a43c-48e8-ba01-2ff2daa07079")), "Futura - Creaton", metalldachplatteTypGrande280)
  val granat11VBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("e66bfd86-1662-4251-ac0a-d22bb2a146d4")), "Granat 11V - Braas", metalldachplatteTypTon265)
  val granat1313VBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("1873de84-9b00-4279-9cf8-8aee01cb8c3a")), "Granat 13 /13V - Braas", metalldachplatteTypTon251)
  val granat15VBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("fe2cf540-19d1-443a-b381-4b7d32aaed95")), "Granat 15V - Braas", metalldachplatteTypTon240)
  val gratusCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("7ed85f4f-acbe-4cbf-bdea-522c0d29cfda")), "Gratus - Creaton", metalldachplatteTypTon261)
  val großfalzziegelErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("1fc18bf5-7197-44f4-b674-fd1849314e28")), "Großfalzziegel - Erlus", metalldachplatteTypTon251)
  val großfalzziegelXXLErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("20e364bb-6f6e-4921-81f4-7c9bdee1f87c")), "Großfalzziegel XXL - Erlus", metalldachplatteTypGrande290)
  val göteborgCreatonEternit: RoofTile = RoofTile(RoofTileId(uuidFrom("922bf8ba-b33d-4025-9adf-96831f289a82")), "Göteborg - Creaton-Eternit", metalldachplatteTypBeton)

  //val h1JacobiWalther: RoofTileType = RoofTileType(uuidFrom("9b2d9dc8-9b28-4e42-82c2-df266ae32eb2"), "H 1 - Jacobi Walther", metalldachplatteSonderanfertigung)
  //val h2JacobiWalther: RoofTileType = RoofTileType(uuidFrom("43a34626-c085-4ede-8248-2e1bbddf4994"), "H 2 - Jacobi Walther", metalldachplatteSonderanfertigung)
  val harmonieCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("58fc5ca8-60c9-4bdd-8c6e-b57e5f7e9352")), "Harmonie - Creaton", metalldachplatteTypExTon260)
  val harzerPfanneBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("90e13f6d-bc63-42bd-a089-8dc5f3cb7073")), "Harzer Pfanne - Braas", metalldachplatteTypBeton)
  val harzerPfanne7BIGBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("137d6a88-2992-4458-8a5c-c31a00cbf259")), "Harzer Pfanne 7 (BIG) - Braas", metalldachplatteTypBetonBig)
  val heidelbergCreatonEternit: RoofTile = RoofTile(RoofTileId(uuidFrom("6d5fed54-ad13-4e2a-80d0-ae5f76cfde52")), "Heidelberg - Creaton Eternit", metalldachplatteTypBeton)
  val heidelbergerEternit: RoofTile = RoofTile(RoofTileId(uuidFrom("ec9a14ba-9433-4b31-8fa7-884beadacc71")), "Heidelberger - Eternit", metalldachplatteTypBeton)
  val herzziegelCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("450a1106-755a-492d-a745-5c973b18bf51")), "Herzziegel - Creaton", metalldachplatteTypTon230)
  val herzziegelMeindl: RoofTile = RoofTile(RoofTileId(uuidFrom("797b9190-a1df-4291-b425-b913bcbf3a0e")), "Herzziegel - Meindl", metalldachplatteTypTon265)
  val hohlstrangfalzWienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("6d75eb8f-a7dc-47d4-bfd1-ee170b806efc")), "Hohlstrangfalz - Wienerberger", metalldachplattenTypHofa)
  val ikarusTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("cdadaace-4d71-4453-9282-7eee3f4ff74f")), "Ikarus - Tondach", metalldachplatteTypTon240)
  val j11VVariwellJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("ba6b0766-8a2b-4a77-b450-f08fba9b0555")), "J 11 V (variwell) - Jacobi Walther", metalldachplatteTypTon270)
  val j13VJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("9fc2f4b2-3b57-4630-a38b-f7480250a186")), "J 13 V - Jacobi Walther", metalldachplatteTypExAT260)
  val j72JuraRapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("9def083c-74e9-4637-bdd2-26c2cd268f4a")), "J72 Jura - Rapperswil", metalldachplatteTypTon251)
  val juraZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("b3133ba0-32b8-4e02-a872-d3385bfe4cf6")), "Jura - ZZ Wancor", metalldachplatteTypTon251)
  val kapstadtCreatonEternit: RoofTile = RoofTile(RoofTileId(uuidFrom("a92f15d9-ab6d-4abe-9dfc-1ec5b2620abd")), "Kapstadt - Creaton Eternit", metalldachplatteTypTGL330)
  val karatErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("f3e3d6ff-11e7-4eba-85e7-9a7bcfa2fba5")), "Karat - Erlus", metalldachplatteTypExtra270)
  val karatXXLErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("15fe4dc0-1282-47f7-8227-83ead756ab99")), "Karat XXL - Erlus", metalldachplatteTypExtra295)
  val kronenPfanneNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("e78872f5-10da-4bd1-9258-bdf7854ce37e")), "Kronen-Pfanne - Nelskamp", metalldachplatteTypBeton)
  val l15MödingLangenzennWienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("688773ce-18aa-434a-83db-ba4dcbf3463f")), "L 15 Möding/Langenzenn - Wienerberger", metalldachplatteTypTon260Z)
  val landdachTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("64af788f-e082-406a-9be9-be14d2f97973")), "Landdach - Tondach", metalldachplatteTypTon240)
  val landdachVerschiebeziegelTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("2421469b-b641-4113-b262-3807cb0c24e1")), "Landdach Verschiebeziegel - Tondach", metalldachplatteTypTon240)
  val lineaErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("01819fb5-7dcd-43aa-92f3-96976a871d7f")), "Linea** - Erlus", metalldachplatteTypGrande290)
  val m72Rapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("80482256-7348-4865-9157-82c2023bfc0c")), "M 72 Rapperswil", metalldachplatteTypTon240)
  val magnumCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("327ca0c6-c40d-4c65-b3ef-aaff38fb0b9c")), "Magnum* - Creaton", metalldachplatteTypGrandeL360)
  val markoJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("cc9c6ef8-f64b-4a92-9efc-f681fe6b5bb3")), "Marko - Jacobi Walther", metalldachplatteTypTon260Z)
  val maximaCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("a835067e-3bac-45f2-9516-f187d28a7429")), "Maxima** - Creaton", metalldachplatteTypBeton)
  val megatonMeindl: RoofTile = RoofTile(RoofTileId(uuidFrom("0a8d8452-ae65-4ac0-8d24-a078a3ace356")), "Megaton - Meindl", metalldachplatteTypTon265)
  val monacoErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("f75e9206-8175-42be-942e-992afcea8540")), "Monaco - Erlus", metalldachplatteTypRK255)
  val mondo11L42Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("e21a5b99-3cdc-4450-9d1a-5adf6e91c5f2")), "Mondo 11 (L 42) - Wienerberger", metalldachplatteTypTon265)
  val mondo12Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("0ca08a30-c182-43ad-baad-d60c93b6efba")), "Mondo 12 - Wienerberger", metalldachplatteTypTon250)
  val mondo15Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("f1f003a7-37da-4bb5-a9d5-9920e82837fd")), "Mondo 15 - Wienerberger", metalldachplatteTypTon230)

  //val monzaPLUSRöben: RoofTileType = RoofTileType(uuidFrom("e8d088e2-f38a-4243-8031-539983a87c73"), "Monza PLUS - Röben", metalldachplatteTypGrande310Lang)
  val morandiForceDeLaTerreMorandiRapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("13714069-1b6f-4905-b163-791934307384")), "Morandi force de la Terre - Morandi Rapperswil", metalldachplatteTypTon261)
  val morandiHerzziegelMorandiRapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("ad779756-109a-41d3-8c95-d09484255361")), "Morandi Herzziegel - Morandi Rapperswil", metalldachplatteTypTon261)
  val morandiJoranMorandiRapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("253b55fd-5e49-435d-a06c-e0365ee0f906")), "Morandi Joran - Morandi Rapperswil", metalldachplatteTypTon261)
  val mS95Rapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("aa42bb7e-2074-41d0-8584-c56034a7efaf")), "MS 95 - Rapperswil", metalldachplatteTypTon240)
  val mZ327x445Creaton: RoofTile = RoofTile(RoofTileId(uuidFrom("637342ba-5129-450c-857b-4b3a2185d499")), "MZ3 27x44,5 - Creaton", metalldachplatteTypGrande310Kurz)
  val mZ3Meindl255x42Creaton: RoofTile = RoofTile(RoofTileId(uuidFrom("03da8a10-ad61-4b85-87ae-e42e9e6209f7")), "MZ3 Meindl 25,5x42 - Creaton", metalldachplatteTypTon260Z)
  val mZ3NEUDorfen26x415Creaton: RoofTile = RoofTile(RoofTileId(uuidFrom("4f340512-5220-46b2-8deb-5500ab186a5a")), "MZ3 NEU Dorfen 26x41,5 - Creaton", metalldachplatteTypTon260Z)
  val mZ5Meindl: RoofTile = RoofTile(RoofTileId(uuidFrom("d76a817c-35b3-41a7-9831-a88490b3881e")), "MZ 5 - Meindl", metalldachplatteTypTon265)
  val nevaZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("0e25e957-c3e2-45d9-a052-50b8f0738ccd")), "Neva - ZZ Wancor", metalldachplatteTypTon251)
  val nibraDS10Nelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("1005bd7f-a188-4bde-a656-ab6eec263c2a")), "Nibra DS 10 - Nelskamp", metalldachplatteTypGrande290)
  val nibraG10NEUNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("3c1bee1f-b9e7-471c-9c25-997c2e75217a")), "Nibra G 10 (NEU) - Nelskamp", metalldachplatteTypGrande300)
  val nibraKombiZiegelR10Nelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("d3aa7ebd-4d78-4bc0-bd13-903d8817e2a1")), "Nibra Kombi-Ziegel R10 - Nelskamp", metalldachplatteTypGrande290)
  val normaTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("f18d4c1e-4183-48fe-b7d3-2335f9a2c68b")), "Norma - Tondach", metalldachplatteTypTon265)
  val p72Rapperswil: RoofTile = RoofTile(RoofTileId(uuidFrom("fcd9f7c8-7f46-42b9-b01e-5665555e5ba1")), "P 72 - Rapperswil", metalldachplatteTypTon260)
  val padaZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("ef13aa2a-5a94-414b-a582-0ed96264ca68")), "Pada** - ZZ Wancor", metalldachplatteTypTon260)

  //val piemontRöben: RoofTileType = RoofTileType(uuidFrom("4761fc64-2b13-4372-be5f-671d50029cf8"), "Piemont - Röben", metalldachplatteTypGrande310Lang)
  val planoZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("a50ff174-065a-4f08-a89d-4e35cf348681")), "Plano - ZZ Wancor", metalldachplatteTypGrande300)

  //val plano11Wienerberger: RoofTileType = RoofTileType(uuidFrom("e7879f6d-7eb4-4bb7-a961-8656614fc51d"), "Plano 11 - Wienerberger", metalldachplatteSonderanfertigung)
  val planumNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("a23c7b1c-b788-4c49-85a6-08e81598b123")), "Planum - Nelskamp", metalldachplatteTypTGL330)
  val premionCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("b6863550-7194-434d-8524-97dd389aebb0")), "Premion - Creaton", metalldachplatteTypTon280)
  val r13SNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("aa98fb3a-1720-4191-bd18-56893ce4e52c")), "R13 S - Nelskamp", metalldachplatteTypTon250)
  val ratioCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("ec225257-8a73-4d66-b3dc-a08460f0205f")), "Ratio - Creaton", metalldachplatteTypTon265)
  val reformpfanneBerchtold: RoofTile = RoofTile(RoofTileId(uuidFrom("603fc8d0-f178-4551-9fe2-4700d3a4cef7")), "Reformpfanne - Berchtold", metalldachplatteTypTon230)
  val reformpfanneSLErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("aadb3b1a-3bd9-41d2-bcfb-f9ead2990654")), "Reformpfanne SL - Erlus", metalldachplatteTypTon260)
  val reformpfanneXXLErlus: RoofTile = RoofTile(RoofTileId(uuidFrom("506ede51-4d68-4a5a-ac70-53fcf6d58d4b")), "Reformpfanne XXL - Erlus", metalldachplatteTypGrande300)
  val rheinlandRöben: RoofTile = RoofTile(RoofTileId(uuidFrom("e770d791-c535-41f1-951b-6311aae22af2")), "Rheinland - Röben", metalldachplatteTypTon240)
  val romaZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("480a2850-3c3b-4842-af0b-e1fc61617397")), "Roma** - ZZ Wancor", metalldachplatteTypGrande280)
  val romanoCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("c2911175-b0be-48ab-8901-3a1dd62bdfce")), "Romano - Creaton", metalldachplatteTypRK255)
  val rombusEternit: RoofTile = RoofTile(RoofTileId(uuidFrom("73ea5ebd-95f5-4445-97c9-6c73c9c62715")), "Rombus - Eternit", metalldachplatteTypSchiefer250x380mmFürStockschraube)
  val rubin9VBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("10b54485-d2d4-43fe-ade7-f2505686ec57")), "Rubin 9V - Braas", metalldachplatteTypGrande313)
  val rubin11VHainstädterBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("c83f4460-5637-45f7-a296-f2151c2a0493")), "Rubin 11V (Hainstädter) - Braas", metalldachplatteTypTon280)
  val rubin13Braas: RoofTile = RoofTile(RoofTileId(uuidFrom("18ae9f5e-6488-46d1-b250-c80f6c315fe6")), "Rubin 13 - Braas", metalldachplatteTypTon260)
  val rubin13VBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("3b02c192-8606-4d4a-bca4-57eee15bb652")), "Rubin 13V - Braas", metalldachplatteTypGrande310Kurz)
  val rubin15Braas: RoofTile = RoofTile(RoofTileId(uuidFrom("496b9e41-3ca1-4a59-9231-cc8553789f0a")), "Rubin 15 - Braas", metalldachplatteTypTon260Z)
  val rubin15VBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("7b1438a8-ff8e-418a-98ed-5deba4fd5b12")), "Rubin 15V - Braas", metalldachplatteTypExTon260)
  val rusticoCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("b0345648-ab4f-4d31-8a15-336843f5a5d1")), "Rustico - Creaton", metalldachplatteTypTon220)
  val sPfanneNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("fb32d330-bd1f-4234-bbe0-48b112db7444")), "S-Pfanne - Nelskamp", metalldachplatteTypBeton)
  val sigmaPfanneNelskamp: RoofTile = RoofTile(RoofTileId(uuidFrom("57c24b78-f1e9-46bf-b9a2-d00122ddebc4")), "Sigma Pfanne - Nelskamp", metalldachplatteTypBeton)
  val standardpfanneBerchtold: RoofTile = RoofTile(RoofTileId(uuidFrom("a3020f85-1dd8-47f5-9221-60f1bf9848cc")), "Standardpfanne - Berchtold", metalldachplatteTypTon250)
  val steinbrückTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("3820e6cd-dbdd-4f89-8eec-7b3811ba01c3")), "Steinbrück - Tondach", metalldachplattenTypHofa)

  //val strangfalzEternit: RoofTileType = RoofTileType(uuidFrom("4b04ec9c-b679-4a28-9017-08ec2a80ee6a"), "Strangfalz - Eternit", metalldachplatteTypEXAT330)
  val strangfalzGepresstTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("a43bb05d-88c7-4b6b-bf9d-e57e77d0e342")), "Strangfalz gepresst** - Tondach", metalldachplatteTypTon240)
  val stylistJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("f254ca23-1e24-4b40-9cfb-dfa47675f787")), "Stylist - Jacobi Walther", metalldachplatteTypTon261)
  val sulmVerschiebeziegelTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("7c9d57a4-6ca0-4ffc-960b-088ab19e5565")), "Sulm Verschiebeziegel - Tondach", metalldachplatteTypExAT260)
  val swissTLZZWancor: RoofTile = RoofTile(RoofTileId(uuidFrom("fb6333a1-2aba-4816-a3b5-885390c2bbf2")), "Swiss TL - ZZ Wancor", metalldachplatteTypGrande300)
  val taunusPfanneBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("0c1f25c3-2430-4d59-ad79-fc79d0d65529")), "Taunus Pfanne - Braas", metalldachplatteTypBeton)
  val tegalitBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("29a09816-46c7-45bd-84e4-1cc5e4f12037")), "Tegalit - Braas", metalldachplatteTypTGL330)
  val tegulaJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("589e1223-ff35-47b4-a4c6-733a8400ca13")), "Tegula - Jacobi Walther", metalldachplatteTypTon261)
  val terraOptimaCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("72f727bd-6361-4349-9ebd-204cf9023740")), "Terra Optima - Creaton", metalldachplatteTypTon265)
  val terraVivaCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("43d33def-c6df-40ff-8617-dddbf49f87bf")), "Terra Viva - Creaton", metalldachplatteTypTon260Z)

  //val titaniaCreaton: RoofTileType = RoofTileType(uuidFrom("efaf187a-8d53-418d-b42e-327977750513"), "Titania - Creaton", metalldachplatteTypGrande323)
  val topas11VBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("29fb8ca3-1650-4b1b-a423-993628863ad0")), "Topas 11V - Braas", metalldachplatteTypTon265)
  val topas13V13Braas: RoofTile = RoofTile(RoofTileId(uuidFrom("054bfb73-f334-442d-a375-6d3e311bb668")), "Topas 13V/13 - Braas", metalldachplatteTypTon261)
  val topas15VBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("f5a928ba-51a4-42b9-85bd-09f1d14770ff")), "Topas 15V - Braas", metalldachplatteTypTon240)
  val tradi12TradiNova: RoofTile = RoofTile(RoofTileId(uuidFrom("d5906466-da74-4302-b4c2-58967c919111")), "Tradi 12 (Tradi Nova)", metalldachplatteTypTon265)
  val tradi15Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("4fc0057a-528e-423b-aff5-7ef741749b01")), "Tradi 15 - Wienerberger", metalldachplatteTypTon230)
  val turmalinBraas: RoofTile = RoofTile(RoofTileId(uuidFrom("b5f287b6-2e24-45fa-9f73-9332f34cf2e3")), "Turmalin - Braas", metalldachplatteTypTon269)
  val universo10Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("7069b358-92bd-4b1a-8045-f3a62b2fb541")), "Universo 10 - Wienerberger", metalldachplatteTypGrande312)
  val universo14Wienerberger: RoofTile = RoofTile(RoofTileId(uuidFrom("3bf8fdfa-a68b-4c5a-a73b-929204b61ca8")), "Universo 14 - Wienerberger", metalldachplatteTypTon260)
  val veronaCreatonEternit: RoofTile = RoofTile(RoofTileId(uuidFrom("b22de4e5-1b5d-4c36-9545-7f2ae3b0d603")), "Verona - Creaton Eternit", metalldachplatteTypGrande330)
  val vivaNEUCreaton: RoofTile = RoofTile(RoofTileId(uuidFrom("69def190-31ac-4e84-a6bc-3a58abeda6f5")), "Viva NEU - Creaton", metalldachplatteTypTon260)
  val w4JacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("a4778296-095f-4f36-80e2-44a983cca2b3")), "W 4 - Jacobi Walther", metalldachplatteTypTon260Z)
  val w5JacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("4ed77bb5-2356-4eb2-90a8-3396cef67297")), "W 5 - Jacobi Walther", metalldachplatteTypTon260Z)
  val w6VJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("b995cd8d-493a-4eaf-be08-24c910878416")), "W 6 V - Jacobi Walther", metalldachplatteTypTon260Z)
  val wienerNormaTondach: RoofTile = RoofTile(RoofTileId(uuidFrom("35236e90-dd12-43f8-bae2-ea23709dda89")), "Wiener Norma - Tondach", metalldachplatteTypTon265)
  val z1225x410mmMühlacker: RoofTile = RoofTile(RoofTileId(uuidFrom("ceb89d87-7cb6-46d1-b90d-8142a27aaad4")), "Z 1 (225x410mm) - Mühlacker", metalldachplatteTypTon220)
  val z1230x410mmMühlacker: RoofTile = RoofTile(RoofTileId(uuidFrom("ae0f6d3a-3170-49c0-993e-8197ede0630d")), "Z 1 (230x410mm) - Mühlacker", metalldachplatteTypTon230)
  val z1Winnenden: RoofTile = RoofTile(RoofTileId(uuidFrom("42610dd4-6eac-4cc7-a884-6d66bac7f44d")), "Z 1 - Winnenden", metalldachplatteTypTon220)
  val z2JacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("1001db44-17e5-4780-9e94-4709d7bcd401")), "Z 2 - Jacobi Walther", metalldachplatteTypTon251)
  val z4NWinnenden: RoofTile = RoofTile(RoofTileId(uuidFrom("9b1195a5-1891-41ca-bd26-ea1447260ecb")), "Z 4 N - Winnenden", metalldachplatteTypTon260Z)
  val z7VJacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("1d0100da-3b5b-4338-83bc-6c03f26601d1")), "Z 7 V - Jacobi Walther", metalldachplatteTypTon240)
  val z10JacobiWalther: RoofTile = RoofTile(RoofTileId(uuidFrom("80a75857-7777-4369-8d4c-017d65c85989")), "Z 10 - Jacobi Walther", metalldachplatteTypGrande290)
  val z15Mühlacker: RoofTile = RoofTile(RoofTileId(uuidFrom("64ef6b15-c8f6-4aab-b501-13bb64f8a619")), "Z 15 - Mühlacker", metalldachplatteTypTon260Z)
  val z16Mühlacker: RoofTile = RoofTile(RoofTileId(uuidFrom("ec9481ff-8507-4d29-9205-62cdf518ca4d")), "Z 16 - Mühlacker", metalldachplatteTypTon230)
  val z17Mühlacker: RoofTile = RoofTile(RoofTileId(uuidFrom("9dc4810d-8536-4c3d-b3ed-2fe4a10c1c4d")), "Z 17 - Mühlacker", metalldachplatteTypTon260)
  val z18Mühlacker: RoofTile = RoofTile(RoofTileId(uuidFrom("de180bfa-0695-40a7-a584-af22dbbd1810")), "Z 18 - Mühlacker", metalldachplatteTypTon260)
  val z2000: RoofTile = RoofTile(RoofTileId(uuidFrom("fb5c4359-428a-4d58-a9f6-65bfce790f37")), "Z 2000", metalldachplatteTypTon250)


  val availableRoofTiles: List<RoofTile> = listOf(
    //achat10VBraas
    actua10Wienerberger,
    alegraZZWancor,
    alegra10Wienerberger,
    alegra12BogenWienerberger,
    alegra15Wienerberger,
    arealJacobiWalther,
    artesoZZWancor,
    //balanceCreaton
    bariRöben,
    bergamoRöben,
    biber190x400,
    biberfalzRundschnittTondach,
    biberschwanzZiegelErlus,
    biberschwanzziegelJacobiWalther,
    biberschwanzziegelWienerberger,
    biberschwanzziegelKlassikCreaton,
    //biberTascheEckigTondach
    //biberTascheGerundetTondach
    bM72Rapperswil,
    bornanSchiebeziegelMorandiRapperswil,
    bornholmRöben,
    bramacDolomitBramac,
    bramacDonauBramac,
    //bramacMarkantBramac
    bramacMAXBramac,
    bramacMonteroBramac,
    bramacTegalitBramac,
    campaZZWancor,
    cantusCreaton,
    cosmo12Wienerberger,
    cosmo15Wienerberger,
    d10GigantJacobiWalther,
    d13D13ÜNelskamp,
    d15ÜNelskamp,
    dominoCreaton,
    doppelSBraas,
    e58Erlus,
    e58MAXErlus,
    e58PLUSErlus,
    e58SErlus,
    e58SLErlus,
    eleganzCreaton,
    elsassRöben,
    europaEternit,
    f10ÜNelskamp,
    f10ÜNibraNelskamp,
    f12ÜNordNibraNelskamp,
    f12ÜSüdNelskamp,
    f13ClassicNelskamp,
    f14Nelskamp,
    f15Nelskamp,
    falzziegelErlus,
    famaZZWancor,
    fidelioTondach,
    figaroTondach,
    figaroDELUXETondach,
    finkenbergerPfanneNelskamp,
    flachdachpfanneBerchtold,
    flandernRöben,
    //flandernPLUSRöben
    formaErlus,
    frankfurterPfanneBraas,
    fS03Rapperswil,
    futuraCreaton,
    granat11VBraas,
    granat1313VBraas,
    granat15VBraas,
    gratusCreaton,
    großfalzziegelErlus,
    großfalzziegelXXLErlus,
    göteborgCreatonEternit,
    //h1JacobiWalther
    //h2JacobiWalther
    harmonieCreaton,
    harzerPfanneBraas,
    harzerPfanne7BIGBraas,
    heidelbergCreatonEternit,
    heidelbergerEternit,
    herzziegelCreaton,
    herzziegelMeindl,
    hohlstrangfalzWienerberger,
    ikarusTondach,
    j11VVariwellJacobiWalther,
    j13VJacobiWalther,
    j72JuraRapperswil,
    juraZZWancor,
    kapstadtCreatonEternit,
    karatErlus,
    karatXXLErlus,
    kronenPfanneNelskamp,
    l15MödingLangenzennWienerberger,
    landdachTondach,
    landdachVerschiebeziegelTondach,
    lineaErlus,
    m72Rapperswil,
    magnumCreaton,
    markoJacobiWalther,
    maximaCreaton,
    megatonMeindl,
    monacoErlus,
    mondo11L42Wienerberger,
    mondo12Wienerberger,
    mondo15Wienerberger,
    //monzaPLUSRöben
    morandiForceDeLaTerreMorandiRapperswil,
    morandiHerzziegelMorandiRapperswil,
    morandiJoranMorandiRapperswil,
    mS95Rapperswil,
    mZ327x445Creaton,
    mZ3Meindl255x42Creaton,
    mZ3NEUDorfen26x415Creaton,
    mZ5Meindl,
    nevaZZWancor,
    nibraDS10Nelskamp,
    nibraG10NEUNelskamp,
    nibraKombiZiegelR10Nelskamp,
    normaTondach,
    p72Rapperswil,
    padaZZWancor,
    //piemontRöben
    planoZZWancor,
    //plano11Wienerberger
    planumNelskamp,
    premionCreaton,
    r13SNelskamp,
    ratioCreaton,
    reformpfanneBerchtold,
    reformpfanneSLErlus,
    reformpfanneXXLErlus,
    rheinlandRöben,
    romaZZWancor,
    romanoCreaton,
    rombusEternit,
    rubin9VBraas,
    rubin11VHainstädterBraas,
    rubin13Braas,
    rubin13VBraas,
    rubin15Braas,
    rubin15VBraas,
    rusticoCreaton,
    sPfanneNelskamp,
    sigmaPfanneNelskamp,
    standardpfanneBerchtold,
    steinbrückTondach,
    //strangfalzEternit
    strangfalzGepresstTondach,
    stylistJacobiWalther,
    sulmVerschiebeziegelTondach,
    swissTLZZWancor,
    taunusPfanneBraas,
    tegalitBraas,
    tegulaJacobiWalther,
    terraOptimaCreaton,
    terraVivaCreaton,
    //titaniaCreaton
    topas11VBraas,
    topas13V13Braas,
    topas15VBraas,
    tradi12TradiNova,
    tradi15Wienerberger,
    turmalinBraas,
    universo10Wienerberger,
    universo14Wienerberger,
    veronaCreatonEternit,
    vivaNEUCreaton,
    w4JacobiWalther,
    w5JacobiWalther,
    w6VJacobiWalther,
    wienerNormaTondach,
    z1225x410mmMühlacker,
    z1230x410mmMühlacker,
    z1Winnenden,
    z2JacobiWalther,
    z4NWinnenden,
    z7VJacobiWalther,
    z10JacobiWalther,
    z15Mühlacker,
    z16Mühlacker,
    z17Mühlacker,
    z18Mühlacker,
    z2000,
  )


  fun create(): AvailableRoofTiles {
    return AvailableRoofTiles(harzerPfanneBraas, availableRoofTiles)
  }

  fun createPrices(): InfoForType<MetalRoofTile, Money> {
    return InfoForType.invoke {
      //add(metalldachplatteSonderanfertigung, Money.euros(0.00))
      add(metalldachplatteTypGrande290, 11.33.euro)
      add(metalldachplatteTypGrande312, 13.87.euro)
      add(metalldachplatteTypTon270, 10.80.euro)
      add(metalldachplatteTypExTon260, 8.07.euro)
      add(metalldachplatteTypGrande300, 11.33.euro)
      //add(metalldachplatteTypGrande323, Money.euros(0.00))
      add(metalldachplatteTypTon280, 11.33.euro)
      add(metalldachplatteTypTon265, 10.80.euro)
      add(metalldachplatteTypVario190, Money.euros(7.75))
      add(metalldachplattenTypHofa, Money.euros(6.09))
      add(metalldachplatteTypVario, 6.45.euro)
      add(metalldachplatteTypTon240, 8.07.euro)
      add(metalldachplatteTypTon261, 8.07.euro)
      add(metalldachplatteTypGrande310Kurz, 11.33.euro)
      add(metalldachplatteTypBeton, 8.07.euro)
      //add(metalldachplatteTypEXAT330, Money.euros(0.00))
      add(metalldachplatteTypBetonBig, 13.87.euro)
      add(metalldachplatteTypTGL330, 13.87.euro)
      add(metalldachplatteTypTon251, 8.07.euro)
      add(metalldachplatteTypTon260, 8.07.euro)
      add(metalldachplatteTypTon250, 8.07.euro)
      add(metalldachplatteTypGrande28M58Verzinkt, 17.99.euro)
      add(metalldachplatteTypGrandeL360, 13.87.euro)
      add(metalldachplatteTypTon230, 8.07.euro)
      add(metalldachplatteTypTon220, 8.07.euro)
      //add(metalldachplatteTypGrande310Lang, Money.euros(0.00))
      add(metalldachplatteTypGrande280, 15.04.euro)
      add(metalldachplatteTypExAT260, 10.80.euro)
      add(metalldachplatteTypExtra270, 8.07.euro)
      add(metalldachplatteTypExtra295, Money.euros(19.18))
      add(metalldachplatteTypTon260Z, 8.07.euro)
      add(metalldachplatteTypRK255, 9.89.euro)
      add(metalldachplatteTypSchiefer250x380mmFürStockschraube, 5.69.euro)
      add(metalldachplatteTypGrande313, 11.33.euro)
      add(metalldachplatteTypTon269, 10.80.euro)
      add(metalldachplatteTypGrande330, 9.89.euro)
    }
  }

}
