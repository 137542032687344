package services.http

import it.neckar.customer.company.CompanyCode
import it.neckar.datetime.minimal.LocalDate
import it.neckar.datetime.minimal.Month
import it.neckar.datetime.minimal.Year
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.sum
import it.neckar.lizergy.model.project.previews.AccountingProjectPreview
import it.neckar.lizergy.model.project.previews.AccountingResolvedProjectPreview
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.open.unit.currency.EUR
import it.neckar.rest.RestResponse
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AccountingProjectQueryRequest(
  val processStatesToFilter: List<@Serializable(with = LizergyProcessStatesSerializer::class) LizergyProcessStates>,
  val processStatesToHide: List<@Serializable(with = LizergyProcessStatesSerializer::class) LizergyProcessStates>,
  val accountingQuerySelection: AccountingQuerySelection,
  val filteredByCompanyCode: CompanyCode?,
  val filteredByMaintainer: UserLoginName?,
  val filteredByEditor: UserLoginName?,
  val filterValueProject: String?,
  val filterValueAddress: String?,
  val filterByProjectState: @Serializable(with = LizergyProcessStatesSerializer::class) LizergyProcessStates?,
  val indexOfFirstVisibleProject: Int,
  val indexOfLastVisibleProject: Int,
  val sorting: ProjectQuerySorting,
  val projectQueryComponent: ProjectQueryComponent?,
  val howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements,
  val loggedInUser: UserLoginName,
)

@Serializable
enum class AccountingQuerySelection {
  All,
  PendingForAccounting,
  SelectedForAccounting,
  Accounted,
}


@Serializable
sealed interface AccountingProjectQueryResponse : RestResponse<AccountingProjectQueryInformation> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: AccountingProjectQueryInformation) : AccountingProjectQueryResponse, RestResponse.SuccessResponse<AccountingProjectQueryInformation>

  @Serializable
  @SerialName("failure")
  data object Failure : AccountingProjectQueryResponse, RestResponse.FailureResponse<AccountingProjectQueryInformation>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: AccountingProjectQueryInformation): AccountingProjectQueryResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): AccountingProjectQueryResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: AccountingProjectQueryInformation?): AccountingProjectQueryResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}

@Serializable
data class AccountingProjectQueryInformation(
  val visibleProjectPreviews: List<AccountingResolvedProjectPreview>,
  val numberOfTotalFilteredProjects: Int,
  val numberOfTotalRelevantProjects: Int,
  val lizergyRevenue: CompanyRevenue,
  val neckarITRevenue: CompanyRevenue,
  val partnerRevenues: List<CompanyRevenue>,
)

@Serializable
data class CompanyRevenue(
  val companyName: String,
  val revenues: List<RevenueOnDate>,
) {
  val totalRevenue: @EUR Money
    get() = revenues.map { it.revenue }.sum()

  val revenueByDay: Map<LocalDate, @EUR Money>
    get() = revenues.groupBy({ it.date }) { it.revenue }.mapValues { it.value.sum() }

  val revenueBysMonth: Map<Month, @EUR Money>
    get() = revenues.groupBy({ it.date.month }) { it.revenue }.mapValues { it.value.sum() }

  val revenueByYear: Map<Year, @EUR Money>
    get() = revenues.groupBy({ it.date.year }) { it.revenue }.mapValues { it.value.sum() }
}

@Serializable
data class RevenueOnDate(
  val date: LocalDate,
  val revenue: @EUR Money,
)
