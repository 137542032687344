package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.ElementsSelection
import it.neckar.lizergy.model.ElementsSelectionEntry
import it.neckar.lizergy.model.configuration.quote.builder.WallboxSelection.WallboxSelectionId
import kotlinx.serialization.Serializable

/**
 * Represents the selection of multiple [Wallbox]s
 */
@Serializable
data class ResolvedWallboxSelection(
  override val id: WallboxSelectionId = WallboxSelectionId.random(),
  override val entries: List<ElementsSelectionEntry<Wallbox>>,
  override val throttled: Boolean,
) : WallboxSelection, ElementsSelection<Wallbox> {

  val isSmartWallBoxSelected: Boolean
    get() = entries.any { it.element.isSmartWallBox }

  /**
   * Creates a new [ResolvedWallboxSelection] with the updated amount for the given [Wallbox]
   */
  fun with(inverterType: Wallbox, amount: Int): ResolvedWallboxSelection {
    val entriesMap = entries.associateBy {
      it.element
    }.toMutableMap()

    if (amount > 0) {
      entriesMap[inverterType] = ElementsSelectionEntry(inverterType, amount)
    } else {
      entriesMap.remove(inverterType)
    }

    return copy(entries = entriesMap.values.toList())
  }

  override fun Wallbox.format(): String {
    return description
  }

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedWallboxSelection {
    val newId = WallboxSelectionId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(id = newId)
  }


  companion object {
    fun getEmpty(id: WallboxSelectionId = WallboxSelectionId.random()): ResolvedWallboxSelection {
      return ResolvedWallboxSelection(id = id, entries = emptyList(), throttled = false)
    }
  }

}
