package it.neckar.lizergy.model.price

import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.cm
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.PriceWithProfit
import kotlinx.serialization.Serializable

/**
 * Contains the roof isolation prices
 */
@Serializable
data class RoofIsolationPrices(
  val priceBelow4cm: PriceWithProfit,
  val priceBelow6cm: PriceWithProfit,
  val priceBelow10cm: PriceWithProfit,
  val priceBelow14cm: PriceWithProfit,
  val priceBelow16cm: PriceWithProfit,
  val priceBelow18cm: PriceWithProfit,
  val priceBelow20cm: PriceWithProfit,
  val priceBelow22cm: PriceWithProfit,
  val priceAbove22cm: PriceWithProfit,
) {

  constructor(
    inputPriceBelow4cm: Money,
    inputPriceBelow6cm: Money,
    inputPriceBelow10cm: Money,
    inputPriceBelow14cm: Money,
    inputPriceBelow16cm: Money,
    inputPriceBelow18cm: Money,
    inputPriceBelow20cm: Money,
    inputPriceBelow22cm: Money,
    inputPriceAbove22cm: Money,
    surCharge: @pct Double,
  ) : this(
    PriceWithProfit.surCharge(inputPriceBelow4cm, surCharge),
    PriceWithProfit.surCharge(inputPriceBelow6cm, surCharge),
    PriceWithProfit.surCharge(inputPriceBelow10cm, surCharge),
    PriceWithProfit.surCharge(inputPriceBelow14cm, surCharge),
    PriceWithProfit.surCharge(inputPriceBelow16cm, surCharge),
    PriceWithProfit.surCharge(inputPriceBelow18cm, surCharge),
    PriceWithProfit.surCharge(inputPriceBelow20cm, surCharge),
    PriceWithProfit.surCharge(inputPriceBelow22cm, surCharge),
    PriceWithProfit.surCharge(inputPriceAbove22cm, surCharge),
  )

  operator fun get(isolationThickness: @cm Int): PriceWithProfit {
    return when {
      isolationThickness < 4 -> priceBelow4cm
      isolationThickness < 6 -> priceBelow6cm
      isolationThickness < 10 -> priceBelow10cm
      isolationThickness < 14 -> priceBelow14cm
      isolationThickness < 16 -> priceBelow16cm
      isolationThickness < 18 -> priceBelow18cm
      isolationThickness < 20 -> priceBelow20cm
      isolationThickness < 22 -> priceBelow22cm
      else -> priceAbove22cm
    }
  }

  fun asList(): List<PriceWithProfit> {
    return listOf(
      priceBelow4cm,
      priceBelow6cm,
      priceBelow10cm,
      priceBelow14cm,
      priceBelow16cm,
      priceBelow18cm,
      priceBelow20cm,
      priceBelow22cm,
      priceAbove22cm,
    )
  }

  companion object {
    /**
     * Contains zero for all values
     */
    val zero: RoofIsolationPrices = RoofIsolationPrices(
      priceBelow4cm = PriceWithProfit.Zero,
      priceBelow6cm = PriceWithProfit.Zero,
      priceBelow10cm = PriceWithProfit.Zero,
      priceBelow14cm = PriceWithProfit.Zero,
      priceBelow16cm = PriceWithProfit.Zero,
      priceBelow18cm = PriceWithProfit.Zero,
      priceBelow20cm = PriceWithProfit.Zero,
      priceBelow22cm = PriceWithProfit.Zero,
      priceAbove22cm = PriceWithProfit.Zero,
    )

    fun thicknessCategory(isolationThickness: @cm Int): String {
      return when {
        isolationThickness < 4 -> "0-4 cm"
        isolationThickness < 6 -> "4-6 cm"
        isolationThickness < 10 -> "6-10 cm"
        isolationThickness < 14 -> "10-14 cm"
        isolationThickness < 16 -> "14-16 cm"
        isolationThickness < 18 -> "16-18 cm"
        isolationThickness < 20 -> "18-20 cm"
        isolationThickness < 22 -> "20-22 cm"
        else -> ">22 cm"
      }
    }
  }
}
