package it.neckar.common.auth

import it.neckar.rest.RestResponse
import it.neckar.rest.jwt.JwtTokenPair
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


/**
 * Response for
 */
@Serializable
sealed interface JwtRefreshTokenResponse : RestResponse<JwtTokenPair> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: JwtTokenPair) : JwtRefreshTokenResponse, RestResponse.SuccessResponse<JwtTokenPair>

  @Serializable
  @SerialName("failure")
  data object Failure : JwtRefreshTokenResponse, RestResponse.FailureResponse<JwtTokenPair>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: JwtTokenPair): JwtRefreshTokenResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): JwtRefreshTokenResponse = Failure

    /**
     * Creates a new result - with the correct type - for the given data.
     * Returns success if the value is not null, failure otherwise
     */
    fun createFor(data: JwtTokenPair?): JwtRefreshTokenResponse {
      return when (data) {
        null -> Failure
        else -> Success(data)
      }
    }
  }
}
