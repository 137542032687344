package services.auth.http

import it.neckar.lizergy.model.company.UsersAndCompanies
import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Response for
 */
@Serializable
sealed interface FetchUsersAndCompaniesResponse : RestResponse<UsersAndCompanies> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: UsersAndCompanies) : FetchUsersAndCompaniesResponse, RestResponse.SuccessResponse<UsersAndCompanies> {

    @Deprecated("Inline!", ReplaceWith("data"))
    fun toUsersAndCompanies(): UsersAndCompanies {
      return data
    }
  }

  @Serializable
  @SerialName("failure")
  data object Failure : FetchUsersAndCompaniesResponse, RestResponse.FailureResponse<UsersAndCompanies>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: UsersAndCompanies): FetchUsersAndCompaniesResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): FetchUsersAndCompaniesResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: UsersAndCompanies?): FetchUsersAndCompaniesResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}
