package it.neckar.lizergy.model.price

import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import kotlinx.serialization.Serializable

/**
 * Contains the currently available roof tiles.
 *
 * This list corresponds to a [PriceList].
 * It *will* change over time!
 */
@Serializable
data class AvailableRoofTiles(
  /**
   * A default tile type
   */
  val defaultTileType: RoofTile,

  /**
   * The available roof tile types
   */
  val available: List<RoofTile>,
)
