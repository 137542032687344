package com.meistercharts.charts.lizergy.roofPlanning

import com.meistercharts.charts.lizergy.roofPlanning.ModuleOrientation.Horizontal
import com.meistercharts.charts.lizergy.roofPlanning.ModuleOrientation.Vertical
import kotlinx.serialization.Serializable

/**
 * The orientation of a module
 */
@Serializable
enum class ModuleOrientation {
  /**
   * Longer side on the y axis
   */
  Vertical,

  /**
   * Longer side on the x axis
   */
  Horizontal,
}

fun ModuleOrientation.rotated(): ModuleOrientation {
  return when (this) {
    Vertical -> Horizontal
    Horizontal -> Vertical
  }
}
