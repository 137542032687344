package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.commons.tags.Tags
import it.neckar.commons.tags.toTags
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.open.formatting.format
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

@Serializable
data class ConfigurationItem(

  val id: ConfigurationItemId = ConfigurationItemId.random(),

  val headline: String,

  val details: String,
  /**
   * The price for *one* item.
   * Is multiplied with [amount]
   */
  val priceForOneElement: PriceWithProfit,

  /**
   * The amount
   */
  val amount: Double = 1.0,

  /**
   * The tags this item is relevant for.
   *
   * Can be used to calculate sums / discounts etc.
   */
  val relevance: Tags = Tags.empty,

  val valueAddedTax: ValueAddedTax = ValueAddedTax.nineteenPercent,

  ) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  val sum: PriceWithProfit
    get() = priceForOneElement * amount

  val isEmpty: Boolean
    get() = headline.isEmpty() && details.isEmpty() && priceForOneElement == PriceWithProfit.Zero

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ConfigurationItem {
    val newId = ConfigurationItemId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(id = newId)
  }

  fun format(): String {
    return "$headline: $details, Menge: ${amount.format(0)}"
  }

  /**
   * An id for a [ConfigurationItem]
   */
  @Serializable
  data class ConfigurationItemId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ConfigurationItemId {
        return ConfigurationItemId(randomUuid4())
      }
    }
  }

  companion object {
    val risikoZuschlag: ConfigurationItem = ConfigurationItem(
      headline = "Risikozuschlag",
      details = "",
      priceForOneElement = PriceWithProfit.Zero,
      amount = 1.0,
      relevance = LizergyCalculationCategories.EconomicsCalculation.toTags(),
      valueAddedTax = ValueAddedTax.zero,
    )

    fun getEmpty(valueAddedTax: ValueAddedTax): ConfigurationItem {
      return ConfigurationItem(
        headline = "",
        details = "",
        priceForOneElement = PriceWithProfit.Zero,
        valueAddedTax = valueAddedTax,
      )
    }
  }

}
