package it.neckar.financial.quote

import it.neckar.customer.Address
import it.neckar.customer.company.BankInformation
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyInformation
import it.neckar.customer.company.CompanyLegalInformation
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.ContactInformation
import it.neckar.customer.company.TestCompanyProfile
import it.neckar.lifeCycle.LifeCycleState
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@SerialName("QuoteCompanyInformation")
@Serializable
data class QuoteCompanyInformation(
  override val companyProfile: CompanyProfile,
  override val name: String,
  override val address: Address,
  override val contactInformation: ContactInformation,
  override val bankInformation: BankInformation,
  override val legalInformation: CompanyLegalInformation,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : CompanyInformation {

  val companyCode: CompanyCode
    get() = companyProfile.companyCode

  companion object {
    val testCompany: QuoteCompanyInformation = QuoteCompanyInformation(
      companyProfile = TestCompanyProfile(CompanyCode.TestCompany),
      name = "Test GmbH",
      address = Address("Teststraße", "99", "77777", "Test-Stadt"),
      contactInformation = ContactInformation("lizergy-test@neckar-it.de", "+49 (0) 7473 / 959496-0", "+49 (0) 7473 / 959496-99", "lizergy-test.neckar.it"),
      bankInformation = BankInformation("VR Bank Steinlach-Wiesaz-Härten", "DE 40 5555 6666 7777 8888 99", "GENODES1STW"),
      legalInformation = CompanyLegalInformation("Martha Testfrau", "Amtsgericht Stuttgart", "DE 192736450", "HRB 112233"),
    )
  }
}
