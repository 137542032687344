package components.project.configuration.layout

import components.project.configuration.layout.moduleLayout.roof.roofPreview
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.react.common.*
import react.*
import react.dom.*

/**
 * Shows the preview for *one* [ResolvedModuleLayout]
 */
fun RBuilder.moduleLayoutPreview(
  resolvedModuleLayout: ResolvedModuleLayout,
): Unit = child(ModuleLayoutPreview) {
  attrs {
    this.resolvedModuleLayout = resolvedModuleLayout
  }
}

val ModuleLayoutPreview: FC<ModuleLayoutPreviewProps> = fc("ModuleLayoutPreview") { props ->
  val resolvedModuleLayout = props::resolvedModuleLayout.safeGet()
  val resolvedRoof = resolvedModuleLayout.roof


  div {

    p {
      +"${resolvedModuleLayout.modulesCount} Module"
    }

    p {
      val moduleType = resolvedModuleLayout.moduleType
      +"${moduleType.description} (${moduleType.powerRating.formatWattPeak()})"
    }

    p {
      +"Leistung: ${resolvedModuleLayout.peakPower.formatKiloWattPeak()}"
    }

    if (resolvedModuleLayout.moduleType.isEndOfLife()) {
      p("text-danger") {
        +"Das ausgewählte Modul ist nicht mehr vorhanden!"
      }
    }

    invalidIf {
      resolvedModuleLayout.moduleType.isEndOfLife()
    }
  }

  roofPreview(resolvedRoof)

}

external interface ModuleLayoutPreviewProps : Props {
  var resolvedModuleLayout: ResolvedModuleLayout
}
