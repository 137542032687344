package services.http

import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed interface AllAccountingProjectPreviewsAsCSVResponse : RestResponse<String> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: String) : AllAccountingProjectPreviewsAsCSVResponse, RestResponse.SuccessResponse<String>

  @Serializable
  @SerialName("failure")
  data object Failure : AllAccountingProjectPreviewsAsCSVResponse, RestResponse.FailureResponse<String>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: String): AllAccountingProjectPreviewsAsCSVResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): AllAccountingProjectPreviewsAsCSVResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: String?): AllAccountingProjectPreviewsAsCSVResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}
