package components.project

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.previews.ServerProjectPreview
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.open.time.nowMillis
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUserResolver

fun RBuilder.archiveModal(projectPreview: ServerProjectPreview): Unit = child(ArchiveModal) {
  attrs {
    this.projectPreview = projectPreview
  }
}

val ArchiveModal: FC<ArchiveModalProps> = fc("ArchiveModal") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val projectPreview = props::projectPreview.safeGet()
  val archiveReasons = projectPreview.archiveReasons

  val noLongerInterested = useState(archiveReasons.otherSellerWasCheaper)
  val otherSellerWasCheaper = useState(archiveReasons.otherSellerWasCheaper)
  val otherSellerInformedBetter = useState(archiveReasons.otherSellerInformedBetter)
  val otherSellerWasFaster = useState(archiveReasons.otherSellerWasFaster)
  val quoteWasNotProfitable = useState(archiveReasons.quoteWasNotProfitable)
  val tookTooLong = useState(archiveReasons.tookTooLong)
  val noResponseFromCustomer = useState(archiveReasons.noResponseFromCustomer)
  val deliveryTimesTooLing = useState(archiveReasons.deliveryTimesTooLing)
  val customerWantedDifferentManufacturer = useState(archiveReasons.customerWantedDifferentManufacturer)
  val customerWantedCloudSolution = useState(archiveReasons.customerWantedCloudSolution)
  val tooExpensive = useState(archiveReasons.tooExpensive)
  val waitingTimeTooLong = useState(archiveReasons.waitingTimeTooLong)
  val otherReasons = useState(archiveReasons.otherReasons)


  actionButtonWithConfirmationModal(
    icon = FontAwesomeIcons.trash,
    confirmationButtonClasses = buildString {
      append("btn btn-primary")
      if (noLongerInterested.value.not() && otherSellerWasCheaper.value.not() && otherSellerInformedBetter.value.not() && otherSellerWasFaster.value.not() && quoteWasNotProfitable.value.not() && tookTooLong.value.not() && noResponseFromCustomer.value.not() && deliveryTimesTooLing.value.not() && customerWantedDifferentManufacturer.value.not() && customerWantedCloudSolution.value.not() && tooExpensive.value.not() && waitingTimeTooLong.value.not() && otherReasons.value.isBlank()) {
        append(" disabled")
      }
    },
    modalTitle = "Soll dieses Projekt archiviert werden?",
    modalContent = {
      h4("my-4") {
        +"Projektabsage"
      }
      div {
        checkbox(
          valueAndSetter = noLongerInterested,
          fieldName = "noLongerInterested",
          title = "Aktuell nicht mehr interessiert",
        )
      }
      div {
        checkbox(
          valueAndSetter = otherSellerWasCheaper,
          fieldName = "otherSellerWasCheaper",
          title = "Anderer Anbieter war günstiger",
        )
      }
      div {
        checkbox(
          valueAndSetter = otherSellerInformedBetter,
          fieldName = "otherSellerInformedBetter",
          title = "Anderer Anbieter hat besser beraten",
        )
      }
      div {
        checkbox(
          valueAndSetter = otherSellerWasFaster,
          fieldName = "otherSellerWasFaster",
          title = "Anderer Anbieter war schneller",
        )
      }
      div {
        checkbox(
          valueAndSetter = quoteWasNotProfitable,
          fieldName = "quoteWasNotProfitable",
          title = "Angebot war nicht wirtschaftlich",
        )
      }
      div {
        checkbox(
          valueAndSetter = tookTooLong,
          fieldName = "tookTooLong",
          title = "Bearbeitungszeit zu lang",
        )
      }
      div {
        checkbox(
          valueAndSetter = noResponseFromCustomer,
          fieldName = "noResponseFromCustomer",
          title = "Keine Rückmeldung vom Kunden",
        )
      }
      div {
        checkbox(
          valueAndSetter = deliveryTimesTooLing,
          fieldName = "deliveryTimesTooLing",
          title = "Zu lange Lieferzeiten",
        )
      }
      div {
        checkbox(
          valueAndSetter = customerWantedDifferentManufacturer,
          fieldName = "customerWantedDifferentManufacturer",
          title = "Möchte anderen Hersteller (z.B. Fronius, E3/DC, usw.). Bitte under Sonstige Begründung",
        )
      }
      div {
        checkbox(
          valueAndSetter = customerWantedCloudSolution,
          fieldName = "customerWantedCloudSolution",
          title = "Möchte Cloudlösung",
        )
      }
      div {
        checkbox(
          valueAndSetter = tooExpensive,
          fieldName = "tooExpensive",
          title = "Zu teuer",
        )
      }
      div {
        checkbox(
          valueAndSetter = waitingTimeTooLong,
          fieldName = "waitingTimeTooLong",
          title = "Wartezeit zu lang",
        )
      }

      div("mt-4") {
        inputArea(
          valueAndSetter = otherReasons,
          fieldName = "otherReasons",
          title = "Sonstige Begründungen",
          placeHolder = "Sonstige Begründungen",
        ) { }
      }

    },
  ) {

    UiActions.archiveProject(
      project = projectPreview,
      archiveReasons = archiveReasons.copy(
        noLongerInterested = noLongerInterested.value,
        otherSellerWasCheaper = otherSellerWasCheaper.value,
        otherSellerInformedBetter = otherSellerInformedBetter.value,
        otherSellerWasFaster = otherSellerWasFaster.value,
        quoteWasNotProfitable = quoteWasNotProfitable.value,
        tookTooLong = tookTooLong.value,
        noResponseFromCustomer = noResponseFromCustomer.value,
        deliveryTimesTooLing = deliveryTimesTooLing.value,
        customerWantedDifferentManufacturer = customerWantedDifferentManufacturer.value,
        customerWantedCloudSolution = customerWantedCloudSolution.value,
        tooExpensive = tooExpensive.value,
        waitingTimeTooLong = waitingTimeTooLong.value,
        otherReasons = otherReasons.value,
      ),
      loggedInUser = loggedInUser,
    )

    noLongerInterested.setter(false)
    otherSellerWasCheaper.setter(false)
    otherSellerInformedBetter.setter(false)
    otherSellerWasFaster.setter(false)
    quoteWasNotProfitable.setter(false)
    tookTooLong.setter(false)
    noResponseFromCustomer.setter(false)
    deliveryTimesTooLing.setter(false)
    customerWantedDifferentManufacturer.setter(false)
    customerWantedCloudSolution.setter(false)
    tooExpensive.setter(false)
    waitingTimeTooLong.setter(false)
    otherReasons.setter("")
  }

}


fun RBuilder.unArchiveModal(projectPreview: ServerProjectPreview): Unit = child(UnArchiveModal) {
  attrs {
    this.projectPreview = projectPreview
  }
}


val UnArchiveModal: FC<ArchiveModalProps> = fc("UnArchiveModal") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()

  val projectPreview = props::projectPreview.safeGet()


  actionButtonWithConfirmationModal(
    icon = FontAwesomeIcons.sync,
    modalTitle = "Soll dieses Projekt reaktiviert werden?",
    modalContent = {},
  ) {
    UiActions.addProcessState(
      processStateFor = projectPreview.uuid,
      newProcessState = ProjectProcessStateEntry.ProjectProcessStates.BeingEdited.toProcessStateEntry(
        user = userResolver[projectPreview.maintainer],
        dueDate = null,
        assignedAt = nowMillis(),
        assignedBy = loggedInUser,
      ),
    )
  }

}


external interface ArchiveModalProps : Props {
  var projectPreview: ServerProjectPreview
}
