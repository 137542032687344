package services.auth.http

import it.neckar.lizergy.model.company.user.UserInformation
import kotlinx.serialization.Serializable

/**
 * The request to delete a user
 */
@Serializable
data class DeleteUserRequest(val userInformation: UserInformation)
