package it.neckar.lizergy.model.price.lizergy

import it.neckar.open.unit.other.pct

/**
 * Contains the sur charges for different applications
 */
data class SurCharges(
  //Sur charges
  val modulesSurCharge: @pct Double,
  val inverterSurCharge: @pct Double,
  val batteryStorageSurCharge: @pct Double,
  val wallBoxesSurCharge: @pct Double,
  val metalRoofTilesSurCharge: @pct Double,
  val substructureSurCharge: @pct Double,
  val constructionSurCharge: @pct Double,
  val waermepumpenSurCharge: @pct Double,
  val energyMeterSurCharge: @pct Double,
  val schienenSurCharge: @pct Double,
  val klemmenSurCharge: @pct Double,
  val specialSurCharge: @pct Double,
  val blackModulesSurCharge: @pct Double,
  val optimizerSurCharge: @pct Double,
  val aufschlagKreuzverbundSurCharge: @pct Double,
)
