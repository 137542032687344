package it.neckar.lizergy.model.configuration.quote.economics

import it.neckar.financial.currency.Money
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.time.pa
import kotlinx.serialization.Serializable

/**
 * The yearly costs
 */
@Serializable
data class YearlyCostInformation(
  val operatingCosts: @pa Money? = null,
  /**
   * The percentage the [operatingCosts] increase each year
   */
  val operatingCostsIncreasePercentage: @pct Double = 0.0.percent,
)
