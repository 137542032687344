@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.project.process.state

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.editHistory.PositionEdit
import it.neckar.editHistory.PositionEditHistory
import it.neckar.lifeCycle.onlyActive
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry.AdvanceInvoiceProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementProcessStateEntry.AssemblyBasementProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry.AssemblyPortfolioProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry.AssemblyRoofProcessStates
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry.BlueprintAcquisitionProcessStates
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry.BlueprintProcessStates
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry.ConfigurationProcessStates
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry.DocumentationProcessStates
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry.FinalAccountProcessStates
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry.FinishingUpProcessStates
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry.GridAssessmentProcessStates
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry.OrderSpecialMaterialProcessStates
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry.PresentationProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry.QuoteConfirmationProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry.QuoteOfferProcessStates
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry.StartupOperationsProcessStates
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry.SwitchMeterBoxProcessStates
import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.unit.si.ms
import it.neckar.processStates.HasDueDate
import it.neckar.processStates.ProcessStateEntry
import it.neckar.processStates.ProcessStateEntryId
import it.neckar.processStates.ProcessStatePhase
import it.neckar.processStates.ProcessStates
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
sealed interface LizergyProcessStateEntry : ProcessStateEntry, Comparable<LizergyProcessStateEntry> {
  override val processState: LizergyProcessStates
  override val availableOptions: List<LizergyProcessStates>

  val isQuoteStarted: Boolean
    get() = when (this) {
      is AdvanceInvoiceProcessStateEntry,
      is AssemblyBasementPreparationProcessStateEntry,
      is AssemblyBasementProcessStateEntry,
      is AssemblyPortfolioProcessStateEntry,
      is AssemblyRoofPreparationProcessStateEntry,
      is AssemblyRoofProcessStateEntry,
      is DocumentationProcessStateEntry,
      is FinalAccountProcessStateEntry,
      is FinishingUpProcessStateEntry,
      is GridAssessmentProcessStateEntry,
      is OrderSpecialMaterialProcessStateEntry,
      is StartupOperationsProcessStateEntry,
      is SwitchMeterBoxProcessStateEntry,
        -> true

      is QuoteConfirmationProcessStateEntry -> this.processState.inPhase.isRejected.not()
      is QuoteOfferProcessStateEntry -> this.processState.inPhase.isRejected.not()

      is BlueprintAcquisitionProcessStateEntry,
      is BlueprintProcessStateEntry,
      is ConfigurationProcessStateEntry,
      is PresentationProcessStateEntry,
      is ProjectProcessStateEntry,
        -> false
    }

  val sortOrder: Int
    get() = when (this) {
      is AdvanceInvoiceProcessStateEntry -> 80
      is AssemblyBasementPreparationProcessStateEntry -> 90
      is AssemblyBasementProcessStateEntry -> 100
      is AssemblyPortfolioProcessStateEntry -> 70
      is AssemblyRoofPreparationProcessStateEntry -> 90
      is AssemblyRoofProcessStateEntry -> 100
      is BlueprintAcquisitionProcessStateEntry -> 0
      is BlueprintProcessStateEntry -> 10
      is ConfigurationProcessStateEntry -> 20
      is DocumentationProcessStateEntry -> 150
      is FinalAccountProcessStateEntry -> 140
      is FinishingUpProcessStateEntry -> 130
      is GridAssessmentProcessStateEntry -> 60
      is OrderSpecialMaterialProcessStateEntry -> 60
      is PresentationProcessStateEntry -> 30
      is ProjectProcessStateEntry -> -100
      is QuoteConfirmationProcessStateEntry -> 50
      is QuoteOfferProcessStateEntry -> 40
      is StartupOperationsProcessStateEntry -> 120
      is SwitchMeterBoxProcessStateEntry -> 110
    }

  override fun compareTo(other: LizergyProcessStateEntry): Int {
    if (this.processState.inPhase == ProcessStatePhase.Paused) return if (other.processState.inPhase == ProcessStatePhase.Paused) this.sortOrder - other.sortOrder else -1
    else if (other.processState.inPhase == ProcessStatePhase.Paused) return 1
    check(this.processState.inPhase != ProcessStatePhase.Paused && other.processState.inPhase != ProcessStatePhase.Paused) { "Paused process states already handled" }
    if (this.sortOrder != other.sortOrder) return this.sortOrder - other.sortOrder
    check(this.sortOrder == other.sortOrder) { "Process states with a different sort order already handled" }
    if (this::class != other::class) return this.assignedAt.compareTo(other.assignedAt)
    check(this::class == other::class) { "Process states with a different class already handled" }
    if (this.processState.inPhase != other.processState.inPhase) return this.processState.inPhase.compareTo(other.processState.inPhase)
    check(this.processState.inPhase == other.processState.inPhase) { "Process states with a different phase already handled" }
    check(this.processState == other.processState) { "Process states with a different state already handled" }
    if (this is HasDueDate && other is HasDueDate) {
      val thisDueDate = this.dueDate
      val otherDueDate = other.dueDate
      if (thisDueDate != null && otherDueDate != null) return thisDueDate.compareTo(otherDueDate)
      if (thisDueDate != null) return 1
      if (otherDueDate != null) return -1
    }
    return this.assignedAt.compareTo(other.assignedAt)
  }
}

/**
 * Base interface for all process states for lizergy projects
 */
@Serializable
sealed interface LizergyProcessStates : ProcessStates {
  companion object {
    val allProcessStates: List<LizergyProcessStates>
      get() = buildList {
        addAll(BlueprintAcquisitionProcessStates.entries)
        addAll(BlueprintProcessStates.entries)
        addAll(ConfigurationProcessStates.entries)
        addAll(PresentationProcessStates.entries)
        addAll(QuoteOfferProcessStates.entries)
        addAll(QuoteConfirmationProcessStates.entries)
        addAll(OrderSpecialMaterialProcessStates.entries)
        addAll(GridAssessmentProcessStates.entries)
        addAll(AssemblyPortfolioProcessStates.entries)
        addAll(AssemblyRoofPreparationProcessStates.entries)
        addAll(AssemblyBasementPreparationProcessStates.entries)
        addAll(AdvanceInvoiceProcessStates.entries)
        addAll(AssemblyRoofProcessStates.entries)
        addAll(AssemblyBasementProcessStates.entries)
        addAll(SwitchMeterBoxProcessStates.entries)
        addAll(StartupOperationsProcessStates.entries)
        addAll(FinishingUpProcessStates.entries)
        addAll(FinalAccountProcessStates.entries)
        addAll(DocumentationProcessStates.entries)
        addAll(ProjectProcessStates.entries)
      }

    val allAccountingProcessStates: List<LizergyProcessStates>
      get() = buildList {
        //addAll(QuoteConfirmationProcessStates.entries)
        //addAll(OrderSpecialMaterialProcessStates.entries)
        //addAll(GridAssessmentProcessStates.entries)
        //addAll(AssemblyPortfolioProcessStates.entries)
        //addAll(AssemblyRoofPreparationProcessStates.entries)
        //addAll(AssemblyBasementPreparationProcessStates.entries)
        //addAll(AdvanceInvoiceProcessStates.entries)
        add(AdvanceInvoiceProcessStates.Sent)
        add(AdvanceInvoiceProcessStates.Paid)
        addAll(AssemblyRoofProcessStates.entries)
        addAll(AssemblyBasementProcessStates.entries)
        addAll(SwitchMeterBoxProcessStates.entries)
        addAll(StartupOperationsProcessStates.entries)
        addAll(FinishingUpProcessStates.entries)
        addAll(FinalAccountProcessStates.entries)
        addAll(DocumentationProcessStates.entries)
      }
  }
}

@Serializable
data class UuidAndProcessStateEntries(
  val processStateFor: Uuid,
  val processStateEntries: List<LizergyProcessStateEntry>?,
) {
  val processStateEntry: LizergyProcessStateEntry?
    get() = processStateEntries?.current()
}

fun List<LizergyProcessStates>.onlyUnfinished(): List<LizergyProcessStates> {
  return filter { it.inPhase.isFinished.not() }
}

fun List<LizergyProcessStates>.onlyActive(): List<LizergyProcessStates> {
  return filter { it.inPhase != ProcessStatePhase.Paused }
}


fun LizergyProcessStates.toProcessStateEntry(id: ProcessStateEntryId = ProcessStateEntryId.random(), assignedTo: UserLoginName, belongsTo: CompanyCode, dueDate: @ms Double?, assignedAt: @ms Double, assignedBy: UserLoginName): LizergyProcessStateEntry {
  return when (this) {
    is AdvanceInvoiceProcessStates -> AdvanceInvoiceProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is AssemblyBasementPreparationProcessStates -> AssemblyBasementPreparationProcessStateEntry(id, this, assignedTo, belongsTo, dueDate, assignedAt, assignedBy)
    is AssemblyBasementProcessStates -> AssemblyBasementProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is AssemblyPortfolioProcessStates -> AssemblyPortfolioProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is AssemblyRoofPreparationProcessStates -> AssemblyRoofPreparationProcessStateEntry(id, this, assignedTo, belongsTo, dueDate, assignedAt, assignedBy)
    is AssemblyRoofProcessStates -> AssemblyRoofProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is BlueprintAcquisitionProcessStates -> BlueprintAcquisitionProcessStateEntry(id, this, assignedTo, belongsTo, dueDate, assignedAt, assignedBy)
    is BlueprintProcessStates -> BlueprintProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is ConfigurationProcessStates -> ConfigurationProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is DocumentationProcessStates -> DocumentationProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is FinalAccountProcessStates -> FinalAccountProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is FinishingUpProcessStates -> FinishingUpProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is GridAssessmentProcessStates -> GridAssessmentProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is OrderSpecialMaterialProcessStates -> OrderSpecialMaterialProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is PresentationProcessStates -> PresentationProcessStateEntry(id, this, assignedTo, belongsTo, dueDate, assignedAt, assignedBy)
    is ProjectProcessStates -> ProjectProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is QuoteConfirmationProcessStates -> QuoteConfirmationProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is QuoteOfferProcessStates -> QuoteOfferProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
    is StartupOperationsProcessStates -> StartupOperationsProcessStateEntry(id, this, assignedTo, belongsTo, dueDate, assignedAt, assignedBy)
    is SwitchMeterBoxProcessStates -> SwitchMeterBoxProcessStateEntry(id, this, assignedTo, belongsTo, assignedAt, assignedBy)
  }
}

fun LizergyProcessStates.toProcessStateEntry(id: ProcessStateEntryId = ProcessStateEntryId.random(), user: UserInformation, dueDate: @ms Double?, assignedAt: @ms Double, assignedBy: UserInformation): LizergyProcessStateEntry {
  return toProcessStateEntry(id = id, assignedTo = user.loginName, belongsTo = user.company.companyCode, dueDate = dueDate, assignedAt = assignedAt, assignedBy.loginName)
}

fun LizergyProcessStates.toProcessStateEntries(): List<LizergyProcessStates> {
  return when (this) {
    is AdvanceInvoiceProcessStates -> AdvanceInvoiceProcessStates.entries
    is AssemblyBasementPreparationProcessStates -> AssemblyBasementPreparationProcessStates.entries
    is AssemblyBasementProcessStates -> AssemblyBasementProcessStates.entries
    is AssemblyPortfolioProcessStates -> AssemblyPortfolioProcessStates.entries
    is AssemblyRoofPreparationProcessStates -> AssemblyRoofPreparationProcessStates.entries
    is AssemblyRoofProcessStates -> AssemblyRoofProcessStates.entries
    is BlueprintAcquisitionProcessStates -> BlueprintAcquisitionProcessStates.entries
    is BlueprintProcessStates -> BlueprintProcessStates.entries
    is ConfigurationProcessStates -> ConfigurationProcessStates.entries
    is DocumentationProcessStates -> DocumentationProcessStates.entries
    is FinalAccountProcessStates -> FinalAccountProcessStates.entries
    is FinishingUpProcessStates -> FinishingUpProcessStates.entries
    is GridAssessmentProcessStates -> GridAssessmentProcessStates.entries
    is OrderSpecialMaterialProcessStates -> OrderSpecialMaterialProcessStates.entries
    is PresentationProcessStates -> PresentationProcessStates.entries
    is ProjectProcessStates -> ProjectProcessStates.entries
    is QuoteConfirmationProcessStates -> QuoteConfirmationProcessStates.entries
    is QuoteOfferProcessStates -> QuoteOfferProcessStates.entries
    is StartupOperationsProcessStates -> StartupOperationsProcessStates.entries
    is SwitchMeterBoxProcessStates -> SwitchMeterBoxProcessStates.entries
  }
}

fun LizergyProcessStates.formatForScheduling(dueDate: @ms Double?, i18nConfiguration: I18nConfiguration, textIfMissing: String = "fehlt", textIfScheduled: String = "terminiert für"): String {
  return buildString {
    append(format(i18nConfiguration))
    if (dueDate != null) {
      append(" ")
      append(textIfScheduled)
      append(" ")
      append(dateFormat.format(dueDate, i18nConfiguration))
    } else {
      append(" ")
      append(textIfMissing)
    }
  }
}

fun List<LizergyProcessStateEntry>.current(): LizergyProcessStateEntry? {
  return onlyActive().maxByOrNull { it.assignedAt }
}


fun OLDProcessState.toNewProcessState(): LizergyProcessStates {
  return when (this) {
    OLDProcessState.ProjectEmpty -> BlueprintAcquisitionProcessStates.Empty
    OLDProcessState.ProjectCreated -> BlueprintAcquisitionProcessStates.Empty
    OLDProcessState.ProjectScheduled -> BlueprintAcquisitionProcessStates.Empty
    OLDProcessState.ProjectBeingEdited -> BlueprintProcessStates.BeingEdited
    OLDProcessState.ConfigurationCreated -> ConfigurationProcessStates.New
    OLDProcessState.ConfigurationBeingEdited -> ConfigurationProcessStates.BeingEdited
    OLDProcessState.ConfigurationFinished -> PresentationProcessStates.InReview
    OLDProcessState.OfferRejected -> QuoteOfferProcessStates.Rejected
    OLDProcessState.OfferSent -> QuoteOfferProcessStates.Sent
    OLDProcessState.OfferAccepted -> QuoteOfferProcessStates.Accepted
    OLDProcessState.ConfirmationRejected -> QuoteConfirmationProcessStates.Rejected
    OLDProcessState.ConfirmationSent -> QuoteConfirmationProcessStates.Sent
    OLDProcessState.ConfirmationAccepted -> QuoteConfirmationProcessStates.Accepted
    OLDProcessState.Archived -> ConfigurationProcessStates.Archived
  }
}

fun PositionEditHistory<OLDProcessState>.toNewProcessStates(): List<LizergyProcessStates> {
  return currentValue.toNewProcessState().toProcessStateEntries()
}

fun PositionEdit<OLDProcessState>.toNewProcessStateEntry(editorInformation: UserInformation?): LizergyProcessStateEntry {
  val editorName = loginName ?: editorInformation?.loginName ?: UserLoginName("lizergy")
  val companyCode = editorInformation?.company?.companyCode ?: CompanyCode.Lizergy
  return value.toNewProcessState().toProcessStateEntry(assignedAt = editTime, assignedBy = editorName, assignedTo = editorName, belongsTo = companyCode, dueDate = null)
}

fun OLDProcessState.toNewProjectProcessState(): ProjectProcessStates {
  return when (this) {
    OLDProcessState.Archived -> ProjectProcessStates.Archived
    else -> ProjectProcessStates.BeingEdited
  }
}

fun PositionEditHistory<OLDProcessState>.toNewProjectProcessStates(): List<LizergyProcessStates> {
  return currentValue.toNewProjectProcessState().toProcessStateEntries()
}
