package it.neckar.lizergy.model.price

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.PerModule
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.PriceWithProfitAndWorkingTime
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.lizergy.model.configuration.quote.builder.BasicBatteryInverter
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod
import it.neckar.lizergy.model.configuration.quote.builder.HybridInverter
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.InverterType
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.open.unit.si.cm
import it.neckar.open.unit.time.h
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlin.time.Duration

/**
 * The price list returns the values used for the quote.
 * Contain prices for the customer and the profit margin information.
 */
interface PriceList : HasUuid {

  val priceListId: PriceListId

  /**
   * The initial costs for site preparation
   * "Baustelleneinrichtung"
   */
  val sitePreparation: PriceWithProfit

  /**
   * The number of (net) working hours per day.
   * This time does not include transportation.
   */
  val workingHoursPerDay: @h Double

  /**
   * Module prices
   */
  val modulePrices: Prices<PvModule>

  /**
   * Prices for inverters
   */
  val inverterPrices: Prices<Inverter>
  val hybridInverterPrices: Prices<HybridInverter>
  val batteryInverterPrices: Prices<BasicBatteryInverter>

  /**
   * Contains the available battery configurations with its material price
   */
  val batteryConfigurationPrices: InfoForType<BatteryConfiguration, PriceWithProfitAndWorkingTime>

  /**
   * The assembly costs by roof
   */
  @PerModule
  val assemblyCostsPerModule: Prices<RoofType>

  /**
   * The metal roof tiles
   */
  val metalRoofTilePrices: Prices<MetalRoofTile>
  val aufschlagColoredMetalPlatesPrices: Prices<MetalRoofTileColor>

  /**
   * The price per module depending on the difficulty of the assembly
   */
  @PerModule
  val assemblyDifficultyPrices: Prices<AssemblyDifficulty>

  val wallboxInstallationPrice: PriceWithProfitAndWorkingTime

  /**
   * The prices for the wall box types
   */
  val wallBoxPrices: Prices<Wallbox>

  /**
   * The prices for the monitoring
   */
  val monitoringPrices: MonitoringPrices

  /**
   * Integrating the inverter into the network infrastructure
   */
  val integrateInverterIntoNetwork: PriceWithProfitAndWorkingTime

  /**
   * "Gerüst" per m²
   */
  val scaffolding: PriceWithProfitAndWorkingTime

  /**
   * Discount to be applied to the assembly cost when the system will be self-assembled
   */
  val optionalDiscountPrices: OptionalDiscountPrices

  /**
   * Per "Dachständerisolierung"
   */
  val dachStaenderIsolierung: PriceWithProfitAndWorkingTime

  /**
   * "Überspannungsschutz" per unit
   */
  val ueberSpannungsSchutz: PriceWithProfitAndWorkingTime
  val ueberSpannungsSchutzaWennExternerBlitzschutzVorhanden: PriceWithProfitAndWorkingTime
  val blitzschutzIntegrieren: PriceWithProfit

  /**
   * Per "Satelliten-Schüssel"
   */
  val moveSatelliteDish: PriceWithProfitAndWorkingTime

  /**
   * Per "Antenne"
   */
  val removeAntenna: PriceWithProfitAndWorkingTime

  /**
   * "Fahrpauschale" per km
   */
  val perKilometerTravelled: PriceWithProfit

  /**
   * Per optimizer
   */
  val optimizerPrice: PriceWithProfitAndWorkingTime

  /**
   * "Kabelwegzuschlag" per meter
   */
  val zuschlagKabelweg: PriceWithProfitAndWorkingTime

  /**
   * "Aufschlag Kreuzverbund" per "Modul"
   */
  val aufschlagKreuzverbund: @PerModule PriceWithProfitAndWorkingTime

  val startingUpPrice: PriceWithProfitAndWorkingTime

  /**
   * The working times depending on the assembly difficulty.
   * The values are required to be able to calculate the required number of trips
   */
  val assemblyDifficultyWorkingTimes: WorkingTimes<AssemblyDifficulty>

  /**
   * Returns the default assembly difficulty
   */
  val defaultAssemblyDifficulty: AssemblyDifficulty

  val sunnyHomeManager: PriceWithProfitAndWorkingTime

  val heaterRodPrices: PricesAndWorkingTimes<HeaterRod>

  val waermepumpenanbindung: PriceWithProfitAndWorkingTime

  val smaEnergyMeter: PriceWithProfitAndWorkingTime

  val erdspiessSetzen: PriceWithProfitAndWorkingTime

  val slsNachruesten: PriceWithProfitAndWorkingTime

  val neuerZaehlerschrank: PriceWithProfitAndWorkingTime

  val zaehlerschrankZusammenlegen: PriceWithProfitAndWorkingTime

  val einbauUnterverteiler: PriceWithProfitAndWorkingTime

  val roofIsolationThicknessPrices: RoofIsolationPrices

  val einbauDigitalerZaehlerPrice: PriceWithProfitAndWorkingTime

  val aufpreisSchienensystemPrice: PriceWithProfit

  val aufpreisSchwarzeKlemmePrice: PriceWithProfit

  val independenceManagerTypePrices: PricesAndWorkingTimes<IndependenceManagerType>


  override val uuid: Uuid
    get() = priceListId.uuid

  /**
   * Returns the price for a module type
   */
  operator fun get(moduleType: PvModule): PriceWithProfit

  operator fun get(roofType: RoofType): PriceWithProfit {
    return assemblyCostPerModule(roofType)
  }

  /**
   * Returns the price for a battery configuration
   */
  operator fun get(type: BatteryConfiguration): PriceWithProfitAndWorkingTime

  /**
   * Returns the price
   */
  operator fun get(metalRoofTile: MetalRoofTile): PriceWithProfit

  operator fun get(assemblyDifficulty: AssemblyDifficulty): PriceWithProfit

  operator fun get(inverter: InverterType): PriceWithProfit
  operator fun get(inverter: Inverter): PriceWithProfit
  operator fun get(inverter: HybridInverter): PriceWithProfit
  operator fun get(inverter: BasicBatteryInverter): PriceWithProfit

  operator fun get(wallbox: Wallbox): PriceWithProfit

  operator fun get(heaterRod: HeaterRod): PriceWithProfitAndWorkingTime


  /**
   * Calculates the monitoring price - depending on the power rating and whether a battery is installed
   */
  fun monitoringPrice(powerRating: PowerRating, includingBattery: Boolean): PriceWithProfit

  /**
   * Returns the extra charge *per module* depending on the roof type
   */
  fun assemblyCostPerModule(roofType: RoofType): @PerModule PriceWithProfit

  fun roofIsolationCostPerModule(roofIsolation: @cm Int): @PerModule PriceWithProfit

  fun getWorkingTime(assemblyDifficulty: AssemblyDifficulty): Duration


  /**
   * An id for a [PhotovoltaicsConfiguration]
   */
  @Serializable
  data class PriceListId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): PriceListId {
        return PriceListId(randomUuid4())
      }
    }
  }

}
