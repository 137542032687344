package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.configuration.quote.builder.WallboxSelection.WallboxSelectionId
import it.neckar.open.unit.si.m
import it.neckar.uuid.HasUuid
import kotlinx.serialization.Serializable

@Serializable
data class WallboxConfiguration(
  val id: WallboxSelectionId = WallboxSelectionId.random(),
  val energyConsumption: Wallbox.ChargingCapacity?,
  val cableLength: Wallbox.CableLength?,
  val cableDistance: @m Double,
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  companion object {
    fun getEmpty(id: WallboxSelectionId = WallboxSelectionId.random()): WallboxConfiguration {
      return WallboxConfiguration(
        id = id,
        energyConsumption = null,
        cableLength = null,
        cableDistance = 0.0,
      )
    }
  }
}
