@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.energy

import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.si.kWh
import it.neckar.open.unit.time.pa
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import kotlin.jvm.JvmInline

/**
 * Represents the usable battery capacity in kWh
 */
@Serializable
@JvmInline
value class BatteryCapacity(val capacity: AmountOfEnergy) {
  operator fun plus(other: BatteryCapacity): @pa BatteryCapacity {
    return BatteryCapacity(this.capacity + other.capacity)
  }

  operator fun times(factor: Double): BatteryCapacity {
    return BatteryCapacity(this.capacity * factor)
  }

  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return capacity.format(i18nConfiguration, whitespaceConfig)
  }

  companion object {
    val Zero: BatteryCapacity = BatteryCapacity(AmountOfEnergy.Zero)

    fun kWh(kWh: @kWh Double): BatteryCapacity {
      return BatteryCapacity(AmountOfEnergy.kWh(kWh))
    }
  }
}
