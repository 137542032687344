package store

import it.neckar.open.collections.fastForEach
import it.neckar.projects.pvplanner.PvPlannerService

/**
 * Contains the online states for each service
 */
data class ServicesOnlineState(
  val authService: OnlineState,
  val commentsService: OnlineState,
  val euScienceHubPlannerService: OnlineState,
  val geoCodeService: OnlineState,
  val pdfPreviewService: OnlineState,
  val pdfReportService: OnlineState,
  val processStatesService: OnlineState,
  val projectQueryService: OnlineState,
  val storageService: OnlineState,
) {
  /**
   * Returns the online state for the provided service
   */
  operator fun get(service: PvPlannerService): OnlineState {
    return when (service) {
      PvPlannerService.AuthService -> authService
      PvPlannerService.CommentsService -> commentsService
      PvPlannerService.EuScienceHubService -> euScienceHubPlannerService
      PvPlannerService.GeoInformationService -> geoCodeService
      PvPlannerService.PdfPreviewService -> pdfPreviewService
      PvPlannerService.PdfReportService -> pdfReportService
      PvPlannerService.ProcessStatesService -> processStatesService
      PvPlannerService.ProjectQueryService -> projectQueryService
      PvPlannerService.StorageService -> storageService
    }
  }

  /**
   * Returns true if *all* states are [OnlineState.Online]
   */
  fun allOnline(): Boolean {
    all {
      if (it != OnlineState.Online) {
        return false
      }
    }

    return true
  }

  /**
   * Returns true if all states are online or unknown
   */
  fun allOnlineOrUnknown(): Boolean {
    allEssential {
      if (it != OnlineState.Online && it != OnlineState.Unknown) {
        return false
      }
    }

    return true
  }

  /**
   * Returns true if *at least one* online state is [OnlineState.RefreshRequired]
   */
  fun refreshRequired(): Boolean {
    all {
      if (it == OnlineState.RefreshRequired) {
        return true
      }
    }

    return false
  }

  /**
   * Calls the block for each service
   */
  inline fun all(block: (OnlineState) -> Unit) {
    PvPlannerService.entries.fastForEach { service ->
      block(this[service])
    }
  }

  inline fun allEssential(block: (OnlineState) -> Unit) {
    PvPlannerService.essentialEntries.fastForEach { service ->
      block(this[service])
    }
  }

  /**
   * Returns the global state over all services
   */
  fun globalState(): OnlineState {
    all {
      when (it) {
        OnlineState.Offline -> return OnlineState.Offline
        OnlineState.RefreshRequired -> return OnlineState.RefreshRequired
        else -> {}
      }
    }

    return OnlineState.Online
  }

  /**
   * Creates a new instance with the updated online state (if necessary)
   */
  fun with(service: PvPlannerService, updatedOnlineState: OnlineState): ServicesOnlineState {
    if (this[service] == updatedOnlineState) {
      return this
    }

    return when (service) {
      PvPlannerService.AuthService -> copy(authService = updatedOnlineState)
      PvPlannerService.CommentsService -> copy(commentsService = updatedOnlineState)
      PvPlannerService.EuScienceHubService -> copy(euScienceHubPlannerService = updatedOnlineState)
      PvPlannerService.GeoInformationService -> copy(geoCodeService = updatedOnlineState)
      PvPlannerService.PdfPreviewService -> copy(pdfPreviewService = updatedOnlineState)
      PvPlannerService.PdfReportService -> copy(pdfReportService = updatedOnlineState)
      PvPlannerService.ProcessStatesService -> copy(processStatesService = updatedOnlineState)
      PvPlannerService.ProjectQueryService -> copy(projectQueryService = updatedOnlineState)
      PvPlannerService.StorageService -> copy(storageService = updatedOnlineState)
    }
  }

  companion object {
    /**
     * All services are marked as [OnlineState.Unknown]
     */
    val Unknown: ServicesOnlineState = ServicesOnlineState(
      authService = OnlineState.Unknown,
      commentsService = OnlineState.Unknown,
      euScienceHubPlannerService = OnlineState.Unknown,
      geoCodeService = OnlineState.Unknown,
      pdfPreviewService = OnlineState.Unknown,
      pdfReportService = OnlineState.Unknown,
      processStatesService = OnlineState.Unknown,
      projectQueryService = OnlineState.Unknown,
      storageService = OnlineState.Unknown,
    )
  }
}
