package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.configuration.components.ExistingBHKWFacilityConfiguration.ExistingBHKWFacilityConfigurationId
import it.neckar.lizergy.model.configuration.components.ExistingPVFacilityConfiguration.ExistingPVFacilityConfigurationId
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import it.neckar.uuid.withAdded
import it.neckar.uuid.withRemoved
import it.neckar.uuid.withReplaced
import kotlinx.serialization.Serializable

@Serializable
data class ExistingFacilitiesConfiguration(
  val id: ExistingFacilityConfigurationId = ExistingFacilityConfigurationId.random(),
  val bilderTypenschild: Boolean = false,
  val datenAbgelegt: Boolean = false,
  val existingPVFacilities: List<ExistingPVFacilityConfiguration> = emptyList(),
  val existingBHKWFacilities: List<ExistingBHKWFacilityConfiguration> = emptyList(),
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  operator fun get(id: ExistingPVFacilityConfigurationId): ExistingPVFacilityConfiguration {
    return existingPVFacilities.first { existingPVFacilityConfiguration ->
      existingPVFacilityConfiguration.uuid == id.uuid
    }
  }

  operator fun get(id: ExistingBHKWFacilityConfigurationId): ExistingBHKWFacilityConfiguration {
    return existingBHKWFacilities.first { existingBHKWFacilityConfiguration ->
      existingBHKWFacilityConfiguration.uuid == id.uuid
    }
  }

  fun withAdded(newConfiguration: ExistingPVFacilityConfiguration): ExistingFacilitiesConfiguration {
    return copy(existingPVFacilities = existingPVFacilities.withAdded(newConfiguration))
  }

  fun withAdded(newConfiguration: ExistingBHKWFacilityConfiguration): ExistingFacilitiesConfiguration {
    return copy(existingBHKWFacilities = existingBHKWFacilities.withAdded(newConfiguration))
  }

  fun withReplaced(updatedConfiguration: ExistingPVFacilityConfiguration): ExistingFacilitiesConfiguration {
    return copy(existingPVFacilities = existingPVFacilities.withReplaced(updatedConfiguration))
  }

  fun withReplaced(updatedConfiguration: ExistingBHKWFacilityConfiguration): ExistingFacilitiesConfiguration {
    return copy(existingBHKWFacilities = existingBHKWFacilities.withReplaced(updatedConfiguration))
  }

  fun withRemoved(configurationToRemove: ExistingPVFacilityConfiguration): ExistingFacilitiesConfiguration {
    return copy(existingPVFacilities = existingPVFacilities.withRemoved(configurationToRemove))
  }

  fun withRemoved(configurationToRemove: ExistingBHKWFacilityConfiguration): ExistingFacilitiesConfiguration {
    return copy(existingBHKWFacilities = existingBHKWFacilities.withRemoved(configurationToRemove))
  }

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ExistingFacilitiesConfiguration {
    val newId = ExistingFacilityConfigurationId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(
      id = newId,
      existingPVFacilities = existingPVFacilities.map { it.duplicate(mapOfOldToNewUuids) },
      existingBHKWFacilities = existingBHKWFacilities.map { it.duplicate(mapOfOldToNewUuids) },
    )
  }


  /**
   * An id for a [ExistingFacilitiesConfiguration]
   */
  @Serializable
  data class ExistingFacilityConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ExistingFacilityConfigurationId {
        return ExistingFacilityConfigurationId(randomUuid4())
      }
    }
  }


  companion object {
    fun getEmpty(id: ExistingFacilityConfigurationId = ExistingFacilityConfigurationId.random()): ExistingFacilitiesConfiguration {
      return ExistingFacilitiesConfiguration(id = id)
    }
  }

}
