package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.rest.jwt.JwtTokenPair
import store.LoggedInState
import store.PlannerClientState

/**
 * Is called if a JWT token has been refreshed
 */
data class JwtTokenPairRefreshed(val tokenPair: JwtTokenPair) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    val currentLoginState = loginState
    require(currentLoginState is LoggedInState) { "Invalid logged in state $currentLoginState" }
    return this.copy(
      loginState = currentLoginState.copy(
        tokenPair = tokenPair,
      )
    )
  }
}
