package store.actions

import it.neckar.common.redux.ChangeType
import store.ErrorState
import store.PlannerClientState


/**
 * Applies an error
 */
data class ErrorOccurredAction(val throwable: Throwable) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(errorState = ErrorState(throwable))
  }
}
