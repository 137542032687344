package it.neckar.react.common.router.key

import it.neckar.events.KeyStroke
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.react.common.router.*

/**
 * Handles key strokes for navigation elements
 */
class NavigationKeyStrokeHandler {
  private val keyStroke2Action = mutableMapOf<KeyStroke, NavigationElement>()

  /**
   * Registers a key stroke for a navigation element
   */
  fun registerKeyStroke(navigationElement: NavigationElement, keyStroke: KeyStroke) {
    logger.debug("Registering key stroke $keyStroke for navigation element ${navigationElement.completePath()}")
    keyStroke2Action[keyStroke] = navigationElement
  }

  /**
   * Should be called for each keystroke
   */
  fun handleKeyStroke(keyStroke: KeyStroke, navigateUrl: NavigateUrlFunction): NavigationResult {
    val navigationElement = keyStroke2Action[keyStroke] ?: return NavigationResult.Ignored

    logger.debug("Found navigation element for [$keyStroke]: ${navigationElement.completePath()}")
    navigateUrl(NavigateUrl(navigationElement.completePath()))
    return NavigationResult.Handled
  }

  companion object {
    private val logger: Logger = LoggerFactory.getLogger("it.neckar.react.common.router.key.NavigationKeyStrokeHandler")
  }

  enum class NavigationResult {
    Handled,
    Ignored,

  }
}
