package components.project

import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry.AdvanceInvoiceProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry.AssemblyPortfolioProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry.GridAssessmentProcessStates
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry.OrderSpecialMaterialProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.react.common.table.*
import react.*
import react.dom.*
import router.useDocumentTitle
import store.hooks.useRequireCompanyForLoggedInUser

val OrderSpecialMaterialsOverviewFromUrl: FC<Props> = fc("OrderSpecialMaterialsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = OrderSpecialMaterialProcessStates.entries

  useDocumentTitle(companyName, "Sondermaterial Bestellungen")

  h1("mb-3") { +"Sondermaterial Bestellungen" }

  ProjectsOverview {
      attrs {
        this.processStatesToFilter = relevantProcessStates
        this.processStatesToHide = listOf(OrderSpecialMaterialProcessStates.Done, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
        this.projectQueryComponent = ProjectQueryComponent.OrderSpecialMaterial
        this.sortedBy = SortedBy.SortedDescending
        this.includeArchived = false
        this.keyPrefix = LocalStorageKeyPrefix("OrderSpecialMaterials")
      }
    }
}

val GridAssessmentsOverviewFromUrl: FC<Props> = fc("GridAssessmentsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = GridAssessmentProcessStates.entries

  useDocumentTitle(companyName, "Netzvoranfragen")

  h1("mb-3") { +"Netzvoranfragen" }

  ProjectsOverview {
      attrs {
        this.processStatesToFilter = relevantProcessStates
        this.processStatesToHide = listOf(GridAssessmentProcessStates.Accepted, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
        this.projectQueryComponent = ProjectQueryComponent.GridAssessment
        this.sortedBy = SortedBy.SortedDescending
        this.includeArchived = false
        this.keyPrefix = LocalStorageKeyPrefix("GridAssessments")
      }
  }
}

val AssemblyPortfoliosOverviewFromUrl: FC<Props> = fc("AssemblyPortfoliosOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = AssemblyPortfolioProcessStates.entries

  useDocumentTitle(companyName, "Montagemappen erstellen")

  h1("mb-3") { +"Montagemappen erstellen" }

  ProjectsOverview {
      attrs {
        this.processStatesToFilter = relevantProcessStates
        this.processStatesToHide = listOf(AssemblyPortfolioProcessStates.Accepted, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
        this.projectQueryComponent = ProjectQueryComponent.AssemblyPortfolio
        this.sortedBy = SortedBy.SortedDescending
        this.includeArchived = false
        this.keyPrefix = LocalStorageKeyPrefix("AssemblyPortfolios")
      }
  }
}

val ScheduleAssembliesOverviewFromUrl: FC<Props> = fc("ScheduleAssembliesOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = listOf(AssemblyRoofPreparationProcessStates.Missing, AssemblyBasementPreparationProcessStates.Missing)

  useDocumentTitle(companyName, "Montagetermine einplanen")

  h1("mb-3") { +"Montagetermine einplanen" }

  ProjectsOverview {
      attrs {
        this.processStatesToFilter = relevantProcessStates
        this.processStatesToHide = listOf(ProjectProcessStates.Archived, ProjectProcessStates.Paused)
        this.projectQueryComponent = ProjectQueryComponent.ScheduleAssembly
        this.sortedBy = SortedBy.SortedDescending
        this.includeArchived = false
        this.keyPrefix = LocalStorageKeyPrefix("ScheduleAssemblies")
      }
  }
}

val AdvanceInvoicesOverviewFromUrl: FC<Props> = fc("AdvanceInvoicesOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = AdvanceInvoiceProcessStates.entries

  useDocumentTitle(companyName, "Anzahlungsrechnungen")

  h1("mb-3") { +"Anzahlungsrechnungen" }

  ProjectsOverview {
      attrs {
        this.processStatesToFilter = relevantProcessStates
        this.processStatesToHide = listOf(AdvanceInvoiceProcessStates.Paid, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
        this.projectQueryComponent = ProjectQueryComponent.AdvanceInvoice
        this.sortedBy = SortedBy.SortedDescending
        this.includeArchived = false
        this.keyPrefix = LocalStorageKeyPrefix("AdvanceInvoices")
      }
    }
}
