package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.geometry.Coordinates
import it.neckar.geometry.Rectangle
import it.neckar.geometry.Shape
import it.neckar.geometry.Size
import it.neckar.open.unit.si.mm

sealed interface Resizable : Clickable {
  var location: @RoofRelative Coordinates

  /**
   * The size of the [Resizable] (in [mm])
   */
  var size: @mm Size

  val minimumSize: @mm Size

  /**
   * Updates the size and location by the given values.
   *
   * This method is used when resizing by handles
   */
  fun updateSizeAndLocation(deltaLocationX: @mm Double, deltaLocationY: @mm Double, deltaWidth: @mm Double, deltaHeight: @mm Double, planningModel: PvRoofPlanningModel) {
    var newTargetSize = size.plus(deltaWidth, deltaHeight)

    if (newTargetSize.atLeastOneSmallerThan(minimumSize)) {
      newTargetSize = newTargetSize.coerceAtLeast(minimumSize)
    }

    var newLocation = location.plus(deltaLocationX, deltaLocationY)

    if (newLocation.x < planningModel.suggestedRoofInsets.left || newLocation.y < planningModel.suggestedRoofInsets.bottom) {
      newLocation = newLocation.coerceAtLeast(
        Coordinates(
          planningModel.suggestedRoofInsets.left,
          planningModel.suggestedRoofInsets.bottom,
        )
      )
    }

    val rightEdge = planningModel.roofSize.width - planningModel.suggestedRoofInsets.right
    val topEdge = planningModel.roofSize.height - planningModel.suggestedRoofInsets.top
    if (newLocation.x + newTargetSize.width > rightEdge || newLocation.y + newTargetSize.height > topEdge) {
      newLocation = newLocation.coerceAtMost(
        Coordinates(
          rightEdge - minimumSize.width,
          topEdge - minimumSize.height,
        )
      )
      newTargetSize = newTargetSize.coerceAtMost(
        Size(
          rightEdge - newLocation.x,
          topEdge - newLocation.y,
        )
      )
    }

    location = newLocation
    size = newTargetSize
  }

  /**
   * Returns the bounds for this unusable area
   */
  fun bounds(): @RoofRelative @mm Shape {
    return Rectangle(location, size)
  }

  /**
   * Returns true if this overlaps with a module/area with the given location + size
   */
  fun overlaps(shape: @RoofRelative @mm Shape): Boolean {
    return this.bounds().overlaps(shape)
  }
}
