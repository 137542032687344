package components.company

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import react.*
import react.dom.*
import router.useCompanyFromUrl
import router.useDocumentTitle

/**
 * Change password form
 */
val EditCompanyFromUrl: FC<Props> = fc("EditCompanyFromUrl") {
  val company = useCompanyFromUrl()
  val companyName = company.simpleName

  useDocumentTitle(companyName, company.name)
  EditCompany {
    attrs {
      this.forCompany = company
    }
  }
}

val EditCompany: FC<CompanyOverviewProps> = fc("EditCompany") { props ->
  val forCompany = props::forCompany.safeGet()

  div("gy-3") {
    ChangeCompanyInfoForm {
      attrs {
        this.forCompany = forCompany
      }
    }
  }
}

external interface CompanyOverviewProps : Props {
  var forCompany: PlannerCompanyInformation
}
