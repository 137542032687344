package components.assemblyPortfolio

import components.gridAssessment.entryPreview
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio
import it.neckar.lizergy.model.assemblyPortfolio.formatGeruest
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedColoredMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedColoredOnlyWhereVisibleMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedNormalMetalRoofTile
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useSelectCompanyResolver

internal val DachmontageOverview: FC<DachmontageOverviewProps> = fc("DachmontageOverview") { props ->
  val companyResolver = useSelectCompanyResolver()

  val project = props::project.safeGet()
  val configuration = props::currentConfiguration.safeGet()
  val assemblyPortfolio = project.assemblyPortfolio

  val layoutsToSave = useState(assemblyPortfolio.assemblyPortfolioModuleLayouts)
  val geruestVorhanden = useState(assemblyPortfolio.geruestVorhanden)
  val oberleitungZuIsolieren = useState(assemblyPortfolio.oberleitungZuIsolieren ?: configuration.assemblyConfiguration.dachstaenderIsolierung)
  val satAnlageZuVersetzen = useState(assemblyPortfolio.satAnlageZuVersetzen ?: configuration.assemblyConfiguration.moveSatelliteDish)
  val antenneEntfernen = useState(assemblyPortfolio.antenneEntfernen ?: configuration.assemblyConfiguration.removeAntenna)
  val firstEingespeist = useState(assemblyPortfolio.firstEingespeist)


  useMemo(
    layoutsToSave.value,
    geruestVorhanden.value,
    oberleitungZuIsolieren.value,
    satAnlageZuVersetzen.value,
    antenneEntfernen.value,
    firstEingespeist.value,
  ) {
    if (
      layoutsToSave.value != assemblyPortfolio.assemblyPortfolioModuleLayouts ||
      geruestVorhanden.value != assemblyPortfolio.geruestVorhanden ||
      oberleitungZuIsolieren.value != assemblyPortfolio.oberleitungZuIsolieren ||
      satAnlageZuVersetzen.value != assemblyPortfolio.satAnlageZuVersetzen ||
      antenneEntfernen.value != assemblyPortfolio.antenneEntfernen ||
      firstEingespeist.value != assemblyPortfolio.firstEingespeist
    ) {
      UiActions.saveAssemblyPortfolio(
        assemblyPortfolio.copy(
          assemblyPortfolioModuleLayouts = layoutsToSave.value,
          geruestVorhanden = geruestVorhanden.value,
          oberleitungZuIsolieren = oberleitungZuIsolieren.value,
          satAnlageZuVersetzen = satAnlageZuVersetzen.value,
          antenneEntfernen = antenneEntfernen.value,
          firstEingespeist = firstEingespeist.value,
        )
      )
    }
  }


  div("row my-4") {
    div("col-lg-6") {
      h3("my-3") { +"Dachmontage" }

      entryPreview("Gerüst vorhanden") {
        nullableFloatingInputField(
          valueAndSetter = geruestVorhanden,
          fieldName = "geruestVorhanden",
          title = configuration.formatGeruest(companyResolver),
        )
      }
      entryPreview("Oberleitung zu isolieren") {
        checkbox(
          valueAndSetter = oberleitungZuIsolieren,
          fieldName = "oberleitungZuIsolieren",
          title = "Oberleitung zu isolieren",
        )
      }
      entryPreview("SAT-Anlage zu versetzen") {
        checkbox(
          valueAndSetter = satAnlageZuVersetzen,
          fieldName = "satAnlageZuVersetzen",
          title = "SAT-Anlage zu versetzen",
        )
      }
      entryPreview("Antenne entfernen") {
        checkbox(
          valueAndSetter = antenneEntfernen,
          fieldName = "antenneEntfernen",
          title = "Antenne entfernen",
        )
      }
      entryPreview("First eingespeist") {
        checkbox(
          valueAndSetter = firstEingespeist,
          fieldName = "firstEingespeist",
          title = "First eingespeist",
        )
      }

      configuration.moduleLayouts.validElements.fastForEachIndexed { index, layout ->
        div("my-4") {
          DachmontageModuleLayoutOverview {
            attrs {
              this.moduleLayout = layout
              this.layoutIndex = index
              this.layoutsToSave = layoutsToSave
            }
          }
        }
      }
    }
  }
}

external interface DachmontageOverviewProps : Props {
  var project: ResolvedProject
  var currentConfiguration: QuoteConfiguration
}


private val DachmontageModuleLayoutOverview: FC<DachmontageModuleLayoutOverviewProps> = fc("DachmontageModuleLayoutOverview") { props ->
  val moduleLayout = props::moduleLayout.safeGet()
  val layoutIndex = props::layoutIndex.safeGet()
  val layoutsToSave = props::layoutsToSave.getNotNull()
  val roof = moduleLayout.roof
  val layout = layoutsToSave.value.firstOrNull { it.id == moduleLayout.id }
    ?: AssemblyPortfolio.Layout.getEmpty(moduleLayout.id)

  val name = useState(layout.name)
  val anzahlModule = useState(layout.anzahlModule)
  val module = useState(layout.module)
  val montageSystem = useState(layout.montageSystem)
  val montageSystemSuffix = useState(layout.montageSystemSuffix)
  val anzahlBlechZiegel = useState(layout.anzahlBlechZiegel)
  val blechZiegel = useState(layout.blechZiegel)
  val moduleImKreuzverbund = useState(layout.moduleImKreuzverbund)
  val aufdachdaemmung = useState(layout.aufdachdaemmung)


  useMemo(
    name.value,
    anzahlModule.value,
    module.value,
    montageSystem.value,
    montageSystemSuffix.value,
    anzahlBlechZiegel.value,
    blechZiegel.value,
    moduleImKreuzverbund.value,
    aufdachdaemmung.value,
  ) {
    if (
      name.value != layout.name ||
      anzahlModule.value != layout.anzahlModule ||
      module.value != layout.module ||
      montageSystem.value != layout.montageSystem ||
      montageSystemSuffix.value != layout.montageSystemSuffix ||
      anzahlBlechZiegel.value != layout.anzahlBlechZiegel ||
      blechZiegel.value != layout.blechZiegel ||
      moduleImKreuzverbund.value != layout.moduleImKreuzverbund ||
      aufdachdaemmung.value != layout.aufdachdaemmung
    ) {
      val newLayout = layout.copy(
        name = name.value,
        anzahlModule = anzahlModule.value,
        module = module.value,
        montageSystem = montageSystem.value,
        montageSystemSuffix = montageSystemSuffix.value,
        anzahlBlechZiegel = anzahlBlechZiegel.value,
        blechZiegel = blechZiegel.value,
        moduleImKreuzverbund = moduleImKreuzverbund.value,
        aufdachdaemmung = aufdachdaemmung.value,
      )

      layoutsToSave.setter(if (layoutsToSave.value.firstOrNull { it.id == moduleLayout.id } != null) {
        layoutsToSave.value.map { layout ->
          if (layout.id == moduleLayout.id) newLayout else layout
        }
      } else {
        layoutsToSave.value + newLayout
      })
    }
  }


  div("row gx-0") {
    h4("mb-3") { +"Dach ${layoutIndex + 1}" }

    div("col-lg-12") {
      nullableFloatingInputField(
        valueAndSetter = name,
        fieldName = "layoutName-${moduleLayout.uuid}",
        title = moduleLayout.layoutName,
      ) {
        attrs {
          mergedBelow()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingIntInputField(
        valueAndSetter = anzahlModule,
        fieldName = "anzahlModule-${moduleLayout.uuid}",
        title = "${moduleLayout.modulesCount} Stück",
      ) {
        attrs {
          mergedRight()
          mergedAbove()
          mergedBelow()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingInputField(
        valueAndSetter = module,
        fieldName = "module-${moduleLayout.uuid}",
        title = moduleLayout.moduleType.description,
      ) {
        attrs {
          mergedLeft()
          mergedAbove()
          mergedBelow()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingInputField(
        valueAndSetter = montageSystem,
        fieldName = "montageSystem-${moduleLayout.uuid}",
        title = roof.roofType.description,
      ) {
        attrs {
          mergedRight()
          mergedAbove()
          mergedBelow()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingInputField(
        valueAndSetter = montageSystemSuffix,
        fieldName = "montageSystemSuffix-${moduleLayout.uuid}",
        title = "Montagesystem von",
      ) {
        attrs {
          mergedLeft()
          mergedAbove()
          mergedBelow()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingIntInputField(
        valueAndSetter = anzahlBlechZiegel,
        fieldName = "anzahlBlechZiegel-${moduleLayout.uuid}",
        title = (roof.metalRoofTileConstructionType?.amount ?: moduleLayout.metalRoofTileCount).let { if (it > 0) "$it Stück" else "Keine" },
      ) {
        attrs {
          mergedRight()
          mergedAbove()
        }
      }
    }
    div("col-lg-6") {
      nullableFloatingInputField(
        valueAndSetter = blechZiegel,
        fieldName = "blechZiegel-${moduleLayout.uuid}",
        title = when (val metalRoofTileConstructionType = roof.metalRoofTileConstructionType) {
          ResolvedNormalMetalRoofTile, is ResolvedColoredMetalRoofTile -> metalRoofTileConstructionType.format(roof.roofTile?.metalRoofTile?.description)
          is ResolvedColoredOnlyWhereVisibleMetalRoofTile -> metalRoofTileConstructionType.formatWithoutAmount(roof.roofTile?.metalRoofTile?.description)
          null -> "Blechziegel"
        },
      ) {
        attrs {
          mergedLeft()
          mergedAbove()
        }
      }
    }
    div("my-3") {
      entryPreview("Aufdachdämmung") {
        nullableFloatingIntInputField(
          valueAndSetter = aufdachdaemmung,
          fieldName = "aufdachdaemmung-${moduleLayout.uuid}",
          title = roof.isolationThickness?.let { "$it cm" } ?: "-",
        )
      }
      entryPreview("Module im Kreuzverbund") {
        nullableFloatingIntInputField(
          valueAndSetter = moduleImKreuzverbund,
          fieldName = "moduleImKreuzverbund-${moduleLayout.uuid}",
          title = "${moduleLayout.horizontalModulesCount} Stück",
        )
      }
    }
  }
}

external interface DachmontageModuleLayoutOverviewProps : Props {
  var moduleLayout: ResolvedModuleLayout
  var layoutIndex: Int
  var layoutsToSave: StateInstance<List<AssemblyPortfolio.Layout>>
}
