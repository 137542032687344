package it.neckar.lizergy.model.configuration

import com.benasher44.uuid.Uuid
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.components.FacilityConfiguration.FacilityConfigurationId
import it.neckar.lizergy.model.configuration.components.ResolvedFacilityConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.open.unit.si.m
import it.neckar.uuid.HasUuid
import kotlinx.serialization.Serializable

@Serializable
data class BlueprintFacilityConfiguration(
  val id: FacilityConfigurationId = FacilityConfigurationId.random(),
  val kabelwegZugschlagLength: @m Double = 0.0,
  val heizstab: Boolean = false,
  val waermepumpenanbindung: Boolean = false,
  val erdspiessSetzen: Boolean = false,
  val onlineMonitoring: ConfiguredOptionality = ConfiguredOptionality.Selected,
  val integrateInverterIntoNetwork: ConfiguredOptionality = ConfiguredOptionality.NotSelected,
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  fun toFacilityConfiguration(defaultHeaterRod: HeaterRod?): ResolvedFacilityConfiguration {
    return ResolvedFacilityConfiguration(
      id = id,
      numberOfOptimizers = 0,
      kabelwegZugschlagLength = kabelwegZugschlagLength,
      numberUeberspannungsSchutz = null,
      inverterSelection = ResolvedInverterSelection.getEmpty(),
      batteryConfiguration = null,
      facilityWorkEffort = ConfigurationItem.risikoZuschlag,
      waermepumpenanbindung = waermepumpenanbindung,
      erdspiessSetzen = erdspiessSetzen,
      onlineMonitoring = onlineMonitoring,
      integrateInverterIntoNetwork = integrateInverterIntoNetwork,
      heaterRod = if (heizstab) defaultHeaterRod else null,
    )
  }

  companion object {
    fun getEmpty(): BlueprintFacilityConfiguration {
      return BlueprintFacilityConfiguration(
        kabelwegZugschlagLength = 0.0,
        heizstab = false,
        waermepumpenanbindung = false,
        erdspiessSetzen = false,
        onlineMonitoring = ConfiguredOptionality.Selected,
        integrateInverterIntoNetwork = ConfiguredOptionality.NotSelected,
      )
    }
  }
}
