import components.ClientVersionMismatchPage
import components.EssentialsLoadingPage
import components.login.LoginPage
import it.neckar.react.common.error.*
import it.neckar.react.common.router.*
import react.*
import react.router.dom.*
import remix.run.router.Router
import router.Navigation
import services.UiActions
import store.actions.LogoutReason
import store.hooks.useLoadEssentialsOnce
import store.useSelector

/**
 * The entry point for the application
 */
val PlannerMainComponent: FC<Props> = fc("PlannerMainComponent") {
  //Check if the user is logged in - else show the login form immediately
  val loginState = useSelector { loginState }
  val onlineState = useSelector { onlineState }
  val errorState = useSelector { errorState }

  val deploymentZone = useSelector {
    deploymentZone
  }

  if (onlineState.refreshRequired()) {
    ClientVersionMismatchPage {}
    return@fc
  }

  if (deploymentZone.isProduction().not()) {
    if (errorState.currentException != null) {
      //Show the "global" error exception - if there is one
      FallbackErrorPage {
        attrs {
          this.throwable = errorState.currentException
          this.errorInfo = errorInfo

          this.contactEmail = "planner-support@neckar.it"
          this.contactPhoneNumber = "07473 959 49 60"

          this.logoutAction = {
            UiActions.logout(LogoutReason.UserInteraction)
          }
        }
      }
      return@fc
    }
  }

  if (loginState.loggedIn) {
    PlannerMainComponentLoggedIn {}
  } else {
    LoginPage {}
  }

}

/**
 * The main component for logged-in users
 */
val PlannerMainComponentLoggedIn: FC<Props> = fc("PlannerMainComponentLoggedIn") {
  val essentials = useLoadEssentialsOnce()

  if (essentials == null) {
    EssentialsLoadingPage {}
  } else {
    PlannerRoutes {}
  }
}

/**
 * Keep the router as field
 */
val appRouter: Router = Navigation.generateRouter()

val PlannerRoutes: FC<Props> = FC<Props> {
  RouterProvider {
    router = appRouter
  }
}
