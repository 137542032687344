package components


import it.neckar.lizergy.model.PlannerFeatureFlags
import it.neckar.react.common.featureflags.*
import react.*
import react.dom.*
import router.useDocumentTitle
import store.hooks.useRequireCompanyForLoggedInUser

val EditFeatureFlags: FC<Props> = fc("EditFeatureFlags") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  h1 { +"Experimente" }

  useDocumentTitle(companyName, "Experimente")
  EditFeatureFlagsComponent {
    attrs {
      /* specificFlags defines which project specific Feature Flags will be displayed
      * if null: all generic flags (defined in FeatureFlag.kt) will be displayed*/
      specificFlags = PlannerFeatureFlags.availableSpecificFlags

    }
  }
}
