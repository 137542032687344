@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout.roof

import io.ktor.util.*
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
sealed interface ResolvedMetalRoofTileConstructionType {
  val color: MetalRoofTileColor?
  val amount: Int?

  fun isColored(): Boolean {
    return when (this) {
      ResolvedNormalMetalRoofTile -> false
      is ResolvedColoredMetalRoofTile -> true
      is ResolvedColoredOnlyWhereVisibleMetalRoofTile -> true
    }
  }

  fun unResolve(): MetalRoofTileConstructionType

  fun format(metalRoofTileDescription: String?): String
}

@Serializable
@SerialName("NormalMetalRoofTile")
object ResolvedNormalMetalRoofTile : ResolvedMetalRoofTileConstructionType {
  override val color: MetalRoofTileColor?
    get() = null
  override val amount: Int?
    get() = null

  override fun unResolve(): NormalMetalRoofTile {
    return NormalMetalRoofTile
  }

  override fun format(metalRoofTileDescription: String?): String {
    return metalRoofTileDescription ?: "Metalldachziegel"
  }

  override fun toString(): String {
    return "NormalMetalRoofTile"
  }
}

@Serializable
@SerialName("ColoredMetalRoofTile")
data class ResolvedColoredMetalRoofTile(override val color: MetalRoofTileColor) : ResolvedMetalRoofTileConstructionType {
  override val amount: Int?
    get() = null

  override fun unResolve(): ColoredMetalRoofTile {
    return ColoredMetalRoofTile(color.id)
  }

  override fun format(metalRoofTileDescription: String?): String {
    return "${color.name.toLowerCasePreservingASCIIRules()}e ${metalRoofTileDescription ?: "Metalldachziegel"}"
  }
}

@Serializable
@SerialName("ColoredOnlyWhereVisibleMetalRoofTile")
data class ResolvedColoredOnlyWhereVisibleMetalRoofTile(
  override val color: MetalRoofTileColor,
  override val amount: Int,
) : ResolvedMetalRoofTileConstructionType {

  override fun unResolve(): ColoredOnlyWhereVisibleMetalRoofTile {
    return ColoredOnlyWhereVisibleMetalRoofTile(
      colorId = color.id,
      amount = amount,
    )
  }

  override fun format(metalRoofTileDescription: String?): String {
    return "$amount ${formatWithoutAmount(metalRoofTileDescription)}"
  }

  fun formatWithoutAmount(metalRoofTileDescription: String?): String {
    return "${color.name.toLowerCasePreservingASCIIRules()}e ${metalRoofTileDescription ?: "Metalldachziegel"} im Sichtbereich"
  }
}
