package components.project.configuration.location

import com.meistercharts.maps.MapCoordinates
import com.meistercharts.maps.RouteMapCoordinates
import components.project.QuoteConfigurationTabProps
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.Address
import it.neckar.lizergy.model.configuration.energy.SpecificAnnualProduction
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.location.MapCoordinatesInformation
import it.neckar.open.kotlin.lang.toIntCeil
import it.neckar.open.unit.si.kWh_kWp_a
import it.neckar.open.unit.si.km
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import kotlinx.html.id
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser
import kotlin.math.roundToInt

val EditConfigurationLocationFromUrl: FC<Props> = fc("EditConfigurationLocationFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Standort", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]

    EditConfigurationLocation {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = Editable
      }
    }
  }
}


val EditConfigurationLocation: FC<QuoteConfigurationTabProps> = fc("EditConfigurationLocation") { props ->
  val loggedInCompany = useRequireCompanyForLoggedInUser()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val location = quoteConfiguration.location
  val editableStatus = props::editableStatus.safeGet()

  val addressToSave = useState(location.address)
  val coordinatesToSave = useState(location.coordinates)

  val shippingStartCoordinated = useState(MapCoordinates.lizergy)
  val manualShippingDistanceToSave = useState(quoteConfiguration.shippingDistanceManual)
  val calculatedShippingDistanceToSave = useState(quoteConfiguration.shippingDistanceCalculated)

  @kWh_kWp_a val specificAnnualProduction = useState(location.specificAnnualProduction.kWhPerkWpAndYear.roundToInt())

  val locationInformationToSave = useMemo(addressToSave.value, coordinatesToSave.value, specificAnnualProduction.value) {
    LocationInformation(
      address = addressToSave.value,
      coordinates = coordinatesToSave.value,
      specificAnnualProduction = SpecificAnnualProduction(specificAnnualProduction.value.toDouble())
    )
  }


  useMemo(locationInformationToSave, manualShippingDistanceToSave.value, calculatedShippingDistanceToSave.value) {
    val configurationToSave = quoteConfiguration.copy(
      location = locationInformationToSave,
      shippingDistanceManual = manualShippingDistanceToSave.value,
      shippingDistanceCalculated = calculatedShippingDistanceToSave.value,
    )

    UiActions.saveQuoteConfiguration(project, configurationToSave)
  }


  useEffect(loggedInCompany) {
    UiActions.coordinatesForAddress(loggedInCompany.address) {
      shippingStartCoordinated.setter(it?.coords ?: MapCoordinates.lizergy)
    }
  }

  useEffect(shippingStartCoordinated.value, coordinatesToSave.value) {
    coordinatesToSave.value?.let { locationCoords ->
      UiActions.routeInformationForCoordinates(
        RouteMapCoordinates(
          startMapCoordinates = shippingStartCoordinated.value,
          destinationMapCoordinates = locationCoords.coords,
        )
      ) { routeInformation ->
        if (routeInformation != null) {
          calculatedShippingDistanceToSave.setter((routeInformation.distance / 1000.0).toIntCeil())
        }
      }
    }
  }


  EditLocation {
    attrs {
      this.addressToSave = addressToSave
      this.coordinatesToSave = coordinatesToSave
      this.manualShippingDistanceToSave = manualShippingDistanceToSave
      this.calculatedShippingDistanceToSave = calculatedShippingDistanceToSave
      this.customerAddress = project.customer.address
      this.editableStatus = editableStatus
    }
  }

  div("my-3") {

    h2 {
      +"Ertrag"
    }

    div("form-floating") {
      floatingIntInputField(
        valueAndSetter = specificAnnualProduction,
        fieldName = "specificAnnualProduction",
        title = "Regionaler Ertrag",
        editableStatus = project.isProjectEditable(),
      ) {
        attrs {
          ariaDescribedBy = "specificAnnualProductionDescription"
        }
      }
    }

    div(classes = "form-text") {
      +"Der spezifische Ertrag pro installierter kWp pro Jahr. Abhängig von der Region."

      attrs {
        id = "specificAnnualProductionDescription"
      }
    }
  }

}

val EditLocation: FC<EditLocationProps> = fc("EditLocation") { props ->
  val addressToSave = props::addressToSave.getNotNull()
  val coordinatesToSave = props.coordinatesToSave
  val manualShippingDistanceToSave = props::manualShippingDistanceToSave.getNotNull()
  val calculatedShippingDistanceToSave = props::calculatedShippingDistanceToSave.getNotNull()

  val customerAddress = props::customerAddress.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val address = addressToSave.value

  val addressStreet = useState(address.street)
  val addressHouseNumber = useState(address.houseNumber)
  val addressZipCode = useState(address.zipCode)
  val addressCity = useState(address.city)


  useMemo(addressStreet.value, addressHouseNumber.value, addressZipCode.value, addressCity.value) {
    addressToSave.setter(
      Address(addressStreet.value, addressHouseNumber.value, addressZipCode.value, addressCity.value)
    )
  }

  useEffect(addressToSave.value) {
    UiActions.coordinatesForAddress(addressToSave.value) {
      coordinatesToSave.setter(it)
    }
  }


  h1 {
    +"Standort"
  }

  locationForm(
    addressStreet = addressStreet,
    addressHouseNumber = addressHouseNumber,
    addressZipCode = addressZipCode,
    addressCity = addressCity,
    customerAddress = customerAddress,
    mapCoordinates = coordinatesToSave.value?.coords,
    editableStatus = editableStatus,
  )

  div("row my-3") {
    div("col-sm-6") {
      nullableFloatingIntInputField(
        valueAndSetter = manualShippingDistanceToSave,
        fieldName = "shippingDistance",
        title = if (manualShippingDistanceToSave.value == null) calculatedShippingDistanceToSave.value?.let { "$it km" } ?: "Entfernung (km)" else "Entfernung (km)",
        numberConstraint = ZeroOrPositive,
        editableStatus = editableStatus,
      )
    }
  }

}


external interface EditLocationProps : Props {
  var addressToSave: StateInstance<Address>
  var coordinatesToSave: StateInstance<MapCoordinatesInformation?>
  var manualShippingDistanceToSave: StateInstance<@km Int?>
  var calculatedShippingDistanceToSave: StateInstance<@km Int?>
  var customerAddress: Address
  var editableStatus: EditableStatus
}
