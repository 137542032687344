package it.neckar.lizergy.model.configuration.energy

import it.neckar.open.formatting.decimalFormat2digits
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.other.Wp
import it.neckar.open.unit.other.kWp
import it.neckar.open.unit.si.V
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * The power voltage in [V]
 */
@Serializable
@JvmInline
value class Voltage internal constructor(
  val volts: @V Double,
) : Comparable<Voltage> {

  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "${decimalFormat2digits.format(volts, i18nConfiguration)}${whitespaceConfig.smallSpace}V"
  }

  override operator fun compareTo(other: Voltage): Int {
    return this.volts.compareTo(other.volts)
  }

  operator fun minus(other: Voltage): Voltage {
    return Voltage(this.volts - other.volts)
  }

  operator fun plus(other: Voltage): Voltage {
    return Voltage(this.volts + other.volts)
  }

  operator fun times(factor: Double): Voltage {
    return Voltage(volts * factor)
  }

  operator fun times(factor: Int): Voltage {
    return Voltage(volts * factor)
  }

  override fun toString(): String {
    return format(I18nConfiguration.Germany, WhitespaceConfig.Spaces)
  }


  companion object {
    val Zero: Voltage = Voltage(0.0)

    fun voltage(voltage: @V Double): Voltage {
      return Voltage(voltage)
    }
  }
}

/**
 * Converts a value to watt peak
 */
val @Wp Int.volts: Voltage
  get() = Voltage.voltage(this.toDouble())

val @kWp Double.volts: Voltage
  get() = Voltage.voltage(this)


/**
 * Calculates the sum of power ratings
 */
fun Iterable<Voltage>.sum(): Voltage {
  return sumOf {
    it.volts
  }.volts
}
