package store.hooks

import com.benasher44.uuid.Uuid
import it.neckar.comments.Comment
import it.neckar.ktor.client.plugin.stats.CompletedRequestDescriptor
import it.neckar.ktor.client.plugin.stats.PendingRequestDescriptor
import it.neckar.ktor.client.plugin.stats.PendingRequestsState
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.company.UsersAndCompanies
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.open.collections.fastMap
import it.neckar.open.unit.other.Order
import it.neckar.open.unit.other.Sorted
import it.neckar.open.unit.other.pct
import it.neckar.processStates.AllProcessStatesForComponents
import it.neckar.react.common.annotations.*
import react.*
import services.Loading
import store.Essentials
import store.IsEssential
import store.LoggedInState
import store.NotLoggedInException
import store.useSelector


/**
 * Returns the users and companies from the state.
 */
@IsEssential
@UsesHooks
fun useSelectUsersAndCompanies(): UsersAndCompanies {
  return useSelector { usersAndCompanies }
}

@IsEssential
@UsesHooks
fun useSelectUserResolver(): UserResolver {
  return useSelector { usersAndCompanies }
}

@IsEssential
@UsesHooks
fun useSelectCompanyResolver(): CompanyResolver {
  return useSelector { usersAndCompanies }
}

/**
 * Returns the [AvailableProducts] - if already loaded.
 * Or schedules loading of the [AvailableProducts] and returns null
 */
@IsEssential
@UsesHooks
fun useSelectAvailableProducts(): AvailableProducts {
  return useSelector { availableProducts }
}

/**
 * Returns the [ProductResolver] - if already loaded.
 * Or schedules loading of the [ProductResolver] and returns null
 */
@IsEssential
@UsesHooks
fun useSelectProductResolver(): ProductResolver {
  return useSelector { productResolver }
}

/**
 * Returns the [PriceList] - if already loaded.
 * Or schedules loading of the [PriceList] and returns null
 */
@IsEssential
@UsesHooks
fun useSelectPriceList(): PriceList {
  return useSelector { priceList }
}

@UsesHooks
fun useSelectPendingRequestsState(): PendingRequestsState {
  return useSelector { pendingRequestsState }
}

@UsesHooks
fun useSelectPendingRequests(): List<PendingRequestDescriptor> {
  return useSelector { pendingRequestsState.pendingRequests }
}

@UsesHooks
fun useSelectCompletedRequests(): List<CompletedRequestDescriptor> {
  return useSelector { pendingRequestsState.completedRequests }
}

/**
 * Returns the progress of the pending requests
 */
@UsesHooks
fun useSelectPendingRequestsProgress(): @pct Double {
  return useSelector { pendingRequestsState.calculateProgress() }
}

/**
 * Throws an exception if the user is not logged in at the moment
 */
@UsesHooks
fun useRequireLoggedIn(): LoggedInState {
  return useSelector { loggedInState } ?: throw NotLoggedInException()
}

/**
 * Throws an exception if the user is not logged in at the moment
 */
@UsesHooks
fun useRequireLoggedInUser(): UserInformation {
  val loggedInState = useRequireLoggedIn()
  val userResolver = useSelectUserResolver()
  return userResolver[loggedInState.loggedInUser]
}

@UsesHooks
fun useRequireCompanyForLoggedInUser(): PlannerCompanyInformation {
  val useRequireLoggedInUser = useRequireLoggedInUser()
  val companyResolver = useSelectCompanyResolver()
  return companyResolver[useRequireLoggedInUser.company]
}

/**
 * Returns the essentials - that are (nearly) always required.
 * Or schedules loading.
 *
 * Does only load the essentials once.
 *
 * Attention: This hook must only be used at the very start of the component hierarchy.
 * "Normal" components don't have to call this hook at all
 */
@UsesHooks
fun useLoadEssentialsOnce(): Essentials? {
  useEffectOnce {
    logger.debug("Loading essentials once")
    Loading.IfNecessary.essentials()
  }

  return useSelector {
    essentials
  }
}

/**
 * Returns the loaded resolved project
 */
@UsesHooks
fun useLoadResolvedProject(projectId: PhotovoltaicsProjectId): ResolvedProject? {
  useDebugValue("projectId") {
    "Project ID: $projectId"
  }

  val availableProducts = useSelectAvailableProducts()
  val productResolver = useSelectProductResolver()
  val priceList = useSelectPriceList()
  val companyResolver = useSelectCompanyResolver()
  val userResolver = useSelectUserResolver()

  useEffect(projectId) {
    Loading.IfNecessary.resolvedProject(projectId, availableProducts, productResolver, priceList, companyResolver, userResolver)
  }

  return useSelector {
    this.resolvedProjects.getOrNull(projectId)
  }
}

/**
 * Returns the process states history for the given Uuid.
 *
 * Null is returned if the process states are not yet loaded or if the uuid is null.
 */
@UsesHooks
fun useLoadProcessStatesForComponent(uuid: Uuid?): @Sorted(Order.ASC, by = "assignedAt") List<LizergyProcessStateEntry>? {
  useDebugValue("uuid") {
    "Uuid: $uuid"
  }

  useEffect {
    Loading.IfNecessary.allProcessStates()
  }

  return useSelector {
    if (uuid == null) {
      null
    } else {
      val processStateEntries = this.allProcessStatesForComponents?.get(uuid)?.validProcessStateEntries
      processStateEntries.unsafeCast<List<LizergyProcessStateEntry>?>()
    }
  }
}

@UsesHooks
fun useLoadProcessStatesForComponents(uuids: List<Uuid>): @Sorted(Order.ASC, by = "assignedAt") List<List<LizergyProcessStateEntry>?> {
  useDebugValue("uuids") {
    "Uuids: $uuids"
  }

  useEffect {
    Loading.IfNecessary.allProcessStates()
  }

  return useSelector {
    uuids.fastMap { uuid ->
      val ass = this.allProcessStatesForComponents?.get(uuid)?.validProcessStateEntries
      ass.unsafeCast<List<LizergyProcessStateEntry>?>()
    }
  }
}

@UsesHooks
fun useLoadProcessStates(): AllProcessStatesForComponents? {
  useEffect {
    Loading.IfNecessary.allProcessStates()
  }

  return useSelector {
    this.allProcessStatesForComponents
  }
}

/**
 * Returns the loaded resolved project
 */
@UsesHooks
fun useLoadCommentsForComponent(uuid: Uuid): List<Comment>? {
  useDebugValue("uuid") {
    "Uuid: $uuid"
  }

  useEffect(uuid) {
    Loading.IfNecessary.commentsForComponent(uuid)
  }

  return useSelector {
    this.allCommentsForComponents.getOrNull(uuid)?.activeComments
  }
}

private val logger: Logger = LoggerFactory.getLogger("store.hooks.StoreHooks")
