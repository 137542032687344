package components.project.blueprint

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

fun RBuilder.imagesForm(
  imagesDach: StateInstance<Boolean>,
  imagesGeruestflaechen: StateInstance<Boolean>,
  imagesKeller: StateInstance<Boolean>,
  imagesZaehlerschrank: StateInstance<Boolean>,
  imagesErdung: StateInstance<Boolean>,
  neubau: StateInstance<Boolean>,
): Unit = child(ImagesForm) {
  attrs {
    this.imagesDach = imagesDach
    this.imagesGeruestflaechen = imagesGeruestflaechen
    this.imagesKeller = imagesKeller
    this.imagesZaehlerschrank = imagesZaehlerschrank
    this.imagesErdung = imagesErdung
    this.neubau = neubau
  }
}

val ImagesForm: FC<ImagesFormProps> = fc("ImagesForm") { props ->
  val imagesDach = props::imagesDach.getNotNull()
  val imagesGeruestflaechen = props::imagesGeruestflaechen.getNotNull()
  val imagesKeller = props::imagesKeller.getNotNull()
  val imagesZaehlerschrank = props::imagesZaehlerschrank.getNotNull()
  val imagesErdung = props::imagesErdung.getNotNull()
  val neubau = props::neubau.getNotNull()


  div("collapse") {
    attrs {
      addClassIf("show") { neubau.value.not() }
    }

    div {
    checkbox(
      valueAndSetter = imagesDach,
      fieldName = "imagesDach",
      title = "Bilder Dach*",
      labelConfig = {
        attrs {
          addClass("fw-bold")
        }
      },
    )
    }
    div {
    checkbox(
      valueAndSetter = imagesGeruestflaechen,
      fieldName = "imagesGeruestflaechen",
      title = "Bilder Gerüstflächen*",
      labelConfig = {
        attrs {
          addClass("fw-bold")
        }
      },
    )
    }
    div {
    checkbox(
      valueAndSetter = imagesKeller,
      fieldName = "imagesKeller",
      title = "Bilder Keller (WR-, Batteriestandort, Kabelwege)*",
      labelConfig = {
        attrs {
          addClass("fw-bold")
        }
      },
    )
    }
    div {
    checkbox(
      valueAndSetter = imagesZaehlerschrank,
      fieldName = "imagesZaehlerschrank",
      title = "Bilder Zählerschrank, Zähler, Schutzisolierung*",
      labelConfig = {
        attrs {
          addClass("fw-bold")
        }
      },
    )
    }
    div {
    checkbox(
      valueAndSetter = imagesErdung,
      fieldName = "imagesErdung",
      title = "Bilder Erdung*",
      labelConfig = {
        attrs {
          addClass("fw-bold")
        }
      },
    )
    }

  }

  div("my-3") {
    checkbox(
      valueAndSetter = neubau,
      fieldName = "neubau",
      title = "Neubau",
    )
  }

}

external interface ImagesFormProps : Props {
  var imagesDach: StateInstance<Boolean>
  var imagesGeruestflaechen: StateInstance<Boolean>
  var imagesKeller: StateInstance<Boolean>
  var imagesZaehlerschrank: StateInstance<Boolean>
  var imagesErdung: StateInstance<Boolean>
  var neubau: StateInstance<Boolean>
}
