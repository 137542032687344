@file:UseSerializers(UuidSerializer::class)

package serialized

import it.neckar.customer.company.CompanyCode
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.configuration.BlueprintFacilityConfiguration
import it.neckar.lizergy.model.configuration.ResolvedPhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.lizergy.model.configuration.components.WallboxConfiguration
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationAnnotation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.lizergy.model.project.Blueprint
import it.neckar.lizergy.model.project.Blueprint.BlueprintId
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.km
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents a [SerializedBlueprint] for a project
 *
 * A [SerializedBlueprint] is supposed to hold *ONLY* technical information about the project
 *
 * This object is saved. It can be loaded at later times and resolved to a [ResolvedPhotovoltaicsConfiguration].
 * Depending on the (then) resolved prices the results might be different.
 */
@Serializable
data class SerializedBlueprint(
  override val projectId: PhotovoltaicsProjectId,

  override val blueprintId: BlueprintId = BlueprintId.random(),

  @Deprecated("Replaced by new Process State Service")
  override val editor: UserLoginName? = null,

  override val sellingCompany: CompanyCode,

  override val location: LocationInformation,

  override val shippingDistanceManual: @km Int? = null,
  override val shippingDistanceCalculated: @km Int? = null,

  override val moduleLayouts: ModuleLayoutsConfiguration = ModuleLayoutsConfiguration.getEmpty(),
  @Deprecated("No longer used or needed")
  override val blackModules: Boolean = false,
  override val dachmasseAbgelegt: Boolean = false,
  @Deprecated("Replaced by Comment Service")
  override val roofAnnotations: List<ConfigurationAnnotation<RoofId>> = emptyList(),
  @Deprecated("Replaced by Comment Service")
  override val roofsAnnotation: String? = null,

  override val powerUsageScenario: PowerUsageScenario = PowerUsageScenario.typical(),
  override val manualPowerConsumptionDistribution: ManualPowerConsumptionDistribution? = null,
  override val pricesTrendScenario: PricesTrendScenario = PricesTrendScenario.typical(),
  @Deprecated("Replaced by Comment Service")
  override val profitabilityAnnotation: String? = null,

  override val facilityConfiguration: BlueprintFacilityConfiguration = BlueprintFacilityConfiguration.getEmpty(),
  override val battery: ConfiguredOptionality = ConfiguredOptionality.NotSelected,
  override val assemblyConfiguration: SerializedAssemblyConfiguration,
  @Deprecated("Replaced by Comment Service")
  override val facilityAnnotation: String? = null,

  override val wallboxConfiguration: WallboxConfiguration = WallboxConfiguration.getEmpty(),
  @Deprecated("Replaced by Comment Service")
  override val wallboxAnnotation: String? = null,

  override val zaehlerschrankSchutzisoliert: Boolean = false,
  override val electricityWorkConfiguration: SerializedElectricityWorkConfiguration = SerializedElectricityWorkConfiguration.getEmpty(),
  @Deprecated("Replaced by Comment Service")
  override val electricityWorkAnnotation: String? = null,

  override val additionalPositions: ConfigurationItemsConfiguration = ConfigurationItemsConfiguration.getEmpty(),
  override val existingFacilitiesConfiguration: ExistingFacilitiesConfiguration = ExistingFacilitiesConfiguration.getEmpty(),
  override val legalNoticeAdditionalLine: String? = null,
  override val legalNotes: List<LegalNote> = emptyList(),
  override val discountPercentage: @pct Double = 0.0,
  @Deprecated("Replaced by Comment Service")
  override val additionalAnnotation: String? = null,

  @Deprecated("Replaced by Comment Service")
  override val annotation: String? = null,
  override val zaehlerNummer: String = "",
  override val flurstueckNummer: String = "",
  override val facilityOperator1: FacilityOperatorInformation = FacilityOperatorInformation(),
  override val facilityOperator2: FacilityOperatorInformation = FacilityOperatorInformation(),
  override val einspeiseart: Einspeiseart = Einspeiseart.Ueberschuss,
  override val lagePlanAngefragt: Boolean = false,

  override val imagesDach: Boolean = false,
  override val imagesGeruestflaechen: Boolean = false,
  override val imagesKeller: Boolean = false,
  override val imagesZaehlerschrank: Boolean = false,
  override val imagesErdung: Boolean = false,
  override val neubau: Boolean = false,

  override val acquisitionDate: @ms Double,

  val worstProblem: ProblemType? = null,
  val formattedProblems: String = "",

  @Deprecated("Being replaced by new Process State Service")
  override val processState: PositionEditHistory<OLDProcessState>? = null,

  ) : Blueprint {

  fun resolve(productResolver: ProductResolver, userResolver: UserResolver, companyResolver: CompanyResolver): ResolvedBlueprint {
    return ResolvedBlueprint(
      projectId = projectId,
      blueprintId = blueprintId,
      editorInformation = editor?.let { userResolver[it] },
      sellingCompanyInformation = companyResolver[sellingCompany],
      location = location,
      shippingDistanceManual = shippingDistanceManual,
      shippingDistanceCalculated = shippingDistanceCalculated,
      moduleLayouts = moduleLayouts.resolve(productResolver),
      blackModules = blackModules,
      dachmasseAbgelegt = dachmasseAbgelegt,
      roofAnnotations = roofAnnotations,
      roofsAnnotation = roofsAnnotation,
      powerUsageScenario = powerUsageScenario,
      manualPowerConsumptionDistribution = manualPowerConsumptionDistribution,
      pricesTrendScenario = pricesTrendScenario,
      profitabilityAnnotation = profitabilityAnnotation,
      facilityConfiguration = facilityConfiguration,
      battery = battery,
      assemblyConfiguration = assemblyConfiguration.resolve(productResolver),
      facilityAnnotation = facilityAnnotation,
      wallboxConfiguration = wallboxConfiguration,
      wallboxAnnotation = wallboxAnnotation,
      zaehlerschrankSchutzisoliert = zaehlerschrankSchutzisoliert,
      electricityWorkConfiguration = electricityWorkConfiguration.resolve(productResolver),
      electricityWorkAnnotation = electricityWorkAnnotation,
      additionalPositions = additionalPositions,
      existingFacilitiesConfiguration = existingFacilitiesConfiguration,
      legalNoticeAdditionalLine = legalNoticeAdditionalLine,
      legalNotes = legalNotes,
      discountPercentage = discountPercentage,
      additionalAnnotation = additionalAnnotation,
      annotation = annotation,
      zaehlerNummer = zaehlerNummer,
      flurstueckNummer = flurstueckNummer,
      facilityOperator1 = facilityOperator1,
      facilityOperator2 = facilityOperator2,
      einspeiseart = einspeiseart,
      lagePlanAngefragt = lagePlanAngefragt,
      imagesDach = imagesDach,
      imagesGeruestflaechen = imagesGeruestflaechen,
      imagesKeller = imagesKeller,
      imagesZaehlerschrank = imagesZaehlerschrank,
      imagesErdung = imagesErdung,
      neubau = neubau,
      acquisitionDate = acquisitionDate,
      processState = processState,
    )
  }

}


fun ResolvedBlueprint.unResolve(): SerializedBlueprint {
  return SerializedBlueprint(
    projectId = projectId,
    blueprintId = blueprintId,
    editor = editor,
    sellingCompany = sellingCompany,
    location = location,
    shippingDistanceManual = shippingDistanceManual,
    shippingDistanceCalculated = shippingDistanceCalculated,
    moduleLayouts = moduleLayouts.unResolve(),
    blackModules = blackModules,
    dachmasseAbgelegt = dachmasseAbgelegt,
    roofAnnotations = roofAnnotations,
    roofsAnnotation = roofsAnnotation,
    powerUsageScenario = powerUsageScenario,
    manualPowerConsumptionDistribution = manualPowerConsumptionDistribution,
    pricesTrendScenario = pricesTrendScenario,
    profitabilityAnnotation = profitabilityAnnotation,
    facilityConfiguration = facilityConfiguration,
    battery = battery,
    assemblyConfiguration = assemblyConfiguration.unResolve(),
    facilityAnnotation = facilityAnnotation,
    wallboxConfiguration = wallboxConfiguration,
    wallboxAnnotation = wallboxAnnotation,
    zaehlerschrankSchutzisoliert = zaehlerschrankSchutzisoliert,
    electricityWorkConfiguration = electricityWorkConfiguration.unResolve(),
    electricityWorkAnnotation = electricityWorkAnnotation,
    additionalPositions = additionalPositions,
    existingFacilitiesConfiguration = existingFacilitiesConfiguration,
    legalNoticeAdditionalLine = legalNoticeAdditionalLine,
    legalNotes = legalNotes,
    discountPercentage = discountPercentage,
    additionalAnnotation = additionalAnnotation,
    annotation = annotation,
    zaehlerNummer = zaehlerNummer,
    flurstueckNummer = flurstueckNummer,
    facilityOperator1 = facilityOperator1,
    facilityOperator2 = facilityOperator2,
    einspeiseart = einspeiseart,
    lagePlanAngefragt = lagePlanAngefragt,
    imagesDach = imagesDach,
    imagesGeruestflaechen = imagesGeruestflaechen,
    imagesKeller = imagesKeller,
    imagesZaehlerschrank = imagesZaehlerschrank,
    imagesErdung = imagesErdung,
    neubau = neubau,
    acquisitionDate = acquisitionDate,
    worstProblem = validationProblems.worstValidationProblem(),
    formattedProblems = validationProblems.format(),
    processState = processState,
  )
}
