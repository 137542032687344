package components.project.configuration

import components.form.commentSection
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Shows the preview for *one* quote
 */
val ConfigurationPreviewWithComments: FC<ConfigurationPreviewWithCommentsProps> = fc("ConfigurationPreviewWithComments") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()


  div("row my-3") {

    div("col-6") {
      ConfigurationPreview {
        attrs {
          this.project = project
          this.quoteConfiguration = quoteConfiguration
          this.editableStatus = EditableStatus.ReadOnly
        }
      }
    }

    div("col-6") {
      commentSection(quoteConfiguration)
    }

  }

}

external interface ConfigurationPreviewWithCommentsProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
}
