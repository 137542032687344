@file:Suppress("DuplicatedCode")

package components.project.configuration.location

import com.meistercharts.maps.MapCoordinates
import components.project.address.addressForm
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.Address
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*

/**
 *
 */
fun RBuilder.locationForm(
  addressStreet: StateInstance<String>,
  addressHouseNumber: StateInstance<String>,
  addressZipCode: StateInstance<String>,
  addressCity: StateInstance<String>,

  customerAddress: Address,

  mapCoordinates: MapCoordinates?,

  editableStatus: EditableStatus,
): Unit = child(LocationForm) {
  attrs {
    this.addressStreet = addressStreet
    this.addressHouseNumber = addressHouseNumber
    this.addressZipCode = addressZipCode
    this.addressCity = addressCity
    this.customerAddress = customerAddress
    this.mapCoordinates = mapCoordinates
    this.editableStatus = editableStatus
  }
}

val LocationForm: FC<LocationFormProps> = fc("LocationForm") { props ->
  val addressStreet = props::addressStreet.getNotNull()
  val addressHouseNumber = props::addressHouseNumber.getNotNull()
  val addressZipCode = props::addressZipCode.getNotNull()
  val addressCity = props::addressCity.getNotNull()

  val editableStatus = props::editableStatus.safeGet()
  val customerAddress = props::customerAddress.safeGet()

  val mapCoordinates = props::mapCoordinates.safeGet()


  addressForm(
    street = addressStreet,
    houseNumber = addressHouseNumber,
    zipCode = addressZipCode,
    city = addressCity,
    editableStatus = editableStatus,
  )

  if (!customerAddress.isEmpty()) {
    h4 {
      +"Kundenanschrift"
    }
    p {
      +customerAddress.singleLine()

      button(classes = "btn btn-link align-baseline") {
        attrs {
          disabled = editableStatus == EditableStatus.ReadOnly ||
            (addressStreet.value == customerAddress.street &&
                  addressHouseNumber.value == customerAddress.houseNumber &&
                  addressZipCode.value == customerAddress.zipCode &&
                  addressCity.value == customerAddress.city)

          onClickFunction = useCallback(
            customerAddress,
            addressStreet.setter,
            addressHouseNumber.setter,
            addressZipCode.setter,
            addressCity.setter,
          ) {
            addressStreet.setter(customerAddress.street)
            addressHouseNumber.setter(customerAddress.houseNumber)
            addressZipCode.setter(customerAddress.zipCode)
            addressCity.setter(customerAddress.city)
          }
        }

        +"Als Standort übernehmen"
      }
    }
  }
  div("row") {
    div("col my-3") {
      locationMap(mapCoordinates)
    }
  }

}

external interface LocationFormProps : Props {
  var addressStreet: StateInstance<String>
  var addressHouseNumber: StateInstance<String>
  var addressZipCode: StateInstance<String>
  var addressCity: StateInstance<String>

  var customerAddress: Address

  var mapCoordinates: MapCoordinates?

  var editableStatus: EditableStatus
}
