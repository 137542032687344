package components.project.configuration.layout.moduleLayout

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Form for a moduleLayout
 */
fun RBuilder.moduleLayoutForm(
  label: StateInstance<String>,
  placeholder: String?,
  moduleType: StateInstance<PvModule>,
  blackModules: StateInstance<Boolean>,
  availableModuleTypes: List<PvModule>,
  editableStatus: EditableStatus,
): Unit = child(ModuleLayoutForm) {
  attrs {
    this.label = label
    this.placeholder = placeholder
    this.moduleType = moduleType
    this.blackModules = blackModules
    this.availableModuleTypes = availableModuleTypes
    this.editableStatus = editableStatus
  }
}

val ModuleLayoutForm: FC<ModuleLayoutFormProps> = fc("ModuleLayoutForm") { props ->
  val label = props::label.getNotNull()
  val placeholder = props::placeholder.safeGet()

  val moduleType = props::moduleType.getNotNull()
  val blackModules = props::blackModules.getNotNull()
  val availableModuleTypes = props::availableModuleTypes.getNotNull()

  val editableStatus = props::editableStatus.safeGet()


  div("module-layout-form") {
    div("row gx-0 mb-3") {

      div("col-sm-6") {
        floatingInputField(
          valueAndSetter = label,
          fieldName = "description",
          title = placeholder ?: "Bezeichnung",
          editableStatus = editableStatus,
        ) {
          attrs {
            mergedRight()
          }
        }
      }

      div("col-sm-6") {

        floatingSelect(
          valueAndSetter = moduleType,
          formatter = { pvModuleType -> pvModuleType.description },
          availableOptions = availableModuleTypes,
          fieldName = "moduleType",
          title = "Modul",
          editableStatus = editableStatus,
        ) {
          attrs {
            mergedLeft()
          }
          invalidIf {
            moduleType.value.lifeCycleState.isEndOfLife()
          }
        }

        div("row justify-content-between my-2") {

          div("col-8") {
            checkbox(
              valueAndSetter = blackModules,
              fieldName = "blackModules",
              title = "Schwarze Module (Glas-Glas-Module)",
              editableStatus = editableStatus,
            )
          }

          div("col-4 text-end form-text") {
            +moduleType.value.size.toString()
          }

        }

      }
    }
  }

}

external interface ModuleLayoutFormProps : Props {
  var label: StateInstance<String>
  var placeholder: String?
  var moduleType: StateInstance<PvModule>
  var blackModules: StateInstance<Boolean>
  var availableModuleTypes: List<PvModule>
  var editableStatus: EditableStatus
}
