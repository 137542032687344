package it.neckar.lizergy.model

import it.neckar.common.featureflags.FeatureFlag

/**
 * Contains the project specific FeatureFlags for the Planner
 */
object PlannerFeatureFlags {

  /**
   * Simulate a delay in UI actions.
   * This feature can be used to test busy indicators.
   *
   * Every async action will be delayed for 1 sec
   */
  val slowUi: FeatureFlag = FeatureFlag.slowUi

  /**
   * Simulates a delay on HTTP requests responses on the server side
   */
  val slowServer: FeatureFlag = FeatureFlag.slowServer

  /**
   * Can be used to simulate failure of refreshing the access token
   */
  val forceFailRefreshAccessToken: FeatureFlag = FeatureFlag.forceFailRefreshAccessToken


  val tokenDurationVeryShort: FeatureFlag = FeatureFlag.tokenDurationVeryShort


  val forceFailEssentialsLoading: FeatureFlag = FeatureFlag.forceFailEssentialsLoading

  /**
   * Disables the version check on the server and client
   */
  val disableVersionCheck: FeatureFlag = FeatureFlag.disableVersionCheck

  /**
   * Forces the version check to fail
   */
  val forceFailVersionCheck: FeatureFlag = FeatureFlag.forceFailVersionCheck

  val throwException: FeatureFlag = FeatureFlag.throwException

  /**
   * forces the Server to simulate an Internal Error when the Client performs requests
   */
  val forceServerError: FeatureFlag = FeatureFlag.forceServerError

  /**
   * The available project specific FeatureFlags.
   */
  val availableSpecificFlags: List<FeatureFlag> = listOf(
    slowUi,
    slowServer,
    forceFailRefreshAccessToken,
    tokenDurationVeryShort,
    forceFailEssentialsLoading,
    disableVersionCheck,
    forceFailVersionCheck,
    throwException,
    forceServerError
  )
}

