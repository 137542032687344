@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.energy.AmountOfEnergy
import it.neckar.lizergy.model.configuration.energy.BatteryCapacity
import it.neckar.lizergy.model.configuration.quote.builder.BatteryInverter
import it.neckar.lizergy.model.configuration.quote.builder.BatteryStorageExtensionType
import it.neckar.lizergy.model.configuration.quote.builder.BatteryStorageType
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * A battery configuration with an option extension
 */
@Serializable
data class BatteryConfiguration(

  val id: BatteryConfigurationId,
  /**
   * The battery (optional)
   */
  val battery: BatteryStorageType,
  /**
   * The (optional) extension. Only makes sense if a battery has been selected, too
   */
  val extension: BatteryStorageExtensionType? = null,

  val inverterType: BatteryInverter,
  ) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  /**
   * The life cycle of this configuration
   */
  override val lifeCycleState: LifeCycleState = battery.lifeCycleState.and(extension?.lifeCycleState)

  val totalNumberOfBatteryModules: Int
    get() = battery.numberOfBatteryModules + (extension?.numberOfBatteryModules ?: 0)

  /**
   * Returns the total storage
   */
  val totalCapacity: BatteryCapacity
    get() = battery.storageCapacity.plus(extension?.storageCapacity ?: BatteryCapacity.Zero)

  val totalStorage: AmountOfEnergy
    get() = totalCapacity.capacity

  /**
   * Formats the configuration.
   */
  fun format(): String {
    return "${formatOnlyBattery()} mit ${inverterType.description}"
  }

  fun formatOnlyBattery(): String {
    return if (extension == null) battery.description else "${battery.description} + ${extension.description}"
  }


  /**
   * An id for a battery configuration
   */
  @Serializable
  data class BatteryConfigurationId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): BatteryConfigurationId {
        return BatteryConfigurationId(randomUuid4())
      }
    }
  }
}
