package it.neckar.rest.jwt

import it.neckar.commons.js.CookieName

/**
 * Contains JWT related constants for cookie support
 */
object JwtCookies {
  /**
   * name for the access token cookie
   */
  val accessToken: CookieName = CookieName("accessToken")
}
