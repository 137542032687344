package serialized

import it.neckar.financial.currency.ValueAddedTax
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.components.ElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.components.ElectricityWorkConfiguration.ElectricityWorkConfigurationId
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType.IndependenceManagerId
import it.neckar.lizergy.model.configuration.components.ResolvedElectricityWorkConfiguration
import it.neckar.lizergy.model.price.ProductResolver
import kotlinx.serialization.Serializable

@Serializable
data class SerializedElectricityWorkConfiguration(
  override val id: ElectricityWorkConfigurationId = ElectricityWorkConfigurationId.random(),
  override val electricityWorkEffort: ConfigurationItem = ConfigurationItem.getEmpty(ValueAddedTax.zero),
  override val sunnyHomeManager: Boolean = false,
  val independenceManagerId: IndependenceManagerId? = null,
  override val neuerZaehlerschrank: Boolean = false,
  override val zusammenZuLegendeZaehlerNummern: List<String> = emptyList(),
  override val einbauUnterverteiler: Boolean = false,
  override val manualEinbauDigitalerZaehler: Int? = null,
  override val smaEnergyMeter: Boolean = false,
  override val slsNachruesten: Boolean = false,
) : ElectricityWorkConfiguration {

  fun resolve(productResolver: ProductResolver): ResolvedElectricityWorkConfiguration {
    return ResolvedElectricityWorkConfiguration(
      id = id,
      electricityWorkEffort = electricityWorkEffort,
      sunnyHomeManager = sunnyHomeManager,
      independenceManager = independenceManagerId?.let { productResolver[it] },
      neuerZaehlerschrank = neuerZaehlerschrank,
      zusammenZuLegendeZaehlerNummern = zusammenZuLegendeZaehlerNummern,
      einbauUnterverteiler = einbauUnterverteiler,
      manualEinbauDigitalerZaehler = manualEinbauDigitalerZaehler,
      smaEnergyMeter = smaEnergyMeter,
      slsNachruesten = slsNachruesten,
    )
  }

  fun duplicate(): SerializedElectricityWorkConfiguration {
    return copy(id = ElectricityWorkConfigurationId.random())
  }

  companion object {
    fun getEmpty(id: ElectricityWorkConfigurationId = ElectricityWorkConfigurationId.random()): SerializedElectricityWorkConfiguration {
      return SerializedElectricityWorkConfiguration(id = id)
    }
  }

}


fun ResolvedElectricityWorkConfiguration.unResolve(): SerializedElectricityWorkConfiguration {
  return SerializedElectricityWorkConfiguration(
    id = id,
    electricityWorkEffort = electricityWorkEffort,
    sunnyHomeManager = sunnyHomeManager,
    independenceManagerId = independenceManager?.id,
    neuerZaehlerschrank = neuerZaehlerschrank,
    zusammenZuLegendeZaehlerNummern = zusammenZuLegendeZaehlerNummern,
    einbauUnterverteiler = einbauUnterverteiler,
    manualEinbauDigitalerZaehler = manualEinbauDigitalerZaehler,
    smaEnergyMeter = smaEnergyMeter,
    slsNachruesten = slsNachruesten,
  )
}
