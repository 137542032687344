package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import store.PlannerClientState

/**
 * Actions related to projects
 */


data class UpdateCompanyAction(val updatedCompany: PlannerCompanyInformation) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(
      essentials = essentialsNonNull.copy(
        usersAndCompanies = usersAndCompanies.withUpdatedCompany(updatedCompany),
      ),
    )
  }
}
