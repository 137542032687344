@file:UseSerializers(UuidSerializer::class)

package serialized

import it.neckar.customer.company.CompanyCode
import it.neckar.editHistory.PositionEditHistory
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.PlannerConfiguration
import it.neckar.lizergy.model.configuration.ResolvedPhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty.AssemblyDifficultyId
import it.neckar.lizergy.model.configuration.quote.economics.EquityFinanced
import it.neckar.lizergy.model.configuration.quote.economics.FinancingType
import it.neckar.lizergy.model.configuration.quote.economics.YearlyCostInformation
import it.neckar.lizergy.model.configuration.quote.evaluate
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.km
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * This object is saved. It can be loaded at later times and resolved to a [ResolvedPhotovoltaicsConfiguration].
 * Depending on the (then) resolved prices the results might be different.
 */
@Serializable
data class SerializedPhotovoltaicsConfiguration(

  override val configurationId: PhotovoltaicsConfigurationId,

  /**
   * The short label
   */
  override val label: String? = null,

  /**
   * The (longer) description
   */
  override val description: String? = null,

  /**
   * The time, when the configuration was created
   */
  override val creationTime: @ms Double = nowMillis(),

  override val sellingCompany: CompanyCode,

  @Deprecated("Replaced by new Process State Service")
  override val editor: UserLoginName?,

  override val location: LocationInformation,

  override val powerUsageScenario: PowerUsageScenario = PowerUsageScenario.typical(),

  override val pricesTrendScenario: PricesTrendScenario = PricesTrendScenario.typical(),

  override val facilityConfiguration: SerializedFacilityConfiguration,

  override val assemblyConfiguration: SerializedAssemblyConfiguration,

  override val wallboxSelection: WallboxIdSelection,

  /**
   * The yearly costs
   */
  override val yearlyCosts: YearlyCostInformation = YearlyCostInformation(),
  override val financing: FinancingType,

  override val shippingDistanceManual: @km Int? = null,
  override val shippingDistanceCalculated: @km Int? = null,

  override val electricityWorkConfiguration: SerializedElectricityWorkConfiguration,

  /**
   * Additional positions
   */
  override val additionalPositions: ConfigurationItemsConfiguration = ConfigurationItemsConfiguration.getEmpty(),
  override val existingFacilitiesConfiguration: ExistingFacilitiesConfiguration = ExistingFacilitiesConfiguration.getEmpty(),
  override val discountPercentage: @pct Double = 0.0,

  override val zaehlerNummer: String = "",
  override val flurstueckNummer: String = "",

  override val facilityOperator1: FacilityOperatorInformation = FacilityOperatorInformation(),
  override val facilityOperator2: FacilityOperatorInformation = FacilityOperatorInformation(),

  override val einspeiseart: Einspeiseart = Einspeiseart.Ueberschuss,

  /**
   * Optional, additional line to be put at the end of the quote
   */
  override val legalNoticeAdditionalLine: String? = null,
  override val legalNotes: List<LegalNote> = emptyList(),

  /**
   * Manually set value for the degree of self-sufficiency.
   * If not set, the calculated value will be returned by powerConsumptionDistribution from a [ResolvedPhotovoltaicsConfiguration]
   *
   * In most cases powerConsumptionDistribution from a [ResolvedPhotovoltaicsConfiguration] should be used.
   */
  override val manualPowerConsumptionDistribution: ManualPowerConsumptionDistribution? = null,

  override val earningsDistribution: SerializedEarningsDistribution,

  override val manualQuoteElements: ManualQuoteElements = ManualQuoteElements.empty,

  @Deprecated("No longer required")
  override val signedQuoteReceived: Boolean = false,

  val worstProblem: ProblemType? = null,
  val formattedProblems: String = "",

  /**
   * Marks the state of this [SerializedPhotovoltaicsConfiguration] for Lizergy
   *   Wrapped in a [PositionEditHistory], so all changes to this member will be recorded
   */
  @Deprecated("Being replaced by new Process State Service")
  override val processState: PositionEditHistory<OLDProcessState>? = null,

  /**
   * Instead of deleting, mark this value as Deleted
   * When listing [SerializedPhotovoltaicsConfiguration]s, consider this value
   */
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : PhotovoltaicsConfiguration {

  /**
   * Resolves this [SerializedPhotovoltaicsConfiguration] to a [ResolvedPhotovoltaicsConfiguration]
   *  with all the calculates values depending on [ModuleLayoutsConfiguration], [ProductResolver], and [PriceList]
   */
  fun resolve(moduleLayouts: ModuleLayoutsConfiguration, productResolver: ProductResolver, userResolver: UserResolver, companyResolver: CompanyResolver): ResolvedPhotovoltaicsConfiguration {
    val sellingCompanyInformation = companyResolver[sellingCompany]
    val resolvedModuleLayouts = moduleLayouts.resolve(productResolver)
    val resolvedWallboxSelection = wallboxSelection.resolve(productResolver)
    val resolvedAssemblyConfiguration = assemblyConfiguration.resolve(productResolver)
    val resolvedFacilityConfiguration = facilityConfiguration.resolve(productResolver)
    val resolvedElectricityWorkConfiguration = electricityWorkConfiguration.resolve(productResolver)

    return ResolvedPhotovoltaicsConfiguration(
      configurationId = configurationId,
      label = label,
      description = description,
      creationTime = creationTime,
      sellingCompanyInformation = sellingCompanyInformation,
      monitoringCompanyInformation = companyResolver[sellingCompanyInformation.companyProfile.relevantParentCompanyCode()],
      editorInformation = editor?.let { userResolver[it] },
      location = location,
      moduleLayouts = resolvedModuleLayouts,
      wallboxSelection = resolvedWallboxSelection,
      powerUsageScenario = powerUsageScenario,
      pricesTrendScenario = pricesTrendScenario,
      facilityConfiguration = resolvedFacilityConfiguration,
      assemblyConfiguration = resolvedAssemblyConfiguration,
      yearlyCosts = yearlyCosts,
      financing = financing,
      shippingDistanceManual = shippingDistanceManual,
      shippingDistanceCalculated = shippingDistanceCalculated,
      electricityWorkConfiguration = resolvedElectricityWorkConfiguration,
      additionalPositions = additionalPositions,
      existingFacilitiesConfiguration = existingFacilitiesConfiguration,
      discountPercentage = discountPercentage,
      zaehlerNummer = zaehlerNummer,
      flurstueckNummer = flurstueckNummer,
      facilityOperator1 = facilityOperator1,
      facilityOperator2 = facilityOperator2,
      einspeiseart = einspeiseart,
      legalNoticeAdditionalLine = legalNoticeAdditionalLine,
      legalNotes = legalNotes,
      manualPowerConsumptionDistribution = manualPowerConsumptionDistribution,
      earningsDistribution = earningsDistribution.resolve(companyResolver),
      manualQuoteElements = manualQuoteElements,
      signedQuoteReceived = signedQuoteReceived,
      processState = processState,
      lifeCycleState = lifeCycleState,
    )
  }

  fun evaluate(
    moduleLayouts: ModuleLayoutsConfiguration,
    currentQuoteSnapshot: QuoteSnapshot?,
    availableProducts: AvailableProducts,
    productResolver: ProductResolver,
    priceList: PriceList,
    userResolver: UserResolver,
    companyResolver: CompanyResolver,
  ): QuoteConfiguration {
    return resolve(
      moduleLayouts = moduleLayouts,
      productResolver = productResolver,
      userResolver = userResolver,
      companyResolver = companyResolver,
    ).evaluate(
      currentQuoteSnapshot = currentQuoteSnapshot,
      availableProducts = availableProducts,
      priceList = priceList,
    )
  }


  companion object {
    fun getEmpty(
      id: PhotovoltaicsConfigurationId = PhotovoltaicsConfigurationId.random(),
      sellingCompany: CompanyCode,
      editor: UserLoginName,
      assemblyDifficultyId: AssemblyDifficultyId,
      loggedInUser: UserLoginName,
      now: @ms Double = nowMillis(),
    ): SerializedPhotovoltaicsConfiguration {
      return SerializedPhotovoltaicsConfiguration(
        configurationId = id,
        creationTime = now,
        sellingCompany = sellingCompany,
        editor = editor,
        location = LocationInformation.empty,
        facilityConfiguration = SerializedFacilityConfiguration.getEmpty(),
        wallboxSelection = WallboxIdSelection.getEmpty(),
        assemblyConfiguration = SerializedAssemblyConfiguration.getEmpty(assemblyDifficultyId),
        electricityWorkConfiguration = SerializedElectricityWorkConfiguration.getEmpty(),
        powerUsageScenario = PowerUsageScenario.typical(),
        pricesTrendScenario = PricesTrendScenario.typical(),
        financing = EquityFinanced,
        earningsDistribution = SerializedEarningsDistribution.getEmpty(defaultCompany = sellingCompany, loggedInUser = loggedInUser),
      )
    }
  }

}

fun PlannerConfiguration.unResolve(): SerializedPhotovoltaicsConfiguration {
  return SerializedPhotovoltaicsConfiguration(
    configurationId = configurationId,
    label = label,
    description = description,
    creationTime = creationTime,
    sellingCompany = sellingCompany,
    editor = editor,
    location = location,
    wallboxSelection = wallboxSelection.unResolve(),
    powerUsageScenario = powerUsageScenario,
    pricesTrendScenario = pricesTrendScenario,
    facilityConfiguration = facilityConfiguration.unResolve(),
    assemblyConfiguration = assemblyConfiguration.unResolve(),
    yearlyCosts = yearlyCosts,
    financing = financing,
    shippingDistanceManual = shippingDistanceManual,
    shippingDistanceCalculated = shippingDistanceCalculated,
    electricityWorkConfiguration = electricityWorkConfiguration.unResolve(),
    additionalPositions = additionalPositions,
    existingFacilitiesConfiguration = existingFacilitiesConfiguration,
    discountPercentage = discountPercentage,
    zaehlerNummer = zaehlerNummer,
    flurstueckNummer = flurstueckNummer,
    facilityOperator1 = facilityOperator1,
    facilityOperator2 = facilityOperator2,
    einspeiseart = einspeiseart,
    legalNoticeAdditionalLine = legalNoticeAdditionalLine,
    legalNotes = legalNotes,
    manualPowerConsumptionDistribution = manualPowerConsumptionDistribution,
    earningsDistribution = earningsDistribution.unResolve(),
    manualQuoteElements = manualQuoteElements,
    signedQuoteReceived = signedQuoteReceived,
    worstProblem = validationProblems.worstValidationProblem(),
    formattedProblems = validationProblems.format(),
    processState = processState,
    lifeCycleState = lifeCycleState,
  )
}
