@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.energy.BatteryCapacity
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Describes a battery storage type - possibly including a inverter
 */
@Serializable
data class BatteryStorageType(
  /**
   * The UUID
   */
  val id: BatteryStorageTypeId,

  /**
   * The description for the battery storage
   */
  val description: String,
  /**
   * The amount of energy that can be stored
   */
  val storageCapacity: BatteryCapacity,

  val numberOfBatteryModules: Int,

  val compatibleWithIndependenceManager: Boolean = false,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  /**
   * An id for a battery storage type
   */
  @Serializable
  data class BatteryStorageTypeId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): BatteryStorageTypeId {
        return BatteryStorageTypeId(randomUuid4())
      }
    }
  }
}
