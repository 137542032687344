package services.http

import com.benasher44.uuid.Uuid
import io.ktor.client.*
import io.ktor.client.call.*
import it.neckar.comments.Comment
import it.neckar.comments.client.DuplicateCommentsRequest
import it.neckar.comments.client.FetchCommentsForComponentResponse
import it.neckar.comments.client.SaveCommentResponse
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.get
import it.neckar.ktor.client.putJson
import it.neckar.ktor.client.safeTryFetch

/**
 * Stores / retrieves information using REST
 */
class CommentsClientService(val httpClient: HttpClient, val urlSupport: PlannerUrlSupport) {

  suspend fun fetchCommentsForComponent(commentsFor: Uuid): FetchCommentsForComponentResponse {
    return safeTryFetch(FetchCommentsForComponentResponse.failure()) {
      httpClient
        .get(urlSupport.comments.comments(commentsFor)) {
          featureFlagsHeader()
        }.body()
    }
  }

  suspend fun sendComment(commentFor: Uuid, newComment: Comment): SaveCommentResponse {
    return safeTryFetch(SaveCommentResponse.failure()) {
      httpClient
        .putJson(urlSupport.comments.comments(commentFor), newComment) {
          featureFlagsHeader()
        }.body()
    }
  }

  suspend fun duplicateComments(mapOfOldToNewUuids: Map<Uuid, Uuid>): SaveCommentResponse {
    return safeTryFetch(SaveCommentResponse.failure()) {
      httpClient
        .putJson(urlSupport.comments.duplicateComments(), DuplicateCommentsRequest(mapOfOldToNewUuids)) {
          featureFlagsHeader()
        }.body()
    }
  }

}
