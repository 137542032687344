package components.project

import components.form.commentSection
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.collections.fastForEach
import it.neckar.processStates.ProcessStatesResolver
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.Companion.toEditableStatus
import kotlinx.css.*
import react.*
import react.dom.*
import react.router.*
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useSelectUserResolver
import styled.*

/**
 * Shows the overview for *one* project
 */
val ProjectOverviewFromUrl: FC<Props> = fc("ProjectOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) })

  busyIfNull(resolvedProject, allProcessStatesForComponents) { loadedProject, loadedAllProcessStatesForComponents ->
    ProjectOverview {
      attrs {
        this.project = loadedProject
        this.processStatesResolver = loadedAllProcessStatesForComponents
      }
    }
  }
}


val ProjectOverview: FC<ProjectOverviewProps> = fc("ProjectOverview") { props ->
  val userResolver = useSelectUserResolver()

  val project = props::project.safeGet()
  val processStatesResolver = props::processStatesResolver.safeGet()


  ProjectOverviewHeader {
    attrs {
      this.project = project
      this.processStatesResolver = processStatesResolver
    }
  }

  hr("my-3") { }


  Outlet {}


  hr("my-3") {}

  div("my-5") {
    validationTable(project.getValidationProblems(null))
  }

  h3("mb-2") {
    +"Bemerkungen Projekt"
  }
  project.blueprint.annotation?.let {
    p { +it }
  }
  commentSection(project)

  div("my-5") {

    h3("mb-3") {
      +"Projektstatus Historie"
    }

    styledDiv {
      css {
        maxHeight = 300.px
        overflowY = Overflow.auto
      }

      project.containedUuids.reversed().fastForEach { containedUuid ->
        div("my-3") {
          infiniteProcessStatesList(containedUuid)
        }
      }

      project.processState?.let {
        hr { }

        p {
          OLDdetailedProcessStateLabel(it.currentEdit, null, userResolver)
        }

        it.editHistory.fastForEach {
          p {
            OLDdetailedProcessStateLabel(it, null, userResolver)
          }
        }
      }

    }

  }

}

external interface ProjectOverviewProps : Props {
  var project: ResolvedProject
  var processStatesResolver: ProcessStatesResolver
}

fun ResolvedProject.isProjectEditable(): EditableStatus {
  return (this.verification == null).toEditableStatus()
}
