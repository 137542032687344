package components.form

import it.neckar.open.collections.fastForEach
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.ElementsSelection
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.uuid.HasUuid
import react.*
import react.dom.*

/**
 * Shows a form to select multiple elements
 */
fun <T : HasUuid, ES : ElementsSelection<T>> RBuilder.elementsSelectionForm(
  /**
   * The currently selected value
   */
  selectedValue: ES,

  onChange: (element: T, newValue: Int) -> Unit,

  availableOptions: List<T>,
  formatter: (T) -> String,

  highlightedOptions: List<T> = listOf(),
): Unit = child(ElementsSelectionForm) {
  attrs {
    this.selectedValue = selectedValue.unsafeCast<ElementsSelection<HasUuid>>()
    this.onChange = onChange.unsafeCast<(HasUuid, Int) -> Unit>()
    this.availableOptions = availableOptions
    this.formatter = formatter.unsafeCast<(HasUuid) -> String>()
    this.highlightedOptions = highlightedOptions
  }
}

val ElementsSelectionForm: FC<ElementsSelectionFormProps<HasUuid, ElementsSelection<HasUuid>>> = fc("ElementsSelectionForm") { props ->
  val selectedValue = props::selectedValue.safeGet()
  val availableOptions = props::availableOptions.safeGet()
  val onChange = props::onChange.safeGet()

  val formatter = props::formatter.safeGet()
  val highlightedOptions = props::highlightedOptions.safeGet()


  div {
    availableOptions.fastForEach { option ->
      val highlighted = highlightedOptions.contains(option)
      div("row mb-1") {
        addClassIf("text-primary") {
          //Add class primary text if highlighted
          highlighted
        }
        key = option.uuid.toString()

        val formatted = formatter(option)

        div("col-sm-9") {
          +"${if (highlighted) "[Empfohlen] " else ""}$formatted"
        }

        div("col-sm-3") {
          val amount = selectedValue.get(option)

          intInput(
            value = amount,
            onChange = { onChange(option, it.coerceAtLeast(0)) },
            fieldName = option.uuid.toString(),
            title = formatted,
            numberConstraint = ZeroOrPositive,
          ) {
            zeroOrPositiveValues()
          }

          attrs {
            onKeyPress = { keyboardEvent ->
              keyboardEvent.stopPropagation()
            }
          }
        }
      }
    }
  }
}

external interface ElementsSelectionFormProps<T : HasUuid, ES : ElementsSelection<T>> : Props {
  var selectedValue: ES

  var onChange: (element: T, newValue: Int) -> Unit

  var availableOptions: List<T>

  var formatter: (T) -> String
  var highlightedOptions: List<T>
}
