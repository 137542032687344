package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.ModuleIndex
import com.meistercharts.charts.lizergy.roofPlanning.ModulePlacement
import kotlinx.serialization.Serializable

/**
 * Placement information for a single module
 */
@Serializable
data class ModuleInformation(
  /**
   * The module index
   */
  val moduleIndex: ModuleIndex,
  /**
   * If the module has been deleted
   */
  val deleted: Boolean,
)


fun ModulePlacement.toRestApiModel(): ModuleInformation {
  return ModuleInformation(
    moduleIndex = this.moduleIndex,
    deleted = moduleArea.getModule(moduleIndex).deleted
  )
}
