package it.neckar.customer.company

import kotlinx.serialization.Serializable

/**
 * The contact information (for a company)
 */
@Serializable
data class ContactInformation(
  val mail: String,
  val phone: String,
  val fax: String,
  /**
   * The web address
   */
  val url: String,
) {

  /**
   * Returns true if all properties are set
   */
  val isPlausible: Boolean
    get() {
      return mail.isNotBlank()
        && phone.isNotBlank()
        && fax.isNotBlank()
    }

  companion object {
    val empty: ContactInformation = ContactInformation("", "", "", "")
    val demo: ContactInformation = ContactInformation("lizergy-test@neckar-it.de", "+49 (0) 7473 / 959496-0", "+49 (0) 7473 / 959496-99", "lizergy-test.neckar.it")
    val Schnitzer: ContactInformation = ContactInformation("info@schnitzer-moessingen.de", "+49 (0) 7473 / 9553-0", "+49 (0) 7473 / 9553-22", "www.schnitzer-moessingen.de")
    val Lizergy: ContactInformation = ContactInformation("info@lizergy.com", "+49 (0) 7441 9521 900", "+49 (0) 7441 9521 909", "www.lizergy.com")
    val NeckarIT: ContactInformation = ContactInformation("info@neckar.it", "+49 (0) 7473 959 496 0", "", "www.neckar.it")
  }
}
