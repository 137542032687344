package components.company

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Creates a form for an address
 */
val bankInformationForm: FC<BankInformationFormProps> = fc("bankInformationForm") { props ->

  //TODO: safeGet()
  val bankName = props::bankName.getNotNull()
  val iban = props::iban.getNotNull()
  val bic = props::bic.getNotNull()

  div("row g-0") {
    div("col-12") {
      floatingInputField(
        valueAndSetter = bankName,
        fieldName = "bankName",
        title = "Bank",
      ) {
        attrs {
          mergedBelow()
        }
      }
    }

    div("col-9") {
      floatingInputField(
        valueAndSetter = iban,
        fieldName = "iban",
        title = "IBAN",
      ) {
        attrs {
          mergedAbove()
          mergedRight()
        }
      }
    }
    div("col-3") {
      floatingInputField(
        valueAndSetter = bic,
        fieldName = "bic",
        title = "BIC",
      ) {
        attrs {
          mergedAbove()
          mergedLeft()
        }
      }
    }

  }

}

external interface BankInformationFormProps : Props {
  var bankName: StateInstance<String>
  var iban: StateInstance<String>
  var bic: StateInstance<String>
}
