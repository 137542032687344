package it.neckar.lizergy.model.configuration.moduleLayout

import com.benasher44.uuid.Uuid
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.sum
import it.neckar.lifeCycle.onlyActive
import it.neckar.lizergy.model.ElementsCollection
import it.neckar.lizergy.model.configuration.energy.Current
import it.neckar.lizergy.model.configuration.energy.PerformanceFactor
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.Voltage
import it.neckar.lizergy.model.configuration.energy.sum
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayouts.ModuleLayoutsId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoofs
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roofs.RoofsId
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.lizergy.LizergyPriceListFactory
import it.neckar.lizergy.model.validation.NeedsValidation
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.lizergy.model.validation.TitleMessage
import it.neckar.lizergy.model.validation.ValidationProblems
import it.neckar.uuid.withAdded
import it.neckar.uuid.withRemoved
import it.neckar.uuid.withReplaced
import kotlinx.serialization.Serializable

/**
 * The [ResolvedModuleLayouts]
 * Resolves the IDs in a [ModuleLayoutConfiguration] to actual data
 */
@Serializable
data class ResolvedModuleLayouts(
  override val moduleLayoutsId: ModuleLayoutsId,
  override val moduleLayouts: List<ResolvedModuleLayout>,
) : ModuleLayouts, NeedsValidation, ElementsCollection<ResolvedModuleLayout> {

  override val elements: List<ResolvedModuleLayout>
    get() = moduleLayouts

  override val validElements: List<ResolvedModuleLayout>
    get() = elements.onlyActive().sortedByDescending { moduleLayout ->
      moduleLayout.roof.size?.area()
    }.sortedByDescending { moduleLayout ->
      moduleLayout.modulesCount
    }

  override val size: Int
    get() = validElements.size

  override val modulesReport: ResolvedModulesReport
    get() = ResolvedModulesReport.create(validElements)

  override operator fun get(moduleLayoutId: ModuleLayoutId): ResolvedModuleLayout {
    return moduleLayouts.find { it.id == moduleLayoutId } ?: throw IllegalArgumentException("No ResolvedModuleLayout found for moduleLayoutId <$moduleLayoutId>")
  }

  override operator fun get(roofId: RoofId): ResolvedRoof {
    return moduleLayouts.find {
      it.roof.id == roofId
    }?.roof ?: throw NoSuchElementException("No roof for the ID $roofId")
  }

  fun invoke(roofId: RoofId): PerformanceFactor? {
    return get(roofId).performanceFactor
  }

  override fun withAdded(additionalElement: ResolvedModuleLayout): ResolvedModuleLayouts {
    val updatedElements: List<ResolvedModuleLayout> = elements.withAdded(additionalElement)
    return copy(moduleLayouts = updatedElements)
  }

  override fun withAdded(additionalElements: List<ResolvedModuleLayout>): ResolvedModuleLayouts {
    val updatedElements: List<ResolvedModuleLayout> = elements + additionalElements
    return copy(moduleLayouts = updatedElements)
  }

  override fun withUpdated(updatedElement: ResolvedModuleLayout): ResolvedModuleLayouts {
    return copy(moduleLayouts = elements.withReplaced(updatedElement))
  }

  override fun withUpdated(updatedElements: List<ResolvedModuleLayout>): ResolvedModuleLayouts {
    return copy(moduleLayouts = elements.withReplaced(updatedElements))
  }

  override fun withRemoved(removedElement: ResolvedModuleLayout): ResolvedModuleLayouts {
    return copy(moduleLayouts = elements.withRemoved(removedElement))
  }

  override fun map(function: (ResolvedModuleLayout) -> ResolvedModuleLayout): ResolvedModuleLayouts {
    return ResolvedModuleLayouts(moduleLayoutsId = moduleLayoutsId, moduleLayouts = moduleLayouts.map(function))
  }

  fun duplicate(markAsCopy: Boolean, mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedModuleLayouts {
    val newId = ModuleLayoutsId.random()
    mapOfOldToNewUuids[moduleLayoutsId.uuid] = newId.uuid
    return copy(
      moduleLayoutsId = newId,
      moduleLayouts = moduleLayouts.map { moduleLayout -> moduleLayout.duplicate(markAsCopy, mapOfOldToNewUuids) },
    )
  }


  val roofs: ResolvedRoofs
    get() = ResolvedRoofs(
      id = RoofsId(moduleLayoutsId.uuid),
      roofs = moduleLayouts.map { moduleLayout ->
        moduleLayout.roof
      },
    )

  val totalNumberOfModules: Int
    get() = validElements.sumOf {
      it.modulesCount
    }

  val numberOfModulesKreuzverbund: Int
    get() = validElements.sumOf {
      it.horizontalModulesCount
    }

  val totalPowerRating: PowerRating
    get() = validElements.map {
      it.peakPower
    }.sum()

  val actualPowerRating: PowerRating
    get() = validElements.map {
      it.actualPowerRating ?: PowerRating.Zero
    }.sum()

  val totalOffLoadVoltage: Voltage
    get() = validElements.map {
      it.offLoadVoltage
    }.sum()

  val totalShortCircuitCurrent: Current
    get() = validElements.map {
      it.shortCircuitCurrent
    }.sum()

  fun scaffoldingAreas(): Map<ModuleLayoutId?, Int> {
    return validElements.associateBy({ it.id }) { it.roof.scaffoldingArea }
  }

  fun totalPriceForZiegelUndBiberschwanzDach(priceList: PriceList): PriceWithProfit {
    return validElements.filter { moduleLayout ->
      val roofType = moduleLayout.roof.roofType
      roofType == LizergyPriceListFactory.ziegelDach || roofType == LizergyPriceListFactory.biberschwanzDach
    }.map { moduleLayout ->
      priceList[moduleLayout.roof.roofType] * moduleLayout.modulesCount
    }.sum()
  }


  override val validationProblems: ValidationProblems
    get() = ValidationProblems(
      titleMessages = listOf(
        TitleMessage(null) { "Modulflächen sehen gut aus!" },
        TitleMessage(ProblemType.Error) { "Die folgenden $it Felder müssen noch in für die Modulflächen ausgefüllt werden:" },
        TitleMessage(ProblemType.Warning) { "Die folgenden $it Felder sollten für die Modulflächen überprüft werden:" },
      ),
      ownProblems = emptyList(),
      childProblems = moduleLayouts.map { it.validationProblems },
    )


  companion object {
    fun createDefault(availableProducts: AvailableProducts): ResolvedModuleLayouts {
      return ResolvedModuleLayouts(
        moduleLayoutsId = ModuleLayoutsId.random(),
        moduleLayouts = listOf(
          ResolvedModuleLayout.createDefault(
            moduleType = availableProducts.availableModules().first(),
            roofType = availableProducts.availableRoofTypes().first(),
          ),
        ),
      )
    }

    fun createDemo(availableProducts: AvailableProducts): ResolvedModuleLayouts {
      return ResolvedModuleLayouts(
        moduleLayoutsId = ModuleLayoutsId.random(),
        moduleLayouts = listOf(
          ResolvedModuleLayout.createDefault(
            moduleType = availableProducts.availableModules().first(),
            roofType = availableProducts.availableRoofTypes().first(),
          ),
        ),
      )
    }
  }

}
