package it.neckar.lizergy.model.stumps

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable

@Serializable
data class GridAssessment(
  @Serializable(with = UuidSerializer::class) override val uuid: Uuid,
  val projectId: PhotovoltaicsProjectId,
  val einstellungWechselrichter: EinstellungWechselrichter = EinstellungWechselrichter.HundredPercent,
  val erzeugungsZaehler: Boolean = false,
  val verteilerNetzbetreiber: Netzbetreiber? = null,
  val verteilerNetzbetreiberOverride: String = "",
) : HasUuid {
  val verteilerNetzbetreiberName: String?
    get() = verteilerNetzbetreiberOverride.nullIfBlank() ?: verteilerNetzbetreiber?.name
}

@Serializable
enum class EinstellungWechselrichter {
  FRE_RSE,
  HundredPercent,

  ;

  fun format(): String {
    return when (this) {
      FRE_RSE -> "FRE/RSE"
      HundredPercent -> "100%"
    }
  }
}
