package components.project

import it.neckar.editHistory.PositionEdit
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.OLDProcessStateStage
import it.neckar.lizergy.model.project.OLDProcessStateType
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.open.collections.fastForEach
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import kotlinx.html.CommonAttributeGroupFacade
import kotlinx.html.DIV
import kotlinx.html.LABEL
import kotlinx.html.SPAN
import plannerI18nConfiguration
import react.*
import react.dom.*

/**
 * Adds a label with the corresponding
 * - quote number (if available)
 * - [LizergyProcessStates]
 * - date the [LizergyProcessStates] was last changed
 * Only displays the date for the time the [LizergyProcessStates] was changed
 * Colors the text depending on the [LizergyProcessStates]
 */
@Deprecated("use new process states instead")
@Suppress("FunctionName")
fun <T : CommonAttributeGroupFacade> RDOMBuilder<T>.OLDcompactProcessStateLabel(processStateEdit: PositionEdit<OLDProcessState>?, dateOverride: ((OLDProcessState) -> Double?) = { null }, i18nConfiguration: I18nConfiguration = plannerI18nConfiguration, config: ((DIV) -> Unit)? = null) {
  div {
    attrs {
      OLDprocessStateClasses(processStateEdit?.value)?.let {
        addClass("text-$it")
      }

      config?.invoke(this)
    }

    processStateEdit?.value?.format()?.let { +it }
    br { }
    span("form-text") { processStateEdit?.value?.formatDate(dateOverride(processStateEdit.value) ?: processStateEdit.editTime, i18nConfiguration)?.let { +it } }
  }
}

/**
 * Adds a label with the corresponding
 * - quote number (if available)
 * - [LizergyProcessStates]
 * - date the [LizergyProcessStates] was last changed
 * Only displays the date for the time the [LizergyProcessStates] was changed
 * Colors the text depending on the [LizergyProcessStates]
 */
@Deprecated("use new process states instead")
@Suppress("FunctionName")
fun <T : CommonAttributeGroupFacade> RDOMBuilder<T>.OLDsimpleProcessStateLabel(
  processStateEdit: PositionEdit<OLDProcessState>?,
  quoteNumber: String?,
  i18nConfiguration: I18nConfiguration = plannerI18nConfiguration,
  config: ((SPAN) -> Unit)? = null,
) {
  span {
    attrs {
      OLDprocessStateClasses(processStateEdit?.value)?.let {
        addClass("text-$it")
      }

      config?.invoke(this)
    }

    quoteNumber?.let {
      b {
        +"$it "
      }
    }
    processStateEdit?.value?.formatWithDate(processStateEdit.editTime, i18nConfiguration)?.let { +it }
  }
}

/**
 * Adds a label with the corresponding
 * - quote number (if available)
 * - [LizergyProcessStates]
 * - date the [LizergyProcessStates] was last changed
 * Displays the date and time for the time the [LizergyProcessStates] was changed
 * Colors the text depending on the [LizergyProcessStates]
 */
@Deprecated("use new process states instead")
@Suppress("FunctionName")
fun <T : CommonAttributeGroupFacade> RDOMBuilder<T>.OLDdetailedProcessStateLabel(processStateEdit: PositionEdit<OLDProcessState>?, quoteNumber: String?, userResolver: UserResolver, i18nConfiguration: I18nConfiguration = plannerI18nConfiguration, config: ((SPAN) -> Unit)? = null) {
  span {
    attrs {
      OLDprocessStateClasses(processStateEdit?.value)?.let {
        addClass("text-$it")
      }

      config?.invoke(this)
    }

    quoteNumber?.let {
      b {
        +"$it "
      }
    }
    +buildString {
      append(processStateEdit?.value?.formatWithDate(processStateEdit.editTime, i18nConfiguration))
      append(" von ")
      append(processStateEdit?.loginName?.let { userResolver[it].editorName } ?: "-")
    }
  }
}

/**
 * Returns the proper class formatting for a given [OLDProcessState]
 */
@Deprecated("use new process states instead")
@Suppress("FunctionName")
fun OLDprocessStateClasses(processState: OLDProcessState?): String? {
  return when (processState?.processStateType()) {
    OLDProcessStateType.Archived -> "muted"

    OLDProcessStateType.NoType, OLDProcessStateType.Rejected -> "danger"

    OLDProcessStateType.BeingEdited -> null

    OLDProcessStateType.DataMissing, OLDProcessStateType.WaitingForResponse -> {
      if (processState.processStateStage() == OLDProcessStateStage.ConfirmationStage) {
        "info"
      } else {
        "warning"
      }
    }

    OLDProcessStateType.Accepted -> "success"
    null -> null
  }
}


@Deprecated("use new process states instead")
@Suppress("FunctionName")
fun <T : CommonAttributeGroupFacade> RDOMBuilder<T>.OLDprocessStateRadioButtons(radioName: String, configurationState: StateInstance<OLDProcessState>, editableStatus: (OLDProcessState) -> EditableStatus = { Editable }, buttonConfig: (RDOMBuilder<LABEL>.() -> Unit)? = null) {

  configurationState.value.processStageValues().let {
    if (it.size <= 1) return@let

    buttonGroup {
      it.fastForEach { currentProcessState ->
        val buttonColor = when (currentProcessState.processStateType()) {
          OLDProcessStateType.NoType -> "danger"
          OLDProcessStateType.Archived -> "muted"
          OLDProcessStateType.Rejected -> "danger"
          OLDProcessStateType.BeingEdited -> "dark"
          OLDProcessStateType.DataMissing -> "warning"
          OLDProcessStateType.WaitingForResponse -> {
            if (currentProcessState == OLDProcessState.OfferSent) {
              "warning"
            } else {
              "info"
            }
          }

          OLDProcessStateType.Accepted -> "success"
        }

        radioButton(
          buttonId = "$radioName$currentProcessState",
          value = configurationState.value == currentProcessState,
          onChange = { configurationState.setter(currentProcessState) },
          editableStatus = editableStatus(currentProcessState),
          labelClasses = "btn btn-outline-$buttonColor",
        ) {
          +currentProcessState.format()

          buttonConfig?.invoke(this)
        }
      }

    }

  }

}
