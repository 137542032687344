package components.project.configuration.yearlycosts

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.formatting.formatEuro
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct100
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import plannerI18nConfiguration
import react.*
import react.dom.*

val EditConfigurationYearlyCosts: FC<EditConfigurationYearlyCostsProps> = fc("EditConfigurationYearlyCosts") { props ->
  val operatingCostsToSave = props::operatingCostsToSave.getNotNull()
  val yearlyIncreaseToSave = props::yearlyIncreaseToSave.getNotNull()
  val calculatedOperatingCosts = props::calculatedOperatingCosts.getNotNull()
  val editableStatus = props::editableStatus.safeGet()


  div("my-5") {

    h2("mb-3") {
      +"Jährliche Kosten"
    }

    nullableFloatingDoubleInputField(
      valueAndSetter = operatingCostsToSave,
      fieldName = "operatingCosts",
      title = "Jährliche Kosten (Jahr 1) [${(operatingCostsToSave.value ?: calculatedOperatingCosts).formatEuro(plannerI18nConfiguration)}]",
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedBelow()
        mergedAbove()
      }
    }

    floatingDoubleInputField(
      valueAndSetter = yearlyIncreaseToSave,
      fieldName = "yearlyIncrease",
      title = "Jährliche Erhöhung (%)",
      numberOfDecimals = 1,
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedAbove()
        mergedLeft()
        step = "0.1"
      }
    }

  }

}

external interface EditConfigurationYearlyCostsProps : Props {
  var operatingCostsToSave: StateInstance<@EUR Double?>
  var yearlyIncreaseToSave: StateInstance<@pct100 Double>
  var calculatedOperatingCosts: @EUR Double
  var editableStatus: EditableStatus
}
