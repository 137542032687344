package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.lizergy.model.configuration.quote.economics.YearIndex
import it.neckar.open.unit.other.pct
import kotlin.math.pow

/**
 * Money value that increases every year
 */
data class IncreasingPricePerKWh(
  val baseValue: PricePerKWh,
  val increase: @pct Double,
) {

  /**
   * Calculates the resulting value for the given year
   */
  fun calculateValue(yearIndex: YearIndex): PricePerKWh {
    val factor = (1 + increase).pow(yearIndex.index)
    return baseValue * factor
  }
}
