package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.open.observable.ObservableObject
import it.neckar.open.observable.ReadOnlyObservableObject

/**
 * Contains the moduls
 */
class Modules {
  /**
   * The list of modules
   */
  val modulesProperty: ObservableObject<List<Module>> = ObservableObject(listOf())

  /**
   * Contains all modules with grid layout
   */
  val modulesWithGridLayoutProperty: ReadOnlyObservableObject<List<Module>> = modulesProperty

  val modulesWithGridLayout: List<Module> by modulesWithGridLayoutProperty

  /**
   * Getter for the current modules
   */
  val modules: List<Module> by modulesProperty

  operator fun get(index: Int): Module {
    return modules[index]
  }

  /**
   * Returns the modules count
   */
  val count: Int
    get() {
      return modules.size
    }

  fun clear() {
    modulesProperty.value = listOf()
  }

  /**
   * Removes all modules that have the grid layout
   */
  fun removeModulesWithGridLayout(moduleArea: ModuleArea) {
    removeAll {
      it.modulePlacement.moduleArea == moduleArea
    }
  }

  fun add(module: Module) {
    modulesProperty.getAndSet {
      it.plus(module)
    }
  }

  fun addAll(modules: Iterable<Module>) {
    modulesProperty.getAndSet {
      it.plus(modules)
    }
  }

  /**
   * Sets the modules
   */
  fun set(modules: Iterable<Module>) {
    modulesProperty.value = modules.toMutableList()
  }

  /**
   * Removes the given modules
   */
  fun removeAll(toRemove: Iterable<Module>) {
    removeAll {
      toRemove.contains(it)
    }
  }

  fun removeAll(predicate: (Module) -> Boolean) {
    modulesProperty.getAndSet {
      it.toMutableList().apply {
        removeAll(predicate)
      }
    }
  }

  fun remove(module: Module) {
    modulesProperty.getAndSet {
      it.minus(module)
    }
  }

  /**
   * Removes the module with the given index
   */
  fun remove(moduleIndex: ModuleIndex) {
    removeAll { module ->
      module.modulePlacement.moduleIndex == moduleIndex
    }
  }

  /**
   * Returns true if any module area overlaps with the given module
   */
  fun overlaps(module: Module): Boolean {
    return modules.any {
      it != module &&
        it.overlaps(module.bounds)
    }
  }
}
