package components.project.configuration.legalnotice

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

val EditLegalNotice: FC<EditLegalNoticeProps> = fc("EditLegalNotice") { props ->
  val legalNoticeAdditionalLineToSave = props::legalNoticeAdditionalLineToSave.getNotNull()
  val legalNotesToSave = props::legalNotesToSave.getNotNull()

  val scaffoldingSupplied = props::scaffoldingSupplied.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val legalNotes = legalNotesToSave.value

  val dachfanggeruestGestellt = useState(legalNotes.contains(LegalNote.DachfanggeruestGestellt) || scaffoldingSupplied)
  val verstaerktesSchienensystem = useState(legalNotes.contains(LegalNote.VerstaerktesSchienensystem))
  val satelitenschuesselDemontage = useState(legalNotes.contains(LegalNote.SatellitenschuesselDemontage))
  val dachstaenderDemontage = useState(legalNotes.contains(LegalNote.DachstaenderDemontage))
  val erdarbeitenGestellt = useState(legalNotes.contains(LegalNote.ErdarbeitenGestellt))
  val zaehlerschrankVorbereitet = useState(legalNotes.contains(LegalNote.ZaehlerschrankVorbereitet))
  val leerrohreVorbereitet = useState(legalNotes.contains(LegalNote.LeerrohreVorbereitet))


  useMemo(dachfanggeruestGestellt.value, verstaerktesSchienensystem.value, satelitenschuesselDemontage.value, dachstaenderDemontage.value, erdarbeitenGestellt.value, zaehlerschrankVorbereitet.value, leerrohreVorbereitet.value) {
    legalNotesToSave.setter(
      buildList {
        if (dachfanggeruestGestellt.value) add(LegalNote.DachfanggeruestGestellt)
        if (verstaerktesSchienensystem.value) add(LegalNote.VerstaerktesSchienensystem)
        if (satelitenschuesselDemontage.value) add(LegalNote.SatellitenschuesselDemontage)
        if (dachstaenderDemontage.value) add(LegalNote.DachstaenderDemontage)
        if (erdarbeitenGestellt.value) add(LegalNote.ErdarbeitenGestellt)
        if (zaehlerschrankVorbereitet.value) add(LegalNote.ZaehlerschrankVorbereitet)
        if (leerrohreVorbereitet.value) add(LegalNote.LeerrohreVorbereitet)
      }
    )
  }


  div {

    h2("mb-3") {
      +"Rechtliches"
    }

    div("row") {
      div("col-sm-6 my-3") {
        floatingInputArea(
          valueAndSetter = legalNoticeAdditionalLineToSave,
          fieldName = "legalNoticeAdditionalLine",
          title = "Rechtliches",
          editableStatus = editableStatus,
        ) {
          attrs {
            addClass("height-200px")
          }
        }
      }

      div("col-sm-6 my-3") {
        legalNotesForm(
          dachfanggeruestGestellt = dachfanggeruestGestellt,
          verstaerktesSchienensystem = verstaerktesSchienensystem,
          satelitenschuesselDemontage = satelitenschuesselDemontage,
          dachstaenderDemontage = dachstaenderDemontage,
          erdarbeitenGestellt = erdarbeitenGestellt,
          zaehlerschrankVorbereitet = zaehlerschrankVorbereitet,
          leerrohreVorbereitet = leerrohreVorbereitet,
          scaffoldingSupplied = scaffoldingSupplied,
          editableStatus = editableStatus,
        )
      }

    }
  }

}


external interface EditLegalNoticeProps : Props {
  var legalNoticeAdditionalLineToSave: StateInstance<String>
  var legalNotesToSave: StateInstance<List<LegalNote>>
  var scaffoldingSupplied: Boolean
  var editableStatus: EditableStatus
}
