package components.project.configuration.profitability

import it.neckar.editHistory.PositionEdit
import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight

data class EarningsTableRow(
  /**
   * label and id of the checkbox
   * */
  val label: String,

  /**
   * Profit [Money] of a role
   * */
  val calculatedProfit: Money,

  val manualProfit: PositionEdit<Money?>,

  /**
   * the right of access to which the values can be accessed
   */
  val accessRight: AccessRight,
) {
  companion object {
    val allRowAccessRights: List<AccessRight> = listOf(
      AccessRight.ViewEarningsReferrerRow,
      AccessRight.ViewEarningsDistributionBlueprintRow,
      AccessRight.ViewEarningsDistributionQuoteRow,
      AccessRight.ViewEarningsTechnicalPlanningRoofRow,
      AccessRight.ViewEarningsTechnicalPlanningElectricRow,
      AccessRight.ViewEarningsRoofAssemblyRow,
      AccessRight.ViewEarningsScaffoldingAssemblyRow,
      AccessRight.ViewEarningsElectricityWorkRow,
      AccessRight.ViewEarningsBureaucracyRow,
    )
  }
}
