package it.neckar.editHistory

import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import kotlinx.serialization.Serializable

/**
 * Represents an edit made to the position being wrapped by [PositionEditHistory]
 */
@Serializable
data class PositionEdit<T>(
  /**
   * Value of the member being wrapped
   */
  val value: T,
  /**
   * Name of the editor, who changed the value
   */
  val loginName: UserLoginName?,
  /**
   * Time when the value was changed
   */
  val editTime: @ms Double,
) {
  operator fun plus(otherProcessState: PositionEditHistory<T>?): PositionEditHistory<T> {
    return otherProcessState?.copy(currentEdit = this, editHistory = listOf(otherProcessState.currentEdit) + otherProcessState.editHistory) ?: PositionEditHistory(this, emptyList())
  }
}
