package services.storage.http

import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.company.UsersAndCompanies
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfigurations
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.project.getBelongsToCompanyCodes
import it.neckar.lizergy.model.project.previews.toPreviewQuoteElements
import it.neckar.lizergy.model.stumps.AdvanceInvoice
import it.neckar.lizergy.model.stumps.AssemblyBasement
import it.neckar.lizergy.model.stumps.AssemblyRoof
import it.neckar.lizergy.model.stumps.Documentation
import it.neckar.lizergy.model.stumps.FinalAccount
import it.neckar.lizergy.model.stumps.FinishingUp
import it.neckar.lizergy.model.stumps.GridAssessment
import it.neckar.lizergy.model.stumps.OrderSpecialMaterial
import it.neckar.lizergy.model.stumps.StartupOperations
import it.neckar.lizergy.model.stumps.SwitchMeterBox
import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import serialized.AccountingSerializedConfigurationPreview
import serialized.AccountingSerializedProjectPreview
import serialized.ModuleLayoutsConfiguration
import serialized.SerializedAssemblyPortfolio
import serialized.SerializedBlueprint
import serialized.SerializedPhotovoltaicsConfiguration
import serialized.SerializedProject
import serialized.toBlueprintPreview

/**
 * Response for
 */
@Serializable
sealed interface FetchProjectResponse : RestResponse<FetchProjectInformation> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: FetchProjectInformation) : FetchProjectResponse, RestResponse.SuccessResponse<FetchProjectInformation>

  @Serializable
  @SerialName("failure")
  data object Failure : FetchProjectResponse, RestResponse.FailureResponse<FetchProjectInformation>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: FetchProjectInformation): FetchProjectResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): FetchProjectResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: FetchProjectInformation?): FetchProjectResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }

  }
}

@Serializable
data class FetchProjectInformation(
  val photovoltaicsProject: SerializedProject,
  val blueprint: SerializedBlueprint,
  val configurations: List<FetchConfiguration>,
  val orderSpecialMaterial: OrderSpecialMaterial,
  val gridAssessment: GridAssessment,
  val assemblyPortfolio: SerializedAssemblyPortfolio,
  val advanceInvoice: AdvanceInvoice,
  val assemblyRoof: AssemblyRoof,
  val assemblyBasement: AssemblyBasement,
  val switchMeterBox: SwitchMeterBox,
  val startupOperations: StartupOperations,
  val finishingUp: FinishingUp,
  val finalAccount: FinalAccount,
  val documentation: Documentation,
  val verification: Verification?,
) {

  fun toResolvedProject(availableProducts: AvailableProducts, productResolver: ProductResolver, priceList: PriceList, userResolver: UserResolver, companyResolver: CompanyResolver): ResolvedProject {
    return photovoltaicsProject.resolve(
      blueprint = blueprint.resolve(
        productResolver = productResolver,
        userResolver = userResolver,
        companyResolver = companyResolver,
      ),
      quoteConfigurations = QuoteConfigurations(configurations.map {
        it.toQuoteConfiguration(
          availableProducts = availableProducts,
          productResolver = productResolver,
          priceList = priceList,
          userResolver = userResolver,
          companyResolver = companyResolver,
        )
      }),
      orderSpecialMaterial = orderSpecialMaterial,
      gridAssessment = gridAssessment,
      assemblyPortfolio = assemblyPortfolio.resolve(userResolver, companyResolver),
      advanceInvoice = advanceInvoice,
      assemblyRoof = assemblyRoof,
      assemblyBasement = assemblyBasement,
      switchMeterBox = switchMeterBox,
      startupOperations = startupOperations,
      finishingUp = finishingUp,
      finalAccount = finalAccount,
      documentation = documentation,
      verification = verification,
      userResolver = userResolver,
      companyResolver = companyResolver,
    )
  }

  @Serializable
  data class FetchConfiguration(
    val configuration: SerializedPhotovoltaicsConfiguration,
    val moduleLayouts: ModuleLayoutsConfiguration,
    val currentQuoteSnapshot: QuoteSnapshot?,
  ) {
    fun toAccountingConfigurationPreview(
      availableProducts: AvailableProducts,
      productResolver: ProductResolver,
      priceList: PriceList,
    ): AccountingSerializedConfigurationPreview {
      val currentQuoteElements = currentQuoteSnapshot?.quote?.quoteElements?.toPreviewQuoteElements()
      val upToDateQuoteElements = configuration.evaluate(
        moduleLayouts = moduleLayouts,
        currentQuoteSnapshot = currentQuoteSnapshot,
        productResolver = productResolver,
        availableProducts = availableProducts,
        priceList = priceList,
        userResolver = UsersAndCompanies.empty,
        companyResolver = UsersAndCompanies.empty,
      ).calculatedQuoteElements.toPreviewQuoteElements()

      return AccountingSerializedConfigurationPreview(
        configurationId = configuration.configurationId,
        label = configuration.label,
        sellingCompany = configuration.sellingCompany,
        location = configuration.location,
        editor = configuration.editor,
        modulesReport = moduleLayouts.modulesReport,
        inverterSelection = configuration.facilityConfiguration.inverterSelection,
        batteryConfigurationId = configuration.facilityConfiguration.batteryConfigurationId,
        eigenmontage = configuration.assemblyConfiguration.assemblyStatus.isBeingSelfAssembled,
        quoteElements = currentQuoteElements,
        upToDateQuoteElements = upToDateQuoteElements,
        manualQuoteElements = configuration.manualQuoteElements,
        earningsDistribution = configuration.earningsDistribution,
        worstProblem = configuration.worstProblem,
        formattedProblems = configuration.formattedProblems,
        processStateEditHistory = configuration.processState,
      )
    }

    fun toQuoteConfiguration(availableProducts: AvailableProducts, productResolver: ProductResolver, priceList: PriceList, userResolver: UserResolver, companyResolver: CompanyResolver): QuoteConfiguration {
      return configuration.evaluate(
        moduleLayouts = moduleLayouts,
        currentQuoteSnapshot = currentQuoteSnapshot,
        productResolver = productResolver,
        availableProducts = availableProducts,
        priceList = priceList,
        userResolver = userResolver,
        companyResolver = companyResolver,
      )
    }

  }

  fun toAccountingProjectPreview(
    availableProducts: AvailableProducts,
    productResolver: ProductResolver,
    priceList: PriceList,
  ): AccountingSerializedProjectPreview {
    return AccountingSerializedProjectPreview(
      projectId = photovoltaicsProject.projectId,
      projectName = photovoltaicsProject.projectName,
      sellingCompany = photovoltaicsProject.sellingCompany,
      customer = photovoltaicsProject.customer,
      maintainer = photovoltaicsProject.maintainer,
      acquisitionDate = blueprint.acquisitionDate,
      blueprintPreview = blueprint.toBlueprintPreview(),
      configurationPreviews = configurations.map { configuration ->
        configuration.toAccountingConfigurationPreview(
          availableProducts = availableProducts,
          productResolver = productResolver,
          priceList = priceList,
        )
      },
      orderSpecialMaterialId = orderSpecialMaterial.uuid,
      gridAssessmentId = gridAssessment.uuid,
      assemblyPortfolioId = assemblyPortfolio.uuid,
      advanceInvoiceId = advanceInvoice.uuid,
      assemblyRoofId = assemblyRoof.uuid,
      assemblyBasementId = assemblyBasement.uuid,
      switchMeterBoxId = switchMeterBox.uuid,
      startupOperationsId = startupOperations.uuid,
      finishingUpId = finishingUp.uuid,
      finalAccountId = finalAccount.uuid,
      documentationId = documentation.uuid,
      verification = verification,
      restArbeiten = assemblyBasement.restArbeiten,
      belongsToCompanies = photovoltaicsProject.getBelongsToCompanyCodes(blueprint, configurations.map { it.configuration }),
      archiveReasons = photovoltaicsProject.archiveReasons,
    )
  }

}
