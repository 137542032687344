package it.neckar.lizergy.model

import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.lizergy.roofPlanning.ModuleIndex
import com.meistercharts.charts.lizergy.roofPlanning.ModuleOrientation
import com.meistercharts.model.Insets
import it.neckar.commons.tags.toTags
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.geometry.Coordinates
import it.neckar.geometry.RightTriangleType
import it.neckar.geometry.Size
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.BlueprintFacilityConfiguration
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.ResolvedPhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.components.AssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.components.ElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration.ExistingFacilityConfigurationId
import it.neckar.lizergy.model.configuration.components.FacilityConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.lizergy.model.configuration.components.ResolvedAssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedFacilityConfiguration
import it.neckar.lizergy.model.configuration.components.ScaffoldingArea
import it.neckar.lizergy.model.configuration.components.WallboxConfiguration
import it.neckar.lizergy.model.configuration.energy.AmountOfEnergy
import it.neckar.lizergy.model.configuration.energy.kWh
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.planning.ModuleAreaInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.ModuleAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.ModuleInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.ModulesInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.UnusableAreaInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.UnusableAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationAnnotation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedMetalRoofTileConstructionType
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofInclination
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofOrientation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.lizergy.model.configuration.quote.builder.InverterSelection
import it.neckar.lizergy.model.configuration.quote.builder.InverterType
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedWallboxSelection
import it.neckar.lizergy.model.configuration.quote.builder.WallboxSelection.WallboxSelectionId
import it.neckar.lizergy.model.configuration.quote.economics.EquityFinanced
import it.neckar.lizergy.model.configuration.quote.economics.FinancingType
import it.neckar.lizergy.model.configuration.quote.economics.YearlyCostInformation
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.price.StaticPriceList
import it.neckar.lizergy.model.price.lizergy.LizergyPriceListFactory
import it.neckar.lizergy.model.project.Blueprint
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.ProjectConfiguration
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.si.km
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.randomUuid4

/**
 * provides factory methods to generate
 * [ResolvedBlueprint],
 * [ResolvedModuleLayouts] and
 * [ResolvedPhotovoltaicsConfiguration]
 */
object DemoDataFactory {

  val staticPriceList: StaticPriceList = LizergyPriceListFactory.createPriceList()

  /**
   * Creates a fully configurable [ResolvedModuleLayout]
   */
  fun createModuleLayout(
    // params of ResolvedRoof
    id: Roof.RoofId = Roof.RoofId(randomUuid4()),
    description: String?,
    size: Size?,
    roofType: RoofType,
    inclination: RoofInclination?,
    orientation: RoofOrientation?,
    roofTile: RoofTile?,
    metalRoofTileConstructionType: ResolvedMetalRoofTileConstructionType?,
    isolationThickness: Int?,
    roofTileOverride: String?,
    scaffoldingArea: Int,
    schwarzeKlemmen: Boolean,
    sparrenabstand: Int?,
    verstaerktesSchienensystemToggle: Boolean,

    //params of moduleLayout
    moduleType: PvModule,
    blackModules: Boolean,
    suggestedRoofInsets: Insets,
    unusableAreasInformation: UnusableAreasInformation,

    // general params
    moduleAreasInformation: ModuleAreasInformation,
    lifeCycleState: LifeCycleState = LifeCycleState.Active,
  ): ResolvedModuleLayout {

    return ResolvedModuleLayout(
      id = ModuleLayout.ModuleLayoutId.random(),
      description = description,
      moduleType = moduleType,
      blackModules = blackModules,
      roof = ResolvedRoof(
        id = id,
        size = size,
        roofType = roofType,
        inclination = inclination,
        orientation = orientation,
        roofTile = roofTile,
        metalRoofTileConstructionType = metalRoofTileConstructionType,
        isolationThickness = isolationThickness,
        roofTileOverride = roofTileOverride,
        scaffoldingArea = scaffoldingArea,
        schwarzeKlemmen = schwarzeKlemmen,
        sparrenabstand = sparrenabstand,
        verstaerktesSchienensystemToggle = verstaerktesSchienensystemToggle,
        lifeCycleState = lifeCycleState,
      ),
      suggestedRoofInsets = suggestedRoofInsets,
      moduleAreasInformation = moduleAreasInformation,
      unusableAreasInformation = unusableAreasInformation,
      lifeCycleState = lifeCycleState
    )
  }

  fun createModuleLayouts(
    moduleLayouts: List<ResolvedModuleLayout> = emptyList(),
  ): ResolvedModuleLayouts {

    return ResolvedModuleLayouts(
      moduleLayoutsId = ModuleLayouts.ModuleLayoutsId(randomUuid4()),
      moduleLayouts = moduleLayouts,
    )
  }

  /**
   * Creates a fully configurable [ResolvedPhotovoltaicsConfiguration]
   */
  fun createConfiguration(
    label: String = "",
    description: String = "",
    yearlyPowerConsumption: AmountOfEnergy = 6700.00.kWh,
    assemblyDifficulty: AssemblyDifficulty = staticPriceList.defaultAssemblyDifficulty,
    creationTime: @ms Double,
    location: LocationInformation = LocationInformation.neckarIT,
    shippingDistance: Int = 26,
    manualPowerConsumptionDistribution: ManualPowerConsumptionDistribution? = ManualPowerConsumptionDistribution(50.percent),
    facilityConfiguration: ResolvedFacilityConfiguration = ResolvedFacilityConfiguration.getEmpty(),
    additionalPositions: ConfigurationItemsConfiguration = ConfigurationItemsConfiguration.getEmpty(),
    existingFacilitiesConfiguration: ExistingFacilitiesConfiguration = ExistingFacilitiesConfiguration.getEmpty(ExistingFacilityConfigurationId.random()),
    electricityWorkConfiguration: ResolvedElectricityWorkConfiguration = ResolvedElectricityWorkConfiguration.getEmpty(),
    yearlyCosts: YearlyCostInformation = YearlyCostInformation(),
    discountPercentage: Double = 55.percent,
    wallboxSelection: ResolvedWallboxSelection = ResolvedWallboxSelection.getEmpty(id = WallboxSelectionId.random()),
    assemblyConfiguration: ResolvedAssemblyConfiguration = ResolvedAssemblyConfiguration.getEmpty(assemblyDifficulty),
    sellingCompanyInformation: PlannerCompanyInformation = PlannerCompanyInformation.createStubCompany(CompanyCode.Lizergy),
    monitoringCompanyInformation: PlannerCompanyInformation = PlannerCompanyInformation.createStubCompany(CompanyCode.Lizergy),
    editor: UserInformation = UserInformation.demo,
    zaehlerNummer: String = "",
    flurstueckNummer: String = "",
    financing: FinancingType = EquityFinanced,
    einspeiseart: Einspeiseart = Einspeiseart.Ueberschuss,
    earningsDistribution: ResolvedEarningsDistribution = ResolvedEarningsDistribution.getEmpty(defaultCompany = MainCompanyProfile(CompanyCode.Lizergy), loggedInUser = UserLoginName("lizergy"), now = creationTime),
    moduleLayouts: ResolvedModuleLayouts = ResolvedModuleLayouts.createDefault(staticPriceList),
    shippingDistanceCalculated: Int = 0,
    pricesTrendScenario: PricesTrendScenario = PricesTrendScenario.typical(),
    lifeCycleState: LifeCycleState = LifeCycleState.Active,
    processState: PositionEditHistory<OLDProcessState> = PositionEditHistory(OLDProcessState.ConfigurationCreated, UserLoginName(""), nowMillis()),
  ): ResolvedPhotovoltaicsConfiguration {

    val powerUsageScenario = PowerUsageScenario(
      id = PowerUsageScenario.PowerUsageScenarioId.random(),
      yearlyPowerConsumption = yearlyPowerConsumption,
    )

    return ResolvedPhotovoltaicsConfiguration(
      configurationId = PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId.random(),
      label = label,
      description = description,
      creationTime = creationTime,
      sellingCompanyInformation = sellingCompanyInformation,
      monitoringCompanyInformation = monitoringCompanyInformation,
      editorInformation = editor,
      location = location,
      powerUsageScenario = powerUsageScenario,
      facilityConfiguration = facilityConfiguration,
      wallboxSelection = wallboxSelection,
      yearlyCosts = yearlyCosts,
      financing = financing,
      assemblyConfiguration = assemblyConfiguration,
      shippingDistanceManual = shippingDistance,
      electricityWorkConfiguration = electricityWorkConfiguration,
      additionalPositions = additionalPositions,
      existingFacilitiesConfiguration = existingFacilitiesConfiguration,
      discountPercentage = discountPercentage,
      zaehlerNummer = zaehlerNummer,
      flurstueckNummer = flurstueckNummer,
      facilityOperator1 = FacilityOperatorInformation(),
      facilityOperator2 = FacilityOperatorInformation(),
      einspeiseart = einspeiseart,
      manualPowerConsumptionDistribution = manualPowerConsumptionDistribution,
      legalNoticeAdditionalLine = null,
      legalNotes = emptyList(),
      earningsDistribution = earningsDistribution,
      moduleLayouts = moduleLayouts,
      shippingDistanceCalculated = shippingDistanceCalculated,
      pricesTrendScenario = pricesTrendScenario,
      manualQuoteElements = ManualQuoteElements.empty,
      lifeCycleState = lifeCycleState,
      processState = processState,
    )
  }

  fun createDemoQuotesConfiguration(
    label: String = "",
    assemblyDifficulty: AssemblyDifficulty,
    loggedInUser: UserInformation,
    batteryConfiguration: BatteryConfiguration? = null,
  ): ResolvedPhotovoltaicsConfiguration {
    return createConfiguration(
      label = label,
      description = "",
      creationTime = nowMillis(),
      editor = UserInformation.demo,
      location = LocationInformation.neckarIT,
      yearlyPowerConsumption = PowerUsageScenario.demo().yearlyPowerConsumption,
      assemblyDifficulty = staticPriceList.defaultAssemblyDifficulty,
      manualPowerConsumptionDistribution = ManualPowerConsumptionDistribution(21.00.percent),
      facilityConfiguration = ResolvedFacilityConfiguration(
        id = FacilityConfiguration.FacilityConfigurationId(randomUuid4()),
        numberOfOptimizers = 0,
        kabelwegZugschlagLength = 35.00,
        numberUeberspannungsSchutz = 2,
        inverterSelection = ResolvedInverterSelection(
          id = InverterSelection.InverterSelectionId(uuidFrom("679f6e60-ff8b-44f0-963c-d92d0048a64b")),
          entries = listOf(ElementsSelectionEntry(staticPriceList[InverterType.InverterId(uuidFrom("679f6e60-ff8b-44f0-963c-d92d0048a64b"))], 1)),
        ),
        facilityWorkEffort = ConfigurationItem(
          headline = "Risikozuschlag",
          details = "",
          priceForOneElement = PriceWithProfit(750.00, 750.00),
          amount = 1.0,
          // TODO teil der Wirtschaftlichkeit
          relevance = LizergyCalculationCategories.EconomicsCalculation.toTags(),
          valueAddedTax = ValueAddedTax.zero,
        ),
        batteryConfiguration = batteryConfiguration,
        waermepumpenanbindung = false,
        erdspiessSetzen = false,
        onlineMonitoring = ConfiguredOptionality.Selected,
        integrateInverterIntoNetwork = ConfiguredOptionality.Selected,
        heaterRod = null,
      ),
      additionalPositions = ConfigurationItemsConfiguration(
        id = ConfigurationItemsConfiguration.ConfigurationItemsConfigurationId(randomUuid4()),
        configurationItems = emptyList(),
      ),
      existingFacilitiesConfiguration = ExistingFacilitiesConfiguration.getEmpty(ExistingFacilityConfigurationId(uuidFrom("cec471d2-2a0b-4a5a-9d8e-77ca4bc0bc22"))),
      yearlyCosts = YearlyCostInformation(operatingCosts = null, operatingCostsIncreasePercentage = 0.0),
      discountPercentage = 0.00.percent,
      wallboxSelection = ResolvedWallboxSelection.getEmpty(id = WallboxSelectionId(uuidFrom("3a96d639-336d-4d7e-bf47-e1f46aa2d395"))),
      electricityWorkConfiguration = ResolvedElectricityWorkConfiguration(
        id = ElectricityWorkConfiguration.ElectricityWorkConfigurationId(randomUuid4()),
        electricityWorkEffort = ConfigurationItem.getEmpty(ValueAddedTax.zero),
        sunnyHomeManager = true,
        independenceManager = null,
        neuerZaehlerschrank = false,
        zusammenZuLegendeZaehlerNummern = emptyList(),
        einbauUnterverteiler = false,
        manualEinbauDigitalerZaehler = null,
        smaEnergyMeter = false,
        slsNachruesten = false,
      ),
      financing = EquityFinanced,
      assemblyConfiguration = ResolvedAssemblyConfiguration(
        id = AssemblyConfiguration.AssemblyConfigurationId(uuid = randomUuid4()),
        assemblyDifficulty = assemblyDifficulty,
        numberOfModulesKreuzverbund = 0,
        dachstaenderIsolierung = false,
        moveSatelliteDish = false,
        removeAntenna = false,
        assemblyStatus = ScaffoldingArea(scaffoldingArea = 80),
      ),
      shippingDistance = @km 26,
      pricesTrendScenario = PricesTrendScenario.demoQuote(),
      earningsDistribution = ResolvedEarningsDistribution.getEmpty(defaultCompany = MainCompanyProfile(CompanyCode.Lizergy), loggedInUser = UserLoginName("lizergy")),
      processState = PositionEditHistory(OLDProcessState.ConfigurationCreated, UserLoginName(loggedInUser.loginName.value), nowMillis()),
      lifeCycleState = LifeCycleState.Active,
      zaehlerNummer = "1 ITR00 5527 9997",
      moduleLayouts = createModuleLayouts(
        listOf(
          createModuleLayout(
            description = null,
            size = Size(10_834, 6_121),
            roofType = staticPriceList[RoofType.RoofTypeId(uuidFrom("a3cd6525-0b97-4a27-870e-fd4de3d52881"))],
            inclination = RoofInclination(10),
            orientation = RoofOrientation.normalized(65),
            roofTile = staticPriceList[RoofTile.RoofTileId(uuidFrom("0ef57b9b-15e6-45f6-a1af-44be53e0776d"))],
            metalRoofTileConstructionType = null,
            isolationThickness = null,
            roofTileOverride = null,
            scaffoldingArea = 0,
            schwarzeKlemmen = false,
            sparrenabstand = 0,
            verstaerktesSchienensystemToggle = true,
            moduleType = staticPriceList[PvModule.PvModuleId(uuidFrom("a9e6a229-9331-4f04-83f7-eb8d38159115"))],
            blackModules = false,
            suggestedRoofInsets = Insets.of(200.0),
            lifeCycleState = LifeCycleState.Active,
            moduleAreasInformation = ModuleAreasInformation(
              moduleAreaInformations = listOf(
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("abbc2e80-23d2-4c1f-a2c8-ef4d36d5f9d6")),
                  location = Coordinates(200, 200),
                  size = Size(0, 0),
                  orientation = ModuleOrientation.Vertical, 20.0,
                  modulesInformation = ModulesInformation(
                    moduleInformations = emptyList()
                  )
                ),
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("b907b8a5-40c7-4856-980f-44e5d70d9dda")),
                  location = Coordinates(701.6267857142858, 298.3732142857139),
                  size = Size(9234.0, 2354.450000000002),
                  orientation = ModuleOrientation.Horizontal, 20.0,
                  modulesInformation = ModulesInformation(
                    listOf(
                      ModuleInformation(ModuleIndex(0, 0), deleted = true),
                      ModuleInformation(ModuleIndex(1, 0), deleted = true),
                      ModuleInformation(ModuleIndex(2, 0), deleted = true),
                      ModuleInformation(ModuleIndex(3, 0), deleted = true),
                      ModuleInformation(ModuleIndex(4, 0), deleted = true),
                      ModuleInformation(ModuleIndex(0, 1), deleted = false),
                      ModuleInformation(ModuleIndex(1, 1), deleted = false),
                      ModuleInformation(ModuleIndex(2, 1), deleted = false),
                      ModuleInformation(ModuleIndex(3, 1), deleted = false),
                      ModuleInformation(ModuleIndex(4, 1), deleted = false),
                    )
                  )
                ),
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("daba6450-11f2-457e-8077-e4ce7877daac")),
                  location = Coordinates(712.5571428571428, 3137.926785714284),
                  size = Size(9234.0, 2354.450000000002),
                  orientation = ModuleOrientation.Horizontal, 20.0,
                  modulesInformation = ModulesInformation(
                    listOf(
                      ModuleInformation(ModuleIndex(0, 0), deleted = true),
                      ModuleInformation(ModuleIndex(1, 0), deleted = true),
                      ModuleInformation(ModuleIndex(0, 1), deleted = false),
                      ModuleInformation(ModuleIndex(1, 1), deleted = false),
                    )
                  )
                ),
              )
            ),
            unusableAreasInformation = UnusableAreasInformation(
              listOf(
                UnusableAreaInformation(
                  location = Coordinates(x = 4464.398214285721, y = 2951.908928571435),
                  size = Size(width = 6369.601785714279, height = 3169.091071428565),
                  description = "Unnamed",
                  rightTriangleType = null
                )
              )
            ),
          ),
          createModuleLayout(
            description = "Süd-West-Dach 2,98 kWp (Kopie)",
            size = Size(10_834, 6_121),
            roofType = staticPriceList[RoofType.RoofTypeId(uuidFrom("a3cd6525-0b97-4a27-870e-fd4de3d52881"))],
            inclination = RoofInclination(10),
            orientation = RoofOrientation.normalized(-115),
            roofTile = staticPriceList[RoofTile.RoofTileId(uuidFrom("0ef57b9b-15e6-45f6-a1af-44be53e0776d"))],
            metalRoofTileConstructionType = null,
            isolationThickness = null,
            roofTileOverride = null,
            scaffoldingArea = 0,
            schwarzeKlemmen = false,
            sparrenabstand = 0,
            verstaerktesSchienensystemToggle = true,
            lifeCycleState = LifeCycleState.Active,
            moduleType = staticPriceList[PvModule.PvModuleId(uuidFrom("a9e6a229-9331-4f04-83f7-eb8d38159115"))],
            blackModules = false,
            suggestedRoofInsets = Insets.of(200.0),
            moduleAreasInformation = ModuleAreasInformation(
              listOf(
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("7d60d2b2-1440-4489-a991-477d453972c3")),
                  location = Coordinates(200, 200),
                  size = Size(0, 0),
                  orientation = ModuleOrientation.Vertical, 20.0,
                  modulesInformation = ModulesInformation(
                    moduleInformations = emptyList()
                  )
                ),
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("75f24431-9c9b-4c0a-b876-1096ef250037")),
                  location = Coordinates(701.6267857142858, 298.3732142857139),
                  size = Size(9234.0, 2354.450000000002),
                  orientation = ModuleOrientation.Horizontal, 20.0,
                  modulesInformation = ModulesInformation(
                    moduleInformations = listOf(
                      ModuleInformation(ModuleIndex(0, 0), deleted = false),
                      ModuleInformation(ModuleIndex(1, 0), deleted = false),
                      ModuleInformation(ModuleIndex(2, 0), deleted = false),
                      ModuleInformation(ModuleIndex(3, 0), deleted = false),
                      ModuleInformation(ModuleIndex(4, 0), deleted = false),
                      ModuleInformation(ModuleIndex(0, 1), deleted = true),
                      ModuleInformation(ModuleIndex(1, 1), deleted = true),
                      ModuleInformation(ModuleIndex(2, 1), deleted = true),
                      ModuleInformation(ModuleIndex(3, 1), deleted = true),
                      ModuleInformation(ModuleIndex(4, 1), deleted = true),
                    )
                  )
                ),
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("1add1166-b1ef-4353-bb53-c5b61f3a6505")),
                  location = Coordinates(712.5571428571428, 3137.926785714284),
                  size = Size(9234.0, 2354.450000000002),
                  orientation = ModuleOrientation.Horizontal, 20.0,
                  modulesInformation = ModulesInformation(
                    listOf(
                      ModuleInformation(ModuleIndex(0, 0), deleted = false),
                      ModuleInformation(ModuleIndex(1, 0), deleted = false),
                      ModuleInformation(ModuleIndex(0, 1), deleted = true),
                      ModuleInformation(ModuleIndex(1, 1), deleted = true),
                    )
                  )
                )
              )
            ),
            unusableAreasInformation = UnusableAreasInformation(
              listOf(
                UnusableAreaInformation(
                  location = Coordinates(x = 4464.398214285721, y = 2951.908928571435),
                  size = Size(width = 6369.601785714279, height = 3169.091071428565),
                  description = "Unnamed",
                  rightTriangleType = null
                )
              )
            ),
          )
        )
      )
    )
  }

  fun createDemoPresentationConfiguration(
    label: String = "",
    assemblyDifficulty: AssemblyDifficulty,
    loggedInUser: UserInformation,
    batteryConfiguration: BatteryConfiguration? = null,
  ): ResolvedPhotovoltaicsConfiguration {
    return createConfiguration(
      label = label,
      description = "",
      creationTime = nowMillis(),
      editor = UserInformation.demo,
      location = LocationInformation.neckarIT,
      assemblyDifficulty = assemblyDifficulty,
      yearlyPowerConsumption = 4500.00.kWh,
      facilityConfiguration = ResolvedFacilityConfiguration.presentationDemo(
        inverterSelection = ResolvedInverterSelection(id = InverterSelection.InverterSelectionId.random(), entries = listOf(ElementsSelectionEntry(staticPriceList[InverterType.InverterId(uuidFrom("6ed9bc4e-abdc-4e78-9e19-440a6105a57b"))], 1))),
        batteryConfiguration = batteryConfiguration,
      ),
      assemblyConfiguration = ResolvedAssemblyConfiguration.presentationDemo(assemblyDifficulty),
      wallboxSelection = ResolvedWallboxSelection(entries = emptyList(), throttled = false),
      yearlyCosts = YearlyCostInformation(operatingCosts = Money.euros(0.0), operatingCostsIncreasePercentage = 0.0),
      shippingDistance = 30,
      electricityWorkConfiguration = ResolvedElectricityWorkConfiguration.getEmpty(),
      additionalPositions = ConfigurationItemsConfiguration(configurationItems = emptyList()),
      discountPercentage = 0.0.percent,
      manualPowerConsumptionDistribution = null,
      earningsDistribution = ResolvedEarningsDistribution.getEmpty(defaultCompany = MainCompanyProfile(CompanyCode.Lizergy), loggedInUser = UserLoginName("lizergy")),
      lifeCycleState = LifeCycleState.Active,
      processState = PositionEditHistory(OLDProcessState.ConfigurationCreated, UserLoginName(loggedInUser.loginName.value), nowMillis()), // TODO

      moduleLayouts = createModuleLayouts(
        moduleLayouts = listOf(
          createModuleLayout(
            description = null,
            size = Size(10_300, 5000),
            roofType = staticPriceList[RoofType.RoofTypeId(uuidFrom("bff1602e-3e42-47d9-abee-d031fa35dcfa"))],
            inclination = RoofInclination(30),
            orientation = RoofOrientation.normalized(0),
            roofTile = staticPriceList[RoofTile.RoofTileId(uuidFrom("90e13f6d-bc63-42bd-a089-8dc5f3cb7073"))],
            metalRoofTileConstructionType = null,
            isolationThickness = null,
            roofTileOverride = null,
            scaffoldingArea = 0,
            schwarzeKlemmen = false,
            sparrenabstand = 0,
            verstaerktesSchienensystemToggle = true,
            moduleType = staticPriceList[PvModule.PvModuleId(uuidFrom("a516b516-0ef1-4df7-af4b-0c93b73ad2cd"))],
            blackModules = false,
            suggestedRoofInsets = Insets.of(100.0),
            moduleAreasInformation = ModuleAreasInformation(
              moduleAreaInformations = listOf(
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("7e6466de-e694-45fc-a560-591a4336a448")),
                  location = Coordinates(435.7142857142858, 700.0),
                  size = Size(9500, 4200),
                  orientation = ModuleOrientation.Vertical, 20.0,
                  modulesInformation = ModulesInformation(
                    moduleInformations = listOf(
                      ModuleInformation(ModuleIndex(0, 0), deleted = false),
                      ModuleInformation(ModuleIndex(1, 0), deleted = false),
                      ModuleInformation(ModuleIndex(2, 0), deleted = false),
                      ModuleInformation(ModuleIndex(3, 0), deleted = false),
                      ModuleInformation(ModuleIndex(4, 0), deleted = false),
                      ModuleInformation(ModuleIndex(5, 0), deleted = false),
                      ModuleInformation(ModuleIndex(6, 0), deleted = false),
                      ModuleInformation(ModuleIndex(7, 0), deleted = false),
                      ModuleInformation(ModuleIndex(0, 1), deleted = false),
                      ModuleInformation(ModuleIndex(1, 1), deleted = false),
                      ModuleInformation(ModuleIndex(2, 1), deleted = false),
                      ModuleInformation(ModuleIndex(3, 1), deleted = false),
                      ModuleInformation(ModuleIndex(4, 1), deleted = false),
                      ModuleInformation(ModuleIndex(5, 1), deleted = false),
                      ModuleInformation(ModuleIndex(6, 1), deleted = false),
                      ModuleInformation(ModuleIndex(7, 1), deleted = false),
                    )
                  )
                )
              )
            ),
            unusableAreasInformation = UnusableAreasInformation(emptyList()),
            lifeCycleState = LifeCycleState.Active,
          ),
        )
      )
    )
  }

  fun createDemoPresentationAdditionalConfiguration(
    label: String = "",
    assemblyDifficulty: AssemblyDifficulty,
    loggedInUser: UserInformation,
    batteryConfiguration: BatteryConfiguration? = null,
  ): ResolvedPhotovoltaicsConfiguration {
    return createConfiguration(
      label = label,
      pricesTrendScenario = PricesTrendScenario.demoPresentationAdditional(),
      description = "",
      creationTime = nowMillis(),
      editor = UserInformation.demo,
      location = LocationInformation.neckarIT,
      assemblyDifficulty = assemblyDifficulty,
      yearlyPowerConsumption = 4500.00.kWh,
      facilityConfiguration = ResolvedFacilityConfiguration.presentationDemo(
        inverterSelection = ResolvedInverterSelection(
          id = InverterSelection.InverterSelectionId.random(),
          entries = listOf(ElementsSelectionEntry(staticPriceList[InverterType.InverterId(uuidFrom("6ed9bc4e-abdc-4e78-9e19-440a6105a57b"))], 1)),
        ),
        batteryConfiguration = batteryConfiguration,
      ),
      assemblyConfiguration = ResolvedAssemblyConfiguration.presentationDemo(assemblyDifficulty),
      wallboxSelection = ResolvedWallboxSelection(entries = emptyList(), throttled = false),
      yearlyCosts = YearlyCostInformation(operatingCosts = Money.euros(0.0), operatingCostsIncreasePercentage = 0.0),
      shippingDistance = 30,
      electricityWorkConfiguration = ResolvedElectricityWorkConfiguration.getEmpty(),
      additionalPositions = ConfigurationItemsConfiguration(configurationItems = emptyList()),
      discountPercentage = 0.0.percent,
      manualPowerConsumptionDistribution = null,
      earningsDistribution = ResolvedEarningsDistribution.getEmpty(defaultCompany = MainCompanyProfile(CompanyCode.Lizergy), loggedInUser = UserLoginName("lizergy")),
      lifeCycleState = LifeCycleState.Active,
      processState = PositionEditHistory(OLDProcessState.ConfigurationCreated, UserLoginName(loggedInUser.loginName.value), nowMillis()), // TODO

      moduleLayouts = createModuleLayouts(
        moduleLayouts = listOf(
          createModuleLayout(
            description = null,
            size = Size(11_070, 5420),
            roofType = staticPriceList[RoofType.RoofTypeId(uuidFrom("bff1602e-3e42-47d9-abee-d031fa35dcfa"))],
            inclination = RoofInclination(30),
            orientation = RoofOrientation.normalized(-80),
            roofTile = staticPriceList[RoofTile.RoofTileId(uuidFrom("90e13f6d-bc63-42bd-a089-8dc5f3cb7073"))],
            metalRoofTileConstructionType = null,
            isolationThickness = null,
            roofTileOverride = null,
            scaffoldingArea = 0,
            schwarzeKlemmen = false,
            sparrenabstand = null,
            verstaerktesSchienensystemToggle = true,
            moduleType = staticPriceList[PvModule.PvModuleId(uuidFrom("a9e6a229-9331-4f04-83f7-eb8d38159115"))],
            blackModules = false,
            suggestedRoofInsets = Insets(100.0, 200.0, 100.0, 200.0),
            moduleAreasInformation = ModuleAreasInformation(
              moduleAreaInformations = listOf(
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("355efd8f-be6d-466d-9e34-6327bdb051b6")),
                  location = Coordinates(256.8214285714286, 100.0),
                  size = Size(10613.178571428565, 5220.0),
                  orientation = ModuleOrientation.Vertical, 20.0,
                  modulesInformation = ModulesInformation(
                    moduleInformations = listOf(
                      ModuleInformation(ModuleIndex(0, 1), deleted = false),
                      ModuleInformation(ModuleIndex(1, 1), deleted = false),
                      ModuleInformation(ModuleIndex(2, 1), deleted = false),
                      ModuleInformation(ModuleIndex(3, 1), deleted = false),
                      ModuleInformation(ModuleIndex(4, 1), deleted = false),
                      ModuleInformation(ModuleIndex(5, 1), deleted = false),
                      ModuleInformation(ModuleIndex(6, 1), deleted = false),
                      ModuleInformation(ModuleIndex(7, 1), deleted = false),
                      ModuleInformation(ModuleIndex(8, 0), deleted = false),
                      ModuleInformation(ModuleIndex(8, 1), deleted = false),
                      ModuleInformation(ModuleIndex(0, 2), deleted = false),
                      ModuleInformation(ModuleIndex(1, 2), deleted = false),
                      ModuleInformation(ModuleIndex(2, 2), deleted = false),
                      ModuleInformation(ModuleIndex(3, 2), deleted = false),
                      ModuleInformation(ModuleIndex(4, 2), deleted = false),
                      ModuleInformation(ModuleIndex(5, 2), deleted = false),
                      ModuleInformation(ModuleIndex(6, 2), deleted = false),
                      ModuleInformation(ModuleIndex(7, 2), deleted = false),
                      ModuleInformation(ModuleIndex(8, 2), deleted = false),
                      ModuleInformation(ModuleIndex(0, 0), deleted = false),
                      ModuleInformation(ModuleIndex(1, 0), deleted = false),
                      ModuleInformation(ModuleIndex(2, 0), deleted = false),
                      ModuleInformation(ModuleIndex(3, 0), deleted = false),
                      ModuleInformation(ModuleIndex(4, 0), deleted = false),
                    )
                  )
                )
              ),
            ),
            unusableAreasInformation = UnusableAreasInformation(
              unusableAreas = listOf(
                UnusableAreaInformation(
                  location = Coordinates(7055.357142857159, 1117.8571428571404),
                  size = Size(width = 285.7142857142856, height = 344.6428571428594),
                  description = "Unnamed",
                  rightTriangleType = null
                ),
                UnusableAreaInformation(
                  location = Coordinates(8842.96428571427, 1077.5357142857108),
                  size = Size(width = 383.8571428571429, height = 496.67857142857395),
                  description = "Unnamed",
                  rightTriangleType = null
                )
              )
            ),
            lifeCycleState = LifeCycleState.Active,
          ),
          createModuleLayout(
            description = null,
            size = Size(2970.0, 5420.0),
            roofType = staticPriceList[RoofType.RoofTypeId(uuidFrom("bff1602e-3e42-47d9-abee-d031fa35dcfa"))],
            inclination = RoofInclination(30),
            orientation = RoofOrientation.normalized(100),
            roofTile = staticPriceList[RoofTile.RoofTileId(uuidFrom("90e13f6d-bc63-42bd-a089-8dc5f3cb7073"))],
            metalRoofTileConstructionType = null,
            isolationThickness = null,
            roofTileOverride = null,
            scaffoldingArea = 0,
            schwarzeKlemmen = false,
            sparrenabstand = null,
            verstaerktesSchienensystemToggle = true,
            moduleType = staticPriceList[PvModule.PvModuleId(uuidFrom("a9e6a229-9331-4f04-83f7-eb8d38159115"))],
            blackModules = false,
            suggestedRoofInsets = Insets.of(100.0),
            moduleAreasInformation = ModuleAreasInformation(
              moduleAreaInformations = listOf(
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("5143205f-a74b-4fe5-a8c9-547d7c7ff30a")),
                  location = Coordinates(400.0, 100.0),
                  size = Size(2470.0, 5220.0),
                  orientation = ModuleOrientation.Vertical, 20.0,
                  modulesInformation = ModulesInformation(
                    moduleInformations = listOf(
                      ModuleInformation(ModuleIndex(0, 0), deleted = false),
                      ModuleInformation(ModuleIndex(0, 1), deleted = false),
                      ModuleInformation(ModuleIndex(1, 0), deleted = false),
                      ModuleInformation(ModuleIndex(1, 1), deleted = false),
                      ModuleInformation(ModuleIndex(0, 2), deleted = false),
                      ModuleInformation(ModuleIndex(1, 2), deleted = false),
                    )
                  )
                )
              ),
            ),
            unusableAreasInformation = UnusableAreasInformation(emptyList()),
            lifeCycleState = LifeCycleState.Active,
          ),
          createModuleLayout(
            description = null,
            size = Size(5020.0, 2790.0),
            roofType = staticPriceList[RoofType.RoofTypeId(uuidFrom("bff1602e-3e42-47d9-abee-d031fa35dcfa"))],
            inclination = RoofInclination(30),
            orientation = RoofOrientation.normalized(10),
            roofTile = null,
            metalRoofTileConstructionType = null,
            isolationThickness = null,
            roofTileOverride = null,
            scaffoldingArea = 0,
            schwarzeKlemmen = false,
            sparrenabstand = null,
            verstaerktesSchienensystemToggle = true,
            moduleType = staticPriceList[PvModule.PvModuleId(uuidFrom("a9e6a229-9331-4f04-83f7-eb8d38159115"))],
            blackModules = false,
            suggestedRoofInsets = Insets.of(100.0),
            moduleAreasInformation = ModuleAreasInformation(
              moduleAreaInformations = listOf(
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("a5002efc-fea8-4ecf-8978-b79c794c38a0")),
                  location = Coordinates(200.0, 200.0),
                  size = Size(0.0, 0.0),
                  orientation = ModuleOrientation.Vertical, 20.0,
                  modulesInformation = ModulesInformation(moduleInformations = emptyList()),
                ),
                ModuleAreaInformation(
                  id = ModuleAreaInformation.ModuleAreaId(uuidFrom("8fed0359-a1a2-4362-acc4-6e9a86fa349d")),
                  location = Coordinates(100.0, 199.6428571428568),
                  size = Size(4820.0, 2490.357142857142),
                  orientation = ModuleOrientation.Horizontal, 20.0,
                  modulesInformation = ModulesInformation(
                    moduleInformations = listOf(
                      ModuleInformation(ModuleIndex(0, 0), deleted = false),
                      ModuleInformation(ModuleIndex(0, 1), deleted = false),
                      ModuleInformation(ModuleIndex(1, 1), deleted = false),
                    )
                  ),
                )
              ),
            ),
            unusableAreasInformation = UnusableAreasInformation(
              unusableAreas = listOf(
                UnusableAreaInformation(
                  location = Coordinates(2551.696428571434, 0.0),
                  size = Size(width = 2468.303571428566, height = 2790.0),
                  description = "Unnamed",
                  rightTriangleType = RightTriangleType.MissingCornerInFourthQuadrant
                )
              )
            ),
            lifeCycleState = LifeCycleState.Active,
          )
        )
      )
    )
  }

  fun createDemoBlueprint(): ResolvedBlueprint {
    return createBlueprint(
      sellingCompany = PlannerCompanyInformation.createStubCompany(CompanyCode.Lizergy),
      assemblyDifficulty = staticPriceList.availableAssemblyDifficulties().first(),
      editor = UserInformation.demo,
      shippingDistanceManual = null,
      shippingDistanceCalculated = null,
      location = LocationInformation.neckarIT,
      resolvedModuleLayouts = createModuleLayouts(
        moduleLayouts = listOf(
          createModuleLayout(
            description = null,
            size = null,
            roofType = staticPriceList[RoofType.RoofTypeId(uuidFrom("bff1602e-3e42-47d9-abee-d031fa35dcfa"))],
            inclination = null,
            orientation = null,
            roofTile = null,
            metalRoofTileConstructionType = null,
            isolationThickness = null,
            roofTileOverride = null,
            scaffoldingArea = 0,
            schwarzeKlemmen = false,
            sparrenabstand = 0,
            verstaerktesSchienensystemToggle = true,
            moduleType = staticPriceList[PvModule.PvModuleId(uuidFrom("9d09ecc6-d332-453e-a95a-e9fe253be436"))],
            blackModules = false,
            suggestedRoofInsets = Insets.of(100.0),
            moduleAreasInformation = ModuleAreasInformation(
              moduleAreaInformations = emptyList()
            ),
            unusableAreasInformation = UnusableAreasInformation(emptyList()),
            lifeCycleState = LifeCycleState.Active,
          ),
        )
      ),
      powerUsageScenario = PowerUsageScenario.typical(),
      pricesTrendScenario = PricesTrendScenario.typical(),

      )
  }

  /**
   * Creates a fully configurable [ResolvedBlueprint]
   */
  fun createBlueprint(
    projectId: ProjectConfiguration.PhotovoltaicsProjectId = ProjectConfiguration.PhotovoltaicsProjectId.random(),
    editor: UserInformation? = UserInformation.empty,
    sellingCompany: PlannerCompanyInformation = PlannerCompanyInformation.createStubCompany(CompanyCode("")),
    location: LocationInformation = LocationInformation.empty,
    loggedInUser: UserLoginName = UserLoginName(""),
    moduleType: PvModule = staticPriceList.availableModules().random(),
    roofType: RoofType = staticPriceList.availableRoofTypes().random(),
    shippingDistanceManual: Int? = @km 26,
    shippingDistanceCalculated: Int? = @km 26,
    assemblyDifficulty: AssemblyDifficulty,
    acquisitionDate: Double = nowMillis(),
    creationTime: @ms Double = nowMillis(),
    resolvedModuleLayouts: ResolvedModuleLayouts = ResolvedModuleLayouts(
      moduleLayoutsId = ModuleLayouts.ModuleLayoutsId.random(),
      moduleLayouts = listOf(
        ResolvedModuleLayout.createDefault(
          moduleType = moduleType,
          roof = ResolvedRoof.createDefault(roofType = roofType),
        ),
      )
    ),
    blackModules: Boolean = false,
    dachmasseAbgelegt: Boolean = false,
    roofAnnotations: List<ConfigurationAnnotation<Roof.RoofId>> = emptyList(),
    roofsAnnotation: String? = null,
    powerUsageScenario: PowerUsageScenario = PowerUsageScenario.typical(),
    manualPowerConsumptionDistribution: ManualPowerConsumptionDistribution? = null,
    pricesTrendScenario: PricesTrendScenario = PricesTrendScenario.typical(),
    profitabilityAnnotation: String? = null,
    facilityConfiguration: BlueprintFacilityConfiguration = BlueprintFacilityConfiguration.getEmpty(),
    battery: ConfiguredOptionality = ConfiguredOptionality.NotSelected,
    assemblyConfiguration: ResolvedAssemblyConfiguration = ResolvedAssemblyConfiguration.getEmpty(assemblyDifficulty),
    facilityAnnotation: String? = null,
    wallboxConfiguration: WallboxConfiguration = WallboxConfiguration.getEmpty(),
    wallboxAnnotation: String? = null,
    zaehlerschrankSchutzisoliert: Boolean = false,
    electricityWorkConfiguration: ResolvedElectricityWorkConfiguration = ResolvedElectricityWorkConfiguration.getEmpty(),
    electricityWorkAnnotation: String? = null,
    additionalPositions: ConfigurationItemsConfiguration = ConfigurationItemsConfiguration.getEmpty(),
    existingFacilitiesConfiguration: ExistingFacilitiesConfiguration = ExistingFacilitiesConfiguration.getEmpty(),
    legalNoticeAdditionalLine: String? = null,
    legalNotes: List<LegalNote> = emptyList(),
    discountPercentage: Double = 0.0,
    additionalAnnotation: String? = null,
    annotation: String? = null,
    zaehlerNummer: String = "",
    flurstueckNummer: String = "",
    facilityOperator1: FacilityOperatorInformation = FacilityOperatorInformation(),
    facilityOperator2: FacilityOperatorInformation = FacilityOperatorInformation(),
    einspeiseart: Einspeiseart = Einspeiseart.Ueberschuss,
    lagePlanAngefragt: Boolean = false,
    imagesDach: Boolean = false,
    imagesGeruestflaechen: Boolean = false,
    imagesKeller: Boolean = false,
    imagesZaehlerschrank: Boolean = false,
    imagesErdung: Boolean = false,
    neubau: Boolean = false,
    processState: PositionEditHistory<OLDProcessState> = PositionEditHistory(OLDProcessState.ProjectScheduled, loggedInUser, creationTime),
  ): ResolvedBlueprint {


    return ResolvedBlueprint(
      blueprintId = Blueprint.BlueprintId.random(),
      projectId = projectId,
      editorInformation = editor,
      sellingCompanyInformation = sellingCompany,
      location = location,
      shippingDistanceManual = shippingDistanceManual,
      shippingDistanceCalculated = shippingDistanceCalculated,
      moduleLayouts = resolvedModuleLayouts,
      blackModules = blackModules,
      dachmasseAbgelegt = dachmasseAbgelegt,
      roofAnnotations = roofAnnotations,
      roofsAnnotation = roofsAnnotation,
      powerUsageScenario = powerUsageScenario,
      manualPowerConsumptionDistribution = manualPowerConsumptionDistribution,
      pricesTrendScenario = pricesTrendScenario,
      profitabilityAnnotation = profitabilityAnnotation,
      facilityConfiguration = facilityConfiguration,
      battery = battery,
      assemblyConfiguration = assemblyConfiguration,
      facilityAnnotation = facilityAnnotation,
      wallboxConfiguration = wallboxConfiguration,
      wallboxAnnotation = wallboxAnnotation,
      zaehlerschrankSchutzisoliert = zaehlerschrankSchutzisoliert,
      electricityWorkConfiguration = electricityWorkConfiguration,
      electricityWorkAnnotation = electricityWorkAnnotation,
      additionalPositions = additionalPositions,
      existingFacilitiesConfiguration = existingFacilitiesConfiguration,
      legalNoticeAdditionalLine = legalNoticeAdditionalLine,
      legalNotes = legalNotes,
      discountPercentage = discountPercentage,
      additionalAnnotation = additionalAnnotation,
      annotation = annotation,
      zaehlerNummer = zaehlerNummer,
      flurstueckNummer = flurstueckNummer,
      facilityOperator1 = facilityOperator1,
      facilityOperator2 = facilityOperator2,
      einspeiseart = einspeiseart,
      lagePlanAngefragt = lagePlanAngefragt,
      imagesDach = imagesDach,
      imagesGeruestflaechen = imagesGeruestflaechen,
      imagesKeller = imagesKeller,
      imagesZaehlerschrank = imagesZaehlerschrank,
      imagesErdung = imagesErdung,
      neubau = neubau,
      processState = processState,
      acquisitionDate = acquisitionDate,
    )
  }

}
