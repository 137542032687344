package it.neckar.react.common.error

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.logging.debug
import it.neckar.react.common.FontAwesome.faBug
import react.*
import react.dom.*

private val logger: Logger = LoggerFactory.getLogger("it.neckar.react.common.error.FallbackErrorPage")


/**
 * Temporary page that is shown while the essentials are loaded
 */
val FallbackErrorPage: FC<FallbackOnErrorProps> = fc("FallbackErrorPage") { props ->
  logger.debug { "Rendering FallbackErrorPage" }

  val contactEmail: String = props::contactEmail.safeGet()
  val contactPhoneNumber: String = props::contactPhoneNumber.safeGet()
  val throwable: Throwable? = props::throwable.safeGet()
  val logoutAction: (() -> Unit)? = props::logoutAction.safeGet()

  logger.debug { "Throwable: $throwable" }
  logger.debug { contactEmail }
  logger.debug { contactPhoneNumber }
  logger.debug { "logoutAction: $logoutAction" }

  /**
   * no safeGet because of Interface
   */
  val errorInfo = props.errorInfo

  div {
    main {
      div("container p-4") {

        ErrorPageHeader {
          attrs {
            this.logoutAction = logoutAction
          }
        }

        div {
          h3 {
            span("pe-2") { faBug() }
            +"Ein unerwarteter Fehler ist aufgetreten"
            span("ps-2") { faBug() }
          }

          throwable?.let {
            h4 {
              +(it.message ?: "Unbekannter Fehler")
            }

            div(classes = "pt-3") {
              p {
                +"Es ist ein unbekannter Fehler aufgetreten. Diesen Fehler bitte melden:"

                a(classes = "ps-2") {
                  i(classes = "fa-solid fa-envelope") {}
                  attrs {
                    href = "mailto:$contactEmail"
                  }
                }
              }

              p {
                +"Telefonischer Support: $contactPhoneNumber"
              }

              details {
                pre() {
                  +it.stackTraceToString()
                }
              }
            }

          } ?: p {
            +"Keine Fehlerinformationen verfügbar"
          }

          errorInfo?.let {
            h4 {
              +"Component Stack:"
            }
            pre {
              +it.componentStack
            }
          }
        }
      }
    }
  }
}


external interface FallbackOnErrorProps : Props {
  var throwable: Throwable?
  var errorInfo: ErrorInfo?

  var contactEmail: String
  var contactPhoneNumber: String

  var logoutAction: (() -> Unit)?
}
