package store.actions

import com.benasher44.uuid.Uuid
import it.neckar.common.redux.ChangeType
import it.neckar.processStates.ProcessStateEntry
import store.PlannerClientState

data class ProcessStateUpdatedAction(val processStateFor: Uuid, val updatedProcessState: ProcessStateEntry) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(
      allProcessStatesForComponents = allProcessStatesForComponents?.withUpdatedProcessState(processStateFor, updatedProcessState),
    )
  }
}
