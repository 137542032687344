package it.neckar.financial.quote

import com.benasher44.uuid.Uuid
import it.neckar.commons.tags.Tag
import it.neckar.commons.tags.Tags
import it.neckar.commons.tags.toTagsOrEmpty
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.uuid.randomUuid4

/**
 * Builder for an item
 */
@QuoteDsl
class QuoteItemBuilder(
  var uuid: Uuid = randomUuid4(),

  /**
   * The (bold) headline
   */
  var headline: String,

  var priceForOneElement: PriceWithProfit,

  /**
   * The amount
   */
  var amount: Amount = Amount(1),

  var relevance: Tags,

  var visibility: Visibility,

  var optionality: Optionality,

  var valueAddedTax: ValueAddedTax,

  var details: String? = null,

  ) : QuoteElementBuilder {

  constructor(
    uuid: Uuid = randomUuid4(),
    headline: String,
    priceForOneElement: PriceWithProfit,
    amount: Amount = Amount(1),
    relevance: Tag,
    visibility: Visibility,
    optionality: Optionality,
    valueAddedTax: ValueAddedTax,
    details: String? = null,
  ) : this(
    uuid = uuid,
    headline = headline,
    priceForOneElement = priceForOneElement,
    amount = amount,
    relevance = relevance.toTagsOrEmpty(),
    visibility = visibility,
    optionality = optionality,
    valueAddedTax = valueAddedTax,
    details = details,
  )

  /**
   * Marks this element as optional
   */
  @QuoteDsl
  fun optional() {
    optionality = Optionality.Optional
  }

  override fun build(): QuoteItem {
    return QuoteItem(
      uuid = uuid,
      headline = headline,
      details = details,
      priceForOneElement = priceForOneElement,
      amount = amount,
      visibility = visibility,
      relevance = relevance,
      optionality = optionality,
      valueAddedTax = valueAddedTax,
    )
  }
}

/**
 * Creates a [QuoteItemBuilder]
 */
fun quoteItem(
  headline: String,
  priceForOneElement: PriceWithProfit,

  amount: Double,

  /**
   * Optional: Only applied if not null
   */
  relevance: Tags,
  /**
   * optional parameter that is only applied if set
   */
  visibility: Visibility,

  optionality: Optionality,

  valueAddedTax: ValueAddedTax,
  /**
   * The configuration
   */
  config: QuoteItemBuilder.() -> Unit = {},
): QuoteItemBuilder {
  return QuoteItemBuilder(
    headline = headline,
    priceForOneElement = priceForOneElement,
    amount = Amount(amount),
    relevance = relevance,
    visibility = visibility,
    optionality = optionality,
    valueAddedTax = valueAddedTax,
  ).also(config)
}

fun quoteItem(headline: String, priceForOneElement: PriceWithProfit, amount: Double, relevance: Tag, visibility: Visibility, optionality: Optionality, valueAddedTax: ValueAddedTax, config: QuoteItemBuilder.() -> Unit = {}): QuoteItemBuilder {
  return quoteItem(headline, priceForOneElement, amount, relevance.toTagsOrEmpty(), visibility, optionality, valueAddedTax, config)
}
