package services.auth.http

import it.neckar.lizergy.model.company.user.UserInformation
import kotlinx.serialization.Serializable

/**
 * The request to add a new user
 */
@Serializable
data class AddNewUserRequest(
  val userInformation: UserInformation,
  val password: String,
) {

  companion object {
    val example: AddNewUserRequest = AddNewUserRequest(
      userInformation = UserInformation.example,
      password = "the-password",
    )
  }
}
