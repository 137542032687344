package it.neckar.lizergy.model.company

import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.CompanyTree
import it.neckar.lizergy.model.company.user.UserInformation

/**
 * Returns company information for a company code
 */
interface CompanyResolver {
  /**
   * Returns the [PlannerCompanyInformation] for the given [CompanyCode].
   * Returns an "unknown company" if no company is found for the given [CompanyCode]
   */
  operator fun get(companyCode: CompanyCode): PlannerCompanyInformation

  /**
   * Returns the [PlannerCompanyInformation] for the given [CompanyProfile].
   *
   * Returns an "unknown company" if no company is found for the given [CompanyProfile]
   */
  operator fun get(companyProfile: CompanyProfile): PlannerCompanyInformation {
    return get(companyProfile.companyCode)
  }
}
