package it.neckar.lizergy.model.company

import it.neckar.customer.Address
import it.neckar.customer.company.BankInformation
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyInformation
import it.neckar.customer.company.CompanyLegalInformation
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.ContactInformation
import it.neckar.customer.company.NeckarITCompanyProfile
import it.neckar.customer.company.TestCompanyProfile
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@SerialName("plannerCompanyInformation")
@Serializable
data class PlannerCompanyInformation(
  override val companyProfile: CompanyProfile,
  override val name: String,
  override val address: Address,
  override val contactInformation: ContactInformation,
  override val bankInformation: BankInformation,
  override val legalInformation: CompanyLegalInformation,

  /**
   * The default user for this company. Will be used as assignee for new tasks/projects.
   * If no default user is configured the first user will be assigned
   */
  val defaultUser: UserLoginName,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : CompanyInformation {

  val companyCode: CompanyCode
    get() = companyProfile.companyCode

  companion object {

    val NeckarIT: PlannerCompanyInformation = PlannerCompanyInformation(
      companyProfile = NeckarITCompanyProfile,
      name = "Neckar IT GmbH",
      address = Address.NeckarIT,
      contactInformation = ContactInformation.NeckarIT,
      bankInformation = BankInformation.NeckarIT,
      legalInformation = CompanyLegalInformation.NeckarIT,
      defaultUser = UserLoginName.NeckarIT,
    )

    /**
     * Returns a company information with default values
     */
    fun createStubCompany(companyCode: CompanyCode): PlannerCompanyInformation {
      return PlannerCompanyInformation(
        companyProfile = TestCompanyProfile(companyCode),
        name = companyCode.value.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() },
        address = Address.empty,
        contactInformation = ContactInformation.empty,
        bankInformation = BankInformation.empty,
        legalInformation = CompanyLegalInformation.empty,
        defaultUser = UserLoginName(companyCode.value),
      )
    }

    /**
     * Returns a company information with default non-empty values
     */
    fun createDemoCompany(companyCode: CompanyCode): PlannerCompanyInformation {
      return PlannerCompanyInformation(
        companyProfile = TestCompanyProfile(companyCode),
        name = companyCode.value.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() },
        address = Address.demo,
        contactInformation = ContactInformation.demo,
        bankInformation = BankInformation.demo,
        legalInformation = CompanyLegalInformation.demo,
        defaultUser = UserLoginName(companyCode.value),
      )
    }

  }
}
