package it.neckar.lizergy.model.configuration.quote.builder

import it.neckar.commons.tags.toTagsOrEmpty
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.financial.quote.Amount
import it.neckar.financial.quote.AmountUnit
import it.neckar.financial.quote.QuoteElements
import it.neckar.financial.quote.QuoteSectionBuilder
import it.neckar.financial.quote.Visibility.Internal
import it.neckar.financial.quote.Visibility.Public
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.configuration.PlannerConfiguration
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedColoredMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedColoredOnlyWhereVisibleMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedNormalMetalRoofTile
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.RoofIsolationPrices
import it.neckar.open.collections.fastForEach
import it.neckar.open.formatting.format
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.km

/**
 * Creates QuotePositions
 */
class LizergyQuotePositionsBuilder {
  val section1Photovoltaik: QuoteSectionBuilder = QuoteSectionBuilder("Photovoltaikanlage", Public)

  val section11KomplettAnlage: QuoteSectionBuilder = QuoteSectionBuilder("Komplettanlage inklusive Montage", Public).also {
    section1Photovoltaik.add(it)
  }

  val section2Battery: QuoteSectionBuilder = QuoteSectionBuilder("Batteriesystem der neuesten Generation", Public)

  val section3Additional: QuoteSectionBuilder = QuoteSectionBuilder("Zusätzliche Arbeiten", Public)

  private val builders: MutableList<QuoteSectionBuilder> = mutableListOf(
    section1Photovoltaik,
    section2Battery,
    section3Additional,
  )


  /**
   * Creates the quote
   */
  fun build(discount: @pct Double): QuoteElements {
    return QuoteElements(builders.map {
      it.build()
    }, discount)
  }

  fun buildQuoteElementsForConfiguration(configuration: PlannerConfiguration, totalDistanceTravelledForAssembly: @km Int, priceList: PriceList, sellingCompanyInformation: PlannerCompanyInformation, monitoringCompanyInformation: PlannerCompanyInformation): QuoteElements {

    with(configuration) {

      val sellingCompanyName = sellingCompanyInformation.simpleName
      val monitoringCompanyName = monitoringCompanyInformation.simpleName

      val inverterSelection = facilityConfiguration.inverterSelection
      val batteryConfiguration = facilityConfiguration.batteryConfiguration

      val toAndBackTravelDistance = shippingDistance?.times(2.0) ?: 0.0

      //
      // Details for position 1
      //
      section11KomplettAnlage.details = buildString {
        modulesReport.countByModules.groupBy { moduleType -> moduleType.moduleType.warranty }.forEach { (warranty, entries) ->
          appendLine(
            entries.joinToString(", ") { (moduleType, count) ->
              "$count monokristalline Module ${moduleType.description}"
            }
          )

          if (entries.isNotEmpty()) {
            appendLine("mit je $warranty Jahren Herstellergarantie garantieren beste Verarbeitung und hohe Belastbarkeit.")
          }
        }

        when {
          inverterSelection.entriesNonEmptyAmount == 1 -> {
            appendLine("Mit dem Wechselrichter ${inverterSelection.entriesNonEmpty.first().element.description} des Marktführers SMA steht $sellingCompanyName für bedingungslose Qualität und hohen Ertrag.")
          }

          inverterSelection.entriesNonEmptyAmount > 1 -> {
            append("Mit den Wechselrichtern ")

            append(inverterSelection.entriesNonEmpty.joinToString(", ") { (inverterType, amount) ->
              when (amount) {
                1 -> {
                  inverterType.description
                }

                else -> {
                  "${amount}× ${inverterType.description}"
                }
              }
            })

            appendLine(" des Marktführers SMA steht $sellingCompanyName für bedingungslose Qualität und hohen Ertrag.")
          }
        }

        appendLine("Für das Untergestell verwenden wir namhafte deutsche Hersteller.")

        if (numberOfModulesWithSchwarzeKlemmen > 0) {
          appendLine("$numberOfModulesWithSchwarzeKlemmen Module werden mit schwarzen Klemmen montiert.")
        }

        if (numberOfModulesKreuzverbund > 0) {
          appendLine("$numberOfModulesKreuzverbund Module werden im Kreuzverbund montiert.")
        }

        if (facilityConfiguration.numberOfOptimizers > 0) {
          appendLine("Die Anlage enthält ${facilityConfiguration.numberOfOptimizers} Moduloptimierer.")
        }

        appendLine("$sellingCompanyName Qualität heißt: Top Produkte, lange Garantiezeiten, Planung und Installation aus einer Hand und von Fachkräften ausgeführt.")
      }

      // Add the modules to section 1
      modulesReport.countByModules.forEach { (moduleType, count) ->
        section11KomplettAnlage {
          item(
            headline = moduleType.description,
            priceForOneElement = priceList[moduleType],
            amount = count.toDouble(),
            relevance = LizergyCalculationCategories.EconomicsCalculation,
            visibility = Internal,
            valueAddedTax = ValueAddedTax.zero,
          )
        }
      }

      if (inverterSelection.isNotEmpty()) {
        section11KomplettAnlage {
          section(headline = "Wechselrichter", visibility = Internal) {
            inverterSelection.entries.forEach { (inverterType, amount) ->
              item(
                headline = inverterType.description,
                priceForOneElement = priceList[inverterType],
                amount = amount.toDouble(),
                relevance = LizergyCalculationCategories.EconomicsCalculation,
                visibility = Internal,
                valueAddedTax = ValueAddedTax.zero,
              )
            }
          }
        }
      }

      moduleLayouts.validElements.forEach { moduleLayout ->
        val roof = moduleLayout.roof
        val roofType = roof.roofType
        val modulesForThisRoof = moduleLayout.modulesCount

        //Create a section for each roof
        section11KomplettAnlage {
          section(headline = roof.roofName, visibility = Internal) {
            // Aufschlag für Dachtypen
            item(
              headline = "${roof.roofName}: Aufschlag ${roofType.description}",
              priceForOneElement = priceList.assemblyCostPerModule(roofType),
              amount = modulesForThisRoof.toDouble(),
              relevance = LizergyCalculationCategories.EconomicsCalculation,
              visibility = Internal,
              valueAddedTax = ValueAddedTax.zero,
            )

            if (roof.verstaerktesSchienensystem) {
              item(
                headline = "${roof.roofName}: Aufpreis verstärktes Schienensystem",
                priceForOneElement = priceList.aufpreisSchienensystemPrice,
                amount = modulesForThisRoof.toDouble(),
                relevance = LizergyCalculationCategories.EconomicsCalculation,
                visibility = Internal,
                valueAddedTax = ValueAddedTax.zero,
              )
            }

            if (roof.schwarzeKlemmen) {
              item(
                headline = "${roof.roofName}: Aufpreis schwarze Klemmen",
                priceForOneElement = priceList.aufpreisSchwarzeKlemmePrice,
                amount = modulesForThisRoof.toDouble(),
                relevance = LizergyCalculationCategories.EconomicsCalculation,
                visibility = Internal,
                valueAddedTax = ValueAddedTax.zero,
              )
            }

            roof.isolationThickness?.let {
              if (it > 0) {
                item(
                  headline = "${roof.roofName}: Aufschlag Aufdachdämmung ${it.format()} cm [Preiskategorie ${
                    RoofIsolationPrices.thicknessCategory(
                      it
                    )
                  }]",
                  priceForOneElement = priceList.roofIsolationCostPerModule(it),
                  amount = modulesForThisRoof.toDouble(),
                  relevance = LizergyCalculationCategories.EconomicsCalculation,
                  visibility = Internal,
                  valueAddedTax = ValueAddedTax.zero,
                )
              }
            }

          }
        }
      }

      // Initial setup costs
      section11KomplettAnlage {
        item(
          headline = "Baustelleneinrichtung",
          priceForOneElement = priceList.sitePreparation,
          relevance = LizergyCalculationCategories.EconomicsCalculation,
          visibility = Internal,
          valueAddedTax = ValueAddedTax.zero,
        )
      }


      // Fahrtkosten
      /**
       * Add the assembly travel costs
       */
      section11KomplettAnlage {
        item(
          headline = "Fahrtkosten Montage (km)",
          priceForOneElement = priceList.perKilometerTravelled,
          amount = totalDistanceTravelledForAssembly.toDouble(),
          relevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
          visibility = Internal,
          valueAddedTax = ValueAddedTax.zero,
        ) {
          amount = Amount(totalDistanceTravelledForAssembly, AmountUnit.KiloMeters)
        }
      }

      /**
       * The electrician always needs to drive at least once to and back
       */
      section11KomplettAnlage {
        item(
          headline = "Fahrtkosten Elektriker (km)",
          priceForOneElement = priceList.perKilometerTravelled,
          amount = toAndBackTravelDistance,
          relevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
          visibility = Internal,
          valueAddedTax = ValueAddedTax.zero,
        ) {
          amount = Amount(toAndBackTravelDistance, AmountUnit.KiloMeters)
        }
      }

      // Adds the optimizers
      if (facilityConfiguration.numberOfOptimizers > 0) {
        section11KomplettAnlage {
          itemWithWork(
            headline = "Moduloptimierer",
            priceForMaterialAndWork = priceList.optimizerPrice,
            amount = facilityConfiguration.numberOfOptimizers,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
            visibility = Internal,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          )
        }
      }

      if (facilityConfiguration.kabelwegZugschlagLength > 0) {
        section11KomplettAnlage {
          itemWithWork(
            headline = "Zuschlag Kabelweg (m)",
            priceForMaterialAndWork = priceList.zuschlagKabelweg,
            amount = facilityConfiguration.kabelwegZugschlagLength,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation.toTagsOrEmpty(),
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
            visibility = Internal,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          )
        }
      }

      // Aufschlag Kreuzverbund
      numberOfModulesKreuzverbund.takeIf { it > 0 }?.let { numberOfModules ->
        if (numberOfModules > 0) {
          section11KomplettAnlage {
            itemWithWork(
              headline = "Aufschlag Kreuzverbund",
              priceForMaterialAndWork = priceList.aufschlagKreuzverbund,
              amount = numberOfModules,
              materialRelevance = LizergyCalculationCategories.EconomicsCalculation,
              workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
              visibility = Internal,
              materialValueAddedTax = ValueAddedTax.zero,
              workValueAddedTax = ValueAddedTax.zero,
            )
          }
        }
      }

      val assemblyDifficulty = assemblyConfiguration.assemblyDifficulty
      section11KomplettAnlage {
        item(
          headline = "Montage (inklusive Unterkonstruktion) ${assemblyDifficulty.description}",
          priceForOneElement = priceList[assemblyDifficulty],
          amount = totalNumberOfModules.toDouble(),
          relevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
          visibility = Internal,
          valueAddedTax = ValueAddedTax.zero,
        )
      }

      val facilityWorkEffort = facilityConfiguration.facilityWorkEffort
      section11KomplettAnlage {
        item(
          headline = facilityWorkEffort.headline,
          priceForOneElement = facilityWorkEffort.priceForOneElement,
          amount = facilityWorkEffort.amount,
          relevance = facilityWorkEffort.relevance,
          visibility = Internal,
          valueAddedTax = facilityWorkEffort.valueAddedTax,
        ) {
          details = facilityWorkEffort.details
        }
      }


      assemblyConfiguration.assemblyStatus.let {

        val assemblyPrice = priceList[assemblyDifficulty] * totalNumberOfModules
        val assemblyTravelPrice = priceList.perKilometerTravelled * totalDistanceTravelledForAssembly
        val priceToBeDiscounted = assemblyPrice + assemblyTravelPrice

        if (it.dachhakenSetzen == true) {
          section1Photovoltaik {
            item(
              headline = "Rabatt Unterkonstruktion-Selbstmontage",
              priceForOneElement = -priceToBeDiscounted * priceList.optionalDiscountPrices.dachhakenSetzenDiscount,
              relevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
              visibility = Public,
              valueAddedTax = ValueAddedTax.zero,
            )
          }
        }

        if (it.schienenMontage == true) {
          section1Photovoltaik {
            item(
              headline = "Rabatt Schienen-Selbstmontage",
              priceForOneElement = -priceToBeDiscounted * priceList.optionalDiscountPrices.schienenMontageDiscount,
              relevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
              visibility = Public,
              valueAddedTax = ValueAddedTax.zero,
            )
          }
        }

        if (it.kabelZiehenUndModuleMontieren == true) {
          section1Photovoltaik {
            item(
              headline = "Rabatt Module Montieren-Selbstmontage",
              priceForOneElement = -priceToBeDiscounted * priceList.optionalDiscountPrices.kabelZiehenUndModuleMontierenDiscount,
              relevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
              visibility = Public,
              valueAddedTax = ValueAddedTax.zero,
            )
          }
        }

        if (it.dachhakenGestellt == true) {
          section1Photovoltaik {
            section(
              headline = "Rabatt Unterkonstruktion (ohne Schienen) wird gestellt",
              visibility = Public,
            ) {

              item(
                headline = "Rabatt Montage- & Fahrtkosten",
                priceForOneElement = -priceToBeDiscounted * priceList.optionalDiscountPrices.dachhakenGestelltDiscount,
                relevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
                visibility = Internal,
                valueAddedTax = ValueAddedTax.zero,
              )

              val priceForRoofsToBeDiscounted = moduleLayouts.totalPriceForZiegelUndBiberschwanzDach(priceList) * priceList.optionalDiscountPrices.dachhakenGestelltRoofDiscount
              item(
                headline = "Rabatt Dachtyp",
                priceForOneElement = -priceForRoofsToBeDiscounted,
                relevance = LizergyCalculationCategories.EconomicsCalculation,
                visibility = Internal,
                valueAddedTax = ValueAddedTax.zero,
              )

            }

          }
        }

      }


      // Additional Positions under 1
      // 1.x Metal Roof Tiles
      val metalRoofTileEntries = metalTilesCalculator.getEntries()
      if (metalRoofTileEntries.isNotEmpty()) {
        section1Photovoltaik {
          section(
            headline = "Metalldachplatten",
            visibility = Public,
          ) {
            metalRoofTileEntries.forEach { it ->
              val entry = it.value
              val metalRoofTileType = entry.matchingMetalTile

              section(
                headline = "${metalRoofTileType.description} für ${it.key.roofName}, ${it.key.roofType.description}",
                visibility = Public,
              ) {
                this.details = "Metallziegel für ${entry.totalModulesCount} Module"

                val coloredTilesAmount = when (val metalRoofTileConstructionType = entry.metalRoofTileConstructionType) {
                  null, ResolvedNormalMetalRoofTile -> 0.0
                  is ResolvedColoredMetalRoofTile -> entry.amount
                  is ResolvedColoredOnlyWhereVisibleMetalRoofTile -> metalRoofTileConstructionType.amount.toDouble()
                }
                val normalTilesAmount = entry.amount - coloredTilesAmount

                when (val metalRoofTileConstructionType = entry.metalRoofTileConstructionType) {

                  null -> {}

                  ResolvedNormalMetalRoofTile -> {
                    item(
                      headline = entry.descriptionWithoutColor,
                      priceForOneElement = priceList[metalRoofTileType],
                      amount = normalTilesAmount,
                      relevance = LizergyCalculationCategories.EconomicsCalculation,
                      visibility = Public,
                      valueAddedTax = ValueAddedTax.zero,
                    )
                  }

                  is ResolvedColoredMetalRoofTile -> {
                    item(
                      headline = entry.descriptionWithColor,
                      priceForOneElement = priceList[metalRoofTileType] + priceList.aufschlagColoredMetalPlatesPrices[metalRoofTileConstructionType.color],
                      amount = coloredTilesAmount,
                      relevance = LizergyCalculationCategories.EconomicsCalculation,
                      visibility = Public,
                      valueAddedTax = ValueAddedTax.zero,
                    )
                  }

                  is ResolvedColoredOnlyWhereVisibleMetalRoofTile -> {
                    item(
                      headline = entry.descriptionWithoutColor,
                      priceForOneElement = priceList[metalRoofTileType],
                      amount = normalTilesAmount,
                      relevance = LizergyCalculationCategories.EconomicsCalculation,
                      visibility = Public,
                      valueAddedTax = ValueAddedTax.zero,
                    )

                    item(
                      headline = "${entry.descriptionWithColor} im Sichtbereich",
                      priceForOneElement = priceList[metalRoofTileType] + priceList.aufschlagColoredMetalPlatesPrices[metalRoofTileConstructionType.color],
                      amount = coloredTilesAmount,
                      relevance = LizergyCalculationCategories.EconomicsCalculation,
                      visibility = Public,
                      valueAddedTax = ValueAddedTax.zero,
                    )
                  }

                }

              }

            }
          }
        }
      }

      // 1.x Gerüst
      section1Photovoltaik {
        section(
          headline = "Gerüst / Absturzsicherung",
          visibility = Public,
        ) {
          scaffoldingAreas().forEach {
            val moduleLayout = it.key?.let { moduleLayoutId -> moduleLayouts[moduleLayoutId] }

            itemWithWork(
              headline = buildString {
                append("Gerüst")
                moduleLayout?.let { append(" ${moduleLayout.layoutName}") }
              },
              priceForMaterialAndWork = priceList.scaffolding,
              amount = it.value,
              materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Scaffolding,
              workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Scaffolding,
              visibility = Internal,
              materialValueAddedTax = ValueAddedTax.zero,
              workValueAddedTax = ValueAddedTax.zero,
            )
          }
        }
      }

      // 1.x Dachständer-Isolierung
      if (assemblyConfiguration.dachstaenderIsolierung) {
        section1Photovoltaik {
          itemWithWork(
            headline = "Dachständer-Isolierung",
            priceForMaterialAndWork = priceList.dachStaenderIsolierung,
            visibility = Public,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          ) {
            details = "wenn von $sellingCompanyName beauftragt"
          }
        }
      }

      // 1.x Überspannungsschutz DC
      facilityConfiguration.numberUeberspannungsSchutz?.let { numberUeberspannungsSchutz ->
        if (numberUeberspannungsSchutz > 0) {
          section1Photovoltaik {
            itemWithWork(
              headline = configuration.ueberspannungsSchutzType,
              priceForMaterialAndWork = if (assemblyConfiguration.externerBlitzschutzVorhanden) priceList.ueberSpannungsSchutzaWennExternerBlitzschutzVorhanden else priceList.ueberSpannungsSchutz,
              amount = numberUeberspannungsSchutz,
              materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityMaterial,
              workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
              visibility = Public,
              materialValueAddedTax = ValueAddedTax.zero,
              workValueAddedTax = ValueAddedTax.zero,
            )
          }
        }
      }

      // 1.x Satellitenantenne ummontieren
      if (assemblyConfiguration.moveSatelliteDish) {
        section1Photovoltaik {
          itemWithWork(
            headline = "Satellitenantenne ummontieren",
            priceForMaterialAndWork = priceList.moveSatelliteDish,
            visibility = Public,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
            materialValueAddedTax = ValueAddedTax.nineteenPercent,
            workValueAddedTax = ValueAddedTax.nineteenPercent,
          )
        }
      }

      // 1.x Antenne entfernen
      if (assemblyConfiguration.removeAntenna) {
        section1Photovoltaik {
          itemWithWork(
            headline = "Antenne entfernen",
            priceForMaterialAndWork = priceList.removeAntenna,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.Assembly,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.nineteenPercent,
            workValueAddedTax = ValueAddedTax.nineteenPercent,
          )
        }
      }

      // 1.x Elektrische Inbetriebnahme der Photovoltaikanlage
      section1Photovoltaik {
        itemWithWork(
          headline = "Elektrische Inbetriebnahme der Photovoltaikanlage",
          priceForMaterialAndWork = priceList.startingUpPrice,
          materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityMaterial,
          workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
          visibility = Public,
          materialValueAddedTax = ValueAddedTax.zero,
          workValueAddedTax = ValueAddedTax.zero,
        ) {
          details =
            "Elektrische Inbetriebnahme, Verlegung der Leitungen, Erdung der PV-Anlage, sowie Installation und Inbetriebnahme des Wechselrichters durch einen Elektromeister der $sellingCompanyName GmbH, Anmeldung beim Netzbetreiber und technische Detailklärung werden bei uns von einem Ingenieur durchgeführt. Wir gehen davon aus, dass der Zählerschrank die Leistung aufnehmen kann und genügend Platz für die notwendige Elektrik bietet."
        }
      }

      // 1.x Integration PV Anlage in äußeren Blitzschutz
      if (assemblyConfiguration.externerBlitzschutzVorhanden) {
        section1Photovoltaik {
          item(
            headline = "Integration PV Anlage in äußeren Blitzschutz",
            priceForOneElement = priceList.blitzschutzIntegrieren,
            relevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityMaterial,
            visibility = Public,
            valueAddedTax = ValueAddedTax.zero,
          )
        }
      }

      // 1.x Online-Überwachung PV-Anlage
      priceList.monitoringPrice(totalPowerRating, batteryConfiguration != null).let { monitoringPrice ->
        section1Photovoltaik {
          optionalItem(
            configuredOptionality = facilityConfiguration.onlineMonitoring,
            headline = "Wartungsvertrag PV-Anlage",
            priceForOneElement = monitoringPrice,
            relevance = LizergyCalculationCategories.EconomicsCalculation,
            visibility = Public,
            valueAddedTax = ValueAddedTax.nineteenPercent,
          ) {
            details = buildString {
              appendLine("Anlagenüberwachung durch die Firma $monitoringCompanyName für die Laufzeit eines Jahres.")
              appendLine("Nach Fertigstellung der Anlage übersenden wir Ihnen einen Wartungsvertrag. Für die Verlängerung kann dieser unterschrieben zurückgesendet werden.")
              appendLine("Inhalt des Wartungsvertrags:")
              appendLine("Ihre PV-Anlage wird regelmäßig online überwacht. Wir prüfen die Anlageperformance auf Störungen und Fehler.")
            }
          }
        }
      }


      // 2 Batterie
      // 2.1 Batterie
      batteryConfiguration?.let { batteryConfiguration ->
        section2Battery {
          itemWithWork(
            headline = "Batteriekomplettsystem mit ${batteryConfiguration.totalStorage.format(CurrentI18nConfiguration)} Speicherkapazität",
            priceForMaterialAndWork = priceList[batteryConfiguration],
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.BatteryStorage,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          ) {

            details = buildString {
              appendLine("- ${batteryConfiguration.battery.description}")
              batteryConfiguration.extension?.let { appendLine("- ${it.description}") }
              appendLine("- ${batteryConfiguration.inverterType.description}")
              appendLine("- Sunny Home Manager 2.0 Energiemanagementsystem")
              appendLine("- Inklusive Kleinteile und Montage")
            }

            /**
             * The electrician might need to drive and additional time if the system has a battery installation
             */
            item(
              headline = "Fahrtkosten Elektriker Batterieinbetriebnahme (km)",
              priceForOneElement = priceList.perKilometerTravelled,
              amount = toAndBackTravelDistance,
              relevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
              visibility = Internal,
              valueAddedTax = ValueAddedTax.zero,
            ) {
              amount = Amount(toAndBackTravelDistance, AmountUnit.KiloMeters)
            }

          }
        }
      }


      // 3 Zusätzliche Leistungen
      section3Additional {
        optionalItemWithWork(
          configuredOptionality = facilityConfiguration.integrateInverterIntoNetwork,
          headline = "Integration Wechselrichter in Hausnetzwerk",
          priceForMaterialAndWork = priceList.integrateInverterIntoNetwork,
          materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityMaterial,
          workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
          visibility = Public,
          materialValueAddedTax = ValueAddedTax.zero,
          workValueAddedTax = ValueAddedTax.zero,
        )
      }

      if (electricityWorkConfiguration.sunnyHomeManager(
          selectedBattery = batteryConfiguration,
          waermepumpeSelected = facilityConfiguration.waermepumpenanbindung,
          intelligentWallboxSelected = wallboxSelection.isSmartWallBoxSelected,
          heizstabSelected = facilityConfiguration.heaterRod != null,
        )
      ) {
        section3Additional {
          itemWithWork(
            headline = "Sunny Home Manager 2.0 Energiemanagementsystem",
            priceForMaterialAndWork = priceList.sunnyHomeManager,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityMaterial,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          ) {
            details = """
                  Energiemanagementsystem:
                  bspw. notwendig für Einbindung von
                  - PV-optimierter E-Ladestation
                  - Speicher
                  - Heizstab PV-optimiert betreiben
                  - Wärmepumpen PV-optimiert betrieben
                  - weitere Verbraucher intelligent ansteuern
                """.trimIndent()
          }
        }
      }

      facilityConfiguration.heaterRod?.let {
        section3Additional {
          itemWithWork(
            headline = it.description,
            priceForMaterialAndWork = priceList[it],
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityMaterial,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.nineteenPercent,
            workValueAddedTax = ValueAddedTax.nineteenPercent,
          ) {
            details = """
                  stufenlos Überschussstrom als Wärme nutzen.
                  Prüfung der mechanischen Passung mit und Einbau in Warmwasserspeicher erfolgt durch einen bauseits organisierten Sanitär. Kosten für Sanitärbetrieb sind nicht enthalten.
                  Datenblatt kann im Bedarfsfall zur Verfügung gestellt werden. Nur in Kombination mit SHM 2.0 möglich.
                """.trimIndent()
          }
        }
      }

      if (facilityConfiguration.waermepumpenanbindung) {
        section3Additional {
          itemWithWork(
            headline = "Wärmepumpenanbindung",
            priceForMaterialAndWork = priceList.waermepumpenanbindung,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityMaterial,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityMaterial,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.nineteenPercent,
            workValueAddedTax = ValueAddedTax.nineteenPercent,
          ) {
            details = """
                  Die Wärmepumpe muss mindestens über den SG-Ready Standard verfügen. Idealerweise wird eine zu SMA kompatible Wärmepumpe gewählt. Je nach Kompatibilität kann die Anbindung dann auch günstiger realisiert werden.
                  Der mögliche Eigenverbrauchsoptimierungsgrad hängt ebenfalls entscheidend davon ab. Die Kompatibilitätsliste kann auf Anfrage ausgehändigt werden. Nur in Kombination mit einem Sunny Home Manager 2.0 möglich. Für die Umsetzung ist ein bauseits organisierter Termin mit dem zuständigen Sanitätsbetrieb notwendig. Die Kosten für den Sanitärbetrieb sind nicht enthalten.
                """.trimIndent()
          }
        }
      }

      electricityWorkConfiguration.independenceManager?.let { independenceManagerType ->
        section3Additional {
          itemWithWork(
            headline = independenceManagerType.description,
            priceForMaterialAndWork = priceList.independenceManagerTypePrices[independenceManagerType],
            materialRelevance = LizergyCalculationCategories.ElectricityMaterial,
            workRelevance = LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          ) {
            details = independenceManagerType.details
          }
        }
      }

      if (electricityWorkConfiguration.smaEnergyMeter) {
        section3Additional {
          itemWithWork(
            headline = "SMA Energy Meter",
            priceForMaterialAndWork = priceList.smaEnergyMeter,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityMaterial,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          )
        }
      }

      if (facilityConfiguration.erdspiessSetzen) {
        section3Additional {
          itemWithWork(
            headline = "Erdspieß setzen",
            priceForMaterialAndWork = priceList.erdspiessSetzen,
            materialRelevance = LizergyCalculationCategories.ElectricityWork,
            workRelevance = LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          )
        }
      }

      if (electricityWorkConfiguration.slsNachruesten) {
        section3Additional {
          itemWithWork(
            headline = "SLS nachrüsten",
            priceForMaterialAndWork = priceList.slsNachruesten,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          )
        }
      }

      if (electricityWorkConfiguration.neuerZaehlerschrank) {
        section3Additional {
          itemWithWork(
            headline = "Neuer Zählerschrank EFH",
            priceForMaterialAndWork = priceList.neuerZaehlerschrank,
            materialRelevance = LizergyCalculationCategories.ElectricityWork,
            workRelevance = LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          ) {
            details = "Grober Schätzpreis, Abrechnung nach tatsächlichem Aufwand"
          }
        }
      }

      if (electricityWorkConfiguration.zusammenZuLegendeZaehlerNummern.isNotEmpty()) {
        section3Additional {
          itemWithWork(
            headline = "Zähler zusammenlegen",
            priceForMaterialAndWork = priceList.zaehlerschrankZusammenlegen,
            amount = electricityWorkConfiguration.zusammenZuLegendeZaehlerNummern.size,
            materialRelevance = LizergyCalculationCategories.ElectricityWork,
            workRelevance = LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.nineteenPercent,
            workValueAddedTax = ValueAddedTax.nineteenPercent,
          )
        }
      }

      if (electricityWorkConfiguration.einbauUnterverteiler) {
        section3Additional {
          itemWithWork(
            headline = "Einbau Unterverteiler",
            priceForMaterialAndWork = priceList.einbauUnterverteiler,
            materialRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
            workRelevance = LizergyCalculationCategories.EconomicsCalculation + LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.zero,
            workValueAddedTax = ValueAddedTax.zero,
          )
        }
      }

      if (electricityWorkConfiguration.einbauDigitalerZaehler > 0) {
        section3Additional {
          itemWithWork(
            headline = "Einbau digitaler Zähler",
            priceForMaterialAndWork = priceList.einbauDigitalerZaehlerPrice,
            amount = electricityWorkConfiguration.einbauDigitalerZaehler,
            materialRelevance = LizergyCalculationCategories.ElectricityWork,
            workRelevance = LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.nineteenPercent,
            workValueAddedTax = ValueAddedTax.nineteenPercent,
          )
        }
      }

      val electricityWorkEffort = electricityWorkConfiguration.electricityWorkEffort
      section3Additional {
        item(
          headline = electricityWorkEffort.headline,
          priceForOneElement = electricityWorkEffort.priceForOneElement,
          amount = electricityWorkEffort.amount,
          relevance = electricityWorkEffort.relevance,
          visibility = Public,
          valueAddedTax = electricityWorkEffort.valueAddedTax,
        ) {
          details = electricityWorkEffort.details
        }
      }

      wallboxSelection.entries.fastForEach { (wallboxType, amount) ->
        section3Additional {
          item(
            headline = wallboxType.description,
            priceForOneElement = priceList[wallboxType],
            amount = amount.toDouble(),
            relevance = LizergyCalculationCategories.ElectricityMaterial,
            visibility = Public,
            valueAddedTax = ValueAddedTax.nineteenPercent,
          ) {
            details = buildString {
              wallboxType.details?.let {
                appendLine(it)
              }
            }
          }
        }

        section3Additional {
          itemWithWork(
            headline = "${wallboxType.description} Montage",
            amount = amount,
            priceForMaterialAndWork = priceList.wallboxInstallationPrice,
            materialRelevance = LizergyCalculationCategories.ElectricityMaterial,
            workRelevance = LizergyCalculationCategories.ElectricityWork,
            visibility = Public,
            materialValueAddedTax = ValueAddedTax.nineteenPercent,
            workValueAddedTax = ValueAddedTax.nineteenPercent,
          ) {
            details = buildString {
              appendLine("Montage der E-Ladestation erfolgt nach tatsächlichem Aufwand. Je nach Kabelweg kommen noch Kosten für das Kabel und die Absicherung im Zählerschrank hinzu - hierbei muss im Schnitt mit 900€ gerechnet werden - Der Zählerschrank muss den aktuellen Vorschriften entsprechen. An einen nicht den Normen entsprechenden Zählerschrank darf keine Wallbox angeschlossen werden.")

              if (wallboxType.requiresInquiry) {
                appendLine("Achtung Netzvoranfrage ist wichtig, diese kann abgelehnt werden. Lehnt der Netzbetreiber einen Netzanschluss ab, sind wir berechtigt, vom Kaufvertrag zurückzutreten.")
              }
            }
          }
        }
      }

      // Additional position
      additionalPositions.fastForEach { additionalPosition ->
        section3Additional {
          item(
            headline = additionalPosition.headline,
            priceForOneElement = additionalPosition.priceForOneElement,
            amount = additionalPosition.amount,
            relevance = additionalPosition.relevance,
            visibility = Public,
            valueAddedTax = additionalPosition.valueAddedTax,
          ) {
            details = additionalPosition.details
          }
        }
      }


      return build(discountPercentage)
    }
  }
}

