package components.company.pricelist.tables

import com.meistercharts.charts.lizergy.roofPlanning.ModuleOrientation
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.formatting.format
import kotlinx.html.ThScope
import plannerI18nConfiguration
import react.*
import react.dom.*
import store.hooks.useSelectPriceList
import style.toStyleClass

/**
 * Shows the module prices in a table
 */
val ModulesPriceTable: FC<Props> = fc("ModulesPriceTable") {
  val priceList = useSelectPriceList()

  val modulePrices = priceList.modulePrices


  div("col") {
    h2 {
      +"Module"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          th(ThScope.col, "text-end") {
            +"DC Leistung"
          }
          th(ThScope.col, "text-end") {
            +"Leerlaufspannung STC, Uoc"
          }
          th(ThScope.col, "text-end") {
            +"Kurzschlusstom STC, Isc"
          }
          th(ThScope.col, "text-end") {
            +"Modulbreite"
          }
          th(ThScope.col, "text-center") {
            +""
          }
          th(ThScope.col) {
            +"Modullänge"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
          th(ThScope.col, "text-center") {
            +"In Gebrauch"
          }
        }
      }

      tbody {

        modulePrices.available.fastForEachIndexed { pvModuleIndex, pvModule ->
          tr(classes = pvModule.lifeCycleState.toStyleClass()) {
            key = pvModule.uuid.toString()

            th(ThScope.row) {
              +(pvModuleIndex + 2).toString()
            }
            td {
              +pvModule.description
            }

            td("text-end") {
              +pvModule.powerRating.formatWattPeak(plannerI18nConfiguration)
            }
            td("text-end") {
              +pvModule.offLoadVoltage.format(plannerI18nConfiguration)
            }
            td("text-end") {
              +pvModule.shortCircuitCurrent.format(plannerI18nConfiguration)
            }

            td("text-end") {
              +"${pvModule.size.width(ModuleOrientation.Vertical).format(plannerI18nConfiguration)} mm"
            }
            td("text-center") {
              +"x"
            }
            td {
              +"${pvModule.size.height(ModuleOrientation.Vertical).format(plannerI18nConfiguration)} mm"
            }

            td("text-end") {
              +modulePrices[pvModule.uuid].inputPrice.format(plannerI18nConfiguration)
            }
            td("text-end") {
              +modulePrices[pvModule.uuid].sellingPrice.format(plannerI18nConfiguration)
            }
            td("text-end") {
              +modulePrices[pvModule.uuid].profit.format(plannerI18nConfiguration)
            }

            td("text-center") {
              +pvModule.lifeCycleState.format()
            }
          }
        }

      }
    }
  }

}
