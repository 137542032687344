package it.neckar.react.common

import react.*
import react.dom.*

/**
 * Shows a busy indicator
 */
val BusyIndicator: FC<BusyIndicatorProps> = fc("BusyIndicator") {
  i("fa fa-spinner fa-spin my-3") {}
}

val BusyIndicatorSmall: FC<BusyIndicatorProps> = fc("BusyIndicatorSmall") {
  i("fa fa-spinner fa-xs fa-spin my-3") {}
}


external interface BusyIndicatorProps : Props

