package it.neckar.financial.quote

import it.neckar.financial.currency.PriceWithProfit
import it.neckar.open.formatting.CachedNumberFormat
import it.neckar.open.formatting.appendUnit
import it.neckar.open.formatting.cached
import it.neckar.open.formatting.decimalFormat
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.time.formatHourAndMinutes
import kotlinx.serialization.Serializable
import kotlin.time.Duration.Companion.hours

enum class AmountUnit {
  None,
  Hours,
  KiloMeters,
}

@Serializable
data class Amount(
  val amount: Double,
  val unit: AmountUnit = AmountUnit.None,
) {

  constructor(amount: Int, unit: AmountUnit = AmountUnit.None) : this(amount.toDouble(), unit)

  operator fun times(factor: Int): Amount {
    return copy(amount = amount * factor)
  }

  operator fun times(factor: Double): Amount {
    return copy(amount = amount * factor)
  }

  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return when (unit) {
      AmountUnit.None -> decimalFormat.format(amount, i18nConfiguration, whitespaceConfig)
      AmountUnit.Hours -> amount.hours.formatHourAndMinutes(whitespaceConfig)
      AmountUnit.KiloMeters -> kiloMetersFormat.format(amount, i18nConfiguration, whitespaceConfig)
    }
  }

  companion object {
    val kiloMetersFormat: CachedNumberFormat = decimalFormat.appendUnit("km").cached()
  }
}

operator fun PriceWithProfit.times(amount: Amount): PriceWithProfit {
  return this * amount.amount
}

operator fun PriceWithProfit.div(amount: Amount): PriceWithProfit {
  return this / amount.amount
}
