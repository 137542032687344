@file:UseSerializers(UuidSerializer::class)
@file:Suppress("NonAsciiCharacters")

package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType.IndependenceManagerId
import it.neckar.lizergy.model.configuration.quote.builder.BasicBatteryInverter
import it.neckar.lizergy.model.configuration.quote.builder.HybridInverter
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.InverterType
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
sealed interface IndependenceManagerType : HasUuid, HasLifeCycle {
  val id: IndependenceManagerId

  /**
   * The name / label of the [IndependenceManagerType]
   */
  val description: String

  /**
   * The details of the [IndependenceManagerType] to be put into the quote
   */
  val details: String

  /**
   * The required inverter types
   * The list contains sets of inverter types that are required for the independence manager to work.
   * One inverter of each set is required.
   */
  val requiredInverterTypes: List<Set<InverterType>>

  override val uuid: Uuid
    get() = id.uuid

  fun invalidManagerCombination(inverterSelection: ResolvedInverterSelection, batteryInverter: InverterType?): Boolean {
    return validManagerCombination(inverterSelection, batteryInverter).not()
  }

  fun validManagerCombination(inverterSelection: ResolvedInverterSelection, batteryInverter: InverterType?): Boolean {
    return validInverterSelected(inverterSelection) && validBatteryInverter(batteryInverter)
  }

  fun validInverterSelected(inverterSelection: ResolvedInverterSelection): Boolean {
    return requiredInverterTypes.all { requiredInverterTypes ->
      val inverters = requiredInverterTypes.filterIsInstance<Inverter>()
      if (inverters.isEmpty()) {
        return@all true
      }
      inverters.any { requiredInverterType ->
        inverterSelection.entries.any { it.element == requiredInverterType }
      }
    }
  }

  fun invalidInverterSelected(inverterSelection: ResolvedInverterSelection): Boolean {
    return validInverterSelected(inverterSelection).not()
  }

  fun validBatteryInverter(batteryInverter: InverterType?): Boolean {
    require(batteryInverter is BasicBatteryInverter? || batteryInverter is HybridInverter?)
    return requiredInverterTypes.all { requiredInverterTypes ->
      val batteryAndHybridInverters = requiredInverterTypes.filter { it is BasicBatteryInverter || it is HybridInverter }
      if (batteryAndHybridInverters.isEmpty()) {
        return@all true
      }
      batteryAndHybridInverters.any { requiredInverterType -> batteryInverter == requiredInverterType }
    }
  }

  fun invalidBatteryInverter(batteryInverter: InverterType?): Boolean {
    return validBatteryInverter(batteryInverter).not()
  }

  /**
   * An id for a roof
   */
  @Serializable
  data class IndependenceManagerId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): IndependenceManagerId {
        return IndependenceManagerId(randomUuid4())
      }
    }
  }
}

@Serializable
data class LizergyIndependenceManagerType(
  override val id: IndependenceManagerId,
  /**
   * The name / label of the [LizergyIndependenceManagerType]
   */
  override val description: String,
  override val details: String,
  override val requiredInverterTypes: List<Set<InverterType>>,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : IndependenceManagerType

@Serializable
data class OtherIndependenceManagerType(
  override val id: IndependenceManagerId,
  /**
   * The name / label of the [OtherIndependenceManagerType]
   */
  override val description: String,
  override val details: String,
  override val requiredInverterTypes: List<Set<InverterType>>,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : IndependenceManagerType
