package components.project.blueprint

import components.form.commentSection
import components.project.configuration.additional.EditElectricityWorkEffort
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser

val EditBlueprintElectricityWorkEffortFromUrl: FC<Props> = fc("EditBlueprintElectricityWorkEffortFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Zählerschrankarbeiten - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    EditBlueprintElectricityWorkEffort {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val EditBlueprintElectricityWorkEffort: FC<EditBlueprintElectricityWorkEffortProps> = fc("EditBlueprintElectricityWorkEffort") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val blueprint = project::blueprint.safeGet()

  val editableStatus = project.isProjectEditable()

  val zaehlerschrankSchutzisoliertToSave = useState(blueprint.zaehlerschrankSchutzisoliert)
  val electricityWorkConfigurationToSave = useState(blueprint.electricityWorkConfiguration)
  val bleibenderZaehlerToSave = useState(blueprint.zaehlerNummer)

  val okAction = {
    navigate(RouterUrls.blueprint(project).wallBox)
  }


  useMemo(zaehlerschrankSchutzisoliertToSave.value, electricityWorkConfigurationToSave.value, bleibenderZaehlerToSave.value) {
    blueprint.copy(
      zaehlerschrankSchutzisoliert = zaehlerschrankSchutzisoliertToSave.value,
      electricityWorkConfiguration = electricityWorkConfigurationToSave.value,
      zaehlerNummer = bleibenderZaehlerToSave.value,
    ).also {
      UiActions.saveBlueprint(it)
    }
  }


  div("my-4") {
    onEnter { okAction() }

    h1("mb-3") {
      +"Zählerschrankarbeiten"
    }

    checkbox(
      valueAndSetter = zaehlerschrankSchutzisoliertToSave,
      fieldName = "zaehlerschrankSchutzisoliertToSave",
      title = "Zählerschrank schutzisoliert",
    )

    EditElectricityWorkEffort {
      attrs {
        this.electricityWorkConfigurationToSave = electricityWorkConfigurationToSave
        this.bleibenderZaehlerToSave = bleibenderZaehlerToSave
        this.selectedBatteryConfiguration = null
        this.waermepumpeSelected = blueprint.facilityConfiguration.waermepumpenanbindung
        this.intelligentWallboxSelected = false
        this.heizstabSelected = blueprint.facilityConfiguration.heizstab
        this.editableStatus = editableStatus
      }
    }

    div("my-5") {
      h3("mb-3") {
        +"Bemerkungen Zählerschrank"
      }
      project.blueprint.electricityWorkAnnotation?.let {
        p { +it }
      }
      commentSection(blueprint.electricityWorkConfiguration)
    }

    wizardFormButtons(
      backwardAction = { navigate(RouterUrls.blueprint(project).facility) },
      forwardAction = { okAction() },
    )
  }

}


external interface EditBlueprintElectricityWorkEffortProps : Props {
  var project: ResolvedProject
}
