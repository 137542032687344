package router

import components.project.AdvanceInvoicesOverviewFromUrl
import components.project.AssemblyBasementsOverviewFromUrl
import components.project.AssemblyPortfoliosOverviewFromUrl
import components.project.AssemblyRoofsOverviewFromUrl
import components.project.BlueprintsOverviewFromUrl
import components.project.DocumentationsOverviewFromUrl
import components.project.FinalAccountsOverviewFromUrl
import components.project.FinishingUpsOverviewFromUrl
import components.project.GridAssessmentsOverviewFromUrl
import components.project.OrderSpecialMaterialsOverviewFromUrl
import components.project.PrepareMaterialsOverviewFromUrl
import components.project.PresentationsOverviewFromUrl
import components.project.QuotesOverviewFromUrl
import components.project.ScheduleAssembliesOverviewFromUrl
import components.project.StartupOperationsOverviewFromUrl
import components.project.SwitchMeterBoxesOverviewFromUrl
import components.project.VerificationsOverviewFromUrl
import it.neckar.react.common.router.*
import react.*

internal fun NavigationElement.Builder.overviewTableRouting() {

  // Overview over *all* blueprints
  route(PathConstants.OverviewTablePathConstants.blueprints) {
    index { element = { BlueprintsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Projekterfassungen"
    }
  }


  // Overview over *all* quotes
  route(PathConstants.OverviewTablePathConstants.quotes) {
    index { element = { QuotesOverviewFromUrl.create() } }
    breadcrumb {
      label = "Angebote"
    }
  }


  // Overview over *all* quotes
  route(PathConstants.OverviewTablePathConstants.presentations) {
    index { element = { PresentationsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Projektvorstellungen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.orderSpecialMaterials) {
    index { element = { OrderSpecialMaterialsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Sondermaterial Bestellungen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.gridInquiries) {
    index { element = { GridAssessmentsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Netzvoranfragen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.assemblyPortfolios) {
    index { element = { AssemblyPortfoliosOverviewFromUrl.create() } }
    breadcrumb {
      label = "Montagemappen erstellen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.scheduleAssemblies) {
    index { element = { ScheduleAssembliesOverviewFromUrl.create() } }
    breadcrumb {
      label = "Montagetermine einplanen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.advanceInvoices) {
    index { element = { AdvanceInvoicesOverviewFromUrl.create() } }
    breadcrumb {
      label = "Anzahlungsrechnungen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.prepareAssemblies) {
    index { element = { PrepareMaterialsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Montage Vorbereitungen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.assemblyRoofs) {
    index { element = { AssemblyRoofsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Montage Dächer"
    }
  }

  route(PathConstants.OverviewTablePathConstants.assemblyBasements) {
    index { element = { AssemblyBasementsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Montage Keller"
    }
  }

  route(PathConstants.OverviewTablePathConstants.switchMeterBoxes) {
    index { element = { SwitchMeterBoxesOverviewFromUrl.create() } }
    breadcrumb {
      label = "Zählertausch"
    }
  }

  route(PathConstants.OverviewTablePathConstants.startups) {
    index { element = { StartupOperationsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Inbetriebnahmen und Kundeneinweisungen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.finishingUps) {
    index { element = { FinishingUpsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Fertigmeldungen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.finalAccounts) {
    index { element = { FinalAccountsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Abschlussrechnungen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.documentations) {
    index { element = { DocumentationsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Dokumentationen"
    }
  }

  route(PathConstants.OverviewTablePathConstants.verifications) {
    index { element = { VerificationsOverviewFromUrl.create() } }
    breadcrumb {
      label = "Überprüfung"
    }
  }

}
