package services.storage.http

import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import serialized.SerializedProject

/**
 * Response for
 */
@Serializable
sealed interface ArchiveProjectResponse : RestResponse<SerializedProject> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: SerializedProject) : ArchiveProjectResponse, RestResponse.SuccessResponse<SerializedProject>

  @Serializable
  @SerialName("failure")
  data object Failure : ArchiveProjectResponse, RestResponse.FailureResponse<SerializedProject>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: SerializedProject): ArchiveProjectResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): ArchiveProjectResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: SerializedProject?): ArchiveProjectResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}
