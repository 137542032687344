package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.UnusableArea
import com.meistercharts.charts.lizergy.roofPlanning.UnusableAreas
import kotlinx.serialization.Serializable

/**
 * Contains a list of unusable areas
 */
@Serializable
data class UnusableAreasInformation(
  val unusableAreas: List<UnusableAreaInformation>
) {
  /**
   * Convert back to "live" unusableAreas
   */
  fun toUnusableAreas(): Iterable<UnusableArea> {
    return buildList {
      unusableAreas.map {
        UnusableArea(
          location = it.location,
          size = it.size,
          rightTriangleType = it.rightTriangleType,
        ).apply {
          description = it.description
        }
      }.let {
        addAll(it)
      }
    }
  }
}

fun UnusableAreas.toRestApiModel(): UnusableAreasInformation {
  return UnusableAreasInformation(
    unusableAreas.map {
      UnusableAreaInformation(
        location = it.location,
        size = it.size,
        description = it.description,
        rightTriangleType = it.rightTriangleType,
      )
    }
  )
}
