package components.company

import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration

enum class CompanyTypeEnum {
  Main,
  Partner,
  Test,

  ;

  @Suppress("UNUSED_PARAMETER")
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    return when (this) {
      Main -> "Hauptfirma"
      Partner -> "Kooperationspartner"
      Test -> "Test"
    }
  }
}
