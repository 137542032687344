package com.meistercharts.charts.lizergy.roofPlanning

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.linechart.LineStyle
import com.meistercharts.algorithms.painter.FillAndStrokeStyle
import com.meistercharts.algorithms.painter.fillAndStroke
import com.meistercharts.annotations.Domain
import com.meistercharts.annotations.DomainRelative
import com.meistercharts.annotations.Window
import com.meistercharts.annotations.Zoomed
import com.meistercharts.axis.OrientationAwareDirection
import com.meistercharts.axis.OrientationAwareDirection.Companion.calculate
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.canvas.StrokeLocation
import com.meistercharts.canvas.devicePixelRatio
import com.meistercharts.canvas.events.CanvasKeyEventHandler
import com.meistercharts.canvas.events.CanvasMouseEventHandler
import com.meistercharts.canvas.events.CanvasMouseEventHandlerBroker
import com.meistercharts.canvas.layout.cache.BoundsCache
import com.meistercharts.canvas.layout.cache.BoundsLayoutCache
import com.meistercharts.canvas.layout.cache.TriangleBoundsLayoutCache
import com.meistercharts.canvas.paintable.MultiSizePaintable
import com.meistercharts.canvas.paintable.ObjectFit
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.canvas.resize.ResizeHandler
import com.meistercharts.canvas.resizeHandlesSupport
import com.meistercharts.canvas.saved
import com.meistercharts.canvas.stroke
import com.meistercharts.color.Color
import com.meistercharts.color.withAlpha
import com.meistercharts.events.EventConsumption
import com.meistercharts.events.EventConsumption.Consumed
import com.meistercharts.events.EventConsumption.Ignored
import com.meistercharts.events.gesture.CanvasDragSupport
import com.meistercharts.events.gesture.connectedMouseEventHandler
import com.meistercharts.range.LinearValueRange
import com.meistercharts.range.ValueRange
import com.meistercharts.resources.Icons
import com.meistercharts.resources.LocalResourcePaintable
import it.neckar.events.KeyCode
import it.neckar.events.KeyStroke
import it.neckar.events.KeyUpEvent
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseMoveEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.AxisOrientationX
import it.neckar.geometry.AxisOrientationY
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Distance
import it.neckar.geometry.Rectangle
import it.neckar.geometry.Size
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.http.Url
import it.neckar.open.kotlin.lang.abs
import it.neckar.open.kotlin.lang.getAndSet
import it.neckar.open.observable.ObservableObject
import it.neckar.open.unit.number.MayBeNegative
import it.neckar.open.unit.si.mm
import it.neckar.open.unit.si.ms
import kotlin.contracts.contract

/**
 * Layer that supports planning of  photo voltaic modules for *one* roof
 */
class PvRoofPlanningLayer(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractLayer() {

  constructor(
    roofPlanningModel: PvRoofPlanningModel,
    selectionModel: RoofPlanningSelectionModel,
    additionalConfiguration: Configuration.() -> Unit = {},
  ): this(Configuration(roofPlanningModel, selectionModel), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  override val type: LayerType = LayerType.Content

  /**
   * The drag support is registered as first mouse handler
   */
  private val dragSupport: CanvasDragSupport = CanvasDragSupport().also {
    it.handle(object : CanvasDragSupport.Handler {
      override fun isDraggingAllowedFromHere(source: CanvasDragSupport, location: @Window Coordinates, chartSupport: ChartSupport): Boolean {
        //Find possible elements that can be dragged

        //Check for unusable areas
        ifUnusableAreaHit(location) { unusableAreaIndex ->
          val unusableArea = configuration.roofPlanningModel.unusableAreas[unusableAreaIndex]
          uiState = uiState.startDragging(unusableArea)
          return true
        }

        //Check for modules
        ifModuleHit(location) { moduleIndex ->
          val module = configuration.roofPlanningModel.modules()[moduleIndex]
          uiState = uiState.startDragging(module)
          return true
        }

        //Check for module grids
        ifModuleAreaHit(location) { moduleGridIndex ->
          val module = configuration.roofPlanningModel.moduleAreas[moduleGridIndex]
          uiState = uiState.startDragging(module)
          return true
        }

        //Nothing found to be dragged
        return false
      }

      override fun onDrag(source: CanvasDragSupport, @Window location: Coordinates, @Zoomed distance: Distance, @ms deltaTime: Double, chartSupport: ChartSupport): EventConsumption {
        uiState.let { uiState ->
          require(uiState is Dragging) {
            "Invalid state. Expected Dragging but was <$uiState>"
          }

          @RoofRelative @mm val deltaXDomain = layout.valueRangeX.deltaToDomain(chartSupport.chartCalculator.zoomedDelta2domainRelativeX(distance.x))
          @RoofRelative @mm val deltaYDomain = layout.valueRangeY.deltaToDomain(chartSupport.chartCalculator.zoomedDelta2domainRelativeY(distance.y))

          when (uiState.activeElementType) {
            PvElementType.ModuleArea -> {
              val area = if (uiState.clickableNonNull is Module) (uiState.clickableNonNull as Module).modulePlacement.moduleArea else uiState.clickableNonNull as ModuleArea
              area::location.getAndSet { oldValue ->
                oldValue.plus(
                  deltaXDomain, deltaYDomain
                ).coerceIn(
                  minimum = configuration.roofPlanningModel.suggestedRoofInsets.bottomLeft,
                  maximumX = configuration.roofPlanningModel.roofSize.width - configuration.roofPlanningModel.suggestedRoofInsets.right - area.size.width,
                  maximumY = configuration.roofPlanningModel.roofSize.height - configuration.roofPlanningModel.suggestedRoofInsets.top - area.size.height,
                )
              }

              area.fillGrid(configuration.roofPlanningModel.modulesSize)
            }

            PvElementType.UnusableArea -> {
              val unusableArea = uiState.clickableNonNull as UnusableArea
              unusableArea::location.getAndSet { oldValue ->
                oldValue.plus(
                  deltaXDomain, deltaYDomain
                ).coerceIn(
                  minimum = configuration.roofPlanningModel.suggestedRoofInsets.bottomLeft,
                  maximumX = configuration.roofPlanningModel.roofSize.width - configuration.roofPlanningModel.suggestedRoofInsets.right - unusableArea.size.width,
                  maximumY = configuration.roofPlanningModel.roofSize.height - configuration.roofPlanningModel.suggestedRoofInsets.top - unusableArea.size.height,
                )
              }

              configuration.roofPlanningModel.updateModuleAreas(configuration.roofPlanningModel.modulesSize)
            }
          }

          chartSupport.markAsDirty(DirtyReason.UserInteraction)
          return Consumed
        }
      }

      override fun onFinish(source: CanvasDragSupport, location: Coordinates, chartSupport: ChartSupport): EventConsumption {
        uiState = uiState.mouseUp()
        return Consumed
      }
    })
  }

  override val mouseEventHandler: CanvasMouseEventHandler = CanvasMouseEventHandlerBroker().also { broker ->
    /**
     * Handle the actions first
     */
    broker.delegate(object : CanvasMouseEventHandler {
      override fun onDown(event: MouseDownEvent, chartSupport: ChartSupport): EventConsumption {
        //This method is called *after* the dragging events have been handled
        val coordinates = event.coordinates

        //check if over delete area of any unusable area
        ifUnusableAreaDeleteHit(coordinates) { index ->
          uiState = uiState.downOnAction(configuration.roofPlanningModel.unusableAreas[index])
          return Consumed
        }

        ifModuleDeleteHit(coordinates) { index ->
          //mouse over delete button of module
          uiState = uiState.downOnAction(configuration.roofPlanningModel.modules()[index])
          return Consumed
        }

        ifModuleAreaDeleteHit(coordinates) { index ->
          //mouse over delete button of module
          uiState = uiState.downOnAction(configuration.roofPlanningModel.moduleAreas[index])
          return Consumed
        }

        ifModuleAreaRotateHit(coordinates) { index ->
          //mouse over delete button of module
          uiState = uiState.downOnAction(configuration.roofPlanningModel.moduleAreas[index])
          return Consumed
        }

        return Ignored
      }
    })

    /**
     * Handle dragging now
     */
    dragSupport.connectedMouseEventHandler().let {
      broker.delegate(it)
    }

    /**
     * All other events are handled here
     */
    broker.delegate(object : CanvasMouseEventHandler {
      override fun onMove(event: MouseMoveEvent, chartSupport: ChartSupport): EventConsumption {
        val coordinates = event.coordinates

        if (coordinates == null) {
          //Moved out of the window
          uiState = uiState.movedOutOfCanvas()
          return Ignored
        }

        ifModuleAreaRotateHit(coordinates) { index ->
          //mouse over rotate button of module area
          val module = configuration.roofPlanningModel.moduleAreas[index]
          uiState = uiState.hoveringOverRotateAction(module)
          return Consumed
        }

        //check if over delete area of any unusable area
        ifUnusableAreaDeleteHit(coordinates) { index ->
          val unusableArea = configuration.roofPlanningModel.unusableAreas[index]
          uiState = uiState.hoveringOverDeleteAction(unusableArea)
          return Consumed
        }

        ifModuleDeleteHit(coordinates) { index ->
          //mouse over delete button of module
          val module = configuration.roofPlanningModel.modules()[index]
          uiState = uiState.hoveringOverDeleteAction(module)
          return Consumed
        }

        ifModuleAreaDeleteHit(coordinates) { index ->
          //mouse over delete button of module
          val module = configuration.roofPlanningModel.moduleAreas[index]
          uiState = uiState.hoveringOverDeleteAction(module)
          return Consumed
        }

        //check if over unusableArea
        ifUnusableAreaHit(coordinates) { index ->
          val unusableArea = configuration.roofPlanningModel.unusableAreas[index]
          uiState = uiState.hoveringOver(unusableArea)
          return Consumed
        }

        //check if over module
        ifModuleHit(coordinates) { index ->
          //mouse over module
          val module = configuration.roofPlanningModel.modules()[index]
          uiState = uiState.hoveringOver(module)
          return Consumed
        }

        //check if over module
        ifModuleAreaHit(coordinates) { index ->
          //mouse over module
          val module = configuration.roofPlanningModel.moduleAreas[index]
          uiState = uiState.hoveringOver(module)
          return Consumed
        }

        //Nothing found under the mouse
        uiState = uiState.moveOverNothing()
        return Consumed
      }

      override fun onDown(event: MouseDownEvent, chartSupport: ChartSupport): EventConsumption {
        //This method is called *after* the dragging events have been handled
        //All downs on modules and unusable areas have already been consumed
        //remove the selection if necessary
        uiState = uiState.downOnNothing()

        return Ignored
      }

      override fun onUp(event: MouseUpEvent, chartSupport: ChartSupport): EventConsumption {
        uiState = uiState.mouseUp()
        //TODO return Consumed???
        return super.onUp(event, chartSupport)
      }
    })
  }

  /**
   * Executes the given lambda if an unusable area has been it
   */
  private inline fun ifUnusableAreaHit(location: @Window Coordinates, action: (unusableAreaIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    layout.unusableAreaBounds.findLastIndex(location)?.let { foundIndex ->
      action(foundIndex)
    }

    layout.unusableTriangleAreaBounds.findLastIndex(location)?.let { foundIndex ->
      action(foundIndex)
    }
  }

  /**
   * Executes the given lambda if hovering above an unusable area but *within* the delete action
   */
  private inline fun ifUnusableAreaDeleteHit(location: @Window Coordinates, action: (unusableAreaIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    //Find the index of the *complete* area. the action index must be >= than this area
    val unusableAreaIndex = layout.unusableAreaBounds.findLastIndex(location) ?: layout.unusableTriangleAreaBounds.findLastIndex(location) ?: return

    layout.unusableAreaDeleteButtonsBounds.findLastIndex(location)?.let { foundIndex ->
      if (foundIndex >= unusableAreaIndex) {
        action(foundIndex)
      }
    }
  }

  /**
   * Executes the given lambda if hovering above a module grid but *within* the action area
   */
  private inline fun ifModuleAreaDeleteHit(location: @Window Coordinates, action: (moduleAreaIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    //Find the index of the *complete* module. the action index must be >= than this area
    layout.moduleAreaBounds.fastForEachIndexed { moduleAreaIndex, _, _, _, _ ->
      if (uiState.roofSelection.isSelected(configuration.roofPlanningModel.moduleAreas[moduleAreaIndex])) {
        layout.moduleAreasDeleteButtonsBounds.findLastIndex(location)?.let { foundIndex ->
          if (foundIndex >= 0 && foundIndex >= moduleAreaIndex) {
            action(foundIndex)
          }
        }
      }
    }
  }

  /**
   * Executes the given lambda if hovering above a module grid but *within* the action area
   */
  private inline fun ifModuleAreaRotateHit(location: @Window Coordinates, action: (moduleAreaIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    //Find the index of the *complete* module. the action index must be >= than this area
    layout.moduleAreaBounds.fastForEachIndexed { moduleAreaIndex, _, _, _, _ ->
      if (uiState.roofSelection.isSelected(configuration.roofPlanningModel.moduleAreas[moduleAreaIndex])) {
        layout.moduleAreaRotateButtonsBounds.findLastIndex(location)?.let { foundIndex ->
          if (foundIndex >= 0 && foundIndex >= moduleAreaIndex) {
            action(foundIndex)
            return
          }
        }
      }
    }
  }

  /**
   * Finds the (top most) module grid at the given location
   * Any module grid is returned
   */
  private inline fun ifModuleAreaHit(location: @Window Coordinates, action: (moduleAreaIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    layout.moduleAreaBounds.findLastIndex(location)?.let { foundIndex ->
      action(foundIndex)
    }
  }

  /**
   * Executes the given lambda if hovering above a module but *within* the action area
   */
  private inline fun ifModuleDeleteHit(location: @Window Coordinates, action: (moduleIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    //Find the index of the *complete* module. the action index must be >= than this area
    val moduleIndex = layout.moduleBounds.findLastIndex(location) ?: return

    layout.moduleDeleteButtonsBounds.findLastIndex(location)?.let { foundIndex ->
      if (foundIndex >= 0 && foundIndex >= moduleIndex) {
        action(foundIndex)
      }
    }
  }

  /**
   * Finds the (top most) module at the given location
   * Any module is returned (both in the grid or manually placed)
   */
  private inline fun ifModuleHit(location: @Window Coordinates, action: (moduleIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    layout.moduleBounds.findLastIndex(location)?.let { foundIndex ->
      action(foundIndex)
    }
  }

  /**
   * Contains the current ui state
   */
  private var uiStateProperty: ObservableObject<RoofPlanningUiState> = ObservableObject(DefaultState(configuration, RoofSelection.empty))
  var uiState: RoofPlanningUiState by uiStateProperty
    private set

  override val keyEventHandler: CanvasKeyEventHandler = object : CanvasKeyEventHandler {
    override fun onUp(event: KeyUpEvent, chartSupport: ChartSupport): EventConsumption {
      if (event.keyStroke == KeyStroke(KeyCode.Delete)) {
        //Delete if a module has been selected
        configuration.selectionModel.selectedModule?.let {
          configuration.roofPlanningModel.removeModule(it)
        }
        //Delete if an unusable area has been selected
        configuration.selectionModel.selectedUnusableArea?.let {
          configuration.roofPlanningModel.removeUnusableArea(it)
        }

        chartSupport.markAsDirty(DirtyReason.UserInteraction)
        return Consumed
      }

      return Ignored
    }
  }

  override fun initialize(paintingContext: LayerPaintingContext) {
    val chartSupport = paintingContext.chartSupport

    //Update the mouse cursor depending on the state
    uiStateProperty.consumeImmediately {

      //Update the mouse cursor
      chartSupport.cursor = when (it) {
        is DefaultState, is HoveringAndButtonsVisible, is HoveringOverDeletedGridModuleAddButtonVisible, is Resizing -> null
        is ArmedForDelete, is ArmedForRotate, is ArmedForAdd -> MouseCursor.Hand
        is Dragging -> MouseCursor.Move
      }

      //Always mark as dirty on state change
      chartSupport.markAsDirty(DirtyReason.UiStateChanged)

      //TODO update the selection model
      //data.selectionModel.select(data.roofPlanningModel.unusableAreas[unusableAreaIndex])
    }

    chartSupport.resizeHandlesSupport.onResize(this, object : ResizeHandler {
      override fun beginResizing(handleDirection: Direction) {
        val resizable = uiState.roofSelection.resizable
        requireNotNull(resizable) {
          "Invalid state - expected selected Resizable"
        }
        uiState = uiState.startResizing(resizable)
      }

      override fun resizingFinished() {
        uiState = uiState.resizingFinished()
      }

      override fun resizing(rawDistance: Distance, handleDirection: Direction, deltaX: Double, deltaY: Double) {
        uiState.let { uiState ->
          val resizable = uiState.roofSelection.selection as Resizable

          val chartCalculator = chartSupport.chartCalculator
          @DomainRelative val deltaXDomainRelative = chartCalculator.zoomedDelta2domainRelativeX(deltaX)
          @DomainRelative val deltaYDomainRelative = chartCalculator.zoomedDelta2domainRelativeY(deltaY)

          @Domain val deltaXDomain = layout.valueRangeX.deltaToDomain(deltaXDomainRelative)
          @Domain val deltaYDomain = layout.valueRangeY.deltaToDomain(deltaYDomainRelative)

          //Calculate the delta for the location and resize - depending on the axis orientation and handle direction
          val directionX: OrientationAwareDirection = OrientationAwareDirection.calculate(chartCalculator.chartState.axisOrientationX, handleDirection.horizontalAlignment)
          val directionY: OrientationAwareDirection = OrientationAwareDirection.calculate(chartCalculator.chartState.axisOrientationY, handleDirection.verticalAlignment)

          val deltaLocationX: @Domain Double
          val deltaLocationY: @Domain Double
          val resizeX: @Domain Double
          val resizeY: @Domain Double

          if (directionX == OrientationAwareDirection.TowardsSmaller) {
            deltaLocationX = deltaXDomain
            resizeX = -deltaXDomain
          } else {
            deltaLocationX = 0.0
            resizeX = deltaXDomain
          }

          if (directionY == OrientationAwareDirection.TowardsSmaller) {
            deltaLocationY = deltaYDomain
            resizeY = -deltaYDomain
          } else {
            deltaLocationY = 0.0
            resizeY = deltaYDomain
          }

          resizable.updateSizeAndLocation(deltaLocationX, deltaLocationY, resizeX, resizeY, configuration.roofPlanningModel)
          configuration.roofPlanningModel.updateModuleAreas(configuration.roofPlanningModel.modulesSize)
          chartSupport.markAsDirty(DirtyReason.UserInteraction)
        }
      }
    })
  }

  override fun layout(paintingContext: LayerPaintingContext) {
    super.layout(paintingContext)
    layout.calculateLayout(paintingContext)

    //Register the resizable area as resizable
    val resizeHandlesSupport = paintingContext.chartSupport.resizeHandlesSupport

    uiState.roofSelection.resizable?.let { resizable ->
      val resizingRect = when (resizable) {

        is UnusableArea -> {
          val index = configuration.roofPlanningModel.unusableAreas.unusableAreas.indexOf(resizable)
          if (layout.unusableTriangleAreaBounds.rightTriangleType(index) != null) {
            layout.unusableTriangleAreaBounds.asRect(index)
          } else {
            layout.unusableAreaBounds.asRect(index)
          }
        }

        is ModuleArea -> {
          val chartCalculator = paintingContext.chartCalculator
          val valueRangeX = layout.valueRangeX
          val valueRangeY = layout.valueRangeY

          val x = chartCalculator.domain2windowX(resizable.location.x, valueRangeX)
          val y = chartCalculator.domain2windowY(resizable.location.y, valueRangeY)
          val width = chartCalculator.domainDelta2zoomedX(resizable.size.width, valueRangeX)
          val height = chartCalculator.domainDelta2zoomedY(resizable.size.height, valueRangeY)

          Rectangle(x, y, width, height)
        }

      }
      resizeHandlesSupport.setResizable(this, resizingRect)
    } ?: resizeHandlesSupport.clear(this)
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc
    val chartCalculator = paintingContext.chartCalculator

    //Paint the roof insets
    if (configuration.mode == Mode.Planning) {
      layout.suggestedRoofArea.let { suggestedRoofArea: @Window BoundsCache ->
        gc.saved {
          //Stroke the area around
          gc.fill(configuration.suggestedRoofInsetsFill)

          gc.beginPath()

          //Outside, counter clockwise

          //Top left
          gc.moveTo(
            chartCalculator.contentAreaRelative2windowX(0.0),
            chartCalculator.contentAreaRelative2windowY(0.0),
          )
          //Bottom left
          gc.lineTo(
            chartCalculator.contentAreaRelative2windowX(0.0),
            chartCalculator.contentAreaRelative2windowY(1.0),
          )
          //bottom right
          gc.lineTo(
            chartCalculator.contentAreaRelative2windowX(1.0),
            chartCalculator.contentAreaRelative2windowY(1.0),
          )
          //Top right
          gc.lineTo(
            chartCalculator.contentAreaRelative2windowX(1.0),
            chartCalculator.contentAreaRelative2windowY(0.0),
          )
          //Top left
          gc.lineTo(
            chartCalculator.contentAreaRelative2windowX(0.0),
            chartCalculator.contentAreaRelative2windowY(0.0),
          )

          //
          //stamp out the inner part
          //

          //stroke the complete rect (counter clockwise)
          //Top left
          gc.moveTo(
            suggestedRoofArea.x,
            suggestedRoofArea.y,
          )
          //Bottom left
          gc.lineTo(
            suggestedRoofArea.x,
            suggestedRoofArea.y + suggestedRoofArea.height,
          )
          //Bottom right
          gc.lineTo(
            suggestedRoofArea.x + suggestedRoofArea.width,
            suggestedRoofArea.y + suggestedRoofArea.height,
          )
          //Top right
          gc.lineTo(
            suggestedRoofArea.x + suggestedRoofArea.width,
            suggestedRoofArea.y,
          )
          //Top left
          gc.lineTo(
            suggestedRoofArea.x,
            suggestedRoofArea.y,
          )

          gc.closePath()
          gc.fill()
        }

        //Stroke the bounds
        configuration.suggestedRoofInsets.apply(gc)
        gc.strokeRect(suggestedRoofArea.x, suggestedRoofArea.y, suggestedRoofArea.width, suggestedRoofArea.height)
      }
    }

    //Paint all modules
    val modules = configuration.roofPlanningModel.modules().modules
    layout.moduleBounds.fastForEachIndexed { index, x, y, width: @MayBeNegative Double, height: @MayBeNegative Double ->
      val module = modules[index]

      if (module.deleted.not()) {
        gc.saved {
          val multiSizePaintable: MultiSizePaintable = if (module.isVertical()) configuration.panelPaintableVertical else configuration.panelPaintableHorizontal

          val devicePixelRatio = paintingContext.chartSupport.devicePixelRatio
          val paintable: Paintable = multiSizePaintable.sameOrLarger((width * devicePixelRatio).abs(), (height * devicePixelRatio).abs(), paintingContext)

          paintable.paintInBoundingBox(paintingContext, x, y, Direction.TopLeft, 0.0, 0.0, width, height, objectFit = ObjectFit.Fill)
        }
      }

      uiState.let { uiState ->
        when (uiState) {
          is DefaultState -> {
            if (module.deleted.not() && configuration.roofPlanningModel.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            }
          }

          is HoveringAndButtonsVisible -> {
            if (module.deleted.not() && configuration.roofPlanningModel.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            } else if (uiState.matchesLoose(module)) {
              //Over this module? Or somewhere else on the grid, if this is a grid module?
              gc.fillAndStroke(configuration.hoverHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            }

            //Paint the delete icon - only for the exact module
            if (uiState.clickable == module) {
              gc.saved {
                configuration.deleteIcon.paint(paintingContext, x + width / 2.0, y + height / 2.0)
              }
            }
          }

          is ArmedForDelete -> {
            if (uiState.clickable == module) {
              gc.fillAndStroke(if (module.deleted.not()) configuration.deleteArmedHighlightStyle else configuration.undeleteArmedHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)

              gc.saved {
                if (module.deleted.not()) {
                  configuration.deleteIconArmed.paint(paintingContext, x + width / 2.0, y + height / 2.0)
                } else {
                  configuration.undeleteIconArmed.paint(paintingContext, x + width / 2.0, y + height / 2.0)
                }
              }
            } else if (module.deleted.not() && configuration.roofPlanningModel.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            }
          }

          is ArmedForRotate -> {
            if (module.deleted.not() && configuration.roofPlanningModel.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            }
          }

          is Dragging -> {
            if (module.deleted.not() && configuration.roofPlanningModel.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            } else if (uiState.matchesLoose(module)) {
              gc.fillAndStroke(configuration.draggingHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            }
          }

          is HoveringOverDeletedGridModuleAddButtonVisible -> {
            if (module.deleted.not() && configuration.roofPlanningModel.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            }
          }

          is ArmedForAdd -> {
            if (module.deleted.not() && configuration.roofPlanningModel.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            }
          }

          is Resizing -> {
            if (module.deleted.not() && configuration.roofPlanningModel.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(x, y, width, height)
              gc.strokeRect(x, y, width, height)
            }
          }
        }
      }

      if (module.deleted.not()) {
        //paint a white border
        gc.stroke(Color.white)
        gc.strokeRect(x, y, width, height, StrokeLocation.Inside)
      }
    }

    //Paint the unusable areas
    val unusableAreas = configuration.roofPlanningModel.unusableAreas.unusableAreas
    layout.unusableAreaBounds.fastForEachIndexed { index, xRectangle, yRectangle, widthRectangle, heightRectangle ->
      val unusableArea = unusableAreas[index]

      //TODO different images

      val triangles = layout.unusableTriangleAreaBounds
      val x: Double
      val y: Double
      val width: Double
      val height: Double

      if (triangles.rightTriangleType(index) == null) {
        x = xRectangle
        y = yRectangle
        width = widthRectangle
        height = heightRectangle
      } else {
        x = triangles.x(index)
        y = triangles.y(index)
        width = triangles.width(index)
        height = triangles.height(index)
      }

      gc.saved {
        //Identify the mode

        val selected = uiState.roofSelection.isSelected(unusableArea)
        val fallbackMode = if (selected) UnusableAreaPainter.Mode.Selected else UnusableAreaPainter.Mode.Default

        val mode: UnusableAreaPainter.Mode = uiState.let { uiState ->
          when (uiState) {
            is DefaultState -> {
              fallbackMode
            }

            is HoveringAndButtonsVisible -> {
              //Over this module? Or somewhere else on the grid, if this is a grid module?
              if (uiState.isActive(unusableArea)) {
                if (selected) {
                  UnusableAreaPainter.Mode.SelectedHovering
                } else {
                  UnusableAreaPainter.Mode.Hover
                }
              } else {
                fallbackMode
              }
            }

            is ArmedForDelete -> {
              if (uiState.isActive(unusableArea)) {
                UnusableAreaPainter.Mode.DeleteArmed
              } else {
                fallbackMode
              }
            }

            is ArmedForRotate -> {
              fallbackMode
            }

            is Dragging -> {
              if (uiState.isActive(unusableArea)) {
                UnusableAreaPainter.Mode.Dragging
              } else {
                fallbackMode
              }
            }

            is HoveringOverDeletedGridModuleAddButtonVisible -> {
              fallbackMode
            }

            is ArmedForAdd -> {
              fallbackMode
            }

            is Resizing -> {
              fallbackMode
            }
          }
        }

        configuration.unusableAreaPainter.paint(paintingContext, x, y, width, height, unusableArea, mode)
      }
    }

    val moduleAreas = configuration.roofPlanningModel.moduleAreas
    layout.moduleAreaBounds.fastForEachIndexed { index, x, y, width, height ->
      val moduleArea = moduleAreas[index]

      gc.saved {
        //Identify the mode

        val selected = uiState.roofSelection.isSelected(moduleArea)
        val fallbackMode = if (selected) ModuleAreaPainter.Mode.Selected else ModuleAreaPainter.Mode.Invisible

        val mode: ModuleAreaPainter.Mode = uiState.let { uiState ->
          when (uiState) {
            is DefaultState -> {
              fallbackMode
            }

            is HoveringAndButtonsVisible -> {
              //Over this module? Or somewhere else on the grid, if this is a grid module?
              if (selected) {
                ModuleAreaPainter.Mode.SelectedHovering
              } else {
                if (uiState.isActive(moduleArea)) ModuleAreaPainter.Mode.Hover else ModuleAreaPainter.Mode.Invisible
              }
            }

            is ArmedForDelete -> {
              if (uiState.clickable == moduleArea) {
                ModuleAreaPainter.Mode.DeleteArmed
              } else {
                if (selected) {
                  ModuleAreaPainter.Mode.SelectedHovering
                } else {
                  if (uiState.isActive(moduleArea)) ModuleAreaPainter.Mode.Hover else ModuleAreaPainter.Mode.Invisible
                }
              }
            }

            is ArmedForRotate -> {
              if (uiState.clickable == moduleArea) {
                ModuleAreaPainter.Mode.RotateArmed
              } else {
                if (selected) {
                  ModuleAreaPainter.Mode.SelectedHovering
                } else {
                  if (uiState.isActive(moduleArea)) ModuleAreaPainter.Mode.Hover else ModuleAreaPainter.Mode.Invisible
                }
              }
            }

            is Dragging -> {
              if (uiState.isActive(moduleArea)) {
                ModuleAreaPainter.Mode.Dragging
              } else {
                fallbackMode
              }
            }

            is HoveringOverDeletedGridModuleAddButtonVisible -> {
              fallbackMode
            }

            is ArmedForAdd -> {
              fallbackMode
            }

            is Resizing -> {
              fallbackMode
            }
          }
        }

        configuration.moduleAreaPainter.paint(paintingContext, x, y, width, height, moduleArea, chartCalculator, mode)
      }
    }
  }

  /**
   * Contains the layout information - used for mouse over effects
   */
  private val layout: Layout = Layout()

  /**
   * Contains the layout information (especially the module coordinates)
   */
  inner class Layout {
    /**
     * The value range for the x axis
     */
    var valueRangeX: @Domain @mm LinearValueRange = ValueRange.default
    var valueRangeY: @Domain @mm LinearValueRange = ValueRange.default

    /**
     * The suggested area where the modules should be placed within
     */
    var suggestedRoofArea: @Window BoundsCache = BoundsCache()

    /**
     * Contains the bounds for the modules
     */
    val moduleBounds: @Window BoundsLayoutCache = BoundsLayoutCache()

    /**
     * Contains the bounds for the unusable rectangle areas
     */
    val unusableAreaBounds: @Window BoundsLayoutCache = BoundsLayoutCache()

    /**
     * Contains the bounds for the unusable triangle areas
     */
    val unusableTriangleAreaBounds: @Window TriangleBoundsLayoutCache = TriangleBoundsLayoutCache()

    val moduleAreaBounds: @Window BoundsLayoutCache = BoundsLayoutCache()

    /**
     * The bounds for the delete buttons for the modules
     */
    val moduleDeleteButtonsBounds: @Window BoundsLayoutCache = BoundsLayoutCache()

    /**
     * The bounds for the delete buttons for the unusable areas
     */
    val unusableAreaDeleteButtonsBounds: @Window BoundsLayoutCache = BoundsLayoutCache()

    val moduleAreasDeleteButtonsBounds: @Window BoundsLayoutCache = BoundsLayoutCache()

    val moduleAreaRotateButtonsBounds: @Window BoundsLayoutCache = BoundsLayoutCache()


    fun calculateLayout(paintingContext: LayerPaintingContext) {
      val chartCalculator = paintingContext.chartCalculator
      val chartState = paintingContext.chartState

      val roofSize = configuration.roofPlanningModel.roofSize
      valueRangeX = ValueRange.linear(0.0, roofSize.width)
      valueRangeY = ValueRange.linear(0.0, roofSize.height)

      //The roof insets
      val roofInsets = configuration.roofPlanningModel.suggestedRoofInsets
      @DomainRelative val roofInsetsX = 0.0 + valueRangeX.deltaToDomainRelative(
        when (chartState.axisOrientationX) {
          AxisOrientationX.OriginAtLeft -> roofInsets.left
          AxisOrientationX.OriginAtRight -> roofInsets.right
        }
      )

      @DomainRelative val roofInsetsY = 0.0 + valueRangeY.deltaToDomainRelative(
        //Depending on the axis orientation bottom or top should be used
        when (chartState.axisOrientationY) {
          AxisOrientationY.OriginAtBottom -> roofInsets.bottom
          AxisOrientationY.OriginAtTop -> roofInsets.top
        }
      )
      @DomainRelative val roofInsetsWidth = 1 - valueRangeX.deltaToDomainRelative(roofInsets.offsetWidth)
      @DomainRelative val roofInsetsHeight = 1 - valueRangeY.deltaToDomainRelative(roofInsets.offsetHeight)

      suggestedRoofArea.x = chartCalculator.domainRelative2windowX(roofInsetsX)
      suggestedRoofArea.y = chartCalculator.domainRelative2windowY(roofInsetsY)
      suggestedRoofArea.width = chartCalculator.domainRelativeDelta2ZoomedX(roofInsetsWidth)
      suggestedRoofArea.height = chartCalculator.domainRelativeDelta2ZoomedY(roofInsetsHeight)

      //The bounding box for the delete icon
      val deleteIconBoundingBox = configuration.deleteIcon.boundingBox(paintingContext)

      //Calculate the module bounds
      moduleBounds.ensureSize(configuration.roofPlanningModel.modules().count) //ensure array sizes
      moduleDeleteButtonsBounds.ensureSize(configuration.roofPlanningModel.modules().count) //ensure array sizes
      configuration.roofPlanningModel.modules().modules.fastForEachIndexed { index, module ->
        val moduleLocation: @RoofRelative Coordinates = module.location

        val x = chartCalculator.domain2windowX(moduleLocation.x, valueRangeX)
        val y = chartCalculator.domain2windowY(moduleLocation.y, valueRangeY)
        val width = chartCalculator.domainDelta2zoomedX(module.width.toDouble(), valueRangeX)
        val height = chartCalculator.domainDelta2zoomedY(module.height.toDouble(), valueRangeY)

        moduleBounds.x(index, x)
        moduleBounds.y(index, y)

        moduleBounds.width(index, width)
        moduleBounds.height(index, height)

        //Calculate the buttons - in the center of the modules
        val centerX = x + width / 2.0
        val centerY = y + height / 2.0

        moduleDeleteButtonsBounds.x(index, centerX + deleteIconBoundingBox.getX())
        moduleDeleteButtonsBounds.y(index, centerY + deleteIconBoundingBox.getY())
        moduleDeleteButtonsBounds.width(index, deleteIconBoundingBox.getWidth())
        moduleDeleteButtonsBounds.height(index, deleteIconBoundingBox.getHeight())
      }

      //Calculate the bounds for the unusable areas
      unusableAreaBounds.ensureSize(configuration.roofPlanningModel.unusableAreas.count) //ensure array sizes
      unusableTriangleAreaBounds.ensureSize(configuration.roofPlanningModel.unusableAreas.count) //ensure array sizes
      unusableAreaDeleteButtonsBounds.ensureSize(configuration.roofPlanningModel.unusableAreas.count) //ensure array sizes
      configuration.roofPlanningModel.unusableAreas.unusableAreas.fastForEachIndexed { index, unusableArea ->
        val unusableAreaLocation: @RoofRelative Coordinates = unusableArea.location

        val x = chartCalculator.domain2windowX(unusableAreaLocation.x, valueRangeX)
        val y = chartCalculator.domain2windowY(unusableAreaLocation.y, valueRangeY)
        val width = chartCalculator.domainDelta2zoomedX(unusableArea.size.width, valueRangeX)
        val height = chartCalculator.domainDelta2zoomedY(unusableArea.size.height, valueRangeY)

        if (unusableArea.rightTriangleType != null) {
          unusableTriangleAreaBounds.x(index, x)
          unusableTriangleAreaBounds.y(index, y)
          unusableTriangleAreaBounds.rightTriangleType(index, unusableArea.rightTriangleType ?: return@fastForEachIndexed)

          unusableTriangleAreaBounds.width(index, width)
          unusableTriangleAreaBounds.height(index, height)
        } else {
          unusableAreaBounds.x(index, x)
          unusableAreaBounds.y(index, y)

          unusableAreaBounds.width(index, width)
          unusableAreaBounds.height(index, height)
        }

        //Calculate the buttons - in the center of the modules
        val centerX = x + width / 2.0
        val centerY = y + height / 2.0

        unusableAreaDeleteButtonsBounds.x(index, centerX + deleteIconBoundingBox.getX())
        unusableAreaDeleteButtonsBounds.y(index, centerY + deleteIconBoundingBox.getY())
        unusableAreaDeleteButtonsBounds.width(index, deleteIconBoundingBox.getWidth())
        unusableAreaDeleteButtonsBounds.height(index, deleteIconBoundingBox.getHeight())
      }

      val moduleAreaDeleteIconBoundingBox = configuration.moduleAreaPainter.configuration.deleteIcon.boundingBox(paintingContext)
      val moduleAreaRotateIconBoundingBox = configuration.moduleAreaPainter.configuration.rotateIcon.boundingBox(paintingContext)

      moduleAreaBounds.ensureSize(configuration.roofPlanningModel.moduleAreas.count) //ensure array sizes
      moduleAreasDeleteButtonsBounds.ensureSize(configuration.roofPlanningModel.moduleAreas.count) //ensure array sizes
      moduleAreaRotateButtonsBounds.ensureSize(configuration.roofPlanningModel.moduleAreas.count) //ensure array sizes
      configuration.roofPlanningModel.moduleAreas.moduleAreas.fastForEachIndexed { index, moduleGrid ->
        val moduleGridLocation: @RoofRelative Coordinates = moduleGrid.location

        val x = chartCalculator.domain2windowX(moduleGridLocation.x, valueRangeX)
        val y = chartCalculator.domain2windowY(moduleGridLocation.y, valueRangeY)
        val width = chartCalculator.domainDelta2zoomedX(moduleGrid.size.width, valueRangeX)
        val height = chartCalculator.domainDelta2zoomedY(moduleGrid.size.height, valueRangeY)

        moduleAreaBounds.x(index, x)
        moduleAreaBounds.y(index, y)
        moduleAreaBounds.width(index, width)
        moduleAreaBounds.height(index, height)

        //Calculate the buttons - in the center of the modules
        val centerX = x + width / 2.0
        val centerY = y + height / 2.0

        moduleAreasDeleteButtonsBounds.x(index, centerX + moduleAreaDeleteIconBoundingBox.getX())
        moduleAreasDeleteButtonsBounds.y(index, centerY + moduleAreaDeleteIconBoundingBox.getY())
        moduleAreasDeleteButtonsBounds.width(index, moduleAreaDeleteIconBoundingBox.getWidth())
        moduleAreasDeleteButtonsBounds.height(index, moduleAreaDeleteIconBoundingBox.getHeight())

        val rightX = x + width - moduleAreaRotateIconBoundingBox.getWidth()
        val topY = y + height + moduleAreaRotateIconBoundingBox.getHeight()

        moduleAreaRotateButtonsBounds.x(index, rightX + moduleAreaRotateIconBoundingBox.getX())
        moduleAreaRotateButtonsBounds.y(index, topY + moduleAreaRotateIconBoundingBox.getY())
        moduleAreaRotateButtonsBounds.width(index, moduleAreaRotateIconBoundingBox.getWidth())
        moduleAreaRotateButtonsBounds.height(index, moduleAreaRotateIconBoundingBox.getHeight())
      }
    }
  }

  /**
   * The mode
   */
  enum class Mode {
    /**
     * The roof is rendered (e.g. to display to the customer)
     */
    Rendering,

    /**
     * We are in planning mode - additional information is painted
     */
    Planning
  }

  @ConfigurationDsl
  class Configuration(
    val roofPlanningModel: PvRoofPlanningModel,
    val selectionModel: RoofPlanningSelectionModel,
  ) {
    /**
     * The current mode
     */
    var mode: Mode = Mode.Rendering //TODO remove mouse over effects when rendering!

    /**
     * The insets for the suggested roof insets
     */
    val suggestedRoofInsetsFill: Color = Color.web("#ff000088")

    /**
     * The line style for the roof insets
     */
    val suggestedRoofInsets: LineStyle = LineStyle(Color.red)

    /**
     * The style when the mouse over an element
     */
    val hoverHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.web("#75b72633"), Color.web("#75b726"))

    /**
     * When hovering above the delete button
     */
    val deleteArmedHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.red().withAlpha(0.3), Color.red())

    val undeleteArmedHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.green().withAlpha(0.3), Color.green())

    /**
     * Highlighting during drag operation
     */
    val draggingHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.white.withAlpha(0.2), Color.white)

    /**
     * The delete icon that is painted on hover
     */
    val deleteIcon: Paintable = Icons.delete(Size.PX_24)

    /**
     * The delete icon while hovering above it
     */
    val deleteIconArmed: Paintable = Icons.delete(Size.PX_24, Color.red)

    val undeleteIconArmed: Paintable = Icons.delete(Size.PX_24, Color.green)

    /**
     * The paintable for the panel
     */
    var panelPaintableVertical: MultiSizePaintable = MultiSizePaintable(
      listOf(
        LocalResourcePaintable(Url.relative("solar/panel-vertical.png"), Size(1334.0, 1860.0)),
        LocalResourcePaintable(Url.relative("solar/panel-vertical_50.png"), Size(930.0, 567.0)),
        LocalResourcePaintable(Url.relative("solar/panel-vertical_25.png"), Size(465.0, 284.0)),
        LocalResourcePaintable(Url.relative("solar/panel-vertical_10.png"), Size(186.0, 113.0)),
        LocalResourcePaintable(Url.relative("solar/panel-vertical_5.png"), Size(93.0, 57.0)),
      )
    )

    var panelPaintableHorizontal: MultiSizePaintable = MultiSizePaintable(
      listOf(
        LocalResourcePaintable(Url.relative("solar/panel-horizontal.png"), Size(1334.0, 1860.0)),
        LocalResourcePaintable(Url.relative("solar/panel-horizontal_50.png"), Size(930.0, 567.0)),
        LocalResourcePaintable(Url.relative("solar/panel-horizontal_25.png"), Size(465.0, 284.0)),
        LocalResourcePaintable(Url.relative("solar/panel-horizontal_10.png"), Size(186.0, 113.0)),
        LocalResourcePaintable(Url.relative("solar/panel-horizontal_5.png"), Size(93.0, 57.0)),
      )
    )

    /**
     * The paintable for unusable areas
     */
    var unusableAreaPainter: UnusableAreaPainter = UnusableAreaPainter()

    var moduleAreaPainter: ModuleAreaPainter = ModuleAreaPainter()
  }
}
