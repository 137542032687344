package it.neckar.react.common.button

import it.neckar.react.common.*
import kotlinx.html.BUTTON
import react.*
import react.dom.*

/**
 * Creates an action button - that has only an icon
 */
fun RBuilder.actionButton(
  /**
   * The icon for the button
   */
  icon: ButtonIconCentered,

  /**
   * The css classes for the button
   */
  classes: String = "btn btn-primary",

  /**
   * The action that is called.
   * While the action is running, the button will be marked as "busy"
   */
  action: suspend () -> Unit,

  /**
   * Additional configuration for the button
   */
  block: RDOMBuilder<BUTTON>.() -> Unit = {},

  ): Unit = child(Button2) {

  attrs {
    this.icon = icon
    this.label = null
    this.classes = classes
    this.action = action
    this.block = block
  }
}

/**
 * Creates a link button.
 */
fun RBuilder.linkButton(
  /**
   * The icon for the link
   */
  icon: ButtonIcon? = null,

  /**
   * The link label
   */
  label: String,

  /**
   * The css classes for the button
   */
  classes: String = "btn btn-link",

  /**
   * The action that is called. While the action is running, the button will be marked as "busy"
   */
  action: suspend () -> Unit,

  block: RDOMBuilder<BUTTON>.() -> Unit = {},

  ): Unit = child(Button2) {
  attrs {
    this.icon = icon
    this.label = label
    this.classes = classes
    this.action = action
    this.block = block
    this.iconBlock = {
      it.attrs {
        addClass("ms-1")
        addClass("me-1")
      }
    }
  }
}


/**
 * Creates an edit button
 */
fun RBuilder.editButton(action: suspend () -> Unit) {
  actionButton(icon = ButtonIcon.centered(FontAwesomeIcons.edit), classes = "btn btn-link btn-sm", action = action) {}
}

/**
 * Creates an "add" button
 */
fun RBuilder.addButton(action: suspend () -> Unit) {
  actionButton(icon = ButtonIcon.centered(FontAwesomeIcons.add), classes = "btn btn-link btn-sm", action = action) {}
}

/**
 * Creates a "copy" button
 */
fun RBuilder.copyButton(action: suspend () -> Unit) {
  actionButton(icon = ButtonIcon.centered(FontAwesomeIcons.copy), classes = "btn btn-link btn-sm", action = action) {}
}

/**
 * Creates a "delete" button
 */
fun RBuilder.deleteButton(action: suspend () -> Unit) {
  actionButton(icon = ButtonIcon.centered(FontAwesomeIcons.trash), classes = "btn btn-link btn-sm", action = action) {}
}


