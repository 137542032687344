package services.http

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import it.neckar.common.auth.LoginResponse
import it.neckar.ktor.client.bearerAuth
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.get
import it.neckar.ktor.client.post
import it.neckar.ktor.client.postJson
import it.neckar.ktor.client.safeTryFetch
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.rest.jwt.JwtToken
import it.neckar.user.UserLoginName
import services.auth.http.AddNewCompanyRequest
import services.auth.http.AddNewCompanyResponse
import services.auth.http.AddNewUserRequest
import services.auth.http.AddNewUserResponse
import services.auth.http.ChangeCompanyInfoRequest
import services.auth.http.ChangePasswordRequest
import services.auth.http.ChangePasswordResponse
import services.auth.http.ChangeUserInfoRequest
import services.auth.http.ChangeUserInfoResponse
import services.auth.http.ChangedCompanyInfoResponse
import services.auth.http.DeleteUserRequest
import services.auth.http.DeletedUserResponse
import services.auth.http.FetchUsersAndCompaniesResponse

/**
 * Service that manages the authentication
 *
 * TODO: Split authentication and user/company management
 */
class AuthenticationClientService(
  val httpClientWithAuth: HttpClient,
  /**
   * HTTP client that does not have any authentication configured.
   */
  val httpClientNoAuth: HttpClient,
  val urlSupport: PlannerUrlSupport,
) {

  /**
   * Login the current user
   */
  suspend fun login(loginName: UserLoginName, password: String, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): LoginResponse {
    return safeTryFetch(LoginResponse.Failure) {
      httpClientNoAuth
        .postJson(urlSupport.auth.login, it.neckar.common.auth.LoginRequest(loginName, password)) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  /**
   * Refreshes the access token (using the refresh token)
   */
  suspend fun refreshAccessToken(refreshToken: JwtToken, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): it.neckar.common.auth.JwtRefreshTokenResponse {
    return safeTryFetch(it.neckar.common.auth.JwtRefreshTokenResponse.Failure) {
      httpClientNoAuth
        .post(urlSupport.auth.refreshAccessToken) {
          featureFlagsHeader()
          bearerAuth(refreshToken)
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun addNewUser(newUserInformation: UserInformation, password: String, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): AddNewUserResponse {
    return safeTryFetch(AddNewUserResponse.failure(AddNewUserResponse.Failure.ErrorType.FailToFetch)) {
      httpClientWithAuth
        .postJson(urlSupport.auth.addNewUser, AddNewUserRequest(newUserInformation, password)) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun changeUserInfo(updatedUserInformation: UserInformation, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): ChangeUserInfoResponse {
    return safeTryFetch(ChangeUserInfoResponse.failure(ChangeUserInfoResponse.Failure.ErrorType.FailToFetch)) {
      httpClientWithAuth
        .postJson(urlSupport.auth.changeUserInfo, ChangeUserInfoRequest(updatedUserInformation)) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun deleteUser(user: UserInformation, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): DeletedUserResponse {
    return safeTryFetch(DeletedUserResponse.failure(DeletedUserResponse.Failure.ErrorType.FailToFetch)) {
      httpClientWithAuth
        .postJson(urlSupport.auth.deleteUser, DeleteUserRequest(user)) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  /**
   * Changes the password for the given user
   */
  suspend fun changePassword(loginName: UserLoginName, /*oldPassword: String,*/ newPassword: String, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): ChangePasswordResponse {
    return safeTryFetch(ChangePasswordResponse.failure(ChangePasswordResponse.Failure.ErrorType.FailToFetch)) {
      httpClientWithAuth
        .postJson(urlSupport.auth.changePassword, ChangePasswordRequest(loginName, /*oldPassword,*/ newPassword)) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun addNewCompany(newCompanyInformation: PlannerCompanyInformation, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): AddNewCompanyResponse {
    return safeTryFetch(AddNewCompanyResponse.failure(AddNewCompanyResponse.Failure.ErrorType.FailToFetch)) {
      httpClientWithAuth
        .postJson(urlSupport.auth.addNewCompany, AddNewCompanyRequest(newCompanyInformation)) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun changeCompanyInfo(updatedCompanyInformation: PlannerCompanyInformation, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): ChangedCompanyInfoResponse {
    return safeTryFetch(ChangedCompanyInfoResponse.failure(ChangedCompanyInfoResponse.Failure.ErrorType.FailToFetch)) {
      httpClientWithAuth
        .postJson(urlSupport.auth.changeCompanyInfo, ChangeCompanyInfoRequest(updatedCompanyInformation)) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun fetchUsersAndCompanies(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): FetchUsersAndCompaniesResponse {
    return safeTryFetch(FetchUsersAndCompaniesResponse.failure()) {
      httpClientWithAuth
        .get(urlSupport.auth.listUsersAndCompanies) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }
}
