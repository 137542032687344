package it.neckar.lizergy.model.project.previews

import it.neckar.financial.currency.Money
import it.neckar.financial.currency.sum
import it.neckar.financial.quote.QuoteElements
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable

@Serializable
data class PreviewQuoteElements(
  val sumsForTags: Map<IncomePercentageCategory, Money>,
  val discountPercentage: @pct Double,
) {

  val total: Money
    get() = sumsForTags.values.sum()

  /**
   * Total of the invoice (for the given relevance) - without discount subtracted
   */
  fun subTotals(category: IncomePercentageCategory): Money {
    return sumsForTags.map {
      if (it.key == category) it.value else Money.Zero
    }.sum()
  }

  fun discounts(category: IncomePercentageCategory): Money {
    return subTotals(category).getDiscount()
  }

  /**
   * Returns the net value ("Netto") - discount removed - for only the given relevance
   */
  fun netPrices(category: IncomePercentageCategory): Money {
    return subTotals(category).toNetPrice()
  }


  fun Money.toNetPrice(): Money {
    return this * (1 - discountPercentage)
  }

  fun Money.getDiscount(): Money {
    return -this * discountPercentage
  }
}

fun QuoteElements.toPreviewQuoteElements(): PreviewQuoteElements {
  return PreviewQuoteElements(
    sumsForTags = IncomePercentageCategory.entries.associateWith {
      netPricesForVats(LizergyCalculationCategories.Query.Profits.get(it)).total
    },
    discountPercentage = discountPercentage,
  )
}
