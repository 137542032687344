package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.geometry.Coordinates

/**
 * Information where a module is placed within a grid
 */
class ModulePlacement(
  /**
   * The index within the grid
   */
  val moduleIndex: ModuleIndex,
  /**
   * The grid that is used to calculate the location
   */
  val moduleArea: ModuleArea,
) {
  /**
   * Returns the location using the known module index and grid
   */
  val location: Coordinates
    get() = moduleArea.calculateModuleOrigin(moduleIndex)

  /**
   * Returns the orientation of (all) modules within the grid
   */
  val orientation: ModuleOrientation
    get() = moduleArea.moduleOrientation
}



