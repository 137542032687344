@file:Suppress("DuplicatedCode")

package it.neckar.react.common.error

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.FontAwesome.faReload
import it.neckar.react.common.FontAwesome.faSignOut
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*

val ErrorPageHeader: FC<ErrorPageHeaderProps> = fc("ErrorPageHeader") { props ->
  val logoutAction = props::logoutAction.safeGet()
  val showReload = props.showReload ?: true

  nav("py-2 bg-light border-bottom") {
    div("container d-flex flex-wrap") {

      ul("nav mx-3") {
        if (showReload) {
          li("nav-item") {

            a(href = "/", classes = "btn btn-link nav-link link-dark px-2") {
              span("pe-2") {
                faReload()
              }
              +"Neu laden"
            }
          }
        }

        if (logoutAction != null) {
          li("nav-item") {
            button(classes = "btn btn-link nav-link link-dark px-2", type = ButtonType.button) {
              span("pe-2") {
                faSignOut()
              }
              +"Logout"
              attrs {
                onClickFunction = {
                  it.preventDefault()
                  logoutAction()
                }
              }
            }
          }
        }
      }
    }
  }
}

external interface ErrorPageHeaderProps : Props {
  var showReload: Boolean?
  var logoutAction: (() -> Unit)?
}
