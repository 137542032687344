package components.project.editor

import components.project.ensureElementInList
import components.restrictedUI
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.CompanyCode
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.user.UserLoginName
import react.*
import react.dom.*
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver
import store.hooks.useSelectUsersAndCompanies

/**
 * Form for an editor
 */
fun RBuilder.editorForm(
  editorTitle: String,
  forCompany: StateInstance<CompanyCode>,
  loginName: StateInstance<UserLoginName>,
  editableStatus: EditableStatus,
): Unit = child(EditorForm) {
  attrs {
    this.editorTitle = editorTitle
    this.forCompany = forCompany
    this.loginName = loginName
    this.editableStatus = editableStatus
  }
}

val EditorForm: FC<EditorFormProps> = fc("EditorForm") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val usersAndCompanies = useSelectUsersAndCompanies()

  val forCompany = props::forCompany.getNotNull()
  val loginName = props::loginName.getNotNull()

  val editorTitle = props::editorTitle.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val allCompanies = usersAndCompanies.activeCompanies(loggedInUser)
  val multipleCompaniesLoaded = allCompanies.size > 1
  val user = useMemo(loginName.value) {
    usersAndCompanies[loginName.value]
  }

  val companyCodes = allCompanies
    .map { it.companyCode }
    .ensureElementInList(forCompany.value)

  val usersForCompany = useMemo(forCompany.value, loginName.value) {
    usersAndCompanies.usersForCompany(forCompany.value).map { it.loginName }.ensureElementInList(loginName.value)
  }


  div {

    floatingSelect(
      valueAndSetter = forCompany,
      idProvider = { it.value },
      formatter = { usersAndCompanies[it].name },
      availableOptions = companyCodes,
      fieldName = "company",
      title = "Firma",
      editableStatus = editableStatus,
      additionalOnChange = { newCompanyCode ->
        if (user.company.companyCode != newCompanyCode) {
          loginName.setter(usersAndCompanies.defaultUserForCompany(newCompanyCode).loginName)
        }
      },
    ) {
      attrs {
        restrictedUI(loggedInUser) { multipleCompaniesLoaded }
        if (multipleCompaniesLoaded) mergedBelow()
      }
    }

    floatingSelect(
      valueAndSetter = loginName,
      idProvider = { it.value },
      formatter = { usersAndCompanies[it].editorName },
      availableOptions = usersForCompany,
      fieldName = "editor",
      title = editorTitle,
      editableStatus = editableStatus,
    ) {
      attrs {
        if (multipleCompaniesLoaded) mergedAbove()
      }
    }

  }

}

fun RBuilder.editorFormNullable(
  editorTitle: String,
  forCompany: StateInstance<CompanyCode>,
  loginName: StateInstance<UserLoginName?>,
  editableStatus: EditableStatus,
): Unit = child(EditorFormNullable) {
  attrs {
    this.editorTitle = editorTitle
    this.forCompany = forCompany
    this.loginName = loginName
    this.editableStatus = editableStatus
  }
}

val EditorFormNullable: FC<EditorFormNullableProps> = fc("EditorFormNullable") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val companyResolver = useSelectCompanyResolver()
  val userResolver = useSelectUserResolver()
  val usersAndCompanies = useSelectUsersAndCompanies()

  val forCompany = props::forCompany.getNotNull()
  val loginName = props::loginName.getNotNull()

  val editorTitle = props::editorTitle.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val allCompanies = usersAndCompanies.activeCompanies(loggedInUser)
  val multipleCompaniesLoaded = allCompanies.size > 1
  val user = useMemo(loginName.value) {
    loginName.value?.let {
      userResolver[it]
    }
  }

  val companyCodes = allCompanies
    .map { it.companyCode }
    .ensureElementInList(forCompany.value)

  val usersForCompany = useMemo(forCompany.value, loginName.value) {
    usersAndCompanies.usersForCompany(forCompany.value).map { it.loginName }.ensureElementInList(loginName.value)
  }


  div("col-6") {

    floatingSelect(
      valueAndSetter = forCompany,
      idProvider = { it.value },
      formatter = { companyResolver[it].name },
      availableOptions = companyCodes,
      fieldName = "company",
      title = "Firma",
      editableStatus = editableStatus,
      additionalOnChange = { newCompanyCode ->
        if (user != null && user.company.companyCode != newCompanyCode) {
          loginName.setter(usersAndCompanies.defaultUserForCompany(newCompanyCode).loginName)
        }
      },
    ) {
      attrs {
        restrictedUI(loggedInUser) { multipleCompaniesLoaded }
        if (multipleCompaniesLoaded) mergedBelow()
      }
    }

    floatingSelectNullable(
      valueAndSetter = loginName,
      idProvider = { it?.value ?: "none" },
      formatter = { it?.let { userResolver[it].editorName } ?: "-" },
      availableOptionsWithoutNull = usersForCompany,
      fieldName = "editor",
      title = editorTitle,
      editableStatus = editableStatus,
    ) {
      attrs {
        if (multipleCompaniesLoaded) mergedAbove()
      }
    }

  }

}

external interface EditorFormProps : Props {
  var editorTitle: String
  var forCompany: StateInstance<CompanyCode>
  var loginName: StateInstance<UserLoginName>
  var editableStatus: EditableStatus
}

external interface EditorFormNullableProps : Props {
  var editorTitle: String
  var forCompany: StateInstance<CompanyCode>
  var loginName: StateInstance<UserLoginName?>
  var editableStatus: EditableStatus
}

