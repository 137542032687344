package it.neckar.lizergy.model.project.process.state

import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import it.neckar.processStates.ProcessStateEntryId
import it.neckar.processStates.ProcessStatePhase
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("AssemblyPortfolioProcessStateEntry")
data class AssemblyPortfolioProcessStateEntry(
  override val id: ProcessStateEntryId,
  override val processState: AssemblyPortfolioProcessStates,
  override val assignedTo: UserLoginName,
  override val belongsTo: CompanyCode,
  override val assignedAt: @Serializable(with = DoubleAsIsoDateTimeSerializer::class) @ms Double,
  override val assignedBy: UserLoginName,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : LizergyProcessStateEntry {

  override val availableOptions: List<AssemblyPortfolioProcessStates>
    get() = AssemblyPortfolioProcessStates.entries

  override fun format(i18nConfiguration: I18nConfiguration): String {
    return processState.format(i18nConfiguration)
  }

  @Serializable
  @SerialName("AssemblyPortfolioProcessStates")
  enum class AssemblyPortfolioProcessStates : LizergyProcessStates {
    Missing,
    BeingEdited,
    BeingVerified,
    Accepted,

    ;

    override val inPhase: ProcessStatePhase
      get() = when (this) {
        Missing -> ProcessStatePhase.Untouched
        BeingEdited -> ProcessStatePhase.InProgress
        BeingVerified -> ProcessStatePhase.Waiting
        Accepted -> ProcessStatePhase.Done
      }

    override fun format(i18nConfiguration: I18nConfiguration): String {
      return when (this) {
        Missing -> "MM fehlt"
        BeingEdited -> "MM in Bearbeitung"
        BeingVerified -> "MM in Prüfung"
        Accepted -> "MM freigegeben"
      }
    }

    override fun formatDate(date: Double, i18nConfiguration: I18nConfiguration): String {
      return "seit ${dateFormat.format(date, i18nConfiguration)}"
    }
  }
}
