package it.neckar.lizergy.model.project

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot.QuoteSnapshotId
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.previews.PreviewQuoteElements
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable

@Serializable
data class Verification(
  override val uuid: @Serializable(with = UuidSerializer::class) Uuid,
  val verifiedBy: UserLoginName,
  val verifiedAt: @Serializable(with = DoubleAsIsoDateTimeSerializer::class) @ms Double,
  val projectId: PhotovoltaicsProjectId,
  val projectProcessStateEntry: ProjectProcessStateEntry?,
  val blueprintProcessStateEntry: BlueprintProcessStateEntry?,
  val configurationIdAndProcessStateEntries: List<ConfigurationIdAndProcessStateEntry>,
  val orderSpecialMaterialProcessStateEntry: OrderSpecialMaterialProcessStateEntry?,
  val gridAssessmentProcessStateEntry: GridAssessmentProcessStateEntry?,
  val assemblyPortfolioProcessStateEntry: AssemblyPortfolioProcessStateEntry?,
  val advanceInvoiceProcessStateEntry: AdvanceInvoiceProcessStateEntry?,
  val assemblyRoofProcessStateEntry: LizergyProcessStateEntry?,
  val assemblyBasementProcessStateEntry: LizergyProcessStateEntry?,
  val switchMeterBoxProcessStateEntry: SwitchMeterBoxProcessStateEntry?,
  val startupOperationsProcessStateEntry: StartupOperationsProcessStateEntry?,
  val finishingUpProcessStateEntry: FinishingUpProcessStateEntry?,
  val finalAccountProcessStateEntry: FinalAccountProcessStateEntry?,
  val documentationProcessStateEntry: DocumentationProcessStateEntry?,
  val archiveReasons: ArchiveReasons,
  val earningsDistribution: ResolvedEarningsDistribution?,
  val quoteElements: PreviewQuoteElements?,
  val manualQuoteElements: ManualQuoteElements?,
  val additionalInfo: String,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : HasUuid, HasLifeCycle {
  @Serializable
  data class ConfigurationIdAndProcessStateEntry(
    val configurationId: PhotovoltaicsConfigurationId,
    val quoteSnapshotId: QuoteSnapshotId?,
    val processStateEntry: LizergyProcessStateEntry,
  )
}
