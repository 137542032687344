package it.neckar.lizergy.model.configuration.energy.selfsufficiency

import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable

/**
 * Describes the origin of the consumed power.
 */
sealed interface PowerConsumptionDistribution {
  /**
   * The percentage of consumed power that is not drawn from the grid
   */
  val selfProducedPercentage: @pct Double

  /**
   * The percentage of consumed that is drawn from the grid
   */
  val fromGridPercentage: @pct Double
    get() = 1.0 - selfProducedPercentage
}

/**
 * Output class for calculateSelfSufficiencyDistribution
 * Each value is a percentage of the consumed electricity each moment and where it's from
 * (i.e. from the PV system, from the battery, or bought from the grid)
 */
@Serializable
data class CalculatedPowerConsumptionDistribution(
  /**
   * The percentage of the *consumed* power that is provided by the PV system directly (without battery storage)
   */
  val directlyFromPVPercentage: @pct Double,
  /**
   * The percentage of the *consumed* power that is provided by the battery storage
   */
  val fromBatteryPercentage: @pct Double
) : PowerConsumptionDistribution {
  override val selfProducedPercentage: @pct Double
    get() = directlyFromPVPercentage + fromBatteryPercentage
}

/**
 * Used when the power consumption is not calculated but put in manually
 */
@Serializable
data class ManualPowerConsumptionDistribution(
  override val selfProducedPercentage: @pct Double
) : PowerConsumptionDistribution

