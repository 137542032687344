package store.actions

import it.neckar.common.redux.ChangeType
import store.PlannerClientState
import store.ServicesOnlineState

/**
 * Updated the online state
 */
data class OnlineStateChangedAction(val newOnlineState: ServicesOnlineState) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(onlineState = newOnlineState)
  }
}
