package it.neckar.lizergy.model.configuration.energy

import it.neckar.open.formatting.decimalFormat
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.kW
import it.neckar.open.unit.si.km
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents energy capacity in kW
 */
@Serializable
@JvmInline
value class Capacity(
  val kW: @kW Double,
) : Comparable<Capacity> {

  constructor(kW: @kW Int) : this(
    kW.toDouble()
  )

  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "${decimalFormat.format(kW, i18nConfiguration)}${whitespaceConfig.smallSpace} kW"
  }

  override operator fun compareTo(other: Capacity): Int {
    return this.kW.compareTo(other.kW)
  }

  operator fun minus(other: Capacity): Capacity {
    return Capacity(this.kW - other.kW)
  }

  operator fun plus(other: Capacity): Capacity {
    return Capacity(this.kW + other.kW)
  }

  operator fun div(other: Capacity): @pct Double {
    return this.kW / other.kW
  }

  operator fun div(divisor: Double): Capacity {
    return Capacity(this.kW / divisor)
  }

  operator fun times(factor: @pct Double): Capacity {
    return Capacity(kW * factor)
  }

  operator fun times(factor: @km Int): Capacity {
    return Capacity(kW * factor)
  }

  override fun toString(): String {
    return format(I18nConfiguration.US, WhitespaceConfig.Spaces)
  }

  companion object {
    val Zero: Capacity = Capacity(0.0)

    fun kW(kW: @kW Double): Capacity {
      return Capacity(kW)
    }
  }
}

/**
 * Calculates the sum of multiple [Capacity] objects
 */
fun Iterable<Capacity>.sum(): Capacity {
  return Capacity(sumOf { it.kW })
}

/**
 * Converts this double into an [Capacity]
 */
val Double.kW: Capacity
  get() = Capacity.kW(this)
