package it.neckar.lizergy.model.project

import com.benasher44.uuid.Uuid
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

@Serializable
data class ArchiveReasons(
  val id: ArchiveReasonsId = ArchiveReasonsId.random(),
  val noLongerInterested: Boolean = false,
  val otherSellerWasCheaper: Boolean = false,
  val otherSellerInformedBetter: Boolean = false,
  val otherSellerWasFaster: Boolean = false,
  val quoteWasNotProfitable: Boolean = false,
  val tookTooLong: Boolean = false,
  val noResponseFromCustomer: Boolean = false,
  val deliveryTimesTooLing: Boolean = false,
  val customerWantedDifferentManufacturer: Boolean = false,
  val customerWantedCloudSolution: Boolean = false,
  val tooExpensive: Boolean = false,
  val waitingTimeTooLong: Boolean = false,
  val otherReasons: String = "",
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  fun format(separator: String = "\n"): String {
    return buildList {
      if (noLongerInterested) add("Aktuell nicht mehr interessiert")
      if (otherSellerWasCheaper) add("Anderer Anbieter war günstiger")
      if (otherSellerInformedBetter) add("Anderer Anbieter hat besser beraten")
      if (otherSellerWasFaster) add("Anderer Anbieter war schneller")
      if (quoteWasNotProfitable) add("Angebot war nicht wirtschaftlich")
      if (tookTooLong) add("Bearbeitungszeit zu lang")
      if (noResponseFromCustomer) add("Keine Rückmeldung vom Kunden")
      if (deliveryTimesTooLing) add("Zu lange Lieferzeiten")
      if (customerWantedDifferentManufacturer) add("Möchte anderen Hersteller (z.B. Fronius, E3/DC, usw.). Bitte under Sonstige Begründung")
      if (customerWantedCloudSolution) add("Möchte Cloudlösung")
      if (tooExpensive) add("Zu teuer")
      if (waitingTimeTooLong) add("Wartezeit zu lang")
      if (otherReasons.isNotBlank()) add("Sonstige Begründung: $otherReasons")
    }.joinToString(separator)
  }

  @Serializable
  data class ArchiveReasonsId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ArchiveReasonsId {
        return ArchiveReasonsId(randomUuid4())
      }
    }
  }

  companion object {
    fun getEmpty(archiveReasonsId: ArchiveReasonsId = ArchiveReasonsId.random()): ArchiveReasons {
      return ArchiveReasons(archiveReasonsId)
    }
  }
}
