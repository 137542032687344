package components.project.blueprint

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.react.common.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import react.router.*
import router.PathConstants
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

val BlueprintOverviewFromUrl: FC<Props> = fc("BlueprintOverviewFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { project?.getDisplayName(it) })

  busyIfNull(project) { loadedProject ->
    BlueprintOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val BlueprintOverview: FC<BlueprintOverviewProps> = fc("BlueprintOverview") { props ->
  val project = props::project.safeGet()

  val processStateForBlueprint = useLoadProcessStatesForComponent(project.blueprint.uuid)

  val advancedTabs = if (processStateForBlueprint?.current() is BlueprintProcessStateEntry) {
    TabButtonStatus.Normal
  } else {
    TabButtonStatus.Disabled
  }


  div {

    tabButtonsPane {

      tabButton(NavigateUrl.empty, "Kunde und Standort")

      tabButton(BlueprintTabs.moduleLayouts, "Dachflächen", advancedTabs)

      tabButton(BlueprintTabs.profitability, "Wirtschaftlichkeitsberechnung", advancedTabs)

      tabButton(BlueprintTabs.facility, "Anlage & Montage", advancedTabs)

      tabButton(BlueprintTabs.electricityWorkEffort, "Zählerschrankarbeiten", advancedTabs)

      tabButton(BlueprintTabs.wallbox, "E-Auto", advancedTabs)

      tabButton(BlueprintTabs.additional, "Zusätzliches", advancedTabs)

      tabButton(BlueprintTabs.finish, "Abschluss", advancedTabs)

    }

  }

  Outlet {}

}


external interface BlueprintOverviewProps : Props {
  var project: ResolvedProject
}

object BlueprintTabs {
  val location: NavigateUrl = NavigateUrl(PathConstants.location)
  val moduleLayouts: NavigateUrl = NavigateUrl(PathConstants.moduleLayouts)
  val profitability: NavigateUrl = NavigateUrl(PathConstants.profitability)
  val facility: NavigateUrl = NavigateUrl(PathConstants.facility)
  val wallbox: NavigateUrl = NavigateUrl(PathConstants.wallbox)
  val electricityWorkEffort: NavigateUrl = NavigateUrl(PathConstants.electricityWorkEffort)
  val additional: NavigateUrl = NavigateUrl(PathConstants.additional)
  val finish: NavigateUrl = NavigateUrl(PathConstants.finish)
}
