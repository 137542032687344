package components.project.configuration.layout

import com.meistercharts.model.Insets
import components.form.commentSection
import components.project.blueprint.EditRoof
import components.project.blueprint.roofSizeForm
import components.project.configuration.isConfigurationEditable
import components.project.configuration.layout.moduleLayout.moduleLayoutForm
import components.project.configuration.layout.moduleLayout.moduleLayoutInsetsForm
import components.project.configuration.layout.moduleLayout.planning.moduleAreaPlanning
import components.project.freezableFormButtons
import components.project.isProjectEditable
import components.project.validMetalRoofTileColors
import components.project.validModuleTypes
import components.project.validRoofTileTypes
import components.project.validRoofTypes
import components.project.validationTable
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.PvModuleTypeMaterial
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.planning.ModuleAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.PvRoofPlanningModelInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.UnusableAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoof
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.open.unit.si.mm
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.router.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import router.useModuleLayoutIdFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useSelectAvailableProducts
import kotlin.math.roundToInt

/**
 * Edits a moduleLayout
 */
val EditConfigurationModuleLayoutFromUrl: FC<Props> = fc("EditConfigurationModuleLayoutFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val moduleLayoutId = useModuleLayoutIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Modulflächen", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName, resolvedProject?.get(configurationId)?.get(moduleLayoutId)?.layoutName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())
    val resolvedModuleLayout = quoteConfiguration[moduleLayoutId]

    EditConfigurationModuleLayout {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.moduleLayout = resolvedModuleLayout
        this.editableStatus = editableStatus
      }
    }
  }
}

val EditConfigurationModuleLayout: FC<EditConfigurationModuleLayoutProps> = fc("EditConfigurationModuleLayout") { props ->
  val navigate = useNavigateUrl()
  val availableProducts = useSelectAvailableProducts()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val initialModuleLayout = props::moduleLayout.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val initialRoof = initialModuleLayout.roof

  @mm val roofWidth = useState(initialRoof.size?.width?.roundToInt())
  @mm val roofHeight = useState(initialRoof.size?.height?.roundToInt())

  val roofInclination = useState(initialRoof.inclination?.angle)
  val roofOrientation = useState(initialRoof.orientation?.orientation)

  val description = useState(initialModuleLayout.description.orEmpty())
  val moduleType = useState(initialModuleLayout.moduleType)
  val blackModules = useState(moduleType.value.material == PvModuleTypeMaterial.GlassGlass)

  val suggestedRoofInsetsLeft = useState(initialModuleLayout.suggestedRoofInsets.left.roundToInt())
  val suggestedRoofInsetsRight = useState(initialModuleLayout.suggestedRoofInsets.right.roundToInt())
  val suggestedRoofInsetsTop = useState(initialModuleLayout.suggestedRoofInsets.top.roundToInt())
  val suggestedRoofInsetsBottom = useState(initialModuleLayout.suggestedRoofInsets.bottom.roundToInt())

  val moduleAreasInformation = useState(initialModuleLayout.moduleAreasInformation)
  val unusableAreasInformation = useState(initialModuleLayout.unusableAreasInformation)

  val resolvedRoofToSave = useState(initialRoof)

  useMemo(blackModules.value) {
    availableProducts.availableModules().first {
      when (blackModules.value) {
        true -> it.material == PvModuleTypeMaterial.GlassGlass
        false -> it.material == PvModuleTypeMaterial.Normal
      }
    }.let { newModuleType ->
      if (newModuleType.material != moduleType.value.material) moduleType.setter(newModuleType)
    }
  }

  useMemo(moduleType.value) {
    when (moduleType.value.material) {
      PvModuleTypeMaterial.GlassGlass -> true
      PvModuleTypeMaterial.Normal -> false
    }.let { newBlackModules ->
      if (newBlackModules != blackModules.value) blackModules.setter(newBlackModules)
    }
  }

  val suggestedRoofInsets = useMemo(
    suggestedRoofInsetsLeft.value,
    suggestedRoofInsetsRight.value,
    suggestedRoofInsetsTop.value,
    suggestedRoofInsetsBottom.value,
  ) {
    Insets.of(
      top = suggestedRoofInsetsTop.value,
      right = suggestedRoofInsetsRight.value,
      bottom = suggestedRoofInsetsBottom.value,
      left = suggestedRoofInsetsLeft.value,
    )
  }

  val roofSize = resolvedRoofToSave.value.size
  val currentPlanningModel = useMemo(
    moduleType.value.size,
    roofSize,
    suggestedRoofInsets,
    moduleAreasInformation.value,
    unusableAreasInformation.value,
  ) {
    if (roofSize != null) {
      PvRoofPlanningModelInformation(
        moduleSize = moduleType.value.size,
        roofSize = roofSize,
        suggestedRoofInsets = suggestedRoofInsets,
        moduleAreasInformation = moduleAreasInformation.value,
        unusableAreasInformation = unusableAreasInformation.value,
      )
    } else null
  }

  val resolvedModuleLayoutToSave = useMemo(
    description.value,
    moduleType.value,
    resolvedRoofToSave.value,
    suggestedRoofInsets,
    moduleAreasInformation.value,
    unusableAreasInformation.value,
  ) {
    initialModuleLayout.copy(
      description = description.value.nullIfBlank(),
      moduleType = moduleType.value,
      roof = resolvedRoofToSave.value,
      suggestedRoofInsets = suggestedRoofInsets,
      moduleAreasInformation = moduleAreasInformation.value,
      unusableAreasInformation = unusableAreasInformation.value,
    )
  }

  val okAction = {
    UiActions.saveModuleLayout(project, quoteConfiguration, resolvedModuleLayoutToSave)
    navigate.back()
  }


  div {
    if (editableStatus == Editable) {
      onEnter { okAction() }
    }

    //div("mb-5") {
    //  h3("mb-2") {
    //    +"Bemerkungen Modulbelegung"
    //  }
    //  commentSection(initialModuleLayout)
    //}

    h2 {
      +"Modul-Belegung"
    }

    roofSizeForm(
      roofWidth = roofWidth,
      roofHeight = roofHeight,
      roofInclination = roofInclination,
      roofOrientation = roofOrientation,
      editableStatus = editableStatus
    )

    EditModuleLayout {
      attrs {
        this.descriptionToSave = description
        this.moduleTypeToSave = moduleType
        this.blackModules = blackModules
        this.suggestedRoofInsetsLeft = suggestedRoofInsetsLeft
        this.suggestedRoofInsetsRight = suggestedRoofInsetsRight
        this.suggestedRoofInsetsTop = suggestedRoofInsetsTop
        this.suggestedRoofInsetsBottom = suggestedRoofInsetsBottom
        this.moduleAreasInformationToSave = moduleAreasInformation
        this.unusableAreasInformationToSave = unusableAreasInformation
        this.descriptionPlaceholder = "${resolvedModuleLayoutToSave.roof.roofName} ${resolvedModuleLayoutToSave.peakPower.formatKiloWattPeak()}"
        this.initialModuleLayout = initialModuleLayout
        this.currentPlanningModel = currentPlanningModel
        this.availableModuleTypes = availableProducts.validModuleTypes(initialModuleLayout.moduleType)
        this.resolvedRoof = resolvedRoofToSave.value
        this.editableStatus = editableStatus
      }
    }

    EditRoof {
      attrs {
        this.resolvedRoofToSave = resolvedRoofToSave
        this.roofWidth = roofWidth
        this.roofHeight = roofHeight
        this.roofInclination = roofInclination
        this.roofOrientation = roofOrientation
        this.availableRoofTypes = availableProducts.validRoofTypes(initialRoof.roofType)
        this.availableRoofTiles = initialRoof.roofTile?.let { availableProducts.validRoofTileTypes(it) } ?: availableProducts.availableRoofTiles()
        this.availableMetalRoofTileColors = initialRoof.metalRoofTileConstructionType?.color?.let { availableProducts.validMetalRoofTileColors(it) } ?: availableProducts.availableMetalRoofTileColors()
        this.totalModulesCount = resolvedModuleLayoutToSave.modulesCount
        this.blackModules = blackModules.value
        this.editableStatus = editableStatus
      }
    }

    div("mb-5") {
      h3("mb-2") {
        +"Bemerkungen Dachfläche"
      }
      project.blueprint.roofAnnotations.firstOrNull { it.id == initialRoof.id }?.let {
        it.annotation?.let { p { +it } }
      }
      commentSection(initialRoof)
    }

    freezableFormButtons(editableStatus) { okAction() }

    div("my-5") {
      validationTable(resolvedModuleLayoutToSave.validationProblems)
    }
  }

}

val EditModuleLayout: FC<EditModuleLayoutProps> = fc("EditModuleLayout") { props ->
  val descriptionToSave = props::descriptionToSave.getNotNull()
  val moduleTypeToSave = props::moduleTypeToSave.getNotNull()
  val blackModules = props::blackModules.getNotNull()
  val suggestedRoofInsetsLeft = props::suggestedRoofInsetsLeft.getNotNull()
  val suggestedRoofInsetsRight = props::suggestedRoofInsetsRight.getNotNull()
  val suggestedRoofInsetsTop = props::suggestedRoofInsetsTop.getNotNull()
  val suggestedRoofInsetsBottom = props::suggestedRoofInsetsBottom.getNotNull()
  val moduleAreasInformationToSave = props::moduleAreasInformationToSave.getNotNull()
  val unusableAreasInformationToSave = props::unusableAreasInformationToSave.getNotNull()

  val currentPlanningModel = props::currentPlanningModel.safeGet()
  val availableModuleTypes = props::availableModuleTypes.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val descriptionPlaceholder = props::descriptionPlaceholder.safeGet()

  val modulesCount = moduleAreasInformationToSave.value.modulesCount()


  div {

    moduleLayoutForm(
      label = descriptionToSave,
      placeholder = descriptionPlaceholder,
      moduleType = moduleTypeToSave,
      blackModules = blackModules,
      availableModuleTypes = availableModuleTypes,
      editableStatus = editableStatus,
    )

    div {

      currentPlanningModel?.let {
        div("row mb-3") {
          div("col-sm-12") {
            moduleAreaPlanning(
              model = it,
              moduleType = moduleTypeToSave.value,
              editableStatus = editableStatus,
            ) { updated ->
              //Update grid, unusable areas and modules when changed
              moduleAreasInformationToSave.setter(updated.moduleAreasInformation)
              unusableAreasInformationToSave.setter(updated.unusableAreasInformation)
            }
          }
        }
      }

      div(classes = "row mb-3") {
        div("col-sm-12") {
          moduleLayoutInsetsForm(
            suggestedRoofInsetsLeft = suggestedRoofInsetsLeft,
            suggestedRoofInsetsRight = suggestedRoofInsetsRight,
            suggestedRoofInsetsTop = suggestedRoofInsetsTop,
            suggestedRoofInsetsBottom = suggestedRoofInsetsBottom,
            editableStatus = editableStatus,
          )
        }
      }

      p("form-text") {
        +"$modulesCount Module | Leistung: ${(moduleTypeToSave.value.powerRating * modulesCount).formatKiloWattPeak(plannerI18nConfiguration)}"
      }

    }

  }
}


external interface EditConfigurationModuleLayoutProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
  var moduleLayout: ResolvedModuleLayout
  var editableStatus: EditableStatus
}

external interface EditModuleLayoutProps : Props {
  var descriptionToSave: StateInstance<String>
  var moduleTypeToSave: StateInstance<PvModule>
  var blackModules: StateInstance<Boolean>
  var suggestedRoofInsetsLeft: StateInstance<Int>
  var suggestedRoofInsetsRight: StateInstance<Int>
  var suggestedRoofInsetsTop: StateInstance<Int>
  var suggestedRoofInsetsBottom: StateInstance<Int>
  var moduleAreasInformationToSave: StateInstance<ModuleAreasInformation>
  var unusableAreasInformationToSave: StateInstance<UnusableAreasInformation>
  var descriptionPlaceholder: String?
  var currentPlanningModel: PvRoofPlanningModelInformation?
  var initialModuleLayout: ResolvedModuleLayout
  var availableModuleTypes: List<PvModule>
  var resolvedRoof: ResolvedRoof
  var editableStatus: EditableStatus
}
