package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.configuration.quote.economics.EquityFinanced
import it.neckar.lizergy.model.configuration.quote.economics.FinancesInformation
import it.neckar.lizergy.model.configuration.quote.economics.FinancingType
import it.neckar.lizergy.model.configuration.quote.economics.FixedRateMortgageCalculator
import it.neckar.lizergy.model.configuration.quote.economics.Leveraged
import it.neckar.lizergy.model.configuration.quote.economics.YearIndex
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable

/**
 * Contains information about financing
 */
@Serializable
data class FinancingInformation(
  /**
   * The residual debt at the start of the corresponding period *before* payment of the [repayment]
   */
  val residualDebtBeforeRepayment: Money,

  /**
   * The interest rate
   * "Zinssatz"
   */
  val interestRate: @pct Double,

  /**
   * The interest for this year
   * "Zins"
   */
  val interest: Money,

  /**
   * "Tilgung"
   */
  val repayment: Money,

  ) {

  val spending: Money
    get() {
      return interest + repayment
    }

  /**
   * "Restschuld" (after payment of [repayment])
   */
  val residualDebtAfterRepayment: Money
    get() {
      return residualDebtBeforeRepayment.minus(repayment)
    }

  class Calculator(
    /**
     * The initial dept at the start
     */
    var initialDept: Money,

    /**
     * The interest rate
     */
    var interestRate: @pct Double,

    /**
     * "Laufzeit in Jahren"
     */
    var termInYears: Int = 20
  ) {
    /**
     * Calculates the financing information for the given year index
     */
    fun calculate(yearIndex: YearIndex): FinancingInformation {
      val fixedRateMortgageCalculator = FixedRateMortgageCalculator(initialDept, interestRate, termInYears)
      val interest = fixedRateMortgageCalculator.yearlyInterest(yearIndex)
      val repayment = fixedRateMortgageCalculator.yearlyRepayment(yearIndex)

      return FinancingInformation(
        residualDebtBeforeRepayment = fixedRateMortgageCalculator.residualDebtAtStartOfYear(yearIndex),
        interestRate = interestRate,
        interest = interest,
        repayment = repayment
      )
    }

    companion object {

      operator fun invoke(financesInformation: FinancesInformation): Calculator {
        return invoke(financesInformation.invest, financesInformation.financing)
      }

      operator fun invoke(
        /**
         * The net costs of the PV system (without optional costs)
         */
        netCosts: Money,
        financingType: FinancingType
      ): Calculator {
        return when (financingType) {
          EquityFinanced -> {
            Calculator(Money.Zero, 1.0) //fallback interest rate that is > 0
          }
          is Leveraged -> {
            Calculator(netCosts, financingType.interestRate)
          }
        }
      }
    }
  }
}
