package it.neckar.rest.pagination

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents the size of a page
 */
@Serializable
@JvmInline
value class PageSize(
  /**
   * A page size of "-1" represents an unlimited page size
   */
  val value: Int,
) {

  init {
    require(value >= -1) { "The page size must be greater than or equal to -1 but was $value" }
  }

  /**
   * Returns true if the page size is unlimited
   */
  fun isUnlimited(): Boolean {
    return value == -1
  }

  companion object {
    /**
     * The default page size
     */
    val Default: PageSize = PageSize(20)

    /**
     * Represents an unlimited page size
     */
    val Unlimited: PageSize = PageSize(-1)
  }
}
