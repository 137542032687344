package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.financial.currency.Money
import it.neckar.open.formatting.format
import it.neckar.open.unit.currency.Cent
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Special class that represent a price per kWh.
 *
 * This class wraps a double value to be able to support the higher precision necessary.
 * It is *not* allowed to add/subtract these values
 */
@Serializable
@JvmInline
value class PricePerKWh internal constructor(val euros: @EUR Double) {

  /**
   * Returns the price in cents
   */
  val cents: @Cent Double
    get() = euros * 100.0

  @Deprecated("not allowed because for precision", level = DeprecationLevel.ERROR)
  operator fun minus(other: Double): PricePerKWh {
    throw UnsupportedOperationException("do not add/subtract these values")
  }

  @Deprecated("not allowed because for precision", level = DeprecationLevel.ERROR)
  operator fun plus(other: Double): PricePerKWh {
    throw UnsupportedOperationException("do not add/subtract these values")
  }

  /**
   * Multiplies this value with an amount of energy
   */
  operator fun times(amount: it.neckar.lizergy.model.configuration.energy.AmountOfEnergy): Money {
    return Money.euros(euros * amount.kWh)
  }

  operator fun times(factor: Double): PricePerKWh {
    return PricePerKWh(euros * factor)
  }

  fun increasingBy(increase: @pct Double): IncreasingPricePerKWh {
    return IncreasingPricePerKWh(this, increase)
  }

  override fun toString(): String {
    return "$euros € / kWh"
  }

  fun format(): String {
    return "${(euros * 100.0).format(2)} Cent / kWh"
  }

  companion object {
    val zero: PricePerKWh = PricePerKWh(0.0)

    /**
     * Creates a price per kWh object from cents
     */
    fun cents(cents: @EUR Double): PricePerKWh {
      return PricePerKWh(cents / 100.0)
    }

    fun euro(euro: @EUR Double): PricePerKWh {
      return PricePerKWh(euro)
    }
  }
}

