package components.project.blueprint

import components.form.commentSection
import components.project.BlueprintTabProps
import components.project.configuration.facility.assembly.ScaffoldingStatus
import components.project.configuration.facility.assembly.assemblyForm
import components.project.isProjectEditable
import i18n.EnumTranslator
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.configuration.components.ScaffoldingArea
import it.neckar.lizergy.model.configuration.components.ScaffoldingProvided
import it.neckar.lizergy.model.configuration.components.SelfAssemblyConfiguration
import it.neckar.open.kotlin.lang.enumEntries
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useSelectPriceList

val EditBlueprintFacilityFromUrl: FC<Props> = fc("EditBlueprintFacilityFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Anlage & Montage", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) })

  busyIfNull(resolvedProject) { loadedProject ->
    EditBlueprintFacility {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val EditBlueprintFacility: FC<BlueprintTabProps> = fc("EditBlueprintFacility") { props ->
  val navigate = useNavigateUrl()
  val priceList = useSelectPriceList()

  val project = props::project.safeGet()
  val blueprint = project.blueprint

  val facilityConfiguration = blueprint.facilityConfiguration
  val editableStatus = project.isProjectEditable()

  val battery = useState(blueprint.battery)
  val kabelwegZugschlagLength = useState(facilityConfiguration.kabelwegZugschlagLength)

  val heizstabToSave = useState(facilityConfiguration.heizstab)
  val waermepumpenanbindungToSave = useState(facilityConfiguration.waermepumpenanbindung)

  val onlineMonitoringToSave = useState(facilityConfiguration.onlineMonitoring)
  val integrateInverterIntoNetworkToSave = useState(facilityConfiguration.integrateInverterIntoNetwork)
  val erdspiessSetzenToSave = useState(facilityConfiguration.erdspiessSetzen)

  val assemblyConfiguration = blueprint.assemblyConfiguration
  val assemblyStatus = assemblyConfiguration.assemblyStatus
  val willBeSelfAssembled = useState(
    when (assemblyStatus) {
      is ScaffoldingArea -> ScaffoldingStatus.ConstructionHandledByLizergy
      ScaffoldingProvided -> ScaffoldingStatus.OnlyScaffoldingProvided
      is SelfAssemblyConfiguration -> ScaffoldingStatus.SelfAssembled
    }
  )

  val dachhakenSetzen = useState(assemblyStatus.dachhakenSetzen ?: false)
  val dachhakenGestellt = useState(assemblyStatus.dachhakenGestellt ?: false)
  val schienenMontage = useState(assemblyStatus.schienenMontage ?: false)
  val kabelZiehenUndModuleMontieren = useState(assemblyStatus.kabelZiehenUndModuleMontieren ?: false)
  val scaffoldingArea = useState(assemblyConfiguration.assemblyStatus.scaffoldingArea)

  val dachstaenderIsolierung = useState(assemblyConfiguration.dachstaenderIsolierung)
  val moveSatelliteDish = useState(assemblyConfiguration.moveSatelliteDish)
  val removeAntenna = useState(assemblyConfiguration.removeAntenna)
  val externerBlitzschutzVorhanden = useState(assemblyConfiguration.externerBlitzschutzVorhanden)

  val okAction = {
    navigate(RouterUrls.blueprint(project).electricityWorkEffort)
  }


  useMemo(
    battery.value,
    kabelwegZugschlagLength.value,
    heizstabToSave.value,
    waermepumpenanbindungToSave.value,
    onlineMonitoringToSave.value,
    integrateInverterIntoNetworkToSave.value,
    erdspiessSetzenToSave.value,
    willBeSelfAssembled.value,
    dachhakenSetzen.value,
    dachhakenGestellt.value,
    schienenMontage.value,
    kabelZiehenUndModuleMontieren.value,
    scaffoldingArea.value,
    dachstaenderIsolierung.value,
    moveSatelliteDish.value,
    removeAntenna.value,
    externerBlitzschutzVorhanden.value,
  ) {
    val assemblyStatusToSave = when (willBeSelfAssembled.value) {
      ScaffoldingStatus.ConstructionHandledByLizergy -> ScaffoldingArea(scaffoldingArea.value)
      ScaffoldingStatus.OnlyScaffoldingProvided -> ScaffoldingProvided
      ScaffoldingStatus.SelfAssembled -> SelfAssemblyConfiguration(
        dachhakenSetzen = dachhakenSetzen.value,
        schienenMontage = schienenMontage.value,
        kabelZiehenUndModuleMontieren = kabelZiehenUndModuleMontieren.value,
        dachhakenGestellt = dachhakenGestellt.value,
      )
    }

    blueprint.copy(
      battery = battery.value,
      facilityConfiguration = facilityConfiguration.copy(
        kabelwegZugschlagLength = kabelwegZugschlagLength.value,
        heizstab = heizstabToSave.value,
        waermepumpenanbindung = waermepumpenanbindungToSave.value,
        onlineMonitoring = onlineMonitoringToSave.value,
        integrateInverterIntoNetwork = integrateInverterIntoNetworkToSave.value,
        erdspiessSetzen = erdspiessSetzenToSave.value,
      ),
      assemblyConfiguration = assemblyConfiguration.copy(
        dachstaenderIsolierung = dachstaenderIsolierung.value,
        moveSatelliteDish = moveSatelliteDish.value,
        removeAntenna = removeAntenna.value,
        externerBlitzschutzVorhanden = externerBlitzschutzVorhanden.value,
        assemblyStatus = assemblyStatusToSave,
      ),
    ).also {
      UiActions.saveBlueprint(it)
    }
  }


  div("my-4") {
    onEnter { okAction() }

    h1("my-3") {
      +"Anlage & Montage"
    }

    div("col-sm-6 my-3") {
      floatingSelectEnum(
        valueAndSetter = battery,
        fieldName = "battery",
        title = "Batterie",
        formatter = EnumTranslator.ConfiguredOptionality,
        availableOptions = enumEntries(),
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
        }
      }

      floatingDoubleInputField(
        valueAndSetter = kabelwegZugschlagLength,
        fieldName = "kabelwegZugschlagLength",
        title = "Zugschlag für Kabelweg (m)",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
        }
      }
    }

    blueprintMiscFacilityForm(
      heizstabToSave = heizstabToSave,
      waermepumpenanbindungToSave = waermepumpenanbindungToSave,
      onlineMonitoringToSave = onlineMonitoringToSave,
      integrateInverterIntoNetworkToSave = integrateInverterIntoNetworkToSave,
      erdspiessSetzenToSave = erdspiessSetzenToSave,
      editableStatus = editableStatus,
    )

    assemblyForm(
      dachstaenderIsolierung = dachstaenderIsolierung,
      moveSatelliteDish = moveSatelliteDish,
      removeAntenna = removeAntenna,
      willBeSelfAssembled = willBeSelfAssembled,
      dachhakenSetzen = dachhakenSetzen,
      schienenMontage = schienenMontage,
      kabelZiehenUndModuleMontieren = kabelZiehenUndModuleMontieren,
      dachhakenGestellt = dachhakenGestellt,
      scaffoldingArea = scaffoldingArea,
      scaffoldingAreaFromRoofs = blueprint.moduleLayouts.scaffoldingAreas().values.sum(),
      priceList = priceList,
      editableStatus = editableStatus,
      externerBlitzschutzVorhanden = externerBlitzschutzVorhanden,
    )

    div("my-5") {
      h3("mb-3") {
        +"Bemerkungen Anlage & Montage"
      }
      project.blueprint.facilityAnnotation?.let {
        p { +it }
      }
      commentSection(blueprint.facilityConfiguration)
    }

    wizardFormButtons(
      backwardAction = { navigate(RouterUrls.blueprint(project).profitability) },
      forwardAction = { okAction() },
    )
  }

}


fun RBuilder.blueprintMiscFacilityForm(
  heizstabToSave: StateInstance<Boolean>,
  waermepumpenanbindungToSave: StateInstance<Boolean>,
  onlineMonitoringToSave: StateInstance<ConfiguredOptionality>,
  integrateInverterIntoNetworkToSave: StateInstance<ConfiguredOptionality>,
  erdspiessSetzenToSave: StateInstance<Boolean>,
  editableStatus: EditableStatus,
): Unit = child(BlueprintMiscFacilityForm) {
  attrs {
    this.heizstabToSave = heizstabToSave
    this.waermepumpenanbindungToSave = waermepumpenanbindungToSave
    this.onlineMonitoringToSave = onlineMonitoringToSave
    this.integrateInverterIntoNetworkToSave = integrateInverterIntoNetworkToSave
    this.erdspiessSetzenToSave = erdspiessSetzenToSave
    this.editableStatus = editableStatus
  }
}

val BlueprintMiscFacilityForm: FC<BlueprintMiscFacilityFormProps> = fc("BlueprintMiscFacilityForm") { props ->
  val heizstabToSave = props::heizstabToSave.getNotNull()
  val waermepumpenanbindungToSave = props::waermepumpenanbindungToSave.getNotNull()
  val onlineMonitoringToSave = props::onlineMonitoringToSave.getNotNull()
  val integrateInverterIntoNetworkToSave = props::integrateInverterIntoNetworkToSave.getNotNull()
  val erdspiessSetzenToSave = props::erdspiessSetzenToSave.getNotNull()

  val editableStatus = props::editableStatus.safeGet()

  div("my-5") {
    h2("mb-2") {
      +"Heizung"
    }
    div {
    checkbox(
      valueAndSetter = heizstabToSave,
      fieldName = "heizstab",
      title = "Heizstab",
      editableStatus = editableStatus,
    )
    }
    div {
    checkbox(
      valueAndSetter = waermepumpenanbindungToSave,
      fieldName = "waermepumpenanbindung",
      title = "Wärmepumpenanbindung",
      editableStatus = editableStatus,
    )
    }
  }

  div("col-sm-6 my-5") {
    h2("mb-2") {
      +"Sonstiges"
    }

    div("my-3") {
      floatingSelectEnum(
        valueAndSetter = onlineMonitoringToSave,
        fieldName = "onlineMonitoring",
        title = "Online-Überwachung PV-Anlage",
        formatter = EnumTranslator.ConfiguredOptionality,
        availableOptions = enumEntries(),
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
        }
      }

      floatingSelectEnum(
        valueAndSetter = integrateInverterIntoNetworkToSave,
        fieldName = "integrateInverterIntoNetwork",
        title = "Inverter in (W)LAN integrieren",
        formatter = EnumTranslator.ConfiguredOptionality,
        availableOptions = enumEntries(),
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
        }
      }
    }

    checkbox(
      valueAndSetter = erdspiessSetzenToSave,
      fieldName = "erdspiessSetzen",
      title = "Erdspieß setzen",
      editableStatus = editableStatus,
    )
  }

}


external interface BlueprintMiscFacilityFormProps : Props {
  var heizstabToSave: StateInstance<Boolean>
  var waermepumpenanbindungToSave: StateInstance<Boolean>
  var onlineMonitoringToSave: StateInstance<ConfiguredOptionality>
  var integrateInverterIntoNetworkToSave: StateInstance<ConfiguredOptionality>
  var erdspiessSetzenToSave: StateInstance<Boolean>
  var editableStatus: EditableStatus
}

