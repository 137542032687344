@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.project.previews

import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.editHistory.PositionEditHistory
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration.BatteryConfigurationId
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModulesReport
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.UuidAndProcessStateEntries
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessStateEntry
import it.neckar.lizergy.model.stumps.RestArbeiten
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Contains a project and some preview values
 *
 * Can be used to load many projects - that can be shown in a table
 */
@Serializable
data class ResolvedProjectPreview(

  override val projectId: PhotovoltaicsProjectId,

  override val projectName: String,

  override val sellingCompanyInformation: PlannerCompanyInformation,

  override val customer: Customer,

  override val maintainerInformation: UserInformation,

  override val acquisitionDate: Double,

  override val blueprintPreview: ResolvedBlueprintPreview,
  override val configurationPreviews: List<ResolvedConfigurationPreview>,

  override val orderSpecialMaterial: UuidAndProcessStateEntries,
  override val gridAssessment: UuidAndProcessStateEntries,
  override val assemblyPortfolio: UuidAndProcessStateEntries,
  override val advanceInvoice: UuidAndProcessStateEntries,
  override val assemblyRoof: UuidAndProcessStateEntries,
  override val assemblyBasement: UuidAndProcessStateEntries,
  override val switchMeterBox: UuidAndProcessStateEntries,
  override val startupOperations: UuidAndProcessStateEntries,
  override val finishingUp: UuidAndProcessStateEntries,
  override val finalAccount: UuidAndProcessStateEntries,
  override val documentation: UuidAndProcessStateEntries,

  override val verification: Verification?,

  override val restArbeiten: RestArbeiten,

  override val belongsToCompanies: Set<CompanyCode>,

  override val processStateEntries: List<LizergyProcessStateEntry>?,

  override val archiveReasons: ArchiveReasons,

  ) : ServerProjectPreview {
  override val currentConfigurationPreview: ResolvedConfigurationPreview?
    get() = super.currentConfigurationPreview as ResolvedConfigurationPreview?
}


interface ServerConfigurationPreview : ConfigurationPreview {
  val configurationName: String
  val processStateEntries: List<LizergyProcessStateEntry>?
  val processStateEditHistory: PositionEditHistory<OLDProcessState>?
  val editorInformation: UserInformation?

  val allProcessStateEntries: List<LizergyProcessStateEntry>?
    get() = processStateEntries ?: processStateEditHistory?.allEdits?.map { processStateEdit -> processStateEdit.toNewProcessStateEntry(editorInformation) }

  val currentProcessStateEntry: LizergyProcessStateEntry?
    get() = allProcessStateEntries?.current()

  override val editor: UserLoginName?
    get() = editorInformation?.loginName
}

@Serializable
data class ResolvedConfigurationPreview(
  override val configurationId: PhotovoltaicsConfigurationId,
  override val configurationName: String,
  override val sellingCompany: CompanyCode,
  override val location: LocationInformation,
  override val modulesReport: ResolvedModulesReport,
  override val inverterSelection: ResolvedInverterSelection,
  val batteryConfiguration: BatteryConfiguration?,
  override val eigenmontage: Boolean,
  override val earningsDistribution: ResolvedEarningsDistribution,
  override val quoteElements: PreviewQuoteElements?,
  override val manualQuoteElements: ManualQuoteElements,
  override val worstProblem: ProblemType? = null,
  override val formattedProblems: String = "",
  override val processStateEntries: List<LizergyProcessStateEntry>?,
  override val processStateEditHistory: PositionEditHistory<OLDProcessState>? = null,
  override val editorInformation: UserInformation?,
) : ServerConfigurationPreview {
  constructor(
    configurationId: PhotovoltaicsConfigurationId,
    configurationName: String,
    sellingCompany: CompanyCode,
    location: LocationInformation,
    editorInformation: UserInformation?,
    modulesReport: ResolvedModulesReport,
    inverterSelection: ResolvedInverterSelection,
    batteryConfiguration: BatteryConfiguration?,
    eigenmontage: Boolean,
    earningsDistribution: ResolvedEarningsDistribution,
    quoteElements: PreviewQuoteElements?,
    manualQuoteElements: ManualQuoteElements,
    worstProblem: ProblemType? = null,
    formattedProblems: String = "",
    processStateEntries: List<LizergyProcessStateEntry>?,
    processStateEditHistory: PositionEditHistory<OLDProcessState>? = null,
    userResolver: UserResolver,
  ) : this(
    configurationId = configurationId,
    configurationName = configurationName,
    sellingCompany = sellingCompany,
    location = location,
    modulesReport = modulesReport,
    inverterSelection = inverterSelection,
    batteryConfiguration = batteryConfiguration,
    eigenmontage = eigenmontage,
    earningsDistribution = earningsDistribution,
    quoteElements = quoteElements,
    manualQuoteElements = manualQuoteElements,
    worstProblem = worstProblem,
    formattedProblems = formattedProblems,
    processStateEntries = processStateEntries,
    processStateEditHistory = processStateEditHistory,
    editorInformation = processStateEntries?.current()?.let { userResolver[it.assignedTo] } ?: editorInformation,
  )

  override val batteryConfigurationId: BatteryConfigurationId?
    get() = batteryConfiguration?.id

  override fun getCalculatedQuoteElements(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): PreviewQuoteElements? {
    TODO("Not yet implemented")
  }
}
