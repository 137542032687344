package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.geometry.Size
import it.neckar.open.formatting.format
import it.neckar.open.unit.si.mm
import kotlinx.serialization.Serializable

/**
 * The size of a module
 */
@Serializable
data class ModuleSize(
  val longer: @mm Int,
  val shorter: @mm Int
) {
  init {
    require(longer >= shorter) {
      "Longer ($longer) must >= shorter ($shorter)"
    }

    require(longer > 0.0) { "Longer <$longer> is too small" }
    require(shorter > 0.0) { "Longer <$shorter> is too small" }
  }

  /**
   * Returns the width of the module - respecting the orientation
   */
  fun width(orientation: ModuleOrientation): @mm Int {
    return when (orientation) {
      ModuleOrientation.Vertical -> shorter
      ModuleOrientation.Horizontal -> longer
    }
  }

  fun height(orientation: ModuleOrientation): @mm Int {
    return when (orientation) {
      ModuleOrientation.Vertical -> longer
      ModuleOrientation.Horizontal -> shorter
    }
  }

  override fun toString(): String {
    return "${longer.format()}mm x ${shorter.format()}mm"
  }

  /**
   * Converts to a size object - respecting the orientation
   */
  fun toSize(orientation: ModuleOrientation): @mm Size {
    return Size(width(orientation), height(orientation))
  }
}
