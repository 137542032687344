package it.neckar.react.common.redux

import it.neckar.common.redux.StateAction
import it.neckar.common.redux.UndoState
import it.neckar.common.redux.UndoStateAction
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import redux.RAction
import redux.Store


/**
 * Supports undo states
 */
@Suppress("UNCHECKED_CAST")
fun <S> undoStateReduceAction(state: UndoState<S>, action: RAction?): UndoState<S> {
  if (action == null) {
    //no action provided - just skip
    return state
  }

  return when (action) {
    is StateActionWrapper<*> -> {
      val stateActionWrapper = action as StateActionWrapper<S>
      val stateAction: StateAction<S> = stateActionWrapper.stateAction

      logger.debug("Reducing store with: ${stateAction::class.simpleName}")

      val currentState = state.currentState

      when (stateAction) {
        is UndoStateAction<*> -> {
          with(stateAction as UndoStateAction<S>) {
            return state.updateState()
          }
        }

        else -> {
          with(stateAction) {
            val newState = currentState.updateState()

            val actionType = stateAction.type

            return state.update(newState, actionType)
          }
        }
      }
    }

    else -> {
      logger.warn("Unsupported action: $action")
      state
    }
  }
}

/**
 * Redux Actions that automatically wraps a [StateAction]. Allows updating of the redux store
 */
data class StateActionWrapper<S>(
  /**
   * The wrapped state action that is executed
   */
  val stateAction: StateAction<S>,
) : RAction

/**
 * Dispatches a state action.
 */
fun <S, R> Store<S, RAction, R>.dispatch(action: StateAction<S>): R {
  return this.dispatch(StateActionWrapper(action))
}

private val logger: Logger = LoggerFactory.getLogger("it.neckar.react.common.redux.StoreExt")
