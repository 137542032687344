package it.neckar.lizergy.model.project.process.state

import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry.StartupOperationsProcessStates.Done
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry.StartupOperationsProcessStates.Missing
import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import it.neckar.processStates.HasDueDate
import it.neckar.processStates.ProcessStateEntryId
import it.neckar.processStates.ProcessStatePhase
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("StartupOperationsProcessStateEntry")
data class StartupOperationsProcessStateEntry(
  override val id: ProcessStateEntryId,
  override val processState: StartupOperationsProcessStates,
  override val assignedTo: UserLoginName,
  override val belongsTo: CompanyCode,
  override val dueDate: @ms @Serializable(with = DoubleAsIsoDateTimeSerializer::class) Double?,
  override val assignedAt: @ms @Serializable(with = DoubleAsIsoDateTimeSerializer::class) Double,
  override val assignedBy: UserLoginName,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : LizergyProcessStateEntry, HasDueDate {

  override val availableOptions: List<StartupOperationsProcessStates>
    get() = StartupOperationsProcessStates.entries

  override fun format(i18nConfiguration: I18nConfiguration): String {
    return when (processState) {
      Missing -> processState.formatForScheduling(dueDate, i18nConfiguration)
      Done -> processState.format(i18nConfiguration)
    }
  }

  @Serializable
  @SerialName("StartupOperationsProcessStates")
  enum class StartupOperationsProcessStates : LizergyProcessStates {
    Missing,
    Done,

    ;

    override val inPhase: ProcessStatePhase
      get() = when (this) {
        Missing -> ProcessStatePhase.Untouched
        Done -> ProcessStatePhase.Done
      }

    override fun format(i18nConfiguration: I18nConfiguration): String {
      return when (this) {
        Missing -> "IBN"
        Done -> "IBN abgeschlossen"
      }
    }

    override fun formatDate(date: Double, i18nConfiguration: I18nConfiguration): String {
      return "seit ${dateFormat.format(date, i18nConfiguration)}"
    }
  }
}
