package it.neckar.lizergy.model.project.process.state

import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry.AssemblyRoofProcessStates.Done
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry.AssemblyRoofProcessStates.InProgress
import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import it.neckar.processStates.ProcessStateEntryId
import it.neckar.processStates.ProcessStatePhase
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("AssemblyRoofProcessStateEntry")
data class AssemblyRoofProcessStateEntry(
  override val id: ProcessStateEntryId,
  override val processState: AssemblyRoofProcessStates,
  override val assignedTo: UserLoginName,
  override val belongsTo: CompanyCode,
  override val assignedAt: @ms @Serializable(with = DoubleAsIsoDateTimeSerializer::class) Double,
  override val assignedBy: UserLoginName,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : LizergyProcessStateEntry {

  override val availableOptions: List<AssemblyRoofProcessStates>
    get() = AssemblyRoofProcessStates.entries

  override fun format(i18nConfiguration: I18nConfiguration): String {
    return when (processState) {
      InProgress, Done -> processState.format(i18nConfiguration)
    }
  }

  @Serializable
  @SerialName("AssemblyRoofProcessStates")
  enum class AssemblyRoofProcessStates : LizergyProcessStates {
    InProgress,
    Done,

    ;

    override val inPhase: ProcessStatePhase
      get() = when (this) {
        InProgress -> ProcessStatePhase.InProgress
        Done -> ProcessStatePhase.Done
      }

    override fun format(i18nConfiguration: I18nConfiguration): String {
      return when (this) {
        InProgress -> "MD in Bearbeitung"
        Done -> "MD fertig"
      }
    }

    override fun formatDate(date: Double, i18nConfiguration: I18nConfiguration): String {
      return "seit ${dateFormat.format(date, i18nConfiguration)}"
    }
  }
}
