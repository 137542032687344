package components.project.configuration.additional

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.configuration.configurationItemForm
import components.project.configuration.isConfigurationEditable
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.financial.currency.euro
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.components.ResolvedElectricityWorkConfiguration
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.kotlin.lang.toCheckboxChar
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

val EditConfigurationElectricityWorkEffortFromUrl: FC<Props> = fc("EditConfigurationElectricityWorkEffortFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Zählerschrankarbeiten", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    EditConfigurationElectricityWorkEffort {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val EditConfigurationElectricityWorkEffort: FC<QuoteConfigurationTabProps> = fc("EditConfigurationElectricityWorkEffort") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val electricityWorkConfigurationToSave = useState(quoteConfiguration.electricityWorkConfiguration)
  val bleibenderZaehlerToSave = useState(quoteConfiguration.zaehlerNummer)

  val configurationToSave = useMemo(
    electricityWorkConfigurationToSave.value,
    bleibenderZaehlerToSave.value,
  ) {
    quoteConfiguration.copy(
      electricityWorkConfiguration = electricityWorkConfigurationToSave.value,
      zaehlerNummer = bleibenderZaehlerToSave.value,
    )
  }


  useMemo(configurationToSave) {
    if (editableStatus == Editable) {
      UiActions.saveQuoteConfiguration(project, configurationToSave)
    }
  }


  div("row my-5") {

    div("col-lg-6") {

      EditElectricityWorkEffort {
        attrs {
          this.electricityWorkConfigurationToSave = electricityWorkConfigurationToSave
          this.bleibenderZaehlerToSave = bleibenderZaehlerToSave
          this.selectedBatteryConfiguration = quoteConfiguration.facilityConfiguration.batteryConfiguration
          this.waermepumpeSelected = quoteConfiguration.facilityConfiguration.waermepumpenanbindung
          this.intelligentWallboxSelected = quoteConfiguration.wallboxSelection.isSmartWallBoxSelected
          this.heizstabSelected = quoteConfiguration.facilityConfiguration.heaterRod != null
          this.editableStatus = editableStatus
        }
      }

      p("form-text") {
        +"Zählerschrank schutzisoliert: ${project.blueprint.zaehlerschrankSchutzisoliert.toCheckboxChar()}"
      }

    }

    div("col-lg-6") {
      h3("mt-0 mb-2") {
        +"Bemerkungen Zählerschrankarbeiten"
      }
      project.blueprint.electricityWorkAnnotation?.let {
        p { +it }
      }
      commentSection(quoteConfiguration.electricityWorkConfiguration)
    }

  }

}

val EditElectricityWorkEffort: FC<EditElectricityWorkEffortProps> = fc("EditElectricityWorkEffort") { props ->
  val electricityWorkConfigurationToSave = props::electricityWorkConfigurationToSave.getNotNull()
  val bleibenderZaehlerToSave = props::bleibenderZaehlerToSave.getNotNull()
  val selectedBatteryConfiguration = props::selectedBatteryConfiguration.safeGet()
  val waermepumpeSelected = props::waermepumpeSelected.safeGet()
  val intelligentWallboxSelected = props::intelligentWallboxSelected.safeGet()
  val heizstabSelected = props::heizstabSelected.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val electricityWorkConfiguration = electricityWorkConfigurationToSave.value

  val electricityWorkEffort = electricityWorkConfiguration.electricityWorkEffort
  val electricityWorkEffortHeadline: StateInstance<String> = useState(electricityWorkEffort.headline)
  val electricityWorkEffortDetails: StateInstance<String> = useState(electricityWorkEffort.details)
  val electricityWorkEffortPrice: StateInstance<@EUR Double> = useState(electricityWorkEffort.priceForOneElement.sellingPrice.euros)
  val electricityWorkEffortAmount: StateInstance<Double> = useState(electricityWorkEffort.amount)
  val electricityWorkEffortRelevanceForEconomicCalculations = useState(electricityWorkEffort.relevance)
  val electricityWorkEffortValueAddedTax: StateInstance<@pct Double> = useState(electricityWorkEffort.valueAddedTax.vat)

  val sunnyHomeManagerToSave = useState(electricityWorkConfiguration.sunnyHomeManager(selectedBatteryConfiguration, waermepumpeSelected, intelligentWallboxSelected, heizstabSelected))
  val lizergyIndependenceManagerToSave = useState(electricityWorkConfiguration.independenceManager)
  val neuerZaehlerschrankToSave = useState(electricityWorkConfiguration.neuerZaehlerschrank)
  val zusammenZuLegendeZaehlerNummernToSave = useState(electricityWorkConfiguration.zusammenZuLegendeZaehlerNummern)
  val einbauUnterverteilerToSave = useState(electricityWorkConfiguration.einbauUnterverteiler)
  val einbauDigitalerZaehlerToSave = useState(electricityWorkConfiguration.manualEinbauDigitalerZaehler)
  val smaEnergyMeterToSave = useState(electricityWorkConfiguration.smaEnergyMeter)
  val slsNachruestenToSave = useState(electricityWorkConfiguration.slsNachruesten)


  val electricityWorkEffortToSave = useMemo(
    electricityWorkEffortHeadline.value,
    electricityWorkEffortDetails.value,
    electricityWorkEffortPrice.value,
    electricityWorkEffortAmount.value,
    electricityWorkEffortRelevanceForEconomicCalculations.value,
    electricityWorkEffortValueAddedTax.value,
  ) {
    ConfigurationItem(
      headline = electricityWorkEffortHeadline.value,
      details = electricityWorkEffortDetails.value,
      priceForOneElement = PriceWithProfit.noProfit(electricityWorkEffortPrice.value.euro),
      amount = electricityWorkEffortAmount.value.toDouble(),
      relevance = electricityWorkEffortRelevanceForEconomicCalculations.value,
      valueAddedTax = ValueAddedTax(electricityWorkEffortValueAddedTax.value)
    )
  }

  useMemo(
    electricityWorkEffortToSave,
    sunnyHomeManagerToSave.value,
    lizergyIndependenceManagerToSave.value,
    neuerZaehlerschrankToSave.value,
    zusammenZuLegendeZaehlerNummernToSave.value,
    einbauUnterverteilerToSave.value,
    einbauDigitalerZaehlerToSave.value,
    smaEnergyMeterToSave.value,
    slsNachruestenToSave.value,
  ) {
    electricityWorkConfigurationToSave.setter(
      electricityWorkConfiguration.copy(
        electricityWorkEffort = electricityWorkEffortToSave,
        sunnyHomeManager = sunnyHomeManagerToSave.value,
        independenceManager = lizergyIndependenceManagerToSave.value,
        neuerZaehlerschrank = neuerZaehlerschrankToSave.value,
        zusammenZuLegendeZaehlerNummern = zusammenZuLegendeZaehlerNummernToSave.value,
        einbauUnterverteiler = einbauUnterverteilerToSave.value,
        manualEinbauDigitalerZaehler = einbauDigitalerZaehlerToSave.value,
        smaEnergyMeter = smaEnergyMeterToSave.value,
        slsNachruesten = slsNachruestenToSave.value,
      )
    )
  }


  configurationItemForm(
    headline = electricityWorkEffortHeadline,
    details = electricityWorkEffortDetails,
    price = electricityWorkEffortPrice,
    amount = electricityWorkEffortAmount,
    relevance = electricityWorkEffortRelevanceForEconomicCalculations,
    valueAddedTax = electricityWorkEffortValueAddedTax,
    editableStatus = editableStatus,
  )

  electricityWorkEffortForm(
    sunnyHomeManagerToSave = sunnyHomeManagerToSave,
    independenceManagerToSave = lizergyIndependenceManagerToSave,
    neuerZaehlerschrankToSave = neuerZaehlerschrankToSave,
    zusammenZuLegendeZaehlerNummernToSave = zusammenZuLegendeZaehlerNummernToSave,
    bleibenderZaehlerToSave = bleibenderZaehlerToSave,
    einbauUnterverteilerToSave = einbauUnterverteilerToSave,
    einbauDigitalerZaehlerToSave = einbauDigitalerZaehlerToSave,
    smaEnergyMeterToSave = smaEnergyMeterToSave,
    slsNachruestenToSave = slsNachruestenToSave,
    selectedBatteryConfiguration = selectedBatteryConfiguration,
    sunnyHomeManagerAutomaticallySet = selectedBatteryConfiguration != null || waermepumpeSelected || intelligentWallboxSelected || heizstabSelected,
    sunnyHomeManagerNote = electricityWorkConfiguration.sunnyHomeManagerNote(selectedBatteryConfiguration, waermepumpeSelected, intelligentWallboxSelected, heizstabSelected),
    editableStatus = editableStatus,
  )

}


external interface EditElectricityWorkEffortProps : Props {
  var electricityWorkConfigurationToSave: StateInstance<ResolvedElectricityWorkConfiguration>
  var bleibenderZaehlerToSave: StateInstance<String>
  var selectedBatteryConfiguration: BatteryConfiguration?
  var waermepumpeSelected: Boolean
  var intelligentWallboxSelected: Boolean
  var heizstabSelected: Boolean
  var editableStatus: EditableStatus
}
