package it.neckar.lizergy.model.configuration.energy.power

import it.neckar.lizergy.model.configuration.quote.economics.simple.IncreasingPricePerKWh
import it.neckar.lizergy.model.configuration.quote.economics.simple.PricePerKWh
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.time.pa
import kotlinx.serialization.Serializable

/**
 * A scenario for prices
 */
@Serializable
data class PricesTrendScenario(
  /**
   * The guaranteed feed in price
   */
  val guaranteedFeedInPrice: PricePerKWh? = null,

  /**
   * The consumption price
   */
  val consumptionPrice: PricePerKWh,

  /**
   * The yearly price increase for the consumption price
   */
  val consumptionPriceIncreasePercentage: @pct @pa Double,
) {

  /**
   * Returns the consumption price as increasing price
   */
  fun consumptionPriceAsIncreasing(): IncreasingPricePerKWh {
    return IncreasingPricePerKWh(consumptionPrice, consumptionPriceIncreasePercentage)
  }

  companion object {
    fun typical(): PricesTrendScenario {
      return PricesTrendScenario(
        guaranteedFeedInPrice = null,
        consumptionPrice = PricePerKWh(0.39),
        consumptionPriceIncreasePercentage = 4.percent
      )
    }

    fun demoQuote(): PricesTrendScenario {
      return PricesTrendScenario(
        guaranteedFeedInPrice = null,
        consumptionPrice = PricePerKWh(0.51),
        consumptionPriceIncreasePercentage = 4.percent
      )
    }

    fun demoPresentation(): PricesTrendScenario {
      return PricesTrendScenario(
        guaranteedFeedInPrice = PricePerKWh(0.084),
        consumptionPrice = PricePerKWh(0.4),
        consumptionPriceIncreasePercentage = 4.percent
      )
    }

    fun demoPresentationAdditional(): PricesTrendScenario {
      return PricesTrendScenario(
        guaranteedFeedInPrice = PricePerKWh(0.084),
        consumptionPrice = PricePerKWh(0.4),
        consumptionPriceIncreasePercentage = 0.04
      )
    }


    fun demo(): PricesTrendScenario {
      return PricesTrendScenario(
        guaranteedFeedInPrice = null,
        consumptionPrice = PricePerKWh(0.51),
        consumptionPriceIncreasePercentage = 4.percent
      )
    }

  }
}
