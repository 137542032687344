package it.neckar.lizergy.model.configuration.quote

import it.neckar.open.unit.si.kg
import it.neckar.open.unit.si.t
import it.neckar.open.unit.time.pa
import kotlinx.serialization.Serializable
import kotlin.math.roundToInt

/**
 * The savings for the environment
 */
@Serializable
data class EnvironmentSavings(
  /**
   * The C02 reduction
   */
  val co2reduction: @t @pa Double,
  /**
   * The number of beeches
   */
  val amountOfBeeches: Int,
) {

  companion object {
    /**
     * Calculates the amount of CO2 that is saved for the given amount of energy
     */
    fun calculateSavedCo2Tonnes(energy: it.neckar.lizergy.model.configuration.energy.AmountOfEnergy): @t Double {
      return energy.kWh * savedKgCo2PerKWh / 1000.0
    }

    /**
     * Calculates the amount of beeches that are required to store the given amount of CO2
     */
    fun calculateBeeches(amountOfCo2: @t @pa Double): Double {
      return (amountOfCo2 * beechesPerTonneCo2ForOneYear)
    }

    /**
     * Calculates the environment savings based upon the production for one year
     */
    fun calculate(energyProduction: @pa it.neckar.lizergy.model.configuration.energy.AmountOfEnergy): EnvironmentSavings {
      val yearlySavedCo2: @t @pa Double = calculateSavedCo2Tonnes(energyProduction)
      val amountOfBeeches = calculateBeeches(yearlySavedCo2).roundToInt()

      return EnvironmentSavings(yearlySavedCo2, amountOfBeeches)
    }

    /**
     * The amount of CO2 that is saved per kWh
     * For details see `internal/closed/lizergy/doc/co2-calculations.adoc`
     */
    private const val savedKgCo2PerKWh: @kg Double = 0.402 - 0.067

    /**
     * "80 Buchen kompensieren 1 Tonne CO2."
     * The amount of beeches that compensate one tonne of CO2 every year.
     *
     * Attention: The amount of beeches is not multiplied with the number of years,
     * since each beech compensates the calculated amount of CO2 every year
     *
     * For details see `internal/closed/lizergy/doc/co2-calculations.adoc`
     */
    private const val beechesPerTonneCo2ForOneYear: Double = 80.0
  }

}
