package it.neckar.lizergy.model.company.user

import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.NeckarITCompanyProfile
import it.neckar.customer.company.TestCompanyProfile
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.user.UserLoginName
import kotlinx.serialization.Serializable

/**
 * Information about the currently logged-in user
 */
@Serializable
data class UserInformation(
  /**
   * The login name of the editor
   * This name is unique
   */
  val loginName: UserLoginName,

  /**
   * The full name used by the planner for this user
   */
  val editorName: String,

  /**
   * Access rights of the editor
   */
  val accessRights: AccessRights,

  /**
   * The company this user belongs to
   */
  val company: CompanyProfile,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : HasLifeCycle {
  companion object {

    val empty: UserInformation = createStubUser(UserLoginName(""), "")
    val demo: UserInformation = createStubUser(UserLoginName("max"), "Max Mustermann")
    val NeckarIT: UserInformation = UserInformation(
      loginName = UserLoginName.NeckarIT,
      editorName = "Neckar IT",
      accessRights = AccessRights.admin,
      company = NeckarITCompanyProfile,
    )

    val example: UserInformation = createStubUser(UserLoginName("max"), "Max Mustermann")

    fun random(): UserInformation {
      val randomEditors = listOf(
        createStubUser(UserLoginName(""), "Max Mustermann"),
        createStubUser(UserLoginName(""), "Erika Musterfrau"),
      )
      return randomEditors.random()
    }

    /**
     * Returns a user information with default values
     */
    fun createStubUser(loginName: UserLoginName, editorName: String? = null): UserInformation {
      return UserInformation(
        loginName = loginName,
        editorName = editorName ?: loginName.value.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() },
        accessRights = AccessRights.none,
        company = TestCompanyProfile(CompanyCode.TestCompany),
      )
    }

    fun createStubUser(loginName: String, editorName: String? = null): UserInformation {
      return createStubUser(UserLoginName(loginName), editorName)
    }

  }
}
