package it.neckar.financial.quote

/**
 * Is the item optional? Or mandatory?
 */
enum class Optionality {
  /**
   * The item is marked as mandatory
   */
  Mandatory,

  /**
   * The item is marked as optional
   */
  Optional,
}
