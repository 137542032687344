package services.http

import it.neckar.lizergy.PlannerClientDeploymentZone
import kotlinx.browser.window

/**
 * Initializes the server using the current location
 */
fun PlannerClientDeploymentZone.Companion.getClientFromWindowLocation(): PlannerClientDeploymentZone {
  return getClientDeploymentZoneFromWindowLocation(window.location.href)
}
