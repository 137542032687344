package components

import components.nav.CreateSampleProjectsNavItem
import components.nav.ExperimentsNavItem
import components.nav.LogoutNavItem
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherUsers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOwnCompany
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewAccounting
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewPriceList
import it.neckar.lizergy.version.LizergyVersions
import it.neckar.react.common.router.*
import kotlinx.html.id
import react.*
import react.dom.*
import react.router.dom.*
import router.RouterUrls
import store.hooks.useRequireLoggedInUser
import store.useSelector
import web.cssom.ClassName

val AppSidebar: FC<AppSidebarProps> = fc("AppSidebar") {
  val loggedInUser = useRequireLoggedInUser()
  val deploymentZone = useSelector {
    deploymentZone
  }


  div("d-flex flex-column pt-2") {
    attrs {
      id = "appSidebarDiv"
    }

    /*upper block*/
    div("position-fixed mt-2 ml-2") {

      div("d-flex justify-content-center") {
        ul(classes = "nav nav-pills flex-column") {
          attrs {
            id = "appSidebarNavUl"
          }

          /**
           * Home
           */
          li("nav-item") {

            Link {
              i(classes = "fas fa-home fs-3 iconSidebar") {}
              span("ms-1") { +"Home" }

              attrs {
                className = ClassName("nav-link px-0")
                toUrl = RouterUrls.home
              }
            }

          }

          /**
           * Projects
           */
          li("nav-item ") {

            Link {
              i(classes = "fas fa-pencil-ruler fs-3 iconSidebar") {}
              span("ms-1 ") { +"Alle Projekte" }

              attrs {
                className = ClassName("nav-link px-0")
                toUrl = RouterUrls.OverviewTableRouterUrls.projects
              }
            }

          }

          /**
           * Price List
           */
          li("nav-item") {

            Link {
              i(classes = "fas fa-list fs-3 iconSidebar") {}
              span("ms-1") { +"Preisliste" }

              attrs {
                className = ClassName("nav-link px-0")
                toUrl = RouterUrls.priceList

                restrictedUI(loggedInUser) { it.accessRights.canAccess(ViewPriceList) }
              }
            }
          }

          /**
           * Profile
           */
          li("nav-item") {

            Link {
              i(classes = "fas fa-person fs-3 iconSidebar") {}
              span("ms-1") { +"Profil" }

              attrs {
                className = ClassName("nav-link px-0")
                toUrl = RouterUrls.admin.users().user(loggedInUser)
              }
            }

          }

          /**
           * Company Overview
           */
          li("nav-item") {

            Link {
              i(classes = "fas fa-building fs-3 iconSidebar") {}
              span("ms-1") { +"Firmenübersicht" }

              attrs {
                className = ClassName("nav-link px-0")
                toUrl = RouterUrls.admin.companies().index

                restrictedUI(loggedInUser) { it.accessRights.canAccess(EditOtherUsers, EditOwnCompany) }
              }
            }
          }

          /**
           * Accounting
           */
          li("nav-item") {

            Link {
              i(classes = "fas fa-coins fs-3 iconSidebar") {}
              span("ms-1") { +"Abrechnung" }

              attrs {
                className = ClassName("nav-link px-0")
                toUrl = RouterUrls.admin.accounting

                restrictedUI(loggedInUser) { it.accessRights.canAccess(ViewAccounting) }
              }
            }
          }

        }
      }

    }

    /*lower block*/
    div("position-fixed bottom-0 mt-2 mx-2") {
      div("d-flex") {

        ul(classes = "nav nav-pills flex-column align-items-start") {
          if (deploymentZone.isProduction().not()) {
            CreateSampleProjectsNavItem{}
            ExperimentsNavItem {}
          }

          LogoutNavItem {}
        }
      }

      div {
        /*version number*/
        div("text-center") {
          small {
            +LizergyVersions.gitVersionAsStringVerbose
          }
        }
      }
    }
  }
}


external interface AppSidebarProps : Props
