package components.nav

import it.neckar.react.common.router.*
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import react.router.dom.*
import router.RouterUrls
import services.UiActions
import store.actions.LogoutReason
import web.cssom.ClassName

/**
 * Shows the Experiments nav item - should not be visible in production
 */
val ExperimentsNavItem: FC<Props> = fc("ExperimentsNavItem") {
  li("nav-item") {
    Link {
      i(classes = "fas fa-wrench fs-3 iconSidebar") {}
      span("ms-1") { +"Experimente" }

      attrs {
        className = ClassName("nav-link px-0")
        toUrl = RouterUrls.featureFlags
      }
    }
  }
}

/**
 * Nav item that creates the sample projects - not visible in production
 */
val CreateSampleProjectsNavItem: FC<Props> = fc("AddSampleProjectsNavItem") {
  li("nav-item") {
    Link {
      i(classes = "fas fa-plus fs-3 iconSidebar") {}
      span("ms-1") { +"Beispiele generieren" }

      attrs {
        className = ClassName("nav-link px-0")
        toUrl = RouterUrls.createSampleProjects
      }
    }
  }
}

/**
 * Shows the logout method
 */
val LogoutNavItem: FC<Props> = fc("LogoutNavItem") {
  li("nav-item") {
    button(classes = "btn btn-link px-0 text-primary text-decoration-none", type = ButtonType.button) {
      i(classes = "fas fa-sign-out fs-3 text-primary iconSidebar") {}
      span("ms-1 text-primary") {
        +"Logout"
      }
      attrs {
        onClickFunction = {
          it.preventDefault()
          UiActions.logout(LogoutReason.UserInteraction)
        }
      }
    }
  }
}
