package store.actions

import it.neckar.comments.CommentsForComponent
import it.neckar.common.redux.ChangeType
import store.PlannerClientState

data class CommentsForComponentLoadedAction(val commentsForComponent: CommentsForComponent) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(
      allCommentsForComponents = allCommentsForComponents.withUpdatedCommentsForComponent(commentsForComponent),
    )
  }
}
