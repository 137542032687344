package it.neckar.common.auth

import it.neckar.rest.RestRequest
import it.neckar.user.UserLoginName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * The request for login
 */
@JsExport
@Serializable
data class LoginRequest(
  val loginName: UserLoginName,
  val password: String,
) : RestRequest
