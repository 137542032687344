package it.neckar.lizergy.model.price.lizergy

import com.benasher44.uuid.uuidFrom
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty.AssemblyDifficultyId

/**
 * Contains common information about price lists.
 *
 * These values must *not* be referenced directly anywhere outside of price list generation / calculation
 *
 */
object PriceListInfo {

  /**
   * The commonly available assembly difficulties.
   */
  val assemblyDifficultyEasy: AssemblyDifficulty = AssemblyDifficulty(AssemblyDifficultyId(uuidFrom("473b3683-bb3d-4cbc-a780-55ae10ebf34f")), "Leicht")
  val assemblyDifficultyMedium: AssemblyDifficulty = AssemblyDifficulty(AssemblyDifficultyId(uuidFrom("2ff95215-d378-4e78-8519-5e3a113d3d02")), "Standard")
  val assemblyDifficultyHard: AssemblyDifficulty = AssemblyDifficulty(AssemblyDifficultyId(uuidFrom("8dd1e611-369a-4717-b500-f46856a516a0")), "Schwer")
}
