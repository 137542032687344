package components.project.configuration.profitability

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.sum
import it.neckar.financial.quote.CalculationRelevanceQuery
import it.neckar.financial.quote.QuoteElements
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.income.IncomePercentage
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.lizergy.model.income.IncomePercentages
import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastSumBy
import it.neckar.open.formatting.formatAsPercentage
import kotlinx.html.ThScope
import plannerI18nConfiguration
import react.*
import react.dom.*

/**
 * Renders a table that displays the profit for each of the roles
 */
val IncomePercentageProfitsTable: FC<IncomePercentageProfitsTableProps> = fc("IncomePercentageProfitsTable") { props ->
  val incomePercentages = props::incomePercentages.safeGet()
  val quotePositions = props::quotePositions.safeGet()

  val profitBases = IncomePercentageCategory.entries.associateWith { category ->
    val query = LizergyCalculationCategories.Query.Profits.get(category)
    quotePositions.netPricesForVats(query).total
  }


  table("table table-responsive table-hover") {
    thead {
      tr {
        th(ThScope.col) {
          +"Rolle"
        }

        IncomePercentageCategory.entries.fastForEach {
          th(ThScope.col) {
            +it.description
          }
        }

        th(ThScope.col) {
          +"Summe"
        }
      }

      tr {
        th(ThScope.row) {
          +"Basis-Betrag"
        }

        IncomePercentageCategory.entries.fastForEach { category ->
          td("text-end") {
            +(profitBases[category]?.format() ?: throw IllegalStateException("No profit base found for $category"))
          }
        }

        td("text-end") {
          +quotePositions.netPricesForVats(CalculationRelevanceQuery.defaultForSum).total.format(plannerI18nConfiguration)
        }
      }
    }

    tbody {
      incomePercentages.fastForEach { incomePercentage ->
        IncomePercentageProfitTableLine {
          attrs {
            this.incomePercentage = incomePercentage
            this.profitBases = profitBases
          }
        }
      }
    }

    tfoot {
      tr {
        th(ThScope.row) {
          +"Summen (%)"
        }

        IncomePercentageCategory.entries.fastForEach { category ->
          val percentageSum = IncomePercentages.all.fastSumBy {
            it.getPercentage(category)
          }
          td("form-text text-end") {
            +percentageSum.formatAsPercentage()
          }
        }

        td("text-end") {
        }
      }
    }
  }
}


external interface IncomePercentageProfitsTableProps : Props {
  var incomePercentages: List<IncomePercentage>
  var quotePositions: QuoteElements
}

val IncomePercentageProfitTableLine: FC<IncomePercentageProfitTableLineProps> = fc("IncomePercentageProfitTableLine") { props ->
  val incomePercentage = props::incomePercentage.safeGet()
  val profitBases = props::profitBases.safeGet()

  val profits = profitBases.mapValues { (category, profitBase) ->
    val percentage = incomePercentage.getPercentage(category)
    profitBase.times(percentage)
  }

  val profitsSum = profits.values.sum()


  tr {
    th(ThScope.row) {
      +incomePercentage.description
    }

    IncomePercentageCategory.entries.fastForEach { category ->
      td("text-end") {
        val profit = profits[category] ?: throw IllegalArgumentException("not found for $category")
        +profit.formatOrIfZero("-")
        br { }
        span("form-text") {
          +incomePercentage.getPercentage(category).formatAsPercentage(1)
        }
      }
    }

    td("text-end") {
      +profitsSum.format()
    }
  }
}

external interface IncomePercentageProfitTableLineProps : Props {
  var incomePercentage: IncomePercentage
  var profitBases: Map<IncomePercentageCategory, Money>
}
