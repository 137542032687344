package router

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.components.ExistingBHKWFacilityConfiguration.ExistingBHKWFacilityConfigurationId
import it.neckar.lizergy.model.configuration.components.ExistingPVFacilityConfiguration.ExistingPVFacilityConfigurationId
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.react.common.annotations.*
import it.neckar.react.common.router.*
import it.neckar.user.UserLoginName
import store.hooks.useLoadResolvedProject
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver


/**
 * Extracts the company code from the URL
 * Most of the time [useCompanyFromUrl] can be used instead
 */
@UsesHooks
private fun useCompanyCodeFromUrl(): CompanyCode {
  val companyCode = useFromParams(RouterVars.companyCode)
  return CompanyCode(companyCode)
}

/**
 * Loads the company from the company code
 */
@UsesHooks
fun useCompanyFromUrl(): PlannerCompanyInformation {
  val companyCode = useCompanyCodeFromUrl()
  return useSelectCompanyResolver()[companyCode]
}

@UsesHooks
private fun useLoginNameFromUrl(): UserLoginName {
  val loginName = useFromParams(RouterVars.loginName)
  return UserLoginName(loginName)
}

@UsesHooks
fun useUserFromUrl(): UserInformation {
  val loginName = useLoginNameFromUrl()
  return useSelectUserResolver()[loginName]
}

@UsesHooks
fun useProjectIdFromUrl(): PhotovoltaicsProjectId {
  return PhotovoltaicsProjectId(
    useFromParamsUuidBase64(RouterVars.projectId)
  )
}

/**
 * Loads the resolved project from the project id from the URL
 */
@UsesHooks
fun useLoadResolvedProjectFromUrl(): ResolvedProject? {
  return useLoadResolvedProject(useProjectIdFromUrl())
}

@UsesHooks
fun useConfigurationIdFromUrl(): PhotovoltaicsConfigurationId {
  return PhotovoltaicsConfigurationId(
    useFromParamsUuidBase64(RouterVars.configurationId)
  )
}

@UsesHooks
fun useAdditionalPositionIdFromUrl(): Uuid {
  return useFromParamsUuidBase64(RouterVars.additionalPositionId)
}

@UsesHooks
fun useExistingPVFacilityIdFromUrl(): ExistingPVFacilityConfigurationId {
  return ExistingPVFacilityConfigurationId(useFromParamsUuidBase64(RouterVars.bestandsAnlagenPVId))
}

@UsesHooks
fun useExistingBHKWFacilityIdFromUrl(): ExistingBHKWFacilityConfigurationId {
  return ExistingBHKWFacilityConfigurationId(useFromParamsUuidBase64(RouterVars.bestandsAnlagenBHKWId))
}

@UsesHooks
fun useModuleLayoutIdFromUrl(): ModuleLayoutId {
  return ModuleLayoutId(useFromParamsUuidBase64(RouterVars.moduleLayoutId))
}

/**
 * Loads the [QuoteConfiguration] from the URL.
 * The URL must contain the `projectId` and `configurationId`
 */
@UsesHooks
fun useLoadQuoteConfigurationFromUrl(): QuoteConfiguration? {
  val project = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()

  return project?.get(configurationId)
}
