package components.gridAssessment

import components.form.commentSection
import components.project.configuration.ConfigurationPreviewWithComments
import components.project.editProcessStateEntry
import components.project.infiniteScrollingProcessStatesList
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry.AssemblyPortfolioProcessStates
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry.GridAssessmentProcessStates
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry.OrderSpecialMaterialProcessStates
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.lizergy.model.stumps.EinstellungWechselrichter
import it.neckar.lizergy.model.stumps.Netzbetreiber
import it.neckar.open.time.nowMillis
import it.neckar.processStatesClient.SendProcessStatesTuple
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser

val GridAssessmentOverviewFromUrl: FC<Props> = fc("GridAssessmentOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Netzvoranfrage - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    GridAssessmentOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

private const val firstColumn = "col-lg-7"
private const val secondColumn = "col-lg-5"

val GridAssessmentOverview: FC<GridAssessmentOverviewProps> = fc("GridAssessmentOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val processStatesResolver = useLoadProcessStates()

  val project = props::project.safeGet()

  val currentConfiguration = processStatesResolver?.let { project.getCurrentQuoteConfiguration(it) }
  val gridAssessment = project.gridAssessment
  val assemblyPortfolio = project.assemblyPortfolio

  val gridAssessmentProcessState = useLoadProcessStatesForComponent(gridAssessment.uuid)?.current()?.processState
  val orderSpecialMaterialProcessState = useLoadProcessStatesForComponent(project.orderSpecialMaterialId)?.current()?.processState
  val assemblyPortfolioProcessState = useLoadProcessStatesForComponent(assemblyPortfolio.uuid)?.current()?.processState

  val einstellungWechselrichterState = useState(gridAssessment.einstellungWechselrichter)
  val erzeugungszaehlerState = useState(gridAssessment.erzeugungsZaehler)
  val verteilerNetzbetreiberState = useState(gridAssessment.verteilerNetzbetreiber)
  val verteilerNetzbetreiberOverrideState = useState(gridAssessment.verteilerNetzbetreiberOverride)


  useMemo(gridAssessmentProcessState, orderSpecialMaterialProcessState) {
    val automaticProcessStates = buildList {
      if (gridAssessmentProcessState == GridAssessmentProcessStates.Accepted && orderSpecialMaterialProcessState == OrderSpecialMaterialProcessStates.Done) {
        if (assemblyPortfolioProcessState == null) add(SendProcessStatesTuple(assemblyPortfolio.uuid, AssemblyPortfolioProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
      }
    }
    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }

  useMemo(
    einstellungWechselrichterState.value,
    erzeugungszaehlerState.value,
    verteilerNetzbetreiberState.value,
    verteilerNetzbetreiberOverrideState.value,
  ) {
    val updatedGridAssessment = gridAssessment.copy(
      einstellungWechselrichter = einstellungWechselrichterState.value,
      erzeugungsZaehler = erzeugungszaehlerState.value,
      verteilerNetzbetreiber = verteilerNetzbetreiberState.value,
      verteilerNetzbetreiberOverride = verteilerNetzbetreiberOverrideState.value,
    )
    UiActions.saveGridAssessment(updatedGridAssessment)
  }


  div("row my-5") {
    div("$firstColumn col-12") {
      editProcessStateEntry(gridAssessment, GridAssessmentProcessStates.entries, editableStatus = project.isProjectEditable())
    }

    infiniteScrollingProcessStatesList(gridAssessment)
  }

  div("my-5") {
    h3("mb-3") {
      +"Bemerkungen Netzvoranfrage"
    }

    commentSection(gridAssessment)
  }

  div("row my-3") {
    div(firstColumn) {
      entryPreview("Kunde", project.customer.format(plannerI18nConfiguration))
    }
    div(secondColumn) {
    }
  }


  val currentQuoteSnapshot = currentConfiguration?.currentQuoteSnapshot
  if (currentQuoteSnapshot != null) {
    GridAssessmentConfigurationOverview {
      attrs {
        this.project = project
        this.currentConfiguration = currentConfiguration
        this.currentQuoteSnapshot = currentQuoteSnapshot
      }
    }
  }

  div("row my-3") {

    div(firstColumn) {
      entryPreview("Einstellung WR") {
        span("me-3") {
          radioButton(
            buttonId = "einstellungWechselrichterJa",
            value = einstellungWechselrichterState.value == EinstellungWechselrichter.FRE_RSE,
            onChange = { if (it) einstellungWechselrichterState.setter(EinstellungWechselrichter.FRE_RSE) },
            inputClasses = "form-check-input",
            labelClasses = "form-check-label ms-2",
          ) {
            +"FRE/RSE"
          }
        }
        span("me-3") {
          radioButton(
            buttonId = "einstellungWechselrichterNein",
            value = einstellungWechselrichterState.value == EinstellungWechselrichter.HundredPercent,
            onChange = { if (it) einstellungWechselrichterState.setter(EinstellungWechselrichter.HundredPercent) },
            inputClasses = "form-check-input",
            labelClasses = "form-check-label ms-2",
          ) {
            +"100%"
          }
        }
      }
    }
    div(secondColumn) {}

    div(firstColumn) {
      entryPreview("Erzeugungszähler") {
        span("me-3") {
          radioButton(
            buttonId = "erzeugungszaehlerJa",
            value = erzeugungszaehlerState.value,
            onChange = { erzeugungszaehlerState.setter(it) },
            inputClasses = "form-check-input",
            labelClasses = "form-check-label ms-2",
          ) {
            +"ja"
          }
        }
        span("me-3") {
          radioButton(
            buttonId = "erzeugungszaehlerNein",
            value = erzeugungszaehlerState.value.not(),
            onChange = { erzeugungszaehlerState.setter(it.not()) },
            inputClasses = "form-check-input",
            labelClasses = "form-check-label ms-2",
          ) {
            +"nein"
          }
        }
      }
    }
    div(secondColumn) {}

  }

  div("row my-2") {
    div(firstColumn) {
      entryPreview("Verteilernetzbetreiber") {
        floatingSelectUuidNullable(
          valueAndSetter = verteilerNetzbetreiberState,
          formatter = { it?.name ?: "-" },
          fieldName = "verteilerNetzbetreiber",
          title = "Verteilernetzbetreiber",
          availableOptionsWithoutNull = Netzbetreiber.availableLizergyNetzbetreiber,
        ) {
          attrs {
            mergedBelow()
          }
        }
        floatingInputField(
          valueAndSetter = verteilerNetzbetreiberOverrideState,
          fieldName = "verteilerNetzbetreiberOverride",
          title = "Verteilernetzbetreiber",
        ) {
          attrs {
            mergedAbove()
          }
        }
      }
    }
    div(secondColumn) {}
  }

  if (currentConfiguration != null) {
    GridAssessmentExistingFacilitiesOverview {
      attrs {
        this.project = project
        this.currentConfiguration = currentConfiguration
      }
    }
  }


  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface GridAssessmentOverviewProps : Props {
  var project: ResolvedProject
}
