package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.financial.currency.Money
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable

/**
 * Contains the information regarding
 */
@Serializable
data class AmortisationInformation(
  /**
   * The invested sum
   */
  val investment: @EUR Money,

  /**
   * The total cash flow.
   *
   * This value is very different for financed vs self-financed scenarios.
   * In the first case the cumulated cash flow starts "0€". In the second it start at "-invested sum".
   */
  val cashFlowCumulated: @EUR Money,

  ) {
  /**
   * "Amortisationsgrad"
   */
  fun amortisationPercentage(financingInformation: FinancingInformation): @pct Double {
    val residualDept = financingInformation.residualDebtAfterRepayment
    return amortisationPercentage(residualDept)
  }

  fun amortisationPercentage(residualDept: Money): Double {
    return (cashFlowCumulated - residualDept) / investment + 1
  }

  class Calculator(
    /**
     * The initial investment
     */
    var initialInvestment: @EUR Money,
  ) {

    /**
     * Calculates the base year.
     *
     * Attention: The loaned sum is a *positive* cash flow
     */
    fun calculateBaseYear(cashFlow: CashFlow, financingInformation: FinancingInformation): AmortisationInformation {
      return AmortisationInformation(
        initialInvestment,
        cashFlow.cashFlow - initialInvestment + financingInformation.residualDebtBeforeRepayment
      )
    }

    fun calculate(previousAmortisation: AmortisationInformation, cashFlow: CashFlow): AmortisationInformation {
      return AmortisationInformation(
        initialInvestment,
        previousAmortisation.cashFlowCumulated + cashFlow.cashFlow
      )
    }
  }
}
