package it.neckar.rest.tracking

import io.ktor.http.*


/**
 * The header key for the client ID
 */
@Suppress("UnusedReceiverParameter")
val HttpHeaders.XClientId: String
  get() {
    return "X-Client-Id"
  }
