package it.neckar.processStates

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid

/**
 * Represents an entry in the process state history.
 */
interface ProcessStateEntry : HasUuid, HasLifeCycle {
  /**
   * The ID for this entry
   */
  val id: ProcessStateEntryId

  /**
   * The current process state for this entry
   */
  val processState: ProcessStates

  val assignedTo: UserLoginName
  val belongsTo: CompanyCode

  val assignedAt: @ms Double
  val assignedBy: UserLoginName

  override val uuid: Uuid
    get() = id.uuid

  val availableOptions: List<ProcessStates>

  fun format(i18nConfiguration: I18nConfiguration): String

  fun formatWithDate(i18nConfiguration: I18nConfiguration): String {
    return "${format(i18nConfiguration)} ${processState.formatDate(assignedAt, i18nConfiguration)}".trim()
  }
}

/**
 * Filter the list of [ProcessStates]s to only those in the given [ProcessStatePhase]
 */
fun List<ProcessStates>.onlyInPhase(processStatePhase: ProcessStatePhase): List<ProcessStates> {
  return filter { it.inPhase == processStatePhase }
}

/**
 * Filter the list of [ProcessStates]s to only those not in the given [ProcessStatePhase]
 */
fun List<ProcessStates>.onlyUnfinished(): List<ProcessStates> {
  return filter { it.inPhase.isFinished.not() }
}
