package it.neckar.lizergy.model.validation

import it.neckar.open.collections.fastForEach
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import kotlinx.serialization.Serializable

data class ValidationProblems(
  val titleMessages: List<TitleMessage>,
  val ownProblems: List<ValidationProblem>,
  val childProblems: List<ValidationProblems>? = null,
) {

  fun worstValidationProblem(includeChildren: Boolean = true): ProblemType? {
    val allProblems = if (includeChildren) allProblems() else ownProblems
    return (allProblems.find {
      it.evaluationLambda() == ProblemType.Error
    } ?: allProblems.find {
      it.evaluationLambda() == ProblemType.Warning
    })?.evaluationLambda?.invoke()
  }

  fun allProblems(): List<ValidationProblem> {
    return ownProblems + (childProblems?.flatMap { it.allProblems() } ?: emptyList())
  }

  fun hasErrors(includeChildren: Boolean = true): Boolean {
    return checkRecursively(includeChildren) {
      onlyProblemTypeAsList(ProblemType.Error, includeChildren).isNotEmpty()
    }
  }

  fun onlyProblemTypeAsList(problemType: ProblemType, includeChildren: Boolean = true): List<ValidationProblem> {
    return gatherRecursively(includeChildren) {
      ownProblems.filter { it.evaluationLambda() == problemType }
    }
  }


  fun getTitleMessage(problemType: ProblemType?): String? {
    val problemCount = problemType?.let { onlyProblemTypeAsList(it).size } ?: 0
    return titleMessages.find { it.problemType == problemType }?.messageLambda?.invoke(problemCount)
  }

  @Suppress("UNUSED_PARAMETER")
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    return buildList {
      add(problemList(ProblemType.Error))
      add(problemList(ProblemType.Warning))
    }.joinToString("\n\n")
  }

  private fun problemList(problemType: ProblemType): String {

    val hasProblems = ownProblems.any { it.evaluationLambda() == problemType }

    return buildList {

      buildList {

        if (hasProblems.not()) {

          if (problemType == ProblemType.Error) {
            getTitleMessage(null)?.let { add(it) }
          }

        } else {

          when (problemType) {

            ProblemType.Error -> {
              getTitleMessage(ProblemType.Error)?.let { add(it) }
            }

            ProblemType.Warning -> {
              getTitleMessage(ProblemType.Warning)?.let { add(it) }
            }

          }

          ownProblems.fastForEach { problem ->
            if (problem.evaluationLambda() == problemType) add(problem.format())
          }

        }
      }.let { if (it.isNotEmpty()) add(it.joinToString("\n")) }

      val worstProblem = worstValidationProblem()
      if (worstProblem != null && worstProblem >= problemType) {
        childProblems?.fastForEach { childProblems ->
          childProblems.problemList(problemType).let { if (it.isNotBlank()) add(it) }
        }
      }

    }.joinToString("\n\n")

  }


  private fun checkRecursively(includeChildren: Boolean = true, lambda: ValidationProblems.(includeChildren: Boolean) -> Boolean): Boolean {
    return lambda(this, includeChildren) || (includeChildren && childProblems?.any { it.lambda(includeChildren) } == true)
  }

  private fun gatherRecursively(includeChildren: Boolean = true, lambda: ValidationProblems.(includeChildren: Boolean) -> List<ValidationProblem>): List<ValidationProblem> {
    return lambda(this, includeChildren).let { validationProblems ->
      if (includeChildren && childProblems != null) {
        validationProblems + childProblems.flatMap { it.lambda(includeChildren) }
      } else {
        validationProblems
      }
    }
  }


  companion object {
    val empty: ValidationProblems
      get() = ValidationProblems(titleMessages = emptyList(), ownProblems = emptyList(), childProblems = emptyList())
  }

}

@Serializable
enum class ProblemType {
  Warning,
  Error,
}

data class ValidationProblem(
  val message: String,
  val evaluationLambda: () -> ProblemType?,
) {

  @Suppress("UNUSED_PARAMETER")
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    return "-${Typography.nbsp}$message"
  }
}

data class TitleMessage(
  val problemType: ProblemType?,
  val messageLambda: (problemCount: Int) -> String,
)
