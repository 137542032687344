package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.open.collections.fastForEach
import it.neckar.processStatesClient.SendProcessStatesTuple
import store.PlannerClientState

data class ProcessStatesAddedAction(val processStateForComponents: List<SendProcessStatesTuple>) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    var updatedProcessStatesForComponent = allProcessStatesForComponents

    processStateForComponents.fastForEach { processStatesForComponent ->
      updatedProcessStatesForComponent = updatedProcessStatesForComponent?.withUpdatedProcessState(processStatesForComponent.processStateFor, processStatesForComponent.processStateEntry)
    }

    return this.copy(allProcessStatesForComponents = updatedProcessStatesForComponent)
  }
}
