package components.project

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.open.kotlin.lang.nullIfBlank
import react.*
import react.dom.*

/**
 *
 */
fun RBuilder.inquiryInformationPreview(quoteConfiguration: QuoteConfiguration): Unit = child(InquiryInformationPreview) {
  attrs {
    this.quoteConfiguration = quoteConfiguration
  }
}

val InquiryInformationPreview: FC<InquiryInformationPreviewProps> = fc("InquiryInformationPreview") { props ->
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val zaehlerNummer = quoteConfiguration.zaehlerNummer
  val facilityOperator1Name = quoteConfiguration.facilityOperator1.name
  val facilityOperator2Name = quoteConfiguration.facilityOperator2.name
  val einspeiseart = quoteConfiguration.einspeiseart


  p {
    +"Zählernummer: ${zaehlerNummer.nullIfBlank() ?: "-"}"
  }

  p {
    +"Anlagenbetreiber 1: ${facilityOperator1Name.nullIfBlank() ?: "-"}"
  }
  p {
    +"Anlagenbetreiber 2: ${facilityOperator2Name.nullIfBlank() ?: "-"}"
  }

  p {
    +"Einspeiseart: ${einspeiseart.format()}"
  }

}

external interface InquiryInformationPreviewProps : Props {
  var quoteConfiguration: QuoteConfiguration
}
