package components

import it.neckar.open.unit.other.pct
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faSignOut
import it.neckar.react.common.loading.*
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import services.UiActions
import store.actions.LogoutReason
import store.hooks.useSelectPendingRequestsProgress
import store.hooks.useSelectPendingRequestsState

/**
 * Temporary page that is shown while the essentials are loaded
 */
val EssentialsLoadingPage: FC<Props> = fc("EssentialsLoading") {
  div {
    main {
      div("container p-4") {

        nav("py-2 bg-light border-bottom") {
          div("container d-flex flex-wrap") {

            OnlineStateBanner {}

            ul("nav mx-3") {
              li("nav-item") {
                button(classes = "btn btn-link nav-link link-dark px-2", type = ButtonType.button) {
                  span("pe-2") {
                    faSignOut()
                  }
                  +"Logout"
                  attrs {
                    onClickFunction = {
                      it.preventDefault()
                      UiActions.logout(LogoutReason.UserInteraction)
                    }
                  }
                }
              }
            }
          }
        }

        div {
          h3 { +"Anwendung wird geladen" }
        }

        busyIfNull(null) {
        }

        div {
          val pendingRequestsState = useSelectPendingRequestsState()
          @pct val pendingRequestsProgress = useSelectPendingRequestsProgress()

          bootstrapProgressBarCustomColor("", pendingRequestsProgress, "bg-primary")
          PendingRequestsStateInfo {
            attrs {
              this.pendingRequestsState = pendingRequestsState
            }
          }
        }
      }
    }
  }

}
