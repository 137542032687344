package it.neckar.react.common.router.key

import convertRelease
import it.neckar.react.common.router.*
import kotlinx.browser.window
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import react.*

/**
 * Registers a component that catches keystrokes and navigates accordingly
 */
val KeyboardNavigation: FC<KeyboardNavigationProps> = fc("KeyboardNavigation") { props ->
  val navigateUrl = useNavigateUrl()

  val handler = props.handler

  useEffectOnceWithCleanup {
    val listener = { event: Event ->
      require(event is KeyboardEvent)

      val keyEvent = event.convertRelease()
      val result = handler.handleKeyStroke(keyEvent.keyStroke, navigateUrl)

      if (result == NavigationKeyStrokeHandler.NavigationResult.Handled) {
        event.stopPropagation()
        event.stopImmediatePropagation()
        event.preventDefault()
      }

      Unit
    }

    window.addEventListener("keyup", listener)

    //Cleanup
    onCleanup {
      window.removeEventListener("keyup", listener)
    }
  }
}


external interface KeyboardNavigationProps : Props {
  var handler: NavigationKeyStrokeHandler
}
