package it.neckar.lizergy.model.project.process.state

import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import it.neckar.processStates.ProcessStateEntryId
import it.neckar.processStates.ProcessStatePhase
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("ProjectProcessStateEntry")
data class ProjectProcessStateEntry(
  override val id: ProcessStateEntryId,
  override val processState: ProjectProcessStates,
  override val assignedTo: UserLoginName,
  override val belongsTo: CompanyCode,
  override val assignedAt: @ms @Serializable(with = DoubleAsIsoDateTimeSerializer::class) Double,
  override val assignedBy: UserLoginName,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : LizergyProcessStateEntry {

  override val availableOptions: List<ProjectProcessStates>
    get() = ProjectProcessStates.entries

  override fun format(i18nConfiguration: I18nConfiguration): String {
    return processState.format(i18nConfiguration)
  }

  @Serializable
  @SerialName("ProjectProcessStates")
  enum class ProjectProcessStates : LizergyProcessStates {
    BeingEdited,
    Paused,
    Archived,

    ;

    override val inPhase: ProcessStatePhase
      get() = when (this) {
        BeingEdited -> ProcessStatePhase.InProgress
        Paused -> ProcessStatePhase.Paused
        Archived -> ProcessStatePhase.Paused
      }

    override fun format(i18nConfiguration: I18nConfiguration): String {
      return when (this) {
        BeingEdited -> "Projekt in Bearbeitung"
        Paused -> "Projekt pausiert"
        Archived -> "Projekt archiviert"
      }
    }

    override fun formatDate(date: Double, i18nConfiguration: I18nConfiguration): String {
      return "seit ${dateFormat.format(date, i18nConfiguration)}"
    }
  }
}
