package it.neckar.ktor.client.auth

import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*

/**
 * Clears the JWS token from the [Auth] plugin.
 */
fun HttpClient.clearJwsToken() {
  plugin(Auth).clearJwsToken()
}

/**
 * Clears the JWS token.
 *
 * Workaround as suggested here:
 * https://youtrack.jetbrains.com/issue/KTOR-4759/Auth-BearerAuthProvider-caches-result-of-loadToken-until-process-death
 */
fun Auth.clearJwsToken() {
  providers.filterIsInstance<BearerAuthProvider>().first().clearToken()
}
