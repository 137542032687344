package components.form

import com.benasher44.uuid.Uuid
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.uuid.HasUuid
import react.*
import react.dom.*
import store.hooks.useLoadCommentsForComponent

fun RBuilder.commentSection(
  commentsFor: Uuid,
  editableStatus: EditableStatus = Editable,
): Unit = child(CommentSection) {
  attrs {
    this.commentsFor = commentsFor
    this.editableStatus = editableStatus
  }
}

fun RBuilder.commentSection(
  commentsFor: HasUuid,
  editableStatus: EditableStatus = Editable,
): Unit = commentSection(commentsFor = commentsFor.uuid, editableStatus = editableStatus)

val CommentSection: FC<CommentSectionProps> = fc("CommentSection") { props ->
  val commentsFor = props::commentsFor.getNotNull()
  val editableStatus = props::editableStatus.safeGet()

  val comments = useLoadCommentsForComponent(commentsFor)

  busyIfNull(comments) { loadedComments ->

    div("list-group gy-3") {
      loadedComments.fastForEach {
        //Existing Comment
        commentForm(commentsFor, it, editableStatus)
      }

      //New comment
      if (editableStatus == Editable) {
        commentForm(commentsFor, null, editableStatus)
      }
    }

  }
}

external interface CommentSectionProps : Props {
  var commentsFor: Uuid
  var editableStatus: EditableStatus
}
