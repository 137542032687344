package it.neckar.financial.currency

import it.neckar.open.unit.number.IsFinite
import it.neckar.open.unit.time.PerHour
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours
import kotlin.time.DurationUnit

/**
 * Represents costs per hour
 */
@JvmInline
@Serializable
value class PricePerHour(
  /**
   * The price per hour
   */
  val price: @PerHour Money,
) {

  val priceWithProfit: PriceWithProfit
    get() = PriceWithProfit(0.00.euro, price)

  /**
   * Calculates the price for the given duration
   */
  operator fun times(duration: Duration): PriceWithProfit {
    return PriceWithProfit(0.00.euro, price * duration.toDouble(DurationUnit.HOURS))
  }

  companion object {

    val zero: PricePerHour = PricePerHour(Money.Zero)

    /**
     * Returns the price per hour (for a euros double value)
     */
    fun euros(euros: @IsFinite Double): PricePerHour {
      return PricePerHour(euros.euro)
    }

    fun euros(euros: Money): PricePerHour {
      return PricePerHour(euros)
    }

  }
}

operator fun Duration.times(pricePerHour: PricePerHour): PriceWithProfit {
  return pricePerHour * this
}

operator fun PriceWithProfit.div(pricePerHour: PricePerHour): Duration {
  return (this.sellingPrice / pricePerHour.price).hours
}
