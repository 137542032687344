package components.project.address

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Creates a form for an address
 */
fun RBuilder.addressForm(
  street: StateInstance<String>,
  houseNumber: StateInstance<String>,
  zipCode: StateInstance<String>,
  city: StateInstance<String>,
  editableStatus: EditableStatus,
): Unit = child(addressForm) {
  attrs {
    this.street = street
    this.houseNumber = houseNumber
    this.zipCode = zipCode
    this.city = city
    this.editableStatus = editableStatus
  }
}

val addressForm: FC<AddressFormProps> = fc("addressForm") { props ->

  val street = props::street.getNotNull()
  val houseNumber = props::houseNumber.getNotNull()
  val zipCode = props::zipCode.getNotNull()
  val city = props::city.getNotNull()

  val editableStatus = props::editableStatus.safeGet()

  div("row g-0") {

    div("col-9") {
      floatingInputField(
        valueAndSetter = street,
        fieldName = "address",
        title = "Straße",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
          mergedRight()
        }
      }
    }
    div("col-3") {
      floatingInputField(
        valueAndSetter = houseNumber,
        fieldName = "houseNumber",
        title = "Hausnummer",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
          mergedLeft()
        }
      }
    }

  }

  div("row gx-0") {

    div("col-4") {
      floatingInputField(
        valueAndSetter = zipCode,
        fieldName = "zipCode",
        title = "Postleitzahl",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
          mergedLeft()
          mergedRight()
        }
      }
    }

    div("col-8") {
      floatingInputField(
        city,
        fieldName = "city",
        title = "Ort",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
          mergedLeft()
        }
      }
    }

  }

}

external interface AddressFormProps : Props {
  var street: StateInstance<String>
  var houseNumber: StateInstance<String>
  var zipCode: StateInstance<String>
  var city: StateInstance<String>
  var editableStatus: EditableStatus
}
