package router

import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof
import it.neckar.lizergy.model.project.ProjectConfiguration
import it.neckar.uuid.encodeForUrl

/**
 * Extension Methods to get the encoded UUIDs to Base64
 * See [encodeForUrl]
 * */
fun ProjectConfiguration.PhotovoltaicsProjectId.encodeForUrl(): String {
  return this.uuid.encodeForUrl()
}

fun PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId.encodeForUrl(): String {
  return this.uuid.encodeForUrl()
}

fun ConfigurationItem.ConfigurationItemId.encodeForUrl(): String {
  return this.uuid.encodeForUrl()
}

fun ModuleLayout.ModuleLayoutId.encodeForUrl(): String {
  return this.uuid.encodeForUrl()
}

fun Roof.RoofId.encodeForUrl(): String {
  return this.uuid.encodeForUrl()
}
