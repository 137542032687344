package it.neckar.lizergy.model.configuration.quote.economics

import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.quote.economics.simple.PricePerKWh
import it.neckar.open.unit.other.kWp

/**
 * Contains the guaranteed feed in prices
 */
object FeedInPrices {
  /**
   * The feed in prices
   *
   * Values from
   * https://www.verbraucherzentrale.de/wissen/energie/erneuerbare-energien/eeg-2023-das-hat-sich-fuer-photovoltaikanlagen-geaendert-75401
   */
  /**
   * "Dachanlagen bis 10 kWp"
   */
  val upTo10kWP: PricePerKWh = PricePerKWh.cents(8.03)

  /**
   * "Dachanlagen bis 30 kWp"
   */
  val upTo30kWP: PricePerKWh = PricePerKWh.cents(6.95)

  /**
   * "Dachanlagen über 30 kWP"
   */
  val over30kWP: PricePerKWh = PricePerKWh.zero

  /**
   * Returns the feed in prices for the given kWP
   */
  operator fun get(powerRating: @kWp PowerRating): PricePerKWh {
    return if (powerRating.kiloWattPeak <= 10.0) {
      upTo10kWP
    } else if (powerRating.kiloWattPeak >= 30.0) {
      over30kWP
    } else {
      PricePerKWh.cents((upTo10kWP.cents * 10.0 + upTo30kWP.cents * (powerRating.kiloWattPeak - 10.0)) / powerRating.kiloWattPeak)
    }
  }
}
