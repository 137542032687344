package components.project.configuration.powerusage

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.energy.AmountOfEnergy
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.open.formatting.formatAsPercentage
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.other.pct100
import it.neckar.open.unit.si.kWh
import it.neckar.open.unit.si.km
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import plannerI18nConfiguration
import react.*
import react.dom.*

/**
 *
 */
fun RBuilder.powerUsageForm(
  quoteConfiguration: QuoteConfiguration?,
  yearlyPowerConsumptionState: StateInstance<@kWh Int>,
  waermepumpeConsumptionState: StateInstance<@kWh Int>,
  electricCarUsageState: StateInstance<@km Int>,
  manualPowerConsumptionState: StateInstance<@pct100 Int?>,
  editableStatus: EditableStatus,
): Unit = child(PowerUsageForm) {
  attrs {
    this.quoteConfiguration = quoteConfiguration
    this.yearlyPowerConsumptionState = yearlyPowerConsumptionState
    this.waermepumpeConsumptionState = waermepumpeConsumptionState
    this.electricCarUsageState = electricCarUsageState
    this.manualPowerConsumptionState = manualPowerConsumptionState
    this.editableStatus = editableStatus
  }
}

val PowerUsageForm: FC<PowerUsageFormProps> = fc("PowerUsageForm") { props ->
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val yearlyPowerConsumptionState = props::yearlyPowerConsumptionState.getNotNull()
  val waermepumpeConsumptionState = props::waermepumpeConsumptionState.getNotNull()
  val manualPowerConsumptionState = props::manualPowerConsumptionState.getNotNull()
  val electricCarUsageState = props::electricCarUsageState.getNotNull()

  val editableStatus = props::editableStatus.safeGet()


  div("form-floating mt-3") {
    floatingIntInputField(
      valueAndSetter = yearlyPowerConsumptionState,
      fieldName = "yearlyPowerConsumption",
      title = "Jahresverbrauch (kWh)",
      numberConstraint = ZeroOrPositive,
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedBelow()
      }
    }

    floatingIntInputField(
      valueAndSetter = waermepumpeConsumptionState,
      fieldName = "waermepumpeConsumption",
      title = "Verbrauch Wärmepumpe (kWh)",
      numberConstraint = ZeroOrPositive,
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedAbove()
        mergedBelow()
      }
    }

    floatingIntInputField(
      valueAndSetter = electricCarUsageState,
      fieldName = "electricCarUsage",
      title = "E-Auto Nutzung (km)",
      numberConstraint = ZeroOrPositive,
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedAbove()
      }
    }
  }

  val houseEnergyConsumption = AmountOfEnergy(yearlyPowerConsumptionState.value + waermepumpeConsumptionState.value)
  val carEnergyConsumption = (PowerUsageScenario.electricCarConsumptionPerKilometer * electricCarUsageState.value)
  val totalEnergyConsumption = houseEnergyConsumption + carEnergyConsumption
  p("form-text") {
    +"Gesamt: ${totalEnergyConsumption.format(plannerI18nConfiguration)} (Jahresverbrauch & Wärmepumpe: ${houseEnergyConsumption.format(plannerI18nConfiguration)} + E-Auto: ${carEnergyConsumption.format(plannerI18nConfiguration)})"
  }


  quoteConfiguration?.let {
    // Calculate the power consumption provided by PV
    val calculatedPowerConsumptionProvidedByPvPercentage: @pct Double = useMemo(quoteConfiguration, yearlyPowerConsumptionState.value, waermepumpeConsumptionState.value, electricCarUsageState.value) {
      quoteConfiguration.copy(
        powerUsageScenario = quoteConfiguration.powerUsageScenario.copy(
          yearlyPowerConsumption = AmountOfEnergy.kWh(yearlyPowerConsumptionState.value.toDouble()),
          waermepumpeConsumption = AmountOfEnergy.kWh(waermepumpeConsumptionState.value.toDouble()),
          electricCarUsage = electricCarUsageState.value.toDouble(),
        ),
        manualPowerConsumptionDistribution = null,
      )
    }.powerConsumptionDistribution.selfProducedPercentage

    div("form-floating my-3") {
      nullableFloatingIntInputField(
        valueAndSetter = manualPowerConsumptionState,
        fieldName = "yearlyPowerConsumption",
        title = "${(manualPowerConsumptionState.value?.percent ?: calculatedPowerConsumptionProvidedByPvPercentage).formatAsPercentage(0)} Autarkiegrad",
        numberConstraint = ZeroOrPositive,
        editableStatus = editableStatus,
      )
    }
  }

}

external interface PowerUsageFormProps : Props {
  var quoteConfiguration: QuoteConfiguration?
  var yearlyPowerConsumptionState: StateInstance<@kWh Int>
  var waermepumpeConsumptionState: StateInstance<@kWh Int>
  var electricCarUsageState: StateInstance<@km Int>
  var manualPowerConsumptionState: StateInstance<@pct100 Int?>
  var editableStatus: EditableStatus
}
