package com.meistercharts.charts.lizergy.roofPlanning

/**
 * Contains the selection for a roof planning.
 * This can be selected:
 * - grid
 * - manual module
 * - unusable area
 *
 * Only one of these can be selected at the same time
 */
@Deprecated("do no longer use!")
class RoofPlanningSelectionModel {
  /**
   * The selected grid layout
   */
  var selectedGrid: ModuleArea? = null
    private set

  /**
   * The selected *manually placed* module (that must not be part of a grid)
   */
  var selectedModule: Module? = null
    private set

  /**
   * The selected unusable area
   */
  var selectedUnusableArea: UnusableArea? = null
    private set

  fun select(toSelect: Module) {
    selectedModule = toSelect
    selectedUnusableArea = null
    selectedGrid = null
  }

  fun select(toSelect: UnusableArea) {
    selectedUnusableArea = toSelect
    selectedModule = null
    selectedGrid = null
  }

  fun select(toSelect: ModuleArea) {
    selectedGrid = toSelect
    selectedModule = null
    selectedUnusableArea = null
  }

  fun clearSelection() {
    selectedGrid = null
    selectedModule = null
    selectedUnusableArea = null
  }

  /**
   * Returns true if the given element is currently selected
   */
  fun isSelected(unusableArea: UnusableArea): Boolean {
    return selectedUnusableArea == unusableArea
  }

  fun isSelected(module: Module): Boolean {
    return selectedModule == module
  }
}
