package it.neckar.lizergy.model.configuration.quote.builder

import it.neckar.commons.tags.Tag
import it.neckar.commons.tags.Tags
import it.neckar.commons.tags.contains
import it.neckar.commons.tags.containsNone
import it.neckar.financial.quote.CalculationRelevanceQuery
import it.neckar.financial.quote.NoneCalculationRelevanceQuery
import it.neckar.financial.quote.asQuery
import it.neckar.financial.quote.flat.FlatQuoteLine
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration

/**
 * Contains the lizergy calculations
 */
object LizergyCalculationCategories {
  /**
   * The item is relevant for the economics calculation
   */
  val EconomicsCalculation: Tag = Tag("Economics")

  /**
   * Battery storage related stuff
   */
  val BatteryStorage: Tag = Tag("BatteryStorage")

  val Scaffolding: Tag = Tag("Scaffolding")

  val ElectricityMaterial: Tag = Tag("ElectricityMaterial")

  /**
   * Assembly on the roof
   */
  val Assembly: Tag = Tag("Assembly")

  val ElectricityWork: Tag = Tag("ElectricityWork")


  /**
   * Contains the queries that can be used to query the quote elements
   */
  object Query {
    /**
     * For economics calculations
     */
    val EconomicsCalculationRelevance: CalculationRelevanceQuery = EconomicsCalculation.asQuery()

    /**
     * Electricity work related
     */
    val ElectricityWorkRelevance: CalculationRelevanceQuery = ElectricityMaterial.asQuery()

    /**
     * Contains queries that can be used for profit calculations
     */
    object Profits {
      /**
       * Returns the query for the given category
       */
      operator fun get(category: IncomePercentageCategory): CalculationRelevanceQuery {
        return when (category) {
          IncomePercentageCategory.Profit1 -> Profit1
          IncomePercentageCategory.Profit2Battery -> Profit2Battery
          IncomePercentageCategory.Profit3Scaffolding -> Profit3Scaffolding
          IncomePercentageCategory.Profit4ElectricityMaterial -> Profit4ElectricityMaterial
          IncomePercentageCategory.Profit5Assembly -> Profit5Assembly
          IncomePercentageCategory.Profit6ElectricityWork -> Profit6ElectricityWork
        }
      }

      val Profit2Battery: CalculationRelevanceQuery = CalculationRelevanceQuery.onlyMandatory + BatteryStorage.asQuery()
      val Profit3Scaffolding: CalculationRelevanceQuery = CalculationRelevanceQuery.onlyMandatory + Scaffolding.asQuery()
      val Profit4ElectricityMaterial: CalculationRelevanceQuery = CalculationRelevanceQuery.onlyMandatory + ElectricityMaterial.asQuery()
      val Profit5Assembly: CalculationRelevanceQuery = CalculationRelevanceQuery.onlyMandatory + Assembly.asQuery()
      val Profit6ElectricityWork: CalculationRelevanceQuery = CalculationRelevanceQuery.onlyMandatory + ElectricityWork.asQuery()

      /**
       * Returns all positions that are *not* part of battery, scaffolding or electricity
       */
      val Profit1: CalculationRelevanceQuery = CalculationRelevanceQuery.onlyMandatory + NoneCalculationRelevanceQuery(
        listOf(Profit2Battery, Profit3Scaffolding, Profit4ElectricityMaterial, Profit5Assembly, Profit6ElectricityWork)
      )
    }
  }
}

@Suppress("UNUSED_PARAMETER")
fun FlatQuoteLine.formatForTable(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
  return IncomePercentageCategory.entries.mapNotNull { category ->
    if (isSumPosition.not()) {
      if (matches(category)) {
        category.description
      } else null
    } else null
  }.joinToString()
}

/**
 * Returns true if the given line matches the category
 */
fun FlatQuoteLine.matches(category: IncomePercentageCategory): Boolean {
  if (isSumPosition) {
    //Do not match any income percentage category for summed positions
    return false
  }

  return this.relevance.matches(category)
}

fun Tags?.matches(category: IncomePercentageCategory): Boolean {
  return when (category) {

    IncomePercentageCategory.Profit1 -> {
      this.containsNone(LizergyCalculationCategories.BatteryStorage, LizergyCalculationCategories.Scaffolding, LizergyCalculationCategories.ElectricityMaterial, LizergyCalculationCategories.Assembly, LizergyCalculationCategories.ElectricityWork)
    }

    IncomePercentageCategory.Profit2Battery -> {
      this.contains(LizergyCalculationCategories.BatteryStorage)
    }

    IncomePercentageCategory.Profit3Scaffolding -> {
      this.contains(LizergyCalculationCategories.Scaffolding)
    }

    IncomePercentageCategory.Profit4ElectricityMaterial -> {
      this.contains(LizergyCalculationCategories.ElectricityMaterial)
    }

    IncomePercentageCategory.Profit5Assembly -> {
      this.contains(LizergyCalculationCategories.Assembly)
    }

    IncomePercentageCategory.Profit6ElectricityWork -> {
      this.contains(LizergyCalculationCategories.ElectricityWork)
    }

  }
}
