@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

interface InverterSelection : HasUuid {
  val id: InverterSelectionId

  override val uuid: Uuid
    get() = id.uuid

  @Serializable
  data class InverterSelectionId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): InverterSelectionId {
        return InverterSelectionId(randomUuid4())
      }
    }
  }
}
