package it.neckar.lizergy.model

import it.neckar.lizergy.model.configuration.quote.economics.EquityFinanced
import it.neckar.lizergy.model.configuration.quote.economics.FinancingType
import it.neckar.lizergy.model.configuration.quote.economics.Leveraged
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.lizergy.model.price.StaticPriceList
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyBasementProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry
import it.neckar.processStates.ProcessStateEntry
import kotlinx.serialization.json.JsonBuilder
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.SerializersModuleBuilder
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass

/**
 * Contains the kotlinx serialization configuration for the planner
 */
fun SerializersModuleBuilder.plannerSerializationModel() {

  polymorphic(AvailableProducts::class) {
    subclass(StaticPriceList::class)
  }
  polymorphic(ProductResolver::class) {
    subclass(StaticPriceList::class)
  }
  polymorphic(PriceList::class) {
    subclass(StaticPriceList::class)
  }

  polymorphic(FinancingType::class) {
    subclass(EquityFinanced::class)
  }
  polymorphic(FinancingType::class) {
    subclass(Leveraged::class)
  }

  polymorphic(ProcessStateEntry::class) {
    subclass(AdvanceInvoiceProcessStateEntry::class)
    subclass(AssemblyBasementProcessStateEntry::class)
    subclass(AssemblyBasementPreparationProcessStateEntry::class)
    subclass(AssemblyPortfolioProcessStateEntry::class)
    subclass(AssemblyRoofProcessStateEntry::class)
    subclass(AssemblyRoofPreparationProcessStateEntry::class)
    subclass(BlueprintAcquisitionProcessStateEntry::class)
    subclass(BlueprintProcessStateEntry::class)
    subclass(ConfigurationProcessStateEntry::class)
    subclass(DocumentationProcessStateEntry::class)
    subclass(FinalAccountProcessStateEntry::class)
    subclass(FinishingUpProcessStateEntry::class)
    subclass(GridAssessmentProcessStateEntry::class)
    subclass(OrderSpecialMaterialProcessStateEntry::class)
    subclass(PresentationProcessStateEntry::class)
    subclass(ProjectProcessStateEntry::class)
    subclass(QuoteConfirmationProcessStateEntry::class)
    subclass(QuoteOfferProcessStateEntry::class)
    subclass(StartupOperationsProcessStateEntry::class)
    subclass(SwitchMeterBoxProcessStateEntry::class)
  }

}

/**
 * Default properties for Json Serialization
 * */
fun JsonBuilder.defaultJsonConfiguration(prettyPrintEnabled: Boolean = true) {
  prettyPrint = prettyPrintEnabled
  /**
   * encode default properties of Serializable Classes
   * */
  encodeDefaults = true
}

/**
 * contains the default properties and serializer module for lizergy
 * @see JsonBuilder
 * */
fun JsonBuilder.plannerJsonConfiguration(prettyPrintEnabled: Boolean = true) {
  defaultJsonConfiguration(prettyPrintEnabled)
  serializersModule = SerializersModule {
    plannerSerializationModel()
  }
}
