package it.neckar.financial.quote

import it.neckar.financial.currency.Money
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.formatting.format
import it.neckar.open.formatting.percentageFormat
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.kotlin.lang.isPositiveOrZero
import it.neckar.open.kotlin.lang.replaceUnusualSpaces
import it.neckar.open.kotlin.lang.substr
import it.neckar.open.kotlin.lang.wrap
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract

/**
 * Represents the "numbers" of a quote.
 * A [QuoteElements] object contains a tree-like structure of sections and items.
 *
 * Output is usually done in form of a list. Therefore, there exists [it.neckar.financial.quote.flat.FlatQuoteElements] which simplifies this process.
 */
@Serializable
data class QuoteElements(
  /**
   * The sections: In the top level only sections are allowed.
   */
  val sections: List<QuoteSection>,

  /**
   * The discount (percentage)
   */
  val discountPercentage: @pct Double,

  ) {

  init {
    require(discountPercentage.isPositiveOrZero()) {
      "Invalid discount percentage <$discountPercentage>"
    }
  }

  /**
   * Total of the invoice (for the given relevance) - without discount subtracted
   */
  fun subTotalsForVATs(sumQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.defaultForSum): SumsForVATs {
    return SumsForVATs(sections.map { quoteElement -> quoteElement.sums(sumQuery) })
  }

  fun discountsForVATs(sumQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.defaultForSum): PricesForVATs {
    return subTotalsForVATs(sumQuery).mapNetValuesToMoney { it.getDiscount() }
  }

  /**
   * Returns the net value ("Netto") - discount removed - for only the given relevance
   */
  fun netPricesForVats(sumQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.defaultForSum): PricesForVATs {
    return subTotalsForVATs(sumQuery).mapNetValuesToMoney { it.toNetPrice() }
  }

  fun grossPricesForVats(sumQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.defaultForSum): PricesForVATs {
    return netPricesForVats(sumQuery).toGrossPrices()
  }

  fun vatPrices(sumQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.defaultForSum): PricesForVATs {
    return netPricesForVats(sumQuery).getVatPrices()
  }


  fun PriceWithProfit.toNetPrice(): Money {
    return this.sellingPrice * (1 - discountPercentage)
  }

  fun PriceWithProfit.getDiscount(): Money {
    return -this.sellingPrice * discountPercentage
  }


  /**
   * Dumps the content of the quote as string
   */
  fun dump(
    /**
     * Which items / sections are visible
     */
    visibilityQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.allIncludingOptional,
    /**
     * Which items / sections are used to calculate the sum
     */
    sumQuery: CalculationRelevanceQuery = CalculationRelevanceQuery.defaultForSum,

    i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration,
  ): String {
    val indexLength = 9
    val headlineLength = 65

    val priceLength = 30
    val amountLength = 15
    val totalLength = 30

    val lineLength = 150

    return buildString {
      /**
       * The method that is called recursively to paint the items
       */
      fun List<QuoteElement>.renderChildren(baseIndex: String?) {
        fastForEachIndexed { elementIndex, quoteElement ->
          if (visibilityQuery.matches(quoteElement).not()) {
            //Skip internal items, if query does not match
            return@fastForEachIndexed
          }

          val myIndex = (elementIndex + 1).format(i18nConfiguration)
          val indexFormatted = if (baseIndex != null) "$baseIndex.$myIndex" else myIndex

          append(indexFormatted.padEnd(indexLength - 2))
          val typeChar = when (quoteElement) {
            is QuoteItem -> "I"
            is QuoteSection -> "S"
          }
          append(" $typeChar ")

          //Headline
          if (quoteElement.isOptional()) {
            append(("[${quoteElement.headline.replaceUnusualSpaces()}]").ensureLength(headlineLength))
          } else {
            append(quoteElement.headline.replaceUnusualSpaces().ensureLength(headlineLength))
          }

          //Price for one element
          if (quoteElement is QuoteItem) {
            append(quoteElement.priceForOneElement.dump(i18nConfiguration).padStart(priceLength))
            append(quoteElement.amount.format(i18nConfiguration, WhitespaceConfig.Spaces).padStart(amountLength))
          } else {
            append(" ".repeat(priceLength))
            quoteElement.amount?.let {
              append(it.format(i18nConfiguration, WhitespaceConfig.Spaces).padStart(amountLength))
            } ?: append(" ".repeat(amountLength))
          }

          //Price for all
          val sum = quoteElement.sums(sumQuery).net
          if (quoteElement.isOptional()) {
            append(("[${sum.dump(i18nConfiguration)}]").padStart(totalLength))
          } else {
            append((sum.dump(i18nConfiguration)).padStart(totalLength))
          }
          appendLine()

          //render the details (if there are any)
          quoteElement.details?.let { details ->
            val lines = details.split("\n").flatMap {
              it.wrap(headlineLength)
            }

            lines.fastForEach {
              append(" ".repeat(indexLength + 1))
              appendLine(it)
            }

            appendLine()
          }

          if (quoteElement is QuoteSection) {
            quoteElement.children.renderChildren(indexFormatted)
          }
        }
      }

      sections.renderChildren(null)

      val subTotals = subTotalsForVATs(sumQuery)
      val discounts = discountsForVATs(sumQuery)
      val netPrices = netPricesForVats(sumQuery)
      val vatPrices = vatPrices(sumQuery)
      val grossPrices = grossPricesForVats(sumQuery)

      append("-".repeat(lineLength))
      appendLine()
      append(" ".repeat(indexLength + 1))
      append(" ".repeat(priceLength + amountLength))
      append("Rechnungssumme".padEnd(headlineLength))
      append(subTotals.net.dump(i18nConfiguration).padStart(priceLength))
      appendLine()

      append(" ".repeat(indexLength + 1))
      append(" ".repeat(priceLength + amountLength))
      append("Rabatt (${percentageFormat.format(discountPercentage, i18nConfiguration, WhitespaceConfig.Spaces)})".padEnd(headlineLength))
      append(discounts.total.format(i18nConfiguration, WhitespaceConfig.Spaces).padStart(priceLength))
      appendLine()

      append(" ".repeat(indexLength + 1))
      append(" ".repeat(priceLength + amountLength))
      append("Nettosumme".padEnd(headlineLength))
      append(netPrices.total.format(i18nConfiguration, WhitespaceConfig.Spaces).padStart(priceLength))
      appendLine()

      vatPrices.forEach {
        append(" ".repeat(indexLength + 1))
        append(" ".repeat(priceLength + amountLength))
        append(it.first.format(i18nConfiguration, WhitespaceConfig.Spaces).padEnd(headlineLength))
        append(it.second.format(i18nConfiguration, WhitespaceConfig.Spaces).padStart(priceLength))
        appendLine()
      }

      append("-".repeat(lineLength))
      appendLine()
      append(" ".repeat(indexLength + 1))
      append(" ".repeat(priceLength + amountLength))
      append("Gesamtsumme".padEnd(headlineLength))
      append(grossPrices.total.format(i18nConfiguration, WhitespaceConfig.Spaces).padStart(priceLength))

      appendLine()
      append("-".repeat(lineLength))
      appendLine()
    }
  }


  /**
   * Executes the given block if there is a discount
   */
  fun ifDiscounted(action: () -> Unit) {
    contract {
      callsInPlace(action, InvocationKind.AT_MOST_ONCE)
    }

    if (discountPercentage != 0.0) {
      action()
    }
  }

  companion object {
    /**
     * An empty quote without any sections / item
     */
    val empty: QuoteElements = QuoteElements(emptyList(), 0.0)

    operator fun invoke(config: QuoteElementsBuilder.() -> Unit): QuoteElements {
      return QuoteElementsBuilder.invoke(config).build()
    }

    fun builder(config: QuoteElementsBuilder.() -> Unit): QuoteElementsBuilder {
      return QuoteElementsBuilder.invoke(config)
    }
  }
}

private fun String.ensureLength(headlineLength: Int): String {
  if (this.length <= headlineLength) {
    return this.padEnd(headlineLength)
  }

  return this.substr(0, headlineLength - 3) + "..."
}

private fun QuoteElement.isOptional(): Boolean {
  if (this is QuoteItem) {
    return this.optionality == Optionality.Optional
  }

  return false
}

