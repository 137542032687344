@file:UseSerializers(UuidSerializer::class)

package it.neckar.financial.quote

import it.neckar.commons.tags.Tags
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * An element within the quote. Either a [QuoteSection] or [QuoteItem]
 */
@Serializable
sealed interface QuoteElement : HasUuid {
  /**
   * The (bold) headline
   */
  val headline: String

  /**
   * The amount (if there is any)
   */
  val amount: Amount?

  /**
   * If set to [Visibility.Internal], the value is *not* added to the quote for the customer.
   * This value is only used for internal calculations
   *
   * An item is only visible to the customer, if this value is set to [Visibility.Public]
   */
  val visibility: Visibility

  /**
   * If an item is optional
   */
  val optionality: Optionality

  /**
   * The details - might be a long text describing in detail (optional)
   */
  val details: String?

  /**
   * Calculates the sum for the given query
   */
  fun sums(sumQuery: CalculationRelevanceQuery): SumsForVATs

  /**
   * Returns the relevance.
   * Returns null for [QuoteSection]s and the value for [QuoteItem]s
   */
  fun relevanceOrNull(): Tags?
}
