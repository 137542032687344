package components.project.configuration.description

import components.form.descriptionForm
import components.project.QuoteConfigurationTabProps
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.react.common.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser

val EditConfigurationDescriptionFromUrl: FC<Props> = fc("EditConfigurationDescriptionFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Angebotsbeschreibung", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]

    EditConfigurationDescription {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = loadedProject.isProjectEditable()
      }
    }
  }
}

val EditConfigurationDescription: FC<QuoteConfigurationTabProps> = fc("EditConfigurationDescription") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val label = useState(quoteConfiguration.label.orEmpty())
  val description = useState(quoteConfiguration.description.orEmpty())


  useMemo(label, description) {
    val configurationToSave = quoteConfiguration.copy(
      label = label.value.nullIfBlank(),
      description = description.value.nullIfBlank(),
    )

    UiActions.saveQuoteConfiguration(project, configurationToSave)
  }


  div {
    h1("mb-3") {
      +"Beschreibung"
    }

    descriptionForm(
      label = label,
      description = description,
      labelPlaceholder = quoteConfiguration.configurationName,
      editableStatus,
    )
  }
}
