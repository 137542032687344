@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.project.previews

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.lifeCycle.onlyActive
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.project.BelongsToCompanyProfile
import it.neckar.lizergy.model.project.ProjectCollection
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry
import it.neckar.lizergy.model.project.process.state.UuidAndProcessStateEntries
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.processStates.HasDueDate
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Contains a project and some preview values
 *
 * Can be used to load many projects - that can be shown in a table
 */
@Serializable
sealed interface ServerProjectPreview : ProjectPreview, BelongsToCompanyProfile {

  val sellingCompanyInformation: PlannerCompanyInformation

  val maintainerInformation: UserInformation

  override val blueprintPreview: ResolvedBlueprintPreview
  override val configurationPreviews: List<ServerConfigurationPreview>

  val orderSpecialMaterial: UuidAndProcessStateEntries
  val gridAssessment: UuidAndProcessStateEntries
  val assemblyPortfolio: UuidAndProcessStateEntries
  val advanceInvoice: UuidAndProcessStateEntries
  val assemblyRoof: UuidAndProcessStateEntries
  val assemblyBasement: UuidAndProcessStateEntries
  val switchMeterBox: UuidAndProcessStateEntries
  val startupOperations: UuidAndProcessStateEntries
  val finishingUp: UuidAndProcessStateEntries
  val finalAccount: UuidAndProcessStateEntries
  val documentation: UuidAndProcessStateEntries

  val processStateEntries: List<LizergyProcessStateEntry>?

  override val orderSpecialMaterialId: Uuid
    get() = orderSpecialMaterial.processStateFor
  override val gridAssessmentId: Uuid
    get() = gridAssessment.processStateFor
  override val assemblyPortfolioId: Uuid
    get() = assemblyPortfolio.processStateFor
  override val advanceInvoiceId: Uuid
    get() = advanceInvoice.processStateFor
  override val assemblyRoofId: Uuid
    get() = assemblyRoof.processStateFor
  override val assemblyBasementId: Uuid
    get() = assemblyBasement.processStateFor
  override val switchMeterBoxId: Uuid
    get() = switchMeterBox.processStateFor
  override val startupOperationsId: Uuid
    get() = startupOperations.processStateFor
  override val finishingUpId: Uuid
    get() = finishingUp.processStateFor
  override val finalAccountId: Uuid
    get() = finalAccount.processStateFor
  override val documentationId: Uuid
    get() = documentation.processStateFor

  override val sellingCompany: CompanyCode
    get() = sellingCompanyInformation.companyCode

  override val sellingCompanyProfile: CompanyProfile
    get() = sellingCompanyInformation.companyProfile

  override val maintainer: UserLoginName
    get() = maintainerInformation.loginName

  val currentConfigurationPreview: ServerConfigurationPreview?
    get() = configurationPreviews.filter { it.currentProcessStateEntry != null }.maxByOrNull { it.currentProcessStateEntry!! } ?: configurationPreviews.firstOrNull()

  val mostAdvancedProcessStateEntries: List<LizergyProcessStateEntry>?
    get() = listOfNotNull(
      blueprintPreview.processStateEntries,
      currentConfigurationPreview?.allProcessStateEntries,
      orderSpecialMaterial.processStateEntries,
      gridAssessment.processStateEntries,
      assemblyPortfolio.processStateEntries,
      advanceInvoice.processStateEntries,
      assemblyRoof.processStateEntries,
      assemblyBasement.processStateEntries,
      switchMeterBox.processStateEntries,
      startupOperations.processStateEntries,
      finishingUp.processStateEntries,
      finalAccount.processStateEntries,
      documentation.processStateEntries,
    ).mapNotNull { it.current() }.onlyActive()

  val mostAdvancedProcessStateEntry: LizergyProcessStateEntry?
    get() = mostAdvancedProcessStateEntries?.current()

  val displayName: String
    get() = displayName(location)

  val location: LocationInformation
    get() = currentConfigurationPreview?.location ?: blueprintPreview.location

  val editor: UserInformation?
    get() = currentConfigurationPreview?.editorInformation ?: blueprintPreview.editorInformation

  val processStateEntry: LizergyProcessStateEntry?
    get() = processStateEntries?.current()

  val worstProblem: ProblemType?
    get() = currentConfigurationPreview?.worstProblem ?: blueprintPreview.worstProblem

  val formattedProblems: String
    get() = currentConfigurationPreview?.formattedProblems ?: blueprintPreview.formattedProblems


  fun currentConfigurationForComponent(projectQueryComponent: ProjectQueryComponent?): ServerConfigurationPreview? {
    val currentRelevantConfigurationPreview = when (projectQueryComponent) {
      ProjectQueryComponent.CurrentConfiguration -> configurationPreviews.filter { it.currentProcessStateEntry is ConfigurationProcessStateEntry }.maxByOrNull { it.currentProcessStateEntry!! }
      ProjectQueryComponent.CurrentQuote -> configurationPreviews.filter {
        it.currentProcessStateEntry is PresentationProcessStateEntry || it.currentProcessStateEntry is QuoteOfferProcessStateEntry || it.currentProcessStateEntry is QuoteConfirmationProcessStateEntry
      }.maxByOrNull { it.currentProcessStateEntry!! }

      else -> currentConfigurationPreview
    }
    return currentRelevantConfigurationPreview
  }

  fun editorForQueryComponent(projectQueryComponent: ProjectQueryComponent?): UserLoginName? {
    return processStateEntryForComponent(projectQueryComponent)?.assignedTo
  }

  fun processStateEntryForComponent(projectQueryComponent: ProjectQueryComponent?): LizergyProcessStateEntry? {
    return processStateEntriesForComponent(projectQueryComponent)?.current()
  }

  fun processStateEntriesForComponent(projectQueryComponent: ProjectQueryComponent?): List<LizergyProcessStateEntry>? {
    val roofProcessStateEntries = assemblyRoof.processStateEntries ?: emptyList()
    val basementProcessStateEntries = assemblyBasement.processStateEntries ?: emptyList()
    val assemblyPreparationProcessStateEntries = (roofProcessStateEntries.filterIsInstance<AssemblyRoofPreparationProcessStateEntry>() + basementProcessStateEntries.filterIsInstance<AssemblyBasementPreparationProcessStateEntry>()).ifEmpty { null }

    return when (projectQueryComponent) {
      ProjectQueryComponent.Project -> null

      ProjectQueryComponent.Blueprint -> if (mostAdvancedProcessStateEntry == blueprintPreview.processStateEntry) blueprintPreview.processStateEntries else null

      ProjectQueryComponent.CurrentConfiguration, ProjectQueryComponent.CurrentQuote -> currentConfigurationForComponent(projectQueryComponent)?.allProcessStateEntries

      ProjectQueryComponent.OrderSpecialMaterial -> orderSpecialMaterial.processStateEntries
      ProjectQueryComponent.GridAssessment -> gridAssessment.processStateEntries
      ProjectQueryComponent.AssemblyPortfolio -> assemblyPortfolio.processStateEntries
      ProjectQueryComponent.ScheduleAssembly -> assemblyPreparationProcessStateEntries
      ProjectQueryComponent.AdvanceInvoice -> advanceInvoice.processStateEntries
      ProjectQueryComponent.PrepareMaterial -> assemblyPreparationProcessStateEntries
      ProjectQueryComponent.AssemblyRoof -> assemblyRoof.processStateEntries
      ProjectQueryComponent.AssemblyBasement -> assemblyBasement.processStateEntries
      ProjectQueryComponent.SwitchMeterBox -> switchMeterBox.processStateEntries
      ProjectQueryComponent.StartupOperations -> startupOperations.processStateEntries
      ProjectQueryComponent.FinishingUp -> finishingUp.processStateEntries
      ProjectQueryComponent.FinalAccount -> finalAccount.processStateEntries
      ProjectQueryComponent.Documentation -> documentation.processStateEntries
      ProjectQueryComponent.Verification -> ((documentation.processStateEntries ?: emptyList()) + (processStateEntries ?: emptyList())).ifEmpty { null }

      null -> mostAdvancedProcessStateEntries
    }?.onlyActive()
  }

  fun belongsToUser(userLoginName: UserLoginName): Boolean {
    return belongsToMaintainer(userLoginName) || belongsToEditor(userLoginName)
  }

  fun belongsToEditor(userLoginName: UserLoginName): Boolean {
    return mostAdvancedProcessStateEntry?.assignedTo == userLoginName
  }

  fun matchesFilterStringAddress(filterString: String): Boolean {
    return filterString.splitToSequence(' ')
      .map { it.trim().trim(',', '.') }
      .filter { it.isNotBlank() }
      .all { word ->
        val customer = customer
        customer.address.streetWithHouseNumber.contains(word, true) ||
            customer.address.cityWithZipCode.contains(word, true) ||
            location.address.streetWithHouseNumber.contains(word, true) ||
            location.address.cityWithZipCode.contains(word, true)
      }
  }

}


fun <T : ServerProjectPreview> ProjectCollection<T>.forProjectQueryComponent(
  processStatesToFilter: List<LizergyProcessStates>,
  projectQueryComponent: ProjectQueryComponent?,
  includeArchived: Boolean = false,
  additionalFilterRequirement: ((projectProcessStateEntry: LizergyProcessStateEntry?, dataProcessStateEntry: LizergyProcessStateEntry?) -> Boolean)? = null,
): ProjectCollection<T> {
  return filter { projectPreview ->
    val projectProcessStateEntry = projectPreview.processStateEntry
    val dataProcessStateEntry = projectPreview.processStateEntryForComponent(projectQueryComponent)
    (includeArchived || (projectProcessStateEntry?.processState != ProjectProcessStateEntry.ProjectProcessStates.Archived && projectProcessStateEntry?.processState != ProjectProcessStateEntry.ProjectProcessStates.Paused)) &&
        (processStatesToFilter.contains(projectProcessStateEntry?.processState) || processStatesToFilter.contains(dataProcessStateEntry?.processState)) &&
        (additionalFilterRequirement?.invoke(projectProcessStateEntry, dataProcessStateEntry) ?: true) &&
        (projectQueryComponent != ProjectQueryComponent.ScheduleAssembly || (dataProcessStateEntry is HasDueDate && dataProcessStateEntry.dueDate == null))
  }
}

fun <T : ServerProjectPreview> ProjectCollection<T>.forProjectQueryUser(
  forUser: UserLoginName?,
  projectQueryComponent: ProjectQueryComponent?,
  projectQueryForUser: ProjectQueryForUser,
): ProjectCollection<T> {
  return filter { projectPreview ->
    val dataProcessStateEntry = projectPreview.processStateEntryForComponent(projectQueryComponent)

    when (projectQueryForUser) {
      ProjectQueryForUser.AssignedEditor -> dataProcessStateEntry?.assignedTo == forUser
      ProjectQueryForUser.AssignedMaintainer -> projectPreview.belongsToMaintainer(forUser)
    }
  }
}
