package it.neckar.lizergy.model.configuration.moduleLayout

import it.neckar.lizergy.model.configuration.energy.Current
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.Voltage
import it.neckar.lizergy.model.configuration.energy.sum
import it.neckar.lizergy.model.configuration.moduleLayout.ModulesReport.ModuleCount
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule.PvModuleId
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModulesReport.ResolvedModuleCount
import it.neckar.lizergy.model.price.ProductResolver
import kotlinx.serialization.Serializable

interface ModulesReport {
  val countByModuleIds: List<ModuleCount>

  /**
   * Returns true if the report is empty (has no entries at all)
   */
  val isEmpty: Boolean
    get() = countByModuleIds.isEmpty()

  val isNotEmpty: Boolean
    get() = isEmpty.not()

  val totalModulesCount: Int
    get() = countByModuleIds.sumBy { it.count }

  val totalDifferentModulesCount: Int
    get() = countByModuleIds.size

  val moduleIds: List<PvModuleId>
    get() = countByModuleIds.map { it.moduleId }

  @Serializable
  data class ModuleCount(
    val moduleId: PvModuleId,
    val count: Int,
  ) {
    fun resolve(productResolver: ProductResolver): ResolvedModuleCount {
      return ResolvedModuleCount(
        moduleType = productResolver[moduleId],
        count = count,
      )
    }
  }
}


/**
 * Contains the total number of different modules
 */
@Serializable
data class ResolvedModulesReport(
  val countByModules: List<ResolvedModuleCount>,
) : ModulesReport {

  override val countByModuleIds: List<ModuleCount>
    get() = countByModules.map { moduleCount -> moduleCount.unResolve() }

  val moduleTypes: List<PvModule>
    get() = countByModules.map { it.moduleType }

  val totalPowerRating: PowerRating
    get() = countByModules.map { it.moduleType.powerRating * it.count }.sum()

  val totalOffLoadVoltage: Voltage
    get() = countByModules.map { it.moduleType.offLoadVoltage * it.count }.sum()

  val totalShortCircuitCurrent: Current
    get() = countByModules.map { it.moduleType.shortCircuitCurrent * it.count }.sum()

  @Serializable
  data class ResolvedModuleCount(
    val moduleType: PvModule,
    val count: Int,
  ) {
    fun unResolve(): ModuleCount {
      return ModuleCount(moduleType.id, count)
    }

    fun format(): String {
      return buildString { append("$count ${moduleType.description}") }
    }
  }

  companion object {
    /**
     * Creates a modules report for the given layouts
     */
    fun create(moduleLayouts: List<ResolvedModuleLayout>): ResolvedModulesReport {
      val groupedByModuleType = moduleLayouts.groupBy { moduleLayout ->
        moduleLayout.moduleType
      }

      val countByModules = groupedByModuleType.map { entry ->
        ResolvedModuleCount(
          moduleType = entry.key,
          count = entry.value.sumOf { moduleLayout -> moduleLayout.modulesCount },
        )
      }

      return ResolvedModulesReport(countByModules)
    }
  }
}
