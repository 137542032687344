package components.company

import components.restrictedUI
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherCompanies
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOwnCompany
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.FontAwesome.faAdd
import it.neckar.react.common.button.*
import it.neckar.react.common.router.*
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver
import store.hooks.useSelectUsersAndCompanies

val CompaniesAndUsersOverviewFromUrl: FC<Props> = fc("CompaniesAndUsersOverviewFromUrl") {
  val userAndCompanies = useSelectUsersAndCompanies()
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val companyResolver = useSelectCompanyResolver()
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Nutzer & Firmen")

  CompaniesAndUsersOverview {
    attrs {
      this.companies = userAndCompanies.activeCompanies(loggedInUser)
    }
  }
}

/**
 * Shows companies and users overview
 */
val CompaniesAndUsersOverview: FC<CompaniesAndUsersOverviewProps> = fc("CompaniesAndUsersOverview") { props ->
  val companies = props::companies.safeGet()

  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()
  val usersAndCompanies = useSelectUsersAndCompanies()

  div("my-3") {
    h1 {
      +"Firmenübersicht"
    }
    button(classes = "btn btn-primary", type = ButtonType.button) {
      attrs {
        restrictedUI(loggedInUser) { it.accessRights.canAccess(EditOtherCompanies) }
        onClickFunction = { navigate(RouterUrls.admin.companies().newCompany) }
      }

      span("pe-2") {
        faAdd()
      }
      +"Neue Firma"
    }
  }

  div("my-3") {
    companies.fastForEach { company ->
      val usersForCompanyCode: List<UserInformation> = usersAndCompanies.usersForCompany(company.companyProfile)

      div("mb-5") {
        div {
          div("d-flex justify-content-between align-items-center") {
            h2 {
              +company.name

              span("ps-1") {
                attrs {
                  restrictedUI(loggedInUser) { it.accessRights.canAccess(EditOtherCompanies) || (it.accessRights.canAccess(EditOwnCompany) && it.company == company.companyProfile) }
                }

                editButton {
                  navigate(RouterUrls.admin.companies().company(company).index)
                }
              }
            }
          }
        }

        div("mt-0") {
          UsersOverview {
            attrs {
              this.companyCode = company.companyCode
              this.usersForCompanyCode = usersForCompanyCode
            }
          }
        }

      }

    }
  }

}

external interface CompaniesAndUsersOverviewProps : Props {
  var companies: List<PlannerCompanyInformation>
}
