@file:UseSerializers(UuidSerializer::class)

package serialized

import com.benasher44.uuid.Uuid
import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.editHistory.PositionEdit
import it.neckar.editHistory.PositionEditHistory
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration.BatteryConfigurationId
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.project.previews.AccountingProjectPreview
import it.neckar.lizergy.model.project.previews.ConfigurationPreview
import it.neckar.lizergy.model.project.previews.PreviewQuoteElements
import it.neckar.lizergy.model.project.previews.ProjectPreview
import it.neckar.lizergy.model.project.previews.ResolvedConfigurationPreview
import it.neckar.lizergy.model.project.previews.ResolvedProjectPreview
import it.neckar.lizergy.model.project.previews.SerializedBlueprintPreview
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.UuidAndProcessStateEntries
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.stumps.RestArbeiten
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.open.collections.fastForEach
import it.neckar.open.kotlin.lang.ifBlank
import it.neckar.processStates.ProcessStatesResolver
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Contains a project and some preview values
 *
 * Can be used to load many projects - that can be shown in a table
 */
@Serializable
data class SerializedProjectPreview(

  override val projectId: PhotovoltaicsProjectId,

  override val projectName: String,

  override val sellingCompany: CompanyCode,

  override val customer: Customer,

  override val maintainer: UserLoginName,

  override val acquisitionDate: Double,

  override val blueprintPreview: SerializedBlueprintPreview,
  override val configurationPreviews: List<SerializedConfigurationPreview>,

  override val orderSpecialMaterialId: Uuid,
  override val gridAssessmentId: Uuid,
  override val assemblyPortfolioId: Uuid,
  override val advanceInvoiceId: Uuid,
  override val assemblyRoofId: Uuid,
  override val assemblyBasementId: Uuid,
  override val switchMeterBoxId: Uuid,
  override val startupOperationsId: Uuid,
  override val finishingUpId: Uuid,
  override val finalAccountId: Uuid,
  override val documentationId: Uuid,

  override val verification: Verification?,

  override val restArbeiten: RestArbeiten,

  override val belongsToCompanies: Set<CompanyCode>,

  override val archiveReasons: ArchiveReasons,

  ) : ProjectPreview {

  fun getDisplayName(processStatesResolver: ProcessStatesResolver): String {
    return displayName(getLocation(processStatesResolver))
  }

  fun getLocation(processStatesResolver: ProcessStatesResolver): LocationInformation {
    return getCurrentConfigurationPreview(processStatesResolver)?.location ?: blueprintPreview.location
  }

  @Deprecated("Replaced by new Process State Service")
  fun getEditor(processStatesResolver: ProcessStatesResolver): UserLoginName? {
    return getCurrentConfigurationPreview(processStatesResolver)?.editor ?: blueprintPreview.editor
  }

  fun belongsToUser(userLoginName: UserLoginName, processStatesResolver: ProcessStatesResolver): Boolean {
    return belongsToMaintainer(userLoginName) || belongsToEditor(userLoginName, processStatesResolver)
  }

  @Deprecated("Replaced by new Process State Service")
  fun belongsToEditor(userLoginName: UserLoginName, processStatesResolver: ProcessStatesResolver): Boolean {
    return userLoginName == getEditor(processStatesResolver)
  }

  fun getCurrentConfigurationPreview(processStatesResolver: ProcessStatesResolver): ConfigurationPreview? {
    var currentQuoteConfiguration: ConfigurationPreview? = null

    var currentProcessState: LizergyProcessStateEntry? = null
    configurationPreviews.fastForEach { configurationPreview ->
      val processStateForConfiguration = ((processStatesResolver.getOrNull(configurationPreview.configurationId.uuid)?.validProcessStateEntries) as List<LizergyProcessStateEntry>?)?.current()
      currentProcessState.let {
        if (processStateForConfiguration != null && (it == null || processStateForConfiguration > it)) {
          currentQuoteConfiguration = configurationPreview
          currentProcessState = processStateForConfiguration
        }
      }
    }

    if (currentQuoteConfiguration == null) {
      var currentOLDProcessState: OLDProcessState? = null
      configurationPreviews.fastForEach { configurationPreview ->
        val OLDprocessStateForConfiguration = configurationPreview.processStateEdit?.value
        currentOLDProcessState.let {
          if (OLDprocessStateForConfiguration != null && (it == null || OLDprocessStateForConfiguration > it)) {
            currentQuoteConfiguration = configurationPreview
            currentOLDProcessState = OLDprocessStateForConfiguration
          }
        }
      }
    }

    return currentQuoteConfiguration
  }

  fun resolve(productResolver: ProductResolver, processStatesResolver: ProcessStatesResolver, userResolver: UserResolver, companyResolver: CompanyResolver): ResolvedProjectPreview {
    val orderSpecialMaterial = UuidAndProcessStateEntries(
      processStateFor = orderSpecialMaterialId,
      processStateEntries = processStatesResolver.getOrNull(orderSpecialMaterialId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val gridAssessment = UuidAndProcessStateEntries(
      processStateFor = gridAssessmentId,
      processStateEntries = processStatesResolver.getOrNull(gridAssessmentId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val assemblyPortfolio = UuidAndProcessStateEntries(
      processStateFor = assemblyPortfolioId,
      processStateEntries = processStatesResolver.getOrNull(assemblyPortfolioId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val advanceInvoice = UuidAndProcessStateEntries(
      processStateFor = advanceInvoiceId,
      processStateEntries = processStatesResolver.getOrNull(advanceInvoiceId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val assemblyRoof = UuidAndProcessStateEntries(
      processStateFor = assemblyRoofId,
      processStateEntries = processStatesResolver.getOrNull(assemblyRoofId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val assemblyBasement = UuidAndProcessStateEntries(
      processStateFor = assemblyBasementId,
      processStateEntries = processStatesResolver.getOrNull(assemblyBasementId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val switchMeterBox = UuidAndProcessStateEntries(
      processStateFor = switchMeterBoxId,
      processStateEntries = processStatesResolver.getOrNull(switchMeterBoxId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val startupOperations = UuidAndProcessStateEntries(
      processStateFor = startupOperationsId,
      processStateEntries = processStatesResolver.getOrNull(startupOperationsId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val finishingUp = UuidAndProcessStateEntries(
      processStateFor = finishingUpId,
      processStateEntries = processStatesResolver.getOrNull(finishingUpId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val finalAccount = UuidAndProcessStateEntries(
      processStateFor = finalAccountId,
      processStateEntries = processStatesResolver.getOrNull(finalAccountId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
    val documentation = UuidAndProcessStateEntries(
      processStateFor = documentationId,
      processStateEntries = processStatesResolver.getOrNull(documentationId)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )

    return ResolvedProjectPreview(
      projectId = projectId,
      projectName = projectName,
      sellingCompanyInformation = companyResolver[sellingCompany],
      customer = customer,
      maintainerInformation = userResolver[maintainer],
      acquisitionDate = acquisitionDate,
      blueprintPreview = blueprintPreview.resolve(processStatesResolver, userResolver),
      configurationPreviews = configurationPreviews.map { it.resolve(productResolver = productResolver, processStatesResolver = processStatesResolver, userResolver = userResolver, companyResolver = companyResolver) },
      orderSpecialMaterial = orderSpecialMaterial,
      gridAssessment = gridAssessment,
      assemblyPortfolio = assemblyPortfolio,
      advanceInvoice = advanceInvoice,
      assemblyRoof = assemblyRoof,
      assemblyBasement = assemblyBasement,
      switchMeterBox = switchMeterBox,
      startupOperations = startupOperations,
      finishingUp = finishingUp,
      finalAccount = finalAccount,
      documentation = documentation,
      verification = verification,
      restArbeiten = restArbeiten,
      belongsToCompanies = belongsToCompanies,
      archiveReasons = archiveReasons,
      processStateEntries = processStatesResolver.getOrNull(projectId.uuid)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
    )
  }

}

@Serializable
data class SerializedConfigurationPreview(
  override val configurationId: PhotovoltaicsConfigurationId,
  val label: String?,
  override val sellingCompany: CompanyCode,
  override val location: LocationInformation,
  @Deprecated("Replaced by new Process State Service")
  override val editor: UserLoginName?,
  override val modulesReport: SerializedModulesReport,
  override val inverterSelection: InverterIdSelection,
  override val batteryConfigurationId: BatteryConfigurationId?,
  override val eigenmontage: Boolean,
  override val earningsDistribution: SerializedEarningsDistribution,
  override val quoteElements: PreviewQuoteElements?,
  override val manualQuoteElements: ManualQuoteElements,
  override val worstProblem: ProblemType? = null,
  override val formattedProblems: String = "",
  @Deprecated("Replaced by new Process State Service")
  val processStateEditHistory: PositionEditHistory<OLDProcessState>? = null,
) : ConfigurationPreview {

  @Deprecated("Replaced by new Process State Service")
  val processStateEdit: PositionEdit<OLDProcessState>?
    get() = processStateEditHistory?.currentEdit

  fun resolve(productResolver: ProductResolver, processStatesResolver: ProcessStatesResolver, userResolver: UserResolver, companyResolver: CompanyResolver): ResolvedConfigurationPreview {
    val resolvedModulesReport = modulesReport.resolve(productResolver)
    val batteryConfiguration = batteryConfigurationId?.let { productResolver[it] }
    return ResolvedConfigurationPreview(
      configurationId = configurationId,
      configurationName = label.ifBlank {
        buildString {
          append("PV-Anlage mit ${resolvedModulesReport.totalPowerRating.formatKiloWattPeak()}")
          batteryConfiguration?.let {
            append(", Batterie ${it.totalStorage.format()}")
          }
        }
      },
      sellingCompany = sellingCompany,
      location = location,
      editorInformation = editor?.let { userResolver[it] },
      modulesReport = resolvedModulesReport,
      inverterSelection = inverterSelection.resolve(productResolver),
      batteryConfiguration = batteryConfiguration,
      eigenmontage = eigenmontage,
      earningsDistribution = earningsDistribution.resolve(companyResolver),
      quoteElements = quoteElements,
      manualQuoteElements = manualQuoteElements,
      worstProblem = worstProblem,
      formattedProblems = formattedProblems,
      processStateEntries = processStatesResolver.getOrNull(configurationId.uuid)?.validProcessStateEntries as List<LizergyProcessStateEntry>?,
      processStateEditHistory = processStateEditHistory,
      userResolver = userResolver,
    )
  }

  override fun getCalculatedQuoteElements(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): PreviewQuoteElements? {
    TODO("Not yet implemented")
  }
}

fun SerializedBlueprint.toBlueprintPreview(): SerializedBlueprintPreview {
  return SerializedBlueprintPreview(blueprintId, location, editor, worstProblem, formattedProblems, processState)
}

fun SerializedPhotovoltaicsConfiguration.toConfigurationPreview(modulesReport: SerializedModulesReport, quoteElements: PreviewQuoteElements?): SerializedConfigurationPreview {
  return SerializedConfigurationPreview(
    configurationId = configurationId,
    label = label,
    sellingCompany = sellingCompany,
    location = location,
    editor = editor,
    modulesReport = modulesReport,
    inverterSelection = facilityConfiguration.inverterSelection,
    batteryConfigurationId = facilityConfiguration.batteryConfigurationId,
    eigenmontage = assemblyConfiguration.assemblyStatus.isBeingSelfAssembled,
    earningsDistribution = earningsDistribution,
    quoteElements = quoteElements,
    manualQuoteElements = manualQuoteElements,
    worstProblem = worstProblem,
    formattedProblems = formattedProblems,
    processStateEditHistory = processState,
  )
}
