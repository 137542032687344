@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout.roof

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.ModuleIndex
import it.neckar.lizergy.model.configuration.moduleLayout.planning.ModuleAreaInformation.ModuleAreaId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class ModulesString(
  val id: StringId,
  val roofStrings: List<RoofString>,
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  val numberOfModules: Int
    get() = roofStrings.sumOf { it.numberOfModules }

  fun forTheseRoofs(roofs: List<RoofId>): ModulesString {
    return copy(roofStrings = roofStrings.filter { roofs.contains(it.roofId) })
  }


  @Serializable
  data class RoofString(
    val id: StringId,
    val roofId: RoofId,
    val stringOfModules: List<Module>,
  ) : HasUuid {
    override val uuid: Uuid
      get() = id.uuid

    val numberOfModules: Int
      get() = stringOfModules.size
  }

  @Serializable
  data class Module(
    val moduleAreaId: ModuleAreaId,
    val moduleIndex: ModuleIndex,
  )


  @Serializable
  data class StringId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): StringId {
        return StringId(randomUuid4())
      }
    }
  }
}
