package it.neckar.financial.quote

/**
 * Whether an item is visible to the enduser or only used for internal calculations
 */
enum class Visibility {
  /**
   * Is visible to the customer
   */
  Public,

  /**
   * Only visible internally - used for calculations.
   * Aggregated in (one of) the parent(s)
   */
  Internal,
}
