@file:Suppress("DuplicatedCode")

package components.project.configuration.facility

import components.form.elementsSelectionForm
import i18n.EnumTranslator
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.lizergy.model.price.InfoForType
import it.neckar.open.collections.fastForEach
import it.neckar.open.kotlin.lang.enumEntries
import it.neckar.open.unit.si.m
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faEdit
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import store.hooks.useSelectAvailableProducts

/**
 * Form for facility related stuff
 */
fun RBuilder.facilityForm(
  availableInverters: List<Inverter>,
  numberOfOptimizers: StateInstance<Int>,
  kabelwegZugschlagLength: StateInstance<@m Double>,
  numberUeberspannungsSchutz: StateInstance<Int?>,
  inverterSelectionState: StateInstance<ResolvedInverterSelection>,
  inverterTypesPrices: InfoForType<Inverter, PriceWithProfit>,
  selectedIndependenceManager: IndependenceManagerType?,
  resolvedModuleLayouts: ResolvedModuleLayouts?,
  totalPowerRating: PowerRating?,
  editableStatus: EditableStatus,
): Unit = child(FacilityForm) {
  attrs {
    this.availableInverters = availableInverters

    this.numberOfOptimizers = numberOfOptimizers
    this.kabelwegZugschlagLength = kabelwegZugschlagLength
    this.numberUeberspannungsSchutz = numberUeberspannungsSchutz

    this.inverterSelectionState = inverterSelectionState
    this.inverterTypesPrices = inverterTypesPrices

    this.selectedIndependenceManager = selectedIndependenceManager
    this.resolvedModuleLayouts = resolvedModuleLayouts
    this.totalPowerRating = totalPowerRating
    this.editableStatus = editableStatus
  }
}

val FacilityForm: FC<FacilityFormProps> = fc("FacilityForm") { props ->
  val availableInverterTypes = props::availableInverters.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val numberOfOptimizers = props::numberOfOptimizers.getNotNull()
  val kabelwegZugschlagLength = props::kabelwegZugschlagLength.getNotNull()
  val numberUeberspannungsSchutz = props::numberUeberspannungsSchutz.getNotNull()
  val inverterSelectionState = props::inverterSelectionState.getNotNull()

  val inverterTypesPrices = props::inverterTypesPrices.safeGet()

  val selectedIndependenceManager = props::selectedIndependenceManager.safeGet()
  val resolvedModuleLayouts = props::resolvedModuleLayouts.safeGet()
  val totalPowerRating = props::totalPowerRating.safeGet()


  floatingIntInputField(
    valueAndSetter = numberOfOptimizers,
    fieldName = "numberOfOptimizers",
    title = "Anzahl Optimierer",
    numberConstraint = ZeroOrPositive,
    editableStatus = editableStatus,
  ) {
    attrs {
      mergedBelow()
    }
  }

  floatingDoubleInputField(
    valueAndSetter = kabelwegZugschlagLength,
    fieldName = "kabelwegZugschlagLength",
    title = "Zuschlag für Kabelweg (m)",
    numberConstraint = ZeroOrPositive,
    editableStatus = editableStatus,
  ) {
    attrs {
      mergedBelow()
      mergedAbove()
    }
  }

  nullableFloatingIntInputField(
    valueAndSetter = numberUeberspannungsSchutz,
    fieldName = "numberUeberspannungsSchutz",
    title = "Anzahl Überspannungsschutz",
    numberConstraint = ZeroOrPositive,
    editableStatus = editableStatus,
  ) {
    attrs {
      mergedAbove()
    }
  }

  div("mt-2") {
    attrs {
      onKeyPress = { keyboardEvent ->
        keyboardEvent.stopPropagation()
      }
    }

    div(classes = "d-flex flex-row") {
      floatingReadOnlyInputField(
        value = inverterSelectionState.value.format(),
        fieldName = "inverterSelection",
        title = "Wechselrichter",
        editableStatus = editableStatus,
        divConfig = {
          attrs {
            addClass("flex-grow-1")
          }
        }
      )

      val idOfModal = "selectInverterModal"
      modalButton(idOfModal, classes = "btn btn-link") {
        faEdit()

        attrs {
          disabled = editableStatus == ReadOnly
        }
      }

      modal(
        id = idOfModal,
        title = "Wechselrichter",
        size = ModalSize.Large,
      ) {

        elementsSelectionForm(
          selectedValue = inverterSelectionState.value,
          onChange = useCallback(inverterSelectionState) { option, amount ->
            val updatedInverterSelection = inverterSelectionState.value.with(option, amount)
            inverterSelectionState.setter(updatedInverterSelection)
          },
          availableOptions = availableInverterTypes,
          formatter = useCallback(inverterTypesPrices) { inverterType ->
            "${inverterType.format()} [${inverterTypesPrices.value(inverterType).sellingPrice.format()}]"
          },
        )

        resolvedModuleLayouts?.let {
          hr {}

          h4 {
            +"Modulflächen"
          }

          div("row") {
            it.validElements.fastForEach { moduleLayout ->
              div("mt-2") {
                div("col-6") {
                  +"${moduleLayout.layoutName}:"
                }
                div("col-6") {
                  span("form-text") {
                    +"${moduleLayout.modulesCount} ${moduleLayout.moduleType.description} Module"
                  }
                }
              }
            }
          }

        }

      }

    }

    inverterSelectionState.value.entriesNonEmpty.fastForEach {
      if (it.element.lifeCycleState.isNearEndOfLive()) {
        p("text-warning") {
          +"Achtung Auslaufmodell. Für Anlagen, die 2023 geplant sind, bitte die Sunny Tripower X Serie auswählen."
        }
      }
    }
    if (selectedIndependenceManager?.invalidInverterSelected(inverterSelectionState.value) == true) {
      p("text-danger") {
        +"Achtung es wurde der Lizergy Independence Manager ausgewählt. Bitte nur oder zum Teil einphasige SB Wechselrichter einplanen!"
      }
    }

  }

  totalPowerRating?.let {
    p("form-text") {
      +"Anlagenleistung: ${it.formatKiloWattPeak()}"
    }
  }

}


fun RBuilder.miscFacilityForm(
  heaterRodToSave: StateInstance<HeaterRod?>,
  waermepumpenanbindungToSave: StateInstance<Boolean>,
  onlineMonitoringToSave: StateInstance<ConfiguredOptionality>,
  integrateInverterIntoNetworkToSave: StateInstance<ConfiguredOptionality>,
  erdspiessSetzenToSave: StateInstance<Boolean>,
  editableStatus: EditableStatus,
): Unit = child(MiscFacilityForm) {
  attrs {
    this.heaterRodToSave = heaterRodToSave
    this.waermepumpenanbindungToSave = waermepumpenanbindungToSave
    this.onlineMonitoringToSave = onlineMonitoringToSave
    this.integrateInverterIntoNetworkToSave = integrateInverterIntoNetworkToSave
    this.erdspiessSetzenToSave = erdspiessSetzenToSave
    this.editableStatus = editableStatus
  }
}

val MiscFacilityForm: FC<MiscFacilityFormProps> = fc("MiscFacilityForm") { props ->
  val availableProducts = useSelectAvailableProducts()

  val heizstabToSave = props::heaterRodToSave.getNotNull()
  val waermepumpenanbindungToSave = props::waermepumpenanbindungToSave.getNotNull()
  val onlineMonitoringToSave = props::onlineMonitoringToSave.getNotNull()
  val integrateInverterIntoNetworkToSave = props::integrateInverterIntoNetworkToSave.getNotNull()
  val erdspiessSetzenToSave = props::erdspiessSetzenToSave.getNotNull()

  val editableStatus = props::editableStatus.safeGet()

  div("my-5") {
    h2("mb-2") {
      +"Heizung"
    }
    div("my-3") {
      floatingSelectNullable(
        valueAndSetter = heizstabToSave,
        fieldName = "heizstab",
        idProvider = { it?.id?.format() ?: "NONE" },
        formatter = { it?.format(plannerI18nConfiguration) ?: "-" },
        availableOptionsWithoutNull = availableProducts.availableHeaterRods(),
        title = "Heizstab",
        editableStatus = editableStatus,
      )
    }
    checkbox(
      valueAndSetter = waermepumpenanbindungToSave,
      fieldName = "waermepumpenanbindung",
      title = "Wärmepumpenanbindung",
      editableStatus = editableStatus,
    )
  }

  div("col-sm-6 my-5") {
    h2("mb-2") {
      +"Sonstiges"
    }

    div("my-3") {
      floatingSelectEnum(
        valueAndSetter = onlineMonitoringToSave,
        fieldName = "onlineMonitoring",
        title = "Online-Überwachung PV-Anlage",
        formatter = EnumTranslator.ConfiguredOptionality,
        availableOptions = enumEntries(),
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
        }
      }

      floatingSelectEnum(
        valueAndSetter = integrateInverterIntoNetworkToSave,
        fieldName = "integrateInverterIntoNetwork",
        title = "Inverter in (W)LAN integrieren",
        formatter = EnumTranslator.ConfiguredOptionality,
        availableOptions = enumEntries(),
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
        }
      }
    }

    checkbox(
      valueAndSetter = erdspiessSetzenToSave,
      fieldName = "erdspiessSetzen",
      title = "Erdspieß setzen",
      editableStatus = editableStatus,
    )
  }

}


external interface FacilityFormProps : Props {
  var availableInverters: List<Inverter>

  var numberOfOptimizers: StateInstance<Int>
  var kabelwegZugschlagLength: StateInstance<@m Double>
  var numberUeberspannungsSchutz: StateInstance<Int?>

  var inverterSelectionState: StateInstance<ResolvedInverterSelection>
  var inverterTypesPrices: InfoForType<Inverter, PriceWithProfit>

  var selectedIndependenceManager: IndependenceManagerType?
  var resolvedModuleLayouts: ResolvedModuleLayouts?
  var totalPowerRating: PowerRating?
  var editableStatus: EditableStatus
}

external interface MiscFacilityFormProps : Props {
  var heaterRodToSave: StateInstance<HeaterRod?>
  var waermepumpenanbindungToSave: StateInstance<Boolean>
  var onlineMonitoringToSave: StateInstance<ConfiguredOptionality>
  var integrateInverterIntoNetworkToSave: StateInstance<ConfiguredOptionality>
  var erdspiessSetzenToSave: StateInstance<Boolean>
  var editableStatus: EditableStatus
}
