package components.project.configuration.additional

import components.project.configuration.configurationItemForm
import components.project.formButtons
import components.project.freezableFormButtons
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.financial.currency.euro
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.useAdditionalPositionIdFromUrl
import router.useConfigurationIdFromUrl
import router.useLoadResolvedProjectFromUrl
import services.UiActions

val EditBlueprintAdditionalPositionFromUrl: FC<Props> = fc("EditBlueprintAdditionalPositionFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val additionalPositionId = useAdditionalPositionIdFromUrl()

  busyIfNull(project) {
    val additionalPosition = it.blueprint.additionalPositions[additionalPositionId]

    EditBlueprintAdditionalPosition {
      attrs {
        this.project = it
        this.additionalPosition = additionalPosition
      }
    }
  }
}

val EditConfigurationAdditionalPositionFromUrl: FC<Props> = fc("EditConfigurationAdditionalPositionFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val additionalPositionId = useAdditionalPositionIdFromUrl()

  busyIfNull(project) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val additionalPosition = quoteConfiguration.additionalPositions[additionalPositionId]

    EditConfigurationAdditionalPosition {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.additionalPosition = additionalPosition
        this.editableStatus = loadedProject.isProjectEditable()
      }
    }
  }
}

val EditBlueprintAdditionalPosition: FC<EditBlueprintAdditionalPositionProps> = fc("EditBlueprintAdditionalPosition") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val blueprint = project::blueprint.safeGet()
  val additionalPosition = props::additionalPosition.safeGet()

  val editableStatus = project.isProjectEditable()

  val additionalPositionToSave = useState(additionalPosition)

  val blueprintToSave = useMemo(blueprint, additionalPositionToSave.value) {
    blueprint.copy(
      additionalPositions = blueprint.additionalPositions.withReplaced(additionalPositionToSave.value),
    )
  }

  val okAction = {
    UiActions.saveBlueprint(blueprintToSave)
    navigate.back()
  }


  div {
    onEnter(okAction)

    EditAdditionalPosition {
      attrs {
        this.additionalPositionToSave = additionalPositionToSave
        this.editableStatus = editableStatus
      }
    }
  }

  formButtons(okAction)
}

val EditConfigurationAdditionalPosition: FC<EditConfigurationAdditionalPositionProps> = fc("EditConfigurationAdditionalPosition") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val additionalPosition = props::additionalPosition.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val additionalPositionToSave = useState(additionalPosition)

  val configurationToSave = useMemo(quoteConfiguration, additionalPositionToSave.value) {
    quoteConfiguration.copy(
      additionalPositions = quoteConfiguration.additionalPositions.withReplaced(additionalPositionToSave.value),
    )
  }

  val okAction = {
    UiActions.saveQuoteConfiguration(project, configurationToSave)
    navigate.back()
  }


  div {
    if (editableStatus == Editable) {
      onEnter(okAction)
    }

    EditAdditionalPosition {
      attrs {
        this.additionalPositionToSave = additionalPositionToSave
        this.editableStatus = editableStatus
      }
    }
  }

  freezableFormButtons(editableStatus, okAction)
}


val EditAdditionalPosition: FC<EditAdditionalPositionProps> = fc("EditAdditionalPosition") { props ->
  val additionalPositionProps = props::additionalPositionToSave.getNotNull()
  val editableStatus = props::editableStatus.safeGet()

  val additionalPositionToSave = additionalPositionProps.value

  val additionalPositionHeadline = useState(additionalPositionToSave.headline)
  val additionalPositionDetails = useState(additionalPositionToSave.details)
  @EUR val additionalPositionPrice = useState(additionalPositionToSave.priceForOneElement.sellingPrice.euros)
  val additionalPositionAmount = useState(additionalPositionToSave.amount)
  val additionalPositionRelevanceForEconomicCalculations = useState(additionalPositionToSave.relevance)
  @pct val additionalPositionValueAddedTax = useState(additionalPositionToSave.valueAddedTax.vat)


  useMemo(
    additionalPositionHeadline.value,
    additionalPositionDetails.value,
    additionalPositionPrice.value,
    additionalPositionAmount.value,
    additionalPositionRelevanceForEconomicCalculations.value,
    additionalPositionValueAddedTax.value,
  ) {
    additionalPositionProps.setter(
      additionalPositionToSave.copy(
        headline = additionalPositionHeadline.value,
        details = additionalPositionDetails.value,
        priceForOneElement = PriceWithProfit.noProfit(additionalPositionPrice.value.euro),
        amount = additionalPositionAmount.value.toDouble(),
        relevance = additionalPositionRelevanceForEconomicCalculations.value,
        valueAddedTax = ValueAddedTax(additionalPositionValueAddedTax.value),
      )
    )
  }


  div("my-5") {
    configurationItemForm(
      headline = additionalPositionHeadline,
      details = additionalPositionDetails,
      price = additionalPositionPrice,
      amount = additionalPositionAmount,
      relevance = additionalPositionRelevanceForEconomicCalculations,
      valueAddedTax = additionalPositionValueAddedTax,
      editableStatus = editableStatus,
    )
  }

}


external interface EditBlueprintAdditionalPositionProps : Props {
  var project: ResolvedProject
  var additionalPosition: ConfigurationItem
}

external interface EditConfigurationAdditionalPositionProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
  var additionalPosition: ConfigurationItem
  var editableStatus: EditableStatus
}

external interface EditAdditionalPositionProps : Props {
  var additionalPositionToSave: StateInstance<ConfigurationItem>
  var editableStatus: EditableStatus
}
