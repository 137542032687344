package services.storage.http

import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import kotlinx.serialization.Serializable
import serialized.ModuleLayoutsConfiguration
import serialized.SerializedPhotovoltaicsConfiguration
import serialized.unResolve

@Serializable
data class SaveQuoteConfigurationRequest(
  val configuration: SerializedPhotovoltaicsConfiguration,
  val moduleLayouts: ModuleLayoutsConfiguration,
  val currentQuoteSnapshot: QuoteSnapshot?,
) {
  constructor(quoteConfiguration: QuoteConfiguration) : this(
    configuration = quoteConfiguration.unResolve(),
    moduleLayouts = quoteConfiguration.moduleLayouts.unResolve(),
    currentQuoteSnapshot = quoteConfiguration.currentQuoteSnapshot,
  )
}
