package it.neckar.lizergy.model.project

import com.benasher44.uuid.Uuid
import it.neckar.customer.Customer
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.encodeForUrl
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

/**
 * Base interface for all types of projects
 */
interface ProjectConfiguration : HasUuid, BelongsToCompany {

  val projectId: PhotovoltaicsProjectId

  val projectName: String

  val customer: Customer

  val maintainer: UserLoginName

  val archiveReasons: ArchiveReasons


  override val uuid: Uuid
    get() = projectId.uuid


  /**
   * Project name to be displayed
   * Will take the set name, if is not blank
   *  or construct one from other given information
   *  or take a placeholder if no information is given
   */
  fun displayName(location: LocationInformation?): String {
    // Return manual description if is neither null nor blank
    projectName.nullIfBlank()?.let {
      return it
    }

    customer.companyOrLastName().nullIfBlank()?.let { customerName ->
      return buildString {
        append(customerName)
        location?.address?.city.nullIfBlank()?.let { cityName ->
          //Both name + city
          append(", ")
          append(cityName)
        }
      }
    }

    /**
     * Take placeholder if neither
     *  - manual description nor
     *  - company name nor
     *  - customer name nor
     *  - city
     *  was given
     */
    return "Unbenanntes Projekt"
  }

  /**
   * Returns true if the provided filter string can be found in at least one property within this project.
   * This method can be used for filtering
   */
  fun matchesFilterStringProject(filterString: String): Boolean {
    return filterString.splitToSequence(' ')
      .filter { it.isNotBlank() }
      .all { word ->
        projectId.format().contains(word, true) ||
            projectId.uuid.encodeForUrl().contains(word, true) ||
            projectName.contains(word, true) ||
            customer.completeName.contains(word, true) ||
            customer.company?.contains(word, true) == true
      }
  }

  fun belongsToMaintainer(userLoginName: UserLoginName?): Boolean {
    return userLoginName == maintainer
  }


  /**
   * An ID for a [ProjectConfiguration]
   */
  @Serializable
  data class PhotovoltaicsProjectId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): PhotovoltaicsProjectId {
        return PhotovoltaicsProjectId(randomUuid4())
      }
    }
  }

}
