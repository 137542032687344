package it.neckar.lizergy.model.project.process.state

import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import it.neckar.processStates.ProcessStateEntryId
import it.neckar.processStates.ProcessStatePhase
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("ConfigurationProcessStateEntry")
data class ConfigurationProcessStateEntry(
  override val id: ProcessStateEntryId,
  override val processState: ConfigurationProcessStates,
  override val assignedTo: UserLoginName,
  override val belongsTo: CompanyCode,
  override val assignedAt: @ms @Serializable(with = DoubleAsIsoDateTimeSerializer::class) Double,
  override val assignedBy: UserLoginName,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : LizergyProcessStateEntry {

  override val availableOptions: List<ConfigurationProcessStates>
    get() = ConfigurationProcessStates.entries

  override fun format(i18nConfiguration: I18nConfiguration): String {
    return processState.format(i18nConfiguration)
  }

  @Serializable
  @SerialName("ConfigurationProcessStates")
  enum class ConfigurationProcessStates : LizergyProcessStates {
    New,
    BeingEdited,
    Archived,

    ;

    override val inPhase: ProcessStatePhase
      get() = when (this) {
        New -> ProcessStatePhase.Untouched
        BeingEdited -> ProcessStatePhase.InProgress
        Archived -> ProcessStatePhase.Paused
      }

    override fun format(i18nConfiguration: I18nConfiguration): String {
      return when (this) {
        New -> "Angebot offen"
        BeingEdited -> "Angebot in Bearbeitung"
        Archived -> "Angebot archiviert"
      }
    }

    override fun formatDate(date: Double, i18nConfiguration: I18nConfiguration): String {
      return "seit ${dateFormat.format(date, i18nConfiguration)}"
    }
  }
}
