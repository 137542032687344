@file:OptIn(ExperimentalStdlibApi::class)

package it.neckar.projects.pvplanner


import it.neckar.projects.common.Port
import it.neckar.projects.common.ServiceDescriptor
import it.neckar.projects.common.ServiceDescriptor.DevelopmentState

/**
 * Enum that contains all active services related to the Lizergy Planner
 */
enum class PvPlannerService(
  /**
   * The name of the service - as defined in the docker-compose.yml
   */
  override val serviceName: String,
  override val apiPrefix: String,
  override val port: Port,
  override val developmentState: DevelopmentState,
) : ServiceDescriptor {
  AuthService("auth-service", "api/auth", PvPlannerPorts.authService, DevelopmentState.InProduction),
  CommentsService("comments-service", "api/comments", PvPlannerPorts.commentsService, DevelopmentState.InProduction),
  GeoInformationService("geoinformation-service", "api/geoinformation", PvPlannerPorts.geoInformationService, DevelopmentState.InProduction),
  PdfReportService("pdf-report-service", "api/pdf-report", PvPlannerPorts.pdfReportService, DevelopmentState.InProduction),
  PdfPreviewService("pdf-preview-service", "api/pdf-preview", PvPlannerPorts.pdfPreviewService, DevelopmentState.InProduction),
  ProcessStatesService("process-state-service", "api/process-states", PvPlannerPorts.processStateService, DevelopmentState.InProduction),
  ProjectQueryService("project-query-service", "api/projects", PvPlannerPorts.projectQueryService, DevelopmentState.InProduction),
  StorageService("storage-service", "api/storage", PvPlannerPorts.storageService, DevelopmentState.InProduction),

  EuScienceHubService("eu-science-hub-service", "api/eu-science-hub", PvPlannerPorts.euScienceHubService, DevelopmentState.NotImplemented),
  ;

  companion object {
    /**
     * Contains all services that are in production
     */
    val entriesInProduction: List<PvPlannerService>
      get() = entries.filter { it.developmentState == DevelopmentState.InProduction }

    val essentialEntries: List<PvPlannerService>
      get() = listOf(
        AuthService,
        ProcessStatesService,
        ProjectQueryService,
      )
  }
}
