@file:Suppress("DuplicatedCode")

package components.project

import components.project.LocalStorageKeys.companyCode
import components.project.LocalStorageKeys.editor
import components.project.LocalStorageKeys.maintainer
import components.restrictedUI
import it.neckar.commons.kotlin.js.LocalStorageKey
import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.commons.kotlin.js.LocalStorageSupport.saveToLocalStorageOptional
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.open.time.nowMillis
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.user.UserLoginName
import plannerI18nConfiguration
import react.*
import react.dom.*
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUsersAndCompanies

/**
 * Visualizes *multiple* projects
 */
fun RBuilder.projectsFiltering(
  filteredByCompanyCode: StateInstance<CompanyCode?>? = null,
  filteredByMaintainerName: StateInstance<UserLoginName?>? = null,
  filteredByEditorName: StateInstance<UserLoginName?>? = null,
  filteredByProjectState: StateInstance<LizergyProcessStates?>? = null,
  processStatesToFilter: List<LizergyProcessStates>,
  filterInputStateProject: StateInstance<String>? = null,
  filterInputStateAddress: StateInstance<String>? = null,
  localStorageKeyPrefix: LocalStorageKeyPrefix,
): Unit = child(ProjectsFiltering) {
  attrs {
    this.filteredByCompanyCode = filteredByCompanyCode
    this.filteredByMaintainerName = filteredByMaintainerName
    this.filteredByEditorName = filteredByEditorName
    this.filteredByProjectState = filteredByProjectState
    this.processStatesToFilter = processStatesToFilter
    this.filterInputStateProject = filterInputStateProject
    this.filterInputStateAddress = filterInputStateAddress
    this.localStorageKeyPrefix = localStorageKeyPrefix
  }
}


val ProjectsFiltering: FC<ProjectsFilteringProps> = fc("ProjectsFiltering") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val usersAndCompanies = useSelectUsersAndCompanies()

  val filteredByCompanyCode = props::filteredByCompanyCode.safeGet()
  val filteredByMaintainerName = props::filteredByMaintainerName.safeGet()
  val filteredByEditorName = props::filteredByEditorName.safeGet()
  val filteredByProjectStateEntry = props::filteredByProjectState.safeGet()
  val filterInputStateProject = props::filterInputStateProject.safeGet()
  val filterInputStateAddress = props::filterInputStateAddress.safeGet()
  val processStatesToFilter = props::processStatesToFilter.safeGet()
  val keyPrefix = props::localStorageKeyPrefix.safeGet()

  val allCompanies = usersAndCompanies.activeCompanies(loggedInUser)
  val allUsers = usersAndCompanies.activeUsers(loggedInUser).ensureElementInList(loggedInUser)
  val multipleCompaniesLoaded = allCompanies.size > 1

  val usersForCompany = (filteredByCompanyCode?.value?.let { companyCode ->
    usersAndCompanies.usersForCompany(companyCode).ensureElementInList(loggedInUser)
  } ?: allUsers)
    .map { it.loginName }

  val filteredByMaintainer = filteredByMaintainerName?.value?.let {
    usersAndCompanies[it]
  }
  val filteredByEditor = filteredByEditorName?.value?.let {
    usersAndCompanies[it]
  }

  val allCompaniesByCode = allCompanies.associateBy { it.companyCode }


  div("row g-2 mt-3 mb-2") {

    filteredByCompanyCode?.let { filteredByCompanyCode ->
      div("col") {
        attrs {
          restrictedUI(loggedInUser) { multipleCompaniesLoaded }
        }

        floatingSelectNullable<CompanyCode?>(
          valueAndSetter = filteredByCompanyCode,
          idProvider = { it?.value ?: "allCompanies" },
          formatter = { it?.let { allCompaniesByCode[it]?.name ?: it.value } ?: "ALLE" },
          availableOptionsWithoutNull = allCompanies.map { it.companyCode }.ensureElementInList(filteredByCompanyCode.value),
          fieldName = "companyFilter",
          title = "Firma",
          additionalOnChange = { selectedCompanyCode ->
            saveToLocalStorageOptional(LocalStorageKey("${keyPrefix}${companyCode}"), selectedCompanyCode)

            selectedCompanyCode?.let {
              filteredByEditorName?.let { filteredByUserLoginName ->
                if (filteredByEditor != null && filteredByEditor.company.companyCode != selectedCompanyCode) {
                  filteredByUserLoginName.setter(usersAndCompanies.defaultUserForCompany(selectedCompanyCode).loginName)
                  saveToLocalStorageOptional(
                    LocalStorageKey("${keyPrefix}${editor}"),
                    usersAndCompanies.defaultUserForCompany(selectedCompanyCode).loginName, UserLoginName.serializer()
                  )
                }
              }

              filteredByMaintainerName?.let { filteredByUserLoginName ->
                if (filteredByMaintainer != null && filteredByMaintainer.company.companyCode != selectedCompanyCode) {
                  val defaultUser = usersAndCompanies.defaultUserForCompany(selectedCompanyCode).loginName

                  filteredByUserLoginName.setter(defaultUser)
                  saveToLocalStorageOptional(
                    LocalStorageKey("${keyPrefix}${maintainer}"),
                    defaultUser,
                  )
                }
              }
            }
          },
        )
      }
    }

    filteredByMaintainerName?.let {
      div("col") {
        floatingSelectNullable<UserLoginName?>(
          valueAndSetter = it,
          idProvider = { it?.value ?: "allMaintainers" },
          formatter = { it?.let { usersAndCompanies[it].editorName } ?: "ALLE" },
          availableOptionsWithoutNull = usersForCompany,
          fieldName = "maintainerFilter",
          title = "Ansprechpartner",
          additionalOnChange = { newUserLoginName ->
            saveToLocalStorageOptional(
              LocalStorageKey("${keyPrefix}${maintainer}"),
              newUserLoginName, UserLoginName.serializer()
            )
          }
        )
      }
    }

    filteredByEditorName?.let {
      div("col") {
        floatingSelectNullable<UserLoginName?>(
          valueAndSetter = it,
          idProvider = { it?.value ?: "allEditors" },
          formatter = { it?.let { usersAndCompanies[it].editorName } ?: "ALLE" },
          availableOptionsWithoutNull = usersForCompany,
          fieldName = "editorFilter",
          title = "Bearbeiter",
          additionalOnChange = { newUserLoginName ->
            saveToLocalStorageOptional(
              LocalStorageKey("${keyPrefix}${editor}"),
              newUserLoginName, UserLoginName.serializer()
            )
          }
        )
      }
    }

    filteredByProjectStateEntry?.let {
      div("col") {
        floatingSelectNullable<LizergyProcessStates?>(
          valueAndSetter = it,
          idProvider = { processState -> processState?.let { "${it::class}${(processState as Enum<*>).name}" } ?: "allProjects" },
          formatter = { it?.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)?.format(plannerI18nConfiguration) ?: "ALLE AKTIVEN" },
          availableOptionsWithoutNull = processStatesToFilter.ensureElementInList(it.value),
          fieldName = "projectState",
          title = "Projektstatus",
        )
      }
    }
  }

  div("row g-2 mt-2 mb-b") {
    filterInputStateAddress?.let {
      div("col") {
        floatingInputField(
          valueAndSetter = it,
          fieldName = "filterAddress",
          title = "Adressen Filter",
        )
      }
    }

    filterInputStateProject?.let {
      div("col") {
        floatingInputField(
          valueAndSetter = it,
          fieldName = "filterProject",
          title = "Projekt Filter",
        )
      }
    }
  }

}

object LocalStorageKeys {
  // keys for local storage to store filter options
  val companyCode = LocalStorageKey("CompanyCodeFilter")
  val editor = LocalStorageKey("EditorNameFilter")
  val maintainer = LocalStorageKey("MaintainerNameFilter")
  val projectState = LocalStorageKey("ProjectStateFilter")
}


external interface ProjectsFilteringProps : Props {
  var filteredByCompanyCode: StateInstance<CompanyCode?>?
  var filteredByMaintainerName: StateInstance<UserLoginName?>?
  var filteredByEditorName: StateInstance<UserLoginName?>?
  var filteredByProjectState: StateInstance<LizergyProcessStates?>?
  var processStatesToFilter: List<LizergyProcessStates>
  var filterInputStateProject: StateInstance<String>?
  var filterInputStateAddress: StateInstance<String>?
  var localStorageKeyPrefix: LocalStorageKeyPrefix
}
