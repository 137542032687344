package components.project.configuration.tabs

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.configuration.annotations.AnnotationsList
import components.project.configuration.isConfigurationEditable
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.react.common.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

val AnnotationsOverviewFromUrl: FC<Props> = fc("AnnotationsOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Bemerkungen", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    AnnotationsOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val AnnotationsOverview: FC<QuoteConfigurationTabProps> = fc("AnnotationsOverview") { props ->
  val resolvedProject = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()


  div("row my-3") {

    div("col-lg-6 my-3") {
      h3("mb-3") {
        +"Bemerkungen Angebot"
      }
      commentSection(quoteConfiguration)
    }

    AnnotationsList {
      attrs {
        this.project = resolvedProject
        this.moduleLayouts = quoteConfiguration.moduleLayouts
        this.powerUsageScenarioId = quoteConfiguration.powerUsageScenario.id
        this.facilityConfigurationId = quoteConfiguration.facilityConfiguration.id
        this.wallboxSelectionId = quoteConfiguration.wallboxSelection.id
        this.electricityWorkConfigurationId = quoteConfiguration.electricityWorkConfiguration.id
        this.additionalPositions = quoteConfiguration.additionalPositions
      }
    }
  }

}
