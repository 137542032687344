@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout.roof

import com.benasher44.uuid.Uuid
import io.ktor.util.*
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor.MetalRoofTileColorId
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
sealed interface MetalRoofTileConstructionType {
  val colorId: MetalRoofTileColorId?
  val amount: Int?

  fun isColored(): Boolean {
    return when (this) {
      NormalMetalRoofTile -> false
      is ColoredMetalRoofTile -> true
      is ColoredOnlyWhereVisibleMetalRoofTile -> true
    }
  }

  fun resolve(productResolver: ProductResolver): ResolvedMetalRoofTileConstructionType

  fun format(productResolver: ProductResolver, i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String
  fun format(totalAmount: Int, productResolver: ProductResolver, i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    return "$totalAmount ${NormalMetalRoofTile.format(productResolver, i18nConfiguration)}"
  }
}

@Serializable
@SerialName("NormalMetalRoofTile")
data object NormalMetalRoofTile : MetalRoofTileConstructionType {
  override val colorId: MetalRoofTileColorId?
    get() = null
  override val amount: Int?
    get() = null

  override fun resolve(productResolver: ProductResolver): ResolvedNormalMetalRoofTile {
    return ResolvedNormalMetalRoofTile
  }

  override fun format(productResolver: ProductResolver, i18nConfiguration: I18nConfiguration): String {
    return "Metalldachziegel"
  }

}

@Serializable
@SerialName("ColoredMetalRoofTile")
data class ColoredMetalRoofTile(override val colorId: MetalRoofTileColorId) : MetalRoofTileConstructionType {
  override val amount: Int?
    get() = null

  override fun resolve(productResolver: ProductResolver): ResolvedColoredMetalRoofTile {
    return ResolvedColoredMetalRoofTile(productResolver[colorId])
  }

  override fun format(productResolver: ProductResolver, i18nConfiguration: I18nConfiguration): String {
    return "${productResolver[colorId].name.toLowerCasePreservingASCIIRules()}e Metalldachziegel"
  }
}

@Serializable
@SerialName("ColoredOnlyWhereVisibleMetalRoofTile")
data class ColoredOnlyWhereVisibleMetalRoofTile(
  override val colorId: MetalRoofTileColorId,
  override val amount: Int,
) : MetalRoofTileConstructionType {

  override fun resolve(productResolver: ProductResolver): ResolvedColoredOnlyWhereVisibleMetalRoofTile {
    return ResolvedColoredOnlyWhereVisibleMetalRoofTile(
      color = productResolver[colorId],
      amount = amount,
    )
  }

  override fun format(productResolver: ProductResolver, i18nConfiguration: I18nConfiguration): String {
    return "$amount ${productResolver[colorId].name.toLowerCasePreservingASCIIRules()}e Metalldachziegel im Sichtbereich"
  }

  override fun format(totalAmount: Int, productResolver: ProductResolver, i18nConfiguration: I18nConfiguration): String {
    return "$totalAmount Metalldachziegel davon ${format(productResolver, i18nConfiguration)}"
  }
}


@Serializable
data class MetalRoofTileColor(
  val id: MetalRoofTileColorId,
  val name: String,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  /**
   * An id for a [MetalRoofTileColor]
   */
  @Serializable
  data class MetalRoofTileColorId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): MetalRoofTileColorId {
        return MetalRoofTileColorId(randomUuid4())
      }
    }
  }

}
