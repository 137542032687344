package components.project

import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementProcessStateEntry.AssemblyBasementProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry.AssemblyRoofProcessStates
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry.FinishingUpProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry.StartupOperationsProcessStates
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry.SwitchMeterBoxProcessStates
import it.neckar.react.common.table.*
import react.*
import react.dom.*
import router.useDocumentTitle
import store.hooks.useRequireCompanyForLoggedInUser

val PrepareMaterialsOverviewFromUrl: FC<Props> = fc("PrepareMaterialsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = AssemblyRoofPreparationProcessStates.entries + AssemblyBasementPreparationProcessStates.entries

  useDocumentTitle(companyName, "Montage Vorbereitungen")

  h1("mb-3") { +"Montage Vorbereitungen" }

  ProjectsOverview {
    attrs {
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = listOf(AssemblyRoofPreparationProcessStates.Done, AssemblyBasementPreparationProcessStates.Done, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.PrepareMaterial
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("PrepareMaterials")
    }
  }
}

val AssemblyRoofsOverviewFromUrl: FC<Props> = fc("AssemblyRoofsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = AssemblyRoofPreparationProcessStates.entries + AssemblyRoofProcessStates.entries

  useDocumentTitle(companyName, "Montage Dächer")

  h1("mb-3") { +"Montage Dächer" }

  ProjectsOverview {
    attrs {
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = AssemblyRoofPreparationProcessStates.entries + listOf(AssemblyRoofProcessStates.Done, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.AssemblyRoof
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("AssemblyRoofs")
    }
  }
}

val AssemblyBasementsOverviewFromUrl: FC<Props> = fc("AssemblyBasementsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = AssemblyBasementPreparationProcessStates.entries + AssemblyBasementProcessStates.entries

  useDocumentTitle(companyName, "Montage Keller")

  h1("mb-3") { +"Montage Keller" }

  ProjectsOverview {
    attrs {
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = AssemblyBasementPreparationProcessStates.entries + listOf(AssemblyBasementProcessStates.Done, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.AssemblyBasement
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("AssemblyBasements")
    }
  }
}

val SwitchMeterBoxesOverviewFromUrl: FC<Props> = fc("SwitchMeterBoxesOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = SwitchMeterBoxProcessStates.entries

  useDocumentTitle(companyName, "Zählertausch")

  h1("mb-3") { +"Zählertausch" }

  ProjectsOverview {
    attrs {
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = listOf(SwitchMeterBoxProcessStates.Done, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.SwitchMeterBox
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("SwitchMeterBoxes")
    }
  }
}

val StartupOperationsOverviewFromUrl: FC<Props> = fc("StartupOperationsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = StartupOperationsProcessStates.entries

  useDocumentTitle(companyName, "Inbetriebnahmen und Kundeneinweisungen")

  h1("mb-3") { +"Inbetriebnahmen und Kundeneinweisungen" }

  ProjectsOverview {
    attrs {
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = listOf(StartupOperationsProcessStates.Done, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.StartupOperations
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("StartupOperations")
    }
  }
}

val FinishingUpsOverviewFromUrl: FC<Props> = fc("FinishingUpsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = FinishingUpProcessStates.entries

  useDocumentTitle(companyName, "Fertigmeldungen")

  h1("mb-3") { +"Fertigmeldungen" }

  ProjectsOverview {
    attrs {
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = listOf(FinishingUpProcessStates.Done, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.FinishingUp
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("FinishingUps")
    }
  }
}
