package it.neckar.common.redux

import it.neckar.open.unit.other.Order
import it.neckar.open.unit.other.Sorted

/**
 * Represents a state that can be undone
 */
data class UndoState<S>(
  /**
   * The snapshot for the current state
   */
  val current: Snapshot<S>,

  /**
   * The previous states (for undo).
   *
   * The first element is the *oldest* state.
   * The last element is the *newest* state.
   */
  val previous: @Sorted(Order.ASC) List<Snapshot<S>> = emptyList(),

  /**
   * The future states (for redo).
   *
   * The first element is the *oldest* state.
   * The last element is the *newest* state.
   */
  val future: @Sorted(Order.ASC) List<Snapshot<S>> = emptyList(),

  /**
   * The max history length (also limits the future)
   */
  val maxHistoryLength: Int,
) {

  constructor(
    initialState: S,
    maxHistoryLength: Int,
  ) : this(
    current = Snapshot(initialState, ChangeType.HistoryReset, null),
    maxHistoryLength = maxHistoryLength,
  )

  /**
   * Returns the current state
   */
  val currentState: S
    get() {
      return current.state
    }

  /**
   * Updates this state. Depending on the [changeType] the history is updated
   */
  fun update(newState: S, changeType: ChangeType): UndoState<S> {
    return update(Snapshot(newState, changeType, null))
  }

  /**
   * Updates the current state
   */
  fun update(newCurrent: Snapshot<S>): UndoState<S> {
    return when (newCurrent.changeType) {
      ChangeType.MinorChange -> {
        copy(
          current = newCurrent,
          previous = previous.takeLast(maxHistoryLength) + current,
          future = emptyList(),
        )
      }

      ChangeType.MajorChange -> {
        copy(
          current = newCurrent,
          previous = (previous + current).condenseMinor().takeLast(maxHistoryLength),
          future = emptyList(),
        )
      }

      ChangeType.HistoryReset -> {
        copy(
          current = newCurrent,
          previous = emptyList(),
          future = emptyList(),
        )
      }
    }
  }

  /**
   * Removes all minor changes from the history
   */
  fun commitMinorChanges(): UndoState<S> {
    return when (current.changeType) {
      ChangeType.MinorChange -> {
        copy(
          previous = previous.condenseMinor().takeLast(maxHistoryLength),
          current = current.copy(changeType = ChangeType.MajorChange),
        )
      }

      ChangeType.MajorChange -> {
        copy(
          previous = previous.condenseMinor().takeLast(maxHistoryLength),
        )
      }

      ChangeType.HistoryReset -> {
        // nothing to do, history must already be empty
        require(previous.isEmpty()) { "History must be empty" }
        this
      }
    }
  }

  fun undo(): UndoState<S> {
    return if (previous.isEmpty()) {
      this
    } else {
      this.copy(
        current = previous.last(),
        previous = previous.dropLast(1),
        future = buildList {
          add(current)
          addAll(future.take(maxHistoryLength))
        },
      )
    }
  }

  fun redo(): UndoState<S> {
    return if (future.isEmpty()) {
      this
    } else {
      this.copy(
        current = future.first(),
        previous = previous + current,
        future = future.drop(1),
      )
    }
  }

  /**
   * A history entry
   */
  data class Snapshot<S>(
    /**
     * The state
     */
    val state: S,

    /**
     * The type of change that resulted in this state
     */
    val changeType: ChangeType,

    /**
     * The optional label
     */
    val label: String?,
  )
}

/**
 * Condenses the list of snapshots by removing minor changes
 */
fun <S> List<UndoState.Snapshot<S>>.condenseMinor(): List<UndoState.Snapshot<S>> {
  return this.filter {
    it.changeType != ChangeType.MinorChange
  }
}
