package it.neckar.lizergy.model.project

import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.unit.si.ms
import kotlinx.serialization.Serializable

/**
 * Describes the life cycle state for a project
 */
@Deprecated("In the process of being reworked")
@Serializable
enum class OLDProcessState {
  /**
   * Currently (basically) unused
   */
  Archived,

  /**
   * The offer made for the project has been declined
   * This [LizergyProcessState] must be set manually
   */
  OfferRejected,

  /**
   * The confirmation sent for the project has been declined
   * This [LizergyProcessState] must be set manually
   */
  ConfirmationRejected,

  /**
   * The project has been created but there is no other information yet available
   */
  ProjectCreated,

  ProjectScheduled,

  ProjectBeingEdited,

  ProjectEmpty,

  /**
   * The configuration has been created but no changes have been made yet
   */
  ConfigurationCreated,

  /**
   * The configuration is active and currently in use
   * This [LizergyProcessState] is automatically set as soon as the first change is made
   */
  ConfigurationBeingEdited,

  /**
   * The configuration is up for review and ready to be sent out
   * This [LizergyProcessState] is manually set by the editor
   */
  ConfigurationFinished,

  /**
   * An offer has been sent for this configuration
   * This [LizergyProcessState] is automatically set when an offer pdf is generated
   */
  OfferSent,

  /**
   * The offer for this configuration has been accepted
   * This [LizergyProcessState] must be set manually
   */
  OfferAccepted,

  /**
   * A confirmation has been sent for this configuration
   * This [LizergyProcessState] is automatically set when a confirmation pdf is generated
   */
  ConfirmationSent,

  /**
   * The confirmation for this configuration has been accepted
   * This [LizergyProcessState] must be set manually
   */
  ConfirmationAccepted,

  ;

  fun processStatePhase(): OLDProcessStatePhase {
    return when (this) {
      ProjectEmpty -> OLDProcessStatePhase.NoPhase

      ProjectCreated, ProjectScheduled, ProjectBeingEdited -> OLDProcessStatePhase.BlueprintPhase

      ConfigurationCreated, ConfigurationBeingEdited -> OLDProcessStatePhase.ConfigurationPhase

      ConfigurationFinished, OfferSent, OfferAccepted, OfferRejected, ConfirmationSent, ConfirmationAccepted, ConfirmationRejected -> OLDProcessStatePhase.PresentationPhase

      Archived -> OLDProcessStatePhase.ArchivedPhase
    }
  }

  fun processStateStage(): OLDProcessStateStage {
    return when (this) {
      ProjectEmpty -> OLDProcessStateStage.NoStage

      ProjectCreated, ProjectScheduled, ProjectBeingEdited -> OLDProcessStateStage.AcquisitionStage

      ConfigurationCreated, ConfigurationBeingEdited, ConfigurationFinished -> OLDProcessStateStage.EditStage

      OfferSent, OfferAccepted, OfferRejected -> OLDProcessStateStage.OfferStage

      ConfirmationSent, ConfirmationAccepted, ConfirmationRejected -> OLDProcessStateStage.ConfirmationStage

      Archived -> OLDProcessStateStage.ArchivedStage
    }
  }

  fun processStateType(): OLDProcessStateType {
    return when (this) {
      ProjectEmpty, ProjectCreated -> OLDProcessStateType.NoType

      ProjectScheduled, ConfigurationCreated -> OLDProcessStateType.DataMissing

      ProjectBeingEdited, ConfigurationBeingEdited -> OLDProcessStateType.BeingEdited

      OfferSent, ConfirmationSent -> OLDProcessStateType.WaitingForResponse

      ConfigurationFinished, OfferAccepted, ConfirmationAccepted -> OLDProcessStateType.Accepted

      OfferRejected, ConfirmationRejected -> OLDProcessStateType.Rejected

      Archived -> OLDProcessStateType.Archived
    }
  }


  /**
   * Used for the manual editing of the [LizergyProcessState]
   */
  fun processStageValues(): List<OLDProcessState> {
    return processStageValues(this.processStateStage())
  }

  fun isEditable(): Boolean {
    val processStateType = processStateType()
    return processStateType == OLDProcessStateType.BeingEdited || processStateType == OLDProcessStateType.DataMissing || this == ConfigurationFinished
  }

  fun inBlueprintPhase(): Boolean {
    return processStatePhase() == OLDProcessStatePhase.BlueprintPhase
  }

  fun inConfigurationPhase(): Boolean {
    return processStatePhase() == OLDProcessStatePhase.ConfigurationPhase
  }

  fun inPresentationPhase(): Boolean {
    return processStatePhase() == OLDProcessStatePhase.PresentationPhase
  }

  fun format(): String {
    return when (this) {
      ProjectCreated -> "PE Termin fehlt"
      ProjectScheduled -> "PE terminiert"
      ProjectBeingEdited -> "PE in Bearbeitung"

      ProjectEmpty -> "Projekt unvollständig"

      ConfigurationCreated -> "AN offen"
      ConfigurationBeingEdited -> "AN in Bearbeitung"
      ConfigurationFinished -> "AN fertig"

      OfferSent -> "AN versendet"
      OfferAccepted -> "AN angenommen"
      OfferRejected -> "AN abgelehnt"

      ConfirmationSent -> "AB versendet"
      ConfirmationAccepted -> "AB angenommen"
      ConfirmationRejected -> "AB abgelehnt"

      Archived -> "Archiviert"
    }
  }

  fun formatDate(date: @ms Double, i18nConfiguration: I18nConfiguration): String {
    return when (this) {
      ProjectCreated, ProjectBeingEdited, ConfigurationCreated, ConfigurationBeingEdited, ConfigurationFinished -> "seit ${dateFormat.format(date, i18nConfiguration)}"

      ProjectScheduled, OfferSent, OfferAccepted, OfferRejected, ConfirmationSent, ConfirmationAccepted, ConfirmationRejected, Archived -> "am ${dateFormat.format(date, i18nConfiguration)}"

      ProjectEmpty -> ""
    }
  }

  fun formatWithDate(date: @ms Double, i18nConfiguration: I18nConfiguration): String {
    return "${format()} ${formatDate(date, i18nConfiguration)}".trim()
  }


  companion object {
    /**
     * Lists the list of corresponding [OLDProcessState]s for the given [OLDProcessStatePhase]
     */
    fun processPhaseValues(processStatePhase: OLDProcessStatePhase): List<OLDProcessState> {
      return when (processStatePhase) {
        OLDProcessStatePhase.NoPhase -> listOf(ProjectEmpty)
        OLDProcessStatePhase.BlueprintPhase -> listOf(ProjectCreated, ProjectScheduled, ProjectBeingEdited)
        OLDProcessStatePhase.ConfigurationPhase -> listOf(ConfigurationCreated, ConfigurationBeingEdited)
        OLDProcessStatePhase.PresentationPhase -> listOf(ConfigurationFinished, OfferSent, OfferAccepted, OfferRejected, ConfirmationSent, ConfirmationAccepted, ConfirmationRejected)
        OLDProcessStatePhase.ArchivedPhase -> listOf(Archived)
      }
    }

    /**
     * Lists the list of corresponding [LizergyProcessState]s for the given [OLDProcessStateStage]
     */
    fun processStageValues(processStateStage: OLDProcessStateStage): List<OLDProcessState> {
      return when (processStateStage) {
        OLDProcessStateStage.NoStage -> listOf(ProjectEmpty)
        OLDProcessStateStage.AcquisitionStage -> listOf(ProjectCreated, ProjectScheduled, ProjectBeingEdited)
        OLDProcessStateStage.EditStage -> listOf(ConfigurationCreated, ConfigurationBeingEdited, ConfigurationFinished)
        OLDProcessStateStage.OfferStage -> listOf(OfferSent, OfferAccepted, OfferRejected)
        OLDProcessStateStage.ConfirmationStage -> listOf(ConfirmationSent, ConfirmationAccepted, ConfirmationRejected)
        OLDProcessStateStage.ArchivedStage -> listOf(Archived)
      }
    }
  }
}


/**
 * The possible stages of a project
 */
@Serializable
@Deprecated("No longer needed, use new process state service instead")
enum class OLDProcessStatePhase {
  /**
   * Currently (basically) unused
   * In this stage, the project can no longer be edited
   */
  ArchivedPhase,

  NoPhase,

  BlueprintPhase,

  ConfigurationPhase,

  PresentationPhase,

}

/**
 * The possible stages of a configuration
 */
@Serializable
@Deprecated("No longer needed, use new process state service instead")
enum class OLDProcessStateStage {
  /**
   * Currently (basically) unused
   * In this stage, the configuration can no longer be edited
   */
  ArchivedStage,

  NoStage,

  AcquisitionStage,

  /**
   * The configuration can *ONLY* be edited in this stage
   */
  EditStage,

  /**
   * An offer has been sent
   * In this stage, the configuration can no longer be edited
   */
  OfferStage,

  /**
   * A confirmation for an offer has been sent
   * In this stage, the configuration can no longer be edited
   */
  ConfirmationStage,

  ;

  fun format(): String {
    return when (this) {
      NoStage -> "Unvollständig"
      AcquisitionStage -> "In Erfassung"
      EditStage -> "In Bearbeitung"
      OfferStage -> "In Angebotsphase"
      ConfirmationStage -> "In Bestätigungsphase"
      ArchivedStage -> "Archiviert"
    }
  }
}

/**
 * The possible types of a [LizergyProcessState]
 */
@Serializable
@Deprecated("No longer needed, use new process state service instead")
enum class OLDProcessStateType {
  /**
   * Currently (basically) unused
   */
  Archived,

  /**
   * Something about the configuration was rejected
   * Currently, either the offer or its confirmation
   */
  Rejected,

  NoType,

  DataMissing,

  /**
   * Currently being edited
   */
  BeingEdited,

  /**
   * Either an offer or its confirmation have been sent out
   */
  WaitingForResponse,

  /**
   * Either an offer or its confirmation have been accepted
   */
  Accepted,
}
