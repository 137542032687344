@file:Suppress("DuplicatedCode")

package components.project.configuration.additional

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.freezableAddButton
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.open.collections.fastForEach
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.RouterUrls
import services.UiActions

val ConfigurationAdditionalPositions: FC<QuoteConfigurationTabProps> = fc("ConfigurationAdditionalPositions") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val legalNoticeAdditionalLineToSave = useState(quoteConfiguration.legalNoticeAdditionalLine.orEmpty())
  val legalNotesToSave = useState(quoteConfiguration.legalNotes)

  val existingFacilityConfigurationToSave = useState(quoteConfiguration.existingFacilitiesConfiguration)


  val configurationToSave = useMemo(
    legalNoticeAdditionalLineToSave.value,
    legalNotesToSave.value,
    existingFacilityConfigurationToSave.value,
  ) {
    quoteConfiguration.copy(
      legalNoticeAdditionalLine = legalNoticeAdditionalLineToSave.value.nullIfBlank(),
      legalNotes = legalNotesToSave.value,
      existingFacilitiesConfiguration = existingFacilityConfigurationToSave.value,
    )
  }


  useMemo(quoteConfiguration.existingFacilitiesConfiguration) {
    existingFacilityConfigurationToSave.setter(quoteConfiguration.existingFacilitiesConfiguration)
  }

  useMemo(configurationToSave) {
    if (editableStatus == Editable) {
      UiActions.saveQuoteConfiguration(project, configurationToSave)
    }
  }


  div("row my-5") {

    div("col-lg-6") {
      AdditionalPositionsOverview {
        attrs {
          this.additionalPositions = quoteConfiguration.additionalPositions
          this.editableStatus = editableStatus

          this.addAction = {
            UiActions.addAdditionalPositionToConfiguration(project, quoteConfiguration).also { additionalPosition ->
              navigate(RouterUrls.project(project).configuration(quoteConfiguration.configurationId).additionalPosition(additionalPosition.uuid))
            }
          }
          this.editAction = { additionalPosition ->
            navigate(RouterUrls.project(project).configuration(quoteConfiguration).additionalPosition(additionalPosition.uuid))
          }
          this.deleteAction = { additionalPosition ->
            UiActions.removeAdditionalPositionFromConfiguration(project, quoteConfiguration, additionalPosition)
          }
        }
      }
    }

    div("col-lg-6") {
      h3("mb-2") {
        +"Bemerkungen Zusätzliches"
      }
      project.blueprint.additionalAnnotation?.let {
        p { +it }
      }
      commentSection(quoteConfiguration.additionalPositions)
    }

  }

  div("row my-5") {

    div("col-lg-6") {
      ExistingFacilitiesOverview {
        attrs {
          this.existingFacilitiesConfigurationToSave = existingFacilityConfigurationToSave
          this.editableStatus = Editable

          this.addPVAction = {
            UiActions.addExistingPVFacilityToConfiguration(project, quoteConfiguration).also { existingFacility ->
              navigate(RouterUrls.project(project).configuration(quoteConfiguration.configurationId).existingPVFacility(existingFacility.uuid))
            }
          }
          this.addBHKWAction = {
            UiActions.addExistingBHKWFacilityToConfiguration(project, quoteConfiguration).also { existingFacility ->
              navigate(RouterUrls.project(project).configuration(quoteConfiguration.configurationId).existingBHKWFacility(existingFacility.uuid))
            }
          }
          this.editPVAction = { existingFacility ->
            navigate(RouterUrls.project(project).configuration(quoteConfiguration).existingPVFacility(existingFacility.uuid))
          }
          this.editBHKWAction = { existingFacility ->
            navigate(RouterUrls.project(project).configuration(quoteConfiguration).existingBHKWFacility(existingFacility.uuid))
          }
          this.deletePVAction = { existingFacility ->
            UiActions.removeExistingPVFacilityFromConfiguration(project, quoteConfiguration, existingFacility)
          }
          this.deleteBHKWAction = { existingFacility ->
            UiActions.removeExistingBHKWFacilityFromConfiguration(project, quoteConfiguration, existingFacility)
          }

        }
      }
    }

    div("col-lg-6") {
      h3("mb-2") {
        +"Bemerkungen Bestandsanlage"
      }
      commentSection(quoteConfiguration.existingFacilitiesConfiguration, ReadOnly)

      if (quoteConfiguration.existingFacilitiesConfiguration.existingPVFacilities.isNotEmpty()) {
        h4("mb-2") { +"Bestandsanlage PV" }
        quoteConfiguration.existingFacilitiesConfiguration.existingPVFacilities.fastForEach {
          div {
            h5("mb-2") { +it.zaehlernummer }
            commentSection(it)
          }
        }
      }

      if (quoteConfiguration.existingFacilitiesConfiguration.existingBHKWFacilities.isNotEmpty()) {
        h4("mb-2") { +"Bestandsanlage BHKW" }
        quoteConfiguration.existingFacilitiesConfiguration.existingBHKWFacilities.fastForEach {
          div {
            h5("mb-2") { +it.zaehlernummer }
            commentSection(it)
          }
        }
      }

    }

  }

}

val AdditionalPositionsOverview: FC<AdditionalPositionsOverviewProps> = fc("AdditionalPositionsOverview") { props ->
  val additionalPositions = props::additionalPositions.safeGet()
  val addAction = props::addAction.safeGet()
  val editAction = props::editAction.safeGet()
  val deleteAction = props::deleteAction.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  div {

    h2("mb-3") {
      +"Zusätzliche Positionen"

      freezableAddButton(editableStatus) {
        addAction()
      }
    }

    div("row") {
      additionalPositions.fastForEach { configurationItem ->
        div {
          key = configurationItem.uuid.toString()
          configurationItemPreview(
            configurationItem = configurationItem,
            editableStatus = editableStatus,
            editAction = editAction,
            deleteAction = deleteAction,
          )
        }
      }
    }

  }

}


external interface AdditionalPositionsOverviewProps : Props {
  var additionalPositions: ConfigurationItemsConfiguration
  var addAction: () -> Unit
  var editAction: (additionalPosition: ConfigurationItem) -> Unit
  var deleteAction: (additionalPosition: ConfigurationItem) -> Unit
  var editableStatus: EditableStatus
}
