package it.neckar.rest

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Empty response that does not have any values.
 * This can be used for responses that do not have any data.
 */
@Serializable
sealed interface EmptyResponse : RestResponse<Boolean> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: Boolean) : EmptyResponse, RestResponse.SuccessResponse<Boolean>

  @Serializable
  @SerialName("failure")
  data object Failure : EmptyResponse, RestResponse.FailureResponse<Boolean>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(): EmptyResponse = Success(true)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): EmptyResponse = Failure

    fun fromBoolean(deleted: Boolean): EmptyResponse {
      return if (deleted) {
        success()
      } else {
        failure()
      }
    }
  }
}
