package it.neckar.customer.company

import kotlinx.serialization.Serializable

/**
 *
 */
@Serializable
data class BankInformation(
  val name: String,
  val iban: String,
  val bic: String,
) {

  /**
   * Returns true if all properties are set
   */
  val isPlausible: Boolean
    get() {
      return name.isNotBlank()
        && iban.isNotBlank()
        && bic.isNotBlank()
    }

  companion object {
    val empty: BankInformation = BankInformation("", "", "")
    val demo: BankInformation = BankInformation("VR Bank Steinlach-Wiesaz-Härten", "DE 40 5555 6666 7777 8888 99", "GENODES1STW")
    val Schnitzer: BankInformation = BankInformation("VR Bank Steinlach-Wiesaz-Härten", "DE 40 6406 1854 0115 0760 00", "GENODES1STW")
    val Lizergy: BankInformation = BankInformation("Volksbank Horb-Freudenstadt", "DE 4564 2910 10 00 9154 0003", "GENODES1FDS")
    val NeckarIT: BankInformation = BankInformation("", "", "")
  }
}
