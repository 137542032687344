package it.neckar.heartbeat

import kotlinx.coroutines.*
import kotlinx.coroutines.flow.*
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

/**
 * Checks the heartbeat to a service
 */
class Heartbeat(
  /**
   * The delay between two checks
   */
  val delayBetweenChecks: Duration = 5.seconds,
  /**
   * The timeout for each check. If the check takes longer than this
   * timeout, it is canceled and [HeartbeatState.Dead] emitted.
   */
  val timeout: Duration = 1.seconds,
  /**
   * Will be called regularly.
   * Returns the HeartbeatState
   */
  val checkConnection: suspend () -> HeartbeatState,
) {

  /**
   * Starts the heart beat.
   *
   * Returns a flow with the heartbeat results
   */
  fun createFlow(): Flow<HeartbeatState> {
    return flow {
      while (currentCoroutineContext().isActive) {
        //Run the first check immediately
        val result = withTimeoutOrNull(timeout) {
          try {
            emit(checkConnection())
          } catch (e: CancellationException) {
            throw e
          } catch (e: Throwable) {
            //Some exception has occurred, connection is dead
            emit(HeartbeatState.Dead.ExceptionOccurred(e))
          }
        }

        //Timeout reached
        if (result == null) {
          emit(HeartbeatState.Dead.ConnectionFailure)
        }

        //delay
        delay(delayBetweenChecks)
      }
    }
  }
}


