package services.http

import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyBasementProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object LizergyProcessStatesSerializer : KSerializer<LizergyProcessStates> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("LizergyProcessStates", PrimitiveKind.STRING)

  override fun serialize(encoder: Encoder, value: LizergyProcessStates) {
    encoder.encodeString("${value::class.simpleName}::$value")
  }

  override fun deserialize(decoder: Decoder): LizergyProcessStates {
    val string = decoder.decodeString()
    val (className, value) = string.split("::")
    return when (className) {
      "AdvanceInvoiceProcessStates" -> AdvanceInvoiceProcessStateEntry.AdvanceInvoiceProcessStates.valueOf(value)
      "AssemblyBasementPreparationProcessStates" -> AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates.valueOf(value)
      "AssemblyBasementProcessStates" -> AssemblyBasementProcessStateEntry.AssemblyBasementProcessStates.valueOf(value)
      "AssemblyPortfolioProcessStates" -> AssemblyPortfolioProcessStateEntry.AssemblyPortfolioProcessStates.valueOf(value)
      "AssemblyRoofPreparationProcessStates" -> AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates.valueOf(value)
      "AssemblyRoofProcessStates" -> AssemblyRoofProcessStateEntry.AssemblyRoofProcessStates.valueOf(value)
      "BlueprintAcquisitionProcessStates" -> BlueprintAcquisitionProcessStateEntry.BlueprintAcquisitionProcessStates.valueOf(value)
      "BlueprintProcessStates" -> BlueprintProcessStateEntry.BlueprintProcessStates.valueOf(value)
      "ConfigurationProcessStates" -> ConfigurationProcessStateEntry.ConfigurationProcessStates.valueOf(value)
      "DocumentationProcessStates" -> DocumentationProcessStateEntry.DocumentationProcessStates.valueOf(value)
      "FinalAccountProcessStates" -> FinalAccountProcessStateEntry.FinalAccountProcessStates.valueOf(value)
      "FinishingUpProcessStates" -> FinishingUpProcessStateEntry.FinishingUpProcessStates.valueOf(value)
      "GridAssessmentProcessStates" -> GridAssessmentProcessStateEntry.GridAssessmentProcessStates.valueOf(value)
      "OrderSpecialMaterialProcessStates" -> OrderSpecialMaterialProcessStateEntry.OrderSpecialMaterialProcessStates.valueOf(value)
      "PresentationProcessStates" -> PresentationProcessStateEntry.PresentationProcessStates.valueOf(value)
      "ProjectProcessStates" -> ProjectProcessStateEntry.ProjectProcessStates.valueOf(value)
      "QuoteConfirmationProcessStates" -> QuoteConfirmationProcessStateEntry.QuoteConfirmationProcessStates.valueOf(value)
      "QuoteOfferProcessStates" -> QuoteOfferProcessStateEntry.QuoteOfferProcessStates.valueOf(value)
      "StartupOperationsProcessStates" -> StartupOperationsProcessStateEntry.StartupOperationsProcessStates.valueOf(value)
      "SwitchMeterBoxProcessStates" -> SwitchMeterBoxProcessStateEntry.SwitchMeterBoxProcessStates.valueOf(value)
      else -> throw IllegalArgumentException("Unknown LizergyProcessStates: $string")
    }
  }
}
