package components.project.configuration.additional

import components.project.configuration.RelevanceForEconomicsCalculation
import components.project.freezableEditButton
import i18n.EnumTranslator
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import kotlinx.html.title
import plannerI18nConfiguration
import react.*
import react.dom.*

/**
 *
 */
fun RBuilder.configurationItemPreview(
  configurationItem: ConfigurationItem,
  editableStatus: EditableStatus,
  editAction: (additionalPosition: ConfigurationItem) -> Unit,
  deleteAction: (additionalPosition: ConfigurationItem) -> Unit,
): Unit = child(ConfigurationItemPreview) {
  attrs {
    this.configurationItem = configurationItem
    this.editAction = editAction
    this.deleteAction = deleteAction
    this.editableStatus = editableStatus
  }
}

val ConfigurationItemPreview: FC<ConfigurationItemPreviewProps> = fc("ConfigurationItemPreview") { props ->
  val configurationItem = props::configurationItem.safeGet()
  val editAction = props::editAction.safeGet()
  val deleteAction = props::deleteAction.safeGet()
  val editableStatus = props::editableStatus.safeGet()


  div {
    attrs {
      if (editableStatus == ReadOnly) {
        title = "Dieses Angebot kann nicht weiter bearbeitet werden!"
      }
    }

    h4("card-title mt-0 mb-3") {
      +"Beschreibung: ${configurationItem.headline}"

      freezableEditButton(editableStatus) {
        editAction(configurationItem)
      }

      if (editableStatus == Editable) {
        actionButtonWithConfirmationModal(
          icon = FontAwesomeIcons.trash,
          modalTitle = "Soll die zusätzliche Position gelöscht werden?",
        ) { deleteAction(configurationItem) }
      }
    }

    p {
      +(configurationItem.details)
    }
    p {
      +"Menge: ${configurationItem.amount}"
    }
    p {
      +"Einzelpreis: ${configurationItem.priceForOneElement.sellingPrice.format()}"
    }
    p {
      +"Gesamt: ${configurationItem.sum.sellingPrice.format()}"
    }
    p {
      +configurationItem.valueAddedTax.format(plannerI18nConfiguration)
    }

    p("mt-3") {
      +when (configurationItem.relevance.tags.firstOrNull { it != LizergyCalculationCategories.EconomicsCalculation }) {
        LizergyCalculationCategories.BatteryStorage -> IncomePercentageCategory.Profit2Battery
        LizergyCalculationCategories.Scaffolding -> IncomePercentageCategory.Profit3Scaffolding
        LizergyCalculationCategories.ElectricityMaterial -> IncomePercentageCategory.Profit4ElectricityMaterial
        LizergyCalculationCategories.Assembly -> IncomePercentageCategory.Profit5Assembly
        LizergyCalculationCategories.ElectricityWork -> IncomePercentageCategory.Profit6ElectricityWork
        else -> IncomePercentageCategory.Profit1
      }.description
    }
    val relevantForEconomicsCalculation = RelevanceForEconomicsCalculation.from(configurationItem.relevance)
    p {
      addClassIf("form-text") { relevantForEconomicsCalculation != RelevanceForEconomicsCalculation.Relevant }
      +EnumTranslator.RelevanceForEconomicsCalculation(relevantForEconomicsCalculation)
    }

  }
}

external interface ConfigurationItemPreviewProps : Props {
  var configurationItem: ConfigurationItem
  var editAction: (additionalPosition: ConfigurationItem) -> Unit
  var deleteAction: (additionalPosition: ConfigurationItem) -> Unit
  var editableStatus: EditableStatus
}
