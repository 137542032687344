package it.neckar.lizergy.model.configuration.quote

import it.neckar.lifeCycle.onlyActive
import it.neckar.lizergy.model.ElementsCollection
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.uuid.withAdded
import it.neckar.uuid.withRemoved
import it.neckar.uuid.withReplaced

/**
 * The quotes
 */
data class QuoteConfigurations(override val elements: List<QuoteConfiguration>) : ElementsCollection<QuoteConfiguration> {

  override val validElements: List<QuoteConfiguration>
    get() = elements.onlyActive().sortedByDescending { resolvedConfiguration ->
      resolvedConfiguration.processState?.currentValue
    }

  override val size: Int
    get() = validElements.size

  operator fun get(configurationId: PhotovoltaicsConfigurationId): QuoteConfiguration {
    return get(configurationId.uuid)
  }

  override fun withAdded(additionalElement: QuoteConfiguration): QuoteConfigurations {
    val updatedElements: List<QuoteConfiguration> = elements.withAdded(additionalElement)
    return copy(elements = updatedElements)
  }

  override fun withAdded(additionalElements: List<QuoteConfiguration>): QuoteConfigurations {
    val updatedElements: List<QuoteConfiguration> = elements + additionalElements
    return copy(elements = updatedElements)
  }

  override fun withUpdated(updatedElement: QuoteConfiguration): QuoteConfigurations {
    return copy(elements = elements.withReplaced(updatedElement))
  }

  override fun withUpdated(updatedElements: List<QuoteConfiguration>): QuoteConfigurations {
    return copy(elements = elements.withReplaced(updatedElements))
  }

  override fun withRemoved(removedElement: QuoteConfiguration): QuoteConfigurations {
    return copy(elements = elements.withRemoved(removedElement))
  }

  override fun map(function: (QuoteConfiguration) -> QuoteConfiguration): QuoteConfigurations {
    return QuoteConfigurations(validElements.map(function))
  }

  companion object {
    val empty: QuoteConfigurations = QuoteConfigurations(emptyList())
  }

}
