package components

import it.neckar.open.kotlin.lang.encodeForCssIdentifier
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import kotlinx.html.DIV
import kotlinx.html.id
import react.*
import react.dom.*


/**
 *
 */
fun RBuilder.toggleableUI(
  toggleTitle: String,
  toggleState: StateInstance<Boolean>,
  editableStatus: EditableStatus,
  block: RDOMBuilder<DIV>.() -> Unit,
): Unit = child(toggleableUI) {
  attrs {
    this.toggleTitle = toggleTitle
    this.toggleState = toggleState
    this.editableStatus = editableStatus
    this.block = block
  }
}

val toggleableUI: FC<ToggleableUIProps> = fc("toggleableUI") { props ->

  val toggleTitle = props::toggleTitle.safeGet()
  val toggleState = props::toggleState.getNotNull()
  val editableStatus = props::editableStatus.safeGet()
  val block = props::block.safeGet()

  val collapseId = "${toggleTitle.replace(" ", "")}Collapse".encodeForCssIdentifier()

  div("my-3") {
    checkbox(
      valueAndSetter = toggleState,
      fieldName = "overrideCheckbox",
      title = toggleTitle,
      editableStatus = editableStatus,
    ) {
      attrs["data-bs-toggle"] = "collapse"
      attrs["data-bs-target"] = "#$collapseId"
      attrs["aria-expanded"] = "false"
      attrs["aria-controls"] = collapseId
    }


    div("collapse") {
      attrs {
        id = collapseId

        addClassIf("show")  {
          toggleState.value
        }
      }

      block(this)
    }
  }

}


external interface ToggleableUIProps : Props {
  var toggleTitle: String
  var toggleState: StateInstance<Boolean>

  var editableStatus: EditableStatus

  var block: (RDOMBuilder<DIV>) -> Unit
}
