package components.sales

import components.project.HomepageProps
import components.project.homepageButtonForPhase
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.previews.ProjectQueryForUser
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementProcessStateEntry.AssemblyBasementProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry.AssemblyRoofProcessStates
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry.FinishingUpProcessStates
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry.StartupOperationsProcessStates
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry.SwitchMeterBoxProcessStates
import it.neckar.lizergy.model.project.process.state.onlyUnfinished
import react.*
import react.dom.*
import router.RouterUrls
import services.http.ProjectCountRequestForPhase

val ConstructionHomepage: FC<HomepageProps> = fc("ConstructionHomepage") { props ->
  val projectCountsForPhases = props::projectCountsForPhases.safeGet()

  div {

    h3("mb-3") {
      +"Montage"
    }

    div("row row-cols-1 gy-2") {

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.prepareMaterial,
        title = "Montage Vorbereitung",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.PrepareMaterial),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.assemblyRoof,
        title = "Montage Dach",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.AssemblyRoof),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.assemblyBasement,
        title = "Montage Keller",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.AssemblyBasement),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.switchMeterBox,
        title = "Zählertausch",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.SwitchMeterBox),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.startupOperations,
        title = "Inbetriebnahme und Kundeneinweisung",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.StartupOperations),
      )

      homepageButtonForPhase(
        url = RouterUrls.OverviewTableRouterUrls.finishingUp,
        title = "Fertigmeldung",
        projectCountForPhase = projectCountsForPhases?.get(ProjectQueryComponent.FinishingUp),
      )

    }

  }
}

val ConstructionCountRequest: List<ProjectCountRequestForPhase> = listOf(
  ProjectCountRequestForPhase(
    relevantProcessStates = AssemblyRoofPreparationProcessStates.entries.onlyUnfinished() + AssemblyBasementPreparationProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.PrepareMaterial,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = AssemblyRoofProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.AssemblyRoof,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = AssemblyBasementProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.AssemblyBasement,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = SwitchMeterBoxProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.SwitchMeterBox,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = StartupOperationsProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.StartupOperations,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
  ProjectCountRequestForPhase(
    relevantProcessStates = FinishingUpProcessStates.entries.onlyUnfinished(),
    projectQueryComponent = ProjectQueryComponent.FinishingUp,
    projectQueryForUser = ProjectQueryForUser.AssignedEditor,
  ),
)
