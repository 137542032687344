package it.neckar.ktor.client

import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import it.neckar.rest.version.RestProtocolVersionInfo


/**
 * Adds the protocol version header to the request.
 *
 * See [RestProtocolVersionInfo.VersionHeaderKey] for the header key and [RestProtocolVersionInfo.Version] for the version number.
 */
fun HttpClientConfig<*>.installProtocolVersionHeader() {
  install(DefaultRequest) {
    headers {
      append(RestProtocolVersionInfo.VersionHeaderKey, RestProtocolVersionInfo.Version)
    }
  }
}
