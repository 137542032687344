package it.neckar.ktor.client.plugin.stats

import it.neckar.open.unit.si.ms
import io.ktor.http.*


sealed interface RequestDescriptor {
  /**
   * The URL for the request
   */
  val url: Url

  /**
   * The HTTP method
   */
  val method: HttpMethod

  /**
   * When the request started
   */
  val startedAt: @ms Double
}

/**
 * A data class representing an intercepted HTTP request that is currently active.
 *
 * @property url the request URL.
 * @property method the request method.
 */
data class PendingRequestDescriptor(
  /**
   * The URL for the request
   */
  override val url: Url,
  /**
   * The HTTP method
   */
  override val method: HttpMethod,
  /**
   * When the request started
   */
  override val startedAt: @ms Double,
) : RequestDescriptor {
  fun receivedAt(finishedAt: Double): CompletedRequestDescriptor {
    return CompletedRequestDescriptor(url, method, startedAt, finishedAt)
  }

  /**
   * Returns true if this matches the provided request (by comparing all properties)
   */
  fun matches(request: RequestDescriptor): Boolean {
    return this.url == request.url && this.method == request.method && this.startedAt == request.startedAt
  }
}

/**
 * Representing a completed HTTP request
 */
data class CompletedRequestDescriptor(
  /**
   * The URL for the request
   */
  override val url: Url,
  /**
   * The HTTP method
   */
  override val method: HttpMethod,
  /**
   * When the request started
   */
  override val startedAt: @ms Double,

  /**
   * When the request has been completed
   */
  val completedAt: @ms Double,

  ) : RequestDescriptor

