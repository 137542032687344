package components.form

import com.benasher44.uuid.Uuid
import it.neckar.comments.Comment
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.editHistory.PositionEdit
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.open.formatting.dateTimeFormat
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import kotlinx.html.LI
import plannerI18nConfiguration
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUserResolver

fun RBuilder.commentForm(
  commentFor: Uuid,
  comment: Comment?,
  editableStatus: EditableStatus = Editable,
): Unit = child(CommentForm) {
  attrs {
    this.commentFor = commentFor
    this.comment = comment
    this.editableStatus = editableStatus
  }
}

val CommentForm: FC<CommentFormProps> = fc("CommentForm") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()

  val commentFor = props::commentFor.getNotNull()
  val initialComment = props::comment.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val currentEdit = initialComment?.currentEdit
  val comment = useState(currentEdit?.value.orEmpty())
  val beingEdited = useState(false)

  val okAction = {
    UiActions.addComment(
      commentFor = commentFor,
      newComment = initialComment?.copy(
        comment = initialComment.comment.with(comment.value, loggedInUser.loginName),
      ) ?: Comment(comment.value, loggedInUser.loginName),
    )
  }


  li("list-group-item") {

    if (currentEdit != null) {

      if (beingEdited.value.not()) {
        displayComment(currentEdit, initialComment, loggedInUser, beingEdited, userResolver, editableStatus)
      } else {
        editExistingComment(commentFor, comment, initialComment, currentEdit, beingEdited, editableStatus, okAction)
      }

    } else {
      writeNewComment(comment, initialComment, editableStatus, okAction)
    }

  }
}

private fun RDOMBuilder<LI>.displayComment(
  currentEdit: PositionEdit<String>,
  initialComment: Comment,
  loggedInUser: UserInformation,
  beingEdited: StateInstance<Boolean>,
  userResolver: UserResolver,
  editableStatus: EditableStatus,
) {
  p {
    span("d-grid gap-2 d-md-flex justify-content-between") {
      +currentEdit.value

      if (editableStatus == Editable && loggedInUser.loginName == initialComment.currentEdit.loginName) {
        editButton {
          beingEdited.setter(true)
        }
      }
    }
    span("form-text") {
      +buildString {
        append(currentEdit.loginName?.let { userResolver[it].editorName } ?: "-")
        append(" um ${dateTimeFormat.format(currentEdit.editTime, plannerI18nConfiguration)}")
        if (initialComment.hasBeenEdited) append(" (Editiert)")
      }
    }
  }
}

private fun RDOMBuilder<LI>.editExistingComment(
  commentFor: Uuid,
  comment: StateInstance<String>,
  initialComment: Comment,
  currentEdit: PositionEdit<String>,
  beingEdited: StateInstance<Boolean>,
  editableStatus: EditableStatus,
  okAction: () -> Unit,
) {
  span("d-grid gap-2 d-md-flex justify-content-between") {
    inputArea(
      valueAndSetter = comment,
      fieldName = initialComment.uuid.toString(),
      title = "Kommentar",
      placeHolder = "Kommentar bearbeiten",
      editableStatus = editableStatus,
    )

    actionButtonWithConfirmationModal(
      icon = FontAwesomeIcons.trash,
      modalTitle = "Soll dieser Kommentar gelöscht werden?",
    ) {
      UiActions.addComment(
        commentFor = commentFor,
        newComment = initialComment.copy(
          lifeCycleState = LifeCycleState.EndOfLife,
        ),
      )
      comment.setter("")
      beingEdited.setter(false)
    }
  }


  div("d-grid gap-2 d-md-flex justify-content-md-end mt-2") {
    formButtons(
      okConfig = {
        attrs {
          it.addClassIf("disabled") { comment.value.isBlank() || comment.value == currentEdit.value }
        }
      },
      cancelAction = { beingEdited.setter(false) },
    ) {
      okAction()
      beingEdited.setter(false)
    }
  }
}

private fun RDOMBuilder<LI>.writeNewComment(comment: StateInstance<String>, initialComment: Comment?, editableStatus: EditableStatus, okAction: () -> Unit) {
  inputArea(
    valueAndSetter = comment,
    fieldName = initialComment?.uuid?.toString() ?: "newComment",
    title = "Kommentar",
    placeHolder = "Neuer Kommentar",
    editableStatus = editableStatus,
  )

  div("d-grid gap-2 d-md-flex justify-content-md-end mt-2") {
    actionButton(
      icon = ButtonIcon.centered(FontAwesomeIcons.save),
      classes = buildList {
        add("btn btn-primary")
        if (comment.value.isBlank()) add("disabled")
      }.joinToString(" "),
      action = okAction,
    ) {
      b("ms-2") {
        +"Kommentieren"
      }
    }
  }
}

external interface CommentFormProps : Props {
  var commentFor: Uuid
  var comment: Comment?
  var editableStatus: EditableStatus
}
