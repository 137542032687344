package it.neckar.lizergy.model.location

import com.meistercharts.maps.MapCoordinates
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import kotlinx.serialization.Serializable

/**
 * Contain coordinates and the precision of these coordinates
 */
@Serializable
data class MapCoordinatesInformation(
  /**
   * The coordinates (should correspond to the address)
   */
  val coords: MapCoordinates,

  /**
   * The precision of the location information
   */
  val precision: LocationPrecision
) {
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    val precisionSign = when (precision) {
      LocationPrecision.Exact -> "✓"
      LocationPrecision.Estimated -> "∽"
      LocationPrecision.Bad -> "?"
    }

    return "${coords.format(i18nConfiguration)} $precisionSign"
  }
}

/**
 * returns the coordinates with the given precision
 */
fun MapCoordinates.with(precision: LocationPrecision): MapCoordinatesInformation {
  return MapCoordinatesInformation(this, precision)
}

/**
 * Marks the coordinates as exact
 */
fun MapCoordinates.exact(): MapCoordinatesInformation {
  return with(LocationPrecision.Exact)
}

/**
 * Marks the coordinates as estimated
 */
fun MapCoordinates.estimated(): MapCoordinatesInformation {
  return with(LocationPrecision.Estimated)
}

