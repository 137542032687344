package components.project.configuration.additional

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType
import it.neckar.lizergy.model.configuration.components.LizergyIndependenceManagerType
import it.neckar.lizergy.model.configuration.components.OtherIndependenceManagerType
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleExclamation
import it.neckar.react.common.form.*
import kotlinx.html.role
import react.*
import react.dom.*
import store.hooks.useSelectAvailableProducts

fun RBuilder.electricityWorkEffortForm(
  sunnyHomeManagerToSave: StateInstance<Boolean>,
  independenceManagerToSave: StateInstance<IndependenceManagerType?>,
  neuerZaehlerschrankToSave: StateInstance<Boolean>,
  zusammenZuLegendeZaehlerNummernToSave: StateInstance<List<String>>,
  bleibenderZaehlerToSave: StateInstance<String>,
  einbauUnterverteilerToSave: StateInstance<Boolean>,
  einbauDigitalerZaehlerToSave: StateInstance<Int?>,
  smaEnergyMeterToSave: StateInstance<Boolean>,
  slsNachruestenToSave: StateInstance<Boolean>,
  selectedBatteryConfiguration: BatteryConfiguration?,
  sunnyHomeManagerAutomaticallySet: Boolean,
  sunnyHomeManagerNote: String,
  editableStatus: EditableStatus,
): Unit = child(ElectricityWorkEffortForm) {
  attrs {
    this.sunnyHomeManagerToSave = sunnyHomeManagerToSave
    this.independenceManagerToSave = independenceManagerToSave
    this.neuerZaehlerschrankToSave = neuerZaehlerschrankToSave
    this.zusammenZuLegendeZaehlerNummernToSave = zusammenZuLegendeZaehlerNummernToSave
    this.bleibenderZaehlerToSave = bleibenderZaehlerToSave
    this.einbauUnterverteilerToSave = einbauUnterverteilerToSave
    this.einbauDigitalerZaehlerToSave = einbauDigitalerZaehlerToSave
    this.smaEnergyMeterToSave = smaEnergyMeterToSave
    this.slsNachruestenToSave = slsNachruestenToSave
    this.selectedBatteryConfiguration = selectedBatteryConfiguration
    this.sunnyHomeManagerAutomaticallySet = sunnyHomeManagerAutomaticallySet
    this.sunnyHomeManagerNote = sunnyHomeManagerNote
    this.editableStatus = editableStatus
  }
}

val ElectricityWorkEffortForm: FC<ElectricityWorkEffortFormProps> = fc("ElectricityWorkEffortForm") { props ->
  val availableProducts = useSelectAvailableProducts()

  val sunnyHomeManagerToSave = props::sunnyHomeManagerToSave.getNotNull()
  val independenceManagerToSave = props::independenceManagerToSave.getNotNull()
  val neuerZaehlerschrankToSave = props::neuerZaehlerschrankToSave.getNotNull()
  val zusammenZuLegendeZaehlerNummernToSave = props::zusammenZuLegendeZaehlerNummernToSave.getNotNull()
  val bleibenderZaehlerToSave = props::bleibenderZaehlerToSave.getNotNull()
  val einbauUnterverteilerToSave = props::einbauUnterverteilerToSave.getNotNull()
  val einbauDigitalerZaehlerToSave = props::einbauDigitalerZaehlerToSave.getNotNull()
  val smaEnergyMeterToSave = props::smaEnergyMeterToSave.getNotNull()
  val slsNachruestenToSave = props::slsNachruestenToSave.getNotNull()
  val selectedBatteryConfiguration = props::selectedBatteryConfiguration.safeGet()
  val sunnyHomeManagerAutomaticallySet = props::sunnyHomeManagerAutomaticallySet.safeGet()
  val sunnyHomeManagerNote = props::sunnyHomeManagerNote.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val validIndependenceManagerTypes = availableProducts.availableIndependenceManagerTypes().filter { it.validBatteryInverter(selectedBatteryConfiguration?.inverterType) }
  val availableLizergyIndependenceManagers = validIndependenceManagerTypes.filterIsInstance<LizergyIndependenceManagerType>()
  val availableOtherIndependenceManagers = validIndependenceManagerTypes.filterIsInstance<OtherIndependenceManagerType>()

  val independenceManagerSelected = independenceManagerToSave.value != null
  val availableIndependenceManagers = when (independenceManagerToSave.value) {
    is LizergyIndependenceManagerType -> availableLizergyIndependenceManagers
    is OtherIndependenceManagerType -> availableOtherIndependenceManagers
    else -> null
  }


  useMemo(independenceManagerSelected) {
    if (independenceManagerSelected.not()) {
      independenceManagerToSave.setter(null)
    } else {
      if (independenceManagerToSave.value == null) {
        independenceManagerToSave.setter(availableLizergyIndependenceManagers.firstOrNull() ?: availableOtherIndependenceManagers.firstOrNull())
      }
    }
  }


  div("my-3") {
    h4("mb-2") {
      +"Manager"
    }
    div {
      checkbox(
        valueAndSetter = sunnyHomeManagerToSave,
        fieldName = "sunnyHomeManager",
        title = "Sunny Home Manager 2.0 Energiemanagementsystem",
        editableStatus = if (editableStatus == EditableStatus.ReadOnly || sunnyHomeManagerAutomaticallySet) {
          EditableStatus.ReadOnly
        } else {
          EditableStatus.Editable
        },
      )
    }
    if (sunnyHomeManagerAutomaticallySet) {
      p("text-info") {
        span("pe-2") { faCircleExclamation() }
        +sunnyHomeManagerNote
      }
    }

    div("mt-3") {
      checkbox(
        value = independenceManagerSelected,
        onChange = { checked ->
          if (checked) {
            if (independenceManagerToSave.value == null) {
              independenceManagerToSave.setter(availableLizergyIndependenceManagers.firstOrNull() ?: availableOtherIndependenceManagers.firstOrNull())
            }
          } else {
            independenceManagerToSave.setter(null)
          }
        },
        fieldName = "independenceManagerSelected",
        title = "Ersatzstromsystem",
        editableStatus = editableStatus.and(validIndependenceManagerTypes.isNotEmpty()),
      )
    }
    if (validIndependenceManagerTypes.isEmpty()) {
      p("text-warning") {
        span("pe-2") { faCircleExclamation() }
        +"Ersatzstromsysteme sind nicht verfügbar. Bitte wählen Sie einen anderen Batterie-Wechselrichter."
      }
    }

    if (independenceManagerSelected) {
      div("btn-group-vertical ms-4") {
        attrs {
          role = "group"
        }

        span {
          radioButton(
            buttonId = "lizergyIndependenceManager",
            value = independenceManagerToSave.value is LizergyIndependenceManagerType,
            onChange = { radioChecked ->
              if (radioChecked && availableIndependenceManagers != availableLizergyIndependenceManagers) {
                independenceManagerToSave.setter(availableLizergyIndependenceManagers.firstOrNull())
              }
            },
            inputClasses = "form-check-input",
            labelClasses = "ms-1 form-check-label",
            editableStatus = editableStatus.and(availableLizergyIndependenceManagers.isNotEmpty()),
          ) {
            +"Lizergy Independence Manager"
          }
        }
        span {
          radioButton(
            buttonId = "otherIndependenceManager",
            value = independenceManagerToSave.value is OtherIndependenceManagerType,
            onChange = { radioChecked ->
              if (radioChecked && availableIndependenceManagers != availableOtherIndependenceManagers) {
                independenceManagerToSave.setter(availableOtherIndependenceManagers.firstOrNull())
              }
            },
            inputClasses = "form-check-input",
            labelClasses = "ms-1 form-check-label",
            editableStatus = editableStatus.and(availableOtherIndependenceManagers.isNotEmpty()),
          ) {
            +"Anderer Independence Manager"
          }
        }
      }

      div("ms-4 my-3") {
        floatingSelectUuidNullable(
          valueAndSetter = independenceManagerToSave,
          formatter = { it?.description ?: "-" },
          fieldName = "lizergyIndependenceManager",
          title = "Ersatzstromsystem",
          availableOptionsWithoutNull = availableIndependenceManagers ?: emptyList(),
          editableStatus = editableStatus,
        )
      }
    }
  }

  div("mb-3") {
    h4("mb-2") {
      +"Zählerschrankarbeiten"
    }
    div {
      checkbox(
        valueAndSetter = neuerZaehlerschrankToSave,
        fieldName = "neuerZaehlerschrank",
        title = "Neuer Zählerschrank EFH",
        editableStatus = editableStatus,
      )
    }
    div {
      checkbox(
        valueAndSetter = einbauUnterverteilerToSave,
        fieldName = "einbauUnterverteiler",
        title = "Einbau Unterverteiler",
        editableStatus = editableStatus,
      )
    }

    div("my-3") {
      zusammenZuLegendeZahlerForm(zusammenZuLegendeZaehlerNummernToSave, bleibenderZaehlerToSave, editableStatus)
    }
    div {
      nullableFloatingIntInputField(
        valueAndSetter = einbauDigitalerZaehlerToSave,
        fieldName = "einbauDigitalerZaehler",
        title = "Einbau digitaler Zähler [${einbauDigitalerZaehlerToSave.value ?: zusammenZuLegendeZaehlerNummernToSave.value.size}]",
        editableStatus = editableStatus,
      )
    }
  }

  div("mb-3") {
    h4("mb-2") {
      +"Sonstiges"
    }
    div {
      checkbox(
        valueAndSetter = smaEnergyMeterToSave,
        fieldName = "smaEnergyMeter",
        title = "SMA Energy Meter",
        editableStatus = editableStatus,
      )
    }
    div {
      checkbox(
        valueAndSetter = slsNachruestenToSave,
        fieldName = "slsNachruesten",
        title = "SLS nachrüsten",
        editableStatus = editableStatus,
      )
    }
  }

}

fun RDOMBuilder<*>.zusammenZuLegendeZahlerForm(zusammenZuLegendeZaehlerNummern: StateInstance<List<String>>, bleibenderZaehler: StateInstance<String>, editableStatus: EditableStatus = EditableStatus.Editable) {
  val numberofZaehlers = zusammenZuLegendeZaehlerNummern.value.size

  floatingIntInputField(
    value = numberofZaehlers,
    onChange = { newNumberofZaehlers ->
      val newZaehlerNummern = buildList {
        repeat(newNumberofZaehlers) { index ->
          add(zusammenZuLegendeZaehlerNummern.value.getOrNull(index).orEmpty())
        }
      }
      zusammenZuLegendeZaehlerNummern.setter(newZaehlerNummern)
    },
    fieldName = "zusammenZuLegendeZaehlerNummern",
    title = "Anzahl Zähler zusammenlegen",
    editableStatus = editableStatus,
  ) {
    attrs {
      if (numberofZaehlers > 0) mergedBelow()
    }
  }

  if (numberofZaehlers > 0) {
    floatingInputField(
      valueAndSetter = bleibenderZaehler,
      fieldName = "bleibenderZaehler",
      title = "Bleibener Zähler",
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedAbove()
        mergedBelow()
      }
    }
  }

  repeat(numberofZaehlers) { index ->
    floatingInputField(
      value = zusammenZuLegendeZaehlerNummern.value.getOrNull(index).orEmpty(),
      onChange = { newZaehlerNummer ->
        val newZaehlerNummern = zusammenZuLegendeZaehlerNummern.value.toMutableList()
        newZaehlerNummern[index] = newZaehlerNummer
        zusammenZuLegendeZaehlerNummern.setter(newZaehlerNummern)
      },
      fieldName = "zusammenZuLegendeZaehlerNummern",
      title = "Entfallender Zähler ${index + 1}",
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedAbove()
        if (index < numberofZaehlers - 1) mergedBelow()
      }
    }
  }
}

external interface ElectricityWorkEffortFormProps : Props {
  var sunnyHomeManagerToSave: StateInstance<Boolean>
  var independenceManagerToSave: StateInstance<IndependenceManagerType?>
  var neuerZaehlerschrankToSave: StateInstance<Boolean>
  var zusammenZuLegendeZaehlerNummernToSave: StateInstance<List<String>>
  var bleibenderZaehlerToSave: StateInstance<String>
  var einbauUnterverteilerToSave: StateInstance<Boolean>
  var einbauDigitalerZaehlerToSave: StateInstance<Int?>
  var smaEnergyMeterToSave: StateInstance<Boolean>
  var slsNachruestenToSave: StateInstance<Boolean>
  var selectedBatteryConfiguration: BatteryConfiguration?
  var sunnyHomeManagerAutomaticallySet: Boolean
  var sunnyHomeManagerNote: String
  var editableStatus: EditableStatus
}
