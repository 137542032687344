package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.open.formatting.format
import it.neckar.open.unit.si.kW
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

@Serializable
data class ExistingBHKWFacilityConfiguration(
  val id: ExistingBHKWFacilityConfigurationId = ExistingBHKWFacilityConfigurationId.random(),
  val zaehlernummer: String = "",
  val power: @kW Double = 0.0,
  val einspeiseart: Einspeiseart = Einspeiseart.Ueberschuss,
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  fun format(): String {
    return "Bestehende BHKW-Anlage: $zaehlernummer [${power.format(0)} kW]"
  }

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ExistingBHKWFacilityConfiguration {
    val newId = ExistingBHKWFacilityConfigurationId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(id = newId)
  }

  /**
   * An id for a [ExistingFacilitiesConfiguration]
   */
  @Serializable
  data class ExistingBHKWFacilityConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ExistingBHKWFacilityConfigurationId {
        return ExistingBHKWFacilityConfigurationId(randomUuid4())
      }
    }
  }

  companion object {
    fun getEmpty(id: ExistingBHKWFacilityConfigurationId = ExistingBHKWFacilityConfigurationId.random()): ExistingBHKWFacilityConfiguration {
      return ExistingBHKWFacilityConfiguration(id = id)
    }
  }

}
