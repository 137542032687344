package services.http

import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.logging.debug
import kotlinx.coroutines.*
import kotlinx.coroutines.channels.*
import kotlinx.coroutines.flow.*
import serialized.SerializedSearchForProjectsResult
import services.storage.http.FetchSearchForProjectsResponse
import kotlin.coroutines.CoroutineContext

/**
 * Supports search for projects
 */
class ProjectSearchSupport(
  /**
   * The context that is used for the search
   */
  context: CoroutineContext = Dispatchers.Default,
  /**
   * Returns the search result
   */
  val searchFunction: suspend (searchTerm: String) -> SerializedSearchForProjectsResult = { searchTerm ->
    when (val result: FetchSearchForProjectsResponse = PlannerUiServices.projectQueryService.searchProjects(searchTerm)) {
      is FetchSearchForProjectsResponse.Success -> result.data
      FetchSearchForProjectsResponse.Failure -> SerializedSearchForProjectsResult.empty
    }
  },
) {
  /**
   * Contains a flow with the search terms
   */
  internal val searchTerm = MutableSharedFlow<String>(
    replay = 1,
    extraBufferCapacity = 0,  //do not save any elements
    onBufferOverflow = BufferOverflow.DROP_OLDEST
  )

  /**
   * Returns the search results
   */
  val searchResults: Flow<SerializedSearchForProjectsResult> = searchTerm
    .debounce(500)
    .distinctUntilChanged()
    .flatMapLatest { query ->
      flow {
        logger.debug { "Searching for projects with query '$query'" }
        val queryResult = searchFunction(query)
        logger.debug { "Result size: ${queryResult.projects.size}" }
        emit(queryResult)
      }.catch { e ->
        logger.warn("Error while searching for projects", e)
        emit(SerializedSearchForProjectsResult.empty)
      }
    }
    .flowOn(context)

  /**
   * Updates the search term
   */
  fun setSearchTerm(term: String) {
    searchTerm.tryEmit(term)
  }

  companion object {
    private val logger: Logger = LoggerFactory.getLogger("services.http.ProjectSearchSupport")
  }
}
