package it.neckar.projects.pvplanner

/**
 * The host names for the PV planner
 */
enum class PvPlannerHost(val hostnamePlanner: String, val hostnameShop: String) {
  Localhost("localhost", "localhost"),
  Spielwiese("spielwiese.pv.neckar.it", "spielwiese.wunschsolar.de"),
  Schaufenster("schaufenster.pv.neckar.it", "schaufenster.wunschsolar.de"),
  Production("pv.neckar.it", "wunschsolar.de"),
  ;
}
