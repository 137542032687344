package it.neckar.lizergy.model.configuration.energy

import it.neckar.open.formatting.decimalFormat
import it.neckar.open.formatting.decimalFormat2digits
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.other.Wp
import it.neckar.open.unit.other.kWp
import it.neckar.open.unit.si.kWh
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * The power rating in [Wp]
 *
 * "Modulleistung"
 */
@Serializable
@JvmInline
value class PowerRating internal constructor(
  val wattPeak: @Wp Double,
) : Comparable<PowerRating> {

  val kiloWattPeak: @kWp Double
    get() = wattPeak / 1000.0

  /**
   * Returns true if the power rating is greater than the EEG limit (30 kWp)
   * If the system exceeds these limits much stricter requirements must be followed.
   *
   * see [EEGLimit]
   */
  val exceedsEEGLimit: Boolean
    get() = this > EEGLimit

  fun formatWattPeak(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "${decimalFormat2digits.format(wattPeak, i18nConfiguration)}${whitespaceConfig.smallSpace}Wp"
  }

  fun formatKiloWattPeak(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "${decimalFormat.format(kiloWattPeak, i18nConfiguration)}${whitespaceConfig.smallSpace}kWp"
  }

  override operator fun compareTo(other: PowerRating): Int {
    return this.wattPeak.compareTo(other.wattPeak)
  }

  operator fun minus(other: PowerRating): PowerRating {
    return PowerRating(this.wattPeak - other.wattPeak)
  }

  operator fun plus(other: PowerRating): PowerRating {
    return PowerRating(this.wattPeak + other.wattPeak)
  }

  operator fun times(factor: Double): PowerRating {
    return PowerRating(wattPeak * factor)
  }

  operator fun times(factor: Int): PowerRating {
    return PowerRating(wattPeak * factor)
  }

  override fun toString(): String {
    return formatWattPeak(I18nConfiguration.Germany, WhitespaceConfig.Spaces)
  }

  operator fun times(specificAnnualProduction: SpecificAnnualProduction): @kWh AmountOfEnergy {
    return AmountOfEnergy(kiloWattPeak * specificAnnualProduction.kWhPerkWpAndYear)
  }


  companion object {
    val Zero: PowerRating = PowerRating(0.0)

    /**
     * The maximum power rating that is allowed without further regulation by the EEG ("Erneuerbares Energie Gesetz").
     */
    val EEGLimit: PowerRating = kiloWattPeak(30.0)

    /**
     * Power rating for a typical module
     */
    val typical: PowerRating = PowerRating(340.0)

    fun kiloWattPeak(kiloWattPeak: @kWp Double): PowerRating {
      return PowerRating(kiloWattPeak * 1000.0)
    }

    fun wattPeak(wattPeak: @Wp Double): PowerRating {
      return PowerRating(wattPeak)
    }
  }
}

/**
 * Converts a value to watt peak
 */
val @Wp Int.wattPeak: PowerRating
  get() {
    return PowerRating.wattPeak(this.toDouble())
  }

val @kWp Double.kiloWattPeak: PowerRating
  get() {
    return PowerRating.kiloWattPeak(this)
  }


/**
 * Calculates the sum of power ratings
 */
fun Iterable<PowerRating>.sum(): PowerRating {
  return sumOf {
    it.kiloWattPeak
  }.kiloWattPeak
}
