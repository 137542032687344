package components.assemblyPortfolio

import components.gridAssessment.entryPreview
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.assemblyPortfolio.AssemblyPortfolio
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.energy.kiloWattPeak
import it.neckar.lizergy.model.configuration.energy.volts
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import react.router.dom.*
import router.RouterUrls
import services.UiActions

internal val BasisdatenOverview: FC<BasisdatenOverviewProps> = fc("BasisdatenOverview") { props ->
  val project = props::project.safeGet()
  val configuration = props::currentConfiguration.safeGet()
  val gridAssessment = project.gridAssessment
  val assemblyPortfolio = project.assemblyPortfolio

  val einspeiseart = useState(assemblyPortfolio.einspeiseart)
  val wartungsvertrag = useState(assemblyPortfolio.wartungsvertrag ?: (configuration.facilityConfiguration.onlineMonitoring == ConfiguredOptionality.Selected))
  val password = useState(assemblyPortfolio.anlagenPasswort)

  val pvLeistung = useState(assemblyPortfolio.pvLeistung?.kiloWattPeak)
  val leerlaufspannung = useState(assemblyPortfolio.leerlaufspannung?.volts)
  val messkonzept = useState(assemblyPortfolio.messkonzept)
  val ueberspannungsschutz = useState(assemblyPortfolio.ueberspannungsschutz)


  useMemo(einspeiseart.value, wartungsvertrag.value, password.value, pvLeistung.value, leerlaufspannung.value, messkonzept.value, ueberspannungsschutz.value) {
    if (
      einspeiseart.value != assemblyPortfolio.einspeiseart ||
      wartungsvertrag.value != (assemblyPortfolio.wartungsvertrag ?: (configuration.facilityConfiguration.onlineMonitoring == ConfiguredOptionality.Selected)) ||
      password.value != assemblyPortfolio.anlagenPasswort ||
      pvLeistung.value != assemblyPortfolio.pvLeistung?.kiloWattPeak ||
      leerlaufspannung.value != assemblyPortfolio.leerlaufspannung?.volts ||
      messkonzept.value != assemblyPortfolio.messkonzept ||
      ueberspannungsschutz.value != assemblyPortfolio.ueberspannungsschutz
    ) {
    UiActions.saveAssemblyPortfolio(
      assemblyPortfolio.copy(
        einspeiseart = if (einspeiseart.value != configuration.einspeiseart) einspeiseart.value else null,
        wartungsvertrag = if (wartungsvertrag.value != (configuration.facilityConfiguration.onlineMonitoring == ConfiguredOptionality.Selected)) wartungsvertrag.value else null,
        anlagenPasswort = password.value,
        pvLeistung = pvLeistung.value?.kiloWattPeak,
        leerlaufspannung = leerlaufspannung.value?.volts,
        messkonzept = messkonzept.value,
        ueberspannungsschutz = ueberspannungsschutz.value.nullIfBlank(),
      )
    )
    }
  }


  div("row my-4") {
    div("col-lg-6") {
      h3("my-3") { +"Basisdaten" }

      entryPreview("Verteiler Netzbetreiber") {
        linkToGridAssessment(project, gridAssessment.verteilerNetzbetreiberName ?: "-")
      }
      entryPreview("Wartungsvertrag") {
        checkbox(
          valueAndSetter = wartungsvertrag,
          fieldName = "wartungsvertrag",
          title = "Wartungsvertrag",
        )
      }
      div("my-2") {
        nullableFloatingInputField(
          valueAndSetter = password,
          fieldName = "password",
          title = "Anlagenpasswort",
        )
      }
      div("my-2") {
        floatingSelectNullable(
          valueAndSetter = einspeiseart,
          idProvider = { it?.name ?: "NONE" },
          formatter = { it?.name ?: "-" },
          fieldName = "einspeiseart",
          title = "Einspeiseart [${configuration.einspeiseart.format(plannerI18nConfiguration)}]",
          availableOptionsWithoutNull = Einspeiseart.entries,
        )
      }

      entryPreview("Installierte PV Leistung", "mb-0") {
        nullableFloatingDoubleInputField(
          valueAndSetter = pvLeistung,
          fieldName = "pvLeistung",
          title = configuration.moduleLayouts.totalPowerRating.formatKiloWattPeak(plannerI18nConfiguration),
        ) {
          attrs {
            mergedBelow()
          }
        }
      }
      entryPreview("Leerlaufspanning [Uoc]", "mt-0") {
        nullableFloatingDoubleInputField(
          valueAndSetter = leerlaufspannung,
          fieldName = "leerlaufspannung",
          title = configuration.moduleLayouts.totalOffLoadVoltage.format(plannerI18nConfiguration),
        ) {
          attrs {
            mergedAbove()
          }
        }
      }

      entryPreview("Messkonzept") {
        floatingSelectNullable(
          valueAndSetter = messkonzept,
          idProvider = { it ?: "NONE" },
          formatter = { it ?: "-" },
          fieldName = "messkonzept",
          title = "Messkonzept",
          availableOptionsWithoutNull = AssemblyPortfolio.messkonzepte,
        )
      }
      entryPreview("ÜSS DC Typ") {
        nullableFloatingInputField(
          valueAndSetter = ueberspannungsschutz,
          fieldName = "ueberspannungsschutz",
          title = configuration.ueberspannungsSchutzType,
        )
      }
      entryPreview("Einstellung Wechselrichter") {
        linkToGridAssessment(project, gridAssessment.einstellungWechselrichter.format())
      }
    }
  }
}

fun RBuilder.linkToGridAssessment(project: ResolvedProject, linkContent: String) {
  Link {
    attrs {
      toUrl = RouterUrls.project(project).gridAssessment
    }

    +linkContent
  }
}

external interface BasisdatenOverviewProps : Props {
  var project: ResolvedProject
  var currentConfiguration: QuoteConfiguration
}
