package it.neckar.financial.quote.flat

import it.neckar.commons.tags.Tags
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.financial.quote.Amount
import it.neckar.financial.quote.Optionality
import it.neckar.financial.quote.Visibility
import it.neckar.financial.quote.div
import it.neckar.open.kotlin.lang.WhitespaceConfig

/**
 * One line in a quote
 */
data class FlatQuoteLine(
  val indentation: FlatQuoteIndentation,
  val headline: String,
  val details: String?,
  val amount: Amount?,
  /**
   * The (actual) sum
   */
  val sum: PriceWithProfit,
  /**
   * Also contains the optional elements
   */
  val sumIncludingOptional: PriceWithProfit,
  /**
   * Is not set for sections
   */
  val optionality: Optionality,
  /**
   * The visibility
   */
  val visibility: Visibility,

  /**
   * Which aspects this line is relevant for.
   * Is null for sections
   */
  val relevance: Tags?,

  val valueAddedTax: List<ValueAddedTax>,

  /**
   * Returns true if this is a summed up position
   */
  val isSumPosition: Boolean,
) {

  val priceForOneElement: PriceWithProfit?
    get() = if (amount != null && amount.amount != 1.0) sumIncludingOptional / amount else null

  fun dump(): String {
    return buildString {
      append(("${indentation.format}: ").padEnd(7, ' '))
      append(headline.padEnd(40, ' '))

      priceForOneElement?.let {
        append(it.dump().padStart(25, ' '))
      } ?: append(" ".repeat(25))

      amount?.let {
        append(it.format(whitespaceConfig = WhitespaceConfig.Spaces).padStart(10, ' '))
      } ?: append(" ".repeat(10))



      when (optionality) {
        Optionality.Optional -> {
          append(("(${sumIncludingOptional.dump()})").padStart(25))
        }

        Optionality.Mandatory -> {
          append(sum.dump().padStart(25))
        }
      }
    }
  }

}
