package it.neckar.lizergy.model.configuration.moduleLayout.roof

import it.neckar.open.formatting.format
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.other.deg
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents a roof inclination
 */
@Serializable
@JvmInline
value class RoofInclination(val angle: @deg Int) {
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "${angle.format(i18nConfiguration, whitespaceConfig)}${whitespaceConfig.smallSpace}°"
  }
}
