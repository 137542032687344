package it.neckar.lizergy.model.price

import com.benasher44.uuid.Uuid
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.PriceWithProfitAndWorkingTime
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.uuid.HasUuid

/**
 * Returns the price for the given element
 */
fun <T> InfoForType<T, PriceWithProfit>.price(element: T): PriceWithProfit where T : HasUuid, T : HasLifeCycle {
  return price(element.uuid)
}

/**
 * Returns the price for the element with the given uuid
 */
fun <T> InfoForType<T, PriceWithProfit>.price(uuid: Uuid): PriceWithProfit where T : HasUuid, T : HasLifeCycle {
  return values[uuid] ?: throw IllegalArgumentException("No price found for <$uuid>")
}

fun <T> InfoForType<T, PriceWithProfitAndWorkingTime>.price(uuid: Uuid): PriceWithProfitAndWorkingTime where T : HasUuid, T : HasLifeCycle {
  return values[uuid] ?: throw IllegalArgumentException("No price found for <$uuid>")
}

fun <T> InfoForType<T, PriceWithProfit>.priceOrNull(element: T): PriceWithProfit? where T : HasUuid, T : HasLifeCycle {
  return priceOrNull(element.uuid)
}

fun <T> InfoForType<T, PriceWithProfit>.priceOrNull(uuid: Uuid): PriceWithProfit? where T : HasUuid, T : HasLifeCycle {
  return values[uuid]
}
