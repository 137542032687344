@file:UseSerializers(UuidSerializer::class)

package serialized

import com.meistercharts.charts.lizergy.roofPlanning.ModuleOrientation
import com.meistercharts.charts.lizergy.roofPlanning.ModuleSize
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel
import com.meistercharts.model.Insets
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Size
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule.PvModuleId
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.planning.ModuleAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.UnusableAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.toRestApiModel
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile.RoofTileId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.open.kotlin.lang.ifBlank
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.open.unit.si.mm
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 *  Represents a module layout
 */
@Serializable
data class SerializedModuleLayout(
  override val id: ModuleLayoutId,

  /**
   * The description
   */
  override val description: String? = null,

  /**
   * The UUID of the type of used modules
   */
  override val moduleTypeId: PvModuleId,

  @Deprecated("No longer used or needed")
  val blackModules: Boolean = false,

  override val roof: SerializedRoof,

  /**
   * The suggested roof insets - when placing the modules
   */
  override val suggestedRoofInsets: @mm Insets,
  /**
   * Contains information about the grid (some/most/all) of the modules are placed in
   */
  override val moduleAreasInformation: ModuleAreasInformation,
  /**
   * The unusable areas information
   */
  override val unusableAreasInformation: UnusableAreasInformation,

  /**
   * Marks if this [SerializedModuleLayout] can be used on [SerializedPhotovoltaicsConfiguration]s
   * Instead of deleting, mark this value as Deleted
   * When listing [SerializedModuleLayout]s, consider this value
   */
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : ModuleLayout, HasLifeCycle {

  /**
   * The size of the modules used in this layout
   */
  fun moduleSize(productResolver: ProductResolver): @mm ModuleSize {
    return productResolver[moduleTypeId].size
  }

  /**
   * Automatically generate a name for a moduleLayout of none is set
   */
  fun layoutName(productResolver: ProductResolver): String {
    return description.ifBlank {
      "${roof.roofName} ${peakPower(productResolver).formatKiloWattPeak()}"
    }
  }

  /**
   * The total power rating for this layout
   * (depends on the number of modules and the power rating of the module)
   */
  fun peakPower(productResolver: ProductResolver): PowerRating {
    return productResolver[moduleTypeId].powerRating * modulesCount
  }

  fun actualPowerRating(productResolver: ProductResolver): PowerRating? {
    return roof.performanceFactor?.percentage?.let { peakPower(productResolver) * it }
  }

  /**
   * Applies the settings from this module plan to the given ui model
   */
  fun applyToMutableModel(targetModel: PvRoofPlanningModel, roofSize: Size, moduleSize: ModuleSize) {
    targetModel.roofSize = roofSize
    targetModel.modulesSize = moduleSize
    targetModel.suggestedRoofInsets = suggestedRoofInsets

    targetModel.unusableAreas.set(unusableAreasInformation.toUnusableAreas())
    targetModel.moduleAreas.set(moduleAreasInformation.toModuleAreas(targetModel.unusableAreas, targetModel.modulesSize))
  }

  fun applyToMutableModel(targetModel: PvRoofPlanningModel, roofSize: Size, productResolver: ProductResolver) {
    applyToMutableModel(targetModel, roofSize, moduleSize(productResolver))
  }

  /**
   * Returns the module type using the given [ProductResolver]
   */
  fun moduleType(productResolver: ProductResolver): PvModule {
    return productResolver[moduleTypeId]
  }

  fun resolve(productResolver: ProductResolver): ResolvedModuleLayout {
    return ResolvedModuleLayout(
      id = id,
      description = description,
      moduleType = moduleType(productResolver),
      blackModules = blackModules,
      roof = roof.resolve(productResolver),
      suggestedRoofInsets = suggestedRoofInsets,
      moduleAreasInformation = moduleAreasInformation,
      unusableAreasInformation = unusableAreasInformation,
      lifeCycleState = lifeCycleState,
    )
  }

  fun duplicate(): SerializedModuleLayout {
    return copy(
      id = ModuleLayoutId.random(),
      description = description.nullIfBlank(),
      roof = roof.duplicate(),
    )
  }


  companion object {
    /**
     * Creates the default module layout for the given roof
     */
    fun createDefault(
      id: ModuleLayoutId = ModuleLayoutId.random(),
      moduleType: PvModule,
      roof: SerializedRoof,
    ): SerializedModuleLayout {

      val mutableModel = PvRoofPlanningModel()

      mutableModel.addModuleArea(
        modulesSize = moduleType.size,
        location = Coordinates(200.0, 200.0),
        size = roof.size?.minus(400.0, 400.0) ?: Size.none,
        orientation = ModuleOrientation.Vertical,
        gap = 20.0,
      )

      val moduleLayout = SerializedModuleLayout(
        id = id,
        moduleTypeId = moduleType.id,
        suggestedRoofInsets = Insets(100.0, 100.0, 100.0, 100.0),
        moduleAreasInformation = mutableModel.moduleAreas.toRestApiModel(),
        unusableAreasInformation = UnusableAreasInformation(emptyList()),
        roof = roof,
      )

      //moduleLayout.applyToMutableModel(mutableModel, roof.size, moduleType.size)

      return moduleLayout
    }

    fun createDefault(
      id: ModuleLayoutId = ModuleLayoutId.random(),
      moduleType: PvModule,
      roofType: RoofType,
      roofTileId: RoofTileId? = null,
    ): SerializedModuleLayout {

      val roof = SerializedRoof.createDefault(
        roofType = roofType,
        roofTileId = roofTileId,
      )

      return createDefault(
        id = id,
        moduleType = moduleType,
        roof = roof,
      )
    }
  }

}


fun ResolvedModuleLayout.unResolve(): SerializedModuleLayout {
  return SerializedModuleLayout(
    id = id,
    description = description,
    moduleTypeId = moduleType.id,
    roof = roof.unResolve(),
    suggestedRoofInsets = suggestedRoofInsets,
    moduleAreasInformation = moduleAreasInformation,
    unusableAreasInformation = unusableAreasInformation,
    lifeCycleState = lifeCycleState,
  )
}
