package it.neckar.customer

import kotlinx.serialization.Serializable

/**
 * The address
 */
@Serializable
data class Address(
  val street: String,
  val houseNumber: String,
  val zipCode: String,
  val city: String,
  /**
   * The country
   */
  val country: String = "Germany",
) {

  /**
   * Returns true if all properties are set
   */
  val isPlausible: Boolean
    get() = street.isNotBlank()
      && houseNumber.isNotBlank()
      && zipCode.isNotBlank()
      && city.isNotBlank()
      && country.isNotBlank()

  /**
   * Returns the street with house number
   */
  val streetWithHouseNumber: String
    get() = "$street $houseNumber"

  val cityWithZipCode: String
    get() = "$zipCode $city"

  /**
   * Returns the address as single line (without the country)
   */
  fun singleLine(): String {
    return "$street $houseNumber, $zipCode $city".trim(' ', ',')
  }

  /**
   * Returns true if all elements are empty
   */
  fun isEmpty(): Boolean {
    return street.isEmpty() && houseNumber.isEmpty() && zipCode.isEmpty() && city.isEmpty()
  }

  companion object {
    /**
     * Represents an empty address in Germany
     */
    val empty: Address = Address("", "", "", "", "Germany")

    val randomAddresses: List<Address> = listOf(
      Address(" Holstenwall", "85", "06548", "Rottleberode", "Germany"),
      Address(" Albrechtstrasse", "30", "86305", "Friedberg", "Germany"),
      Address(" Gotthardstrasse", "42", "09112", "Chemnitz", "Germany")
    )

    val demo: Address = Address("Musterstraße", "99", "77777", "Musterstadt", "Germany")

    val Lizergy: Address = Address("Jaspisstraße", "13", "72250", "Freudenstadt", "Germany")

    val Schnitzer: Address = Address("Sebastiansweilerstrasse", "13", "72116", "Mössingen-Belsen", "Germany")

    val testCompanyAddress: Address = Address("Teststraße", "99", "77777", "Test-Stadt", "Germany")

    val NeckarIT: Address = Address("Ulrichstraße", "25", "72116", "Mössingen", "Germany")
  }
}
