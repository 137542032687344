package components.project.configuration.additional

import components.form.commentSection
import components.project.formButtons
import components.project.freezableFormButtons
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.ExistingBHKWFacilityConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.unit.si.kW
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import kotlinx.browser.window
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useExistingBHKWFacilityIdFromUrl
import router.useLoadResolvedProjectFromUrl
import services.UiActions

val EditBlueprintExistingBHKWFacilityFromUrl: FC<Props> = fc("EditBlueprintExistingBHKWFacilityFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val existingBHKWFacilityId = useExistingBHKWFacilityIdFromUrl()

  busyIfNull(project) {
    val existingFacilityConfiguration = it.blueprint.existingFacilitiesConfiguration[existingBHKWFacilityId]

    EditBlueprintExistingBHKWFacility {
      attrs {
        this.project = it
        this.existingFacilityConfiguration = existingFacilityConfiguration
      }
    }
  }
}

val EditConfigurationExistingBHKWFacilityFromUrl: FC<Props> = fc("EditConfigurationExistingBHKWFacilityFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val existingBHKWFacilityId = useExistingBHKWFacilityIdFromUrl()

  busyIfNull(project) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val existingFacilityConfiguration = quoteConfiguration.existingFacilitiesConfiguration[existingBHKWFacilityId]

    EditConfigurationExistingBHKWFacility {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.existingFacilityConfiguration = existingFacilityConfiguration
        this.editableStatus = loadedProject.isProjectEditable()
      }
    }
  }
}


val EditBlueprintExistingBHKWFacility: FC<EditBlueprintExistingBHKWFacilityProps> = fc("EditBlueprintExistingBHKWFacility") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val blueprint = project::blueprint.safeGet()
  val existingFacilityConfiguration = props::existingFacilityConfiguration.safeGet()

  val editableStatus = project.isProjectEditable()

  val existingFacilityConfigurationToSave = useState(existingFacilityConfiguration)

  val blueprintToSave = useMemo(blueprint, existingFacilityConfigurationToSave.value) {
    blueprint.copy(
      existingFacilitiesConfiguration = blueprint.existingFacilitiesConfiguration.withReplaced(existingFacilityConfigurationToSave.value),
    )
  }

  val okAction = {
    UiActions.saveBlueprint(blueprintToSave)
    navigate.back()
  }


  div {
    onEnter(okAction)

    EditExistingBHKWFacility {
      attrs {
        this.existingFacilityConfigurationToSave = existingFacilityConfigurationToSave
        this.editableStatus = editableStatus
      }
    }
  }

  formButtons(okAction)
}

val EditConfigurationExistingBHKWFacility: FC<EditConfigurationExistingBHKWFacilityProps> = fc("EditConfigurationExistingBHKWFacility") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val existingFacilityConfiguration = props::existingFacilityConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val existingFacilityConfigurationToSave = useState(existingFacilityConfiguration)

  val configurationToSave = useMemo(quoteConfiguration, existingFacilityConfigurationToSave.value) {
    quoteConfiguration.copy(
      existingFacilitiesConfiguration = quoteConfiguration.existingFacilitiesConfiguration.withReplaced(existingFacilityConfigurationToSave.value),
    )
  }

  val okAction = {
    UiActions.saveQuoteConfiguration(project, configurationToSave)
    navigate.back()
  }


  div {
    if (editableStatus == EditableStatus.Editable) {
      onEnter(okAction)
    }

    EditExistingBHKWFacility {
      attrs {
        this.existingFacilityConfigurationToSave = existingFacilityConfigurationToSave
        this.editableStatus = editableStatus
      }
    }
  }

  freezableFormButtons(editableStatus, okAction)
}


val EditExistingBHKWFacility: FC<EditExistingBHKWFacilityProps> = fc("EditExistingBHKWFacility") { props ->
  useEffectOnce {
    window.scrollTo(0.0, 1000.0)
  }

  val existingFacilityConfigurationToSave = props::existingFacilityConfigurationToSave.getNotNull()
  val editableStatus = props::editableStatus.safeGet()

  val originalExistingFacilityConfiguration = existingFacilityConfigurationToSave.value
  val zaehlernummerToSave = useState(originalExistingFacilityConfiguration.zaehlernummer)
  @kW val powerToSave = useState(originalExistingFacilityConfiguration.power)
  val einspeiseartToSave = useState(originalExistingFacilityConfiguration.einspeiseart)


  useMemo(
    zaehlernummerToSave.value,
    powerToSave.value,
    einspeiseartToSave.value,
  ) {
    existingFacilityConfigurationToSave.setter(
      originalExistingFacilityConfiguration.copy(
        zaehlernummer = zaehlernummerToSave.value,
        power = powerToSave.value,
        einspeiseart = einspeiseartToSave.value,
      )
    )
  }


  div("row") {

    h2("mb-3") { +"Bestandsanlage BHKW" }

    div("col-md-6") {

      div("my-3") {

        floatingInputField(
          valueAndSetter = zaehlernummerToSave,
          fieldName = "zaehlernummerBHKW",
          title = "Zählernummer BHKW",
          editableStatus = editableStatus,
        ) {
          attrs {
            mergedBelow()
          }
        }

        floatingDoubleInputField(
          valueAndSetter = powerToSave,
          fieldName = "powerBHKW",
          title = "Elektrische Leistung in kW",
          editableStatus = editableStatus,
        ) {
          attrs {
            mergedAbove()
          }
        }

      }

      div("my-3") {
        einspeiseArtForm(einspeiseartToSave, editableStatus)
      }

    }

    div("col-md-6") {
      commentSection(originalExistingFacilityConfiguration)
    }

  }

}


external interface EditBlueprintExistingBHKWFacilityProps : Props {
  var project: ResolvedProject
  var existingFacilityConfiguration: ExistingBHKWFacilityConfiguration
}

external interface EditConfigurationExistingBHKWFacilityProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
  var existingFacilityConfiguration: ExistingBHKWFacilityConfiguration
  var editableStatus: EditableStatus
}

external interface EditExistingBHKWFacilityProps : Props {
  var existingFacilityConfigurationToSave: StateInstance<ExistingBHKWFacilityConfiguration>
  var editableStatus: EditableStatus
}
