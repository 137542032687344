package it.neckar.lizergy.model

import it.neckar.open.formatting.format
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import kotlinx.serialization.Serializable


/**
 * Selection that allows the selection of multiple types.
 * For each type there can be several elements selected
 */
interface ElementsSelection<T> {

  /**
   * The entries
   */
  val entries: List<ElementsSelectionEntry<T>>

  /**
   * Formats the element
   */
  fun T.format(): String

  /**
   * Returns the entries which are *not* empty
   */
  val entriesNonEmpty: List<ElementsSelectionEntry<T>>
    get() = entries.filter {
      it.amount != 0
    }

  /**
   * The number of entries (*not* the total of selected items)
   */
  val entriesNonEmptyCount: Int
    get() = entriesNonEmpty.size

  /**
   * The number of total selected items
   */
  val entriesNonEmptyAmount: Int
    get() = entriesNonEmpty.sumOf {
      it.amount
    }

  /**
   * Returns the different types (without the number)
   */
  val types: Set<T>
    get() = entries.map {
      it.element
    }.toSet()


  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, entrySeparator: String = ", ", whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    if (entries.isEmpty()) {
      return "-"
    }

    return entriesNonEmpty.joinToString(entrySeparator) {
      "${it.amount.format(i18nConfiguration, whitespaceConfig)} × ${it.element.format()}"
    }
  }

  fun isEmpty(): Boolean {
    return entries.isEmpty() || entries.all {
      it.amount == 0
    }
  }

  fun isNotEmpty(): Boolean {
    return !isEmpty()
  }

  /**
   * Returns the selected number for the given type
   */
  operator fun get(typeId: T): Int {
    //TODO performance!
    return entries.firstOrNull {
      it.element == typeId
    }?.amount ?: 0
  }

  companion object {
    /**
     * Returns an empty selection
     */
    fun <T> empty(): ElementsSelection<T> = object : ElementsSelection<T> {
      override val entries: List<ElementsSelectionEntry<T>>
        get() = emptyList()

      override fun T.format(): String {
        throw UnsupportedOperationException("must not be called for empty selection")
      }
    }
  }

}

/**
 * One entry of the selection. Contains *one* type and the amount for that type
 */
@Serializable
data class ElementsSelectionEntry<out T>(
  val element: T,
  val amount: Int,
)
