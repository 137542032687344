package components.company.pricelist.tables

import io.ktor.util.*
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.kotlin.lang.ifNull
import kotlinx.html.ThScope
import react.*
import react.dom.*
import store.hooks.useSelectPriceList
import style.toStyleClass

val MetalRoofTilesPriceTable: FC<Props> = fc("MetalRoofTilesPriceTable") {
  val priceList = useSelectPriceList()

  val metalRoofTilePrices = priceList.metalRoofTilePrices
  val aufschlagColoredMetalPlatesPrices = priceList.aufschlagColoredMetalPlatesPrices


  div("col") {
    h2 {
      +"Metalldachziegel"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
          th(ThScope.col, "text-center") {
            +"In Gebrauch"
          }
        }
      }

      tbody {

        aufschlagColoredMetalPlatesPrices.available.forEachIndexed { metalRoofTileColorIndex, metalRoofTileColor ->
          tr {
            key = metalRoofTileColor.uuid.toString()

            th(ThScope.row) {
              +(metalRoofTileColorIndex + 1).toString()
            }
            td {
              +"Aufpreis ${metalRoofTileColor.name.toLowerCasePreservingASCIIRules()}e Metalldachplatten pro Stück"
            }

            td("text-end") {
              +aufschlagColoredMetalPlatesPrices[metalRoofTileColor].inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +aufschlagColoredMetalPlatesPrices[metalRoofTileColor].sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +aufschlagColoredMetalPlatesPrices[metalRoofTileColor].profit.format().ifNull("-")
            }

            td("text-center") {
            }
          }
        }

        metalRoofTilePrices.available.fastForEachIndexed { metalRoofTilesTypeIndex, metalRoofTilesType ->
          tr(classes = metalRoofTilesType.lifeCycleState.toStyleClass()) {
            key = metalRoofTilesType.uuid.toString()

            th(ThScope.row) {
              +(metalRoofTilesTypeIndex + aufschlagColoredMetalPlatesPrices.available.size + 1).toString()
            }
            td {
              +metalRoofTilesType.description
            }

            td("text-end") {
              +metalRoofTilePrices[metalRoofTilesType.uuid].inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +metalRoofTilePrices[metalRoofTilesType.uuid].sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +metalRoofTilePrices[metalRoofTilesType.uuid].profit.format().ifNull("-")
            }

            td("text-center") {
              +metalRoofTilesType.lifeCycleState.format()
            }
          }
        }

      }
    }
  }
}
