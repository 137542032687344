package components.project.dashboard

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyBasementProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintAcquisitionProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProjectProcessState
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.open.formatting.dateFormat
import it.neckar.open.time.nowMillis
import it.neckar.processStates.ProcessStatesResolver
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleCheck
import it.neckar.react.common.FontAwesome.faEye
import kotlinx.css.*
import kotlinx.html.ButtonType
import kotlinx.html.classes
import kotlinx.html.id
import kotlinx.html.title
import plannerI18nConfiguration
import react.*
import react.dom.*
import react.router.*
import react.router.dom.*
import router.RouterUrls
import router.encodeForUrl
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUserResolver
import styled.*

enum class ProjectPhase {
  Vertrieb,
  Arbeitsvorbereitung,
  Montage,
  Projektabschluss,
}

val ProcessStatesOverview: FC<ProcessStatesOverviewProps> = fc("ProcessStatesOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val location = useLocation()

  val project = props::project.safeGet()
  val currentProjectPhase = props::currentProjectPhase.safeGet()
  val processStatesResolver = props::processStatesResolver.safeGet()

  val currentQuoteConfiguration = project.getCurrentQuoteConfiguration(processStatesResolver)

  val processStateEntries = project.containedUuids.filter { it != project.uuid }.mapNotNull { (processStatesResolver.getOrNull(it)?.processStateEntries as List<LizergyProcessStateEntry>?)?.current() }
  val mostAdvancedProcessStateEntry = processStateEntries.maxOrNull() ?: project.processState?.currentValue?.toNewProjectProcessState()?.toProcessStateEntry(
    user = userResolver[project.maintainer],
    dueDate = null,
    assignedAt = nowMillis(),
    assignedBy = loggedInUser,
  )
  val relevantProjectPhase = currentProjectPhase ?: mostAdvancedProcessStateEntry.toProjectPhase()


  button(classes = "btn btn-primary btn-sm", type = ButtonType.button) {
    attrs["data-bs-toggle"] = "collapse"
    attrs["data-bs-target"] = ".multi-collapse"
    attrs["aria-expanded"] = "false"
    attrs["aria-controls"] = buildList {
      if (relevantProjectPhase != ProjectPhase.Vertrieb) add("vertriebCollapse")
      if (relevantProjectPhase != ProjectPhase.Arbeitsvorbereitung) add("arbeitsvorbereitungCollapse")
      if (relevantProjectPhase != ProjectPhase.Montage) add("montageCollapse")
      if (relevantProjectPhase != ProjectPhase.Projektabschluss) add("projektabschlussCollapse")
    }.joinToString(" ")

    span("pe-2") { faEye() }
    +"Vollständigen Verlauf anzeigen"
  }

  div {

    div {
      attrs {
        id = "vertriebCollapse"
        addClassIf("collapse multi-collapse") { relevantProjectPhase != ProjectPhase.Vertrieb }
      }

      h3 {
        +"Vertrieb"
      }
      div("horizontal-timeline my-5") {
        ul("list-inline items") {

          styledLi {
            attrs {
              classes = setOf("list-inline-item", "items-list")
            }
            css {
              width = 20.pct
            }

            div("event-date fw-bold") {
              NavLink {
                attrs {
                  to = RouterUrls.project(project).index.value

                  if (location.pathname.endsWith(project.projectId.encodeForUrl()) || location.pathname.endsWith("index")) {
                    addClass("btn")
                    addClass("btn-secondary")
                  }
                }

                +"Kundenerfassung"
              }
            }

            p("text-primary") {
              attrs {
                this.title = "seit ${dateFormat.format(project.acquisitionDate, plannerI18nConfiguration)} [${userResolver[project.maintainer].editorName}]"
              }

              faCircleCheck()
            }
          }

          timelineItem("Projekterfassung", project.blueprint, RouterUrls.project(project).blueprint.index.value, 5, mostAdvancedProcessStateEntry)
          timelineItem("Bearbeitung", currentQuoteConfiguration, RouterUrls.project(project).configurations.value, 5, mostAdvancedProcessStateEntry) { it is ConfigurationProcessStateEntry || it is PresentationProcessStateEntry }

          timelineItem(
            title = "Angebot",
            component = currentQuoteConfiguration,
            linkDestination = RouterUrls.project(project).configurationUrl(currentQuoteConfiguration).value,
            itemCount = 5,
            mostAdvancedProcessStateEntry = mostAdvancedProcessStateEntry,
          ) { it is QuoteOfferProcessStateEntry }

          timelineItem(
            title = "Auftragsbestätigung",
            component = currentQuoteConfiguration,
            linkDestination = RouterUrls.project(project).configurationUrl(currentQuoteConfiguration).value,
            itemCount = 5,
            mostAdvancedProcessStateEntry = mostAdvancedProcessStateEntry,
          ) { it is QuoteConfirmationProcessStateEntry }

        }
      }
    }

    div {
      attrs {
        id = "arbeitsvorbereitungCollapse"
        addClassIf("collapse multi-collapse") { relevantProjectPhase != ProjectPhase.Arbeitsvorbereitung }
      }

      h3 {
        +"Arbeitsvorbereitung"
      }
      div("horizontal-timeline my-5") {
        ul("list-inline items") {
          timelineItem("Netzvoranfrage", project.gridAssessment, RouterUrls.project(project).gridAssessment.value, 4, mostAdvancedProcessStateEntry)
          timelineItem("Montagemappe erstellen", project.assemblyPortfolio, RouterUrls.project(project).assemblyPortfolio.value, 4, mostAdvancedProcessStateEntry)
          timelineItem(
            title = "Montagetermin Dach einplanen",
            component = project.assemblyRoof,
            linkDestination = RouterUrls.project(project).scheduleAssembly.value,
            itemCount = 4,
            mostAdvancedProcessStateEntry = mostAdvancedProcessStateEntry,
          ) { it is AssemblyRoofPreparationProcessStateEntry && it.processState == AssemblyRoofPreparationProcessStates.Missing }
          timelineItem("Anzahlungsrechnung", project.advanceInvoice, RouterUrls.project(project).advanceInvoice.value, 4, mostAdvancedProcessStateEntry)
        }
      }
      div("horizontal-timeline my-5") {
        ul("list-inline items") {
          timelineItem("Sondermaterial Bestellung", project.orderSpecialMaterial, RouterUrls.project(project).orderSpecialMaterial.value, 4, mostAdvancedProcessStateEntry)
          emptyTimelineItem(4)
          timelineItem(
            title = "Montagetermin Keller einplanen",
            component = project.assemblyBasement,
            linkDestination = RouterUrls.project(project).scheduleAssembly.value,
            itemCount = 4,
            mostAdvancedProcessStateEntry = mostAdvancedProcessStateEntry,
          ) { it is AssemblyBasementPreparationProcessStateEntry && it.processState == AssemblyBasementPreparationProcessStates.Missing }
        }
      }
    }

    div {
      attrs {
        id = "montageCollapse"
        addClassIf("collapse multi-collapse") { relevantProjectPhase != ProjectPhase.Montage }
      }

      h3 {
        +"Montage"
      }
      div("horizontal-timeline my-5") {
        ul("list-inline items") {
          timelineItem(
            title = "Montage Dach Vorbereitung",
            component = project.assemblyRoof,
            linkDestination = RouterUrls.project(project).prepareAssembly.value,
            itemCount = 4,
            mostAdvancedProcessStateEntry = mostAdvancedProcessStateEntry,
          ) { it is AssemblyRoofPreparationProcessStateEntry && it.processState != AssemblyRoofPreparationProcessStates.Missing }
          timelineItem("Montage Dach", project.assemblyRoof, RouterUrls.project(project).assemblyRoof.value, 4, mostAdvancedProcessStateEntry) { it is AssemblyRoofProcessStateEntry }
          timelineItem("Zählertausch", project.switchMeterBox, RouterUrls.project(project).switchMeterBox.value, 4, mostAdvancedProcessStateEntry)
          timelineItem("Fertigmeldung", project.finishingUp, RouterUrls.project(project).finishingUp.value, 4, mostAdvancedProcessStateEntry)
        }
      }
      div("horizontal-timeline my-5") {
        ul("list-inline items") {
          timelineItem(
            title = "Montage Keller Vorbereitung",
            component = project.assemblyBasement,
            linkDestination = RouterUrls.project(project).prepareAssembly.value,
            itemCount = 4,
            mostAdvancedProcessStateEntry = mostAdvancedProcessStateEntry
          ) { it is AssemblyBasementPreparationProcessStateEntry && it.processState != AssemblyBasementPreparationProcessStates.Missing }
          timelineItem("Montage Keller", project.assemblyBasement, RouterUrls.project(project).assemblyBasement.value, 4, mostAdvancedProcessStateEntry) { it is AssemblyBasementProcessStateEntry }
          timelineItem("Inbetriebnahme", project.startupOperations, RouterUrls.project(project).startupOperations.value, 4, mostAdvancedProcessStateEntry)
        }
      }
    }

    div {
      attrs {
        id = "projektabschlussCollapse"
        addClassIf("collapse multi-collapse") { relevantProjectPhase != ProjectPhase.Projektabschluss }
      }

      h3 {
        +"Projektabschluss"
      }
      div("horizontal-timeline my-5") {
        ul("list-inline items") {
          timelineItem("Abschlussrechnung", project.finalAccount, RouterUrls.project(project).finalAccount.value, 2, mostAdvancedProcessStateEntry)
          timelineItem("Dokumentation", project.documentation, RouterUrls.project(project).documentation.value, 2, mostAdvancedProcessStateEntry)
        }
      }
    }

  }
}


private fun LizergyProcessStateEntry?.toProjectPhase(): ProjectPhase {
  return when (this) {
    is AdvanceInvoiceProcessStateEntry -> ProjectPhase.Arbeitsvorbereitung
    is AssemblyBasementPreparationProcessStateEntry -> if (this.processState == AssemblyBasementPreparationProcessStates.Missing) ProjectPhase.Arbeitsvorbereitung else ProjectPhase.Montage
    is AssemblyBasementProcessStateEntry -> ProjectPhase.Montage
    is AssemblyPortfolioProcessStateEntry -> ProjectPhase.Arbeitsvorbereitung
    is AssemblyRoofPreparationProcessStateEntry -> if (this.processState == AssemblyRoofPreparationProcessStates.Missing) ProjectPhase.Arbeitsvorbereitung else ProjectPhase.Montage
    is AssemblyRoofProcessStateEntry -> ProjectPhase.Montage
    is BlueprintAcquisitionProcessStateEntry -> ProjectPhase.Vertrieb
    is BlueprintProcessStateEntry -> ProjectPhase.Vertrieb
    is ConfigurationProcessStateEntry -> ProjectPhase.Vertrieb
    is DocumentationProcessStateEntry -> ProjectPhase.Projektabschluss
    is FinalAccountProcessStateEntry -> ProjectPhase.Projektabschluss
    is FinishingUpProcessStateEntry -> ProjectPhase.Montage
    is GridAssessmentProcessStateEntry -> ProjectPhase.Arbeitsvorbereitung
    is OrderSpecialMaterialProcessStateEntry -> ProjectPhase.Arbeitsvorbereitung
    is PresentationProcessStateEntry -> ProjectPhase.Vertrieb
    is ProjectProcessStateEntry -> ProjectPhase.Vertrieb
    is QuoteConfirmationProcessStateEntry -> ProjectPhase.Vertrieb
    is QuoteOfferProcessStateEntry -> ProjectPhase.Vertrieb
    is StartupOperationsProcessStateEntry -> ProjectPhase.Montage
    is SwitchMeterBoxProcessStateEntry -> ProjectPhase.Montage
    null -> ProjectPhase.Vertrieb
  }
}


external interface ProcessStatesOverviewProps : Props {
  var project: ResolvedProject
  var currentProjectPhase: ProjectPhase?
  var processStatesResolver: ProcessStatesResolver
}
