package it.neckar.lizergy.model.configuration.energy.selfsufficiency

import it.neckar.lizergy.model.configuration.energy.AmountOfEnergy
import it.neckar.lizergy.model.configuration.energy.BatteryCapacity
import it.neckar.lizergy.model.configuration.energy.PerformanceFactor
import it.neckar.lizergy.model.configuration.energy.SpecificAnnualProduction
import it.neckar.lizergy.model.configuration.energy.div
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ElectricityDistributionTable.PeakMap
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ElectricityDistributionTable.PeakPerformance
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ElectricityDistributionTable.Row
import it.neckar.open.kotlin.lang.interpolate
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.kotlin.lang.relativeDistanceBetween
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.kWh
import it.neckar.open.unit.time.pa
import kotlin.jvm.JvmInline

/**
 * Calculates the distribution of generated energy and the degree of self-sufficiency
 */
interface SelfSufficiencyCalculator {
  /**
   * Returns the electricity distribution of **produced electricity** for the given PowerRating, BatteryCapacity, and AnnualElectricityConsumption
   *
   * @return null if no valid values could be calculated
   */
  fun calculatePowerProductionDistribution(
    /**
     * Total PowerRating of the system
     */
    totalAnnualProduction: @kWh AmountOfEnergy,
    /**
     * Total BatteryCapacity of the system
     */
    batteryCapacity: @pa BatteryCapacity,
    /**
     * Total power consumption of the system's house
     */
    annualElectricityConsumption: @pa AmountOfEnergy,
  ): CalculatedPowerProductionDistribution?

  /**
   * Returns the electricity distribution of **used electricity** for the given PowerRating, BatteryCapacity, and AnnualElectricityConsumption
   *
   * @return null if no valid values could be calculated
   */
  fun calculatePowerConsumptionDistribution(
    /**
     * Total PowerRating of the system
     */
    totalAnnualProduction: @kWh AmountOfEnergy,
    /**
     * Total BatteryCapacity of the system
     */
    batteryCapacity: @pa BatteryCapacity,
    /**
     * Total power consumption of the system's house
     */
    annualElectricityConsumption: @pa AmountOfEnergy,
  ): CalculatedPowerConsumptionDistribution?
}


object SimpleSelfSufficiencyCalculator : SelfSufficiencyCalculator {
  override fun calculatePowerProductionDistribution(
    totalAnnualProduction: @kWh AmountOfEnergy,
    batteryCapacity: @pa BatteryCapacity,
    annualElectricityConsumption: @pa AmountOfEnergy,
  ): CalculatedPowerProductionDistribution? {
    val electricityDistribution = privateConsumptionPercentageTable.calculateSimpleElectricityDistribution(
      totalAnnualProduction = totalAnnualProduction,
      batteryCapacity = batteryCapacity,
      electricityConsumption = annualElectricityConsumption,
    ) ?: return null
    return CalculatedPowerProductionDistribution(electricityDistribution.directPercentage, electricityDistribution.batteryPercentage)
  }

  override fun calculatePowerConsumptionDistribution(
    totalAnnualProduction: @kWh AmountOfEnergy,
    batteryCapacity: @pa BatteryCapacity,
    annualElectricityConsumption: @pa AmountOfEnergy,
  ): CalculatedPowerConsumptionDistribution? {
    val electricityDistribution = selfSufficiencyPercentageTable.calculateSimpleElectricityDistribution(
      totalAnnualProduction = totalAnnualProduction,
      batteryCapacity = batteryCapacity,
      electricityConsumption = annualElectricityConsumption,
    ) ?: return null
    return CalculatedPowerConsumptionDistribution(electricityDistribution.directPercentage, electricityDistribution.batteryPercentage)
  }
}


object InterpolatedSelfSufficiencyCalculator : SelfSufficiencyCalculator {
  @Deprecated("Does not match calculatePowerConsumptionDistribution in all cases, Use calculatePowerConsumptionDistribution instead")
  override fun calculatePowerProductionDistribution(
    totalAnnualProduction: @kWh AmountOfEnergy,
    batteryCapacity: @pa BatteryCapacity,
    annualElectricityConsumption: @pa AmountOfEnergy,
  ): CalculatedPowerProductionDistribution? {
    val electricityDistribution = privateConsumptionPercentageTable.calculateInterpolatedElectricityDistribution(
      totalAnnualProduction = totalAnnualProduction,
      batteryCapacity = batteryCapacity,
      electricityConsumption = annualElectricityConsumption,
    ) ?: return null
    return CalculatedPowerProductionDistribution(electricityDistribution.directPercentage, electricityDistribution.batteryPercentage)
  }

  override fun calculatePowerConsumptionDistribution(
    totalAnnualProduction: @kWh AmountOfEnergy,
    batteryCapacity: @pa BatteryCapacity,
    annualElectricityConsumption: @pa AmountOfEnergy,
  ): CalculatedPowerConsumptionDistribution? {
    val electricityDistribution = selfSufficiencyPercentageTable.calculateInterpolatedElectricityDistribution(
      totalAnnualProduction = totalAnnualProduction,
      batteryCapacity = batteryCapacity,
      electricityConsumption = annualElectricityConsumption,
    ) ?: return null
    return CalculatedPowerConsumptionDistribution(electricityDistribution.directPercentage, electricityDistribution.batteryPercentage)
  }
}


/**
 * Percentages indexed by (PowerRating / AnnualElectricityConsumption * 100) and (BatteryCapacity / AnnualElectricityConsumption * 100) inside a table
 *
 * Findings for private consumption:
 *  - When maximizing for private consumption percentage:
 *    - Above a relativeKiloWattPeak of about 0.2, more than 50% of the produced electricity will be sold to the grid
 *        even with a battery; without a battery, the percentage is even under 20%
 *    - Above a relativeBatteryCapacity of 0.2, returns diminish to practically 0
 *
 * Findings for self-sufficiency:
 *  - Without a battery, the degree of self-sufficiency caps at around 44%
 *  - With a battery, the degree of self-sufficiency can reach almost 100%, though as diminishing returns are reached, it gets increasingly expensive
 *  - When maximizing for degree self-sufficiency:
 *      - A relativeKiloWattPeak of 0.15 already achieves over 80% of the possible maximum
 *      - A relativeBatteryCapacity of 0.15 achieves well over 80% of the possible maximum
 *      - Increasing the BatteryCapacity, naturally, only makes sense given an already high relativeKiloWattPeak
 */
data class ElectricityDistributionTable(val rows: List<Row>) {
  /**
   * Calculates the distribution of generated energy and the degree of self-sufficiency
   * Uses a table, look up estimate values
   */
  fun calculateSimpleElectricityDistribution(
    totalAnnualProduction: @kWh AmountOfEnergy,
    batteryCapacity: @pa BatteryCapacity,
    electricityConsumption: @pa AmountOfEnergy,
  ): PowerDistribution? {
    require(totalAnnualProduction.kWh >= 0) { "Total annual production is ${totalAnnualProduction.kWh}, needs to be greater than or equal to 0" }
    require(batteryCapacity.capacity.kWh >= 0) { "Battery capacity is ${batteryCapacity.capacity.kWh}, needs to be greater than or equal to 0" }
    require(electricityConsumption.kWh >= 0) { "Annual electricity consumption is ${electricityConsumption.kWh}, needs to be greater than or equal to 0" }

    if (electricityConsumption.kWh == 0.0) return null

    /**
     * The data we use was measured in Berlin which has a SpecificAnnualProduction of 929
     * As we use this data to look up the corresponding degrees of self-sufficiency, we need to adjust our inputs
     * In this case, the only affected input is the PowerRating
     * We 'norm' said PowerRating and then apply the right SpecificAnnualProduction
     * This process simply linearly scales our values for the according SpecificAnnualProduction
     */
    val regionAdjustedPowerRating = totalAnnualProduction / SpecificAnnualProduction.Berlin

    // This is simply a mapping value for the table and does not in itself have scientific meaning
    val relativeKiloWattPeak = regionAdjustedPowerRating.kiloWattPeak / electricityConsumption.kWh * 100.0
    // This is simply a mapping value for the table and does not in itself have scientific meaning
    val relativeBatteryCapacity = batteryCapacity.capacity / electricityConsumption * 100.0

    val lowerBoundRow = findLowerBoundRow(relativeBatteryCapacity) ?: return null
    val lowerPerformanceBound = lowerBoundRow.findLowerPerformanceBound(relativeKiloWattPeak) ?: return null
    val totalConsumption = lowerPerformanceBound.performanceFactor.percentage

    val findLowerPerformanceBound = findRowWithoutBattery().findLowerPerformanceBound(relativeKiloWattPeak) ?: return null

    val directConsumption = findLowerPerformanceBound.performanceFactor.percentage
    return PowerDistribution(directConsumption, totalConsumption - directConsumption)
  }


  /**
   * Calculates the distribution of generated energy and the degree of self-sufficiency
   * Uses a table, look up estimate values and linearly interpolates between them
   */
  fun calculateInterpolatedElectricityDistribution(
    totalAnnualProduction: @kWh AmountOfEnergy,
    batteryCapacity: @pa BatteryCapacity,
    electricityConsumption: @pa AmountOfEnergy,
  ): PowerDistribution? {
    require(batteryCapacity.capacity.kWh >= 0) { "Battery capacity is ${batteryCapacity.capacity.kWh}, needs to be greater than or equal to 0" }
    require(electricityConsumption.kWh >= 0) { "Annual electricity consumption is ${electricityConsumption.kWh}, needs to be greater than or equal to 0" }

    if (electricityConsumption.kWh == 0.0) return null

    val regionAdjustedPowerRating = totalAnnualProduction / SpecificAnnualProduction.Berlin
    // This is simply a mapping value for the table and does not in itself have scientific meaning
    val relativeKiloWattPeak = regionAdjustedPowerRating.kiloWattPeak / electricityConsumption.kWh * 100.0
    // This is simply a mapping value for the table and does not in itself have scientific meaning
    val relativeBatteryCapacity = batteryCapacity.capacity / electricityConsumption * 100.0

    val upperBoundRow = findUpperBoundRow(relativeBatteryCapacity) ?: return null
    val lowerBoundRow = findLowerBoundRow(relativeBatteryCapacity) ?: return null
    val upperPerformanceFactor = upperBoundRow.findInterpolatedValue(relativeKiloWattPeak) ?: return null
    val lowerPerformanceFactor = lowerBoundRow.findInterpolatedValue(relativeKiloWattPeak) ?: return null

    val findLowerPerformanceBound = findRowWithoutBattery().findLowerPerformanceBound(relativeKiloWattPeak) ?: return null
    val directConsumption = findLowerPerformanceBound.performanceFactor.percentage

    if (upperBoundRow == lowerBoundRow) {
      val totalConsumption = upperPerformanceFactor.percentage
      return PowerDistribution(directConsumption, totalConsumption - directConsumption)
    }

    val relativeDistance = relativeBatteryCapacity.relativeDistanceBetween(
      upperBoundRow.relativeBatteryCapacity,
      lowerBoundRow.relativeBatteryCapacity
    )

    val interpolatedTotalConsumption = relativeDistance.interpolate(upperPerformanceFactor.percentage, lowerPerformanceFactor.percentage)
    return PowerDistribution(directConsumption, interpolatedTotalConsumption - directConsumption)
  }


  // The first row has all the values for relativeKiloWattPeaks without a battery
  private fun findRowWithoutBattery(): Row = rows.first().also { row -> require(row.relativeBatteryCapacity == 0.0) { "First row in rows does not have relativeBatteryCapacity 0.0 but ${row.relativeBatteryCapacity}" } }

  /**
   * Returns the lower bounding Row from the table for the given relativeBatteryCapacity
   * For calculateSimpleElectricityDistribution this is treated as the most accurate Row
   */
  private fun findLowerBoundRow(relativeBatteryCapacity: Double): Row? {
    return rows.findLast { row ->
      row.relativeBatteryCapacity <= relativeBatteryCapacity
    }
  }

  /**
   * Returns the upper bounding Row from the table for the given relativeBatteryCapacity
   */
  private fun findUpperBoundRow(relativeBatteryCapacity: Double): Row? {
    return rows.find { row ->
      row.relativeBatteryCapacity >= relativeBatteryCapacity
    }
  }


  /**
   * Contains an PeakMap with the corresponding PeakPerformances for one relativeBatteryCapacity
   */
  data class Row(
    val relativeBatteryCapacity: @pct Double,
    val peakMap: PeakMap
  ) {
    fun findLowerPerformanceBound(relativeKiloWattPeak: Double): PeakPerformance? {
      return peakMap.map.findLast { peakPerformance ->
        peakPerformance.relativeKiloWattPeak <= relativeKiloWattPeak
      }
    }

    fun findUpperPerformanceBound(relativeKiloWattPeak: Double): PeakPerformance {
      return peakMap.map.find { peakPerformance ->
        peakPerformance.relativeKiloWattPeak >= relativeKiloWattPeak
      } ?: peakMap.map.last()
    }

    /**
     * Returns a linearly interpolated factor for the relativeKiloWattPeak
     */
    fun findInterpolatedValue(relativeKiloWattPeak: Double): @pct PerformanceFactor? {
      val upperBound = findUpperPerformanceBound(relativeKiloWattPeak)
      val lowerBound = findLowerPerformanceBound(relativeKiloWattPeak) ?: return null
      if (upperBound == lowerBound) return lowerBound.performanceFactor

      val relativeDistance = relativeKiloWattPeak.relativeDistanceBetween(upperBound.relativeKiloWattPeak, lowerBound.relativeKiloWattPeak)
      val interpolated = relativeDistance.interpolate(upperBound.performanceFactor.percentage, lowerBound.performanceFactor.percentage)
      return PerformanceFactor(interpolated)
    }
  }

  @JvmInline
  value class PeakMap(val map: List<PeakPerformance>)
  data class PeakPerformance(val relativeKiloWattPeak: @pct Double, val performanceFactor: @pct PerformanceFactor)

  data class PowerDistribution(val directPercentage: @pct Double, val batteryPercentage: @pct Double)
}


/**
 * Data was copied from this spreadsheet https://docs.google.com/spreadsheets/d/1knLcaERyV4EbaPcd8qmNs1LpSLmRtIHLNCD9XJnGmnQ/edit?usp=sharing into the following strings
 * Note: the relative values in the spreadsheet are bigger than these by a magnitude of 10 (e.g. this calculation would use a value of 0.2 for a value of 2 in the spreadsheet)
 *
 * They are then parsed to ElectricityDistributionTables
 */

/**
 * Table of percentages representing the share of generated electricity either immediately consumed or stored to a battery as specified by two metrics:
 *  - X axis: relativeKiloWattPeak (PowerRating / AnnualElectricityConsumption * 100)
 *  - Y axis: relativeBatteryCapacity (BatteryCapacity / AnnualElectricityConsumption * 100)
 *  Each step on an axis corresponds to 1/160 as the relative values range from 0.0 to 1.0
 */
internal val privateConsumptionPercentageTable = """
  100.00%	96.16%	83.49%	72.76%	64.73%	58.60%	53.73%	49.72%	46.32%	43.36%	40.72%	38.35%	36.22%	34.32%	32.62%	31.09%	29.70%	28.43%	27.27%	26.20%	25.22%	24.31%	23.47%	22.69%	21.96%	21.27%	20.63%	20.03%	19.47%	18.93%	18.43%	17.95%	17.49%	17.06%	16.66%	16.27%	15.90%	15.54%	15.20%	14.88%	14.57%	14.27%	13.99%	13.72%	13.45%	13.20%	12.96%	12.72%	12.50%	12.28%	12.07%	11.87%	11.67%	11.48%	11.30%	11.12%	10.95%	10.79%	10.62%	10.47%	10.32%	10.17%	10.03%	9.89%	9.75%	9.62%	9.49%	9.37%	9.25%	9.13%	9.01%	8.90%	8.79%	8.69%	8.58%	8.48%	8.38%	8.29%	8.19%	8.10%	8.01%	7.92%	7.84%	7.75%	7.67%	7.59%	7.51%	7.43%	7.35%	7.28%	7.21%	7.14%	7.07%	7.00%	6.93%	6.86%	6.80%	6.74%	6.67%	6.61%	6.55%	6.49%	6.44%	6.38%	6.32%	6.27%	6.21%	6.16%	6.11%	6.06%	6.01%	5.96%	5.91%	5.86%	5.81%	5.77%	5.72%	5.68%	5.63%	5.59%	5.55%	5.51%	5.46%	5.42%	5.38%	5.34%	5.30%	5.27%	5.23%	5.19%	5.15%	5.12%	5.08%	5.05%	5.01%	4.98%	4.94%	4.91%	4.88%	4.84%	4.81%	4.78%	4.75%	4.72%	4.69%	4.66%	4.63%	4.60%	4.57%	4.54%	4.51%	4.48%	4.46%	4.43%	4.40%	4.38%	4.35%	4.32%	4.30%	4.27%	4.25%
  100.00%	100.00%	95.08%	86.07%	77.11%	69.58%	63.41%	58.21%	53.77%	49.87%	46.47%	43.51%	40.94%	38.67%	36.63%	34.81%	33.17%	31.69%	30.33%	29.09%	27.96%	26.91%	25.93%	25.03%	24.19%	23.41%	22.67%	21.99%	21.34%	20.73%	20.16%	19.62%	19.11%	18.62%	18.16%	17.72%	17.31%	16.91%	16.53%	16.17%	15.83%	15.50%	15.18%	14.88%	14.59%	14.31%	14.04%	13.78%	13.53%	13.29%	13.06%	12.84%	12.62%	12.41%	12.21%	12.01%	11.83%	11.64%	11.46%	11.29%	11.13%	10.96%	10.81%	10.65%	10.51%	10.36%	10.22%	10.08%	9.95%	9.82%	9.69%	9.57%	9.45%	9.34%	9.22%	9.11%	9.00%	8.89%	8.79%	8.69%	8.59%	8.49%	8.40%	8.31%	8.22%	8.13%	8.04%	7.96%	7.87%	7.79%	7.71%	7.63%	7.56%	7.48%	7.41%	7.34%	7.27%	7.20%	7.13%	7.07%	7.00%	6.94%	6.87%	6.81%	6.75%	6.69%	6.63%	6.58%	6.52%	6.46%	6.41%	6.36%	6.30%	6.25%	6.20%	6.15%	6.10%	6.05%	6.00%	5.96%	5.91%	5.86%	5.82%	5.78%	5.73%	5.69%	5.65%	5.61%	5.56%	5.52%	5.48%	5.44%	5.41%	5.37%	5.33%	5.29%	5.26%	5.22%	5.18%	5.15%	5.11%	5.08%	5.05%	5.01%	4.98%	4.95%	4.92%	4.89%	4.85%	4.82%	4.79%	4.76%	4.73%	4.70%	4.68%	4.65%	4.62%	4.59%	4.56%	4.54%	4.51%
  100.00%	100.00%	97.90%	91.68%	84.03%	76.33%	69.78%	63.96%	58.80%	54.37%	50.54%	47.23%	44.37%	41.85%	39.61%	37.59%	35.79%	34.15%	32.66%	31.29%	30.04%	28.89%	27.83%	26.85%	25.93%	25.07%	24.28%	23.53%	22.82%	22.16%	21.54%	20.95%	20.40%	19.87%	19.37%	18.90%	18.45%	18.02%	17.61%	17.22%	16.85%	16.49%	16.15%	15.83%	15.52%	15.22%	14.93%	14.65%	14.38%	14.12%	13.88%	13.64%	13.41%	13.18%	12.97%	12.76%	12.56%	12.36%	12.17%	11.99%	11.81%	11.63%	11.47%	11.30%	11.14%	10.99%	10.84%	10.69%	10.55%	10.41%	10.28%	10.15%	10.02%	9.89%	9.77%	9.65%	9.53%	9.42%	9.31%	9.20%	9.10%	8.99%	8.89%	8.79%	8.70%	8.60%	8.51%	8.42%	8.33%	8.24%	8.16%	8.08%	7.99%	7.92%	7.84%	7.76%	7.69%	7.61%	7.54%	7.47%	7.40%	7.33%	7.26%	7.20%	7.13%	7.07%	7.01%	6.95%	6.89%	6.83%	6.77%	6.71%	6.66%	6.60%	6.55%	6.49%	6.44%	6.39%	6.34%	6.29%	6.24%	6.19%	6.14%	6.10%	6.05%	6.00%	5.96%	5.91%	5.87%	5.83%	5.79%	5.74%	5.70%	5.66%	5.62%	5.58%	5.54%	5.51%	5.47%	5.43%	5.39%	5.36%	5.32%	5.29%	5.25%	5.22%	5.18%	5.15%	5.12%	5.08%	5.05%	5.02%	4.99%	4.96%	4.93%	4.90%	4.87%	4.84%	4.81%	4.78%	4.75%
  100.00%	100.00%	99.35%	94.73%	88.29%	81.20%	74.41%	68.27%	62.73%	57.99%	53.89%	50.35%	47.27%	44.55%	42.14%	39.96%	38.01%	36.25%	34.65%	33.19%	31.84%	30.60%	29.46%	28.41%	27.44%	26.52%	25.67%	24.87%	24.12%	23.42%	22.76%	22.14%	21.55%	20.99%	20.45%	19.95%	19.47%	19.01%	18.58%	18.16%	17.77%	17.39%	17.03%	16.68%	16.35%	16.03%	15.73%	15.43%	15.15%	14.88%	14.62%	14.36%	14.12%	13.88%	13.65%	13.43%	13.22%	13.01%	12.81%	12.62%	12.43%	12.24%	12.07%	11.89%	11.73%	11.56%	11.41%	11.25%	11.10%	10.96%	10.81%	10.67%	10.54%	10.41%	10.28%	10.15%	10.03%	9.91%	9.79%	9.68%	9.57%	9.46%	9.35%	9.25%	9.14%	9.04%	8.95%	8.85%	8.76%	8.67%	8.58%	8.49%	8.40%	8.32%	8.24%	8.16%	8.08%	8.00%	7.92%	7.85%	7.78%	7.70%	7.63%	7.56%	7.50%	7.43%	7.36%	7.30%	7.23%	7.17%	7.11%	7.05%	6.99%	6.93%	6.88%	6.82%	6.76%	6.71%	6.66%	6.60%	6.55%	6.50%	6.45%	6.40%	6.35%	6.30%	6.26%	6.21%	6.16%	6.12%	6.07%	6.03%	5.99%	5.94%	5.90%	5.86%	5.82%	5.78%	5.74%	5.70%	5.66%	5.62%	5.58%	5.55%	5.51%	5.47%	5.44%	5.40%	5.37%	5.33%	5.30%	5.27%	5.23%	5.20%	5.17%	5.14%	5.11%	5.08%	5.04%	5.01%	4.98%
  100.00%	100.00%	99.92%	96.73%	91.31%	84.85%	78.03%	71.75%	66.08%	61.09%	56.79%	53.06%	49.80%	46.93%	44.37%	42.07%	39.99%	38.12%	36.42%	34.88%	33.46%	32.16%	30.95%	29.84%	28.80%	27.84%	26.93%	26.09%	25.30%	24.56%	23.87%	23.21%	22.59%	22.01%	21.45%	20.92%	20.42%	19.94%	19.48%	19.05%	18.63%	18.23%	17.85%	17.48%	17.13%	16.80%	16.48%	16.17%	15.87%	15.58%	15.31%	15.04%	14.79%	14.54%	14.30%	14.07%	13.84%	13.62%	13.41%	13.21%	13.01%	12.82%	12.63%	12.45%	12.28%	12.11%	11.94%	11.78%	11.62%	11.47%	11.32%	11.17%	11.03%	10.89%	10.76%	10.63%	10.50%	10.37%	10.25%	10.13%	10.02%	9.90%	9.79%	9.68%	9.57%	9.47%	9.37%	9.27%	9.17%	9.07%	8.98%	8.89%	8.80%	8.71%	8.62%	8.54%	8.46%	8.37%	8.29%	8.22%	8.14%	8.06%	7.99%	7.92%	7.85%	7.78%	7.71%	7.64%	7.57%	7.51%	7.44%	7.38%	7.32%	7.26%	7.20%	7.14%	7.08%	7.02%	6.96%	6.91%	6.85%	6.80%	6.75%	6.70%	6.65%	6.59%	6.55%	6.50%	6.45%	6.40%	6.35%	6.31%	6.26%	6.22%	6.17%	6.13%	6.09%	6.04%	6.00%	5.96%	5.92%	5.88%	5.84%	5.80%	5.76%	5.73%	5.69%	5.65%	5.62%	5.58%	5.54%	5.51%	5.47%	5.44%	5.41%	5.37%	5.34%	5.31%	5.28%	5.24%	5.21%
  100.00%	100.00%	100.00%	98.13%	93.59%	87.57%	81.11%	74.76%	69.04%	63.87%	59.40%	55.51%	52.11%	49.09%	46.41%	43.99%	41.81%	39.85%	38.07%	36.44%	34.95%	33.59%	32.33%	31.16%	30.08%	29.07%	28.13%	27.25%	26.42%	25.64%	24.91%	24.23%	23.58%	22.97%	22.39%	21.84%	21.32%	20.82%	20.34%	19.88%	19.45%	19.03%	18.64%	18.26%	17.89%	17.54%	17.20%	16.88%	16.57%	16.27%	15.98%	15.70%	15.43%	15.17%	14.92%	14.68%	14.44%	14.21%	13.99%	13.78%	13.57%	13.37%	13.18%	12.99%	12.81%	12.63%	12.46%	12.29%	12.12%	11.96%	11.81%	11.66%	11.51%	11.37%	11.22%	11.09%	10.95%	10.82%	10.70%	10.57%	10.45%	10.33%	10.21%	10.10%	9.99%	9.88%	9.77%	9.67%	9.57%	9.47%	9.37%	9.27%	9.18%	9.09%	9.00%	8.91%	8.82%	8.74%	8.66%	8.57%	8.49%	8.42%	8.34%	8.26%	8.19%	8.11%	8.04%	7.97%	7.90%	7.83%	7.77%	7.70%	7.64%	7.57%	7.51%	7.45%	7.39%	7.33%	7.27%	7.21%	7.15%	7.10%	7.04%	6.99%	6.93%	6.88%	6.83%	6.78%	6.73%	6.68%	6.63%	6.58%	6.53%	6.49%	6.44%	6.40%	6.35%	6.31%	6.26%	6.22%	6.18%	6.13%	6.09%	6.05%	6.01%	5.97%	5.93%	5.90%	5.86%	5.82%	5.78%	5.75%	5.71%	5.67%	5.64%	5.60%	5.57%	5.54%	5.50%	5.47%	5.44%
  100.00%	100.00%	100.00%	99.06%	95.38%	89.85%	83.75%	77.48%	71.69%	66.42%	61.80%	57.80%	54.26%	51.12%	48.33%	45.81%	43.54%	41.49%	39.63%	37.93%	36.38%	34.96%	33.64%	32.43%	31.31%	30.26%	29.27%	28.36%	27.50%	26.69%	25.93%	25.21%	24.54%	23.90%	23.30%	22.73%	22.18%	21.66%	21.17%	20.69%	20.24%	19.81%	19.39%	19.00%	18.62%	18.26%	17.91%	17.57%	17.24%	16.93%	16.63%	16.34%	16.06%	15.79%	15.53%	15.27%	15.03%	14.79%	14.56%	14.34%	14.12%	13.92%	13.71%	13.52%	13.33%	13.14%	12.96%	12.78%	12.61%	12.45%	12.29%	12.13%	11.98%	11.83%	11.68%	11.54%	11.40%	11.26%	11.13%	11.00%	10.87%	10.75%	10.63%	10.51%	10.40%	10.28%	10.17%	10.06%	9.96%	9.85%	9.75%	9.65%	9.56%	9.46%	9.37%	9.28%	9.19%	9.10%	9.01%	8.93%	8.84%	8.76%	8.68%	8.60%	8.52%	8.45%	8.37%	8.30%	8.23%	8.16%	8.09%	8.02%	7.95%	7.88%	7.82%	7.75%	7.69%	7.63%	7.57%	7.51%	7.45%	7.39%	7.33%	7.28%	7.22%	7.16%	7.11%	7.06%	7.01%	6.95%	6.90%	6.85%	6.80%	6.75%	6.71%	6.66%	6.61%	6.57%	6.52%	6.48%	6.43%	6.39%	6.34%	6.30%	6.26%	6.22%	6.18%	6.14%	6.10%	6.06%	6.02%	5.98%	5.94%	5.91%	5.87%	5.83%	5.80%	5.76%	5.73%	5.69%	5.66%
  100.00%	100.00%	100.00%	99.63%	96.75%	91.79%	86.07%	79.97%	74.10%	68.82%	64.08%	59.96%	56.31%	53.08%	50.17%	47.57%	45.21%	43.08%	41.16%	39.39%	37.77%	36.29%	34.92%	33.67%	32.50%	31.42%	30.40%	29.45%	28.55%	27.71%	26.92%	26.18%	25.48%	24.82%	24.19%	23.60%	23.03%	22.49%	21.98%	21.48%	21.02%	20.57%	20.14%	19.73%	19.33%	18.96%	18.59%	18.24%	17.91%	17.58%	17.27%	16.97%	16.68%	16.40%	16.12%	15.86%	15.61%	15.36%	15.12%	14.89%	14.67%	14.45%	14.24%	14.04%	13.84%	13.65%	13.46%	13.28%	13.10%	12.93%	12.76%	12.59%	12.44%	12.28%	12.13%	11.98%	11.84%	11.70%	11.56%	11.42%	11.29%	11.16%	11.04%	10.92%	10.80%	10.68%	10.56%	10.45%	10.34%	10.24%	10.13%	10.03%	9.93%	9.83%	9.73%	9.63%	9.54%	9.45%	9.36%	9.27%	9.19%	9.10%	9.02%	8.94%	8.86%	8.78%	8.70%	8.62%	8.55%	8.47%	8.40%	8.33%	8.26%	8.19%	8.12%	8.06%	7.99%	7.93%	7.86%	7.80%	7.74%	7.68%	7.62%	7.56%	7.50%	7.44%	7.39%	7.33%	7.28%	7.23%	7.17%	7.12%	7.07%	7.02%	6.97%	6.92%	6.87%	6.82%	6.78%	6.73%	6.68%	6.64%	6.59%	6.55%	6.51%	6.46%	6.42%	6.38%	6.34%	6.30%	6.26%	6.22%	6.18%	6.14%	6.10%	6.06%	6.02%	5.99%	5.95%	5.92%	5.88%
  100.00%	100.00%	100.00%	99.92%	97.87%	93.56%	88.12%	82.25%	76.38%	71.07%	66.27%	62.03%	58.31%	54.95%	51.98%	49.27%	46.84%	44.65%	42.65%	40.82%	39.15%	37.61%	36.18%	34.88%	33.67%	32.55%	31.50%	30.52%	29.59%	28.72%	27.90%	27.13%	26.41%	25.72%	25.07%	24.46%	23.87%	23.31%	22.78%	22.27%	21.78%	21.32%	20.87%	20.45%	20.04%	19.65%	19.27%	18.91%	18.56%	18.22%	17.90%	17.59%	17.29%	17.00%	16.72%	16.45%	16.18%	15.93%	15.68%	15.44%	15.21%	14.98%	14.77%	14.55%	14.35%	14.15%	13.95%	13.76%	13.58%	13.40%	13.23%	13.06%	12.89%	12.73%	12.57%	12.42%	12.27%	12.12%	11.98%	11.84%	11.71%	11.57%	11.44%	11.32%	11.19%	11.07%	10.95%	10.84%	10.72%	10.61%	10.50%	10.40%	10.29%	10.19%	10.09%	9.99%	9.90%	9.80%	9.71%	9.62%	9.53%	9.44%	9.35%	9.27%	9.18%	9.10%	9.02%	8.94%	8.87%	8.79%	8.71%	8.64%	8.57%	8.49%	8.42%	8.36%	8.29%	8.22%	8.16%	8.09%	8.03%	7.96%	7.90%	7.84%	7.78%	7.72%	7.66%	7.61%	7.55%	7.50%	7.44%	7.39%	7.33%	7.28%	7.23%	7.18%	7.13%	7.08%	7.03%	6.98%	6.93%	6.89%	6.84%	6.79%	6.75%	6.70%	6.66%	6.62%	6.57%	6.53%	6.49%	6.45%	6.41%	6.37%	6.33%	6.29%	6.25%	6.21%	6.17%	6.14%	6.10%
  100.00%	100.00%	100.00%	100.00%	98.70%	95.05%	89.89%	84.29%	78.57%	73.16%	68.38%	64.05%	60.22%	56.80%	53.72%	50.95%	48.44%	46.18%	44.12%	42.23%	40.49%	38.91%	37.44%	36.08%	34.82%	33.66%	32.58%	31.57%	30.62%	29.72%	28.87%	28.08%	27.32%	26.62%	25.94%	25.31%	24.70%	24.13%	23.58%	23.05%	22.54%	22.06%	21.60%	21.16%	20.74%	20.33%	19.94%	19.57%	19.21%	18.86%	18.53%	18.20%	17.89%	17.59%	17.30%	17.02%	16.75%	16.48%	16.23%	15.98%	15.74%	15.51%	15.28%	15.07%	14.85%	14.65%	14.45%	14.25%	14.06%	13.87%	13.69%	13.52%	13.35%	13.18%	13.02%	12.86%	12.70%	12.55%	12.40%	12.26%	12.12%	11.98%	11.85%	11.71%	11.59%	11.46%	11.34%	11.22%	11.10%	10.99%	10.87%	10.76%	10.66%	10.55%	10.45%	10.34%	10.24%	10.15%	10.05%	9.96%	9.86%	9.77%	9.68%	9.60%	9.51%	9.43%	9.34%	9.26%	9.18%	9.10%	9.02%	8.95%	8.87%	8.80%	8.73%	8.65%	8.58%	8.51%	8.45%	8.38%	8.31%	8.25%	8.18%	8.12%	8.06%	8.00%	7.94%	7.88%	7.82%	7.76%	7.71%	7.65%	7.60%	7.54%	7.49%	7.44%	7.38%	7.33%	7.28%	7.23%	7.18%	7.13%	7.08%	7.04%	6.99%	6.94%	6.90%	6.85%	6.81%	6.77%	6.72%	6.68%	6.64%	6.60%	6.56%	6.52%	6.48%	6.44%	6.40%	6.36%	6.32%
  100.00%	100.00%	100.00%	100.00%	99.31%	96.29%	91.50%	86.13%	80.58%	75.17%	70.37%	66.00%	62.09%	58.58%	55.43%	52.58%	50.01%	47.68%	45.56%	43.62%	41.83%	40.19%	38.68%	37.27%	35.97%	34.77%	33.65%	32.60%	31.63%	30.70%	29.83%	29.01%	28.23%	27.50%	26.81%	26.15%	25.52%	24.93%	24.36%	23.82%	23.30%	22.80%	22.33%	21.87%	21.43%	21.01%	20.61%	20.22%	19.85%	19.49%	19.14%	18.81%	18.49%	18.18%	17.88%	17.59%	17.31%	17.04%	16.77%	16.52%	16.27%	16.03%	15.80%	15.57%	15.35%	15.14%	14.93%	14.73%	14.53%	14.34%	14.16%	13.97%	13.80%	13.63%	13.46%	13.29%	13.13%	12.98%	12.82%	12.67%	12.53%	12.38%	12.25%	12.11%	11.98%	11.85%	11.72%	11.60%	11.48%	11.36%	11.24%	11.13%	11.02%	10.91%	10.80%	10.69%	10.59%	10.49%	10.39%	10.29%	10.20%	10.11%	10.01%	9.92%	9.83%	9.75%	9.66%	9.58%	9.49%	9.41%	9.33%	9.25%	9.18%	9.10%	9.02%	8.95%	8.88%	8.81%	8.74%	8.67%	8.60%	8.53%	8.46%	8.40%	8.34%	8.27%	8.21%	8.15%	8.09%	8.03%	7.97%	7.91%	7.86%	7.80%	7.74%	7.69%	7.64%	7.58%	7.53%	7.48%	7.43%	7.38%	7.33%	7.28%	7.23%	7.18%	7.14%	7.09%	7.05%	7.00%	6.96%	6.91%	6.87%	6.82%	6.78%	6.74%	6.70%	6.66%	6.62%	6.58%	6.54%
  100.00%	100.00%	100.00%	100.00%	99.68%	97.33%	93.00%	87.80%	82.48%	77.12%	72.26%	67.88%	63.91%	60.33%	57.10%	54.19%	51.54%	49.15%	46.98%	44.99%	43.16%	41.46%	39.90%	38.46%	37.12%	35.87%	34.71%	33.63%	32.62%	31.67%	30.78%	29.93%	29.13%	28.38%	27.66%	26.98%	26.34%	25.73%	25.14%	24.58%	24.05%	23.54%	23.04%	22.57%	22.12%	21.69%	21.27%	20.87%	20.49%	20.12%	19.76%	19.41%	19.08%	18.76%	18.45%	18.16%	17.87%	17.59%	17.31%	17.05%	16.80%	16.55%	16.31%	16.07%	15.85%	15.63%	15.42%	15.21%	15.01%	14.81%	14.62%	14.43%	14.25%	14.07%	13.89%	13.73%	13.56%	13.40%	13.24%	13.09%	12.94%	12.79%	12.64%	12.50%	12.37%	12.23%	12.10%	11.97%	11.85%	11.72%	11.60%	11.49%	11.37%	11.26%	11.15%	11.04%	10.94%	10.83%	10.73%	10.63%	10.53%	10.43%	10.34%	10.25%	10.15%	10.06%	9.98%	9.89%	9.80%	9.72%	9.64%	9.56%	9.48%	9.40%	9.32%	9.24%	9.17%	9.10%	9.02%	8.95%	8.88%	8.81%	8.74%	8.68%	8.61%	8.55%	8.48%	8.42%	8.36%	8.29%	8.23%	8.17%	8.12%	8.06%	8.00%	7.94%	7.89%	7.83%	7.78%	7.73%	7.67%	7.62%	7.57%	7.52%	7.47%	7.42%	7.37%	7.33%	7.28%	7.23%	7.19%	7.14%	7.10%	7.05%	7.01%	6.96%	6.92%	6.88%	6.84%	6.80%	6.76%
  100.00%	100.00%	100.00%	100.00%	99.90%	98.15%	94.33%	89.35%	84.22%	78.97%	74.05%	69.70%	65.68%	62.04%	58.74%	55.75%	53.06%	50.60%	48.38%	46.34%	44.45%	42.72%	41.11%	39.63%	38.25%	36.97%	35.77%	34.65%	33.61%	32.63%	31.71%	30.84%	30.02%	29.24%	28.51%	27.81%	27.15%	26.52%	25.91%	25.33%	24.78%	24.26%	23.76%	23.27%	22.81%	22.36%	21.93%	21.52%	21.12%	20.74%	20.37%	20.02%	19.67%	19.34%	19.02%	18.72%	18.42%	18.13%	17.85%	17.58%	17.32%	17.06%	16.81%	16.57%	16.34%	16.11%	15.89%	15.68%	15.47%	15.27%	15.07%	14.88%	14.69%	14.51%	14.33%	14.15%	13.98%	13.82%	13.66%	13.50%	13.34%	13.19%	13.04%	12.90%	12.75%	12.62%	12.48%	12.35%	12.22%	12.09%	11.97%	11.85%	11.73%	11.61%	11.50%	11.39%	11.28%	11.17%	11.06%	10.96%	10.86%	10.76%	10.66%	10.57%	10.47%	10.38%	10.29%	10.20%	10.11%	10.03%	9.94%	9.86%	9.77%	9.69%	9.61%	9.54%	9.46%	9.38%	9.31%	9.23%	9.16%	9.09%	9.02%	8.95%	8.88%	8.82%	8.75%	8.68%	8.62%	8.56%	8.50%	8.43%	8.37%	8.31%	8.25%	8.20%	8.14%	8.08%	8.03%	7.97%	7.92%	7.86%	7.81%	7.76%	7.71%	7.66%	7.61%	7.56%	7.51%	7.46%	7.41%	7.37%	7.32%	7.27%	7.23%	7.19%	7.14%	7.10%	7.06%	7.01%	6.97%
  100.00%	100.00%	100.00%	100.00%	99.99%	98.79%	95.48%	90.81%	85.76%	80.72%	75.82%	71.42%	67.39%	63.71%	60.35%	57.30%	54.53%	52.03%	49.75%	47.65%	45.74%	43.96%	42.31%	40.78%	39.37%	38.05%	36.82%	35.67%	34.59%	33.58%	32.63%	31.74%	30.90%	30.10%	29.35%	28.63%	27.95%	27.30%	26.68%	26.09%	25.52%	24.98%	24.46%	23.96%	23.48%	23.02%	22.58%	22.16%	21.75%	21.36%	20.98%	20.61%	20.26%	19.92%	19.59%	19.27%	18.96%	18.67%	18.38%	18.10%	17.83%	17.57%	17.31%	17.07%	16.83%	16.59%	16.37%	16.15%	15.93%	15.72%	15.52%	15.32%	15.13%	14.94%	14.76%	14.58%	14.40%	14.23%	14.06%	13.90%	13.74%	13.59%	13.43%	13.28%	13.14%	12.99%	12.86%	12.72%	12.59%	12.45%	12.33%	12.20%	12.08%	11.96%	11.84%	11.73%	11.61%	11.50%	11.39%	11.29%	11.18%	11.08%	10.98%	10.88%	10.79%	10.69%	10.60%	10.50%	10.41%	10.32%	10.24%	10.15%	10.07%	9.98%	9.90%	9.82%	9.74%	9.66%	9.59%	9.51%	9.44%	9.36%	9.29%	9.22%	9.15%	9.08%	9.01%	8.95%	8.88%	8.82%	8.75%	8.69%	8.63%	8.57%	8.50%	8.44%	8.39%	8.33%	8.27%	8.21%	8.16%	8.10%	8.05%	7.99%	7.94%	7.89%	7.84%	7.79%	7.74%	7.69%	7.64%	7.59%	7.54%	7.50%	7.45%	7.40%	7.36%	7.31%	7.27%	7.22%	7.18%
  100.00%	100.00%	100.00%	100.00%	100.00%	99.29%	96.51%	92.13%	87.19%	82.36%	77.52%	73.07%	69.04%	65.33%	61.93%	58.81%	55.99%	53.44%	51.10%	48.96%	46.99%	45.18%	43.50%	41.94%	40.48%	39.12%	37.86%	36.68%	35.57%	34.53%	33.55%	32.63%	31.77%	30.95%	30.17%	29.44%	28.73%	28.06%	27.43%	26.82%	26.24%	25.68%	25.15%	24.64%	24.15%	23.68%	23.22%	22.79%	22.36%	21.96%	21.57%	21.20%	20.83%	20.48%	20.14%	19.82%	19.50%	19.19%	18.90%	18.61%	18.33%	18.06%	17.80%	17.55%	17.30%	17.06%	16.83%	16.60%	16.38%	16.16%	15.95%	15.75%	15.55%	15.36%	15.17%	14.99%	14.81%	14.63%	14.46%	14.29%	14.13%	13.97%	13.81%	13.66%	13.51%	13.36%	13.22%	13.08%	12.94%	12.81%	12.67%	12.55%	12.42%	12.30%	12.17%	12.06%	11.94%	11.82%	11.71%	11.60%	11.50%	11.39%	11.29%	11.19%	11.09%	10.99%	10.89%	10.80%	10.70%	10.61%	10.52%	10.43%	10.35%	10.26%	10.18%	10.10%	10.01%	9.93%	9.86%	9.78%	9.70%	9.63%	9.55%	9.48%	9.41%	9.34%	9.27%	9.20%	9.13%	9.06%	9.00%	8.93%	8.87%	8.81%	8.74%	8.68%	8.62%	8.56%	8.50%	8.44%	8.39%	8.33%	8.27%	8.22%	8.16%	8.11%	8.06%	8.01%	7.95%	7.90%	7.85%	7.80%	7.75%	7.71%	7.66%	7.61%	7.56%	7.52%	7.47%	7.43%	7.38%
  100.00%	100.00%	100.00%	100.00%	100.00%	99.62%	97.37%	93.31%	88.55%	83.86%	79.15%	74.67%	70.63%	66.90%	63.48%	60.30%	57.42%	54.81%	52.43%	50.24%	48.23%	46.37%	44.66%	43.06%	41.57%	40.18%	38.88%	37.67%	36.53%	35.46%	34.45%	33.51%	32.61%	31.77%	30.97%	30.22%	29.49%	28.80%	28.15%	27.52%	26.92%	26.35%	25.81%	25.28%	24.78%	24.30%	23.83%	23.38%	22.95%	22.53%	22.13%	21.75%	21.37%	21.01%	20.67%	20.33%	20.00%	19.69%	19.38%	19.09%	18.80%	18.52%	18.25%	17.99%	17.74%	17.49%	17.25%	17.02%	16.79%	16.57%	16.36%	16.15%	15.95%	15.75%	15.55%	15.37%	15.18%	15.00%	14.83%	14.65%	14.49%	14.32%	14.16%	14.00%	13.85%	13.70%	13.55%	13.41%	13.27%	13.13%	13.00%	12.86%	12.73%	12.61%	12.48%	12.36%	12.24%	12.12%	12.01%	11.90%	11.79%	11.68%	11.57%	11.47%	11.36%	11.26%	11.16%	11.07%	10.97%	10.88%	10.79%	10.69%	10.61%	10.52%	10.43%	10.35%	10.26%	10.18%	10.10%	10.02%	9.94%	9.87%	9.79%	9.72%	9.64%	9.57%	9.50%	9.43%	9.36%	9.29%	9.22%	9.16%	9.09%	9.03%	8.96%	8.90%	8.84%	8.78%	8.72%	8.66%	8.60%	8.54%	8.48%	8.43%	8.37%	8.32%	8.26%	8.21%	8.15%	8.10%	8.05%	8.00%	7.95%	7.90%	7.85%	7.80%	7.75%	7.71%	7.66%	7.61%	7.57%
  100.00%	100.00%	100.00%	100.00%	100.00%	99.86%	98.03%	94.39%	89.85%	85.24%	80.70%	76.24%	72.16%	68.41%	64.96%	61.76%	58.81%	56.15%	53.73%	51.49%	49.43%	47.54%	45.79%	44.16%	42.64%	41.21%	39.88%	38.62%	37.45%	36.35%	35.31%	34.33%	33.42%	32.55%	31.73%	30.95%	30.21%	29.50%	28.83%	28.19%	27.57%	26.99%	26.42%	25.89%	25.37%	24.88%	24.40%	23.94%	23.50%	23.07%	22.66%	22.26%	21.88%	21.51%	21.15%	20.81%	20.47%	20.15%	19.84%	19.53%	19.24%	18.95%	18.68%	18.41%	18.15%	17.89%	17.65%	17.41%	17.18%	16.95%	16.73%	16.52%	16.31%	16.10%	15.91%	15.71%	15.52%	15.34%	15.16%	14.98%	14.81%	14.64%	14.48%	14.32%	14.16%	14.01%	13.86%	13.71%	13.57%	13.43%	13.29%	13.15%	13.02%	12.89%	12.76%	12.64%	12.52%	12.40%	12.28%	12.16%	12.05%	11.94%	11.83%	11.72%	11.62%	11.52%	11.41%	11.31%	11.22%	11.12%	11.03%	10.93%	10.84%	10.75%	10.67%	10.58%	10.49%	10.41%	10.33%	10.25%	10.17%	10.09%	10.01%	9.93%	9.86%	9.78%	9.71%	9.64%	9.57%	9.50%	9.43%	9.36%	9.30%	9.23%	9.16%	9.10%	9.04%	8.97%	8.91%	8.85%	8.79%	8.73%	8.67%	8.62%	8.56%	8.50%	8.45%	8.39%	8.34%	8.29%	8.23%	8.18%	8.13%	8.08%	8.03%	7.98%	7.93%	7.88%	7.83%	7.79%	7.74%
  100.00%	100.00%	100.00%	100.00%	100.00%	99.96%	98.60%	95.38%	91.09%	86.51%	82.12%	77.73%	73.64%	69.87%	66.40%	63.18%	60.18%	57.46%	54.97%	52.69%	50.58%	48.64%	46.85%	45.19%	43.64%	42.18%	40.80%	39.52%	38.31%	37.18%	36.12%	35.12%	34.17%	33.28%	32.44%	31.64%	30.88%	30.16%	29.47%	28.81%	28.18%	27.57%	27.00%	26.45%	25.92%	25.41%	24.92%	24.46%	24.01%	23.57%	23.15%	22.74%	22.35%	21.97%	21.60%	21.25%	20.91%	20.58%	20.26%	19.95%	19.64%	19.35%	19.07%	18.80%	18.53%	18.27%	18.02%	17.77%	17.54%	17.30%	17.08%	16.86%	16.65%	16.44%	16.24%	16.04%	15.85%	15.66%	15.47%	15.29%	15.12%	14.95%	14.78%	14.62%	14.46%	14.30%	14.15%	14.00%	13.85%	13.71%	13.57%	13.43%	13.29%	13.16%	13.03%	12.91%	12.78%	12.66%	12.54%	12.42%	12.30%	12.19%	12.08%	11.97%	11.86%	11.76%	11.65%	11.55%	11.45%	11.35%	11.26%	11.16%	11.07%	10.98%	10.89%	10.80%	10.71%	10.63%	10.54%	10.46%	10.38%	10.30%	10.22%	10.14%	10.06%	9.99%	9.91%	9.84%	9.77%	9.70%	9.63%	9.56%	9.49%	9.42%	9.36%	9.29%	9.23%	9.16%	9.10%	9.04%	8.98%	8.92%	8.86%	8.80%	8.74%	8.68%	8.63%	8.57%	8.51%	8.46%	8.41%	8.35%	8.30%	8.25%	8.20%	8.15%	8.10%	8.05%	8.00%	7.95%	7.90%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.08%	96.24%	92.22%	87.71%	83.43%	79.17%	75.06%	71.26%	67.74%	64.49%	61.45%	58.67%	56.13%	53.80%	51.65%	49.67%	47.84%	46.14%	44.56%	43.08%	41.67%	40.35%	39.11%	37.94%	36.86%	35.84%	34.87%	33.95%	33.09%	32.27%	31.49%	30.76%	30.05%	29.38%	28.74%	28.12%	27.53%	26.97%	26.43%	25.91%	25.41%	24.93%	24.47%	24.03%	23.61%	23.19%	22.79%	22.40%	22.03%	21.67%	21.32%	20.98%	20.65%	20.34%	20.03%	19.73%	19.45%	19.17%	18.89%	18.63%	18.37%	18.12%	17.88%	17.65%	17.42%	17.19%	16.98%	16.76%	16.56%	16.35%	16.16%	15.97%	15.78%	15.60%	15.42%	15.24%	15.07%	14.91%	14.74%	14.58%	14.43%	14.27%	14.12%	13.98%	13.83%	13.69%	13.56%	13.42%	13.29%	13.16%	13.03%	12.91%	12.79%	12.67%	12.55%	12.43%	12.32%	12.21%	12.10%	11.99%	11.88%	11.78%	11.68%	11.58%	11.48%	11.38%	11.29%	11.19%	11.10%	11.01%	10.92%	10.84%	10.75%	10.67%	10.58%	10.50%	10.42%	10.34%	10.26%	10.18%	10.11%	10.03%	9.96%	9.89%	9.82%	9.75%	9.68%	9.61%	9.54%	9.47%	9.41%	9.34%	9.28%	9.22%	9.15%	9.09%	9.03%	8.97%	8.91%	8.85%	8.80%	8.74%	8.68%	8.63%	8.57%	8.52%	8.47%	8.41%	8.36%	8.31%	8.26%	8.21%	8.16%	8.11%	8.06%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.43%	96.95%	93.22%	88.86%	84.66%	80.48%	76.38%	72.53%	68.96%	65.68%	62.62%	59.78%	57.18%	54.80%	52.62%	50.60%	48.73%	47.01%	45.40%	43.89%	42.46%	41.11%	39.84%	38.66%	37.54%	36.50%	35.51%	34.58%	33.70%	32.86%	32.07%	31.32%	30.60%	29.92%	29.26%	28.64%	28.04%	27.46%	26.91%	26.38%	25.88%	25.39%	24.92%	24.47%	24.04%	23.62%	23.21%	22.82%	22.44%	22.07%	21.71%	21.37%	21.03%	20.71%	20.40%	20.10%	19.81%	19.52%	19.25%	18.98%	18.72%	18.46%	18.21%	17.97%	17.74%	17.51%	17.29%	17.07%	16.86%	16.66%	16.46%	16.26%	16.07%	15.88%	15.70%	15.52%	15.35%	15.18%	15.01%	14.85%	14.69%	14.54%	14.38%	14.24%	14.09%	13.95%	13.81%	13.67%	13.54%	13.40%	13.27%	13.15%	13.02%	12.90%	12.78%	12.66%	12.55%	12.43%	12.32%	12.21%	12.11%	12.00%	11.90%	11.79%	11.69%	11.60%	11.50%	11.40%	11.31%	11.22%	11.13%	11.04%	10.95%	10.87%	10.78%	10.70%	10.61%	10.53%	10.45%	10.37%	10.30%	10.22%	10.15%	10.07%	10.00%	9.93%	9.86%	9.79%	9.72%	9.65%	9.59%	9.52%	9.45%	9.39%	9.33%	9.26%	9.20%	9.14%	9.08%	9.02%	8.96%	8.91%	8.85%	8.79%	8.74%	8.68%	8.63%	8.57%	8.52%	8.47%	8.42%	8.37%	8.31%	8.27%	8.22%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.67%	97.54%	94.12%	89.92%	85.72%	81.65%	77.58%	73.70%	70.07%	66.71%	63.65%	60.79%	58.16%	55.74%	53.51%	51.47%	49.57%	47.81%	46.18%	44.65%	43.19%	41.83%	40.54%	39.33%	38.19%	37.12%	36.12%	35.17%	34.27%	33.42%	32.61%	31.85%	31.12%	30.42%	29.76%	29.12%	28.51%	27.93%	27.37%	26.83%	26.31%	25.82%	25.34%	24.89%	24.44%	24.02%	23.61%	23.21%	22.83%	22.45%	22.09%	21.74%	21.40%	21.07%	20.75%	20.45%	20.15%	19.86%	19.58%	19.30%	19.04%	18.78%	18.53%	18.29%	18.05%	17.82%	17.59%	17.37%	17.15%	16.95%	16.74%	16.54%	16.35%	16.16%	15.97%	15.79%	15.61%	15.44%	15.27%	15.11%	14.94%	14.79%	14.63%	14.48%	14.33%	14.19%	14.05%	13.91%	13.77%	13.63%	13.50%	13.37%	13.25%	13.12%	13.00%	12.88%	12.77%	12.65%	12.54%	12.43%	12.32%	12.21%	12.10%	12.00%	11.90%	11.80%	11.70%	11.60%	11.51%	11.41%	11.32%	11.23%	11.14%	11.06%	10.97%	10.88%	10.80%	10.72%	10.64%	10.56%	10.48%	10.40%	10.32%	10.25%	10.18%	10.10%	10.03%	9.96%	9.89%	9.82%	9.75%	9.69%	9.62%	9.56%	9.49%	9.43%	9.37%	9.30%	9.24%	9.18%	9.12%	9.06%	9.01%	8.95%	8.89%	8.84%	8.78%	8.73%	8.67%	8.62%	8.57%	8.51%	8.46%	8.41%	8.36%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.83%	98.08%	94.95%	90.92%	86.66%	82.68%	78.65%	74.73%	71.06%	67.66%	64.59%	61.73%	59.06%	56.61%	54.36%	52.28%	50.36%	48.57%	46.91%	45.35%	43.88%	42.49%	41.19%	39.95%	38.80%	37.71%	36.69%	35.72%	34.81%	33.95%	33.13%	32.35%	31.61%	30.90%	30.23%	29.58%	28.97%	28.37%	27.81%	27.26%	26.74%	26.23%	25.75%	25.28%	24.83%	24.40%	23.98%	23.58%	23.19%	22.81%	22.45%	22.09%	21.75%	21.41%	21.09%	20.77%	20.47%	20.18%	19.89%	19.61%	19.34%	19.08%	18.83%	18.58%	18.34%	18.10%	17.87%	17.65%	17.43%	17.22%	17.01%	16.81%	16.61%	16.42%	16.23%	16.04%	15.86%	15.69%	15.52%	15.35%	15.19%	15.03%	14.87%	14.71%	14.56%	14.42%	14.27%	14.13%	13.99%	13.86%	13.72%	13.59%	13.47%	13.34%	13.22%	13.09%	12.98%	12.86%	12.74%	12.63%	12.52%	12.41%	12.30%	12.20%	12.10%	11.99%	11.89%	11.80%	11.70%	11.60%	11.51%	11.42%	11.33%	11.24%	11.15%	11.07%	10.98%	10.90%	10.81%	10.73%	10.65%	10.57%	10.50%	10.42%	10.35%	10.27%	10.20%	10.13%	10.05%	9.98%	9.92%	9.85%	9.78%	9.72%	9.65%	9.59%	9.52%	9.46%	9.40%	9.34%	9.28%	9.22%	9.16%	9.10%	9.04%	8.99%	8.93%	8.87%	8.82%	8.77%	8.71%	8.66%	8.61%	8.56%	8.50%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.93%	98.50%	95.64%	91.77%	87.52%	83.59%	79.59%	75.67%	71.99%	68.56%	65.45%	62.60%	59.90%	57.43%	55.15%	53.04%	51.11%	49.29%	47.60%	46.01%	44.52%	43.12%	41.80%	40.56%	39.38%	38.28%	37.24%	36.26%	35.33%	34.45%	33.62%	32.83%	32.08%	31.36%	30.68%	30.02%	29.39%	28.80%	28.22%	27.67%	27.13%	26.62%	26.13%	25.66%	25.20%	24.76%	24.34%	23.93%	23.54%	23.16%	22.78%	22.42%	22.08%	21.74%	21.41%	21.09%	20.78%	20.48%	20.20%	19.91%	19.64%	19.37%	19.12%	18.86%	18.62%	18.38%	18.15%	17.92%	17.70%	17.49%	17.28%	17.07%	16.87%	16.67%	16.48%	16.29%	16.11%	15.93%	15.76%	15.59%	15.42%	15.26%	15.10%	14.94%	14.79%	14.64%	14.49%	14.35%	14.21%	14.07%	13.94%	13.81%	13.68%	13.55%	13.42%	13.30%	13.18%	13.06%	12.94%	12.83%	12.72%	12.61%	12.50%	12.39%	12.29%	12.18%	12.08%	11.98%	11.88%	11.79%	11.69%	11.60%	11.51%	11.42%	11.33%	11.24%	11.15%	11.07%	10.99%	10.90%	10.82%	10.74%	10.66%	10.59%	10.51%	10.43%	10.36%	10.29%	10.21%	10.14%	10.07%	10.00%	9.94%	9.87%	9.80%	9.74%	9.67%	9.61%	9.55%	9.48%	9.42%	9.36%	9.30%	9.24%	9.19%	9.13%	9.07%	9.02%	8.96%	8.90%	8.85%	8.80%	8.74%	8.69%	8.64%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.98%	98.85%	96.16%	92.48%	88.29%	84.38%	80.43%	76.54%	72.82%	69.39%	66.24%	63.39%	60.70%	58.19%	55.90%	53.78%	51.81%	49.98%	48.25%	46.64%	45.12%	43.71%	42.38%	41.12%	39.94%	38.81%	37.76%	36.76%	35.82%	34.93%	34.09%	33.29%	32.53%	31.80%	31.10%	30.44%	29.80%	29.20%	28.61%	28.05%	27.52%	27.00%	26.50%	26.02%	25.56%	25.11%	24.68%	24.27%	23.87%	23.48%	23.11%	22.74%	22.39%	22.05%	21.72%	21.40%	21.08%	20.78%	20.49%	20.20%	19.92%	19.65%	19.39%	19.13%	18.88%	18.64%	18.41%	18.18%	17.95%	17.74%	17.52%	17.31%	17.11%	16.91%	16.72%	16.53%	16.34%	16.16%	15.98%	15.81%	15.64%	15.47%	15.31%	15.15%	15.00%	14.85%	14.70%	14.56%	14.41%	14.27%	14.14%	14.00%	13.87%	13.74%	13.62%	13.49%	13.37%	13.25%	13.13%	13.01%	12.90%	12.79%	12.68%	12.57%	12.47%	12.36%	12.26%	12.16%	12.06%	11.96%	11.86%	11.77%	11.68%	11.58%	11.49%	11.41%	11.32%	11.23%	11.15%	11.06%	10.98%	10.90%	10.82%	10.74%	10.66%	10.59%	10.51%	10.44%	10.37%	10.29%	10.22%	10.15%	10.08%	10.01%	9.95%	9.88%	9.82%	9.75%	9.69%	9.62%	9.56%	9.50%	9.44%	9.38%	9.32%	9.26%	9.21%	9.15%	9.09%	9.04%	8.98%	8.93%	8.88%	8.82%	8.77%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.13%	96.60%	93.10%	89.01%	85.06%	81.19%	77.32%	73.59%	70.15%	66.99%	64.10%	61.42%	58.91%	56.60%	54.46%	52.47%	50.61%	48.86%	47.22%	45.69%	44.25%	42.91%	41.65%	40.45%	39.32%	38.25%	37.24%	36.29%	35.38%	34.53%	33.72%	32.94%	32.21%	31.50%	30.83%	30.19%	29.57%	28.98%	28.41%	27.87%	27.35%	26.84%	26.35%	25.89%	25.43%	25.00%	24.58%	24.18%	23.79%	23.41%	23.04%	22.69%	22.34%	22.00%	21.68%	21.36%	21.06%	20.76%	20.47%	20.19%	19.91%	19.65%	19.39%	19.13%	18.89%	18.65%	18.42%	18.19%	17.97%	17.76%	17.55%	17.34%	17.14%	16.94%	16.75%	16.56%	16.38%	16.20%	16.03%	15.85%	15.69%	15.52%	15.36%	15.20%	15.05%	14.90%	14.75%	14.61%	14.47%	14.33%	14.19%	14.06%	13.93%	13.80%	13.67%	13.55%	13.43%	13.31%	13.19%	13.08%	12.96%	12.85%	12.74%	12.64%	12.53%	12.43%	12.32%	12.22%	12.12%	12.03%	11.93%	11.84%	11.74%	11.65%	11.56%	11.47%	11.39%	11.30%	11.22%	11.13%	11.05%	10.97%	10.89%	10.81%	10.74%	10.66%	10.58%	10.51%	10.44%	10.37%	10.29%	10.22%	10.16%	10.09%	10.02%	9.95%	9.89%	9.82%	9.76%	9.70%	9.63%	9.57%	9.51%	9.45%	9.39%	9.34%	9.28%	9.22%	9.17%	9.11%	9.06%	9.00%	8.95%	8.89%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.34%	97.00%	93.67%	89.69%	85.71%	81.90%	78.04%	74.31%	70.87%	67.69%	64.76%	62.09%	59.58%	57.24%	55.09%	53.08%	51.19%	49.43%	47.77%	46.22%	44.77%	43.41%	42.14%	40.93%	39.79%	38.71%	37.69%	36.72%	35.81%	34.94%	34.12%	33.33%	32.59%	31.88%	31.20%	30.55%	29.92%	29.32%	28.75%	28.20%	27.67%	27.17%	26.67%	26.20%	25.74%	25.30%	24.88%	24.47%	24.07%	23.69%	23.32%	22.96%	22.62%	22.28%	21.95%	21.63%	21.32%	21.01%	20.72%	20.44%	20.16%	19.89%	19.63%	19.37%	19.12%	18.88%	18.65%	18.42%	18.20%	17.98%	17.76%	17.56%	17.35%	17.16%	16.96%	16.77%	16.59%	16.41%	16.23%	16.05%	15.88%	15.72%	15.55%	15.40%	15.24%	15.09%	14.94%	14.79%	14.65%	14.51%	14.37%	14.24%	14.10%	13.97%	13.85%	13.72%	13.60%	13.48%	13.36%	13.24%	13.13%	13.02%	12.91%	12.80%	12.69%	12.59%	12.48%	12.38%	12.28%	12.18%	12.09%	11.99%	11.90%	11.80%	11.71%	11.62%	11.54%	11.45%	11.36%	11.28%	11.20%	11.12%	11.03%	10.95%	10.88%	10.80%	10.72%	10.65%	10.57%	10.50%	10.43%	10.36%	10.29%	10.22%	10.15%	10.09%	10.02%	9.95%	9.89%	9.82%	9.76%	9.70%	9.64%	9.58%	9.52%	9.46%	9.40%	9.34%	9.29%	9.23%	9.18%	9.12%	9.07%	9.01%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.49%	97.38%	94.19%	90.32%	86.33%	82.54%	78.70%	74.98%	71.52%	68.34%	65.38%	62.69%	60.19%	57.85%	55.68%	53.64%	51.73%	49.95%	48.27%	46.70%	45.24%	43.87%	42.59%	41.37%	40.22%	39.13%	38.10%	37.12%	36.20%	35.32%	34.49%	33.70%	32.95%	32.23%	31.54%	30.88%	30.25%	29.65%	29.07%	28.51%	27.98%	27.47%	26.97%	26.50%	26.03%	25.59%	25.16%	24.74%	24.34%	23.96%	23.58%	23.22%	22.87%	22.53%	22.20%	21.87%	21.56%	21.26%	20.96%	20.67%	20.39%	20.12%	19.85%	19.60%	19.35%	19.10%	18.86%	18.63%	18.40%	18.18%	17.97%	17.76%	17.56%	17.36%	17.16%	16.97%	16.78%	16.60%	16.42%	16.24%	16.07%	15.90%	15.74%	15.58%	15.42%	15.26%	15.11%	14.97%	14.82%	14.68%	14.54%	14.40%	14.27%	14.14%	14.01%	13.88%	13.76%	13.64%	13.52%	13.40%	13.29%	13.17%	13.06%	12.95%	12.84%	12.74%	12.63%	12.53%	12.43%	12.33%	12.23%	12.14%	12.04%	11.95%	11.86%	11.77%	11.68%	11.59%	11.50%	11.42%	11.33%	11.25%	11.17%	11.09%	11.01%	10.93%	10.86%	10.78%	10.70%	10.63%	10.56%	10.49%	10.42%	10.35%	10.28%	10.21%	10.14%	10.08%	10.01%	9.95%	9.88%	9.82%	9.76%	9.70%	9.64%	9.58%	9.52%	9.46%	9.40%	9.35%	9.29%	9.23%	9.18%	9.13%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.58%	97.70%	94.59%	90.90%	86.91%	83.09%	79.32%	75.61%	72.13%	68.94%	65.97%	63.23%	60.73%	58.40%	56.19%	54.14%	52.22%	50.42%	48.73%	47.15%	45.68%	44.30%	43.00%	41.77%	40.62%	39.52%	38.48%	37.50%	36.56%	35.68%	34.84%	34.04%	33.27%	32.55%	31.86%	31.19%	30.56%	29.95%	29.36%	28.80%	28.26%	27.75%	27.25%	26.77%	26.30%	25.85%	25.42%	25.00%	24.60%	24.20%	23.83%	23.46%	23.11%	22.76%	22.43%	22.11%	21.79%	21.48%	21.18%	20.89%	20.61%	20.33%	20.07%	19.81%	19.55%	19.31%	19.07%	18.83%	18.60%	18.38%	18.16%	17.95%	17.74%	17.54%	17.35%	17.15%	16.97%	16.78%	16.60%	16.42%	16.25%	16.08%	15.91%	15.75%	15.59%	15.43%	15.28%	15.13%	14.98%	14.84%	14.70%	14.56%	14.43%	14.29%	14.16%	14.04%	13.91%	13.79%	13.67%	13.55%	13.43%	13.32%	13.21%	13.10%	12.99%	12.88%	12.77%	12.67%	12.57%	12.47%	12.37%	12.27%	12.18%	12.08%	11.99%	11.90%	11.81%	11.72%	11.63%	11.55%	11.46%	11.38%	11.30%	11.21%	11.13%	11.06%	10.98%	10.90%	10.83%	10.75%	10.68%	10.61%	10.54%	10.46%	10.40%	10.33%	10.26%	10.19%	10.13%	10.06%	10.00%	9.93%	9.87%	9.81%	9.75%	9.69%	9.63%	9.57%	9.51%	9.45%	9.40%	9.34%	9.29%	9.23%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.66%	97.97%	94.93%	91.42%	87.47%	83.59%	79.86%	76.15%	72.66%	69.47%	66.49%	63.73%	61.21%	58.86%	56.65%	54.58%	52.65%	50.84%	49.14%	47.55%	46.07%	44.68%	43.37%	42.14%	40.97%	39.87%	38.83%	37.84%	36.89%	36.00%	35.15%	34.35%	33.58%	32.85%	32.15%	31.48%	30.84%	30.22%	29.63%	29.07%	28.52%	28.00%	27.50%	27.02%	26.55%	26.10%	25.66%	25.24%	24.83%	24.44%	24.05%	23.68%	23.33%	22.98%	22.65%	22.32%	22.01%	21.70%	21.39%	21.10%	20.81%	20.54%	20.27%	20.00%	19.75%	19.50%	19.26%	19.02%	18.79%	18.57%	18.35%	18.13%	17.92%	17.72%	17.52%	17.33%	17.14%	16.95%	16.77%	16.59%	16.42%	16.25%	16.08%	15.92%	15.75%	15.60%	15.44%	15.29%	15.14%	15.00%	14.85%	14.71%	14.58%	14.44%	14.31%	14.18%	14.06%	13.93%	13.81%	13.69%	13.57%	13.46%	13.34%	13.23%	13.12%	13.01%	12.91%	12.80%	12.70%	12.60%	12.50%	12.40%	12.30%	12.21%	12.12%	12.02%	11.93%	11.84%	11.76%	11.67%	11.58%	11.50%	11.42%	11.33%	11.25%	11.17%	11.10%	11.02%	10.94%	10.87%	10.79%	10.72%	10.65%	10.58%	10.51%	10.44%	10.37%	10.30%	10.24%	10.17%	10.11%	10.04%	9.98%	9.92%	9.85%	9.79%	9.73%	9.67%	9.62%	9.56%	9.50%	9.44%	9.39%	9.33%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.73%	98.20%	95.24%	91.86%	87.95%	84.03%	80.33%	76.62%	73.12%	69.91%	66.95%	64.17%	61.63%	59.26%	57.05%	54.97%	53.03%	51.22%	49.51%	47.92%	46.43%	45.03%	43.71%	42.48%	41.30%	40.19%	39.14%	38.15%	37.21%	36.31%	35.45%	34.64%	33.86%	33.12%	32.42%	31.74%	31.10%	30.48%	29.89%	29.32%	28.77%	28.24%	27.74%	27.25%	26.78%	26.33%	25.89%	25.47%	25.05%	24.66%	24.27%	23.90%	23.54%	23.19%	22.85%	22.52%	22.20%	21.89%	21.59%	21.29%	21.01%	20.73%	20.45%	20.19%	19.93%	19.68%	19.44%	19.20%	18.97%	18.74%	18.52%	18.30%	18.09%	17.88%	17.68%	17.49%	17.29%	17.11%	16.92%	16.74%	16.57%	16.40%	16.23%	16.06%	15.90%	15.74%	15.59%	15.43%	15.28%	15.14%	14.99%	14.85%	14.71%	14.58%	14.45%	14.32%	14.19%	14.06%	13.94%	13.82%	13.70%	13.58%	13.47%	13.35%	13.24%	13.14%	13.03%	12.92%	12.82%	12.72%	12.62%	12.52%	12.42%	12.33%	12.23%	12.14%	12.05%	11.96%	11.87%	11.78%	11.70%	11.61%	11.53%	11.45%	11.36%	11.28%	11.20%	11.13%	11.05%	10.97%	10.90%	10.83%	10.75%	10.68%	10.61%	10.54%	10.47%	10.41%	10.34%	10.27%	10.21%	10.14%	10.08%	10.02%	9.95%	9.89%	9.83%	9.77%	9.71%	9.65%	9.60%	9.54%	9.48%	9.43%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.79%	98.39%	95.54%	92.23%	88.38%	84.42%	80.73%	77.01%	73.53%	70.32%	67.36%	64.59%	62.00%	59.60%	57.39%	55.31%	53.37%	51.55%	49.85%	48.25%	46.75%	45.35%	44.02%	42.77%	41.59%	40.47%	39.42%	38.42%	37.48%	36.57%	35.71%	34.89%	34.12%	33.37%	32.66%	31.98%	31.33%	30.71%	30.11%	29.54%	28.99%	28.46%	27.95%	27.46%	26.98%	26.53%	26.09%	25.66%	25.25%	24.85%	24.46%	24.08%	23.72%	23.37%	23.02%	22.69%	22.37%	22.06%	21.76%	21.46%	21.17%	20.89%	20.62%	20.35%	20.09%	19.83%	19.59%	19.35%	19.12%	18.89%	18.67%	18.45%	18.23%	18.03%	17.82%	17.63%	17.43%	17.24%	17.06%	16.88%	16.70%	16.53%	16.36%	16.20%	16.04%	15.88%	15.72%	15.57%	15.41%	15.27%	15.12%	14.98%	14.84%	14.70%	14.57%	14.44%	14.31%	14.18%	14.06%	13.93%	13.81%	13.70%	13.58%	13.47%	13.36%	13.25%	13.14%	13.03%	12.93%	12.83%	12.73%	12.63%	12.53%	12.43%	12.34%	12.24%	12.15%	12.06%	11.97%	11.88%	11.80%	11.71%	11.63%	11.55%	11.46%	11.38%	11.30%	11.22%	11.15%	11.07%	11.00%	10.92%	10.85%	10.78%	10.71%	10.64%	10.57%	10.50%	10.43%	10.36%	10.30%	10.23%	10.17%	10.11%	10.04%	9.98%	9.92%	9.86%	9.80%	9.74%	9.68%	9.63%	9.57%	9.51%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.84%	98.55%	95.83%	92.57%	88.71%	84.74%	81.03%	77.35%	73.87%	70.65%	67.69%	64.93%	62.31%	59.89%	57.68%	55.61%	53.66%	51.85%	50.14%	48.54%	47.04%	45.63%	44.29%	43.03%	41.85%	40.73%	39.66%	38.66%	37.71%	36.81%	35.95%	35.13%	34.34%	33.59%	32.88%	32.19%	31.53%	30.90%	30.30%	29.73%	29.17%	28.64%	28.13%	27.63%	27.16%	26.70%	26.26%	25.83%	25.42%	25.01%	24.62%	24.25%	23.88%	23.53%	23.18%	22.85%	22.52%	22.21%	21.91%	21.61%	21.32%	21.04%	20.77%	20.50%	20.23%	19.98%	19.73%	19.49%	19.25%	19.03%	18.80%	18.58%	18.37%	18.16%	17.96%	17.76%	17.56%	17.37%	17.19%	17.01%	16.83%	16.66%	16.49%	16.32%	16.16%	16.00%	15.84%	15.69%	15.54%	15.39%	15.24%	15.10%	14.96%	14.82%	14.68%	14.55%	14.42%	14.29%	14.17%	14.04%	13.92%	13.80%	13.69%	13.57%	13.46%	13.35%	13.24%	13.13%	13.03%	12.93%	12.83%	12.73%	12.63%	12.53%	12.43%	12.34%	12.25%	12.16%	12.07%	11.98%	11.89%	11.81%	11.72%	11.64%	11.56%	11.48%	11.40%	11.32%	11.24%	11.16%	11.09%	11.01%	10.94%	10.87%	10.79%	10.72%	10.65%	10.58%	10.52%	10.45%	10.38%	10.32%	10.25%	10.19%	10.13%	10.07%	10.00%	9.94%	9.88%	9.82%	9.77%	9.71%	9.65%	9.59%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.88%	98.67%	96.11%	92.85%	88.99%	85.03%	81.29%	77.66%	74.17%	70.95%	67.98%	65.22%	62.59%	60.15%	57.92%	55.86%	53.92%	52.11%	50.41%	48.81%	47.30%	45.88%	44.54%	43.27%	42.08%	40.96%	39.89%	38.88%	37.93%	37.02%	36.16%	35.33%	34.54%	33.79%	33.06%	32.37%	31.71%	31.08%	30.47%	29.90%	29.34%	28.81%	28.29%	27.79%	27.32%	26.85%	26.41%	25.98%	25.56%	25.16%	24.77%	24.40%	24.03%	23.67%	23.33%	22.99%	22.66%	22.35%	22.04%	21.75%	21.46%	21.18%	20.90%	20.63%	20.37%	20.11%	19.86%	19.62%	19.38%	19.15%	18.92%	18.71%	18.49%	18.28%	18.08%	17.88%	17.68%	17.49%	17.30%	17.12%	16.94%	16.77%	16.60%	16.43%	16.27%	16.11%	15.95%	15.79%	15.64%	15.49%	15.35%	15.20%	15.06%	14.92%	14.79%	14.65%	14.52%	14.39%	14.27%	14.14%	14.02%	13.90%	13.78%	13.67%	13.55%	13.44%	13.33%	13.22%	13.12%	13.02%	12.91%	12.81%	12.71%	12.62%	12.52%	12.43%	12.33%	12.24%	12.15%	12.06%	11.97%	11.89%	11.80%	11.72%	11.64%	11.56%	11.48%	11.40%	11.32%	11.24%	11.17%	11.09%	11.02%	10.94%	10.87%	10.80%	10.73%	10.66%	10.59%	10.53%	10.46%	10.39%	10.33%	10.26%	10.20%	10.14%	10.08%	10.02%	9.96%	9.90%	9.84%	9.78%	9.72%	9.66%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.91%	98.77%	96.35%	93.10%	89.24%	85.30%	81.54%	77.95%	74.44%	71.22%	68.25%	65.47%	62.85%	60.39%	58.14%	56.08%	54.15%	52.34%	50.64%	49.04%	47.54%	46.11%	44.76%	43.49%	42.29%	41.16%	40.10%	39.08%	38.12%	37.21%	36.34%	35.51%	34.72%	33.96%	33.23%	32.54%	31.87%	31.24%	30.63%	30.05%	29.49%	28.96%	28.44%	27.94%	27.46%	26.99%	26.55%	26.11%	25.70%	25.29%	24.90%	24.53%	24.16%	23.80%	23.45%	23.12%	22.79%	22.47%	22.16%	21.86%	21.57%	21.29%	21.01%	20.74%	20.48%	20.22%	19.97%	19.73%	19.49%	19.26%	19.03%	18.81%	18.60%	18.39%	18.18%	17.98%	17.78%	17.59%	17.40%	17.22%	17.04%	16.86%	16.69%	16.52%	16.36%	16.20%	16.04%	15.89%	15.73%	15.59%	15.44%	15.29%	15.15%	15.01%	14.88%	14.74%	14.61%	14.48%	14.35%	14.23%	14.11%	13.99%	13.87%	13.75%	13.64%	13.52%	13.41%	13.31%	13.20%	13.09%	12.99%	12.89%	12.79%	12.69%	12.60%	12.50%	12.41%	12.32%	12.23%	12.14%	12.05%	11.96%	11.88%	11.79%	11.71%	11.63%	11.55%	11.47%	11.39%	11.31%	11.24%	11.16%	11.09%	11.01%	10.94%	10.87%	10.80%	10.73%	10.66%	10.59%	10.53%	10.46%	10.39%	10.33%	10.27%	10.20%	10.14%	10.08%	10.02%	9.96%	9.90%	9.84%	9.79%	9.73%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.93%	98.86%	96.57%	93.34%	89.48%	85.57%	81.78%	78.21%	74.70%	71.48%	68.49%	65.70%	63.07%	60.60%	58.34%	56.26%	54.34%	52.54%	50.84%	49.26%	47.75%	46.31%	44.95%	43.68%	42.48%	41.35%	40.27%	39.26%	38.29%	37.37%	36.50%	35.67%	34.87%	34.11%	33.38%	32.68%	32.01%	31.38%	30.77%	30.18%	29.62%	29.09%	28.57%	28.07%	27.58%	27.11%	26.66%	26.23%	25.81%	25.41%	25.02%	24.64%	24.27%	23.91%	23.56%	23.22%	22.90%	22.58%	22.27%	21.96%	21.67%	21.39%	21.11%	20.84%	20.58%	20.32%	20.07%	19.83%	19.59%	19.36%	19.13%	18.91%	18.69%	18.48%	18.27%	18.07%	17.88%	17.68%	17.49%	17.31%	17.13%	16.95%	16.78%	16.61%	16.45%	16.28%	16.13%	15.97%	15.82%	15.67%	15.52%	15.38%	15.23%	15.10%	14.96%	14.82%	14.69%	14.56%	14.43%	14.31%	14.19%	14.06%	13.94%	13.83%	13.71%	13.60%	13.49%	13.38%	13.27%	13.17%	13.06%	12.96%	12.86%	12.76%	12.67%	12.57%	12.48%	12.38%	12.29%	12.20%	12.12%	12.03%	11.94%	11.86%	11.77%	11.69%	11.61%	11.53%	11.45%	11.37%	11.30%	11.22%	11.15%	11.07%	11.00%	10.93%	10.86%	10.79%	10.72%	10.65%	10.59%	10.52%	10.45%	10.39%	10.33%	10.26%	10.20%	10.14%	10.08%	10.02%	9.96%	9.90%	9.84%	9.79%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.94%	98.94%	96.76%	93.57%	89.71%	85.82%	82.01%	78.44%	74.94%	71.70%	68.68%	65.89%	63.24%	60.78%	58.51%	56.42%	54.51%	52.72%	51.03%	49.44%	47.93%	46.49%	45.13%	43.85%	42.64%	41.51%	40.43%	39.41%	38.44%	37.51%	36.64%	35.80%	35.01%	34.24%	33.51%	32.81%	32.14%	31.50%	30.89%	30.30%	29.74%	29.20%	28.68%	28.18%	27.69%	27.22%	26.77%	26.34%	25.92%	25.51%	25.12%	24.74%	24.37%	24.01%	23.66%	23.32%	22.99%	22.67%	22.36%	22.06%	21.76%	21.48%	21.20%	20.93%	20.67%	20.41%	20.16%	19.92%	19.68%	19.44%	19.22%	18.99%	18.78%	18.56%	18.36%	18.15%	17.96%	17.77%	17.58%	17.39%	17.21%	17.03%	16.86%	16.69%	16.52%	16.36%	16.20%	16.05%	15.89%	15.74%	15.60%	15.45%	15.31%	15.17%	15.03%	14.90%	14.77%	14.63%	14.51%	14.38%	14.26%	14.14%	14.02%	13.90%	13.78%	13.67%	13.56%	13.45%	13.34%	13.23%	13.13%	13.03%	12.93%	12.83%	12.73%	12.63%	12.54%	12.45%	12.35%	12.26%	12.18%	12.09%	12.00%	11.92%	11.83%	11.75%	11.67%	11.59%	11.51%	11.43%	11.35%	11.28%	11.20%	11.13%	11.06%	10.99%	10.91%	10.84%	10.78%	10.71%	10.64%	10.57%	10.51%	10.44%	10.38%	10.32%	10.25%	10.19%	10.13%	10.07%	10.01%	9.95%	9.89%	9.84%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.96%	99.00%	96.94%	93.79%	89.93%	86.05%	82.23%	78.66%	75.16%	71.89%	68.87%	66.05%	63.40%	60.95%	58.67%	56.58%	54.65%	52.87%	51.19%	49.60%	48.10%	46.66%	45.29%	44.01%	42.80%	41.66%	40.58%	39.56%	38.58%	37.65%	36.77%	35.93%	35.13%	34.36%	33.63%	32.93%	32.26%	31.62%	31.01%	30.42%	29.85%	29.31%	28.79%	28.28%	27.80%	27.33%	26.88%	26.44%	26.02%	25.61%	25.21%	24.83%	24.46%	24.10%	23.75%	23.41%	23.08%	22.76%	22.45%	22.15%	21.85%	21.56%	21.28%	21.01%	20.75%	20.49%	20.24%	20.00%	19.76%	19.53%	19.30%	19.07%	18.86%	18.64%	18.43%	18.23%	18.03%	17.84%	17.65%	17.47%	17.28%	17.11%	16.93%	16.76%	16.60%	16.43%	16.27%	16.12%	15.96%	15.81%	15.66%	15.52%	15.37%	15.23%	15.10%	14.96%	14.83%	14.70%	14.57%	14.45%	14.32%	14.20%	14.08%	13.96%	13.85%	13.73%	13.62%	13.51%	13.40%	13.29%	13.19%	13.09%	12.99%	12.89%	12.79%	12.69%	12.60%	12.50%	12.41%	12.32%	12.23%	12.14%	12.06%	11.97%	11.89%	11.80%	11.72%	11.64%	11.56%	11.48%	11.41%	11.33%	11.25%	11.18%	11.11%	11.03%	10.96%	10.89%	10.82%	10.76%	10.69%	10.62%	10.56%	10.49%	10.43%	10.36%	10.30%	10.24%	10.18%	10.12%	10.06%	10.00%	9.94%	9.88%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.97%	99.05%	97.09%	94.00%	90.13%	86.27%	82.44%	78.87%	75.36%	72.07%	69.04%	66.20%	63.56%	61.10%	58.82%	56.72%	54.80%	53.01%	51.35%	49.75%	48.25%	46.82%	45.45%	44.16%	42.96%	41.81%	40.72%	39.69%	38.72%	37.78%	36.89%	36.05%	35.25%	34.48%	33.75%	33.05%	32.38%	31.74%	31.12%	30.53%	29.96%	29.41%	28.89%	28.38%	27.89%	27.42%	26.97%	26.53%	26.11%	25.70%	25.30%	24.92%	24.55%	24.19%	23.84%	23.50%	23.16%	22.84%	22.53%	22.23%	21.93%	21.64%	21.36%	21.09%	20.82%	20.57%	20.32%	20.07%	19.83%	19.60%	19.37%	19.15%	18.93%	18.71%	18.50%	18.30%	18.10%	17.91%	17.72%	17.53%	17.35%	17.17%	17.00%	16.83%	16.66%	16.50%	16.33%	16.18%	16.02%	15.87%	15.72%	15.57%	15.43%	15.29%	15.15%	15.02%	14.89%	14.76%	14.63%	14.50%	14.38%	14.26%	14.14%	14.02%	13.90%	13.79%	13.67%	13.56%	13.46%	13.35%	13.24%	13.14%	13.04%	12.94%	12.84%	12.74%	12.65%	12.55%	12.46%	12.37%	12.28%	12.19%	12.10%	12.02%	11.93%	11.85%	11.77%	11.69%	11.61%	11.53%	11.45%	11.37%	11.30%	11.22%	11.15%	11.08%	11.01%	10.94%	10.87%	10.80%	10.73%	10.66%	10.60%	10.53%	10.47%	10.41%	10.34%	10.28%	10.22%	10.16%	10.10%	10.04%	9.98%	9.92%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.98%	99.09%	97.22%	94.20%	90.33%	86.48%	82.65%	79.08%	75.55%	72.23%	69.18%	66.34%	63.70%	61.23%	58.97%	56.86%	54.93%	53.15%	51.48%	49.89%	48.40%	46.96%	45.60%	44.31%	43.09%	41.94%	40.85%	39.82%	38.83%	37.90%	37.01%	36.16%	35.35%	34.58%	33.85%	33.15%	32.48%	31.84%	31.22%	30.63%	30.05%	29.50%	28.98%	28.47%	27.98%	27.51%	27.05%	26.61%	26.19%	25.78%	25.38%	25.00%	24.63%	24.26%	23.91%	23.57%	23.24%	22.92%	22.60%	22.30%	22.00%	21.71%	21.43%	21.16%	20.89%	20.63%	20.38%	20.14%	19.90%	19.66%	19.44%	19.21%	18.99%	18.78%	18.57%	18.36%	18.16%	17.97%	17.77%	17.59%	17.41%	17.23%	17.05%	16.88%	16.72%	16.55%	16.39%	16.23%	16.08%	15.92%	15.77%	15.63%	15.48%	15.34%	15.21%	15.07%	14.94%	14.81%	14.68%	14.55%	14.43%	14.31%	14.19%	14.07%	13.95%	13.84%	13.73%	13.61%	13.51%	13.40%	13.29%	13.19%	13.09%	12.99%	12.89%	12.79%	12.69%	12.60%	12.50%	12.41%	12.32%	12.23%	12.15%	12.06%	11.98%	11.89%	11.81%	11.73%	11.65%	11.57%	11.49%	11.42%	11.34%	11.27%	11.19%	11.12%	11.05%	10.98%	10.91%	10.84%	10.77%	10.70%	10.64%	10.57%	10.51%	10.45%	10.38%	10.32%	10.26%	10.20%	10.14%	10.08%	10.02%	9.96%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.12%	97.34%	94.37%	90.53%	86.67%	82.85%	79.27%	75.72%	72.39%	69.30%	66.48%	63.83%	61.37%	59.10%	57.00%	55.07%	53.27%	51.60%	50.02%	48.53%	47.10%	45.74%	44.44%	43.22%	42.07%	40.97%	39.93%	38.95%	38.01%	37.12%	36.27%	35.46%	34.69%	33.95%	33.25%	32.58%	31.94%	31.32%	30.72%	30.15%	29.59%	29.06%	28.55%	28.06%	27.59%	27.13%	26.69%	26.27%	25.86%	25.46%	25.07%	24.70%	24.34%	23.98%	23.64%	23.31%	22.99%	22.67%	22.37%	22.07%	21.78%	21.50%	21.23%	20.96%	20.70%	20.45%	20.20%	19.96%	19.73%	19.50%	19.27%	19.05%	18.84%	18.63%	18.42%	18.22%	18.02%	17.83%	17.65%	17.46%	17.28%	17.11%	16.94%	16.77%	16.60%	16.44%	16.28%	16.13%	15.98%	15.83%	15.68%	15.54%	15.40%	15.26%	15.12%	14.99%	14.86%	14.73%	14.60%	14.48%	14.36%	14.24%	14.12%	14.00%	13.89%	13.77%	13.66%	13.55%	13.45%	13.34%	13.24%	13.13%	13.03%	12.93%	12.83%	12.74%	12.64%	12.55%	12.46%	12.37%	12.28%	12.19%	12.10%	12.02%	11.93%	11.85%	11.77%	11.69%	11.61%	11.53%	11.46%	11.38%	11.30%	11.23%	11.16%	11.09%	11.02%	10.95%	10.88%	10.81%	10.74%	10.68%	10.61%	10.55%	10.48%	10.42%	10.36%	10.30%	10.23%	10.17%	10.12%	10.06%	10.00%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.16%	97.45%	94.53%	90.71%	86.85%	83.05%	79.46%	75.88%	72.54%	69.43%	66.60%	63.95%	61.50%	59.23%	57.14%	55.20%	53.40%	51.72%	50.14%	48.66%	47.23%	45.87%	44.58%	43.35%	42.19%	41.09%	40.05%	39.06%	38.11%	37.22%	36.37%	35.57%	34.79%	34.05%	33.35%	32.67%	32.03%	31.41%	30.81%	30.24%	29.68%	29.15%	28.64%	28.15%	27.67%	27.21%	26.77%	26.34%	25.93%	25.53%	25.15%	24.77%	24.41%	24.05%	23.71%	23.38%	23.05%	22.74%	22.43%	22.13%	21.84%	21.56%	21.29%	21.02%	20.76%	20.51%	20.26%	20.02%	19.78%	19.55%	19.33%	19.11%	18.90%	18.69%	18.48%	18.28%	18.08%	17.89%	17.70%	17.51%	17.34%	17.16%	16.99%	16.82%	16.65%	16.49%	16.33%	16.18%	16.02%	15.88%	15.73%	15.58%	15.44%	15.30%	15.17%	15.03%	14.90%	14.77%	14.65%	14.52%	14.40%	14.28%	14.16%	14.05%	13.93%	13.82%	13.71%	13.60%	13.49%	13.38%	13.28%	13.17%	13.07%	12.97%	12.88%	12.78%	12.68%	12.59%	12.50%	12.41%	12.32%	12.23%	12.14%	12.06%	11.97%	11.89%	11.81%	11.73%	11.65%	11.57%	11.49%	11.42%	11.34%	11.27%	11.19%	11.12%	11.05%	10.98%	10.91%	10.84%	10.78%	10.71%	10.65%	10.58%	10.52%	10.45%	10.39%	10.33%	10.27%	10.21%	10.15%	10.09%	10.03%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.20%	97.54%	94.68%	90.89%	87.02%	83.22%	79.63%	76.04%	72.67%	69.55%	66.72%	64.07%	61.63%	59.35%	57.27%	55.33%	53.52%	51.84%	50.26%	48.78%	47.36%	45.99%	44.70%	43.47%	42.30%	41.20%	40.15%	39.16%	38.22%	37.32%	36.47%	35.67%	34.89%	34.15%	33.44%	32.76%	32.11%	31.49%	30.89%	30.32%	29.76%	29.23%	28.72%	28.22%	27.74%	27.28%	26.84%	26.41%	26.00%	25.60%	25.21%	24.84%	24.47%	24.12%	23.77%	23.44%	23.11%	22.80%	22.49%	22.19%	21.90%	21.62%	21.35%	21.08%	20.82%	20.57%	20.32%	20.07%	19.84%	19.61%	19.38%	19.16%	18.95%	18.74%	18.53%	18.33%	18.13%	17.94%	17.75%	17.56%	17.38%	17.21%	17.03%	16.86%	16.70%	16.53%	16.38%	16.22%	16.07%	15.92%	15.77%	15.63%	15.49%	15.35%	15.21%	15.08%	14.94%	14.82%	14.69%	14.56%	14.44%	14.32%	14.20%	14.08%	13.97%	13.86%	13.74%	13.64%	13.53%	13.42%	13.32%	13.21%	13.11%	13.01%	12.91%	12.82%	12.72%	12.63%	12.53%	12.44%	12.35%	12.27%	12.18%	12.09%	12.01%	11.92%	11.84%	11.76%	11.68%	11.60%	11.53%	11.45%	11.37%	11.30%	11.23%	11.15%	11.08%	11.01%	10.94%	10.88%	10.81%	10.74%	10.68%	10.61%	10.55%	10.48%	10.42%	10.36%	10.30%	10.24%	10.18%	10.12%	10.06%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.23%	97.63%	94.83%	91.07%	87.18%	83.40%	79.79%	76.20%	72.79%	69.67%	66.82%	64.19%	61.74%	59.48%	57.39%	55.45%	53.64%	51.95%	50.37%	48.90%	47.47%	46.11%	44.81%	43.58%	42.41%	41.30%	40.25%	39.26%	38.31%	37.42%	36.57%	35.76%	34.98%	34.24%	33.53%	32.84%	32.19%	31.57%	30.97%	30.39%	29.83%	29.30%	28.78%	28.29%	27.81%	27.34%	26.90%	26.47%	26.06%	25.66%	25.27%	24.89%	24.53%	24.17%	23.83%	23.50%	23.17%	22.85%	22.54%	22.24%	21.95%	21.67%	21.40%	21.13%	20.87%	20.62%	20.37%	20.12%	19.89%	19.65%	19.43%	19.21%	18.99%	18.78%	18.57%	18.37%	18.17%	17.98%	17.79%	17.61%	17.42%	17.25%	17.07%	16.90%	16.74%	16.57%	16.41%	16.26%	16.11%	15.96%	15.81%	15.67%	15.52%	15.38%	15.25%	15.11%	14.98%	14.85%	14.72%	14.60%	14.47%	14.35%	14.23%	14.12%	14.00%	13.89%	13.78%	13.67%	13.56%	13.45%	13.35%	13.25%	13.15%	13.04%	12.95%	12.85%	12.75%	12.66%	12.57%	12.48%	12.39%	12.30%	12.21%	12.12%	12.04%	11.96%	11.87%	11.79%	11.71%	11.63%	11.56%	11.48%	11.40%	11.33%	11.26%	11.18%	11.11%	11.04%	10.97%	10.90%	10.84%	10.77%	10.70%	10.64%	10.57%	10.51%	10.45%	10.39%	10.32%	10.26%	10.20%	10.15%	10.09%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.27%	97.72%	94.97%	91.25%	87.33%	83.56%	79.95%	76.35%	72.91%	69.78%	66.93%	64.29%	61.86%	59.60%	57.51%	55.57%	53.76%	52.07%	50.49%	49.01%	47.58%	46.22%	44.92%	43.69%	42.51%	41.40%	40.35%	39.35%	38.41%	37.51%	36.66%	35.85%	35.07%	34.32%	33.61%	32.92%	32.27%	31.64%	31.04%	30.46%	29.90%	29.37%	28.85%	28.35%	27.87%	27.41%	26.96%	26.53%	26.12%	25.71%	25.33%	24.95%	24.58%	24.23%	23.88%	23.55%	23.22%	22.90%	22.59%	22.29%	22.00%	21.72%	21.45%	21.18%	20.92%	20.66%	20.41%	20.17%	19.93%	19.70%	19.47%	19.25%	19.03%	18.82%	18.61%	18.41%	18.21%	18.02%	17.83%	17.64%	17.46%	17.28%	17.11%	16.94%	16.77%	16.61%	16.45%	16.29%	16.14%	15.99%	15.84%	15.70%	15.56%	15.42%	15.28%	15.15%	15.01%	14.88%	14.75%	14.63%	14.50%	14.38%	14.26%	14.15%	14.03%	13.92%	13.81%	13.70%	13.59%	13.48%	13.38%	13.28%	13.17%	13.07%	12.98%	12.88%	12.78%	12.69%	12.60%	12.50%	12.41%	12.33%	12.24%	12.15%	12.07%	11.98%	11.90%	11.82%	11.74%	11.66%	11.58%	11.51%	11.43%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.93%	10.86%	10.79%	10.73%	10.66%	10.60%	10.53%	10.47%	10.41%	10.35%	10.29%	10.23%	10.17%	10.11%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.31%	97.80%	95.11%	91.41%	87.48%	83.72%	80.10%	76.49%	73.03%	69.90%	67.03%	64.39%	61.97%	59.72%	57.62%	55.69%	53.88%	52.19%	50.60%	49.11%	47.68%	46.32%	45.02%	43.78%	42.61%	41.49%	40.43%	39.44%	38.49%	37.60%	36.74%	35.93%	35.15%	34.40%	33.69%	33.00%	32.34%	31.71%	31.10%	30.52%	29.97%	29.43%	28.91%	28.41%	27.93%	27.46%	27.02%	26.59%	26.17%	25.77%	25.37%	25.00%	24.63%	24.27%	23.93%	23.60%	23.27%	22.95%	22.64%	22.34%	22.05%	21.76%	21.49%	21.22%	20.96%	20.70%	20.45%	20.21%	19.97%	19.73%	19.51%	19.28%	19.07%	18.86%	18.65%	18.44%	18.25%	18.05%	17.86%	17.68%	17.49%	17.32%	17.14%	16.97%	16.80%	16.64%	16.48%	16.32%	16.17%	16.02%	15.87%	15.73%	15.58%	15.44%	15.31%	15.17%	15.04%	14.91%	14.78%	14.65%	14.53%	14.41%	14.29%	14.17%	14.06%	13.94%	13.83%	13.72%	13.61%	13.51%	13.40%	13.30%	13.20%	13.10%	13.00%	12.90%	12.81%	12.71%	12.62%	12.53%	12.44%	12.35%	12.26%	12.18%	12.09%	12.01%	11.92%	11.84%	11.76%	11.68%	11.61%	11.53%	11.45%	11.38%	11.30%	11.23%	11.16%	11.09%	11.02%	10.95%	10.88%	10.81%	10.75%	10.68%	10.62%	10.55%	10.49%	10.43%	10.37%	10.31%	10.25%	10.19%	10.13%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.35%	97.88%	95.24%	91.54%	87.63%	83.86%	80.23%	76.61%	73.14%	70.01%	67.13%	64.49%	62.08%	59.82%	57.73%	55.79%	53.99%	52.30%	50.71%	49.21%	47.78%	46.42%	45.11%	43.87%	42.69%	41.58%	40.52%	39.52%	38.57%	37.68%	36.82%	36.01%	35.22%	34.47%	33.76%	33.07%	32.41%	31.77%	31.17%	30.59%	30.03%	29.49%	28.97%	28.47%	27.99%	27.52%	27.07%	26.64%	26.22%	25.81%	25.42%	25.04%	24.68%	24.32%	23.98%	23.64%	23.31%	22.99%	22.68%	22.38%	22.09%	21.81%	21.53%	21.26%	20.99%	20.74%	20.49%	20.24%	20.00%	19.77%	19.54%	19.32%	19.10%	18.89%	18.68%	18.48%	18.28%	18.09%	17.90%	17.71%	17.53%	17.35%	17.17%	17.00%	16.83%	16.67%	16.51%	16.35%	16.20%	16.04%	15.90%	15.75%	15.61%	15.47%	15.33%	15.20%	15.06%	14.93%	14.81%	14.68%	14.55%	14.43%	14.31%	14.19%	14.08%	13.97%	13.85%	13.74%	13.64%	13.53%	13.43%	13.32%	13.22%	13.12%	13.02%	12.93%	12.83%	12.74%	12.64%	12.55%	12.46%	12.37%	12.29%	12.20%	12.11%	12.03%	11.95%	11.87%	11.79%	11.71%	11.63%	11.55%	11.47%	11.40%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.90%	10.83%	10.77%	10.70%	10.64%	10.57%	10.51%	10.45%	10.39%	10.33%	10.27%	10.21%	10.15%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.38%	97.95%	95.36%	91.68%	87.77%	84.00%	80.36%	76.74%	73.26%	70.12%	67.24%	64.58%	62.17%	59.92%	57.84%	55.90%	54.10%	52.41%	50.82%	49.31%	47.87%	46.52%	45.19%	43.95%	42.77%	41.66%	40.61%	39.61%	38.66%	37.76%	36.90%	36.08%	35.29%	34.54%	33.82%	33.13%	32.47%	31.84%	31.23%	30.64%	30.08%	29.54%	29.02%	28.52%	28.04%	27.57%	27.13%	26.69%	26.27%	25.86%	25.47%	25.09%	24.72%	24.36%	24.02%	23.68%	23.35%	23.03%	22.72%	22.42%	22.13%	21.84%	21.56%	21.29%	21.03%	20.77%	20.52%	20.27%	20.04%	19.80%	19.58%	19.35%	19.14%	18.92%	18.71%	18.51%	18.31%	18.12%	17.92%	17.74%	17.55%	17.38%	17.20%	17.03%	16.86%	16.70%	16.53%	16.38%	16.22%	16.07%	15.92%	15.78%	15.63%	15.49%	15.35%	15.22%	15.09%	14.96%	14.83%	14.70%	14.58%	14.45%	14.33%	14.22%	14.10%	13.99%	13.88%	13.77%	13.66%	13.55%	13.45%	13.34%	13.24%	13.14%	13.04%	12.95%	12.85%	12.76%	12.67%	12.57%	12.48%	12.39%	12.31%	12.22%	12.14%	12.05%	11.97%	11.89%	11.81%	11.73%	11.65%	11.57%	11.49%	11.42%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.92%	10.85%	10.79%	10.72%	10.66%	10.59%	10.53%	10.47%	10.41%	10.35%	10.29%	10.23%	10.17%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.42%	98.02%	95.47%	91.81%	87.92%	84.12%	80.48%	76.86%	73.36%	70.23%	67.33%	64.68%	62.26%	60.01%	57.93%	56.01%	54.21%	52.52%	50.92%	49.41%	47.97%	46.59%	45.27%	44.02%	42.85%	41.74%	40.69%	39.68%	38.73%	37.83%	36.97%	36.15%	35.36%	34.61%	33.89%	33.20%	32.54%	31.90%	31.29%	30.70%	30.14%	29.60%	29.08%	28.58%	28.09%	27.63%	27.18%	26.74%	26.32%	25.91%	25.51%	25.13%	24.76%	24.41%	24.06%	23.72%	23.39%	23.07%	22.76%	22.46%	22.17%	21.88%	21.60%	21.33%	21.06%	20.80%	20.55%	20.31%	20.07%	19.83%	19.61%	19.38%	19.17%	18.95%	18.74%	18.54%	18.34%	18.14%	17.95%	17.76%	17.58%	17.40%	17.23%	17.05%	16.89%	16.72%	16.56%	16.40%	16.25%	16.09%	15.94%	15.80%	15.65%	15.51%	15.38%	15.24%	15.11%	14.98%	14.85%	14.72%	14.60%	14.48%	14.36%	14.24%	14.12%	14.01%	13.90%	13.79%	13.68%	13.57%	13.47%	13.36%	13.26%	13.16%	13.06%	12.97%	12.87%	12.78%	12.68%	12.59%	12.50%	12.41%	12.33%	12.24%	12.15%	12.07%	11.99%	11.90%	11.82%	11.74%	11.67%	11.59%	11.51%	11.44%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.94%	10.87%	10.80%	10.74%	10.67%	10.61%	10.55%	10.48%	10.42%	10.36%	10.30%	10.24%	10.18%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.46%	98.09%	95.56%	91.95%	88.06%	84.23%	80.59%	76.97%	73.47%	70.33%	67.43%	64.76%	62.34%	60.11%	58.03%	56.11%	54.31%	52.62%	51.02%	49.50%	48.05%	46.67%	45.34%	44.10%	42.92%	41.81%	40.76%	39.76%	38.80%	37.89%	37.03%	36.21%	35.42%	34.67%	33.94%	33.25%	32.59%	31.96%	31.34%	30.75%	30.19%	29.65%	29.13%	28.63%	28.14%	27.67%	27.22%	26.78%	26.36%	25.95%	25.56%	25.17%	24.80%	24.45%	24.10%	23.76%	23.43%	23.11%	22.80%	22.50%	22.20%	21.91%	21.63%	21.36%	21.09%	20.83%	20.58%	20.34%	20.10%	19.86%	19.63%	19.41%	19.20%	18.98%	18.77%	18.57%	18.37%	18.17%	17.98%	17.79%	17.61%	17.43%	17.25%	17.08%	16.91%	16.74%	16.58%	16.42%	16.27%	16.11%	15.97%	15.82%	15.68%	15.53%	15.40%	15.26%	15.13%	15.00%	14.87%	14.74%	14.62%	14.50%	14.38%	14.26%	14.14%	14.03%	13.91%	13.80%	13.69%	13.59%	13.48%	13.38%	13.28%	13.18%	13.08%	12.98%	12.89%	12.79%	12.70%	12.61%	12.52%	12.43%	12.34%	12.25%	12.17%	12.09%	12.00%	11.92%	11.84%	11.76%	11.68%	11.61%	11.53%	11.45%	11.38%	11.31%	11.23%	11.16%	11.09%	11.02%	10.95%	10.89%	10.82%	10.75%	10.69%	10.62%	10.56%	10.50%	10.44%	10.38%	10.32%	10.26%	10.20%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.49%	98.15%	95.66%	92.07%	88.18%	84.33%	80.70%	77.07%	73.57%	70.44%	67.53%	64.85%	62.42%	60.20%	58.12%	56.20%	54.40%	52.71%	51.11%	49.59%	48.13%	46.74%	45.41%	44.16%	42.99%	41.87%	40.83%	39.83%	38.87%	37.96%	37.09%	36.27%	35.48%	34.72%	34.00%	33.31%	32.64%	32.01%	31.39%	30.80%	30.24%	29.70%	29.18%	28.68%	28.19%	27.72%	27.26%	26.83%	26.40%	25.99%	25.60%	25.22%	24.85%	24.49%	24.14%	23.80%	23.47%	23.15%	22.83%	22.53%	22.23%	21.95%	21.66%	21.39%	21.12%	20.86%	20.61%	20.37%	20.12%	19.89%	19.66%	19.44%	19.22%	19.01%	18.80%	18.59%	18.39%	18.19%	18.00%	17.81%	17.63%	17.45%	17.27%	17.10%	16.93%	16.77%	16.60%	16.44%	16.29%	16.14%	15.99%	15.84%	15.70%	15.56%	15.42%	15.28%	15.15%	15.02%	14.89%	14.76%	14.64%	14.51%	14.39%	14.28%	14.16%	14.04%	13.93%	13.82%	13.71%	13.61%	13.50%	13.40%	13.30%	13.20%	13.10%	13.00%	12.90%	12.81%	12.72%	12.62%	12.53%	12.44%	12.36%	12.27%	12.18%	12.10%	12.02%	11.94%	11.86%	11.78%	11.70%	11.62%	11.54%	11.47%	11.40%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.90%	10.84%	10.77%	10.70%	10.64%	10.58%	10.51%	10.45%	10.39%	10.33%	10.27%	10.21%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.53%	98.22%	95.76%	92.17%	88.30%	84.44%	80.80%	77.18%	73.68%	70.53%	67.62%	64.93%	62.50%	60.28%	58.21%	56.29%	54.50%	52.80%	51.20%	49.67%	48.21%	46.81%	45.48%	44.23%	43.05%	41.94%	40.89%	39.89%	38.93%	38.02%	37.15%	36.33%	35.54%	34.78%	34.05%	33.36%	32.70%	32.06%	31.45%	30.86%	30.29%	29.75%	29.23%	28.72%	28.23%	27.76%	27.31%	26.87%	26.45%	26.04%	25.64%	25.26%	24.89%	24.53%	24.18%	23.84%	23.51%	23.18%	22.87%	22.56%	22.27%	21.98%	21.70%	21.42%	21.16%	20.90%	20.64%	20.39%	20.15%	19.92%	19.69%	19.47%	19.25%	19.03%	18.82%	18.62%	18.42%	18.22%	18.03%	17.84%	17.65%	17.47%	17.30%	17.12%	16.95%	16.79%	16.63%	16.47%	16.31%	16.16%	16.01%	15.86%	15.72%	15.58%	15.44%	15.30%	15.17%	15.04%	14.91%	14.78%	14.65%	14.53%	14.41%	14.29%	14.18%	14.06%	13.95%	13.84%	13.73%	13.62%	13.52%	13.41%	13.31%	13.21%	13.11%	13.01%	12.92%	12.82%	12.73%	12.64%	12.55%	12.46%	12.37%	12.28%	12.20%	12.12%	12.03%	11.95%	11.87%	11.79%	11.71%	11.64%	11.56%	11.48%	11.41%	11.34%	11.27%	11.19%	11.12%	11.05%	10.98%	10.92%	10.85%	10.78%	10.72%	10.65%	10.59%	10.53%	10.46%	10.40%	10.34%	10.28%	10.22%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.57%	98.28%	95.85%	92.27%	88.41%	84.55%	80.90%	77.28%	73.78%	70.61%	67.71%	65.01%	62.58%	60.36%	58.30%	56.38%	54.59%	52.89%	51.28%	49.75%	48.27%	46.88%	45.54%	44.29%	43.12%	42.00%	40.95%	39.95%	38.99%	38.08%	37.21%	36.38%	35.59%	34.83%	34.11%	33.41%	32.75%	32.11%	31.50%	30.91%	30.34%	29.80%	29.28%	28.77%	28.28%	27.81%	27.35%	26.91%	26.49%	26.08%	25.68%	25.30%	24.93%	24.57%	24.22%	23.88%	23.54%	23.22%	22.90%	22.60%	22.30%	22.01%	21.73%	21.45%	21.19%	20.92%	20.67%	20.42%	20.18%	19.95%	19.72%	19.49%	19.27%	19.06%	18.85%	18.64%	18.44%	18.25%	18.05%	17.86%	17.68%	17.50%	17.32%	17.14%	16.98%	16.81%	16.65%	16.49%	16.33%	16.18%	16.03%	15.88%	15.74%	15.60%	15.46%	15.32%	15.19%	15.06%	14.93%	14.80%	14.67%	14.55%	14.43%	14.31%	14.19%	14.08%	13.97%	13.86%	13.75%	13.64%	13.53%	13.43%	13.33%	13.23%	13.13%	13.03%	12.93%	12.84%	12.75%	12.65%	12.56%	12.47%	12.39%	12.30%	12.21%	12.13%	12.05%	11.97%	11.88%	11.81%	11.73%	11.65%	11.57%	11.50%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.93%	10.86%	10.80%	10.73%	10.67%	10.60%	10.54%	10.48%	10.41%	10.35%	10.29%	10.23%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.60%	98.33%	95.93%	92.37%	88.51%	84.65%	80.99%	77.38%	73.88%	70.69%	67.79%	65.10%	62.66%	60.44%	58.39%	56.46%	54.67%	52.97%	51.36%	49.82%	48.34%	46.94%	45.61%	44.35%	43.18%	42.06%	41.01%	40.00%	39.05%	38.14%	37.27%	36.44%	35.64%	34.88%	34.16%	33.47%	32.80%	32.16%	31.55%	30.96%	30.39%	29.85%	29.32%	28.81%	28.32%	27.85%	27.39%	26.95%	26.53%	26.12%	25.72%	25.34%	24.97%	24.61%	24.26%	23.91%	23.58%	23.26%	22.94%	22.63%	22.33%	22.04%	21.76%	21.48%	21.22%	20.95%	20.70%	20.45%	20.21%	19.97%	19.74%	19.52%	19.30%	19.08%	18.87%	18.67%	18.46%	18.27%	18.07%	17.88%	17.70%	17.52%	17.34%	17.17%	17.00%	16.83%	16.67%	16.51%	16.35%	16.20%	16.05%	15.90%	15.76%	15.62%	15.48%	15.34%	15.21%	15.07%	14.94%	14.82%	14.69%	14.57%	14.45%	14.33%	14.21%	14.10%	13.98%	13.87%	13.76%	13.66%	13.55%	13.45%	13.34%	13.24%	13.14%	13.05%	12.95%	12.85%	12.76%	12.67%	12.58%	12.49%	12.40%	12.31%	12.23%	12.14%	12.06%	11.98%	11.90%	11.82%	11.74%	11.66%	11.59%	11.51%	11.44%	11.37%	11.29%	11.22%	11.15%	11.08%	11.01%	10.94%	10.88%	10.81%	10.74%	10.68%	10.61%	10.55%	10.49%	10.43%	10.37%	10.31%	10.25%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.64%	98.36%	96.01%	92.46%	88.62%	84.75%	81.07%	77.48%	73.98%	70.78%	67.87%	65.18%	62.74%	60.51%	58.46%	56.54%	54.74%	53.05%	51.43%	49.88%	48.40%	47.01%	45.67%	44.42%	43.24%	42.12%	41.06%	40.06%	39.10%	38.20%	37.32%	36.49%	35.69%	34.93%	34.21%	33.52%	32.85%	32.22%	31.60%	31.01%	30.44%	29.90%	29.37%	28.86%	28.37%	27.89%	27.44%	27.00%	26.57%	26.16%	25.77%	25.38%	25.01%	24.65%	24.29%	23.95%	23.62%	23.29%	22.97%	22.67%	22.37%	22.08%	21.79%	21.51%	21.25%	20.98%	20.73%	20.48%	20.24%	20.00%	19.77%	19.54%	19.32%	19.11%	18.89%	18.69%	18.48%	18.29%	18.10%	17.91%	17.72%	17.54%	17.36%	17.19%	17.02%	16.85%	16.69%	16.53%	16.37%	16.22%	16.07%	15.92%	15.78%	15.64%	15.50%	15.36%	15.23%	15.09%	14.96%	14.84%	14.71%	14.59%	14.47%	14.35%	14.23%	14.11%	14.00%	13.89%	13.78%	13.67%	13.57%	13.46%	13.36%	13.26%	13.16%	13.06%	12.96%	12.87%	12.77%	12.68%	12.59%	12.50%	12.41%	12.33%	12.24%	12.16%	12.07%	11.99%	11.91%	11.83%	11.75%	11.68%	11.60%	11.53%	11.45%	11.38%	11.31%	11.23%	11.16%	11.09%	11.02%	10.96%	10.89%	10.82%	10.76%	10.69%	10.63%	10.56%	10.50%	10.44%	10.38%	10.32%	10.26%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.67%	98.39%	96.09%	92.54%	88.71%	84.85%	81.15%	77.57%	74.08%	70.85%	67.94%	65.26%	62.81%	60.59%	58.54%	56.62%	54.82%	53.13%	51.51%	49.94%	48.47%	47.07%	45.73%	44.48%	43.29%	42.17%	41.12%	40.11%	39.15%	38.25%	37.38%	36.54%	35.74%	34.98%	34.26%	33.57%	32.90%	32.27%	31.65%	31.06%	30.49%	29.94%	29.41%	28.90%	28.41%	27.93%	27.48%	27.04%	26.61%	26.20%	25.80%	25.42%	25.04%	24.68%	24.33%	23.99%	23.65%	23.33%	23.01%	22.70%	22.40%	22.11%	21.82%	21.55%	21.28%	21.01%	20.76%	20.51%	20.26%	20.03%	19.79%	19.57%	19.34%	19.13%	18.91%	18.71%	18.51%	18.31%	18.12%	17.93%	17.74%	17.56%	17.38%	17.21%	17.04%	16.87%	16.71%	16.55%	16.39%	16.24%	16.09%	15.94%	15.80%	15.66%	15.52%	15.38%	15.24%	15.11%	14.98%	14.85%	14.73%	14.60%	14.48%	14.36%	14.25%	14.13%	14.02%	13.90%	13.79%	13.69%	13.58%	13.48%	13.37%	13.27%	13.17%	13.08%	12.98%	12.88%	12.79%	12.70%	12.61%	12.52%	12.43%	12.34%	12.26%	12.17%	12.09%	12.01%	11.93%	11.85%	11.77%	11.69%	11.61%	11.54%	11.46%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.90%	10.83%	10.77%	10.70%	10.64%	10.58%	10.51%	10.45%	10.39%	10.33%	10.27%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.69%	98.43%	96.16%	92.62%	88.80%	84.94%	81.22%	77.65%	74.17%	70.93%	68.01%	65.35%	62.89%	60.66%	58.60%	56.69%	54.89%	53.20%	51.57%	50.01%	48.53%	47.13%	45.79%	44.53%	43.34%	42.22%	41.17%	40.16%	39.20%	38.29%	37.43%	36.59%	35.80%	35.03%	34.31%	33.62%	32.95%	32.32%	31.70%	31.11%	30.54%	29.99%	29.46%	28.95%	28.45%	27.98%	27.52%	27.08%	26.65%	26.24%	25.84%	25.46%	25.08%	24.72%	24.37%	24.03%	23.69%	23.36%	23.04%	22.73%	22.43%	22.14%	21.85%	21.57%	21.30%	21.04%	20.78%	20.53%	20.29%	20.05%	19.82%	19.59%	19.37%	19.15%	18.94%	18.73%	18.53%	18.33%	18.14%	17.95%	17.76%	17.58%	17.40%	17.23%	17.06%	16.89%	16.73%	16.57%	16.41%	16.26%	16.11%	15.96%	15.82%	15.68%	15.54%	15.40%	15.26%	15.13%	15.00%	14.87%	14.74%	14.62%	14.50%	14.38%	14.26%	14.15%	14.03%	13.92%	13.81%	13.70%	13.60%	13.49%	13.39%	13.29%	13.19%	13.09%	12.99%	12.90%	12.80%	12.71%	12.62%	12.53%	12.44%	12.36%	12.27%	12.19%	12.10%	12.02%	11.94%	11.86%	11.78%	11.70%	11.63%	11.55%	11.48%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.91%	10.85%	10.78%	10.72%	10.65%	10.59%	10.53%	10.46%	10.40%	10.34%	10.28%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.72%	98.46%	96.23%	92.70%	88.89%	85.02%	81.30%	77.73%	74.25%	71.00%	68.09%	65.42%	62.97%	60.73%	58.66%	56.76%	54.96%	53.26%	51.64%	50.07%	48.59%	47.19%	45.85%	44.58%	43.40%	42.27%	41.22%	40.21%	39.25%	38.34%	37.47%	36.65%	35.85%	35.08%	34.36%	33.66%	33.00%	32.36%	31.74%	31.15%	30.58%	30.03%	29.50%	28.99%	28.50%	28.02%	27.56%	27.12%	26.69%	26.28%	25.88%	25.49%	25.12%	24.76%	24.41%	24.06%	23.72%	23.39%	23.07%	22.76%	22.46%	22.17%	21.88%	21.60%	21.33%	21.07%	20.81%	20.56%	20.31%	20.07%	19.84%	19.61%	19.39%	19.17%	18.96%	18.75%	18.55%	18.35%	18.16%	17.97%	17.78%	17.60%	17.42%	17.25%	17.08%	16.91%	16.75%	16.59%	16.43%	16.28%	16.13%	15.98%	15.84%	15.69%	15.55%	15.42%	15.28%	15.15%	15.02%	14.89%	14.76%	14.64%	14.52%	14.40%	14.28%	14.16%	14.05%	13.94%	13.83%	13.72%	13.61%	13.51%	13.41%	13.30%	13.20%	13.11%	13.01%	12.91%	12.82%	12.73%	12.64%	12.55%	12.46%	12.37%	12.28%	12.20%	12.12%	12.03%	11.95%	11.87%	11.79%	11.71%	11.64%	11.56%	11.49%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.92%	10.86%	10.79%	10.73%	10.66%	10.60%	10.54%	10.47%	10.41%	10.35%	10.29%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.74%	98.49%	96.30%	92.78%	88.98%	85.10%	81.38%	77.80%	74.33%	71.08%	68.16%	65.50%	63.04%	60.79%	58.73%	56.82%	55.02%	53.33%	51.69%	50.13%	48.65%	47.24%	45.90%	44.63%	43.45%	42.32%	41.26%	40.26%	39.30%	38.39%	37.52%	36.69%	35.90%	35.13%	34.41%	33.71%	33.05%	32.41%	31.79%	31.20%	30.62%	30.07%	29.54%	29.03%	28.54%	28.06%	27.60%	27.16%	26.73%	26.32%	25.92%	25.53%	25.16%	24.80%	24.44%	24.10%	23.76%	23.43%	23.11%	22.79%	22.49%	22.20%	21.91%	21.63%	21.36%	21.09%	20.83%	20.58%	20.34%	20.10%	19.86%	19.63%	19.41%	19.19%	18.98%	18.77%	18.57%	18.37%	18.18%	17.99%	17.80%	17.62%	17.44%	17.27%	17.10%	16.93%	16.77%	16.61%	16.45%	16.30%	16.15%	16.00%	15.85%	15.71%	15.57%	15.43%	15.30%	15.17%	15.03%	14.91%	14.78%	14.66%	14.53%	14.41%	14.30%	14.18%	14.07%	13.95%	13.84%	13.73%	13.63%	13.52%	13.42%	13.32%	13.22%	13.12%	13.02%	12.93%	12.83%	12.74%	12.65%	12.56%	12.47%	12.38%	12.30%	12.21%	12.13%	12.05%	11.96%	11.88%	11.80%	11.73%	11.65%	11.57%	11.50%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.93%	10.87%	10.80%	10.74%	10.67%	10.61%	10.55%	10.49%	10.42%	10.36%	10.30%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.76%	98.52%	96.35%	92.85%	89.07%	85.17%	81.45%	77.87%	74.40%	71.15%	68.23%	65.57%	63.11%	60.86%	58.79%	56.88%	55.08%	53.39%	51.75%	50.19%	48.70%	47.29%	45.95%	44.68%	43.50%	42.37%	41.31%	40.30%	39.35%	38.44%	37.57%	36.74%	35.95%	35.19%	34.45%	33.76%	33.09%	32.45%	31.84%	31.24%	30.67%	30.12%	29.59%	29.07%	28.58%	28.10%	27.64%	27.20%	26.77%	26.36%	25.96%	25.57%	25.20%	24.83%	24.48%	24.13%	23.79%	23.46%	23.14%	22.82%	22.52%	22.23%	21.94%	21.66%	21.38%	21.12%	20.86%	20.61%	20.36%	20.12%	19.88%	19.66%	19.43%	19.21%	19.00%	18.79%	18.59%	18.39%	18.20%	18.01%	17.82%	17.64%	17.46%	17.29%	17.12%	16.95%	16.79%	16.63%	16.47%	16.32%	16.17%	16.02%	15.87%	15.73%	15.59%	15.45%	15.32%	15.18%	15.05%	14.92%	14.80%	14.67%	14.55%	14.43%	14.31%	14.20%	14.08%	13.97%	13.86%	13.75%	13.64%	13.54%	13.44%	13.34%	13.23%	13.14%	13.04%	12.94%	12.85%	12.75%	12.66%	12.57%	12.48%	12.40%	12.31%	12.22%	12.14%	12.06%	11.98%	11.89%	11.82%	11.74%	11.66%	11.58%	11.51%	11.44%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.94%	10.88%	10.81%	10.75%	10.68%	10.62%	10.56%	10.50%	10.43%	10.37%	10.31%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.78%	98.56%	96.41%	92.92%	89.14%	85.24%	81.51%	77.93%	74.46%	71.23%	68.30%	65.64%	63.18%	60.92%	58.85%	56.93%	55.14%	53.45%	51.81%	50.25%	48.75%	47.34%	46.00%	44.74%	43.54%	42.42%	41.35%	40.35%	39.39%	38.48%	37.61%	36.78%	35.99%	35.23%	34.50%	33.80%	33.14%	32.50%	31.88%	31.28%	30.71%	30.16%	29.63%	29.12%	28.62%	28.14%	27.68%	27.24%	26.81%	26.39%	25.99%	25.61%	25.23%	24.87%	24.51%	24.16%	23.82%	23.49%	23.17%	22.85%	22.55%	22.25%	21.96%	21.68%	21.41%	21.14%	20.88%	20.63%	20.38%	20.14%	19.91%	19.68%	19.46%	19.24%	19.02%	18.81%	18.61%	18.41%	18.22%	18.03%	17.84%	17.66%	17.48%	17.31%	17.14%	16.97%	16.81%	16.65%	16.49%	16.33%	16.18%	16.03%	15.89%	15.75%	15.61%	15.47%	15.33%	15.20%	15.07%	14.94%	14.81%	14.69%	14.57%	14.45%	14.33%	14.21%	14.10%	13.99%	13.88%	13.77%	13.66%	13.56%	13.45%	13.35%	13.25%	13.15%	13.05%	12.96%	12.86%	12.77%	12.68%	12.59%	12.50%	12.41%	12.32%	12.24%	12.15%	12.07%	11.99%	11.91%	11.83%	11.75%	11.67%	11.59%	11.52%	11.45%	11.37%	11.30%	11.23%	11.16%	11.09%	11.02%	10.95%	10.89%	10.82%	10.76%	10.69%	10.63%	10.57%	10.51%	10.44%	10.38%	10.32%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.79%	98.59%	96.47%	92.99%	89.22%	85.31%	81.57%	77.99%	74.52%	71.30%	68.37%	65.71%	63.25%	60.99%	58.91%	56.98%	55.20%	53.50%	51.86%	50.29%	48.80%	47.39%	46.04%	44.78%	43.59%	42.47%	41.40%	40.39%	39.44%	38.53%	37.66%	36.83%	36.04%	35.28%	34.55%	33.85%	33.18%	32.54%	31.92%	31.32%	30.75%	30.20%	29.67%	29.16%	28.66%	28.18%	27.72%	27.28%	26.85%	26.43%	26.03%	25.64%	25.26%	24.90%	24.54%	24.19%	23.85%	23.51%	23.19%	22.88%	22.58%	22.28%	21.99%	21.71%	21.43%	21.17%	20.91%	20.65%	20.41%	20.16%	19.93%	19.70%	19.48%	19.26%	19.04%	18.84%	18.63%	18.43%	18.24%	18.05%	17.86%	17.68%	17.50%	17.32%	17.15%	16.99%	16.82%	16.66%	16.51%	16.35%	16.20%	16.05%	15.91%	15.76%	15.62%	15.49%	15.35%	15.22%	15.09%	14.96%	14.83%	14.71%	14.58%	14.46%	14.35%	14.23%	14.11%	14.00%	13.89%	13.78%	13.68%	13.57%	13.47%	13.36%	13.26%	13.16%	13.07%	12.97%	12.88%	12.78%	12.69%	12.60%	12.51%	12.42%	12.33%	12.25%	12.16%	12.08%	12.00%	11.92%	11.84%	11.76%	11.68%	11.61%	11.53%	11.46%	11.38%	11.31%	11.24%	11.17%	11.10%	11.03%	10.96%	10.90%	10.83%	10.77%	10.70%	10.64%	10.58%	10.52%	10.45%	10.39%	10.33%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.80%	98.62%	96.52%	93.07%	89.29%	85.37%	81.61%	78.04%	74.58%	71.37%	68.43%	65.77%	63.32%	61.05%	58.97%	57.04%	55.25%	53.55%	51.91%	50.34%	48.85%	47.43%	46.09%	44.83%	43.64%	42.51%	41.45%	40.44%	39.48%	38.57%	37.71%	36.87%	36.08%	35.32%	34.59%	33.89%	33.23%	32.58%	31.96%	31.36%	30.79%	30.24%	29.71%	29.19%	28.70%	28.22%	27.76%	27.32%	26.89%	26.47%	26.07%	25.67%	25.29%	24.93%	24.57%	24.22%	23.87%	23.54%	23.22%	22.91%	22.60%	22.31%	22.02%	21.73%	21.46%	21.19%	20.93%	20.68%	20.43%	20.19%	19.95%	19.72%	19.50%	19.28%	19.07%	18.86%	18.65%	18.45%	18.26%	18.07%	17.88%	17.70%	17.52%	17.34%	17.17%	17.00%	16.84%	16.68%	16.52%	16.37%	16.22%	16.07%	15.92%	15.78%	15.64%	15.50%	15.37%	15.23%	15.10%	14.97%	14.85%	14.72%	14.60%	14.48%	14.36%	14.25%	14.13%	14.02%	13.91%	13.80%	13.69%	13.59%	13.48%	13.38%	13.28%	13.18%	13.08%	12.98%	12.89%	12.79%	12.70%	12.61%	12.52%	12.43%	12.35%	12.26%	12.17%	12.09%	12.01%	11.93%	11.85%	11.77%	11.69%	11.62%	11.54%	11.47%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.91%	10.84%	10.78%	10.71%	10.65%	10.59%	10.52%	10.46%	10.40%	10.34%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.81%	98.66%	96.57%	93.14%	89.36%	85.42%	81.65%	78.10%	74.64%	71.43%	68.50%	65.84%	63.38%	61.11%	59.02%	57.09%	55.30%	53.60%	51.96%	50.38%	48.89%	47.48%	46.13%	44.87%	43.68%	42.56%	41.49%	40.48%	39.53%	38.62%	37.75%	36.92%	36.12%	35.36%	34.64%	33.94%	33.27%	32.62%	32.00%	31.40%	30.83%	30.28%	29.75%	29.23%	28.74%	28.26%	27.80%	27.35%	26.92%	26.50%	26.10%	25.70%	25.32%	24.95%	24.60%	24.25%	23.90%	23.57%	23.25%	22.93%	22.63%	22.33%	22.04%	21.76%	21.48%	21.22%	20.95%	20.70%	20.45%	20.21%	19.97%	19.75%	19.52%	19.30%	19.09%	18.88%	18.67%	18.47%	18.27%	18.08%	17.90%	17.71%	17.53%	17.36%	17.19%	17.02%	16.86%	16.70%	16.54%	16.38%	16.23%	16.09%	15.94%	15.80%	15.66%	15.52%	15.38%	15.25%	15.12%	14.99%	14.86%	14.74%	14.62%	14.50%	14.38%	14.26%	14.15%	14.03%	13.92%	13.81%	13.71%	13.60%	13.50%	13.39%	13.29%	13.19%	13.09%	13.00%	12.90%	12.81%	12.71%	12.62%	12.53%	12.44%	12.36%	12.27%	12.19%	12.10%	12.02%	11.94%	11.86%	11.78%	11.70%	11.63%	11.55%	11.48%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.92%	10.85%	10.79%	10.72%	10.66%	10.60%	10.53%	10.47%	10.41%	10.35%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.82%	98.69%	96.62%	93.21%	89.43%	85.47%	81.70%	78.15%	74.69%	71.50%	68.56%	65.91%	63.44%	61.17%	59.08%	57.14%	55.34%	53.63%	52.01%	50.43%	48.93%	47.52%	46.17%	44.91%	43.72%	42.60%	41.54%	40.53%	39.57%	38.66%	37.79%	36.96%	36.16%	35.40%	34.68%	33.98%	33.31%	32.66%	32.04%	31.44%	30.87%	30.32%	29.79%	29.27%	28.78%	28.30%	27.83%	27.39%	26.95%	26.53%	26.13%	25.73%	25.35%	24.98%	24.62%	24.27%	23.93%	23.60%	23.27%	22.96%	22.65%	22.36%	22.07%	21.78%	21.51%	21.24%	20.98%	20.72%	20.47%	20.23%	20.00%	19.77%	19.54%	19.32%	19.11%	18.90%	18.69%	18.49%	18.29%	18.10%	17.91%	17.73%	17.55%	17.38%	17.20%	17.04%	16.87%	16.71%	16.56%	16.40%	16.25%	16.10%	15.96%	15.82%	15.68%	15.54%	15.40%	15.27%	15.14%	15.01%	14.88%	14.76%	14.63%	14.51%	14.39%	14.28%	14.16%	14.05%	13.94%	13.83%	13.72%	13.61%	13.51%	13.41%	13.30%	13.20%	13.11%	13.01%	12.91%	12.82%	12.73%	12.63%	12.54%	12.46%	12.37%	12.28%	12.20%	12.11%	12.03%	11.95%	11.87%	11.79%	11.71%	11.64%	11.56%	11.49%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.93%	10.86%	10.80%	10.73%	10.67%	10.60%	10.54%	10.48%	10.42%	10.36%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.84%	98.72%	96.67%	93.28%	89.49%	85.51%	81.74%	78.20%	74.74%	71.55%	68.63%	65.97%	63.50%	61.23%	59.13%	57.18%	55.37%	53.67%	52.05%	50.47%	48.98%	47.57%	46.22%	44.95%	43.77%	42.65%	41.59%	40.57%	39.61%	38.70%	37.83%	37.00%	36.20%	35.44%	34.72%	34.02%	33.35%	32.70%	32.08%	31.48%	30.90%	30.35%	29.82%	29.31%	28.81%	28.33%	27.87%	27.42%	26.98%	26.56%	26.16%	25.76%	25.38%	25.01%	24.65%	24.30%	23.96%	23.62%	23.30%	22.99%	22.68%	22.38%	22.09%	21.81%	21.53%	21.26%	21.00%	20.75%	20.50%	20.26%	20.02%	19.79%	19.56%	19.34%	19.13%	18.92%	18.71%	18.51%	18.31%	18.12%	17.93%	17.75%	17.57%	17.39%	17.22%	17.05%	16.89%	16.73%	16.57%	16.42%	16.27%	16.12%	15.97%	15.83%	15.69%	15.55%	15.42%	15.28%	15.15%	15.02%	14.90%	14.77%	14.65%	14.53%	14.41%	14.29%	14.18%	14.06%	13.95%	13.84%	13.73%	13.63%	13.52%	13.42%	13.32%	13.22%	13.12%	13.02%	12.93%	12.83%	12.74%	12.65%	12.56%	12.47%	12.38%	12.29%	12.21%	12.12%	12.04%	11.96%	11.88%	11.80%	11.72%	11.65%	11.57%	11.50%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.94%	10.87%	10.80%	10.74%	10.68%	10.61%	10.55%	10.49%	10.43%	10.37%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.85%	98.75%	96.72%	93.35%	89.55%	85.54%	81.78%	78.25%	74.79%	71.61%	68.69%	66.03%	63.56%	61.29%	59.18%	57.23%	55.40%	53.71%	52.09%	50.51%	49.02%	47.61%	46.26%	44.99%	43.81%	42.69%	41.63%	40.62%	39.65%	38.74%	37.87%	37.04%	36.24%	35.48%	34.75%	34.06%	33.39%	32.74%	32.12%	31.52%	30.94%	30.39%	29.86%	29.34%	28.84%	28.36%	27.90%	27.45%	27.01%	26.59%	26.19%	25.79%	25.41%	25.04%	24.68%	24.33%	23.98%	23.65%	23.33%	23.01%	22.70%	22.41%	22.11%	21.83%	21.56%	21.29%	21.03%	20.77%	20.52%	20.28%	20.04%	19.81%	19.58%	19.36%	19.15%	18.93%	18.73%	18.53%	18.33%	18.14%	17.95%	17.77%	17.59%	17.41%	17.24%	17.07%	16.91%	16.75%	16.59%	16.43%	16.28%	16.13%	15.99%	15.85%	15.71%	15.57%	15.43%	15.30%	15.17%	15.04%	14.91%	14.79%	14.66%	14.54%	14.42%	14.31%	14.19%	14.08%	13.97%	13.86%	13.75%	13.64%	13.54%	13.43%	13.33%	13.23%	13.13%	13.03%	12.94%	12.84%	12.75%	12.66%	12.57%	12.48%	12.39%	12.30%	12.22%	12.14%	12.05%	11.97%	11.89%	11.81%	11.73%	11.66%	11.58%	11.51%	11.43%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.94%	10.88%	10.81%	10.75%	10.68%	10.62%	10.56%	10.50%	10.44%	10.38%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.86%	98.79%	96.77%	93.42%	89.61%	85.58%	81.82%	78.29%	74.83%	71.67%	68.75%	66.09%	63.62%	61.34%	59.23%	57.27%	55.44%	53.74%	52.12%	50.55%	49.06%	47.65%	46.30%	45.03%	43.84%	42.73%	41.67%	40.66%	39.70%	38.78%	37.91%	37.08%	36.28%	35.52%	34.79%	34.09%	33.42%	32.78%	32.15%	31.55%	30.97%	30.42%	29.89%	29.37%	28.87%	28.39%	27.92%	27.48%	27.04%	26.62%	26.21%	25.82%	25.43%	25.06%	24.70%	24.35%	24.01%	23.68%	23.35%	23.04%	22.73%	22.43%	22.14%	21.86%	21.58%	21.31%	21.05%	20.79%	20.54%	20.30%	20.06%	19.83%	19.60%	19.38%	19.17%	18.95%	18.75%	18.54%	18.35%	18.16%	17.97%	17.78%	17.60%	17.43%	17.26%	17.09%	16.92%	16.76%	16.60%	16.45%	16.30%	16.15%	16.00%	15.86%	15.72%	15.59%	15.45%	15.32%	15.18%	15.05%	14.93%	14.80%	14.68%	14.56%	14.44%	14.32%	14.21%	14.09%	13.98%	13.87%	13.76%	13.65%	13.55%	13.45%	13.34%	13.24%	13.14%	13.04%	12.95%	12.85%	12.76%	12.67%	12.58%	12.49%	12.40%	12.31%	12.23%	12.15%	12.06%	11.98%	11.90%	11.82%	11.74%	11.67%	11.59%	11.52%	11.44%	11.37%	11.30%	11.23%	11.16%	11.09%	11.02%	10.95%	10.89%	10.82%	10.76%	10.69%	10.63%	10.57%	10.51%	10.44%	10.38%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.87%	98.82%	96.81%	93.48%	89.67%	85.62%	81.85%	78.33%	74.88%	71.73%	68.81%	66.14%	63.67%	61.39%	59.27%	57.31%	55.47%	53.77%	52.15%	50.59%	49.10%	47.69%	46.34%	45.07%	43.88%	42.77%	41.71%	40.70%	39.74%	38.82%	37.95%	37.12%	36.32%	35.56%	34.82%	34.12%	33.45%	32.81%	32.19%	31.59%	31.01%	30.45%	29.91%	29.40%	28.90%	28.42%	27.95%	27.51%	27.07%	26.65%	26.24%	25.84%	25.46%	25.09%	24.73%	24.38%	24.03%	23.70%	23.37%	23.06%	22.75%	22.45%	22.16%	21.88%	21.60%	21.33%	21.07%	20.81%	20.56%	20.32%	20.08%	19.85%	19.62%	19.40%	19.18%	18.97%	18.77%	18.56%	18.37%	18.17%	17.99%	17.80%	17.62%	17.45%	17.27%	17.10%	16.94%	16.78%	16.62%	16.47%	16.31%	16.17%	16.02%	15.88%	15.74%	15.60%	15.46%	15.33%	15.20%	15.07%	14.94%	14.82%	14.69%	14.57%	14.45%	14.33%	14.22%	14.11%	13.99%	13.88%	13.77%	13.67%	13.56%	13.46%	13.35%	13.25%	13.15%	13.06%	12.96%	12.87%	12.77%	12.68%	12.59%	12.50%	12.41%	12.33%	12.24%	12.16%	12.07%	11.99%	11.91%	11.83%	11.75%	11.68%	11.60%	11.53%	11.45%	11.38%	11.31%	11.24%	11.17%	11.10%	11.03%	10.96%	10.89%	10.83%	10.76%	10.70%	10.64%	10.57%	10.51%	10.45%	10.39%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.89%	98.85%	96.86%	93.54%	89.73%	85.66%	81.87%	78.36%	74.93%	71.78%	68.87%	66.19%	63.73%	61.43%	59.32%	57.34%	55.50%	53.80%	52.18%	50.63%	49.14%	47.73%	46.38%	45.12%	43.92%	42.80%	41.75%	40.75%	39.78%	38.86%	37.98%	37.15%	36.35%	35.59%	34.86%	34.16%	33.48%	32.84%	32.22%	31.62%	31.03%	30.48%	29.94%	29.43%	28.93%	28.45%	27.98%	27.53%	27.10%	26.68%	26.27%	25.87%	25.49%	25.12%	24.76%	24.40%	24.06%	23.72%	23.40%	23.08%	22.78%	22.48%	22.18%	21.90%	21.62%	21.35%	21.09%	20.84%	20.59%	20.34%	20.10%	19.87%	19.64%	19.42%	19.20%	18.99%	18.78%	18.58%	18.38%	18.19%	18.00%	17.82%	17.64%	17.46%	17.29%	17.12%	16.96%	16.80%	16.64%	16.48%	16.33%	16.18%	16.04%	15.89%	15.75%	15.61%	15.48%	15.34%	15.21%	15.08%	14.95%	14.83%	14.71%	14.58%	14.47%	14.35%	14.23%	14.12%	14.01%	13.89%	13.79%	13.68%	13.57%	13.47%	13.37%	13.27%	13.17%	13.07%	12.97%	12.88%	12.78%	12.69%	12.60%	12.51%	12.42%	12.34%	12.25%	12.17%	12.08%	12.00%	11.92%	11.84%	11.76%	11.69%	11.61%	11.54%	11.46%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.90%	10.84%	10.77%	10.71%	10.65%	10.58%	10.52%	10.46%	10.40%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.90%	98.88%	96.91%	93.61%	89.78%	85.70%	81.90%	78.39%	74.98%	71.83%	68.92%	66.25%	63.78%	61.48%	59.36%	57.38%	55.54%	53.83%	52.21%	50.67%	49.18%	47.77%	46.42%	45.16%	43.96%	42.84%	41.78%	40.79%	39.82%	38.90%	38.02%	37.18%	36.38%	35.62%	34.89%	34.19%	33.51%	32.87%	32.25%	31.64%	31.06%	30.51%	29.97%	29.45%	28.95%	28.48%	28.01%	27.56%	27.13%	26.70%	26.29%	25.90%	25.51%	25.14%	24.78%	24.43%	24.08%	23.75%	23.42%	23.10%	22.80%	22.50%	22.21%	21.92%	21.64%	21.38%	21.11%	20.86%	20.61%	20.36%	20.12%	19.89%	19.66%	19.44%	19.22%	19.01%	18.80%	18.60%	18.40%	18.21%	18.02%	17.84%	17.66%	17.48%	17.31%	17.14%	16.97%	16.81%	16.65%	16.50%	16.35%	16.20%	16.05%	15.91%	15.77%	15.63%	15.49%	15.36%	15.23%	15.10%	14.97%	14.84%	14.72%	14.60%	14.48%	14.36%	14.24%	14.13%	14.02%	13.91%	13.80%	13.69%	13.58%	13.48%	13.38%	13.28%	13.18%	13.08%	12.98%	12.89%	12.79%	12.70%	12.61%	12.52%	12.43%	12.35%	12.26%	12.18%	12.09%	12.01%	11.93%	11.85%	11.77%	11.70%	11.62%	11.55%	11.47%	11.40%	11.33%	11.25%	11.18%	11.11%	11.05%	10.98%	10.91%	10.85%	10.78%	10.72%	10.65%	10.59%	10.53%	10.47%	10.41%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.91%	98.92%	96.96%	93.67%	89.84%	85.73%	81.93%	78.43%	75.02%	71.88%	68.98%	66.30%	63.83%	61.52%	59.40%	57.41%	55.57%	53.87%	52.24%	50.70%	49.22%	47.81%	46.46%	45.19%	44.00%	42.88%	41.82%	40.82%	39.86%	38.93%	38.05%	37.21%	36.41%	35.65%	34.91%	34.21%	33.54%	32.89%	32.27%	31.67%	31.09%	30.53%	30.00%	29.48%	28.98%	28.50%	28.04%	27.59%	27.15%	26.73%	26.32%	25.92%	25.54%	25.17%	24.81%	24.45%	24.11%	23.77%	23.44%	23.13%	22.82%	22.52%	22.23%	21.94%	21.67%	21.40%	21.13%	20.88%	20.63%	20.38%	20.14%	19.91%	19.68%	19.46%	19.24%	19.03%	18.82%	18.62%	18.42%	18.23%	18.04%	17.85%	17.67%	17.50%	17.32%	17.16%	16.99%	16.83%	16.67%	16.51%	16.36%	16.21%	16.07%	15.92%	15.78%	15.64%	15.51%	15.37%	15.24%	15.11%	14.98%	14.86%	14.73%	14.61%	14.49%	14.37%	14.26%	14.14%	14.03%	13.92%	13.81%	13.70%	13.60%	13.49%	13.39%	13.29%	13.19%	13.09%	12.99%	12.90%	12.80%	12.71%	12.62%	12.53%	12.44%	12.36%	12.27%	12.19%	12.10%	12.02%	11.94%	11.86%	11.78%	11.71%	11.63%	11.55%	11.48%	11.41%	11.33%	11.26%	11.19%	11.12%	11.05%	10.99%	10.92%	10.85%	10.79%	10.73%	10.66%	10.60%	10.54%	10.48%	10.42%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.92%	98.95%	97.01%	93.73%	89.89%	85.77%	81.96%	78.46%	75.06%	71.92%	69.03%	66.35%	63.88%	61.56%	59.43%	57.45%	55.60%	53.89%	52.27%	50.73%	49.26%	47.85%	46.50%	45.23%	44.04%	42.91%	41.85%	40.85%	39.89%	38.97%	38.09%	37.24%	36.44%	35.67%	34.94%	34.24%	33.57%	32.92%	32.30%	31.70%	31.12%	30.56%	30.02%	29.51%	29.01%	28.53%	28.06%	27.62%	27.18%	26.76%	26.35%	25.95%	25.57%	25.19%	24.83%	24.48%	24.13%	23.79%	23.47%	23.15%	22.84%	22.54%	22.25%	21.97%	21.69%	21.42%	21.16%	20.90%	20.65%	20.40%	20.16%	19.93%	19.70%	19.48%	19.26%	19.05%	18.84%	18.64%	18.44%	18.25%	18.06%	17.87%	17.69%	17.51%	17.34%	17.17%	17.01%	16.84%	16.68%	16.53%	16.38%	16.23%	16.08%	15.94%	15.80%	15.66%	15.52%	15.39%	15.25%	15.12%	15.00%	14.87%	14.75%	14.62%	14.50%	14.39%	14.27%	14.15%	14.04%	13.93%	13.82%	13.71%	13.61%	13.50%	13.40%	13.30%	13.20%	13.10%	13.00%	12.91%	12.81%	12.72%	12.63%	12.54%	12.45%	12.37%	12.28%	12.20%	12.11%	12.03%	11.95%	11.87%	11.79%	11.72%	11.64%	11.56%	11.49%	11.42%	11.34%	11.27%	11.20%	11.13%	11.06%	11.00%	10.93%	10.86%	10.80%	10.73%	10.67%	10.61%	10.55%	10.48%	10.42%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.94%	98.98%	97.06%	93.79%	89.93%	85.81%	81.99%	78.49%	75.10%	71.96%	69.08%	66.40%	63.92%	61.60%	59.47%	57.48%	55.63%	53.92%	52.30%	50.77%	49.29%	47.89%	46.53%	45.26%	44.07%	42.94%	41.88%	40.88%	39.93%	39.00%	38.11%	37.27%	36.46%	35.69%	34.96%	34.26%	33.59%	32.95%	32.32%	31.72%	31.15%	30.59%	30.05%	29.53%	29.03%	28.55%	28.09%	27.64%	27.21%	26.79%	26.38%	25.98%	25.59%	25.22%	24.86%	24.50%	24.15%	23.81%	23.49%	23.17%	22.86%	22.56%	22.27%	21.99%	21.71%	21.44%	21.18%	20.92%	20.67%	20.42%	20.18%	19.95%	19.72%	19.50%	19.28%	19.07%	18.86%	18.66%	18.46%	18.26%	18.07%	17.89%	17.71%	17.53%	17.36%	17.19%	17.02%	16.86%	16.70%	16.54%	16.39%	16.24%	16.10%	15.95%	15.81%	15.67%	15.54%	15.40%	15.27%	15.14%	15.01%	14.88%	14.76%	14.64%	14.52%	14.40%	14.28%	14.17%	14.05%	13.94%	13.83%	13.73%	13.62%	13.51%	13.41%	13.31%	13.21%	13.11%	13.01%	12.92%	12.83%	12.73%	12.64%	12.55%	12.46%	12.38%	12.29%	12.21%	12.12%	12.04%	11.96%	11.88%	11.80%	11.72%	11.65%	11.57%	11.50%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.94%	10.87%	10.81%	10.74%	10.68%	10.62%	10.55%	10.49%	10.43%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.95%	99.02%	97.11%	93.86%	89.98%	85.85%	82.01%	78.52%	75.14%	72.00%	69.13%	66.45%	63.97%	61.64%	59.50%	57.51%	55.67%	53.95%	52.32%	50.80%	49.32%	47.92%	46.57%	45.29%	44.10%	42.97%	41.91%	40.91%	39.96%	39.03%	38.14%	37.29%	36.49%	35.72%	34.99%	34.29%	33.62%	32.97%	32.35%	31.75%	31.17%	30.62%	30.08%	29.56%	29.06%	28.58%	28.12%	27.67%	27.24%	26.81%	26.40%	26.00%	25.62%	25.24%	24.88%	24.52%	24.18%	23.84%	23.51%	23.19%	22.88%	22.58%	22.29%	22.01%	21.73%	21.46%	21.20%	20.94%	20.68%	20.44%	20.20%	19.97%	19.74%	19.52%	19.30%	19.09%	18.88%	18.67%	18.48%	18.28%	18.09%	17.91%	17.72%	17.55%	17.37%	17.20%	17.04%	16.87%	16.72%	16.56%	16.41%	16.26%	16.11%	15.97%	15.83%	15.69%	15.55%	15.41%	15.28%	15.15%	15.02%	14.90%	14.77%	14.65%	14.53%	14.41%	14.29%	14.18%	14.07%	13.95%	13.84%	13.74%	13.63%	13.53%	13.42%	13.32%	13.22%	13.12%	13.03%	12.93%	12.84%	12.74%	12.65%	12.56%	12.47%	12.39%	12.30%	12.22%	12.13%	12.05%	11.97%	11.89%	11.81%	11.73%	11.66%	11.58%	11.51%	11.43%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.95%	10.88%	10.81%	10.75%	10.69%	10.62%	10.56%	10.50%	10.44%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.96%	99.05%	97.16%	93.92%	90.03%	85.88%	82.04%	78.55%	75.17%	72.04%	69.18%	66.50%	64.01%	61.69%	59.53%	57.54%	55.70%	53.98%	52.35%	50.82%	49.35%	47.95%	46.60%	45.33%	44.13%	43.00%	41.94%	40.93%	39.98%	39.06%	38.17%	37.32%	36.51%	35.74%	35.01%	34.31%	33.64%	33.00%	32.37%	31.77%	31.19%	30.64%	30.11%	29.59%	29.09%	28.61%	28.14%	27.69%	27.26%	26.84%	26.43%	26.03%	25.64%	25.27%	24.90%	24.55%	24.20%	23.86%	23.53%	23.21%	22.91%	22.61%	22.31%	22.03%	21.75%	21.48%	21.21%	20.96%	20.70%	20.46%	20.22%	19.99%	19.76%	19.54%	19.32%	19.10%	18.90%	18.69%	18.49%	18.30%	18.11%	17.92%	17.74%	17.56%	17.39%	17.22%	17.05%	16.89%	16.73%	16.57%	16.42%	16.27%	16.13%	15.98%	15.84%	15.70%	15.56%	15.43%	15.30%	15.16%	15.04%	14.91%	14.78%	14.66%	14.54%	14.42%	14.31%	14.19%	14.08%	13.97%	13.86%	13.75%	13.64%	13.54%	13.43%	13.33%	13.23%	13.13%	13.04%	12.94%	12.85%	12.75%	12.66%	12.57%	12.48%	12.40%	12.31%	12.23%	12.14%	12.06%	11.98%	11.90%	11.82%	11.74%	11.67%	11.59%	11.52%	11.44%	11.37%	11.30%	11.23%	11.16%	11.09%	11.02%	10.95%	10.89%	10.82%	10.76%	10.69%	10.63%	10.57%	10.51%	10.45%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.97%	99.08%	97.21%	93.98%	90.08%	85.92%	82.07%	78.57%	75.21%	72.07%	69.23%	66.54%	64.06%	61.72%	59.56%	57.57%	55.73%	54.00%	52.37%	50.85%	49.38%	47.98%	46.63%	45.35%	44.16%	43.03%	41.96%	40.96%	40.00%	39.08%	38.19%	37.34%	36.54%	35.77%	35.03%	34.33%	33.66%	33.02%	32.40%	31.80%	31.22%	30.66%	30.13%	29.61%	29.11%	28.63%	28.17%	27.72%	27.29%	26.87%	26.45%	26.05%	25.67%	25.29%	24.93%	24.57%	24.22%	23.88%	23.55%	23.24%	22.93%	22.63%	22.33%	22.05%	21.77%	21.50%	21.23%	20.98%	20.72%	20.48%	20.24%	20.01%	19.78%	19.55%	19.34%	19.12%	18.91%	18.71%	18.51%	18.32%	18.13%	17.94%	17.76%	17.58%	17.41%	17.24%	17.07%	16.91%	16.75%	16.59%	16.44%	16.29%	16.14%	16.00%	15.85%	15.72%	15.58%	15.44%	15.31%	15.18%	15.05%	14.92%	14.80%	14.67%	14.55%	14.43%	14.32%	14.20%	14.09%	13.98%	13.87%	13.76%	13.65%	13.55%	13.45%	13.34%	13.24%	13.14%	13.05%	12.95%	12.86%	12.76%	12.67%	12.58%	12.50%	12.41%	12.32%	12.24%	12.15%	12.07%	11.99%	11.91%	11.83%	11.75%	11.67%	11.60%	11.52%	11.45%	11.38%	11.31%	11.24%	11.17%	11.10%	11.03%	10.96%	10.90%	10.83%	10.76%	10.70%	10.64%	10.57%	10.51%	10.45%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.98%	99.11%	97.26%	94.03%	90.13%	85.96%	82.10%	78.59%	75.25%	72.11%	69.27%	66.58%	64.10%	61.76%	59.60%	57.60%	55.76%	54.03%	52.40%	50.87%	49.41%	48.01%	46.66%	45.38%	44.18%	43.06%	41.99%	40.98%	40.02%	39.11%	38.22%	37.37%	36.56%	35.79%	35.06%	34.36%	33.69%	33.05%	32.42%	31.82%	31.24%	30.69%	30.16%	29.64%	29.14%	28.66%	28.19%	27.74%	27.31%	26.89%	26.48%	26.08%	25.69%	25.32%	24.95%	24.59%	24.24%	23.91%	23.58%	23.26%	22.95%	22.65%	22.35%	22.07%	21.79%	21.52%	21.25%	20.99%	20.74%	20.50%	20.26%	20.02%	19.80%	19.57%	19.35%	19.14%	18.93%	18.73%	18.53%	18.33%	18.14%	17.96%	17.77%	17.60%	17.42%	17.25%	17.08%	16.92%	16.76%	16.61%	16.45%	16.30%	16.15%	16.01%	15.87%	15.73%	15.59%	15.46%	15.32%	15.19%	15.06%	14.93%	14.81%	14.69%	14.57%	14.45%	14.33%	14.21%	14.10%	13.99%	13.88%	13.77%	13.67%	13.56%	13.46%	13.35%	13.25%	13.16%	13.06%	12.96%	12.87%	12.78%	12.68%	12.59%	12.51%	12.42%	12.33%	12.25%	12.16%	12.08%	12.00%	11.92%	11.84%	11.76%	11.68%	11.61%	11.53%	11.46%	11.39%	11.31%	11.24%	11.17%	11.11%	11.04%	10.97%	10.90%	10.84%	10.77%	10.71%	10.64%	10.58%	10.52%	10.46%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.14%	97.31%	94.07%	90.18%	86.00%	82.13%	78.61%	75.28%	72.14%	69.32%	66.63%	64.14%	61.80%	59.63%	57.63%	55.79%	54.06%	52.42%	50.89%	49.43%	48.04%	46.69%	45.40%	44.21%	43.08%	42.01%	41.00%	40.04%	39.13%	38.24%	37.39%	36.59%	35.81%	35.08%	34.38%	33.71%	33.07%	32.45%	31.85%	31.27%	30.71%	30.18%	29.66%	29.17%	28.68%	28.22%	27.77%	27.34%	26.91%	26.50%	26.10%	25.72%	25.34%	24.98%	24.62%	24.27%	23.93%	23.60%	23.28%	22.97%	22.67%	22.37%	22.09%	21.81%	21.54%	21.27%	21.01%	20.76%	20.52%	20.28%	20.04%	19.81%	19.59%	19.37%	19.16%	18.95%	18.74%	18.54%	18.35%	18.16%	17.97%	17.79%	17.61%	17.44%	17.27%	17.10%	16.94%	16.78%	16.62%	16.47%	16.32%	16.17%	16.02%	15.88%	15.74%	15.61%	15.47%	15.34%	15.20%	15.07%	14.95%	14.82%	14.70%	14.58%	14.46%	14.34%	14.23%	14.11%	14.00%	13.89%	13.78%	13.68%	13.57%	13.47%	13.37%	13.27%	13.17%	13.07%	12.97%	12.88%	12.79%	12.69%	12.60%	12.52%	12.43%	12.34%	12.26%	12.17%	12.09%	12.01%	11.93%	11.85%	11.77%	11.69%	11.62%	11.54%	11.47%	11.40%	11.32%	11.25%	11.18%	11.11%	11.04%	10.98%	10.91%	10.84%	10.78%	10.71%	10.65%	10.59%	10.52%	10.46%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.15%	97.35%	94.12%	90.23%	86.03%	82.15%	78.63%	75.31%	72.18%	69.36%	66.67%	64.18%	61.83%	59.66%	57.66%	55.82%	54.08%	52.44%	50.92%	49.46%	48.06%	46.71%	45.43%	44.23%	43.10%	42.04%	41.02%	40.06%	39.15%	38.27%	37.42%	36.61%	35.84%	35.10%	34.40%	33.73%	33.09%	32.47%	31.87%	31.29%	30.74%	30.20%	29.69%	29.19%	28.71%	28.24%	27.79%	27.36%	26.94%	26.53%	26.13%	25.74%	25.36%	25.00%	24.64%	24.29%	23.95%	23.62%	23.30%	22.99%	22.69%	22.39%	22.11%	21.83%	21.55%	21.29%	21.03%	20.78%	20.53%	20.29%	20.06%	19.83%	19.61%	19.39%	19.17%	18.96%	18.76%	18.56%	18.37%	18.18%	17.99%	17.81%	17.63%	17.45%	17.28%	17.12%	16.95%	16.79%	16.64%	16.48%	16.33%	16.18%	16.04%	15.90%	15.76%	15.62%	15.48%	15.35%	15.22%	15.09%	14.96%	14.83%	14.71%	14.59%	14.47%	14.35%	14.24%	14.12%	14.01%	13.90%	13.79%	13.69%	13.58%	13.48%	13.38%	13.28%	13.18%	13.08%	12.98%	12.89%	12.80%	12.70%	12.61%	12.52%	12.44%	12.35%	12.27%	12.18%	12.10%	12.02%	11.94%	11.86%	11.78%	11.70%	11.63%	11.55%	11.48%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.91%	10.85%	10.78%	10.72%	10.65%	10.59%	10.53%	10.47%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.17%	97.40%	94.16%	90.27%	86.06%	82.18%	78.65%	75.34%	72.21%	69.39%	66.71%	64.21%	61.86%	59.69%	57.69%	55.85%	54.10%	52.47%	50.94%	49.48%	48.08%	46.74%	45.46%	44.25%	43.12%	42.06%	41.04%	40.08%	39.17%	38.29%	37.45%	36.63%	35.86%	35.13%	34.42%	33.75%	33.11%	32.49%	31.89%	31.32%	30.76%	30.23%	29.71%	29.21%	28.73%	28.27%	27.82%	27.38%	26.96%	26.55%	26.15%	25.76%	25.39%	25.02%	24.66%	24.31%	23.97%	23.64%	23.32%	23.01%	22.71%	22.41%	22.12%	21.85%	21.57%	21.31%	21.05%	20.80%	20.55%	20.31%	20.08%	19.85%	19.62%	19.40%	19.19%	18.98%	18.78%	18.58%	18.38%	18.19%	18.01%	17.82%	17.64%	17.47%	17.30%	17.13%	16.97%	16.81%	16.65%	16.50%	16.35%	16.20%	16.05%	15.91%	15.77%	15.63%	15.50%	15.36%	15.23%	15.10%	14.97%	14.85%	14.72%	14.60%	14.48%	14.37%	14.25%	14.14%	14.02%	13.91%	13.81%	13.70%	13.59%	13.49%	13.39%	13.29%	13.19%	13.09%	13.00%	12.90%	12.81%	12.71%	12.62%	12.53%	12.45%	12.36%	12.27%	12.19%	12.11%	12.03%	11.94%	11.87%	11.79%	11.71%	11.63%	11.56%	11.48%	11.41%	11.34%	11.26%	11.19%	11.12%	11.05%	10.99%	10.92%	10.85%	10.79%	10.72%	10.66%	10.59%	10.53%	10.47%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.18%	97.45%	94.21%	90.31%	86.09%	82.21%	78.67%	75.36%	72.24%	69.41%	66.75%	64.25%	61.90%	59.72%	57.72%	55.87%	54.13%	52.49%	50.96%	49.50%	48.10%	46.76%	45.48%	44.28%	43.14%	42.07%	41.06%	40.10%	39.18%	38.31%	37.47%	36.66%	35.89%	35.15%	34.45%	33.78%	33.13%	32.51%	31.92%	31.34%	30.78%	30.25%	29.73%	29.24%	28.76%	28.29%	27.84%	27.40%	26.98%	26.57%	26.17%	25.79%	25.41%	25.05%	24.69%	24.34%	24.00%	23.66%	23.34%	23.03%	22.73%	22.43%	22.14%	21.86%	21.59%	21.33%	21.07%	20.82%	20.57%	20.33%	20.09%	19.86%	19.64%	19.42%	19.21%	19.00%	18.79%	18.59%	18.40%	18.21%	18.02%	17.84%	17.66%	17.49%	17.31%	17.15%	16.98%	16.82%	16.67%	16.51%	16.36%	16.21%	16.07%	15.93%	15.79%	15.65%	15.51%	15.37%	15.24%	15.11%	14.99%	14.86%	14.74%	14.62%	14.50%	14.38%	14.26%	14.15%	14.04%	13.93%	13.82%	13.71%	13.61%	13.50%	13.40%	13.30%	13.20%	13.10%	13.01%	12.91%	12.82%	12.72%	12.63%	12.54%	12.46%	12.37%	12.28%	12.20%	12.11%	12.03%	11.95%	11.87%	11.79%	11.72%	11.64%	11.56%	11.49%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.92%	10.86%	10.79%	10.73%	10.66%	10.60%	10.54%	10.47%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.19%	97.50%	94.25%	90.35%	86.12%	82.24%	78.68%	75.39%	72.27%	69.44%	66.79%	64.28%	61.93%	59.75%	57.75%	55.90%	54.15%	52.51%	50.98%	49.52%	48.12%	46.79%	45.50%	44.30%	43.16%	42.09%	41.08%	40.12%	39.20%	38.33%	37.49%	36.68%	35.91%	35.17%	34.47%	33.80%	33.16%	32.54%	31.94%	31.36%	30.81%	30.27%	29.76%	29.26%	28.78%	28.31%	27.86%	27.43%	27.01%	26.60%	26.20%	25.81%	25.43%	25.07%	24.71%	24.36%	24.02%	23.69%	23.36%	23.05%	22.75%	22.45%	22.16%	21.88%	21.61%	21.35%	21.09%	20.83%	20.59%	20.35%	20.11%	19.88%	19.66%	19.44%	19.22%	19.01%	18.81%	18.61%	18.42%	18.22%	18.04%	17.86%	17.68%	17.50%	17.33%	17.16%	17.00%	16.84%	16.68%	16.53%	16.38%	16.23%	16.08%	15.94%	15.80%	15.66%	15.52%	15.39%	15.26%	15.13%	15.00%	14.87%	14.75%	14.63%	14.51%	14.39%	14.27%	14.16%	14.05%	13.94%	13.83%	13.72%	13.62%	13.51%	13.41%	13.31%	13.21%	13.11%	13.01%	12.92%	12.83%	12.73%	12.64%	12.55%	12.46%	12.38%	12.29%	12.20%	12.12%	12.04%	11.96%	11.88%	11.80%	11.72%	11.64%	11.57%	11.49%	11.42%	11.35%	11.27%	11.20%	11.13%	11.06%	10.99%	10.93%	10.86%	10.79%	10.73%	10.67%	10.60%	10.54%	10.48%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.20%	97.55%	94.30%	90.39%	86.15%	82.26%	78.70%	75.41%	72.30%	69.46%	66.82%	64.31%	61.96%	59.78%	57.78%	55.92%	54.17%	52.53%	51.00%	49.54%	48.14%	46.82%	45.53%	44.32%	43.18%	42.11%	41.10%	40.14%	39.22%	38.35%	37.51%	36.71%	35.93%	35.20%	34.49%	33.82%	33.18%	32.56%	31.96%	31.38%	30.83%	30.30%	29.78%	29.28%	28.80%	28.33%	27.88%	27.45%	27.03%	26.62%	26.22%	25.83%	25.46%	25.09%	24.73%	24.38%	24.04%	23.71%	23.38%	23.07%	22.77%	22.47%	22.18%	21.90%	21.63%	21.36%	21.10%	20.85%	20.60%	20.36%	20.13%	19.90%	19.67%	19.45%	19.24%	19.03%	18.83%	18.63%	18.43%	18.24%	18.05%	17.87%	17.69%	17.52%	17.35%	17.18%	17.01%	16.85%	16.70%	16.54%	16.39%	16.24%	16.10%	15.95%	15.81%	15.67%	15.54%	15.40%	15.27%	15.14%	15.01%	14.88%	14.76%	14.64%	14.52%	14.40%	14.29%	14.17%	14.06%	13.95%	13.84%	13.73%	13.63%	13.52%	13.42%	13.32%	13.22%	13.12%	13.02%	12.93%	12.83%	12.74%	12.65%	12.56%	12.47%	12.38%	12.30%	12.21%	12.13%	12.04%	11.96%	11.88%	11.80%	11.73%	11.65%	11.57%	11.50%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.93%	10.86%	10.80%	10.73%	10.67%	10.61%	10.54%	10.48%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.21%	97.60%	94.33%	90.43%	86.18%	82.29%	78.71%	75.43%	72.32%	69.48%	66.86%	64.34%	61.99%	59.81%	57.81%	55.95%	54.20%	52.56%	51.02%	49.56%	48.16%	46.84%	45.55%	44.34%	43.20%	42.13%	41.11%	40.16%	39.24%	38.37%	37.54%	36.73%	35.96%	35.22%	34.52%	33.84%	33.20%	32.58%	31.98%	31.41%	30.85%	30.32%	29.80%	29.30%	28.82%	28.35%	27.91%	27.47%	27.05%	26.64%	26.24%	25.85%	25.48%	25.11%	24.76%	24.40%	24.06%	23.73%	23.41%	23.09%	22.79%	22.49%	22.20%	21.92%	21.65%	21.38%	21.12%	20.87%	20.62%	20.38%	20.15%	19.92%	19.69%	19.47%	19.26%	19.05%	18.84%	18.64%	18.45%	18.26%	18.07%	17.89%	17.71%	17.53%	17.36%	17.19%	17.03%	16.87%	16.71%	16.56%	16.41%	16.26%	16.11%	15.97%	15.82%	15.69%	15.55%	15.41%	15.28%	15.15%	15.02%	14.90%	14.77%	14.65%	14.53%	14.41%	14.30%	14.18%	14.07%	13.96%	13.85%	13.74%	13.64%	13.53%	13.43%	13.33%	13.22%	13.13%	13.03%	12.93%	12.84%	12.75%	12.65%	12.56%	12.47%	12.39%	12.30%	12.22%	12.13%	12.05%	11.97%	11.89%	11.81%	11.73%	11.65%	11.58%	11.50%	11.43%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.93%	10.87%	10.80%	10.74%	10.67%	10.61%	10.55%	10.49%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.22%	97.65%	94.37%	90.47%	86.21%	82.31%	78.72%	75.44%	72.35%	69.50%	66.89%	64.37%	62.03%	59.85%	57.83%	55.97%	54.22%	52.58%	51.05%	49.57%	48.18%	46.86%	45.57%	44.36%	43.22%	42.14%	41.13%	40.17%	39.26%	38.39%	37.56%	36.75%	35.98%	35.24%	34.54%	33.87%	33.22%	32.60%	32.00%	31.43%	30.87%	30.34%	29.82%	29.32%	28.84%	28.37%	27.93%	27.49%	27.07%	26.67%	26.27%	25.88%	25.50%	25.14%	24.78%	24.42%	24.08%	23.75%	23.43%	23.11%	22.81%	22.51%	22.22%	21.94%	21.67%	21.40%	21.14%	20.89%	20.64%	20.40%	20.16%	19.93%	19.71%	19.49%	19.27%	19.06%	18.86%	18.66%	18.46%	18.27%	18.08%	17.90%	17.72%	17.55%	17.38%	17.21%	17.04%	16.88%	16.73%	16.57%	16.42%	16.27%	16.12%	15.98%	15.84%	15.70%	15.56%	15.42%	15.29%	15.16%	15.03%	14.91%	14.78%	14.66%	14.54%	14.42%	14.31%	14.19%	14.08%	13.97%	13.86%	13.75%	13.64%	13.54%	13.43%	13.33%	13.23%	13.13%	13.03%	12.94%	12.84%	12.75%	12.66%	12.57%	12.48%	12.39%	12.31%	12.22%	12.14%	12.06%	11.97%	11.89%	11.81%	11.74%	11.66%	11.58%	11.51%	11.43%	11.36%	11.29%	11.21%	11.14%	11.07%	11.01%	10.94%	10.87%	10.81%	10.74%	10.68%	10.61%	10.55%	10.49%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.23%	97.70%	94.40%	90.51%	86.24%	82.33%	78.74%	75.45%	72.37%	69.52%	66.93%	64.40%	62.06%	59.88%	57.86%	56.00%	54.24%	52.60%	51.07%	49.59%	48.19%	46.87%	45.59%	44.38%	43.24%	42.16%	41.15%	40.19%	39.28%	38.40%	37.58%	36.77%	36.00%	35.27%	34.56%	33.89%	33.24%	32.62%	32.02%	31.45%	30.89%	30.36%	29.84%	29.34%	28.86%	28.39%	27.95%	27.51%	27.10%	26.69%	26.29%	25.90%	25.52%	25.16%	24.80%	24.44%	24.10%	23.77%	23.45%	23.13%	22.83%	22.53%	22.24%	21.96%	21.69%	21.42%	21.16%	20.91%	20.66%	20.42%	20.18%	19.95%	19.72%	19.50%	19.29%	19.08%	18.87%	18.67%	18.48%	18.29%	18.10%	17.92%	17.74%	17.56%	17.39%	17.22%	17.06%	16.90%	16.74%	16.59%	16.43%	16.28%	16.14%	15.99%	15.85%	15.71%	15.57%	15.44%	15.30%	15.17%	15.04%	14.92%	14.79%	14.67%	14.55%	14.43%	14.31%	14.20%	14.09%	13.98%	13.86%	13.76%	13.65%	13.54%	13.44%	13.34%	13.24%	13.14%	13.04%	12.94%	12.85%	12.76%	12.67%	12.58%	12.49%	12.40%	12.31%	12.23%	12.14%	12.06%	11.98%	11.90%	11.82%	11.74%	11.66%	11.59%	11.51%	11.44%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.94%	10.88%	10.81%	10.74%	10.68%	10.62%	10.55%	10.49%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.24%	97.74%	94.44%	90.55%	86.27%	82.35%	78.75%	75.47%	72.39%	69.54%	66.96%	64.43%	62.09%	59.91%	57.88%	56.02%	54.27%	52.62%	51.09%	49.61%	48.21%	46.89%	45.61%	44.40%	43.25%	42.18%	41.17%	40.21%	39.30%	38.42%	37.59%	36.79%	36.02%	35.29%	34.58%	33.91%	33.26%	32.64%	32.04%	31.46%	30.91%	30.38%	29.86%	29.36%	28.88%	28.41%	27.97%	27.53%	27.12%	26.71%	26.31%	25.92%	25.54%	25.18%	24.82%	24.46%	24.12%	23.79%	23.47%	23.15%	22.85%	22.55%	22.26%	21.98%	21.71%	21.44%	21.18%	20.92%	20.68%	20.43%	20.20%	19.97%	19.74%	19.52%	19.30%	19.09%	18.89%	18.69%	18.49%	18.30%	18.11%	17.93%	17.75%	17.58%	17.41%	17.24%	17.07%	16.91%	16.75%	16.60%	16.45%	16.30%	16.15%	16.00%	15.86%	15.72%	15.58%	15.45%	15.31%	15.18%	15.05%	14.93%	14.80%	14.68%	14.56%	14.44%	14.32%	14.21%	14.09%	13.98%	13.87%	13.76%	13.66%	13.55%	13.45%	13.34%	13.24%	13.14%	13.05%	12.95%	12.86%	12.76%	12.67%	12.58%	12.49%	12.40%	12.32%	12.23%	12.15%	12.06%	11.98%	11.90%	11.82%	11.74%	11.67%	11.59%	11.51%	11.44%	11.37%	11.29%	11.22%	11.15%	11.08%	11.01%	10.95%	10.88%	10.81%	10.75%	10.68%	10.62%	10.56%	10.50%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.25%	97.78%	94.47%	90.60%	86.30%	82.37%	78.76%	75.48%	72.41%	69.57%	66.99%	64.46%	62.12%	59.93%	57.91%	56.04%	54.29%	52.65%	51.11%	49.62%	48.22%	46.90%	45.63%	44.41%	43.27%	42.20%	41.18%	40.22%	39.31%	38.44%	37.61%	36.82%	36.05%	35.31%	34.61%	33.93%	33.29%	32.66%	32.06%	31.48%	30.93%	30.40%	29.88%	29.38%	28.90%	28.43%	27.99%	27.55%	27.14%	26.73%	26.33%	25.94%	25.56%	25.20%	24.84%	24.48%	24.14%	23.81%	23.49%	23.17%	22.87%	22.57%	22.28%	22.00%	21.72%	21.46%	21.19%	20.94%	20.69%	20.45%	20.21%	19.98%	19.76%	19.53%	19.32%	19.11%	18.90%	18.70%	18.51%	18.31%	18.13%	17.94%	17.76%	17.59%	17.42%	17.25%	17.09%	16.92%	16.77%	16.61%	16.46%	16.31%	16.16%	16.01%	15.87%	15.73%	15.59%	15.46%	15.32%	15.19%	15.06%	14.94%	14.81%	14.69%	14.57%	14.45%	14.33%	14.22%	14.10%	13.99%	13.88%	13.77%	13.66%	13.56%	13.45%	13.35%	13.25%	13.15%	13.05%	12.96%	12.86%	12.77%	12.68%	12.59%	12.50%	12.41%	12.32%	12.24%	12.15%	12.07%	11.99%	11.91%	11.83%	11.75%	11.67%	11.59%	11.52%	11.44%	11.37%	11.30%	11.23%	11.16%	11.09%	11.02%	10.95%	10.88%	10.82%	10.75%	10.69%	10.62%	10.56%	10.50%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.26%	97.82%	94.51%	90.64%	86.33%	82.39%	78.76%	75.49%	72.42%	69.59%	67.01%	64.49%	62.15%	59.96%	57.93%	56.07%	54.31%	52.67%	51.13%	49.63%	48.24%	46.92%	45.65%	44.43%	43.29%	42.21%	41.20%	40.24%	39.33%	38.46%	37.63%	36.84%	36.07%	35.33%	34.63%	33.95%	33.31%	32.68%	32.08%	31.50%	30.95%	30.41%	29.90%	29.40%	28.92%	28.45%	28.01%	27.57%	27.16%	26.75%	26.35%	25.96%	25.59%	25.22%	24.86%	24.50%	24.16%	23.83%	23.51%	23.19%	22.89%	22.59%	22.30%	22.02%	21.74%	21.47%	21.21%	20.96%	20.71%	20.46%	20.23%	20.00%	19.77%	19.55%	19.33%	19.12%	18.92%	18.72%	18.52%	18.33%	18.14%	17.96%	17.78%	17.60%	17.43%	17.26%	17.10%	16.94%	16.78%	16.62%	16.47%	16.32%	16.17%	16.02%	15.88%	15.74%	15.60%	15.47%	15.33%	15.20%	15.07%	14.95%	14.82%	14.70%	14.58%	14.46%	14.34%	14.22%	14.11%	14.00%	13.88%	13.78%	13.67%	13.56%	13.46%	13.36%	13.26%	13.16%	13.06%	12.96%	12.87%	12.78%	12.68%	12.59%	12.50%	12.41%	12.33%	12.24%	12.16%	12.07%	11.99%	11.91%	11.83%	11.75%	11.67%	11.60%	11.52%	11.45%	11.37%	11.30%	11.23%	11.16%	11.09%	11.02%	10.95%	10.89%	10.82%	10.76%	10.69%	10.63%	10.56%	10.50%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.27%	97.86%	94.54%	90.68%	86.36%	82.41%	78.77%	75.50%	72.44%	69.61%	67.04%	64.52%	62.18%	59.99%	57.96%	56.09%	54.34%	52.69%	51.15%	49.65%	48.25%	46.93%	45.67%	44.45%	43.31%	42.23%	41.22%	40.25%	39.34%	38.48%	37.65%	36.86%	36.09%	35.35%	34.65%	33.98%	33.33%	32.70%	32.10%	31.52%	30.96%	30.43%	29.92%	29.42%	28.94%	28.47%	28.03%	27.59%	27.17%	26.77%	26.37%	25.99%	25.61%	25.24%	24.88%	24.52%	24.18%	23.85%	23.53%	23.21%	22.91%	22.61%	22.32%	22.03%	21.76%	21.49%	21.23%	20.97%	20.72%	20.48%	20.24%	20.01%	19.78%	19.56%	19.35%	19.14%	18.93%	18.73%	18.53%	18.34%	18.15%	17.97%	17.79%	17.62%	17.44%	17.28%	17.11%	16.95%	16.79%	16.64%	16.48%	16.33%	16.18%	16.04%	15.89%	15.75%	15.61%	15.48%	15.34%	15.21%	15.08%	14.96%	14.83%	14.71%	14.58%	14.46%	14.34%	14.23%	14.11%	14.00%	13.89%	13.78%	13.67%	13.57%	13.46%	13.36%	13.26%	13.16%	13.06%	12.97%	12.87%	12.78%	12.69%	12.60%	12.51%	12.42%	12.33%	12.24%	12.16%	12.08%	11.99%	11.91%	11.83%	11.75%	11.68%	11.60%	11.52%	11.45%	11.38%	11.30%	11.23%	11.16%	11.09%	11.02%	10.96%	10.89%	10.82%	10.76%	10.69%	10.63%	10.57%	10.51%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.29%	97.89%	94.57%	90.72%	86.39%	82.43%	78.78%	75.51%	72.46%	69.63%	67.06%	64.54%	62.21%	60.01%	57.99%	56.12%	54.36%	52.71%	51.17%	49.66%	48.26%	46.94%	45.69%	44.47%	43.32%	42.25%	41.23%	40.27%	39.36%	38.49%	37.66%	36.87%	36.11%	35.37%	34.67%	34.00%	33.35%	32.72%	32.12%	31.54%	30.98%	30.45%	29.93%	29.44%	28.96%	28.49%	28.05%	27.61%	27.19%	26.79%	26.39%	26.01%	25.63%	25.26%	24.90%	24.54%	24.20%	23.87%	23.55%	23.23%	22.93%	22.63%	22.34%	22.05%	21.78%	21.51%	21.24%	20.99%	20.74%	20.49%	20.26%	20.03%	19.80%	19.58%	19.36%	19.15%	18.94%	18.74%	18.55%	18.35%	18.17%	17.98%	17.80%	17.63%	17.46%	17.29%	17.12%	16.96%	16.80%	16.65%	16.49%	16.34%	16.19%	16.04%	15.90%	15.76%	15.62%	15.49%	15.35%	15.22%	15.09%	14.96%	14.84%	14.71%	14.59%	14.47%	14.35%	14.23%	14.12%	14.01%	13.90%	13.79%	13.68%	13.57%	13.47%	13.37%	13.27%	13.17%	13.07%	12.97%	12.88%	12.78%	12.69%	12.60%	12.51%	12.42%	12.33%	12.25%	12.16%	12.08%	12.00%	11.92%	11.84%	11.76%	11.68%	11.60%	11.53%	11.45%	11.38%	11.31%	11.24%	11.17%	11.10%	11.03%	10.96%	10.89%	10.83%	10.76%	10.70%	10.63%	10.57%	10.51%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.30%	97.93%	94.59%	90.76%	86.42%	82.45%	78.78%	75.53%	72.48%	69.65%	67.08%	64.57%	62.23%	60.04%	58.01%	56.14%	54.39%	52.74%	51.18%	49.68%	48.27%	46.96%	45.71%	44.49%	43.34%	42.27%	41.25%	40.29%	39.38%	38.51%	37.68%	36.89%	36.13%	35.39%	34.69%	34.02%	33.37%	32.74%	32.14%	31.56%	31.00%	30.47%	29.95%	29.46%	28.98%	28.51%	28.06%	27.63%	27.21%	26.80%	26.41%	26.03%	25.65%	25.28%	24.92%	24.56%	24.22%	23.89%	23.56%	23.25%	22.95%	22.65%	22.35%	22.07%	21.79%	21.52%	21.26%	21.00%	20.75%	20.51%	20.27%	20.04%	19.81%	19.59%	19.37%	19.16%	18.96%	18.75%	18.56%	18.37%	18.18%	17.99%	17.81%	17.64%	17.47%	17.30%	17.13%	16.97%	16.81%	16.66%	16.50%	16.35%	16.20%	16.05%	15.91%	15.77%	15.63%	15.50%	15.36%	15.23%	15.10%	14.97%	14.84%	14.72%	14.60%	14.48%	14.36%	14.24%	14.13%	14.01%	13.90%	13.79%	13.69%	13.58%	13.48%	13.37%	13.27%	13.17%	13.07%	12.98%	12.88%	12.79%	12.69%	12.60%	12.51%	12.43%	12.34%	12.25%	12.17%	12.08%	12.00%	11.92%	11.84%	11.76%	11.68%	11.61%	11.53%	11.46%	11.38%	11.31%	11.24%	11.17%	11.10%	11.03%	10.96%	10.90%	10.83%	10.76%	10.70%	10.64%	10.57%	10.51%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.31%	97.97%	94.62%	90.80%	86.45%	82.46%	78.79%	75.54%	72.49%	69.68%	67.10%	64.59%	62.25%	60.07%	58.04%	56.17%	54.41%	52.76%	51.19%	49.69%	48.28%	46.97%	45.72%	44.51%	43.36%	42.28%	41.27%	40.30%	39.39%	38.52%	37.70%	36.91%	36.15%	35.41%	34.71%	34.03%	33.39%	32.76%	32.15%	31.57%	31.02%	30.48%	29.97%	29.47%	28.99%	28.53%	28.08%	27.65%	27.23%	26.82%	26.43%	26.05%	25.67%	25.30%	24.94%	24.58%	24.24%	23.91%	23.58%	23.27%	22.96%	22.66%	22.37%	22.09%	21.81%	21.54%	21.28%	21.02%	20.77%	20.52%	20.29%	20.05%	19.83%	19.60%	19.39%	19.18%	18.97%	18.77%	18.57%	18.38%	18.19%	18.01%	17.83%	17.65%	17.48%	17.31%	17.15%	16.98%	16.83%	16.67%	16.51%	16.36%	16.21%	16.06%	15.92%	15.78%	15.64%	15.51%	15.37%	15.24%	15.11%	14.98%	14.85%	14.73%	14.60%	14.48%	14.36%	14.25%	14.13%	14.02%	13.91%	13.80%	13.69%	13.59%	13.48%	13.38%	13.28%	13.18%	13.08%	12.98%	12.89%	12.79%	12.70%	12.61%	12.52%	12.43%	12.34%	12.26%	12.17%	12.09%	12.00%	11.92%	11.84%	11.76%	11.69%	11.61%	11.54%	11.46%	11.39%	11.31%	11.24%	11.17%	11.10%	11.03%	10.97%	10.90%	10.83%	10.77%	10.70%	10.64%	10.58%	10.51%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.32%	98.01%	94.65%	90.84%	86.48%	82.47%	78.80%	75.55%	72.51%	69.70%	67.12%	64.62%	62.28%	60.09%	58.06%	56.19%	54.43%	52.78%	51.21%	49.70%	48.29%	46.98%	45.73%	44.52%	43.38%	42.30%	41.28%	40.32%	39.41%	38.54%	37.71%	36.92%	36.17%	35.43%	34.73%	34.05%	33.40%	32.78%	32.17%	31.59%	31.04%	30.50%	29.99%	29.49%	29.01%	28.55%	28.10%	27.66%	27.24%	26.84%	26.44%	26.06%	25.69%	25.32%	24.96%	24.60%	24.26%	23.93%	23.60%	23.29%	22.98%	22.68%	22.39%	22.10%	21.83%	21.56%	21.29%	21.03%	20.78%	20.54%	20.30%	20.07%	19.84%	19.62%	19.40%	19.19%	18.98%	18.78%	18.58%	18.39%	18.20%	18.02%	17.84%	17.66%	17.49%	17.32%	17.16%	17.00%	16.84%	16.68%	16.52%	16.37%	16.22%	16.07%	15.93%	15.79%	15.65%	15.51%	15.38%	15.25%	15.11%	14.98%	14.86%	14.73%	14.61%	14.49%	14.37%	14.25%	14.14%	14.02%	13.91%	13.80%	13.70%	13.59%	13.49%	13.38%	13.28%	13.18%	13.08%	12.99%	12.89%	12.80%	12.70%	12.61%	12.52%	12.43%	12.35%	12.26%	12.17%	12.09%	12.01%	11.93%	11.85%	11.77%	11.69%	11.61%	11.54%	11.46%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.90%	10.84%	10.77%	10.71%	10.64%	10.58%	10.52%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.33%	98.05%	94.67%	90.87%	86.50%	82.49%	78.80%	75.56%	72.53%	69.72%	67.14%	64.64%	62.30%	60.12%	58.09%	56.22%	54.46%	52.81%	51.22%	49.71%	48.30%	46.99%	45.74%	44.54%	43.40%	42.32%	41.30%	40.34%	39.42%	38.55%	37.73%	36.94%	36.18%	35.45%	34.74%	34.07%	33.42%	32.80%	32.19%	31.61%	31.05%	30.52%	30.00%	29.51%	29.03%	28.56%	28.12%	27.68%	27.26%	26.85%	26.46%	26.08%	25.71%	25.34%	24.98%	24.62%	24.28%	23.94%	23.62%	23.30%	23.00%	22.70%	22.41%	22.12%	21.84%	21.57%	21.31%	21.05%	20.80%	20.55%	20.31%	20.08%	19.85%	19.63%	19.41%	19.20%	18.99%	18.79%	18.60%	18.40%	18.21%	18.03%	17.85%	17.67%	17.50%	17.33%	17.17%	17.01%	16.85%	16.69%	16.53%	16.38%	16.23%	16.08%	15.94%	15.80%	15.66%	15.52%	15.39%	15.25%	15.12%	14.99%	14.86%	14.74%	14.61%	14.49%	14.37%	14.26%	14.14%	14.03%	13.92%	13.81%	13.70%	13.60%	13.49%	13.39%	13.29%	13.19%	13.09%	12.99%	12.89%	12.80%	12.71%	12.61%	12.52%	12.44%	12.35%	12.26%	12.18%	12.09%	12.01%	11.93%	11.85%	11.77%	11.69%	11.62%	11.54%	11.47%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.91%	10.84%	10.77%	10.71%	10.65%	10.58%	10.52%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.34%	98.09%	94.70%	90.90%	86.53%	82.50%	78.81%	75.58%	72.55%	69.74%	67.16%	64.67%	62.32%	60.15%	58.11%	56.24%	54.48%	52.83%	51.23%	49.72%	48.31%	47.00%	45.76%	44.56%	43.41%	42.34%	41.32%	40.35%	39.44%	38.57%	37.74%	36.95%	36.20%	35.47%	34.76%	34.09%	33.44%	32.82%	32.21%	31.63%	31.07%	30.54%	30.02%	29.52%	29.04%	28.58%	28.13%	27.70%	27.28%	26.87%	26.48%	26.10%	25.73%	25.36%	25.00%	24.64%	24.30%	23.96%	23.64%	23.32%	23.01%	22.71%	22.42%	22.14%	21.86%	21.59%	21.32%	21.07%	20.81%	20.57%	20.33%	20.09%	19.87%	19.64%	19.43%	19.21%	19.01%	18.81%	18.61%	18.42%	18.23%	18.04%	17.86%	17.69%	17.51%	17.34%	17.18%	17.02%	16.86%	16.70%	16.54%	16.39%	16.24%	16.09%	15.95%	15.81%	15.67%	15.53%	15.39%	15.26%	15.13%	15.00%	14.87%	14.74%	14.62%	14.50%	14.38%	14.26%	14.15%	14.04%	13.92%	13.81%	13.71%	13.60%	13.49%	13.39%	13.29%	13.19%	13.09%	12.99%	12.90%	12.80%	12.71%	12.62%	12.53%	12.44%	12.35%	12.27%	12.18%	12.10%	12.02%	11.93%	11.85%	11.78%	11.70%	11.62%	11.55%	11.47%	11.40%	11.32%	11.25%	11.18%	11.11%	11.04%	10.98%	10.91%	10.84%	10.78%	10.71%	10.65%	10.59%	10.52%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.35%	98.13%	94.73%	90.94%	86.55%	82.51%	78.82%	75.59%	72.56%	69.76%	67.18%	64.69%	62.35%	60.18%	58.14%	56.27%	54.50%	52.85%	51.24%	49.73%	48.32%	47.01%	45.77%	44.58%	43.43%	42.35%	41.33%	40.37%	39.46%	38.58%	37.75%	36.96%	36.21%	35.49%	34.78%	34.10%	33.46%	32.83%	32.23%	31.65%	31.09%	30.56%	30.04%	29.54%	29.06%	28.59%	28.15%	27.71%	27.30%	26.89%	26.49%	26.11%	25.74%	25.38%	25.02%	24.66%	24.31%	23.98%	23.65%	23.34%	23.03%	22.73%	22.44%	22.15%	21.88%	21.60%	21.34%	21.08%	20.83%	20.58%	20.34%	20.11%	19.88%	19.66%	19.44%	19.23%	19.02%	18.82%	18.62%	18.43%	18.24%	18.05%	17.87%	17.70%	17.53%	17.36%	17.19%	17.03%	16.87%	16.71%	16.55%	16.40%	16.25%	16.10%	15.96%	15.82%	15.68%	15.54%	15.40%	15.27%	15.13%	15.00%	14.88%	14.75%	14.63%	14.51%	14.39%	14.27%	14.15%	14.04%	13.93%	13.82%	13.71%	13.60%	13.50%	13.40%	13.29%	13.19%	13.09%	13.00%	12.90%	12.81%	12.71%	12.62%	12.53%	12.44%	12.36%	12.27%	12.19%	12.10%	12.02%	11.94%	11.86%	11.78%	11.70%	11.62%	11.55%	11.47%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.91%	10.85%	10.78%	10.72%	10.65%	10.59%	10.53%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.36%	98.17%	94.75%	90.97%	86.57%	82.51%	78.82%	75.60%	72.58%	69.77%	67.20%	64.72%	62.37%	60.20%	58.16%	56.29%	54.53%	52.87%	51.25%	49.74%	48.33%	47.02%	45.78%	44.60%	43.45%	42.37%	41.35%	40.39%	39.47%	38.60%	37.77%	36.98%	36.22%	35.50%	34.80%	34.12%	33.47%	32.85%	32.25%	31.67%	31.11%	30.57%	30.05%	29.55%	29.07%	28.61%	28.16%	27.73%	27.31%	26.90%	26.51%	26.13%	25.76%	25.39%	25.03%	24.68%	24.33%	24.00%	23.67%	23.36%	23.05%	22.75%	22.45%	22.17%	21.89%	21.62%	21.35%	21.09%	20.84%	20.59%	20.35%	20.12%	19.89%	19.67%	19.45%	19.24%	19.03%	18.83%	18.63%	18.44%	18.25%	18.07%	17.89%	17.71%	17.54%	17.37%	17.20%	17.04%	16.88%	16.72%	16.56%	16.41%	16.26%	16.11%	15.97%	15.82%	15.68%	15.54%	15.41%	15.27%	15.14%	15.01%	14.88%	14.76%	14.63%	14.51%	14.39%	14.27%	14.16%	14.05%	13.93%	13.82%	13.71%	13.61%	13.50%	13.40%	13.30%	13.20%	13.10%	13.00%	12.90%	12.81%	12.72%	12.63%	12.54%	12.45%	12.36%	12.27%	12.19%	12.11%	12.02%	11.94%	11.86%	11.78%	11.70%	11.63%	11.55%	11.48%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.92%	10.85%	10.78%	10.72%	10.66%	10.59%	10.53%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.37%	98.21%	94.78%	91.00%	86.60%	82.52%	78.83%	75.60%	72.59%	69.79%	67.22%	64.74%	62.39%	60.23%	58.19%	56.31%	54.55%	52.88%	51.26%	49.75%	48.34%	47.03%	45.79%	44.61%	43.47%	42.39%	41.37%	40.40%	39.48%	38.61%	37.78%	36.99%	36.24%	35.51%	34.82%	34.14%	33.49%	32.87%	32.26%	31.68%	31.12%	30.59%	30.07%	29.57%	29.09%	28.62%	28.18%	27.74%	27.33%	26.92%	26.53%	26.14%	25.78%	25.41%	25.05%	24.70%	24.35%	24.01%	23.69%	23.37%	23.07%	22.76%	22.47%	22.18%	21.91%	21.64%	21.37%	21.11%	20.86%	20.61%	20.37%	20.13%	19.91%	19.68%	19.47%	19.25%	19.05%	18.84%	18.65%	18.45%	18.26%	18.08%	17.90%	17.72%	17.55%	17.38%	17.21%	17.05%	16.89%	16.73%	16.57%	16.42%	16.27%	16.12%	15.97%	15.83%	15.69%	15.55%	15.41%	15.28%	15.15%	15.02%	14.89%	14.76%	14.64%	14.52%	14.40%	14.28%	14.16%	14.05%	13.94%	13.83%	13.72%	13.61%	13.51%	13.40%	13.30%	13.20%	13.10%	13.00%	12.91%	12.81%	12.72%	12.63%	12.54%	12.45%	12.36%	12.28%	12.19%	12.11%	12.03%	11.94%	11.86%	11.79%	11.71%	11.63%	11.56%	11.48%	11.41%	11.33%	11.26%	11.19%	11.12%	11.05%	10.99%	10.92%	10.85%	10.79%	10.72%	10.66%	10.60%	10.53%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.38%	98.24%	94.81%	91.02%	86.62%	82.53%	78.84%	75.61%	72.60%	69.81%	67.24%	64.77%	62.42%	60.26%	58.22%	56.34%	54.57%	52.90%	51.27%	49.76%	48.35%	47.04%	45.80%	44.63%	43.49%	42.40%	41.38%	40.42%	39.50%	38.62%	37.79%	37.00%	36.25%	35.53%	34.84%	34.16%	33.51%	32.88%	32.28%	31.70%	31.14%	30.60%	30.08%	29.58%	29.10%	28.64%	28.19%	27.76%	27.34%	26.94%	26.54%	26.16%	25.79%	25.43%	25.07%	24.72%	24.37%	24.03%	23.71%	23.39%	23.08%	22.78%	22.49%	22.20%	21.92%	21.65%	21.38%	21.12%	20.87%	20.62%	20.38%	20.15%	19.92%	19.70%	19.48%	19.27%	19.06%	18.86%	18.66%	18.46%	18.28%	18.09%	17.91%	17.73%	17.56%	17.39%	17.22%	17.06%	16.90%	16.74%	16.58%	16.43%	16.28%	16.13%	15.98%	15.84%	15.70%	15.56%	15.42%	15.29%	15.15%	15.02%	14.89%	14.77%	14.64%	14.52%	14.40%	14.28%	14.17%	14.05%	13.94%	13.83%	13.72%	13.62%	13.51%	13.41%	13.31%	13.20%	13.11%	13.01%	12.91%	12.82%	12.72%	12.63%	12.54%	12.45%	12.37%	12.28%	12.20%	12.11%	12.03%	11.95%	11.87%	11.79%	11.71%	11.63%	11.56%	11.48%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.92%	10.86%	10.79%	10.73%	10.66%	10.60%	10.54%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.39%	98.27%	94.83%	91.04%	86.64%	82.53%	78.84%	75.62%	72.61%	69.82%	67.26%	64.79%	62.44%	60.28%	58.24%	56.36%	54.60%	52.91%	51.28%	49.77%	48.36%	47.05%	45.81%	44.64%	43.50%	42.42%	41.40%	40.43%	39.51%	38.63%	37.80%	37.01%	36.26%	35.54%	34.85%	34.18%	33.53%	32.90%	32.30%	31.72%	31.16%	30.62%	30.10%	29.60%	29.12%	28.65%	28.20%	27.77%	27.36%	26.95%	26.56%	26.18%	25.81%	25.44%	25.08%	24.73%	24.39%	24.05%	23.72%	23.41%	23.10%	22.79%	22.50%	22.21%	21.93%	21.66%	21.40%	21.14%	20.88%	20.64%	20.40%	20.16%	19.93%	19.71%	19.49%	19.28%	19.07%	18.87%	18.67%	18.48%	18.29%	18.10%	17.92%	17.74%	17.57%	17.40%	17.23%	17.07%	16.91%	16.75%	16.59%	16.43%	16.28%	16.14%	15.99%	15.85%	15.70%	15.56%	15.43%	15.29%	15.16%	15.03%	14.90%	14.77%	14.65%	14.53%	14.41%	14.29%	14.17%	14.06%	13.95%	13.84%	13.73%	13.62%	13.51%	13.41%	13.31%	13.21%	13.11%	13.01%	12.92%	12.82%	12.73%	12.64%	12.55%	12.46%	12.37%	12.28%	12.20%	12.12%	12.03%	11.95%	11.87%	11.79%	11.71%	11.64%	11.56%	11.49%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.92%	10.86%	10.79%	10.73%	10.66%	10.60%	10.54%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.41%	98.30%	94.86%	91.07%	86.66%	82.54%	78.85%	75.62%	72.62%	69.84%	67.28%	64.81%	62.46%	60.31%	58.27%	56.39%	54.62%	52.93%	51.29%	49.78%	48.37%	47.06%	45.82%	44.66%	43.52%	42.44%	41.42%	40.45%	39.52%	38.65%	37.82%	37.03%	36.27%	35.56%	34.86%	34.19%	33.54%	32.92%	32.31%	31.73%	31.17%	30.63%	30.11%	29.61%	29.13%	28.67%	28.22%	27.79%	27.37%	26.97%	26.58%	26.19%	25.82%	25.46%	25.10%	24.75%	24.40%	24.07%	23.74%	23.42%	23.11%	22.81%	22.52%	22.23%	21.95%	21.68%	21.41%	21.15%	20.90%	20.65%	20.41%	20.17%	19.95%	19.72%	19.50%	19.29%	19.08%	18.88%	18.68%	18.49%	18.30%	18.11%	17.93%	17.76%	17.58%	17.41%	17.24%	17.08%	16.91%	16.75%	16.60%	16.44%	16.29%	16.14%	16.00%	15.85%	15.71%	15.57%	15.43%	15.30%	15.16%	15.03%	14.90%	14.78%	14.65%	14.53%	14.41%	14.29%	14.18%	14.06%	13.95%	13.84%	13.73%	13.62%	13.52%	13.41%	13.31%	13.21%	13.11%	13.02%	12.92%	12.83%	12.73%	12.64%	12.55%	12.46%	12.37%	12.29%	12.20%	12.12%	12.04%	11.96%	11.88%	11.80%	11.72%	11.64%	11.57%	11.49%	11.42%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.93%	10.86%	10.80%	10.73%	10.67%	10.60%	10.54%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.42%	98.33%	94.89%	91.09%	86.68%	82.55%	78.86%	75.63%	72.63%	69.85%	67.30%	64.83%	62.49%	60.34%	58.29%	56.41%	54.64%	52.94%	51.30%	49.79%	48.38%	47.07%	45.83%	44.67%	43.54%	42.46%	41.43%	40.46%	39.54%	38.66%	37.83%	37.04%	36.28%	35.57%	34.88%	34.21%	33.56%	32.93%	32.33%	31.75%	31.19%	30.65%	30.13%	29.63%	29.14%	28.68%	28.23%	27.80%	27.38%	26.98%	26.59%	26.21%	25.84%	25.47%	25.11%	24.76%	24.42%	24.09%	23.76%	23.44%	23.13%	22.83%	22.53%	22.24%	21.96%	21.69%	21.42%	21.16%	20.91%	20.66%	20.42%	20.19%	19.96%	19.74%	19.52%	19.30%	19.10%	18.89%	18.69%	18.50%	18.31%	18.13%	17.95%	17.77%	17.59%	17.42%	17.25%	17.09%	16.92%	16.76%	16.61%	16.45%	16.30%	16.15%	16.01%	15.86%	15.72%	15.58%	15.44%	15.30%	15.17%	15.04%	14.91%	14.78%	14.66%	14.53%	14.41%	14.30%	14.18%	14.07%	13.95%	13.84%	13.73%	13.63%	13.52%	13.42%	13.32%	13.22%	13.12%	13.02%	12.92%	12.83%	12.74%	12.64%	12.55%	12.47%	12.38%	12.29%	12.21%	12.12%	12.04%	11.96%	11.88%	11.80%	11.72%	11.64%	11.57%	11.49%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.93%	10.86%	10.80%	10.73%	10.67%	10.61%	10.55%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.43%	98.36%	94.92%	91.12%	86.70%	82.56%	78.86%	75.64%	72.64%	69.87%	67.33%	64.85%	62.51%	60.36%	58.32%	56.44%	54.66%	52.95%	51.32%	49.80%	48.39%	47.08%	45.84%	44.68%	43.56%	42.47%	41.45%	40.48%	39.55%	38.67%	37.84%	37.05%	36.30%	35.58%	34.89%	34.23%	33.58%	32.95%	32.34%	31.76%	31.20%	30.67%	30.14%	29.64%	29.16%	28.69%	28.25%	27.82%	27.40%	27.00%	26.61%	26.23%	25.86%	25.49%	25.13%	24.78%	24.44%	24.10%	23.78%	23.46%	23.14%	22.84%	22.55%	22.26%	21.98%	21.70%	21.44%	21.18%	20.92%	20.68%	20.44%	20.20%	19.97%	19.75%	19.53%	19.32%	19.11%	18.91%	18.71%	18.51%	18.32%	18.14%	17.96%	17.78%	17.60%	17.43%	17.26%	17.10%	16.93%	16.77%	16.62%	16.46%	16.31%	16.16%	16.01%	15.86%	15.72%	15.58%	15.44%	15.31%	15.17%	15.04%	14.91%	14.79%	14.66%	14.54%	14.42%	14.30%	14.18%	14.07%	13.96%	13.85%	13.74%	13.63%	13.53%	13.42%	13.32%	13.22%	13.12%	13.02%	12.93%	12.83%	12.74%	12.65%	12.56%	12.47%	12.38%	12.30%	12.21%	12.13%	12.04%	11.96%	11.88%	11.80%	11.73%	11.65%	11.57%	11.50%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.93%	10.87%	10.80%	10.74%	10.67%	10.61%	10.55%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.44%	98.39%	94.94%	91.14%	86.71%	82.56%	78.87%	75.64%	72.66%	69.89%	67.35%	64.87%	62.53%	60.38%	58.34%	56.46%	54.68%	52.96%	51.33%	49.81%	48.40%	47.09%	45.85%	44.69%	43.57%	42.49%	41.47%	40.49%	39.56%	38.69%	37.86%	37.06%	36.31%	35.59%	34.90%	34.24%	33.59%	32.96%	32.36%	31.77%	31.22%	30.68%	30.16%	29.66%	29.17%	28.71%	28.26%	27.83%	27.41%	27.01%	26.62%	26.24%	25.87%	25.51%	25.15%	24.79%	24.45%	24.12%	23.79%	23.47%	23.16%	22.86%	22.56%	22.27%	21.99%	21.72%	21.45%	21.19%	20.94%	20.69%	20.45%	20.22%	19.99%	19.76%	19.54%	19.33%	19.12%	18.92%	18.72%	18.53%	18.34%	18.15%	17.97%	17.79%	17.61%	17.44%	17.27%	17.10%	16.94%	16.78%	16.62%	16.47%	16.32%	16.17%	16.02%	15.87%	15.73%	15.59%	15.45%	15.31%	15.18%	15.05%	14.92%	14.79%	14.67%	14.54%	14.42%	14.30%	14.19%	14.07%	13.96%	13.85%	13.74%	13.64%	13.53%	13.43%	13.32%	13.22%	13.12%	13.03%	12.93%	12.84%	12.74%	12.65%	12.56%	12.47%	12.39%	12.30%	12.21%	12.13%	12.05%	11.97%	11.89%	11.81%	11.73%	11.65%	11.58%	11.50%	11.43%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.94%	10.87%	10.81%	10.74%	10.68%	10.61%	10.55%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.45%	98.42%	94.97%	91.16%	86.73%	82.57%	78.88%	75.65%	72.67%	69.90%	67.36%	64.89%	62.56%	60.40%	58.37%	56.49%	54.70%	52.97%	51.34%	49.82%	48.41%	47.10%	45.86%	44.70%	43.59%	42.51%	41.48%	40.50%	39.58%	38.70%	37.87%	37.07%	36.32%	35.60%	34.92%	34.25%	33.61%	32.98%	32.37%	31.79%	31.23%	30.69%	30.18%	29.67%	29.19%	28.72%	28.28%	27.84%	27.43%	27.02%	26.63%	26.26%	25.89%	25.52%	25.16%	24.81%	24.47%	24.13%	23.81%	23.49%	23.18%	22.87%	22.57%	22.28%	22.00%	21.73%	21.46%	21.20%	20.95%	20.70%	20.46%	20.23%	20.00%	19.77%	19.56%	19.34%	19.13%	18.93%	18.73%	18.54%	18.35%	18.16%	17.98%	17.80%	17.62%	17.45%	17.28%	17.11%	16.95%	16.79%	16.63%	16.48%	16.32%	16.17%	16.02%	15.88%	15.73%	15.59%	15.45%	15.32%	15.18%	15.05%	14.92%	14.79%	14.67%	14.55%	14.43%	14.31%	14.19%	14.08%	13.97%	13.86%	13.75%	13.64%	13.53%	13.43%	13.33%	13.23%	13.13%	13.03%	12.94%	12.84%	12.75%	12.66%	12.57%	12.48%	12.39%	12.30%	12.22%	12.13%	12.05%	11.97%	11.89%	11.81%	11.73%	11.65%	11.58%	11.50%	11.43%	11.36%	11.29%	11.21%	11.14%	11.08%	11.01%	10.94%	10.87%	10.81%	10.74%	10.68%	10.62%	10.55%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.46%	98.45%	95.00%	91.17%	86.74%	82.58%	78.88%	75.65%	72.68%	69.92%	67.38%	64.91%	62.58%	60.43%	58.40%	56.51%	54.72%	52.98%	51.35%	49.83%	48.42%	47.11%	45.87%	44.70%	43.61%	42.53%	41.50%	40.52%	39.59%	38.71%	37.88%	37.09%	36.33%	35.61%	34.93%	34.27%	33.62%	32.99%	32.39%	31.80%	31.24%	30.71%	30.19%	29.69%	29.20%	28.74%	28.29%	27.86%	27.44%	27.04%	26.65%	26.27%	25.90%	25.54%	25.18%	24.82%	24.48%	24.15%	23.82%	23.50%	23.19%	22.88%	22.59%	22.30%	22.02%	21.74%	21.48%	21.22%	20.96%	20.72%	20.48%	20.24%	20.01%	19.79%	19.57%	19.36%	19.15%	18.94%	18.74%	18.55%	18.36%	18.17%	17.99%	17.81%	17.63%	17.46%	17.29%	17.12%	16.96%	16.80%	16.64%	16.48%	16.33%	16.18%	16.03%	15.88%	15.74%	15.60%	15.46%	15.32%	15.19%	15.05%	14.93%	14.80%	14.67%	14.55%	14.43%	14.31%	14.20%	14.08%	13.97%	13.86%	13.75%	13.64%	13.54%	13.43%	13.33%	13.23%	13.13%	13.04%	12.94%	12.84%	12.75%	12.66%	12.57%	12.48%	12.39%	12.31%	12.22%	12.14%	12.05%	11.97%	11.89%	11.81%	11.74%	11.66%	11.58%	11.51%	11.43%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.94%	10.88%	10.81%	10.75%	10.68%	10.62%	10.56%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.47%	98.48%	95.02%	91.19%	86.76%	82.58%	78.89%	75.66%	72.69%	69.94%	67.39%	64.93%	62.60%	60.45%	58.42%	56.54%	54.74%	52.99%	51.36%	49.84%	48.43%	47.12%	45.88%	44.71%	43.62%	42.54%	41.51%	40.53%	39.60%	38.73%	37.89%	37.10%	36.34%	35.63%	34.94%	34.28%	33.64%	33.01%	32.40%	31.82%	31.26%	30.72%	30.20%	29.70%	29.22%	28.75%	28.30%	27.87%	27.45%	27.05%	26.66%	26.29%	25.92%	25.55%	25.19%	24.84%	24.49%	24.16%	23.84%	23.52%	23.20%	22.90%	22.60%	22.31%	22.03%	21.76%	21.49%	21.23%	20.98%	20.73%	20.49%	20.26%	20.03%	19.80%	19.58%	19.37%	19.16%	18.95%	18.75%	18.56%	18.37%	18.18%	18.00%	17.82%	17.64%	17.47%	17.30%	17.13%	16.97%	16.80%	16.64%	16.49%	16.34%	16.18%	16.03%	15.89%	15.74%	15.60%	15.46%	15.33%	15.19%	15.06%	14.93%	14.80%	14.68%	14.56%	14.44%	14.32%	14.20%	14.09%	13.97%	13.86%	13.76%	13.65%	13.54%	13.44%	13.34%	13.24%	13.14%	13.04%	12.94%	12.85%	12.75%	12.66%	12.57%	12.48%	12.40%	12.31%	12.22%	12.14%	12.06%	11.98%	11.90%	11.82%	11.74%	11.66%	11.59%	11.51%	11.44%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.95%	10.88%	10.81%	10.75%	10.69%	10.62%	10.56%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.48%	98.50%	95.05%	91.21%	86.77%	82.59%	78.89%	75.67%	72.70%	69.95%	67.41%	64.95%	62.63%	60.47%	58.45%	56.56%	54.75%	53.00%	51.37%	49.85%	48.44%	47.13%	45.88%	44.72%	43.63%	42.56%	41.52%	40.54%	39.62%	38.74%	37.91%	37.11%	36.36%	35.64%	34.95%	34.29%	33.65%	33.02%	32.41%	31.83%	31.27%	30.74%	30.22%	29.72%	29.23%	28.77%	28.32%	27.89%	27.47%	27.06%	26.67%	26.30%	25.93%	25.57%	25.21%	24.85%	24.51%	24.17%	23.85%	23.53%	23.22%	22.91%	22.61%	22.32%	22.04%	21.77%	21.50%	21.24%	20.99%	20.74%	20.50%	20.27%	20.04%	19.81%	19.59%	19.38%	19.17%	18.97%	18.77%	18.57%	18.38%	18.19%	18.01%	17.83%	17.65%	17.48%	17.31%	17.14%	16.97%	16.81%	16.65%	16.50%	16.34%	16.19%	16.04%	15.89%	15.75%	15.61%	15.47%	15.33%	15.20%	15.06%	14.93%	14.81%	14.68%	14.56%	14.44%	14.32%	14.21%	14.09%	13.98%	13.87%	13.76%	13.65%	13.55%	13.44%	13.34%	13.24%	13.14%	13.04%	12.95%	12.85%	12.76%	12.67%	12.58%	12.49%	12.40%	12.31%	12.23%	12.14%	12.06%	11.98%	11.90%	11.82%	11.74%	11.67%	11.59%	11.51%	11.44%	11.37%	11.30%	11.22%	11.15%	11.09%	11.02%	10.95%	10.88%	10.82%	10.75%	10.69%	10.63%	10.56%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.49%	98.52%	95.08%	91.22%	86.79%	82.60%	78.90%	75.67%	72.71%	69.97%	67.42%	64.97%	62.65%	60.49%	58.47%	56.58%	54.77%	53.02%	51.38%	49.86%	48.45%	47.13%	45.89%	44.73%	43.64%	42.58%	41.54%	40.56%	39.63%	38.75%	37.92%	37.12%	36.37%	35.65%	34.96%	34.30%	33.66%	33.04%	32.43%	31.85%	31.29%	30.75%	30.23%	29.73%	29.25%	28.78%	28.33%	27.90%	27.48%	27.08%	26.69%	26.31%	25.95%	25.58%	25.22%	24.87%	24.52%	24.19%	23.86%	23.55%	23.23%	22.93%	22.63%	22.34%	22.06%	21.78%	21.51%	21.25%	21.00%	20.75%	20.51%	20.28%	20.05%	19.83%	19.61%	19.39%	19.18%	18.98%	18.78%	18.58%	18.39%	18.20%	18.02%	17.84%	17.66%	17.48%	17.31%	17.14%	16.98%	16.82%	16.66%	16.50%	16.35%	16.19%	16.04%	15.90%	15.75%	15.61%	15.47%	15.33%	15.20%	15.07%	14.94%	14.81%	14.69%	14.56%	14.44%	14.33%	14.21%	14.10%	13.98%	13.87%	13.76%	13.66%	13.55%	13.45%	13.34%	13.24%	13.14%	13.05%	12.95%	12.86%	12.76%	12.67%	12.58%	12.49%	12.40%	12.32%	12.23%	12.15%	12.07%	11.98%	11.90%	11.82%	11.75%	11.67%	11.59%	11.52%	11.44%	11.37%	11.30%	11.23%	11.16%	11.09%	11.02%	10.95%	10.89%	10.82%	10.76%	10.69%	10.63%	10.57%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.50%	98.54%	95.10%	91.24%	86.80%	82.60%	78.91%	75.68%	72.73%	69.99%	67.44%	64.99%	62.67%	60.52%	58.50%	56.60%	54.78%	53.03%	51.39%	49.88%	48.46%	47.14%	45.90%	44.74%	43.65%	42.59%	41.55%	40.57%	39.64%	38.76%	37.93%	37.14%	36.38%	35.66%	34.98%	34.31%	33.67%	33.05%	32.44%	31.86%	31.30%	30.76%	30.24%	29.74%	29.26%	28.80%	28.35%	27.91%	27.50%	27.09%	26.70%	26.32%	25.96%	25.60%	25.24%	24.88%	24.53%	24.20%	23.88%	23.56%	23.25%	22.94%	22.64%	22.35%	22.07%	21.79%	21.53%	21.27%	21.01%	20.77%	20.53%	20.29%	20.06%	19.84%	19.62%	19.40%	19.19%	18.99%	18.79%	18.59%	18.40%	18.21%	18.03%	17.84%	17.67%	17.49%	17.32%	17.15%	16.99%	16.82%	16.67%	16.51%	16.35%	16.20%	16.05%	15.90%	15.76%	15.61%	15.48%	15.34%	15.20%	15.07%	14.94%	14.82%	14.69%	14.57%	14.45%	14.33%	14.21%	14.10%	13.99%	13.88%	13.77%	13.66%	13.55%	13.45%	13.35%	13.25%	13.15%	13.05%	12.95%	12.86%	12.77%	12.67%	12.58%	12.49%	12.41%	12.32%	12.24%	12.15%	12.07%	11.99%	11.91%	11.83%	11.75%	11.67%	11.60%	11.52%	11.45%	11.37%	11.30%	11.23%	11.16%	11.09%	11.02%	10.96%	10.89%	10.82%	10.76%	10.70%	10.63%	10.57%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.51%	98.55%	95.13%	91.25%	86.82%	82.61%	78.91%	75.68%	72.74%	70.00%	67.45%	65.00%	62.70%	60.54%	58.52%	56.62%	54.80%	53.04%	51.40%	49.89%	48.47%	47.15%	45.91%	44.75%	43.66%	42.60%	41.57%	40.58%	39.66%	38.78%	37.94%	37.15%	36.39%	35.67%	34.99%	34.32%	33.68%	33.07%	32.46%	31.87%	31.31%	30.78%	30.26%	29.76%	29.28%	28.81%	28.36%	27.93%	27.51%	27.11%	26.71%	26.34%	25.97%	25.61%	25.25%	24.89%	24.55%	24.21%	23.89%	23.57%	23.26%	22.95%	22.66%	22.37%	22.08%	21.81%	21.54%	21.28%	21.03%	20.78%	20.54%	20.30%	20.07%	19.85%	19.63%	19.41%	19.20%	19.00%	18.80%	18.60%	18.41%	18.22%	18.04%	17.85%	17.67%	17.50%	17.33%	17.16%	16.99%	16.83%	16.67%	16.51%	16.36%	16.20%	16.05%	15.91%	15.76%	15.62%	15.48%	15.34%	15.21%	15.08%	14.95%	14.82%	14.70%	14.57%	14.45%	14.33%	14.22%	14.10%	13.99%	13.88%	13.77%	13.66%	13.56%	13.45%	13.35%	13.25%	13.15%	13.05%	12.96%	12.86%	12.77%	12.68%	12.59%	12.50%	12.41%	12.32%	12.24%	12.15%	12.07%	11.99%	11.91%	11.83%	11.75%	11.68%	11.60%	11.52%	11.45%	11.38%	11.31%	11.23%	11.16%	11.10%	11.03%	10.96%	10.89%	10.83%	10.76%	10.70%	10.64%	10.57%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.53%	98.57%	95.16%	91.27%	86.83%	82.62%	78.92%	75.69%	72.75%	70.02%	67.47%	65.02%	62.71%	60.56%	58.55%	56.64%	54.81%	53.05%	51.41%	49.90%	48.48%	47.16%	45.92%	44.76%	43.67%	42.62%	41.58%	40.60%	39.67%	38.79%	37.96%	37.16%	36.40%	35.69%	35.00%	34.33%	33.69%	33.08%	32.47%	31.89%	31.33%	30.79%	30.27%	29.77%	29.29%	28.83%	28.38%	27.94%	27.52%	27.12%	26.73%	26.35%	25.99%	25.63%	25.26%	24.91%	24.56%	24.23%	23.90%	23.59%	23.28%	22.97%	22.67%	22.38%	22.10%	21.82%	21.55%	21.29%	21.04%	20.79%	20.55%	20.31%	20.08%	19.86%	19.64%	19.43%	19.21%	19.01%	18.81%	18.61%	18.42%	18.23%	18.04%	17.86%	17.68%	17.51%	17.33%	17.17%	17.00%	16.84%	16.68%	16.52%	16.36%	16.21%	16.06%	15.91%	15.77%	15.62%	15.48%	15.35%	15.21%	15.08%	14.95%	14.83%	14.70%	14.58%	14.46%	14.34%	14.22%	14.11%	14.00%	13.88%	13.78%	13.67%	13.56%	13.46%	13.36%	13.26%	13.16%	13.06%	12.96%	12.87%	12.77%	12.68%	12.59%	12.50%	12.41%	12.33%	12.24%	12.16%	12.08%	11.99%	11.91%	11.83%	11.76%	11.68%	11.60%	11.53%	11.45%	11.38%	11.31%	11.24%	11.17%	11.10%	11.03%	10.96%	10.90%	10.83%	10.77%	10.70%	10.64%	10.58%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.54%	98.59%	95.18%	91.29%	86.85%	82.63%	78.93%	75.70%	72.76%	70.03%	67.49%	65.04%	62.73%	60.58%	58.57%	56.66%	54.83%	53.06%	51.42%	49.91%	48.49%	47.17%	45.93%	44.77%	43.67%	42.63%	41.59%	40.61%	39.68%	38.80%	37.97%	37.17%	36.42%	35.70%	35.01%	34.34%	33.70%	33.09%	32.49%	31.90%	31.34%	30.80%	30.28%	29.78%	29.30%	28.84%	28.39%	27.96%	27.54%	27.13%	26.74%	26.36%	26.00%	25.64%	25.28%	24.92%	24.58%	24.24%	23.91%	23.60%	23.29%	22.98%	22.68%	22.39%	22.11%	21.83%	21.57%	21.30%	21.05%	20.80%	20.56%	20.32%	20.09%	19.87%	19.65%	19.44%	19.23%	19.02%	18.82%	18.62%	18.43%	18.24%	18.05%	17.87%	17.69%	17.51%	17.34%	17.17%	17.01%	16.85%	16.68%	16.52%	16.37%	16.21%	16.06%	15.92%	15.77%	15.63%	15.49%	15.35%	15.22%	15.09%	14.96%	14.83%	14.70%	14.58%	14.46%	14.34%	14.23%	14.11%	14.00%	13.89%	13.78%	13.67%	13.57%	13.46%	13.36%	13.26%	13.16%	13.06%	12.97%	12.87%	12.78%	12.69%	12.59%	12.51%	12.42%	12.33%	12.25%	12.16%	12.08%	12.00%	11.92%	11.84%	11.76%	11.68%	11.61%	11.53%	11.46%	11.38%	11.31%	11.24%	11.17%	11.10%	11.03%	10.97%	10.90%	10.83%	10.77%	10.70%	10.64%	10.58%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.55%	98.61%	95.21%	91.30%	86.86%	82.63%	78.93%	75.70%	72.77%	70.04%	67.50%	65.06%	62.75%	60.60%	58.60%	56.68%	54.84%	53.07%	51.44%	49.92%	48.50%	47.18%	45.94%	44.78%	43.68%	42.63%	41.61%	40.63%	39.70%	38.82%	37.98%	37.19%	36.43%	35.71%	35.02%	34.35%	33.71%	33.10%	32.50%	31.92%	31.36%	30.82%	30.30%	29.80%	29.32%	28.85%	28.40%	27.97%	27.55%	27.15%	26.75%	26.38%	26.01%	25.65%	25.29%	24.94%	24.59%	24.25%	23.93%	23.61%	23.30%	23.00%	22.70%	22.41%	22.12%	21.85%	21.58%	21.32%	21.06%	20.81%	20.57%	20.34%	20.11%	19.88%	19.66%	19.45%	19.24%	19.03%	18.83%	18.63%	18.44%	18.25%	18.06%	17.88%	17.70%	17.52%	17.35%	17.18%	17.02%	16.85%	16.69%	16.53%	16.37%	16.22%	16.07%	15.92%	15.78%	15.63%	15.49%	15.36%	15.22%	15.09%	14.96%	14.83%	14.71%	14.59%	14.47%	14.35%	14.23%	14.12%	14.00%	13.89%	13.78%	13.68%	13.57%	13.47%	13.36%	13.26%	13.16%	13.07%	12.97%	12.87%	12.78%	12.69%	12.60%	12.51%	12.42%	12.33%	12.25%	12.17%	12.08%	12.00%	11.92%	11.84%	11.76%	11.69%	11.61%	11.53%	11.46%	11.39%	11.32%	11.24%	11.17%	11.10%	11.04%	10.97%	10.90%	10.84%	10.77%	10.71%	10.64%	10.58%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.56%	98.63%	95.24%	91.32%	86.88%	82.64%	78.94%	75.71%	72.78%	70.05%	67.52%	65.08%	62.77%	60.63%	58.63%	56.70%	54.85%	53.08%	51.45%	49.93%	48.51%	47.19%	45.95%	44.79%	43.69%	42.64%	41.62%	40.64%	39.71%	38.83%	37.99%	37.20%	36.44%	35.72%	35.03%	34.37%	33.72%	33.11%	32.52%	31.93%	31.37%	30.83%	30.31%	29.81%	29.33%	28.87%	28.42%	27.98%	27.56%	27.16%	26.77%	26.39%	26.02%	25.66%	25.31%	24.95%	24.60%	24.27%	23.94%	23.63%	23.31%	23.01%	22.71%	22.42%	22.14%	21.86%	21.59%	21.33%	21.07%	20.82%	20.58%	20.35%	20.12%	19.89%	19.67%	19.46%	19.25%	19.04%	18.84%	18.64%	18.45%	18.25%	18.07%	17.88%	17.70%	17.53%	17.36%	17.19%	17.02%	16.86%	16.69%	16.53%	16.38%	16.22%	16.07%	15.93%	15.78%	15.64%	15.50%	15.36%	15.23%	15.10%	14.97%	14.84%	14.71%	14.59%	14.47%	14.35%	14.23%	14.12%	14.01%	13.90%	13.79%	13.68%	13.57%	13.47%	13.37%	13.27%	13.17%	13.07%	12.97%	12.88%	12.78%	12.69%	12.60%	12.51%	12.43%	12.34%	12.25%	12.17%	12.09%	12.00%	11.92%	11.84%	11.77%	11.69%	11.61%	11.54%	11.46%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.91%	10.84%	10.77%	10.71%	10.65%	10.58%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.57%	98.64%	95.26%	91.34%	86.89%	82.65%	78.95%	75.71%	72.80%	70.06%	67.53%	65.09%	62.79%	60.65%	58.65%	56.72%	54.87%	53.10%	51.46%	49.94%	48.52%	47.20%	45.96%	44.80%	43.69%	42.65%	41.64%	40.65%	39.72%	38.84%	38.01%	37.21%	36.45%	35.73%	35.04%	34.38%	33.74%	33.12%	32.53%	31.95%	31.38%	30.85%	30.33%	29.82%	29.34%	28.88%	28.43%	28.00%	27.58%	27.17%	26.78%	26.40%	26.04%	25.68%	25.32%	24.96%	24.62%	24.28%	23.95%	23.64%	23.33%	23.02%	22.72%	22.43%	22.15%	21.87%	21.60%	21.34%	21.08%	20.84%	20.59%	20.36%	20.13%	19.90%	19.68%	19.47%	19.26%	19.05%	18.85%	18.65%	18.45%	18.26%	18.07%	17.89%	17.71%	17.54%	17.36%	17.20%	17.03%	16.86%	16.70%	16.54%	16.38%	16.23%	16.08%	15.93%	15.78%	15.64%	15.50%	15.37%	15.23%	15.10%	14.97%	14.84%	14.72%	14.59%	14.47%	14.36%	14.24%	14.12%	14.01%	13.90%	13.79%	13.68%	13.58%	13.47%	13.37%	13.27%	13.17%	13.07%	12.98%	12.88%	12.79%	12.70%	12.61%	12.52%	12.43%	12.34%	12.26%	12.17%	12.09%	12.01%	11.93%	11.85%	11.77%	11.69%	11.62%	11.54%	11.47%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.91%	10.84%	10.78%	10.71%	10.65%	10.59%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.58%	98.65%	95.29%	91.35%	86.91%	82.65%	78.95%	75.72%	72.81%	70.07%	67.55%	65.10%	62.81%	60.67%	58.68%	56.74%	54.88%	53.11%	51.47%	49.95%	48.54%	47.21%	45.97%	44.80%	43.70%	42.66%	41.65%	40.67%	39.74%	38.85%	38.02%	37.22%	36.46%	35.74%	35.06%	34.39%	33.75%	33.13%	32.54%	31.96%	31.40%	30.86%	30.34%	29.84%	29.36%	28.89%	28.44%	28.01%	27.59%	27.19%	26.79%	26.42%	26.05%	25.69%	25.33%	24.98%	24.63%	24.29%	23.97%	23.65%	23.34%	23.03%	22.74%	22.44%	22.16%	21.88%	21.61%	21.35%	21.10%	20.85%	20.60%	20.37%	20.14%	19.91%	19.69%	19.48%	19.27%	19.06%	18.86%	18.66%	18.46%	18.27%	18.08%	17.90%	17.72%	17.54%	17.37%	17.20%	17.03%	16.87%	16.70%	16.54%	16.39%	16.23%	16.08%	15.93%	15.79%	15.65%	15.51%	15.37%	15.24%	15.10%	14.97%	14.85%	14.72%	14.60%	14.48%	14.36%	14.24%	14.13%	14.02%	13.90%	13.80%	13.69%	13.58%	13.48%	13.38%	13.27%	13.18%	13.08%	12.98%	12.89%	12.79%	12.70%	12.61%	12.52%	12.43%	12.35%	12.26%	12.18%	12.09%	12.01%	11.93%	11.85%	11.77%	11.70%	11.62%	11.54%	11.47%	11.40%	11.32%	11.25%	11.18%	11.11%	11.04%	10.98%	10.91%	10.84%	10.78%	10.71%	10.65%	10.59%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.59%	98.66%	95.32%	91.37%	86.92%	82.66%	78.96%	75.73%	72.82%	70.09%	67.56%	65.12%	62.83%	60.69%	58.70%	56.76%	54.89%	53.12%	51.48%	49.96%	48.55%	47.22%	45.98%	44.81%	43.70%	42.66%	41.66%	40.68%	39.75%	38.87%	38.03%	37.23%	36.48%	35.76%	35.07%	34.40%	33.76%	33.14%	32.55%	31.97%	31.41%	30.87%	30.35%	29.85%	29.37%	28.90%	28.46%	28.02%	27.61%	27.20%	26.81%	26.43%	26.06%	25.70%	25.34%	24.99%	24.64%	24.31%	23.98%	23.66%	23.35%	23.04%	22.75%	22.46%	22.17%	21.89%	21.63%	21.36%	21.11%	20.86%	20.62%	20.38%	20.15%	19.92%	19.70%	19.49%	19.28%	19.07%	18.87%	18.67%	18.47%	18.28%	18.09%	17.91%	17.73%	17.55%	17.38%	17.21%	17.04%	16.87%	16.71%	16.55%	16.39%	16.24%	16.09%	15.94%	15.79%	15.65%	15.51%	15.38%	15.24%	15.11%	14.98%	14.85%	14.73%	14.60%	14.48%	14.36%	14.25%	14.13%	14.02%	13.91%	13.80%	13.69%	13.59%	13.48%	13.38%	13.28%	13.18%	13.08%	12.98%	12.89%	12.80%	12.70%	12.61%	12.52%	12.44%	12.35%	12.26%	12.18%	12.10%	12.02%	11.93%	11.85%	11.78%	11.70%	11.62%	11.55%	11.47%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.91%	10.85%	10.78%	10.72%	10.65%	10.59%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.60%	98.67%	95.34%	91.39%	86.94%	82.67%	78.97%	75.73%	72.83%	70.10%	67.58%	65.13%	62.85%	60.72%	58.73%	56.78%	54.90%	53.13%	51.49%	49.97%	48.56%	47.23%	45.99%	44.82%	43.71%	42.67%	41.67%	40.69%	39.76%	38.88%	38.04%	37.25%	36.49%	35.77%	35.08%	34.41%	33.77%	33.15%	32.56%	31.99%	31.43%	30.89%	30.37%	29.86%	29.38%	28.92%	28.47%	28.04%	27.62%	27.21%	26.82%	26.44%	26.08%	25.72%	25.36%	25.00%	24.66%	24.32%	23.99%	23.67%	23.36%	23.06%	22.76%	22.47%	22.18%	21.91%	21.64%	21.37%	21.12%	20.87%	20.63%	20.39%	20.16%	19.93%	19.72%	19.50%	19.29%	19.08%	18.87%	18.67%	18.48%	18.28%	18.10%	17.91%	17.73%	17.56%	17.38%	17.21%	17.04%	16.88%	16.71%	16.55%	16.40%	16.24%	16.09%	15.94%	15.80%	15.66%	15.52%	15.38%	15.25%	15.11%	14.98%	14.86%	14.73%	14.61%	14.49%	14.37%	14.25%	14.14%	14.02%	13.91%	13.80%	13.70%	13.59%	13.49%	13.38%	13.28%	13.18%	13.08%	12.99%	12.89%	12.80%	12.71%	12.62%	12.53%	12.44%	12.35%	12.27%	12.18%	12.10%	12.02%	11.94%	11.86%	11.78%	11.70%	11.63%	11.55%	11.48%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.92%	10.85%	10.78%	10.72%	10.66%	10.59%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.61%	98.68%	95.37%	91.40%	86.95%	82.67%	78.97%	75.74%	72.84%	70.11%	67.59%	65.15%	62.86%	60.74%	58.75%	56.80%	54.91%	53.14%	51.50%	49.98%	48.57%	47.24%	46.00%	44.82%	43.72%	42.68%	41.68%	40.71%	39.78%	38.89%	38.06%	37.26%	36.50%	35.78%	35.09%	34.42%	33.78%	33.16%	32.57%	32.00%	31.44%	30.90%	30.38%	29.88%	29.39%	28.93%	28.48%	28.05%	27.63%	27.23%	26.83%	26.46%	26.09%	25.73%	25.37%	25.02%	24.67%	24.33%	24.01%	23.69%	23.37%	23.07%	22.77%	22.48%	22.20%	21.92%	21.65%	21.39%	21.13%	20.88%	20.64%	20.40%	20.17%	19.95%	19.73%	19.51%	19.30%	19.09%	18.88%	18.68%	18.48%	18.29%	18.10%	17.92%	17.74%	17.56%	17.39%	17.22%	17.05%	16.88%	16.72%	16.56%	16.40%	16.25%	16.10%	15.95%	15.80%	15.66%	15.52%	15.38%	15.25%	15.12%	14.99%	14.86%	14.73%	14.61%	14.49%	14.37%	14.26%	14.14%	14.03%	13.92%	13.81%	13.70%	13.59%	13.49%	13.39%	13.29%	13.19%	13.09%	12.99%	12.90%	12.80%	12.71%	12.62%	12.53%	12.44%	12.36%	12.27%	12.19%	12.10%	12.02%	11.94%	11.86%	11.78%	11.70%	11.63%	11.55%	11.48%	11.41%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.92%	10.85%	10.79%	10.72%	10.66%	10.60%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.62%	98.69%	95.39%	91.42%	86.96%	82.68%	78.98%	75.75%	72.85%	70.12%	67.61%	65.16%	62.88%	60.76%	58.78%	56.82%	54.92%	53.15%	51.51%	49.99%	48.58%	47.25%	46.01%	44.83%	43.72%	42.68%	41.69%	40.72%	39.79%	38.91%	38.07%	37.27%	36.51%	35.79%	35.10%	34.43%	33.79%	33.17%	32.58%	32.01%	31.45%	30.91%	30.39%	29.89%	29.41%	28.94%	28.49%	28.06%	27.64%	27.24%	26.85%	26.47%	26.10%	25.74%	25.38%	25.03%	24.68%	24.35%	24.02%	23.70%	23.38%	23.08%	22.78%	22.49%	22.21%	21.93%	21.66%	21.40%	21.14%	20.89%	20.65%	20.41%	20.18%	19.96%	19.74%	19.52%	19.31%	19.10%	18.89%	18.69%	18.49%	18.30%	18.11%	17.93%	17.75%	17.57%	17.40%	17.22%	17.05%	16.89%	16.72%	16.56%	16.41%	16.25%	16.10%	15.95%	15.81%	15.67%	15.53%	15.39%	15.25%	15.12%	14.99%	14.86%	14.74%	14.62%	14.50%	14.38%	14.26%	14.15%	14.03%	13.92%	13.81%	13.70%	13.60%	13.49%	13.39%	13.29%	13.19%	13.09%	13.00%	12.90%	12.81%	12.72%	12.62%	12.54%	12.45%	12.36%	12.27%	12.19%	12.11%	12.02%	11.94%	11.86%	11.79%	11.71%	11.63%	11.56%	11.48%	11.41%	11.34%	11.26%	11.19%	11.12%	11.06%	10.99%	10.92%	10.85%	10.79%	10.72%	10.66%	10.60%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.64%	98.70%	95.41%	91.43%	86.97%	82.69%	78.99%	75.75%	72.86%	70.13%	67.63%	65.18%	62.90%	60.78%	58.80%	56.84%	54.94%	53.16%	51.52%	50.00%	48.59%	47.26%	46.01%	44.83%	43.73%	42.69%	41.70%	40.73%	39.80%	38.92%	38.08%	37.28%	36.52%	35.80%	35.11%	34.44%	33.80%	33.18%	32.59%	32.02%	31.47%	30.93%	30.41%	29.90%	29.42%	28.95%	28.51%	28.07%	27.66%	27.25%	26.86%	26.48%	26.11%	25.75%	25.39%	25.04%	24.70%	24.36%	24.03%	23.71%	23.40%	23.09%	22.79%	22.50%	22.22%	21.94%	21.67%	21.41%	21.15%	20.90%	20.66%	20.42%	20.19%	19.97%	19.75%	19.53%	19.32%	19.10%	18.90%	18.70%	18.50%	18.31%	18.12%	17.93%	17.75%	17.58%	17.40%	17.23%	17.06%	16.89%	16.73%	16.57%	16.41%	16.26%	16.11%	15.96%	15.81%	15.67%	15.53%	15.39%	15.26%	15.13%	15.00%	14.87%	14.74%	14.62%	14.50%	14.38%	14.26%	14.15%	14.04%	13.93%	13.82%	13.71%	13.60%	13.50%	13.40%	13.29%	13.19%	13.10%	13.00%	12.90%	12.81%	12.72%	12.63%	12.54%	12.45%	12.36%	12.28%	12.19%	12.11%	12.03%	11.95%	11.87%	11.79%	11.71%	11.63%	11.56%	11.48%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.92%	10.86%	10.79%	10.73%	10.66%	10.60%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.65%	98.71%	95.42%	91.45%	86.97%	82.70%	78.99%	75.76%	72.88%	70.14%	67.64%	65.19%	62.92%	60.80%	58.82%	56.86%	54.95%	53.17%	51.53%	50.01%	48.60%	47.27%	46.02%	44.84%	43.73%	42.69%	41.71%	40.75%	39.82%	38.93%	38.09%	37.30%	36.54%	35.81%	35.12%	34.46%	33.81%	33.19%	32.60%	32.04%	31.48%	30.94%	30.42%	29.92%	29.43%	28.97%	28.52%	28.09%	27.67%	27.27%	26.87%	26.49%	26.13%	25.77%	25.41%	25.06%	24.71%	24.37%	24.04%	23.72%	23.41%	23.10%	22.80%	22.51%	22.23%	21.95%	21.68%	21.42%	21.16%	20.92%	20.67%	20.43%	20.20%	19.98%	19.76%	19.54%	19.32%	19.11%	18.91%	18.70%	18.51%	18.32%	18.13%	17.94%	17.76%	17.58%	17.41%	17.23%	17.06%	16.90%	16.73%	16.57%	16.42%	16.26%	16.11%	15.96%	15.82%	15.68%	15.54%	15.40%	15.26%	15.13%	15.00%	14.87%	14.75%	14.62%	14.50%	14.39%	14.27%	14.15%	14.04%	13.93%	13.82%	13.71%	13.61%	13.50%	13.40%	13.30%	13.20%	13.10%	13.00%	12.91%	12.81%	12.72%	12.63%	12.54%	12.45%	12.37%	12.28%	12.20%	12.11%	12.03%	11.95%	11.87%	11.79%	11.71%	11.64%	11.56%	11.49%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.93%	10.86%	10.79%	10.73%	10.67%	10.60%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.66%	98.72%	95.44%	91.47%	86.98%	82.70%	79.00%	75.76%	72.89%	70.15%	67.66%	65.21%	62.94%	60.82%	58.84%	56.88%	54.96%	53.19%	51.55%	50.02%	48.61%	47.28%	46.03%	44.85%	43.74%	42.70%	41.71%	40.76%	39.83%	38.95%	38.11%	37.31%	36.55%	35.83%	35.14%	34.47%	33.82%	33.21%	32.61%	32.05%	31.49%	30.96%	30.43%	29.93%	29.45%	28.98%	28.53%	28.10%	27.68%	27.28%	26.89%	26.51%	26.14%	25.78%	25.42%	25.07%	24.73%	24.39%	24.06%	23.73%	23.42%	23.11%	22.81%	22.52%	22.24%	21.97%	21.70%	21.43%	21.18%	20.93%	20.68%	20.45%	20.21%	19.99%	19.76%	19.55%	19.33%	19.12%	18.91%	18.71%	18.52%	18.32%	18.13%	17.95%	17.77%	17.59%	17.41%	17.24%	17.07%	16.90%	16.74%	16.58%	16.42%	16.27%	16.12%	15.97%	15.82%	15.68%	15.54%	15.40%	15.27%	15.14%	15.01%	14.88%	14.75%	14.63%	14.51%	14.39%	14.27%	14.16%	14.04%	13.93%	13.82%	13.72%	13.61%	13.51%	13.40%	13.30%	13.20%	13.10%	13.01%	12.91%	12.82%	12.73%	12.63%	12.54%	12.46%	12.37%	12.28%	12.20%	12.12%	12.03%	11.95%	11.87%	11.79%	11.72%	11.64%	11.56%	11.49%	11.42%	11.34%	11.27%	11.20%	11.13%	11.06%	11.00%	10.93%	10.86%	10.80%	10.73%	10.67%	10.61%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.67%	98.73%	95.46%	91.48%	86.99%	82.71%	79.01%	75.77%	72.90%	70.16%	67.67%	65.22%	62.96%	60.83%	58.85%	56.89%	54.97%	53.20%	51.56%	50.03%	48.62%	47.29%	46.03%	44.85%	43.75%	42.70%	41.72%	40.77%	39.84%	38.96%	38.12%	37.32%	36.56%	35.84%	35.15%	34.48%	33.83%	33.22%	32.62%	32.06%	31.51%	30.97%	30.45%	29.94%	29.46%	28.99%	28.54%	28.11%	27.69%	27.29%	26.90%	26.52%	26.15%	25.79%	25.43%	25.08%	24.74%	24.40%	24.07%	23.74%	23.43%	23.12%	22.82%	22.53%	22.25%	21.98%	21.71%	21.44%	21.19%	20.94%	20.69%	20.46%	20.22%	20.00%	19.77%	19.56%	19.34%	19.13%	18.92%	18.72%	18.52%	18.33%	18.14%	17.95%	17.77%	17.59%	17.42%	17.24%	17.07%	16.91%	16.74%	16.58%	16.43%	16.27%	16.12%	15.97%	15.83%	15.68%	15.54%	15.41%	15.27%	15.14%	15.01%	14.88%	14.76%	14.63%	14.51%	14.39%	14.28%	14.16%	14.05%	13.94%	13.83%	13.72%	13.61%	13.51%	13.41%	13.31%	13.21%	13.11%	13.01%	12.91%	12.82%	12.73%	12.64%	12.55%	12.46%	12.37%	12.29%	12.20%	12.12%	12.04%	11.95%	11.87%	11.80%	11.72%	11.64%	11.57%	11.49%	11.42%	11.35%	11.27%	11.20%	11.13%	11.07%	11.00%	10.93%	10.86%	10.80%	10.73%	10.67%	10.61%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.68%	98.74%	95.48%	91.50%	87.00%	82.72%	79.01%	75.78%	72.91%	70.17%	67.69%	65.24%	62.98%	60.85%	58.87%	56.91%	54.98%	53.21%	51.57%	50.04%	48.63%	47.30%	46.04%	44.86%	43.75%	42.71%	41.73%	40.78%	39.86%	38.97%	38.13%	37.33%	36.57%	35.85%	35.16%	34.49%	33.84%	33.23%	32.63%	32.07%	31.52%	30.98%	30.46%	29.96%	29.47%	29.01%	28.56%	28.12%	27.70%	27.30%	26.91%	26.53%	26.17%	25.80%	25.44%	25.09%	24.75%	24.41%	24.08%	23.76%	23.44%	23.13%	22.84%	22.55%	22.26%	21.99%	21.72%	21.46%	21.20%	20.95%	20.70%	20.47%	20.23%	20.01%	19.78%	19.56%	19.35%	19.14%	18.93%	18.73%	18.53%	18.34%	18.15%	17.96%	17.78%	17.60%	17.42%	17.25%	17.08%	16.91%	16.75%	16.59%	16.43%	16.28%	16.13%	15.98%	15.83%	15.69%	15.55%	15.41%	15.28%	15.14%	15.01%	14.89%	14.76%	14.64%	14.52%	14.40%	14.28%	14.17%	14.05%	13.94%	13.83%	13.72%	13.62%	13.51%	13.41%	13.31%	13.21%	13.11%	13.01%	12.92%	12.82%	12.73%	12.64%	12.55%	12.46%	12.37%	12.29%	12.20%	12.12%	12.04%	11.96%	11.88%	11.80%	11.72%	11.64%	11.57%	11.49%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.93%	10.87%	10.80%	10.74%	10.67%	10.61%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.69%	98.75%	95.50%	91.52%	87.00%	82.72%	79.02%	75.78%	72.92%	70.18%	67.70%	65.25%	63.00%	60.87%	58.89%	56.92%	54.99%	53.22%	51.58%	50.05%	48.64%	47.30%	46.05%	44.87%	43.76%	42.72%	41.73%	40.79%	39.87%	38.98%	38.14%	37.35%	36.58%	35.86%	35.17%	34.50%	33.86%	33.24%	32.64%	32.08%	31.53%	30.99%	30.47%	29.97%	29.48%	29.02%	28.57%	28.13%	27.72%	27.31%	26.92%	26.55%	26.18%	25.82%	25.46%	25.11%	24.76%	24.42%	24.09%	23.77%	23.45%	23.15%	22.85%	22.56%	22.27%	22.00%	21.73%	21.47%	21.21%	20.96%	20.71%	20.48%	20.24%	20.02%	19.79%	19.57%	19.36%	19.14%	18.94%	18.73%	18.54%	18.34%	18.15%	17.97%	17.78%	17.60%	17.43%	17.26%	17.08%	16.92%	16.75%	16.59%	16.44%	16.28%	16.13%	15.98%	15.84%	15.69%	15.55%	15.42%	15.28%	15.15%	15.02%	14.89%	14.77%	14.64%	14.52%	14.40%	14.29%	14.17%	14.06%	13.95%	13.84%	13.73%	13.62%	13.52%	13.41%	13.31%	13.21%	13.11%	13.02%	12.92%	12.83%	12.73%	12.64%	12.55%	12.46%	12.38%	12.29%	12.21%	12.12%	12.04%	11.96%	11.88%	11.80%	11.72%	11.65%	11.57%	11.50%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.94%	10.87%	10.80%	10.74%	10.68%	10.61%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.70%	98.76%	95.51%	91.53%	87.01%	82.73%	79.03%	75.79%	72.93%	70.19%	67.72%	65.27%	63.01%	60.89%	58.91%	56.93%	55.01%	53.23%	51.59%	50.06%	48.65%	47.31%	46.05%	44.87%	43.76%	42.72%	41.74%	40.80%	39.88%	39.00%	38.16%	37.36%	36.60%	35.87%	35.18%	34.51%	33.87%	33.25%	32.66%	32.09%	31.54%	31.01%	30.49%	29.98%	29.50%	29.03%	28.58%	28.15%	27.73%	27.32%	26.93%	26.56%	26.19%	25.83%	25.47%	25.12%	24.78%	24.44%	24.10%	23.78%	23.46%	23.16%	22.86%	22.57%	22.28%	22.01%	21.74%	21.48%	21.22%	20.97%	20.72%	20.49%	20.25%	20.02%	19.80%	19.58%	19.36%	19.15%	18.94%	18.74%	18.54%	18.35%	18.16%	17.97%	17.79%	17.61%	17.43%	17.26%	17.09%	16.92%	16.76%	16.60%	16.44%	16.29%	16.14%	15.99%	15.84%	15.70%	15.56%	15.42%	15.29%	15.15%	15.02%	14.90%	14.77%	14.65%	14.53%	14.41%	14.29%	14.17%	14.06%	13.95%	13.84%	13.73%	13.63%	13.52%	13.42%	13.31%	13.21%	13.12%	13.02%	12.92%	12.83%	12.74%	12.65%	12.56%	12.47%	12.38%	12.29%	12.21%	12.13%	12.04%	11.96%	11.88%	11.80%	11.73%	11.65%	11.57%	11.50%	11.43%	11.35%	11.28%	11.21%	11.14%	11.07%	11.01%	10.94%	10.87%	10.81%	10.74%	10.68%	10.62%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.71%	98.77%	95.52%	91.55%	87.02%	82.74%	79.03%	75.79%	72.94%	70.21%	67.73%	65.28%	63.03%	60.91%	58.92%	56.94%	55.02%	53.24%	51.60%	50.08%	48.66%	47.32%	46.06%	44.88%	43.77%	42.73%	41.75%	40.81%	39.89%	39.01%	38.17%	37.37%	36.61%	35.88%	35.19%	34.52%	33.88%	33.26%	32.67%	32.10%	31.55%	31.02%	30.50%	30.00%	29.51%	29.04%	28.59%	28.16%	27.74%	27.34%	26.95%	26.57%	26.20%	25.84%	25.48%	25.13%	24.79%	24.45%	24.11%	23.79%	23.48%	23.17%	22.87%	22.58%	22.30%	22.02%	21.75%	21.49%	21.23%	20.98%	20.73%	20.50%	20.26%	20.03%	19.81%	19.59%	19.37%	19.16%	18.95%	18.75%	18.55%	18.35%	18.16%	17.98%	17.80%	17.62%	17.44%	17.27%	17.10%	16.93%	16.76%	16.60%	16.45%	16.29%	16.14%	15.99%	15.85%	15.70%	15.56%	15.43%	15.29%	15.16%	15.03%	14.90%	14.77%	14.65%	14.53%	14.41%	14.29%	14.18%	14.07%	13.95%	13.84%	13.74%	13.63%	13.52%	13.42%	13.32%	13.22%	13.12%	13.02%	12.93%	12.83%	12.74%	12.65%	12.56%	12.47%	12.38%	12.30%	12.21%	12.13%	12.05%	11.97%	11.89%	11.81%	11.73%	11.65%	11.58%	11.50%	11.43%	11.36%	11.28%	11.21%	11.14%	11.08%	11.01%	10.94%	10.87%	10.81%	10.74%	10.68%	10.62%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.72%	98.78%	95.53%	91.57%	87.03%	82.74%	79.04%	75.80%	72.95%	70.22%	67.75%	65.30%	63.04%	60.92%	58.94%	56.96%	55.03%	53.25%	51.61%	50.09%	48.66%	47.32%	46.06%	44.88%	43.78%	42.73%	41.75%	40.81%	39.90%	39.02%	38.18%	37.38%	36.62%	35.90%	35.20%	34.53%	33.89%	33.27%	32.68%	32.11%	31.56%	31.03%	30.51%	30.01%	29.52%	29.06%	28.61%	28.17%	27.75%	27.35%	26.96%	26.58%	26.22%	25.85%	25.49%	25.14%	24.80%	24.46%	24.13%	23.80%	23.49%	23.18%	22.88%	22.59%	22.31%	22.03%	21.76%	21.50%	21.24%	20.99%	20.74%	20.51%	20.27%	20.04%	19.82%	19.60%	19.38%	19.17%	18.96%	18.75%	18.56%	18.36%	18.17%	17.99%	17.80%	17.62%	17.44%	17.27%	17.10%	16.93%	16.77%	16.61%	16.45%	16.30%	16.14%	16.00%	15.85%	15.71%	15.57%	15.43%	15.29%	15.16%	15.03%	14.90%	14.78%	14.66%	14.53%	14.41%	14.30%	14.18%	14.07%	13.96%	13.85%	13.74%	13.63%	13.53%	13.42%	13.32%	13.22%	13.12%	13.03%	12.93%	12.84%	12.74%	12.65%	12.56%	12.47%	12.39%	12.30%	12.22%	12.13%	12.05%	11.97%	11.89%	11.81%	11.73%	11.66%	11.58%	11.51%	11.43%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.94%	10.88%	10.81%	10.75%	10.68%	10.62%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.73%	98.79%	95.53%	91.58%	87.03%	82.75%	79.05%	75.81%	72.95%	70.23%	67.76%	65.31%	63.05%	60.94%	58.96%	56.97%	55.04%	53.27%	51.62%	50.10%	48.67%	47.33%	46.07%	44.89%	43.78%	42.74%	41.76%	40.82%	39.91%	39.04%	38.20%	37.39%	36.63%	35.91%	35.22%	34.54%	33.90%	33.28%	32.69%	32.12%	31.57%	31.04%	30.53%	30.02%	29.54%	29.07%	28.62%	28.18%	27.76%	27.36%	26.97%	26.59%	26.23%	25.87%	25.51%	25.15%	24.81%	24.47%	24.14%	23.81%	23.50%	23.19%	22.89%	22.60%	22.32%	22.04%	21.77%	21.51%	21.25%	21.00%	20.75%	20.51%	20.28%	20.05%	19.83%	19.60%	19.39%	19.17%	18.96%	18.76%	18.56%	18.37%	18.18%	17.99%	17.81%	17.63%	17.45%	17.28%	17.11%	16.94%	16.77%	16.61%	16.46%	16.30%	16.15%	16.00%	15.86%	15.71%	15.57%	15.43%	15.30%	15.17%	15.04%	14.91%	14.78%	14.66%	14.54%	14.42%	14.30%	14.19%	14.07%	13.96%	13.85%	13.74%	13.63%	13.53%	13.43%	13.32%	13.22%	13.13%	13.03%	12.93%	12.84%	12.75%	12.65%	12.56%	12.48%	12.39%	12.30%	12.22%	12.13%	12.05%	11.97%	11.89%	11.81%	11.73%	11.66%	11.58%	11.51%	11.43%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.95%	10.88%	10.81%	10.75%	10.69%	10.62%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.74%	98.80%	95.54%	91.60%	87.04%	82.76%	79.05%	75.81%	72.96%	70.24%	67.78%	65.33%	63.07%	60.96%	58.97%	56.98%	55.05%	53.28%	51.63%	50.11%	48.68%	47.34%	46.08%	44.90%	43.79%	42.74%	41.76%	40.83%	39.92%	39.05%	38.21%	37.41%	36.64%	35.92%	35.23%	34.56%	33.91%	33.29%	32.70%	32.13%	31.58%	31.05%	30.54%	30.04%	29.55%	29.08%	28.63%	28.20%	27.78%	27.37%	26.98%	26.60%	26.24%	25.88%	25.52%	25.17%	24.83%	24.48%	24.15%	23.83%	23.51%	23.20%	22.90%	22.61%	22.33%	22.05%	21.78%	21.52%	21.26%	21.01%	20.76%	20.52%	20.29%	20.06%	19.83%	19.61%	19.39%	19.18%	18.97%	18.77%	18.57%	18.37%	18.18%	18.00%	17.81%	17.63%	17.45%	17.28%	17.11%	16.94%	16.78%	16.62%	16.46%	16.31%	16.15%	16.01%	15.86%	15.72%	15.58%	15.44%	15.30%	15.17%	15.04%	14.91%	14.79%	14.66%	14.54%	14.42%	14.31%	14.19%	14.08%	13.96%	13.85%	13.74%	13.64%	13.53%	13.43%	13.33%	13.23%	13.13%	13.03%	12.94%	12.84%	12.75%	12.66%	12.57%	12.48%	12.39%	12.31%	12.22%	12.14%	12.06%	11.97%	11.89%	11.82%	11.74%	11.66%	11.59%	11.51%	11.44%	11.36%	11.29%	11.22%	11.15%	11.08%	11.02%	10.95%	10.88%	10.82%	10.75%	10.69%	10.63%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.76%	98.81%	95.55%	91.62%	87.05%	82.77%	79.06%	75.82%	72.96%	70.25%	67.80%	65.34%	63.08%	60.98%	58.99%	56.99%	55.07%	53.29%	51.64%	50.12%	48.68%	47.34%	46.08%	44.90%	43.79%	42.75%	41.77%	40.84%	39.93%	39.06%	38.22%	37.42%	36.66%	35.93%	35.24%	34.57%	33.92%	33.30%	32.71%	32.14%	31.59%	31.07%	30.55%	30.05%	29.56%	29.09%	28.64%	28.21%	27.79%	27.38%	26.99%	26.61%	26.25%	25.89%	25.53%	25.18%	24.84%	24.50%	24.16%	23.84%	23.52%	23.21%	22.91%	22.62%	22.34%	22.06%	21.79%	21.53%	21.27%	21.02%	20.77%	20.53%	20.30%	20.07%	19.84%	19.62%	19.40%	19.19%	18.98%	18.77%	18.58%	18.38%	18.19%	18.00%	17.82%	17.64%	17.46%	17.29%	17.12%	16.95%	16.78%	16.62%	16.47%	16.31%	16.16%	16.01%	15.86%	15.72%	15.58%	15.44%	15.31%	15.18%	15.05%	14.92%	14.79%	14.67%	14.55%	14.43%	14.31%	14.19%	14.08%	13.97%	13.86%	13.75%	13.64%	13.54%	13.43%	13.33%	13.23%	13.13%	13.03%	12.94%	12.84%	12.75%	12.66%	12.57%	12.48%	12.39%	12.31%	12.22%	12.14%	12.06%	11.98%	11.90%	11.82%	11.74%	11.66%	11.59%	11.51%	11.44%	11.37%	11.30%	11.22%	11.15%	11.09%	11.02%	10.95%	10.88%	10.82%	10.75%	10.69%	10.63%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.77%	98.82%	95.56%	91.63%	87.06%	82.77%	79.07%	75.83%	72.97%	70.26%	67.81%	65.36%	63.10%	61.00%	59.00%	57.01%	55.08%	53.30%	51.65%	50.12%	48.69%	47.35%	46.09%	44.91%	43.80%	42.76%	41.77%	40.85%	39.94%	39.07%	38.23%	37.43%	36.67%	35.94%	35.25%	34.58%	33.93%	33.31%	32.72%	32.15%	31.60%	31.08%	30.56%	30.06%	29.58%	29.11%	28.66%	28.22%	27.80%	27.40%	27.00%	26.63%	26.26%	25.90%	25.54%	25.19%	24.85%	24.51%	24.17%	23.85%	23.53%	23.22%	22.92%	22.63%	22.35%	22.07%	21.80%	21.54%	21.28%	21.03%	20.78%	20.54%	20.31%	20.07%	19.85%	19.62%	19.41%	19.19%	18.98%	18.78%	18.58%	18.39%	18.20%	18.01%	17.82%	17.64%	17.46%	17.29%	17.12%	16.95%	16.79%	16.63%	16.47%	16.32%	16.16%	16.02%	15.87%	15.73%	15.59%	15.45%	15.31%	15.18%	15.05%	14.92%	14.80%	14.67%	14.55%	14.43%	14.31%	14.20%	14.08%	13.97%	13.86%	13.75%	13.64%	13.54%	13.44%	13.33%	13.23%	13.13%	13.04%	12.94%	12.85%	12.76%	12.66%	12.57%	12.49%	12.40%	12.31%	12.23%	12.14%	12.06%	11.98%	11.90%	11.82%	11.74%	11.67%	11.59%	11.52%	11.44%	11.37%	11.30%	11.23%	11.16%	11.09%	11.02%	10.95%	10.89%	10.82%	10.76%	10.69%	10.63%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.78%	98.83%	95.57%	91.65%	87.06%	82.78%	79.07%	75.83%	72.98%	70.27%	67.82%	65.37%	63.11%	61.01%	59.01%	57.02%	55.09%	53.31%	51.67%	50.13%	48.70%	47.36%	46.10%	44.91%	43.81%	42.76%	41.78%	40.85%	39.96%	39.08%	38.25%	37.44%	36.68%	35.95%	35.26%	34.59%	33.94%	33.32%	32.73%	32.16%	31.61%	31.09%	30.57%	30.07%	29.59%	29.12%	28.67%	28.23%	27.81%	27.41%	27.02%	26.64%	26.27%	25.91%	25.55%	25.20%	24.86%	24.52%	24.18%	23.86%	23.54%	23.24%	22.93%	22.64%	22.36%	22.08%	21.81%	21.55%	21.29%	21.04%	20.79%	20.55%	20.31%	20.08%	19.86%	19.63%	19.41%	19.20%	18.99%	18.79%	18.59%	18.39%	18.20%	18.01%	17.83%	17.65%	17.47%	17.30%	17.13%	16.96%	16.79%	16.63%	16.47%	16.32%	16.17%	16.02%	15.87%	15.73%	15.59%	15.45%	15.32%	15.18%	15.05%	14.93%	14.80%	14.68%	14.55%	14.43%	14.32%	14.20%	14.09%	13.97%	13.86%	13.75%	13.65%	13.54%	13.44%	13.34%	13.24%	13.14%	13.04%	12.94%	12.85%	12.76%	12.67%	12.58%	12.49%	12.40%	12.31%	12.23%	12.15%	12.06%	11.98%	11.90%	11.82%	11.75%	11.67%	11.59%	11.52%	11.44%	11.37%	11.30%	11.23%	11.16%	11.09%	11.02%	10.96%	10.89%	10.82%	10.76%	10.70%	10.63%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.79%	98.84%	95.58%	91.66%	87.07%	82.79%	79.08%	75.84%	72.98%	70.28%	67.83%	65.38%	63.12%	61.03%	59.03%	57.03%	55.10%	53.32%	51.68%	50.14%	48.70%	47.36%	46.10%	44.92%	43.81%	42.77%	41.78%	40.86%	39.96%	39.09%	38.26%	37.46%	36.69%	35.97%	35.27%	34.60%	33.95%	33.33%	32.74%	32.17%	31.62%	31.10%	30.58%	30.08%	29.60%	29.13%	28.68%	28.25%	27.83%	27.42%	27.03%	26.65%	26.28%	25.92%	25.56%	25.21%	24.87%	24.53%	24.20%	23.87%	23.56%	23.25%	22.94%	22.65%	22.36%	22.09%	21.82%	21.55%	21.30%	21.05%	20.80%	20.56%	20.32%	20.09%	19.86%	19.64%	19.42%	19.21%	19.00%	18.79%	18.60%	18.40%	18.21%	18.02%	17.83%	17.65%	17.47%	17.30%	17.13%	16.96%	16.80%	16.64%	16.48%	16.32%	16.17%	16.02%	15.88%	15.74%	15.59%	15.46%	15.32%	15.19%	15.06%	14.93%	14.80%	14.68%	14.56%	14.44%	14.32%	14.20%	14.09%	13.98%	13.87%	13.76%	13.65%	13.55%	13.44%	13.34%	13.24%	13.14%	13.04%	12.95%	12.85%	12.76%	12.67%	12.58%	12.49%	12.40%	12.32%	12.23%	12.15%	12.07%	11.99%	11.91%	11.83%	11.75%	11.67%	11.60%	11.52%	11.45%	11.37%	11.30%	11.23%	11.16%	11.09%	11.03%	10.96%	10.89%	10.83%	10.76%	10.70%	10.64%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.80%	98.85%	95.59%	91.68%	87.08%	82.79%	79.08%	75.84%	72.99%	70.29%	67.84%	65.40%	63.14%	61.05%	59.04%	57.04%	55.11%	53.33%	51.69%	50.15%	48.71%	47.37%	46.11%	44.93%	43.82%	42.77%	41.79%	40.86%	39.97%	39.10%	38.27%	37.47%	36.70%	35.98%	35.28%	34.61%	33.97%	33.34%	32.75%	32.18%	31.63%	31.11%	30.59%	30.10%	29.61%	29.15%	28.69%	28.26%	27.84%	27.43%	27.04%	26.66%	26.29%	25.93%	25.58%	25.23%	24.89%	24.54%	24.21%	23.88%	23.57%	23.26%	22.95%	22.66%	22.37%	22.10%	21.83%	21.56%	21.31%	21.06%	20.81%	20.57%	20.33%	20.10%	19.87%	19.65%	19.43%	19.21%	19.00%	18.80%	18.60%	18.41%	18.21%	18.02%	17.84%	17.66%	17.48%	17.31%	17.14%	16.97%	16.80%	16.64%	16.48%	16.33%	16.18%	16.03%	15.88%	15.74%	15.60%	15.46%	15.33%	15.19%	15.06%	14.93%	14.81%	14.68%	14.56%	14.44%	14.32%	14.21%	14.09%	13.98%	13.87%	13.76%	13.65%	13.55%	13.44%	13.34%	13.24%	13.14%	13.05%	12.95%	12.86%	12.76%	12.67%	12.58%	12.49%	12.41%	12.32%	12.24%	12.15%	12.07%	11.99%	11.91%	11.83%	11.75%	11.67%	11.60%	11.52%	11.45%	11.38%	11.31%	11.23%	11.17%	11.10%	11.03%	10.96%	10.89%	10.83%	10.76%	10.70%	10.64%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.81%	98.86%	95.60%	91.70%	87.09%	82.80%	79.09%	75.85%	72.99%	70.30%	67.85%	65.41%	63.15%	61.06%	59.05%	57.05%	55.12%	53.34%	51.70%	50.15%	48.72%	47.38%	46.12%	44.93%	43.82%	42.78%	41.80%	40.87%	39.98%	39.11%	38.28%	37.48%	36.72%	35.99%	35.30%	34.62%	33.98%	33.35%	32.76%	32.19%	31.64%	31.12%	30.61%	30.11%	29.63%	29.16%	28.71%	28.27%	27.85%	27.44%	27.05%	26.67%	26.31%	25.94%	25.59%	25.24%	24.90%	24.56%	24.22%	23.89%	23.58%	23.27%	22.96%	22.67%	22.38%	22.11%	21.84%	21.57%	21.32%	21.06%	20.82%	20.58%	20.34%	20.10%	19.88%	19.65%	19.43%	19.22%	19.01%	18.81%	18.61%	18.41%	18.22%	18.03%	17.84%	17.66%	17.48%	17.31%	17.14%	16.97%	16.81%	16.65%	16.49%	16.33%	16.18%	16.03%	15.89%	15.74%	15.60%	15.47%	15.33%	15.20%	15.07%	14.94%	14.81%	14.69%	14.56%	14.44%	14.33%	14.21%	14.10%	13.98%	13.87%	13.76%	13.66%	13.55%	13.45%	13.35%	13.25%	13.15%	13.05%	12.95%	12.86%	12.77%	12.68%	12.59%	12.50%	12.41%	12.32%	12.24%	12.15%	12.07%	11.99%	11.91%	11.83%	11.75%	11.68%	11.60%	11.53%	11.45%	11.38%	11.31%	11.24%	11.17%	11.10%	11.03%	10.96%	10.90%	10.83%	10.77%	10.70%	10.64%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.82%	98.87%	95.61%	91.71%	87.09%	82.81%	79.10%	75.86%	73.00%	70.31%	67.86%	65.43%	63.17%	61.08%	59.06%	57.07%	55.14%	53.36%	51.70%	50.16%	48.72%	47.38%	46.12%	44.94%	43.83%	42.79%	41.80%	40.87%	39.99%	39.12%	38.29%	37.49%	36.73%	36.00%	35.31%	34.63%	33.99%	33.37%	32.77%	32.20%	31.65%	31.13%	30.62%	30.12%	29.64%	29.17%	28.72%	28.28%	27.86%	27.46%	27.06%	26.68%	26.32%	25.96%	25.60%	25.25%	24.91%	24.57%	24.23%	23.91%	23.59%	23.27%	22.97%	22.68%	22.39%	22.12%	21.84%	21.58%	21.32%	21.07%	20.83%	20.58%	20.34%	20.11%	19.88%	19.66%	19.44%	19.23%	19.02%	18.81%	18.61%	18.42%	18.22%	18.03%	17.85%	17.67%	17.49%	17.32%	17.15%	16.98%	16.81%	16.65%	16.49%	16.34%	16.19%	16.04%	15.89%	15.75%	15.61%	15.47%	15.33%	15.20%	15.07%	14.94%	14.81%	14.69%	14.57%	14.45%	14.33%	14.21%	14.10%	13.99%	13.88%	13.77%	13.66%	13.55%	13.45%	13.35%	13.25%	13.15%	13.05%	12.96%	12.86%	12.77%	12.68%	12.59%	12.50%	12.41%	12.33%	12.24%	12.16%	12.08%	11.99%	11.91%	11.83%	11.76%	11.68%	11.60%	11.53%	11.46%	11.38%	11.31%	11.24%	11.17%	11.10%	11.03%	10.97%	10.90%	10.83%	10.77%	10.71%	10.64%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.83%	98.88%	95.61%	91.73%	87.10%	82.81%	79.10%	75.86%	73.00%	70.33%	67.87%	65.44%	63.18%	61.09%	59.07%	57.08%	55.15%	53.37%	51.71%	50.17%	48.73%	47.39%	46.13%	44.95%	43.84%	42.79%	41.81%	40.88%	40.00%	39.13%	38.30%	37.50%	36.74%	36.01%	35.32%	34.65%	34.00%	33.38%	32.78%	32.21%	31.66%	31.14%	30.63%	30.13%	29.65%	29.18%	28.73%	28.29%	27.87%	27.47%	27.07%	26.69%	26.33%	25.97%	25.61%	25.26%	24.92%	24.58%	24.24%	23.92%	23.60%	23.28%	22.98%	22.69%	22.40%	22.12%	21.85%	21.59%	21.33%	21.08%	20.83%	20.59%	20.35%	20.12%	19.89%	19.67%	19.45%	19.23%	19.03%	18.82%	18.62%	18.42%	18.23%	18.04%	17.85%	17.67%	17.50%	17.32%	17.15%	16.98%	16.82%	16.66%	16.50%	16.34%	16.19%	16.04%	15.90%	15.75%	15.61%	15.47%	15.34%	15.20%	15.07%	14.94%	14.82%	14.69%	14.57%	14.45%	14.33%	14.22%	14.10%	13.99%	13.88%	13.77%	13.66%	13.56%	13.45%	13.35%	13.25%	13.15%	13.06%	12.96%	12.87%	12.77%	12.68%	12.59%	12.50%	12.42%	12.33%	12.24%	12.16%	12.08%	12.00%	11.92%	11.84%	11.76%	11.68%	11.61%	11.53%	11.46%	11.39%	11.31%	11.24%	11.17%	11.10%	11.04%	10.97%	10.90%	10.84%	10.77%	10.71%	10.65%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.84%	98.89%	95.62%	91.75%	87.11%	82.82%	79.11%	75.87%	73.01%	70.34%	67.88%	65.46%	63.19%	61.10%	59.07%	57.09%	55.16%	53.38%	51.72%	50.17%	48.74%	47.40%	46.13%	44.95%	43.84%	42.80%	41.81%	40.88%	40.00%	39.14%	38.31%	37.52%	36.75%	36.02%	35.33%	34.66%	34.01%	33.39%	32.79%	32.22%	31.67%	31.15%	30.64%	30.14%	29.66%	29.20%	28.74%	28.31%	27.89%	27.48%	27.09%	26.71%	26.34%	25.98%	25.62%	25.27%	24.93%	24.59%	24.26%	23.93%	23.61%	23.29%	22.99%	22.70%	22.41%	22.13%	21.86%	21.60%	21.34%	21.09%	20.84%	20.60%	20.36%	20.13%	19.90%	19.67%	19.45%	19.24%	19.03%	18.83%	18.63%	18.43%	18.23%	18.04%	17.86%	17.68%	17.50%	17.33%	17.16%	16.99%	16.82%	16.66%	16.50%	16.35%	16.20%	16.05%	15.90%	15.76%	15.62%	15.48%	15.34%	15.21%	15.08%	14.95%	14.82%	14.70%	14.57%	14.45%	14.34%	14.22%	14.11%	13.99%	13.88%	13.77%	13.67%	13.56%	13.46%	13.36%	13.26%	13.16%	13.06%	12.96%	12.87%	12.78%	12.68%	12.59%	12.51%	12.42%	12.33%	12.25%	12.16%	12.08%	12.00%	11.92%	11.84%	11.76%	11.69%	11.61%	11.53%	11.46%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.90%	10.84%	10.77%	10.71%	10.65%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.85%	98.90%	95.63%	91.75%	87.12%	82.83%	79.12%	75.87%	73.02%	70.35%	67.89%	65.47%	63.21%	61.12%	59.07%	57.10%	55.17%	53.39%	51.72%	50.18%	48.75%	47.40%	46.14%	44.96%	43.85%	42.80%	41.82%	40.89%	40.01%	39.15%	38.32%	37.53%	36.76%	36.04%	35.34%	34.67%	34.02%	33.40%	32.80%	32.23%	31.68%	31.16%	30.65%	30.15%	29.67%	29.21%	28.76%	28.32%	27.90%	27.49%	27.10%	26.72%	26.35%	25.99%	25.63%	25.28%	24.94%	24.60%	24.27%	23.94%	23.62%	23.30%	23.00%	22.71%	22.42%	22.14%	21.87%	21.60%	21.35%	21.09%	20.84%	20.60%	20.37%	20.13%	19.90%	19.68%	19.46%	19.25%	19.04%	18.83%	18.63%	18.43%	18.24%	18.05%	17.86%	17.68%	17.51%	17.33%	17.16%	16.99%	16.83%	16.67%	16.51%	16.35%	16.20%	16.05%	15.91%	15.76%	15.62%	15.48%	15.34%	15.21%	15.08%	14.95%	14.82%	14.70%	14.58%	14.46%	14.34%	14.22%	14.11%	14.00%	13.89%	13.78%	13.67%	13.56%	13.46%	13.36%	13.26%	13.16%	13.06%	12.97%	12.87%	12.78%	12.69%	12.60%	12.51%	12.42%	12.33%	12.25%	12.17%	12.08%	12.00%	11.92%	11.84%	11.76%	11.69%	11.61%	11.54%	11.46%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.97%	10.91%	10.84%	10.78%	10.71%	10.65%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.86%	98.91%	95.64%	91.76%	87.12%	82.84%	79.12%	75.88%	73.02%	70.36%	67.90%	65.49%	63.22%	61.13%	59.08%	57.10%	55.18%	53.40%	51.73%	50.19%	48.75%	47.41%	46.15%	44.96%	43.85%	42.81%	41.82%	40.89%	40.02%	39.16%	38.33%	37.54%	36.78%	36.05%	35.35%	34.68%	34.03%	33.41%	32.81%	32.24%	31.69%	31.17%	30.66%	30.16%	29.68%	29.22%	28.77%	28.33%	27.91%	27.50%	27.11%	26.73%	26.36%	26.00%	25.64%	25.29%	24.95%	24.61%	24.28%	23.95%	23.63%	23.31%	23.01%	22.72%	22.43%	22.15%	21.88%	21.61%	21.35%	21.10%	20.85%	20.61%	20.37%	20.14%	19.91%	19.69%	19.47%	19.25%	19.05%	18.84%	18.64%	18.44%	18.24%	18.06%	17.87%	17.69%	17.51%	17.34%	17.17%	17.00%	16.83%	16.67%	16.51%	16.36%	16.21%	16.06%	15.91%	15.77%	15.62%	15.48%	15.35%	15.21%	15.08%	14.95%	14.83%	14.70%	14.58%	14.46%	14.34%	14.23%	14.11%	14.00%	13.89%	13.78%	13.67%	13.57%	13.46%	13.36%	13.26%	13.16%	13.06%	12.97%	12.87%	12.78%	12.69%	12.60%	12.51%	12.42%	12.34%	12.25%	12.17%	12.09%	12.00%	11.92%	11.85%	11.77%	11.69%	11.61%	11.54%	11.47%	11.39%	11.32%	11.25%	11.18%	11.11%	11.04%	10.98%	10.91%	10.84%	10.78%	10.72%	10.65%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.88%	98.92%	95.65%	91.77%	87.13%	82.84%	79.13%	75.89%	73.03%	70.37%	67.91%	65.50%	63.24%	61.14%	59.08%	57.11%	55.20%	53.40%	51.74%	50.20%	48.76%	47.42%	46.15%	44.97%	43.86%	42.81%	41.83%	40.90%	40.02%	39.17%	38.34%	37.55%	36.79%	36.06%	35.36%	34.69%	34.04%	33.42%	32.82%	32.25%	31.70%	31.18%	30.67%	30.17%	29.69%	29.23%	28.78%	28.34%	27.92%	27.51%	27.12%	26.74%	26.37%	26.01%	25.65%	25.30%	24.96%	24.62%	24.28%	23.96%	23.64%	23.32%	23.02%	22.73%	22.44%	22.16%	21.89%	21.62%	21.36%	21.10%	20.86%	20.61%	20.38%	20.15%	19.92%	19.69%	19.48%	19.26%	19.05%	18.84%	18.64%	18.44%	18.25%	18.06%	17.88%	17.69%	17.52%	17.34%	17.17%	17.00%	16.84%	16.68%	16.52%	16.36%	16.21%	16.06%	15.91%	15.77%	15.63%	15.49%	15.35%	15.22%	15.09%	14.96%	14.83%	14.71%	14.58%	14.46%	14.35%	14.23%	14.12%	14.00%	13.89%	13.78%	13.68%	13.57%	13.47%	13.36%	13.26%	13.17%	13.07%	12.97%	12.88%	12.78%	12.69%	12.60%	12.51%	12.43%	12.34%	12.26%	12.17%	12.09%	12.01%	11.93%	11.85%	11.77%	11.69%	11.62%	11.54%	11.47%	11.40%	11.32%	11.25%	11.18%	11.11%	11.05%	10.98%	10.91%	10.85%	10.78%	10.72%	10.66%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.89%	98.93%	95.66%	91.78%	87.14%	82.85%	79.14%	75.89%	73.03%	70.38%	67.92%	65.52%	63.25%	61.16%	59.09%	57.11%	55.21%	53.41%	51.75%	50.20%	48.77%	47.42%	46.16%	44.98%	43.86%	42.82%	41.84%	40.91%	40.03%	39.18%	38.35%	37.56%	36.80%	36.07%	35.38%	34.70%	34.05%	33.43%	32.83%	32.26%	31.71%	31.19%	30.68%	30.19%	29.70%	29.24%	28.79%	28.36%	27.93%	27.53%	27.13%	26.75%	26.38%	26.02%	25.66%	25.31%	24.97%	24.63%	24.29%	23.97%	23.65%	23.33%	23.03%	22.73%	22.45%	22.17%	21.89%	21.63%	21.37%	21.11%	20.86%	20.62%	20.38%	20.15%	19.93%	19.70%	19.48%	19.27%	19.06%	18.85%	18.65%	18.45%	18.26%	18.07%	17.88%	17.70%	17.52%	17.35%	17.18%	17.01%	16.84%	16.68%	16.52%	16.37%	16.21%	16.06%	15.92%	15.77%	15.63%	15.49%	15.36%	15.22%	15.09%	14.96%	14.83%	14.71%	14.59%	14.47%	14.35%	14.23%	14.12%	14.01%	13.90%	13.79%	13.68%	13.57%	13.47%	13.37%	13.27%	13.17%	13.07%	12.98%	12.88%	12.79%	12.70%	12.61%	12.52%	12.43%	12.34%	12.26%	12.17%	12.09%	12.01%	11.93%	11.85%	11.77%	11.70%	11.62%	11.55%	11.47%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.91%	10.85%	10.78%	10.72%	10.66%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.90%	98.94%	95.67%	91.79%	87.15%	82.86%	79.14%	75.90%	73.04%	70.39%	67.93%	65.53%	63.27%	61.17%	59.09%	57.12%	55.22%	53.42%	51.75%	50.21%	48.77%	47.43%	46.17%	44.98%	43.87%	42.83%	41.84%	40.91%	40.03%	39.19%	38.36%	37.57%	36.81%	36.08%	35.39%	34.71%	34.06%	33.44%	32.84%	32.27%	31.72%	31.20%	30.69%	30.20%	29.72%	29.25%	28.80%	28.37%	27.95%	27.54%	27.14%	26.76%	26.40%	26.03%	25.67%	25.32%	24.98%	24.64%	24.30%	23.97%	23.66%	23.34%	23.04%	22.74%	22.45%	22.17%	21.90%	21.63%	21.37%	21.12%	20.87%	20.63%	20.39%	20.16%	19.93%	19.71%	19.49%	19.28%	19.06%	18.86%	18.65%	18.45%	18.26%	18.07%	17.89%	17.70%	17.53%	17.35%	17.18%	17.01%	16.85%	16.69%	16.53%	16.37%	16.22%	16.07%	15.92%	15.78%	15.63%	15.50%	15.36%	15.23%	15.09%	14.96%	14.84%	14.71%	14.59%	14.47%	14.35%	14.24%	14.12%	14.01%	13.90%	13.79%	13.68%	13.58%	13.47%	13.37%	13.27%	13.17%	13.07%	12.98%	12.88%	12.79%	12.70%	12.61%	12.52%	12.43%	12.35%	12.26%	12.18%	12.09%	12.01%	11.93%	11.85%	11.78%	11.70%	11.62%	11.55%	11.47%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.98%	10.92%	10.85%	10.79%	10.72%	10.66%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.91%	98.95%	95.68%	91.80%	87.15%	82.86%	79.15%	75.91%	73.05%	70.40%	67.94%	65.55%	63.28%	61.18%	59.10%	57.12%	55.23%	53.43%	51.76%	50.22%	48.78%	47.44%	46.17%	44.99%	43.88%	42.83%	41.85%	40.92%	40.04%	39.20%	38.37%	37.58%	36.82%	36.09%	35.40%	34.72%	34.07%	33.45%	32.85%	32.28%	31.73%	31.21%	30.70%	30.21%	29.73%	29.26%	28.81%	28.38%	27.96%	27.55%	27.16%	26.78%	26.41%	26.04%	25.68%	25.33%	24.99%	24.65%	24.31%	23.98%	23.66%	23.35%	23.05%	22.75%	22.46%	22.18%	21.91%	21.64%	21.38%	21.12%	20.88%	20.63%	20.40%	20.17%	19.94%	19.72%	19.50%	19.28%	19.07%	18.86%	18.66%	18.46%	18.27%	18.08%	17.89%	17.71%	17.53%	17.36%	17.19%	17.02%	16.85%	16.69%	16.53%	16.37%	16.22%	16.07%	15.92%	15.78%	15.64%	15.50%	15.36%	15.23%	15.10%	14.97%	14.84%	14.72%	14.59%	14.47%	14.36%	14.24%	14.13%	14.01%	13.90%	13.79%	13.69%	13.58%	13.48%	13.37%	13.27%	13.17%	13.08%	12.98%	12.89%	12.79%	12.70%	12.61%	12.52%	12.44%	12.35%	12.26%	12.18%	12.10%	12.02%	11.94%	11.86%	11.78%	11.70%	11.63%	11.55%	11.48%	11.40%	11.33%	11.26%	11.19%	11.12%	11.05%	10.99%	10.92%	10.85%	10.79%	10.73%	10.66%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.92%	98.96%	95.69%	91.80%	87.16%	82.87%	79.16%	75.91%	73.05%	70.41%	67.95%	65.56%	63.29%	61.19%	59.10%	57.13%	55.23%	53.43%	51.77%	50.22%	48.79%	47.44%	46.18%	44.99%	43.88%	42.84%	41.85%	40.92%	40.04%	39.20%	38.38%	37.59%	36.84%	36.11%	35.41%	34.73%	34.09%	33.46%	32.86%	32.29%	31.74%	31.22%	30.71%	30.22%	29.74%	29.27%	28.83%	28.39%	27.97%	27.56%	27.17%	26.79%	26.42%	26.06%	25.70%	25.34%	25.00%	24.66%	24.32%	23.99%	23.67%	23.36%	23.06%	22.76%	22.47%	22.19%	21.92%	21.65%	21.39%	21.13%	20.88%	20.64%	20.40%	20.17%	19.95%	19.72%	19.50%	19.29%	19.07%	18.87%	18.66%	18.47%	18.27%	18.08%	17.90%	17.71%	17.54%	17.36%	17.19%	17.02%	16.86%	16.69%	16.53%	16.38%	16.23%	16.08%	15.93%	15.78%	15.64%	15.50%	15.37%	15.23%	15.10%	14.97%	14.84%	14.72%	14.60%	14.48%	14.36%	14.24%	14.13%	14.02%	13.91%	13.80%	13.69%	13.58%	13.48%	13.38%	13.28%	13.18%	13.08%	12.98%	12.89%	12.80%	12.71%	12.61%	12.53%	12.44%	12.35%	12.27%	12.18%	12.10%	12.02%	11.94%	11.86%	11.78%	11.70%	11.63%	11.55%	11.48%	11.41%	11.33%	11.26%	11.19%	11.12%	11.06%	10.99%	10.92%	10.86%	10.79%	10.73%	10.66%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.93%	98.97%	95.70%	91.81%	87.17%	82.88%	79.16%	75.92%	73.06%	70.42%	67.96%	65.58%	63.31%	61.19%	59.10%	57.13%	55.24%	53.44%	51.78%	50.23%	48.79%	47.45%	46.18%	45.00%	43.89%	42.84%	41.86%	40.93%	40.05%	39.21%	38.39%	37.60%	36.85%	36.12%	35.42%	34.75%	34.10%	33.47%	32.87%	32.30%	31.75%	31.23%	30.72%	30.23%	29.75%	29.28%	28.84%	28.40%	27.98%	27.57%	27.18%	26.80%	26.43%	26.07%	25.71%	25.35%	25.01%	24.67%	24.33%	24.00%	23.68%	23.37%	23.06%	22.77%	22.48%	22.20%	21.92%	21.65%	21.39%	21.14%	20.89%	20.64%	20.41%	20.18%	19.95%	19.73%	19.51%	19.29%	19.08%	18.87%	18.67%	18.47%	18.28%	18.09%	17.90%	17.72%	17.54%	17.37%	17.19%	17.03%	16.86%	16.70%	16.54%	16.38%	16.23%	16.08%	15.93%	15.79%	15.65%	15.51%	15.37%	15.24%	15.10%	14.98%	14.85%	14.72%	14.60%	14.48%	14.36%	14.25%	14.13%	14.02%	13.91%	13.80%	13.69%	13.59%	13.48%	13.38%	13.28%	13.18%	13.08%	12.99%	12.89%	12.80%	12.71%	12.62%	12.53%	12.44%	12.35%	12.27%	12.19%	12.10%	12.02%	11.94%	11.86%	11.78%	11.71%	11.63%	11.56%	11.48%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.92%	10.86%	10.79%	10.73%	10.67%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.94%	98.98%	95.70%	91.82%	87.18%	82.88%	79.17%	75.92%	73.06%	70.43%	67.97%	65.59%	63.32%	61.20%	59.11%	57.13%	55.25%	53.45%	51.78%	50.24%	48.80%	47.46%	46.19%	45.01%	43.89%	42.85%	41.86%	40.93%	40.05%	39.22%	38.40%	37.61%	36.86%	36.13%	35.43%	34.76%	34.11%	33.48%	32.89%	32.31%	31.76%	31.24%	30.73%	30.24%	29.76%	29.29%	28.85%	28.42%	27.99%	27.59%	27.19%	26.81%	26.44%	26.08%	25.72%	25.37%	25.02%	24.67%	24.34%	24.01%	23.69%	23.38%	23.07%	22.78%	22.49%	22.21%	21.93%	21.66%	21.40%	21.14%	20.89%	20.65%	20.41%	20.18%	19.96%	19.74%	19.52%	19.30%	19.09%	18.88%	18.68%	18.48%	18.28%	18.09%	17.91%	17.72%	17.55%	17.37%	17.20%	17.03%	16.86%	16.70%	16.54%	16.39%	16.23%	16.08%	15.94%	15.79%	15.65%	15.51%	15.37%	15.24%	15.11%	14.98%	14.85%	14.73%	14.60%	14.48%	14.37%	14.25%	14.14%	14.02%	13.91%	13.80%	13.70%	13.59%	13.49%	13.38%	13.28%	13.18%	13.09%	12.99%	12.90%	12.80%	12.71%	12.62%	12.53%	12.44%	12.36%	12.27%	12.19%	12.11%	12.02%	11.94%	11.86%	11.79%	11.71%	11.63%	11.56%	11.48%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	10.99%	10.93%	10.86%	10.80%	10.73%	10.67%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.95%	98.99%	95.71%	91.83%	87.18%	82.89%	79.18%	75.93%	73.07%	70.45%	67.98%	65.61%	63.34%	61.21%	59.11%	57.14%	55.26%	53.46%	51.79%	50.24%	48.81%	47.46%	46.20%	45.01%	43.90%	42.85%	41.87%	40.94%	40.06%	39.23%	38.41%	37.62%	36.87%	36.14%	35.44%	34.77%	34.12%	33.49%	32.90%	32.32%	31.77%	31.25%	30.74%	30.25%	29.77%	29.31%	28.86%	28.43%	28.01%	27.60%	27.20%	26.82%	26.45%	26.09%	25.73%	25.38%	25.03%	24.68%	24.34%	24.01%	23.69%	23.38%	23.08%	22.78%	22.49%	22.21%	21.94%	21.67%	21.40%	21.15%	20.90%	20.66%	20.42%	20.19%	19.96%	19.74%	19.52%	19.30%	19.09%	18.88%	18.68%	18.48%	18.29%	18.10%	17.91%	17.73%	17.55%	17.38%	17.20%	17.03%	16.87%	16.71%	16.55%	16.39%	16.24%	16.09%	15.94%	15.79%	15.65%	15.51%	15.38%	15.24%	15.11%	14.98%	14.86%	14.73%	14.61%	14.49%	14.37%	14.25%	14.14%	14.03%	13.92%	13.81%	13.70%	13.59%	13.49%	13.39%	13.29%	13.19%	13.09%	12.99%	12.90%	12.81%	12.71%	12.62%	12.53%	12.45%	12.36%	12.28%	12.19%	12.11%	12.03%	11.95%	11.87%	11.79%	11.71%	11.64%	11.56%	11.49%	11.41%	11.34%	11.27%	11.20%	11.13%	11.06%	11.00%	10.93%	10.86%	10.80%	10.74%	10.67%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.96%	99.00%	95.72%	91.84%	87.19%	82.90%	79.18%	75.94%	73.07%	70.46%	67.99%	65.62%	63.35%	61.22%	59.12%	57.14%	55.26%	53.46%	51.80%	50.25%	48.81%	47.47%	46.20%	45.02%	43.91%	42.86%	41.87%	40.94%	40.06%	39.23%	38.42%	37.63%	36.88%	36.15%	35.46%	34.78%	34.13%	33.50%	32.91%	32.33%	31.78%	31.26%	30.75%	30.26%	29.78%	29.32%	28.87%	28.44%	28.02%	27.61%	27.21%	26.83%	26.46%	26.10%	25.74%	25.39%	25.04%	24.69%	24.35%	24.02%	23.70%	23.39%	23.09%	22.79%	22.50%	22.22%	21.94%	21.67%	21.41%	21.16%	20.91%	20.66%	20.43%	20.20%	19.97%	19.75%	19.53%	19.31%	19.10%	18.89%	18.69%	18.49%	18.29%	18.10%	17.92%	17.74%	17.56%	17.38%	17.21%	17.04%	16.87%	16.71%	16.55%	16.39%	16.24%	16.09%	15.94%	15.80%	15.66%	15.52%	15.38%	15.25%	15.12%	14.99%	14.86%	14.73%	14.61%	14.49%	14.37%	14.26%	14.14%	14.03%	13.92%	13.81%	13.70%	13.60%	13.49%	13.39%	13.29%	13.19%	13.09%	13.00%	12.90%	12.81%	12.72%	12.63%	12.54%	12.45%	12.36%	12.28%	12.19%	12.11%	12.03%	11.95%	11.87%	11.79%	11.72%	11.64%	11.56%	11.49%	11.42%	11.35%	11.27%	11.20%	11.13%	11.07%	11.00%	10.93%	10.87%	10.80%	10.74%	10.67%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.97%	99.01%	95.73%	91.84%	87.20%	82.91%	79.19%	75.94%	73.08%	70.47%	68.00%	65.64%	63.36%	61.23%	59.12%	57.15%	55.27%	53.47%	51.80%	50.26%	48.82%	47.47%	46.21%	45.03%	43.91%	42.87%	41.88%	40.95%	40.07%	39.24%	38.43%	37.64%	36.88%	36.16%	35.47%	34.79%	34.14%	33.51%	32.92%	32.34%	31.79%	31.27%	30.76%	30.27%	29.79%	29.33%	28.88%	28.45%	28.03%	27.62%	27.23%	26.84%	26.47%	26.11%	25.75%	25.40%	25.05%	24.70%	24.36%	24.03%	23.71%	23.40%	23.09%	22.80%	22.51%	22.23%	21.95%	21.68%	21.42%	21.16%	20.91%	20.67%	20.43%	20.20%	19.98%	19.75%	19.53%	19.32%	19.10%	18.89%	18.69%	18.49%	18.30%	18.11%	17.92%	17.74%	17.56%	17.38%	17.21%	17.04%	16.88%	16.71%	16.55%	16.40%	16.24%	16.09%	15.95%	15.80%	15.66%	15.52%	15.38%	15.25%	15.12%	14.99%	14.86%	14.74%	14.61%	14.49%	14.38%	14.26%	14.15%	14.03%	13.92%	13.81%	13.70%	13.60%	13.50%	13.39%	13.29%	13.19%	13.10%	13.00%	12.90%	12.81%	12.72%	12.63%	12.54%	12.45%	12.37%	12.28%	12.20%	12.11%	12.03%	11.95%	11.87%	11.79%	11.72%	11.64%	11.57%	11.49%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.94%	10.87%	10.80%	10.74%	10.68%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.98%	99.01%	95.74%	91.85%	87.21%	82.91%	79.20%	75.95%	73.09%	70.48%	68.01%	65.65%	63.37%	61.24%	59.13%	57.15%	55.28%	53.48%	51.81%	50.27%	48.83%	47.48%	46.22%	45.03%	43.92%	42.87%	41.89%	40.95%	40.07%	39.24%	38.44%	37.65%	36.89%	36.18%	35.48%	34.80%	34.15%	33.53%	32.93%	32.35%	31.80%	31.28%	30.77%	30.28%	29.80%	29.34%	28.89%	28.46%	28.04%	27.63%	27.24%	26.86%	26.49%	26.12%	25.76%	25.41%	25.06%	24.71%	24.37%	24.04%	23.71%	23.40%	23.10%	22.81%	22.52%	22.23%	21.96%	21.69%	21.42%	21.17%	20.92%	20.68%	20.44%	20.21%	19.98%	19.76%	19.54%	19.32%	19.11%	18.90%	18.70%	18.50%	18.30%	18.11%	17.93%	17.75%	17.56%	17.39%	17.22%	17.05%	16.88%	16.72%	16.56%	16.40%	16.25%	16.10%	15.95%	15.81%	15.66%	15.52%	15.39%	15.25%	15.12%	14.99%	14.87%	14.74%	14.62%	14.50%	14.38%	14.26%	14.15%	14.04%	13.92%	13.82%	13.71%	13.60%	13.50%	13.40%	13.30%	13.20%	13.10%	13.00%	12.91%	12.81%	12.72%	12.63%	12.54%	12.46%	12.37%	12.28%	12.20%	12.12%	12.04%	11.96%	11.88%	11.80%	11.72%	11.64%	11.57%	11.50%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.00%	10.94%	10.87%	10.81%	10.74%	10.68%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.02%	95.75%	91.86%	87.21%	82.92%	79.20%	75.95%	73.09%	70.49%	68.02%	65.66%	63.38%	61.25%	59.13%	57.15%	55.29%	53.49%	51.82%	50.27%	48.83%	47.49%	46.22%	45.04%	43.92%	42.88%	41.89%	40.96%	40.08%	39.25%	38.45%	37.66%	36.90%	36.18%	35.49%	34.81%	34.16%	33.54%	32.94%	32.36%	31.81%	31.29%	30.78%	30.29%	29.81%	29.35%	28.90%	28.47%	28.05%	27.64%	27.25%	26.87%	26.50%	26.13%	25.77%	25.42%	25.06%	24.71%	24.37%	24.04%	23.72%	23.41%	23.11%	22.81%	22.52%	22.24%	21.96%	21.69%	21.43%	21.17%	20.92%	20.68%	20.44%	20.21%	19.99%	19.76%	19.54%	19.33%	19.11%	18.91%	18.70%	18.50%	18.31%	18.12%	17.93%	17.75%	17.57%	17.39%	17.22%	17.05%	16.88%	16.72%	16.56%	16.41%	16.25%	16.10%	15.95%	15.81%	15.67%	15.53%	15.39%	15.26%	15.13%	15.00%	14.87%	14.74%	14.62%	14.50%	14.38%	14.27%	14.15%	14.04%	13.93%	13.82%	13.71%	13.61%	13.50%	13.40%	13.30%	13.20%	13.10%	13.01%	12.91%	12.82%	12.73%	12.64%	12.55%	12.46%	12.37%	12.29%	12.20%	12.12%	12.04%	11.96%	11.88%	11.80%	11.72%	11.65%	11.57%	11.50%	11.42%	11.35%	11.28%	11.21%	11.14%	11.07%	11.01%	10.94%	10.87%	10.81%	10.74%	10.68%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.03%	95.76%	91.87%	87.22%	82.93%	79.21%	75.96%	73.10%	70.50%	68.03%	65.68%	63.39%	61.26%	59.13%	57.16%	55.29%	53.49%	51.83%	50.28%	48.84%	47.49%	46.23%	45.04%	43.93%	42.88%	41.90%	40.97%	40.09%	39.25%	38.45%	37.67%	36.91%	36.19%	35.50%	34.82%	34.17%	33.55%	32.95%	32.37%	31.82%	31.30%	30.79%	30.30%	29.82%	29.36%	28.91%	28.48%	28.06%	27.66%	27.26%	26.88%	26.51%	26.14%	25.78%	25.42%	25.07%	24.72%	24.38%	24.05%	23.73%	23.42%	23.11%	22.82%	22.53%	22.25%	21.97%	21.70%	21.44%	21.18%	20.93%	20.69%	20.45%	20.22%	19.99%	19.77%	19.55%	19.33%	19.12%	18.91%	18.71%	18.51%	18.31%	18.12%	17.94%	17.75%	17.57%	17.40%	17.22%	17.05%	16.89%	16.73%	16.57%	16.41%	16.26%	16.11%	15.96%	15.81%	15.67%	15.53%	15.40%	15.26%	15.13%	15.00%	14.87%	14.75%	14.63%	14.50%	14.39%	14.27%	14.15%	14.04%	13.93%	13.82%	13.71%	13.61%	13.50%	13.40%	13.30%	13.20%	13.10%	13.01%	12.91%	12.82%	12.73%	12.64%	12.55%	12.46%	12.37%	12.29%	12.21%	12.12%	12.04%	11.96%	11.88%	11.80%	11.73%	11.65%	11.57%	11.50%	11.43%	11.36%	11.28%	11.21%	11.15%	11.08%	11.01%	10.94%	10.88%	10.81%	10.75%	10.68%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.04%	95.77%	91.88%	87.23%	82.93%	79.22%	75.97%	73.10%	70.51%	68.05%	65.69%	63.40%	61.27%	59.14%	57.16%	55.29%	53.50%	51.83%	50.29%	48.85%	47.50%	46.24%	45.05%	43.94%	42.89%	41.90%	40.97%	40.09%	39.26%	38.46%	37.68%	36.92%	36.20%	35.51%	34.83%	34.18%	33.56%	32.96%	32.38%	31.83%	31.31%	30.80%	30.30%	29.83%	29.37%	28.92%	28.49%	28.07%	27.67%	27.27%	26.89%	26.52%	26.15%	25.79%	25.43%	25.08%	24.73%	24.39%	24.06%	23.74%	23.42%	23.12%	22.82%	22.53%	22.25%	21.98%	21.71%	21.44%	21.19%	20.94%	20.69%	20.46%	20.23%	20.00%	19.77%	19.55%	19.34%	19.13%	18.92%	18.71%	18.51%	18.32%	18.13%	17.94%	17.76%	17.58%	17.40%	17.23%	17.06%	16.89%	16.73%	16.57%	16.41%	16.26%	16.11%	15.96%	15.82%	15.68%	15.54%	15.40%	15.26%	15.13%	15.00%	14.88%	14.75%	14.63%	14.51%	14.39%	14.27%	14.16%	14.05%	13.93%	13.83%	13.72%	13.61%	13.51%	13.41%	13.30%	13.21%	13.11%	13.01%	12.92%	12.82%	12.73%	12.64%	12.55%	12.46%	12.38%	12.29%	12.21%	12.13%	12.04%	11.96%	11.88%	11.81%	11.73%	11.65%	11.58%	11.50%	11.43%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.94%	10.88%	10.81%	10.75%	10.68%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.05%	95.78%	91.89%	87.24%	82.94%	79.22%	75.97%	73.11%	70.52%	68.06%	65.70%	63.41%	61.27%	59.14%	57.17%	55.30%	53.51%	51.84%	50.29%	48.85%	47.51%	46.24%	45.06%	43.94%	42.90%	41.91%	40.98%	40.10%	39.26%	38.47%	37.69%	36.93%	36.21%	35.52%	34.85%	34.19%	33.57%	32.97%	32.39%	31.84%	31.32%	30.81%	30.31%	29.84%	29.38%	28.93%	28.50%	28.08%	27.68%	27.28%	26.90%	26.53%	26.16%	25.80%	25.44%	25.09%	24.74%	24.39%	24.06%	23.74%	23.43%	23.13%	22.83%	22.54%	22.26%	21.98%	21.71%	21.45%	21.19%	20.94%	20.70%	20.46%	20.23%	20.00%	19.78%	19.56%	19.34%	19.13%	18.92%	18.72%	18.52%	18.33%	18.13%	17.95%	17.76%	17.58%	17.40%	17.23%	17.06%	16.90%	16.73%	16.57%	16.42%	16.26%	16.11%	15.97%	15.82%	15.68%	15.54%	15.40%	15.27%	15.14%	15.01%	14.88%	14.75%	14.63%	14.51%	14.39%	14.28%	14.16%	14.05%	13.94%	13.83%	13.72%	13.62%	13.51%	13.41%	13.31%	13.21%	13.11%	13.01%	12.92%	12.83%	12.73%	12.64%	12.55%	12.47%	12.38%	12.30%	12.21%	12.13%	12.05%	11.97%	11.89%	11.81%	11.73%	11.66%	11.58%	11.51%	11.43%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.95%	10.88%	10.81%	10.75%	10.68%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.06%	95.78%	91.89%	87.24%	82.95%	79.23%	75.98%	73.11%	70.53%	68.07%	65.71%	63.42%	61.28%	59.15%	57.17%	55.30%	53.52%	51.85%	50.30%	48.86%	47.51%	46.25%	45.06%	43.95%	42.90%	41.91%	40.98%	40.10%	39.27%	38.48%	37.70%	36.94%	36.22%	35.54%	34.86%	34.21%	33.58%	32.98%	32.41%	31.85%	31.33%	30.82%	30.32%	29.85%	29.39%	28.94%	28.51%	28.09%	27.69%	27.30%	26.91%	26.54%	26.17%	25.81%	25.45%	25.09%	24.74%	24.40%	24.07%	23.75%	23.44%	23.13%	22.84%	22.55%	22.26%	21.99%	21.72%	21.46%	21.20%	20.95%	20.71%	20.47%	20.24%	20.01%	19.78%	19.56%	19.35%	19.14%	18.93%	18.72%	18.53%	18.33%	18.14%	17.95%	17.77%	17.59%	17.41%	17.24%	17.07%	16.90%	16.74%	16.58%	16.42%	16.27%	16.12%	15.97%	15.82%	15.68%	15.54%	15.41%	15.27%	15.14%	15.01%	14.88%	14.76%	14.64%	14.51%	14.40%	14.28%	14.16%	14.05%	13.94%	13.83%	13.72%	13.62%	13.51%	13.41%	13.31%	13.21%	13.11%	13.02%	12.92%	12.83%	12.74%	12.65%	12.56%	12.47%	12.38%	12.30%	12.21%	12.13%	12.05%	11.97%	11.89%	11.81%	11.73%	11.66%	11.58%	11.51%	11.44%	11.36%	11.29%	11.22%	11.15%	11.08%	11.01%	10.95%	10.88%	10.81%	10.75%	10.69%
  100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	100.00%	99.07%	95.79%	91.90%	87.25%	82.96%	79.24%	75.99%	73.12%	70.54%	68.08%	65.72%	63.43%	61.29%	59.15%	57.17%	55.31%	53.52%	51.86%	50.31%	48.87%	47.52%	46.25%	45.07%	43.95%	42.91%	41.92%	40.99%	40.11%	39.27%	38.48%	37.71%	36.95%	36.23%	35.54%	34.87%	34.22%	33.59%	32.99%	32.42%	31.86%	31.34%	30.82%	30.33%	29.86%	29.40%	28.95%	28.52%	28.10%	27.70%	27.31%	26.92%	26.55%	26.18%	25.82%	25.46%	25.10%	24.75%	24.41%	24.08%	23.76%	23.45%	23.14%	22.84%	22.55%	22.27%	21.99%	21.72%	21.46%	21.21%	20.96%	20.71%	20.48%	20.24%	20.01%	19.79%	19.57%	19.35%	19.14%	18.93%	18.73%	18.53%	18.34%	18.14%	17.95%	17.77%	17.59%	17.41%	17.24%	17.07%	16.90%	16.74%	16.58%	16.42%	16.27%	16.12%	15.97%	15.83%	15.69%	15.55%	15.41%	15.28%	15.14%	15.01%	14.89%	14.76%	14.64%	14.52%	14.40%	14.28%	14.17%	14.06%	13.94%	13.83%	13.73%	13.62%	13.52%	13.41%	13.31%	13.21%	13.12%	13.02%	12.93%	12.83%	12.74%	12.65%	12.56%	12.47%	12.39%	12.30%	12.22%	12.13%	12.05%	11.97%	11.89%	11.81%	11.74%	11.66%	11.58%	11.51%	11.44%	11.36%	11.29%	11.22%	11.15%	11.08%	11.02%	10.95%	10.88%	10.82%	10.75%	10.69%
"""
  .parseStringToPercentageTable()

/**
 * Table of percentages representing the degree of self-sufficient achieved as specified by two metrics:
 *  - X axis: relativeKiloWattPeak (PowerRating / AnnualElectricityConsumption * 100)
 *  - Y axis: relativeBatteryCapacity (BatteryCapacity / AnnualElectricityConsumption * 100)
 *  Each step on an axis corresponds to 1/160 as the relative values range from 0.0 to 1.0
 */
private val selfSufficiencyPercentageTable = """
  0.00%	6.16%	10.70%	13.99%	16.60%	18.78%	20.66%	22.31%	23.75%	25.01%	26.10%	27.04%	27.86%	28.60%	29.27%	29.89%	30.45%	30.98%	31.46%	31.91%	32.33%	32.73%	33.10%	33.45%	33.78%	34.09%	34.39%	34.67%	34.93%	35.19%	35.43%	35.66%	35.88%	36.09%	36.30%	36.49%	36.68%	36.86%	37.03%	37.20%	37.36%	37.51%	37.66%	37.80%	37.94%	38.08%	38.21%	38.33%	38.45%	38.57%	38.69%	38.80%	38.91%	39.01%	39.11%	39.21%	39.31%	39.40%	39.49%	39.58%	39.67%	39.76%	39.84%	39.92%	40.00%	40.08%	40.16%	40.23%	40.30%	40.38%	40.45%	40.51%	40.58%	40.65%	40.71%	40.77%	40.83%	40.89%	40.95%	41.01%	41.07%	41.12%	41.18%	41.23%	41.28%	41.33%	41.39%	41.43%	41.48%	41.53%	41.58%	41.62%	41.67%	41.71%	41.75%	41.80%	41.84%	41.88%	41.92%	41.96%	42.00%	42.04%	42.07%	42.11%	42.15%	42.18%	42.22%	42.25%	42.29%	42.32%	42.36%	42.39%	42.42%	42.45%	42.48%	42.51%	42.55%	42.58%	42.61%	42.63%	42.66%	42.69%	42.72%	42.75%	42.78%	42.80%	42.83%	42.86%	42.88%	42.91%	42.93%	42.96%	42.98%	43.01%	43.03%	43.05%	43.08%	43.10%	43.12%	43.14%	43.17%	43.19%	43.21%	43.23%	43.25%	43.27%	43.29%	43.31%	43.34%	43.36%	43.37%	43.39%	43.41%	43.43%	43.45%	43.47%	43.49%	43.51%	43.53%	43.54%	43.56%
  0.00%	6.37%	11.95%	16.14%	19.26%	21.73%	23.79%	25.51%	26.96%	28.17%	29.19%	30.10%	30.91%	31.64%	32.29%	32.89%	33.45%	33.96%	34.43%	34.87%	35.27%	35.66%	36.01%	36.34%	36.66%	36.96%	37.24%	37.51%	37.76%	38.00%	38.23%	38.45%	38.66%	38.86%	39.05%	39.24%	39.41%	39.58%	39.75%	39.91%	40.07%	40.22%	40.36%	40.50%	40.63%	40.76%	40.88%	41.00%	41.12%	41.23%	41.34%	41.45%	41.55%	41.66%	41.75%	41.85%	41.94%	42.03%	42.12%	42.20%	42.29%	42.37%	42.45%	42.52%	42.60%	42.67%	42.74%	42.81%	42.88%	42.94%	43.01%	43.07%	43.13%	43.19%	43.25%	43.31%	43.36%	43.41%	43.47%	43.52%	43.57%	43.62%	43.67%	43.71%	43.76%	43.81%	43.85%	43.89%	43.94%	43.98%	44.02%	44.06%	44.10%	44.14%	44.18%	44.22%	44.26%	44.30%	44.33%	44.37%	44.40%	44.44%	44.47%	44.51%	44.54%	44.57%	44.61%	44.64%	44.67%	44.70%	44.73%	44.76%	44.79%	44.82%	44.85%	44.87%	44.90%	44.93%	44.96%	44.98%	45.01%	45.03%	45.06%	45.08%	45.11%	45.13%	45.16%	45.18%	45.20%	45.22%	45.25%	45.27%	45.29%	45.31%	45.33%	45.35%	45.37%	45.39%	45.42%	45.44%	45.46%	45.48%	45.49%	45.51%	45.53%	45.55%	45.57%	45.59%	45.61%	45.63%	45.64%	45.66%	45.68%	45.70%	45.71%	45.73%	45.75%	45.76%	45.78%	45.80%	45.81%
  0.00%	6.37%	12.25%	17.05%	20.75%	23.55%	25.84%	27.67%	29.12%	30.34%	31.38%	32.30%	33.12%	33.86%	34.54%	35.14%	35.70%	36.21%	36.68%	37.11%	37.52%	37.90%	38.26%	38.60%	38.91%	39.20%	39.48%	39.75%	39.99%	40.23%	40.46%	40.68%	40.88%	41.08%	41.27%	41.45%	41.62%	41.79%	41.95%	42.11%	42.26%	42.41%	42.55%	42.69%	42.82%	42.95%	43.08%	43.20%	43.32%	43.43%	43.54%	43.65%	43.75%	43.86%	43.95%	44.05%	44.14%	44.23%	44.32%	44.40%	44.49%	44.57%	44.64%	44.72%	44.79%	44.87%	44.94%	45.00%	45.07%	45.14%	45.20%	45.26%	45.32%	45.38%	45.43%	45.49%	45.54%	45.59%	45.64%	45.69%	45.74%	45.79%	45.84%	45.89%	45.93%	45.97%	46.02%	46.06%	46.10%	46.14%	46.18%	46.22%	46.26%	46.30%	46.34%	46.38%	46.41%	46.45%	46.48%	46.52%	46.55%	46.58%	46.62%	46.65%	46.68%	46.71%	46.74%	46.77%	46.80%	46.83%	46.86%	46.89%	46.92%	46.95%	46.97%	47.00%	47.03%	47.05%	47.08%	47.10%	47.13%	47.15%	47.18%	47.20%	47.22%	47.25%	47.27%	47.29%	47.31%	47.33%	47.35%	47.38%	47.40%	47.42%	47.44%	47.46%	47.48%	47.50%	47.52%	47.54%	47.56%	47.57%	47.59%	47.61%	47.63%	47.65%	47.66%	47.68%	47.70%	47.72%	47.73%	47.75%	47.77%	47.78%	47.80%	47.82%	47.83%	47.85%	47.86%	47.88%	47.89%
  0.00%	6.37%	12.41%	17.54%	21.67%	24.86%	27.34%	29.29%	30.81%	32.10%	33.18%	34.14%	34.99%	35.75%	36.44%	37.05%	37.61%	38.13%	38.61%	39.05%	39.45%	39.83%	40.19%	40.53%	40.85%	41.15%	41.43%	41.70%	41.95%	42.19%	42.42%	42.65%	42.86%	43.06%	43.25%	43.43%	43.60%	43.77%	43.93%	44.08%	44.24%	44.38%	44.53%	44.67%	44.80%	44.94%	45.06%	45.18%	45.30%	45.42%	45.53%	45.64%	45.74%	45.85%	45.94%	46.04%	46.13%	46.23%	46.31%	46.40%	46.49%	46.57%	46.65%	46.73%	46.80%	46.88%	46.95%	47.02%	47.09%	47.16%	47.22%	47.28%	47.34%	47.40%	47.46%	47.51%	47.57%	47.62%	47.67%	47.72%	47.77%	47.82%	47.87%	47.91%	47.96%	48.00%	48.04%	48.09%	48.13%	48.17%	48.21%	48.25%	48.29%	48.33%	48.37%	48.40%	48.44%	48.48%	48.51%	48.55%	48.58%	48.61%	48.65%	48.68%	48.71%	48.74%	48.77%	48.80%	48.83%	48.85%	48.88%	48.91%	48.94%	48.96%	48.99%	49.02%	49.04%	49.07%	49.09%	49.12%	49.14%	49.17%	49.19%	49.22%	49.24%	49.26%	49.28%	49.31%	49.33%	49.35%	49.37%	49.39%	49.41%	49.43%	49.45%	49.47%	49.49%	49.51%	49.53%	49.55%	49.57%	49.59%	49.60%	49.62%	49.64%	49.66%	49.67%	49.69%	49.71%	49.73%	49.74%	49.76%	49.78%	49.79%	49.81%	49.82%	49.84%	49.85%	49.87%	49.88%	49.90%
  0.00%	6.37%	12.47%	17.86%	22.32%	25.84%	28.51%	30.61%	32.26%	33.60%	34.75%	35.75%	36.63%	37.42%	38.12%	38.75%	39.32%	39.84%	40.33%	40.78%	41.19%	41.59%	41.95%	42.29%	42.62%	42.92%	43.20%	43.47%	43.73%	43.98%	44.21%	44.44%	44.66%	44.87%	45.07%	45.26%	45.44%	45.62%	45.78%	45.94%	46.09%	46.24%	46.38%	46.52%	46.66%	46.79%	46.92%	47.04%	47.16%	47.28%	47.39%	47.51%	47.61%	47.72%	47.82%	47.92%	48.01%	48.11%	48.20%	48.29%	48.37%	48.46%	48.54%	48.62%	48.70%	48.77%	48.85%	48.92%	48.99%	49.05%	49.12%	49.19%	49.25%	49.31%	49.37%	49.43%	49.49%	49.55%	49.60%	49.65%	49.71%	49.76%	49.80%	49.85%	49.90%	49.94%	49.99%	50.03%	50.08%	50.12%	50.16%	50.20%	50.24%	50.28%	50.32%	50.36%	50.39%	50.43%	50.47%	50.50%	50.53%	50.57%	50.60%	50.63%	50.67%	50.70%	50.73%	50.76%	50.79%	50.82%	50.84%	50.87%	50.90%	50.93%	50.95%	50.98%	51.01%	51.03%	51.06%	51.08%	51.11%	51.13%	51.15%	51.18%	51.20%	51.22%	51.25%	51.27%	51.29%	51.31%	51.33%	51.35%	51.37%	51.39%	51.41%	51.43%	51.45%	51.47%	51.49%	51.51%	51.53%	51.55%	51.56%	51.58%	51.60%	51.62%	51.63%	51.65%	51.67%	51.68%	51.70%	51.72%	51.73%	51.75%	51.77%	51.78%	51.80%	51.81%	51.83%	51.85%	51.86%
  0.00%	6.37%	12.48%	18.09%	22.81%	26.58%	29.50%	31.74%	33.53%	34.94%	36.15%	37.20%	38.12%	38.93%	39.66%	40.30%	40.88%	41.42%	41.92%	42.37%	42.80%	43.20%	43.58%	43.93%	44.26%	44.58%	44.87%	45.15%	45.41%	45.66%	45.90%	46.13%	46.36%	46.58%	46.79%	46.98%	47.18%	47.36%	47.53%	47.69%	47.86%	48.01%	48.16%	48.31%	48.45%	48.58%	48.71%	48.83%	48.96%	49.07%	49.19%	49.30%	49.41%	49.52%	49.63%	49.73%	49.83%	49.92%	50.01%	50.10%	50.19%	50.27%	50.36%	50.44%	50.52%	50.60%	50.68%	50.75%	50.82%	50.90%	50.97%	51.03%	51.10%	51.16%	51.22%	51.29%	51.34%	51.40%	51.46%	51.51%	51.57%	51.62%	51.67%	51.72%	51.77%	51.82%	51.87%	51.92%	51.96%	52.01%	52.05%	52.09%	52.13%	52.18%	52.22%	52.25%	52.29%	52.33%	52.37%	52.41%	52.44%	52.48%	52.51%	52.55%	52.58%	52.61%	52.64%	52.67%	52.70%	52.73%	52.76%	52.79%	52.82%	52.85%	52.88%	52.91%	52.93%	52.96%	52.99%	53.01%	53.04%	53.06%	53.08%	53.11%	53.13%	53.15%	53.18%	53.20%	53.22%	53.24%	53.26%	53.28%	53.30%	53.32%	53.34%	53.36%	53.38%	53.40%	53.42%	53.44%	53.46%	53.47%	53.49%	53.51%	53.53%	53.54%	53.56%	53.58%	53.60%	53.61%	53.63%	53.65%	53.66%	53.68%	53.69%	53.71%	53.73%	53.74%	53.76%	53.77%	53.79%
  0.00%	6.37%	12.48%	18.24%	23.19%	27.19%	30.36%	32.76%	34.67%	36.18%	37.44%	38.55%	39.51%	40.35%	41.10%	41.77%	42.37%	42.93%	43.44%	43.90%	44.34%	44.75%	45.14%	45.50%	45.85%	46.17%	46.47%	46.76%	47.04%	47.29%	47.54%	47.78%	48.01%	48.23%	48.45%	48.65%	48.85%	49.04%	49.22%	49.39%	49.56%	49.72%	49.87%	50.02%	50.17%	50.31%	50.45%	50.58%	50.71%	50.83%	50.95%	51.06%	51.17%	51.28%	51.39%	51.49%	51.59%	51.69%	51.78%	51.88%	51.97%	52.06%	52.14%	52.23%	52.31%	52.39%	52.47%	52.54%	52.62%	52.69%	52.77%	52.84%	52.90%	52.97%	53.04%	53.10%	53.17%	53.23%	53.29%	53.34%	53.40%	53.45%	53.50%	53.56%	53.61%	53.66%	53.71%	53.76%	53.80%	53.85%	53.90%	53.94%	53.99%	54.04%	54.08%	54.12%	54.16%	54.20%	54.24%	54.28%	54.32%	54.35%	54.39%	54.43%	54.46%	54.50%	54.53%	54.56%	54.59%	54.62%	54.65%	54.68%	54.71%	54.74%	54.77%	54.80%	54.83%	54.85%	54.88%	54.91%	54.93%	54.96%	54.98%	55.01%	55.03%	55.06%	55.08%	55.10%	55.13%	55.15%	55.17%	55.19%	55.21%	55.23%	55.25%	55.27%	55.29%	55.31%	55.33%	55.35%	55.37%	55.39%	55.40%	55.42%	55.44%	55.46%	55.47%	55.49%	55.51%	55.52%	55.54%	55.56%	55.57%	55.59%	55.61%	55.62%	55.64%	55.65%	55.67%	55.68%	55.70%
  0.00%	6.37%	12.48%	18.33%	23.49%	27.71%	31.10%	33.70%	35.71%	37.34%	38.67%	39.83%	40.83%	41.72%	42.49%	43.19%	43.81%	44.38%	44.91%	45.39%	45.84%	46.26%	46.65%	47.03%	47.39%	47.73%	48.05%	48.34%	48.62%	48.89%	49.15%	49.39%	49.63%	49.86%	50.08%	50.30%	50.50%	50.69%	50.87%	51.05%	51.23%	51.39%	51.56%	51.71%	51.86%	52.01%	52.15%	52.29%	52.42%	52.55%	52.67%	52.79%	52.91%	53.02%	53.13%	53.23%	53.34%	53.44%	53.53%	53.63%	53.72%	53.81%	53.90%	53.99%	54.08%	54.16%	54.24%	54.32%	54.39%	54.47%	54.54%	54.61%	54.69%	54.76%	54.82%	54.89%	54.96%	55.02%	55.08%	55.14%	55.20%	55.26%	55.31%	55.37%	55.42%	55.48%	55.53%	55.58%	55.63%	55.68%	55.73%	55.77%	55.82%	55.87%	55.91%	55.96%	56.00%	56.04%	56.09%	56.13%	56.17%	56.21%	56.24%	56.28%	56.32%	56.35%	56.39%	56.42%	56.45%	56.49%	56.52%	56.55%	56.58%	56.61%	56.64%	56.67%	56.70%	56.73%	56.76%	56.79%	56.81%	56.84%	56.86%	56.89%	56.91%	56.94%	56.96%	56.99%	57.01%	57.03%	57.06%	57.08%	57.10%	57.12%	57.15%	57.17%	57.19%	57.21%	57.23%	57.25%	57.27%	57.29%	57.30%	57.32%	57.34%	57.36%	57.38%	57.39%	57.41%	57.43%	57.45%	57.46%	57.48%	57.50%	57.51%	57.53%	57.54%	57.56%	57.57%	57.59%	57.61%
  0.00%	6.37%	12.48%	18.38%	23.73%	28.18%	31.77%	34.56%	36.69%	38.43%	39.85%	41.06%	42.12%	43.03%	43.85%	44.56%	45.22%	45.81%	46.36%	46.85%	47.32%	47.74%	48.14%	48.53%	48.90%	49.25%	49.59%	49.90%	50.19%	50.47%	50.73%	50.98%	51.23%	51.47%	51.69%	51.91%	52.13%	52.33%	52.52%	52.70%	52.87%	53.05%	53.21%	53.37%	53.53%	53.68%	53.83%	53.97%	54.11%	54.24%	54.37%	54.49%	54.61%	54.73%	54.85%	54.96%	55.07%	55.17%	55.27%	55.37%	55.46%	55.56%	55.65%	55.74%	55.83%	55.91%	56.00%	56.08%	56.16%	56.23%	56.31%	56.38%	56.45%	56.53%	56.59%	56.66%	56.73%	56.80%	56.86%	56.92%	56.98%	57.04%	57.10%	57.16%	57.21%	57.27%	57.33%	57.38%	57.43%	57.48%	57.53%	57.59%	57.64%	57.69%	57.73%	57.78%	57.83%	57.87%	57.91%	57.96%	58.00%	58.04%	58.08%	58.12%	58.16%	58.20%	58.23%	58.27%	58.30%	58.33%	58.37%	58.40%	58.43%	58.46%	58.49%	58.52%	58.55%	58.58%	58.61%	58.64%	58.67%	58.70%	58.73%	58.76%	58.78%	58.81%	58.84%	58.86%	58.89%	58.91%	58.93%	58.96%	58.98%	59.00%	59.02%	59.04%	59.07%	59.09%	59.11%	59.13%	59.15%	59.17%	59.19%	59.21%	59.23%	59.25%	59.27%	59.29%	59.31%	59.32%	59.34%	59.36%	59.38%	59.40%	59.41%	59.43%	59.45%	59.46%	59.48%	59.49%	59.51%
  0.00%	6.37%	12.48%	18.39%	23.91%	28.59%	32.34%	35.33%	37.63%	39.44%	40.98%	42.25%	43.36%	44.32%	45.17%	45.92%	46.59%	47.21%	47.78%	48.29%	48.77%	49.22%	49.63%	50.02%	50.39%	50.75%	51.10%	51.43%	51.74%	52.02%	52.29%	52.55%	52.81%	53.05%	53.29%	53.51%	53.73%	53.95%	54.15%	54.34%	54.52%	54.69%	54.86%	55.02%	55.18%	55.34%	55.49%	55.64%	55.78%	55.92%	56.05%	56.18%	56.30%	56.43%	56.54%	56.66%	56.77%	56.88%	56.99%	57.09%	57.19%	57.29%	57.38%	57.48%	57.57%	57.66%	57.74%	57.83%	57.91%	57.99%	58.07%	58.14%	58.22%	58.29%	58.36%	58.43%	58.49%	58.56%	58.63%	58.69%	58.75%	58.81%	58.87%	58.93%	58.99%	59.05%	59.11%	59.16%	59.22%	59.27%	59.33%	59.38%	59.43%	59.48%	59.53%	59.58%	59.63%	59.68%	59.73%	59.77%	59.82%	59.86%	59.90%	59.94%	59.98%	60.02%	60.06%	60.10%	60.13%	60.17%	60.20%	60.24%	60.27%	60.31%	60.34%	60.37%	60.40%	60.43%	60.46%	60.49%	60.52%	60.55%	60.58%	60.60%	60.63%	60.66%	60.69%	60.71%	60.74%	60.77%	60.79%	60.82%	60.84%	60.87%	60.89%	60.91%	60.94%	60.96%	60.98%	61.00%	61.02%	61.04%	61.06%	61.08%	61.10%	61.12%	61.14%	61.16%	61.18%	61.20%	61.22%	61.24%	61.26%	61.28%	61.30%	61.31%	61.33%	61.35%	61.37%	61.38%	61.40%
  0.00%	6.37%	12.48%	18.39%	24.04%	28.92%	32.86%	36.02%	38.50%	40.42%	42.05%	43.40%	44.56%	45.57%	46.45%	47.24%	47.94%	48.59%	49.18%	49.72%	50.21%	50.66%	51.09%	51.50%	51.88%	52.24%	52.59%	52.93%	53.25%	53.56%	53.84%	54.11%	54.37%	54.62%	54.87%	55.10%	55.33%	55.54%	55.75%	55.95%	56.14%	56.32%	56.50%	56.67%	56.83%	56.99%	57.14%	57.29%	57.43%	57.58%	57.71%	57.85%	57.98%	58.11%	58.23%	58.35%	58.46%	58.58%	58.68%	58.79%	58.89%	59.00%	59.10%	59.20%	59.29%	59.39%	59.47%	59.56%	59.65%	59.73%	59.81%	59.89%	59.97%	60.04%	60.12%	60.19%	60.25%	60.32%	60.38%	60.45%	60.51%	60.57%	60.64%	60.70%	60.76%	60.82%	60.88%	60.93%	60.99%	61.05%	61.10%	61.16%	61.21%	61.27%	61.32%	61.37%	61.42%	61.47%	61.52%	61.57%	61.61%	61.66%	61.71%	61.75%	61.79%	61.83%	61.88%	61.91%	61.95%	61.99%	62.03%	62.06%	62.10%	62.13%	62.17%	62.20%	62.23%	62.27%	62.30%	62.33%	62.36%	62.39%	62.42%	62.45%	62.48%	62.50%	62.53%	62.56%	62.59%	62.61%	62.64%	62.67%	62.69%	62.72%	62.74%	62.77%	62.79%	62.81%	62.84%	62.86%	62.88%	62.91%	62.93%	62.95%	62.97%	63.00%	63.02%	63.04%	63.06%	63.08%	63.10%	63.11%	63.13%	63.15%	63.17%	63.19%	63.21%	63.23%	63.24%	63.26%	63.28%
  0.00%	6.37%	12.48%	18.39%	24.12%	29.20%	33.34%	36.65%	39.32%	41.36%	43.07%	44.52%	45.74%	46.79%	47.71%	48.53%	49.26%	49.93%	50.56%	51.11%	51.63%	52.10%	52.54%	52.96%	53.35%	53.72%	54.08%	54.42%	54.75%	55.07%	55.36%	55.65%	55.92%	56.18%	56.43%	56.67%	56.91%	57.13%	57.34%	57.55%	57.75%	57.94%	58.12%	58.29%	58.46%	58.63%	58.79%	58.94%	59.08%	59.23%	59.37%	59.50%	59.64%	59.77%	59.89%	60.02%	60.14%	60.26%	60.37%	60.48%	60.59%	60.69%	60.80%	60.90%	61.00%	61.09%	61.19%	61.28%	61.37%	61.46%	61.54%	61.62%	61.70%	61.78%	61.86%	61.93%	62.00%	62.07%	62.14%	62.21%	62.27%	62.33%	62.39%	62.46%	62.52%	62.58%	62.64%	62.69%	62.75%	62.81%	62.87%	62.93%	62.98%	63.04%	63.09%	63.15%	63.20%	63.25%	63.30%	63.35%	63.40%	63.45%	63.50%	63.54%	63.59%	63.63%	63.67%	63.72%	63.76%	63.80%	63.84%	63.87%	63.91%	63.95%	63.99%	64.02%	64.06%	64.09%	64.13%	64.16%	64.19%	64.22%	64.25%	64.28%	64.31%	64.34%	64.37%	64.40%	64.43%	64.46%	64.48%	64.51%	64.54%	64.56%	64.59%	64.61%	64.63%	64.66%	64.68%	64.71%	64.73%	64.75%	64.78%	64.80%	64.82%	64.84%	64.87%	64.89%	64.91%	64.93%	64.96%	64.98%	65.00%	65.02%	65.04%	65.06%	65.08%	65.10%	65.12%	65.13%	65.15%
  0.00%	6.37%	12.48%	18.39%	24.17%	29.42%	33.77%	37.23%	40.06%	42.26%	44.03%	45.59%	46.88%	47.99%	48.95%	49.80%	50.56%	51.26%	51.90%	52.49%	53.03%	53.52%	53.98%	54.41%	54.81%	55.20%	55.55%	55.90%	56.24%	56.56%	56.87%	57.17%	57.45%	57.72%	57.98%	58.23%	58.47%	58.70%	58.92%	59.13%	59.34%	59.53%	59.73%	59.91%	60.08%	60.25%	60.42%	60.57%	60.73%	60.87%	61.01%	61.15%	61.29%	61.42%	61.55%	61.68%	61.80%	61.92%	62.04%	62.16%	62.27%	62.38%	62.49%	62.59%	62.69%	62.79%	62.89%	62.98%	63.07%	63.17%	63.26%	63.34%	63.43%	63.51%	63.59%	63.66%	63.74%	63.81%	63.88%	63.95%	64.02%	64.08%	64.15%	64.21%	64.27%	64.33%	64.39%	64.45%	64.51%	64.57%	64.63%	64.68%	64.74%	64.80%	64.85%	64.91%	64.96%	65.02%	65.07%	65.12%	65.17%	65.22%	65.27%	65.32%	65.36%	65.41%	65.46%	65.50%	65.54%	65.59%	65.63%	65.67%	65.71%	65.75%	65.78%	65.82%	65.86%	65.90%	65.93%	65.97%	66.00%	66.04%	66.07%	66.10%	66.13%	66.16%	66.19%	66.22%	66.25%	66.28%	66.31%	66.34%	66.37%	66.39%	66.42%	66.44%	66.47%	66.49%	66.52%	66.54%	66.56%	66.59%	66.61%	66.63%	66.66%	66.68%	66.70%	66.73%	66.75%	66.77%	66.79%	66.81%	66.84%	66.86%	66.88%	66.90%	66.92%	66.94%	66.96%	66.98%	67.00%
  0.00%	6.37%	12.48%	18.39%	24.18%	29.59%	34.14%	37.78%	40.73%	43.10%	44.99%	46.61%	47.98%	49.16%	50.16%	51.04%	51.84%	52.57%	53.23%	53.84%	54.41%	54.92%	55.40%	55.84%	56.26%	56.66%	57.03%	57.38%	57.72%	58.05%	58.36%	58.67%	58.96%	59.24%	59.51%	59.77%	60.02%	60.26%	60.49%	60.71%	60.92%	61.12%	61.32%	61.51%	61.69%	61.86%	62.03%	62.19%	62.35%	62.50%	62.65%	62.79%	62.93%	63.06%	63.20%	63.33%	63.45%	63.57%	63.69%	63.81%	63.92%	64.04%	64.15%	64.26%	64.36%	64.47%	64.56%	64.66%	64.76%	64.85%	64.94%	65.03%	65.12%	65.20%	65.29%	65.37%	65.45%	65.52%	65.60%	65.67%	65.74%	65.81%	65.87%	65.94%	66.00%	66.06%	66.13%	66.19%	66.25%	66.30%	66.36%	66.42%	66.48%	66.53%	66.59%	66.64%	66.70%	66.75%	66.80%	66.86%	66.91%	66.96%	67.01%	67.07%	67.11%	67.16%	67.21%	67.25%	67.30%	67.34%	67.39%	67.43%	67.47%	67.51%	67.55%	67.59%	67.63%	67.67%	67.71%	67.74%	67.78%	67.82%	67.85%	67.88%	67.92%	67.95%	67.98%	68.02%	68.05%	68.08%	68.11%	68.14%	68.16%	68.19%	68.22%	68.25%	68.27%	68.30%	68.32%	68.35%	68.38%	68.40%	68.42%	68.45%	68.47%	68.49%	68.51%	68.54%	68.56%	68.58%	68.61%	68.63%	68.65%	68.67%	68.69%	68.71%	68.73%	68.75%	68.78%	68.80%	68.82%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.73%	34.48%	38.28%	41.34%	43.90%	45.90%	47.59%	49.05%	50.29%	51.35%	52.26%	53.09%	53.85%	54.54%	55.17%	55.76%	56.30%	56.80%	57.26%	57.69%	58.10%	58.48%	58.85%	59.19%	59.52%	59.84%	60.15%	60.46%	60.75%	61.03%	61.29%	61.54%	61.78%	62.02%	62.25%	62.46%	62.68%	62.88%	63.07%	63.26%	63.44%	63.61%	63.77%	63.93%	64.09%	64.24%	64.39%	64.53%	64.67%	64.81%	64.94%	65.06%	65.19%	65.31%	65.42%	65.54%	65.65%	65.77%	65.88%	65.99%	66.09%	66.19%	66.29%	66.39%	66.49%	66.58%	66.67%	66.76%	66.85%	66.93%	67.02%	67.10%	67.18%	67.26%	67.33%	67.40%	67.47%	67.54%	67.61%	67.68%	67.74%	67.80%	67.86%	67.93%	67.99%	68.05%	68.10%	68.16%	68.22%	68.27%	68.33%	68.38%	68.44%	68.49%	68.54%	68.59%	68.64%	68.69%	68.75%	68.79%	68.84%	68.89%	68.94%	68.99%	69.03%	69.08%	69.12%	69.16%	69.21%	69.25%	69.29%	69.33%	69.37%	69.41%	69.45%	69.48%	69.52%	69.56%	69.59%	69.63%	69.66%	69.70%	69.73%	69.76%	69.79%	69.82%	69.85%	69.88%	69.91%	69.94%	69.97%	70.00%	70.02%	70.05%	70.08%	70.10%	70.13%	70.15%	70.18%	70.20%	70.22%	70.25%	70.27%	70.29%	70.32%	70.34%	70.36%	70.38%	70.40%	70.42%	70.44%	70.47%	70.49%	70.51%	70.53%	70.55%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.82%	34.75%	38.73%	41.93%	44.62%	46.77%	48.54%	50.08%	51.39%	52.51%	53.46%	54.32%	55.11%	55.83%	56.48%	57.09%	57.65%	58.18%	58.66%	59.11%	59.52%	59.91%	60.29%	60.65%	60.98%	61.30%	61.61%	61.91%	62.21%	62.49%	62.76%	63.02%	63.26%	63.49%	63.72%	63.94%	64.15%	64.36%	64.56%	64.76%	64.94%	65.12%	65.29%	65.45%	65.60%	65.75%	65.90%	66.05%	66.19%	66.32%	66.46%	66.58%	66.71%	66.83%	66.95%	67.07%	67.18%	67.29%	67.40%	67.51%	67.62%	67.72%	67.82%	67.92%	68.01%	68.11%	68.20%	68.29%	68.38%	68.46%	68.55%	68.63%	68.71%	68.79%	68.87%	68.95%	69.02%	69.09%	69.16%	69.23%	69.29%	69.36%	69.42%	69.49%	69.55%	69.61%	69.66%	69.72%	69.78%	69.84%	69.89%	69.95%	70.00%	70.05%	70.10%	70.15%	70.20%	70.25%	70.30%	70.35%	70.40%	70.45%	70.49%	70.54%	70.59%	70.63%	70.68%	70.72%	70.76%	70.81%	70.85%	70.89%	70.93%	70.98%	71.02%	71.05%	71.09%	71.13%	71.16%	71.20%	71.23%	71.27%	71.30%	71.34%	71.37%	71.40%	71.43%	71.46%	71.49%	71.52%	71.55%	71.58%	71.61%	71.63%	71.66%	71.69%	71.72%	71.74%	71.77%	71.79%	71.82%	71.84%	71.87%	71.89%	71.91%	71.93%	71.96%	71.98%	72.00%	72.02%	72.05%	72.07%	72.09%	72.11%	72.13%	72.15%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.88%	34.96%	39.13%	42.49%	45.29%	47.61%	49.46%	51.07%	52.44%	53.63%	54.64%	55.52%	56.33%	57.09%	57.76%	58.39%	58.97%	59.51%	60.02%	60.48%	60.91%	61.30%	61.67%	62.03%	62.37%	62.69%	62.99%	63.29%	63.59%	63.87%	64.14%	64.40%	64.65%	64.89%	65.12%	65.34%	65.55%	65.76%	65.96%	66.15%	66.34%	66.53%	66.70%	66.86%	67.02%	67.17%	67.31%	67.45%	67.60%	67.74%	67.87%	68.00%	68.12%	68.24%	68.36%	68.48%	68.59%	68.70%	68.81%	68.91%	69.02%	69.12%	69.22%	69.32%	69.41%	69.50%	69.59%	69.69%	69.78%	69.86%	69.95%	70.03%	70.11%	70.19%	70.27%	70.34%	70.42%	70.49%	70.56%	70.63%	70.70%	70.77%	70.83%	70.90%	70.96%	71.02%	71.08%	71.14%	71.20%	71.26%	71.31%	71.37%	71.42%	71.47%	71.53%	71.58%	71.63%	71.68%	71.73%	71.78%	71.83%	71.87%	71.92%	71.97%	72.01%	72.06%	72.10%	72.15%	72.19%	72.23%	72.28%	72.32%	72.36%	72.40%	72.45%	72.49%	72.53%	72.57%	72.61%	72.64%	72.68%	72.71%	72.75%	72.78%	72.82%	72.85%	72.88%	72.92%	72.95%	72.98%	73.01%	73.04%	73.07%	73.09%	73.12%	73.15%	73.17%	73.20%	73.23%	73.25%	73.28%	73.31%	73.33%	73.36%	73.38%	73.41%	73.43%	73.45%	73.48%	73.50%	73.52%	73.54%	73.56%	73.58%	73.61%	73.63%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.91%	35.15%	39.51%	43.02%	45.91%	48.38%	50.35%	52.02%	53.46%	54.72%	55.79%	56.69%	57.53%	58.29%	58.99%	59.62%	60.21%	60.77%	61.29%	61.77%	62.20%	62.60%	62.98%	63.32%	63.66%	63.99%	64.30%	64.60%	64.88%	65.17%	65.44%	65.71%	65.96%	66.20%	66.43%	66.64%	66.85%	67.05%	67.26%	67.45%	67.64%	67.82%	68.00%	68.17%	68.33%	68.48%	68.62%	68.76%	68.90%	69.04%	69.17%	69.31%	69.43%	69.55%	69.67%	69.79%	69.90%	70.01%	70.12%	70.23%	70.33%	70.43%	70.53%	70.63%	70.72%	70.82%	70.91%	71.00%	71.09%	71.18%	71.27%	71.35%	71.43%	71.51%	71.59%	71.67%	71.75%	71.82%	71.89%	71.97%	72.04%	72.11%	72.17%	72.24%	72.30%	72.37%	72.43%	72.49%	72.56%	72.61%	72.67%	72.73%	72.79%	72.84%	72.89%	72.95%	73.00%	73.05%	73.10%	73.15%	73.19%	73.24%	73.29%	73.34%	73.38%	73.43%	73.47%	73.51%	73.56%	73.60%	73.65%	73.69%	73.73%	73.77%	73.81%	73.85%	73.89%	73.93%	73.97%	74.01%	74.05%	74.09%	74.13%	74.16%	74.20%	74.23%	74.27%	74.30%	74.33%	74.36%	74.40%	74.43%	74.46%	74.49%	74.52%	74.54%	74.57%	74.60%	74.62%	74.65%	74.67%	74.70%	74.73%	74.75%	74.78%	74.80%	74.83%	74.86%	74.88%	74.90%	74.93%	74.95%	74.97%	75.00%	75.02%	75.04%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.30%	39.83%	43.51%	46.49%	49.08%	51.19%	52.94%	54.44%	55.72%	56.85%	57.79%	58.63%	59.41%	60.12%	60.78%	61.37%	61.94%	62.47%	62.96%	63.41%	63.81%	64.18%	64.52%	64.86%	65.18%	65.50%	65.80%	66.08%	66.36%	66.63%	66.89%	67.14%	67.39%	67.62%	67.84%	68.05%	68.25%	68.45%	68.65%	68.84%	69.03%	69.21%	69.38%	69.55%	69.71%	69.86%	70.00%	70.14%	70.28%	70.41%	70.54%	70.67%	70.79%	70.91%	71.03%	71.15%	71.26%	71.37%	71.48%	71.59%	71.69%	71.80%	71.89%	71.99%	72.09%	72.18%	72.27%	72.36%	72.45%	72.54%	72.62%	72.71%	72.79%	72.87%	72.95%	73.03%	73.11%	73.18%	73.25%	73.33%	73.40%	73.47%	73.54%	73.60%	73.67%	73.73%	73.79%	73.85%	73.91%	73.97%	74.03%	74.09%	74.14%	74.20%	74.25%	74.31%	74.36%	74.41%	74.46%	74.51%	74.55%	74.60%	74.65%	74.69%	74.74%	74.79%	74.83%	74.87%	74.92%	74.96%	75.01%	75.05%	75.09%	75.13%	75.17%	75.21%	75.25%	75.29%	75.33%	75.37%	75.41%	75.45%	75.48%	75.52%	75.56%	75.59%	75.63%	75.66%	75.70%	75.73%	75.76%	75.80%	75.83%	75.86%	75.89%	75.92%	75.95%	75.97%	76.00%	76.03%	76.05%	76.08%	76.10%	76.13%	76.15%	76.18%	76.21%	76.23%	76.26%	76.28%	76.31%	76.33%	76.35%	76.38%	76.40%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.42%	40.09%	43.94%	47.05%	49.74%	51.97%	53.79%	55.33%	56.64%	57.80%	58.79%	59.65%	60.43%	61.15%	61.82%	62.43%	63.00%	63.54%	64.05%	64.51%	64.92%	65.28%	65.63%	65.97%	66.28%	66.60%	66.90%	67.19%	67.47%	67.74%	68.00%	68.26%	68.51%	68.74%	68.97%	69.19%	69.40%	69.60%	69.80%	69.99%	70.18%	70.36%	70.53%	70.70%	70.87%	71.03%	71.18%	71.33%	71.46%	71.60%	71.73%	71.85%	71.98%	72.10%	72.23%	72.35%	72.47%	72.58%	72.69%	72.80%	72.91%	73.01%	73.11%	73.21%	73.31%	73.40%	73.49%	73.59%	73.68%	73.76%	73.85%	73.93%	74.01%	74.09%	74.17%	74.25%	74.33%	74.40%	74.48%	74.55%	74.62%	74.69%	74.76%	74.83%	74.90%	74.97%	75.03%	75.09%	75.16%	75.22%	75.28%	75.34%	75.40%	75.45%	75.51%	75.56%	75.62%	75.67%	75.72%	75.77%	75.82%	75.87%	75.92%	75.96%	76.01%	76.06%	76.10%	76.15%	76.19%	76.24%	76.28%	76.32%	76.37%	76.41%	76.45%	76.49%	76.53%	76.57%	76.61%	76.65%	76.68%	76.72%	76.76%	76.80%	76.84%	76.88%	76.91%	76.95%	76.99%	77.02%	77.05%	77.09%	77.12%	77.15%	77.19%	77.22%	77.25%	77.28%	77.31%	77.34%	77.37%	77.39%	77.42%	77.45%	77.47%	77.50%	77.52%	77.55%	77.57%	77.60%	77.62%	77.65%	77.67%	77.69%	77.72%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.49%	40.32%	44.33%	47.56%	50.31%	52.66%	54.57%	56.14%	57.48%	58.64%	59.69%	60.57%	61.37%	62.11%	62.78%	63.41%	63.99%	64.53%	65.05%	65.53%	65.94%	66.33%	66.68%	67.01%	67.33%	67.64%	67.94%	68.24%	68.52%	68.80%	69.06%	69.31%	69.56%	69.81%	70.04%	70.26%	70.48%	70.68%	70.88%	71.07%	71.26%	71.45%	71.63%	71.80%	71.97%	72.14%	72.30%	72.45%	72.60%	72.74%	72.87%	73.00%	73.12%	73.25%	73.37%	73.49%	73.61%	73.73%	73.84%	73.95%	74.06%	74.16%	74.26%	74.36%	74.46%	74.56%	74.65%	74.75%	74.84%	74.92%	75.01%	75.09%	75.17%	75.25%	75.33%	75.41%	75.49%	75.57%	75.64%	75.72%	75.79%	75.86%	75.94%	76.01%	76.08%	76.14%	76.21%	76.28%	76.34%	76.40%	76.46%	76.53%	76.59%	76.64%	76.70%	76.76%	76.82%	76.87%	76.92%	76.97%	77.03%	77.08%	77.13%	77.17%	77.22%	77.27%	77.32%	77.36%	77.41%	77.45%	77.50%	77.54%	77.58%	77.63%	77.67%	77.71%	77.76%	77.80%	77.83%	77.87%	77.91%	77.95%	77.99%	78.03%	78.06%	78.10%	78.14%	78.18%	78.21%	78.25%	78.29%	78.32%	78.36%	78.39%	78.42%	78.46%	78.49%	78.52%	78.55%	78.58%	78.61%	78.64%	78.67%	78.70%	78.73%	78.76%	78.78%	78.81%	78.83%	78.86%	78.88%	78.91%	78.93%	78.96%	78.98%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.55%	40.52%	44.68%	48.04%	50.82%	53.28%	55.26%	56.86%	58.23%	59.41%	60.49%	61.44%	62.25%	62.99%	63.68%	64.32%	64.92%	65.48%	66.00%	66.48%	66.90%	67.29%	67.66%	67.99%	68.31%	68.63%	68.93%	69.22%	69.51%	69.79%	70.06%	70.32%	70.57%	70.82%	71.05%	71.28%	71.50%	71.72%	71.92%	72.12%	72.30%	72.49%	72.67%	72.85%	73.02%	73.19%	73.35%	73.51%	73.66%	73.80%	73.94%	74.07%	74.20%	74.32%	74.44%	74.56%	74.68%	74.80%	74.92%	75.03%	75.14%	75.25%	75.35%	75.46%	75.56%	75.66%	75.75%	75.85%	75.94%	76.03%	76.12%	76.20%	76.29%	76.37%	76.45%	76.52%	76.60%	76.68%	76.76%	76.83%	76.91%	76.98%	77.06%	77.13%	77.20%	77.27%	77.34%	77.41%	77.47%	77.54%	77.60%	77.67%	77.73%	77.79%	77.85%	77.91%	77.96%	78.02%	78.08%	78.13%	78.19%	78.24%	78.29%	78.34%	78.39%	78.44%	78.49%	78.53%	78.58%	78.63%	78.67%	78.72%	78.76%	78.80%	78.85%	78.89%	78.93%	78.98%	79.02%	79.06%	79.10%	79.14%	79.18%	79.21%	79.25%	79.29%	79.33%	79.36%	79.40%	79.44%	79.47%	79.51%	79.55%	79.58%	79.62%	79.65%	79.68%	79.72%	79.75%	79.78%	79.82%	79.85%	79.88%	79.91%	79.93%	79.96%	79.99%	80.02%	80.05%	80.08%	80.10%	80.13%	80.15%	80.18%	80.20%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.58%	40.68%	44.98%	48.46%	51.28%	53.81%	55.86%	57.52%	58.92%	60.13%	61.23%	62.23%	63.07%	63.83%	64.53%	65.19%	65.81%	66.37%	66.89%	67.37%	67.80%	68.21%	68.58%	68.93%	69.26%	69.57%	69.88%	70.17%	70.46%	70.74%	71.01%	71.28%	71.53%	71.78%	72.02%	72.25%	72.47%	72.69%	72.90%	73.10%	73.29%	73.47%	73.65%	73.83%	74.00%	74.18%	74.34%	74.51%	74.66%	74.82%	74.96%	75.10%	75.23%	75.36%	75.49%	75.61%	75.73%	75.85%	75.96%	76.08%	76.19%	76.30%	76.41%	76.51%	76.62%	76.72%	76.82%	76.92%	77.01%	77.11%	77.20%	77.28%	77.37%	77.45%	77.53%	77.61%	77.69%	77.77%	77.84%	77.92%	77.99%	78.07%	78.14%	78.22%	78.29%	78.36%	78.43%	78.50%	78.57%	78.64%	78.71%	78.77%	78.84%	78.90%	78.96%	79.02%	79.08%	79.14%	79.19%	79.25%	79.31%	79.36%	79.41%	79.46%	79.51%	79.56%	79.61%	79.66%	79.71%	79.76%	79.80%	79.85%	79.90%	79.94%	79.99%	80.03%	80.07%	80.11%	80.15%	80.20%	80.24%	80.28%	80.32%	80.36%	80.40%	80.44%	80.47%	80.51%	80.54%	80.58%	80.62%	80.65%	80.69%	80.73%	80.76%	80.80%	80.83%	80.87%	80.90%	80.93%	80.97%	81.00%	81.03%	81.06%	81.09%	81.12%	81.15%	81.18%	81.21%	81.24%	81.27%	81.29%	81.32%	81.35%	81.38%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.59%	40.81%	45.20%	48.80%	51.70%	54.28%	56.40%	58.13%	59.56%	60.80%	61.91%	62.95%	63.84%	64.61%	65.34%	66.02%	66.64%	67.22%	67.73%	68.21%	68.64%	69.06%	69.45%	69.81%	70.15%	70.46%	70.77%	71.07%	71.36%	71.64%	71.91%	72.18%	72.45%	72.69%	72.93%	73.17%	73.40%	73.61%	73.83%	74.04%	74.23%	74.42%	74.61%	74.78%	74.96%	75.13%	75.30%	75.47%	75.63%	75.78%	75.94%	76.08%	76.22%	76.35%	76.48%	76.60%	76.73%	76.85%	76.96%	77.08%	77.19%	77.30%	77.41%	77.51%	77.62%	77.72%	77.82%	77.92%	78.02%	78.11%	78.21%	78.30%	78.38%	78.47%	78.55%	78.63%	78.71%	78.79%	78.86%	78.94%	79.01%	79.09%	79.16%	79.24%	79.31%	79.38%	79.45%	79.53%	79.60%	79.67%	79.73%	79.80%	79.87%	79.93%	80.00%	80.06%	80.12%	80.18%	80.24%	80.30%	80.35%	80.41%	80.46%	80.52%	80.57%	80.62%	80.67%	80.72%	80.77%	80.82%	80.87%	80.92%	80.97%	81.01%	81.06%	81.10%	81.15%	81.19%	81.23%	81.28%	81.32%	81.36%	81.40%	81.44%	81.48%	81.52%	81.56%	81.60%	81.64%	81.67%	81.71%	81.75%	81.78%	81.82%	81.85%	81.89%	81.93%	81.96%	82.00%	82.03%	82.06%	82.10%	82.13%	82.16%	82.20%	82.23%	82.26%	82.29%	82.32%	82.35%	82.38%	82.41%	82.43%	82.46%	82.49%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	40.92%	45.39%	49.10%	52.08%	54.68%	56.89%	58.67%	60.14%	61.42%	62.56%	63.60%	64.54%	65.34%	66.09%	66.79%	67.42%	68.00%	68.52%	68.99%	69.43%	69.85%	70.26%	70.63%	70.98%	71.31%	71.61%	71.91%	72.21%	72.49%	72.77%	73.04%	73.30%	73.55%	73.80%	74.03%	74.26%	74.48%	74.70%	74.91%	75.11%	75.31%	75.49%	75.67%	75.85%	76.02%	76.19%	76.35%	76.52%	76.68%	76.84%	76.99%	77.13%	77.27%	77.40%	77.53%	77.65%	77.78%	77.90%	78.01%	78.13%	78.24%	78.35%	78.45%	78.56%	78.66%	78.77%	78.87%	78.97%	79.07%	79.17%	79.26%	79.35%	79.44%	79.53%	79.61%	79.69%	79.77%	79.85%	79.92%	80.00%	80.07%	80.15%	80.22%	80.29%	80.36%	80.44%	80.51%	80.58%	80.65%	80.72%	80.79%	80.86%	80.93%	80.99%	81.06%	81.12%	81.18%	81.24%	81.30%	81.36%	81.42%	81.47%	81.53%	81.58%	81.64%	81.69%	81.74%	81.80%	81.84%	81.89%	81.94%	81.99%	82.04%	82.08%	82.13%	82.18%	82.22%	82.27%	82.31%	82.36%	82.40%	82.44%	82.48%	82.52%	82.56%	82.60%	82.64%	82.68%	82.72%	82.76%	82.80%	82.84%	82.87%	82.91%	82.94%	82.98%	83.01%	83.05%	83.08%	83.12%	83.15%	83.19%	83.22%	83.25%	83.29%	83.32%	83.35%	83.38%	83.41%	83.44%	83.47%	83.50%	83.53%	83.56%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.00%	45.56%	49.38%	52.45%	55.07%	57.35%	59.17%	60.68%	61.99%	63.16%	64.21%	65.18%	66.03%	66.79%	67.50%	68.15%	68.72%	69.25%	69.73%	70.17%	70.60%	71.01%	71.39%	71.76%	72.09%	72.41%	72.71%	73.00%	73.29%	73.56%	73.83%	74.10%	74.35%	74.60%	74.84%	75.07%	75.29%	75.51%	75.73%	75.94%	76.14%	76.33%	76.52%	76.70%	76.87%	77.04%	77.21%	77.37%	77.53%	77.70%	77.85%	78.00%	78.15%	78.29%	78.41%	78.54%	78.66%	78.78%	78.90%	79.02%	79.13%	79.24%	79.35%	79.46%	79.56%	79.67%	79.77%	79.87%	79.97%	80.07%	80.16%	80.26%	80.35%	80.44%	80.53%	80.61%	80.70%	80.78%	80.85%	80.93%	81.00%	81.08%	81.15%	81.22%	81.29%	81.37%	81.44%	81.51%	81.58%	81.65%	81.72%	81.79%	81.86%	81.93%	81.99%	82.06%	82.13%	82.19%	82.25%	82.31%	82.37%	82.43%	82.48%	82.54%	82.60%	82.65%	82.71%	82.76%	82.81%	82.87%	82.92%	82.97%	83.01%	83.06%	83.11%	83.16%	83.20%	83.25%	83.30%	83.34%	83.39%	83.43%	83.47%	83.51%	83.56%	83.60%	83.64%	83.68%	83.72%	83.76%	83.80%	83.84%	83.87%	83.91%	83.95%	83.99%	84.02%	84.06%	84.09%	84.13%	84.16%	84.20%	84.23%	84.26%	84.30%	84.33%	84.36%	84.40%	84.43%	84.46%	84.49%	84.52%	84.55%	84.58%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.05%	45.73%	49.62%	52.79%	55.43%	57.76%	59.64%	61.18%	62.52%	63.72%	64.78%	65.76%	66.65%	67.44%	68.16%	68.81%	69.39%	69.92%	70.40%	70.85%	71.29%	71.70%	72.10%	72.47%	72.82%	73.14%	73.45%	73.74%	74.03%	74.31%	74.58%	74.84%	75.10%	75.36%	75.60%	75.84%	76.06%	76.28%	76.50%	76.71%	76.91%	77.11%	77.30%	77.49%	77.67%	77.84%	78.01%	78.17%	78.33%	78.49%	78.65%	78.80%	78.96%	79.10%	79.24%	79.37%	79.49%	79.61%	79.73%	79.85%	79.97%	80.08%	80.19%	80.30%	80.40%	80.51%	80.61%	80.71%	80.81%	80.91%	81.01%	81.11%	81.20%	81.30%	81.39%	81.48%	81.56%	81.65%	81.73%	81.81%	81.88%	81.96%	82.03%	82.11%	82.18%	82.25%	82.32%	82.39%	82.46%	82.53%	82.60%	82.67%	82.74%	82.81%	82.88%	82.95%	83.02%	83.08%	83.15%	83.21%	83.27%	83.33%	83.39%	83.45%	83.50%	83.56%	83.62%	83.67%	83.73%	83.78%	83.84%	83.89%	83.94%	83.99%	84.04%	84.09%	84.13%	84.18%	84.23%	84.27%	84.32%	84.36%	84.41%	84.45%	84.50%	84.54%	84.58%	84.62%	84.66%	84.70%	84.74%	84.78%	84.82%	84.86%	84.90%	84.94%	84.97%	85.01%	85.05%	85.08%	85.12%	85.15%	85.19%	85.22%	85.26%	85.29%	85.32%	85.36%	85.39%	85.42%	85.45%	85.49%	85.52%	85.55%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.09%	45.87%	49.82%	53.10%	55.78%	58.12%	60.07%	61.65%	63.01%	64.24%	65.31%	66.29%	67.21%	68.03%	68.75%	69.40%	69.99%	70.53%	71.02%	71.48%	71.92%	72.34%	72.74%	73.12%	73.47%	73.81%	74.12%	74.42%	74.71%	74.99%	75.27%	75.54%	75.79%	76.05%	76.30%	76.54%	76.77%	76.99%	77.21%	77.42%	77.63%	77.83%	78.03%	78.22%	78.40%	78.58%	78.75%	78.92%	79.08%	79.24%	79.39%	79.55%	79.71%	79.86%	80.00%	80.14%	80.27%	80.39%	80.51%	80.63%	80.75%	80.86%	80.98%	81.09%	81.20%	81.30%	81.41%	81.51%	81.61%	81.71%	81.81%	81.91%	82.01%	82.10%	82.19%	82.29%	82.38%	82.47%	82.55%	82.64%	82.72%	82.80%	82.87%	82.94%	83.02%	83.09%	83.16%	83.23%	83.30%	83.37%	83.44%	83.51%	83.58%	83.65%	83.72%	83.79%	83.85%	83.92%	83.99%	84.05%	84.12%	84.18%	84.24%	84.30%	84.36%	84.42%	84.47%	84.53%	84.59%	84.64%	84.70%	84.75%	84.80%	84.86%	84.91%	84.96%	85.01%	85.06%	85.10%	85.15%	85.20%	85.24%	85.29%	85.33%	85.38%	85.42%	85.47%	85.51%	85.55%	85.60%	85.64%	85.68%	85.72%	85.76%	85.80%	85.83%	85.87%	85.91%	85.95%	85.98%	86.02%	86.06%	86.09%	86.13%	86.16%	86.20%	86.23%	86.27%	86.30%	86.33%	86.37%	86.40%	86.43%	86.46%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.12%	45.98%	49.98%	53.38%	56.11%	58.44%	60.45%	62.06%	63.44%	64.69%	65.79%	66.77%	67.70%	68.53%	69.26%	69.92%	70.52%	71.07%	71.57%	72.04%	72.49%	72.91%	73.32%	73.70%	74.06%	74.41%	74.73%	75.04%	75.34%	75.62%	75.90%	76.17%	76.43%	76.69%	76.94%	77.18%	77.41%	77.64%	77.86%	78.08%	78.29%	78.49%	78.69%	78.89%	79.08%	79.27%	79.44%	79.61%	79.78%	79.94%	80.10%	80.25%	80.41%	80.56%	80.72%	80.86%	81.00%	81.13%	81.25%	81.37%	81.49%	81.61%	81.72%	81.84%	81.95%	82.06%	82.17%	82.27%	82.37%	82.47%	82.57%	82.67%	82.77%	82.87%	82.96%	83.06%	83.15%	83.24%	83.33%	83.42%	83.50%	83.59%	83.67%	83.74%	83.82%	83.89%	83.96%	84.03%	84.10%	84.17%	84.24%	84.31%	84.38%	84.45%	84.51%	84.58%	84.65%	84.72%	84.78%	84.85%	84.91%	84.98%	85.04%	85.11%	85.17%	85.23%	85.29%	85.34%	85.40%	85.46%	85.51%	85.57%	85.62%	85.68%	85.73%	85.78%	85.84%	85.89%	85.94%	85.98%	86.03%	86.08%	86.12%	86.17%	86.22%	86.26%	86.31%	86.35%	86.40%	86.44%	86.48%	86.53%	86.57%	86.61%	86.65%	86.69%	86.73%	86.77%	86.81%	86.85%	86.88%	86.92%	86.96%	86.99%	87.03%	87.06%	87.10%	87.14%	87.17%	87.21%	87.24%	87.27%	87.31%	87.34%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.14%	46.08%	50.14%	53.61%	56.40%	58.73%	60.78%	62.41%	63.81%	65.08%	66.21%	67.20%	68.12%	68.96%	69.71%	70.38%	71.00%	71.56%	72.07%	72.56%	73.01%	73.44%	73.86%	74.25%	74.61%	74.96%	75.30%	75.62%	75.92%	76.21%	76.49%	76.76%	77.03%	77.29%	77.53%	77.78%	78.02%	78.25%	78.48%	78.70%	78.91%	79.12%	79.32%	79.52%	79.71%	79.91%	80.09%	80.27%	80.44%	80.60%	80.76%	80.92%	81.07%	81.22%	81.37%	81.52%	81.67%	81.81%	81.94%	82.06%	82.18%	82.30%	82.41%	82.52%	82.64%	82.75%	82.86%	82.97%	83.07%	83.18%	83.27%	83.37%	83.47%	83.56%	83.66%	83.76%	83.85%	83.94%	84.03%	84.12%	84.21%	84.30%	84.38%	84.47%	84.54%	84.62%	84.69%	84.76%	84.84%	84.91%	84.98%	85.05%	85.11%	85.18%	85.25%	85.32%	85.38%	85.45%	85.52%	85.58%	85.65%	85.71%	85.77%	85.84%	85.90%	85.96%	86.03%	86.09%	86.15%	86.20%	86.26%	86.32%	86.37%	86.43%	86.48%	86.54%	86.59%	86.64%	86.69%	86.75%	86.80%	86.85%	86.90%	86.94%	86.99%	87.03%	87.08%	87.13%	87.17%	87.22%	87.26%	87.30%	87.35%	87.39%	87.43%	87.48%	87.52%	87.56%	87.60%	87.64%	87.68%	87.71%	87.75%	87.79%	87.83%	87.86%	87.90%	87.93%	87.97%	88.01%	88.04%	88.08%	88.11%	88.15%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.17%	46.16%	50.28%	53.82%	56.65%	58.98%	61.06%	62.71%	64.14%	65.42%	66.58%	67.60%	68.50%	69.33%	70.10%	70.79%	71.41%	71.99%	72.52%	73.01%	73.48%	73.92%	74.34%	74.73%	75.10%	75.45%	75.79%	76.12%	76.43%	76.73%	77.01%	77.29%	77.56%	77.82%	78.07%	78.31%	78.55%	78.79%	79.01%	79.24%	79.46%	79.67%	79.87%	80.07%	80.27%	80.46%	80.65%	80.84%	81.01%	81.17%	81.34%	81.49%	81.64%	81.80%	81.95%	82.09%	82.24%	82.38%	82.53%	82.66%	82.78%	82.90%	83.02%	83.13%	83.24%	83.35%	83.46%	83.57%	83.68%	83.79%	83.89%	83.99%	84.09%	84.19%	84.28%	84.38%	84.47%	84.57%	84.66%	84.75%	84.84%	84.93%	85.02%	85.11%	85.19%	85.27%	85.35%	85.43%	85.50%	85.57%	85.64%	85.71%	85.78%	85.85%	85.92%	85.98%	86.05%	86.12%	86.18%	86.25%	86.31%	86.38%	86.44%	86.50%	86.57%	86.63%	86.69%	86.75%	86.82%	86.88%	86.94%	87.00%	87.05%	87.11%	87.17%	87.22%	87.28%	87.33%	87.38%	87.44%	87.49%	87.54%	87.59%	87.64%	87.69%	87.74%	87.79%	87.83%	87.88%	87.93%	87.97%	88.02%	88.06%	88.10%	88.15%	88.19%	88.23%	88.28%	88.32%	88.36%	88.40%	88.44%	88.48%	88.52%	88.56%	88.60%	88.63%	88.67%	88.71%	88.74%	88.78%	88.81%	88.85%	88.88%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.18%	46.23%	50.42%	54.00%	56.85%	59.18%	61.27%	62.96%	64.42%	65.71%	66.89%	67.93%	68.82%	69.64%	70.42%	71.14%	71.77%	72.38%	72.92%	73.42%	73.90%	74.35%	74.76%	75.15%	75.52%	75.88%	76.22%	76.55%	76.88%	77.19%	77.48%	77.76%	78.03%	78.30%	78.55%	78.78%	79.02%	79.25%	79.48%	79.71%	79.93%	80.14%	80.35%	80.55%	80.75%	80.94%	81.14%	81.32%	81.51%	81.68%	81.85%	82.01%	82.16%	82.32%	82.46%	82.61%	82.76%	82.90%	83.05%	83.19%	83.33%	83.45%	83.57%	83.69%	83.80%	83.91%	84.02%	84.13%	84.24%	84.35%	84.46%	84.57%	84.67%	84.77%	84.86%	84.96%	85.05%	85.15%	85.24%	85.34%	85.43%	85.52%	85.61%	85.70%	85.79%	85.88%	85.96%	86.04%	86.12%	86.19%	86.26%	86.34%	86.41%	86.48%	86.55%	86.61%	86.68%	86.75%	86.81%	86.88%	86.94%	87.00%	87.07%	87.13%	87.19%	87.26%	87.32%	87.38%	87.44%	87.51%	87.57%	87.63%	87.69%	87.75%	87.81%	87.86%	87.92%	87.97%	88.03%	88.08%	88.14%	88.19%	88.24%	88.29%	88.35%	88.40%	88.45%	88.50%	88.55%	88.59%	88.64%	88.68%	88.73%	88.77%	88.82%	88.86%	88.91%	88.95%	88.99%	89.04%	89.08%	89.12%	89.16%	89.20%	89.24%	89.28%	89.32%	89.36%	89.40%	89.43%	89.47%	89.51%	89.54%	89.58%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.20%	46.28%	50.56%	54.15%	57.01%	59.37%	61.45%	63.19%	64.66%	65.97%	67.16%	68.21%	69.11%	69.92%	70.70%	71.44%	72.10%	72.71%	73.28%	73.80%	74.28%	74.73%	75.14%	75.53%	75.91%	76.28%	76.62%	76.96%	77.28%	77.60%	77.90%	78.19%	78.46%	78.71%	78.96%	79.20%	79.43%	79.66%	79.89%	80.12%	80.35%	80.57%	80.78%	80.98%	81.18%	81.38%	81.57%	81.76%	81.95%	82.13%	82.30%	82.47%	82.63%	82.79%	82.94%	83.09%	83.23%	83.38%	83.52%	83.66%	83.81%	83.94%	84.07%	84.19%	84.30%	84.42%	84.53%	84.64%	84.75%	84.86%	84.97%	85.08%	85.18%	85.29%	85.39%	85.49%	85.58%	85.67%	85.77%	85.86%	85.95%	86.05%	86.14%	86.23%	86.32%	86.41%	86.50%	86.58%	86.67%	86.75%	86.82%	86.89%	86.96%	87.03%	87.10%	87.17%	87.24%	87.31%	87.37%	87.44%	87.50%	87.56%	87.62%	87.69%	87.75%	87.81%	87.87%	87.94%	88.00%	88.06%	88.12%	88.18%	88.25%	88.31%	88.37%	88.43%	88.48%	88.54%	88.59%	88.65%	88.70%	88.76%	88.81%	88.86%	88.92%	88.97%	89.02%	89.07%	89.12%	89.17%	89.22%	89.27%	89.31%	89.36%	89.40%	89.45%	89.49%	89.54%	89.58%	89.63%	89.67%	89.71%	89.76%	89.80%	89.84%	89.88%	89.92%	89.96%	90.00%	90.04%	90.08%	90.11%	90.15%	90.19%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.21%	46.33%	50.67%	54.28%	57.16%	59.55%	61.63%	63.41%	64.87%	66.20%	67.40%	68.46%	69.37%	70.18%	70.95%	71.69%	72.38%	73.01%	73.59%	74.13%	74.63%	75.08%	75.49%	75.88%	76.26%	76.63%	76.99%	77.32%	77.64%	77.96%	78.26%	78.56%	78.83%	79.08%	79.32%	79.57%	79.81%	80.04%	80.27%	80.50%	80.73%	80.95%	81.17%	81.38%	81.58%	81.77%	81.96%	82.15%	82.34%	82.52%	82.70%	82.88%	83.04%	83.20%	83.36%	83.51%	83.65%	83.80%	83.94%	84.08%	84.22%	84.36%	84.49%	84.62%	84.74%	84.86%	84.97%	85.08%	85.19%	85.30%	85.41%	85.52%	85.62%	85.73%	85.84%	85.94%	86.04%	86.13%	86.23%	86.32%	86.41%	86.51%	86.60%	86.69%	86.78%	86.87%	86.96%	87.05%	87.13%	87.22%	87.30%	87.37%	87.45%	87.52%	87.59%	87.66%	87.73%	87.80%	87.87%	87.93%	87.99%	88.06%	88.12%	88.18%	88.24%	88.30%	88.37%	88.43%	88.49%	88.55%	88.61%	88.68%	88.74%	88.80%	88.86%	88.92%	88.98%	89.04%	89.09%	89.15%	89.21%	89.26%	89.32%	89.37%	89.42%	89.48%	89.53%	89.58%	89.63%	89.68%	89.73%	89.78%	89.83%	89.88%	89.93%	89.97%	90.02%	90.06%	90.11%	90.15%	90.20%	90.24%	90.28%	90.33%	90.37%	90.41%	90.46%	90.50%	90.54%	90.58%	90.62%	90.66%	90.70%	90.74%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.22%	46.37%	50.78%	54.41%	57.30%	59.72%	61.79%	63.61%	65.08%	66.42%	67.62%	68.68%	69.59%	70.40%	71.17%	71.91%	72.62%	73.27%	73.86%	74.42%	74.93%	75.38%	75.79%	76.19%	76.58%	76.95%	77.30%	77.64%	77.96%	78.27%	78.58%	78.87%	79.15%	79.40%	79.65%	79.89%	80.13%	80.37%	80.60%	80.83%	81.06%	81.29%	81.51%	81.71%	81.91%	82.11%	82.30%	82.49%	82.68%	82.86%	83.04%	83.23%	83.40%	83.56%	83.72%	83.87%	84.02%	84.16%	84.30%	84.44%	84.58%	84.72%	84.85%	84.99%	85.13%	85.25%	85.37%	85.48%	85.59%	85.70%	85.81%	85.91%	86.02%	86.13%	86.23%	86.34%	86.44%	86.55%	86.64%	86.74%	86.83%	86.92%	87.02%	87.11%	87.20%	87.29%	87.38%	87.47%	87.56%	87.64%	87.72%	87.80%	87.88%	87.96%	88.03%	88.11%	88.18%	88.25%	88.31%	88.38%	88.45%	88.51%	88.57%	88.63%	88.70%	88.76%	88.82%	88.88%	88.94%	89.01%	89.07%	89.13%	89.19%	89.25%	89.31%	89.37%	89.43%	89.49%	89.55%	89.61%	89.67%	89.72%	89.78%	89.83%	89.89%	89.94%	89.99%	90.05%	90.10%	90.15%	90.20%	90.25%	90.30%	90.35%	90.40%	90.44%	90.49%	90.54%	90.59%	90.63%	90.68%	90.72%	90.76%	90.81%	90.85%	90.90%	90.94%	90.98%	91.02%	91.07%	91.11%	91.15%	91.19%	91.23%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.22%	46.40%	50.87%	54.54%	57.43%	59.88%	61.95%	63.78%	65.28%	66.61%	67.80%	68.86%	69.77%	70.59%	71.36%	72.10%	72.82%	73.50%	74.11%	74.68%	75.20%	75.65%	76.06%	76.46%	76.85%	77.22%	77.58%	77.92%	78.25%	78.55%	78.85%	79.15%	79.42%	79.69%	79.94%	80.19%	80.43%	80.67%	80.90%	81.13%	81.36%	81.59%	81.81%	82.01%	82.21%	82.42%	82.61%	82.80%	82.99%	83.18%	83.36%	83.54%	83.71%	83.88%	84.04%	84.20%	84.35%	84.50%	84.64%	84.77%	84.91%	85.05%	85.18%	85.32%	85.45%	85.59%	85.72%	85.84%	85.95%	86.06%	86.17%	86.28%	86.39%	86.49%	86.60%	86.71%	86.81%	86.92%	87.02%	87.12%	87.21%	87.31%	87.40%	87.49%	87.58%	87.68%	87.77%	87.86%	87.94%	88.03%	88.11%	88.19%	88.27%	88.35%	88.43%	88.51%	88.59%	88.66%	88.72%	88.79%	88.86%	88.92%	88.99%	89.05%	89.12%	89.18%	89.24%	89.30%	89.36%	89.42%	89.48%	89.54%	89.61%	89.67%	89.73%	89.79%	89.84%	89.90%	89.96%	90.02%	90.08%	90.13%	90.19%	90.25%	90.30%	90.35%	90.41%	90.46%	90.51%	90.56%	90.61%	90.66%	90.72%	90.77%	90.82%	90.86%	90.91%	90.96%	91.01%	91.06%	91.11%	91.15%	91.20%	91.24%	91.29%	91.33%	91.37%	91.42%	91.46%	91.50%	91.55%	91.59%	91.63%	91.67%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.23%	46.43%	50.96%	54.65%	57.56%	60.03%	62.11%	63.95%	65.46%	66.78%	67.97%	69.02%	69.94%	70.77%	71.55%	72.28%	73.00%	73.70%	74.33%	74.91%	75.44%	75.90%	76.32%	76.72%	77.12%	77.49%	77.85%	78.19%	78.52%	78.82%	79.12%	79.40%	79.69%	79.95%	80.21%	80.46%	80.71%	80.95%	81.19%	81.42%	81.65%	81.87%	82.08%	82.30%	82.50%	82.70%	82.90%	83.10%	83.29%	83.47%	83.65%	83.83%	84.01%	84.18%	84.35%	84.50%	84.66%	84.80%	84.95%	85.09%	85.22%	85.36%	85.50%	85.63%	85.77%	85.90%	86.04%	86.17%	86.29%	86.41%	86.52%	86.62%	86.73%	86.84%	86.94%	87.05%	87.15%	87.26%	87.36%	87.46%	87.56%	87.66%	87.75%	87.85%	87.94%	88.03%	88.12%	88.21%	88.29%	88.38%	88.46%	88.54%	88.63%	88.71%	88.79%	88.87%	88.95%	89.02%	89.09%	89.16%	89.23%	89.29%	89.36%	89.43%	89.49%	89.55%	89.62%	89.68%	89.74%	89.80%	89.86%	89.92%	89.98%	90.04%	90.10%	90.16%	90.22%	90.28%	90.33%	90.39%	90.45%	90.50%	90.56%	90.62%	90.67%	90.72%	90.78%	90.83%	90.88%	90.93%	90.99%	91.04%	91.09%	91.14%	91.19%	91.24%	91.29%	91.34%	91.39%	91.44%	91.49%	91.53%	91.58%	91.63%	91.68%	91.72%	91.77%	91.81%	91.85%	91.90%	91.94%	91.98%	92.02%	92.07%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.23%	46.45%	51.03%	54.77%	57.69%	60.18%	62.26%	64.11%	65.62%	66.93%	68.12%	69.17%	70.10%	70.93%	71.72%	72.46%	73.18%	73.88%	74.54%	75.12%	75.66%	76.14%	76.57%	76.97%	77.37%	77.75%	78.10%	78.44%	78.76%	79.07%	79.36%	79.65%	79.93%	80.20%	80.47%	80.72%	80.97%	81.22%	81.46%	81.69%	81.92%	82.13%	82.35%	82.56%	82.76%	82.97%	83.17%	83.37%	83.56%	83.74%	83.92%	84.10%	84.28%	84.45%	84.62%	84.78%	84.93%	85.08%	85.23%	85.37%	85.51%	85.65%	85.79%	85.92%	86.06%	86.19%	86.33%	86.46%	86.58%	86.70%	86.82%	86.93%	87.04%	87.14%	87.25%	87.35%	87.45%	87.55%	87.65%	87.76%	87.86%	87.96%	88.06%	88.15%	88.25%	88.34%	88.43%	88.51%	88.60%	88.68%	88.76%	88.85%	88.93%	89.02%	89.10%	89.18%	89.26%	89.34%	89.41%	89.49%	89.56%	89.63%	89.69%	89.76%	89.82%	89.89%	89.95%	90.02%	90.08%	90.14%	90.20%	90.26%	90.32%	90.38%	90.43%	90.49%	90.55%	90.61%	90.66%	90.72%	90.78%	90.83%	90.89%	90.94%	91.00%	91.05%	91.10%	91.16%	91.21%	91.27%	91.32%	91.37%	91.42%	91.47%	91.52%	91.58%	91.63%	91.68%	91.73%	91.78%	91.83%	91.87%	91.92%	91.97%	92.02%	92.07%	92.11%	92.16%	92.21%	92.25%	92.30%	92.34%	92.38%	92.42%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.46%	51.09%	54.87%	57.80%	60.31%	62.40%	64.26%	65.77%	67.07%	68.25%	69.30%	70.24%	71.08%	71.88%	72.62%	73.35%	74.05%	74.72%	75.32%	75.87%	76.36%	76.80%	77.20%	77.60%	77.97%	78.33%	78.66%	78.99%	79.30%	79.59%	79.88%	80.16%	80.43%	80.69%	80.96%	81.21%	81.46%	81.70%	81.93%	82.15%	82.37%	82.58%	82.79%	83.00%	83.20%	83.41%	83.61%	83.80%	83.99%	84.17%	84.35%	84.53%	84.70%	84.86%	85.02%	85.18%	85.33%	85.48%	85.63%	85.77%	85.91%	86.05%	86.18%	86.32%	86.45%	86.58%	86.71%	86.84%	86.96%	87.08%	87.20%	87.31%	87.42%	87.52%	87.62%	87.72%	87.82%	87.93%	88.03%	88.13%	88.23%	88.33%	88.43%	88.52%	88.62%	88.70%	88.79%	88.88%	88.96%	89.05%	89.13%	89.21%	89.30%	89.38%	89.46%	89.55%	89.63%	89.71%	89.78%	89.85%	89.93%	90.00%	90.06%	90.13%	90.19%	90.26%	90.33%	90.39%	90.45%	90.51%	90.57%	90.63%	90.69%	90.75%	90.80%	90.86%	90.91%	90.97%	91.03%	91.08%	91.14%	91.19%	91.25%	91.30%	91.36%	91.41%	91.47%	91.52%	91.57%	91.63%	91.68%	91.73%	91.79%	91.84%	91.89%	91.94%	91.99%	92.04%	92.09%	92.14%	92.19%	92.24%	92.29%	92.34%	92.39%	92.44%	92.49%	92.53%	92.58%	92.62%	92.67%	92.71%	92.76%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.48%	51.15%	54.97%	57.92%	60.43%	62.54%	64.41%	65.91%	67.20%	68.36%	69.43%	70.37%	71.23%	72.03%	72.79%	73.52%	74.22%	74.88%	75.50%	76.07%	76.57%	77.02%	77.42%	77.82%	78.19%	78.54%	78.88%	79.20%	79.51%	79.81%	80.10%	80.38%	80.65%	80.92%	81.18%	81.44%	81.69%	81.94%	82.16%	82.39%	82.60%	82.82%	83.03%	83.23%	83.44%	83.64%	83.84%	84.03%	84.22%	84.41%	84.59%	84.76%	84.93%	85.10%	85.26%	85.42%	85.57%	85.72%	85.87%	86.01%	86.16%	86.30%	86.44%	86.57%	86.70%	86.84%	86.97%	87.09%	87.22%	87.34%	87.46%	87.58%	87.69%	87.79%	87.89%	87.99%	88.09%	88.19%	88.29%	88.39%	88.49%	88.59%	88.69%	88.79%	88.88%	88.97%	89.06%	89.15%	89.24%	89.32%	89.41%	89.49%	89.57%	89.66%	89.74%	89.82%	89.91%	89.99%	90.06%	90.14%	90.21%	90.28%	90.36%	90.43%	90.50%	90.56%	90.62%	90.68%	90.75%	90.81%	90.87%	90.93%	90.99%	91.04%	91.10%	91.16%	91.21%	91.27%	91.32%	91.38%	91.43%	91.49%	91.55%	91.60%	91.65%	91.71%	91.76%	91.81%	91.87%	91.92%	91.97%	92.03%	92.08%	92.13%	92.19%	92.24%	92.29%	92.34%	92.39%	92.45%	92.50%	92.55%	92.60%	92.65%	92.70%	92.74%	92.79%	92.84%	92.88%	92.93%	92.97%	93.02%	93.06%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.25%	46.50%	51.20%	55.05%	58.03%	60.55%	62.68%	64.55%	66.04%	67.34%	68.47%	69.55%	70.50%	71.37%	72.17%	72.95%	73.68%	74.38%	75.04%	75.67%	76.26%	76.77%	77.22%	77.64%	78.03%	78.40%	78.75%	79.09%	79.41%	79.71%	80.02%	80.31%	80.60%	80.87%	81.14%	81.40%	81.66%	81.92%	82.16%	82.39%	82.61%	82.83%	83.04%	83.26%	83.46%	83.66%	83.86%	84.05%	84.25%	84.44%	84.63%	84.82%	84.99%	85.16%	85.33%	85.49%	85.65%	85.80%	85.95%	86.09%	86.24%	86.39%	86.53%	86.67%	86.81%	86.95%	87.08%	87.21%	87.33%	87.45%	87.58%	87.70%	87.82%	87.93%	88.04%	88.14%	88.24%	88.34%	88.44%	88.54%	88.64%	88.74%	88.84%	88.93%	89.02%	89.12%	89.21%	89.31%	89.40%	89.49%	89.57%	89.66%	89.74%	89.83%	89.91%	90.00%	90.08%	90.16%	90.24%	90.32%	90.39%	90.47%	90.54%	90.62%	90.69%	90.76%	90.83%	90.89%	90.96%	91.02%	91.08%	91.14%	91.20%	91.26%	91.32%	91.38%	91.43%	91.49%	91.54%	91.60%	91.65%	91.71%	91.76%	91.82%	91.87%	91.93%	91.98%	92.03%	92.09%	92.14%	92.19%	92.25%	92.30%	92.35%	92.41%	92.46%	92.51%	92.57%	92.62%	92.67%	92.72%	92.77%	92.83%	92.87%	92.92%	92.97%	93.02%	93.07%	93.12%	93.16%	93.21%	93.26%	93.30%	93.35%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.51%	51.25%	55.13%	58.14%	60.66%	62.80%	64.68%	66.17%	67.45%	68.59%	69.66%	70.62%	71.50%	72.32%	73.11%	73.84%	74.53%	75.20%	75.83%	76.43%	76.96%	77.42%	77.84%	78.23%	78.60%	78.94%	79.28%	79.61%	79.91%	80.22%	80.52%	80.81%	81.08%	81.35%	81.61%	81.87%	82.12%	82.36%	82.59%	82.82%	83.04%	83.25%	83.46%	83.66%	83.86%	84.06%	84.25%	84.45%	84.64%	84.83%	85.02%	85.20%	85.36%	85.53%	85.70%	85.86%	86.01%	86.16%	86.31%	86.45%	86.60%	86.74%	86.89%	87.03%	87.17%	87.30%	87.43%	87.55%	87.67%	87.80%	87.92%	88.03%	88.15%	88.26%	88.36%	88.46%	88.56%	88.66%	88.76%	88.86%	88.96%	89.06%	89.15%	89.24%	89.34%	89.43%	89.52%	89.62%	89.71%	89.80%	89.89%	89.98%	90.06%	90.14%	90.23%	90.31%	90.39%	90.47%	90.55%	90.62%	90.70%	90.77%	90.85%	90.92%	90.99%	91.06%	91.12%	91.19%	91.26%	91.32%	91.38%	91.44%	91.50%	91.56%	91.62%	91.68%	91.74%	91.79%	91.85%	91.91%	91.96%	92.02%	92.07%	92.12%	92.18%	92.23%	92.28%	92.34%	92.39%	92.44%	92.49%	92.55%	92.60%	92.65%	92.71%	92.76%	92.81%	92.87%	92.92%	92.97%	93.02%	93.07%	93.12%	93.17%	93.22%	93.27%	93.31%	93.36%	93.41%	93.46%	93.51%	93.55%	93.60%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.53%	51.29%	55.21%	58.24%	60.76%	62.92%	64.80%	66.30%	67.55%	68.70%	69.77%	70.74%	71.63%	72.46%	73.25%	73.99%	74.69%	75.35%	75.99%	76.60%	77.13%	77.59%	78.02%	78.42%	78.77%	79.12%	79.46%	79.79%	80.10%	80.40%	80.71%	81.00%	81.28%	81.55%	81.81%	82.06%	82.30%	82.55%	82.78%	83.00%	83.22%	83.44%	83.64%	83.85%	84.04%	84.24%	84.43%	84.63%	84.82%	85.02%	85.20%	85.38%	85.56%	85.72%	85.89%	86.05%	86.21%	86.35%	86.50%	86.65%	86.79%	86.94%	87.08%	87.23%	87.37%	87.50%	87.63%	87.75%	87.87%	87.99%	88.11%	88.23%	88.34%	88.45%	88.56%	88.66%	88.76%	88.86%	88.96%	89.06%	89.16%	89.25%	89.34%	89.44%	89.53%	89.62%	89.72%	89.81%	89.90%	90.00%	90.09%	90.18%	90.26%	90.35%	90.43%	90.51%	90.59%	90.67%	90.75%	90.82%	90.90%	90.97%	91.04%	91.12%	91.19%	91.26%	91.33%	91.39%	91.46%	91.53%	91.59%	91.66%	91.72%	91.78%	91.84%	91.90%	91.96%	92.02%	92.08%	92.13%	92.18%	92.24%	92.29%	92.34%	92.40%	92.45%	92.50%	92.56%	92.61%	92.66%	92.72%	92.77%	92.82%	92.87%	92.93%	92.98%	93.03%	93.08%	93.13%	93.18%	93.24%	93.29%	93.34%	93.39%	93.44%	93.49%	93.53%	93.58%	93.63%	93.68%	93.73%	93.78%	93.82%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.54%	51.33%	55.29%	58.34%	60.86%	63.04%	64.92%	66.42%	67.66%	68.80%	69.87%	70.85%	71.75%	72.60%	73.39%	74.14%	74.84%	75.51%	76.15%	76.76%	77.29%	77.76%	78.19%	78.59%	78.95%	79.29%	79.63%	79.96%	80.28%	80.59%	80.89%	81.19%	81.47%	81.74%	81.99%	82.24%	82.48%	82.72%	82.95%	83.18%	83.40%	83.61%	83.82%	84.02%	84.22%	84.41%	84.61%	84.80%	85.00%	85.19%	85.37%	85.55%	85.72%	85.90%	86.07%	86.23%	86.38%	86.53%	86.68%	86.83%	86.97%	87.12%	87.26%	87.41%	87.55%	87.68%	87.81%	87.93%	88.05%	88.17%	88.28%	88.40%	88.52%	88.62%	88.73%	88.83%	88.94%	89.04%	89.15%	89.24%	89.34%	89.43%	89.52%	89.61%	89.71%	89.80%	89.89%	89.99%	90.08%	90.17%	90.26%	90.35%	90.44%	90.52%	90.61%	90.69%	90.77%	90.85%	90.92%	91.00%	91.07%	91.14%	91.21%	91.29%	91.36%	91.43%	91.50%	91.57%	91.64%	91.71%	91.78%	91.84%	91.91%	91.97%	92.03%	92.09%	92.15%	92.21%	92.27%	92.32%	92.38%	92.44%	92.49%	92.54%	92.60%	92.65%	92.70%	92.76%	92.81%	92.86%	92.91%	92.97%	93.02%	93.07%	93.12%	93.17%	93.22%	93.27%	93.33%	93.38%	93.43%	93.48%	93.53%	93.58%	93.63%	93.68%	93.73%	93.78%	93.83%	93.88%	93.93%	93.98%	94.02%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.56%	51.38%	55.36%	58.44%	60.95%	63.15%	65.03%	66.53%	67.76%	68.91%	69.97%	70.95%	71.87%	72.72%	73.52%	74.28%	74.99%	75.67%	76.30%	76.91%	77.44%	77.92%	78.35%	78.75%	79.12%	79.46%	79.79%	80.13%	80.45%	80.76%	81.06%	81.37%	81.65%	81.91%	82.17%	82.41%	82.65%	82.88%	83.11%	83.34%	83.56%	83.77%	83.99%	84.19%	84.38%	84.58%	84.77%	84.97%	85.16%	85.35%	85.52%	85.70%	85.88%	86.05%	86.23%	86.39%	86.54%	86.69%	86.84%	86.99%	87.13%	87.28%	87.42%	87.56%	87.70%	87.83%	87.96%	88.08%	88.20%	88.32%	88.44%	88.55%	88.67%	88.77%	88.88%	88.99%	89.09%	89.20%	89.30%	89.40%	89.49%	89.58%	89.67%	89.77%	89.86%	89.95%	90.05%	90.14%	90.23%	90.32%	90.41%	90.50%	90.59%	90.67%	90.76%	90.84%	90.92%	91.00%	91.07%	91.14%	91.22%	91.29%	91.36%	91.43%	91.50%	91.58%	91.65%	91.72%	91.79%	91.86%	91.93%	92.00%	92.07%	92.13%	92.20%	92.26%	92.32%	92.38%	92.43%	92.49%	92.55%	92.61%	92.66%	92.72%	92.77%	92.83%	92.88%	92.93%	92.98%	93.04%	93.09%	93.14%	93.19%	93.24%	93.29%	93.34%	93.39%	93.44%	93.50%	93.55%	93.60%	93.65%	93.70%	93.75%	93.80%	93.85%	93.90%	93.95%	94.00%	94.05%	94.10%	94.15%	94.20%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.57%	51.41%	55.43%	58.52%	61.05%	63.25%	65.14%	66.63%	67.86%	69.01%	70.07%	71.05%	71.99%	72.84%	73.65%	74.41%	75.14%	75.82%	76.46%	77.06%	77.59%	78.08%	78.50%	78.89%	79.27%	79.61%	79.95%	80.28%	80.60%	80.92%	81.23%	81.53%	81.81%	82.07%	82.33%	82.57%	82.81%	83.04%	83.27%	83.50%	83.72%	83.93%	84.14%	84.35%	84.54%	84.74%	84.93%	85.13%	85.31%	85.49%	85.67%	85.85%	86.03%	86.20%	86.37%	86.54%	86.69%	86.84%	86.99%	87.14%	87.29%	87.43%	87.57%	87.70%	87.84%	87.97%	88.10%	88.22%	88.35%	88.47%	88.58%	88.70%	88.82%	88.92%	89.03%	89.14%	89.24%	89.35%	89.45%	89.54%	89.64%	89.73%	89.82%	89.92%	90.01%	90.10%	90.19%	90.28%	90.37%	90.46%	90.55%	90.64%	90.73%	90.81%	90.90%	90.98%	91.06%	91.14%	91.21%	91.29%	91.36%	91.43%	91.50%	91.58%	91.65%	91.72%	91.79%	91.86%	91.94%	92.01%	92.08%	92.15%	92.22%	92.28%	92.35%	92.41%	92.47%	92.53%	92.59%	92.65%	92.71%	92.76%	92.82%	92.88%	92.94%	92.99%	93.05%	93.10%	93.15%	93.20%	93.25%	93.30%	93.35%	93.40%	93.46%	93.51%	93.56%	93.61%	93.66%	93.71%	93.76%	93.81%	93.86%	93.91%	93.96%	94.01%	94.07%	94.12%	94.17%	94.22%	94.27%	94.32%	94.37%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.59%	51.45%	55.50%	58.60%	61.14%	63.34%	65.23%	66.73%	67.95%	69.11%	70.17%	71.15%	72.09%	72.95%	73.77%	74.55%	75.29%	75.97%	76.61%	77.20%	77.73%	78.23%	78.64%	79.03%	79.40%	79.77%	80.11%	80.44%	80.76%	81.08%	81.39%	81.68%	81.96%	82.22%	82.48%	82.73%	82.96%	83.20%	83.42%	83.65%	83.86%	84.08%	84.29%	84.50%	84.70%	84.89%	85.09%	85.28%	85.46%	85.64%	85.82%	85.99%	86.17%	86.35%	86.52%	86.68%	86.84%	86.99%	87.14%	87.29%	87.43%	87.58%	87.71%	87.84%	87.97%	88.10%	88.23%	88.36%	88.48%	88.61%	88.73%	88.84%	88.96%	89.06%	89.17%	89.28%	89.38%	89.48%	89.58%	89.68%	89.78%	89.87%	89.96%	90.05%	90.14%	90.23%	90.32%	90.41%	90.50%	90.59%	90.68%	90.77%	90.85%	90.94%	91.02%	91.10%	91.18%	91.26%	91.34%	91.42%	91.49%	91.56%	91.64%	91.71%	91.78%	91.85%	91.92%	92.00%	92.07%	92.14%	92.21%	92.28%	92.35%	92.42%	92.48%	92.55%	92.61%	92.67%	92.73%	92.79%	92.85%	92.91%	92.97%	93.03%	93.08%	93.14%	93.19%	93.25%	93.30%	93.35%	93.41%	93.46%	93.51%	93.56%	93.61%	93.66%	93.71%	93.76%	93.81%	93.86%	93.91%	93.97%	94.02%	94.07%	94.12%	94.17%	94.22%	94.27%	94.32%	94.37%	94.42%	94.47%	94.52%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.61%	51.48%	55.56%	58.68%	61.24%	63.43%	65.32%	66.83%	68.04%	69.21%	70.26%	71.24%	72.19%	73.06%	73.89%	74.68%	75.42%	76.12%	76.75%	77.34%	77.87%	78.35%	78.76%	79.15%	79.53%	79.91%	80.26%	80.59%	80.91%	81.22%	81.53%	81.83%	82.10%	82.36%	82.62%	82.87%	83.11%	83.34%	83.57%	83.79%	84.00%	84.22%	84.44%	84.64%	84.84%	85.04%	85.24%	85.42%	85.60%	85.78%	85.96%	86.13%	86.31%	86.49%	86.66%	86.82%	86.97%	87.13%	87.28%	87.42%	87.57%	87.71%	87.84%	87.97%	88.10%	88.23%	88.36%	88.49%	88.61%	88.74%	88.86%	88.98%	89.09%	89.20%	89.30%	89.41%	89.51%	89.61%	89.71%	89.81%	89.90%	89.99%	90.09%	90.18%	90.27%	90.36%	90.45%	90.54%	90.63%	90.71%	90.80%	90.88%	90.97%	91.05%	91.14%	91.22%	91.30%	91.38%	91.46%	91.54%	91.61%	91.69%	91.76%	91.83%	91.91%	91.98%	92.05%	92.12%	92.19%	92.26%	92.33%	92.40%	92.47%	92.54%	92.60%	92.67%	92.73%	92.80%	92.86%	92.92%	92.98%	93.04%	93.10%	93.16%	93.21%	93.27%	93.32%	93.38%	93.43%	93.49%	93.54%	93.60%	93.65%	93.70%	93.75%	93.80%	93.85%	93.90%	93.95%	94.00%	94.05%	94.10%	94.15%	94.21%	94.26%	94.31%	94.36%	94.41%	94.46%	94.50%	94.55%	94.60%	94.65%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.62%	51.51%	55.61%	58.76%	61.33%	63.51%	65.41%	66.92%	68.13%	69.30%	70.36%	71.33%	72.28%	73.17%	74.00%	74.80%	75.55%	76.25%	76.89%	77.48%	78.00%	78.47%	78.88%	79.27%	79.66%	80.03%	80.39%	80.73%	81.04%	81.35%	81.66%	81.95%	82.23%	82.49%	82.75%	83.00%	83.24%	83.48%	83.70%	83.92%	84.14%	84.35%	84.57%	84.78%	84.98%	85.18%	85.36%	85.55%	85.73%	85.91%	86.09%	86.27%	86.44%	86.62%	86.79%	86.96%	87.11%	87.26%	87.40%	87.55%	87.69%	87.83%	87.96%	88.09%	88.22%	88.35%	88.48%	88.61%	88.73%	88.85%	88.97%	89.09%	89.21%	89.32%	89.43%	89.53%	89.63%	89.73%	89.83%	89.92%	90.02%	90.11%	90.21%	90.30%	90.39%	90.48%	90.56%	90.65%	90.74%	90.82%	90.91%	90.99%	91.08%	91.17%	91.25%	91.33%	91.41%	91.49%	91.57%	91.65%	91.72%	91.80%	91.88%	91.95%	92.02%	92.09%	92.16%	92.23%	92.30%	92.37%	92.44%	92.51%	92.58%	92.65%	92.71%	92.78%	92.84%	92.91%	92.97%	93.03%	93.09%	93.15%	93.21%	93.27%	93.33%	93.38%	93.44%	93.50%	93.55%	93.61%	93.66%	93.72%	93.77%	93.82%	93.88%	93.93%	93.98%	94.03%	94.08%	94.13%	94.18%	94.23%	94.28%	94.33%	94.38%	94.43%	94.48%	94.53%	94.58%	94.63%	94.67%	94.72%	94.76%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.64%	51.54%	55.66%	58.83%	61.41%	63.58%	65.49%	67.00%	68.22%	69.40%	70.45%	71.42%	72.36%	73.27%	74.11%	74.92%	75.67%	76.38%	77.02%	77.60%	78.12%	78.58%	78.98%	79.39%	79.77%	80.14%	80.52%	80.86%	81.17%	81.48%	81.78%	82.08%	82.36%	82.62%	82.88%	83.13%	83.37%	83.61%	83.83%	84.05%	84.27%	84.49%	84.70%	84.91%	85.12%	85.30%	85.49%	85.68%	85.86%	86.04%	86.22%	86.40%	86.58%	86.75%	86.92%	87.09%	87.24%	87.38%	87.53%	87.68%	87.82%	87.95%	88.09%	88.21%	88.34%	88.47%	88.60%	88.72%	88.85%	88.97%	89.09%	89.21%	89.33%	89.44%	89.55%	89.65%	89.75%	89.85%	89.94%	90.04%	90.13%	90.23%	90.32%	90.41%	90.50%	90.59%	90.68%	90.76%	90.85%	90.93%	91.02%	91.11%	91.19%	91.28%	91.36%	91.44%	91.53%	91.61%	91.68%	91.76%	91.84%	91.91%	91.99%	92.06%	92.13%	92.20%	92.27%	92.34%	92.41%	92.48%	92.55%	92.62%	92.69%	92.76%	92.82%	92.89%	92.95%	93.01%	93.07%	93.13%	93.19%	93.25%	93.31%	93.38%	93.44%	93.49%	93.55%	93.61%	93.67%	93.72%	93.78%	93.83%	93.89%	93.94%	94.00%	94.05%	94.10%	94.15%	94.21%	94.26%	94.31%	94.36%	94.41%	94.45%	94.50%	94.55%	94.60%	94.65%	94.69%	94.74%	94.78%	94.83%	94.87%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.65%	51.58%	55.71%	58.89%	61.48%	63.65%	65.56%	67.09%	68.31%	69.48%	70.54%	71.50%	72.45%	73.37%	74.22%	75.03%	75.79%	76.50%	77.14%	77.73%	78.23%	78.69%	79.09%	79.49%	79.89%	80.26%	80.63%	80.98%	81.30%	81.60%	81.90%	82.20%	82.48%	82.75%	83.00%	83.25%	83.50%	83.74%	83.96%	84.18%	84.40%	84.62%	84.84%	85.04%	85.24%	85.43%	85.62%	85.81%	85.99%	86.18%	86.36%	86.53%	86.71%	86.88%	87.06%	87.22%	87.36%	87.51%	87.66%	87.80%	87.94%	88.08%	88.21%	88.34%	88.47%	88.59%	88.72%	88.84%	88.96%	89.08%	89.21%	89.33%	89.45%	89.55%	89.66%	89.76%	89.86%	89.96%	90.06%	90.15%	90.25%	90.34%	90.43%	90.52%	90.61%	90.70%	90.79%	90.87%	90.96%	91.05%	91.13%	91.22%	91.30%	91.39%	91.47%	91.56%	91.64%	91.72%	91.79%	91.87%	91.94%	92.02%	92.09%	92.16%	92.24%	92.31%	92.38%	92.45%	92.52%	92.59%	92.66%	92.73%	92.80%	92.86%	92.93%	92.99%	93.05%	93.12%	93.18%	93.24%	93.30%	93.36%	93.42%	93.48%	93.54%	93.60%	93.66%	93.72%	93.77%	93.83%	93.89%	93.95%	94.00%	94.06%	94.11%	94.17%	94.22%	94.28%	94.33%	94.38%	94.42%	94.47%	94.52%	94.57%	94.62%	94.67%	94.71%	94.75%	94.80%	94.84%	94.89%	94.93%	94.98%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.67%	51.61%	55.76%	58.95%	61.55%	63.73%	65.64%	67.17%	68.40%	69.56%	70.63%	71.59%	72.53%	73.46%	74.32%	75.14%	75.91%	76.61%	77.26%	77.84%	78.33%	78.79%	79.20%	79.60%	80.00%	80.38%	80.74%	81.09%	81.42%	81.72%	82.02%	82.31%	82.59%	82.86%	83.12%	83.37%	83.62%	83.86%	84.09%	84.31%	84.53%	84.75%	84.97%	85.17%	85.36%	85.56%	85.75%	85.93%	86.12%	86.31%	86.49%	86.66%	86.84%	87.01%	87.18%	87.34%	87.49%	87.64%	87.78%	87.92%	88.06%	88.20%	88.33%	88.46%	88.58%	88.71%	88.84%	88.96%	89.08%	89.20%	89.32%	89.44%	89.55%	89.66%	89.76%	89.87%	89.98%	90.08%	90.17%	90.26%	90.35%	90.44%	90.53%	90.62%	90.71%	90.80%	90.89%	90.98%	91.07%	91.16%	91.24%	91.33%	91.42%	91.50%	91.58%	91.67%	91.75%	91.82%	91.90%	91.97%	92.05%	92.12%	92.20%	92.27%	92.34%	92.42%	92.49%	92.55%	92.62%	92.69%	92.76%	92.83%	92.90%	92.96%	93.03%	93.09%	93.15%	93.22%	93.28%	93.34%	93.40%	93.46%	93.52%	93.58%	93.64%	93.70%	93.76%	93.82%	93.88%	93.94%	94.00%	94.05%	94.11%	94.17%	94.22%	94.28%	94.33%	94.38%	94.44%	94.49%	94.54%	94.59%	94.64%	94.68%	94.73%	94.77%	94.82%	94.86%	94.91%	94.95%	95.00%	95.04%	95.08%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.69%	51.63%	55.81%	59.01%	61.62%	63.80%	65.70%	67.25%	68.49%	69.64%	70.71%	71.67%	72.61%	73.54%	74.42%	75.24%	76.01%	76.72%	77.37%	77.94%	78.43%	78.89%	79.30%	79.70%	80.10%	80.48%	80.84%	81.19%	81.53%	81.84%	82.14%	82.43%	82.70%	82.97%	83.24%	83.49%	83.74%	83.99%	84.22%	84.44%	84.67%	84.88%	85.09%	85.29%	85.48%	85.68%	85.87%	86.06%	86.25%	86.43%	86.62%	86.80%	86.97%	87.14%	87.31%	87.47%	87.62%	87.76%	87.90%	88.04%	88.18%	88.32%	88.45%	88.57%	88.70%	88.83%	88.95%	89.07%	89.19%	89.31%	89.43%	89.55%	89.66%	89.76%	89.87%	89.97%	90.07%	90.17%	90.27%	90.37%	90.46%	90.55%	90.64%	90.73%	90.82%	90.91%	91.00%	91.09%	91.18%	91.27%	91.35%	91.44%	91.53%	91.61%	91.69%	91.77%	91.85%	91.93%	92.01%	92.08%	92.16%	92.23%	92.30%	92.38%	92.45%	92.52%	92.59%	92.66%	92.72%	92.79%	92.86%	92.93%	93.00%	93.06%	93.13%	93.19%	93.25%	93.32%	93.38%	93.44%	93.50%	93.56%	93.62%	93.68%	93.74%	93.80%	93.86%	93.92%	93.98%	94.04%	94.10%	94.16%	94.22%	94.27%	94.33%	94.38%	94.44%	94.49%	94.54%	94.60%	94.65%	94.69%	94.74%	94.79%	94.83%	94.88%	94.92%	94.97%	95.01%	95.06%	95.10%	95.15%	95.19%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.70%	51.65%	55.85%	59.06%	61.69%	63.87%	65.77%	67.33%	68.58%	69.71%	70.78%	71.76%	72.70%	73.63%	74.52%	75.34%	76.11%	76.83%	77.47%	78.03%	78.53%	78.99%	79.41%	79.81%	80.20%	80.58%	80.94%	81.29%	81.63%	81.95%	82.25%	82.54%	82.81%	83.08%	83.35%	83.61%	83.87%	84.11%	84.34%	84.57%	84.79%	85.01%	85.22%	85.41%	85.61%	85.80%	85.99%	86.18%	86.37%	86.56%	86.74%	86.92%	87.10%	87.27%	87.44%	87.60%	87.74%	87.89%	88.03%	88.16%	88.30%	88.44%	88.56%	88.69%	88.82%	88.94%	89.07%	89.19%	89.31%	89.42%	89.54%	89.65%	89.76%	89.86%	89.97%	90.07%	90.17%	90.27%	90.37%	90.47%	90.56%	90.65%	90.75%	90.84%	90.93%	91.02%	91.11%	91.20%	91.29%	91.38%	91.47%	91.55%	91.63%	91.72%	91.80%	91.88%	91.96%	92.04%	92.11%	92.19%	92.26%	92.34%	92.41%	92.48%	92.55%	92.62%	92.69%	92.76%	92.83%	92.89%	92.96%	93.03%	93.10%	93.16%	93.23%	93.29%	93.36%	93.42%	93.48%	93.54%	93.60%	93.66%	93.72%	93.78%	93.84%	93.90%	93.96%	94.02%	94.08%	94.14%	94.20%	94.26%	94.32%	94.37%	94.43%	94.48%	94.54%	94.59%	94.64%	94.69%	94.74%	94.79%	94.84%	94.89%	94.94%	94.98%	95.03%	95.07%	95.12%	95.16%	95.21%	95.25%	95.30%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.71%	51.66%	55.89%	59.11%	61.75%	63.94%	65.82%	67.41%	68.66%	69.78%	70.85%	71.84%	72.78%	73.71%	74.60%	75.43%	76.21%	76.94%	77.57%	78.12%	78.62%	79.09%	79.51%	79.91%	80.30%	80.67%	81.04%	81.39%	81.73%	82.05%	82.36%	82.65%	82.92%	83.19%	83.46%	83.73%	83.99%	84.23%	84.47%	84.69%	84.91%	85.13%	85.34%	85.53%	85.73%	85.92%	86.11%	86.31%	86.50%	86.68%	86.86%	87.04%	87.22%	87.40%	87.56%	87.72%	87.87%	88.01%	88.15%	88.28%	88.42%	88.55%	88.68%	88.81%	88.93%	89.05%	89.18%	89.30%	89.42%	89.53%	89.64%	89.75%	89.86%	89.96%	90.06%	90.16%	90.26%	90.36%	90.47%	90.57%	90.66%	90.76%	90.85%	90.94%	91.03%	91.12%	91.21%	91.30%	91.39%	91.48%	91.57%	91.65%	91.74%	91.82%	91.90%	91.99%	92.07%	92.14%	92.22%	92.29%	92.37%	92.44%	92.51%	92.58%	92.65%	92.72%	92.79%	92.86%	92.93%	92.99%	93.06%	93.13%	93.20%	93.26%	93.33%	93.39%	93.46%	93.52%	93.58%	93.64%	93.70%	93.76%	93.82%	93.88%	93.94%	94.00%	94.06%	94.12%	94.18%	94.24%	94.29%	94.35%	94.41%	94.47%	94.52%	94.58%	94.63%	94.68%	94.73%	94.79%	94.84%	94.89%	94.94%	94.98%	95.03%	95.08%	95.13%	95.18%	95.23%	95.27%	95.31%	95.35%	95.40%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.72%	51.68%	55.93%	59.16%	61.81%	64.00%	65.88%	67.47%	68.74%	69.85%	70.92%	71.92%	72.87%	73.79%	74.68%	75.52%	76.30%	77.03%	77.66%	78.21%	78.72%	79.19%	79.60%	80.00%	80.39%	80.76%	81.14%	81.49%	81.82%	82.15%	82.46%	82.76%	83.03%	83.31%	83.57%	83.84%	84.11%	84.35%	84.58%	84.81%	85.03%	85.25%	85.46%	85.66%	85.85%	86.04%	86.24%	86.43%	86.62%	86.81%	86.99%	87.16%	87.34%	87.52%	87.69%	87.85%	87.99%	88.13%	88.27%	88.40%	88.53%	88.67%	88.79%	88.92%	89.04%	89.17%	89.29%	89.41%	89.52%	89.63%	89.74%	89.84%	89.95%	90.05%	90.16%	90.26%	90.36%	90.46%	90.56%	90.66%	90.76%	90.86%	90.95%	91.05%	91.14%	91.23%	91.32%	91.40%	91.49%	91.58%	91.67%	91.75%	91.84%	91.93%	92.01%	92.09%	92.17%	92.25%	92.32%	92.39%	92.47%	92.54%	92.61%	92.68%	92.75%	92.82%	92.89%	92.96%	93.03%	93.09%	93.16%	93.23%	93.30%	93.36%	93.43%	93.49%	93.56%	93.62%	93.68%	93.74%	93.80%	93.86%	93.92%	93.98%	94.04%	94.10%	94.16%	94.21%	94.27%	94.33%	94.39%	94.45%	94.50%	94.56%	94.61%	94.66%	94.72%	94.77%	94.82%	94.88%	94.93%	94.98%	95.03%	95.08%	95.13%	95.18%	95.23%	95.27%	95.32%	95.37%	95.41%	95.46%	95.50%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.73%	51.69%	55.96%	59.20%	61.87%	64.06%	65.94%	67.53%	68.81%	69.92%	70.99%	72.00%	72.95%	73.87%	74.75%	75.61%	76.39%	77.12%	77.75%	78.30%	78.81%	79.28%	79.69%	80.09%	80.48%	80.85%	81.22%	81.58%	81.92%	82.24%	82.56%	82.87%	83.15%	83.42%	83.68%	83.95%	84.22%	84.47%	84.70%	84.93%	85.15%	85.36%	85.57%	85.78%	85.97%	86.16%	86.36%	86.55%	86.73%	86.92%	87.11%	87.28%	87.46%	87.64%	87.81%	87.97%	88.11%	88.25%	88.38%	88.52%	88.65%	88.78%	88.91%	89.03%	89.15%	89.27%	89.39%	89.50%	89.61%	89.72%	89.83%	89.94%	90.05%	90.15%	90.25%	90.35%	90.45%	90.56%	90.66%	90.76%	90.85%	90.95%	91.05%	91.15%	91.24%	91.33%	91.42%	91.50%	91.59%	91.68%	91.77%	91.86%	91.94%	92.03%	92.11%	92.19%	92.27%	92.35%	92.42%	92.49%	92.57%	92.64%	92.71%	92.78%	92.85%	92.92%	92.99%	93.06%	93.13%	93.19%	93.26%	93.33%	93.40%	93.47%	93.53%	93.59%	93.66%	93.72%	93.78%	93.84%	93.90%	93.96%	94.02%	94.08%	94.13%	94.19%	94.25%	94.31%	94.37%	94.42%	94.48%	94.53%	94.59%	94.64%	94.69%	94.75%	94.80%	94.86%	94.91%	94.97%	95.02%	95.07%	95.12%	95.17%	95.22%	95.27%	95.32%	95.37%	95.41%	95.46%	95.51%	95.55%	95.60%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.74%	51.71%	56.00%	59.25%	61.92%	64.12%	66.00%	67.59%	68.87%	69.98%	71.06%	72.08%	73.03%	73.94%	74.83%	75.68%	76.47%	77.21%	77.84%	78.39%	78.89%	79.36%	79.77%	80.17%	80.57%	80.94%	81.31%	81.67%	82.01%	82.34%	82.65%	82.97%	83.26%	83.53%	83.79%	84.06%	84.32%	84.58%	84.82%	85.04%	85.26%	85.48%	85.69%	85.89%	86.09%	86.28%	86.47%	86.66%	86.85%	87.04%	87.22%	87.41%	87.58%	87.76%	87.93%	88.09%	88.23%	88.36%	88.49%	88.63%	88.76%	88.89%	89.01%	89.13%	89.25%	89.37%	89.49%	89.60%	89.71%	89.82%	89.93%	90.03%	90.14%	90.24%	90.35%	90.45%	90.55%	90.65%	90.75%	90.85%	90.95%	91.05%	91.14%	91.24%	91.33%	91.42%	91.52%	91.60%	91.69%	91.78%	91.87%	91.95%	92.04%	92.13%	92.21%	92.29%	92.37%	92.45%	92.52%	92.60%	92.67%	92.74%	92.81%	92.88%	92.95%	93.02%	93.09%	93.16%	93.23%	93.30%	93.36%	93.43%	93.50%	93.57%	93.63%	93.69%	93.76%	93.82%	93.88%	93.94%	94.00%	94.05%	94.11%	94.17%	94.23%	94.29%	94.34%	94.40%	94.45%	94.51%	94.56%	94.62%	94.67%	94.73%	94.78%	94.83%	94.89%	94.94%	95.00%	95.05%	95.10%	95.15%	95.21%	95.26%	95.31%	95.36%	95.41%	95.46%	95.50%	95.55%	95.60%	95.65%	95.69%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.75%	51.72%	56.03%	59.29%	61.98%	64.17%	66.05%	67.64%	68.93%	70.05%	71.13%	72.15%	73.11%	74.02%	74.90%	75.75%	76.55%	77.29%	77.92%	78.48%	78.97%	79.44%	79.85%	80.26%	80.65%	81.03%	81.39%	81.76%	82.10%	82.43%	82.75%	83.06%	83.36%	83.64%	83.91%	84.17%	84.43%	84.69%	84.93%	85.15%	85.37%	85.59%	85.81%	86.01%	86.21%	86.40%	86.59%	86.78%	86.97%	87.16%	87.34%	87.52%	87.71%	87.88%	88.04%	88.20%	88.33%	88.47%	88.61%	88.74%	88.87%	88.99%	89.12%	89.23%	89.35%	89.47%	89.58%	89.69%	89.80%	89.91%	90.02%	90.13%	90.24%	90.34%	90.44%	90.54%	90.64%	90.75%	90.85%	90.94%	91.04%	91.14%	91.23%	91.33%	91.42%	91.52%	91.61%	91.70%	91.79%	91.88%	91.96%	92.05%	92.14%	92.22%	92.30%	92.39%	92.47%	92.55%	92.62%	92.70%	92.77%	92.84%	92.91%	92.98%	93.05%	93.12%	93.19%	93.26%	93.33%	93.40%	93.46%	93.53%	93.60%	93.67%	93.73%	93.79%	93.86%	93.92%	93.97%	94.03%	94.09%	94.15%	94.21%	94.26%	94.32%	94.37%	94.43%	94.48%	94.54%	94.59%	94.65%	94.70%	94.76%	94.81%	94.86%	94.92%	94.97%	95.02%	95.08%	95.13%	95.18%	95.23%	95.29%	95.34%	95.39%	95.44%	95.49%	95.54%	95.59%	95.64%	95.69%	95.74%	95.78%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.76%	51.74%	56.07%	59.34%	62.03%	64.21%	66.10%	67.69%	68.99%	70.12%	71.20%	72.22%	73.18%	74.09%	74.97%	75.82%	76.63%	77.37%	77.99%	78.56%	79.05%	79.51%	79.93%	80.34%	80.73%	81.11%	81.48%	81.84%	82.19%	82.53%	82.84%	83.16%	83.46%	83.75%	84.01%	84.28%	84.54%	84.79%	85.03%	85.26%	85.48%	85.70%	85.92%	86.12%	86.32%	86.51%	86.71%	86.89%	87.08%	87.27%	87.46%	87.64%	87.82%	87.99%	88.15%	88.30%	88.44%	88.57%	88.71%	88.84%	88.97%	89.09%	89.22%	89.33%	89.45%	89.57%	89.68%	89.79%	89.90%	90.01%	90.12%	90.22%	90.33%	90.43%	90.54%	90.64%	90.74%	90.84%	90.94%	91.03%	91.13%	91.23%	91.32%	91.42%	91.51%	91.61%	91.70%	91.79%	91.88%	91.97%	92.06%	92.15%	92.23%	92.32%	92.40%	92.48%	92.57%	92.64%	92.72%	92.80%	92.87%	92.94%	93.01%	93.08%	93.15%	93.22%	93.29%	93.36%	93.43%	93.50%	93.56%	93.63%	93.70%	93.76%	93.83%	93.89%	93.95%	94.01%	94.07%	94.13%	94.18%	94.24%	94.30%	94.35%	94.40%	94.46%	94.51%	94.57%	94.62%	94.68%	94.73%	94.79%	94.84%	94.89%	94.95%	95.00%	95.05%	95.10%	95.16%	95.21%	95.26%	95.31%	95.37%	95.42%	95.47%	95.52%	95.58%	95.63%	95.68%	95.73%	95.77%	95.82%	95.87%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.77%	51.76%	56.10%	59.38%	62.08%	64.26%	66.14%	67.74%	69.04%	70.19%	71.26%	72.29%	73.25%	74.17%	75.04%	75.89%	76.70%	77.44%	78.07%	78.63%	79.12%	79.59%	80.01%	80.42%	80.81%	81.19%	81.56%	81.93%	82.28%	82.62%	82.94%	83.25%	83.55%	83.85%	84.12%	84.38%	84.64%	84.90%	85.13%	85.37%	85.59%	85.81%	86.03%	86.23%	86.43%	86.62%	86.82%	87.01%	87.20%	87.39%	87.57%	87.75%	87.93%	88.10%	88.25%	88.40%	88.54%	88.67%	88.80%	88.94%	89.07%	89.19%	89.32%	89.43%	89.55%	89.66%	89.77%	89.88%	89.99%	90.10%	90.21%	90.32%	90.43%	90.53%	90.63%	90.73%	90.83%	90.93%	91.03%	91.12%	91.22%	91.31%	91.41%	91.50%	91.60%	91.69%	91.78%	91.88%	91.97%	92.06%	92.15%	92.24%	92.33%	92.41%	92.50%	92.58%	92.66%	92.74%	92.82%	92.89%	92.97%	93.04%	93.11%	93.18%	93.25%	93.32%	93.39%	93.46%	93.53%	93.60%	93.66%	93.73%	93.80%	93.86%	93.92%	93.98%	94.05%	94.10%	94.16%	94.22%	94.27%	94.33%	94.38%	94.43%	94.49%	94.54%	94.60%	94.65%	94.71%	94.76%	94.81%	94.87%	94.92%	94.97%	95.03%	95.08%	95.13%	95.18%	95.24%	95.29%	95.34%	95.39%	95.45%	95.50%	95.55%	95.60%	95.66%	95.71%	95.76%	95.81%	95.86%	95.90%	95.95%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.77%	51.77%	56.12%	59.42%	62.12%	64.30%	66.17%	67.78%	69.09%	70.25%	71.32%	72.36%	73.33%	74.24%	75.11%	75.95%	76.77%	77.51%	78.14%	78.69%	79.20%	79.66%	80.08%	80.49%	80.89%	81.28%	81.65%	82.01%	82.36%	82.71%	83.03%	83.35%	83.64%	83.94%	84.22%	84.49%	84.75%	85.00%	85.23%	85.47%	85.70%	85.92%	86.13%	86.34%	86.54%	86.74%	86.93%	87.13%	87.32%	87.50%	87.68%	87.86%	88.02%	88.19%	88.35%	88.50%	88.63%	88.77%	88.90%	89.03%	89.17%	89.30%	89.41%	89.53%	89.64%	89.76%	89.87%	89.98%	90.09%	90.20%	90.31%	90.42%	90.52%	90.62%	90.73%	90.83%	90.93%	91.02%	91.12%	91.21%	91.31%	91.40%	91.49%	91.59%	91.68%	91.78%	91.87%	91.96%	92.06%	92.15%	92.24%	92.33%	92.42%	92.51%	92.59%	92.67%	92.76%	92.83%	92.91%	92.99%	93.07%	93.14%	93.21%	93.28%	93.35%	93.42%	93.49%	93.56%	93.63%	93.69%	93.76%	93.82%	93.89%	93.95%	94.02%	94.08%	94.14%	94.19%	94.25%	94.30%	94.36%	94.41%	94.46%	94.52%	94.57%	94.63%	94.68%	94.74%	94.79%	94.84%	94.89%	94.95%	95.00%	95.05%	95.10%	95.16%	95.21%	95.26%	95.32%	95.37%	95.42%	95.47%	95.53%	95.58%	95.63%	95.68%	95.74%	95.79%	95.84%	95.89%	95.94%	95.98%	96.03%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.78%	51.79%	56.15%	59.46%	62.17%	64.34%	66.21%	67.83%	69.14%	70.31%	71.39%	72.43%	73.40%	74.30%	75.18%	76.01%	76.83%	77.56%	78.21%	78.76%	79.26%	79.73%	80.15%	80.56%	80.97%	81.36%	81.73%	82.09%	82.45%	82.79%	83.12%	83.44%	83.73%	84.03%	84.31%	84.59%	84.85%	85.10%	85.33%	85.56%	85.79%	86.02%	86.24%	86.44%	86.65%	86.85%	87.04%	87.24%	87.42%	87.60%	87.78%	87.95%	88.12%	88.28%	88.44%	88.59%	88.73%	88.86%	88.99%	89.13%	89.26%	89.39%	89.51%	89.62%	89.74%	89.85%	89.96%	90.07%	90.18%	90.29%	90.40%	90.51%	90.62%	90.72%	90.82%	90.92%	91.01%	91.11%	91.20%	91.30%	91.39%	91.48%	91.58%	91.67%	91.77%	91.86%	91.95%	92.05%	92.14%	92.23%	92.33%	92.42%	92.51%	92.60%	92.69%	92.77%	92.85%	92.93%	93.01%	93.09%	93.16%	93.24%	93.31%	93.38%	93.45%	93.52%	93.59%	93.66%	93.72%	93.79%	93.85%	93.92%	93.98%	94.04%	94.11%	94.16%	94.22%	94.28%	94.33%	94.39%	94.44%	94.49%	94.55%	94.60%	94.66%	94.71%	94.76%	94.81%	94.87%	94.92%	94.97%	95.03%	95.08%	95.13%	95.18%	95.24%	95.29%	95.34%	95.40%	95.45%	95.50%	95.55%	95.61%	95.66%	95.71%	95.76%	95.82%	95.86%	95.91%	95.96%	96.01%	96.06%	96.11%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.78%	51.80%	56.17%	59.51%	62.21%	64.37%	66.24%	67.87%	69.18%	70.37%	71.45%	72.50%	73.46%	74.37%	75.25%	76.08%	76.88%	77.62%	78.27%	78.82%	79.33%	79.80%	80.22%	80.63%	81.04%	81.44%	81.82%	82.18%	82.53%	82.88%	83.21%	83.53%	83.82%	84.12%	84.40%	84.69%	84.95%	85.20%	85.43%	85.66%	85.89%	86.12%	86.34%	86.55%	86.75%	86.96%	87.15%	87.33%	87.52%	87.70%	87.88%	88.04%	88.21%	88.37%	88.53%	88.68%	88.82%	88.96%	89.09%	89.22%	89.35%	89.48%	89.60%	89.72%	89.83%	89.95%	90.06%	90.17%	90.28%	90.39%	90.50%	90.61%	90.71%	90.81%	90.91%	91.00%	91.10%	91.19%	91.29%	91.38%	91.48%	91.57%	91.66%	91.76%	91.85%	91.94%	92.04%	92.13%	92.23%	92.32%	92.41%	92.51%	92.60%	92.69%	92.78%	92.86%	92.95%	93.03%	93.10%	93.18%	93.26%	93.33%	93.40%	93.48%	93.55%	93.62%	93.68%	93.75%	93.81%	93.88%	93.94%	94.01%	94.07%	94.13%	94.19%	94.25%	94.31%	94.36%	94.42%	94.47%	94.52%	94.58%	94.63%	94.68%	94.74%	94.79%	94.84%	94.89%	94.95%	95.00%	95.05%	95.11%	95.16%	95.21%	95.26%	95.32%	95.37%	95.42%	95.47%	95.53%	95.58%	95.63%	95.69%	95.74%	95.79%	95.84%	95.89%	95.94%	95.98%	96.03%	96.08%	96.13%	96.17%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.79%	51.82%	56.20%	59.55%	62.25%	64.40%	66.27%	67.91%	69.23%	70.42%	71.51%	72.57%	73.52%	74.44%	75.31%	76.13%	76.92%	77.67%	78.33%	78.88%	79.39%	79.87%	80.29%	80.70%	81.11%	81.52%	81.90%	82.26%	82.62%	82.96%	83.29%	83.61%	83.91%	84.21%	84.49%	84.78%	85.05%	85.29%	85.52%	85.75%	85.98%	86.21%	86.44%	86.65%	86.85%	87.05%	87.24%	87.43%	87.61%	87.79%	87.97%	88.13%	88.30%	88.46%	88.62%	88.77%	88.91%	89.05%	89.18%	89.32%	89.44%	89.57%	89.69%	89.81%	89.93%	90.04%	90.15%	90.26%	90.37%	90.48%	90.59%	90.70%	90.80%	90.90%	90.99%	91.09%	91.18%	91.28%	91.37%	91.47%	91.56%	91.65%	91.75%	91.84%	91.94%	92.03%	92.12%	92.22%	92.31%	92.40%	92.50%	92.59%	92.68%	92.78%	92.87%	92.95%	93.04%	93.12%	93.20%	93.27%	93.35%	93.42%	93.49%	93.57%	93.64%	93.71%	93.78%	93.84%	93.90%	93.97%	94.03%	94.10%	94.16%	94.22%	94.28%	94.33%	94.39%	94.44%	94.50%	94.55%	94.60%	94.66%	94.71%	94.76%	94.81%	94.87%	94.92%	94.97%	95.03%	95.08%	95.13%	95.18%	95.24%	95.29%	95.34%	95.40%	95.45%	95.50%	95.55%	95.61%	95.66%	95.71%	95.76%	95.81%	95.86%	95.91%	95.96%	96.01%	96.05%	96.10%	96.15%	96.20%	96.24%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.79%	51.84%	56.23%	59.59%	62.29%	64.43%	66.30%	67.95%	69.27%	70.47%	71.57%	72.62%	73.59%	74.51%	75.37%	76.19%	76.97%	77.71%	78.38%	78.94%	79.45%	79.94%	80.35%	80.77%	81.18%	81.59%	81.98%	82.35%	82.70%	83.04%	83.37%	83.69%	84.00%	84.30%	84.58%	84.86%	85.13%	85.39%	85.62%	85.85%	86.08%	86.31%	86.53%	86.75%	86.94%	87.13%	87.33%	87.52%	87.70%	87.88%	88.05%	88.22%	88.39%	88.55%	88.71%	88.86%	89.00%	89.14%	89.28%	89.41%	89.53%	89.66%	89.78%	89.90%	90.02%	90.14%	90.25%	90.36%	90.47%	90.57%	90.68%	90.79%	90.89%	90.99%	91.08%	91.17%	91.27%	91.36%	91.46%	91.55%	91.64%	91.74%	91.83%	91.93%	92.02%	92.11%	92.21%	92.30%	92.39%	92.49%	92.58%	92.68%	92.77%	92.86%	92.96%	93.04%	93.13%	93.21%	93.29%	93.36%	93.44%	93.51%	93.58%	93.65%	93.72%	93.79%	93.86%	93.93%	93.99%	94.06%	94.12%	94.19%	94.24%	94.30%	94.36%	94.41%	94.47%	94.52%	94.58%	94.63%	94.68%	94.74%	94.79%	94.84%	94.89%	94.95%	95.00%	95.05%	95.11%	95.16%	95.21%	95.26%	95.32%	95.37%	95.42%	95.47%	95.52%	95.58%	95.63%	95.68%	95.73%	95.78%	95.83%	95.88%	95.93%	95.98%	96.03%	96.07%	96.12%	96.17%	96.22%	96.27%	96.31%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.80%	51.85%	56.25%	59.63%	62.33%	64.45%	66.33%	67.98%	69.31%	70.53%	71.63%	72.68%	73.65%	74.56%	75.42%	76.24%	77.01%	77.76%	78.43%	79.00%	79.52%	80.00%	80.42%	80.84%	81.25%	81.66%	82.06%	82.43%	82.78%	83.12%	83.45%	83.77%	84.09%	84.38%	84.66%	84.94%	85.21%	85.48%	85.71%	85.94%	86.17%	86.39%	86.61%	86.82%	87.03%	87.22%	87.41%	87.60%	87.79%	87.97%	88.14%	88.31%	88.47%	88.64%	88.80%	88.95%	89.09%	89.23%	89.37%	89.50%	89.62%	89.75%	89.87%	89.99%	90.11%	90.23%	90.34%	90.45%	90.56%	90.66%	90.77%	90.87%	90.98%	91.07%	91.16%	91.26%	91.35%	91.45%	91.54%	91.64%	91.73%	91.82%	91.92%	92.01%	92.10%	92.20%	92.29%	92.39%	92.48%	92.57%	92.67%	92.76%	92.85%	92.95%	93.04%	93.13%	93.21%	93.29%	93.37%	93.45%	93.52%	93.60%	93.67%	93.74%	93.81%	93.88%	93.95%	94.02%	94.08%	94.15%	94.21%	94.27%	94.33%	94.38%	94.44%	94.49%	94.55%	94.60%	94.66%	94.71%	94.76%	94.81%	94.87%	94.92%	94.97%	95.03%	95.08%	95.13%	95.18%	95.23%	95.29%	95.34%	95.39%	95.44%	95.49%	95.55%	95.60%	95.65%	95.70%	95.75%	95.80%	95.85%	95.90%	95.95%	96.00%	96.05%	96.09%	96.14%	96.19%	96.24%	96.29%	96.34%	96.38%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.80%	51.87%	56.28%	59.67%	62.37%	64.48%	66.35%	68.01%	69.35%	70.58%	71.69%	72.74%	73.71%	74.62%	75.47%	76.29%	77.05%	77.80%	78.48%	79.06%	79.58%	80.06%	80.49%	80.91%	81.32%	81.73%	82.13%	82.51%	82.85%	83.20%	83.53%	83.85%	84.17%	84.46%	84.74%	85.01%	85.29%	85.56%	85.80%	86.03%	86.25%	86.47%	86.69%	86.90%	87.11%	87.30%	87.50%	87.69%	87.87%	88.05%	88.22%	88.39%	88.56%	88.73%	88.89%	89.04%	89.18%	89.32%	89.45%	89.58%	89.71%	89.84%	89.96%	90.08%	90.19%	90.31%	90.43%	90.54%	90.65%	90.75%	90.86%	90.96%	91.06%	91.15%	91.25%	91.34%	91.44%	91.53%	91.63%	91.72%	91.81%	91.91%	92.00%	92.10%	92.19%	92.28%	92.38%	92.47%	92.56%	92.66%	92.75%	92.84%	92.94%	93.03%	93.13%	93.21%	93.30%	93.38%	93.45%	93.53%	93.61%	93.68%	93.75%	93.82%	93.89%	93.96%	94.03%	94.10%	94.17%	94.23%	94.29%	94.35%	94.40%	94.46%	94.52%	94.57%	94.63%	94.68%	94.73%	94.79%	94.84%	94.89%	94.94%	95.00%	95.05%	95.10%	95.15%	95.20%	95.26%	95.31%	95.36%	95.41%	95.46%	95.52%	95.57%	95.62%	95.67%	95.72%	95.78%	95.82%	95.87%	95.92%	95.97%	96.02%	96.07%	96.11%	96.16%	96.21%	96.26%	96.31%	96.36%	96.41%	96.45%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.81%	51.88%	56.31%	59.70%	62.40%	64.51%	66.37%	68.04%	69.40%	70.63%	71.75%	72.79%	73.77%	74.67%	75.53%	76.33%	77.09%	77.84%	78.52%	79.11%	79.64%	80.13%	80.56%	80.97%	81.39%	81.79%	82.20%	82.59%	82.93%	83.27%	83.60%	83.93%	84.24%	84.53%	84.81%	85.09%	85.36%	85.63%	85.88%	86.10%	86.32%	86.54%	86.76%	86.97%	87.18%	87.39%	87.58%	87.77%	87.96%	88.13%	88.31%	88.48%	88.65%	88.82%	88.98%	89.13%	89.27%	89.40%	89.53%	89.67%	89.80%	89.93%	90.05%	90.16%	90.28%	90.40%	90.51%	90.62%	90.74%	90.84%	90.94%	91.04%	91.14%	91.24%	91.33%	91.43%	91.52%	91.62%	91.71%	91.80%	91.90%	91.99%	92.09%	92.18%	92.27%	92.37%	92.46%	92.55%	92.65%	92.74%	92.84%	92.93%	93.02%	93.12%	93.21%	93.29%	93.38%	93.45%	93.53%	93.61%	93.68%	93.76%	93.83%	93.91%	93.98%	94.05%	94.11%	94.18%	94.24%	94.30%	94.36%	94.43%	94.48%	94.54%	94.59%	94.65%	94.70%	94.76%	94.81%	94.86%	94.91%	94.97%	95.02%	95.07%	95.12%	95.17%	95.23%	95.28%	95.33%	95.38%	95.43%	95.49%	95.54%	95.59%	95.64%	95.69%	95.75%	95.80%	95.84%	95.89%	95.94%	95.99%	96.04%	96.09%	96.14%	96.18%	96.23%	96.28%	96.33%	96.38%	96.43%	96.47%	96.52%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.81%	51.90%	56.33%	59.74%	62.44%	64.53%	66.39%	68.07%	69.44%	70.67%	71.80%	72.85%	73.82%	74.71%	75.57%	76.37%	77.14%	77.88%	78.56%	79.17%	79.70%	80.19%	80.62%	81.04%	81.45%	81.86%	82.27%	82.66%	83.01%	83.35%	83.68%	84.00%	84.30%	84.60%	84.88%	85.16%	85.43%	85.69%	85.94%	86.17%	86.40%	86.62%	86.83%	87.05%	87.25%	87.46%	87.67%	87.86%	88.04%	88.22%	88.39%	88.56%	88.74%	88.91%	89.07%	89.22%	89.35%	89.48%	89.61%	89.75%	89.88%	90.01%	90.13%	90.25%	90.36%	90.48%	90.60%	90.71%	90.82%	90.92%	91.03%	91.13%	91.23%	91.32%	91.42%	91.51%	91.61%	91.70%	91.79%	91.89%	91.98%	92.08%	92.17%	92.26%	92.36%	92.45%	92.55%	92.64%	92.73%	92.83%	92.92%	93.01%	93.10%	93.20%	93.29%	93.37%	93.46%	93.53%	93.61%	93.69%	93.76%	93.84%	93.91%	93.99%	94.06%	94.13%	94.19%	94.25%	94.31%	94.38%	94.44%	94.50%	94.56%	94.61%	94.67%	94.72%	94.78%	94.83%	94.88%	94.93%	94.99%	95.04%	95.09%	95.14%	95.20%	95.25%	95.30%	95.35%	95.40%	95.46%	95.51%	95.56%	95.61%	95.66%	95.72%	95.77%	95.82%	95.86%	95.91%	95.96%	96.01%	96.06%	96.11%	96.16%	96.20%	96.25%	96.30%	96.35%	96.40%	96.45%	96.49%	96.54%	96.59%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.82%	51.92%	56.36%	59.78%	62.48%	64.56%	66.41%	68.09%	69.48%	70.72%	71.85%	72.90%	73.88%	74.76%	75.62%	76.42%	77.18%	77.93%	78.60%	79.22%	79.76%	80.25%	80.68%	81.11%	81.51%	81.92%	82.33%	82.73%	83.09%	83.42%	83.75%	84.06%	84.36%	84.66%	84.94%	85.22%	85.49%	85.75%	86.01%	86.24%	86.47%	86.69%	86.91%	87.12%	87.33%	87.54%	87.74%	87.94%	88.13%	88.30%	88.48%	88.65%	88.82%	88.99%	89.15%	89.30%	89.43%	89.56%	89.69%	89.83%	89.96%	90.09%	90.21%	90.33%	90.45%	90.56%	90.68%	90.79%	90.90%	91.01%	91.11%	91.21%	91.31%	91.41%	91.50%	91.60%	91.69%	91.79%	91.88%	91.97%	92.07%	92.16%	92.26%	92.35%	92.44%	92.54%	92.63%	92.72%	92.82%	92.91%	93.00%	93.09%	93.18%	93.27%	93.37%	93.45%	93.53%	93.61%	93.69%	93.77%	93.84%	93.92%	93.99%	94.07%	94.14%	94.20%	94.27%	94.33%	94.39%	94.45%	94.51%	94.57%	94.63%	94.69%	94.74%	94.80%	94.85%	94.90%	94.96%	95.01%	95.06%	95.11%	95.17%	95.22%	95.27%	95.32%	95.37%	95.43%	95.48%	95.53%	95.58%	95.63%	95.69%	95.74%	95.79%	95.84%	95.88%	95.93%	95.98%	96.03%	96.08%	96.13%	96.18%	96.22%	96.27%	96.32%	96.37%	96.42%	96.47%	96.51%	96.56%	96.61%	96.66%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.82%	51.93%	56.39%	59.82%	62.51%	64.58%	66.44%	68.12%	69.51%	70.75%	71.90%	72.95%	73.93%	74.81%	75.66%	76.46%	77.22%	77.97%	78.64%	79.27%	79.81%	80.31%	80.74%	81.16%	81.58%	81.98%	82.39%	82.79%	83.16%	83.49%	83.81%	84.12%	84.42%	84.71%	85.00%	85.28%	85.55%	85.82%	86.07%	86.31%	86.54%	86.76%	86.98%	87.19%	87.40%	87.61%	87.82%	88.03%	88.21%	88.39%	88.56%	88.73%	88.90%	89.07%	89.23%	89.38%	89.51%	89.64%	89.77%	89.90%	90.04%	90.17%	90.29%	90.42%	90.53%	90.65%	90.76%	90.87%	90.98%	91.08%	91.19%	91.30%	91.40%	91.49%	91.59%	91.68%	91.78%	91.87%	91.96%	92.06%	92.15%	92.25%	92.34%	92.43%	92.53%	92.62%	92.71%	92.80%	92.90%	92.99%	93.08%	93.17%	93.26%	93.35%	93.45%	93.53%	93.61%	93.69%	93.77%	93.85%	93.92%	94.00%	94.07%	94.15%	94.22%	94.28%	94.34%	94.40%	94.46%	94.52%	94.59%	94.65%	94.71%	94.76%	94.82%	94.87%	94.93%	94.98%	95.03%	95.08%	95.14%	95.19%	95.24%	95.29%	95.34%	95.40%	95.45%	95.50%	95.55%	95.60%	95.66%	95.71%	95.76%	95.81%	95.86%	95.90%	95.95%	96.00%	96.05%	96.10%	96.15%	96.20%	96.24%	96.29%	96.34%	96.39%	96.44%	96.49%	96.54%	96.58%	96.63%	96.68%	96.72%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.83%	51.95%	56.41%	59.85%	62.54%	64.61%	66.46%	68.15%	69.54%	70.79%	71.95%	73.00%	73.98%	74.86%	75.71%	76.50%	77.26%	78.00%	78.68%	79.31%	79.86%	80.37%	80.80%	81.22%	81.64%	82.04%	82.44%	82.84%	83.23%	83.56%	83.87%	84.17%	84.47%	84.76%	85.05%	85.34%	85.62%	85.88%	86.13%	86.37%	86.61%	86.84%	87.06%	87.27%	87.48%	87.68%	87.89%	88.10%	88.30%	88.47%	88.65%	88.82%	88.99%	89.15%	89.31%	89.46%	89.59%	89.72%	89.85%	89.98%	90.12%	90.25%	90.37%	90.49%	90.62%	90.73%	90.84%	90.95%	91.06%	91.16%	91.27%	91.38%	91.48%	91.58%	91.67%	91.77%	91.86%	91.95%	92.05%	92.14%	92.24%	92.33%	92.42%	92.52%	92.61%	92.70%	92.79%	92.88%	92.97%	93.07%	93.16%	93.25%	93.34%	93.43%	93.52%	93.61%	93.69%	93.77%	93.85%	93.93%	94.00%	94.08%	94.15%	94.22%	94.29%	94.35%	94.41%	94.48%	94.54%	94.60%	94.66%	94.72%	94.78%	94.84%	94.89%	94.94%	95.00%	95.05%	95.10%	95.16%	95.21%	95.26%	95.31%	95.37%	95.42%	95.47%	95.52%	95.57%	95.63%	95.68%	95.73%	95.78%	95.83%	95.88%	95.93%	95.97%	96.02%	96.07%	96.12%	96.17%	96.22%	96.26%	96.31%	96.36%	96.41%	96.46%	96.51%	96.56%	96.60%	96.65%	96.70%	96.74%	96.78%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.83%	51.96%	56.44%	59.89%	62.57%	64.64%	66.48%	68.17%	69.57%	70.83%	72.00%	73.06%	74.03%	74.90%	75.74%	76.54%	77.30%	78.04%	78.72%	79.36%	79.91%	80.42%	80.85%	81.27%	81.69%	82.10%	82.50%	82.89%	83.28%	83.62%	83.92%	84.23%	84.52%	84.82%	85.10%	85.39%	85.68%	85.94%	86.20%	86.43%	86.67%	86.91%	87.13%	87.34%	87.55%	87.76%	87.97%	88.17%	88.38%	88.56%	88.73%	88.90%	89.07%	89.23%	89.39%	89.54%	89.67%	89.80%	89.93%	90.06%	90.20%	90.33%	90.45%	90.57%	90.70%	90.81%	90.92%	91.03%	91.14%	91.24%	91.35%	91.46%	91.56%	91.66%	91.76%	91.85%	91.94%	92.04%	92.13%	92.23%	92.32%	92.41%	92.50%	92.59%	92.69%	92.78%	92.87%	92.96%	93.05%	93.15%	93.24%	93.33%	93.42%	93.51%	93.60%	93.69%	93.77%	93.85%	93.93%	94.01%	94.08%	94.16%	94.23%	94.30%	94.36%	94.43%	94.49%	94.55%	94.61%	94.67%	94.74%	94.80%	94.85%	94.91%	94.96%	95.02%	95.07%	95.13%	95.18%	95.23%	95.28%	95.34%	95.39%	95.44%	95.49%	95.54%	95.60%	95.65%	95.70%	95.75%	95.80%	95.85%	95.90%	95.95%	95.99%	96.04%	96.09%	96.14%	96.19%	96.24%	96.29%	96.33%	96.38%	96.43%	96.48%	96.53%	96.58%	96.62%	96.67%	96.71%	96.76%	96.80%	96.85%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.84%	51.98%	56.47%	59.93%	62.60%	64.66%	66.50%	68.19%	69.60%	70.86%	72.05%	73.10%	74.07%	74.95%	75.78%	76.57%	77.35%	78.08%	78.75%	79.40%	79.96%	80.47%	80.91%	81.33%	81.74%	82.15%	82.55%	82.94%	83.33%	83.67%	83.98%	84.28%	84.58%	84.87%	85.16%	85.45%	85.73%	86.01%	86.26%	86.50%	86.73%	86.97%	87.20%	87.42%	87.62%	87.83%	88.04%	88.25%	88.46%	88.64%	88.81%	88.98%	89.14%	89.31%	89.47%	89.62%	89.75%	89.88%	90.01%	90.14%	90.27%	90.41%	90.53%	90.65%	90.77%	90.89%	91.00%	91.11%	91.22%	91.32%	91.43%	91.54%	91.64%	91.74%	91.84%	91.94%	92.03%	92.12%	92.21%	92.31%	92.40%	92.49%	92.58%	92.67%	92.77%	92.86%	92.95%	93.04%	93.13%	93.22%	93.32%	93.41%	93.50%	93.59%	93.68%	93.77%	93.85%	93.93%	94.01%	94.09%	94.16%	94.23%	94.30%	94.37%	94.44%	94.50%	94.56%	94.62%	94.69%	94.75%	94.81%	94.87%	94.93%	94.98%	95.04%	95.09%	95.15%	95.20%	95.25%	95.31%	95.36%	95.41%	95.46%	95.51%	95.57%	95.62%	95.67%	95.72%	95.77%	95.82%	95.87%	95.92%	95.97%	96.01%	96.06%	96.11%	96.16%	96.21%	96.26%	96.31%	96.35%	96.40%	96.45%	96.50%	96.55%	96.60%	96.64%	96.69%	96.73%	96.78%	96.82%	96.87%	96.91%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.84%	51.99%	56.49%	59.96%	62.63%	64.69%	66.52%	68.21%	69.63%	70.90%	72.09%	73.15%	74.12%	74.99%	75.82%	76.61%	77.39%	78.11%	78.79%	79.43%	80.00%	80.52%	80.96%	81.37%	81.79%	82.20%	82.59%	82.99%	83.37%	83.72%	84.03%	84.33%	84.63%	84.92%	85.21%	85.50%	85.79%	86.06%	86.32%	86.56%	86.80%	87.03%	87.27%	87.49%	87.70%	87.91%	88.11%	88.32%	88.53%	88.72%	88.89%	89.06%	89.22%	89.39%	89.55%	89.69%	89.83%	89.96%	90.09%	90.22%	90.35%	90.49%	90.61%	90.73%	90.84%	90.96%	91.08%	91.19%	91.29%	91.40%	91.51%	91.62%	91.72%	91.82%	91.92%	92.02%	92.11%	92.20%	92.29%	92.38%	92.48%	92.57%	92.66%	92.75%	92.85%	92.94%	93.03%	93.12%	93.21%	93.30%	93.40%	93.49%	93.58%	93.67%	93.76%	93.85%	93.93%	94.01%	94.09%	94.16%	94.23%	94.31%	94.38%	94.45%	94.51%	94.57%	94.64%	94.70%	94.76%	94.82%	94.88%	94.94%	95.00%	95.06%	95.11%	95.17%	95.22%	95.27%	95.33%	95.38%	95.43%	95.48%	95.54%	95.59%	95.64%	95.69%	95.74%	95.79%	95.84%	95.89%	95.94%	95.99%	96.03%	96.08%	96.13%	96.18%	96.23%	96.28%	96.33%	96.37%	96.42%	96.47%	96.52%	96.57%	96.61%	96.66%	96.71%	96.75%	96.79%	96.84%	96.88%	96.93%	96.97%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.85%	52.01%	56.52%	59.99%	62.67%	64.72%	66.54%	68.22%	69.67%	70.93%	72.14%	73.19%	74.17%	75.04%	75.86%	76.65%	77.43%	78.15%	78.82%	79.47%	80.04%	80.56%	81.00%	81.41%	81.83%	82.24%	82.64%	83.03%	83.41%	83.77%	84.08%	84.39%	84.68%	84.97%	85.26%	85.55%	85.84%	86.12%	86.38%	86.62%	86.86%	87.10%	87.34%	87.56%	87.77%	87.98%	88.19%	88.40%	88.60%	88.79%	88.97%	89.14%	89.30%	89.47%	89.63%	89.77%	89.91%	90.04%	90.17%	90.30%	90.43%	90.56%	90.68%	90.80%	90.92%	91.03%	91.15%	91.26%	91.37%	91.48%	91.59%	91.69%	91.80%	91.89%	91.99%	92.09%	92.19%	92.28%	92.37%	92.46%	92.56%	92.65%	92.74%	92.83%	92.92%	93.02%	93.11%	93.20%	93.29%	93.38%	93.48%	93.57%	93.66%	93.75%	93.84%	93.93%	94.01%	94.09%	94.17%	94.24%	94.31%	94.38%	94.45%	94.52%	94.59%	94.65%	94.71%	94.77%	94.83%	94.90%	94.96%	95.02%	95.08%	95.13%	95.19%	95.24%	95.30%	95.35%	95.40%	95.45%	95.51%	95.56%	95.61%	95.66%	95.71%	95.76%	95.81%	95.86%	95.91%	95.96%	96.01%	96.05%	96.10%	96.15%	96.20%	96.25%	96.30%	96.35%	96.39%	96.44%	96.49%	96.54%	96.59%	96.63%	96.68%	96.72%	96.76%	96.81%	96.85%	96.89%	96.94%	96.98%	97.01%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.02%	56.55%	60.02%	62.70%	64.74%	66.56%	68.24%	69.69%	70.96%	72.18%	73.23%	74.21%	75.08%	75.89%	76.68%	77.46%	78.18%	78.85%	79.50%	80.08%	80.60%	81.04%	81.46%	81.87%	82.28%	82.68%	83.07%	83.44%	83.81%	84.13%	84.44%	84.73%	85.03%	85.32%	85.60%	85.89%	86.18%	86.44%	86.69%	86.92%	87.16%	87.40%	87.63%	87.84%	88.05%	88.26%	88.47%	88.67%	88.87%	89.04%	89.21%	89.38%	89.55%	89.71%	89.85%	89.99%	90.12%	90.25%	90.38%	90.51%	90.64%	90.76%	90.87%	90.99%	91.11%	91.23%	91.34%	91.45%	91.56%	91.66%	91.77%	91.87%	91.97%	92.07%	92.16%	92.26%	92.36%	92.45%	92.54%	92.64%	92.73%	92.82%	92.91%	93.00%	93.10%	93.19%	93.28%	93.37%	93.46%	93.55%	93.65%	93.74%	93.83%	93.92%	94.01%	94.09%	94.17%	94.24%	94.31%	94.38%	94.45%	94.52%	94.59%	94.66%	94.72%	94.78%	94.85%	94.91%	94.97%	95.03%	95.09%	95.15%	95.21%	95.26%	95.31%	95.37%	95.42%	95.48%	95.53%	95.58%	95.63%	95.68%	95.73%	95.78%	95.83%	95.88%	95.93%	95.98%	96.03%	96.07%	96.12%	96.17%	96.22%	96.27%	96.32%	96.37%	96.41%	96.46%	96.51%	96.56%	96.60%	96.65%	96.69%	96.73%	96.77%	96.82%	96.86%	96.90%	96.94%	96.98%	97.02%	97.05%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.03%	56.57%	60.04%	62.73%	64.76%	66.58%	68.25%	69.72%	70.99%	72.23%	73.27%	74.25%	75.11%	75.93%	76.72%	77.50%	78.21%	78.88%	79.53%	80.12%	80.63%	81.09%	81.50%	81.91%	82.32%	82.73%	83.11%	83.48%	83.85%	84.18%	84.49%	84.79%	85.08%	85.37%	85.66%	85.95%	86.23%	86.50%	86.75%	86.99%	87.23%	87.46%	87.69%	87.91%	88.13%	88.34%	88.54%	88.74%	88.94%	89.11%	89.29%	89.46%	89.63%	89.79%	89.93%	90.06%	90.20%	90.33%	90.46%	90.59%	90.71%	90.83%	90.95%	91.07%	91.18%	91.30%	91.41%	91.53%	91.63%	91.74%	91.84%	91.95%	92.04%	92.14%	92.24%	92.34%	92.43%	92.53%	92.62%	92.71%	92.81%	92.90%	92.99%	93.08%	93.17%	93.27%	93.36%	93.45%	93.54%	93.63%	93.73%	93.82%	93.91%	94.00%	94.09%	94.17%	94.24%	94.31%	94.39%	94.46%	94.53%	94.60%	94.67%	94.73%	94.80%	94.86%	94.92%	94.98%	95.04%	95.11%	95.17%	95.22%	95.28%	95.33%	95.39%	95.44%	95.50%	95.55%	95.60%	95.65%	95.70%	95.75%	95.80%	95.85%	95.90%	95.95%	96.00%	96.05%	96.09%	96.14%	96.19%	96.24%	96.29%	96.34%	96.38%	96.43%	96.48%	96.52%	96.57%	96.61%	96.65%	96.69%	96.74%	96.78%	96.82%	96.86%	96.90%	96.94%	96.98%	97.01%	97.05%	97.09%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.04%	56.60%	60.07%	62.75%	64.78%	66.60%	68.27%	69.75%	71.02%	72.25%	73.32%	74.29%	75.15%	75.97%	76.76%	77.54%	78.25%	78.91%	79.56%	80.15%	80.66%	81.13%	81.54%	81.95%	82.36%	82.76%	83.15%	83.52%	83.89%	84.23%	84.54%	84.84%	85.13%	85.42%	85.71%	86.00%	86.28%	86.55%	86.81%	87.05%	87.29%	87.53%	87.75%	87.98%	88.20%	88.40%	88.61%	88.81%	89.01%	89.19%	89.36%	89.53%	89.71%	89.87%	90.01%	90.14%	90.28%	90.41%	90.53%	90.66%	90.79%	90.90%	91.02%	91.14%	91.26%	91.37%	91.49%	91.60%	91.71%	91.81%	91.92%	92.02%	92.12%	92.21%	92.31%	92.41%	92.51%	92.60%	92.70%	92.79%	92.89%	92.98%	93.07%	93.16%	93.25%	93.35%	93.44%	93.53%	93.62%	93.71%	93.80%	93.90%	93.99%	94.08%	94.16%	94.24%	94.31%	94.39%	94.46%	94.53%	94.60%	94.67%	94.74%	94.81%	94.87%	94.93%	94.99%	95.06%	95.12%	95.18%	95.24%	95.30%	95.35%	95.41%	95.46%	95.52%	95.57%	95.62%	95.68%	95.72%	95.77%	95.82%	95.87%	95.92%	95.97%	96.02%	96.07%	96.11%	96.16%	96.21%	96.26%	96.30%	96.35%	96.39%	96.44%	96.48%	96.53%	96.57%	96.61%	96.65%	96.69%	96.74%	96.78%	96.82%	96.86%	96.90%	96.93%	96.97%	97.01%	97.05%	97.09%	97.12%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.04%	56.62%	60.10%	62.78%	64.80%	66.63%	68.29%	69.77%	71.05%	72.28%	73.36%	74.33%	75.19%	76.00%	76.79%	77.57%	78.28%	78.95%	79.59%	80.18%	80.70%	81.17%	81.58%	81.99%	82.40%	82.80%	83.18%	83.56%	83.92%	84.28%	84.59%	84.89%	85.19%	85.47%	85.76%	86.05%	86.33%	86.60%	86.86%	87.11%	87.35%	87.59%	87.82%	88.04%	88.26%	88.47%	88.68%	88.88%	89.08%	89.26%	89.44%	89.61%	89.78%	89.95%	90.09%	90.22%	90.36%	90.48%	90.61%	90.73%	90.86%	90.98%	91.10%	91.21%	91.33%	91.45%	91.56%	91.67%	91.78%	91.88%	91.99%	92.09%	92.19%	92.29%	92.39%	92.48%	92.58%	92.68%	92.78%	92.87%	92.97%	93.06%	93.15%	93.24%	93.33%	93.43%	93.52%	93.61%	93.70%	93.79%	93.88%	93.98%	94.07%	94.16%	94.24%	94.31%	94.39%	94.46%	94.53%	94.61%	94.68%	94.75%	94.82%	94.88%	94.94%	95.01%	95.07%	95.13%	95.19%	95.25%	95.31%	95.37%	95.43%	95.48%	95.54%	95.59%	95.64%	95.70%	95.74%	95.79%	95.84%	95.89%	95.94%	95.99%	96.03%	96.08%	96.13%	96.17%	96.22%	96.26%	96.31%	96.35%	96.40%	96.44%	96.49%	96.53%	96.57%	96.61%	96.65%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.00%	97.04%	97.08%	97.12%	97.16%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.05%	56.65%	60.12%	62.81%	64.83%	66.65%	68.30%	69.79%	71.08%	72.30%	73.40%	74.36%	75.23%	76.04%	76.83%	77.60%	78.31%	78.98%	79.63%	80.21%	80.73%	81.21%	81.62%	82.03%	82.43%	82.83%	83.22%	83.59%	83.96%	84.32%	84.64%	84.95%	85.24%	85.53%	85.82%	86.10%	86.39%	86.66%	86.92%	87.17%	87.42%	87.65%	87.88%	88.10%	88.32%	88.54%	88.74%	88.95%	89.15%	89.34%	89.51%	89.68%	89.86%	90.03%	90.17%	90.30%	90.43%	90.56%	90.68%	90.81%	90.93%	91.05%	91.17%	91.29%	91.40%	91.52%	91.63%	91.75%	91.85%	91.96%	92.06%	92.17%	92.26%	92.36%	92.46%	92.56%	92.66%	92.75%	92.85%	92.95%	93.04%	93.14%	93.23%	93.32%	93.41%	93.50%	93.60%	93.69%	93.78%	93.87%	93.96%	94.06%	94.15%	94.23%	94.31%	94.39%	94.46%	94.54%	94.61%	94.68%	94.75%	94.82%	94.89%	94.96%	95.02%	95.08%	95.14%	95.20%	95.27%	95.33%	95.39%	95.44%	95.50%	95.55%	95.61%	95.66%	95.71%	95.76%	95.81%	95.86%	95.90%	95.95%	95.99%	96.04%	96.08%	96.13%	96.17%	96.22%	96.26%	96.31%	96.35%	96.40%	96.44%	96.48%	96.53%	96.57%	96.61%	96.65%	96.69%	96.73%	96.77%	96.81%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.07%	97.11%	97.15%	97.19%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.05%	56.68%	60.15%	62.83%	64.85%	66.67%	68.31%	69.81%	71.10%	72.32%	73.43%	74.40%	75.26%	76.08%	76.87%	77.63%	78.34%	79.01%	79.66%	80.24%	80.76%	81.25%	81.66%	82.07%	82.46%	82.86%	83.25%	83.63%	84.00%	84.35%	84.69%	84.99%	85.29%	85.58%	85.87%	86.16%	86.44%	86.71%	86.97%	87.23%	87.48%	87.72%	87.94%	88.16%	88.38%	88.60%	88.81%	89.01%	89.22%	89.41%	89.58%	89.76%	89.93%	90.10%	90.25%	90.38%	90.50%	90.63%	90.76%	90.88%	91.01%	91.13%	91.24%	91.36%	91.48%	91.60%	91.71%	91.82%	91.93%	92.03%	92.14%	92.24%	92.34%	92.44%	92.53%	92.63%	92.73%	92.83%	92.92%	93.02%	93.12%	93.22%	93.31%	93.40%	93.49%	93.58%	93.68%	93.77%	93.86%	93.95%	94.04%	94.13%	94.23%	94.31%	94.38%	94.46%	94.54%	94.61%	94.68%	94.75%	94.82%	94.89%	94.96%	95.03%	95.09%	95.15%	95.21%	95.27%	95.33%	95.40%	95.46%	95.51%	95.57%	95.62%	95.67%	95.72%	95.77%	95.82%	95.86%	95.90%	95.95%	95.99%	96.04%	96.08%	96.13%	96.17%	96.22%	96.26%	96.30%	96.35%	96.39%	96.44%	96.48%	96.52%	96.56%	96.60%	96.64%	96.68%	96.72%	96.76%	96.80%	96.84%	96.88%	96.91%	96.95%	96.99%	97.03%	97.07%	97.11%	97.15%	97.18%	97.22%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.06%	56.70%	60.17%	62.86%	64.87%	66.68%	68.32%	69.82%	71.13%	72.34%	73.47%	74.43%	75.30%	76.11%	76.90%	77.67%	78.37%	79.04%	79.69%	80.27%	80.79%	81.29%	81.70%	82.10%	82.50%	82.89%	83.28%	83.67%	84.03%	84.39%	84.73%	85.04%	85.34%	85.63%	85.92%	86.21%	86.49%	86.76%	87.02%	87.28%	87.53%	87.78%	88.00%	88.22%	88.44%	88.66%	88.87%	89.08%	89.29%	89.48%	89.66%	89.83%	90.00%	90.17%	90.32%	90.45%	90.58%	90.70%	90.83%	90.96%	91.08%	91.20%	91.32%	91.44%	91.55%	91.67%	91.78%	91.90%	92.00%	92.11%	92.21%	92.32%	92.41%	92.51%	92.61%	92.71%	92.80%	92.90%	93.00%	93.10%	93.19%	93.29%	93.39%	93.48%	93.57%	93.66%	93.76%	93.85%	93.94%	94.03%	94.12%	94.21%	94.30%	94.38%	94.46%	94.53%	94.61%	94.68%	94.75%	94.82%	94.89%	94.96%	95.03%	95.10%	95.16%	95.22%	95.28%	95.34%	95.40%	95.46%	95.52%	95.58%	95.63%	95.67%	95.72%	95.77%	95.81%	95.86%	95.90%	95.95%	95.99%	96.04%	96.08%	96.12%	96.17%	96.21%	96.26%	96.30%	96.35%	96.39%	96.44%	96.48%	96.52%	96.56%	96.60%	96.64%	96.68%	96.71%	96.75%	96.79%	96.83%	96.87%	96.91%	96.95%	96.98%	97.02%	97.06%	97.10%	97.14%	97.18%	97.22%	97.25%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.06%	56.73%	60.19%	62.89%	64.89%	66.70%	68.33%	69.83%	71.15%	72.36%	73.51%	74.46%	75.34%	76.15%	76.94%	77.70%	78.40%	79.07%	79.72%	80.29%	80.82%	81.32%	81.73%	82.13%	82.53%	82.92%	83.31%	83.70%	84.07%	84.43%	84.78%	85.09%	85.39%	85.69%	85.97%	86.26%	86.55%	86.82%	87.08%	87.33%	87.59%	87.83%	88.06%	88.28%	88.50%	88.72%	88.94%	89.15%	89.35%	89.55%	89.73%	89.91%	90.08%	90.24%	90.39%	90.52%	90.65%	90.78%	90.90%	91.03%	91.16%	91.27%	91.39%	91.51%	91.63%	91.74%	91.86%	91.97%	92.08%	92.18%	92.29%	92.39%	92.49%	92.58%	92.68%	92.78%	92.88%	92.97%	93.07%	93.17%	93.27%	93.36%	93.46%	93.56%	93.65%	93.74%	93.83%	93.92%	94.01%	94.10%	94.20%	94.29%	94.37%	94.45%	94.52%	94.60%	94.68%	94.75%	94.82%	94.89%	94.96%	95.03%	95.10%	95.16%	95.22%	95.28%	95.34%	95.40%	95.46%	95.52%	95.57%	95.62%	95.67%	95.72%	95.76%	95.81%	95.86%	95.90%	95.94%	95.99%	96.03%	96.08%	96.12%	96.17%	96.21%	96.26%	96.30%	96.34%	96.39%	96.43%	96.47%	96.51%	96.55%	96.59%	96.63%	96.67%	96.71%	96.75%	96.79%	96.82%	96.86%	96.90%	96.94%	96.98%	97.02%	97.05%	97.09%	97.13%	97.17%	97.21%	97.25%	97.28%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.07%	56.76%	60.21%	62.91%	64.91%	66.71%	68.34%	69.84%	71.17%	72.38%	73.54%	74.49%	75.37%	76.19%	76.97%	77.73%	78.44%	79.10%	79.75%	80.32%	80.85%	81.35%	81.77%	82.17%	82.56%	82.95%	83.35%	83.73%	84.11%	84.46%	84.82%	85.14%	85.44%	85.74%	86.03%	86.32%	86.60%	86.87%	87.13%	87.38%	87.63%	87.88%	88.12%	88.34%	88.56%	88.78%	89.00%	89.21%	89.42%	89.62%	89.81%	89.98%	90.15%	90.31%	90.46%	90.59%	90.73%	90.85%	90.98%	91.10%	91.23%	91.35%	91.47%	91.58%	91.70%	91.82%	91.93%	92.04%	92.15%	92.25%	92.36%	92.46%	92.56%	92.66%	92.75%	92.85%	92.95%	93.04%	93.14%	93.24%	93.34%	93.43%	93.53%	93.63%	93.72%	93.81%	93.90%	94.00%	94.09%	94.18%	94.27%	94.36%	94.44%	94.51%	94.59%	94.67%	94.74%	94.81%	94.88%	94.95%	95.02%	95.09%	95.16%	95.22%	95.28%	95.34%	95.40%	95.45%	95.51%	95.56%	95.61%	95.66%	95.71%	95.76%	95.81%	95.85%	95.90%	95.94%	95.99%	96.03%	96.08%	96.12%	96.17%	96.21%	96.25%	96.30%	96.34%	96.39%	96.43%	96.47%	96.51%	96.55%	96.59%	96.62%	96.66%	96.70%	96.74%	96.78%	96.82%	96.86%	96.89%	96.93%	96.97%	97.01%	97.05%	97.09%	97.12%	97.16%	97.20%	97.24%	97.28%	97.32%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.07%	56.78%	60.23%	62.94%	64.93%	66.73%	68.35%	69.85%	71.19%	72.40%	73.57%	74.52%	75.41%	76.22%	77.00%	77.76%	78.47%	79.14%	79.78%	80.34%	80.87%	81.37%	81.80%	82.20%	82.59%	82.99%	83.38%	83.76%	84.14%	84.50%	84.86%	85.19%	85.49%	85.79%	86.08%	86.37%	86.65%	86.92%	87.18%	87.43%	87.68%	87.93%	88.17%	88.39%	88.61%	88.83%	89.05%	89.27%	89.49%	89.69%	89.88%	90.05%	90.22%	90.38%	90.53%	90.66%	90.79%	90.93%	91.05%	91.18%	91.30%	91.42%	91.54%	91.66%	91.77%	91.89%	92.00%	92.11%	92.22%	92.32%	92.43%	92.53%	92.63%	92.73%	92.82%	92.92%	93.02%	93.11%	93.21%	93.31%	93.40%	93.50%	93.60%	93.69%	93.79%	93.88%	93.98%	94.07%	94.16%	94.25%	94.34%	94.42%	94.50%	94.58%	94.65%	94.73%	94.80%	94.87%	94.94%	95.01%	95.08%	95.15%	95.21%	95.27%	95.33%	95.39%	95.45%	95.51%	95.56%	95.61%	95.66%	95.71%	95.75%	95.80%	95.85%	95.89%	95.94%	95.98%	96.03%	96.07%	96.12%	96.16%	96.21%	96.25%	96.30%	96.34%	96.38%	96.42%	96.46%	96.50%	96.54%	96.58%	96.62%	96.66%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.96%	97.00%	97.04%	97.08%	97.12%	97.16%	97.20%	97.23%	97.27%	97.31%	97.35%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.08%	56.80%	60.25%	62.97%	64.95%	66.75%	68.36%	69.87%	71.20%	72.42%	73.60%	74.56%	75.45%	76.26%	77.03%	77.79%	78.50%	79.17%	79.82%	80.36%	80.90%	81.40%	81.83%	82.23%	82.62%	83.02%	83.41%	83.80%	84.18%	84.54%	84.89%	85.23%	85.54%	85.84%	86.13%	86.42%	86.70%	86.97%	87.23%	87.48%	87.73%	87.98%	88.22%	88.45%	88.67%	88.89%	89.11%	89.33%	89.55%	89.76%	89.95%	90.12%	90.29%	90.45%	90.60%	90.73%	90.86%	91.00%	91.13%	91.25%	91.38%	91.50%	91.61%	91.73%	91.85%	91.96%	92.07%	92.18%	92.29%	92.39%	92.50%	92.60%	92.70%	92.79%	92.89%	92.99%	93.08%	93.18%	93.27%	93.37%	93.47%	93.56%	93.66%	93.76%	93.85%	93.95%	94.04%	94.13%	94.23%	94.32%	94.41%	94.49%	94.56%	94.64%	94.71%	94.79%	94.86%	94.93%	95.00%	95.07%	95.14%	95.21%	95.27%	95.33%	95.38%	95.44%	95.50%	95.55%	95.60%	95.65%	95.70%	95.75%	95.80%	95.84%	95.89%	95.94%	95.98%	96.03%	96.07%	96.12%	96.16%	96.21%	96.25%	96.29%	96.34%	96.38%	96.42%	96.46%	96.49%	96.53%	96.57%	96.61%	96.65%	96.69%	96.73%	96.76%	96.80%	96.84%	96.88%	96.92%	96.96%	96.99%	97.03%	97.07%	97.11%	97.15%	97.19%	97.22%	97.26%	97.30%	97.34%	97.38%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.08%	56.82%	60.28%	62.99%	64.97%	66.76%	68.37%	69.88%	71.22%	72.45%	73.63%	74.59%	75.48%	76.29%	77.06%	77.82%	78.53%	79.20%	79.85%	80.38%	80.92%	81.42%	81.87%	82.26%	82.66%	83.05%	83.44%	83.83%	84.21%	84.57%	84.93%	85.28%	85.58%	85.89%	86.19%	86.47%	86.76%	87.02%	87.27%	87.53%	87.78%	88.03%	88.27%	88.50%	88.73%	88.95%	89.17%	89.39%	89.61%	89.82%	90.02%	90.19%	90.35%	90.52%	90.66%	90.80%	90.93%	91.07%	91.20%	91.33%	91.45%	91.57%	91.69%	91.80%	91.91%	92.03%	92.14%	92.25%	92.35%	92.46%	92.56%	92.66%	92.76%	92.86%	92.95%	93.05%	93.15%	93.24%	93.34%	93.43%	93.53%	93.63%	93.72%	93.82%	93.91%	94.01%	94.11%	94.20%	94.29%	94.38%	94.47%	94.55%	94.62%	94.70%	94.77%	94.85%	94.92%	94.99%	95.06%	95.13%	95.20%	95.26%	95.32%	95.38%	95.44%	95.49%	95.54%	95.59%	95.64%	95.69%	95.74%	95.79%	95.84%	95.88%	95.93%	95.98%	96.02%	96.07%	96.11%	96.16%	96.20%	96.25%	96.29%	96.33%	96.37%	96.41%	96.45%	96.48%	96.52%	96.56%	96.60%	96.64%	96.68%	96.71%	96.75%	96.79%	96.83%	96.87%	96.91%	96.94%	96.98%	97.02%	97.06%	97.10%	97.14%	97.17%	97.21%	97.25%	97.29%	97.33%	97.37%	97.40%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.09%	56.84%	60.29%	63.02%	65.00%	66.78%	68.37%	69.89%	71.23%	72.47%	73.65%	74.62%	75.51%	76.32%	77.09%	77.86%	78.56%	79.23%	79.87%	80.41%	80.93%	81.44%	81.90%	82.29%	82.69%	83.08%	83.47%	83.86%	84.24%	84.61%	84.97%	85.32%	85.63%	85.93%	86.23%	86.53%	86.80%	87.07%	87.32%	87.57%	87.82%	88.08%	88.32%	88.56%	88.79%	89.01%	89.23%	89.45%	89.66%	89.87%	90.07%	90.26%	90.42%	90.59%	90.73%	90.87%	91.00%	91.13%	91.27%	91.40%	91.53%	91.64%	91.76%	91.87%	91.98%	92.10%	92.21%	92.31%	92.42%	92.52%	92.62%	92.73%	92.82%	92.92%	93.02%	93.11%	93.21%	93.31%	93.40%	93.50%	93.59%	93.69%	93.79%	93.88%	93.98%	94.07%	94.17%	94.27%	94.36%	94.45%	94.53%	94.60%	94.68%	94.75%	94.83%	94.90%	94.98%	95.05%	95.12%	95.19%	95.25%	95.31%	95.37%	95.43%	95.48%	95.53%	95.58%	95.63%	95.68%	95.73%	95.78%	95.83%	95.88%	95.92%	95.97%	96.02%	96.06%	96.11%	96.15%	96.19%	96.24%	96.28%	96.32%	96.36%	96.40%	96.43%	96.47%	96.51%	96.55%	96.59%	96.63%	96.66%	96.70%	96.74%	96.78%	96.82%	96.86%	96.89%	96.93%	96.97%	97.01%	97.05%	97.09%	97.12%	97.16%	97.20%	97.24%	97.28%	97.32%	97.35%	97.39%	97.43%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.09%	56.86%	60.31%	63.05%	65.02%	66.79%	68.38%	69.90%	71.25%	72.49%	73.67%	74.65%	75.54%	76.35%	77.13%	77.89%	78.59%	79.26%	79.89%	80.43%	80.95%	81.46%	81.93%	82.32%	82.72%	83.11%	83.50%	83.89%	84.27%	84.64%	85.00%	85.35%	85.68%	85.98%	86.28%	86.57%	86.85%	87.12%	87.37%	87.62%	87.87%	88.12%	88.37%	88.61%	88.84%	89.07%	89.29%	89.50%	89.72%	89.93%	90.13%	90.32%	90.49%	90.66%	90.80%	90.94%	91.07%	91.20%	91.34%	91.47%	91.60%	91.71%	91.83%	91.94%	92.05%	92.16%	92.27%	92.38%	92.48%	92.58%	92.69%	92.79%	92.89%	92.98%	93.08%	93.17%	93.27%	93.37%	93.46%	93.56%	93.65%	93.75%	93.85%	93.94%	94.04%	94.13%	94.23%	94.32%	94.42%	94.50%	94.58%	94.66%	94.73%	94.81%	94.88%	94.96%	95.03%	95.10%	95.17%	95.24%	95.30%	95.36%	95.41%	95.47%	95.52%	95.57%	95.62%	95.67%	95.72%	95.77%	95.82%	95.86%	95.91%	95.96%	96.01%	96.05%	96.10%	96.14%	96.19%	96.23%	96.27%	96.31%	96.35%	96.38%	96.42%	96.46%	96.50%	96.54%	96.58%	96.61%	96.65%	96.69%	96.73%	96.77%	96.81%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.07%	97.11%	97.15%	97.19%	97.23%	97.27%	97.30%	97.34%	97.38%	97.42%	97.45%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.10%	56.88%	60.33%	63.07%	65.04%	66.80%	68.38%	69.91%	71.27%	72.51%	73.69%	74.67%	75.57%	76.38%	77.16%	77.92%	78.63%	79.29%	79.91%	80.44%	80.97%	81.48%	81.96%	82.36%	82.75%	83.14%	83.54%	83.92%	84.30%	84.67%	85.04%	85.39%	85.73%	86.03%	86.32%	86.62%	86.90%	87.16%	87.42%	87.67%	87.92%	88.17%	88.41%	88.66%	88.89%	89.12%	89.34%	89.56%	89.77%	89.98%	90.19%	90.39%	90.56%	90.72%	90.87%	91.00%	91.14%	91.27%	91.41%	91.54%	91.67%	91.78%	91.89%	92.01%	92.12%	92.23%	92.34%	92.44%	92.54%	92.65%	92.75%	92.85%	92.94%	93.04%	93.14%	93.23%	93.33%	93.42%	93.52%	93.62%	93.71%	93.81%	93.90%	94.00%	94.10%	94.19%	94.29%	94.38%	94.48%	94.56%	94.63%	94.71%	94.78%	94.86%	94.93%	95.01%	95.08%	95.15%	95.22%	95.29%	95.35%	95.40%	95.46%	95.51%	95.56%	95.61%	95.66%	95.70%	95.75%	95.80%	95.85%	95.90%	95.95%	96.00%	96.04%	96.09%	96.13%	96.18%	96.22%	96.26%	96.29%	96.33%	96.37%	96.41%	96.45%	96.49%	96.53%	96.56%	96.60%	96.64%	96.68%	96.72%	96.76%	96.79%	96.83%	96.87%	96.91%	96.95%	96.99%	97.02%	97.06%	97.10%	97.14%	97.18%	97.22%	97.25%	97.29%	97.33%	97.37%	97.41%	97.44%	97.48%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.10%	56.90%	60.34%	63.10%	65.06%	66.81%	68.39%	69.92%	71.28%	72.53%	73.72%	74.70%	75.59%	76.42%	77.19%	77.95%	78.66%	79.33%	79.94%	80.46%	80.98%	81.50%	81.98%	82.39%	82.78%	83.18%	83.57%	83.95%	84.33%	84.70%	85.07%	85.43%	85.77%	86.07%	86.37%	86.66%	86.95%	87.21%	87.46%	87.72%	87.97%	88.22%	88.46%	88.70%	88.94%	89.18%	89.40%	89.61%	89.82%	90.03%	90.24%	90.45%	90.63%	90.79%	90.94%	91.07%	91.21%	91.34%	91.47%	91.60%	91.73%	91.85%	91.96%	92.08%	92.19%	92.29%	92.40%	92.50%	92.61%	92.71%	92.81%	92.91%	93.00%	93.10%	93.19%	93.29%	93.39%	93.48%	93.58%	93.67%	93.77%	93.87%	93.96%	94.06%	94.15%	94.25%	94.35%	94.44%	94.53%	94.61%	94.69%	94.76%	94.84%	94.91%	94.99%	95.06%	95.14%	95.21%	95.28%	95.33%	95.39%	95.44%	95.49%	95.54%	95.59%	95.64%	95.69%	95.74%	95.79%	95.84%	95.89%	95.94%	95.99%	96.03%	96.08%	96.13%	96.17%	96.21%	96.24%	96.28%	96.32%	96.36%	96.40%	96.44%	96.47%	96.51%	96.55%	96.59%	96.63%	96.67%	96.71%	96.74%	96.78%	96.82%	96.86%	96.90%	96.94%	96.97%	97.01%	97.05%	97.09%	97.13%	97.17%	97.20%	97.24%	97.28%	97.32%	97.36%	97.39%	97.43%	97.47%	97.51%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.11%	56.93%	60.36%	63.12%	65.08%	66.82%	68.39%	69.93%	71.30%	72.55%	73.74%	74.73%	75.62%	76.45%	77.22%	77.98%	78.69%	79.36%	79.95%	80.48%	81.00%	81.52%	82.00%	82.42%	82.81%	83.21%	83.60%	83.99%	84.37%	84.74%	85.11%	85.46%	85.80%	86.11%	86.41%	86.70%	86.99%	87.26%	87.51%	87.76%	88.02%	88.27%	88.51%	88.75%	88.99%	89.22%	89.45%	89.66%	89.88%	90.09%	90.30%	90.50%	90.69%	90.86%	91.01%	91.14%	91.28%	91.41%	91.53%	91.66%	91.79%	91.92%	92.03%	92.14%	92.25%	92.36%	92.46%	92.57%	92.67%	92.77%	92.87%	92.96%	93.06%	93.16%	93.25%	93.35%	93.44%	93.54%	93.64%	93.73%	93.83%	93.92%	94.02%	94.12%	94.21%	94.31%	94.40%	94.50%	94.59%	94.66%	94.74%	94.81%	94.89%	94.97%	95.04%	95.12%	95.19%	95.26%	95.32%	95.38%	95.43%	95.48%	95.53%	95.58%	95.63%	95.68%	95.73%	95.78%	95.83%	95.88%	95.93%	95.98%	96.02%	96.07%	96.11%	96.15%	96.19%	96.23%	96.27%	96.31%	96.35%	96.39%	96.42%	96.46%	96.50%	96.54%	96.58%	96.62%	96.65%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.92%	96.96%	97.00%	97.04%	97.08%	97.12%	97.15%	97.19%	97.23%	97.27%	97.31%	97.34%	97.38%	97.42%	97.46%	97.50%	97.53%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.11%	56.95%	60.37%	63.14%	65.10%	66.83%	68.40%	69.94%	71.31%	72.57%	73.76%	74.75%	75.65%	76.48%	77.25%	78.01%	78.72%	79.39%	79.97%	80.49%	81.01%	81.53%	82.02%	82.45%	82.85%	83.24%	83.63%	84.02%	84.40%	84.77%	85.14%	85.50%	85.84%	86.16%	86.45%	86.74%	87.03%	87.31%	87.56%	87.81%	88.06%	88.31%	88.56%	88.80%	89.03%	89.27%	89.49%	89.72%	89.93%	90.14%	90.35%	90.56%	90.75%	90.93%	91.08%	91.21%	91.34%	91.47%	91.60%	91.73%	91.86%	91.98%	92.10%	92.21%	92.32%	92.42%	92.53%	92.63%	92.73%	92.83%	92.93%	93.02%	93.12%	93.21%	93.31%	93.41%	93.50%	93.60%	93.69%	93.79%	93.89%	93.98%	94.08%	94.17%	94.27%	94.37%	94.46%	94.56%	94.64%	94.72%	94.79%	94.87%	94.94%	95.02%	95.09%	95.17%	95.24%	95.30%	95.36%	95.42%	95.47%	95.52%	95.57%	95.62%	95.67%	95.72%	95.77%	95.82%	95.86%	95.91%	95.96%	96.01%	96.06%	96.10%	96.14%	96.18%	96.22%	96.26%	96.30%	96.34%	96.37%	96.41%	96.45%	96.49%	96.53%	96.57%	96.60%	96.64%	96.68%	96.72%	96.76%	96.80%	96.83%	96.87%	96.91%	96.95%	96.99%	97.03%	97.06%	97.10%	97.14%	97.18%	97.22%	97.26%	97.29%	97.33%	97.37%	97.41%	97.45%	97.49%	97.52%	97.56%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.12%	56.97%	60.39%	63.17%	65.11%	66.84%	68.40%	69.95%	71.33%	72.59%	73.78%	74.78%	75.67%	76.51%	77.28%	78.05%	78.75%	79.42%	79.99%	80.51%	81.03%	81.55%	82.04%	82.48%	82.88%	83.27%	83.66%	84.05%	84.43%	84.80%	85.17%	85.52%	85.87%	86.20%	86.49%	86.79%	87.08%	87.35%	87.61%	87.86%	88.11%	88.36%	88.61%	88.84%	89.08%	89.31%	89.54%	89.77%	89.98%	90.19%	90.40%	90.61%	90.81%	90.99%	91.14%	91.27%	91.40%	91.53%	91.66%	91.79%	91.92%	92.04%	92.16%	92.28%	92.38%	92.48%	92.59%	92.69%	92.79%	92.89%	92.98%	93.08%	93.18%	93.27%	93.37%	93.46%	93.56%	93.66%	93.75%	93.85%	93.95%	94.04%	94.14%	94.23%	94.33%	94.42%	94.52%	94.61%	94.69%	94.77%	94.84%	94.92%	95.00%	95.07%	95.14%	95.22%	95.28%	95.35%	95.41%	95.46%	95.51%	95.56%	95.61%	95.66%	95.70%	95.75%	95.80%	95.85%	95.90%	95.95%	96.00%	96.04%	96.08%	96.12%	96.17%	96.21%	96.25%	96.28%	96.32%	96.36%	96.40%	96.44%	96.48%	96.52%	96.55%	96.59%	96.63%	96.67%	96.71%	96.75%	96.78%	96.82%	96.86%	96.90%	96.94%	96.98%	97.01%	97.05%	97.09%	97.13%	97.17%	97.21%	97.24%	97.28%	97.32%	97.36%	97.40%	97.44%	97.47%	97.51%	97.55%	97.59%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.13%	56.99%	60.40%	63.19%	65.13%	66.85%	68.41%	69.96%	71.34%	72.61%	73.80%	74.80%	75.70%	76.54%	77.32%	78.08%	78.79%	79.45%	80.00%	80.52%	81.05%	81.56%	82.06%	82.51%	82.91%	83.30%	83.69%	84.08%	84.46%	84.83%	85.19%	85.55%	85.90%	86.23%	86.53%	86.83%	87.12%	87.39%	87.65%	87.91%	88.16%	88.41%	88.65%	88.88%	89.12%	89.35%	89.59%	89.81%	90.03%	90.25%	90.45%	90.66%	90.87%	91.05%	91.21%	91.34%	91.47%	91.60%	91.73%	91.85%	91.98%	92.11%	92.22%	92.34%	92.44%	92.55%	92.65%	92.75%	92.85%	92.95%	93.04%	93.14%	93.23%	93.33%	93.43%	93.52%	93.62%	93.72%	93.81%	93.91%	94.00%	94.10%	94.19%	94.29%	94.39%	94.48%	94.58%	94.67%	94.75%	94.82%	94.90%	94.97%	95.05%	95.12%	95.19%	95.26%	95.33%	95.39%	95.44%	95.49%	95.54%	95.59%	95.64%	95.69%	95.74%	95.79%	95.84%	95.89%	95.93%	95.98%	96.03%	96.07%	96.11%	96.15%	96.19%	96.23%	96.27%	96.31%	96.35%	96.39%	96.43%	96.47%	96.50%	96.54%	96.58%	96.62%	96.66%	96.70%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.96%	97.00%	97.04%	97.08%	97.12%	97.16%	97.19%	97.23%	97.27%	97.31%	97.35%	97.39%	97.42%	97.46%	97.50%	97.54%	97.58%	97.61%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.13%	57.01%	60.42%	63.21%	65.15%	66.85%	68.41%	69.97%	71.36%	72.62%	73.82%	74.83%	75.73%	76.57%	77.35%	78.11%	78.82%	79.48%	80.02%	80.54%	81.06%	81.58%	82.08%	82.55%	82.94%	83.34%	83.73%	84.11%	84.49%	84.86%	85.22%	85.58%	85.93%	86.27%	86.58%	86.87%	87.16%	87.44%	87.70%	87.95%	88.21%	88.45%	88.69%	88.93%	89.16%	89.39%	89.63%	89.86%	90.09%	90.30%	90.51%	90.72%	90.92%	91.11%	91.27%	91.40%	91.53%	91.66%	91.79%	91.92%	92.05%	92.17%	92.28%	92.39%	92.51%	92.61%	92.71%	92.81%	92.91%	93.00%	93.10%	93.20%	93.29%	93.39%	93.49%	93.58%	93.68%	93.77%	93.87%	93.97%	94.06%	94.16%	94.25%	94.35%	94.44%	94.54%	94.64%	94.72%	94.80%	94.87%	94.95%	95.02%	95.10%	95.17%	95.24%	95.31%	95.38%	95.43%	95.48%	95.53%	95.58%	95.63%	95.68%	95.73%	95.78%	95.83%	95.87%	95.92%	95.96%	96.01%	96.05%	96.09%	96.14%	96.18%	96.22%	96.26%	96.30%	96.34%	96.38%	96.41%	96.45%	96.49%	96.53%	96.57%	96.61%	96.65%	96.68%	96.72%	96.76%	96.80%	96.84%	96.88%	96.91%	96.95%	96.99%	97.03%	97.07%	97.11%	97.14%	97.18%	97.22%	97.26%	97.30%	97.34%	97.37%	97.41%	97.45%	97.49%	97.53%	97.57%	97.60%	97.64%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.14%	57.03%	60.44%	63.22%	65.16%	66.86%	68.42%	69.97%	71.37%	72.64%	73.84%	74.86%	75.75%	76.61%	77.38%	78.14%	78.85%	79.50%	80.03%	80.56%	81.08%	81.59%	82.10%	82.57%	82.97%	83.37%	83.76%	84.14%	84.52%	84.88%	85.25%	85.60%	85.96%	86.30%	86.62%	86.91%	87.20%	87.48%	87.74%	88.00%	88.25%	88.49%	88.73%	88.97%	89.20%	89.44%	89.67%	89.91%	90.13%	90.35%	90.56%	90.77%	90.98%	91.16%	91.32%	91.46%	91.59%	91.72%	91.85%	91.98%	92.11%	92.23%	92.34%	92.45%	92.56%	92.68%	92.77%	92.87%	92.97%	93.06%	93.16%	93.25%	93.35%	93.45%	93.54%	93.64%	93.74%	93.83%	93.93%	94.02%	94.12%	94.22%	94.31%	94.41%	94.50%	94.60%	94.69%	94.77%	94.85%	94.93%	95.00%	95.07%	95.14%	95.21%	95.29%	95.36%	95.41%	95.47%	95.52%	95.57%	95.62%	95.67%	95.72%	95.76%	95.81%	95.86%	95.90%	95.95%	95.99%	96.03%	96.08%	96.12%	96.16%	96.20%	96.25%	96.29%	96.33%	96.36%	96.40%	96.44%	96.48%	96.52%	96.56%	96.60%	96.63%	96.67%	96.71%	96.75%	96.79%	96.83%	96.86%	96.90%	96.94%	96.98%	97.02%	97.06%	97.09%	97.13%	97.17%	97.21%	97.25%	97.29%	97.32%	97.36%	97.40%	97.44%	97.48%	97.52%	97.55%	97.59%	97.63%	97.67%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.14%	57.05%	60.45%	63.24%	65.18%	66.86%	68.42%	69.98%	71.38%	72.66%	73.86%	74.88%	75.78%	76.64%	77.41%	78.17%	78.88%	79.52%	80.05%	80.57%	81.09%	81.61%	82.11%	82.60%	83.00%	83.40%	83.79%	84.18%	84.54%	84.91%	85.27%	85.63%	85.99%	86.33%	86.66%	86.96%	87.25%	87.52%	87.79%	88.05%	88.29%	88.54%	88.78%	89.01%	89.24%	89.48%	89.71%	89.95%	90.18%	90.40%	90.61%	90.82%	91.03%	91.21%	91.38%	91.53%	91.66%	91.79%	91.92%	92.04%	92.17%	92.29%	92.40%	92.51%	92.62%	92.73%	92.83%	92.93%	93.02%	93.12%	93.22%	93.31%	93.41%	93.51%	93.60%	93.70%	93.79%	93.89%	93.99%	94.08%	94.18%	94.27%	94.37%	94.47%	94.56%	94.66%	94.74%	94.83%	94.90%	94.97%	95.05%	95.12%	95.19%	95.26%	95.33%	95.40%	95.45%	95.50%	95.56%	95.61%	95.65%	95.70%	95.75%	95.80%	95.84%	95.89%	95.93%	95.97%	96.02%	96.06%	96.10%	96.15%	96.19%	96.23%	96.27%	96.31%	96.35%	96.39%	96.43%	96.47%	96.51%	96.54%	96.58%	96.62%	96.66%	96.70%	96.74%	96.78%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.04%	97.08%	97.12%	97.16%	97.20%	97.24%	97.27%	97.31%	97.35%	97.39%	97.43%	97.47%	97.50%	97.54%	97.58%	97.62%	97.66%	97.69%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.15%	57.06%	60.47%	63.26%	65.19%	66.87%	68.43%	69.98%	71.39%	72.67%	73.88%	74.91%	75.80%	76.67%	77.44%	78.20%	78.91%	79.54%	80.06%	80.59%	81.11%	81.63%	82.13%	82.63%	83.04%	83.43%	83.82%	84.21%	84.57%	84.94%	85.30%	85.66%	86.01%	86.36%	86.70%	87.00%	87.29%	87.56%	87.83%	88.08%	88.33%	88.58%	88.82%	89.05%	89.29%	89.52%	89.76%	89.99%	90.22%	90.45%	90.67%	90.87%	91.08%	91.27%	91.43%	91.58%	91.72%	91.85%	91.98%	92.11%	92.23%	92.34%	92.46%	92.57%	92.68%	92.78%	92.89%	92.99%	93.08%	93.18%	93.27%	93.37%	93.47%	93.56%	93.66%	93.76%	93.85%	93.95%	94.04%	94.14%	94.24%	94.33%	94.43%	94.52%	94.62%	94.71%	94.79%	94.87%	94.95%	95.02%	95.09%	95.17%	95.24%	95.31%	95.38%	95.44%	95.49%	95.54%	95.59%	95.64%	95.69%	95.73%	95.78%	95.83%	95.87%	95.91%	95.96%	96.00%	96.04%	96.09%	96.13%	96.17%	96.22%	96.26%	96.30%	96.34%	96.38%	96.42%	96.46%	96.49%	96.53%	96.57%	96.61%	96.65%	96.69%	96.72%	96.76%	96.80%	96.84%	96.88%	96.92%	96.96%	96.99%	97.03%	97.07%	97.11%	97.15%	97.19%	97.22%	97.26%	97.30%	97.34%	97.38%	97.41%	97.45%	97.49%	97.53%	97.57%	97.61%	97.64%	97.68%	97.72%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.15%	57.08%	60.48%	63.27%	65.21%	66.87%	68.43%	69.99%	71.40%	72.69%	73.91%	74.93%	75.83%	76.70%	77.47%	78.24%	78.94%	79.56%	80.08%	80.60%	81.13%	81.64%	82.15%	82.65%	83.07%	83.46%	83.85%	84.23%	84.60%	84.96%	85.33%	85.68%	86.04%	86.39%	86.73%	87.04%	87.33%	87.60%	87.86%	88.12%	88.38%	88.62%	88.86%	89.09%	89.33%	89.56%	89.80%	90.03%	90.27%	90.50%	90.72%	90.93%	91.14%	91.32%	91.48%	91.64%	91.78%	91.91%	92.04%	92.17%	92.29%	92.40%	92.51%	92.63%	92.73%	92.83%	92.94%	93.04%	93.14%	93.24%	93.33%	93.43%	93.53%	93.62%	93.72%	93.81%	93.91%	94.01%	94.10%	94.20%	94.29%	94.39%	94.49%	94.58%	94.68%	94.76%	94.84%	94.92%	95.00%	95.07%	95.14%	95.21%	95.29%	95.36%	95.42%	95.47%	95.52%	95.57%	95.62%	95.67%	95.72%	95.77%	95.81%	95.85%	95.90%	95.94%	95.98%	96.03%	96.07%	96.11%	96.16%	96.20%	96.24%	96.28%	96.32%	96.37%	96.41%	96.44%	96.48%	96.52%	96.56%	96.60%	96.64%	96.67%	96.71%	96.75%	96.79%	96.83%	96.87%	96.90%	96.94%	96.98%	97.02%	97.06%	97.10%	97.13%	97.17%	97.21%	97.25%	97.29%	97.33%	97.36%	97.40%	97.44%	97.48%	97.52%	97.56%	97.59%	97.63%	97.67%	97.71%	97.75%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.16%	57.10%	60.50%	63.29%	65.22%	66.88%	68.44%	70.00%	71.41%	72.70%	73.93%	74.95%	75.86%	76.73%	77.51%	78.27%	78.97%	79.57%	80.10%	80.62%	81.14%	81.66%	82.16%	82.67%	83.10%	83.49%	83.89%	84.26%	84.62%	84.99%	85.35%	85.71%	86.06%	86.42%	86.76%	87.08%	87.37%	87.64%	87.90%	88.16%	88.42%	88.66%	88.90%	89.14%	89.37%	89.61%	89.84%	90.08%	90.31%	90.54%	90.77%	90.98%	91.19%	91.37%	91.54%	91.69%	91.84%	91.98%	92.11%	92.23%	92.35%	92.46%	92.57%	92.68%	92.78%	92.89%	92.99%	93.10%	93.20%	93.29%	93.39%	93.49%	93.58%	93.68%	93.78%	93.87%	93.97%	94.06%	94.16%	94.26%	94.35%	94.45%	94.54%	94.64%	94.73%	94.81%	94.89%	94.97%	95.04%	95.12%	95.19%	95.26%	95.33%	95.40%	95.46%	95.51%	95.56%	95.61%	95.66%	95.70%	95.75%	95.79%	95.84%	95.88%	95.92%	95.97%	96.01%	96.05%	96.10%	96.14%	96.18%	96.23%	96.27%	96.31%	96.35%	96.39%	96.43%	96.47%	96.51%	96.55%	96.59%	96.62%	96.66%	96.70%	96.74%	96.78%	96.82%	96.85%	96.89%	96.93%	96.97%	97.01%	97.05%	97.08%	97.12%	97.16%	97.20%	97.24%	97.28%	97.31%	97.35%	97.39%	97.43%	97.47%	97.51%	97.54%	97.58%	97.62%	97.66%	97.70%	97.74%	97.77%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.16%	57.11%	60.52%	63.30%	65.23%	66.88%	68.44%	70.00%	71.42%	72.72%	73.95%	74.97%	75.88%	76.76%	77.54%	78.30%	78.99%	79.59%	80.11%	80.63%	81.16%	81.67%	82.18%	82.68%	83.13%	83.53%	83.92%	84.29%	84.65%	85.01%	85.38%	85.73%	86.09%	86.45%	86.79%	87.13%	87.41%	87.68%	87.94%	88.20%	88.45%	88.71%	88.94%	89.18%	89.41%	89.65%	89.88%	90.12%	90.35%	90.59%	90.82%	91.03%	91.24%	91.42%	91.59%	91.74%	91.89%	92.04%	92.16%	92.28%	92.41%	92.52%	92.63%	92.73%	92.84%	92.94%	93.04%	93.15%	93.25%	93.35%	93.45%	93.55%	93.64%	93.74%	93.83%	93.93%	94.03%	94.12%	94.22%	94.31%	94.41%	94.51%	94.60%	94.69%	94.78%	94.86%	94.94%	95.02%	95.09%	95.16%	95.24%	95.31%	95.37%	95.44%	95.49%	95.54%	95.59%	95.64%	95.69%	95.73%	95.78%	95.82%	95.86%	95.91%	95.95%	95.99%	96.04%	96.08%	96.12%	96.17%	96.21%	96.25%	96.30%	96.34%	96.38%	96.42%	96.46%	96.50%	96.53%	96.57%	96.61%	96.65%	96.69%	96.73%	96.77%	96.80%	96.84%	96.88%	96.92%	96.96%	97.00%	97.03%	97.07%	97.11%	97.15%	97.19%	97.23%	97.26%	97.30%	97.34%	97.38%	97.42%	97.46%	97.49%	97.53%	97.57%	97.61%	97.65%	97.69%	97.72%	97.76%	97.80%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.17%	57.13%	60.53%	63.32%	65.24%	66.89%	68.45%	70.01%	71.43%	72.73%	73.97%	74.99%	75.91%	76.79%	77.57%	78.33%	79.02%	79.60%	80.13%	80.65%	81.17%	81.69%	82.19%	82.70%	83.16%	83.56%	83.95%	84.31%	84.68%	85.04%	85.40%	85.76%	86.12%	86.47%	86.82%	87.16%	87.44%	87.72%	87.97%	88.23%	88.49%	88.75%	88.99%	89.22%	89.46%	89.69%	89.93%	90.16%	90.39%	90.63%	90.86%	91.09%	91.29%	91.48%	91.64%	91.79%	91.95%	92.10%	92.22%	92.34%	92.46%	92.58%	92.68%	92.78%	92.89%	92.99%	93.10%	93.20%	93.31%	93.41%	93.51%	93.60%	93.70%	93.80%	93.89%	93.99%	94.08%	94.18%	94.28%	94.37%	94.47%	94.56%	94.65%	94.74%	94.83%	94.91%	94.99%	95.06%	95.14%	95.21%	95.28%	95.35%	95.41%	95.47%	95.52%	95.57%	95.62%	95.67%	95.72%	95.76%	95.80%	95.85%	95.89%	95.93%	95.98%	96.02%	96.06%	96.11%	96.15%	96.19%	96.24%	96.28%	96.32%	96.36%	96.40%	96.44%	96.48%	96.52%	96.56%	96.60%	96.64%	96.68%	96.72%	96.75%	96.79%	96.83%	96.87%	96.91%	96.95%	96.98%	97.02%	97.06%	97.10%	97.14%	97.18%	97.21%	97.25%	97.29%	97.33%	97.37%	97.41%	97.44%	97.48%	97.52%	97.56%	97.60%	97.64%	97.67%	97.71%	97.75%	97.79%	97.82%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.17%	57.14%	60.55%	63.33%	65.25%	66.90%	68.46%	70.01%	71.44%	72.75%	73.99%	75.01%	75.94%	76.81%	77.60%	78.36%	79.04%	79.62%	80.14%	80.67%	81.19%	81.71%	82.21%	82.71%	83.19%	83.59%	83.98%	84.34%	84.70%	85.07%	85.43%	85.79%	86.14%	86.50%	86.85%	87.18%	87.48%	87.75%	88.01%	88.27%	88.53%	88.78%	89.03%	89.26%	89.50%	89.73%	89.97%	90.20%	90.44%	90.67%	90.90%	91.14%	91.35%	91.53%	91.69%	91.85%	92.00%	92.14%	92.28%	92.40%	92.52%	92.63%	92.73%	92.84%	92.94%	93.05%	93.15%	93.25%	93.36%	93.46%	93.57%	93.66%	93.76%	93.85%	93.95%	94.05%	94.14%	94.24%	94.33%	94.43%	94.52%	94.61%	94.70%	94.80%	94.87%	94.95%	95.03%	95.11%	95.19%	95.25%	95.32%	95.38%	95.45%	95.50%	95.55%	95.60%	95.65%	95.70%	95.74%	95.79%	95.83%	95.87%	95.92%	95.96%	96.00%	96.05%	96.09%	96.13%	96.18%	96.22%	96.26%	96.31%	96.35%	96.39%	96.43%	96.47%	96.51%	96.55%	96.59%	96.63%	96.66%	96.70%	96.74%	96.78%	96.82%	96.86%	96.90%	96.93%	96.97%	97.01%	97.05%	97.09%	97.13%	97.16%	97.20%	97.24%	97.28%	97.32%	97.36%	97.39%	97.43%	97.47%	97.51%	97.55%	97.59%	97.62%	97.66%	97.70%	97.74%	97.78%	97.81%	97.85%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.18%	57.16%	60.56%	63.34%	65.26%	66.90%	68.46%	70.02%	71.45%	72.77%	74.00%	75.04%	75.96%	76.84%	77.63%	78.40%	79.07%	79.64%	80.16%	80.68%	81.20%	81.72%	82.23%	82.73%	83.22%	83.62%	84.00%	84.37%	84.73%	85.09%	85.46%	85.81%	86.17%	86.53%	86.88%	87.21%	87.52%	87.79%	88.05%	88.31%	88.56%	88.82%	89.07%	89.31%	89.54%	89.78%	90.01%	90.24%	90.48%	90.71%	90.95%	91.18%	91.40%	91.58%	91.75%	91.90%	92.05%	92.19%	92.33%	92.46%	92.58%	92.68%	92.79%	92.89%	92.99%	93.10%	93.20%	93.31%	93.41%	93.52%	93.62%	93.72%	93.82%	93.91%	94.01%	94.10%	94.20%	94.29%	94.39%	94.48%	94.57%	94.66%	94.76%	94.84%	94.92%	95.00%	95.08%	95.15%	95.23%	95.29%	95.36%	95.42%	95.48%	95.53%	95.58%	95.63%	95.68%	95.73%	95.77%	95.81%	95.86%	95.90%	95.94%	95.99%	96.03%	96.07%	96.12%	96.16%	96.20%	96.25%	96.29%	96.33%	96.37%	96.42%	96.46%	96.50%	96.54%	96.58%	96.61%	96.65%	96.69%	96.73%	96.77%	96.81%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.08%	97.11%	97.15%	97.19%	97.23%	97.27%	97.31%	97.34%	97.38%	97.42%	97.46%	97.50%	97.54%	97.57%	97.61%	97.65%	97.69%	97.73%	97.76%	97.80%	97.84%	97.88%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.18%	57.17%	60.58%	63.35%	65.27%	66.91%	68.47%	70.02%	71.46%	72.78%	74.02%	75.06%	75.99%	76.86%	77.66%	78.43%	79.10%	79.65%	80.18%	80.70%	81.22%	81.74%	82.24%	82.74%	83.24%	83.65%	84.03%	84.39%	84.76%	85.12%	85.48%	85.84%	86.20%	86.55%	86.91%	87.24%	87.55%	87.83%	88.09%	88.34%	88.60%	88.86%	89.11%	89.35%	89.58%	89.82%	90.05%	90.29%	90.52%	90.76%	90.99%	91.22%	91.45%	91.64%	91.80%	91.95%	92.09%	92.24%	92.38%	92.51%	92.63%	92.73%	92.84%	92.94%	93.05%	93.15%	93.26%	93.36%	93.46%	93.57%	93.67%	93.78%	93.87%	93.97%	94.07%	94.16%	94.25%	94.35%	94.44%	94.53%	94.62%	94.72%	94.81%	94.89%	94.97%	95.04%	95.12%	95.20%	95.26%	95.33%	95.39%	95.46%	95.52%	95.57%	95.62%	95.66%	95.71%	95.75%	95.80%	95.84%	95.88%	95.93%	95.97%	96.01%	96.06%	96.10%	96.14%	96.19%	96.23%	96.27%	96.32%	96.36%	96.40%	96.44%	96.48%	96.52%	96.56%	96.60%	96.64%	96.68%	96.72%	96.76%	96.79%	96.83%	96.87%	96.91%	96.95%	96.99%	97.03%	97.06%	97.10%	97.14%	97.18%	97.22%	97.26%	97.29%	97.33%	97.37%	97.41%	97.45%	97.49%	97.52%	97.56%	97.60%	97.64%	97.68%	97.71%	97.75%	97.79%	97.83%	97.87%	97.90%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.19%	57.19%	60.60%	63.36%	65.28%	66.91%	68.47%	70.03%	71.47%	72.80%	74.03%	75.08%	76.02%	76.89%	77.70%	78.45%	79.12%	79.67%	80.19%	80.71%	81.24%	81.75%	82.26%	82.76%	83.26%	83.68%	84.05%	84.42%	84.78%	85.15%	85.51%	85.87%	86.22%	86.58%	86.94%	87.26%	87.58%	87.86%	88.12%	88.38%	88.64%	88.90%	89.14%	89.39%	89.63%	89.86%	90.09%	90.33%	90.56%	90.80%	91.03%	91.27%	91.50%	91.69%	91.85%	92.00%	92.14%	92.29%	92.43%	92.57%	92.68%	92.79%	92.89%	93.00%	93.10%	93.20%	93.31%	93.41%	93.52%	93.62%	93.72%	93.83%	93.93%	94.03%	94.12%	94.21%	94.31%	94.40%	94.49%	94.59%	94.68%	94.77%	94.86%	94.93%	95.01%	95.09%	95.16%	95.23%	95.30%	95.37%	95.43%	95.49%	95.55%	95.60%	95.65%	95.69%	95.73%	95.78%	95.82%	95.86%	95.91%	95.95%	96.00%	96.04%	96.08%	96.13%	96.17%	96.21%	96.26%	96.30%	96.34%	96.39%	96.43%	96.47%	96.51%	96.55%	96.59%	96.63%	96.67%	96.71%	96.74%	96.78%	96.82%	96.86%	96.90%	96.94%	96.97%	97.01%	97.05%	97.09%	97.13%	97.17%	97.20%	97.24%	97.28%	97.32%	97.36%	97.40%	97.44%	97.47%	97.51%	97.55%	97.59%	97.63%	97.66%	97.70%	97.74%	97.78%	97.82%	97.86%	97.89%	97.93%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.19%	57.20%	60.61%	63.37%	65.29%	66.92%	68.48%	70.03%	71.48%	72.81%	74.05%	75.10%	76.04%	76.92%	77.73%	78.48%	79.14%	79.68%	80.21%	80.73%	81.25%	81.77%	82.27%	82.78%	83.28%	83.71%	84.08%	84.45%	84.81%	85.17%	85.54%	85.89%	86.25%	86.61%	86.96%	87.29%	87.61%	87.90%	88.16%	88.42%	88.67%	88.93%	89.18%	89.43%	89.67%	89.90%	90.14%	90.37%	90.61%	90.84%	91.07%	91.31%	91.54%	91.74%	91.90%	92.05%	92.19%	92.33%	92.48%	92.62%	92.73%	92.84%	92.94%	93.05%	93.15%	93.26%	93.36%	93.47%	93.57%	93.67%	93.78%	93.88%	93.98%	94.08%	94.17%	94.27%	94.36%	94.45%	94.55%	94.64%	94.73%	94.82%	94.90%	94.97%	95.05%	95.13%	95.20%	95.27%	95.34%	95.40%	95.47%	95.53%	95.58%	95.63%	95.67%	95.72%	95.76%	95.80%	95.85%	95.89%	95.93%	95.98%	96.02%	96.07%	96.11%	96.15%	96.20%	96.24%	96.28%	96.33%	96.37%	96.41%	96.45%	96.50%	96.54%	96.58%	96.62%	96.66%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.92%	96.96%	97.00%	97.04%	97.08%	97.12%	97.15%	97.19%	97.23%	97.27%	97.31%	97.35%	97.38%	97.42%	97.46%	97.50%	97.54%	97.58%	97.61%	97.65%	97.69%	97.73%	97.77%	97.81%	97.84%	97.88%	97.92%	97.96%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.20%	57.21%	60.63%	63.38%	65.31%	66.92%	68.48%	70.04%	71.49%	72.83%	74.06%	75.12%	76.07%	76.94%	77.76%	78.51%	79.16%	79.70%	80.22%	80.75%	81.27%	81.79%	82.29%	82.79%	83.30%	83.74%	84.11%	84.47%	84.84%	85.20%	85.56%	85.92%	86.28%	86.63%	86.99%	87.32%	87.63%	87.94%	88.20%	88.45%	88.71%	88.97%	89.22%	89.47%	89.71%	89.94%	90.18%	90.41%	90.65%	90.88%	91.12%	91.35%	91.58%	91.79%	91.95%	92.09%	92.24%	92.38%	92.52%	92.67%	92.79%	92.89%	93.00%	93.10%	93.21%	93.31%	93.41%	93.52%	93.62%	93.73%	93.83%	93.93%	94.03%	94.13%	94.23%	94.32%	94.41%	94.50%	94.60%	94.69%	94.78%	94.86%	94.94%	95.02%	95.09%	95.17%	95.24%	95.31%	95.38%	95.44%	95.50%	95.56%	95.61%	95.66%	95.70%	95.74%	95.79%	95.83%	95.87%	95.92%	95.96%	96.00%	96.05%	96.09%	96.13%	96.18%	96.22%	96.27%	96.31%	96.35%	96.40%	96.44%	96.48%	96.52%	96.56%	96.60%	96.64%	96.68%	96.72%	96.76%	96.80%	96.84%	96.87%	96.91%	96.95%	96.99%	97.03%	97.07%	97.10%	97.14%	97.18%	97.22%	97.26%	97.30%	97.33%	97.37%	97.41%	97.45%	97.49%	97.53%	97.56%	97.60%	97.64%	97.68%	97.72%	97.76%	97.79%	97.83%	97.87%	97.91%	97.95%	97.98%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.20%	57.22%	60.64%	63.39%	65.32%	66.93%	68.49%	70.04%	71.50%	72.85%	74.08%	75.14%	76.09%	76.97%	77.79%	78.53%	79.18%	79.72%	80.24%	80.76%	81.28%	81.80%	82.30%	82.81%	83.31%	83.76%	84.13%	84.50%	84.86%	85.23%	85.59%	85.95%	86.30%	86.66%	87.01%	87.34%	87.66%	87.97%	88.23%	88.49%	88.75%	89.01%	89.26%	89.50%	89.75%	89.99%	90.22%	90.46%	90.69%	90.92%	91.16%	91.39%	91.63%	91.84%	92.00%	92.14%	92.29%	92.43%	92.57%	92.72%	92.84%	92.95%	93.05%	93.15%	93.26%	93.36%	93.47%	93.57%	93.68%	93.78%	93.88%	93.98%	94.08%	94.18%	94.28%	94.37%	94.46%	94.55%	94.64%	94.73%	94.82%	94.91%	94.98%	95.06%	95.13%	95.20%	95.27%	95.35%	95.41%	95.48%	95.54%	95.59%	95.64%	95.68%	95.73%	95.77%	95.81%	95.86%	95.90%	95.94%	95.99%	96.03%	96.07%	96.12%	96.16%	96.20%	96.25%	96.29%	96.33%	96.38%	96.42%	96.46%	96.51%	96.55%	96.59%	96.63%	96.67%	96.71%	96.75%	96.78%	96.82%	96.86%	96.90%	96.94%	96.98%	97.02%	97.05%	97.09%	97.13%	97.17%	97.21%	97.25%	97.28%	97.32%	97.36%	97.40%	97.44%	97.48%	97.51%	97.55%	97.59%	97.63%	97.67%	97.71%	97.74%	97.78%	97.82%	97.86%	97.90%	97.94%	97.97%	98.01%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.21%	57.23%	60.66%	63.40%	65.33%	66.93%	68.49%	70.05%	71.51%	72.86%	74.10%	75.16%	76.11%	77.00%	77.82%	78.56%	79.20%	79.73%	80.25%	80.78%	81.30%	81.82%	82.32%	82.82%	83.33%	83.79%	84.16%	84.52%	84.89%	85.25%	85.62%	85.97%	86.33%	86.69%	87.04%	87.37%	87.69%	88.00%	88.27%	88.53%	88.79%	89.04%	89.29%	89.54%	89.79%	90.03%	90.26%	90.50%	90.73%	90.97%	91.20%	91.43%	91.67%	91.88%	92.05%	92.19%	92.33%	92.48%	92.62%	92.76%	92.89%	93.00%	93.10%	93.21%	93.31%	93.42%	93.52%	93.62%	93.72%	93.83%	93.93%	94.03%	94.13%	94.23%	94.33%	94.42%	94.51%	94.60%	94.69%	94.78%	94.87%	94.95%	95.03%	95.10%	95.17%	95.24%	95.31%	95.38%	95.45%	95.51%	95.58%	95.62%	95.67%	95.71%	95.75%	95.80%	95.84%	95.88%	95.93%	95.97%	96.01%	96.06%	96.10%	96.14%	96.19%	96.23%	96.27%	96.32%	96.36%	96.40%	96.45%	96.49%	96.53%	96.57%	96.62%	96.66%	96.70%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.00%	97.04%	97.08%	97.12%	97.16%	97.20%	97.23%	97.27%	97.31%	97.35%	97.39%	97.43%	97.46%	97.50%	97.54%	97.58%	97.62%	97.66%	97.69%	97.73%	97.77%	97.81%	97.85%	97.89%	97.92%	97.96%	98.00%	98.04%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.22%	57.24%	60.67%	63.41%	65.34%	66.94%	68.50%	70.05%	71.53%	72.87%	74.11%	75.18%	76.14%	77.02%	77.86%	78.59%	79.22%	79.75%	80.27%	80.79%	81.32%	81.83%	82.34%	82.84%	83.34%	83.80%	84.19%	84.55%	84.91%	85.28%	85.64%	86.00%	86.36%	86.71%	87.07%	87.39%	87.71%	88.02%	88.31%	88.57%	88.82%	89.08%	89.33%	89.58%	89.82%	90.07%	90.31%	90.54%	90.77%	91.01%	91.24%	91.48%	91.71%	91.92%	92.09%	92.24%	92.38%	92.52%	92.67%	92.81%	92.94%	93.05%	93.16%	93.26%	93.36%	93.47%	93.57%	93.67%	93.77%	93.87%	93.97%	94.07%	94.17%	94.27%	94.38%	94.47%	94.56%	94.65%	94.74%	94.83%	94.91%	94.99%	95.06%	95.14%	95.21%	95.28%	95.35%	95.42%	95.49%	95.55%	95.60%	95.65%	95.69%	95.74%	95.78%	95.82%	95.87%	95.91%	95.95%	96.00%	96.04%	96.08%	96.13%	96.17%	96.21%	96.26%	96.30%	96.34%	96.39%	96.43%	96.47%	96.52%	96.56%	96.60%	96.64%	96.68%	96.72%	96.76%	96.80%	96.84%	96.88%	96.91%	96.95%	96.99%	97.03%	97.07%	97.11%	97.15%	97.18%	97.22%	97.26%	97.30%	97.34%	97.38%	97.41%	97.45%	97.49%	97.53%	97.57%	97.61%	97.64%	97.68%	97.72%	97.76%	97.80%	97.84%	97.87%	97.91%	97.95%	97.99%	98.02%	98.06%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.22%	57.25%	60.69%	63.42%	65.35%	66.94%	68.50%	70.06%	71.54%	72.88%	74.13%	75.20%	76.16%	77.05%	77.89%	78.61%	79.24%	79.76%	80.29%	80.81%	81.33%	81.85%	82.35%	82.86%	83.35%	83.82%	84.21%	84.58%	84.94%	85.31%	85.67%	86.03%	86.38%	86.74%	87.09%	87.42%	87.74%	88.05%	88.34%	88.60%	88.86%	89.12%	89.37%	89.61%	89.86%	90.11%	90.35%	90.58%	90.82%	91.05%	91.29%	91.52%	91.75%	91.97%	92.14%	92.28%	92.43%	92.57%	92.71%	92.86%	92.99%	93.10%	93.21%	93.31%	93.42%	93.52%	93.62%	93.72%	93.82%	93.92%	94.02%	94.12%	94.22%	94.32%	94.42%	94.51%	94.61%	94.70%	94.79%	94.88%	94.96%	95.03%	95.10%	95.17%	95.24%	95.31%	95.39%	95.46%	95.52%	95.59%	95.63%	95.68%	95.72%	95.76%	95.81%	95.85%	95.89%	95.94%	95.98%	96.02%	96.07%	96.11%	96.15%	96.20%	96.24%	96.28%	96.33%	96.37%	96.41%	96.46%	96.50%	96.54%	96.59%	96.63%	96.67%	96.71%	96.75%	96.79%	96.83%	96.86%	96.90%	96.94%	96.98%	97.02%	97.06%	97.09%	97.13%	97.17%	97.21%	97.25%	97.29%	97.33%	97.36%	97.40%	97.44%	97.48%	97.52%	97.56%	97.59%	97.63%	97.67%	97.71%	97.75%	97.79%	97.82%	97.86%	97.90%	97.94%	97.97%	98.01%	98.05%	98.08%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.23%	57.26%	60.71%	63.43%	65.36%	66.95%	68.51%	70.06%	71.55%	72.89%	74.14%	75.22%	76.18%	77.08%	77.92%	78.64%	79.26%	79.78%	80.30%	80.83%	81.35%	81.86%	82.37%	82.87%	83.36%	83.83%	84.24%	84.60%	84.97%	85.33%	85.70%	86.05%	86.41%	86.76%	87.12%	87.45%	87.77%	88.08%	88.38%	88.64%	88.90%	89.15%	89.40%	89.65%	89.90%	90.14%	90.39%	90.63%	90.86%	91.09%	91.33%	91.56%	91.80%	92.01%	92.19%	92.33%	92.48%	92.62%	92.76%	92.91%	93.03%	93.15%	93.26%	93.36%	93.46%	93.57%	93.67%	93.77%	93.87%	93.97%	94.07%	94.17%	94.27%	94.37%	94.47%	94.56%	94.65%	94.74%	94.83%	94.92%	95.00%	95.07%	95.14%	95.21%	95.28%	95.35%	95.42%	95.49%	95.56%	95.61%	95.66%	95.70%	95.75%	95.79%	95.83%	95.88%	95.92%	95.96%	96.01%	96.05%	96.09%	96.14%	96.18%	96.22%	96.27%	96.31%	96.35%	96.40%	96.44%	96.48%	96.53%	96.57%	96.61%	96.65%	96.69%	96.74%	96.78%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.04%	97.08%	97.12%	97.16%	97.20%	97.24%	97.27%	97.31%	97.35%	97.39%	97.43%	97.47%	97.51%	97.54%	97.58%	97.62%	97.66%	97.70%	97.73%	97.77%	97.81%	97.85%	97.88%	97.92%	97.96%	97.99%	98.03%	98.07%	98.10%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.23%	57.26%	60.72%	63.44%	65.37%	66.95%	68.51%	70.07%	71.56%	72.91%	74.16%	75.23%	76.20%	77.10%	77.95%	78.67%	79.27%	79.80%	80.32%	80.84%	81.36%	81.88%	82.38%	82.89%	83.37%	83.85%	84.27%	84.63%	84.99%	85.36%	85.72%	86.08%	86.43%	86.79%	87.15%	87.47%	87.79%	88.10%	88.41%	88.68%	88.93%	89.19%	89.44%	89.69%	89.93%	90.18%	90.43%	90.67%	90.90%	91.14%	91.37%	91.60%	91.84%	92.05%	92.23%	92.38%	92.52%	92.67%	92.81%	92.95%	93.08%	93.20%	93.31%	93.41%	93.51%	93.61%	93.71%	93.81%	93.92%	94.02%	94.12%	94.22%	94.32%	94.42%	94.52%	94.61%	94.70%	94.79%	94.88%	94.96%	95.03%	95.10%	95.18%	95.25%	95.32%	95.39%	95.46%	95.53%	95.59%	95.64%	95.68%	95.73%	95.77%	95.82%	95.86%	95.90%	95.95%	95.99%	96.03%	96.08%	96.12%	96.16%	96.21%	96.25%	96.29%	96.34%	96.38%	96.42%	96.47%	96.51%	96.55%	96.60%	96.64%	96.68%	96.72%	96.76%	96.80%	96.84%	96.88%	96.92%	96.96%	96.99%	97.03%	97.07%	97.11%	97.15%	97.19%	97.22%	97.26%	97.30%	97.34%	97.38%	97.42%	97.45%	97.49%	97.53%	97.57%	97.61%	97.65%	97.68%	97.72%	97.76%	97.80%	97.83%	97.87%	97.91%	97.94%	97.98%	98.01%	98.05%	98.09%	98.12%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.24%	57.27%	60.74%	63.45%	65.38%	66.96%	68.52%	70.07%	71.57%	72.92%	74.18%	75.25%	76.22%	77.13%	77.98%	78.69%	79.29%	79.81%	80.33%	80.86%	81.38%	81.90%	82.40%	82.90%	83.38%	83.86%	84.29%	84.66%	85.02%	85.38%	85.75%	86.10%	86.46%	86.82%	87.17%	87.50%	87.82%	88.13%	88.44%	88.71%	88.97%	89.23%	89.48%	89.72%	89.97%	90.22%	90.46%	90.71%	90.94%	91.18%	91.41%	91.65%	91.88%	92.09%	92.28%	92.43%	92.57%	92.71%	92.86%	93.00%	93.13%	93.24%	93.36%	93.46%	93.56%	93.66%	93.76%	93.86%	93.96%	94.06%	94.16%	94.26%	94.36%	94.47%	94.57%	94.66%	94.75%	94.84%	94.93%	95.00%	95.07%	95.14%	95.21%	95.28%	95.35%	95.43%	95.50%	95.56%	95.62%	95.67%	95.71%	95.75%	95.80%	95.84%	95.89%	95.93%	95.97%	96.02%	96.06%	96.10%	96.15%	96.19%	96.23%	96.28%	96.32%	96.36%	96.41%	96.45%	96.49%	96.54%	96.58%	96.62%	96.67%	96.71%	96.75%	96.79%	96.83%	96.87%	96.91%	96.94%	96.98%	97.02%	97.06%	97.10%	97.14%	97.17%	97.21%	97.25%	97.29%	97.33%	97.37%	97.40%	97.44%	97.48%	97.52%	97.56%	97.60%	97.63%	97.67%	97.71%	97.75%	97.78%	97.82%	97.85%	97.89%	97.93%	97.96%	98.00%	98.04%	98.07%	98.11%	98.14%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.24%	57.27%	60.75%	63.47%	65.39%	66.96%	68.52%	70.08%	71.58%	72.93%	74.19%	75.26%	76.24%	77.16%	78.01%	78.72%	79.30%	79.83%	80.35%	80.87%	81.39%	81.91%	82.42%	82.91%	83.39%	83.87%	84.31%	84.68%	85.05%	85.41%	85.77%	86.13%	86.49%	86.84%	87.20%	87.53%	87.85%	88.16%	88.47%	88.75%	89.01%	89.27%	89.51%	89.76%	90.01%	90.25%	90.50%	90.75%	90.99%	91.22%	91.45%	91.69%	91.92%	92.14%	92.32%	92.47%	92.62%	92.76%	92.91%	93.05%	93.17%	93.28%	93.40%	93.51%	93.61%	93.71%	93.81%	93.91%	94.01%	94.11%	94.21%	94.31%	94.41%	94.51%	94.61%	94.71%	94.80%	94.88%	94.96%	95.04%	95.11%	95.18%	95.25%	95.32%	95.39%	95.46%	95.53%	95.59%	95.65%	95.69%	95.74%	95.78%	95.82%	95.87%	95.91%	95.96%	96.00%	96.04%	96.09%	96.13%	96.17%	96.22%	96.26%	96.30%	96.35%	96.39%	96.43%	96.48%	96.52%	96.56%	96.61%	96.65%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.05%	97.09%	97.12%	97.16%	97.20%	97.24%	97.28%	97.32%	97.35%	97.39%	97.43%	97.47%	97.51%	97.55%	97.58%	97.62%	97.66%	97.69%	97.73%	97.77%	97.80%	97.84%	97.88%	97.91%	97.95%	97.98%	98.02%	98.06%	98.09%	98.13%	98.16%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.25%	57.28%	60.77%	63.48%	65.40%	66.97%	68.53%	70.09%	71.59%	72.94%	74.21%	75.28%	76.26%	77.18%	78.05%	78.74%	79.32%	79.84%	80.37%	80.89%	81.41%	81.93%	82.43%	82.92%	83.40%	83.88%	84.33%	84.71%	85.07%	85.44%	85.80%	86.16%	86.51%	86.87%	87.23%	87.55%	87.87%	88.18%	88.49%	88.78%	89.04%	89.30%	89.55%	89.80%	90.05%	90.29%	90.54%	90.78%	91.03%	91.26%	91.50%	91.73%	91.96%	92.18%	92.36%	92.52%	92.67%	92.81%	92.95%	93.09%	93.21%	93.33%	93.44%	93.55%	93.66%	93.76%	93.86%	93.96%	94.06%	94.16%	94.26%	94.36%	94.46%	94.56%	94.66%	94.75%	94.84%	94.93%	95.00%	95.07%	95.14%	95.22%	95.29%	95.36%	95.43%	95.50%	95.56%	95.63%	95.68%	95.72%	95.76%	95.81%	95.85%	95.89%	95.94%	95.98%	96.03%	96.07%	96.11%	96.16%	96.20%	96.24%	96.29%	96.33%	96.37%	96.42%	96.46%	96.50%	96.55%	96.59%	96.63%	96.68%	96.72%	96.76%	96.80%	96.84%	96.88%	96.92%	96.96%	97.00%	97.03%	97.07%	97.11%	97.15%	97.19%	97.23%	97.27%	97.30%	97.34%	97.38%	97.42%	97.46%	97.50%	97.53%	97.57%	97.61%	97.64%	97.68%	97.71%	97.75%	97.79%	97.82%	97.86%	97.90%	97.93%	97.97%	98.01%	98.04%	98.08%	98.11%	98.15%	98.19%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.25%	57.28%	60.79%	63.49%	65.41%	66.97%	68.53%	70.09%	71.60%	72.95%	74.22%	75.30%	76.28%	77.21%	78.08%	78.77%	79.33%	79.86%	80.38%	80.90%	81.43%	81.94%	82.45%	82.93%	83.41%	83.89%	84.35%	84.74%	85.10%	85.46%	85.83%	86.18%	86.54%	86.90%	87.25%	87.58%	87.90%	88.21%	88.52%	88.82%	89.08%	89.34%	89.59%	89.84%	90.08%	90.33%	90.58%	90.82%	91.07%	91.30%	91.54%	91.77%	92.01%	92.22%	92.40%	92.57%	92.71%	92.86%	93.00%	93.13%	93.25%	93.37%	93.48%	93.60%	93.70%	93.80%	93.90%	94.00%	94.11%	94.21%	94.31%	94.41%	94.51%	94.61%	94.71%	94.80%	94.88%	94.97%	95.04%	95.11%	95.18%	95.25%	95.32%	95.39%	95.46%	95.53%	95.59%	95.66%	95.70%	95.75%	95.79%	95.83%	95.88%	95.92%	95.96%	96.01%	96.05%	96.10%	96.14%	96.18%	96.23%	96.27%	96.31%	96.36%	96.40%	96.44%	96.49%	96.53%	96.57%	96.62%	96.66%	96.70%	96.74%	96.79%	96.83%	96.87%	96.91%	96.95%	96.98%	97.02%	97.06%	97.10%	97.14%	97.18%	97.22%	97.25%	97.29%	97.33%	97.37%	97.41%	97.44%	97.48%	97.52%	97.55%	97.59%	97.63%	97.66%	97.70%	97.74%	97.77%	97.81%	97.84%	97.88%	97.92%	97.95%	97.99%	98.03%	98.06%	98.10%	98.14%	98.17%	98.21%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.26%	57.29%	60.80%	63.50%	65.41%	66.98%	68.54%	70.10%	71.61%	72.96%	74.24%	75.31%	76.30%	77.24%	78.11%	78.80%	79.35%	79.87%	80.40%	80.92%	81.44%	81.96%	82.46%	82.94%	83.42%	83.90%	84.36%	84.76%	85.13%	85.49%	85.85%	86.21%	86.57%	86.92%	87.28%	87.61%	87.92%	88.24%	88.55%	88.85%	89.12%	89.38%	89.63%	89.87%	90.12%	90.37%	90.61%	90.86%	91.10%	91.35%	91.58%	91.82%	92.05%	92.26%	92.45%	92.62%	92.76%	92.90%	93.05%	93.17%	93.30%	93.41%	93.53%	93.64%	93.75%	93.85%	93.95%	94.05%	94.15%	94.25%	94.35%	94.45%	94.56%	94.66%	94.76%	94.84%	94.93%	95.00%	95.08%	95.15%	95.22%	95.29%	95.36%	95.43%	95.49%	95.56%	95.62%	95.68%	95.73%	95.77%	95.82%	95.86%	95.90%	95.95%	95.99%	96.03%	96.08%	96.12%	96.17%	96.21%	96.25%	96.30%	96.34%	96.38%	96.43%	96.47%	96.51%	96.56%	96.60%	96.64%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.05%	97.09%	97.13%	97.16%	97.20%	97.24%	97.28%	97.32%	97.36%	97.39%	97.43%	97.47%	97.50%	97.54%	97.57%	97.61%	97.65%	97.68%	97.72%	97.76%	97.79%	97.83%	97.87%	97.90%	97.94%	97.98%	98.01%	98.05%	98.08%	98.12%	98.16%	98.19%	98.23%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.26%	57.30%	60.81%	63.51%	65.42%	66.99%	68.54%	70.10%	71.62%	72.97%	74.25%	75.33%	76.33%	77.26%	78.14%	78.82%	79.37%	79.89%	80.41%	80.94%	81.46%	81.98%	82.47%	82.95%	83.43%	83.92%	84.38%	84.79%	85.15%	85.52%	85.88%	86.24%	86.59%	86.95%	87.31%	87.63%	87.95%	88.26%	88.57%	88.88%	89.16%	89.41%	89.66%	89.91%	90.16%	90.40%	90.65%	90.90%	91.14%	91.39%	91.62%	91.86%	92.09%	92.30%	92.49%	92.66%	92.81%	92.95%	93.09%	93.21%	93.34%	93.45%	93.57%	93.68%	93.80%	93.90%	94.00%	94.10%	94.20%	94.30%	94.40%	94.50%	94.60%	94.70%	94.80%	94.88%	94.97%	95.04%	95.11%	95.18%	95.25%	95.33%	95.40%	95.46%	95.53%	95.59%	95.66%	95.71%	95.76%	95.80%	95.84%	95.89%	95.93%	95.97%	96.02%	96.06%	96.10%	96.15%	96.19%	96.24%	96.28%	96.32%	96.37%	96.41%	96.45%	96.50%	96.54%	96.58%	96.63%	96.67%	96.71%	96.76%	96.80%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.08%	97.11%	97.15%	97.19%	97.23%	97.27%	97.30%	97.34%	97.38%	97.41%	97.45%	97.49%	97.52%	97.56%	97.60%	97.63%	97.67%	97.71%	97.74%	97.78%	97.81%	97.85%	97.89%	97.92%	97.96%	98.00%	98.03%	98.07%	98.11%	98.14%	98.18%	98.21%	98.25%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.27%	57.30%	60.82%	63.52%	65.42%	66.99%	68.55%	70.11%	71.63%	72.98%	74.27%	75.34%	76.35%	77.28%	78.16%	78.85%	79.38%	79.91%	80.43%	80.95%	81.47%	81.99%	82.48%	82.96%	83.45%	83.93%	84.40%	84.81%	85.18%	85.54%	85.91%	86.26%	86.62%	86.98%	87.33%	87.66%	87.98%	88.29%	88.60%	88.91%	89.19%	89.45%	89.70%	89.95%	90.19%	90.44%	90.69%	90.93%	91.18%	91.42%	91.67%	91.90%	92.13%	92.35%	92.53%	92.70%	92.86%	93.00%	93.13%	93.26%	93.38%	93.50%	93.61%	93.72%	93.84%	93.95%	94.05%	94.15%	94.25%	94.35%	94.45%	94.55%	94.65%	94.75%	94.84%	94.93%	95.01%	95.08%	95.15%	95.22%	95.29%	95.36%	95.43%	95.49%	95.56%	95.62%	95.68%	95.73%	95.78%	95.83%	95.87%	95.91%	95.96%	96.00%	96.04%	96.09%	96.13%	96.17%	96.22%	96.26%	96.30%	96.35%	96.39%	96.44%	96.48%	96.52%	96.57%	96.61%	96.65%	96.70%	96.74%	96.78%	96.82%	96.87%	96.91%	96.95%	96.99%	97.03%	97.06%	97.10%	97.14%	97.18%	97.22%	97.25%	97.29%	97.33%	97.36%	97.40%	97.43%	97.47%	97.51%	97.54%	97.58%	97.62%	97.65%	97.69%	97.73%	97.76%	97.80%	97.84%	97.87%	97.91%	97.94%	97.98%	98.02%	98.05%	98.09%	98.13%	98.16%	98.20%	98.24%	98.27%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.27%	57.31%	60.83%	63.53%	65.43%	67.00%	68.56%	70.11%	71.64%	72.99%	74.29%	75.36%	76.37%	77.30%	78.18%	78.87%	79.40%	79.92%	80.45%	80.97%	81.49%	82.01%	82.49%	82.97%	83.46%	83.94%	84.41%	84.83%	85.21%	85.57%	85.93%	86.29%	86.65%	87.00%	87.36%	87.69%	88.00%	88.32%	88.63%	88.94%	89.22%	89.49%	89.74%	89.98%	90.23%	90.48%	90.72%	90.97%	91.22%	91.46%	91.71%	91.94%	92.18%	92.39%	92.57%	92.74%	92.90%	93.05%	93.18%	93.30%	93.42%	93.54%	93.65%	93.77%	93.88%	93.99%	94.09%	94.20%	94.30%	94.40%	94.50%	94.60%	94.69%	94.79%	94.88%	94.97%	95.04%	95.12%	95.19%	95.26%	95.33%	95.39%	95.46%	95.52%	95.59%	95.65%	95.71%	95.76%	95.81%	95.85%	95.90%	95.94%	95.98%	96.03%	96.07%	96.11%	96.16%	96.20%	96.24%	96.29%	96.33%	96.37%	96.42%	96.46%	96.51%	96.55%	96.59%	96.64%	96.68%	96.72%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.05%	97.09%	97.13%	97.16%	97.20%	97.24%	97.27%	97.31%	97.35%	97.38%	97.42%	97.46%	97.49%	97.53%	97.57%	97.60%	97.64%	97.67%	97.71%	97.75%	97.78%	97.82%	97.86%	97.89%	97.93%	97.97%	98.00%	98.04%	98.07%	98.11%	98.15%	98.18%	98.22%	98.26%	98.29%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.28%	57.31%	60.84%	63.54%	65.44%	67.00%	68.56%	70.12%	71.65%	73.00%	74.30%	75.38%	76.39%	77.32%	78.20%	78.89%	79.41%	79.94%	80.46%	80.98%	81.51%	82.02%	82.50%	82.99%	83.47%	83.95%	84.43%	84.85%	85.23%	85.60%	85.96%	86.32%	86.67%	87.03%	87.39%	87.71%	88.03%	88.34%	88.65%	88.96%	89.25%	89.52%	89.77%	90.02%	90.27%	90.51%	90.76%	91.01%	91.25%	91.50%	91.74%	91.98%	92.22%	92.43%	92.61%	92.79%	92.95%	93.09%	93.22%	93.34%	93.46%	93.58%	93.70%	93.81%	93.92%	94.04%	94.14%	94.24%	94.34%	94.44%	94.54%	94.64%	94.74%	94.83%	94.92%	95.01%	95.08%	95.15%	95.22%	95.29%	95.36%	95.43%	95.49%	95.56%	95.62%	95.69%	95.74%	95.79%	95.83%	95.88%	95.92%	95.97%	96.01%	96.05%	96.10%	96.14%	96.18%	96.23%	96.27%	96.31%	96.36%	96.40%	96.44%	96.49%	96.53%	96.57%	96.62%	96.66%	96.71%	96.75%	96.79%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.08%	97.11%	97.15%	97.19%	97.22%	97.26%	97.29%	97.33%	97.37%	97.40%	97.44%	97.48%	97.51%	97.55%	97.59%	97.62%	97.66%	97.70%	97.73%	97.77%	97.81%	97.84%	97.88%	97.91%	97.95%	97.99%	98.02%	98.06%	98.10%	98.13%	98.17%	98.21%	98.24%	98.28%	98.31%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.28%	57.32%	60.85%	63.55%	65.44%	67.01%	68.57%	70.12%	71.66%	73.01%	74.32%	75.39%	76.41%	77.34%	78.22%	78.91%	79.43%	79.95%	80.48%	81.00%	81.52%	82.03%	82.51%	83.00%	83.48%	83.96%	84.44%	84.88%	85.26%	85.62%	85.99%	86.34%	86.70%	87.06%	87.41%	87.74%	88.06%	88.37%	88.68%	88.99%	89.28%	89.56%	89.81%	90.06%	90.30%	90.55%	90.80%	91.04%	91.29%	91.54%	91.78%	92.03%	92.26%	92.47%	92.66%	92.83%	93.00%	93.14%	93.26%	93.38%	93.51%	93.62%	93.74%	93.85%	93.97%	94.08%	94.19%	94.29%	94.39%	94.49%	94.59%	94.68%	94.78%	94.87%	94.97%	95.05%	95.12%	95.19%	95.26%	95.33%	95.39%	95.46%	95.52%	95.59%	95.65%	95.71%	95.76%	95.81%	95.86%	95.90%	95.95%	95.99%	96.04%	96.08%	96.12%	96.17%	96.21%	96.25%	96.30%	96.34%	96.38%	96.43%	96.47%	96.51%	96.56%	96.60%	96.64%	96.69%	96.73%	96.77%	96.82%	96.86%	96.90%	96.94%	96.99%	97.02%	97.06%	97.10%	97.13%	97.17%	97.21%	97.24%	97.28%	97.32%	97.35%	97.39%	97.43%	97.46%	97.50%	97.53%	97.57%	97.61%	97.64%	97.68%	97.72%	97.75%	97.79%	97.83%	97.86%	97.90%	97.94%	97.97%	98.01%	98.04%	98.08%	98.12%	98.15%	98.19%	98.23%	98.26%	98.30%	98.33%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.29%	57.32%	60.86%	63.56%	65.45%	67.01%	68.57%	70.13%	71.67%	73.02%	74.33%	75.41%	76.43%	77.36%	78.24%	78.92%	79.45%	79.97%	80.49%	81.02%	81.54%	82.04%	82.52%	83.01%	83.49%	83.97%	84.45%	84.90%	85.29%	85.65%	86.01%	86.37%	86.73%	87.08%	87.44%	87.76%	88.08%	88.39%	88.71%	89.02%	89.31%	89.59%	89.85%	90.09%	90.34%	90.59%	90.83%	91.08%	91.33%	91.57%	91.82%	92.06%	92.30%	92.52%	92.70%	92.87%	93.04%	93.18%	93.30%	93.43%	93.55%	93.67%	93.78%	93.89%	94.01%	94.12%	94.24%	94.34%	94.44%	94.54%	94.63%	94.73%	94.82%	94.91%	95.01%	95.08%	95.16%	95.23%	95.29%	95.36%	95.42%	95.49%	95.55%	95.62%	95.68%	95.74%	95.79%	95.84%	95.89%	95.93%	95.98%	96.02%	96.06%	96.11%	96.15%	96.19%	96.24%	96.28%	96.32%	96.37%	96.41%	96.45%	96.50%	96.54%	96.58%	96.63%	96.67%	96.71%	96.76%	96.80%	96.84%	96.89%	96.93%	96.97%	97.01%	97.04%	97.08%	97.12%	97.15%	97.19%	97.23%	97.26%	97.30%	97.34%	97.37%	97.41%	97.45%	97.48%	97.52%	97.56%	97.59%	97.63%	97.67%	97.70%	97.74%	97.77%	97.81%	97.85%	97.88%	97.92%	97.96%	97.99%	98.03%	98.07%	98.10%	98.14%	98.17%	98.21%	98.25%	98.28%	98.32%	98.35%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.29%	57.33%	60.87%	63.57%	65.45%	67.02%	68.58%	70.13%	71.68%	73.03%	74.35%	75.42%	76.45%	77.39%	78.26%	78.94%	79.46%	79.99%	80.51%	81.03%	81.55%	82.05%	82.54%	83.02%	83.50%	83.98%	84.46%	84.91%	85.31%	85.68%	86.04%	86.40%	86.75%	87.11%	87.46%	87.79%	88.11%	88.42%	88.73%	89.04%	89.35%	89.62%	89.89%	90.13%	90.38%	90.62%	90.87%	91.12%	91.36%	91.61%	91.86%	92.10%	92.35%	92.56%	92.74%	92.91%	93.08%	93.22%	93.35%	93.47%	93.59%	93.71%	93.82%	93.94%	94.05%	94.16%	94.28%	94.39%	94.48%	94.58%	94.67%	94.77%	94.86%	94.96%	95.05%	95.12%	95.19%	95.26%	95.33%	95.39%	95.46%	95.52%	95.59%	95.65%	95.71%	95.77%	95.82%	95.87%	95.91%	95.96%	96.00%	96.05%	96.09%	96.13%	96.18%	96.22%	96.26%	96.31%	96.35%	96.39%	96.44%	96.48%	96.52%	96.57%	96.61%	96.65%	96.70%	96.74%	96.78%	96.83%	96.87%	96.91%	96.96%	96.99%	97.03%	97.07%	97.10%	97.14%	97.18%	97.21%	97.25%	97.29%	97.32%	97.36%	97.39%	97.43%	97.47%	97.50%	97.54%	97.58%	97.61%	97.65%	97.69%	97.72%	97.76%	97.80%	97.83%	97.87%	97.91%	97.94%	97.98%	98.01%	98.05%	98.09%	98.12%	98.16%	98.20%	98.23%	98.27%	98.30%	98.34%	98.38%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.30%	57.33%	60.87%	63.58%	65.46%	67.02%	68.58%	70.14%	71.69%	73.04%	74.37%	75.44%	76.47%	77.41%	78.28%	78.95%	79.48%	80.00%	80.52%	81.05%	81.57%	82.06%	82.55%	83.03%	83.51%	83.99%	84.47%	84.93%	85.33%	85.70%	86.07%	86.42%	86.78%	87.13%	87.49%	87.82%	88.14%	88.45%	88.76%	89.07%	89.38%	89.66%	89.92%	90.17%	90.42%	90.66%	90.91%	91.15%	91.40%	91.65%	91.89%	92.14%	92.38%	92.60%	92.78%	92.96%	93.13%	93.26%	93.39%	93.51%	93.63%	93.75%	93.86%	93.98%	94.09%	94.21%	94.32%	94.43%	94.53%	94.62%	94.72%	94.81%	94.90%	95.00%	95.08%	95.16%	95.23%	95.29%	95.36%	95.42%	95.49%	95.55%	95.62%	95.68%	95.74%	95.79%	95.84%	95.89%	95.94%	95.98%	96.03%	96.07%	96.12%	96.16%	96.20%	96.25%	96.29%	96.33%	96.38%	96.42%	96.46%	96.51%	96.55%	96.59%	96.64%	96.68%	96.72%	96.77%	96.81%	96.85%	96.90%	96.94%	96.98%	97.01%	97.05%	97.09%	97.12%	97.16%	97.20%	97.23%	97.27%	97.31%	97.34%	97.38%	97.42%	97.45%	97.49%	97.53%	97.56%	97.60%	97.63%	97.67%	97.71%	97.74%	97.78%	97.82%	97.85%	97.89%	97.93%	97.96%	98.00%	98.04%	98.07%	98.11%	98.14%	98.18%	98.22%	98.25%	98.29%	98.33%	98.36%	98.40%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.30%	57.34%	60.88%	63.59%	65.46%	67.03%	68.59%	70.14%	71.70%	73.05%	74.38%	75.45%	76.48%	77.43%	78.31%	78.97%	79.49%	80.02%	80.54%	81.06%	81.58%	82.07%	82.56%	83.04%	83.52%	84.00%	84.48%	84.94%	85.35%	85.73%	86.09%	86.45%	86.81%	87.16%	87.52%	87.84%	88.16%	88.47%	88.78%	89.10%	89.41%	89.69%	89.96%	90.21%	90.45%	90.70%	90.95%	91.19%	91.44%	91.68%	91.93%	92.18%	92.42%	92.64%	92.83%	93.00%	93.17%	93.31%	93.43%	93.55%	93.68%	93.79%	93.91%	94.02%	94.14%	94.25%	94.36%	94.47%	94.57%	94.66%	94.76%	94.85%	94.95%	95.04%	95.12%	95.19%	95.26%	95.32%	95.39%	95.45%	95.52%	95.58%	95.65%	95.71%	95.77%	95.82%	95.87%	95.92%	95.97%	96.01%	96.05%	96.10%	96.14%	96.19%	96.23%	96.27%	96.32%	96.36%	96.40%	96.45%	96.49%	96.53%	96.58%	96.62%	96.66%	96.71%	96.75%	96.79%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.07%	97.11%	97.14%	97.18%	97.22%	97.25%	97.29%	97.33%	97.36%	97.40%	97.44%	97.47%	97.51%	97.55%	97.58%	97.62%	97.66%	97.69%	97.73%	97.77%	97.80%	97.84%	97.87%	97.91%	97.95%	97.98%	98.02%	98.06%	98.09%	98.13%	98.17%	98.20%	98.24%	98.27%	98.31%	98.35%	98.38%	98.42%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.31%	57.34%	60.88%	63.60%	65.47%	67.03%	68.59%	70.15%	71.70%	73.06%	74.40%	75.47%	76.50%	77.45%	78.33%	78.99%	79.51%	80.03%	80.56%	81.08%	81.59%	82.09%	82.57%	83.05%	83.53%	84.01%	84.49%	84.96%	85.37%	85.75%	86.12%	86.48%	86.83%	87.19%	87.54%	87.87%	88.19%	88.50%	88.81%	89.12%	89.43%	89.72%	89.99%	90.24%	90.49%	90.74%	90.98%	91.23%	91.47%	91.72%	91.97%	92.21%	92.46%	92.68%	92.87%	93.04%	93.21%	93.35%	93.47%	93.60%	93.72%	93.83%	93.95%	94.06%	94.18%	94.29%	94.40%	94.51%	94.61%	94.70%	94.80%	94.89%	94.99%	95.08%	95.16%	95.23%	95.29%	95.36%	95.42%	95.49%	95.55%	95.62%	95.68%	95.74%	95.79%	95.84%	95.90%	95.95%	95.99%	96.04%	96.08%	96.12%	96.17%	96.21%	96.26%	96.30%	96.34%	96.39%	96.43%	96.47%	96.52%	96.56%	96.60%	96.65%	96.69%	96.73%	96.78%	96.82%	96.86%	96.90%	96.94%	96.98%	97.02%	97.06%	97.09%	97.13%	97.17%	97.20%	97.24%	97.28%	97.31%	97.35%	97.39%	97.42%	97.46%	97.49%	97.53%	97.57%	97.60%	97.64%	97.68%	97.71%	97.75%	97.79%	97.82%	97.86%	97.90%	97.93%	97.97%	98.00%	98.04%	98.08%	98.11%	98.15%	98.19%	98.22%	98.26%	98.30%	98.33%	98.37%	98.40%	98.44%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.32%	57.35%	60.89%	63.61%	65.47%	67.04%	68.60%	70.15%	71.71%	73.07%	74.41%	75.49%	76.51%	77.47%	78.35%	79.00%	79.53%	80.05%	80.57%	81.09%	81.60%	82.10%	82.58%	83.06%	83.54%	84.02%	84.50%	84.97%	85.39%	85.78%	86.14%	86.50%	86.86%	87.21%	87.57%	87.90%	88.22%	88.53%	88.84%	89.15%	89.46%	89.75%	90.02%	90.28%	90.53%	90.77%	91.02%	91.27%	91.51%	91.76%	92.00%	92.25%	92.50%	92.72%	92.91%	93.08%	93.25%	93.39%	93.51%	93.64%	93.76%	93.88%	93.99%	94.11%	94.22%	94.33%	94.44%	94.54%	94.65%	94.75%	94.84%	94.94%	95.03%	95.11%	95.19%	95.26%	95.32%	95.39%	95.45%	95.52%	95.58%	95.65%	95.71%	95.77%	95.82%	95.87%	95.92%	95.97%	96.02%	96.06%	96.11%	96.15%	96.19%	96.24%	96.28%	96.33%	96.37%	96.41%	96.46%	96.50%	96.54%	96.59%	96.63%	96.67%	96.72%	96.76%	96.80%	96.85%	96.89%	96.92%	96.96%	97.00%	97.04%	97.08%	97.11%	97.15%	97.19%	97.22%	97.26%	97.30%	97.33%	97.37%	97.41%	97.44%	97.48%	97.52%	97.55%	97.59%	97.63%	97.66%	97.70%	97.73%	97.77%	97.81%	97.84%	97.88%	97.92%	97.95%	97.99%	98.03%	98.06%	98.10%	98.14%	98.17%	98.21%	98.24%	98.28%	98.32%	98.35%	98.39%	98.43%	98.46%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.32%	57.35%	60.89%	63.62%	65.48%	67.04%	68.60%	70.16%	71.71%	73.09%	74.43%	75.50%	76.53%	77.49%	78.37%	79.02%	79.54%	80.06%	80.59%	81.11%	81.61%	82.11%	82.59%	83.07%	83.55%	84.03%	84.51%	84.99%	85.41%	85.81%	86.17%	86.53%	86.88%	87.24%	87.60%	87.92%	88.24%	88.55%	88.86%	89.17%	89.49%	89.78%	90.05%	90.32%	90.56%	90.81%	91.06%	91.30%	91.55%	91.79%	92.04%	92.29%	92.53%	92.76%	92.95%	93.12%	93.30%	93.43%	93.56%	93.68%	93.80%	93.92%	94.03%	94.15%	94.26%	94.36%	94.47%	94.58%	94.69%	94.79%	94.88%	94.98%	95.07%	95.15%	95.22%	95.29%	95.35%	95.42%	95.48%	95.55%	95.61%	95.68%	95.74%	95.80%	95.85%	95.90%	95.95%	96.00%	96.05%	96.09%	96.13%	96.18%	96.22%	96.26%	96.31%	96.35%	96.40%	96.44%	96.48%	96.53%	96.57%	96.61%	96.66%	96.70%	96.74%	96.79%	96.83%	96.87%	96.91%	96.95%	96.99%	97.02%	97.06%	97.10%	97.14%	97.17%	97.21%	97.24%	97.28%	97.32%	97.35%	97.39%	97.43%	97.46%	97.50%	97.54%	97.57%	97.61%	97.65%	97.68%	97.72%	97.76%	97.79%	97.83%	97.87%	97.90%	97.94%	97.97%	98.01%	98.05%	98.08%	98.12%	98.16%	98.19%	98.23%	98.27%	98.30%	98.34%	98.37%	98.41%	98.45%	98.48%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.33%	57.36%	60.90%	63.63%	65.48%	67.05%	68.61%	70.16%	71.72%	73.10%	74.44%	75.52%	76.55%	77.51%	78.38%	79.03%	79.56%	80.08%	80.60%	81.12%	81.62%	82.12%	82.60%	83.08%	83.56%	84.04%	84.52%	85.00%	85.44%	85.83%	86.20%	86.55%	86.91%	87.27%	87.62%	87.95%	88.27%	88.58%	88.89%	89.20%	89.51%	89.81%	90.08%	90.35%	90.60%	90.85%	91.09%	91.34%	91.59%	91.83%	92.08%	92.32%	92.57%	92.79%	92.99%	93.17%	93.34%	93.48%	93.60%	93.72%	93.85%	93.96%	94.08%	94.18%	94.29%	94.40%	94.51%	94.62%	94.73%	94.83%	94.93%	95.02%	95.10%	95.18%	95.25%	95.32%	95.39%	95.45%	95.52%	95.58%	95.65%	95.71%	95.77%	95.82%	95.87%	95.92%	95.97%	96.03%	96.07%	96.12%	96.16%	96.20%	96.25%	96.29%	96.33%	96.38%	96.42%	96.47%	96.51%	96.55%	96.60%	96.64%	96.68%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.05%	97.08%	97.12%	97.16%	97.19%	97.23%	97.27%	97.30%	97.34%	97.38%	97.41%	97.45%	97.49%	97.52%	97.56%	97.59%	97.63%	97.67%	97.70%	97.74%	97.78%	97.81%	97.85%	97.89%	97.92%	97.96%	98.00%	98.03%	98.07%	98.10%	98.14%	98.18%	98.21%	98.25%	98.29%	98.32%	98.36%	98.40%	98.43%	98.47%	98.50%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.33%	57.36%	60.90%	63.65%	65.49%	67.05%	68.61%	70.17%	71.72%	73.11%	74.45%	75.53%	76.56%	77.53%	78.40%	79.05%	79.57%	80.10%	80.62%	81.13%	81.63%	82.13%	82.61%	83.09%	83.57%	84.05%	84.53%	85.01%	85.46%	85.85%	86.22%	86.58%	86.94%	87.29%	87.65%	87.98%	88.29%	88.61%	88.92%	89.23%	89.54%	89.85%	90.12%	90.38%	90.64%	90.88%	91.13%	91.38%	91.62%	91.87%	92.11%	92.36%	92.61%	92.83%	93.03%	93.21%	93.38%	93.52%	93.64%	93.76%	93.89%	94.00%	94.11%	94.22%	94.33%	94.44%	94.55%	94.66%	94.76%	94.87%	94.97%	95.06%	95.13%	95.21%	95.29%	95.35%	95.42%	95.48%	95.55%	95.61%	95.68%	95.74%	95.80%	95.85%	95.90%	95.95%	96.00%	96.05%	96.10%	96.14%	96.19%	96.23%	96.27%	96.32%	96.36%	96.40%	96.45%	96.49%	96.54%	96.58%	96.62%	96.67%	96.71%	96.75%	96.79%	96.83%	96.87%	96.91%	96.95%	96.99%	97.03%	97.07%	97.10%	97.14%	97.18%	97.21%	97.25%	97.29%	97.32%	97.36%	97.40%	97.43%	97.47%	97.51%	97.54%	97.58%	97.62%	97.65%	97.69%	97.73%	97.76%	97.80%	97.83%	97.87%	97.91%	97.94%	97.98%	98.02%	98.05%	98.09%	98.13%	98.16%	98.20%	98.23%	98.27%	98.31%	98.34%	98.38%	98.42%	98.45%	98.49%	98.52%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.34%	57.37%	60.91%	63.66%	65.49%	67.06%	68.62%	70.18%	71.73%	73.12%	74.46%	75.55%	76.58%	77.55%	78.41%	79.06%	79.59%	80.11%	80.64%	81.14%	81.64%	82.14%	82.62%	83.10%	83.58%	84.06%	84.54%	85.02%	85.47%	85.87%	86.25%	86.61%	86.96%	87.32%	87.68%	88.00%	88.32%	88.63%	88.94%	89.25%	89.56%	89.87%	90.15%	90.41%	90.67%	90.92%	91.17%	91.41%	91.66%	91.91%	92.15%	92.40%	92.64%	92.87%	93.06%	93.25%	93.42%	93.56%	93.68%	93.81%	93.93%	94.04%	94.15%	94.26%	94.37%	94.48%	94.58%	94.69%	94.80%	94.91%	95.01%	95.09%	95.17%	95.24%	95.32%	95.38%	95.45%	95.51%	95.58%	95.64%	95.71%	95.77%	95.82%	95.88%	95.93%	95.98%	96.03%	96.08%	96.13%	96.17%	96.21%	96.26%	96.30%	96.34%	96.39%	96.43%	96.47%	96.52%	96.56%	96.61%	96.65%	96.69%	96.74%	96.78%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.05%	97.09%	97.13%	97.16%	97.20%	97.24%	97.27%	97.31%	97.34%	97.38%	97.42%	97.45%	97.49%	97.53%	97.56%	97.60%	97.64%	97.67%	97.71%	97.75%	97.78%	97.82%	97.86%	97.89%	97.93%	97.97%	98.00%	98.04%	98.07%	98.11%	98.15%	98.18%	98.22%	98.26%	98.29%	98.33%	98.37%	98.40%	98.44%	98.47%	98.51%	98.54%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.34%	57.37%	60.91%	63.67%	65.50%	67.06%	68.62%	70.18%	71.73%	73.13%	74.47%	75.57%	76.59%	77.58%	78.43%	79.08%	79.60%	80.13%	80.65%	81.15%	81.65%	82.15%	82.63%	83.11%	83.59%	84.07%	84.55%	85.03%	85.49%	85.89%	86.28%	86.63%	86.99%	87.35%	87.70%	88.03%	88.35%	88.66%	88.97%	89.28%	89.59%	89.90%	90.18%	90.45%	90.71%	90.96%	91.20%	91.45%	91.70%	91.94%	92.19%	92.43%	92.68%	92.91%	93.10%	93.28%	93.47%	93.60%	93.73%	93.85%	93.97%	94.08%	94.19%	94.29%	94.40%	94.51%	94.62%	94.73%	94.84%	94.94%	95.05%	95.12%	95.20%	95.27%	95.35%	95.42%	95.48%	95.55%	95.61%	95.68%	95.74%	95.80%	95.85%	95.90%	95.95%	96.00%	96.05%	96.10%	96.15%	96.20%	96.24%	96.28%	96.33%	96.37%	96.41%	96.46%	96.50%	96.54%	96.59%	96.63%	96.68%	96.72%	96.76%	96.80%	96.84%	96.87%	96.91%	96.95%	96.99%	97.03%	97.07%	97.11%	97.15%	97.18%	97.22%	97.26%	97.29%	97.33%	97.37%	97.40%	97.44%	97.48%	97.51%	97.55%	97.59%	97.62%	97.66%	97.69%	97.73%	97.77%	97.80%	97.84%	97.88%	97.91%	97.95%	97.99%	98.02%	98.06%	98.10%	98.13%	98.17%	98.20%	98.24%	98.28%	98.31%	98.35%	98.39%	98.42%	98.46%	98.50%	98.53%	98.57%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.35%	57.38%	60.92%	63.68%	65.50%	67.07%	68.63%	70.19%	71.74%	73.14%	74.48%	75.58%	76.61%	77.59%	78.44%	79.10%	79.62%	80.14%	80.66%	81.16%	81.66%	82.16%	82.64%	83.12%	83.60%	84.08%	84.57%	85.05%	85.51%	85.91%	86.30%	86.66%	87.02%	87.37%	87.73%	88.06%	88.37%	88.69%	89.00%	89.31%	89.62%	89.93%	90.21%	90.48%	90.75%	91.00%	91.24%	91.49%	91.73%	91.98%	92.23%	92.47%	92.72%	92.94%	93.14%	93.32%	93.50%	93.65%	93.77%	93.89%	94.01%	94.11%	94.22%	94.33%	94.44%	94.55%	94.66%	94.77%	94.87%	94.98%	95.07%	95.15%	95.23%	95.31%	95.38%	95.45%	95.51%	95.58%	95.64%	95.71%	95.77%	95.83%	95.88%	95.93%	95.98%	96.03%	96.08%	96.13%	96.18%	96.22%	96.27%	96.31%	96.35%	96.40%	96.44%	96.48%	96.53%	96.57%	96.61%	96.66%	96.70%	96.74%	96.78%	96.82%	96.86%	96.90%	96.93%	96.97%	97.01%	97.05%	97.09%	97.13%	97.17%	97.20%	97.24%	97.28%	97.31%	97.35%	97.39%	97.42%	97.46%	97.50%	97.53%	97.57%	97.61%	97.64%	97.68%	97.72%	97.75%	97.79%	97.83%	97.86%	97.90%	97.93%	97.97%	98.01%	98.04%	98.08%	98.12%	98.15%	98.19%	98.23%	98.26%	98.30%	98.33%	98.37%	98.41%	98.44%	98.48%	98.52%	98.55%	98.59%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.35%	57.39%	60.92%	63.69%	65.51%	67.07%	68.63%	70.19%	71.74%	73.15%	74.49%	75.60%	76.62%	77.61%	78.45%	79.11%	79.64%	80.16%	80.67%	81.17%	81.68%	82.17%	82.65%	83.13%	83.61%	84.10%	84.58%	85.06%	85.52%	85.93%	86.32%	86.69%	87.04%	87.40%	87.76%	88.08%	88.40%	88.71%	89.02%	89.33%	89.64%	89.95%	90.24%	90.51%	90.78%	91.03%	91.28%	91.52%	91.77%	92.02%	92.26%	92.51%	92.75%	92.98%	93.17%	93.36%	93.54%	93.69%	93.81%	93.93%	94.04%	94.15%	94.26%	94.37%	94.48%	94.59%	94.69%	94.80%	94.91%	95.01%	95.10%	95.19%	95.26%	95.34%	95.41%	95.48%	95.54%	95.61%	95.67%	95.74%	95.80%	95.85%	95.90%	95.95%	96.01%	96.06%	96.11%	96.16%	96.20%	96.25%	96.29%	96.34%	96.38%	96.42%	96.47%	96.51%	96.55%	96.60%	96.64%	96.68%	96.72%	96.76%	96.80%	96.84%	96.88%	96.92%	96.96%	96.99%	97.03%	97.07%	97.11%	97.15%	97.19%	97.23%	97.26%	97.30%	97.34%	97.37%	97.41%	97.44%	97.48%	97.52%	97.55%	97.59%	97.63%	97.66%	97.70%	97.74%	97.77%	97.81%	97.85%	97.88%	97.92%	97.96%	97.99%	98.03%	98.06%	98.10%	98.14%	98.17%	98.21%	98.25%	98.28%	98.32%	98.36%	98.39%	98.43%	98.46%	98.50%	98.54%	98.57%	98.61%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.36%	57.39%	60.93%	63.70%	65.51%	67.08%	68.64%	70.20%	71.75%	73.16%	74.50%	75.61%	76.64%	77.63%	78.46%	79.13%	79.65%	80.18%	80.68%	81.19%	81.69%	82.18%	82.66%	83.14%	83.62%	84.11%	84.59%	85.07%	85.54%	85.95%	86.35%	86.71%	87.07%	87.43%	87.78%	88.11%	88.43%	88.74%	89.05%	89.36%	89.67%	89.98%	90.27%	90.54%	90.81%	91.07%	91.32%	91.56%	91.81%	92.05%	92.30%	92.55%	92.79%	93.02%	93.21%	93.39%	93.58%	93.73%	93.85%	93.97%	94.08%	94.19%	94.30%	94.41%	94.51%	94.62%	94.73%	94.83%	94.94%	95.04%	95.13%	95.22%	95.29%	95.37%	95.44%	95.51%	95.58%	95.64%	95.71%	95.77%	95.83%	95.88%	95.93%	95.98%	96.03%	96.08%	96.13%	96.18%	96.23%	96.27%	96.32%	96.36%	96.41%	96.45%	96.49%	96.54%	96.58%	96.62%	96.66%	96.70%	96.74%	96.78%	96.82%	96.86%	96.90%	96.94%	96.98%	97.02%	97.06%	97.09%	97.13%	97.17%	97.21%	97.25%	97.28%	97.32%	97.36%	97.39%	97.43%	97.47%	97.50%	97.54%	97.58%	97.61%	97.65%	97.69%	97.72%	97.76%	97.79%	97.83%	97.87%	97.90%	97.94%	97.98%	98.01%	98.05%	98.09%	98.12%	98.16%	98.20%	98.23%	98.27%	98.30%	98.34%	98.38%	98.41%	98.45%	98.49%	98.52%	98.56%	98.59%	98.63%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.36%	57.40%	60.94%	63.71%	65.52%	67.09%	68.65%	70.20%	71.75%	73.17%	74.51%	75.63%	76.66%	77.64%	78.47%	79.14%	79.67%	80.19%	80.70%	81.20%	81.70%	82.19%	82.67%	83.15%	83.64%	84.12%	84.60%	85.08%	85.55%	85.98%	86.37%	86.74%	87.10%	87.45%	87.81%	88.13%	88.45%	88.76%	89.08%	89.39%	89.70%	90.01%	90.31%	90.57%	90.84%	91.11%	91.35%	91.60%	91.84%	92.09%	92.34%	92.58%	92.83%	93.05%	93.25%	93.43%	93.62%	93.76%	93.90%	94.01%	94.12%	94.23%	94.33%	94.44%	94.55%	94.66%	94.76%	94.87%	94.97%	95.06%	95.15%	95.24%	95.32%	95.40%	95.48%	95.54%	95.61%	95.67%	95.74%	95.80%	95.85%	95.91%	95.96%	96.01%	96.06%	96.11%	96.16%	96.21%	96.26%	96.30%	96.35%	96.39%	96.43%	96.48%	96.52%	96.56%	96.61%	96.65%	96.69%	96.72%	96.76%	96.80%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.08%	97.12%	97.15%	97.19%	97.23%	97.27%	97.30%	97.34%	97.38%	97.41%	97.45%	97.49%	97.52%	97.56%	97.60%	97.63%	97.67%	97.71%	97.74%	97.78%	97.82%	97.85%	97.89%	97.93%	97.96%	98.00%	98.03%	98.07%	98.11%	98.14%	98.18%	98.22%	98.25%	98.29%	98.33%	98.36%	98.40%	98.43%	98.47%	98.51%	98.54%	98.58%	98.62%	98.65%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.37%	57.40%	60.94%	63.71%	65.53%	67.09%	68.65%	70.21%	71.76%	73.18%	74.53%	75.64%	76.67%	77.66%	78.47%	79.15%	79.68%	80.21%	80.71%	81.21%	81.71%	82.20%	82.68%	83.17%	83.65%	84.13%	84.61%	85.09%	85.57%	86.00%	86.39%	86.77%	87.12%	87.48%	87.83%	88.16%	88.48%	88.79%	89.10%	89.41%	89.72%	90.03%	90.34%	90.61%	90.87%	91.14%	91.39%	91.64%	91.88%	92.13%	92.37%	92.62%	92.87%	93.09%	93.29%	93.47%	93.65%	93.80%	93.93%	94.04%	94.15%	94.26%	94.37%	94.48%	94.59%	94.69%	94.80%	94.90%	95.00%	95.09%	95.18%	95.27%	95.36%	95.43%	95.51%	95.57%	95.64%	95.70%	95.77%	95.83%	95.88%	95.93%	95.98%	96.03%	96.08%	96.14%	96.19%	96.24%	96.28%	96.33%	96.37%	96.42%	96.46%	96.50%	96.55%	96.59%	96.63%	96.67%	96.71%	96.75%	96.78%	96.82%	96.86%	96.90%	96.94%	96.98%	97.02%	97.06%	97.10%	97.14%	97.18%	97.21%	97.25%	97.29%	97.33%	97.36%	97.40%	97.44%	97.47%	97.51%	97.54%	97.58%	97.62%	97.65%	97.69%	97.73%	97.76%	97.80%	97.84%	97.87%	97.91%	97.95%	97.98%	98.02%	98.06%	98.09%	98.13%	98.16%	98.20%	98.24%	98.27%	98.31%	98.35%	98.38%	98.42%	98.46%	98.49%	98.53%	98.56%	98.60%	98.64%	98.67%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.37%	57.41%	60.95%	63.72%	65.53%	67.10%	68.66%	70.21%	71.76%	73.19%	74.54%	75.66%	76.69%	77.67%	78.48%	79.16%	79.70%	80.22%	80.72%	81.22%	81.72%	82.21%	82.69%	83.18%	83.66%	84.14%	84.62%	85.10%	85.58%	86.02%	86.41%	86.79%	87.15%	87.50%	87.86%	88.19%	88.51%	88.82%	89.13%	89.44%	89.75%	90.06%	90.36%	90.64%	90.90%	91.17%	91.43%	91.67%	91.92%	92.16%	92.41%	92.66%	92.90%	93.13%	93.32%	93.51%	93.69%	93.84%	93.97%	94.08%	94.19%	94.30%	94.41%	94.52%	94.62%	94.73%	94.83%	94.93%	95.03%	95.12%	95.21%	95.30%	95.38%	95.46%	95.54%	95.61%	95.67%	95.74%	95.80%	95.86%	95.91%	95.96%	96.01%	96.06%	96.11%	96.16%	96.21%	96.26%	96.31%	96.35%	96.40%	96.44%	96.49%	96.53%	96.57%	96.61%	96.65%	96.69%	96.73%	96.77%	96.81%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.08%	97.12%	97.16%	97.20%	97.24%	97.27%	97.31%	97.35%	97.38%	97.42%	97.46%	97.49%	97.53%	97.57%	97.60%	97.64%	97.68%	97.71%	97.75%	97.78%	97.82%	97.86%	97.89%	97.93%	97.97%	98.00%	98.04%	98.08%	98.11%	98.15%	98.19%	98.22%	98.26%	98.30%	98.33%	98.37%	98.40%	98.44%	98.48%	98.51%	98.55%	98.59%	98.62%	98.66%	98.69%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.38%	57.41%	60.95%	63.72%	65.54%	67.10%	68.66%	70.22%	71.77%	73.20%	74.55%	75.68%	76.70%	77.69%	78.48%	79.17%	79.72%	80.23%	80.73%	81.23%	81.73%	82.22%	82.70%	83.19%	83.67%	84.15%	84.63%	85.11%	85.59%	86.04%	86.43%	86.81%	87.18%	87.53%	87.89%	88.21%	88.53%	88.84%	89.15%	89.47%	89.78%	90.09%	90.39%	90.67%	90.94%	91.20%	91.46%	91.71%	91.96%	92.20%	92.45%	92.69%	92.94%	93.16%	93.36%	93.54%	93.73%	93.88%	94.00%	94.12%	94.23%	94.34%	94.45%	94.55%	94.65%	94.76%	94.86%	94.96%	95.05%	95.14%	95.23%	95.32%	95.41%	95.50%	95.57%	95.64%	95.70%	95.77%	95.83%	95.88%	95.93%	95.98%	96.04%	96.09%	96.14%	96.19%	96.24%	96.29%	96.34%	96.38%	96.42%	96.47%	96.51%	96.55%	96.59%	96.63%	96.67%	96.71%	96.75%	96.79%	96.83%	96.87%	96.91%	96.94%	96.98%	97.02%	97.06%	97.10%	97.14%	97.18%	97.22%	97.26%	97.29%	97.33%	97.37%	97.40%	97.44%	97.48%	97.51%	97.55%	97.59%	97.62%	97.66%	97.70%	97.73%	97.77%	97.81%	97.84%	97.88%	97.92%	97.95%	97.99%	98.02%	98.06%	98.10%	98.13%	98.17%	98.21%	98.24%	98.28%	98.32%	98.35%	98.39%	98.43%	98.46%	98.50%	98.53%	98.57%	98.61%	98.64%	98.68%	98.71%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.38%	57.42%	60.96%	63.73%	65.54%	67.11%	68.67%	70.22%	71.77%	73.21%	74.56%	75.69%	76.72%	77.70%	78.49%	79.17%	79.73%	80.24%	80.74%	81.24%	81.74%	82.23%	82.72%	83.20%	83.68%	84.16%	84.64%	85.12%	85.60%	86.05%	86.45%	86.84%	87.20%	87.56%	87.91%	88.24%	88.56%	88.87%	89.18%	89.49%	89.80%	90.11%	90.42%	90.70%	90.97%	91.23%	91.50%	91.75%	91.99%	92.24%	92.48%	92.73%	92.98%	93.20%	93.40%	93.58%	93.76%	93.91%	94.03%	94.15%	94.26%	94.37%	94.48%	94.58%	94.69%	94.79%	94.89%	94.99%	95.08%	95.17%	95.26%	95.35%	95.44%	95.53%	95.60%	95.67%	95.73%	95.80%	95.86%	95.91%	95.96%	96.01%	96.06%	96.11%	96.16%	96.21%	96.27%	96.32%	96.36%	96.41%	96.45%	96.49%	96.53%	96.57%	96.61%	96.65%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.00%	97.04%	97.08%	97.12%	97.16%	97.20%	97.24%	97.28%	97.32%	97.35%	97.39%	97.43%	97.46%	97.50%	97.54%	97.57%	97.61%	97.64%	97.68%	97.72%	97.75%	97.79%	97.83%	97.86%	97.90%	97.94%	97.97%	98.01%	98.05%	98.08%	98.12%	98.16%	98.19%	98.23%	98.26%	98.30%	98.34%	98.37%	98.41%	98.45%	98.48%	98.52%	98.56%	98.59%	98.63%	98.66%	98.70%	98.74%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.39%	57.42%	60.96%	63.74%	65.55%	67.11%	68.67%	70.23%	71.78%	73.22%	74.57%	75.71%	76.74%	77.72%	78.49%	79.18%	79.75%	80.25%	80.75%	81.25%	81.75%	82.24%	82.73%	83.21%	83.69%	84.17%	84.65%	85.13%	85.61%	86.07%	86.47%	86.86%	87.23%	87.58%	87.94%	88.27%	88.59%	88.90%	89.21%	89.52%	89.83%	90.14%	90.44%	90.73%	91.00%	91.27%	91.53%	91.78%	92.03%	92.28%	92.52%	92.77%	93.01%	93.24%	93.43%	93.62%	93.80%	93.94%	94.06%	94.18%	94.30%	94.41%	94.51%	94.62%	94.72%	94.82%	94.92%	95.02%	95.11%	95.20%	95.29%	95.37%	95.46%	95.55%	95.63%	95.70%	95.77%	95.83%	95.89%	95.94%	95.99%	96.04%	96.09%	96.14%	96.19%	96.24%	96.29%	96.34%	96.39%	96.43%	96.48%	96.52%	96.56%	96.59%	96.63%	96.67%	96.71%	96.75%	96.79%	96.83%	96.87%	96.91%	96.95%	96.99%	97.03%	97.06%	97.10%	97.14%	97.18%	97.22%	97.26%	97.30%	97.34%	97.37%	97.41%	97.45%	97.48%	97.52%	97.56%	97.59%	97.63%	97.67%	97.70%	97.74%	97.78%	97.81%	97.85%	97.88%	97.92%	97.96%	97.99%	98.03%	98.07%	98.10%	98.14%	98.18%	98.21%	98.25%	98.29%	98.32%	98.36%	98.39%	98.43%	98.47%	98.50%	98.54%	98.58%	98.61%	98.65%	98.69%	98.72%	98.76%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.39%	57.43%	60.97%	63.74%	65.55%	67.12%	68.68%	70.23%	71.79%	73.23%	74.58%	75.72%	76.75%	77.73%	78.50%	79.18%	79.76%	80.26%	80.76%	81.26%	81.76%	82.25%	82.74%	83.22%	83.70%	84.18%	84.66%	85.14%	85.62%	86.08%	86.49%	86.88%	87.25%	87.61%	87.97%	88.29%	88.61%	88.92%	89.23%	89.54%	89.86%	90.17%	90.47%	90.76%	91.03%	91.30%	91.56%	91.82%	92.07%	92.31%	92.56%	92.80%	93.05%	93.28%	93.47%	93.65%	93.84%	93.97%	94.09%	94.21%	94.34%	94.44%	94.54%	94.65%	94.75%	94.85%	94.95%	95.04%	95.13%	95.22%	95.31%	95.40%	95.49%	95.58%	95.67%	95.73%	95.80%	95.86%	95.91%	95.96%	96.01%	96.06%	96.11%	96.17%	96.22%	96.27%	96.32%	96.37%	96.42%	96.46%	96.50%	96.54%	96.58%	96.62%	96.66%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.05%	97.09%	97.13%	97.16%	97.20%	97.24%	97.28%	97.32%	97.36%	97.39%	97.43%	97.47%	97.50%	97.54%	97.58%	97.61%	97.65%	97.69%	97.72%	97.76%	97.80%	97.83%	97.87%	97.91%	97.94%	97.98%	98.02%	98.05%	98.09%	98.12%	98.16%	98.20%	98.23%	98.27%	98.31%	98.34%	98.38%	98.42%	98.45%	98.49%	98.53%	98.56%	98.60%	98.63%	98.67%	98.71%	98.74%	98.78%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.40%	57.43%	60.97%	63.75%	65.56%	67.12%	68.68%	70.24%	71.79%	73.24%	74.59%	75.74%	76.77%	77.74%	78.50%	79.19%	79.77%	80.27%	80.77%	81.27%	81.77%	82.27%	82.75%	83.23%	83.71%	84.19%	84.67%	85.15%	85.63%	86.10%	86.51%	86.90%	87.28%	87.64%	87.99%	88.32%	88.64%	88.95%	89.26%	89.57%	89.88%	90.19%	90.49%	90.79%	91.06%	91.33%	91.60%	91.86%	92.10%	92.35%	92.60%	92.84%	93.09%	93.31%	93.51%	93.69%	93.87%	94.00%	94.13%	94.25%	94.36%	94.47%	94.58%	94.68%	94.78%	94.88%	94.98%	95.07%	95.16%	95.25%	95.34%	95.43%	95.52%	95.61%	95.69%	95.76%	95.83%	95.89%	95.94%	95.99%	96.04%	96.09%	96.14%	96.19%	96.24%	96.29%	96.34%	96.40%	96.44%	96.48%	96.52%	96.56%	96.60%	96.64%	96.68%	96.72%	96.75%	96.79%	96.83%	96.87%	96.91%	96.95%	96.99%	97.03%	97.07%	97.11%	97.15%	97.19%	97.22%	97.26%	97.30%	97.34%	97.38%	97.42%	97.45%	97.49%	97.53%	97.56%	97.60%	97.64%	97.67%	97.71%	97.74%	97.78%	97.82%	97.85%	97.89%	97.93%	97.96%	98.00%	98.04%	98.07%	98.11%	98.15%	98.18%	98.22%	98.26%	98.29%	98.33%	98.36%	98.40%	98.44%	98.47%	98.51%	98.55%	98.58%	98.62%	98.66%	98.69%	98.73%	98.76%	98.80%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.41%	57.44%	60.98%	63.75%	65.56%	67.13%	68.69%	70.24%	71.80%	73.25%	74.60%	75.76%	76.78%	77.75%	78.51%	79.19%	79.78%	80.28%	80.78%	81.28%	81.78%	82.28%	82.76%	83.24%	83.72%	84.20%	84.68%	85.16%	85.64%	86.11%	86.54%	86.92%	87.30%	87.66%	88.02%	88.35%	88.67%	88.98%	89.29%	89.60%	89.91%	90.22%	90.52%	90.82%	91.09%	91.36%	91.63%	91.89%	92.14%	92.39%	92.63%	92.88%	93.12%	93.35%	93.54%	93.73%	93.91%	94.04%	94.16%	94.27%	94.39%	94.50%	94.61%	94.71%	94.81%	94.92%	95.01%	95.10%	95.19%	95.28%	95.37%	95.45%	95.54%	95.63%	95.72%	95.80%	95.86%	95.91%	95.96%	96.02%	96.07%	96.12%	96.17%	96.22%	96.27%	96.32%	96.37%	96.42%	96.46%	96.50%	96.54%	96.58%	96.62%	96.66%	96.70%	96.74%	96.78%	96.82%	96.85%	96.89%	96.93%	96.97%	97.01%	97.05%	97.09%	97.13%	97.17%	97.21%	97.25%	97.28%	97.32%	97.36%	97.40%	97.44%	97.47%	97.51%	97.55%	97.58%	97.62%	97.66%	97.69%	97.73%	97.77%	97.80%	97.84%	97.88%	97.91%	97.95%	97.98%	98.02%	98.06%	98.09%	98.13%	98.17%	98.20%	98.24%	98.28%	98.31%	98.35%	98.39%	98.42%	98.46%	98.49%	98.53%	98.57%	98.60%	98.64%	98.68%	98.71%	98.75%	98.79%	98.82%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.41%	57.44%	60.98%	63.76%	65.57%	67.13%	68.69%	70.25%	71.80%	73.26%	74.61%	75.77%	76.80%	77.76%	78.52%	79.20%	79.79%	80.29%	80.79%	81.29%	81.79%	82.29%	82.77%	83.25%	83.73%	84.21%	84.69%	85.17%	85.65%	86.13%	86.56%	86.94%	87.32%	87.69%	88.05%	88.37%	88.69%	89.00%	89.31%	89.62%	89.93%	90.24%	90.55%	90.85%	91.13%	91.39%	91.66%	91.93%	92.18%	92.42%	92.67%	92.92%	93.16%	93.39%	93.58%	93.76%	93.95%	94.07%	94.19%	94.30%	94.42%	94.53%	94.64%	94.74%	94.84%	94.95%	95.04%	95.12%	95.21%	95.30%	95.39%	95.48%	95.57%	95.66%	95.75%	95.83%	95.89%	95.94%	95.99%	96.04%	96.09%	96.14%	96.19%	96.25%	96.30%	96.35%	96.40%	96.44%	96.48%	96.52%	96.56%	96.60%	96.64%	96.68%	96.72%	96.76%	96.80%	96.84%	96.88%	96.91%	96.95%	96.99%	97.03%	97.07%	97.11%	97.15%	97.19%	97.23%	97.27%	97.31%	97.34%	97.38%	97.42%	97.46%	97.49%	97.53%	97.57%	97.60%	97.64%	97.68%	97.71%	97.75%	97.79%	97.82%	97.86%	97.90%	97.93%	97.97%	98.01%	98.04%	98.08%	98.12%	98.15%	98.19%	98.22%	98.26%	98.30%	98.33%	98.37%	98.41%	98.44%	98.48%	98.52%	98.55%	98.59%	98.62%	98.66%	98.70%	98.73%	98.77%	98.81%	98.84%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.42%	57.45%	60.99%	63.76%	65.57%	67.14%	68.70%	70.25%	71.81%	73.28%	74.62%	75.79%	76.82%	77.77%	78.52%	79.20%	79.80%	80.30%	80.80%	81.30%	81.80%	82.30%	82.78%	83.26%	83.74%	84.22%	84.70%	85.18%	85.66%	86.14%	86.58%	86.96%	87.35%	87.72%	88.07%	88.40%	88.72%	89.03%	89.34%	89.65%	89.96%	90.27%	90.57%	90.87%	91.16%	91.42%	91.69%	91.96%	92.21%	92.46%	92.71%	92.95%	93.20%	93.42%	93.62%	93.80%	93.98%	94.10%	94.21%	94.33%	94.44%	94.56%	94.67%	94.77%	94.88%	94.97%	95.06%	95.15%	95.24%	95.33%	95.42%	95.51%	95.60%	95.68%	95.77%	95.85%	95.92%	95.97%	96.02%	96.07%	96.12%	96.17%	96.22%	96.27%	96.32%	96.37%	96.42%	96.46%	96.50%	96.54%	96.58%	96.62%	96.66%	96.70%	96.74%	96.78%	96.82%	96.86%	96.90%	96.94%	96.98%	97.01%	97.05%	97.09%	97.13%	97.17%	97.21%	97.25%	97.29%	97.33%	97.37%	97.40%	97.44%	97.48%	97.52%	97.55%	97.59%	97.63%	97.66%	97.70%	97.74%	97.77%	97.81%	97.84%	97.88%	97.92%	97.95%	97.99%	98.03%	98.06%	98.10%	98.14%	98.17%	98.21%	98.25%	98.28%	98.32%	98.36%	98.39%	98.43%	98.46%	98.50%	98.54%	98.57%	98.61%	98.65%	98.68%	98.72%	98.75%	98.79%	98.83%	98.86%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.42%	57.45%	60.99%	63.77%	65.58%	67.14%	68.70%	70.26%	71.81%	73.29%	74.63%	75.80%	76.83%	77.78%	78.53%	79.21%	79.81%	80.31%	80.81%	81.31%	81.81%	82.31%	82.79%	83.27%	83.75%	84.23%	84.71%	85.19%	85.67%	86.15%	86.60%	86.98%	87.37%	87.74%	88.10%	88.43%	88.74%	89.06%	89.37%	89.68%	89.99%	90.30%	90.60%	90.90%	91.19%	91.46%	91.72%	91.99%	92.25%	92.50%	92.74%	92.99%	93.24%	93.46%	93.66%	93.84%	94.01%	94.12%	94.24%	94.35%	94.47%	94.58%	94.70%	94.81%	94.91%	95.00%	95.09%	95.18%	95.27%	95.36%	95.44%	95.53%	95.62%	95.71%	95.80%	95.88%	95.94%	95.99%	96.04%	96.09%	96.15%	96.20%	96.25%	96.30%	96.35%	96.40%	96.45%	96.49%	96.52%	96.56%	96.60%	96.64%	96.68%	96.72%	96.76%	96.80%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.07%	97.11%	97.15%	97.19%	97.23%	97.27%	97.31%	97.35%	97.39%	97.43%	97.46%	97.50%	97.54%	97.57%	97.61%	97.65%	97.68%	97.72%	97.76%	97.79%	97.83%	97.87%	97.90%	97.94%	97.98%	98.01%	98.05%	98.08%	98.12%	98.16%	98.19%	98.23%	98.27%	98.30%	98.34%	98.38%	98.41%	98.45%	98.49%	98.52%	98.56%	98.59%	98.63%	98.67%	98.70%	98.74%	98.78%	98.81%	98.85%	98.88%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.43%	57.46%	61.00%	63.77%	65.58%	67.15%	68.71%	70.26%	71.82%	73.30%	74.64%	75.82%	76.84%	77.79%	78.53%	79.21%	79.82%	80.32%	80.82%	81.32%	81.82%	82.32%	82.80%	83.28%	83.76%	84.24%	84.72%	85.20%	85.68%	86.16%	86.61%	87.00%	87.39%	87.77%	88.13%	88.45%	88.77%	89.08%	89.39%	89.70%	90.01%	90.32%	90.63%	90.93%	91.22%	91.49%	91.75%	92.02%	92.29%	92.53%	92.78%	93.03%	93.27%	93.50%	93.69%	93.88%	94.04%	94.15%	94.26%	94.38%	94.49%	94.61%	94.72%	94.84%	94.94%	95.03%	95.11%	95.20%	95.29%	95.38%	95.47%	95.56%	95.65%	95.74%	95.83%	95.90%	95.97%	96.02%	96.07%	96.12%	96.17%	96.22%	96.27%	96.32%	96.38%	96.43%	96.47%	96.51%	96.55%	96.59%	96.62%	96.66%	96.70%	96.74%	96.78%	96.82%	96.86%	96.90%	96.94%	96.98%	97.02%	97.06%	97.10%	97.13%	97.17%	97.21%	97.25%	97.29%	97.33%	97.37%	97.41%	97.45%	97.48%	97.52%	97.56%	97.59%	97.63%	97.67%	97.70%	97.74%	97.78%	97.81%	97.85%	97.89%	97.92%	97.96%	98.00%	98.03%	98.07%	98.11%	98.14%	98.18%	98.22%	98.25%	98.29%	98.32%	98.36%	98.40%	98.43%	98.47%	98.51%	98.54%	98.58%	98.62%	98.65%	98.69%	98.72%	98.76%	98.80%	98.83%	98.87%	98.90%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.43%	57.46%	61.00%	63.78%	65.59%	67.15%	68.71%	70.27%	71.82%	73.31%	74.65%	75.84%	76.86%	77.80%	78.54%	79.22%	79.83%	80.33%	80.83%	81.33%	81.83%	82.33%	82.81%	83.29%	83.77%	84.25%	84.73%	85.21%	85.69%	86.17%	86.63%	87.03%	87.41%	87.79%	88.15%	88.48%	88.80%	89.11%	89.42%	89.73%	90.04%	90.35%	90.65%	90.95%	91.25%	91.52%	91.79%	92.05%	92.32%	92.57%	92.82%	93.06%	93.31%	93.53%	93.73%	93.91%	94.06%	94.18%	94.29%	94.41%	94.52%	94.64%	94.75%	94.86%	94.96%	95.05%	95.14%	95.23%	95.32%	95.41%	95.50%	95.59%	95.68%	95.76%	95.85%	95.92%	95.99%	96.05%	96.10%	96.15%	96.20%	96.25%	96.30%	96.35%	96.40%	96.45%	96.49%	96.53%	96.57%	96.61%	96.65%	96.69%	96.72%	96.76%	96.80%	96.84%	96.88%	96.92%	96.96%	97.00%	97.04%	97.08%	97.12%	97.16%	97.19%	97.23%	97.27%	97.31%	97.35%	97.39%	97.43%	97.47%	97.51%	97.54%	97.58%	97.62%	97.65%	97.69%	97.73%	97.76%	97.80%	97.84%	97.87%	97.91%	97.94%	97.98%	98.02%	98.05%	98.09%	98.13%	98.16%	98.20%	98.24%	98.27%	98.31%	98.35%	98.38%	98.42%	98.45%	98.49%	98.53%	98.56%	98.60%	98.64%	98.67%	98.71%	98.75%	98.78%	98.82%	98.85%	98.89%	98.92%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.44%	57.47%	61.01%	63.78%	65.59%	67.16%	68.72%	70.28%	71.83%	73.32%	74.66%	75.85%	76.87%	77.81%	78.54%	79.22%	79.84%	80.34%	80.84%	81.34%	81.84%	82.34%	82.82%	83.30%	83.78%	84.26%	84.74%	85.23%	85.71%	86.19%	86.64%	87.05%	87.43%	87.81%	88.18%	88.51%	88.82%	89.13%	89.45%	89.76%	90.07%	90.38%	90.68%	90.98%	91.28%	91.55%	91.82%	92.08%	92.35%	92.61%	92.85%	93.10%	93.35%	93.57%	93.76%	93.94%	94.09%	94.20%	94.32%	94.43%	94.55%	94.66%	94.78%	94.89%	94.99%	95.08%	95.17%	95.26%	95.35%	95.43%	95.52%	95.61%	95.70%	95.79%	95.88%	95.94%	96.01%	96.07%	96.12%	96.17%	96.22%	96.28%	96.33%	96.38%	96.43%	96.47%	96.51%	96.55%	96.59%	96.63%	96.67%	96.71%	96.75%	96.79%	96.82%	96.86%	96.90%	96.94%	96.98%	97.02%	97.06%	97.10%	97.14%	97.18%	97.22%	97.26%	97.29%	97.33%	97.37%	97.41%	97.45%	97.49%	97.53%	97.56%	97.60%	97.64%	97.67%	97.71%	97.75%	97.78%	97.82%	97.86%	97.89%	97.93%	97.97%	98.00%	98.04%	98.08%	98.11%	98.15%	98.18%	98.22%	98.26%	98.29%	98.33%	98.37%	98.40%	98.44%	98.48%	98.51%	98.55%	98.59%	98.62%	98.66%	98.69%	98.73%	98.77%	98.80%	98.84%	98.88%	98.91%	98.93%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.44%	57.48%	61.01%	63.79%	65.60%	67.16%	68.72%	70.28%	71.83%	73.33%	74.67%	75.86%	76.88%	77.82%	78.55%	79.23%	79.84%	80.35%	80.85%	81.35%	81.85%	82.35%	82.83%	83.31%	83.79%	84.28%	84.76%	85.24%	85.72%	86.20%	86.66%	87.07%	87.45%	87.84%	88.20%	88.53%	88.85%	89.16%	89.47%	89.78%	90.09%	90.40%	90.71%	91.01%	91.30%	91.58%	91.85%	92.12%	92.38%	92.65%	92.89%	93.14%	93.38%	93.60%	93.79%	93.97%	94.11%	94.23%	94.34%	94.46%	94.57%	94.69%	94.80%	94.91%	95.01%	95.10%	95.19%	95.28%	95.37%	95.46%	95.55%	95.64%	95.73%	95.82%	95.90%	95.96%	96.03%	96.09%	96.15%	96.20%	96.25%	96.30%	96.35%	96.40%	96.45%	96.49%	96.53%	96.57%	96.61%	96.65%	96.69%	96.73%	96.77%	96.81%	96.85%	96.88%	96.92%	96.96%	97.00%	97.04%	97.08%	97.12%	97.16%	97.20%	97.24%	97.28%	97.32%	97.35%	97.39%	97.43%	97.47%	97.51%	97.55%	97.58%	97.62%	97.66%	97.69%	97.73%	97.77%	97.80%	97.84%	97.88%	97.91%	97.95%	97.99%	98.02%	98.06%	98.10%	98.13%	98.17%	98.21%	98.24%	98.28%	98.32%	98.35%	98.39%	98.42%	98.46%	98.50%	98.53%	98.57%	98.61%	98.64%	98.68%	98.72%	98.75%	98.79%	98.82%	98.86%	98.89%	98.92%	98.94%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.44%	57.48%	61.02%	63.79%	65.60%	67.17%	68.73%	70.29%	71.84%	73.34%	74.68%	75.88%	76.89%	77.83%	78.55%	79.24%	79.85%	80.36%	80.86%	81.37%	81.87%	82.36%	82.84%	83.32%	83.80%	84.29%	84.77%	85.25%	85.73%	86.21%	86.68%	87.09%	87.47%	87.86%	88.23%	88.56%	88.88%	89.19%	89.50%	89.81%	90.12%	90.43%	90.73%	91.03%	91.33%	91.61%	91.88%	92.15%	92.41%	92.68%	92.93%	93.17%	93.42%	93.64%	93.82%	94.00%	94.14%	94.26%	94.37%	94.49%	94.60%	94.71%	94.83%	94.93%	95.03%	95.13%	95.22%	95.31%	95.40%	95.49%	95.58%	95.67%	95.75%	95.84%	95.92%	95.99%	96.05%	96.11%	96.18%	96.23%	96.28%	96.33%	96.38%	96.43%	96.47%	96.51%	96.55%	96.59%	96.63%	96.67%	96.71%	96.75%	96.79%	96.83%	96.87%	96.91%	96.95%	96.98%	97.02%	97.06%	97.10%	97.14%	97.18%	97.22%	97.26%	97.30%	97.34%	97.38%	97.41%	97.45%	97.49%	97.53%	97.57%	97.61%	97.64%	97.68%	97.72%	97.75%	97.79%	97.83%	97.86%	97.90%	97.94%	97.97%	98.01%	98.04%	98.08%	98.12%	98.15%	98.19%	98.23%	98.26%	98.30%	98.34%	98.37%	98.41%	98.45%	98.48%	98.52%	98.55%	98.59%	98.63%	98.66%	98.70%	98.74%	98.77%	98.80%	98.84%	98.87%	98.90%	98.93%	98.96%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.44%	57.49%	61.03%	63.80%	65.61%	67.18%	68.74%	70.29%	71.84%	73.35%	74.69%	75.89%	76.90%	77.84%	78.56%	79.24%	79.85%	80.37%	80.88%	81.38%	81.88%	82.37%	82.85%	83.33%	83.82%	84.30%	84.78%	85.26%	85.74%	86.22%	86.69%	87.11%	87.49%	87.88%	88.26%	88.58%	88.90%	89.21%	89.52%	89.84%	90.15%	90.46%	90.76%	91.06%	91.36%	91.65%	91.91%	92.18%	92.45%	92.71%	92.97%	93.21%	93.45%	93.67%	93.86%	94.03%	94.17%	94.28%	94.40%	94.51%	94.63%	94.74%	94.85%	94.95%	95.05%	95.15%	95.25%	95.34%	95.42%	95.51%	95.60%	95.69%	95.78%	95.87%	95.94%	96.01%	96.07%	96.14%	96.20%	96.25%	96.30%	96.35%	96.41%	96.45%	96.49%	96.53%	96.57%	96.61%	96.65%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.04%	97.08%	97.12%	97.16%	97.20%	97.24%	97.28%	97.32%	97.36%	97.40%	97.44%	97.47%	97.51%	97.55%	97.59%	97.63%	97.66%	97.70%	97.74%	97.77%	97.81%	97.85%	97.88%	97.92%	97.96%	97.99%	98.03%	98.07%	98.10%	98.14%	98.18%	98.21%	98.25%	98.28%	98.32%	98.36%	98.39%	98.43%	98.47%	98.50%	98.54%	98.58%	98.61%	98.65%	98.68%	98.72%	98.75%	98.79%	98.82%	98.85%	98.88%	98.91%	98.94%	98.97%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.44%	57.49%	61.03%	63.80%	65.62%	67.18%	68.74%	70.30%	71.85%	73.36%	74.71%	75.90%	76.91%	77.85%	78.56%	79.25%	79.86%	80.38%	80.89%	81.39%	81.89%	82.38%	82.86%	83.34%	83.83%	84.31%	84.79%	85.27%	85.75%	86.23%	86.71%	87.13%	87.52%	87.90%	88.28%	88.61%	88.93%	89.24%	89.55%	89.86%	90.17%	90.48%	90.79%	91.08%	91.38%	91.68%	91.94%	92.21%	92.48%	92.74%	93.00%	93.24%	93.48%	93.70%	93.89%	94.07%	94.19%	94.31%	94.42%	94.54%	94.65%	94.77%	94.87%	94.97%	95.08%	95.18%	95.27%	95.36%	95.45%	95.54%	95.63%	95.72%	95.81%	95.89%	95.96%	96.03%	96.09%	96.16%	96.22%	96.28%	96.33%	96.38%	96.43%	96.48%	96.52%	96.55%	96.59%	96.63%	96.67%	96.71%	96.75%	96.79%	96.83%	96.87%	96.91%	96.95%	96.99%	97.03%	97.07%	97.11%	97.14%	97.18%	97.22%	97.26%	97.30%	97.34%	97.38%	97.42%	97.46%	97.50%	97.54%	97.57%	97.61%	97.65%	97.69%	97.72%	97.76%	97.79%	97.83%	97.87%	97.90%	97.94%	97.98%	98.01%	98.05%	98.09%	98.12%	98.16%	98.20%	98.23%	98.27%	98.31%	98.34%	98.38%	98.42%	98.45%	98.49%	98.52%	98.56%	98.60%	98.63%	98.66%	98.70%	98.73%	98.77%	98.80%	98.83%	98.86%	98.89%	98.92%	98.95%	98.98%
  0.00%	6.37%	12.48%	18.39%	24.19%	29.92%	35.60%	41.24%	46.86%	52.44%	57.50%	61.04%	63.81%	65.62%	67.19%	68.75%	70.30%	71.85%	73.37%	74.72%	75.91%	76.92%	77.86%	78.57%	79.25%	79.86%	80.40%	80.90%	81.40%	81.90%	82.39%	82.87%	83.36%	83.84%	84.32%	84.80%	85.28%	85.76%	86.24%	86.72%	87.15%	87.54%	87.92%	88.30%	88.64%	88.96%	89.27%	89.58%	89.89%	90.20%	90.51%	90.81%	91.11%	91.41%	91.71%	91.98%	92.24%	92.51%	92.78%	93.03%	93.27%	93.51%	93.73%	93.92%	94.10%	94.22%	94.34%	94.45%	94.56%	94.68%	94.79%	94.89%	94.99%	95.10%	95.20%	95.30%	95.39%	95.48%	95.57%	95.66%	95.74%	95.83%	95.91%	95.98%	96.05%	96.11%	96.18%	96.24%	96.31%	96.36%	96.41%	96.46%	96.50%	96.54%	96.58%	96.62%	96.65%	96.69%	96.73%	96.77%	96.81%	96.85%	96.89%	96.93%	96.97%	97.01%	97.05%	97.09%	97.13%	97.17%	97.20%	97.24%	97.28%	97.32%	97.36%	97.40%	97.44%	97.48%	97.52%	97.56%	97.60%	97.63%	97.67%	97.71%	97.74%	97.78%	97.82%	97.85%	97.89%	97.93%	97.96%	98.00%	98.04%	98.07%	98.11%	98.14%	98.18%	98.22%	98.25%	98.29%	98.33%	98.36%	98.40%	98.44%	98.47%	98.51%	98.54%	98.58%	98.61%	98.65%	98.68%	98.71%	98.75%	98.78%	98.82%	98.85%	98.87%	98.90%	98.93%	98.96%	98.99%
"""
  .parseStringToPercentageTable()


/**
 * Parses one of the above string tables into a usable ElectricityDistributionTable
 */
private fun String.parseStringToPercentageTable(): ElectricityDistributionTable {
  // The tables have dimensions of 160x160
  val numberOfRows = 160
  val numberOfColumns = 160
  return this.trimIndent()
    .lineSequence()
    .mapIndexed { batteryIndex, line: String ->
      line.splitToSequence(" ", "\t")
        .mapIndexed { peakIndex, percentage: String ->
          PeakPerformance(
            /**
             * The relativeKiloWattPeak corresponds to PowerRating / AnnualElectricityConsumption * 100 and ranges from 0.0 to 1.0
             * 0.0 meaning the PV system is nonexistent or minuscule compared to the yearly consumed electricity
             * and 1.0 meaning the PV system is relatively large compared to the yearly consumed electricity
             * these are not scientific values but a middle step in the mapping process
             */
            /**
             * The relativeKiloWattPeak corresponds to PowerRating / AnnualElectricityConsumption * 100 and ranges from 0.0 to 1.0
             * 0.0 meaning the PV system is nonexistent or minuscule compared to the yearly consumed electricity
             * and 1.0 meaning the PV system is relatively large compared to the yearly consumed electricity
             * these are not scientific values but a middle step in the mapping process
             */
            peakIndex.toDouble() / numberOfColumns.toDouble(),
            PerformanceFactor(percentage.trim('%').toDouble().percent)
          )
        }.toList()
        .let { listOfPeakPerformances ->
          Row(
            /**
             * The relativeBatteryCapacity corresponds to BatteryCapacity / AnnualElectricityConsumption * 100 and ranges from 0.0 to 1.0
             * 0.0 meaning the battery is nonexistent or minuscule compared to the yearly consumed electricity
             * and 1.0 meaning the battery is relatively large compared to the yearly consumed electricity
             * these are not scientific values but a middle step in the mapping process
             */
            /**
             * The relativeBatteryCapacity corresponds to BatteryCapacity / AnnualElectricityConsumption * 100 and ranges from 0.0 to 1.0
             * 0.0 meaning the battery is nonexistent or minuscule compared to the yearly consumed electricity
             * and 1.0 meaning the battery is relatively large compared to the yearly consumed electricity
             * these are not scientific values but a middle step in the mapping process
             */
            batteryIndex.toDouble() / numberOfRows.toDouble(),
            PeakMap(listOfPeakPerformances)
          )
        }
    }.toList()
    .let { listOfRows ->
      ElectricityDistributionTable(listOfRows)
    }
}
