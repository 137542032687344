package it.neckar.react.common

import react.*
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract

/**
 * Shows a busy indicator if the property is null.
 * Calls the handler with the value if it is not null.
 */
fun <T> RBuilder.busyIfNull(value: T?, small: Boolean = false, nonNullHandler: RElementBuilder<*>.(T) -> Unit) {
  contract {
    callsInPlace(nonNullHandler, InvocationKind.AT_MOST_ONCE)
  }

  return child(BusyIfNull) {
    attrs {
      this.busy = value == null
      this.small = small
    }

    if (value != null) {
      this.nonNullHandler(value)
    }
  }
}

fun <T1, T2> RBuilder.busyIfNull(value1: T1?, value2: T2?, small: Boolean = false, nonNullHandler: RElementBuilder<*>.(T1, T2) -> Unit) {
  contract {
    callsInPlace(nonNullHandler, InvocationKind.AT_MOST_ONCE)
  }

  return child(BusyIfNull) {
    attrs {
      this.busy = value1 == null || value2 == null
      this.small = small
    }

    if (value1 != null && value2 != null) {
      this.nonNullHandler(value1, value2)
    }
  }
}

fun <T1, T2, T3> RBuilder.busyIfNull(value1: T1?, value2: T2?, value3: T3?, small: Boolean = false, nonNullHandler: RElementBuilder<*>.(T1, T2, T3) -> Unit) {
  contract {
    callsInPlace(nonNullHandler, InvocationKind.AT_MOST_ONCE)
  }

  return child(BusyIfNull) {
    attrs {
      this.busy = value1 == null || value2 == null || value3 == null
      this.small = small
    }

    if (value1 != null && value2 != null && value3 != null) {
      this.nonNullHandler(value1, value2, value3)
    }
  }
}

val BusyIfNull: FC<BusyIfNullProps> = fc("busyIfNull") { props ->
  if (props.busy == true) {
    if (props.small == true) {
      BusyIndicatorSmall {}
    } else {
      BusyIndicator {}
    }
  } else {
    props.children()
  }
}

external interface BusyIfNullProps : PropsWithChildren {
  var busy: Boolean?
  var small: Boolean?
}
