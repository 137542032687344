@file:Suppress("DuplicatedCode")

package components.company

import components.project.address.addressForm
import components.project.ensureElementInList
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.user.UserLoginName
import react.*
import react.dom.*
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUsersAndCompanies

/**
 * Form for a company
 */
fun RBuilder.companyForm(
  companyCode: StateInstance<String>,
  companyName: StateInstance<String>,
  companyType: StateInstance<CompanyTypeEnum>?,
  defaultUser: StateInstance<UserLoginName>?,

  street: StateInstance<String>,
  houseNumber: StateInstance<String>,
  zipCode: StateInstance<String>,
  city: StateInstance<String>,

  mail: StateInstance<String>,
  phone: StateInstance<String>,
  fax: StateInstance<String>,
  url: StateInstance<String>,

  bankName: StateInstance<String>,
  iban: StateInstance<String>,
  bic: StateInstance<String>,

  director: StateInstance<String>,
  registerCourt: StateInstance<String>,
  taxId: StateInstance<String>,
  registrationNumber: StateInstance<String>,

  existingCompany: PlannerCompanyInformation?,
): Unit = child(CompanyForm) {
  attrs {
    this.companyCode = companyCode
    this.companyName = companyName
    this.companyType = companyType
    this.defaultUser = defaultUser

    this.street = street
    this.houseNumber = houseNumber
    this.zipCode = zipCode
    this.city = city

    this.mail = mail
    this.phone = phone
    this.fax = fax
    this.url = url

    this.bankName = bankName
    this.iban = iban
    this.bic = bic

    this.director = director
    this.registerCourt = registerCourt
    this.taxId = taxId
    this.registrationNumber = registrationNumber

    this.existingCompany = existingCompany
  }
}

val CompanyForm: FC<CompanyFormProps> = fc("CompanyForm") { props ->

  val existingCompany = props::existingCompany.safeGet()
  val defaultUser = props::defaultUser.safeGet()

  val companyCode = props::companyCode.getNotNull()
  val companyName = props::companyName.getNotNull()
  val companyType = props.companyType

  val street = props::street.getNotNull()
  val houseNumber = props::houseNumber.getNotNull()
  val zipCode = props::zipCode.getNotNull()
  val city = props::city.getNotNull()

  val mail = props::mail.getNotNull()
  val phone = props::phone.getNotNull()
  val fax = props::fax.getNotNull()
  val url = props::url.getNotNull()

  val bankName = props::bankName.getNotNull()
  val iban = props::iban.getNotNull()
  val bic = props::bic.getNotNull()

  val director = props::director.getNotNull()
  val registerCourt = props::registerCourt.getNotNull()
  val taxId = props::taxId.getNotNull()
  val registrationNumber = props::registrationNumber.getNotNull()

  val loggedInUser = useRequireLoggedInUser()
  val usersAndCompanies = useSelectUsersAndCompanies()
  val usersForCompany = usersAndCompanies.usersForCompany(CompanyCode(companyCode.value))
    .ensureElementInList(loggedInUser)
    .map { it.loginName }


  div {

    div("my-3") {
      floatingInputField(
        valueAndSetter = companyCode,
        fieldName = "companyCode",
        title = "Firmencode",
        editableStatus = if (existingCompany == null) EditableStatus.Editable else EditableStatus.ReadOnly,
      ) {
        attrs {
          mergedBelow()
        }
      }
      floatingInputField(
        valueAndSetter = companyName,
        fieldName = "companyName",
        title = "Firmenname",
      ) {
        attrs {
          mergedAbove()
        }
      }
    }

    companyType?.let {
      div("my-3") {
        floatingSelect(
          valueAndSetter = companyType,
          idProvider = { it.name },
          formatter = { it.format() },
          availableOptions = CompanyTypeEnum.entries,
          fieldName = "companyType",
          title = "Firmentyp",
        )
      }
    }

    defaultUser?.let { defaultUser ->
      div("my-3") {
        floatingSelect(
          valueAndSetter = defaultUser,
          idProvider = { it.value },
          formatter = { usersAndCompanies[it].editorName },
          availableOptions = usersForCompany,
          fieldName = "defaultUser",
          title = "Default Nutzer",
        )
      }
    }

    div("my-3") {
      h3 {
        +"Adresse"
      }

      div("my-3") {
        addressForm(
          street = street,
          houseNumber = houseNumber,
          zipCode = zipCode,
          city = city,
          editableStatus = EditableStatus.Editable,
        )
      }
    }

    div("my-3") {
      h3 {
        +"Kontaktinformationen"
      }

      div("my-3") {
        contactInformationForm {
          attrs {
            this.mail = mail
            this.phone = phone
            this.fax = fax
            this.url = url
          }
        }
      }
    }

    div("my-3") {
      h3 {
        +"Bankinformationen"
      }

      div("my-3") {
        bankInformationForm {
          attrs {
            this.bankName = bankName
            this.iban = iban
            this.bic = bic
          }
        }
      }
    }

    div("my-3") {
      h3 {
        +"Rechtliches"
      }

      div("my-3") {
        legalInformationForm {
          attrs {
            this.director = director
            this.registerCourt = registerCourt
            this.taxId = taxId
            this.registrationNumber = registrationNumber
          }
        }
      }
    }

  }

}

external interface CompanyFormProps : Props {
  var companyCode: StateInstance<String>
  var companyName: StateInstance<String>
  var companyType: StateInstance<CompanyTypeEnum>?
  var defaultUser: StateInstance<UserLoginName>?

  var street: StateInstance<String>
  var houseNumber: StateInstance<String>
  var zipCode: StateInstance<String>
  var city: StateInstance<String>

  var mail: StateInstance<String>
  var phone: StateInstance<String>
  var fax: StateInstance<String>
  var url: StateInstance<String>

  var bankName: StateInstance<String>
  var iban: StateInstance<String>
  var bic: StateInstance<String>

  var director: StateInstance<String>
  var registerCourt: StateInstance<String>
  var taxId: StateInstance<String>
  var registrationNumber: StateInstance<String>

  var existingCompany: PlannerCompanyInformation?
}
