package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.lizergy.model.configuration.quote.economics.YearIndex
import it.neckar.open.kotlin.lang.fastMap
import it.neckar.open.kotlin.lang.isCloseTo
import it.neckar.open.kotlin.lang.or1ifInfinite
import it.neckar.open.kotlin.lang.or1ifNaN
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.kWh
import it.neckar.open.unit.time.pa
import kotlinx.serialization.Serializable

/**
 * Contains the information related to energy
 */
@Serializable
data class EnergyInformation(
  /**
   * The amount of energy that has been produced in a time frame
   */
  val production: @kWh it.neckar.lizergy.model.configuration.energy.AmountOfEnergy,

  /**
   * The amount of energy that is fed (sold) into the grid
   */
  val feedIn: @kWh it.neckar.lizergy.model.configuration.energy.AmountOfEnergy,

  /**
   * Energy that is consumed directly (and not sold)
   */
  val ownConsumption: @kWh it.neckar.lizergy.model.configuration.energy.AmountOfEnergy,

  ) {
  init {
    require(production.kWh.isCloseTo((feedIn + ownConsumption).kWh, 0.0001)) {
      "Values do not make sense: production: $production should be same as ($feedIn + $ownConsumption = ${feedIn + ownConsumption}) "
    }
  }

  /**
   * Calculates the energy information
   */
  class Calculator(
    /**
     * The initial production
     */
    var initialProduction: it.neckar.lizergy.model.configuration.energy.AmountOfEnergy,

    /**
     * The amount of the produced energy that is consumed directly
     */
    var ownConsumption: it.neckar.lizergy.model.configuration.energy.AmountOfEnergy,
  ) {

    /**
     * The percentage that is removed from the production for every year
     */
    var degradation: @pct @pa Double = 0.5.percent

    /**
     * The percentage that is fed in
     */
    val feedInPercentage: @pct Double
      get() {
        return 1 - ownConsumptionPercentage
      }

    val ownConsumptionPercentage: @pct Double
      get() {
        return (ownConsumption / initialProduction).or1ifNaN().or1ifInfinite()
      }

    /**
     * Returns the percentage for a year index
     * 0 represents the first year
     */
    fun productionPercentageForYear(yearIndex: YearIndex): @pct Double {
      return 1.0 - degradation * yearIndex.index
    }

    /**
     * Returns the production for a year index
     * 0 represents the first year
     */
    fun productionForYear(yearIndex: YearIndex): it.neckar.lizergy.model.configuration.energy.AmountOfEnergy {
      return initialProduction * productionPercentageForYear(yearIndex)
    }

    /**
     * Calculates the amount of energy that has been consumed
     */
    fun ownConsumptionForYear(yearIndex: YearIndex): it.neckar.lizergy.model.configuration.energy.AmountOfEnergy {
      return productionForYear(yearIndex) * ownConsumptionPercentage
    }

    /**
     * The amount that has been fed in
     */
    fun fedInForYear(yearIndex: YearIndex): it.neckar.lizergy.model.configuration.energy.AmountOfEnergy {
      return productionForYear(yearIndex) * feedInPercentage
    }

    /**
     * Calculates the energy information for the given year index
     */
    fun calculate(base: YearIndex): EnergyInformation {
      return EnergyInformation(
        productionForYear(base),
        fedInForYear(base),
        ownConsumptionForYear(base)
      )
    }

    /**
     * Calculates a series of years starting at year 0
     */
    fun calculateSeries(numberOfYears: Int): List<EnergyInformation> {
      return numberOfYears.fastMap {
        calculate(YearIndex(it))
      }
    }
  }
}
