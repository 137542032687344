package it.neckar.financial.quote

import it.neckar.financial.currency.Money
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.financial.currency.sum
import it.neckar.open.collections.fastForEach
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.currency.EUR
import kotlinx.serialization.Serializable

@Serializable
data class PricesForVATs(private val map: Map<ValueAddedTax, @EUR Money>) {
  constructor(maps: List<PricesForVATs>) : this(mutableMapOf<ValueAddedTax, @EUR Money>().apply {
    maps.fastForEach { pricesForVATs -> putAll(pricesForVATs.map) }
  })

  val total: @EUR Money
    get() = map.values.sum()

  val vats: List<ValueAddedTax>
    get() = map.keys.sortedBy { it.vat }

  val prices: List<@EUR Money>
    get() = map.values.toList()

  operator fun get(valueAddedTax: ValueAddedTax): @EUR Money {
    return map[valueAddedTax] ?: Money.Zero
  }

  fun toGrossPrices(): PricesForVATs {
    return map {
      it.key to it.value * (it.key + 100.percent)
    }
  }

  fun getVatPrices(): PricesForVATs {
    return map { it.key to (it.value * it.key) }
  }

  fun map(function: (Map.Entry<ValueAddedTax, @EUR Money>) -> Pair<ValueAddedTax, @EUR Money>): PricesForVATs {
    return PricesForVATs(map.map { function(it) }.toMap())
  }

  fun mapValues(function: (Money) -> Money): PricesForVATs {
    return PricesForVATs(map.mapValues { function(it.value) }.toMap())
  }

  fun forEach(function: (Pair<ValueAddedTax, Money>) -> Unit) {
    map.toList().sortedBy { it.first.vat }.fastForEach { function(it) }
  }

  fun forEachValue(function: (Money) -> Unit) {
    map.toList().sortedBy { it.first.vat }.fastForEach { function(it.second) }
  }

  fun all(function: (Map.Entry<ValueAddedTax, @EUR Money>) -> Boolean): Boolean {
    return map.all(function)
  }

  fun allValues(function: (Money) -> Boolean): Boolean {
    return map.all { function(it.value) }
  }


  companion object {
    val empty: PricesForVATs = PricesForVATs(emptyMap())
  }

}
