package it.neckar.lizergy.model.configuration.energy

import it.neckar.financial.currency.Money
import it.neckar.open.formatting.decimalFormat2digits
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.kWh_kWp_a
import it.neckar.open.unit.time.pa
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents the amount of energy that is produced per kWp per year at a *given location*
 */
@Serializable
@JvmInline
value class SpecificAnnualProduction(val kWhPerkWpAndYear: @kWh_kWp_a Double) : Comparable<SpecificAnnualProduction> {
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "${decimalFormat2digits.format(kWhPerkWpAndYear, i18nConfiguration)}${whitespaceConfig.smallSpace}kWh${whitespaceConfig.smallSpace}/${whitespaceConfig.smallSpace}kWp${whitespaceConfig.smallSpace}/${whitespaceConfig.smallSpace}a"
  }

  override operator fun compareTo(other: SpecificAnnualProduction): Int {
    return this.kWhPerkWpAndYear.compareTo(other.kWhPerkWpAndYear)
  }

  operator fun times(factor: @pct Double): SpecificAnnualProduction {
    return SpecificAnnualProduction(kWhPerkWpAndYear * factor)
  }

  /**
   * Returns the amount of energy that is produced for the given power rating for *one year*
   */
  operator fun times(powerRating: PowerRating): @pa it.neckar.lizergy.model.configuration.energy.AmountOfEnergy {
    return it.neckar.lizergy.model.configuration.energy.AmountOfEnergy.kWh(this.kWhPerkWpAndYear * powerRating.kiloWattPeak)
  }

  /**
   * Multiplies
   */
  operator fun times(price: Money): Money {
    return Money.euros(price.euros * kWhPerkWpAndYear)
  }

  override fun toString(): String {
    return format(I18nConfiguration.US, WhitespaceConfig.Spaces)
  }

  /**
   * The values have been extracted from:
   * https://photovoltaiksolarstrom.com/photovoltaiklexikon/solarertrag-staedte/
   */
  companion object {
    val Zero: SpecificAnnualProduction = SpecificAnnualProduction(0.0)

    val Moessingen: SpecificAnnualProduction = SpecificAnnualProduction(1035.0)
    val Freiburg: SpecificAnnualProduction = SpecificAnnualProduction(1048.0)
    val Stuttgart: SpecificAnnualProduction = SpecificAnnualProduction(1025.0)
    val Karlsruhe: SpecificAnnualProduction = SpecificAnnualProduction(1032.0)
    val Berlin: SpecificAnnualProduction = SpecificAnnualProduction(929.0)

    /**
     * Default requested by lizergy
     */
    val LizergyDefault: SpecificAnnualProduction = SpecificAnnualProduction(1100.0)
  }
}

operator fun it.neckar.lizergy.model.configuration.energy.AmountOfEnergy.div(specificAnnualProduction: SpecificAnnualProduction): PowerRating {
  return PowerRating.kiloWattPeak(this.kWh / specificAnnualProduction.kWhPerkWpAndYear)
}
