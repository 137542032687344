package it.neckar.ktor.client.plugin.stats

import it.neckar.open.unit.other.pct

/**
 * A data class representing the state of pending and completed HTTP requests.
 */
data class PendingRequestsState(
  /**
   * The pending requests (does *not* contain the completed requests)
   */
  val pendingRequests: List<PendingRequestDescriptor> = listOf(),

  /**
   * The completed requests
   */
  val completedRequests: List<CompletedRequestDescriptor> = listOf(),
) {

  /**
   * Returns the total requests count - pending and completed
   */
  val totalRequestsCount: Int
    get() {
      return pendingRequests.size + completedRequests.size
    }

  /**
   * Checks if all the started requests have been completed.
   * Returns [empty] if all requests have been completed.
   */
  fun clearedIfCompleted(): PendingRequestsState {
    if (pendingRequests.isEmpty()) {
      return empty
    }

    return this
  }

  /**
   * Calculates the progress percentage of the completed requests.
   *
   * @return the progress percentage.
   */
  fun calculateProgress(): @pct Double {
    if (totalRequestsCount == 0) {
      return 0.0
    }

    val completedRequestsCount = completedRequests.size
    val pendingRequestCount = pendingRequests.size

    return completedRequestsCount.toDouble() / pendingRequestCount.toDouble().coerceAtLeast(0.2)
  }

  /**
   * Returns a new instance with the additional pending request
   */
  fun withPending(request: PendingRequestDescriptor): PendingRequestsState {
    return this.copy(pendingRequests = pendingRequests + request)
  }

  /**
   * Removes the request from pending - and adds a completed request
   */
  fun withCompleted(completedRequestDescriptor: CompletedRequestDescriptor): PendingRequestsState {
    return this.copy(
      pendingRequests = pendingRequests.filter {
        it.matches(completedRequestDescriptor).not() //remove the corresponding pending request
      },
      completedRequests = completedRequests + completedRequestDescriptor
    )
  }

  fun hasPendingRequests(): Boolean {
    return pendingRequests.isNotEmpty()
  }

  companion object {
    val empty: PendingRequestsState = PendingRequestsState(emptyList(), emptyList())
  }
}



