package components.project.configuration.pricestrend

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.quote.economics.simple.PricePerKWh
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.kotlin.lang.roundDecimalPlaces
import it.neckar.open.kotlin.lang.roundDecimalPlacesHalfUp
import it.neckar.open.unit.currency.Cent
import it.neckar.open.unit.other.pct100
import it.neckar.open.unit.time.pa
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

val EditPricesTrend: FC<EditPricesTrendProps> = fc("EditPricesTrend") { props ->
  val pricesTrendScenarioToSave = props::pricesTrendScenarioToSave.getNotNull()
  val fallbackGuaranteedFeedInPrice = props::fallbackGuaranteedFeedInPrice.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val pricesTrendScenario = pricesTrendScenarioToSave.value

  @Cent val guaranteedFeedInPrice = useState(pricesTrendScenario.guaranteedFeedInPrice?.cents?.roundDecimalPlacesHalfUp(2))
  @Cent val consumptionPrice = useState(pricesTrendScenario.consumptionPrice.cents)
  @pa @pct100 val consumptionPriceIncreasePercentage = useState(pricesTrendScenario.consumptionPriceIncreasePercentage * 100)


  useMemo(guaranteedFeedInPrice.value, consumptionPrice.value, consumptionPriceIncreasePercentage.value) {
    pricesTrendScenarioToSave.setter(
      PricesTrendScenario(
        guaranteedFeedInPrice = guaranteedFeedInPrice.value?.let { PricePerKWh.cents(it) },
        consumptionPrice = PricePerKWh.cents(consumptionPrice.value),
        consumptionPriceIncreasePercentage = consumptionPriceIncreasePercentage.value.percent,
      )
    )
  }


  div("my-5") {

    h2 {
      +"Strompreise"
    }

    pricesTrendScenarioForm(
      guaranteedFeedInPrice = guaranteedFeedInPrice,
      consumptionPrice = consumptionPrice,
      consumptionPriceIncreasePercentage = consumptionPriceIncreasePercentage,
      fallbackGuaranteedFeedInPrice = fallbackGuaranteedFeedInPrice,
      editableStatus = editableStatus,
    )
  }

}


external interface EditPricesTrendProps : Props {
  var pricesTrendScenarioToSave: StateInstance<PricesTrendScenario>
  var fallbackGuaranteedFeedInPrice: @Cent Double?
  var editableStatus: EditableStatus
}
