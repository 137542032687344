package it.neckar.lizergy.model.configuration.energy

import com.meistercharts.charts.lizergy.solar.SouthRelative
import it.neckar.lizergy.model.configuration.energy.PerformanceTable.InclinationMap
import it.neckar.lizergy.model.configuration.energy.PerformanceTable.InclinationPerformance
import it.neckar.lizergy.model.configuration.energy.PerformanceTable.Row
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofInclination
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofOrientation
import it.neckar.open.formatting.format
import it.neckar.open.kotlin.lang.abs
import it.neckar.open.kotlin.lang.interpolate
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.kotlin.lang.relativeDistanceBetween
import it.neckar.open.unit.other.deg
import it.neckar.open.unit.other.pct
import kotlin.jvm.JvmInline

/**
 * Calculates the "performance" of the modules depending on the roof inclination and orientation
 */
interface PerformanceCalculator {
  /**
   * Returns the performance factor for the given azimuth and orientation
   */
  fun calculatePerformanceFactor(orientation: @SouthRelative RoofOrientation, inclination: @deg RoofInclination): @pct PerformanceFactor

  fun dump(interval: Int = @deg 5) {
    val orientationRange = @deg 180
    val inclinationRange = @deg 90

    print("Absolute Values For $this\n\n")
    print("Inclination:".padEnd(10))
    for (inclination in 0..inclinationRange step interval) print(inclination.format().padStart(10))
    println()
    print("Orientation:\n")

    for (orientation in 0..orientationRange step interval) {
      print(orientation.format().padStart(7).padEnd(15))

      for (inclination in 0..inclinationRange step interval) {
        val value = calculatePerformanceFactor(RoofOrientation.normalized(orientation), RoofInclination(inclination))
        print(value.format().padStart(10))
      }
      println()
    }
  }
}


object InterpolatedPerformanceCalculator : PerformanceCalculator {
  override fun calculatePerformanceFactor(
    orientation: @SouthRelative RoofOrientation,
    inclination: @deg RoofInclination
  ): @pct PerformanceFactor {
    return performanceTable.calculateInterpolatedPerformanceFactor(orientation, inclination)
  }
}


/**
 * Performances indexed by RoofOrientation and RoofInclination inside a table
 */
data class PerformanceTable(val rows: List<Row>) {
  /**
   * Calculates the "performance" of the modules depending on the roof inclination and orientation
   * Uses a table, look up estimate values
   */
  fun calculateSimplePerformanceFactor(
    orientation: @SouthRelative RoofOrientation,
    inclination: @deg RoofInclination,
  ): @pct PerformanceFactor {
    val bestRow = findRowCloserToSouth(orientation)
    return bestRow.findLowerPerformanceBound(inclination).performanceFactor
  }


  /**
   * Calculates the "performance" of the modules depending on the roof inclination and orientation
   * Uses a table, look up estimate values and linearly interpolates between them
   */
  fun calculateInterpolatedPerformanceFactor(
    orientation: @SouthRelative RoofOrientation,
    inclination: @deg RoofInclination,
  ): @pct PerformanceFactor {
    val rowCloserToNorth = findRowCloserToNorth(orientation)
    val rowCloserToSouth = findRowCloserToSouth(orientation)
    val northernPerformanceFactor = rowCloserToNorth.findInterpolatedValue(inclination)
    val southernPerformanceFactor = rowCloserToSouth.findInterpolatedValue(inclination)

    if (rowCloserToNorth == rowCloserToSouth) return southernPerformanceFactor

    val relativeDistance = orientation.orientation.abs().toDouble().relativeDistanceBetween(
      rowCloserToNorth.roofOrientation.orientation.toDouble(),
      rowCloserToSouth.roofOrientation.orientation.toDouble()
    )
    val interpolated = relativeDistance.interpolate(northernPerformanceFactor.percentage, southernPerformanceFactor.percentage)
    return PerformanceFactor(interpolated)
  }


  /**
   * Returns the southern Row from the table for the given orientation
   * For calculateSimplePerformanceFactor this is treated as the most accurate Row
   */
  private fun findRowCloserToSouth(orientation: @SouthRelative RoofOrientation): Row {
    return rows.findLast {
      it.roofOrientation.orientation <= orientation.orientation.abs()
    } ?: throw IllegalArgumentException("No Row found for orientation $orientation")
  }

  /**
   * Returns the northern Row from the table for the given orientation
   */
  private fun findRowCloserToNorth(orientation: @SouthRelative RoofOrientation): Row {
    return rows.find {
      it.roofOrientation.orientation >= orientation.orientation.abs()
    } ?: throw IllegalArgumentException("No Row found for orientation $orientation")
  }


  /**
   * Contains an InclinationMap with the corresponding PerformanceFactors for one orientation
   */
  data class Row(
    val roofOrientation: @SouthRelative RoofOrientation,
    val inclinationMap: InclinationMap
  ) {
    fun findLowerPerformanceBound(inclination: @deg RoofInclination): InclinationPerformance {
      return inclinationMap.map.findLast {
        it.inclination.angle <= inclination.angle
      } ?: throw IllegalArgumentException("No InclinationPerformance found for inclination $inclination")
    }

    private fun findUpperPerformanceBound(inclination: @deg RoofInclination): InclinationPerformance {
      return inclinationMap.map.find {
        it.inclination.angle >= inclination.angle
      } ?: throw IllegalArgumentException("No InclinationPerformance found for inclination $inclination")
    }

    /**
     * Returns a linearly interpolated factor for the inclination
     */
    fun findInterpolatedValue(inclination: @deg RoofInclination): @pct PerformanceFactor {
      val upperBound = findUpperPerformanceBound(inclination)
      val lowerBound = findLowerPerformanceBound(inclination)
      if (upperBound == lowerBound) return lowerBound.performanceFactor

      val relativeDistance = inclination.angle.toDouble().relativeDistanceBetween(upperBound.inclination.angle.toDouble(), lowerBound.inclination.angle.toDouble())
      val interpolated = relativeDistance.interpolate(upperBound.performanceFactor.percentage, lowerBound.performanceFactor.percentage)
      return PerformanceFactor(interpolated)
    }
  }

  @JvmInline
  value class InclinationMap(val map: List<InclinationPerformance>)
  data class InclinationPerformance(val inclination: @deg RoofInclination, val performanceFactor: @pct PerformanceFactor)
}


/**
 * This performance table was pulled from:
 *    https://www.rechnerphotovoltaik.de/photovoltaik/voraussetzungen/dachausrichtung
 *    https://www.photovoltaik-web.de/photovoltaik/dacheignung/dachausrichtung
 * and validated with:
 *    https://re.jrc.ec.europa.eu/pvg_tools/en/
 */
private val performanceTable = PerformanceTable(
  listOf(
    Row(
      RoofOrientation.normalized(0), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(93.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(97.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(100.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(100.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(98.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(94.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(88.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(80.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(69.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(10), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(93.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(97.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(99.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(99.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(97.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(93.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(79.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(69.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(20), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(93.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(97.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(99.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(99.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(96.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(92.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(86.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(78.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(69.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(30), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(92.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(96.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(97.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(97.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(95.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(91.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(85.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(77.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(67.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(40), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(92.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(95.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(96.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(95.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(93.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(88.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(82.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(75.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(65.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(50), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(91.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(93.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(94.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(93.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(90.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(85.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(79.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(72.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(63.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(60), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(90.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(91.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(91.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(90.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(82.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(76.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(68.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(60.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(70), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(89.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(89.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(88.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(86.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(83.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(78.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(72.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(65.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(56.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(80), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(88.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(85.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(83.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(79.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(74.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(68.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(61.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(53.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(90), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(86.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(85.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(82.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(79.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(75.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(70.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(63.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(56.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(48.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(100), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(85.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(82.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(79.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(75.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(70.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(65.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(58.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(51.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(44.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(110), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(84.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(80.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(75.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(71.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(66.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(60.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(54.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(47.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(40.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(120), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(83.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(77.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(72.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(67.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(61.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(55.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(49.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(42.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(35.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(130), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(81.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(75.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(69.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(63.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(56.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(50.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(44.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(37.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(31.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(140), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(81.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(73.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(66.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(59.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(52.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(46.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(39.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(33.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(27.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(150), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(80.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(71.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(64.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(56.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(48.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(41.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(35.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(29.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(24.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(160), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(79.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(70.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(62.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(54.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(45.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(38.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(32.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(26.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(21.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(170), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(79.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(70.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(61.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(52.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(44.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(36.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(29.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(24.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(19.percent)),
        )
      )
    ),
    Row(
      RoofOrientation.normalized(180), InclinationMap(
        listOf(
          InclinationPerformance(RoofInclination(0), PerformanceFactor(87.percent)),
          InclinationPerformance(RoofInclination(10), PerformanceFactor(79.percent)),
          InclinationPerformance(RoofInclination(20), PerformanceFactor(70.percent)),
          InclinationPerformance(RoofInclination(30), PerformanceFactor(61.percent)),
          InclinationPerformance(RoofInclination(40), PerformanceFactor(52.percent)),
          InclinationPerformance(RoofInclination(50), PerformanceFactor(43.percent)),
          InclinationPerformance(RoofInclination(60), PerformanceFactor(35.percent)),
          InclinationPerformance(RoofInclination(70), PerformanceFactor(28.percent)),
          InclinationPerformance(RoofInclination(80), PerformanceFactor(23.percent)),
          InclinationPerformance(RoofInclination(90), PerformanceFactor(18.percent)),
        )
      )
    )
  )
)
