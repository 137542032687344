package components.project

import components.form.descriptionForm
import components.project.customer.EditCustomer
import components.project.editor.editorForm
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver

val EditProjectDetailsFromUrl: FC<Props> = fc("EditProjectDetailsFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) })

  busyIfNull(resolvedProject) { loadedProject ->
    EditProjectDetails {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val EditProjectDetails: FC<EditProjectDetailsProps> = fc("EditProjectDetails") { props ->
  val processStatesResolver = useLoadProcessStates()
  val userResolver = useSelectUserResolver()
  val companyResolver = useSelectCompanyResolver()

  val project = props::project.safeGet()

  val blueprint = project.blueprint
  val editableStatus = project.isProjectEditable()

  val name = useState(project.projectName)
  val description = useState(project.projectDescription)

  val acquisitionTimeState = useState(blueprint.acquisitionDate)

  val customerToSave = useState(project.customer)

  val maintainer = project::maintainer.safeGet()
  val forCompanyState = useState(project.sellingCompany)
  val loginNameState = useState(maintainer)

  val maintainerToSave = useMemo(loginNameState.value) {
    userResolver[loginNameState.value]
  }


  useMemo(name.value, description.value, customerToSave.value, forCompanyState.value, maintainerToSave) {
    val projectToSave = project.copy(
      projectName = name.value,
      projectDescription = description.value,
      customer = customerToSave.value,
      sellingCompanyInformation = companyResolver[forCompanyState.value],
      maintainerInformation = maintainerToSave,
    )
    UiActions.saveProject(projectToSave)
  }

  useMemo(acquisitionTimeState.value) {
    val blueprintToSave = blueprint.copy(
      acquisitionDate = acquisitionTimeState.value,
    )
    UiActions.saveBlueprint(blueprintToSave)
  }


  div("row my-5") {

    div("col-md-6") {
      h1 {
        +"Projekt-Beschreibung"
      }

      descriptionForm(
        label = name,
        description = description,
        labelPlaceholder = processStatesResolver?.let { project.getDisplayName(it) } ?: "Projektname",
        editableStatus = editableStatus,
      )

      div("row my-3") {
        div("col-6") {
          h2("mb-4") {
            +"Erfassungsdatum"
          }
          datePicker(acquisitionTimeState)
        }
      }
    }

    div("col-md-6") {
      h1 {
        +"Ansprechpartner:in"
      }

      editorForm(
        editorTitle = "Ansprechpartner:in",
        forCompany = forCompanyState,
        loginName = loginNameState,
        editableStatus = editableStatus,
      )
    }

  }

  div("my-5") {
    EditCustomer {
      attrs {
        this.customerToSave = customerToSave
      }
    }
  }

}


external interface EditProjectDetailsProps : Props {
  var project: ResolvedProject
}
