package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.geometry.Rectangle
import it.neckar.open.observable.ObservableObject
import it.neckar.open.unit.si.mm

/**
 * Contains the [ModuleArea]s
 */
class ModuleAreas(initialList: List<ModuleArea> = emptyList()) {
  /**
   * The [ModuleAreas] property
   */
  val moduleAreasProperty: ObservableObject<List<ModuleArea>> = ObservableObject(initialList)

  /**
   * Getter for the current [ModuleArea]
   */
  val moduleAreas: List<ModuleArea> by moduleAreasProperty

  /**
   * Returns the [ModuleArea] count
   */
  val count: Int
    get() {
      return moduleAreas.size
    }

  fun clear() {
    moduleAreasProperty.value = emptyList()
  }

  fun add(moduleArea: ModuleArea) {
    moduleAreasProperty.getAndSet {
      it.plus(moduleArea)
    }
  }

  fun addAll(newModuleAreas: Iterable<ModuleArea>) {
    moduleAreasProperty.getAndSet {
      it.plus(newModuleAreas)
    }
  }

  /**
   * Sets the unusableAreas
   */
  fun set(unusableAreas: Iterable<ModuleArea>) {
    moduleAreasProperty.value = unusableAreas.toMutableList()
  }

  operator fun get(index: Int): ModuleArea {
    return moduleAreas[index]
  }

  /**
   * Removes the given unusableAreas
   */
  fun removeAll(toRemove: Set<ModuleArea>) {
    removeAll {
      toRemove.contains(it)
    }
  }

  fun removeAll(predicate: (ModuleArea) -> Boolean) {
    moduleAreasProperty.getAndSet {
      it.toMutableList().apply {
        removeAll(predicate)
      }
    }
  }

  fun remove(unusableArea: ModuleArea) {
    moduleAreasProperty.getAndSet {
      it.minus(unusableArea)
    }
  }

  /**
   * Returns true if any unusable area overlaps with the given rectangle
   */
  fun overlaps(rectangle: @RoofRelative @mm Rectangle): Boolean {
    return moduleAreas.any {
      it.overlaps(rectangle)
    }
  }
}
