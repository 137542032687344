package components.company.user

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import kotlinx.html.InputType
import react.*
import react.dom.*

/**
 * Change password form
 */

fun RBuilder.passwordForm(
  fieldName: String,
  newPassword1: StateInstance<String>,
  newPassword2: StateInstance<String>,
): Unit = child(passwordForm) {
  attrs {
    this.fieldName = fieldName
    this.newPassword1 = newPassword1
    this.newPassword2 = newPassword2
  }
}

val passwordForm: FC<PasswordFormProps> = fc("passwordForm") { props ->

  val fieldName = props::fieldName.safeGet()
  val newPassword1 = props::newPassword1.getNotNull()
  val newPassword2 = props::newPassword2.getNotNull()

  div("my-3") {
    floatingInputField(
      valueAndSetter = newPassword1,
      fieldName = "password1",
      title = fieldName,
    ) {
      attrs {
        type = InputType.password
        mergedBelow()
      }
    }

    floatingInputField(
      valueAndSetter = newPassword2,
      fieldName = "password2",
      title = "Passwort-Wiederholung",
    ) {
      attrs {
        type = InputType.password
        mergedAbove()
      }
    }
  }

}

external interface PasswordFormProps : Props {
  var fieldName: String
  var newPassword1: StateInstance<String>
  var newPassword2: StateInstance<String>
}
