package components.company

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Creates a form for an address
 */
val legalInformationForm: FC<LegalInformationFormProps> = fc("legalInformationForm") { props ->

  val director = props::director.getNotNull()
  val registerCourt = props::registerCourt.getNotNull()
  val taxId = props::taxId.getNotNull()
  val registrationNumber = props::registrationNumber.getNotNull()

  div("row g-0") {
    div("col-6") {
      floatingInputField(
        valueAndSetter = director,
        fieldName = "director",
        title = "Geschäftsführer",
      ) {
        attrs {
          mergedBelow()
          mergedRight()
        }
      }
    }
    div("col-6") {
      floatingInputField(
        valueAndSetter = registerCourt,
        fieldName = "registerCourt",
        title = "Registergericht",
      ) {
        attrs {
          mergedBelow()
          mergedLeft()
        }
      }
    }

    div("col-6") {
      floatingInputField(
        valueAndSetter = taxId,
        fieldName = "taxId",
        title = "Ust-ID",
      ) {
        attrs {
          mergedAbove()
          mergedRight()
        }
      }
    }

    div("col-6") {
      floatingInputField(
        valueAndSetter = registrationNumber,
        fieldName = "registrationNumber",
        title = "Registernummer",
      ) {
        attrs {
          mergedAbove()
          mergedLeft()
        }
      }
    }

  }

}

external interface LegalInformationFormProps : Props {
  var director: StateInstance<String>
  var registerCourt: StateInstance<String>
  var taxId: StateInstance<String>
  var registrationNumber: StateInstance<String>
}
