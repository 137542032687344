package it.neckar.lizergy.model.configuration.moduleLayout

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.ModuleOrientation
import com.meistercharts.model.Insets
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule.PvModuleId
import it.neckar.lizergy.model.configuration.moduleLayout.planning.ModuleAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.UnusableAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof
import it.neckar.open.kotlin.lang.toIntCeil
import it.neckar.open.unit.si.mm
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable


interface ModuleLayout : HasUuid {
  /**
   * The ID of the module layout
   */
  val id: ModuleLayoutId

  /**
   * The description
   */
  val description: String?

  /**
   * The UUID of the type of used modules
   */
  val moduleTypeId: PvModuleId

  /**
   * The roof
   */
  val roof: Roof

  /**
   * The suggested roof insets - when placing the modules
   */
  val suggestedRoofInsets: @mm Insets

  /**
   * Contains information about the grid (some/most/all) of the modules are placed in
   */
  val moduleAreasInformation: ModuleAreasInformation

  /**
   * The unusable areas information
   */
  val unusableAreasInformation: UnusableAreasInformation


  override val uuid: Uuid
    get() = id.uuid

  /**
   * The number of modules for placed in this layout
   */
  val modulesCount: Int
    get() = moduleAreasInformation.modulesCount()

  /**
   * The number of modules that are placed horizontally
   */
  val horizontalModulesCount: Int
    get() = moduleAreasInformation.modulesCount {
      it.orientation == ModuleOrientation.Horizontal
    }

  val metalRoofTileCount: Int
    get() = (modulesCount * 3.5).toIntCeil()


  /**
   * ID for a [ModuleLayout]
   */
  @Serializable
  data class ModuleLayoutId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ModuleLayoutId {
        return ModuleLayoutId(randomUuid4())
      }
    }
  }

}
