package components.project.configuration.layout.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.ModuleOrientation
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningGestalt
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningLayer
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel
import com.meistercharts.charts.lizergy.roofPlanning.UnusableArea
import com.meistercharts.react.meistercharts
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.geometry.Coordinates
import it.neckar.geometry.RightTriangleType.MissingCornerInFirstQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInFourthQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInSecondQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInThirdQuadrant
import it.neckar.geometry.Size
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.planning.PvRoofPlanningModelInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.toRestApiModel
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faAdd
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import kotlinx.atomicfu.atomic
import kotlinx.css.*
import kotlinx.html.js.onClickFunction
import kotlinx.html.role
import react.*
import react.dom.*
import styled.*

/**
 * Creates a MeisterCharts module area planning element
 */
fun RBuilder.moduleAreaPlanning(
  model: PvRoofPlanningModelInformation,
  moduleType: PvModule,
  editableStatus: EditableStatus,
  changeListener: (updatedModel: PvRoofPlanningModelInformation) -> Unit,
): Unit = child(ModuleAreaPlanning) {
  attrs {
    this.model = model
    this.moduleType = moduleType
    this.editableStatus = editableStatus
    this.changeListener = changeListener
  }
}

val ModuleAreaPlanning: FC<ModuleAreaPlanningProps> = fc("ModuleAreaPlanning") { props ->
  val model = props::model.safeGet()
  val changeListener = props::changeListener.safeGet()
  val moduleType = props::moduleType.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  /**
   * The *mutable* model that is updated
   */
  val updatingMutableModelFromReact = useMemo {
    atomic(false)
  }

  val mutableModel = useMemo {
    PvRoofPlanningModel().also { mutableModel ->
      //Apply the loaded model initially!
      model.applyToMutableModel(mutableModel)

      //Publish the changes
      val publishChanges: (newValue: Any?) -> Unit = {
        if (!updatingMutableModelFromReact.value) {
          changeListener(mutableModel.toRestApiModel())
        }
      }

      mutableModel.unusableAreas.unusableAreasProperty.consume(action = publishChanges)
      mutableModel.moduleAreas.moduleAreasProperty.consume(action = publishChanges)

      /**
       * Register the location listeners for unusable areas
       */
      mutableModel.unusableAreas.unusableAreas.fastForEach { unusableArea: UnusableArea ->
        unusableArea.locationProperty.consume(action = publishChanges)
        unusableArea.sizeProperty.consume(action = publishChanges)
        unusableArea.rightTriangleTypeProperty.consume(action = publishChanges)
      }

      mutableModel.unusableAreas.unusableAreasProperty.consume { newUnusableAreas ->
        newUnusableAreas.fastForEach {
          it.locationProperty.consume(action = publishChanges)
          it.sizeProperty.consume(action = publishChanges)
          it.rightTriangleTypeProperty.consume(action = publishChanges)
        }
      }

      mutableModel.moduleAreas.moduleAreas.fastForEach {
        it.locationProperty.consume(action = publishChanges)
        it.sizeProperty.consume(action = publishChanges)
        it.moduleOrientationProperty.consume(action = publishChanges)

        it.modules.modulesProperty.consume(action = publishChanges)
        it.modules.modulesProperty.consume { modules ->
          modules.fastForEach { module ->
            module.deletedProperty.consume(action = publishChanges)
          }
        }
        it.modules.modules.fastForEach { module ->
          module.deletedProperty.consume(action = publishChanges)
        }
      }

      mutableModel.moduleAreas.moduleAreasProperty.consume { newModuleAreas ->
        newModuleAreas.fastForEach {
          it.locationProperty.consume(action = publishChanges)
          it.sizeProperty.consume(action = publishChanges)
          it.moduleOrientationProperty.consume(action = publishChanges)

          it.modules.modulesProperty.consume(action = publishChanges)
          it.modules.modulesProperty.consume { modules ->
            modules.fastForEach { module ->
              module.deletedProperty.consume(action = publishChanges)
            }
          }
          it.modules.modules.fastForEach { module ->
            module.deletedProperty.consume(action = publishChanges)
          }
        }
      }
    }
  }

  //Apply the changes
  updatingMutableModelFromReact.value = true
  try {
    mutableModel.roofSize = model.roofSize
    mutableModel.modulesSize = model.moduleSize
    mutableModel.suggestedRoofInsets = model.suggestedRoofInsets

    //do *NOT* updated the modules and unusable areas
  } finally {
    updatingMutableModelFromReact.value = false
  }

  val roofPlanningGestalt: PvRoofPlanningGestalt = useMemo {
    PvRoofPlanningGestalt(mutableModel).also {
      it.pvRoofPlanningLayer.configuration.mode = when (editableStatus) {
        Editable -> PvRoofPlanningLayer.Mode.Planning
        ReadOnly -> PvRoofPlanningLayer.Mode.Rendering
      }
    }
  }


  useMemo(moduleType) {
    mutableModel.updateModuleAreas(moduleType.size)
  }


  meistercharts(
    description = "Roof Planner",
    gestalt = roofPlanningGestalt,
    // Always mark as dirty on rerender. MeisterCharts is fast!
    callMarkAsDirty = true
  )

  div("row") {
    div("col col-md-3") {
      h4("mb-2") {
        +"Modulflächen"
      }

      div("btn-group") {
        attrs {
          role = "group"
        }

        button(classes = "btn btn-primary") {
          span("pe-2") {
            faAdd()
          }

          styledImg(src = "/solar/panel-vertical.png") {
            css {
              height = 44.px
              width = 26.px
            }
          }

          attrs {
            disabled = editableStatus == ReadOnly
            onClickFunction = {
              mutableModel.addModuleArea(orientation = ModuleOrientation.Vertical)
            }
          }
        }
        button(classes = "btn btn-primary") {
          span("pe-2") {
            faAdd()
          }

          styledImg(src = "/solar/panel-horizontal.png") {
            css {
              height = 26.px
              width = 44.px
            }
          }

          attrs {
            disabled = editableStatus == ReadOnly
            onClickFunction = {
              mutableModel.addModuleArea(orientation = ModuleOrientation.Horizontal)
            }
          }
        }
      }
    }

    div("col") {
      h4("mb-2") {
        +"Nicht belegbare Flächen"
      }

      div("btn-group") {
        attrs {
          role = "group"
        }

        button(classes = "btn btn-secondary") {
          span("pe-2") {
            faAdd()
          }

          styledImg(src = "/solar/square.png") {
            css {
              height = 44.px
              width = 44.px
            }
          }

          attrs {
            disabled = editableStatus == ReadOnly
            onClickFunction = {
              UnusableArea(Coordinates.origin, Size(500.0, 1300.0)).let {
                mutableModel.addUnusableArea(it)
              }
            }
          }
        }
        button(classes = "btn btn-secondary") {
          span("pe-2") {
            faAdd()
          }

          styledImg(src = "/solar/triangle1.png") {
            css {
              height = 44.px
              width = 44.px
            }
          }

          attrs {
            disabled = editableStatus == ReadOnly
            onClickFunction = {
              UnusableArea(Coordinates.origin, Size(500.0, 1300.0), MissingCornerInFirstQuadrant).let {
                mutableModel.addUnusableArea(it)
              }
            }
          }
        }
        button(classes = "btn btn-secondary") {
          span("pe-2") {
            faAdd()
          }

          styledImg(src = "/solar/triangle2.png") {
            css {
              height = 44.px
              width = 44.px
            }
          }

          attrs {
            disabled = editableStatus == ReadOnly
            onClickFunction = {
              UnusableArea(Coordinates.origin, Size(500.0, 1300.0), MissingCornerInSecondQuadrant).let {
                mutableModel.addUnusableArea(it)
              }
            }
          }
        }
        button(classes = "btn btn-secondary") {
          span("pe-2") {
            faAdd()
          }

          styledImg(src = "/solar/triangle3.png") {
            css {
              height = 44.px
              width = 44.px
            }
          }

          attrs {
            disabled = editableStatus == ReadOnly
            onClickFunction = {
              UnusableArea(Coordinates.origin, Size(500.0, 1300.0), MissingCornerInThirdQuadrant).let {
                mutableModel.addUnusableArea(it)
              }
            }
          }
        }
        button(classes = "btn btn-secondary") {
          span("pe-2") {
            faAdd()
          }

          styledImg(src = "/solar/triangle4.png") {
            css {
              height = 44.px
              width = 44.px
            }
          }

          attrs {
            disabled = editableStatus == ReadOnly
            onClickFunction = {
              UnusableArea(Coordinates.origin, Size(500.0, 1300.0), MissingCornerInFourthQuadrant).let {
                mutableModel.addUnusableArea(it)
              }
            }
          }
        }
      }
    }

    div("col") {
      h4("mb-2") {
        +"Operationen"
      }

      div("btn-group") {
        attrs {
          role = "group"
        }

        actionButtonWithConfirmationModal(
          icon = FontAwesomeIcons.ban,
          buttonClasses = "btn btn-danger",
          buttonContent = {
            attrs {
              disabled = editableStatus == ReadOnly
            }
            span("ps-2") {
              +"Alle Modulflächen löschen"
            }
          },
          modalTitle = "Sollen alle Modulflächen gelöscht werden?",
        ) {
          mutableModel.deleteAllAreas()
        }

        actionButtonWithConfirmationModal(
          icon = FontAwesomeIcons.ban,
          buttonClasses = "btn btn-warning",
          buttonContent = {
            attrs {
              disabled = editableStatus == ReadOnly
            }
            span("ps-2") {
              +"Alle nicht belegbare Flächen löschen"
            }
          },
          modalTitle = "Sollen alle nicht belegbare Flächen gelöscht werden?",
        ) {
          mutableModel.deleteAllUnusableAreas()
        }

      }
    }
  }
}


external interface ModuleAreaPlanningProps : Props {
  var model: PvRoofPlanningModelInformation
  var changeListener: (updatedModel: PvRoofPlanningModelInformation) -> Unit
  var moduleType: PvModule
  var editableStatus: EditableStatus
}
