package components.project.configuration.tabs

import components.project.QuoteConfigurationTabProps
import components.project.configuration.isConfigurationEditable
import components.project.configuration.profitability.IncomePercentageProfitsTable
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.income.IncomePercentages
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import kotlinx.html.title
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

val ProfitOverviewFromUrl: FC<Props> = fc("ProfitOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Deckungsbeitrag", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    ProfitOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val ProfitOverview: FC<QuoteConfigurationTabProps> = fc("ProfitOverview") { props ->
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val quoteElements = if (editableStatus == EditableStatus.Editable) quoteConfiguration.calculatedQuoteElements else quoteConfiguration.quoteElements


  div("row my-3") {
    attrs {
      if (editableStatus == EditableStatus.ReadOnly) {
        title = "Dieses Angebot kann nicht weiter bearbeitet werden!"
      }
    }

    div("col-12") {
      IncomePercentageProfitsTable {
        attrs {
          this.incomePercentages = IncomePercentages.all
          this.quotePositions = quoteElements
        }
      }
    }
  }
}
