package it.neckar.customer.company

import kotlinx.serialization.Serializable

/**
 * Legal information for a company
 */
@Serializable
data class CompanyLegalInformation(
  /**
   * "Geschäftsführer"
   */
  val director: String,
  /**
   * "Registergericht"
   */
  val registerCourt: String,

  /**
   * "Ust-ID"
   */
  val taxId: String,
  /**
   * "Registernummer"
   */
  val registrationNumber: String,
) {

  /**
   * Returns true if all properties are set
   */
  val isPlausible: Boolean
    get() {
      return director.isNotBlank()
        && registerCourt.isNotBlank()
        && taxId.isNotBlank()
        && registrationNumber.isNotBlank()
    }

  companion object {
    val empty: CompanyLegalInformation = CompanyLegalInformation("", "", "", "")
    val demo: CompanyLegalInformation = CompanyLegalInformation("Martha Testfrau", "Amtsgericht Stuttgart", "DE 192736450", "HRB 112233")
    val Schnitzer: CompanyLegalInformation = CompanyLegalInformation("Wolfgang Schnitzer", "Amtsgericht Stuttgart", "DE 185272453", "HRB 381763")
    val Lizergy: CompanyLegalInformation = CompanyLegalInformation("Simon Hänel", "Amtsgericht Stuttgart", "DE290865644", "HRB: 745905")
    val NeckarIT: CompanyLegalInformation = CompanyLegalInformation("Johannes Schneider, Christian Erbelding", "Amtsgericht Stuttgart", "DE326321633", "HRB: 770878")
  }
}
