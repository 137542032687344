package components.project.blueprint

import com.meistercharts.charts.lizergy.solar.SouthRelative
import com.meistercharts.model.Insets
import components.form.commentSection
import components.project.configuration.layout.EditModuleLayout
import components.project.configuration.layout.moduleLayout.roof.roofForm
import components.project.isProjectEditable
import components.project.validMetalRoofTileColors
import components.project.validModuleTypes
import components.project.validRoofTileTypes
import components.project.validRoofTypes
import components.project.validationTable
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.geometry.Size
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditModuleLayouts
import it.neckar.lizergy.model.configuration.energy.InterpolatedPerformanceCalculator
import it.neckar.lizergy.model.configuration.moduleLayout.PvModuleTypeMaterial
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.planning.PvRoofPlanningModelInformation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileCompatability.NotSupported
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileCompatability.Required
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileCompatability.Supported
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedNormalMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofInclination
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofOrientation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.formatting.format
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.open.unit.other.deg
import it.neckar.open.unit.si.mm
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import kotlinx.html.DIV
import kotlinx.html.id
import react.*
import react.dom.*
import router.useLoadResolvedProjectFromUrl
import router.useModuleLayoutIdFromUrl
import services.UiActions
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectAvailableProducts
import kotlin.math.roundToInt

/**
 * Edits a moduleLayout
 */
val EditBlueprintRoofFromUrl: FC<Props> = fc("EditBlueprintRoofFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val moduleLayoutId = useModuleLayoutIdFromUrl()
  val resolvedModuleLayout = project?.blueprint?.get(moduleLayoutId)

  busyIfNull(project, resolvedModuleLayout) { loadedProject, loadedResolvedModuleLayout ->
    EditBlueprintRoof {
      attrs {
        this.project = loadedProject
        this.resolvedModuleLayout = loadedResolvedModuleLayout
      }
    }
  }
}

val EditBlueprintRoof: FC<EditBlueprintRoofProps> = fc("EditBlueprintRoof") { props ->
  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()
  val availableProducts = useSelectAvailableProducts()

  val project = props::project.safeGet()
  val initialModuleLayout = props::resolvedModuleLayout.safeGet()

  val blueprint = project.blueprint
  val initialRoof = initialModuleLayout.roof
  val editableStatus = project.isProjectEditable()

  @mm val roofWidth = useState(initialRoof.size?.width?.roundToInt())
  @mm val roofHeight = useState(initialRoof.size?.height?.roundToInt())

  val roofInclination = useState(initialRoof.inclination?.angle)
  val roofOrientation = useState(initialRoof.orientation?.orientation)

  val description = useState(initialModuleLayout.description.orEmpty())
  val moduleType = useState(initialModuleLayout.moduleType)
  val blackModules = useState(moduleType.value.material == PvModuleTypeMaterial.GlassGlass)

  val suggestedRoofInsetsLeft = useState(initialModuleLayout.suggestedRoofInsets.left.roundToInt())
  val suggestedRoofInsetsRight = useState(initialModuleLayout.suggestedRoofInsets.right.roundToInt())
  val suggestedRoofInsetsTop = useState(initialModuleLayout.suggestedRoofInsets.top.roundToInt())
  val suggestedRoofInsetsBottom = useState(initialModuleLayout.suggestedRoofInsets.bottom.roundToInt())

  val moduleAreasInformation = useState(initialModuleLayout.moduleAreasInformation)
  val unusableAreasInformation = useState(initialModuleLayout.unusableAreasInformation)

  val resolvedRoofToSave = useState(initialRoof)

  useMemo(blackModules.value) {
    availableProducts.availableModules().first {
      when (blackModules.value) {
        true -> it.material == PvModuleTypeMaterial.GlassGlass
        false -> it.material == PvModuleTypeMaterial.Normal
      }
    }.let { newModuleType ->
      if (newModuleType.material != moduleType.value.material) moduleType.setter(newModuleType)
    }
  }

  useMemo(moduleType.value) {
    when (moduleType.value.material) {
      PvModuleTypeMaterial.GlassGlass -> true
      PvModuleTypeMaterial.Normal -> false
    }.let { newBlackModules ->
      if (newBlackModules != blackModules.value) blackModules.setter(newBlackModules)
    }
  }

  val suggestedRoofInsets = useMemo(
    suggestedRoofInsetsLeft.value,
    suggestedRoofInsetsRight.value,
    suggestedRoofInsetsTop.value,
    suggestedRoofInsetsBottom.value,
  ) {
    Insets.of(
      top = suggestedRoofInsetsTop.value,
      right = suggestedRoofInsetsRight.value,
      bottom = suggestedRoofInsetsBottom.value,
      left = suggestedRoofInsetsLeft.value,
    )
  }

  val currentPlanningModel = useMemo(
    moduleType.value.size,
    resolvedRoofToSave.value.size,
    suggestedRoofInsets,
    moduleAreasInformation.value,
    unusableAreasInformation.value,
  ) {
    if (resolvedRoofToSave.value.size != null) {
      PvRoofPlanningModelInformation(
        moduleSize = moduleType.value.size,
        roofSize = resolvedRoofToSave.value.size!!,
        suggestedRoofInsets = suggestedRoofInsets,
        moduleAreasInformation = moduleAreasInformation.value,
        unusableAreasInformation = unusableAreasInformation.value,
      )
    } else null
  }

  val resolvedModuleLayoutToSave = useMemo(
    description.value,
    moduleType.value,
    resolvedRoofToSave.value,
    suggestedRoofInsets,
    moduleAreasInformation.value,
    unusableAreasInformation.value,
  ) {
    initialModuleLayout.copy(
      description = description.value.nullIfBlank(),
      moduleType = moduleType.value,
      roof = resolvedRoofToSave.value,
      suggestedRoofInsets = suggestedRoofInsets,
      moduleAreasInformation = moduleAreasInformation.value,
      unusableAreasInformation = unusableAreasInformation.value,
    )
  }

  val okAction = {
    UiActions.saveModuleLayout(blueprint, resolvedModuleLayoutToSave)
    navigate.back()
  }


  div {
    //div("mb-5") {
    //  h3("mb-2") {
    //    +"Bemerkungen Modulbelegung"
    //  }
    //  commentSection(initialModuleLayout)
    //}

    if (loggedInUser.accessRights.canAccess(EditModuleLayouts)) {
      h2 { +"Modul-Belegung" }
    } else {
      h2 { +"Dachfläche" }
    }

    roofSizeForm(
      roofWidth = roofWidth,
      roofHeight = roofHeight,
      roofInclination = roofInclination,
      roofOrientation = roofOrientation,
      editableStatus = editableStatus
    )

    if (loggedInUser.accessRights.canAccess(EditModuleLayouts)) {
      EditModuleLayout {
        attrs {
          this.descriptionToSave = description
          this.moduleTypeToSave = moduleType
          this.blackModules = blackModules
          this.suggestedRoofInsetsLeft = suggestedRoofInsetsLeft
          this.suggestedRoofInsetsRight = suggestedRoofInsetsRight
          this.suggestedRoofInsetsTop = suggestedRoofInsetsTop
          this.suggestedRoofInsetsBottom = suggestedRoofInsetsBottom
          this.moduleAreasInformationToSave = moduleAreasInformation
          this.unusableAreasInformationToSave = unusableAreasInformation
          this.descriptionPlaceholder = "${resolvedModuleLayoutToSave.roof.roofName} ${resolvedModuleLayoutToSave.peakPower.formatKiloWattPeak()}"
          this.initialModuleLayout = initialModuleLayout
          this.currentPlanningModel = currentPlanningModel
          this.availableModuleTypes = availableProducts.validModuleTypes(initialModuleLayout.moduleType)
          this.resolvedRoof = resolvedRoofToSave.value
          this.editableStatus = editableStatus
        }
      }
    }

    EditRoof {
      attrs {
        this.resolvedRoofToSave = resolvedRoofToSave
        this.roofWidth = roofWidth
        this.roofHeight = roofHeight
        this.roofInclination = roofInclination
        this.roofOrientation = roofOrientation
        this.availableRoofTypes = availableProducts.validRoofTypes(initialRoof.roofType)
        this.availableRoofTiles = initialRoof.roofTile?.let { availableProducts.validRoofTileTypes(it) } ?: availableProducts.availableRoofTiles()
        this.availableMetalRoofTileColors = initialRoof.metalRoofTileConstructionType?.color?.let { availableProducts.validMetalRoofTileColors(it) } ?: availableProducts.availableMetalRoofTileColors()
        this.totalModulesCount = resolvedModuleLayoutToSave.modulesCount
        this.blackModules = blackModules.value
        this.editableStatus = editableStatus
      }
    }

    div("mb-5") {
      h3("mb-2") {
        +"Bemerkungen Dachfläche"
      }
      project.blueprint.roofAnnotations.firstOrNull { it.id == initialRoof.id }?.let {
        it.annotation?.let { p { +it } }
      }
      commentSection(initialRoof)
    }


    formButtons(
      cancelText = "Zurück",
      cancelAction = { navigate.back() },
      okAction = { okAction() },
    )

    div("my-5") {
      validationTable(resolvedModuleLayoutToSave.validationProblems)
    }
  }

}


val EditRoof: FC<EditRoofProps> = fc("EditRoof") { props ->
  val resolvedRoofToSave = props::resolvedRoofToSave.getNotNull()

  val availableRoofTypes = props::availableRoofTypes.safeGet()
  val availableRoofTileTypes = props::availableRoofTiles.safeGet()
  val availableMetalRoofTileColors = props::availableMetalRoofTileColors.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val initialRoof = resolvedRoofToSave.value

  @mm val roofWidth = props::roofWidth.getNotNull()
  @mm val roofHeight = props::roofHeight.getNotNull()

  val roofInclination = props::roofInclination.getNotNull()
  val roofOrientation = props::roofOrientation.getNotNull()

  val totalModulesCount = props::totalModulesCount.safeGet()
  val blackModules = props::blackModules.safeGet()

  val roofType = useState(initialRoof.roofType)
  val roofTileType = useState(initialRoof.roofTile)
  val roofTileTypeOverride = useState(initialRoof.roofTileOverride.orEmpty())
  val metalRoofTileConstructionType = useState(
    when (initialRoof.roofType.metalRoofTileCompatability) {
      NotSupported, Supported -> initialRoof.metalRoofTileConstructionType
      Required -> initialRoof.metalRoofTileConstructionType ?: ResolvedNormalMetalRoofTile
    }
  )
  val metalRoofTileColor = useState(initialRoof.metalRoofTileConstructionType?.color ?: availableMetalRoofTileColors.first())
  val metalRoofTileAmount = useState(initialRoof.metalRoofTileConstructionType?.amount ?: 0)

  val roofIsolation = useState(initialRoof.isolationThickness)
  val sparrenabstand = useState(initialRoof.sparrenabstand)
  val verstaerktesSchienensystem = useState(initialRoof.verstaerktesSchienensystemToggle)
  val scaffoldingArea = useState(initialRoof.scaffoldingArea)
  val schwarzeKlemmen = useState(initialRoof.schwarzeKlemmen)


  useMemo(blackModules) {
    schwarzeKlemmen.setter(blackModules || schwarzeKlemmen.value)
  }

  val roofWidthValue = roofWidth.value
  val roofHeightValue = roofHeight.value
  useMemo(
    roofWidthValue,
    roofHeightValue,
    roofInclination.value,
    roofOrientation.value,
    roofType.value,
    roofTileType.value,
    roofTileTypeOverride.value,
    metalRoofTileConstructionType.value,
    roofIsolation.value,
    sparrenabstand.value,
    verstaerktesSchienensystem.value,
    scaffoldingArea.value,
    schwarzeKlemmen.value,
  ) {
    resolvedRoofToSave.setter(
      initialRoof.copy(
        size = if (roofWidthValue != null && roofHeightValue != null) Size(roofWidthValue, roofHeightValue) else null,
        inclination = roofInclination.value?.let { RoofInclination(it) },
        orientation = roofOrientation.value?.let { RoofOrientation.normalized(it) },
        roofType = roofType.value,
        roofTile = roofTileType.value,
        roofTileOverride = roofTileTypeOverride.value.nullIfBlank(),
        metalRoofTileConstructionType = metalRoofTileConstructionType.value,
        isolationThickness = if (roofType.value.withIsolation) roofIsolation.value else null,
        sparrenabstand = sparrenabstand.value,
        verstaerktesSchienensystemToggle = verstaerktesSchienensystem.value,
        scaffoldingArea = scaffoldingArea.value,
        schwarzeKlemmen = schwarzeKlemmen.value,
      )
    )
  }


  div("my-5") {

    h3("my-3") {
      +"Dachfläche"
    }

    roofForm(
      roofType = roofType,
      availableRoofTypes = availableRoofTypes,
      roofTile = roofTileType,
      roofTileTypeOverride = roofTileTypeOverride,
      metalRoofTileConstructionType = metalRoofTileConstructionType,
      metalRoofTileColor = metalRoofTileColor,
      metalRoofTileAmount = metalRoofTileAmount,
      availableTileTypes = availableRoofTileTypes,
      availableMetalRoofTileColors = availableMetalRoofTileColors,
      roofIsolation = roofIsolation,
      sparrenabstand = sparrenabstand,
      verstaerktesSchienensystem = verstaerktesSchienensystem,
      scaffoldingArea = scaffoldingArea,
      schwarzeKlemmen = schwarzeKlemmen,
      totalModulesCount = totalModulesCount,
      editableStatus = editableStatus,
    )

  }

}


fun RDOMBuilder<DIV>.roofSizeForm(
  roofWidth: StateInstance<Int?>,
  roofHeight: StateInstance<Int?>,
  roofInclination: StateInstance<@deg Int?>,
  roofOrientation: StateInstance<@SouthRelative Int?>,
  editableStatus: EditableStatus,
) {
  val widthInMeter = roofWidth.value?.div(1000.0)
  val heightInMeter = roofHeight.value?.div(1000.0)
  val areaInMeter = heightInMeter?.let { widthInMeter?.times(it) }

  val performanceFactor = if (roofOrientation.value != null && roofInclination.value != null) {
    InterpolatedPerformanceCalculator.calculatePerformanceFactor(
      RoofOrientation.normalized(roofOrientation.value!!),
      RoofInclination(roofInclination.value!!)
    )
  } else null

  div("row gx-0 my-3") {

    div("col-md-6") {
      nullableFloatingIntInputField(
        valueAndSetter = roofWidth,
        fieldName = "width",
        title = "Breite (mm)",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
          mergedRight()
          step = "100"
        }
      }
    }
    div("col-md-6") {
      nullableFloatingIntInputField(
        valueAndSetter = roofHeight,
        fieldName = "height",
        title = "Höhe (mm)",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
          mergedLeft()
          ariaDescribedBy = "roofSizeHelpText"
          step = "100"
        }
      }
    }

    div("col-md-6") {
      nullableFloatingIntInputField(
        valueAndSetter = roofInclination,
        fieldName = "inclination",
        title = "Neigung (°)",
        numberConstraint = CoerceIn(0, 90),
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
          mergedRight()
          step = "1"
        }
      }
    }
    div("col-md-6") {
      nullableFloatingIntInputField(
        valueAndSetter = roofOrientation,
        fieldName = "orientation",
        title = "Ausrichtung (°) - 0° entspricht Süd",
        numberConstraint = CoerceIn(-180, 180),
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
          mergedLeft()
          step = "1"
        }
      }
    }

    div("col-md-6 form-text") {
      +"Dach-Größe: ${if (widthInMeter != null && heightInMeter != null && areaInMeter != null) "${(widthInMeter).format()} m × ${(heightInMeter).format()} m = ${(areaInMeter).format()} m²" else "Unvollständige Maße"}"

      attrs {
        id = "roofSizeHelpText"
      }
    }

    div("col-md-6 d-flex justify-content-between form-text") {
      span {
        +"Ausrichtung: ${roofOrientation.value?.let { RoofOrientation.normalized(it).toCardinalDirection().labelGerman } ?: "-"}"
      }
      span("text-end") {
        +"Effektivität: ${performanceFactor?.format() ?: "-"}"
      }
    }

  }
}


external interface EditBlueprintRoofProps : Props {
  var project: ResolvedProject
  var resolvedModuleLayout: ResolvedModuleLayout
}

external interface EditRoofProps : Props {
  var roofWidth: StateInstance<@mm Int?>
  var roofHeight: StateInstance<@mm Int?>
  var roofInclination: StateInstance<Int?>
  var roofOrientation: StateInstance<Int?>
  var resolvedRoofToSave: StateInstance<ResolvedRoof>
  var availableRoofTypes: List<RoofType>
  var availableRoofTiles: List<RoofTile>
  var availableMetalRoofTileColors: List<MetalRoofTileColor>
  var totalModulesCount: Int
  var blackModules: Boolean
  var editableStatus: EditableStatus
}
