@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout.roof

import com.benasher44.uuid.Uuid
import it.neckar.geometry.Size
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lizergy.model.configuration.energy.InterpolatedPerformanceCalculator
import it.neckar.lizergy.model.configuration.energy.PerformanceFactor
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile.RoofTileId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType.RoofTypeId
import it.neckar.open.formatting.format
import it.neckar.open.unit.other.deg
import it.neckar.open.unit.si.cm
import it.neckar.open.unit.si.m
import it.neckar.open.unit.si.m2
import it.neckar.open.unit.si.mm
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Information about a roof.
 *
 * Does *not* contain the modules for the roof.
 */
interface Roof : HasUuid, HasLifeCycle {
  /**
   * ID that identifies a roof information (*not* the instance of the data object)
   */
  val id: RoofId

  /**
   * The size of the roof
   */
  val size: @mm Size?

  /**
   * The type of the roof
   */
  val roofTypeId: RoofTypeId

  /**
   * The inclination of the roof in degrees
   */
  val inclination: @deg RoofInclination?

  /**
   * The orientation of the roof in degrees relative to south
   */
  val orientation: RoofOrientation?

  /**
   * The type of the roof tiles
   */
  val roofTileId: RoofTileId?
  val roofTileOverride: String?

  val isolationThickness: @cm Int?

  val sparrenabstand: @cm Int?
  val verstaerktesSchienensystemToggle: Boolean

  val scaffoldingArea: @m2 Int?
  val schwarzeKlemmen: Boolean


  override val uuid: Uuid
    get() = id.uuid

  /**
   * Returns the name for the roof.
   * If a label is set, the label will be returned.
   * If the label is blank, the cardinal direction will be used to generate a default name
   */
  val roofName: String
    get() = cardinalDirection?.let { "${it.labelGerman}-Dach" } ?: "Unvollständiges Dach"

  /**
   * Returns the cardinal direction of this roof
   */
  val cardinalDirection: CardinalDirection?
    get() = orientation?.toCardinalDirection()

  /**
   * The performance factor for this roof
   */
  val performanceFactor: PerformanceFactor?
    get() = if (orientation != null && inclination != null) {
      InterpolatedPerformanceCalculator.calculatePerformanceFactor(orientation!!, inclination!!)
    } else null

  val verstaerktesSchienensystem: Boolean
    get() = sparrenabstand?.let { it > 80 && verstaerktesSchienensystemToggle } ?: false

  val widthInMeter: @m Double?
    get() = size?.width?.div(1000.0)

  val heightInMeter: @m Double?
    get() = size?.height?.div(1000.0)

  /**
   * Returns the area in m²
   */
  val areaInMeter: @m2 Double?
    get() = size?.area()?.div(1000.0)?.div(1000.0)

  /**
   * Formats the given area
   */
  fun areaFormatted(): String? {
    return areaInMeter?.let { "${it.format()} m²" }
  }

  /**
   * Returns the dimensions (in m) as formatted string
   */
  fun dimensionsFormatted(): String {
    return if (widthInMeter != null && heightInMeter != null) {
      "${widthInMeter!!.format()} m × ${heightInMeter!!.format()} m"
    } else "Keine Maße abgelegt"
  }


  /**
   * An id for a [Roof]
   */
  @Serializable
  data class RoofId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): RoofId {
        return RoofId(randomUuid4())
      }
    }
  }

}
