package it.neckar.processStates

import com.benasher44.uuid.Uuid
import it.neckar.uuid.HasUuid

data class AllProcessStatesForComponents(val allProcessStatesForComponents: List<ProcessStatesForComponent>) : ProcessStatesResolver {

  private val map: Map<Uuid, ProcessStatesForComponent> = allProcessStatesForComponents.associateBy { it.processStatesFor }

  private val reverseMap: Map<ProcessStates?, List<ProcessStatesForComponent>> = allProcessStatesForComponents.groupBy { processStatesForComponent ->
    processStatesForComponent.validProcessStateEntries.current()?.processState
  }


  override operator fun get(uuid: Uuid): ProcessStatesForComponent {
    return map[uuid] ?: ProcessStatesForComponent(uuid, emptyList())
  }

  override fun getOrNull(uuid: Uuid): ProcessStatesForComponent? {
    return map[uuid]
  }

  override operator fun get(hasUuid: HasUuid): ProcessStatesForComponent {
    return map[hasUuid.uuid] ?: ProcessStatesForComponent(hasUuid.uuid, emptyList())
  }

  override fun getOrNull(hasUuid: HasUuid): ProcessStatesForComponent? {
    return map[hasUuid.uuid]
  }

  override operator fun get(processState: ProcessStates): List<ProcessStatesForComponent> {
    return reverseMap[processState] ?: emptyList()
  }

  override fun getOrNull(processState: ProcessStates): List<ProcessStatesForComponent>? {
    return reverseMap[processState]
  }

  override operator fun get(processStates: List<ProcessStates>): List<ProcessStatesForComponent> {
    return processStates.flatMap { get(it) }
  }

  fun withUpdatedProcessState(processStateFor: Uuid, newProcessState: ProcessStateEntry): AllProcessStatesForComponents {
    return withUpdatedProcessStatesForComponent(get(processStateFor).withUpdatedProcessState(newProcessState))
  }

  fun withNewProcessStatesForComponent(newProcessStatesForComponent: ProcessStatesForComponent): AllProcessStatesForComponents {
    return copy(allProcessStatesForComponents = allProcessStatesForComponents + newProcessStatesForComponent)
  }

  fun withUpdatedProcessStatesForComponent(updatedProcessStatesForComponent: ProcessStatesForComponent): AllProcessStatesForComponents {
    val updatedUserLoginName = allProcessStatesForComponents.indexOfFirst {
      it.processStatesFor == updatedProcessStatesForComponent.processStatesFor
    }

    if (updatedUserLoginName < 0) return withNewProcessStatesForComponent(updatedProcessStatesForComponent)

    val newProcessStatesForComponent = this.allProcessStatesForComponents.toMutableList()
    newProcessStatesForComponent[updatedUserLoginName] = updatedProcessStatesForComponent

    return copy(allProcessStatesForComponents = newProcessStatesForComponent)
  }

  companion object {
    val empty: AllProcessStatesForComponents
      get() = AllProcessStatesForComponents(emptyList())
  }
}
