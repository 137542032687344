@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.ModuleOrientation
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel
import com.meistercharts.model.Insets
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Size
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.energy.Current
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.Voltage
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule.PvModuleId
import it.neckar.lizergy.model.configuration.moduleLayout.planning.ModuleAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.UnusableAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.toRestApiModel
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.validation.NeedsValidation
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.lizergy.model.validation.TitleMessage
import it.neckar.lizergy.model.validation.ValidationProblems
import it.neckar.open.kotlin.lang.ifBlank
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.open.unit.si.mm
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 *  Represents a module layout
 */
@Serializable
data class ResolvedModuleLayout(

  override val id: ModuleLayoutId,

  /**
   * The description
   */
  override val description: String?,

  /**
   * The UUID of the type of used modules
   */
  val moduleType: PvModule,

  @Deprecated("No longer used or needed")
  val blackModules: Boolean,

  override val roof: ResolvedRoof,

  /**
   * The suggested roof insets - when placing the modules
   */
  override val suggestedRoofInsets: @mm Insets,
  /**
   * Contains information about the grid (some/most/all) of the modules are placed in
   */
  override val moduleAreasInformation: ModuleAreasInformation,
  /**
   * The unusable areas information
   */
  override val unusableAreasInformation: UnusableAreasInformation,

  /**
   * Marks if this [ResolvedModuleLayout] has been deleted
   * Instead of deleting, mark this value as Deleted
   * When listing [ResolvedModuleLayout]s, consider this value
   */
  override val lifeCycleState: LifeCycleState,

  ) : ModuleLayout, HasLifeCycle, NeedsValidation {

  override val moduleTypeId: PvModuleId
    get() = moduleType.id

  /**
   * Automatically generate a name for a moduleLayout of none is set
   */
  val layoutName: String
    get() = description.ifBlank {
      "${roof.roofName} ${peakPower.formatKiloWattPeak()}"
    }

  /**
   * The total power rating for this layout
   * (depends on the number of modules and the power rating of the module)
   */
  val peakPower: PowerRating
    get() = moduleType.powerRating * modulesCount

  val actualPowerRating: PowerRating?
    get() = roof.performanceFactor?.percentage?.let { peakPower * it }

  val offLoadVoltage: Voltage
    get() = moduleType.offLoadVoltage * modulesCount

  val shortCircuitCurrent: Current
    get() = moduleType.shortCircuitCurrent * modulesCount


  override val validationProblems: ValidationProblems
    get() = ValidationProblems(
      titleMessages = listOf(
        TitleMessage(null) { "Modulfläche $layoutName sieht gut aus!" },
        TitleMessage(ProblemType.Error) { "Die folgenden $it Felder müssen noch in in der Modulfläche $layoutName ausgefüllt werden:" },
        TitleMessage(ProblemType.Warning) { "Die folgenden $it Felder sollten in der Modulfläche $layoutName überprüft werden:" },
      ),
      ownProblems = emptyList(),
      childProblems = listOf(roof.validationProblems),
    )


  fun duplicate(markAsCopy: Boolean, mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedModuleLayout {
    val newId = ModuleLayoutId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(
      id = newId,
      description = description.nullIfBlank()?.let { if (markAsCopy) "$it (Kopie)" else it },
      roof = roof.duplicate(markAsCopy, mapOfOldToNewUuids),
    )
  }


  companion object {
    /**
     * Creates the default module layout for the given roof
     */
    fun createDefault(
      id: ModuleLayoutId = ModuleLayoutId.random(),
      moduleType: PvModule,
      roof: ResolvedRoof,
    ): ResolvedModuleLayout {

      val mutableModel = PvRoofPlanningModel()

      mutableModel.addModuleArea(
        modulesSize = moduleType.size,
        location = Coordinates(200.0, 200.0),
        size = roof.size?.minus(400.0, 400.0) ?: Size.none,
        orientation = ModuleOrientation.Vertical,
        gap = 20.0,
      )

      return ResolvedModuleLayout(
        id = id,
        description = null,
        moduleType = moduleType,
        blackModules = false,
        roof = roof,
        suggestedRoofInsets = Insets(100.0, 100.0, 100.0, 100.0),
        moduleAreasInformation = mutableModel.moduleAreas.toRestApiModel(),
        unusableAreasInformation = UnusableAreasInformation(emptyList()),
        lifeCycleState = LifeCycleState.Active,
      )
    }

    /**
     * moduleAreaPlacements with an empty list
     */
    fun empty(
      id: ModuleLayoutId = ModuleLayoutId.random(),
      moduleType: PvModule,
      roof: ResolvedRoof,
    ): ResolvedModuleLayout {
      val mutableModel = PvRoofPlanningModel()
      return ResolvedModuleLayout(
        id = id,
        description = null,
        moduleType = moduleType,
        blackModules = false,
        roof = roof,
        suggestedRoofInsets = Insets(100.0, 100.0, 100.0, 100.0),
        // TODO moduleAreaPlacements soll eine empty liste sein
        moduleAreasInformation = mutableModel.moduleAreas.toRestApiModel(),
        unusableAreasInformation = UnusableAreasInformation(emptyList()),
        lifeCycleState = LifeCycleState.Active,
      )
    }

    fun createDefault(
      id: ModuleLayoutId = ModuleLayoutId.random(),
      moduleType: PvModule,
      roofType: RoofType,
      roofTile: RoofTile? = null,
    ): ResolvedModuleLayout {

      val resolvedRoof = ResolvedRoof.createDefault(
        roofType = roofType,
        roofTile = roofTile,
      )

      return createDefault(
        id = id,
        moduleType = moduleType,
        roof = resolvedRoof,
      )
    }
  }

}
