package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import store.PlannerClientState

data class CompanyAddedAction(val newCompany: PlannerCompanyInformation) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return this.copy(
      essentials = essentialsNonNull.copy(
        usersAndCompanies = usersAndCompanies.withNewCompany(newCompany),
      ),
    )
  }
}
