@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.project

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.configuration.BlueprintFacilityConfiguration
import it.neckar.lizergy.model.configuration.ResolvedPhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.components.AssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.components.ElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.lizergy.model.configuration.components.WallboxConfiguration
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationAnnotation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.km
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents a [Blueprint] for a project
 *
 * A [Blueprint] is supposed to hold *ONLY* technical information about the project
 *
 * This object is saved. It can be loaded at later times and resolved to a [ResolvedPhotovoltaicsConfiguration].
 * Depending on the (then) resolved prices the results might be different.
 */
interface Blueprint : HasUuid, BelongsToCompany, HasProcessState {
  val projectId: PhotovoltaicsProjectId

  val blueprintId: BlueprintId

  @Deprecated("Replaced by new Process State Service")
  val editor: UserLoginName?

  val location: LocationInformation

  val shippingDistanceManual: @km Int?
  val shippingDistanceCalculated: @km Int?

  val moduleLayouts: ModuleLayouts

  @Deprecated("No longer used or needed")
  val blackModules: Boolean
  val dachmasseAbgelegt: Boolean

  @Deprecated("Replaced by Comment Service")
  val roofAnnotations: List<ConfigurationAnnotation<RoofId>>

  @Deprecated("Replaced by Comment Service")
  val roofsAnnotation: String?

  val powerUsageScenario: PowerUsageScenario
  val manualPowerConsumptionDistribution: ManualPowerConsumptionDistribution?

  val pricesTrendScenario: PricesTrendScenario

  @Deprecated("Replaced by Comment Service")
  val profitabilityAnnotation: String?

  val facilityConfiguration: BlueprintFacilityConfiguration
  val battery: ConfiguredOptionality
  val assemblyConfiguration: AssemblyConfiguration

  @Deprecated("Replaced by Comment Service")
  val facilityAnnotation: String?

  val wallboxConfiguration: WallboxConfiguration

  @Deprecated("Replaced by Comment Service")
  val wallboxAnnotation: String?

  val zaehlerschrankSchutzisoliert: Boolean
  val electricityWorkConfiguration: ElectricityWorkConfiguration

  @Deprecated("Replaced by Comment Service")
  val electricityWorkAnnotation: String?

  val additionalPositions: ConfigurationItemsConfiguration
  val existingFacilitiesConfiguration: ExistingFacilitiesConfiguration
  val legalNoticeAdditionalLine: String?
  val legalNotes: List<LegalNote>
  val discountPercentage: @pct Double

  @Deprecated("Replaced by Comment Service")
  val additionalAnnotation: String?

  @Deprecated("Replaced by Comment Service")
  val annotation: String?
  val zaehlerNummer: String
  val flurstueckNummer: String
  val facilityOperator1: FacilityOperatorInformation
  val facilityOperator2: FacilityOperatorInformation
  val einspeiseart: Einspeiseart
  val lagePlanAngefragt: Boolean

  val imagesDach: Boolean
  val imagesGeruestflaechen: Boolean
  val imagesKeller: Boolean
  val imagesZaehlerschrank: Boolean
  val imagesErdung: Boolean
  val neubau: Boolean

  val acquisitionDate: @ms Double


  override val uuid: Uuid
    get() = blueprintId.uuid

  override val belongsToCompanies: Set<CompanyCode>
    get() = buildSet {
      add(sellingCompany)
    }

  /**
   * An ID for a [Blueprint]
   */
  @Serializable
  data class BlueprintId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): BlueprintId {
        return BlueprintId(randomUuid4())
      }
    }
  }

}
