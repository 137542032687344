package components.diagnosis

import components.toggleableUI
import it.neckar.commons.kotlin.js.safeStringify
import it.neckar.lizergy.model.plannerJsonConfiguration
import it.neckar.open.collections.fastForEach
import it.neckar.projects.pvplanner.PvPlannerService
import it.neckar.react.common.form.*
import kotlinx.css.*
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import react.*
import react.dom.*
import store.hooks.useRequireCompanyForLoggedInUser
import store.store
import store.useSelector
import styled.*


/**
 * Used to serialize the objects to visualize them on the diagnosis page
 */
private val diagnosisJson: Json = Json {
  prettyPrint = true
  plannerJsonConfiguration()
}

val DiagnosisOverview: FC<Props> = fc("DiagnosisOverview") { props ->
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val state = store.state

  val onlineState = useSelector { onlineState }
  val deploymentZone = useSelector {
    deploymentZone
  }

  val essentials = useSelector {
    essentials
  }


  //The JSON objects

  //
  // Essentials
  //

  val priceList = essentials?.priceList
  val priceListJson: JSON? = useMemo(priceList) {
    priceList?.let { JSON.parse(diagnosisJson.encodeToString(priceList)) }
  }

  val usersAndCompanies = essentials?.usersAndCompanies
  val usersAndCompaniesJson: JSON? = useMemo(usersAndCompanies) {
    usersAndCompanies?.let { JSON.parse(diagnosisJson.encodeToString(usersAndCompanies)) }
  }

  val availableProducts = essentials?.availableProducts
  val availableProductsJson: JSON? = useMemo(availableProducts) {
    availableProducts?.let { JSON.parse(diagnosisJson.encodeToString(availableProducts)) }
  }


  //
  // User / Logged in state
  //

  val loggedInUser = state.loggedInState?.loggedInUser

  val loggedInState = state.loggedInState
  val loggedInStateJson: JSON? = useMemo(loggedInState) {
    loggedInState?.let { JSON.parse(diagnosisJson.encodeToString(loggedInState)) }
  }

  val accessToken = loggedInState?.accessToken
  val accessTokenPayloadJson: JSON? = useMemo(accessToken) {
    accessToken?.let { JSON.parse(accessToken.extractJwtPayload()) }
  }

  val refreshToken = loggedInState?.refreshToken
  val refreshTokenPayloadJson: JSON? = useMemo(refreshToken) {
    refreshToken?.let { JSON.parse(refreshToken.extractJwtPayload()) }
  }

  //
  //
  //
  val errorState = state.errorState
  val updateCounter = state.updateCounter

  val resolvedProjects = state.resolvedProjects
  val resolvedProjectsJson: JSON = useMemo(resolvedProjects) {
    resolvedProjects.let { JSON.parse(safeStringify(resolvedProjects)) }
  }


  //The visibility states
  val usersAndCompaniesVisible = useState(true)
  val priceListVisible = useState(false)
  val availableProductsVisible = useState(false)
  val loggedInStateVisible = useState(true)
  val accessTokenPayloadVisible = useState(false)
  val refreshTokenPayloadVisible = useState(false)
  val projectPreviewsVisible = useState(false)
  val projectVisible = useState(false)


  div {
    h1 {
      +"Diagnose"
    }

    div {
      +" Deployment Zone: $deploymentZone"
    }

    div {
      h2 {
        +"Essentials"
      }

      if (essentials != null) {
        toggleableUI("Users/Companies", usersAndCompaniesVisible, EditableStatus.Editable) {
          JsonPreview {
            attrs {
              json = usersAndCompaniesJson
            }
          }
        }

        toggleableUI("Preisliste", priceListVisible, EditableStatus.Editable) {
          JsonPreview {
            attrs {
              json = priceListJson
            }
          }
        }

        toggleableUI("Available Products", availableProductsVisible, EditableStatus.Editable) {
          JsonPreview {
            attrs {
              json = availableProductsJson
            }
          }
        }
      }


      h2 {
        +"User / Login"
      }

      dl {
        dt {
          +"loggedInUser"
        }
        dd {
          +(loggedInUser ?: "null")
        }
        dt {
          +"CompanyName"
        }
        dd {
          +companyName
        }
      }

      toggleableUI("LoggedInState", loggedInStateVisible, EditableStatus.Editable) {
        JsonPreview {
          attrs {
            json = loggedInStateJson
          }
        }
      }


      h2 {
        +"JWS / Token"
      }

      toggleableUI("Access Token Payload", accessTokenPayloadVisible, EditableStatus.Editable) {
        JsonPreview {
          attrs {
            json = accessTokenPayloadJson
          }
        }
      }

      toggleableUI("Refresh Token Payload", refreshTokenPayloadVisible, EditableStatus.Editable) {
        JsonPreview {
          attrs {
            json = refreshTokenPayloadJson
          }
        }
      }

      h2 {
        +"State"
      }

      p {
        +("Error State: ${errorState.currentException?.message ?: "null"}")
      }
      p {
        +("Update Counter: $updateCounter")
      }

      h2 {
        +"Online State"
      }

      ul {
        PvPlannerService.entries.fastForEach {
          li {
            +it.name
            +" : "
            +onlineState[it].toString()
          }
        }
      }

      h2 {
        +"Projects"
      }

      h5 {
        +"Project Previews"
      }

      toggleableUI("Resolved Projects", projectVisible, EditableStatus.Editable) {
        JsonPreview {
          attrs {
            json = resolvedProjectsJson
          }
        }
      }


    }
  }
}

val JsonPreview: FC<JsonPreviewProps> = fc("JsonPreview") { props ->
  val json = props.json

  div {
    styledDiv {
      css {
        maxHeight = 250.px
        overflow = Overflow.auto
        fontSize = 8.pt
      }

      ReactJson {
        attrs {
          src = json
          collapsed = 1
          indentWidth = 2
          displayDataTypes = false
          enableClipboard = true
        }
      }
    }
  }
}

external interface JsonPreviewProps : Props {
  var json: Any?
}
