@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.project

import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.ElementsSelectionEntry
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.BlueprintFacilityConfiguration
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.ResolvedPhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.lizergy.model.configuration.components.ResolvedAssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.components.ScaffoldingArea
import it.neckar.lizergy.model.configuration.components.WallboxConfiguration
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationAnnotation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.configuration.quote.QuoteConfigurations
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedWallboxSelection
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.configuration.quote.evaluate
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.project.Blueprint.BlueprintId
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.validation.NeedsValidation
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.lizergy.model.validation.TitleMessage
import it.neckar.lizergy.model.validation.ValidationProblem
import it.neckar.lizergy.model.validation.ValidationProblems
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.km
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents a [ResolvedBlueprint] for a project
 *
 * A [ResolvedBlueprint] is supposed to hold *ONLY* technical information about the project
 *
 * This object is saved. It can be loaded at later times and resolved to a [ResolvedPhotovoltaicsConfiguration].
 * Depending on the (then) resolved prices the results might be different.
 */
@Serializable
data class ResolvedBlueprint(
  override val projectId: PhotovoltaicsProjectId,

  override val blueprintId: BlueprintId,

  @Deprecated("Replaced by new Process State Service")
  val editorInformation: UserInformation?,

  val sellingCompanyInformation: PlannerCompanyInformation,

  override val location: LocationInformation,

  override val shippingDistanceManual: @km Int?,
  override val shippingDistanceCalculated: @km Int?,

  override val moduleLayouts: ResolvedModuleLayouts,
  @Deprecated("No longer used or needed")
  override val blackModules: Boolean,
  override val dachmasseAbgelegt: Boolean,
  @Deprecated("Replaced by Comment Service")
  override val roofAnnotations: List<ConfigurationAnnotation<RoofId>>,
  @Deprecated("Replaced by Comment Service")
  override val roofsAnnotation: String?,

  override val powerUsageScenario: PowerUsageScenario,
  override val manualPowerConsumptionDistribution: ManualPowerConsumptionDistribution?,
  override val pricesTrendScenario: PricesTrendScenario,
  @Deprecated("Replaced by Comment Service")
  override val profitabilityAnnotation: String?,

  override val facilityConfiguration: BlueprintFacilityConfiguration,
  override val battery: ConfiguredOptionality,
  override val assemblyConfiguration: ResolvedAssemblyConfiguration,
  @Deprecated("Replaced by Comment Service")
  override val facilityAnnotation: String?,

  override val wallboxConfiguration: WallboxConfiguration,
  @Deprecated("Replaced by Comment Service")
  override val wallboxAnnotation: String?,

  override val zaehlerschrankSchutzisoliert: Boolean,
  override val electricityWorkConfiguration: ResolvedElectricityWorkConfiguration,
  @Deprecated("Replaced by Comment Service")
  override val electricityWorkAnnotation: String?,

  override val additionalPositions: ConfigurationItemsConfiguration,
  override val existingFacilitiesConfiguration: ExistingFacilitiesConfiguration,
  override val legalNoticeAdditionalLine: String?,
  override val legalNotes: List<LegalNote>,
  override val discountPercentage: @pct Double,
  @Deprecated("Replaced by Comment Service")
  override val additionalAnnotation: String?,

  @Deprecated("Replaced by Comment Service")
  override val annotation: String?,
  override val zaehlerNummer: String,
  override val flurstueckNummer: String,
  override val facilityOperator1: FacilityOperatorInformation,
  override val facilityOperator2: FacilityOperatorInformation,
  override val einspeiseart: Einspeiseart,
  override val lagePlanAngefragt: Boolean,

  override val imagesDach: Boolean,
  override val imagesGeruestflaechen: Boolean,
  override val imagesKeller: Boolean,
  override val imagesZaehlerschrank: Boolean,
  override val imagesErdung: Boolean,
  override val neubau: Boolean,

  override val acquisitionDate: @ms Double,
  @Deprecated("Being replaced by new Process State Service")
  override val processState: PositionEditHistory<OLDProcessState>? = null,

  ) : Blueprint, BelongsToCompanyProfile, NeedsValidation {

  override val sellingCompanyProfile: CompanyProfile
    get() = sellingCompanyInformation.companyProfile

  override val sellingCompany: CompanyCode
    get() = sellingCompanyProfile.companyCode

  @Deprecated("Replaced by new Process State Service")
  override val editor: UserLoginName?
    get() = editorInformation?.loginName

  /**
   * The total (hypothetical) [PowerRating] of all selected modules for this [ResolvedBlueprint]
   */
  val totalPowerRating: PowerRating
    get() = moduleLayouts.totalPowerRating

  val shippingDistance: Int?
    get() = shippingDistanceManual ?: shippingDistanceCalculated

  fun scaffoldingAreas(): Map<ModuleLayoutId?, Int> {
    return assemblyConfiguration.scaffoldingArea?.associateBy { null } ?: moduleLayouts.scaffoldingAreas()
  }

  val scaffoldingSupplied: Boolean
    get() = assemblyConfiguration.assemblyStatus !is ScaffoldingArea

  override val validationProblems: ValidationProblems
    get() = ValidationProblems(
      titleMessages = listOf(
        TitleMessage(null) { "Projekterfassung kann abgeschlossen werden!" },
        TitleMessage(ProblemType.Error) { "Die folgenden $it Felder müssen ausgefüllt werden bevor die Projekterfassung abgeschlossen werden kann:" },
        TitleMessage(ProblemType.Warning) { "Die folgenden $it Felder sollten überprüft werden:" },
      ),
      ownProblems = listOf(
        ValidationProblem("Entfernung zum Objekt") { if (shippingDistance == null) ProblemType.Error else null },
        ValidationProblem("Dachmaße organisiert/abgelegt") { if (dachmasseAbgelegt.not()) ProblemType.Error else null },
        ValidationProblem("Lageplan angefragt/abgelegt") { if (lagePlanAngefragt.not()) ProblemType.Error else null },

        ValidationProblem("Bilder Dach") { if (neubau.not() && imagesDach.not()) ProblemType.Error else null },
        ValidationProblem("Bilder Gerüstflächen") { if (neubau.not() && imagesGeruestflaechen.not()) ProblemType.Error else null },
        ValidationProblem("Bilder Keller (WR-, Batteriestandort, Kabelwege)") { if (neubau.not() && imagesKeller.not()) ProblemType.Error else null },
        ValidationProblem("Bilder Zählerschrank, Zähler, Schutzisolierung") { if (neubau.not() && imagesZaehlerschrank.not()) ProblemType.Error else null },
        ValidationProblem("Bilder Erdung") { if (neubau.not() && imagesErdung.not()) ProblemType.Error else null },

        // Warnings
        ValidationProblem("Standort ist unvollständig") { if (location.address.isPlausible.not()) ProblemType.Warning else null },
        ValidationProblem("Zählerschrank ist nicht schutzisoliert") { if (zaehlerschrankSchutzisoliert.not()) ProblemType.Warning else null },
        ValidationProblem("Zählernummer fehlt") { if (neubau.not() && zaehlerNummer.isBlank()) ProblemType.Warning else null },
        ValidationProblem("Anlagenbetreiber fehlt") { if (facilityOperator1.isValid().not()) ProblemType.Warning else null },
        ValidationProblem("Achtung keine Bilder vorhanden weil Neubau! Überprüfen, dass Pläne hinterlegt sind") { if (neubau) ProblemType.Warning else null },
      ),
    )

  operator fun get(moduleLayoutId: ModuleLayoutId): ResolvedModuleLayout {
    return moduleLayouts.find { it.id == moduleLayoutId } ?: throw IllegalArgumentException("No ResolvedModuleLayout found for moduleLayoutId <$moduleLayoutId>")
  }

  operator fun get(roofId: RoofId): ResolvedRoof {
    return moduleLayouts.find {
      it.roof.id == roofId
    }?.roof ?: throw NoSuchElementException("No roof for the ID $roofId")
  }

  fun toQuoteConfigurations(loggedInUser: UserLoginName, availableProducts: AvailableProducts, priceList: PriceList, companyResolver: CompanyResolver, now: @ms Double = nowMillis()): QuoteConfigurations {
    require(validationProblems.hasErrors().not()) {
      "Tried to convert Blueprint but it still encountered the following problems: ${validationProblems.onlyProblemTypeAsList(ProblemType.Error)}"
    }

    val resolvedFacilityConfiguration = facilityConfiguration.toFacilityConfiguration(
      defaultHeaterRod = availableProducts.availableHeaterRods().firstOrNull(),
    )
    val bestFittingWallbox = bestFittingWallbox(availableProducts.availableWallBoxes())

    val baseResolvedConfiguration = ResolvedPhotovoltaicsConfiguration.getEmpty(
      id = PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId.random(),
      sellingCompany = sellingCompanyInformation,
      monitoringCompany = companyResolver[sellingCompanyProfile.relevantParentCompanyCode()],
      editor = editorInformation,
      location = location,
      resolvedModuleLayouts = moduleLayouts,
      powerUsageScenario = powerUsageScenario,
      facilityConfiguration = resolvedFacilityConfiguration,
      wallboxSelection = bestFittingWallbox,
      assemblyConfiguration = assemblyConfiguration,
      shippingDistanceManual = shippingDistanceManual,
      shippingDistanceCalculated = shippingDistanceCalculated,
      electricityWorkConfiguration = electricityWorkConfiguration,
      additionalPositions = additionalPositions,
      existingFacilitiesConfiguration = existingFacilitiesConfiguration,
      discountPercentage = discountPercentage,
      zaehlerNummer = zaehlerNummer,
      flurstueckNummer = flurstueckNummer,
      facilityOperator1 = facilityOperator1,
      facilityOperator2 = facilityOperator2,
      einspeiseart = einspeiseart,
      legalNoticeAdditionalLine = legalNoticeAdditionalLine,
      legalNotes = legalNotes,
      powerConsumptionDistribution = manualPowerConsumptionDistribution,
      pricesTrendScenario = pricesTrendScenario,
      loggedInUser = loggedInUser,
      now = now,
    )

    val baseQuoteConfiguration = baseResolvedConfiguration.evaluate(
      currentQuoteSnapshot = null,
      availableProducts = availableProducts,
      priceList = priceList,
    )

    val baseQuoteConfigurationWithBattery = baseQuoteConfiguration.copy(
      configurationId = PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId.random(),
      facilityConfiguration = baseQuoteConfiguration.facilityConfiguration.copy(
        batteryConfiguration = baseQuoteConfiguration.recommendedBatteryConfiguration,
      ),
    )

    return QuoteConfigurations(buildList {
      when (battery) {
        ConfiguredOptionality.Selected -> add(baseQuoteConfigurationWithBattery)

        ConfiguredOptionality.NotSelected -> add(baseQuoteConfiguration)

        ConfiguredOptionality.Optional -> {
          add(baseQuoteConfigurationWithBattery)
          add(baseQuoteConfiguration)
        }
      }
    })

  }

  private fun bestFittingWallbox(availableWallboxen: List<Wallbox>): ResolvedWallboxSelection {
    val wallboxConfigurationId = wallboxConfiguration.id

    return availableWallboxen.firstOrNull {
      it.chargingCapacity == wallboxConfiguration.energyConsumption && it.cableLength == wallboxConfiguration.cableLength
    }?.let {
      ResolvedWallboxSelection(
        id = wallboxConfigurationId,
        entries = listOf(ElementsSelectionEntry(it, 1)),
        throttled = false,
      )
    } ?: ResolvedWallboxSelection.getEmpty(wallboxConfigurationId)
  }


  companion object {
    fun createDefault(
      projectId: PhotovoltaicsProjectId = PhotovoltaicsProjectId.random(),
      blueprintId: BlueprintId = BlueprintId.random(),
      sellingCompany: PlannerCompanyInformation,
      location: LocationInformation,
      moduleType: PvModule,
      roofType: RoofType,
      assemblyDifficulty: AssemblyDifficulty,
      acquisitionDate: Double,
    ): ResolvedBlueprint {

      val resolvedModuleLayouts = ResolvedModuleLayouts(
        moduleLayoutsId = ModuleLayouts.ModuleLayoutsId.random(),
        moduleLayouts = listOf(
          ResolvedModuleLayout.createDefault(
            moduleType = moduleType,
            roof = ResolvedRoof.createDefault(roofType = roofType),
          ),
        )
      )

      return ResolvedBlueprint(
        projectId = projectId,
        blueprintId = blueprintId,
        editorInformation = null,
        sellingCompanyInformation = sellingCompany,
        location = location,
        shippingDistanceManual = null,
        shippingDistanceCalculated = null,
        moduleLayouts = resolvedModuleLayouts,
        blackModules = false,
        dachmasseAbgelegt = false,
        roofAnnotations = emptyList(),
        roofsAnnotation = null,
        powerUsageScenario = PowerUsageScenario.typical(),
        manualPowerConsumptionDistribution = null,
        pricesTrendScenario = PricesTrendScenario.typical(),
        profitabilityAnnotation = null,
        facilityConfiguration = BlueprintFacilityConfiguration.getEmpty(),
        battery = ConfiguredOptionality.NotSelected,
        assemblyConfiguration = ResolvedAssemblyConfiguration.getEmpty(assemblyDifficulty),
        facilityAnnotation = null,
        wallboxConfiguration = WallboxConfiguration.getEmpty(),
        wallboxAnnotation = null,
        zaehlerschrankSchutzisoliert = false,
        electricityWorkConfiguration = ResolvedElectricityWorkConfiguration.getEmpty(),
        electricityWorkAnnotation = null,
        additionalPositions = ConfigurationItemsConfiguration.getEmpty(),
        existingFacilitiesConfiguration = ExistingFacilitiesConfiguration.getEmpty(),
        legalNoticeAdditionalLine = null,
        legalNotes = emptyList(),
        discountPercentage = 0.0,
        additionalAnnotation = null,
        annotation = null,
        zaehlerNummer = "",
        flurstueckNummer = "",
        facilityOperator1 = FacilityOperatorInformation(),
        facilityOperator2 = FacilityOperatorInformation(),
        einspeiseart = Einspeiseart.Ueberschuss,
        lagePlanAngefragt = false,
        imagesDach = false,
        imagesGeruestflaechen = false,
        imagesKeller = false,
        imagesZaehlerschrank = false,
        imagesErdung = false,
        neubau = false,
        acquisitionDate = acquisitionDate,
      )
    }

  }

}
