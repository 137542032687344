package router

import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.react.common.annotations.*
import react.*
import react.dom.*
import store.hooks.useLoadProcessStates
import web.dom.document

/** creates and sets the document title*/
@UsesHooks
fun useDocumentTitle(companyName: String, title: String?, projectName: String? = null, configurationName: String? = null, moduleName: String? = null) {
  useEffect(title, projectName, configurationName, moduleName) {
    document.title = createDocumentTitle(companyName, title, projectName, configurationName, moduleName)
  }
}

/** creates a document Title with '·' and an '· PV Planner' appendix*/
fun createDocumentTitle(companyName: String, title: String?, projectName: String? = null, configurationName: String? = null, moduleName: String? = null): String {

  return if (title == null && projectName == null) {
    "$companyName PV Planner"
  } else {
    buildString {
      moduleName?.let {
        append("$it · ")
      }
      configurationName?.let {
        append("$it · ")
      }
      projectName?.let {
        append("$it · ")
      }
      title?.let {
        append("$it · ")
      }

      append("$companyName PV Planner")
    }
  }
}


val EditorNameFromUrl: FC<Props> = fc("EditorNameFromUrl") {
  span {
    +useUserFromUrl().editorName
  }
}

val CompanyNameFromUrl: FC<Props> = fc("CompanyNameFromUrl") {
  span {
    +useCompanyFromUrl().simpleName
  }
}

val ProjectNameFromUrl: FC<Props> = fc("ProjectNameFromUrl") {
  val allProcessStatesForComponents = useLoadProcessStates()
  val project = useLoadResolvedProjectFromUrl()
  span {
    +(allProcessStatesForComponents?.let { project?.getDisplayName(it).nullIfBlank() } ?: "Projekt")
  }
}

val ConfigurationLabelFromUrl: FC<Props> = fc("ConfigurationLabelFromUrl") {
  span {
    +(useLoadQuoteConfigurationFromUrl()?.configurationName.nullIfBlank() ?: "Angebot")
  }
}

val BlueprintRoofLabelFromUrl: FC<Props> = fc("BlueprintRoofLabelFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val moduleLayoutId = useModuleLayoutIdFromUrl()
  val blueprint = project?.blueprint

  span {
    +(blueprint?.moduleLayouts?.get(moduleLayoutId)?.roof?.roofName.nullIfBlank() ?: "Dachfläche")
  }
}

val ConfigurationModuleLayoutLabelFromUrl: FC<Props> = fc("ConfigurationModuleLayoutLabelFromUrl") {
  val quoteConfiguration = useLoadQuoteConfigurationFromUrl()
  val moduleLayoutId = useModuleLayoutIdFromUrl()

  span {
    +(quoteConfiguration?.moduleLayouts?.get(moduleLayoutId)?.layoutName.nullIfBlank() ?: "Modulfläche")
  }
}

val BlueprintAdditionalPositionLabelFromUrl: FC<Props> = fc("BlueprintAdditionalPositionLabelFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val additionalPositionId = useAdditionalPositionIdFromUrl()
  val blueprint = project?.blueprint

  span {
    +(blueprint?.additionalPositions?.get(additionalPositionId)?.headline.nullIfBlank() ?: "Zusätzliche Position")
  }
}

val ConfigurationAdditionalPositionLabelFromUrl: FC<Props> = fc("ConfigurationAdditionalPositionLabelFromUrl") {
  val quoteConfiguration = useLoadQuoteConfigurationFromUrl()
  val additionalPositionId = useAdditionalPositionIdFromUrl()

  span {
    +(quoteConfiguration?.additionalPositions?.get(additionalPositionId)?.headline.nullIfBlank() ?: "Zusätzliche Position")
  }
}
