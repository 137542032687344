package components.project

import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.lizergy.model.validation.ValidationProblems
import it.neckar.open.collections.fastForEach
import it.neckar.open.kotlin.lang.ifNull
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleCheck
import it.neckar.react.common.FontAwesome.faCircleExclamation
import it.neckar.react.common.FontAwesome.faCircleQuestion
import kotlinx.html.SPAN
import kotlinx.html.title
import plannerI18nConfiguration
import react.*
import react.dom.*

fun RBuilder.validationTable(validationProblems: ValidationProblems) {
  div {
    problemList(validationProblems, ProblemType.Error)
    problemList(validationProblems, ProblemType.Warning)
  }
}

fun RBuilder.validationIcon(validationProblems: ValidationProblems, config: ((SPAN) -> Unit)? = null) {
  validationIcon(validationProblems.worstValidationProblem(), validationProblems.format(), config)
}

fun RBuilder.validationIcon(worstProblem: ProblemType?, formattedProblems: String?, config: ((SPAN) -> Unit)? = null) {

  span {
    attrs {
      formattedProblems?.let { title = formattedProblems }
      config?.invoke(this)
    }

    when (worstProblem) {

      null -> {
        attrs {
          addClass("text-success")
        }
        span("pe-2") { faCircleCheck() }
      }

      ProblemType.Error -> {
        attrs {
          addClass("text-danger")
        }
        span("pe-2") { faCircleExclamation() }
      }

      ProblemType.Warning -> {
        attrs {
          addClass("text-warning")
        }
        span("pe-2") { faCircleQuestion() }
      }

    }

  }
}

fun RBuilder.problemList(validationProblems: ValidationProblems, problemType: ProblemType) {
  val hasProblems = validationProblems.ownProblems.any { it.evaluationLambda() == problemType }

  div("my-3") {
    if (hasProblems.not()) {

      if (problemType == ProblemType.Error) {
        attrs {
          addClass("text-success")
        }

        validationIcon(null, validationProblems.format(plannerI18nConfiguration))
        b { +validationProblems.getTitleMessage(null).ifNull("") }
      }

    } else {

      when (problemType) {

        ProblemType.Error -> {
          attrs {
            addClass("text-danger")
          }

          validationIcon(ProblemType.Error, validationProblems.format(plannerI18nConfiguration))
          b { +validationProblems.getTitleMessage(ProblemType.Error).ifNull("") }
        }

        ProblemType.Warning -> {
          attrs {
            addClass("text-warning")
          }

          validationIcon(ProblemType.Warning, validationProblems.format(plannerI18nConfiguration))
          b { +validationProblems.getTitleMessage(ProblemType.Warning).ifNull("") }
        }

      }

      ul {
        validationProblems.ownProblems.fastForEach { problem ->
          if (problem.evaluationLambda() == problemType) li { +problem.message }
        }
      }

    }

    val worstProblem = validationProblems.worstValidationProblem()
    if (worstProblem != null && worstProblem >= problemType) {
      validationProblems.childProblems?.fastForEach { childProblems ->
        problemList(childProblems, problemType)
      }
    }

  }

}
