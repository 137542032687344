package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.configuration.components.AssemblyConfiguration.AssemblyConfigurationId
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.open.unit.si.m2
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

interface AssemblyConfiguration : HasUuid {
  val id: AssemblyConfigurationId
  val numberOfModulesKreuzverbund: Int?
  val dachstaenderIsolierung: Boolean
  val moveSatelliteDish: Boolean
  val removeAntenna: Boolean
  val externerBlitzschutzVorhanden: Boolean

  /**
   * If the system will be assembled by the customer
   */
  val assemblyStatus: AssemblyStatus

  override val uuid: Uuid
    get() = id.uuid

  val scaffoldingArea: List<@m2 Int>?
    get() = when (assemblyStatus) {
      is ScaffoldingArea -> assemblyStatus.scaffoldingArea?.let { listOf(it) }
      ScaffoldingProvided, is SelfAssemblyConfiguration -> emptyList()
    }


  @Serializable
  data class AssemblyConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): AssemblyConfigurationId {
        return AssemblyConfigurationId(randomUuid4())
      }
    }
  }

}

@Serializable
data class ResolvedAssemblyConfiguration(
  override val id: AssemblyConfigurationId = AssemblyConfigurationId.random(),
  val assemblyDifficulty: AssemblyDifficulty,
  override val numberOfModulesKreuzverbund: Int? = null,
  override val dachstaenderIsolierung: Boolean = false,
  override val moveSatelliteDish: Boolean = false,
  override val removeAntenna: Boolean = false,
  override val externerBlitzschutzVorhanden: Boolean = false,
  /**
   * If the system will be assembled by the customer
   */
  override val assemblyStatus: AssemblyStatus = ScaffoldingArea(),
) : AssemblyConfiguration {

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedAssemblyConfiguration {
    val newId = AssemblyConfigurationId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(id = newId)
  }

  companion object {
    fun getEmpty(assemblyDifficulty: AssemblyDifficulty): ResolvedAssemblyConfiguration {
      return ResolvedAssemblyConfiguration(assemblyDifficulty = assemblyDifficulty)
    }

    fun getDemo(assemblyDifficulty: AssemblyDifficulty): ResolvedAssemblyConfiguration {
      return ResolvedAssemblyConfiguration(
        assemblyDifficulty = assemblyDifficulty,
        numberOfModulesKreuzverbund = 0,
        dachstaenderIsolierung = false,
        moveSatelliteDish = false,
        removeAntenna = false,
        externerBlitzschutzVorhanden = false,
        assemblyStatus = ScaffoldingArea(scaffoldingArea = 80)
      )
    }

    fun presentationDemo(assemblyDifficulty: AssemblyDifficulty): ResolvedAssemblyConfiguration {
      return ResolvedAssemblyConfiguration(
        assemblyDifficulty = assemblyDifficulty,
        numberOfModulesKreuzverbund = null,
        dachstaenderIsolierung = false,
        moveSatelliteDish = false,
        removeAntenna = false,
        externerBlitzschutzVorhanden = false,
        assemblyStatus = ScaffoldingArea(scaffoldingArea = 0)
      )
    }

  }

}
