package components.project.configuration.description

import components.project.OLDdetailedProcessStateLabel
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.collections.fastForEach
import react.*
import react.dom.*
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUsersAndCompanies


fun RBuilder.editConfigurationProcessState(
  project: ResolvedProject,
  quoteConfiguration: QuoteConfiguration,
): Unit = child(EditConfigurationProcessState) {
  attrs {
    this.project = project
    this.quoteConfiguration = quoteConfiguration
  }
}

val EditConfigurationProcessState: FC<EditConfigurationProcessStateProps> = fc("EditConfigurationProcessState") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val usersAndCompanies = useSelectUsersAndCompanies()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val processState = quoteConfiguration.processState
  //val configurationState = useState(processState.currentValue)
  //val configurationStateStage = useState(processState.currentValue.processStateStage())


  //useMemo(processState.currentValue) {
  //  configurationState.setter(processState.currentValue)
  //  configurationStateStage.setter(processState.currentValue.processStateStage())
  //}
  //
  //useMemo(configurationState.value) {
  //  if (configurationState.value != processState.currentValue) {
  //    val configurationToSave = quoteConfiguration.copy(
  //      processState = processState.with(configurationState.value, loggedInUser.loginName),
  //    )
  //
  //    UiActions.saveQuoteConfiguration(project, configurationToSave)
  //  }
  //}


  //div("my-3") {
  //  buttonGroup {
  //    processStateStageRadioButton(EditStage, configurationState, configurationStateStage)
  //    if (advancedButtonsEnabled) {
  //      processStateStageRadioButton(OfferStage, configurationState, configurationStateStage)
  //      processStateStageRadioButton(ConfirmationStage, configurationState, configurationStateStage)
  //    }
  //    processStateStageRadioButton(ArchivedStage, configurationState, configurationStateStage)
  //  }
  //}
  //div("my-3") {
  //  processStateRadioButtons("processStateBtnRadio", configurationState, { EditableStatus.Editable.and(it != OLDProcessState.ConfigurationFinished || quoteConfiguration.validationProblems.hasErrors().not()) })
  //}

  div {
    p {
      OLDdetailedProcessStateLabel(processState?.currentEdit, null, usersAndCompanies)
    }

    processState?.editHistory?.fastForEach {
      p {
        OLDdetailedProcessStateLabel(it, null, usersAndCompanies)
      }
    }
  }

}

external interface EditConfigurationProcessStateProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
}
