package it.neckar.lizergy.model.configuration.quote.economics

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents a relative year
 */
@Serializable
@JvmInline
value class YearIndex(val index: Int) {
  init {
    require(index >= 0) { "Invalid index <$index>" }
  }

  operator fun minus(i: Int): YearIndex {
    return YearIndex(index - i)
  }

  /**
   * A human-readable index (1 based)
   */
  val indexBase1: Int
    get() {
      return index + 1
    }

  companion object {
    /**
     * The first or base year
     */
    val base: YearIndex = YearIndex(0)
    val second: YearIndex = YearIndex(1)
    val third: YearIndex = YearIndex(2)
    val fourth: YearIndex = YearIndex(3)
  }
}
