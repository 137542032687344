package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.ModuleArea
import com.meistercharts.charts.lizergy.roofPlanning.ModuleOrientation
import com.meistercharts.charts.lizergy.roofPlanning.RoofRelative
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Size
import it.neckar.open.unit.si.mm
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

/**
 * The placement information for a single module area
 */
@Serializable
data class ModuleAreaInformation(
  val id: ModuleAreaId = ModuleAreaId.random(),
  /**
   * The location of the module area (bottom left corner of the roof)
   */
  val location: @RoofRelative Coordinates,
  /**
   * The size of the module area
   */
  val size: @mm Size,
  /**
   * The module orientation
   */
  val orientation: ModuleOrientation,
  /**
   * The horizontal/vertical gap between modules
   */
  val gap: @mm Double,
  /**
   * The information for each module for this module area
   */
  val modulesInformation: ModulesInformation,
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  @Serializable
  data class ModuleAreaId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ModuleAreaId {
        return ModuleAreaId(randomUuid4())
      }
    }
  }
}


fun ModuleArea.toRestApiModel(): ModuleAreaInformation {
  return ModuleAreaInformation(
    id = ModuleAreaInformation.ModuleAreaId(this.uuid),
    location = this.location,
    size = this.size,
    orientation = this.moduleOrientation,
    gap = this.gap,
    modulesInformation = this.modules.toRestApiModel()
  )
}
