@file:UseSerializers(UuidSerializer::class)

package it.neckar.financial.quote

import com.benasher44.uuid.Uuid
import it.neckar.customer.Customer
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.si.ms
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract

const val quoteValidDuration: @ms Double = 1000 * 60 * 60 * 24 * 30.0

/**
 * Represents an actual quote.
 * A quote does *not* contain economics calculations
 */
@Serializable
data class Quote(
  val id: QuoteId,

  /**
   * The company the quote has been created by
   */
  val sellingCompany: QuoteCompanyInformation,

  /**
   * The title of the quote
   */
  val title: String,

  /**
   * The name of the maintainer of the project
   */
  val maintainerName: String,

  /**
   * The customer
   */
  val customer: Customer,

  /**
   * The quote elements
   */
  val quoteElements: QuoteElements,

  /**
   * The sources of this quote
   */
  val sources: QuoteSources,

  /**
   * The creation date
   */
  val creationDate: @ms Double,

  /**
   * Until when the quote is valid
   */
  val validUntil: @ms Double = creationDate + quoteValidDuration,

  /**
   * More human-readable ID for this [Quote]
   */
  val quoteNumber: String = QuoteNumberGenerator.create(creationDate),

  /**
   * Additional information about delivery time
   */
  val deliveryTimeInMonths: Int = 5,

  /**
   * The legal notice
   * May contain multiple lines to be added to the end of the [Quote]
   */
  val legalNotice: QuoteLegalNotice = QuoteLegalNotice.empty,

  ) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  /**
   * Total of the invoice - without discount
   */
  val subTotals: SumsForVATs
    get() = quoteElements.subTotalsForVATs()

  /**
   * The discount (returns a *negative) value)
   */
  val discounts: PricesForVATs
    get() = quoteElements.discountsForVATs()

  /**
   * Net value ("Netto") - discount removed
   */
  val netPrices: PricesForVATs
    get() = quoteElements.netPricesForVats()

  /**
   * Gross value ("Brutto")
   */
  val grossPrices: PricesForVATs
    get() = quoteElements.grossPricesForVats()

  /**
   * "Mehrwertsteuer"
   */
  val vatPrices: PricesForVATs
    get() = quoteElements.vatPrices()

  /**
   * Is the current time past the valid until date
   */
  fun isOutdated(): Boolean {
    return nowMillis() > validUntil
  }

  /**
   * ID for a quote
   */
  @Serializable
  data class QuoteId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): QuoteId {
        return QuoteId(randomUuid4())
      }
    }
  }

  /**
   * Executes the given block if there is a discount
   */
  fun ifDiscounted(action: () -> Unit) {
    contract {
      callsInPlace(action, InvocationKind.AT_MOST_ONCE)
    }

    return quoteElements.ifDiscounted(action)
  }
}
