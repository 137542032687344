package it.neckar.customer.company

import it.neckar.open.kotlin.serializers.SerializerFor
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.contracts.contract

/**
 * Contains additional information about a company
 */
@Serializable
sealed interface CompanyProfile : Comparable<CompanyProfile> {
  val companyCode: CompanyCode

  /**
   * Returns the relevant parent company.
   * Returns this for main companies.
   */
  fun relevantParentCompanyCode(): CompanyCode = when (this) {
    is MainCompanyProfile -> this.companyCode
    is PartnerCompanyProfile -> this.parentCompany
    is TestCompanyProfile -> this.companyCode
    NeckarITCompanyProfile -> this.companyCode
  }
}

fun CompanyProfile.isTestCompany(): Boolean {
  contract { returns(true) implies (this@isTestCompany is TestCompanyProfile) }
  return this is TestCompanyProfile
}

fun CompanyProfile.isPartnerCompany(): Boolean {
  contract { returns(true) implies (this@isPartnerCompany is PartnerCompanyProfile) }
  return this is PartnerCompanyProfile
}

fun CompanyProfile.isMainCompany(): Boolean {
  contract { returns(true) implies (this@isMainCompany is MainCompanyProfile) }
  return this is MainCompanyProfile
}


@Serializable
@SerialName("Main")
@SerializerFor(MainCompanyProfile::class)
data class MainCompanyProfile(
  override val companyCode: CompanyCode,
) : CompanyProfile {
  override fun compareTo(other: CompanyProfile): Int {
    return when (other) {
      is MainCompanyProfile -> return this.companyCode.value.compareTo(other.companyCode.value)
      else -> 1
    }
  }
}


@Serializable
@SerialName("Partner")
data class PartnerCompanyProfile(
  override val companyCode: CompanyCode,
  val parentCompany: CompanyCode,
) : CompanyProfile {
  override fun compareTo(other: CompanyProfile): Int {
    return when (other) {
      is MainCompanyProfile -> -1
      NeckarITCompanyProfile -> -1
      is PartnerCompanyProfile -> return this.companyCode.value.compareTo(other.companyCode.value)
      is TestCompanyProfile -> 1
    }
  }
}


@Serializable
@SerialName("Test")
data class TestCompanyProfile(
  override val companyCode: CompanyCode,
) : CompanyProfile {
  override fun compareTo(other: CompanyProfile): Int {
    return when (other) {
      is TestCompanyProfile -> return this.companyCode.value.compareTo(other.companyCode.value)
      else -> -1
    }
  }
}

@Serializable
@SerialName("NeckarIT")
data object NeckarITCompanyProfile : CompanyProfile {
  override val companyCode: CompanyCode
    get() = CompanyCode.NeckarIT

  override fun compareTo(other: CompanyProfile): Int {
    return when (other) {
      is MainCompanyProfile -> -1
      NeckarITCompanyProfile -> 0
      else -> 1
    }
  }
}
