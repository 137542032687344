package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.lizergy.model.configuration.components.ElectricityWorkConfiguration.ElectricityWorkConfigurationId
import it.neckar.lizergy.model.configuration.quote.builder.InverterType
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

interface ElectricityWorkConfiguration : HasUuid {
  val id: ElectricityWorkConfigurationId
  val electricityWorkEffort: ConfigurationItem
  val sunnyHomeManager: Boolean
  val neuerZaehlerschrank: Boolean
  val zusammenZuLegendeZaehlerNummern: List<String>
  val einbauUnterverteiler: Boolean
  val manualEinbauDigitalerZaehler: Int?
  val smaEnergyMeter: Boolean
  val slsNachruesten: Boolean

  override val uuid: Uuid
    get() = id.uuid

  val einbauDigitalerZaehler: Int
    get() = manualEinbauDigitalerZaehler ?: zusammenZuLegendeZaehlerNummern.size

  fun sunnyHomeManager(selectedBattery: BatteryConfiguration?, waermepumpeSelected: Boolean, intelligentWallboxSelected: Boolean, heizstabSelected: Boolean): Boolean {
    return selectedBattery == null && (sunnyHomeManager || waermepumpeSelected || intelligentWallboxSelected || heizstabSelected)
  }

  fun sunnyHomeManagerNote(selectedBattery: BatteryConfiguration?, waermepumpeSelected: Boolean, intelligentWallboxSelected: Boolean, heizstabSelected: Boolean): String {
    return if (selectedBattery != null) {
      "Sunny Home Manager 2.0 ist bereits im Batteriesystem enthalten."
    } else {
      buildList {
        if (waermepumpeSelected) add("Wärmepumpe")
        if (intelligentWallboxSelected) add("intelligente Wallbox")
        if (heizstabSelected) add("Heizstab")
      }.joinToString(", ", "Sunny Home Manager 2.0 muss ausgewählt sein, da ", " ausgewählt sind.")
    }
  }


  @Serializable
  data class ElectricityWorkConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ElectricityWorkConfigurationId {
        return ElectricityWorkConfigurationId(randomUuid4())
      }
    }
  }
}


@Serializable
data class ResolvedElectricityWorkConfiguration(
  override val id: ElectricityWorkConfigurationId,
  override val electricityWorkEffort: ConfigurationItem,
  override val sunnyHomeManager: Boolean,
  val independenceManager: IndependenceManagerType?,
  override val neuerZaehlerschrank: Boolean,
  override val zusammenZuLegendeZaehlerNummern: List<String>,
  override val einbauUnterverteiler: Boolean,
  override val manualEinbauDigitalerZaehler: Int?,
  override val smaEnergyMeter: Boolean,
  override val slsNachruesten: Boolean,
) : ElectricityWorkConfiguration {

  fun invalidManagerCombination(inverterSelection: ResolvedInverterSelection, batteryInverter: InverterType?): Boolean? {
    return independenceManager?.invalidManagerCombination(inverterSelection, batteryInverter)
  }

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedElectricityWorkConfiguration {
    val newId = ElectricityWorkConfigurationId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(id = newId)
  }

  companion object {
    fun getEmpty(id: ElectricityWorkConfigurationId = ElectricityWorkConfigurationId.random()): ResolvedElectricityWorkConfiguration {
      return ResolvedElectricityWorkConfiguration(
        id = id,
        electricityWorkEffort = ConfigurationItem.getEmpty(ValueAddedTax.zero),
        sunnyHomeManager = false,
        independenceManager = null,
        neuerZaehlerschrank = false,
        zusammenZuLegendeZaehlerNummern = emptyList(),
        einbauUnterverteiler = false,
        manualEinbauDigitalerZaehler = null,
        smaEnergyMeter = false,
        slsNachruesten = false,
      )
    }
  }
}
