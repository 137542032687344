package it.neckar.editHistory

import it.neckar.open.time.nowMillis
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import kotlinx.serialization.Serializable

/**
 * Wraps a position
 * When changes this position, call the [with] method to automatically extend the [editHistory]
 */
@Serializable
data class PositionEditHistory<T>(
  /**
   * Most recent [PositionEdit]
   * Contains the current value, time it was edited, and name of the editor
   */
  val currentEdit: PositionEdit<T>,
  /**
   * All other, previous [PositionEdit]s for this [PositionEditHistory]
   */
  val editHistory: List<PositionEdit<T>>,
) {

  // TODO history nicht empty list
  constructor(newEntry: T, loginName: UserLoginName, editTime: @ms Double = nowMillis()) : this(PositionEdit(newEntry, loginName, editTime), emptyList())
  constructor(newEntry: T, loginName: UserLoginName, previousEdits: List<PositionEdit<T>>, editTime: @ms Double = nowMillis()) : this(PositionEdit(newEntry, loginName, editTime), previousEdits)

  val currentValue: T
    get() = currentEdit.value

  val lastEditedTime: @ms Double
    get() = currentEdit.editTime

  val allEdits: List<PositionEdit<T>>
    get() = listOf(currentEdit) + editHistory

  operator fun plus(otherProcessState: PositionEditHistory<T>?): PositionEditHistory<T> {
    return otherProcessState?.copy(editHistory = otherProcessState.editHistory + currentEdit + editHistory) ?: this
  }

  /**
   * *ALWAYS* call this function to extend this [PositionEditHistory] instead of [copy]
   */
  fun with(newEntry: T, loginName: UserLoginName, editTime: @ms Double = nowMillis()): PositionEditHistory<T> {
    if (newEntry == currentValue) return this
    return PositionEditHistory(newEntry, loginName, listOf(currentEdit) + editHistory, editTime)
  }

}
