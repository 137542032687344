package it.neckar.common.auth.login.token

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents a login token that can be used in place of a username / password combination
 */
@Serializable
@JvmInline
value class LoginToken(val value: String) {
  override fun toString(): String {
    return value
  }
}
