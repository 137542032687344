@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.editHistory.PositionEditHistory
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.lizergy.model.configuration.components.ResolvedAssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedFacilityConfiguration
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedWallboxSelection
import it.neckar.lizergy.model.configuration.quote.economics.EquityFinanced
import it.neckar.lizergy.model.configuration.quote.economics.FinancingType
import it.neckar.lizergy.model.configuration.quote.economics.YearlyCostInformation
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.km
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * A [PlannerConfiguration] with all IDs resolved
 */
@Serializable
data class ResolvedPhotovoltaicsConfiguration(

  override val configurationId: PhotovoltaicsConfigurationId,

  override val label: String?,

  override val description: String?,

  override val creationTime: Double,

  override val sellingCompanyInformation: PlannerCompanyInformation,
  override val monitoringCompanyInformation: PlannerCompanyInformation,

  @Deprecated("Replaced by new Process State Service")
  val editorInformation: UserInformation?,

  override val location: LocationInformation,

  /**
   * The [ResolvedModuleLayouts] of this [ResolvedPhotovoltaicsConfiguration]
   */
  override val moduleLayouts: ResolvedModuleLayouts,

  override val wallboxSelection: ResolvedWallboxSelection,

  override val powerUsageScenario: PowerUsageScenario,

  override val pricesTrendScenario: PricesTrendScenario,

  override val facilityConfiguration: ResolvedFacilityConfiguration,

  override val assemblyConfiguration: ResolvedAssemblyConfiguration,

  override val yearlyCosts: YearlyCostInformation,

  override val financing: FinancingType,

  override val shippingDistanceManual: @km Int?,

  override val shippingDistanceCalculated: @km Int?,

  override val electricityWorkConfiguration: ResolvedElectricityWorkConfiguration,

  override val additionalPositions: ConfigurationItemsConfiguration,

  override val existingFacilitiesConfiguration: ExistingFacilitiesConfiguration,

  override val discountPercentage: Double,

  override val zaehlerNummer: String,
  override val flurstueckNummer: String,

  override val facilityOperator1: FacilityOperatorInformation,
  override val facilityOperator2: FacilityOperatorInformation,

  override val einspeiseart: Einspeiseart,

  override val legalNoticeAdditionalLine: String?,

  override val legalNotes: List<LegalNote>,

  override val manualPowerConsumptionDistribution: ManualPowerConsumptionDistribution?,

  override val earningsDistribution: ResolvedEarningsDistribution,

  override val manualQuoteElements: ManualQuoteElements,

  @Deprecated("No longer required")
  override val signedQuoteReceived: Boolean = false,

  @Deprecated("Being replaced by new Process State Service")
  override val processState: PositionEditHistory<OLDProcessState>? = null,

  override val lifeCycleState: LifeCycleState,

  ) : PlannerConfiguration {

  override val sellingCompany: CompanyCode
    get() = sellingCompanyProfile.companyCode

  @Deprecated("Replaced by new Process State Service")
  override val editor: UserLoginName?
    get() = editorInformation?.loginName


  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedPhotovoltaicsConfiguration {
    val newId = PhotovoltaicsConfigurationId.random()
    mapOfOldToNewUuids[configurationId.uuid] = newId.uuid
    return copy(
      configurationId = newId,
      label = "$configurationName (Kopie)",
      editorInformation = editorInformation,
      moduleLayouts = moduleLayouts.duplicate(false, mapOfOldToNewUuids),
      powerUsageScenario = powerUsageScenario.duplicate(mapOfOldToNewUuids),
      facilityConfiguration = facilityConfiguration.duplicate(mapOfOldToNewUuids),
      assemblyConfiguration = assemblyConfiguration.duplicate(mapOfOldToNewUuids),
      wallboxSelection = wallboxSelection.duplicate(mapOfOldToNewUuids),
      electricityWorkConfiguration = electricityWorkConfiguration.duplicate(mapOfOldToNewUuids),
      additionalPositions = additionalPositions.duplicate(mapOfOldToNewUuids),
    )
  }


  companion object {

    fun getEmpty(
      id: PhotovoltaicsConfigurationId = PhotovoltaicsConfigurationId.random(),
      sellingCompany: PlannerCompanyInformation,
      monitoringCompany: PlannerCompanyInformation,
      editor: UserInformation?,
      location: LocationInformation,
      resolvedModuleLayouts: ResolvedModuleLayouts,
      powerUsageScenario: PowerUsageScenario,
      facilityConfiguration: ResolvedFacilityConfiguration,
      wallboxSelection: ResolvedWallboxSelection,
      assemblyConfiguration: ResolvedAssemblyConfiguration,
      shippingDistanceManual: @km Int?,
      shippingDistanceCalculated: @km Int?,
      electricityWorkConfiguration: ResolvedElectricityWorkConfiguration,
      additionalPositions: ConfigurationItemsConfiguration,
      existingFacilitiesConfiguration: ExistingFacilitiesConfiguration,
      discountPercentage: @pct Double,
      zaehlerNummer: String,
      flurstueckNummer: String,
      facilityOperator1: FacilityOperatorInformation,
      facilityOperator2: FacilityOperatorInformation,
      einspeiseart: Einspeiseart,
      legalNoticeAdditionalLine: String?,
      legalNotes: List<LegalNote>,
      powerConsumptionDistribution: ManualPowerConsumptionDistribution?,
      pricesTrendScenario: PricesTrendScenario,
      loggedInUser: UserLoginName,
      now: @ms Double = nowMillis(),
    ): ResolvedPhotovoltaicsConfiguration {
      return ResolvedPhotovoltaicsConfiguration(
        configurationId = id,
        label = "",
        description = "",
        creationTime = now,
        sellingCompanyInformation = sellingCompany,
        monitoringCompanyInformation = monitoringCompany,
        editorInformation = editor,
        location = location,
        moduleLayouts = resolvedModuleLayouts,
        powerUsageScenario = powerUsageScenario,
        facilityConfiguration = facilityConfiguration,
        wallboxSelection = wallboxSelection,
        financing = EquityFinanced,
        assemblyConfiguration = assemblyConfiguration,
        shippingDistanceManual = shippingDistanceManual,
        shippingDistanceCalculated = shippingDistanceCalculated,
        electricityWorkConfiguration = electricityWorkConfiguration,
        additionalPositions = additionalPositions,
        existingFacilitiesConfiguration = existingFacilitiesConfiguration,
        discountPercentage = discountPercentage,
        zaehlerNummer = zaehlerNummer,
        flurstueckNummer = flurstueckNummer,
        facilityOperator1 = facilityOperator1,
        facilityOperator2 = facilityOperator2,
        einspeiseart = einspeiseart,
        legalNoticeAdditionalLine = legalNoticeAdditionalLine,
        legalNotes = legalNotes,
        manualPowerConsumptionDistribution = powerConsumptionDistribution,
        pricesTrendScenario = pricesTrendScenario,
        yearlyCosts = YearlyCostInformation(),
        earningsDistribution = ResolvedEarningsDistribution.getEmpty(defaultCompany = sellingCompany.companyProfile, loggedInUser = loggedInUser, now = now),
        manualQuoteElements = ManualQuoteElements.empty,
        lifeCycleState = LifeCycleState.Active,
      )
    }

  }

}
