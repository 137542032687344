package it.neckar.lizergy.model.stumps

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.project.ProjectConfiguration
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable

@Serializable
data class FinalAccount(
  @Serializable(with = UuidSerializer::class) override val uuid: Uuid,
  override val projectId: ProjectConfiguration.PhotovoltaicsProjectId,
) : ProjectStump
