package components.project

import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.price.AvailableProducts


fun AvailableProducts.validRoofTileTypes(currentRoofTile: RoofTile): List<RoofTile> {
  return availableRoofTiles().ensureElementInList(currentRoofTile)
}

fun AvailableProducts.validModuleTypes(currentModuleType: PvModule): List<PvModule> {
  return availableModules().ensureElementInList(currentModuleType)
}

fun AvailableProducts.validInverterTypes(currentInverters: Collection<Inverter>): List<Inverter> {
  return availableInverters().plus(currentInverters).distinct()
}

fun AvailableProducts.validWallBoxes(currentWallboxes: Collection<Wallbox>): List<Wallbox> {
  return availableWallBoxes().plus(currentWallboxes).distinct()
}

fun AvailableProducts.validRoofTypes(currentRoofType: RoofType): List<RoofType> {
  return availableRoofTypes().ensureElementInList(currentRoofType)
}

fun AvailableProducts.validMetalRoofTiles(currentMetalRoofTile: MetalRoofTile): List<MetalRoofTile> {
  return availableMetalRoofTiles().ensureElementInList(currentMetalRoofTile)
}

fun AvailableProducts.validMetalRoofTileColors(currentMetalRoofTileColor: MetalRoofTileColor): List<MetalRoofTileColor> {
  return availableMetalRoofTileColors().ensureElementInList(currentMetalRoofTileColor)
}

fun AvailableProducts.validAssemblyDifficulties(currentAssemblyDifficulty: AssemblyDifficulty): List<AssemblyDifficulty> {
  return availableAssemblyDifficulties().ensureElementInList(currentAssemblyDifficulty)
}

fun AvailableProducts.validBatteryConfigurations(currentBatteryConfiguration: BatteryConfiguration?): List<BatteryConfiguration> {
  return availableBatteryConfigurations().ensureElementInList(currentBatteryConfiguration)
}


internal fun <T> List<T>.ensureElementInList(current: T?): List<T> {
  return this.plus(current).filterNotNull().distinct()
}

internal fun <T> List<T>.ensureElementsInList(vararg current: T?): List<T> {
  return this.plus(current).filterNotNull().distinct()
}
