@file:UseSerializers(UuidSerializer::class)

package it.neckar.financial.quote

import com.benasher44.uuid.Uuid
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * The source objects/IDs that have been used to create a quote
 */
@Serializable
data class QuoteSources(
  /**
   * The version numbers of the software that has been used to create the quote
   */
  val softwareVersion: String,

  /**
   * The price list that has been used to create this quote
   */
  val basePriceListId: Uuid,
)
