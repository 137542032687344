package components.project.configuration

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry.QuoteConfirmationProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteOfferProcessStateEntry.QuoteOfferProcessStates
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.open.time.nowMillis
import it.neckar.processStates.current
import it.neckar.processStatesClient.SendProcessStatesTuple
import it.neckar.react.common.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import services.UiActions
import services.http.PlannerUiServices
import store.hooks.useLoadProcessStates
import store.hooks.useRequireLoggedInUser

fun RBuilder.quoteOfferPreviewButton(
  project: ResolvedProject,
  quoteConfiguration: QuoteConfiguration,
): Unit = child(QuoteOfferPreviewButton) {
  attrs {
    this.project = project
    this.quoteConfiguration = quoteConfiguration
  }
}


val QuoteOfferPreviewButton: FC<QuoteDownloadButtonsProps> = fc("QuoteOfferPreviewButton") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val currentQuoteSnapshot = quoteConfiguration.currentQuoteSnapshot

  if (currentQuoteSnapshot != null) {
    a(href = PlannerUiServices.urlSupport.pdfPreviewService.quoteOfferPreview(project.projectId, quoteConfiguration.configurationId, currentQuoteSnapshot.id).value, target = "_blank", classes = "btn btn-secondary") {
      span("pe-2") { i(FontAwesomeIcons.eye) {} }
      b { +"Vorschau AN" }
    }
  } else {
    a(href = PlannerUiServices.urlSupport.pdfPreviewService.quoteOfferPreviewCurrent(project.projectId, quoteConfiguration.configurationId).value, target = "_blank", classes = "btn btn-secondary") {
      span("pe-2") { i(FontAwesomeIcons.eye) {} }
      b { +"Vorschau AN" }
    }
  }
}


/**
 * Creates the download buttons for a quote
 */
fun RBuilder.quoteDownloadButtons(
  project: ResolvedProject,
  quoteConfiguration: QuoteConfiguration,
): Unit = child(QuoteDownloadButtons) {
  attrs {
    this.project = project
    this.quoteConfiguration = quoteConfiguration
  }
}

val QuoteDownloadButtons: FC<QuoteDownloadButtonsProps> = fc("QuoteDownloadButtons") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val allProcessStatesForComponents = useLoadProcessStates()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val currentQuoteSnapshot = quoteConfiguration.currentQuoteSnapshot

  val (downloadOfferBusy, setDownloadOfferBusy) = useState(false)
  val (downloadConfirmationBusy, setDownloadConfirmationBusy) = useState(false)


  div("row") {

    div("btn-group col my-2") {

      quoteOfferPreviewButton(project, quoteConfiguration)

      actionButtonWithConfirmationModal(
        icon = if (downloadOfferBusy) "spinner-border spinner-border-sm" else FontAwesomeIcons.download,
        buttonClasses = "btn btn-primary",
        buttonContent = {
          b("ms-2") {
            +"Neues AN"
          }
        },
        modalTitle = "Soll ein neues Angebot erstellt werden?",
        modalContent = {
          p {
            +"Zusätzlich zur Ausgabe der PDF wird der Status dieses Angebots automatisch als '${QuoteOfferProcessStates.Sent.format(plannerI18nConfiguration)}' gesetzt."
          }

          p {
            +"Das Angebot kann dann nicht weiter bearbeitet werden (es kann jedoch noch kopiert werden und die Kopie kann normal weiter bearbeitet werden) und alle dazugehörigen Preise werden eingefroren."
          }
        },
      ) {
        setDownloadOfferBusy(true)
        val quoteOfferSnapshot = quoteConfiguration.toQuoteSnapshot(
          projectId = project.projectId,
          customer = project.customer,
          maintainer = project.maintainerInformation,
        )

        try {
          UiActions.addQuoteSnapshot(project.projectId, quoteConfiguration, quoteOfferSnapshot)
        } finally {
          try {
            //Download the newly created quote offer snapshot
            UiActions.downloadQuoteOfferPdf(project.projectId, quoteConfiguration, quoteOfferSnapshot.id)
          } finally {
            val newProcessStateEntry = QuoteOfferProcessStates.Sent.toProcessStateEntry(user = loggedInUser, assignedAt = nowMillis(), dueDate = null, assignedBy = loggedInUser)
            UiActions.addProcessStates(
              listOf(
                SendProcessStatesTuple(quoteConfiguration.uuid, newProcessStateEntry),
                SendProcessStatesTuple(quoteOfferSnapshot.uuid, newProcessStateEntry),
              )
            )
            setDownloadOfferBusy(false)
          }
        }
      }

    }

    if (currentQuoteSnapshot != null) {

      div("btn-group col my-2") {

        a(href = PlannerUiServices.urlSupport.pdfPreviewService.quoteConfirmationPreview(project.projectId, quoteConfiguration.configurationId, currentQuoteSnapshot.id).value, target = "_blank", classes = "btn btn-secondary") {
          span("pe-2") { i(FontAwesomeIcons.eye) {} }
          b { +"Vorschau AB" }
        }

        actionButtonWithConfirmationModal(
          icon = if (downloadConfirmationBusy) "spinner-border spinner-border-sm" else FontAwesomeIcons.download,
          buttonClasses = "btn btn-primary",
          buttonContent = {
            attrs {
              val currentProcessStateEntry = allProcessStatesForComponents?.get(quoteConfiguration.uuid)?.validProcessStateEntries?.current()
              disabled = allProcessStatesForComponents == null ||
                  (currentProcessStateEntry == null || (currentProcessStateEntry is QuoteConfirmationProcessStateEntry || currentProcessStateEntry.processState == QuoteOfferProcessStates.Accepted).not()) ||
                  project.isEveryOtherQuoteArchived(quoteConfiguration, allProcessStatesForComponents).not()
            }
            b("ms-2") {
              +"Neue AB"
            }
          },
          modalTitle = "Soll eine neue Auftragsbestätigung erstellt werden?",
          modalContent = {
            +"Zusätzlich zur Ausgabe der PDF wird der Status dieses Angebots automatisch als '${QuoteConfirmationProcessStates.Sent.format(plannerI18nConfiguration)}' gesetzt."
          },
        ) {
          setDownloadConfirmationBusy(true)
          try {
            UiActions.downloadOrderConfirmationPdf(project.projectId, quoteConfiguration.configurationId, currentQuoteSnapshot.id)
          } finally {
            UiActions.addProcessState(quoteConfiguration.uuid, QuoteConfirmationProcessStates.Sent.toProcessStateEntry(user = loggedInUser, assignedAt = nowMillis(), dueDate = null, assignedBy = loggedInUser))
            UiActions.addProcessState(currentQuoteSnapshot.uuid, QuoteConfirmationProcessStates.Sent.toProcessStateEntry(user = loggedInUser, assignedAt = nowMillis(), dueDate = null, assignedBy = loggedInUser))
            setDownloadConfirmationBusy(false)
          }
        }

      }

    }

  }
}

external interface QuoteDownloadButtonsProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
}
