@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout.roof

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class RoofTile(
  /**
   * The ID that identifies the tile type
   */
  val id: RoofTileId,

  /**
   * The name / label of the roof tile
   */
  val description: String,

  /**
   * The matching metal roof tile type
   */
  val metalRoofTile: MetalRoofTile,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  /**
   * An id for a roof
   */
  @Serializable
  data class RoofTileId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): RoofTileId {
        return RoofTileId(randomUuid4())
      }
    }
  }
}
