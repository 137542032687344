package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.collections.fastForEach
import store.PlannerClientState

/**
 *  New projects has been added (testing)
 */
data class AddProjectsAction(val projects: List<ResolvedProject>) : StateAction {
  override val type: ChangeType = ChangeType.MajorChange

  override fun PlannerClientState.updateState(): PlannerClientState {
    var updatedProjects = resolvedProjects

    projects.fastForEach { project ->
      updatedProjects = updatedProjects.withUpdated(project)
    }

    return this.copy(resolvedProjects = updatedProjects)
  }
}

