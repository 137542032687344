package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.rest.jwt.JwtTokenPair
import it.neckar.user.UserLoginName
import store.PlannerClientState

/**
 * A user has been logged in
 */
data class LoggedInAction(
  /**
   * The username that has been logged in
   */
  val loginName: UserLoginName,

  /**
   * The password to refresh the JWS token
   */
  val password: String,

  /**
   * The jws tokens
   */
  val jwtTokenPair: JwtTokenPair,

  ) : StateAction {

  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return login(loginName, password, jwtTokenPair)
  }
}
