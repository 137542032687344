package services

import com.benasher44.uuid.Uuid
import com.meistercharts.maps.RouteMapCoordinates
import io.ktor.http.*
import it.neckar.comments.Comment
import it.neckar.common.auth.LoginResponse
import it.neckar.commons.kotlin.js.BlobSupport
import it.neckar.customer.Address
import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.DemoDataFactory
import it.neckar.lizergy.model.DemoProjectFactory
import it.neckar.lizergy.model.assemblyPortfolio.ResolvedAssemblyPortfolio
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.components.ExistingBHKWFacilityConfiguration
import it.neckar.lizergy.model.configuration.components.ExistingPVFacilityConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.configuration.components.ResolvedElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.energy.AmountOfEnergy
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfigurations
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot.QuoteSnapshotId
import it.neckar.lizergy.model.location.GeocodingResponse
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.location.MapCoordinatesInformation
import it.neckar.lizergy.model.location.RouteInformation
import it.neckar.lizergy.model.location.RouteInformationResponse
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.price.lizergy.LizergyPriceListFactory
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.PhotovoltaicsProject
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.project.previews.AccountingProjectPreview
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.lizergy.model.stumps.AssemblyBasement
import it.neckar.lizergy.model.stumps.GridAssessment
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.si.km
import it.neckar.open.unit.time.pa
import it.neckar.processStatesClient.SendProcessStatesTuple
import it.neckar.react.common.*
import it.neckar.react.common.toast.*
import it.neckar.user.UserLoginName
import kotlinx.css.*
import react.router.*
import services.auth.http.AddNewCompanyResponse
import services.auth.http.AddNewUserResponse
import services.auth.http.ChangePasswordResponse
import services.auth.http.ChangeUserInfoResponse
import services.auth.http.ChangedCompanyInfoResponse
import services.auth.http.DeletedUserResponse
import services.http.AccountingProjectQueryResponse
import services.http.AccountingQuerySelection
import services.http.AllAccountingProjectPreviewsAsCSVResponse
import services.http.PlannerUiServices
import services.http.ProjectCountQueryResponse
import services.http.ProjectCountRequestForPhase
import services.http.ProjectQueryResponse
import services.http.ProjectQuerySorting
import store.actions.LogoutReason

/**
 * Handles all UI actions.
 *
 * The idea behind this class:
 * * every (non-trivial) user interaction (usually clicks) results in a method call within [UiActions].
 * * these methods then:
 *    * delegate the work to other services
 *    * update the store - where necessary
 *    * notify the user about updates
 *
 *
 * ## Usage Patterns
 *
 * ### suspend functions
 * These functions return a value that will be useful for the callee (e.g. an ID) that have been stored on the server before.
 *
 * It is necessary to introduce some kind of busy indicator.
 *
 * ### "normal" non-suspending functions
 * This methods can be called directly. They potentially schedule requests to the server.
 *
 */
object UiActions {
  private val workflow: PlannerWorkflow = PlannerWorkflow()

  /**
   * Logs in the user with the given credentials
   */
  suspend fun login(loginName: UserLoginName, password: String) {
    logger.debug("Logging in $loginName")

    delayIfSlowUiEnabled()

    when (workflow.login(loginName, password)) {
      is LoginResponse.Success -> {
        notifySuccess("Login erfolgreich")
      }

      is LoginResponse.Failure -> {
        notifyError("Login fehlgeschlagen", "Falscher Benutzer / Passwort")
      }
    }
  }

  /**
   * Logs the current user out
   */
  fun logout(logoutReason: LogoutReason) {
    workflow.logout(logoutReason)
    notifySuccess("Logout erfolgreich")
  }

  /**
   * Adds a new user
   */
  suspend fun addNewUser(newUser: UserInformation, password: String): UserLoginName? {
    delayIfSlowUiEnabled()
    when (val result: AddNewUserResponse = workflow.addNewUser(newUser, password)) {
      is AddNewUserResponse.Success -> {
        notifySuccess("Nutzer erfolgreich angelegt")

        return result.data
      }

      is AddNewUserResponse.Failure -> {
        val errorMessage = when (result.errorType) {
          AddNewUserResponse.Failure.ErrorType.UserAlreadyExists -> "Benutzer existiert bereits"
          AddNewUserResponse.Failure.ErrorType.InvalidPassword -> "Passwort ist ungültig"
          AddNewUserResponse.Failure.ErrorType.FailToFetch -> "Server nicht erreichbar"
        }

        notifyError("Nutzer konnte nicht angelegt werden", errorMessage)

        return null
      }
    }
  }

  suspend fun changeUserInfo(updatedUserInformation: UserInformation) {
    delayIfSlowUiEnabled()
    when (val result: ChangeUserInfoResponse = workflow.changedUserInfoResult(updatedUserInformation)) {
      is ChangeUserInfoResponse.Success -> {
        Toast.info(
          "Nutzer erfolgreich geändert", options = ToastOptions(
            timeOut = 2000, positionClass = ToastPosition.BOTTOMCENTER
          )
        )
      }

      is ChangeUserInfoResponse.Failure -> {
        val errorMessage = when (result.errorType) {
          ChangeUserInfoResponse.Failure.ErrorType.UserNotFound -> "Benutzer existiert nicht"
          ChangeUserInfoResponse.Failure.ErrorType.FailToFetch -> "Server nicht erreichbar"
        }

        notifyError("Nutzer konnte nicht geändert werden", errorMessage)
      }
    }
  }

  suspend fun deleteUser(user: UserInformation) {
    delayIfSlowUiEnabled()
    when (val result: DeletedUserResponse = workflow.deleteUser(user)) {
      is DeletedUserResponse.Success -> {
        notifySuccess("Nutzer erfolgreich gelöscht")
      }

      is DeletedUserResponse.Failure -> {
        val errorMessage = when (result.errorType) {
          DeletedUserResponse.Failure.ErrorType.UserNotFound -> "Benutzer existiert nicht"
          DeletedUserResponse.Failure.ErrorType.FailToFetch -> "Server nicht erreichbar"
        }

        notifyError("Nutzer konnte nicht gelöscht werden", errorMessage)
      }
    }
  }


  /**
   * Changes the password
   */
  suspend fun changePassword(loginName: UserLoginName, /*oldPassword: String,*/ newPassword1: String, newPassword2: String) {
    //require(oldPassword.isNotBlank()) { "Old password must not be blank" }
    require(newPassword1 == newPassword2) { "Passwords do not match" }

    delayIfSlowUiEnabled()
    when (val result: ChangePasswordResponse = workflow.changePassword(loginName, newPassword1)) {
      is ChangePasswordResponse.Success -> {
        notifySuccess("Passwort erfolgreich geändert")
      }

      is ChangePasswordResponse.Failure -> {
        val errorMessage = when (result.errorType) {
          ChangePasswordResponse.Failure.ErrorType.UserNotFound -> "Benutzer nicht gefunden"
          ChangePasswordResponse.Failure.ErrorType.InvalidOldPassword -> "Altes Passwort ist ungültig"
          ChangePasswordResponse.Failure.ErrorType.FailToFetch -> "Server nicht erreichbar"
        }

        notifyError("Passwort konnte nicht geändert werden", errorMessage)
      }
    }
  }

  fun createRandomSampleProject(
    loggedInUser: UserInformation,
    availableProducts: AvailableProducts,
    priceList: PriceList,
    count: Int,
  ): List<ResolvedProject> {

    val resolvedConfiguration = DemoDataFactory.createConfiguration(
      yearlyPowerConsumption = PowerUsageScenario.typical().yearlyPowerConsumption,
      creationTime = nowMillis()
    )
    val projects = List(count) {
      DemoProjectFactory.createDemoProject(
        availableProducts = availableProducts,
        priceList = priceList,
        maintainer = UserInformation.createStubUser(UserLoginName("demo")),
        customer = Customer.random(),
        resolvedConfigurations = listOf(resolvedConfiguration),
        blueprint = DemoDataFactory.createBlueprint(
          moduleType = availableProducts.availableModules().random(),
          roofType = availableProducts.availableRoofTypes().random(),
          assemblyDifficulty = priceList.defaultAssemblyDifficulty,
          acquisitionDate = nowMillis()
        )
      )
    }
    return workflow.createRandomSampleProjects(
      coroutineScope = AppScope,
      loggedInUser = loggedInUser,
      resolvedProjects = projects,
    )

  }


  /**
   * Creates a list of sample projects [ResolvedProject]
   * quote: https://spielwiese.pv.neckar.it/projects/quotes/KsSpVgp1TmmIAPaDkpuTLA?featureFlags=disableVersionCheck
   * presentation: https://spielwiese.pv.neckar.it/projects/presentations/9r_fUGzCRii_H05HXTSlsQ?featureFlags=disableVersionCheck
   * blueprint: https://spielwiese.pv.neckar.it/projects/blueprints/76m3iUZPSIiC8rtpW1kejA?featureFlags=disableVersionCheck
   */
  fun createSampleProjects(
    loggedInUser: UserInformation,
    availableProducts: AvailableProducts,
    priceList: PriceList,
  ): List<ResolvedProject> {

    val resolvedProjects = listOf(
      DemoProjectFactory.createDemoProject(
        availableProducts = availableProducts,
        priceList = priceList,
        customer = Customer(
          id = Customer.CustomerId.random(),
          firstName = "Marc",
          lastName = "Kraibühler",
          Address.randomAddresses.first(),
          company = "Wasserwerk Brandeck"
        ),
        maintainer = UserInformation.createStubUser(UserLoginName("demo")),
        resolvedConfigurations = listOf(
          DemoDataFactory.createDemoQuotesConfiguration(
            label = "PV-Anlage mit 5,95 kWp",
            assemblyDifficulty = LizergyPriceListFactory.createPriceList().availableAssemblyDifficulties().first(),
            loggedInUser = loggedInUser
          ),
          DemoDataFactory.createDemoQuotesConfiguration(
            label = "PV-Anlage mit 5,95 kWp, Batterie 12kWh",
            assemblyDifficulty = LizergyPriceListFactory.createPriceList().availableAssemblyDifficulties().first(),
            loggedInUser = loggedInUser,
            batteryConfiguration = availableProducts.availableBatteryConfigurations().find {
              it.battery.id.uuid.toString() == "f98ed12c-0854-4159-be4c-76d324b25275"
            },
          )
        ),
        blueprint = DemoDataFactory.createBlueprint(
          sellingCompany = PlannerCompanyInformation.createDemoCompany(CompanyCode.Lizergy),
          powerUsageScenario = PowerUsageScenario(
            yearlyPowerConsumption = @pa AmountOfEnergy(16000),
            waermepumpeConsumption = @pa AmountOfEnergy(0),
            electricCarUsage = @km 0.0,
            override = null
          ),
          electricityWorkConfiguration = ResolvedElectricityWorkConfiguration.getEmpty(),
          pricesTrendScenario = PricesTrendScenario.demoQuote(),
          location = LocationInformation.demo,
          loggedInUser = loggedInUser.loginName,
          moduleType = availableProducts.availableModules().random(),
          roofType = availableProducts.availableRoofTypes().random(),
          assemblyDifficulty = priceList.defaultAssemblyDifficulty,
          acquisitionDate = nowMillis()
        ),
      ),
      DemoProjectFactory.createDemoProject(
        availableProducts = availableProducts,
        priceList = priceList,
        maintainer = UserInformation.createStubUser(UserLoginName("demo")),
        customer = Customer(
          id = Customer.CustomerId.random(),
          firstName = "Nadine und Thomas",
          lastName = "Paternoga",
          Address.randomAddresses.last(),
          customerWish = "Kunde von Alfred Maier\n" +
            "Hat bereits das Minimum gekauft. Jetzt geht es darum zu prüfen ob eine größere Dachbelegung samt Speicher sinnvoll wäre."
        ),
        resolvedConfigurations = listOf(
          DemoDataFactory.createDemoPresentationConfiguration(
            label = "PV-Anlage mit 6,48 kWp",
            assemblyDifficulty = LizergyPriceListFactory.createPriceList().availableAssemblyDifficulties().first(),
            loggedInUser = loggedInUser
          ),
          DemoDataFactory.createDemoPresentationAdditionalConfiguration(
            label = "PV-Anlage mit 14,03 kWp, Batterie 7,7 kWh",
            assemblyDifficulty = LizergyPriceListFactory.createPriceList().defaultAssemblyDifficulty,
            loggedInUser = loggedInUser,
            batteryConfiguration = availableProducts.availableBatteryConfigurations().find {
              it.battery.id.uuid.toString() == "b7e8ce1f-552b-48e0-a49e-97f01e223f3c"
            },
          )
        ),
        blueprint = DemoDataFactory.createBlueprint(
          assemblyDifficulty = availableProducts.availableAssemblyDifficulties().first(),
          pricesTrendScenario = PricesTrendScenario.demoPresentation(),
          facilityOperator1 = FacilityOperatorInformation(name = null, birthday = null),
          facilityOperator2 = FacilityOperatorInformation(name = null, birthday = null),
        )
      ),
      DemoProjectFactory.createDemoProject(
        availableProducts = availableProducts,
        priceList = priceList,
        maintainer = UserInformation.createStubUser(UserLoginName("demo")),
        customer = Customer(
          id = Customer.CustomerId.random(),
          address = Address.randomAddresses[1],
          firstName = "Jürgen",
          lastName = "Hermann",
          phone = "+49 0000 0000",
          email = "demo@test.com",
          customerWish = "Anfrage PV-Anlage"
        ),
        resolvedConfigurations = emptyList(),
        blueprint = DemoDataFactory.createDemoBlueprint(),
        currentQuoteSnapshot = null // TODO nicht null benutzen?
      )
    )

    return workflow.createSampleProjects(
      coroutineScope = AppScope,
      loggedInUser = loggedInUser,
      resolvedProjects = resolvedProjects,
    )
  }

  /**
   * Adds a new company. Returns the company code if the company has been created successfully
   */
  suspend fun addNewCompany(newCompany: PlannerCompanyInformation): CompanyCode? {
    delayIfSlowUiEnabled()
    when (val result = workflow.addNewCompany(newCompany)) {
      is AddNewCompanyResponse.Success -> {
        notifySuccess("Firma erfolgreich angelegt")

        return result.data
      }

      is AddNewCompanyResponse.Failure -> {
        val errorMessage = when (result.errorType) {
          AddNewCompanyResponse.Failure.ErrorType.CompanyAlreadyExists -> "Firma existiert bereits"
          AddNewCompanyResponse.Failure.ErrorType.FailToFetch -> "Server nicht erreichbar"
        }

        notifyError("Firma konnte nicht angelegt werden", errorMessage)
        return null
      }
    }
  }

  suspend fun changeCompanyInfo(updatedCompanyInformation: PlannerCompanyInformation) {
    delayIfSlowUiEnabled()
    when (val result: ChangedCompanyInfoResponse = workflow.changedCompanyInfo(updatedCompanyInformation)) {
      is ChangedCompanyInfoResponse.Success -> {
        notifySuccess("Firma erfolgreich geändert")
      }

      is ChangedCompanyInfoResponse.Failure -> {
        val errorMessage = when (result.errorType) {
          ChangedCompanyInfoResponse.Failure.ErrorType.CompanyNotFound -> "Firma existiert nicht"
          ChangedCompanyInfoResponse.Failure.ErrorType.FailToFetch -> "Server nicht erreichbar"
        }

        notifyError("Firma konnte nicht geändert werden", errorMessage)
      }
    }
  }

  fun queryProjectPreviews(
    processStatesToFilter: List<LizergyProcessStates>,
    processStatesToHide: List<LizergyProcessStates>,
    filteredByCompanyCode: CompanyCode?,
    filteredByMaintainer: UserLoginName?,
    filteredByEditor: UserLoginName?,
    filterValueProject: String?,
    filterValueAddress: String?,
    filterByProjectState: LizergyProcessStates?,
    indexOfFirstVisibleProject: Int,
    indexOfLastVisibleProject: Int,
    sorting: ProjectQuerySorting,
    projectQueryComponent: ProjectQueryComponent?,
    includeArchived: Boolean,
    projectQueryCallback: (ProjectQueryResponse.Success?) -> Unit,
  ) {
    launchAndNotify {
      val projectQueryResponse = workflow.queryProjectPreviews(
        processStatesToFilter = processStatesToFilter,
        processStatesToHide = processStatesToHide,
        filteredByCompanyCode = filteredByCompanyCode,
        filteredByMaintainer = filteredByMaintainer,
        filteredByEditor = filteredByEditor,
        filterValueProject = filterValueProject,
        filterValueAddress = filterValueAddress,
        filterByProjectState = filterByProjectState,
        indexOfFirstVisibleProject = indexOfFirstVisibleProject,
        indexOfLastVisibleProject = indexOfLastVisibleProject,
        sorting = sorting,
        projectQueryComponent = projectQueryComponent,
        includeArchived = includeArchived,
      )

      when (projectQueryResponse) {
        is ProjectQueryResponse.Success -> projectQueryResponse
        ProjectQueryResponse.Failure -> null
      }.let {
        projectQueryCallback(it)
      }
    }
  }

  fun countProjectPreviews(
    requestedProjectCountsForPhases: List<ProjectCountRequestForPhase>,
    loggedInUser: UserLoginName,
    projectQueryCallback: (ProjectCountQueryResponse.Success?) -> Unit,
  ) {
    launchAndNotify {
      val projectCountQueryResponse = workflow.countProjectPreviews(
        requestedProjectCountsForPhases = requestedProjectCountsForPhases,
        loggedInUser = loggedInUser,
      )

      when (projectCountQueryResponse) {
        is ProjectCountQueryResponse.Success -> projectCountQueryResponse
        ProjectCountQueryResponse.Failure -> null
      }.let {
        projectQueryCallback(it)
      }
    }
  }

  fun accountingQueryProjectPreviews(
    processStatesToFilter: List<LizergyProcessStates>,
    processStatesToHide: List<LizergyProcessStates>,
    accountingQuerySelection: AccountingQuerySelection,
    filteredByCompanyCode: CompanyCode?,
    filteredByMaintainer: UserLoginName?,
    filteredByEditor: UserLoginName?,
    filterValueProject: String?,
    filterValueAddress: String?,
    filterByProjectState: LizergyProcessStates?,
    indexOfFirstVisibleProject: Int,
    indexOfLastVisibleProject: Int,
    sorting: ProjectQuerySorting,
    projectQueryComponent: ProjectQueryComponent?,
    howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements,
    loggedInUser: UserLoginName,
    projectQueryCallback: (AccountingProjectQueryResponse.Success?) -> Unit,
  ) {
    launchAndNotify {
      val projectQueryResponse = workflow.accountingProjectPreviews(
        processStatesToFilter = processStatesToFilter,
        processStatesToHide = processStatesToHide,
        accountingQuerySelection = accountingQuerySelection,
        filteredByCompanyCode = filteredByCompanyCode,
        filteredByMaintainer = filteredByMaintainer,
        filteredByEditor = filteredByEditor,
        filterValueProject = filterValueProject,
        filterValueAddress = filterValueAddress,
        filterByProjectState = filterByProjectState,
        indexOfFirstVisibleProject = indexOfFirstVisibleProject,
        indexOfLastVisibleProject = indexOfLastVisibleProject,
        sorting = sorting,
        projectQueryComponent = projectQueryComponent,
        howDoYouLikeYourQuoteElements = howDoYouLikeYourQuoteElements,
        loggedInUser = loggedInUser,
      )

      when (projectQueryResponse) {
        is AccountingProjectQueryResponse.Success -> projectQueryResponse
        AccountingProjectQueryResponse.Failure -> null
      }.let {
        projectQueryCallback(it)
      }
    }
  }

  fun allAccountingProjectPreviewsAsCSV(projectQueryCallback: (AllAccountingProjectPreviewsAsCSVResponse.Success?) -> Unit) {
    launchAndNotify {
      val projectQueryResponse = workflow.allAccountingProjectPreviewsAsCSV()

      when (projectQueryResponse) {
        is AllAccountingProjectPreviewsAsCSVResponse.Success -> projectQueryResponse
        AllAccountingProjectPreviewsAsCSVResponse.Failure -> null
      }.let {
        projectQueryCallback(it)
      }
    }
  }

  /**
   * Creates a new [ResolvedProject]
   * Writing to the server is scheduled later.
   */
  fun createProjectForCustomer(customer: Customer, maintainer: UserInformation, blueprint: ResolvedBlueprint, loggedInUser: UserInformation): ResolvedProject {
    return workflow.createProjectForCustomerOptimistic(AppScope, customer, maintainer, blueprint, loggedInUser = loggedInUser)
  }

  /**
   * Saves the [ResolvedProject]
   */
  fun saveProject(updatedProject: ResolvedProject) {
    workflow.saveProject(AppScope, updatedProject)
  }

  fun archiveProject(project: PhotovoltaicsProject, archiveReasons: ArchiveReasons, loggedInUser: UserInformation) {
    workflow.archiveProjectOptimistic(AppScope, project.projectId, archiveReasons, loggedInUser.loginName)
    workflow.addProcessState(
      coroutineScope = AppScope,
      processStateFor = project.uuid,
      newProcessState = ProjectProcessStates.Archived.toProcessStateEntry(
        user = loggedInUser,
        dueDate = null,
        assignedAt = nowMillis(),
        assignedBy = loggedInUser,
      ),
    )

    notifySuccess("Project archiviert")
  }

  /**
   * Saves the [ResolvedBlueprint]
   */
  fun saveBlueprint(blueprint: ResolvedBlueprint) {
    workflow.saveBlueprint(AppScope, blueprint)
  }

  fun saveVerification(project: ResolvedProject, verification: Verification) {
    workflow.saveVerification(AppScope, project, verification)
  }

  fun deleteVerification(project: ResolvedProject) {
    workflow.deleteVerification(AppScope, project)
  }

  /**
   * Converts the [ResolvedBlueprint] of a [ResolvedProject] to [QuoteConfigurations]
   */
  fun resolveBlueprintToConfigurations(project: ResolvedProject, assignedTo: UserLoginName, belongsTo: CompanyCode, availableProducts: AvailableProducts, priceList: PriceList, companyResolver: CompanyResolver, loggedInUser: UserInformation) {
    workflow.resolveBlueprintToQuoteConfigurations(AppScope, project.blueprint, assignedTo, belongsTo, availableProducts, priceList, companyResolver, loggedInUser)
  }

  /**
   * Adds a new [QuoteConfiguration] to the given [ResolvedProject]
   */
  fun addQuoteConfiguration(project: ResolvedProject, availableProducts: AvailableProducts, priceList: PriceList, companyResolver: CompanyResolver, loggedInUser: UserInformation): QuoteConfiguration {
    return workflow.addQuoteConfiguration(AppScope, project, availableProducts, priceList, companyResolver, loggedInUser)
  }

  /**
   * Duplicates the given [QuoteConfiguration]
   */
  fun duplicateQuoteConfiguration(project: ResolvedProject, quoteConfiguration: QuoteConfiguration, loggedInUser: UserInformation): QuoteConfiguration {
    return workflow.duplicateQuoteConfiguration(AppScope, project, quoteConfiguration, loggedInUser)
  }

  fun removeQuoteConfiguration(project: ResolvedProject, quoteConfiguration: QuoteConfiguration) {
    workflow.removeQuoteConfiguration(AppScope, project, quoteConfiguration)
  }

  fun saveQuoteConfiguration(project: ResolvedProject, quoteConfiguration: QuoteConfiguration) {
    workflow.saveQuoteConfiguration(AppScope, project, quoteConfiguration)
  }

  /**
   * Adds a new [ResolvedModuleLayout] to the [ResolvedBlueprint]
   */
  fun addModuleLayout(blueprint: ResolvedBlueprint, availableProducts: AvailableProducts): ResolvedModuleLayout {
    return workflow.addModuleLayout(AppScope, blueprint, availableProducts)
  }

  /**
   * Adds a new [ResolvedModuleLayout] to the [QuoteConfiguration]
   */
  fun addModuleLayout(project: ResolvedProject, quoteConfiguration: QuoteConfiguration, availableProducts: AvailableProducts): ResolvedModuleLayout {
    return workflow.addModuleLayout(AppScope, project, quoteConfiguration, availableProducts)
  }

  /**
   * Duplicates the given [ResolvedModuleLayout] for the [ResolvedBlueprint]
   * Sets the opposite orientation for the [ResolvedModuleLayout]
   */
  fun duplicateModuleLayout(blueprint: ResolvedBlueprint, moduleLayout: ResolvedModuleLayout): ResolvedModuleLayout {
    return workflow.duplicateModuleLayout(AppScope, blueprint, moduleLayout)
  }

  /**
   * Duplicates the given [ResolvedModuleLayout] for the [QuoteConfiguration]
   * Sets the opposite orientation for the [ResolvedRoof]
   */
  fun duplicateModuleLayout(project: ResolvedProject, quoteConfiguration: QuoteConfiguration, moduleLayout: ResolvedModuleLayout): ResolvedModuleLayout {
    return workflow.duplicateModuleLayout(AppScope, project, quoteConfiguration, moduleLayout)
  }

  fun saveModuleLayout(blueprint: ResolvedBlueprint, moduleLayout: ResolvedModuleLayout) {
    workflow.saveModuleLayout(AppScope, blueprint, moduleLayout)
  }

  fun saveModuleLayout(project: ResolvedProject, quoteConfiguration: QuoteConfiguration, moduleLayout: ResolvedModuleLayout) {
    workflow.saveModuleLayout(AppScope, project, quoteConfiguration, moduleLayout)
  }

  /**
   * Removes the [ResolvedModuleLayout] from the [ResolvedBlueprint]
   */
  fun removeModuleLayout(blueprint: ResolvedBlueprint, moduleLayout: ResolvedModuleLayout) {
    workflow.removeModuleLayout(AppScope, blueprint, moduleLayout)
  }

  /**
   * Removes the [ResolvedModuleLayout] from the [QuoteConfiguration]
   */
  fun removeModuleLayout(project: ResolvedProject, quoteConfiguration: QuoteConfiguration, moduleLayout: ResolvedModuleLayout) {
    workflow.removeModuleLayout(AppScope, project, quoteConfiguration, moduleLayout)
  }

  /**
   * Adds a [ConfigurationItem] to the [ResolvedBlueprint]
   */
  fun addAdditionalPositionToBlueprint(blueprint: ResolvedBlueprint): ConfigurationItem {
    return workflow.addAdditionalPositionToBlueprint(AppScope, blueprint)
  }

  /**
   * Adds a [ConfigurationItem] to the [QuoteConfiguration]
   */
  fun addAdditionalPositionToConfiguration(project: ResolvedProject, quoteConfiguration: QuoteConfiguration): ConfigurationItem {
    return workflow.addAdditionalPositionToConfiguration(AppScope, project, quoteConfiguration)
  }

  /**
   * Removes the given additional [ConfigurationItem] from the quote [ResolvedBlueprint]
   */
  fun removeAdditionalPositionFromBlueprint(blueprint: ResolvedBlueprint, toDelete: ConfigurationItem) {
    workflow.removeAdditionalPositionFromBlueprint(AppScope, blueprint, toDelete)
  }

  /**
   * Removes the given additional [ConfigurationItem] from the quote [QuoteConfiguration]
   */
  fun removeAdditionalPositionFromConfiguration(project: ResolvedProject, quoteConfiguration: QuoteConfiguration, toDelete: ConfigurationItem) {
    workflow.removeAdditionalPositionFromConfiguration(AppScope, project, quoteConfiguration, toDelete)
  }


  fun addExistingPVFacilityToBlueprint(blueprint: ResolvedBlueprint): ExistingPVFacilityConfiguration {
    return workflow.addExistingPVFacilityToBlueprint(AppScope, blueprint)
  }

  fun addExistingBHKWFacilityToBlueprint(blueprint: ResolvedBlueprint): ExistingBHKWFacilityConfiguration {
    return workflow.addExistingBHKWFacilityToBlueprint(AppScope, blueprint)
  }

  fun addExistingPVFacilityToConfiguration(project: ResolvedProject, quoteConfiguration: QuoteConfiguration): ExistingPVFacilityConfiguration {
    return workflow.addExistingPVFacilityToConfiguration(AppScope, project, quoteConfiguration)
  }

  fun addExistingBHKWFacilityToConfiguration(project: ResolvedProject, quoteConfiguration: QuoteConfiguration): ExistingBHKWFacilityConfiguration {
    return workflow.addExistingBHKWFacilityToConfiguration(AppScope, project, quoteConfiguration)
  }

  fun removeExistingPVFacilityFromBlueprint(blueprint: ResolvedBlueprint, toDelete: ExistingPVFacilityConfiguration) {
    workflow.removeExistingPVFacilityFromBlueprint(AppScope, blueprint, toDelete)
  }

  fun removeExistingBHKWFacilityFromBlueprint(blueprint: ResolvedBlueprint, toDelete: ExistingBHKWFacilityConfiguration) {
    workflow.removeExistingBHKWFacilityFromBlueprint(AppScope, blueprint, toDelete)
  }

  fun removeExistingPVFacilityFromConfiguration(project: ResolvedProject, quoteConfiguration: QuoteConfiguration, toDelete: ExistingPVFacilityConfiguration) {
    workflow.removeExistingPVFacilityFromConfiguration(AppScope, project, quoteConfiguration, toDelete)
  }

  fun removeExistingBHKWFacilityFromConfiguration(project: ResolvedProject, quoteConfiguration: QuoteConfiguration, toDelete: ExistingBHKWFacilityConfiguration) {
    workflow.removeExistingBHKWFacilityFromConfiguration(AppScope, project, quoteConfiguration, toDelete)
  }

  /**
   * Saves the [AssemblyBasement]
   */
  fun saveAssemblyBasement(updatedAssemblyBasement: AssemblyBasement) {
    workflow.saveAssemblyBasement(AppScope, updatedAssemblyBasement)
  }

  /**
   * Saves the [GridAssessment]
   */
  fun saveGridAssessment(updatedGridAssessment: GridAssessment) {
    workflow.saveGridAssessment(AppScope, updatedGridAssessment)
  }

  /**
   * Saves the [ResolvedAssemblyPortfolio]
   */
  fun saveAssemblyPortfolio(updatedAssemblyPortfolio: ResolvedAssemblyPortfolio) {
    workflow.saveAssemblyPortfolio(AppScope, updatedAssemblyPortfolio)
  }


  fun coordinatesForAddress(address: Address, coordinatesCallback: (MapCoordinatesInformation?) -> Unit) {
    launchAndNotify {
      when (val geocodingResult = PlannerUiServices.geoInformationService.lookupCoordinates(address)) {
        is GeocodingResponse.Success -> geocodingResult.data
        GeocodingResponse.Failure -> null
      }.let { coordinatesCallback(it) }
    }
  }

  fun routeInformationForCoordinates(route: RouteMapCoordinates, routeInformationCallback: (RouteInformation?) -> Unit) {
    launchAndNotify {
      val routeInformation = when (val routeInformationResult = PlannerUiServices.geoInformationService.lookupRoute(route)) {
        is RouteInformationResponse.Success -> routeInformationResult.data
        RouteInformationResponse.Failure -> null
      }

      routeInformationCallback(routeInformation)
    }
  }


  /**
   * Adds and saves the [QuoteSnapshot] to the project
   */
  fun addQuoteSnapshot(projectId: PhotovoltaicsProjectId, quoteConfiguration: QuoteConfiguration, quoteSnapshot: QuoteSnapshot): QuoteSnapshot {
    return workflow.addQuoteSnapshot(AppScope, projectId, quoteConfiguration, quoteSnapshot)
  }

  fun removeQuoteSnapshot(projectId: PhotovoltaicsProjectId, quoteConfiguration: QuoteConfiguration, quoteSnapshot: QuoteSnapshot) {
    return workflow.removeQuoteSnapshot(AppScope, projectId, quoteConfiguration, quoteSnapshot)
  }


  /**
   * Previews the quote offer
   */
  suspend fun previewQuoteOfferPdf(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    snapshotId: QuoteSnapshotId,
  ) {
    executeAndNotify("Angebots-Vorschau erstellt") {
      val pdfReport = PlannerUiServices.pdfReportService.downloadQuoteOfferPdfForSnapshot(projectId, configurationId, snapshotId)
      val blob = BlobSupport.createBlob(pdfReport, ContentType.Application.Pdf)

      BlobSupport.showBlobInNewWindow(blob)
    }
  }

  suspend fun previewQuoteOfferPdfForCurrent(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
  ) {
    executeAndNotify("Angebots-Vorschau erstellt") {
      val pdfReport = PlannerUiServices.pdfReportService.downloadQuoteOfferPdfForCurrent(projectId, configurationId)
      val blob = BlobSupport.createBlob(pdfReport, ContentType.Application.Pdf)

      BlobSupport.showBlobInNewWindow(blob)
    }
  }

  /**
   * Downloads the pdf for the quote offer
   * //TODO what is the difference to preview?
   */
  suspend fun downloadQuoteOfferPdf(
    projectId: PhotovoltaicsProjectId,
    quoteConfiguration: QuoteConfiguration,
    quoteSnapshotId: QuoteSnapshotId,
  ) {
    executeAndNotify("Angebot erstellt") {
      val pdfReport = PlannerUiServices.pdfReportService.downloadQuoteOfferPdfForSnapshot(projectId, quoteConfiguration.configurationId, quoteSnapshotId)
      val blob = BlobSupport.createBlob(pdfReport, ContentType.Application.Pdf)

      //@Suppress("UNUSED_PARAMETER")
      //val fileName = "AN ${quoteSnapshot.quote.title.encodeForFileName()}.pdf"

      BlobSupport.showBlobInNewWindow(blob)
    }
  }

  suspend fun downloadQuoteOfferPdfForCurrent(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
  ) {
    executeAndNotify("Angebot erstellt") {
      val pdfReport = PlannerUiServices.pdfReportService.downloadQuoteOfferPdfForCurrent(projectId, configurationId)
      val blob = BlobSupport.createBlob(pdfReport, ContentType.Application.Pdf)

      //@Suppress("UNUSED_PARAMETER")
      //val fileName = "AN ${quoteSnapshot.quote.title.encodeForFileName()}.pdf"

      BlobSupport.showBlobInNewWindow(blob)
    }
  }

  /**
   * Previews the quote confirmation
   */
  suspend fun previewQuoteConfirmationPdf(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    snapshotId: QuoteSnapshotId,
  ) {
    executeAndNotify("Auftragsbestätigung-Vorschau erstellt") {
      val pdfReport = PlannerUiServices.pdfReportService.downloadOrderConfirmationPdf(projectId, configurationId, snapshotId)
      val blob = BlobSupport.createBlob(pdfReport, ContentType.Application.Pdf)

      BlobSupport.showBlobInNewWindow(blob)
    }
  }

  /**
   * Downloads the pdf for the quote confirmation
   */
  suspend fun downloadOrderConfirmationPdf(
    //TOODO what is the difference to preview?
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    snapshotId: QuoteSnapshotId,
  ) {
    executeAndNotify("Auftragsbestätigung erstellt") {
      val pdfReport = PlannerUiServices.pdfReportService.downloadOrderConfirmationPdf(projectId, configurationId, snapshotId)
      val blob = BlobSupport.createBlob(pdfReport, ContentType.Application.Pdf)
      //val fileName = "AB ${quoteSnapshot.quote.title.encodeForFileName()}.pdf"

      BlobSupport.showBlobInNewWindow(blob)
    }
  }

  suspend fun downloadAssemblyPortfolioPdf(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
  ) {
    executeAndNotify("Montageportfolio erstellt") {
      val pdfReport = PlannerUiServices.pdfReportService.downloadAssemblyPortfolioPdf(projectId, configurationId)
      val blob = BlobSupport.createBlob(pdfReport, ContentType.Application.Pdf)

      BlobSupport.showBlobInNewWindow(blob)
    }
  }


  fun addComment(commentFor: Uuid, newComment: Comment) {
    workflow.addComment(AppScope, commentFor, newComment)
  }

  fun addProcessState(processStateFor: Uuid, newProcessState: LizergyProcessStateEntry) {
    workflow.addProcessState(AppScope, processStateFor, newProcessState)
  }

  fun addProcessStates(processStateForComponents: List<SendProcessStatesTuple>) {
    workflow.addProcessStates(AppScope, processStateForComponents)
  }

  fun archiveProcessState(processStateFor: Uuid, processStateToArchive: LizergyProcessStateEntry) {
    workflow.archiveProcessState(AppScope, processStateFor, processStateToArchive)
  }

  fun saveEarningsDistribution(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, earningsDistribution: ResolvedEarningsDistribution) {
    workflow.saveEarningsDistribution(AppScope, projectId, configurationId, earningsDistribution)
  }

  fun saveManualQuoteElements(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, manualQuoteElements: ManualQuoteElements) {
    workflow.saveManualQuoteElements(AppScope, projectId, configurationId, manualQuoteElements)
  }


  private val logger: Logger = LoggerFactory.getLogger("services.UiActions")
}
