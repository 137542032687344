package it.neckar.ktor.client.plugin.stats

import it.neckar.open.time.nowMillis
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.util.*

/**
 * Configuration class for [HttpClientLoadingPlugin].
 *
 * @property loadingCallback the [LoadingCallback] to be called on send and receive requests.
 */
class HttpClientLoadingPluginConfig {
  /**
   * The callback that is notified
   */
  var loadingCallback: LoadingCallback? = null
}


/**
 * A plugin that intercepts HTTP requests and notifies a [LoadingCallback] when they are sent and completed.
 *
 * @property loadingCallback the [LoadingCallback] to be called on send and receive requests.
 */
@KtorDsl
class HttpClientLoadingPlugin private constructor(
  val loadingCallback: LoadingCallback,
) {
  companion object Plugin : HttpClientPlugin<HttpClientLoadingPluginConfig, HttpClientLoadingPlugin> {
    /**
     * The context objects for the requests that have been sent.
     * The context is the only object that is the same for before and receive events
     */
    private val sentRequestContexts2Descriptor: MutableMap<HttpRequestBuilder, PendingRequestDescriptor> = mutableMapOf() //TODO synchronize(?)

    override val key: AttributeKey<HttpClientLoadingPlugin> = AttributeKey("HttpClientLoadingPlugin")

    override fun prepare(block: HttpClientLoadingPluginConfig.() -> Unit): HttpClientLoadingPlugin {
      val config = HttpClientLoadingPluginConfig().apply(block)

      val loadingCallback = requireNotNull(config.loadingCallback) {
        "No loadingCallback set"
      }
      return HttpClientLoadingPlugin(loadingCallback)
    }

    override fun install(plugin: HttpClientLoadingPlugin, scope: HttpClient) {
      scope.sendPipeline.intercept(HttpSendPipeline.Before) {
        val context = this@intercept.context
        val pendingRequestDescriptor = PendingRequestDescriptor(context.url.build(), context.method, nowMillis())
        plugin.loadingCallback.requestSent(pendingRequestDescriptor)

        sentRequestContexts2Descriptor[context] = pendingRequestDescriptor
      }

      scope.sendPipeline.intercept(HttpSendPipeline.Receive) {
        val context = this@intercept.context

        val descriptor = sentRequestContexts2Descriptor.remove(context) ?: throw IllegalStateException("Received request with context $context which cannot be found in the list of started requests.")

        plugin.loadingCallback.requestCompleted(descriptor.receivedAt(nowMillis()))
        sentRequestContexts2Descriptor.remove(context)
      }
    }
  }
}

/**
 * A callback interface that is called when an HTTP request is sent and completed.
 */
interface LoadingCallback {
  /**
   * Called when an HTTP request has been sent.
   *
   * @param request the intercepted request.
   */
  fun requestSent(request: PendingRequestDescriptor)

  /**
   * Called when an HTTP request has completed.
   *
   * @param request the intercepted request.
   */
  fun requestCompleted(request: CompletedRequestDescriptor)
}

