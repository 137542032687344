package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.user.UserLoginName
import store.PlannerClientState

data class DeleteUserAction(val loginNameToDelete: UserLoginName) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  constructor(userToDelete: UserInformation) : this(userToDelete.loginName)

  override fun PlannerClientState.updateState(): PlannerClientState {
    val userInformationToDelete = usersAndCompanies[loginNameToDelete]

    return this.copy(
      essentials = essentialsNonNull.copy(
        usersAndCompanies = usersAndCompanies.withUpdatedUser(userInformationToDelete.copy(lifeCycleState = LifeCycleState.EndOfLife)),
      ),
    )
  }
}
