package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import store.PlannerClientState

/**
 * Actions related to configurations
 */


/**
 * Updates a single configuration
 * Does *not* navigate to the configuration
 */
data class UpdateQuoteConfigurationAction(val projectId: PhotovoltaicsProjectId, val configurationId: PhotovoltaicsConfigurationId, val updateAction: QuoteConfiguration.() -> QuoteConfiguration) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    val originalProject = this.resolvedProjects[projectId]
    val originalConfiguration = originalProject[configurationId]

    val updatedConfiguration = originalConfiguration.updateAction()
    val updatedConfigurations = originalProject.quoteConfigurations.withUpdated(updatedConfiguration)
    val updatedProject = originalProject.copy(quoteConfigurations = updatedConfigurations)

    val updatedProjects = this.resolvedProjects.withUpdated(updatedProject)

    return this.copy(resolvedProjects = updatedProjects)
  }
}
