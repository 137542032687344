package store

import it.neckar.comments.AllCommentsForComponents
import it.neckar.ktor.client.plugin.stats.PendingRequestsState
import it.neckar.lizergy.PlannerClientDeploymentZone
import it.neckar.lizergy.model.company.UsersAndCompanies
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.lizergy.model.project.ResolvedProjects
import it.neckar.open.annotations.LazyLoading
import it.neckar.processStates.AllProcessStatesForComponents
import it.neckar.rest.jwt.JwtTokenPair
import it.neckar.user.UserLoginName

/**
 * The client state.
 *
 * Elements that are marked with [LazyLoading] should not be accessed directly.
 * Instead, the corresponding `useLoad*` methods should be used:
 */
data class PlannerClientState(
  /**
   * The server the application is running on.
   * Can be used to determine the base URL for API calls and if this is production or development
   */
  val deploymentZone: PlannerClientDeploymentZone,

  /**
   * The online state
   */
  val onlineState: ServicesOnlineState = ServicesOnlineState.Unknown,

  /**
   * The login state (contains the JWS token and user information about the currently logged-in user)
   */
  val loginState: LoginState = NotYetLoggedIn,

  /**
   * The essentials are loaded very early.
   * They are always available as soon as the user is logged in and any component is rendered.
   *
   * As long as the essentials are not yet loaded, no "normal" component is rendered
   */
  val essentials: Essentials? = null,

  val pendingRequestsState: PendingRequestsState = PendingRequestsState(),

  /**
   * The projects to be displayed in the project overview.
   */
  @LazyLoading(description = "The elements are loaded lazily and added to this object")
  val resolvedProjects: ResolvedProjects = ResolvedProjects.empty,

  @LazyLoading(description = "The elements are loaded lazily and added to this object")
  val allCommentsForComponents: AllCommentsForComponents = AllCommentsForComponents.empty,

  @LazyLoading(description = "The elements are loaded lazily and added to this object")
  val allProcessStatesForComponents: AllProcessStatesForComponents? = null,

  /**
   * The current error state
   */
  val errorState: ErrorState = ErrorState.none,

  /**
   * The current, formatted string of the format version of the storage version
   */
  val versionString: String? = null,

  /**
   * Update counter that is automatically incremented in init
   */
  var updateCounter: Int = -1,

  ) {

  init {
    if (essentials != null) {
      requireNotNull(loggedInState) {
        "Essentials must be null for logged in users!"
      }
    }

    //Automatically increase the update counter
    updateCounter++
  }

  /**
   * Returns the essentials.
   * Throws an exception if the essentials have not yet been loaded.
   */
  val essentialsNonNull: Essentials
    get() = essentials ?: throw IllegalStateException("essentials not yet loaded")

  /**
   * Returns the logged in state - or null if the user is currently not logged in
   */
  val loggedInState: LoggedInState?
    get() = loginState as? LoggedInState

  /**
   * ATTENTION:
   * Use `useLoadUsersAndCompanies` in components. This property must only be used in actions when
   * it is 100% sure, that the value has been initialized
   */
  @IsEssential
  val usersAndCompanies: UsersAndCompanies
    get() = essentialsNonNull.usersAndCompanies

  @IsEssential
  val availableProducts: AvailableProducts
    get() = essentialsNonNull.availableProducts

  @IsEssential
  val productResolver: ProductResolver
    get() = essentialsNonNull.productResolver

  @IsEssential
  val priceList: PriceList
    get() = essentialsNonNull.priceList


  fun login(
    loginName: UserLoginName,
    password: String,
    tokenPair: JwtTokenPair,
  ): PlannerClientState {
    return copy(loginState = LoggedInState(loginName.value.lowercase(), password, tokenPair))
  }

  fun logout(): PlannerClientState {
    return copy(
      loginState = LoggedOutState,
      //Clear the essentials on logout
      essentials = null,
      resolvedProjects = ResolvedProjects.empty,
      allCommentsForComponents = AllCommentsForComponents.empty,
      allProcessStatesForComponents = null,
    )
  }
}

/**
 * Contains the "essential" values that are always required
 * This wrapper object simplifies loading of the essentials
 */
data class Essentials(
  /**
   * Contains all users + companies this user has access to
   */
  val usersAndCompanies: UsersAndCompanies,

  /**
   * The [AvailableProducts] (empty if no price lists have yet been loaded)
   * Is loaded after login
   */
  val availableProducts: AvailableProducts,

  /**
   * The [ProductResolver] (empty if no price lists have yet been loaded)
   * Is loaded after login
   */
  val productResolver: ProductResolver,

  /**
   * The [PriceList] (empty if no price lists have yet been loaded)
   * Is loaded after login
   */
  val priceList: PriceList,
)
