package i18n

import components.project.configuration.RelevanceForEconomicsCalculation
import it.neckar.financial.quote.ConfiguredOptionality

/**
 * Contains translation for some enums
 */
object EnumTranslator {

  val ConfiguredOptionality: (value: ConfiguredOptionality) -> String = { value ->
    when (value) {
      it.neckar.financial.quote.ConfiguredOptionality.Selected -> "Ja"
      it.neckar.financial.quote.ConfiguredOptionality.NotSelected -> "Nein"
      it.neckar.financial.quote.ConfiguredOptionality.Optional -> "Optional"
    }

  }

  val RelevanceForEconomicsCalculation: (value: RelevanceForEconomicsCalculation) -> String = { value ->
    when (value) {
      components.project.configuration.RelevanceForEconomicsCalculation.Relevant -> "Teil der Wirtschaftlichkeitsrechnung"
      components.project.configuration.RelevanceForEconomicsCalculation.NotRelevant -> "Nicht relevant für Wirtschaftlichkeit"
    }
  }


}
