package com.meistercharts.charts.lizergy.roofPlanning

import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.addShowLoadingOnMissingResources
import com.meistercharts.canvas.BindContentAreaSize2Property
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.MeisterchartBuilder
import com.meistercharts.canvas.resize.addResizeByHandlesLayer
import com.meistercharts.charts.ChartGestalt
import com.meistercharts.model.Insets
import com.meistercharts.resize.ResetToDefaultsOnWindowResize
import com.meistercharts.zoom.FittingWithMarginAspectRatio
import it.neckar.geometry.AxisOrientationX
import it.neckar.geometry.AxisOrientationY

/**
 * Gestalt for roof planning
 */
class PvRoofPlanningGestalt(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : ChartGestalt {

  constructor(
    model: PvRoofPlanningModel = PvModuleSampleModels.realistic(),
    roofPlanningLayerSelection: RoofPlanningSelectionModel = RoofPlanningSelectionModel(),
    additionalConfiguration: Configuration.() -> Unit = {},
  ) : this(Configuration(model, roofPlanningLayerSelection), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  val roofBackgroundLayer: RoofBackgroundLayer = RoofBackgroundLayer({ configuration.model.roofSize })

  val pvRoofPlanningLayer: PvRoofPlanningLayer = PvRoofPlanningLayer(configuration.model, configuration.roofPlanningLayerSelection)

  override fun configure(meisterChartBuilder: MeisterchartBuilder) {
    with(meisterChartBuilder) {
      enableZoomAndTranslation = false
      contentAreaSizingStrategy = BindContentAreaSize2Property(configuration.model.roofSizeProperty)

      zoomAndTranslationDefaults {
        FittingWithMarginAspectRatio {
          Insets.of(20.0)
        }
      }
      configure {
        chartSupport.windowResizeBehavior = ResetToDefaultsOnWindowResize
      }

      configure {
        chartSupport.rootChartState.axisOrientationY = AxisOrientationY.OriginAtBottom //everything is measured from the bottom
        chartSupport.rootChartState.axisOrientationX = AxisOrientationX.OriginAtLeft //everything is measured from the left

        layers.addClearBackground()
        layers.addLayer(roofBackgroundLayer)
        layers.addLayer(pvRoofPlanningLayer)
        layers.addResizeByHandlesLayer()
        layers.addShowLoadingOnMissingResources()
      }
    }
  }

  @ConfigurationDsl
  class Configuration(
    val model: PvRoofPlanningModel = PvModuleSampleModels.realistic(),
    val roofPlanningLayerSelection: RoofPlanningSelectionModel = RoofPlanningSelectionModel(),
  )
}
