package components.project.configuration.tabs

import components.project.QuoteConfigurationTabProps
import components.project.configuration.description.editConfigurationProcessState
import components.project.configuration.isConfigurationEditable
import components.project.editProcessStateEntry
import components.project.infiniteProcessStatesList
import components.project.isProjectEditable
import components.project.processStatesList
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ConfigurationProcessStateEntry.ConfigurationProcessStates
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry.GridAssessmentProcessStates
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry.OrderSpecialMaterialProcessStates
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.PresentationProcessStateEntry.PresentationProcessStates
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.QuoteConfirmationProcessStateEntry.QuoteConfirmationProcessStates
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.lizergy.model.project.process.state.toNewProcessStates
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.open.time.nowMillis
import it.neckar.processStatesClient.SendProcessStatesTuple
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import kotlinx.css.*
import kotlinx.html.classes
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import styled.*

val EditConfigurationProcessStateFromUrl: FC<Props> = fc("EditConfigurationProcessStateFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Angebotszustand", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    EditConfigurationProcessState {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val EditConfigurationProcessState: FC<QuoteConfigurationTabProps> = fc("EditConfigurationProcessState") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val allProcessStatesForComponents = useLoadProcessStates()
  val processStateEntries = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
  val currentProcessStateEntry = processStateEntries?.current()

  val nextProcessStateForQuoteConfiguration = if (project.getValidationProblems(quoteConfiguration.configurationId).hasErrors().not()) {
    PresentationProcessStates.InReview
  } else null

  val currentQuoteSnapshot = quoteConfiguration.currentQuoteSnapshot
  val orderSpecialMaterial = project.orderSpecialMaterial
  val gridAssessment = project.gridAssessment
  val orderSpecialMaterialProcessStateEntry = useLoadProcessStatesForComponent(orderSpecialMaterial.uuid)?.current()
  val gridAssessmentProcessStateEntry = useLoadProcessStatesForComponent(gridAssessment.uuid)?.current()

  val confirmationSignedState = useState(currentQuoteSnapshot?.confirmationSigned ?: false)
  val warrantSignedState = useState(currentQuoteSnapshot?.warrantSigned ?: false)
  val cancellationPolicySignedState = useState(currentQuoteSnapshot?.cancellationPolicySigned ?: false)

  val quoteSnapshotToSave = useMemo(currentQuoteSnapshot, confirmationSignedState.value, warrantSignedState.value, cancellationPolicySignedState.value) {
    currentQuoteSnapshot?.copy(
      confirmationSigned = confirmationSignedState.value,
      warrantSigned = warrantSignedState.value,
      cancellationPolicySigned = cancellationPolicySignedState.value,
    )
  }

  val availableProcessStates = when (currentProcessStateEntry?.processState) {
    is QuoteConfirmationProcessStates -> buildList {
      add(QuoteConfirmationProcessStates.Sent)
      if (quoteSnapshotToSave?.allDocumentsSigned == true) add(QuoteConfirmationProcessStates.Accepted)
      add(QuoteConfirmationProcessStates.Rejected)
    }

    else -> currentProcessStateEntry?.availableOptions ?: quoteConfiguration.processState?.toNewProcessStates() ?: ConfigurationProcessStates.entries
  }


  useMemo(currentProcessStateEntry?.processState) {
    val automaticProcessStates = buildList {
      if (currentProcessStateEntry?.processState == QuoteConfirmationProcessStates.Accepted) {
        if (orderSpecialMaterialProcessStateEntry == null) {
          add(SendProcessStatesTuple(orderSpecialMaterial.uuid, OrderSpecialMaterialProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

        if (gridAssessmentProcessStateEntry == null) {
          add(SendProcessStatesTuple(gridAssessment.uuid, GridAssessmentProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }
      }
    }

    if (automaticProcessStates.isNotEmpty()) {
      UiActions.addProcessStates(automaticProcessStates)
    }
    if (currentQuoteSnapshot != null && currentProcessStateEntry?.processState == ConfigurationProcessStates.BeingEdited) {
      UiActions.removeQuoteSnapshot(project.projectId, quoteConfiguration, currentQuoteSnapshot)
    }
  }

  useEffect(quoteSnapshotToSave) {
    if (quoteSnapshotToSave != null && quoteSnapshotToSave != currentQuoteSnapshot) {
      UiActions.addQuoteSnapshot(project.projectId, quoteConfiguration, quoteSnapshotToSave)
    }
  }


  div("row my-3") {

    div("col-lg-6 col-12") {
      editProcessStateEntry(
        processStateFor = quoteConfiguration,
        availableProcessStates = availableProcessStates,
        previousProcessState = if (currentProcessStateEntry is PresentationProcessStateEntry) ConfigurationProcessStates.BeingEdited else null,
        nextProcessState = if (currentProcessStateEntry is ConfigurationProcessStateEntry) nextProcessStateForQuoteConfiguration else null,
        editableStatus = project.isProjectEditable(),
      )
    }

    styledDiv {
      attrs {
        classes = setOf("col-lg-6 col-12")
      }

      css {
        maxHeight = 200.px
        overflowY = Overflow.auto
      }

      infiniteProcessStatesList(quoteConfiguration)

      hr("my-3") {}

      editConfigurationProcessState(project, quoteConfiguration)
    }

  }

  if (currentProcessStateEntry?.processState == QuoteConfirmationProcessStates.Accepted) {

    div("row my-3") {
      div("col-md-6") {
        editProcessStateEntry(
          processStateFor = orderSpecialMaterial,
          availableProcessStates = listOf(OrderSpecialMaterialProcessStates.Missing),
          editableStatus = project.isProjectEditable(),
        )
      }

      processStatesList(orderSpecialMaterial)
    }

    div("row my-3") {
      div("col-md-6") {
        editProcessStateEntry(
          processStateFor = gridAssessment,
          availableProcessStates = listOf(GridAssessmentProcessStates.Missing),
          editableStatus = project.isProjectEditable(),
        )
      }

      processStatesList(gridAssessment)
    }

  }

  if (currentQuoteSnapshot != null && currentProcessStateEntry is QuoteConfirmationProcessStateEntry) {

    div("row my-5") {
      div {
        checkbox(
          valueAndSetter = confirmationSignedState,
          title = "Unterschriebene AB abgelegt*",
          fieldName = "confirmationSignedState",
          labelConfig = { attrs { addClass("fw-bold") } },
        )
      }
      div {
        checkbox(
          valueAndSetter = warrantSignedState,
          title = "Unterschriebene Vollmacht abgelegt*",
          fieldName = "warrantSignedState",
          labelConfig = { attrs { addClass("fw-bold") } },
        )
      }
      div {
        checkbox(
          valueAndSetter = cancellationPolicySignedState,
          title = "Unterschriebene Widerrufsbelehrung abgelegt*",
          fieldName = "cancellationPolicySignedState",
          labelConfig = { attrs { addClass("fw-bold") } },
        )
      }
    }

  }

  if (allProcessStatesForComponents == null || project.isEveryOtherQuoteArchived(quoteConfiguration, allProcessStatesForComponents).not()) {
    p("text-danger fw-bold my-3") {
      +"Alle anderen Angebote müssen archiviert werden bevor eine AB ausgegeben werden kann!"
    }
  }

}
