package components.project

import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faEdit
import it.neckar.react.common.FontAwesome.faEye
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.router.*
import kotlinx.coroutines.*
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*

/**
 * Creates a freezable edit button
 * This is used for the UI for editing a configuration which can be frozen and restricted from being edited
 */
fun RBuilder.freezableEditButton(
  editableStatus: EditableStatus,
  action: suspend () -> Unit,
) {
  button(classes = "btn btn-link btn-sm") {
    when (editableStatus) {
      Editable -> faEdit()
      ReadOnly -> faEye()
    }

    attrs {
      onClickFunction = {
        AppScope.launch {
          action()
        }
      }
    }
  }
}

fun RBuilder.viewButton(action: suspend () -> Unit) {
  button(classes = "btn btn-link btn-sm") {
    faEye()

    attrs {
      onClickFunction = {
        AppScope.launch {
          action()
        }
      }
    }
  }
}

/**
 * Add button that uses the editable status
 */
fun RBuilder.freezableAddButton(
  editableStatus: EditableStatus,
  action: suspend () -> Unit,
) {

  button(classes = "btn btn-link btn-sm") {
    i(FontAwesomeIcons.add) { }

    attrs {
      disabled = editableStatus == ReadOnly
      onClickFunction = {
        AppScope.launch {
          action()
        }
      }
    }
  }

}

fun RBuilder.freezableCopyButton(
  editableStatus: EditableStatus,
  action: suspend () -> Unit,
) {

  button(classes = "btn btn-link btn-sm") {
    i(FontAwesomeIcons.copy) { }

    attrs {
      disabled = editableStatus == ReadOnly
      onClickFunction = {
        AppScope.launch {
          action()
        }
      }
    }
  }

}

fun RBuilder.freezableDeleteButton(
  editableStatus: EditableStatus,
  action: suspend () -> Unit,
) {

  button(classes = "btn btn-link btn-sm") {
    i(FontAwesomeIcons.trash) { }

    attrs {
      disabled = editableStatus == ReadOnly
      onClickFunction = {
        AppScope.launch {
          action()
        }
      }
    }
  }

}

/**
 * Creates form buttons with a default cancel action
 */
fun RBuilder.formButtons(okAction: () -> Unit) {
  val navigate = useNavigateUrl()

  formButtons(
    cancelAction = { navigate.back() },
    okAction = { okAction() },
  )
}

/**
 * Creates freezable form buttons
 * This is used for the UI for editing a configuration which can be frozen and restricted from being edited
 */
fun RBuilder.freezableFormButtons(editableStatus: EditableStatus, okAction: () -> Unit) {
  val navigate = useNavigateUrl()

  when (editableStatus) {
    Editable -> {
      formButtons(
        okAction = { okAction() },
      )
    }

    ReadOnly -> {
      button(classes = "btn btn-primary mt-3") {
        span {
          +"Zurück"
        }

        attrs {
          onClickFunction = { navigate.back() }
        }
      }
    }
  }
}
