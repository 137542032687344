package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.ModuleArea
import com.meistercharts.charts.lizergy.roofPlanning.ModuleAreas
import com.meistercharts.charts.lizergy.roofPlanning.ModuleSize
import com.meistercharts.charts.lizergy.roofPlanning.UnusableAreas
import kotlinx.serialization.Serializable

/**
 * Information about several module areas for a single roof
 */
@Serializable
data class ModuleAreasInformation(
  /**
   * Information for *each* module area.
   */
  val moduleAreaInformations: List<ModuleAreaInformation>,
) {

  fun modulesCount(moduleAreaPlacementsSelector: (ModuleAreaInformation) -> Boolean = { true }): Int {
    return moduleAreaInformations.filter {
      moduleAreaPlacementsSelector(it)
    }.sumOf { moduleAreaPlacementInformation ->
      moduleAreaPlacementInformation.modulesInformation.moduleInformations.filter { modulePlacementInformation ->
        modulePlacementInformation.deleted.not()
      }.size
    }
  }

  /**
   * Convert back to "live" modules
   */
  fun toModuleAreas(unusableAreas: UnusableAreas, moduleSize: ModuleSize): List<ModuleArea> {
    return buildList {
      moduleAreaInformations.map { moduleAreaPlacementInformation ->
        ModuleArea(
          uuid = moduleAreaPlacementInformation.uuid,
          initialLocation = moduleAreaPlacementInformation.location,
          initialSize = moduleAreaPlacementInformation.size,
          modulesSize = moduleSize,
          initialOrientation = moduleAreaPlacementInformation.orientation,
          gap = moduleAreaPlacementInformation.gap,
          unusableAreas = unusableAreas,
        ).also { moduleArea ->
          moduleArea.modules.clear()
          moduleArea.modules.addAll(moduleAreaPlacementInformation.modulesInformation.toModules(moduleArea))
        }
      }.let {
        addAll(it)
      }
    }
  }
}

/**
 * Converts a module grid to a REST API model
 */
fun ModuleAreas.toRestApiModel(): ModuleAreasInformation {
  return ModuleAreasInformation(
    this.moduleAreas.map { moduleArea ->
      moduleArea.toRestApiModel()
    },
  )
}
