package components.accounting

import it.neckar.react.common.router.*
import react.*
import react.dom.*
import react.router.*
import router.PathConstants
import router.useDocumentTitle
import store.hooks.useRequireCompanyForLoggedInUser

val AccountingOverview3: FC<Props> = fc("AccountingOverview3") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Abrechnung")

  div("mt-5") {
    tabButtonsPane {

      tabButton(NavigateUrl.empty, "Übersicht")

      tabButton(NavigateUrl(PathConstants.accounting), "Abrechnung")

    }
  }

  div("mt-5") {
    Outlet {}
  }
}
