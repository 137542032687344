package it.neckar.lizergy.model.configuration.quote

import com.benasher44.uuid.Uuid
import it.neckar.customer.Customer
import it.neckar.financial.quote.Quote
import it.neckar.financial.quote.QuoteLegalNotice
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.quote.economics.EconomicsReport
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

/**
 *  Represents the snapshot of a [Quote]
 *  Useful for a price freeze to create order confirmation at a later point
 */
@Serializable
data class QuoteSnapshot(

  val id: QuoteSnapshotId,

  /**
   * The corresponding project
   */
  val projectId: PhotovoltaicsProjectId,

  /**
   * The corresponding configuration
   */
  val configurationId: PhotovoltaicsConfigurationId,

  /**
   * The roof id this layout has been created for
   */
  val quote: Quote,

  /**
   * The corresponding economics report
   */
  val economicsReport: EconomicsReport,

  val resolvedModuleLayouts: ResolvedModuleLayouts,

  val location: LocationInformation,

  /**
   * Name of the editor who created this [QuoteSnapshot]
   */
  val creatorName: UserLoginName,

  val legalNotice: QuoteLegalNotice? = null,

  val confirmationSigned: Boolean = false,
  val warrantSigned: Boolean = false,
  val cancellationPolicySigned: Boolean = false,

  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,

  ) : HasUuid, HasLifeCycle {

  override val uuid: Uuid
    get() = id.uuid

  val creationDate: @ms Double
    get() = quote.creationDate

  val allDocumentsSigned: Boolean
    get() = confirmationSigned && warrantSigned && cancellationPolicySigned

  /**
   * Returns a new instance of the quote instance with the updated values
   */
  fun withUpdatedValues(customer: Customer, updatedQuoteConfiguration: PhotovoltaicsConfiguration): QuoteSnapshot {
    val updatedLegalNotice = updatedQuoteConfiguration.generateQuoteLegalNotice()
    return copy(
      quote = quote.copy(
        customer = customer,
        legalNotice = updatedLegalNotice,
      ),
      location = updatedQuoteConfiguration.location,
      legalNotice = updatedLegalNotice,
    )
  }

  /**
   * ID for a module layout
   */
  @Serializable
  data class QuoteSnapshotId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): QuoteSnapshotId {
        return QuoteSnapshotId(randomUuid4())
      }
    }
  }

}
