package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.datetime.minimal.Year
import it.neckar.lizergy.model.configuration.quote.economics.YearIndex
import it.neckar.open.collections.fastForEach
import it.neckar.open.formatting.percentageFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.kotlin.lang.fastFor
import kotlinx.serialization.Serializable

/**
 * Contains the data for a simple profitability table
 */
@Serializable
data class SimpleProfitabilityTable(
  /**
   * The columns. Each column contains information about one year
   */
  val columns: List<SimpleProfitabilityTableColumn>,

  ) {

  init {
    //TODO Verify the years
  }

  /**
   * Formats the content of this table as Ascii art
   */
  fun formatAscii(): String {
    //Contains the columns - each column is a list of string
    val content: MutableList<List<String>> = mutableListOf()

    //First column - the descriptions
    content.add(buildList {
      add("Year")
      add("----")
      add("Production (kWh)")
      add("Feed in (kWh)")
      add("Own consumption (kWh)")
      add("----")
      add("+ Feed in compensation")
      add("+ Savings own consumption")
      add("- Operating costs")
      add("= Cash flow from Operations")
      add("----")
      add("- Interest")
      add("- Repayment")
      add("= Cash flow from Financing")
      add("----")
      add("= Cash flow")
      add("----")
      add("Residual dept")
      add("Amortisation (%)")
      add("Cash flow cumulated")
    })

    //Add the content
    this.columns.fastForEach { column ->
      content.add(buildList {
        add(column.year.value.toString())
        add("----")
        add(column.energyInformation.production.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add(column.energyInformation.feedIn.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add(column.energyInformation.ownConsumption.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add("----")
        add(column.cashFlow.feedInCompensation.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add(column.cashFlow.savingsOwnConsumption.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add(column.cashFlow.operatingCosts.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add(column.cashFlow.cashFlowFromOperations.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add("----")
        add(column.cashFlow.financingInformation.interest.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add(column.cashFlow.financingInformation.repayment.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add(column.cashFlow.cashFlowFromFinancingActivities.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add("----")
        add(column.cashFlow.cashFlow.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add("----")
        add(column.cashFlow.financingInformation.residualDebtAfterRepayment.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
        add(percentageFormat.format(column.amortisationInformation.amortisationPercentage(column.cashFlow.financingInformation), I18nConfiguration.US, WhitespaceConfig.Spaces))
        add(column.amortisationInformation.cashFlowCumulated.format(I18nConfiguration.US, WhitespaceConfig.Spaces))
      })
    }

    //pad the strings
    val padded = content.mapIndexed { index, column ->
      val maxLength = column.maxOf {
        it.length
      }

      column.map {
        if (index == 0) {
          it.padEnd(maxLength + 1, ' ')
        } else {
          it.padStart(maxLength + 3, ' ')
        }
      }
    }

    return buildString {
      val numberOfRows = padded.first().size
      val numberOfColumns = padded.size

      numberOfRows.fastFor { rowIndex ->
        numberOfColumns.fastFor { columnIndex ->
          val column = padded[columnIndex]
          val value = column[rowIndex]
          append(value)
        }
        appendLine()
      }
    }
  }

  /**
   * Returns the total production over 20 years
   */
  fun totalProduction(): it.neckar.lizergy.model.configuration.energy.AmountOfEnergy {
    return it.neckar.lizergy.model.configuration.energy.AmountOfEnergy(
      columns.sumOf { simpleProfitabilityTableColumn ->
        simpleProfitabilityTableColumn.energyInformation.production.kWh
      }
    )
  }

  /**
   * Returns the production of the first year
   */
  fun firstYearProduction(): it.neckar.lizergy.model.configuration.energy.AmountOfEnergy {
    return columns.first().energyInformation.production
  }


  /**
   * Calculates a [SimpleProfitabilityTable]
   */
  class Calculator(
    /**
     * The first / base year
     */
    var baseYear: Year,

    /**
     * Is used to calculate the energy information
     */
    val energyInformationCalculator: EnergyInformation.Calculator,

    val cashFlowCalculator: CashFlow.Calculator,

    val tariffInformationCalculator: TariffInformation.Calculator,

    val financingInformationCalculator: FinancingInformation.Calculator,

    val amortisationInformationCalculator: AmortisationInformation.Calculator,

    ) {

    fun calculateTable(): SimpleProfitabilityTable {
      return SimpleProfitabilityTable(
        calculateSeries(20)
      )
    }

    /**
     * Calculates a series of years starting at year 0
     */
    fun calculateSeries(numberOfYears: Int): List<SimpleProfitabilityTableColumn> {
      if (numberOfYears == 0) {
        return emptyList()
      }

      require(numberOfYears >= 2) { "Need at least 2 years" }

      return buildList {
        var currentColumn = calculateFirstColumn()
        add(currentColumn)

        for (i in 1 until numberOfYears) {
          currentColumn = calculate(YearIndex(i), currentColumn)
          add(currentColumn)
        }
      }
    }

    fun calculateFirstColumn(): SimpleProfitabilityTableColumn {
      val energyInformation = energyInformationCalculator.calculate(YearIndex.base)
      val tariffInformation = tariffInformationCalculator.calculate(YearIndex.base)
      val financingInformation = financingInformationCalculator.calculate(YearIndex.base)

      val cashFlow = cashFlowCalculator.calculate(
        YearIndex.base,
        tariffInformation,
        energyInformation,
        financingInformation,
      )

      val amortisationInformation = amortisationInformationCalculator.calculateBaseYear(cashFlow, financingInformation)

      return SimpleProfitabilityTableColumn(
        baseYear,
        YearIndex.base,
        energyInformation,
        cashFlow,
        amortisationInformation,
        tariffInformation,
      )
    }

    /**
     * Calculates a column for the given year index (*not* the first column)
     */
    fun calculate(yearIndex: YearIndex, previousColumn: SimpleProfitabilityTableColumn): SimpleProfitabilityTableColumn {
      require(yearIndex != YearIndex.base) { "Not allowed for base. Use calculateFirstColumn instead" }

      val energyInformation = energyInformationCalculator.calculate(yearIndex)
      val tariffInformation = tariffInformationCalculator.calculate(yearIndex)
      val financingInformation = financingInformationCalculator.calculate(yearIndex)

      val cashFlow = cashFlowCalculator.calculate(
        yearIndex = yearIndex,
        tariffInformation = tariffInformation,
        energyInformation = energyInformation,
        financingInformation = financingInformation,
      )

      val amortisationInformation = amortisationInformationCalculator.calculate(previousColumn.amortisationInformation, cashFlow)

      return SimpleProfitabilityTableColumn(
        year = yearIndex.toYear(),
        yearIndex = yearIndex,
        energyInformation = energyInformation,
        cashFlow = cashFlow,
        amortisationInformation = amortisationInformation,
        tariffInformation = tariffInformation,
      )
    }

    /**
     * Returns the year for the given index
     */
    fun YearIndex.toYear(): Year {
      return baseYear + index
    }
  }
}

