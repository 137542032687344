package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

interface WallboxSelection : HasUuid {
   val id: WallboxSelectionId
  val throttled: Boolean

   override val uuid: Uuid
     get() = id.uuid

   @Serializable
   data class WallboxSelectionId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

     override fun toString(): String {
       return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): WallboxSelectionId {
        return WallboxSelectionId(randomUuid4())
      }
    }
  }

}
