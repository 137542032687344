package components.project.blueprint

import components.form.commentSection
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.formatting.decimalFormat
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser

val EditBlueprintWallboxFromUrl: FC<Props> = fc("EditBlueprintWallboxFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "E-Auto - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    EditBlueprintWallbox {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

val EditBlueprintWallbox: FC<EditBlueprintWallboxProps> = fc("EditBlueprintWallbox") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val blueprint = project.blueprint
  val wallboxConfiguration = blueprint.wallboxConfiguration
  val editableStatus = project.isProjectEditable()

  val energyConsumption = useState(wallboxConfiguration.energyConsumption)
  val cableLength = useState(wallboxConfiguration.cableLength)
  val cableDistance = useState(wallboxConfiguration.cableDistance)

  val wallboxConfigurationToSave = useMemo(energyConsumption.value, cableLength.value, cableDistance.value) {
    wallboxConfiguration.copy(
      energyConsumption = energyConsumption.value,
      cableLength = cableLength.value,
      cableDistance = cableDistance.value
    )
  }

  val okAction = {
    navigate(RouterUrls.blueprint(project).additional)
  }


  useMemo(wallboxConfigurationToSave) {
    blueprint.copy(
      wallboxConfiguration = wallboxConfigurationToSave,
    ).also {
      UiActions.saveBlueprint(it)
    }
  }


  div("my-4") {
    onEnter { okAction() }

    h1("my-3") {
      +"Wallbox"
    }

    div("col-sm-6") {
      floatingSelectNullable(
        valueAndSetter = energyConsumption,
        idProvider = { it?.name ?: "none" },
        formatter = { it?.capacity()?.format(plannerI18nConfiguration) ?: "-" },
        availableOptionsWithoutNull = Wallbox.ChargingCapacity.entries,
        fieldName = "energyConsumption",
        title = "Größe in kW",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedBelow()
        }
      }

      floatingSelectNullable(
        valueAndSetter = cableLength,
        idProvider = { it?.name ?: "zero" },
        formatter = { it?.length()?.let { length -> decimalFormat.format(length) } ?: "-" },
        availableOptionsWithoutNull = Wallbox.CableLength.entries,
        fieldName = "cableLength",
        title = "Kabellänge (m)",
        editableStatus = editableStatus,
      ) {
        attrs {
          mergedAbove()
          mergedBelow()
        }
      }

      floatingDoubleInputField(
        valueAndSetter = cableDistance,
        fieldName = "cableDistance",
        title = "Kabelweg",
        editableStatus = editableStatus,
      )
      {
        attrs {
          mergedAbove()
        }
      }
    }

    div("my-5") {
      h3("mb-3") {
        +"Bemerkungen E-Auto"
      }
      project.blueprint.wallboxAnnotation?.let {
        p { +it }
      }
      commentSection(blueprint.wallboxConfiguration)
    }

    wizardFormButtons(
      backwardAction = { navigate(RouterUrls.blueprint(project).electricityWorkEffort) },
      forwardAction = { okAction() },
    )
  }

}


external interface EditBlueprintWallboxProps : Props {
  var project: ResolvedProject
}
