package it.neckar.lizergy

import it.neckar.projects.pvplanner.PvPlannerHost
import it.neckar.projects.pvplanner.PvPlannerPorts

/**
 * Describes the environment the *client* application runs in
 */
enum class PlannerClientDeploymentZone {
  /**
   * Localhost / development - *no* docker
   */
  LocalDevelopment,

  /**
   * Deployed within docker.
   *
   * ### UI:
   * Only relevant when running on the developers machine (localhost).
   * On the server there should always be used one of the other modes.
   *
   * ### Services
   * This mode will be selected on all severs and if executed on the developers machine (localhost) using docker
   */
  LocalDevelopmentDocker,

  /**
   * Spielwiese server (Staging)
   */
  Spielwiese,

  /**
   * Schaufenster server (Demo)
   */
  Schaufenster,

  /**
   * Production.
   * Attention: Must be checked for last!
   */
  Production,
  ;

  /**
   * Returns true if docker is used
   */
  fun inDocker(): Boolean {
    return this != LocalDevelopmentDocker
  }

  /**
   * Returns true if the current server instance is localhost
   */
  fun isLocalhost(): Boolean {
    return this == LocalDevelopment
  }

  fun isProduction(): Boolean {
    return this == Production || this == Schaufenster
  }

  companion object {
    /**
     * Returns the server for the given URL (of the web application the client is running in)
     */
    fun getClientDeploymentZoneFromWindowLocation(windowLocationHref: String): PlannerClientDeploymentZone {
      return when {
        windowLocationHref.startsWith("https://${PvPlannerHost.Production.hostnamePlanner}") -> Production
        windowLocationHref.startsWith("https://${PvPlannerHost.Schaufenster.hostnamePlanner}") -> Schaufenster
        windowLocationHref.startsWith("https://${PvPlannerHost.Spielwiese.hostnamePlanner}") -> Spielwiese
        windowLocationHref.startsWith("http://localhost:${PvPlannerPorts.PlannerUi.docker}") -> LocalDevelopmentDocker
        windowLocationHref.startsWith("http://localhost:${PvPlannerPorts.PlannerUi.devServer}") -> LocalDevelopment
        else -> throw IllegalStateException("Could not identify server for url $windowLocationHref")
      }
    }
  }
}

