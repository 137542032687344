package it.neckar.problem

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents an unexpected problem
 */
@Serializable
@SerialName("Unexpected")
data class UnexpectedProblem(
  /**
   * The message from the exception
   */
  override val fallbackMessage: String?,

  /**
   * The class name of the exception type
   */
  val exceptionType: String,
  /**
   * The (optional instance URI)
   */
  override val instanceUri: String?,

  override val stackTrace: String?,
) : Problem
