package components.company.pricelist.tables

import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.kotlin.lang.ifNull
import it.neckar.open.time.formatHourAndMinutes
import kotlinx.css.*
import kotlinx.html.ThScope
import kotlinx.html.classes
import react.*
import react.dom.*
import store.hooks.useSelectPriceList
import style.toStyleClass
import styled.*

val IndependenceManagerPriceTable: FC<Props> = fc("IndependenceManagerPriceTable") {
  val priceList = useSelectPriceList()

  val independenceManagerPrices = priceList.independenceManagerTypePrices


  div("col") {
    h2 {
      +"Ersatzstromsysteme"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          styledTh {
            attrs {
              classes = setOf("text-center")
            }
            css {
              maxWidth = 150.px
            }
            +"Kompatibel mit Batterietyp"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
          th(ThScope.col, "text-end") {
            +"In Gebrauch"
          }
        }
      }

      tbody {
        independenceManagerPrices.available.fastForEachIndexed { index, independenceManager ->
          val lifeCycleState = independenceManager.lifeCycleState

          tr(classes = lifeCycleState.toStyleClass()) {
            key = independenceManager.uuid.toString()

            th(ThScope.row) {
              +"${index + 1}"
            }
            td {
              +independenceManager.description
            }

            td("text-center") {
              +independenceManager.requiredInverterTypes.joinToString("\n+\n") { it.joinToString { inverterType -> inverterType.description } }
            }

            td("text-end") {
              +independenceManagerPrices[independenceManager.uuid].materialPrice.inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +independenceManagerPrices[independenceManager.uuid].materialPrice.sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +independenceManagerPrices[independenceManager.uuid].materialPrice.profit.format().ifNull("-")
            }

            td("text-center") {
              +lifeCycleState.format()
            }
          }

          tr(classes = lifeCycleState.toStyleClass()) {
            td {
            }
            th(ThScope.row) {
              +"Arbeitszeit:"
            }
            td("text-end") {
              +independenceManagerPrices[independenceManager.uuid].workingTimes.workingTime.formatHourAndMinutes().ifNull("-")
            }

            td("text-end") {
              +independenceManagerPrices[independenceManager.uuid].workingTimes.priceWithProfit.inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +independenceManagerPrices[independenceManager.uuid].workingTimes.priceWithProfit.sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +independenceManagerPrices[independenceManager.uuid].workingTimes.priceWithProfit.profit.format().ifNull("-")
            }

            td("text-center") {
            }
          }

          tr(classes = lifeCycleState.toStyleClass()) {
            td {
            }
            th(ThScope.row) {
              b { +"Gesamt:" }
            }
            td("text-end") {
            }

            td("text-end") {
              b { +independenceManagerPrices[independenceManager.uuid].inputPrice.format().ifNull("-") }
            }
            td("text-end") {
              b { +independenceManagerPrices[independenceManager.uuid].sellingPrice.format().ifNull("-") }
            }
            td("text-end") {
              b { +independenceManagerPrices[independenceManager.uuid].profit.format().ifNull("-") }
            }

            td("text-center") {
            }
          }

        }
      }
    }

  }
}
