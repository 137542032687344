package store

import it.neckar.rest.jwt.JwtToken
import it.neckar.rest.jwt.JwtTokenPair
import kotlinx.serialization.Serializable


/**
 * The login state
 */
sealed class LoginState {
  abstract val loggedIn: Boolean
}

/**
 * The logged in state
 * This object is stored in local storage - and restored if possible
 */
@Serializable
data class LoggedInState(
  /**
   * The current login name
   */
  val loggedInUser: String,

  /**
   * The password - is required to refresh the JWS token
   */
  val password: String,

  /**
   * The JWS token pair
   */
  val tokenPair: JwtTokenPair,

  ) : LoginState() {
  override val loggedIn: Boolean
    get() = true

  /**
   * The current access token
   */
  val accessToken: JwtToken
    get() = tokenPair.accessToken

  /**
   * The current refresh token that can be used to retrieve a new access token
   */
  val refreshToken: JwtToken
    get() = tokenPair.refreshToken
}

/**
 * The user has not yet been logged in.
 * This is the *initial* state of the application.
 *
 * If the user has been logged out, use [LoggedOutState] instead
 */
data object NotYetLoggedIn : LoginState() {
  override val loggedIn: Boolean
    get() = false
}

/**
 * The user has been logged out (actively!)
 */
data object LoggedOutState : LoginState() {
  override val loggedIn: Boolean
    get() = false
}

