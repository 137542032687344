package it.neckar.react.common.redux

import it.neckar.common.redux.RedoAction
import it.neckar.common.redux.StateAction
import it.neckar.common.redux.UndoAction
import it.neckar.common.redux.UndoState
import it.neckar.common.redux.dispatch
import it.neckar.logging.LoggerFactory
import kotlinx.browser.window
import org.w3c.dom.events.KeyboardEvent
import react.*
import react.redux.*
import react.redux.ProviderProps
import redux.RAction
import redux.Store
import redux.rEnhancer
import redux.state

/**
 * Wrapper class for a store including undo functionality
 */
data class UndoStore<S>(
  /**
   * The wrapped React redux store
   */
  val store: Store<UndoState<S>, RAction, dynamic>,
) {

  /**
   * Returns the undo state of this store
   */
  val undoState: UndoState<S>
    get() = store.state

  /**
   * Returns the current state
   */
  val state: S
    get() {
      val undoState = undoState
      return undoState.currentState
    }

  /**
   * Subscribe to changes in the store
   */
  fun subscribe(function: (store: UndoStore<S>) -> Unit) {
    store.subscribe {
      function(this)
    }
  }

  /**
   * Dispatches the provided state action to this store
   */
  fun dispatch(stateAction: StateAction<S>) {
    store.dispatch(StateActionWrapper(stateAction))
  }

  companion object {
    /**
     * Creates a store that supports undo/redo
     */
    fun <S> create(
      preloadedState: S,
      /**
       * The max history length
       */
      maxHistoryLength: Int = 100,
    ): UndoStore<S> {
      val store: Store<UndoState<S>, RAction, dynamic> = redux.createStore<UndoState<S>, RAction, dynamic>(
        reducer = ::undoStateReduceAction, preloadedState = UndoState(preloadedState, maxHistoryLength = maxHistoryLength), enhancer = rEnhancer()
      ).also { store ->
        store.subscribe {
          logger.debug("Store updated", store)
        }
      }

      return UndoStore(store)
    }

    private val logger = LoggerFactory.getLogger("it.neckar.react.common.redux.UndoStore")
  }
}

/**
 * Registers the key event listeners for Undo/Redo
 */
fun UndoStore<*>.registerUndoRedoKeyHandling() {
  window.addEventListener("keydown", { event ->
    event as KeyboardEvent

    if (event.ctrlKey && event.shiftKey && (event.key == "z" || event.key == "Z")) {
      event.preventDefault()
      dispatch(RedoAction)
      return@addEventListener
    }

    if (event.ctrlKey && event.key == "z") {
      event.preventDefault()
      dispatch(UndoAction)
      return@addEventListener
    }
  })
}

/**
 * Provides an undo store to the children
 */
fun RBuilder.provider(
  store: UndoStore<*>,
  context: Context<*>? = null,
  handler: RHandler<ProviderProps>,
) {
  Provider {
    attrs.store = store.store
    if (context != null) attrs.context = context
    handler()
  }
}

/**
 * Use the current state of an undo store
 */
inline fun <S, R> useUndoStoreSelector(crossinline selector: S.() -> R): R {
  return useSelector<UndoState<S>, R> {
    val current = it.currentState
    selector(current)
  }
}

/**
 * Returns the history of an undo store
 */
fun <S> useUndoStoreHistory(): List<UndoState.Snapshot<S>> {
  return useSelector<UndoState<S>, List<UndoState.Snapshot<S>>> {
    it.previous
  }
}

fun <S> useUndoStoreCurrent(): UndoState.Snapshot<S> {
  return useSelector<UndoState<S>, UndoState.Snapshot<S>> {
    it.current
  }
}

/**
 * Returns the current state of the undo store
 */
fun <S> useUndoStoreCurrentState(): S {
  return useSelector<UndoState<S>, S> {
    it.currentState
  }
}

/**
 * Returns the history of an undo store
 */
fun <S> useUndoStoreFuture(): List<UndoState.Snapshot<S>> {
  return useSelector<UndoState<S>, List<UndoState.Snapshot<S>>> {
    it.future
  }
}
