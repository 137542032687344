package components.project.configuration.wallbox

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.configuration.isConfigurationEditable
import components.project.isProjectEditable
import components.project.validWallBoxes
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedWallboxSelection
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.price.Prices
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectPriceList

val EditConfigurationWallboxFromUrl: FC<Props> = fc("EditConfigurationWallboxFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "E-Auto", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    EditConfigurationWallbox {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val EditConfigurationWallbox: FC<QuoteConfigurationTabProps> = fc("EditConfigurationWallbox") { props ->
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val wallboxSelectionToSave: StateInstance<ResolvedWallboxSelection> = useState(quoteConfiguration.wallboxSelection)

  val configurationToSave = useMemo(
    wallboxSelectionToSave.value,
  ) {
    quoteConfiguration.copy(wallboxSelection = wallboxSelectionToSave.value)
  }


  useMemo(configurationToSave) {
    if (editableStatus == Editable) {
      UiActions.saveQuoteConfiguration(project, configurationToSave)
    }
  }


  div("row my-5") {

    div("col-lg-6") {
      div("mb-5") {
        EditWallbox {
          attrs {
            this.wallboxSelectionToSave = wallboxSelectionToSave
            this.availableWallboxes = availableProducts.validWallBoxes(quoteConfiguration.wallboxSelection.types)
            this.wallboxPrices = priceList.wallBoxPrices
            this.editableStatus = editableStatus
          }
        }
      }
    }

    div("col-lg-6") {
      h3("mt-0 mb-2") {
        +"Bemerkungen E-Auto"
      }
      project.blueprint.wallboxAnnotation?.let {
        p { +it }
      }
      commentSection(quoteConfiguration.wallboxSelection)
    }

  }

}

val EditWallbox: FC<EditWallboxProps> = fc("EditWallbox") { props ->
  val wallboxSelectionToSave = props::wallboxSelectionToSave.getNotNull()

  val availableWallboxTypes = props::availableWallboxes.safeGet()
  val wallboxPrices = props::wallboxPrices.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  wallboxForm(
    wallboxSelectionState = wallboxSelectionToSave,
    wallboxPrices = wallboxPrices,
    availableWallboxes = availableWallboxTypes,
    editableStatus = editableStatus,
  )

}


external interface EditWallboxProps : Props {
  var wallboxSelectionToSave: StateInstance<ResolvedWallboxSelection>
  var availableWallboxes: List<Wallbox>
  var wallboxPrices: Prices<Wallbox>
  var editableStatus: EditableStatus
}
