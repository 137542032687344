package it.neckar.lizergy.model.location

import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Response for
 */
@Serializable
sealed interface GeocodingResponse : RestResponse<MapCoordinatesInformation> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: MapCoordinatesInformation) : GeocodingResponse, RestResponse.SuccessResponse<MapCoordinatesInformation>

  @Serializable
  @SerialName("failure")
  data object Failure : GeocodingResponse, RestResponse.FailureResponse<MapCoordinatesInformation>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: MapCoordinatesInformation): GeocodingResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): GeocodingResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: MapCoordinatesInformation?): GeocodingResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}


