package it.neckar.financial.quote

import it.neckar.open.kotlin.lang.substr
import it.neckar.open.unit.si.ms

/**
 * Generates a quote number.
 *
 * Attention: The created number might not be unique!
 */
object QuoteNumberGenerator {
  /**
   * Creates a quote number for a given time
   */
  fun create(time: @ms Double): String {
    val number = time.toLong().toString().takeLast(8)

    return buildString {
      append(number.substr(0, 3))
      append("-")
      append(number.substr(3, 3))
      append("-")
      append(number.substr(6, 2))
    }
  }
}
