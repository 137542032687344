package services.auth.http

import it.neckar.rest.RestResponse
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Response for
 */
@Serializable
sealed interface AddNewUserResponse : RestResponse<UserLoginName> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: UserLoginName) : AddNewUserResponse, RestResponse.SuccessResponse<UserLoginName>

  @Serializable
  @SerialName("failure")
  data class Failure(val errorType: ErrorType) : AddNewUserResponse, RestResponse.FailureResponse<UserLoginName> {
    enum class ErrorType {
      UserAlreadyExists,
      InvalidPassword,
      FailToFetch,
    }
  }

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: UserLoginName): AddNewUserResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(errorType: Failure.ErrorType): AddNewUserResponse = Failure(errorType)
  }
}
