package components.project.configuration.powerusage

import components.project.QuoteConfigurationTabProps
import components.project.inquiryInformationForm
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.react.common.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser

val EditInquiryInformationFromUrl: FC<Props> = fc("EditInquiryInformationFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Anlagenbetreiber", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  h1 {
    +"Anlagenbetreiber"
  }

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]

    EditInquiryInformation {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = loadedProject.isProjectEditable()
      }
    }
  }
}

val EditInquiryInformation: FC<QuoteConfigurationTabProps> = fc("EditInquiryInformation") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val zaehlerNummerToSave = useState(quoteConfiguration.zaehlerNummer)
  val flurstueckNummerToSave = useState(quoteConfiguration.flurstueckNummer)
  val facilityOperator1Name = useState(quoteConfiguration.facilityOperator1.name)
  val facilityOperator1Birthday = useState(quoteConfiguration.facilityOperator1.birthday)
  val facilityOperator2Name = useState(quoteConfiguration.facilityOperator2.name)
  val facilityOperator2Birthday = useState(quoteConfiguration.facilityOperator2.birthday)
  val einspeiseart = useState(quoteConfiguration.einspeiseart)

  val configurationToSave = useMemo(
    zaehlerNummerToSave.value,
    facilityOperator1Name.value,
    facilityOperator1Birthday.value,
    facilityOperator2Name.value,
    facilityOperator2Birthday.value,
    einspeiseart.value,
  ) {
    quoteConfiguration.copy(
      zaehlerNummer = zaehlerNummerToSave.value,
      facilityOperator1 = FacilityOperatorInformation(facilityOperator1Name.value, facilityOperator1Birthday.value),
      facilityOperator2 = FacilityOperatorInformation(facilityOperator2Name.value, facilityOperator2Birthday.value),
      einspeiseart = einspeiseart.value,
    )
  }


  useMemo(configurationToSave) {
    UiActions.saveQuoteConfiguration(project, configurationToSave)
  }


  inquiryInformationForm(
    zaehlerNummer = zaehlerNummerToSave,
    flurstueckNummer = flurstueckNummerToSave,
    facilityOperator1Name = facilityOperator1Name,
    facilityOperator1Birthday = facilityOperator1Birthday,
    facilityOperator2Name = facilityOperator2Name,
    facilityOperator2Birthday = facilityOperator2Birthday,
    einspeiseart = einspeiseart,
    editableStatus = editableStatus,
  )

}
