package it.neckar.open.version.io

import it.neckar.open.version.Version
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 *
 */
object VersionSerializer : KSerializer<Version> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("VersionSerializer", PrimitiveKind.STRING)

  override fun serialize(encoder: Encoder, value: Version) {
    encoder.encodeString(value.format())
  }

  override fun deserialize(decoder: Decoder): Version {
    return Version.parse(decoder.decodeString())
  }
}
