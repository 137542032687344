package services.http

import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.previews.ProjectQueryForUser
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.rest.RestResponse
import it.neckar.rest.pagination.Page
import it.neckar.rest.pagination.PaginationInfo
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Response for
 */
@Serializable
data class ProjectCountQueryRequest(
  val requestedProjectCountsForPhases: List<ProjectCountRequestForPhase>,
  val loggedInUser: UserLoginName,
)

@Serializable
data class ProjectCountRequestForPhase(
  val relevantProcessStates: List<@Serializable(with = LizergyProcessStatesSerializer::class) LizergyProcessStates>,
  val projectQueryComponent: ProjectQueryComponent?,
  val projectQueryForUser: ProjectQueryForUser,
)


@Serializable
sealed interface ProjectCountQueryResponse : RestResponse<List<ProjectCountForPhase>> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): RestResponse.PaginatedSuccessResponse<List<ProjectCountForPhase>> {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(
    override val data: List<ProjectCountForPhase>,
    override val paginationInfo: PaginationInfo,
  ) : ProjectCountQueryResponse, RestResponse.PaginatedSuccessResponse<List<ProjectCountForPhase>>

  @Serializable
  @SerialName("failure")
  data object Failure : ProjectCountQueryResponse, RestResponse.FailureResponse<List<ProjectCountForPhase>>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: List<ProjectCountForPhase>, paginationInfo: PaginationInfo): ProjectCountQueryResponse = Success(data, paginationInfo)
    fun success(page: Page<ProjectCountForPhase>): ProjectCountQueryResponse = Success(page.pageElements, page.paginationInfo)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): ProjectCountQueryResponse = Failure
  }
}

@Serializable
data class ProjectCountForPhase(
  val projectQueryComponent: ProjectQueryComponent?,
  val projectCountForUser: Int,
  val totalProjectCount: Int,
)
