package services.http

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.util.reflect.*
import it.neckar.customer.company.CompanyCode
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.get
import it.neckar.ktor.client.parameter
import it.neckar.ktor.client.postJson
import it.neckar.ktor.client.putJson
import it.neckar.ktor.client.safeTryFetch
import it.neckar.lizergy.model.assemblyPortfolio.ResolvedAssemblyPortfolio
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.PlannerConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.http.PlannerApiPathConstants
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.Blueprint
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.project.previews.AccountingProjectPreview
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.stumps.AssemblyBasement
import it.neckar.lizergy.model.stumps.GridAssessment
import it.neckar.rest.EmptyResponse
import it.neckar.user.UserLoginName
import serialized.ModuleLayoutsConfiguration
import serialized.SerializedBlueprint
import serialized.SerializedEarningsDistribution
import serialized.SerializedPhotovoltaicsConfiguration
import serialized.SerializedProject
import serialized.unResolve
import services.storage.http.ArchiveProjectResponse
import services.storage.http.FetchAdvanceInvoiceResponse
import services.storage.http.FetchAssemblyBasementResponse
import services.storage.http.FetchAssemblyPortfolioResponse
import services.storage.http.FetchAssemblyRoofResponse
import services.storage.http.FetchAvailableAccountingProjectsResponse
import services.storage.http.FetchAvailableProjectsResponse
import services.storage.http.FetchBlueprintResponse
import services.storage.http.FetchConfigurationResponse
import services.storage.http.FetchConfigurationsResponse
import services.storage.http.FetchDocumentationResponse
import services.storage.http.FetchFinalAccountResponse
import services.storage.http.FetchFinishingUpResponse
import services.storage.http.FetchGridAssessmentResponse
import services.storage.http.FetchModuleLayoutsResponse
import services.storage.http.FetchOrderSpecialMaterialResponse
import services.storage.http.FetchProjectResponse
import services.storage.http.FetchQuoteSnapshotResponse
import services.storage.http.FetchQuoteSnapshotsResponse
import services.storage.http.FetchSearchForProjectsResponse
import services.storage.http.FetchStartupOperationsResponse
import services.storage.http.FetchStaticPriceListResponse
import services.storage.http.FetchSwitchMeterBoxResponse
import services.storage.http.SaveQuoteConfigurationRequest
import services.storage.http.SendResolvedProjectRequest

/**
 * Stores / retrieves information using REST
 */
class ProjectQueryClientService(val httpClient: HttpClient, val urlSupport: PlannerUrlSupport) {

  /**
   * Loads the [StaticPriceList]
   */
  suspend fun fetchStaticPriceList(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): FetchStaticPriceListResponse {
    return safeTryFetch(FetchStaticPriceListResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.staticPriceList()) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  /**
   * Returns the available [ProjectPreviews] for the given company.
   */
  suspend fun fetchAvailableProjects(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): FetchAvailableProjectsResponse {
    return safeTryFetch(FetchAvailableProjectsResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.projects()) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchAvailableAccountingProjects(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): FetchAvailableAccountingProjectsResponse {
    return safeTryFetch(FetchAvailableAccountingProjectsResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.accountingProjects()) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchProject(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchProjectResponse {
    return safeTryFetch(FetchProjectResponse.Failure) {
      httpClient.get(urlSupport.projectQuery.project(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  /**
   * Loads the [ResolvedProjectPreviews]
   */
  suspend fun queryProjectPreviews(
    processStatesToFilter: List<LizergyProcessStates>,
    processStatesToHide: List<LizergyProcessStates>,
    filteredByCompanyCode: CompanyCode?,
    filteredByMaintainer: UserLoginName?,
    filteredByEditor: UserLoginName?,
    filterValueProject: String?,
    filterValueAddress: String?,
    filterByProjectState: LizergyProcessStates?,
    indexOfFirstVisibleProject: Int,
    indexOfLastVisibleProject: Int,
    sorting: ProjectQuerySorting,
    projectQueryComponent: ProjectQueryComponent?,
    includeArchived: Boolean,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): ProjectQueryResponse {
    val request = ProjectQueryRequest(
      processStatesToFilter = processStatesToFilter,
      processStatesToHide = processStatesToHide,
      filteredByCompanyCode = filteredByCompanyCode,
      filteredByMaintainer = filteredByMaintainer,
      filteredByEditor = filteredByEditor,
      filterValueProject = filterValueProject,
      filterValueAddress = filterValueAddress,
      filterByProjectState = filterByProjectState,
      indexOfFirstVisibleProject = indexOfFirstVisibleProject,
      indexOfLastVisibleProject = indexOfLastVisibleProject,
      sorting = sorting,
      projectQueryComponent = projectQueryComponent,
      includeArchived = includeArchived,
    )
    return safeTryFetch(ProjectQueryResponse.Failure) {
      httpClient.postJson(urlSupport.projectQuery.projectPreviewsUrl, request) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun countProjectPreviews(
    requestedProjectCountsForPhases: List<ProjectCountRequestForPhase>,
    loggedInUser: UserLoginName,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): ProjectCountQueryResponse {
    val request = ProjectCountQueryRequest(
      requestedProjectCountsForPhases = requestedProjectCountsForPhases,
      loggedInUser = loggedInUser,
    )
    return safeTryFetch(ProjectCountQueryResponse.Failure) {
      httpClient.postJson(urlSupport.projectQuery.projectCountsUrl, request) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun accountingProjectPreviews(
    processStatesToFilter: List<LizergyProcessStates>,
    processStatesToHide: List<LizergyProcessStates>,
    accountingQuerySelection: AccountingQuerySelection,
    filteredByCompanyCode: CompanyCode?,
    filteredByMaintainer: UserLoginName?,
    filteredByEditor: UserLoginName?,
    filterValueProject: String?,
    filterValueAddress: String?,
    filterByProjectState: LizergyProcessStates?,
    indexOfFirstVisibleProject: Int,
    indexOfLastVisibleProject: Int,
    sorting: ProjectQuerySorting,
    projectQueryComponent: ProjectQueryComponent?,
    howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements,
    loggedInUser: UserLoginName,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): AccountingProjectQueryResponse {
    val request = AccountingProjectQueryRequest(
      processStatesToFilter = processStatesToFilter,
      processStatesToHide = processStatesToHide,
      accountingQuerySelection = accountingQuerySelection,
      filteredByCompanyCode = filteredByCompanyCode,
      filteredByMaintainer = filteredByMaintainer,
      filteredByEditor = filteredByEditor,
      filterValueProject = filterValueProject,
      filterValueAddress = filterValueAddress,
      filterByProjectState = filterByProjectState,
      indexOfFirstVisibleProject = indexOfFirstVisibleProject,
      indexOfLastVisibleProject = indexOfLastVisibleProject,
      sorting = sorting,
      projectQueryComponent = projectQueryComponent,
      howDoYouLikeYourQuoteElements = howDoYouLikeYourQuoteElements,
      loggedInUser = loggedInUser,
    )
    return safeTryFetch(AccountingProjectQueryResponse.Failure) {
      httpClient.postJson(urlSupport.projectQuery.accountingProjectPreviewsUrl, request) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun allAccountingProjectPreviewsAsCSV(
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): AllAccountingProjectPreviewsAsCSVResponse {
    return safeTryFetch(AllAccountingProjectPreviewsAsCSVResponse.Failure) {
      httpClient.get(urlSupport.projectQuery.allAccountingProjectPreviewsAsCSVUrl) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  /**
   * Finds projects by the given search string
   */
  suspend fun searchProjects(
    searchTerm: String,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchSearchForProjectsResponse {
    return safeTryFetch(FetchSearchForProjectsResponse.Failure) {
      httpClient.get(urlSupport.projectQuery.projectsSearch()) {
        featureFlagsHeader()
        parameter(PlannerApiPathConstants.Parameters.searchTerm, searchTerm)
        additionalRequestConfiguration()
      }.body()
    }
  }


  suspend fun fetchBlueprint(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchBlueprintResponse {
    return safeTryFetch(FetchBlueprintResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.blueprint(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchOrderSpecialMaterial(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchOrderSpecialMaterialResponse {
    return safeTryFetch(FetchOrderSpecialMaterialResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.orderSpecialMaterial(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchGridAssessment(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchGridAssessmentResponse {
    return safeTryFetch(FetchGridAssessmentResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.gridAssessment(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchAssemblyPortfolio(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchAssemblyPortfolioResponse {
    return safeTryFetch(FetchAssemblyPortfolioResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.assemblyPortfolio(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchAdvanceInvoice(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchAdvanceInvoiceResponse {
    return safeTryFetch(FetchAdvanceInvoiceResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.advanceInvoice(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchAssemblyRoof(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchAssemblyRoofResponse {
    return safeTryFetch(FetchAssemblyRoofResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.assemblyRoof(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchAssemblyBasement(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchAssemblyBasementResponse {
    return safeTryFetch(FetchAssemblyBasementResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.assemblyBasement(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchSwitchMeterBox(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchSwitchMeterBoxResponse {
    return safeTryFetch(FetchSwitchMeterBoxResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.switchMeterBox(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchStartupOperations(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchStartupOperationsResponse {
    return safeTryFetch(FetchStartupOperationsResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.startupOperations(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchFinishingUp(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchFinishingUpResponse {
    return safeTryFetch(FetchFinishingUpResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.finishingUp(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchFinalAccount(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchFinalAccountResponse {
    return safeTryFetch(FetchFinalAccountResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.finalAccount(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchDocumentation(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchDocumentationResponse {
    return safeTryFetch(FetchDocumentationResponse.failure()) {
      httpClient.get(urlSupport.projectQuery.documentation(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun listConfigurations(
    projectId: PhotovoltaicsProjectId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchConfigurationsResponse {
    return safeTryFetch(FetchConfigurationsResponse.Failure) {
      httpClient.get(urlSupport.projectQuery.configurationsForProject(projectId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun findConfiguration(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchConfigurationResponse {
    return safeTryFetch(FetchConfigurationResponse.Failure) {
      httpClient.get(urlSupport.projectQuery.configuration(projectId, configurationId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun listModuleLayouts(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchModuleLayoutsResponse {
    return safeTryFetch(FetchModuleLayoutsResponse.Failure) {
      httpClient.get(urlSupport.projectQuery.moduleLayouts(projectId, configurationId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun listQuoteSnapshots(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchQuoteSnapshotsResponse {
    return safeTryFetch(FetchQuoteSnapshotsResponse.Failure) {
      httpClient.get(urlSupport.projectQuery.quoteSnapshots(projectId, configurationId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun fetchQuoteSnapshot(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    quoteSnapshotId: QuoteSnapshot.QuoteSnapshotId,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): FetchQuoteSnapshotResponse {
    return safeTryFetch(FetchQuoteSnapshotResponse.Failure) {
      httpClient.get(urlSupport.projectQuery.quoteSnapshot(projectId, configurationId, quoteSnapshotId)) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }


  suspend fun sendProject(
    project: SerializedProject,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.project(project.projectId), project) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun sendProject(
    resolvedProject: ResolvedProject,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return sendProject(resolvedProject.unResolve(), additionalRequestConfiguration)
  }

  /**
   * Saves the given [ResolvedProject] including all its individual parts
   */
  suspend fun sendResolvedProject(
    sendResolvedProjectRequest: SendResolvedProjectRequest,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.resolvedProject(sendResolvedProjectRequest.photovoltaicsProject.projectId), sendResolvedProjectRequest) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun sendResolvedProject(
    resolvedProject: ResolvedProject,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return sendResolvedProject(SendResolvedProjectRequest(resolvedProject), additionalRequestConfiguration)
  }

  /**
   * Saves the given [ResolvedProject] including all its individual parts
   */
  suspend fun sendMultipleResolvedProjects(
    projectsRequests: List<SendResolvedProjectRequest>,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.multipleResolvedProjects(), projectsRequests) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun archiveProject(
    projectId: PhotovoltaicsProjectId,
    archiveReasons: ArchiveReasons,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): ArchiveProjectResponse {
    return safeTryFetch(ArchiveProjectResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.archiveProjectUrl(projectId), archiveReasons) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  /**
   * Saves the [Blueprint] for the given project
   */
  suspend fun sendBlueprint(
    blueprint: SerializedBlueprint,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.blueprint(blueprint.projectId), blueprint) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun sendBlueprint(
    blueprint: ResolvedBlueprint,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return sendBlueprint(blueprint.unResolve(), additionalRequestConfiguration)
  }

  suspend fun sendVerification(
    projectId: PhotovoltaicsProjectId,
    verification: Verification,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.verifications(projectId), verification) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun sendGridAssessment(
    projectId: PhotovoltaicsProjectId,
    gridAssessment: GridAssessment,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.gridAssessment(projectId), gridAssessment) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun sendAssemblyPortfolio(
    projectId: PhotovoltaicsProjectId,
    assemblyPortfolio: ResolvedAssemblyPortfolio,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.assemblyPortfolio(projectId), assemblyPortfolio.unResolve()) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun sendAssemblyBasement(
    projectId: PhotovoltaicsProjectId,
    assemblyBasement: AssemblyBasement,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.assemblyBasement(projectId), assemblyBasement) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun sendConfiguration(
    projectId: PhotovoltaicsProjectId,
    configuration: SerializedPhotovoltaicsConfiguration,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.configuration(projectId, configuration.configurationId), configuration) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  /**
   * Saves the [PlannerConfiguration] for the given project
   */
  suspend fun sendConfiguration(
    projectId: PhotovoltaicsProjectId,
    configuration: PlannerConfiguration,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return sendConfiguration(projectId, configuration.unResolve(), additionalRequestConfiguration)
  }

  suspend fun sendQuoteSnapshot(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    quoteSnapshot: QuoteSnapshot,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.quoteSnapshot(projectId, configurationId, quoteSnapshot.id), quoteSnapshot) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  /**
   * Saves the entire [QuoteConfiguration] for the given project
   */
  suspend fun sendQuoteConfiguration(
    projectId: PhotovoltaicsProjectId,
    saveQuoteConfigurationRequest: SaveQuoteConfigurationRequest,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.quoteConfiguration(projectId, saveQuoteConfigurationRequest.configuration.configurationId), saveQuoteConfigurationRequest) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun sendQuoteConfiguration(
    projectId: PhotovoltaicsProjectId,
    quoteConfiguration: QuoteConfiguration,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return sendQuoteConfiguration(projectId, SaveQuoteConfigurationRequest(quoteConfiguration), additionalRequestConfiguration)
  }

  suspend fun sendEarningsDistribution(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    earningsDistribution: SerializedEarningsDistribution,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.earningsDistribution(projectId, configurationId), earningsDistribution) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

  suspend fun sendEarningsDistribution(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    earningsDistribution: ResolvedEarningsDistribution,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return sendEarningsDistribution(projectId, configurationId, earningsDistribution.unResolve(), additionalRequestConfiguration)
  }

  suspend fun sendManualQuoteElements(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    manualQuoteElements: ManualQuoteElements,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.manualQuoteElements(projectId, configurationId), manualQuoteElements) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }


  /**
   * Saves the [ModuleLayoutsConfiguration] for the given project
   */
  suspend fun sendModuleLayouts(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    moduleLayouts: ModuleLayoutsConfiguration,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.moduleLayouts(projectId, configurationId), moduleLayouts) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }


  suspend fun sendQuoteSnapshots(
    projectId: PhotovoltaicsProjectId,
    configurationId: PhotovoltaicsConfigurationId,
    quoteSnapshot: QuoteSnapshot,
    additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {},
  ): EmptyResponse {
    return safeTryFetch(EmptyResponse.failure()) {
      httpClient.putJson(urlSupport.projectQuery.quoteSnapshot(projectId, configurationId, quoteSnapshot.id), quoteSnapshot) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }.body()
    }
  }

}
