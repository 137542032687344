@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.financial.quote.QuoteLegalNotice
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lizergy.model.configuration.components.AssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.components.ElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.lizergy.model.configuration.components.ScaffoldingArea
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.WallboxSelection
import it.neckar.lizergy.model.configuration.quote.economics.FinancingType
import it.neckar.lizergy.model.configuration.quote.economics.YearlyCostInformation
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.EarningsDistribution
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.project.BelongsToCompany
import it.neckar.lizergy.model.project.HasProcessState
import it.neckar.open.collections.fastForEach
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.si.km
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents a [PhotovoltaicsConfiguration] for a project
 *
 * A [PhotovoltaicsConfiguration] is supposed to hold *ONLY* technical information about the project
 */
interface PhotovoltaicsConfiguration : HasUuid, BelongsToCompany, HasLifeCycle, HasProcessState {

  val configurationId: PhotovoltaicsConfigurationId

  /**
   * The short label
   */
  val label: String?

  /**
   * The (longer) description
   */
  val description: String?

  /**
   * The time, when the configuration was created
   */
  val creationTime: @ms Double

  @Deprecated("Replaced by new Process State Service")
  val editor: UserLoginName?

  val location: LocationInformation

  val wallboxSelection: WallboxSelection

  val powerUsageScenario: PowerUsageScenario

  val pricesTrendScenario: PricesTrendScenario

  val facilityConfiguration: FacilityConfiguration

  val assemblyConfiguration: AssemblyConfiguration

  /**
   * The yearly costs
   */
  val yearlyCosts: YearlyCostInformation
  val financing: FinancingType

  val shippingDistanceManual: @km Int?
  val shippingDistanceCalculated: @km Int?

  val electricityWorkConfiguration: ElectricityWorkConfiguration

  /**
   * Additional positions
   */
  val additionalPositions: ConfigurationItemsConfiguration
  val existingFacilitiesConfiguration: ExistingFacilitiesConfiguration
  val discountPercentage: @pct Double

  val zaehlerNummer: String
  val flurstueckNummer: String

  val facilityOperator1: FacilityOperatorInformation
  val facilityOperator2: FacilityOperatorInformation

  val einspeiseart: Einspeiseart

  /**
   * Optional, additional line to be put at the end of the quote
   */
  val legalNoticeAdditionalLine: String?
  val legalNotes: List<LegalNote>

  val manualPowerConsumptionDistribution: ManualPowerConsumptionDistribution?

  val ueberspannungsSchutzType: String
    get() = buildString {
      append("Überspannungsschutz DC ")
      if (assemblyConfiguration.externerBlitzschutzVorhanden) {
        append("Typ 1 & ")
      }
      append("Typ 2")
    }

  val earningsDistribution: EarningsDistribution

  val manualQuoteElements: ManualQuoteElements

  @Deprecated("No longer required")
  val signedQuoteReceived: Boolean


  override val uuid: Uuid
    get() = configurationId.uuid

  override val belongsToCompanies: Set<CompanyCode>
    get() = buildSet {
      add(sellingCompany)
      add(earningsDistribution.tippgeber.company)
      add(earningsDistribution.vertriebProjekterfassung.company)
      add(earningsDistribution.vertriebAngebotsvorstellung.company)
      add(earningsDistribution.technischePlanungDach.company)
      add(earningsDistribution.technischePlanungElektrik.company)
      add(earningsDistribution.montageDach.company)
      add(earningsDistribution.montageGeruest.company)
      add(earningsDistribution.elektroInstallation.company)
      add(earningsDistribution.netzvoranfrage.company)
    }

  val scaffoldingSupplied: Boolean
    get() = assemblyConfiguration.assemblyStatus !is ScaffoldingArea

  fun generateQuoteLegalNotice(): QuoteLegalNotice {
    return QuoteLegalNotice(
      buildList {
        legalNoticeAdditionalLine?.let { legalNoticeAdditionalLines ->
          addAll(legalNoticeAdditionalLines.split("\n", "\uE00A"))
        }

        legalNotes.fastForEach {
          val legalString = buildString {
            append(it.legalText)
            if (it.legalText.endsWith(".").not()) append(".")
          }
          add(legalString)
        }

        if (scaffoldingSupplied && legalNotes.contains(LegalNote.DachfanggeruestGestellt).not()) {
          add("Dachfanggerüst wird bauseits BG-konform für Dacharbeiten gestellt.")
        }

        assemblyConfiguration.assemblyStatus.let {
          if (it.dachhakenGestellt == true) {
            add("Modulstützen passend zum Ziegeltyp werden vom Zimmermann geliefert. Absprache mit Zimmermann notwendig.")
          }
          if (it.dachhakenSetzen == true) {
            add("Unterkonstruktion/Modulstützen werden bauseits montiert.")
          }
          if (it.schienenMontage == true) {
            add("Schienen werden bauseits montiert.")
          }
          if (it.kabelZiehenUndModuleMontieren == true) {
            add("Dach wird bauseits montiert inklusive DC-Kabelverlegung.")
          }
        }
      }
    )
  }

  fun generateListOfLegalNotes(includeAssemblyConfiguration: Boolean = true): List<String> {
    return buildList {
      legalNoticeAdditionalLine?.let { legalNoticeAdditionalLines ->
        addAll(legalNoticeAdditionalLines.split("\n", "\uE00A"))
      }

      addAll(legalNotes.map {
        buildString {
          append(it.legalText)
          if (it.legalText.endsWith(".").not()) append(".")
        }
      })

      if (scaffoldingSupplied && legalNotes.contains(LegalNote.DachfanggeruestGestellt).not()) {
        add("Dachfanggerüst wird bauseits BG-konform für Dacharbeiten gestellt.")
      }

      if (includeAssemblyConfiguration) {
        assemblyConfiguration.assemblyStatus.let {
          if (it.dachhakenGestellt == true) {
            add("Modulstützen passend zum Ziegeltyp werden vom Zimmermann geliefert. Absprache mit Zimmermann notwendig.")
          }
          if (it.dachhakenSetzen == true) {
            add("Unterkonstruktion/Modulstützen werden bauseits montiert.")
          }
          if (it.schienenMontage == true) {
            add("Schienen werden bauseits montiert.")
          }
          if (it.kabelZiehenUndModuleMontieren == true) {
            add("Dach wird bauseits montiert inklusive DC-Kabelverlegung.")
          }
        }
      }
    }
  }


  /**
   * An id for a [PhotovoltaicsConfiguration]
   */
  @Serializable
  data class PhotovoltaicsConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): PhotovoltaicsConfigurationId {
        return PhotovoltaicsConfigurationId(randomUuid4())
      }
    }
  }

}
