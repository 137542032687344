@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.project.previews

import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.PartnerCompanyProfile
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.sum
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration.BatteryConfigurationId
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModulesReport
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.lizergy.model.income.IncomePercentage
import it.neckar.lizergy.model.income.IncomePercentages
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.UuidAndProcessStateEntries
import it.neckar.lizergy.model.stumps.RestArbeiten
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.processStates.current
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Contains a project and some preview values
 *
 * Can be used to load many projects - that can be shown in a table
 */
interface AccountingProjectPreview : ProjectPreview {
  @Serializable
  enum class QuoteElements {
    Frozen,
    UpToDate,
    Whatever,
  }

  val currentConfigurationPreview: AccountingConfigurationPreview?
}

interface AccountingConfigurationPreview : ConfigurationPreview {
  val upToDateQuoteElements: PreviewQuoteElements

  override fun getCalculatedQuoteElements(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): PreviewQuoteElements? {
    return when (howDoYouLikeYourQuoteElements) {
      AccountingProjectPreview.QuoteElements.Frozen -> quoteElements
      AccountingProjectPreview.QuoteElements.UpToDate -> upToDateQuoteElements
      AccountingProjectPreview.QuoteElements.Whatever -> quoteElements ?: upToDateQuoteElements
    }
  }
}

@Serializable
data class AccountingResolvedProjectPreview(

  override val projectId: PhotovoltaicsProjectId,

  override val projectName: String,

  override val sellingCompanyInformation: PlannerCompanyInformation,

  override val customer: Customer,

  override val maintainerInformation: UserInformation,

  override val acquisitionDate: Double,

  override val blueprintPreview: ResolvedBlueprintPreview,
  override val configurationPreviews: List<AccountingResolvedConfigurationPreview>,

  override val orderSpecialMaterial: UuidAndProcessStateEntries,
  override val gridAssessment: UuidAndProcessStateEntries,
  override val assemblyPortfolio: UuidAndProcessStateEntries,
  override val advanceInvoice: UuidAndProcessStateEntries,
  override val assemblyRoof: UuidAndProcessStateEntries,
  override val assemblyBasement: UuidAndProcessStateEntries,
  override val switchMeterBox: UuidAndProcessStateEntries,
  override val startupOperations: UuidAndProcessStateEntries,
  override val finishingUp: UuidAndProcessStateEntries,
  override val finalAccount: UuidAndProcessStateEntries,
  override val documentation: UuidAndProcessStateEntries,

  override val verification: Verification?,

  override val restArbeiten: RestArbeiten,

  override val belongsToCompanies: Set<CompanyCode>,

  override val processStateEntries: List<LizergyProcessStateEntry>?,

  override val archiveReasons: ArchiveReasons,

  ) : AccountingProjectPreview, ServerProjectPreview {
  override val currentConfigurationPreview: AccountingResolvedConfigurationPreview?
    get() = super.currentConfigurationPreview as AccountingResolvedConfigurationPreview?
}

@Serializable
data class AccountingResolvedConfigurationPreview(
  override val configurationId: PhotovoltaicsConfigurationId,
  override val configurationName: String,
  override val sellingCompany: CompanyCode,
  override val location: LocationInformation,
  override val modulesReport: ResolvedModulesReport,
  override val inverterSelection: ResolvedInverterSelection,
  val batteryConfiguration: BatteryConfiguration?,
  override val eigenmontage: Boolean,
  override val quoteElements: PreviewQuoteElements?,
  override val upToDateQuoteElements: PreviewQuoteElements,
  override val manualQuoteElements: ManualQuoteElements,
  override val earningsDistribution: ResolvedEarningsDistribution,
  override val worstProblem: ProblemType? = null,
  override val formattedProblems: String = "",
  override val processStateEntries: List<LizergyProcessStateEntry>?,
  override val processStateEditHistory: PositionEditHistory<OLDProcessState>? = null,
  override val editorInformation: UserInformation?,
) : ServerConfigurationPreview, AccountingConfigurationPreview {
  constructor(
    configurationId: PhotovoltaicsConfigurationId,
    configurationName: String,
    sellingCompany: CompanyCode,
    location: LocationInformation,
    editorInformation: UserInformation?,
    modulesReport: ResolvedModulesReport,
    inverterSelection: ResolvedInverterSelection,
    batteryConfiguration: BatteryConfiguration?,
    eigenmontage: Boolean,
    quoteElements: PreviewQuoteElements?,
    upToDateQuoteElements: PreviewQuoteElements,
    manualQuoteElements: ManualQuoteElements,
    earningsDistribution: ResolvedEarningsDistribution,
    worstProblem: ProblemType? = null,
    formattedProblems: String = "",
    processStateEntries: List<LizergyProcessStateEntry>?,
    processStateEditHistory: PositionEditHistory<OLDProcessState>? = null,
    userResolver: UserResolver,
  ) : this(
    configurationId = configurationId,
    configurationName = configurationName,
    sellingCompany = sellingCompany,
    location = location,
    modulesReport = modulesReport,
    inverterSelection = inverterSelection,
    batteryConfiguration = batteryConfiguration,
    eigenmontage = eigenmontage,
    quoteElements = quoteElements,
    upToDateQuoteElements = upToDateQuoteElements,
    manualQuoteElements = manualQuoteElements,
    earningsDistribution = earningsDistribution,
    worstProblem = worstProblem,
    formattedProblems = formattedProblems,
    processStateEntries = processStateEntries,
    processStateEditHistory = processStateEditHistory,
    editorInformation = processStateEntries?.current()?.let { userResolver[it.assignedTo] } ?: editorInformation,
  )

  override val batteryConfigurationId: BatteryConfigurationId?
    get() = batteryConfiguration?.id

  val softwareIncomePercentage: IncomePercentage
    get() = if (earningsDistribution.includesPartnerCompanies) IncomePercentages.softwareWithPartner else IncomePercentages.softwareWithoutPartner


  fun getEarningsForCompany(forCompany: CompanyCode, howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return buildList {
      IncomePercentages.allWithoutSoftware.fastForEachIndexed { index, incomePercentage ->
        val earningsCompanyEntry = earningsDistribution.all[index]
        val belongsToCompany = earningsCompanyEntry.company == forCompany
        if (belongsToCompany) add(earningsCompanyEntry.manualEarnings ?: getEarningsForIncomePercentage(incomePercentage, howDoYouLikeYourQuoteElements))
      }
    }.sum()
  }

  fun getEarningsForMainCompany(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    val netPrices = getNetPrice(howDoYouLikeYourQuoteElements)
    val totalEarningsForThirdPartyCompanies = getTotalEarningsForThirdPartyCompanies(howDoYouLikeYourQuoteElements)
    return netPrices - totalEarningsForThirdPartyCompanies
  }

  fun getNeckarITEarnings(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return earningsDistribution.neckarITAccountingStatus.manualEarnings ?: getEarningsForIncomePercentage(softwareIncomePercentage, howDoYouLikeYourQuoteElements)
  }

  fun getTotalEarningsForPartnerCompanies(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return buildList {
      IncomePercentages.allWithoutSoftware.fastForEachIndexed { index, incomePercentage ->
        val earningsCompanyEntry = earningsDistribution.all[index]
        if (earningsCompanyEntry.companyProfile is PartnerCompanyProfile) {
          add(earningsCompanyEntry.manualEarnings ?: getEarningsForIncomePercentage(incomePercentage, howDoYouLikeYourQuoteElements))
        }
      }
    }.sum()
  }

  fun getTotalEarningsForThirdPartyCompanies(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    val totalEarningsForPartnerCompanies = getTotalEarningsForPartnerCompanies(howDoYouLikeYourQuoteElements)
    val totalNeckarITEarnings = getNeckarITEarnings(howDoYouLikeYourQuoteElements)
    return totalEarningsForPartnerCompanies + totalNeckarITEarnings
  }
}
