package components.project

import com.benasher44.uuid.Uuid
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteProcessStates
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.si.ms
import it.neckar.processStates.ProcessStatePhase
import it.neckar.processStates.ProcessStates
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.uuid.HasUuid
import kotlinx.css.*
import kotlinx.html.classes
import kotlinx.html.title
import plannerI18nConfiguration
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUserResolver
import styled.*

fun RBuilder.processStatesList(forUuid: Uuid): Unit = child(ProcessStatesList) {
  attrs {
    this.forUuid = forUuid
  }
}

fun RBuilder.processStatesList(forComponent: HasUuid): Unit = processStatesList(forComponent.uuid)


val ProcessStatesList: FC<ProcessStatesListProps> = fc("ProcessStatesList") { props ->
  val forUuid = props::forUuid.safeGet()

  styledDiv {
    attrs {
      classes = setOf("my-3")
    }

    css {
      maxHeight = 200.px
      overflowY = Overflow.auto
    }

    infiniteProcessStatesList(forUuid)
  }
}


fun RBuilder.infiniteProcessStatesList(forUuid: Uuid): Unit = child(InfiniteProcessStatesList) {
  attrs {
    this.forUuid = forUuid
  }
}

fun RBuilder.infiniteProcessStatesList(forComponent: HasUuid): Unit = infiniteProcessStatesList(forComponent.uuid)

fun RBuilder.infiniteScrollingProcessStatesList(forComponent: HasUuid, classes: String = "col-lg-6"): Unit {
  styledDiv {
    attrs {
      this.classes = setOf("$classes col-12")
    }

    css {
      maxHeight = 200.px
      overflowY = Overflow.auto
    }

    infiniteProcessStatesList(forComponent)
  }
}

val InfiniteProcessStatesList: FC<ProcessStatesListProps> = fc("InfiniteProcessStatesList") { props ->
  val forUuid = props::forUuid.safeGet()
  val processStateEntries = useLoadProcessStatesForComponent(forUuid)?.reversed()

  processStateEntries?.fastForEachIndexed { index, processStateEntry ->
    p {
      if (index == 0) {
        processStatePillWithDateAndEditor(forUuid, processStateEntry)
      } else {
        processStateWithDateAndEditor(processStateEntry)
      }
    }
  }
}


fun RBuilder.processStatePillWithDate(processStateEntry: LizergyProcessStateEntry) {
  val processState = processStateEntry.processState

  processStatePill(processStateEntry)
  p("form-text") {
    +processState.formatDate(processStateEntry.assignedAt, plannerI18nConfiguration)
  }
}

fun RBuilder.processStatePillWithDateAndEditor(forUuid: Uuid, processStateEntry: LizergyProcessStateEntry): Unit = child(ProcessStatePillWithDateAndEditor) {
  attrs {
    this.forUuid = forUuid
    this.processStateEntry = processStateEntry
  }
}

fun RBuilder.processStatePillWithDateAndEditor(processStateEntry: LizergyProcessStateEntry): Unit = child(ProcessStatePillWithDateAndEditor) {
  attrs {
    this.forUuid = null
    this.processStateEntry = processStateEntry
  }
}

val ProcessStatePillWithDateAndEditor: FC<ProcessStateWithDateAndEditorProps> = fc("ProcessStatePillWithDateAndEditor") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val forUuid = props::forUuid.safeGet()
  val processStateEntry = props::processStateEntry.safeGet()
  val processState = processStateEntry.processState
  val assignedTo = userResolver[processStateEntry.assignedTo]
  val assignedBy = userResolver[processStateEntry.assignedBy]

  span {
    attrs {
      title = "Zugewiesen von: ${assignedBy.editorName}"
    }
    attrs["data-bs-toggle"] = "tooltip"
    processStatePill(processStateEntry)
    span("form-text ps-2") { +processState.formatDate(processStateEntry.assignedAt, plannerI18nConfiguration) }
    span("form-text ps-1") { +assignedTo.editorName }
    if (forUuid != null && loggedInUser.accessRights.canAccess(DeleteProcessStates)) {
      actionButtonWithConfirmationModal(
        icon = FontAwesomeIcons.trash,
        modalTitle = "Soll dieser Status gelöscht werden?",
        modalContent = {},
      ) {
        UiActions.archiveProcessState(
          processStateFor = forUuid,
          processStateToArchive = processStateEntry,
        )
      }
    }
  }
}

fun RBuilder.processStateWithDateAndEditor(processStateEntry: LizergyProcessStateEntry): Unit = child(ProcessStateWithDateAndEditor) {
  attrs {
    this.forUuid = null
    this.processStateEntry = processStateEntry
  }
}

val ProcessStateWithDateAndEditor: FC<ProcessStateWithDateAndEditorProps> = fc("ProcessStateWithDateAndEditor") { props ->
  val userResolver = useSelectUserResolver()
  val processStateEntry = props::processStateEntry.safeGet()
  val processState = processStateEntry.processState
  val assignedTo = userResolver[processStateEntry.assignedTo]
  val assignedBy = userResolver[processStateEntry.assignedBy]
  val colorClass = processState.colorClass()

  span {
    attrs {
      title = "Zugewiesen von: ${assignedBy.editorName}"
    }
    attrs["data-bs-toggle"] = "tooltip"
    span("badge bg-muted text-$colorClass") { +processStateEntry.format(plannerI18nConfiguration) }
    span("form-text ps-0") { +processState.formatDate(processStateEntry.assignedAt, plannerI18nConfiguration) }
    span("form-text ps-1") { +assignedTo.editorName }
  }
}


fun RBuilder.processStateStageRadioButtons(
  selectedProcessState: StateInstance<LizergyProcessStates?>,
  availableProcessStates: List<LizergyProcessStates>,
  previousProcessState: LizergyProcessStates?,
  nextProcessState: LizergyProcessStates?,
  dueDate: @ms Double?,
  editableStatus: EditableStatus,
) {
  div("d-grid gap-3") {

    previousProcessState?.let {
      processStateStageRadioButton(
        forProcessState = it,
        selectedProcessState = selectedProcessState,
        dueDate = dueDate,
        editableStatus = editableStatus,
      )
    }

    buttonGroup {
      availableProcessStates.fastForEach { forProcessState ->
        processStateStageRadioButton(
          forProcessState = forProcessState,
          selectedProcessState = selectedProcessState,
          dueDate = dueDate,
          editableStatus = editableStatus,
        )
      }
    }

    nextProcessState?.let {
      processStateStageRadioButton(
        forProcessState = it,
        selectedProcessState = selectedProcessState,
        dueDate = dueDate,
        editableStatus = editableStatus,
      )
    }

  }
}

fun RBuilder.processStateStageRadioButton(
  forProcessState: LizergyProcessStates,
  selectedProcessState: StateInstance<LizergyProcessStates?>,
  dueDate: @ms Double?,
  editableStatus: EditableStatus,
): Unit = child(ProcessStateStageRadioButton) {
  attrs {
    this.forProcessState = forProcessState
    this.selectedProcessState = selectedProcessState
    this.dueDate = dueDate
    this.editableStatus = editableStatus
  }
}

val ProcessStateStageRadioButton: FC<ProcessStateStageRadioButtonProps> = fc("ProcessStateStageRadioButton") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val forProcessState = props::forProcessState.safeGet()
  val selectedProcessState = props::selectedProcessState.getNotNull()
  val dueDate = props::dueDate.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val selected = selectedProcessState.value == forProcessState

  radioButton(
    buttonId = uniqueIdMemo("phasebtnradio$forProcessState"),
    value = selected,
    onChange = { selectedProcessState.setter(forProcessState) },
    editableStatus = editableStatus,
    labelClasses = "btn btn-outline-${forProcessState.colorClass()}",
  ) {
    addClassIf("fw-bold") { selected }
    +forProcessState.toProcessStateEntry(user = loggedInUser, assignedAt = nowMillis(), dueDate = dueDate, assignedBy = loggedInUser).format(plannerI18nConfiguration)
  }
}


fun RBuilder.processStatePill(processStateEntry: LizergyProcessStateEntry) {
  val processState = processStateEntry.processState
  val pillClass = processState.colorClass()

  span("badge rounded-pill bg-$pillClass") { +processStateEntry.format(plannerI18nConfiguration) }
}

fun ProcessStates.colorClass(): String {
  return when (inPhase) {
    ProcessStatePhase.Paused -> "light"
    ProcessStatePhase.Rejected -> "danger"
    ProcessStatePhase.Untouched -> "info"
    ProcessStatePhase.InProgress -> "dark"
    ProcessStatePhase.Waiting -> "warning"
    ProcessStatePhase.Done -> "primary"
  }
}


external interface ProcessStatesListProps : Props {
  var forUuid: Uuid
}

external interface ProcessStateWithDateAndEditorProps : Props {
  var forUuid: Uuid?
  var processStateEntry: LizergyProcessStateEntry
}

external interface ProcessStatePillProps : Props {
  var processStateEntry: LizergyProcessStateEntry
}

external interface ProcessStateStageRadioButtonProps : Props {
  var forProcessState: LizergyProcessStates
  var selectedProcessState: StateInstance<LizergyProcessStates?>
  var dueDate: @ms Double?
  var editableStatus: EditableStatus
}
