package it.neckar.lizergy.model.project

import com.benasher44.uuid.Uuid
import it.neckar.open.unit.si.ms

interface PhotovoltaicsProject : ProjectConfiguration {

  val acquisitionDate: @ms Double?

  val blueprintId: Uuid
  val configurationIds: List<Uuid>
  val orderSpecialMaterialId: Uuid
  val gridAssessmentId: Uuid
  val assemblyPortfolioId: Uuid
  val advanceInvoiceId: Uuid
  val assemblyRoofId: Uuid
  val assemblyBasementId: Uuid
  val switchMeterBoxId: Uuid
  val startupOperationsId: Uuid
  val finishingUpId: Uuid
  val finalAccountId: Uuid
  val documentationId: Uuid

  val verification: Verification?


  val containedUuids: List<Uuid>
    get() = buildList {
      add(projectId.uuid)
      add(blueprintId)
      addAll(configurationIds)
      add(orderSpecialMaterialId)
      add(gridAssessmentId)
      add(assemblyPortfolioId)
      add(advanceInvoiceId)
      add(assemblyRoofId)
      add(assemblyBasementId)
      add(switchMeterBoxId)
      add(startupOperationsId)
      add(finishingUpId)
      add(finalAccountId)
      add(documentationId)
    }
}
