package components.project.configuration.pricestrend

import it.neckar.open.formatting.format
import it.neckar.open.unit.currency.Cent
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 *
 */
fun RBuilder.pricesTrendScenarioForm(
  guaranteedFeedInPrice: StateInstance<@Cent Double?>,
  consumptionPrice: StateInstance<@Cent Double>,
  consumptionPriceIncreasePercentage: StateInstance<Double>,
  fallbackGuaranteedFeedInPrice: @Cent Double?,
  editableStatus: EditableStatus,
): Unit = child(pricesTrendScenarioForm) {
  attrs {
    this.guaranteedFeedInPrice = guaranteedFeedInPrice
    this.consumptionPrice = consumptionPrice
    this.consumptionPriceIncreasePercentage = consumptionPriceIncreasePercentage
    this.fallbackGuaranteedFeedInPrice = fallbackGuaranteedFeedInPrice
    this.editableStatus = editableStatus
  }
}

val pricesTrendScenarioForm: FC<PricesTrendScenarioFormProps> = fc("pricesTrendScenarioForm") { props ->
  val guaranteedFeedInPrice = props::guaranteedFeedInPrice.getNotNull()
  val consumptionPrice = props::consumptionPrice.getNotNull()
  val consumptionPriceIncreasePercentage = props::consumptionPriceIncreasePercentage.getNotNull()

  val fallbackGuaranteedFeedInPrice = props::fallbackGuaranteedFeedInPrice.safeGet()
  val editableStatus = props::editableStatus.safeGet()


  div("form-floating my-3") {

    nullableFloatingDoubleInputField(
      valueAndSetter = guaranteedFeedInPrice,
      fieldName = "guaranteedFeedInPrice",
      title = "${(guaranteedFeedInPrice.value ?: fallbackGuaranteedFeedInPrice?.format(2))?.let { "$it ct " } ?: ""}Einspeisevergütung (Cent/kWh)",
      numberConstraint = ZeroOrPositive,
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedBelow()
      }
    }

    div("row gx-0") {

      div("col-sm-7") {
        div("form-floating") {
          floatingDoubleInputField(
            valueAndSetter = consumptionPrice,
            fieldName = "consumptionPrice",
            title = "Netzbezugspreis (Cent/kWh)",
            numberConstraint = ZeroOrPositive,
            editableStatus = editableStatus,
          ) {
            attrs {
              mergedAbove()
              mergedRight()
              step = "0.1"
            }
          }
        }
      }

      div("col-sm-5") {
        div("form-floating") {
          floatingDoubleInputField(
            valueAndSetter = consumptionPriceIncreasePercentage,
            fieldName = "consumptionPriceIncreasePercentage",
            title = "Jährliche Strompreiserhöhung (%)",
            numberOfDecimals = 1,
            numberConstraint = ZeroOrPositive,
            editableStatus = editableStatus,
          ) {
            attrs {
              mergedAbove()
              mergedLeft()
              step = "0.1"
            }
          }
        }
      }

    }

  }
}

external interface PricesTrendScenarioFormProps : Props {
  var guaranteedFeedInPrice: StateInstance<@Cent Double?>
  var consumptionPrice: StateInstance<@Cent Double>
  var consumptionPriceIncreasePercentage: StateInstance<Double>
  var fallbackGuaranteedFeedInPrice: @Cent Double?
  var editableStatus: EditableStatus
}
