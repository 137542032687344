package it.neckar.lizergy.model.configuration.components

import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import kotlinx.serialization.Serializable

@Serializable
enum class Einspeiseart {
  Ueberschuss,
  Volleinspeisung,

  ;

  @Suppress("UNUSED_PARAMETER")
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration): String {
    return when (this) {
      Ueberschuss -> "Überschuss"
      Volleinspeisung -> "Volleinspeisung"
    }
  }
}
