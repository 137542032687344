package it.neckar.lizergy.model.assemblyPortfolio

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.ElementsSelection
import it.neckar.lizergy.model.ElementsSelectionEntry
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.energy.Capacity
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.Voltage
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.open.unit.si.cm
import it.neckar.open.unit.si.m
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid
import kotlinx.serialization.Serializable
import kotlin.time.Duration

interface AssemblyPortfolio : HasUuid {
  val projectId: PhotovoltaicsProjectId

  val projektLeiter: UserLoginName?
  val pvMontageDurch: CompanyCode?
  val ansprechpartnerDach: UserLoginName?
  val elektroMontageDurch: CompanyCode?
  val ansprechpartnerElektro: UserLoginName?

  val locationFirstName: String?
  val locationLastName: String?
  val locationPhone: String?
  val locationCellphone: String?
  val locationEmail: String?

  val anlagenPasswort: String?
  val einspeiseart: Einspeiseart?
  val wartungsvertrag: Boolean?
  val geruestVorhanden: String?

  val pvLeistung: PowerRating?
  val leerlaufspannung: Voltage?
  val verteilerNetzbetreiberName: String?

  val kalkulierteStunden: Duration?
  val ueberspannungsschutz: String?
  val messkonzept: String?

  val bleibenderZaehler: String?
  val zusammenZuLegendeZaehlerNummern: List<String>?

  val slsEinbauen: Boolean?
  val unterverteilerEinbauen: Boolean?
  val erdspiessSetzen: Boolean?
  val waermepumpenAnbindung: Boolean?
  val internetAnschluss: Boolean?

  val assemblyPortfolioModuleLayouts: List<AssemblyPortfolioModuleLayout>

  val wechselrichter: StringSelection

  val anzahlOptimierer: Int?
  val optimierer: String?

  val speicher: String?

  val ladestationen: CustomWallboxSelection<*>
  val heizstab: AssemblyPortfolioHeaterRod

  val ersatzstrom: String?
  val sunnyHomeManagerOderMeter: String?

  val firstEingespeist: Boolean
  val oberleitungZuIsolieren: Boolean?
  val satAnlageZuVersetzen: Boolean?
  val antenneEntfernen: Boolean?

  val besonderheiten: String?
  val bestandsanlagen: String?
  val sonstigeArbeiten: String?

  @Serializable
  sealed interface AssemblyPortfolioWallbox : HasUuid {
    val id: Wallbox.WallboxId
    val name: String?
    val leistung: Capacity?
    val drosselung: Boolean
    val kabellaenge: @m Double?
    val montageZeitInklusive: Boolean
    val montageZeit: Duration?

    override val uuid: Uuid
      get() = id.uuid
  }

  @Serializable
  sealed interface AssemblyPortfolioHeaterRod {
    val name: String?
    val leistung: Capacity?
    val montageZeitInklusive: Boolean
    val montageZeit: Duration?
  }

  @Serializable
  sealed interface AssemblyPortfolioModuleLayout : HasUuid {
    val id: ModuleLayoutId
    val name: String?
    val anzahlModule: Int?
    val module: String?
    val montageSystem: String?
    val montageSystemSuffix: String?
    val anzahlBlechZiegel: Int?
    val blechZiegel: String?
    val moduleImKreuzverbund: Int?
    val aufdachdaemmung: @cm Int?
    val verstaerkteSchienen: Boolean?
    val schwarzeKlemmen: Boolean?

    override val uuid: Uuid
      get() = id.uuid
  }

  @Serializable
  data class Layout(
    override val id: ModuleLayoutId,
    override val name: String?,
    override val anzahlModule: Int?,
    override val module: String?,
    override val montageSystem: String?,
    override val montageSystemSuffix: String?,
    override val anzahlBlechZiegel: Int?,
    override val blechZiegel: String?,
    override val moduleImKreuzverbund: Int?,
    override val aufdachdaemmung: @cm Int?,
    override val verstaerkteSchienen: Boolean? = null,
    override val schwarzeKlemmen: Boolean? = null,
  ) : AssemblyPortfolioModuleLayout {
    companion object {
      fun getEmpty(id: ModuleLayoutId = ModuleLayoutId.random()): Layout {
        return Layout(id, null, null, null, null, null, null, null, null, null, null, null)
      }
    }
  }

  @Serializable
  data class Ladestation(
    override val id: Wallbox.WallboxId,
    override val name: String?,
    override val leistung: Capacity?,
    override val drosselung: Boolean,
    override val kabellaenge: @m Double?,
    override val montageZeitInklusive: Boolean = true,
    override val montageZeit: Duration?,
  ) : AssemblyPortfolioWallbox {
    companion object {
      fun getEmpty(id: Wallbox.WallboxId = Wallbox.WallboxId.random(), drosselung: Boolean): Ladestation {
        return Ladestation(id, null, null, drosselung, null, true, null)
      }
    }
  }

  @Serializable
  data class HeaterRod(
    override val name: String?,
    override val leistung: Capacity?,
    override val montageZeitInklusive: Boolean = true,
    override val montageZeit: Duration?,
  ) : AssemblyPortfolioHeaterRod {
    companion object {
      fun getEmpty(): HeaterRod {
        return HeaterRod(null, null, true, null)
      }
    }
  }

  @Serializable
  data class CustomWallboxSelection<T : AssemblyPortfolioWallbox>(
    override val entries: List<ElementsSelectionEntry<T>>,
  ) : ElementsSelection<T> {
    override fun T.format(): String {
      return this.format()
    }

    companion object {
      fun <T : AssemblyPortfolioWallbox> empty(): CustomWallboxSelection<T> {
        return CustomWallboxSelection(emptyList())
      }
    }
  }

  @Serializable
  data class StringSelection(
    override val entries: List<ElementsSelectionEntry<String?>>,
  ) : ElementsSelection<String?> {
    override fun String?.format(): String {
      return this?.format() ?: "-"
    }

    companion object {
      fun empty(): StringSelection {
        return StringSelection(emptyList())
      }
    }
  }


  companion object {
    val messkonzepte: List<String> = listOf(
      "MK 1",
      "MK 2",
      "MK 3",
      "MK 4",
      "MK 6",
      "MK 7",
      "MK 7.1",
      "MK 11",
      "MK 40",
      "MK 3 AC-Speicher",
      "MK 3 DC-Speicher",
      "MK 4 AC-Speicher",
      "MK 4 DC-Speicher",
      "MK 7 AC-Speicher",
      "MK 7 DC-Speicher",
      "MK 40 AC-Speicher",
      "MK 40 DC-Speicher",
    )
  }
}


