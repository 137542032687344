package components.project

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*
import services.UiActions

fun RBuilder.archiveReasonsOverview(project: ResolvedProject, archiveReasons: ArchiveReasons): Unit = child(ArchiveReasonsOverview) {
  attrs {
    this.project = project
    this.archiveReasons = archiveReasons
  }
}


val ArchiveReasonsOverview: FC<ArchiveReasonsOverviewProps> = fc("ArchiveReasonsOverview") { props ->
  val project = props::project.safeGet()
  val archiveReasons = props::archiveReasons.safeGet()

  val noLongerInterestedState = useState(archiveReasons.noLongerInterested)
  val otherSellerWasCheaperState = useState(archiveReasons.otherSellerWasCheaper)
  val otherSellerInformedBetterState = useState(archiveReasons.otherSellerInformedBetter)
  val otherSellerWasFasterState = useState(archiveReasons.otherSellerWasFaster)
  val quoteWasNotProfitableState = useState(archiveReasons.quoteWasNotProfitable)
  val tookTooLongState = useState(archiveReasons.tookTooLong)
  val noResponseFromCustomerState = useState(archiveReasons.noResponseFromCustomer)
  val deliveryTimesTooLingState = useState(archiveReasons.deliveryTimesTooLing)
  val customerWantedDifferentManufacturerState = useState(archiveReasons.customerWantedDifferentManufacturer)
  val customerWantedCloudSolutionState = useState(archiveReasons.customerWantedCloudSolution)
  val tooExpensiveState = useState(archiveReasons.tooExpensive)
  val waitingTimeTooLongState = useState(archiveReasons.waitingTimeTooLong)
  val otherReasonsState = useState(archiveReasons.otherReasons)


  useEffect(
    noLongerInterestedState.value,
    otherSellerWasCheaperState.value,
    otherSellerInformedBetterState.value,
    otherSellerWasFasterState.value,
    quoteWasNotProfitableState.value,
    tookTooLongState.value,
    noResponseFromCustomerState.value,
    deliveryTimesTooLingState.value,
    customerWantedDifferentManufacturerState.value,
    customerWantedCloudSolutionState.value,
    tooExpensiveState.value,
    waitingTimeTooLongState.value,
    otherReasonsState.value,
  ) {
    val archiveReasonsToSave = archiveReasons.copy(
      noLongerInterested = noLongerInterestedState.value,
      otherSellerWasCheaper = otherSellerWasCheaperState.value,
      otherSellerInformedBetter = otherSellerInformedBetterState.value,
      otherSellerWasFaster = otherSellerWasFasterState.value,
      quoteWasNotProfitable = quoteWasNotProfitableState.value,
      tookTooLong = tookTooLongState.value,
      noResponseFromCustomer = noResponseFromCustomerState.value,
      deliveryTimesTooLing = deliveryTimesTooLingState.value,
      customerWantedDifferentManufacturer = customerWantedDifferentManufacturerState.value,
      customerWantedCloudSolution = customerWantedCloudSolutionState.value,
      tooExpensive = tooExpensiveState.value,
      waitingTimeTooLong = waitingTimeTooLongState.value,
      otherReasons = otherReasonsState.value,
    )

    UiActions.saveProject(
      project.copy(archiveReasons = archiveReasonsToSave)
    )
  }


  h4("my-4") {
    +"Gründe für Projektabsage"
  }

  div {
    checkbox(
      valueAndSetter = noLongerInterestedState,
      title = "Aktuell nicht mehr interessiert",
      fieldName = "noLongerInterestedState",
    )
  }
  div {
    checkbox(
      valueAndSetter = otherSellerWasCheaperState,
      title = "Anderer Anbieter war günstiger",
      fieldName = "otherSellerWasCheaperState",
    )
  }
  div {
    checkbox(
      valueAndSetter = otherSellerInformedBetterState,
      title = "Anderer Anbieter hat besser beraten",
      fieldName = "otherSellerInformedBetterState",
    )
  }
  div {
    checkbox(
      valueAndSetter = otherSellerWasFasterState,
      title = "Anderer Anbieter war schneller",
      fieldName = "otherSellerWasFasterState",
    )
  }
  div {
    checkbox(
      valueAndSetter = quoteWasNotProfitableState,
      title = "Angebot war nicht wirtschaftlich",
      fieldName = "quoteWasNotProfitableState",
    )
  }
  div {
    checkbox(
      valueAndSetter = tookTooLongState,
      title = "Bearbeitungszeit zu lang",
      fieldName = "tookTooLongState",
    )
  }
  div {
    checkbox(
      valueAndSetter = noResponseFromCustomerState,
      title = "Keine Rückmeldung vom Kunden",
      fieldName = "noResponseFromCustomerState",
    )
  }
  div {
    checkbox(
      valueAndSetter = deliveryTimesTooLingState,
      title = "Zu lange Lieferzeiten",
      fieldName = "deliveryTimesTooLingState",
    )
  }
  div {
    checkbox(
      valueAndSetter = customerWantedDifferentManufacturerState,
      title = "Möchte anderen Hersteller (z.B. Fronius, E3/DC, usw.). Bitte under Sonstige Begründung",
      fieldName = "customerWantedDifferentManufacturerState",
    )
  }
  div {
    checkbox(
      valueAndSetter = customerWantedCloudSolutionState,
      title = "Möchte Cloudlösung",
      fieldName = "customerWantedCloudSolutionState",
    )
  }
  div {
    checkbox(
      valueAndSetter = tooExpensiveState,
      title = "Zu teuer",
      fieldName = "tooExpensiveState",
    )
  }
  div {
    checkbox(
      valueAndSetter = waitingTimeTooLongState,
      title = "Wartezeit zu lang",
      fieldName = "waitingTimeTooLongState",
    )
  }

  p("mt-4") {
    inputArea(
      valueAndSetter = otherReasonsState,
      title = "Sonstige Begründung",
      fieldName = "otherReasonsState",
    )
  }

}


external interface ArchiveReasonsOverviewProps : Props {
  var project: ResolvedProject
  var archiveReasons: ArchiveReasons
}
