package store

/**
 * Represents the current error state of the application.
 * Exceptions are stored within the error state
 */
data class ErrorState(
  /**
   * The current exception that is shown in the error component.
   */
  val currentException: Throwable?,
) {

  companion object {
    /**
     * The error state
     */
    val none: ErrorState = ErrorState(null)
  }
}
