@file:UseSerializers(UuidSerializer::class)

package it.neckar.comments.client

import com.benasher44.uuid.Uuid
import it.neckar.rest.RestRequest
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class DuplicateCommentsRequest(val mapOfOldToNewUuids: Map<Uuid, Uuid>) : RestRequest

