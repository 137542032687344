package it.neckar.lizergy.model.configuration.quote.economics

import it.neckar.datetime.minimal.Year
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 * Use like this:
 * ```
 * @file: UseSerializers(YearSerializer::class)
 * ```
 */
@Deprecated("Still required? I don't think so")
object YearSerializer : KSerializer<Year> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("Year", PrimitiveKind.STRING)

  override fun serialize(encoder: Encoder, value: Year) {
    encoder.encodeInt(value.value)
  }

  override fun deserialize(decoder: Decoder): Year {
    return Year(decoder.decodeInt())
  }
}
