package components.gridAssessment

import components.form.commentSection
import components.project.configuration.additional.zusammenZuLegendeZahlerForm
import components.project.configuration.location.locationMap
import components.project.configuration.powerusage.EditInquiryInformation
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.customer.company.NeckarITCompanyProfile
import it.neckar.customer.company.PartnerCompanyProfile
import it.neckar.customer.company.TestCompanyProfile
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.configuration.components.SelfAssemblyConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.configuration.quote.builder.BasicBatteryInverter
import it.neckar.lizergy.model.configuration.quote.builder.HybridInverter
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.collections.fastForEach
import it.neckar.open.kotlin.lang.toCheckboxChar
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import kotlinx.html.SPAN
import plannerI18nConfiguration
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useSelectCompanyResolver

private const val firstColumn = "col-lg-7"
private const val secondColumn = "col-lg-5"

val GridAssessmentConfigurationOverview: FC<GridAssessmentConfigurationOverviewProps> = fc("GridAssessmentConfigurationOverview") { props ->
  val companyResolver = useSelectCompanyResolver()

  val project = props::project.safeGet()
  val configuration = props::currentConfiguration.safeGet()
  val quoteSnapshot = props::currentQuoteSnapshot.safeGet()

  val editableStatus = project.isProjectEditable()

  val zusammenZuLegendeZaehlerNummernToSave = useState(configuration.electricityWorkConfiguration.zusammenZuLegendeZaehlerNummern)
  val bleibenderZaehlerToSave = useState(configuration.zaehlerNummer)
  val throttledState = useState(configuration.wallboxSelection.throttled)


  useMemo(zusammenZuLegendeZaehlerNummernToSave.value, bleibenderZaehlerToSave.value, throttledState.value) {
    val updatedQuoteConfiguration = configuration.copy(
      electricityWorkConfiguration = configuration.electricityWorkConfiguration.copy(zusammenZuLegendeZaehlerNummern = zusammenZuLegendeZaehlerNummernToSave.value),
      wallboxSelection = configuration.wallboxSelection.copy(throttled = throttledState.value),
      zaehlerNummer = bleibenderZaehlerToSave.value,
    )
    UiActions.saveQuoteConfiguration(project, updatedQuoteConfiguration)
  }


  div {

    div("row my-3") {
      div(firstColumn) {
        entryPreview("Standort", configuration.location.address.singleLine())
      }
      div(secondColumn) {
        locationMap(configuration.location.coordinates?.coords)
      }
    }

    div("row my-3") {
      div(firstColumn) {
        EditInquiryInformation {
          attrs {
            this.project = project
            this.quoteConfiguration = configuration
            this.editableStatus = editableStatus
          }
        }
      }
      div(secondColumn) {}
    }

    div("row my-3") {
      div(firstColumn) {
        entryPreview("Lageplan abgelegt (falls vorhanden)", project.blueprint.lagePlanAngefragt.toCheckboxChar())
        entryPreview("Vollmacht abgelegt", quoteSnapshot.cancellationPolicySigned.toCheckboxChar())
      }
      div(secondColumn) {
        h3("mb-2") { +"Bemerkungen Anlage & Montage" }
        commentSection(configuration.facilityConfiguration)
      }
    }

    div("row my-3") {
      div(firstColumn) {
        entryPreview("Montage Dach") {
          val companyInformation = configuration.earningsDistribution.montageDach.company.let { companyResolver[it] }
          companyPill(companyInformation)
        }

        val assemblyStatus = configuration.assemblyConfiguration.assemblyStatus
        if (assemblyStatus is SelfAssemblyConfiguration) {
          entryPreview("Eigenmontage", assemblyStatus.format())
        }
      }
      div(secondColumn) {}
    }

    div("row my-3") {
      div(firstColumn) {
        entryPreview("Elektroinstallation") {
          val companyInformation = configuration.earningsDistribution.elektroInstallation.company.let { companyResolver[it] }
          companyPill(companyInformation)
        }
      }
      div(secondColumn) {}
    }

    div("row my-3") {
      div(firstColumn) {
        entryPreview("PV-Leistung [kWp]", configuration.kiloWattPeak.formatKiloWattPeak(plannerI18nConfiguration))
        entryPreview("Module", configuration.modulesReport.countByModules.joinToString { it.format() })
      }
      div(secondColumn) {}
    }

    div("row my-3") {
      div(firstColumn) {

        div("mb-2") {
          val ueberspannungsSchutz = configuration.facilityConfiguration.numberUeberspannungsSchutz?.let { "$it × ${configuration.ueberspannungsSchutzType}" }
          entryPreview("Überspannungsschutz", ueberspannungsSchutz)
        }

        div("mb-2") {
          entryPreview("Wechselrichter", configuration.facilityConfiguration.inverterSelection.format(plannerI18nConfiguration, "\n"))
        }

        div("mb-2") {
          val batteryConfiguration = configuration.facilityConfiguration.batteryConfiguration
          if (batteryConfiguration != null) {
            entryPreview("Batteriesystem", batteryConfiguration.formatOnlyBattery())
            entryPreview("Speicher", "${batteryConfiguration.totalCapacity.format(plannerI18nConfiguration)}, ${batteryConfiguration.totalNumberOfBatteryModules} Batteriemodule")
            entryPreview(
              title = when (batteryConfiguration.inverterType) {
                is BasicBatteryInverter -> "Wechselrichter"
                is HybridInverter -> "Hybrid-Wechselrichter"
              },
              content = batteryConfiguration.inverterType.format(),
            )
          }
        }

      }
      div(secondColumn) {}
    }

    div("row my-3") {
      div(firstColumn) {
        entryPreview("Zählerzusammenlegung") {
          zusammenZuLegendeZahlerForm(zusammenZuLegendeZaehlerNummernToSave, bleibenderZaehlerToSave)
        }
      }
      div(secondColumn) {
        h3("mb-2") { +"Bemerkungen Zählerschrank" }
        commentSection(configuration.electricityWorkConfiguration)
      }
    }

    div("row my-3") {
      div(firstColumn) {
        configuration.wallboxSelection.entries.fastForEach { (wallbox, amount) ->
          div("mb-2") {
            entryPreview("Wallbox", "$amount × ${wallbox.description}")
            entryPreview("Leistung", wallbox.chargingCapacity.capacity().format(plannerI18nConfiguration))
            entryPreview("Kabellänge", wallbox.cableLength.format(plannerI18nConfiguration))
          }
        }
        if (configuration.wallboxSelection.isNotEmpty()) {
          entryPreview("Drosselung") {
            checkbox(
              valueAndSetter = throttledState,
              fieldName = "throttled",
              title = "",
            )
          }
        }
      }
      div(secondColumn) {
        h3("mb-2") { +"Bemerkungen E-Auto" }
        commentSection(configuration.wallboxSelection)
      }
    }

  }

}

private fun RDOMBuilder<SPAN>.companyPill(companyInformation: PlannerCompanyInformation) {
  span("badge rounded pill") {
    when (companyInformation.companyProfile) {
      is MainCompanyProfile -> addClass("bg-primary")
      NeckarITCompanyProfile -> addClass("bg-danger")
      is PartnerCompanyProfile -> addClass("bg-dark")
      is TestCompanyProfile -> addClass("bg-secondary")
    }
    +companyInformation.name
  }
}

fun RBuilder.entryPreview(title: String, content: String?) {
  entryPreview(title) { +(content ?: "-") }
}

fun RBuilder.entryPreview(title: String, classes: String = "my-2", content: RDOMBuilder<SPAN>.() -> Unit) {
  div("row") {
    addClass(classes)
    div("col-lg-6 my-auto") { b { +"$title:" } }
    div("col-lg-6") { span { content() } }
  }
}

external interface GridAssessmentConfigurationOverviewProps : Props {
  var project: ResolvedProject
  var currentConfiguration: QuoteConfiguration
  var currentQuoteSnapshot: QuoteSnapshot
}
