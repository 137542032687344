package it.neckar.lizergy.model

import com.benasher44.uuid.uuid4
import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.assemblyPortfolio.ResolvedAssemblyPortfolio
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.ResolvedPhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfigurations
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot
import it.neckar.lizergy.model.configuration.quote.evaluate
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.StaticPriceList
import it.neckar.lizergy.model.price.lizergy.LizergyPriceListFactory
import it.neckar.lizergy.model.project.ArchiveReasons
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.stumps.AdvanceInvoice
import it.neckar.lizergy.model.stumps.AssemblyBasement
import it.neckar.lizergy.model.stumps.AssemblyRoof
import it.neckar.lizergy.model.stumps.Documentation
import it.neckar.lizergy.model.stumps.FinalAccount
import it.neckar.lizergy.model.stumps.FinishingUp
import it.neckar.lizergy.model.stumps.GridAssessment
import it.neckar.lizergy.model.stumps.OrderSpecialMaterial
import it.neckar.lizergy.model.stumps.StartupOperations
import it.neckar.lizergy.model.stumps.SwitchMeterBox
import it.neckar.open.time.nowMillis

/**
 * creates Demo Projects for Testing
 * see [DemoDataFactory]
 */
object DemoProjectFactory {

  private val staticPriceList: StaticPriceList = LizergyPriceListFactory.createPriceList()

  fun createDemoProject(
    resolvedConfigurations: List<ResolvedPhotovoltaicsConfiguration> = listOf(DemoDataFactory.createConfiguration(creationTime = nowMillis())),
    availableProducts: AvailableProducts,
    priceList: PriceList = staticPriceList,
    projectName: String = "",
    projectDescription: String = "",
    customer: Customer = Customer.demoWithRandomAddress(),
    maintainer: UserInformation,
    blueprint: ResolvedBlueprint,
    currentQuoteSnapshot: QuoteSnapshot? = null,
  ): ResolvedProject {

    val quoteConfigurationsList = resolvedConfigurations.map {
      it.evaluate(
        currentQuoteSnapshot = currentQuoteSnapshot,
        availableProducts = availableProducts,
        priceList = priceList
      )
    }
    val quoteConfigurations = QuoteConfigurations(quoteConfigurationsList)


    return ResolvedProject(
      projectId = blueprint.projectId,
      projectName = projectName,
      projectDescription = projectDescription,
      sellingCompanyInformation = PlannerCompanyInformation.createDemoCompany(CompanyCode.DemoCompany),
      customer = customer,
      maintainerInformation = maintainer,
      blueprint = blueprint,
      quoteConfigurations = quoteConfigurations,
      orderSpecialMaterial = OrderSpecialMaterial(uuid4(), blueprint.projectId),
      gridAssessment = GridAssessment(uuid4(), blueprint.projectId),
      assemblyPortfolio = ResolvedAssemblyPortfolio.getEmpty(blueprint.projectId, uuid4()),
      advanceInvoice = AdvanceInvoice(uuid4(), blueprint.projectId),
      assemblyRoof = AssemblyRoof(uuid4(), blueprint.projectId),
      assemblyBasement = AssemblyBasement(uuid4(), blueprint.projectId),
      switchMeterBox = SwitchMeterBox(uuid4(), blueprint.projectId),
      startupOperations = StartupOperations(uuid4(), blueprint.projectId),
      finishingUp = FinishingUp(uuid4(), blueprint.projectId),
      finalAccount = FinalAccount(uuid4(), blueprint.projectId),
      documentation = Documentation(uuid4(), blueprint.projectId),
      verification = null,
      archiveReasons = ArchiveReasons.getEmpty(),
      creationTime = nowMillis(),
    )
  }
}
