package components.project.configuration.tabs

import components.project.QuoteConfigurationTabProps
import components.project.configuration.QuotePositionsTable
import components.project.configuration.QuotePositionsTableMode
import components.project.configuration.isConfigurationEditable
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.LocalStorageKey
import it.neckar.commons.kotlin.js.LocalStorageSupport
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.quote.CalculationRelevanceQuery
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

/**
 * Visualizes the quote positions in a tab
 */
val QuotePositionsOverviewFromUrl: FC<Props> = fc("QuotePositionsOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Rechnungsvorschau", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    QuotePositionsOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val QuotePositionsOverview: FC<QuoteConfigurationTabProps> = fc("QuotePositionsOverview") { props ->
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val quoteElements = if (editableStatus == EditableStatus.Editable) quoteConfiguration.calculatedQuoteElements else quoteConfiguration.quoteElements

  val quotePositionsTableMode = LocalStorageSupport.loadFromLocalStorage(
    key = LocalStorageKey("QuotePositionsTableMode"),
    serializer = QuotePositionsTableMode.serializer(),
  ) ?: QuotePositionsTableMode.Simplified

  val modeState = useState(quotePositionsTableMode)


  div("row my-3") {

      div("col-6 my-3") {
        floatingSelectEnum(
          valueAndSetter = modeState,
          formatter = {
            when (it) {
              QuotePositionsTableMode.Simplified -> "Vereinfacht"
              QuotePositionsTableMode.Detailed -> "Detailliert"
            }
          },
          availableOptions = QuotePositionsTableMode.entries,
          fieldName = "quotePositionsTableMode",
          title = "Ansichtsmodus",
          editableStatus = EditableStatus.Editable,
          additionalOnChange = {
            LocalStorageSupport.saveToLocalStorageOptional(LocalStorageKey("QuotePositionsTableMode"), it, QuotePositionsTableMode.serializer())
          },
        )
      }

    div("col-12 mt-4") {
      QuotePositionsTable {
        attrs {
          this.quoteElements = quoteElements
          this.mode = modeState.value
          this.query = when (modeState.value) {
            QuotePositionsTableMode.Simplified -> CalculationRelevanceQuery.onlyPublicSkipZero
            QuotePositionsTableMode.Detailed -> CalculationRelevanceQuery.skipZero
          }
        }
      }
    }

  }

}
