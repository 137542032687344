package it.neckar.lizergy.model.configuration.moduleLayout

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

interface ModuleLayouts : HasUuid {
  val moduleLayoutsId: ModuleLayoutsId
  val moduleLayouts: List<ModuleLayout>

  override val uuid: Uuid
    get() = moduleLayoutsId.uuid

  operator fun get(moduleLayoutId: ModuleLayout.ModuleLayoutId): ModuleLayout

  operator fun get(roofId: RoofId): Roof

  val modulesReport: ModulesReport

  @Serializable
  data class ModuleLayoutsId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ModuleLayoutsId {
        return ModuleLayoutsId(randomUuid4())
      }
    }
  }

}
