package serialized

import it.neckar.lifeCycle.onlyActive
import it.neckar.lizergy.model.ElementsCollection
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayouts.ModuleLayoutsId
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.uuid.withAdded
import it.neckar.uuid.withRemoved
import it.neckar.uuid.withReplaced
import kotlinx.serialization.Serializable

@Serializable
data class ModuleLayoutsConfiguration(
  override val moduleLayoutsId: ModuleLayoutsId = ModuleLayoutsId.random(),
  override val moduleLayouts: List<SerializedModuleLayout>,
) : ModuleLayouts, ElementsCollection<SerializedModuleLayout> {

  override val elements: List<SerializedModuleLayout>
    get() = moduleLayouts

  override val validElements: List<SerializedModuleLayout>
    get() = elements.onlyActive().sortedByDescending { moduleLayout ->
      moduleLayout.roof.size?.area()
    }.sortedByDescending { moduleLayout ->
      moduleLayout.modulesCount
    }

  override val size: Int
    get() = validElements.size

  override val modulesReport: SerializedModulesReport
    get() = SerializedModulesReport.create(validElements)

  override operator fun get(moduleLayoutId: ModuleLayoutId): SerializedModuleLayout {
    return moduleLayouts.find { it.id == moduleLayoutId } ?: throw IllegalArgumentException("No ResolvedModuleLayout found for moduleLayoutId <$moduleLayoutId>")
  }

  override operator fun get(roofId: RoofId): SerializedRoof {
    return moduleLayouts.find {
      it.roof.id == roofId
    }?.roof ?: throw NoSuchElementException("No roof for the ID $roofId")
  }

  override fun withAdded(additionalElement: SerializedModuleLayout): ModuleLayoutsConfiguration {
    val updatedElements: List<SerializedModuleLayout> = elements.withAdded(additionalElement)
    return copy(moduleLayouts = updatedElements)
  }

  override fun withAdded(additionalElements: List<SerializedModuleLayout>): ModuleLayoutsConfiguration {
    val updatedElements: List<SerializedModuleLayout> = elements + additionalElements
    return copy(moduleLayouts = updatedElements)
  }

  override fun withUpdated(updatedElement: SerializedModuleLayout): ModuleLayoutsConfiguration {
    return copy(moduleLayouts = elements.withReplaced(updatedElement))
  }

  override fun withUpdated(updatedElements: List<SerializedModuleLayout>): ModuleLayoutsConfiguration {
    return copy(moduleLayouts = elements.withReplaced(updatedElements))
  }

  override fun withRemoved(removedElement: SerializedModuleLayout): ModuleLayoutsConfiguration {
    return copy(moduleLayouts = elements.withRemoved(removedElement))
  }

  override fun map(function: (SerializedModuleLayout) -> SerializedModuleLayout): ModuleLayoutsConfiguration {
    return ModuleLayoutsConfiguration(moduleLayoutsId = moduleLayoutsId, moduleLayouts = moduleLayouts.map(function))
  }

  fun resolve(productResolver: ProductResolver): ResolvedModuleLayouts {
    return ResolvedModuleLayouts(
      moduleLayoutsId = moduleLayoutsId,
      moduleLayouts = moduleLayouts.map { it.resolve(productResolver) },
    )
  }

  fun duplicate(): ModuleLayoutsConfiguration {
    return copy(
      moduleLayoutsId = ModuleLayoutsId.random(),
      moduleLayouts = moduleLayouts.map { moduleLayout -> moduleLayout.duplicate() },
    )
  }


  companion object {
    fun getEmpty(moduleLayoutsId: ModuleLayoutsId = ModuleLayoutsId.random()): ModuleLayoutsConfiguration {
      return ModuleLayoutsConfiguration(moduleLayoutsId = moduleLayoutsId, moduleLayouts = emptyList())
    }
  }

}


fun ResolvedModuleLayouts.unResolve(): ModuleLayoutsConfiguration {
  return ModuleLayoutsConfiguration(
    moduleLayoutsId = moduleLayoutsId,
    moduleLayouts = moduleLayouts.map { it.unResolve() },
  )
}
