package it.neckar.lizergy.model.configuration.moduleLayout.roof

import com.meistercharts.charts.lizergy.solar.SouthRelative
import it.neckar.open.formatting.format
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import kotlinx.serialization.Serializable

/**
 * Represents a roof orientation
 */
@Suppress("DataClassPrivateConstructor")
@Serializable
data class RoofOrientation private constructor(val orientation: @SouthRelative Int) {
  init {
    require((-180..180).contains(orientation)) {
      "Orientation must be between -180.0 and 180.0 but was <$orientation>"
    }
  }

  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "${orientation.format(i18nConfiguration)}${whitespaceConfig.smallSpace}°"
  }

  @Deprecated("unused!")
  operator fun rem(divisor: Int): RoofOrientation {
    return RoofOrientation(orientation % divisor)
  }

  operator fun compareTo(other: Int): Int {
    return orientation - other
  }

  /**
   * Returns the "opposite" roof orientation.
   * E.g. returns "east" for a "west" orientation
   */
  fun opposite(): RoofOrientation {
    return normalized(orientation + 180)
  }

  /**
   * Returns the cardinal direction
   *
   * RoofOrientations go from -180° (North) to 180° (North)
   * with 0° being South, -90° East, and 90° being West
   */
  fun toCardinalDirection(): CardinalDirection {
    return when (orientation) {
      in -180..-158, in 158..180 -> CardinalDirection.North
      in -157..-113 -> CardinalDirection.NorthEast
      in -112..-68 -> CardinalDirection.East
      in -67..-23 -> CardinalDirection.SouthEast
      in -22..22 -> CardinalDirection.South
      in 23..67 -> CardinalDirection.SouthWest
      in 68..112 -> CardinalDirection.West
      in 113..157 -> CardinalDirection.NorthWest
      else -> throw IllegalArgumentException("Orientation $this could not be translated into CardinalDirection")
    }
  }

  companion object {
    /**
     * Pointing directly towards south
     */
    val south: RoofOrientation = RoofOrientation(0)

    /**
     * Creates a new instance with normalized orientation
     */
    fun normalized(orientation: @SouthRelative Int): RoofOrientation {
      val normalizedOrientation: @SouthRelative Int = orientation.let { original ->
        if (original > 180 || original < -180) ((original + 180) % 360 - 180) % 360 else original
      }

      return RoofOrientation(normalizedOrientation)
    }
  }
}
