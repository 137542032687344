package it.neckar.lizergy.model.project

import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.user.UserLoginName
import it.neckar.uuid.withAdded
import it.neckar.uuid.withReplaced

/**
 * Contains the resolved projects
 */
data class ResolvedProjects(override val projects: List<ResolvedProject>) : ProjectCollection<ResolvedProject> {

  val projectsMap: Map<PhotovoltaicsProjectId, ResolvedProject> = projects.associateBy { it.projectId }

  /**
   * Returns the project with the given id
   */
  override operator fun get(projectId: PhotovoltaicsProjectId): ResolvedProject {
    return projectsMap[projectId] ?: throw NoSuchElementException("Project with id $projectId not found")
  }

  override fun getOrNull(projectId: PhotovoltaicsProjectId): ResolvedProject? {
    return projectsMap[projectId]
  }

  override fun filter(predicate: (ResolvedProject) -> Boolean): ResolvedProjects {
    return ResolvedProjects(projects.filter { predicate(it) })
  }

  /**
   * Filters the sequence of all project previews by the provided filter.
   * This method can be used for complex filters
   */
  override fun filterSequence(filter: Sequence<ResolvedProject>.() -> Sequence<ResolvedProject>): ResolvedProjects {
    val filtered: Sequence<ResolvedProject> = projects.asSequence().filter()
    return ResolvedProjects(filtered.toList())
  }

  override fun forMaintainer(user: UserLoginName): ResolvedProjects {
    return filter { it.belongsToMaintainer(user) }
  }

  override fun withAdded(newProject: ResolvedProject): ResolvedProjects {
    return copy(projects = projects.withAdded(newProject))
  }

  override fun withUpdated(updatedProject: ResolvedProject): ResolvedProjects {
    return copy(projects = projects.withReplaced(updatedProject))
  }

  override fun withUpdated(updatedProjects: List<ResolvedProject>): ResolvedProjects {
    return copy(projects = projects.withReplaced(updatedProjects))
  }

  override fun sortedWith(comparator: Comparator<ResolvedProject>): ProjectCollection<ResolvedProject> {
    return copy(projects = projects.sortedWith(comparator))
  }


  companion object {
    val empty: ResolvedProjects = ResolvedProjects(emptyList())
  }

}
