package components.assemblyPortfolio

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import react.*
import react.dom.*

val AssemblyPortfolioConfigurationOverview: FC<AssemblyPortfolioConfigurationOverviewProps> = fc("AssemblyPortfolioConfigurationOverview") { props ->
  val project = props::project.safeGet()
  val configuration = props::currentConfiguration.safeGet()


  div {
    h2 { +"Laufkarte" }

    PlandatenOverview {
      attrs {
        this.project = project
        this.currentConfiguration = configuration
      }
    }

    KundendatenOverview {
      attrs {
        this.project = project
        this.currentConfiguration = configuration
      }
    }

    BasisdatenOverview {
      attrs {
        this.project = project
        this.currentConfiguration = configuration
      }
    }

    KomponentenOverview {
      attrs {
        this.project = project
        this.currentConfiguration = configuration
      }
    }

    DachmontageOverview {
      attrs {
        this.project = project
        this.currentConfiguration = configuration
      }
    }
  }

}

external interface AssemblyPortfolioConfigurationOverviewProps : Props {
  var project: ResolvedProject
  var currentConfiguration: QuoteConfiguration
}
