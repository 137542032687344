package components.company

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

/**
 * Creates a form for an address
 */
val contactInformationForm: FC<ContactInformationFormProps> = fc("contactInformationForm") { props ->

  val mail = props::mail.getNotNull()
  val phone = props::phone.getNotNull()
  val fax = props::fax.getNotNull()
  val url = props::url.getNotNull()

  div("row g-0") {

    div("col-6") {
      floatingInputField(
        valueAndSetter = mail,
        fieldName = "mail",
        title = "Email",
      ) {
        attrs {
          mergedBelow()
          mergedRight()
        }
      }
    }
    div("col-6") {
      floatingInputField(
        valueAndSetter = phone,
        fieldName = "phone",
        title = "Telefon",
      ) {
        attrs {
          mergedBelow()
          mergedLeft()
        }
      }
    }
    div("col-6") {
      floatingInputField(
        valueAndSetter = url,
        fieldName = "url",
        title = "Website",
      ) {
        attrs {
          mergedAbove()
          mergedRight()
        }
      }
    }

    div("col-6") {
      floatingInputField(
        valueAndSetter = fax,
        fieldName = "fax",
        title = "Fax",
      ) {
        attrs {
          mergedAbove()
          mergedLeft()
        }
      }
    }

  }

}

external interface ContactInformationFormProps : Props {
  var mail: StateInstance<String>
  var phone: StateInstance<String>
  var fax: StateInstance<String>
  var url: StateInstance<String>
}
