package router

import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.Blueprint
import it.neckar.lizergy.model.project.ProjectConfiguration
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.react.common.router.*

fun NavigateUrlFunction.toHome() {
  invoke(RouterUrls.home)
}

/**
 * Navigates to the [ResolvedBlueprint] with the given [PhotovoltaicsProjectId]
 */
fun NavigateUrlFunction.toBlueprint(projectId: PhotovoltaicsProjectId) {
  invoke(RouterUrls.project(projectId).blueprint.index)
}

fun NavigateUrlFunction.toBlueprint(project: ProjectConfiguration) {
  this.toBlueprint(project.projectId)
}

fun NavigateUrlFunction.toBlueprint(blueprint: Blueprint) {
  this.toBlueprint(blueprint.projectId)
}


/**
 * Navigates to the [ResolvedProject] with the given [PhotovoltaicsProjectId]
 */
fun NavigateUrlFunction.toProject(projectId: PhotovoltaicsProjectId) {
  invoke(RouterUrls.project(projectId).index)
}

fun NavigateUrlFunction.toProject(project: ProjectConfiguration) {
  this.toProject(project.projectId)
}


/**
 * Navigates to the [QuoteConfiguration] with the given [PhotovoltaicsConfiguration]
 */
fun NavigateUrlFunction.toConfiguration(projectId: PhotovoltaicsProjectId, configuration: PhotovoltaicsConfiguration) {
  invoke(RouterUrls.project(projectId).configuration(configuration).index)
}
