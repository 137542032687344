@file:UseSerializers(UuidSerializer::class)

package it.neckar.processStates

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.onlyActive
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class ProcessStatesForComponent(
  val processStatesFor: Uuid,
  val processStateEntries: List<ProcessStateEntry> = emptyList(),
) {

  val validProcessStateEntries: List<ProcessStateEntry>
    get() = processStateEntries.onlyActive()

  fun isEmpty(): Boolean {
    return validProcessStateEntries.isEmpty()
  }

  fun withNewProcessState(newProcessStateEntry: ProcessStateEntry): ProcessStatesForComponent {
    return copy(processStateEntries = processStateEntries + newProcessStateEntry)
  }

  fun withUpdatedProcessState(updatedProcessStateEntry: ProcessStateEntry): ProcessStatesForComponent {
    val updatedUserLoginName = processStateEntries.indexOfFirst {
      it.id == updatedProcessStateEntry.id
    }

    if (updatedUserLoginName < 0) return withNewProcessState(updatedProcessStateEntry)

    val newCommentsForComponent = this.processStateEntries.toMutableList()
    newCommentsForComponent[updatedUserLoginName] = updatedProcessStateEntry

    return copy(processStateEntries = newCommentsForComponent)
  }
}

fun List<ProcessStateEntry>.current(): ProcessStateEntry? {
  return maxByOrNull { it.assignedAt }
}
