package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.RoofRelative
import it.neckar.geometry.Coordinates
import it.neckar.geometry.RightTriangleType
import it.neckar.geometry.Size
import it.neckar.open.unit.si.mm
import kotlinx.serialization.Serializable

@Serializable
data class UnusableAreaInformation(
  /**
   * The location (bottom left) of the unusable area
   */
  val location: @RoofRelative Coordinates,
  /**
   * The size of the unusable area
   */
  val size: @mm Size,

  /**
   * The description of the unusable area
   */
  val description: String,

  /**
   * The type of the right triangle - or null if this area has the shape of a rectangle
   */
  val rightTriangleType: RightTriangleType?,
)


