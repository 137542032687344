package it.neckar.lizergy.model.location

import it.neckar.open.unit.si.m
import it.neckar.open.unit.si.s
import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Response for
 */
@Serializable
sealed interface RouteInformationResponse : RestResponse<RouteInformation> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: RouteInformation) : RouteInformationResponse, RestResponse.SuccessResponse<RouteInformation>

  @Serializable
  @SerialName("failure")
  data object Failure : RouteInformationResponse, RestResponse.FailureResponse<RouteInformation>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: RouteInformation): RouteInformationResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): RouteInformationResponse = Failure
  }
}

@Serializable
data class RouteInformation(
  /**
   * The Duration in Seconds
   */
  val duration: @s Double,

  /**
   * the distance between the
   * two Coords in meters
   */
  val distance: @m Double,
)
