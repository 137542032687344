package store

import it.neckar.commons.kotlin.js.CookiesSupport
import it.neckar.commons.kotlin.js.LocalStorageKey
import it.neckar.commons.kotlin.js.LocalStorageSupport
import it.neckar.lizergy.model.http.PlannerApiPathConstants
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import kotlinx.serialization.serializer
import store.actions.LoggedInLoadedFromLocalStorageAction


object AppStateLocalStorage {
  private val logger: Logger = LoggerFactory.getLogger("store.AppStateLocalStorage")

  /**
   * Connects the store with local storage
   */
  fun PlannerStore.connectWithLocalStorage() {
    logger.debug("Connecting store with local storage")
    connectLoginState()
  }

  /**
   * Connect the login state with local storage
   */
  private fun PlannerStore.connectLoginState() {

    var lastLoginState: LoginState = state.loginState
    subscribe {
      val loginState = state.loginState

      if (loginState != lastLoginState) {
        //The login state has changed
        lastLoginState = loginState

        //The login state has been updated
        when (loginState) {
          is LoggedInState -> {
            //Save to local storage
            saveLoggedInState(loginState)
          }

          LoggedOutState -> {
            //Delete credentials
            deleteLoggedInState()
          }

          NotYetLoggedIn -> {
            //Initial state - do nothing
          }
        }
      }
    }

    if (this.state.loginState == NotYetLoggedIn) {
      //Load from local storage
      loadLoggedInState()?.let {
        logger.debug("Loaded LoggedInState $it! Will dispatch state")

        dispatch(LoggedInLoadedFromLocalStorageAction(it))
      }
    }

  }

  /**
   * Loads the logged in state
   */
  fun loadLoggedInState(): LoggedInState? {
    return LocalStorageSupport.loadFromLocalStorage<LoggedInState?>(Key.LoggedInState, serializer())?.also { loggedInStateFromLocalStorage ->
      CookiesSupport.setCookie(cookieName = PlannerApiPathConstants.Cookies.accessToken, cookieValue = loggedInStateFromLocalStorage.accessToken.token)
    }
  }

  fun saveLoggedInState(loggedInState: LoggedInState) {
    LocalStorageSupport.saveToLocalStorage(Key.LoggedInState, loggedInState, serializer())
    CookiesSupport.setCookie(cookieName = PlannerApiPathConstants.Cookies.accessToken, cookieValue = loggedInState.accessToken.token)
  }

  fun deleteLoggedInState() {
    LocalStorageSupport.deleteFromLocalStorage(Key.LoggedInState)
    CookiesSupport.deleteAllCookiesForKey(PlannerApiPathConstants.Cookies.accessToken)
  }


  /**
   * Contains the keys for local storage
   */
  object Key {
    val LoggedInState: LocalStorageKey = LocalStorageKey("LoggedInState")
  }
}
