package services.http

import com.benasher44.uuid.Uuid
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.get
import it.neckar.ktor.client.putJson
import it.neckar.ktor.client.safeTryFetch
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.processStatesClient.DuplicateProcessStatesRequest
import it.neckar.processStatesClient.FetchProcessStatesForComponentResponse
import it.neckar.processStatesClient.FetchProcessStatesResponse
import it.neckar.processStatesClient.SendProcessStateResponse
import it.neckar.processStatesClient.SendProcessStatesResponse
import it.neckar.processStatesClient.SendProcessStatesTuple

/**
 * Stores / retrieves information using REST
 */
class ProcessStatesClientService(val httpClient: HttpClient, val urlSupport: PlannerUrlSupport) {

  suspend fun fetchProcessStatesForComponent(processStatesFor: Uuid, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): FetchProcessStatesForComponentResponse {
    return safeTryFetch(FetchProcessStatesForComponentResponse.failure()) {
      httpClient
        .get(urlSupport.processStates.processStates(processStatesFor)) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun sendProcessState(processStateFor: Uuid, newProcessState: LizergyProcessStateEntry): SendProcessStateResponse {
    return safeTryFetch(SendProcessStateResponse.failure()) {
      httpClient
        .putJson<LizergyProcessStateEntry>(urlSupport.processStates.processStates(processStateFor), newProcessState) {
          featureFlagsHeader()
        }.body()
    }
  }

  suspend fun sendMultipleProcessStates(processStateForComponents: List<SendProcessStatesTuple>): SendProcessStatesResponse {
    return safeTryFetch(SendProcessStatesResponse.failure()) {
      httpClient
        .putJson(urlSupport.processStates.multipleProcessStates(), processStateForComponents) {
          featureFlagsHeader()
        }.body()
    }
  }

  suspend fun duplicateProcessStates(mapOfOldToNewUuids: Map<Uuid, Uuid>): SendProcessStateResponse {
    return safeTryFetch(SendProcessStateResponse.failure()) {
      httpClient
        .putJson(urlSupport.processStates.duplicateProcessStates(), DuplicateProcessStatesRequest(mapOfOldToNewUuids)) {
          featureFlagsHeader()
        }.body()
    }
  }

  suspend fun fetchAllProcessStates(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): FetchProcessStatesResponse {
    return safeTryFetch(FetchProcessStatesResponse.failure()) {
      httpClient
        .get(urlSupport.processStates.allProcessStates()) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

}
