package it.neckar.lizergy.model.project.process.state

import it.neckar.customer.company.CompanyCode
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.open.formatting.dateFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import it.neckar.processStates.ProcessStateEntryId
import it.neckar.processStates.ProcessStatePhase
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FinishingUpProcessStateEntry")
data class FinishingUpProcessStateEntry(
  override val id: ProcessStateEntryId,
  override val processState: FinishingUpProcessStates,
  override val assignedTo: UserLoginName,
  override val belongsTo: CompanyCode,
  override val assignedAt: @ms @Serializable(with = DoubleAsIsoDateTimeSerializer::class) Double,
  override val assignedBy: UserLoginName,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : LizergyProcessStateEntry {

  override val availableOptions: List<FinishingUpProcessStates>
    get() = FinishingUpProcessStates.entries

  override fun format(i18nConfiguration: I18nConfiguration): String {
    return processState.format(i18nConfiguration)
  }

  @Serializable
  @SerialName("FinishingUpProcessStates")
  enum class FinishingUpProcessStates : LizergyProcessStates {
    Missing,
    Ordered,
    Done,

    ;

    override val inPhase: ProcessStatePhase
      get() = when (this) {
        Missing -> ProcessStatePhase.Untouched
        Ordered -> ProcessStatePhase.Waiting
        Done -> ProcessStatePhase.Done
      }

    override fun format(i18nConfiguration: I18nConfiguration): String {
      return when (this) {
        Missing -> "FM fehlt"
        Ordered -> "FM beauftragt"
        Done -> "FM abgeschlossen"
      }
    }

    override fun formatDate(date: Double, i18nConfiguration: I18nConfiguration): String {
      return "seit ${dateFormat.format(date, i18nConfiguration)}"
    }
  }
}
