@file: UseSerializers(YearSerializer::class)

package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.datetime.minimal.Year
import it.neckar.lizergy.model.configuration.quote.economics.YearIndex
import it.neckar.lizergy.model.configuration.quote.economics.YearSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Contains the values for one column
 */
@Serializable
data class SimpleProfitabilityTableColumn(
  /**
   * The year this column is valid for
   */
  val year: Year,

  /**
   * The year index within this calculation
   */
  val yearIndex: YearIndex,

  /**
   * The energy production and usage related information
   */
  val energyInformation: EnergyInformation,

  /**
   * The cash flow
   */
  val cashFlow: CashFlow,

  /**
   * Information about the amortisation
   */
  val amortisationInformation: AmortisationInformation,

  /**
   * Contains the information about how much money is payed within a certain period of time (year, month etc)
   */
  val tariffInformation: TariffInformation,
)
