package services.storage

import it.neckar.lizergy.model.company.UsersAndCompanies
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Response for
 */
@Serializable
sealed interface LoadEssentialsResponse : RestResponse<LoadedEssentials> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: LoadedEssentials) : LoadEssentialsResponse, RestResponse.SuccessResponse<LoadedEssentials>

  @Serializable
  @SerialName("failure")
  data object Failure : LoadEssentialsResponse, RestResponse.FailureResponse<LoadedEssentials>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: LoadedEssentials): LoadEssentialsResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): LoadEssentialsResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: LoadedEssentials?): LoadEssentialsResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}

@Serializable
data class LoadedEssentials(
  val usersAndCompanies: UsersAndCompanies,
  val availableProducts: AvailableProducts,
  val productResolver: ProductResolver,
  val priceList: PriceList,
)
