package components.accounting

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.datetime.minimal.LocalDate
import it.neckar.datetime.minimal.Month
import it.neckar.datetime.minimal.Year
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.*
import it.neckar.react.common.table.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import services.http.CompanyRevenue

/**
 * Collects the projects for each year.
 * Visualizes the sum of the earnings for each year.
 *
 */
val RevenueOverview: FC<RevenueOverviewProps> = fc("RevenueOverview") { props ->
  val lizergyRevenue = props::lizergyRevenue.safeGet()
  val neckarITRevenue = props::neckarITRevenue.safeGet()
  val partnerRevenues = props::partnerRevenues.safeGet()

  val allRevenues = buildList {
    lizergyRevenue?.let { add(it) }
    neckarITRevenue?.let { add(it) }
    partnerRevenues?.let { addAll(it) }
  }

  val sortedByFunction = useState(
    SortedByFunction(
      sortedBy = SortedBy.SortedDescending,
      tableDataColumn = totalRevenueColumn,
    )
  )

  val dayColumns = buildList {
    allRevenues.flatMap { it.revenueByDay.keys }.distinct().sorted().fastForEach { date ->
      add(getDailyCompanyRevenueColumn(date))
    }
  }

  val monthColumns = buildList {
    allRevenues.flatMap { it.revenueBysMonth.keys }.distinct().sorted().fastForEach { month ->
      add(getMonthlyCompanyRevenueColumn(month))
    }
  }

  val yearColumns = buildList {
    allRevenues.flatMap { it.revenueByYear.keys }.distinct().sorted().fastForEach { year ->
      add(getYearlyCompanyRevenueColumn(year))
    }
  }

  val columns = buildList {
    add(companyColumn)
    dayColumns.fastForEach { add(it) }
    add(totalRevenueColumn)
  }


  div("my-3") {

    busyIfNull(lizergyRevenue) { loadedLizergyRevenue ->
      table(
        columns = columns,
        entries = allRevenues,
        footerEntry = CompanyRevenue("Gesamt", allRevenues.flatMap { it.revenues }),
        sortedByFunction = sortedByFunction,
      ) {}
    }

  }

}


val companyColumn: TableHeaderColumn<CompanyRevenue>
  get() = TableHeaderColumn(
    id = "company",
    title = "Firma",
    sortFunction = compareBy { it.companyName },
  ) { companyRevenue ->
    {
      +companyRevenue.companyName
    }
  }

fun getDailyCompanyRevenueColumn(date: LocalDate): TableDataColumn<CompanyRevenue> {
  return TableDataColumn(
    id = "$date",
    title = "$date",
    titleClasses = "justify-content-end",
    sortFunction = compareBy { it.revenueByDay[date] },
  ) { companyRevenue ->
    {
      attrs {
        addClass("text-end")
      }

      +(companyRevenue.revenueByDay[date]?.format(plannerI18nConfiguration) ?: "-")
    }
  }
}

fun getMonthlyCompanyRevenueColumn(month: Month): TableDataColumn<CompanyRevenue> {
  return TableDataColumn(
    id = "$month",
    title = "$month",
    titleClasses = "justify-content-end",
    sortFunction = compareBy { it.revenueBysMonth[month] },
  ) { companyRevenue ->
    {
      attrs {
        addClass("text-end")
      }

      +(companyRevenue.revenueBysMonth[month]?.format(plannerI18nConfiguration) ?: "-")
    }
  }
}

fun getYearlyCompanyRevenueColumn(year: Year): TableDataColumn<CompanyRevenue> {
  return TableDataColumn(
    id = "$year",
    title = "$year",
    titleClasses = "justify-content-end",
    sortFunction = compareBy { it.revenueByYear[year] },
  ) { companyRevenue ->
    {
      attrs {
        addClass("text-end")
      }

      +(companyRevenue.revenueByYear[year]?.format(plannerI18nConfiguration) ?: "-")
    }
  }
}

val totalRevenueColumn: TableDataColumn<CompanyRevenue>
  get() = TableDataColumn(
    id = "totalRevenue",
    title = "Gesamt",
    titleClasses = "justify-content-end",
    sortFunction = compareBy { it.totalRevenue },
  ) { companyRevenue ->
    {
      attrs {
        addClass("text-end")
      }

      +companyRevenue.totalRevenue.format(plannerI18nConfiguration)
    }
  }


external interface RevenueOverviewProps : Props {
  var lizergyRevenue: CompanyRevenue?
  var neckarITRevenue: CompanyRevenue?
  var partnerRevenues: List<CompanyRevenue>?
}
