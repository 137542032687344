package serialized

import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration.BatteryConfigurationId
import it.neckar.lizergy.model.configuration.components.ConfigurationItem
import it.neckar.lizergy.model.configuration.components.FacilityConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityConfiguration.FacilityConfigurationId
import it.neckar.lizergy.model.configuration.components.ResolvedFacilityConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod.HeaterRodId
import it.neckar.lizergy.model.price.ProductResolver
import it.neckar.open.unit.si.m
import kotlinx.serialization.Serializable

@Serializable
data class SerializedFacilityConfiguration(
  override val id: FacilityConfigurationId = FacilityConfigurationId.random(),
  override val numberOfOptimizers: Int = 0,
  override val kabelwegZugschlagLength: @m Double = 0.0,
  override val numberUeberspannungsSchutz: Int? = null,

  override val inverterSelection: InverterIdSelection = InverterIdSelection.getEmpty(),
  override val inverterConfigurations: List<SerializedInverterConfiguration>? = null,
  override val batteryConfigurationId: BatteryConfigurationId? = null,

  override val facilityWorkEffort: ConfigurationItem = ConfigurationItem.risikoZuschlag,

  override val waermepumpenanbindung: Boolean = false,
  override val erdspiessSetzen: Boolean = false,
  override val onlineMonitoring: ConfiguredOptionality = ConfiguredOptionality.Selected,
  override val integrateInverterIntoNetwork: ConfiguredOptionality = ConfiguredOptionality.NotSelected,
  val heaterRodId: HeaterRodId? = null,
) : FacilityConfiguration {

  fun resolve(productResolver: ProductResolver): ResolvedFacilityConfiguration {
    val batteryConfiguration = batteryConfigurationId?.let { productResolver[it] }
    val resolvedInverterSelection = inverterSelection.resolve(productResolver)
    val resolvedInverterConfigurations = inverterConfigurations?.map { it.resolve(productResolver) }
    val heaterRod = heaterRodId?.let { productResolver[it] }

    return if (!resolvedInverterConfigurations.isNullOrEmpty()) {
      ResolvedFacilityConfiguration(
        id = id,
        numberOfOptimizers = numberOfOptimizers,
        kabelwegZugschlagLength = kabelwegZugschlagLength,
        numberUeberspannungsSchutz = numberUeberspannungsSchutz,
        inverterSelection = resolvedInverterSelection,
        inverterConfigurations = resolvedInverterConfigurations,
        batteryConfiguration = batteryConfiguration,
        facilityWorkEffort = facilityWorkEffort,
        waermepumpenanbindung = waermepumpenanbindung,
        erdspiessSetzen = erdspiessSetzen,
        onlineMonitoring = onlineMonitoring,
        integrateInverterIntoNetwork = integrateInverterIntoNetwork,
        heaterRod = heaterRod,
      )
    } else {
      ResolvedFacilityConfiguration(
        id = id,
        numberOfOptimizers = numberOfOptimizers,
        kabelwegZugschlagLength = kabelwegZugschlagLength,
        numberUeberspannungsSchutz = numberUeberspannungsSchutz,
        inverterSelection = resolvedInverterSelection,
        batteryConfiguration = batteryConfiguration,
        facilityWorkEffort = facilityWorkEffort,
        waermepumpenanbindung = waermepumpenanbindung,
        erdspiessSetzen = erdspiessSetzen,
        onlineMonitoring = onlineMonitoring,
        integrateInverterIntoNetwork = integrateInverterIntoNetwork,
        heaterRod = heaterRod,
      )
    }
  }

  fun duplicate(): SerializedFacilityConfiguration {
    return copy(id = FacilityConfigurationId.random())
  }

  companion object {
    fun getEmpty(): SerializedFacilityConfiguration {
      //inverterSelection = InverterIdSelection.getEmpty(),
      //batteryConfigurationId = null,
      return SerializedFacilityConfiguration(inverterSelection = InverterIdSelection.getEmpty(), inverterConfigurations = null, batteryConfigurationId = null)
    }
  }

}


fun ResolvedFacilityConfiguration.unResolve(): SerializedFacilityConfiguration {
  return SerializedFacilityConfiguration(
    id = id,
    numberOfOptimizers = numberOfOptimizers,
    kabelwegZugschlagLength = kabelwegZugschlagLength,
    numberUeberspannungsSchutz = numberUeberspannungsSchutz,
    batteryConfigurationId = batteryConfiguration?.id,
    inverterSelection = inverterSelection.unResolve(),
    inverterConfigurations = inverterConfigurations.map { it.unResolve() },
    facilityWorkEffort = facilityWorkEffort,
    waermepumpenanbindung = waermepumpenanbindung,
    erdspiessSetzen = erdspiessSetzen,
    onlineMonitoring = onlineMonitoring,
    integrateInverterIntoNetwork = integrateInverterIntoNetwork,
    heaterRodId = heaterRod?.id,
  )
}
