package components.project

import com.benasher44.uuid.Uuid
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.open.provider.DoubleProvider.Companion.nowMillis
import it.neckar.processStates.HasDueDate
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.uuid.HasUuid
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUsersAndCompanies

/**
 * Overview over the process states
 */
fun RBuilder.editProcessStateEntry(
  processStateFor: Uuid,
  availableProcessStates: List<LizergyProcessStates>,
  previousProcessState: LizergyProcessStates? = null,
  nextProcessState: LizergyProcessStates? = null,
  editableStatus: EditableStatus,
): Unit = child(EditProcessStateEntry) {
  attrs {
    this.processStateFor = processStateFor
    this.availableProcessStates = availableProcessStates
    this.previousProcessState = previousProcessState
    this.nextProcessState = nextProcessState
    this.editableStatus = editableStatus
  }
}

/**
 * Overview over the process states
 */
fun RBuilder.editProcessStateEntry(
  processStateFor: HasUuid,
  availableProcessStates: List<LizergyProcessStates>,
  previousProcessState: LizergyProcessStates? = null,
  nextProcessState: LizergyProcessStates? = null,
  editableStatus: EditableStatus,
): Unit = editProcessStateEntry(
  processStateFor = processStateFor.uuid,
  availableProcessStates = availableProcessStates,
  previousProcessState = previousProcessState,
  nextProcessState = nextProcessState,
  editableStatus = editableStatus,
)


val EditProcessStateEntry: FC<EditProcessStateEntryProps> = fc("EditProcessStateEntry") { props ->
  val processStateFor = props::processStateFor.safeGet()
  val availableProcessStates = props::availableProcessStates.safeGet()
  val previousProcessState = props::previousProcessState.safeGet()
  val nextProcessState = props::nextProcessState.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val processStateForComponent = useLoadProcessStatesForComponent(processStateFor)

  busyIfNull(processStateForComponent) { loadedProcessStateForComponent ->
    EditProcessStateEntry2 {
      attrs {
        this.processStateFor = processStateFor
        this.processStatesForComponent = loadedProcessStateForComponent
        this.availableProcessStates = availableProcessStates
        this.previousProcessState = previousProcessState
        this.nextProcessState = nextProcessState
        this.editableStatus = editableStatus
      }
    }
  }
}

val EditProcessStateEntry2: FC<EditProcessStateEntryProps2> = fc("EditProcessStateEntry2") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val usersAndCompanies = useSelectUsersAndCompanies()

  val processStateFor = props::processStateFor.safeGet()
  val processStatesForComponent = props::processStatesForComponent.safeGet()
  val availableProcessStates = props::availableProcessStates.safeGet()
  val previousProcessState = props::previousProcessState.safeGet()
  val nextProcessState = props::nextProcessState.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val processStateForComponent = processStatesForComponent.current()

  val selectedProcessState = useState(processStateForComponent?.processState)
  val currentUser = useState(processStateForComponent?.assignedTo ?: loggedInUser.loginName)
  val currentCompany = useState(processStateForComponent?.belongsTo ?: loggedInUser.company.companyCode)
  val initialDueDate = if (processStateForComponent is HasDueDate) processStateForComponent.dueDate else null
  val currentDueDate = useState(initialDueDate)


  useMemo(processStateForComponent) {
    selectedProcessState.setter(processStateForComponent?.processState)
  }

  useMemo(currentCompany.value) {
    if (usersAndCompanies.usersForCompany(currentCompany.value).map { it.loginName }.contains(currentUser.value).not()) {
      currentUser.setter(usersAndCompanies.defaultUserForCompany(currentCompany.value).loginName)
    }
  }

  useMemo(
    selectedProcessState.value,
    currentUser.value,
    currentDueDate.value,
  ) {
    if (
      selectedProcessState.value == processStateForComponent?.processState &&
      currentUser.value == processStateForComponent?.assignedTo &&
      currentCompany.value == processStateForComponent.belongsTo &&
      currentDueDate.value == initialDueDate
    ) return@useMemo

    selectedProcessState.value?.let { selectedValue ->
      UiActions.addProcessState(
        processStateFor = processStateFor,
        newProcessState = selectedValue.toProcessStateEntry(
          assignedTo = currentUser.value,
          belongsTo = currentCompany.value,
          assignedAt = nowMillis(),
          dueDate = currentDueDate.value,
          assignedBy = loggedInUser.loginName,
        ),
      )
    }
  }


  div("card") {

    div("card-header") {

      processStateStageRadioButtons(
        selectedProcessState = selectedProcessState,
        availableProcessStates = availableProcessStates,
        previousProcessState = previousProcessState,
        nextProcessState = nextProcessState,
        dueDate = currentDueDate.value,
        editableStatus = editableStatus,
      )

    }

    div("card-body") {
      div("row gx-0 gy-3") {

        div("col-md-6") {
          floatingSelect(
            valueAndSetter = currentCompany,
            idProvider = { it.value },
            formatter = { usersAndCompanies[it].name },
            availableOptions = usersAndCompanies.activeCompanies(loggedInUser).map { it.companyCode },
            fieldName = "currentCompany${processStateForComponent?.uuid}",
            title = "Firma",
            editableStatus = editableStatus,
          ) {
            attrs {
              mergedRight()
            }
          }
        }

        div("col-md-6") {
          //TODO think about this workaround!
          val usersForCompany = usersAndCompanies.usersForCompany(currentCompany.value)
          when {
            usersForCompany.isEmpty() -> {
              div("alert alert-warning") {
                +"Keine Benutzer für Firma ${currentCompany.value} gefunden"
              }
            }

            else -> {
              floatingSelect(
                valueAndSetter = currentUser,
                idProvider = { it.value },
                formatter = { usersAndCompanies[it].editorName },
                availableOptions = usersForCompany.map { it.loginName },
                fieldName = "currentUser_${processStateForComponent?.uuid}",
                title = "Bearbeiter",
                editableStatus = editableStatus,
              )
              {
                attrs {
                  mergedLeft()
                }
              }
            }
          }
        }

      }
    }

    if (processStateForComponent is HasDueDate) {
      div("card-footer") {
        div("row") {
          div("col-md-12") {
            datePickerNullable(dateState = currentDueDate)
          }
        }

      }
    }

  }

}


external interface EditProcessStateEntryProps : Props {
  var processStateFor: Uuid
  var availableProcessStates: List<LizergyProcessStates>
  var previousProcessState: LizergyProcessStates?
  var nextProcessState: LizergyProcessStates?
  var editableStatus: EditableStatus
}

external interface EditProcessStateEntryProps2 : Props {
  var processStateFor: Uuid
  var processStatesForComponent: List<LizergyProcessStateEntry>
  var availableProcessStates: List<LizergyProcessStates>
  var previousProcessState: LizergyProcessStates?
  var nextProcessState: LizergyProcessStates?
  var editableStatus: EditableStatus
}
