package components.project.configuration.layout

import components.form.commentSection
import components.project.configuration.isConfigurationEditable
import components.project.configuration.layout.moduleLayout.planning.moduleStringsPlanning
import components.project.freezableFormButtons
import components.project.isProjectEditable
import components.project.validationTable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.planning.PvStringsPlanningModelInformation
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.router.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import router.useModuleLayoutIdFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

val EditModuleStringsFromUrl: FC<Props> = fc("EditModuleStringsFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val moduleLayoutId = useModuleLayoutIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Stringplanung", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName, resolvedProject?.get(configurationId)?.get(moduleLayoutId)?.layoutName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    EditModuleStrings {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.moduleLayout = quoteConfiguration[moduleLayoutId]
        this.editableStatus = editableStatus
      }
    }
  }
}

val EditModuleStrings: FC<EditModuleStringsProps> = fc("EditModuleStrings") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val moduleLayout = props::moduleLayout.safeGet()
  val editableStatus = ReadOnly //props::editableStatus.safeGet()

  val roof = moduleLayout.roof

  val roofSize = roof.size
  val moduleType = moduleLayout.moduleType
  val modulesCount = moduleLayout.modulesCount
  val moduleAreasInformation = moduleLayout.moduleAreasInformation
  val unusableAreasInformation = moduleLayout.unusableAreasInformation
  val suggestedRoofInsets = moduleLayout.suggestedRoofInsets

  val roofInclination = roof.inclination?.angle
  val roofOrientation = roof.orientation?.orientation


  val currentPlanningModel = useMemo(
    moduleType.size,
    roofSize,
    suggestedRoofInsets,
    moduleAreasInformation,
    unusableAreasInformation,
  ) {
    if (roofSize != null) {
      PvStringsPlanningModelInformation(
        moduleSize = moduleType.size,
        roofSize = roofSize,
        suggestedRoofInsets = suggestedRoofInsets,
        moduleAreasInformation = moduleAreasInformation,
        unusableAreasInformation = unusableAreasInformation,
      )
    } else null
  }

  val resolvedModuleLayoutToSave = useMemo {
    moduleLayout.copy(
    )
  }

  val okAction = {
    UiActions.saveModuleLayout(project, quoteConfiguration, resolvedModuleLayoutToSave)
    navigate.back()
  }


  div {
    if (editableStatus == Editable) {
      onEnter { okAction() }
    }

    h2 {
      +moduleLayout.layoutName
    }

    div {

      currentPlanningModel?.let {
        div("row mb-3") {
          div("col-sm-12") {
            moduleStringsPlanning(
              model = it,
              editableStatus = editableStatus,
            ) { updated ->
              //Update grid, unusable areas and modules when changed
              //moduleAreasInformationToSave.setter(updated.moduleAreasInformation)
              //unusableAreasInformationToSave.setter(updated.unusableAreasInformation)
            }
          }
        }
      }

      p("form-text") {
        +"$modulesCount Module | Leistung: ${(moduleType.powerRating * modulesCount).formatKiloWattPeak(plannerI18nConfiguration)}"
      }
    }

    div("mb-5") {
      h3("mb-2") {
        +"Bemerkungen Dachfläche"
      }
      project.blueprint.roofAnnotations.firstOrNull { it.id == roof.id }?.let {
        it.annotation?.let { p { +it } }
      }
      commentSection(roof)
    }

    freezableFormButtons(editableStatus) { okAction() }

    div("my-5") {
      validationTable(resolvedModuleLayoutToSave.validationProblems)
    }
  }
}


external interface EditModuleStringsProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
  var moduleLayout: ResolvedModuleLayout
  var editableStatus: EditableStatus
}
