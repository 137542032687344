package components.company.pricelist.tables

import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.PriceWithProfitAndWorkingTime
import it.neckar.open.kotlin.lang.ifNull
import it.neckar.open.time.formatHourAndMinutes
import kotlinx.html.TBODY
import kotlinx.html.ThScope
import react.*
import react.dom.*
import store.hooks.useSelectPriceList

val MiscellaneousPriceTable: FC<Props> = fc("MiscellaneousPriceTable") {
  val priceList = useSelectPriceList()


  div("col") {
    h2 {
      +"Sonstiges"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          th(ThScope.col) {
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
        }
      }

      tbody {
        var rowIndex = 1

        rowIndex += addRow(rowIndex, "Baustelleneinrichtung", priceList.sitePreparation)
        rowIndex += addRow(rowIndex, "Preis pro gefahrenem km", priceList.perKilometerTravelled)
        rowIndex += addRow(rowIndex, "Aufpreis verstärktes Schienensystem (pro Modul)", priceList.aufpreisSchienensystemPrice)
        rowIndex += addRow(rowIndex, "Aufpreis schwarze Klemmen (pro Modul)", priceList.aufpreisSchwarzeKlemmePrice)
        rowIndex += addRow(rowIndex, "Integration PV Anlage in äußeren Blitzschutz", priceList.blitzschutzIntegrieren)
        rowIndex += addRow(rowIndex, "Gerüst pro m²", priceList.scaffolding)
        rowIndex += addRow(rowIndex, "Dachständerisolierung", priceList.dachStaenderIsolierung)
        rowIndex += addRow(rowIndex, "Überspannungsschutz", priceList.ueberSpannungsSchutz)
        rowIndex += addRow(rowIndex, "Satellitenantenne ummontieren", priceList.moveSatelliteDish)
        rowIndex += addRow(rowIndex, "Antenne entfernen", priceList.removeAntenna)
        rowIndex += addRow(rowIndex, "Inverter in Netzwerk integrieren", priceList.integrateInverterIntoNetwork)
        rowIndex += addRow(rowIndex, "Optimierer", priceList.optimizerPrice)
        rowIndex += addRow(rowIndex, "Zuschlag Kabelweg (pro m)", priceList.zuschlagKabelweg)
        rowIndex += addRow(rowIndex, "Aufschlag Kreuzverbund (pro Modul)", priceList.aufschlagKreuzverbund)
        rowIndex += addRow(rowIndex, "Elektrische Inbetriebnahme", priceList.startingUpPrice)
        rowIndex += addRow(rowIndex, "Sunny Home Manager 2.0 Energiemanagementsystem", priceList.sunnyHomeManager)
        rowIndex += addRow(rowIndex, "Wärmepumpenanbindung", priceList.waermepumpenanbindung)
        rowIndex += addRow(rowIndex, "SMA Energy Meter ", priceList.smaEnergyMeter)
        rowIndex += addRow(rowIndex, "Erdspieß setzen", priceList.erdspiessSetzen)
        rowIndex += addRow(rowIndex, "SLS nachrüsten", priceList.slsNachruesten)
        rowIndex += addRow(rowIndex, "Neuer Zählerschrank EFH Grober Schätzpreis, Abrechnung nach tatsächlichem Aufwand", priceList.neuerZaehlerschrank)
        rowIndex += addRow(rowIndex, "Zähler zusammenlegen", priceList.zaehlerschrankZusammenlegen)
        rowIndex += addRow(rowIndex, "Einbau Unterverteiler", priceList.einbauUnterverteiler)
        rowIndex += addRow(rowIndex, "Einbau digitaler Zähler", priceList.einbauDigitalerZaehlerPrice)
        rowIndex += addRow(rowIndex, "Überspannungsschutz", priceList.ueberSpannungsSchutz)
        rowIndex += addRow(rowIndex, "Überspannungsschutz DC Typ 1 & Typ 2", priceList.ueberSpannungsSchutzaWennExternerBlitzschutzVorhanden)
      }
    }
  }
}

private fun RDOMBuilder<TBODY>.addRow(rowIndex: Int, label: String, priceWithProfit: PriceWithProfit): Int {
  tr {
    th(ThScope.row) {
      +"$rowIndex"
    }

    td {
      +label
    }

    td {
    }

    td("text-end") {
      +priceWithProfit.inputPrice.format().ifNull("-")
    }
    td("text-end") {
      +priceWithProfit.sellingPrice.format().ifNull("-")
    }
    td("text-end") {
      +priceWithProfit.profit.format().ifNull("-")
    }
  }

  return 1
}

private fun RDOMBuilder<TBODY>.addRow(rowIndex: Int, label: String, priceWithProfitAndWorkingTime: PriceWithProfitAndWorkingTime): Int {
  tr {
    th(ThScope.row) {
      +"$rowIndex"
    }

    td {
      +label
    }

    td {
    }

    td("text-end") {
      +priceWithProfitAndWorkingTime.materialPrice.inputPrice.format().ifNull("-")
    }
    td("text-end") {
      +priceWithProfitAndWorkingTime.materialPrice.sellingPrice.format().ifNull("-")
    }
    td("text-end") {
      +priceWithProfitAndWorkingTime.materialPrice.profit.format().ifNull("-")
    }
  }

  tr {
    th(ThScope.row) {
    }

    td {
      +"Arbeitszeit:"
    }

    td("text-end") {
      +priceWithProfitAndWorkingTime.workingTimes.workingTime.formatHourAndMinutes().ifNull("-")
    }

    td("text-end") {
      +priceWithProfitAndWorkingTime.workingTimes.priceWithProfit.inputPrice.format().ifNull("-")
    }
    td("text-end") {
      +priceWithProfitAndWorkingTime.workingTimes.priceWithProfit.sellingPrice.format().ifNull("-")
    }
    td("text-end") {
      +priceWithProfitAndWorkingTime.workingTimes.priceWithProfit.profit.format().ifNull("-")
    }
  }

  tr {
    th(ThScope.row) {
    }

    td {
      b { +"Gesamt:" }
    }

    td("text-end") {
    }

    td("text-end") {
      b { +priceWithProfitAndWorkingTime.inputPrice.format().ifNull("-") }
    }
    td("text-end") {
      b { +priceWithProfitAndWorkingTime.sellingPrice.format().ifNull("-") }
    }
    td("text-end") {
      b { +priceWithProfitAndWorkingTime.profit.format().ifNull("-") }
    }
  }

  return 1
}
