package it.neckar.lizergy.model.configuration.components

enum class LegalNote {
  DachfanggeruestGestellt,
  VerstaerktesSchienensystem,
  SatellitenschuesselDemontage,
  DachstaenderDemontage,
  ErdarbeitenGestellt,
  ZaehlerschrankVorbereitet,
  LeerrohreVorbereitet,

  ;

  val legalText: String
    get() = when (this) {
      DachfanggeruestGestellt -> "Dachfanggerüst wird bauseits BG-konform für Dacharbeiten gestellt"

      VerstaerktesSchienensystem -> "Bei einem Sparrenabstand von mehr als 80 cm wird ein verstärktes Schienensystem benötigt. Die Mehrkosten für das verstärkte Schienensystem sind in diesem Angebot nicht enthalten"

      SatellitenschuesselDemontage -> "Die Demontage der Satellitenschüssel wird bauseits gestellt"

      DachstaenderDemontage -> "Die Demontage des Dachständers wird bauseits gestellt"

      ErdarbeitenGestellt -> "Erdarbeiten für die Kabelverlegung werden bauseits gestellt"

      ZaehlerschrankVorbereitet -> "Zählerschrank wird bauseits vorbereitet"

      LeerrohreVorbereitet -> "Leerrohre werden bauseits vorbereitet"
    }
}
