package components.project.configuration.additional

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.configuration.discount.EditDiscount
import components.project.configuration.isConfigurationEditable
import components.project.configuration.legalnotice.EditLegalNotice
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.kotlin.lang.nullIfBlank
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct100
import it.neckar.react.common.*
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser

val ConfigurationAdditionalOverviewFromUrl: FC<Props> = fc("ConfigurationAdditionalOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Zusätzliches", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    ConfigurationAdditionalOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val ConfigurationAdditionalOverview: FC<QuoteConfigurationTabProps> = fc("ConfigurationAdditionalOverview") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val legalNoticeAdditionalLineToSave = useState(quoteConfiguration.legalNoticeAdditionalLine.orEmpty())
  val legalNotesToSave = useState(quoteConfiguration.legalNotes)

  @pct100 val discountPercentageToSave = useState(quoteConfiguration.discountPercentage * 100.0)


  useMemo(legalNoticeAdditionalLineToSave.value, legalNotesToSave.value, discountPercentageToSave.value) {
    quoteConfiguration.copy(
      legalNoticeAdditionalLine = legalNoticeAdditionalLineToSave.value.nullIfBlank(),
      legalNotes = legalNotesToSave.value,
      discountPercentage = discountPercentageToSave.value.percent.coerceAtLeast(0.0),
    ).also {
      UiActions.saveQuoteConfiguration(project, it)
    }
  }


  div("my-5") {

    div("my-4") {
      ConfigurationAdditionalPositions {
        attrs {
          this.project = project
          this.quoteConfiguration = quoteConfiguration
          this.editableStatus = editableStatus
        }
      }
    }

    div("my-5") {
      EditLegalNotice {
        attrs {
          this.legalNoticeAdditionalLineToSave = legalNoticeAdditionalLineToSave
          this.legalNotesToSave = legalNotesToSave
          this.scaffoldingSupplied = quoteConfiguration.scaffoldingSupplied
          this.editableStatus = editableStatus
        }
      }
    }

    div("row my-5") {
      EditDiscount {
        attrs {
          this.discountPercentageToSave = discountPercentageToSave
          this.subTotal = null
          this.editableStatus = editableStatus
        }
      }
    }

    div("my-5") {
      h3("mb-3") {
        +"Bemerkungen Zusätzliches"
      }
      commentSection(quoteConfiguration.additionalPositions)
    }
  }

}
