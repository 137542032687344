package components.project.configuration.layout.moduleLayout.roof

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedRoof
import react.*
import react.dom.*

/**
 * Preview on module layout
 */
fun RBuilder.roofPreview(resolvedRoof: ResolvedRoof): Unit = child(roofPreview) {
  attrs {
    this.resolvedRoof = resolvedRoof
  }
}

val roofPreview: FC<RoofPreviewProps> = fc("roofPreview") { props ->
  val resolvedRoof = props::resolvedRoof.safeGet()


  div {

    p("mb-3") {
      +"${resolvedRoof.dimensionsFormatted()}${resolvedRoof.areaFormatted()?.let { " = $it" }.orEmpty()}"
    }

    p {
      +resolvedRoof.roofType.description
    }

    if (resolvedRoof.roofType.metalRoofTileCompatability.compatible) {
      p {
        +"Ziegeltyp: ${resolvedRoof.roofTileTypeDescription}"
      }

      resolvedRoof.metalRoofTileConstructionType?.let { metalRoofTileConstructionType ->
        resolvedRoof.roofTile?.let { roofTileType ->
          p {
            +"Metalldachziegel: ${roofTileType.metalRoofTile.description}"
            metalRoofTileConstructionType.format(roofTileType.description).let { metalRoofTileConstructionType ->
              br {}
              span("form-text") {
                +metalRoofTileConstructionType
              }
            }
          }
        }
      }
    }

    resolvedRoof.inclination?.let {
      p("mt-3") {
        +"Neigung: ${it.format()}"
      }
    }
    resolvedRoof.orientation?.let {
      p("mb-2") {
        +"Ausrichtung: ${it.format()}"
      }
    }

    resolvedRoof.performanceFactor?.let {
      p {
        +"Effektivität: ${it.format()}"
      }
    }

  }

}

external interface RoofPreviewProps : Props {
  var resolvedRoof: ResolvedRoof
}
