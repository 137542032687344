package components.company.pricelist.tables

import it.neckar.lizergy.model.configuration.quote.builder.HybridInverter
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.kotlin.lang.ifNull
import it.neckar.open.kotlin.lang.toCheckboxChar
import it.neckar.open.time.formatHourAndMinutes
import kotlinx.css.*
import kotlinx.html.ThScope
import kotlinx.html.classes
import react.*
import react.dom.*
import store.hooks.useSelectPriceList
import style.toStyleClass
import styled.*

val BatteryConfigurationPriceTable: FC<Props> = fc("BatteryConfigurationPriceTable") {
  val priceList = useSelectPriceList()

  val batteryConfigurationPrices = priceList.batteryConfigurationPrices


  div("col") {
    h2 {
      +"Batteriespeicher-Systeme"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          th(ThScope.col, "text-end") {
            +"Kapazität"
          }
          styledTh {
            attrs {
              classes = setOf("text-center")
            }
            css {
              maxWidth = 150.px
            }
            +"Kompatibel mit Lizergy Independence Manager"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
          th(ThScope.col, "text-end") {
            +"In Gebrauch"
          }
        }
      }

      tbody {
        batteryConfigurationPrices.available.filter {
          it.inverterType !is HybridInverter
        }.fastForEachIndexed { index, batteryConfiguration ->
          val lifeCycleState = batteryConfiguration.lifeCycleState

          tr(classes = lifeCycleState.toStyleClass()) {
            key = batteryConfiguration.uuid.toString()

            th(ThScope.row) {
              +"${index + 1}"
            }
            styledTd {
              css {
                maxWidth = 250.px
              }
              +batteryConfiguration.format()
            }

            td("text-end") {
              +batteryConfiguration.totalStorage.format()
            }
            td("text-center") {
              +batteryConfiguration.battery.compatibleWithIndependenceManager.toCheckboxChar()
            }

            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].materialPrice.inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].materialPrice.sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].materialPrice.profit.format().ifNull("-")
            }

            td("text-center") {
              +lifeCycleState.format()
            }
          }

          tr(classes = lifeCycleState.toStyleClass()) {
            td {
            }
            th(ThScope.row) {
              +"Arbeitszeit:"
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].workingTimes.workingTime.formatHourAndMinutes().ifNull("-")
            }
            td("text-center") {
            }

            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].workingTimes.priceWithProfit.inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].workingTimes.priceWithProfit.sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].workingTimes.priceWithProfit.profit.format().ifNull("-")
            }

            td("text-center") {
            }
          }

          tr(classes = lifeCycleState.toStyleClass()) {
            td {
            }
            th(ThScope.row) {
              b { +"Gesamt:" }
            }
            td("text-end") {
            }
            td("text-center") {
            }

            td("text-end") {
              b { +batteryConfigurationPrices[batteryConfiguration.uuid].inputPrice.format().ifNull("-") }
            }
            td("text-end") {
              b { +batteryConfigurationPrices[batteryConfiguration.uuid].sellingPrice.format().ifNull("-") }
            }
            td("text-end") {
              b { +batteryConfigurationPrices[batteryConfiguration.uuid].profit.format().ifNull("-") }
            }

            td("text-center") {
            }
          }

        }
      }
    }

    hr("my-5") {}

    h2 {
      +"Batteriespeicher-Systeme (inklusive Batterie-Wechselrichter)"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          th(ThScope.col, "text-end") {
            +"Kapazität"
          }
          styledTh {
            attrs {
              classes = setOf("text-center")
            }
            css {
              maxWidth = 150.px
            }
            +"Kompatibel mit Lizergy Independence Manager"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
          th(ThScope.col, "text-end") {
            +"In Gebrauch"
          }
        }
      }

      tbody {
        batteryConfigurationPrices.available.filter {
          it.inverterType is HybridInverter
        }.fastForEachIndexed { index, batteryConfiguration ->
          val lifeCycleState = batteryConfiguration.lifeCycleState

          tr(classes = lifeCycleState.toStyleClass()) {
            key = batteryConfiguration.uuid.toString()

            th(ThScope.row) {
              +"${index + 1}"
            }
            styledTd {
              css {
                maxWidth = 250.px
              }
              +batteryConfiguration.format()
            }

            td("text-end") {
              +batteryConfiguration.totalStorage.format()
            }
            td("text-center") {
              +batteryConfiguration.battery.compatibleWithIndependenceManager.toCheckboxChar()
            }

            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].materialPrice.inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].materialPrice.sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].materialPrice.profit.format().ifNull("-")
            }

            td("text-center") {
              +lifeCycleState.format()
            }
          }

          tr(classes = lifeCycleState.toStyleClass()) {
            td {
            }
            th(ThScope.row) {
              +"Arbeitszeit:"
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].workingTimes.workingTime.formatHourAndMinutes().ifNull("-")
            }
            td("text-center") {
            }

            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].workingTimes.priceWithProfit.inputPrice.format().ifNull("-")
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].workingTimes.priceWithProfit.sellingPrice.format().ifNull("-")
            }
            td("text-end") {
              +batteryConfigurationPrices[batteryConfiguration.uuid].workingTimes.priceWithProfit.profit.format().ifNull("-")
            }

            td("text-center") {
            }
          }

          tr(classes = lifeCycleState.toStyleClass()) {
            td {
            }
            th(ThScope.row) {
              b { +"Gesamt:" }
            }
            td("text-end") {
            }
            td("text-center") {
            }

            td("text-end") {
              b { +batteryConfigurationPrices[batteryConfiguration.uuid].inputPrice.format().ifNull("-") }
            }
            td("text-end") {
              b { +batteryConfigurationPrices[batteryConfiguration.uuid].sellingPrice.format().ifNull("-") }
            }
            td("text-end") {
              b { +batteryConfigurationPrices[batteryConfiguration.uuid].profit.format().ifNull("-") }
            }

            td("text-center") {
            }
          }

        }
      }
    }

  }
}
