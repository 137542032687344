package it.neckar.react.common.coroutines

import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.react.common.annotations.*
import kotlinx.coroutines.*
import react.*


private val logger: Logger = LoggerFactory.getLogger("it.neckar.react.common.coroutines.ReactCoroutines")


/**
 * Creates a new coroutine scope.
 * This scope will automatically be cancelled when the component is unmounted.
 */
@UsesHooks
fun useCoroutineScope(dispatcher: CoroutineDispatcher = Dispatchers.Main): CoroutineScope {
  //Create the scope itself
  val coroutineScope: CoroutineScope = useMemo {
    CoroutineScope(dispatcher).also {
      it.coroutineContext[Job]?.invokeOnCompletion {
        logger.debug("Job for coroutineScope completed")
      }
    }
  }

  //Register the cleanup effect
  logger.debug("register cleanup effect for coroutine")

  useEffectOnceWithCleanup {
    logger.debug("register cleanup effect for coroutine")
    onCleanup {
      logger.debug("Cancel coroutineScope for component")
      coroutineScope.cancel(CancellationException("Component unmounted"))
    }
  }
  return coroutineScope
}
