@file:UseSerializers(UuidSerializer::class)

package it.neckar.processStatesClient

import com.benasher44.uuid.Uuid
import it.neckar.processStates.ProcessStateEntry
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * A tuple of the UUID and the ProcessStateEntry
 */
@Serializable
data class SendProcessStatesTuple(
  val processStateFor: Uuid,
  val processStateEntry: ProcessStateEntry,
)
