package it.neckar.lizergy.model.configuration.components

import com.meistercharts.charts.lizergy.roofPlanning.PerModule
import it.neckar.open.kotlin.lang.toIntCeil
import it.neckar.open.unit.si.km
import it.neckar.open.unit.time.d
import it.neckar.open.unit.time.h
import kotlin.time.Duration
import kotlin.time.DurationUnit

/**
 * Calculates the assembly time for a project
 */
object AssemblyTimeCalculator {

  /**
   * Calculates the total assembly times
   */
  fun estimateAssemblyTime(numberOfModules: Int, timePerModule: @h @PerModule Duration): Duration {
    return timePerModule * numberOfModules
  }

  /**
   * "Personentage"
   */
  fun estimateAssemblyTimeManDays(numberOfModules: Int, timePerModule: @h @PerModule Duration, workingHoursPerDay: @h Double): @d Double {
    val estimatedWorkingHours: @h Double = estimateAssemblyTime(numberOfModules, timePerModule).toDouble(DurationUnit.HOURS)
    return estimatedWorkingHours / workingHoursPerDay
  }

  /**
   * Returns the number of trips (both directions)
   */
  fun estimatedNumberOfTripsForAssembly(numberOfModules: Int, timePerModule: @h @PerModule Duration, workingHoursPerDay: @h Double): Int {
    val manDays: @d Double = estimateAssemblyTimeManDays(numberOfModules, timePerModule, workingHoursPerDay)
    // Divide by 3 as it is assumed that there will always be working people at once
    return (manDays / 3).toIntCeil() * 2
  }

  /**
   * Calculates the estimated distance travelled for assembly
   */
  fun estimatedTotalDistanceTravelledForAssembly(numberOfModules: Int, timePerModule: @h @PerModule Duration, shippingDistance: @km Int, workingHoursPerDay: @h Double): @km Int {
    val numberOfTrips = estimatedNumberOfTripsForAssembly(numberOfModules, timePerModule, workingHoursPerDay)
    return numberOfTrips * shippingDistance
  }
}
