package it.neckar.lizergy.model.configuration.energy

import it.neckar.open.formatting.decimalFormat2digits
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.other.Wp
import it.neckar.open.unit.other.kWp
import it.neckar.open.unit.si.A
import it.neckar.open.unit.si.V
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * The power current in [A]
 */
@Serializable
@JvmInline
value class Current internal constructor(
  val ampere: @A Double,
) : Comparable<Current> {

  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "${decimalFormat2digits.format(ampere, i18nConfiguration)}${whitespaceConfig.smallSpace}A"
  }

  override operator fun compareTo(other: Current): Int {
    return this.ampere.compareTo(other.ampere)
  }

  operator fun minus(other: Current): Current {
    return Current(this.ampere - other.ampere)
  }

  operator fun plus(other: Current): Current {
    return Current(this.ampere + other.ampere)
  }

  operator fun times(factor: Double): Current {
    return Current(ampere * factor)
  }

  operator fun times(factor: Int): Current {
    return Current(ampere * factor)
  }

  override fun toString(): String {
    return format(I18nConfiguration.Germany, WhitespaceConfig.Spaces)
  }


  companion object {
    val Zero: Current = Current(0.0)

    fun ampere(ampere: @V Double): Current {
      return Current(ampere)
    }
  }
}

/**
 * Converts a value to watt peak
 */
val @Wp Int.ampere: Current
  get() = Current.ampere(this.toDouble())

val @kWp Double.ampere: Current
  get() = Current.ampere(this)


/**
 * Calculates the sum of power ratings
 */
fun Iterable<Current>.sum(): Current {
  return sumOf {
    it.ampere
  }.ampere
}
