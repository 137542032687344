package components.gridAssessment

import components.form.commentSection
import components.project.configuration.additional.ExistingFacilitiesOverview
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.RouterUrls
import services.UiActions

val GridAssessmentExistingFacilitiesOverview: FC<GridAssessmentExistingFacilitiesOverviewProps> = fc("GridAssessmentExistingFacilitiesOverview") { props ->
  val navigate = useNavigateUrl()

  val project = props::project.safeGet()
  val configuration = props::currentConfiguration.safeGet()

  val editableStatus = project.isProjectEditable()

  val existingFacilityConfigurationToSave = useState(configuration.existingFacilitiesConfiguration)


  useMemo(configuration.existingFacilitiesConfiguration) {
    existingFacilityConfigurationToSave.setter(configuration.existingFacilitiesConfiguration)
  }


  div("row my-5") {

    div("col-lg-6") {
      ExistingFacilitiesOverview {
        attrs {
          this.existingFacilitiesConfigurationToSave = existingFacilityConfigurationToSave
          this.editableStatus = editableStatus

          this.addPVAction = {
            UiActions.addExistingPVFacilityToConfiguration(project, configuration).also { existingFacility ->
              navigate(RouterUrls.project(project).configuration(configuration.configurationId).existingPVFacility(existingFacility.uuid))
            }
          }
          this.addBHKWAction = {
            UiActions.addExistingBHKWFacilityToConfiguration(project, configuration).also { existingFacility ->
              navigate(RouterUrls.project(project).configuration(configuration.configurationId).existingBHKWFacility(existingFacility.uuid))
            }
          }
          this.editPVAction = { existingFacility ->
            navigate(RouterUrls.project(project).configuration(configuration).existingPVFacility(existingFacility.uuid))
          }
          this.editBHKWAction = { existingFacility ->
            navigate(RouterUrls.project(project).configuration(configuration).existingBHKWFacility(existingFacility.uuid))
          }
          this.deletePVAction = { existingFacility ->
            UiActions.removeExistingPVFacilityFromConfiguration(project, configuration, existingFacility)
          }
          this.deleteBHKWAction = { existingFacility ->
            UiActions.removeExistingBHKWFacilityFromConfiguration(project, configuration, existingFacility)
          }

        }
      }
    }

    div("col-lg-6") {
      h3("mb-2") {
        +"Bemerkungen Bestandsanlage"
      }
      commentSection(configuration.existingFacilitiesConfiguration, ReadOnly)

      if (configuration.existingFacilitiesConfiguration.existingPVFacilities.isNotEmpty()) {
        h4("mb-2") { +"Bestandsanlage PV" }
        configuration.existingFacilitiesConfiguration.existingPVFacilities.fastForEach {
          div {
            h5("mb-2") { +it.zaehlernummer }
            commentSection(it)
          }
        }
      }

      if (configuration.existingFacilitiesConfiguration.existingBHKWFacilities.isNotEmpty()) {
        h4("mb-2") { +"Bestandsanlage BHKW" }
        configuration.existingFacilitiesConfiguration.existingBHKWFacilities.fastForEach {
          div {
            h5("mb-2") { +it.zaehlernummer }
            commentSection(it)
          }
        }
      }

    }

  }

  div("row my-3") {
    div("col-lg-6") {
      h3("mb-2") { +"Bemerkungen Zusätzliches" }
      commentSection(configuration.additionalPositions)
    }
    div("col-lg-6") {
    }
  }

}


external interface GridAssessmentExistingFacilitiesOverviewProps : Props {
  var project: ResolvedProject
  var currentConfiguration: QuoteConfiguration
}
