package components.company.pricelist.tables

import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import kotlinx.html.ThScope
import plannerI18nConfiguration
import react.*
import react.dom.*
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectPriceList
import style.toStyleClass

val InverterPriceTable: FC<Props> = fc("InverterPriceTable") {
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()

  val availableInverterTypes = availableProducts.availableInverters(null)
  val inverterPrices = priceList.inverterPrices


  div("col") {
    h2 {
      +"Wechselrichter"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          th(ThScope.col) {
            +"Leistung"
          }
          th(ThScope.col) {
            +"MPP-Eingänge"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
          th(ThScope.col, "text-center") {
            +"In Gebrauch"
          }
        }
      }

      tbody {
        availableInverterTypes.fastForEachIndexed { inverterIndex, inverter ->
          tr(classes = inverter.lifeCycleState.toStyleClass()) {
            key = inverter.uuid.toString()

            th(ThScope.row) {
              +(inverterIndex + 1).toString()
            }
            td {
              +inverter.description
            }
            td {
              +inverter.capacity.format(plannerI18nConfiguration)
            }
            td {
              inverter.mppInputs.fastForEach { p { +it.format() } }
            }

            td("text-end") {
              +inverterPrices[inverter.uuid].inputPrice.format()
            }
            td("text-end") {
              +inverterPrices[inverter.uuid].sellingPrice.format()
            }
            td("text-end") {
              +inverterPrices[inverter.uuid].profit.format()
            }

            td("text-center") {
              +inverter.lifeCycleState.format()
            }
          }
        }
      }
    }
  }

}
