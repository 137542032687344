package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.lizergy.model.configuration.quote.economics.YearIndex
import it.neckar.open.kotlin.lang.fastMap
import kotlinx.serialization.Serializable

/**
 * Contains the information about how much money is payed within a certain period of time (year, month etc)
 */
@Serializable
data class TariffInformation(
  /**
   * The guaranteed price
   */
  val feedInPrice: PricePerKWh,

  /**
   * The price we save for own consumption
   */
  val ownConsumptionPrice: PricePerKWh,

  ) {

  class Calculator(
    var feedInGuaranteedPriceFor20Years: PricePerKWh,

    /**
     * The base price and increase used for own consumption
     */
    var consumptionPrice: IncreasingPricePerKWh,
  ) {

    /**
     * Returns the consumption price for the given year
     */
    fun calculateConsumptionPrice(yearIndex: YearIndex): PricePerKWh {
      return consumptionPrice.calculateValue(yearIndex)
    }

    fun calculate(yearIndex: YearIndex): TariffInformation {
      return TariffInformation(
        feedInGuaranteedPriceFor20Years,
        calculateConsumptionPrice(yearIndex)
      )
    }

    /**
     * Calculates the complete series
     */
    fun calculateSeries(numberOfYears: Int): List<TariffInformation> {
      return numberOfYears.fastMap {
        calculate(YearIndex(it))
      }
    }
  }
}
