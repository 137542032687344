package components.accounting

import components.form.commentSection
import components.project.LocalStorageKeys
import components.project.ResolvedProjectPreviewsTable
import components.project.projectsFiltering
import it.neckar.commons.kotlin.js.LocalStorageKey
import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.commons.kotlin.js.LocalStorageSupport
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.customer.company.NeckarITCompanyProfile
import it.neckar.customer.company.PartnerCompanyProfile
import it.neckar.customer.company.TestCompanyProfile
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.lizergy.model.price.AccountingStatus
import it.neckar.lizergy.model.price.ResolvedCompanyEarningsEntry
import it.neckar.lizergy.model.price.ResolvedEarningsEntry
import it.neckar.lizergy.model.price.ResolvedNeckarITEarningsEntry
import it.neckar.lizergy.model.project.previews.AccountingProjectPreview
import it.neckar.lizergy.model.project.previews.AccountingResolvedConfigurationPreview
import it.neckar.lizergy.model.project.previews.AccountingResolvedProjectPreview
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.open.collections.fastForEach
import it.neckar.open.formatting.dateFormat
import it.neckar.open.formatting.format
import it.neckar.open.kotlin.lang.divFloor
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleCheck
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import it.neckar.react.common.table.*
import it.neckar.user.UserLoginName
import kotlinx.html.DIV
import kotlinx.html.InputType
import kotlinx.html.title
import plannerI18nConfiguration
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import services.UiActions
import services.http.AccountingQuerySelection
import services.http.CompanyRevenue
import services.http.ProjectQuerySortedBy
import services.http.ProjectQuerySortedColumn
import services.http.ProjectQuerySorting
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver

val AccountingOverview2: FC<Props> = fc("AccountingOverview2") {
  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val companyResolver = useSelectCompanyResolver()
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Abrechnung")

  val keyPrefix = LocalStorageKeyPrefix("Accounting2")

  val filteredByCompanyCode: StateInstance<CompanyCode?> = useState(null)

  // If the value is null, all projects shall be shown.
  val filteredByMaintainerName: StateInstance<UserLoginName?> = useState(
    LocalStorageSupport.loadFromLocalStorage(
      key = LocalStorageKey("${keyPrefix}${LocalStorageKeys.maintainer}"),
      serializer = UserLoginName.serializer()
    )
  )

  // If the value is null, all projects shall be shown.
  val filteredByEditorName: StateInstance<UserLoginName?> = useState(
    LocalStorageSupport.loadFromLocalStorage(
      key = LocalStorageKey("${keyPrefix}${LocalStorageKeys.maintainer}"),
      serializer = UserLoginName.serializer()
    )
  )

  val filteredByProjectState: StateInstance<LizergyProcessStates?> = useState(null) //default: show all projects - but not archived!

  val filterInputStateProject = useState("")
  val filterInputStateAddress = useState("")

  val sortedByFunction = useState(
    SortedByFunction(
      sortedBy = SortedBy.SortedDescending,
      tableDataColumn = ResolvedProjectPreviewsTable.getProcessStateColumn<AccountingResolvedProjectPreview>(null),
    )
  )

  val pageSize: StateInstance<Int?> = useState(15)
  val currentPage = useState(0)

  val visibleProjects: StateInstance<List<AccountingResolvedProjectPreview>?> = useState(null)
  val numberOfFilteredProjects: StateInstance<Int?> = useState(null)
  val numberOfRelevantProjects: StateInstance<Int?> = useState(null)

  val lizergyRevenue: StateInstance<CompanyRevenue?> = useState(null)
  val neckarITRevenue: StateInstance<CompanyRevenue?> = useState(null)
  val partnerRevenues: StateInstance<List<CompanyRevenue>?> = useState(null)

  val accountingQuerySelection = useState(AccountingQuerySelection.SelectedForAccounting)
  val howDoYouLikeYourQuoteElements = useState(AccountingProjectPreview.QuoteElements.Frozen)


  useEffect(
    filteredByCompanyCode.value,
    filteredByMaintainerName.value,
    filteredByEditorName.value,
    filterInputStateProject.value,
    filterInputStateAddress.value,
    filteredByProjectState.value,
    sortedByFunction.value,
    pageSize.value,
    currentPage.value,
    accountingQuerySelection.value,
    howDoYouLikeYourQuoteElements.value,
  ) {
    val sorting = ProjectQuerySorting(
      sortedBy = when (sortedByFunction.value.sortedBy) {
        SortedBy.Unsorted -> ProjectQuerySortedBy.Unsorted
        SortedBy.SortedAscending -> ProjectQuerySortedBy.SortedAscending
        SortedBy.SortedDescending -> ProjectQuerySortedBy.SortedDescending
      },
      sortedColumn = when (sortedByFunction.value.sortedColumn.id) {
        "project" -> ProjectQuerySortedColumn.ProjectColumn
        "accountingTable" -> ProjectQuerySortedColumn.AccountingTable
        "processState" -> ProjectQuerySortedColumn.ProcessStateColumn
        else -> throw IllegalStateException("Unknown sortedColumn: ${sortedByFunction.value.sortedColumn.id}")
      },
    )

    visibleProjects.setter(null)
    numberOfFilteredProjects.setter(null)
    numberOfRelevantProjects.setter(null)

    UiActions.accountingQueryProjectPreviews(
      processStatesToFilter = LizergyProcessStates.allAccountingProcessStates,
      processStatesToHide = listOf(ProjectProcessStates.Archived, ProjectProcessStates.Paused),
      accountingQuerySelection = accountingQuerySelection.value,
      filteredByCompanyCode = filteredByCompanyCode.value,
      filteredByMaintainer = filteredByMaintainerName.value,
      filteredByEditor = filteredByEditorName.value,
      filterValueProject = filterInputStateProject.value,
      filterValueAddress = filterInputStateAddress.value,
      filterByProjectState = filteredByProjectState.value,
      indexOfFirstVisibleProject = pageSize.value?.let { currentPage.value * it } ?: 0,
      indexOfLastVisibleProject = pageSize.value?.let { (currentPage.value + 1) * it } ?: Int.MAX_VALUE,
      sorting = sorting,
      projectQueryComponent = null,
      howDoYouLikeYourQuoteElements = howDoYouLikeYourQuoteElements.value,
      loggedInUser = loggedInUser.loginName,
    ) { projectQuery ->
      val queryInformation = projectQuery?.data
      visibleProjects.setter(queryInformation?.visibleProjectPreviews)
      numberOfFilteredProjects.setter(queryInformation?.numberOfTotalFilteredProjects)
      numberOfRelevantProjects.setter(queryInformation?.numberOfTotalRelevantProjects)
      currentPage.setter(currentPage.value.coerceIn(0, queryInformation?.numberOfTotalFilteredProjects?.divFloor(pageSize.value ?: 1)))

      lizergyRevenue.setter(queryInformation?.lizergyRevenue)
      neckarITRevenue.setter(queryInformation?.neckarITRevenue)
      partnerRevenues.setter(queryInformation?.partnerRevenues)
    }
  }


  div {

    h2 {
      +"Abrechnung"
    }

    RevenueOverview {
      attrs {
        this.lizergyRevenue = lizergyRevenue.value
        this.neckarITRevenue = neckarITRevenue.value
        this.partnerRevenues = partnerRevenues.value
      }
    }

    div {

      projectsFiltering(
        filteredByCompanyCode = filteredByCompanyCode,
        filteredByMaintainerName = filteredByMaintainerName,
        filteredByEditorName = filteredByEditorName,
        filteredByProjectState = filteredByProjectState,
        filterInputStateProject = filterInputStateProject,
        filterInputStateAddress = filterInputStateAddress,
        processStatesToFilter = LizergyProcessStates.allAccountingProcessStates,
        localStorageKeyPrefix = keyPrefix,
      )

      div("row g-2 mt-2 mb-3") {
        div("col") {
          floatingSelectEnum(
            valueAndSetter = accountingQuerySelection,
            formatter = {
              when (it) {
                AccountingQuerySelection.All -> "ALLE"
                AccountingQuerySelection.PendingForAccounting -> "Ausstehend"
                AccountingQuerySelection.SelectedForAccounting -> "Gebucht"
                AccountingQuerySelection.Accounted -> "Abgerechnet"
              }
            },
            availableOptions = listOf(AccountingQuerySelection.SelectedForAccounting, AccountingQuerySelection.Accounted),
            fieldName = "accountingAccountingQuerySelection",
            title = "Projektauswahl",
          )
        }

        div("col") {
          floatingSelectEnum(
            valueAndSetter = howDoYouLikeYourQuoteElements,
            formatter = {
              when (it) {
                AccountingProjectPreview.QuoteElements.Frozen -> "Nur eingefrorene Preise"
                AccountingProjectPreview.QuoteElements.UpToDate -> "Nur aktuelle Preise"
                AccountingProjectPreview.QuoteElements.Whatever -> "Aktuelle Preise, falls Eingefrorene nicht vorhanden"
              }
            },
            availableOptions = AccountingProjectPreview.QuoteElements.entries,
            fieldName = "accountingHowDoYouLikeYourQuoteElements",
            title = "Preise",
          )
        }
      }

      busyIfNull(visibleProjects.value) { loadedProjectPreviews ->
        remoteTableWithPagination(
          tableClasses = "table table-striped table-responsive",
          entries = loadedProjectPreviews,
          columns = buildList {
            add(ResolvedProjectPreviewsTable.editButtonFirstColumn { navigate(RouterUrls.project(it).configuration((it.currentConfigurationPreview ?: return@editButtonFirstColumn).configurationId).earnings) })
            add(getAccountingProjectColumnWithComments(companyResolver))
            add(accountingTable(loggedInUser = loggedInUser, userResolver = userResolver, companyResolver = companyResolver, howDoYouLikeYourQuoteElements = howDoYouLikeYourQuoteElements.value))
            add(ResolvedProjectPreviewsTable.getMostRecentProcessStateColumn())
          },
          pageSize = pageSize,
          currentPage = currentPage,
          numberOfTotalFilteredProjects = numberOfFilteredProjects.value ?: 0,
          sortedByFunction = sortedByFunction,
        )
      }

      div("mt-1") {
        span("form-text") {
          +"${numberOfFilteredProjects.value ?: "-"} von ${numberOfRelevantProjects.value ?: "-"} Projekten ausgewählt"
        }
      }

    }

  }

}


data class AccountingTableEntries(
  val companyProfile: CompanyProfile,
  val configurationPreview: AccountingResolvedConfigurationPreview,
  val earningsCompanyEntries: List<EarningsCompanyEntryAndStates>,
) {
  val earningsCompanyEntriesByCompany: Map<CompanyCode, List<EarningsCompanyEntryAndStates>>
    get() = earningsCompanyEntries.groupBy { it.earningsCompanyEntry.company }

  val relevantEarningsCompanyEntries: List<EarningsCompanyEntryAndStates>
    get() = when (companyProfile) {
      is MainCompanyProfile -> emptyList()
      NeckarITCompanyProfile, is PartnerCompanyProfile -> earningsCompanyEntriesByCompany[companyProfile.companyCode] ?: emptyList()
      is TestCompanyProfile -> emptyList()
    }

  val openEarningsCompanyEntries: List<EarningsCompanyEntryAndStates>
    get() = relevantEarningsCompanyEntries.filter { it.accountingStatusState.value == AccountingStatus.Pending }

  fun relevantEarnings(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return when (companyProfile) {
      is MainCompanyProfile -> configurationPreview.getEarningsForMainCompany(howDoYouLikeYourQuoteElements)
      is PartnerCompanyProfile, is TestCompanyProfile -> configurationPreview.getEarningsForCompany(companyProfile.companyCode, howDoYouLikeYourQuoteElements)
      NeckarITCompanyProfile -> configurationPreview.getNeckarITEarnings(howDoYouLikeYourQuoteElements)
    }
  }
}

data class EarningsCompanyEntryAndStates(
  val earningsCompanyEntry: ResolvedEarningsEntry,
  val incomeState: StateInstance<Double?>,
  val accountingStatusState: StateInstance<AccountingStatus>,
)

data class IncomePercentageCategoryAndState(
  val incomePercentageCategory: IncomePercentageCategory,
  val incomeState: StateInstance<Double?>,
)


fun getAccountingProjectColumnWithComments(companyResolver: CompanyResolver): TableDataColumn<AccountingResolvedProjectPreview> {
  return TableDataColumn(
    id = "project",
    title = "Projekt",
    sortFunction = compareBy { it.displayName },
  ) { projectPreview ->
    {
      accountingProjectColumnDetails(projectPreview, companyResolver)
      div("my-4") {
        projectPreview.currentConfigurationPreview?.earningsDistribution?.let { commentSection(it) }
      }
    }
  }
}

fun accountingTable(loggedInUser: UserInformation, userResolver: UserResolver, companyResolver: CompanyResolver, howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): TableDataColumn<AccountingResolvedProjectPreview> {
  return TableDataColumn(
    id = "accountingTable",
    title = "Abrechnung",
    sortFunction = compareBy { it.currentConfigurationPreview?.getNetPrice(howDoYouLikeYourQuoteElements) },
  ) { projectPreview ->
    val currentConfigurationPreview = projectPreview.currentConfigurationPreview
    val earningsDistribution = currentConfigurationPreview?.earningsDistribution
    val manualQuoteElements = currentConfigurationPreview?.manualQuoteElements
    {
      val tippgeberState = useState(earningsDistribution?.tippgeber?.accountingStatus ?: AccountingStatus.Pending)
      val vertriebProjekterfassungState = useState(earningsDistribution?.vertriebProjekterfassung?.accountingStatus ?: AccountingStatus.Pending)
      val vertriebAngebotsvorstellungState = useState(earningsDistribution?.vertriebAngebotsvorstellung?.accountingStatus ?: AccountingStatus.Pending)
      val technischePlanungDachState = useState(earningsDistribution?.technischePlanungDach?.accountingStatus ?: AccountingStatus.Pending)
      val technischePlanungElektrikState = useState(earningsDistribution?.technischePlanungElektrik?.accountingStatus ?: AccountingStatus.Pending)
      val montageDachState = useState(earningsDistribution?.montageDach?.accountingStatus ?: AccountingStatus.Pending)
      val montageGeruestState = useState(earningsDistribution?.montageGeruest?.accountingStatus ?: AccountingStatus.Pending)
      val elektroInstallationState = useState(earningsDistribution?.elektroInstallation?.accountingStatus ?: AccountingStatus.Pending)
      val netzvoranfrageState = useState(earningsDistribution?.netzvoranfrage?.accountingStatus ?: AccountingStatus.Pending)
      val neckarITAccountingStatusState = useState(earningsDistribution?.neckarITAccountingStatus?.accountingStatus ?: AccountingStatus.Pending)

      val manualTippgeberEarnings = useState(earningsDistribution?.tippgeber?.manualEarnings?.euros)
      val manualVertriebProjekterfassungEarnings = useState(earningsDistribution?.vertriebProjekterfassung?.manualEarnings?.euros)
      val manualVertriebAngebotsvorstellungEarnings = useState(earningsDistribution?.vertriebAngebotsvorstellung?.manualEarnings?.euros)
      val manualTechnischePlanungDachEarnings = useState(earningsDistribution?.technischePlanungDach?.manualEarnings?.euros)
      val manualTechnischePlanungElektrikEarnings = useState(earningsDistribution?.technischePlanungElektrik?.manualEarnings?.euros)
      val manualMontageDachEarnings = useState(earningsDistribution?.montageDach?.manualEarnings?.euros)
      val manualMontageGeruestEarnings = useState(earningsDistribution?.montageGeruest?.manualEarnings?.euros)
      val manualElektroInstallationEarnings = useState(earningsDistribution?.elektroInstallation?.manualEarnings?.euros)
      val manualNetzvoranfrageEarnings = useState(earningsDistribution?.netzvoranfrage?.manualEarnings?.euros)
      val manualNeckarITEarnings = useState(earningsDistribution?.neckarITAccountingStatus?.manualEarnings?.euros)

      val manualProfit1 = useState(manualQuoteElements?.manualSumsForTags?.get(IncomePercentageCategory.Profit1)?.currentValue?.euros)
      val manualProfit2Battery = useState(manualQuoteElements?.manualSumsForTags?.get(IncomePercentageCategory.Profit2Battery)?.currentValue?.euros)
      val manualProfit3Scaffolding = useState(manualQuoteElements?.manualSumsForTags?.get(IncomePercentageCategory.Profit3Scaffolding)?.currentValue?.euros)
      val manualProfit4ElectricityMaterial = useState(manualQuoteElements?.manualSumsForTags?.get(IncomePercentageCategory.Profit4ElectricityMaterial)?.currentValue?.euros)
      val manualProfit5Assembly = useState(manualQuoteElements?.manualSumsForTags?.get(IncomePercentageCategory.Profit5Assembly)?.currentValue?.euros)
      val manualProfit6ElectricityWork = useState(manualQuoteElements?.manualSumsForTags?.get(IncomePercentageCategory.Profit6ElectricityWork)?.currentValue?.euros)

      val manualQuoteElementsMap = buildMap {
        put(IncomePercentageCategory.Profit1, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit1, manualProfit1))
        put(IncomePercentageCategory.Profit2Battery, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit2Battery, manualProfit2Battery))
        put(IncomePercentageCategory.Profit3Scaffolding, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit3Scaffolding, manualProfit3Scaffolding))
        put(IncomePercentageCategory.Profit4ElectricityMaterial, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit4ElectricityMaterial, manualProfit4ElectricityMaterial))
        put(IncomePercentageCategory.Profit5Assembly, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit5Assembly, manualProfit5Assembly))
        put(IncomePercentageCategory.Profit6ElectricityWork, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit6ElectricityWork, manualProfit6ElectricityWork))
      }


      val tippgeberToSave = useMemo(tippgeberState.value, manualTippgeberEarnings.value) {
        earningsDistribution!!.tippgeber.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualTippgeberEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.tippgeber.accountingStatusHistory.with(tippgeberState.value, loggedInUser.loginName),
        )
      }

      val vertriebProjekterfassungToSave = useMemo(vertriebProjekterfassungState.value, manualVertriebProjekterfassungEarnings.value) {
        earningsDistribution!!.vertriebProjekterfassung.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualVertriebProjekterfassungEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.vertriebProjekterfassung.accountingStatusHistory.with(vertriebProjekterfassungState.value, loggedInUser.loginName),
        )
      }

      val vertriebAngebotsvorstellungToSave = useMemo(
        vertriebAngebotsvorstellungState.value,
        manualVertriebAngebotsvorstellungEarnings.value,
      ) {
        earningsDistribution!!.vertriebAngebotsvorstellung.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualVertriebAngebotsvorstellungEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.vertriebAngebotsvorstellung.accountingStatusHistory.with(vertriebAngebotsvorstellungState.value, loggedInUser.loginName),
        )
      }

      val technischePlanungDachToSave = useMemo(technischePlanungDachState.value, manualTechnischePlanungDachEarnings.value) {
        earningsDistribution!!.technischePlanungDach.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualTechnischePlanungDachEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.technischePlanungDach.accountingStatusHistory.with(technischePlanungDachState.value, loggedInUser.loginName),
        )
      }

      val technischePlanungElektrikToSave = useMemo(technischePlanungElektrikState.value, manualTechnischePlanungElektrikEarnings.value) {
        earningsDistribution!!.technischePlanungElektrik.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualTechnischePlanungElektrikEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.technischePlanungElektrik.accountingStatusHistory.with(technischePlanungElektrikState.value, loggedInUser.loginName),
        )
      }

      val montageDachToSave = useMemo(montageDachState.value, manualMontageDachEarnings.value) {
        earningsDistribution!!.montageDach.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualMontageDachEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.montageDach.accountingStatusHistory.with(montageDachState.value, loggedInUser.loginName),
        )
      }

      val montageGeruestToSave = useMemo(montageGeruestState.value, manualMontageGeruestEarnings.value) {
        earningsDistribution!!.montageGeruest.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualMontageGeruestEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.montageGeruest.accountingStatusHistory.with(montageGeruestState.value, loggedInUser.loginName),
        )
      }

      val elektroInstallationToSave = useMemo(elektroInstallationState.value, manualElektroInstallationEarnings.value) {
        earningsDistribution!!.elektroInstallation.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualElektroInstallationEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.elektroInstallation.accountingStatusHistory.with(elektroInstallationState.value, loggedInUser.loginName),
        )
      }

      val netzvoranfrageToSave = useMemo(netzvoranfrageState.value, manualNetzvoranfrageEarnings.value) {
        earningsDistribution!!.netzvoranfrage.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualNetzvoranfrageEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.netzvoranfrage.accountingStatusHistory.with(netzvoranfrageState.value, loggedInUser.loginName),
        )
      }

      val neckarITToSave = useMemo(neckarITAccountingStatusState.value, manualNeckarITEarnings.value) {
        earningsDistribution!!.neckarITAccountingStatus.copy(
          manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualNeckarITEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
          accountingStatusHistory = earningsDistribution.neckarITAccountingStatus.accountingStatusHistory.with(neckarITAccountingStatusState.value, loggedInUser.loginName),
        )
      }

      val updatedEarningsDistribution = useMemo(
        tippgeberToSave,
        vertriebProjekterfassungToSave,
        vertriebAngebotsvorstellungToSave,
        technischePlanungDachToSave,
        technischePlanungElektrikToSave,
        montageDachToSave,
        montageGeruestToSave,
        elektroInstallationToSave,
        netzvoranfrageToSave,
        neckarITToSave,
      ) {
        if (
          earningsDistribution == null ||
          tippgeberToSave == earningsDistribution.tippgeber &&
          vertriebProjekterfassungToSave == earningsDistribution.vertriebProjekterfassung &&
          vertriebAngebotsvorstellungToSave == earningsDistribution.vertriebAngebotsvorstellung &&
          technischePlanungDachToSave == earningsDistribution.technischePlanungDach &&
          technischePlanungElektrikToSave == earningsDistribution.technischePlanungElektrik &&
          montageDachToSave == earningsDistribution.montageDach &&
          montageGeruestToSave == earningsDistribution.montageGeruest &&
          elektroInstallationToSave == earningsDistribution.elektroInstallation &&
          netzvoranfrageToSave == earningsDistribution.netzvoranfrage &&
          neckarITToSave == earningsDistribution.neckarITAccountingStatus
        ) {
          return@useMemo earningsDistribution
        }

        earningsDistribution.copy(
          tippgeber = tippgeberToSave,
          vertriebProjekterfassung = vertriebProjekterfassungToSave,
          vertriebAngebotsvorstellung = vertriebAngebotsvorstellungToSave,
          technischePlanungDach = technischePlanungDachToSave,
          technischePlanungElektrik = technischePlanungElektrikToSave,
          montageDach = montageDachToSave,
          montageGeruest = montageGeruestToSave,
          elektroInstallation = elektroInstallationToSave,
          netzvoranfrage = netzvoranfrageToSave,
          neckarITAccountingStatus = neckarITToSave,
        ).also { newEarningsDistribution ->
          if (newEarningsDistribution != earningsDistribution) {
            UiActions.saveEarningsDistribution(
              projectId = projectPreview.projectId,
              configurationId = currentConfigurationPreview.configurationId,
              earningsDistribution = newEarningsDistribution,
            )
          }
        }
      }

      val updatedManualQuoteElements = useMemo(
        manualProfit1.value,
        manualProfit2Battery.value,
        manualProfit3Scaffolding.value,
        manualProfit4ElectricityMaterial.value,
        manualProfit5Assembly.value,
        manualProfit6ElectricityWork.value,
      ) {
        if (manualQuoteElements == null ||
          manualProfit1.value == manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit1]?.currentValue?.euros &&
          manualProfit2Battery.value == manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit2Battery]?.currentValue?.euros &&
          manualProfit3Scaffolding.value == manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit3Scaffolding]?.currentValue?.euros &&
          manualProfit4ElectricityMaterial.value == manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit4ElectricityMaterial]?.currentValue?.euros &&
          manualProfit5Assembly.value == manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit5Assembly]?.currentValue?.euros &&
          manualProfit6ElectricityWork.value == manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit6ElectricityWork]?.currentValue?.euros
        ) {
          return@useMemo manualQuoteElements
        }

        manualQuoteElements.copy(
          manualSumsForTags = buildMap {
            IncomePercentageCategory.entries.fastForEach { incomePercentageCategory ->
              val newManualSum = manualQuoteElementsMap[incomePercentageCategory]?.incomeState?.value?.let { Money.euros(it) }
              val newEditHistory = manualQuoteElements.manualSumsForTags[incomePercentageCategory]?.let { editHistory ->
                val oldManualSum = editHistory.currentValue
                if (newManualSum != oldManualSum) editHistory.with(newManualSum, loggedInUser.loginName) else editHistory
              } ?: PositionEditHistory(newManualSum, loggedInUser.loginName)
              put(incomePercentageCategory, newEditHistory)
            }
          },
        ).also { updatedManualQuoteElements ->
          if (updatedManualQuoteElements != manualQuoteElements) {
            UiActions.saveManualQuoteElements(
              projectId = projectPreview.projectId,
              configurationId = currentConfigurationPreview.configurationId,
              manualQuoteElements = updatedManualQuoteElements,
            )
          }
        }
      }


      if (currentConfigurationPreview != null) {
        val upToDateConfigurationPreview = currentConfigurationPreview.copy(
          earningsDistribution = updatedEarningsDistribution ?: currentConfigurationPreview.earningsDistribution,
          manualQuoteElements = updatedManualQuoteElements ?: currentConfigurationPreview.manualQuoteElements,
        )

        val accountingTableEntriesList = buildList {
          updatedEarningsDistribution?.let {
            add(EarningsCompanyEntryAndStates(it.tippgeber, manualTippgeberEarnings, tippgeberState))
            add(EarningsCompanyEntryAndStates(it.vertriebProjekterfassung, manualVertriebProjekterfassungEarnings, vertriebProjekterfassungState))
            add(EarningsCompanyEntryAndStates(it.vertriebAngebotsvorstellung, manualVertriebAngebotsvorstellungEarnings, vertriebAngebotsvorstellungState))
            add(EarningsCompanyEntryAndStates(it.technischePlanungDach, manualTechnischePlanungDachEarnings, technischePlanungDachState))
            add(EarningsCompanyEntryAndStates(it.technischePlanungElektrik, manualTechnischePlanungElektrikEarnings, technischePlanungElektrikState))
            add(EarningsCompanyEntryAndStates(it.montageDach, manualMontageDachEarnings, montageDachState))
            add(EarningsCompanyEntryAndStates(it.montageGeruest, manualMontageGeruestEarnings, montageGeruestState))
            add(EarningsCompanyEntryAndStates(it.elektroInstallation, manualElektroInstallationEarnings, elektroInstallationState))
            add(EarningsCompanyEntryAndStates(it.netzvoranfrage, manualNetzvoranfrageEarnings, netzvoranfrageState))
            add(EarningsCompanyEntryAndStates(it.neckarITAccountingStatus, manualNeckarITEarnings, neckarITAccountingStatusState))
          }
        }.groupBy { it.earningsCompanyEntry.companyProfile }.map {
          AccountingTableEntries(
            companyProfile = it.key,
            configurationPreview = upToDateConfigurationPreview,
            earningsCompanyEntries = it.value,
          )
        }.sortedByDescending { it.companyProfile }


        accountingTableEntriesList.fastForEach { accountingTableEntries ->
          val companyInformation = companyResolver[accountingTableEntries.companyProfile]
          val earningsCompanyEntries = accountingTableEntries.relevantEarningsCompanyEntries
          val openEarningsCompanyEntries = accountingTableEntries.openEarningsCompanyEntries
          val accountedEarningsCompanyEntries = earningsCompanyEntries.size - openEarningsCompanyEntries.size

          div("mb-3") {
            div("row") {
              div("col") {
                b { +companyInformation.name }
                span("ms-2") {
                  attrs {
                    addClass("text-center")
                    addClass("align-middle")

                    if (openEarningsCompanyEntries.isEmpty()) {
                      addClass("text-success")
                    } else if (accountedEarningsCompanyEntries > 0) {
                      addClass("text-warning")
                    } else {
                      addClass("text-danger")
                    }
                  }

                  if (openEarningsCompanyEntries.isEmpty()) {
                    faCircleCheck()
                  } else {
                    b { +"${accountedEarningsCompanyEntries}/${earningsCompanyEntries.size}" }
                  }
                }
              }
              div("col text-end") {
                +accountingTableEntries.relevantEarnings(howDoYouLikeYourQuoteElements).format(plannerI18nConfiguration)
              }
            }

            accountingTableEntries.relevantEarningsCompanyEntries.fastForEach { earningsCompanyEntryAndState ->
              val earningsCompanyEntry = earningsCompanyEntryAndState.earningsCompanyEntry
              val incomeState = earningsCompanyEntryAndState.incomeState
              val accountingStatusState = earningsCompanyEntryAndState.accountingStatusState
              val incomePercentage = when (earningsCompanyEntry) {
                is ResolvedCompanyEarningsEntry -> earningsCompanyEntry.incomePercentage
                is ResolvedNeckarITEarningsEntry -> upToDateConfigurationPreview.softwareIncomePercentage
              }

              div("row") {
                div("col my-auto") {
                  checkbox(
                    value = accountingStatusState.value == AccountingStatus.Accounted,
                    onChange = { checked ->
                      val setAsAccounted = { accountingStatusState.setter(if (checked) AccountingStatus.Accounted else AccountingStatus.Pending) }
                      if (checked) {
                        setAsAccounted()
                      } else {
                        confirm("Soll diese Abrechnung wirklich rückgängig gemacht werden?", setAsAccounted)
                      }
                    },
                    fieldName = "${accountingTableEntries.configurationPreview.uuid}-${earningsCompanyEntry.companyProfile.companyCode}",
                    title = incomePercentage.description,
                  )
                  span("form-text ms-3") {
                    attrs {
                      this.title = earningsCompanyEntry.accountingStatusHistory.currentEdit.loginName?.let { userResolver[it].editorName } ?: "-"
                    }
                    +"${earningsCompanyEntry.accountingStatusHistory.currentEdit.loginName?.let { userResolver[it].editorName } ?: "-"}: "
                    +dateFormat.format(earningsCompanyEntry.accountingStatusHistory.lastEditedTime, plannerI18nConfiguration)
                  }
                }

                val relevantEarnings = upToDateConfigurationPreview.getEarningsForIncomePercentage(incomePercentage, howDoYouLikeYourQuoteElements)
                div("col-5 text-end") {
                  euroInputField(incomeState, "${projectPreview.uuid}-${earningsCompanyEntry.company}-${incomePercentage.description}", relevantEarnings)
                  div("form-text") {
                    +"${earningsCompanyEntry.manualEarningsHistory.currentEdit.loginName?.let { userResolver[it].editorName } ?: "-"}: "
                    +dateFormat.format(earningsCompanyEntry.manualEarningsHistory.lastEditedTime, plannerI18nConfiguration)
                  }
                }
              }
            }
          }

          hr {}
        }

        manualQuoteElementsMap.values.toList().fastForEach { categoryAndState ->
          val incomePercentageCategory = categoryAndState.incomePercentageCategory
          val state = categoryAndState.incomeState
          val netPriceForCategory = upToDateConfigurationPreview.getNetPrice(incomePercentageCategory, howDoYouLikeYourQuoteElements)

          div("row") {
            div("col form-text my-auto") { +incomePercentageCategory.description }
            div("col-5 text-end") {
              euroInputField(state, "${projectPreview.uuid}-${incomePercentageCategory.name}", netPriceForCategory)
              updatedManualQuoteElements?.manualSumsForTags?.get(incomePercentageCategory)?.let { editHistoryEntry ->
                div("form-text") {
                  +"${editHistoryEntry.currentEdit.loginName?.let { userResolver[it].editorName } ?: "-"}: "
                  +dateFormat.format(editHistoryEntry.lastEditedTime, plannerI18nConfiguration)
                }
              }
            }
          }
        }

        hr {}

        div("row") {
          div("col my-auto") { b { +"Gesamt" } }
          div("col text-end") {
            b { +upToDateConfigurationPreview.getNetPrice(howDoYouLikeYourQuoteElements).format(plannerI18nConfiguration) }
          }
        }
      }
    }
  }
}

private fun RDOMBuilder<DIV>.euroInputField(
  state: StateInstance<Double?>,
  fieldName: String,
  netPriceForCategory: Money?,
  mergeAbove: Boolean = false,
  mergeBelow: Boolean = false,
) {
  div("input-group") {
    nullableInputField(
      value = state.value?.toString(),
      onChange = { state.setter(it?.toDoubleOrNull()) },
      fieldName = fieldName,
      title = netPriceForCategory?.format(plannerI18nConfiguration) ?: "Nettopreis",
      placeholder = (netPriceForCategory?.euros ?: 0.00).format(i18nConfiguration = plannerI18nConfiguration),
      classes = "form-control float-end text-end",
    ) {
      attrs {
        type = InputType.number
        if (mergeAbove) mergedAbove()
        if (mergeBelow) mergedBelow()
      }
    }
    div("input-group-append") {
      span("input-group-text") { +"€" }
    }
  }
}
