package it.neckar.processStatesClient

import it.neckar.processStates.ProcessStatesForComponent
import it.neckar.rest.RestResponse
import it.neckar.rest.pagination.Page
import it.neckar.rest.pagination.PaginationInfo
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Response for fetching process states
 */
@Serializable
sealed interface FetchProcessStatesResponse : RestResponse<List<ProcessStatesForComponent>> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(
    override val data: List<ProcessStatesForComponent>,
    override val paginationInfo: PaginationInfo,
  ) : FetchProcessStatesResponse, RestResponse.PaginatedSuccessResponse<List<ProcessStatesForComponent>>

  @Serializable
  @SerialName("failure")
  data object Failure : FetchProcessStatesResponse, RestResponse.FailureResponse<List<ProcessStatesForComponent>>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: List<ProcessStatesForComponent>, paginationInfo: PaginationInfo): FetchProcessStatesResponse = Success(data, paginationInfo)
    fun success(page: Page<ProcessStatesForComponent>): FetchProcessStatesResponse = Success(page.pageElements, page.paginationInfo)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): FetchProcessStatesResponse = Failure
  }
}
