package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.stumps.AssemblyBasement
import store.PlannerClientState

/**
 * Updates a single project.
 * Does *not* navigate to the project
 */
data class UpdateAssemblyBasementAction(val projectId: PhotovoltaicsProjectId, val updatedAssemblyBasement: AssemblyBasement) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    println("Updating assemblyBasement <${updatedAssemblyBasement.uuid}> for project <${projectId}>")

    val resolvedProject = this.resolvedProjects[projectId]
    val updatedProject = resolvedProject.copy(assemblyBasement = updatedAssemblyBasement)

    val updatedProjects = this.resolvedProjects.withUpdated(updatedProject)

    return this.copy(resolvedProjects = updatedProjects)
  }
}
