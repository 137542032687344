package it.neckar.ktor.client.heartbeat

import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.statement.*
import it.neckar.heartbeat.HeartbeatCheck
import it.neckar.heartbeat.HeartbeatState
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.get
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.open.http.Url
import it.neckar.problem.ExpectedProblem
import it.neckar.problem.Problem
import it.neckar.problem.UnexpectedProblem
import kotlinx.serialization.json.Json
import kotlin.time.Duration
import kotlin.time.measureTime

/**
 * Verifies the response. Returns the heartbeat state depending on the result.
 */
typealias ResponseVerification = suspend (HttpResponse, responseTime: Duration) -> HeartbeatState

/**
 * Uses the provided HTTP client to check the availability of the service
 */
class HttpClientHeartbeatCheck(
  /**
   * The URL that is checked
   */
  val heartbeatUrl: Url,

  /**
   * The client that is used to check the heartbeat.
   * Should be configured with the necessary settings - e.g., authorization if necessary
   */
  val httpClient: HttpClient,

  /**
   * Callback to verify the HTTP response.
   * The callback is *only* called for successful responses.
   *
   * Responses with a status code >= 400 are considered dead - without calling the [responseVerification].
   */
  val responseVerification: ResponseVerification = { _, responseTime ->
    HeartbeatState.Alive(responseTime)
  },

  ) : HeartbeatCheck {
  override val description: String
    get() = heartbeatUrl.value

  override suspend fun check(): HeartbeatState {
    try {
      val result: HttpResponse
      val duration = measureTime {
        result = httpClient.get(heartbeatUrl) {
          featureFlagsHeader()
        }
      }

      return responseVerification(result, duration)
    } catch (e: ClientRequestException) {
      val bodyAsText = e.response.bodyAsText()

      try {
        when (val problem = Json.Default.decodeFromString(Problem.serializer(), bodyAsText)) {
          is ExpectedProblem -> {
            return when (problem.errorCode) {
              it.neckar.rest.version.RestProtocolVersionInfo.ErrorCodes.MissingClientVersion -> HeartbeatState.Dead.VersionMismatch
              it.neckar.rest.version.RestProtocolVersionInfo.ErrorCodes.InvalidClientVersion -> HeartbeatState.Dead.VersionMismatch
              else -> HeartbeatState.Dead.ErrorResponse(
                statusCode = problem.status.statusCode,
                message = problem.fallbackMessage
              )
            }
          }
          is UnexpectedProblem -> return HeartbeatState.Dead.ErrorResponse(
            statusCode = e.response.status.value,
            message = problem.fallbackMessage
          )
        }

      } catch (innerException: Exception) {
        logger.warn("Could not handle problem response: $bodyAsText", innerException)
      }

      return HeartbeatState.Dead.ErrorResponse(
        statusCode = e.response.status.value,
        message = e.response.bodyAsText()
      )
    }
  }

  companion object {
    private val logger: Logger = LoggerFactory.getLogger("it.neckar.ktor.client.heartbeat.HttpClientHeartbeatCheck")
  }
}
