package it.neckar.heartbeat

import kotlin.jvm.JvmInline
import kotlin.time.Duration

/**
 * The heartbeat state
 */
sealed interface HeartbeatState {
  /**
   * Returns true if this is a success
   */
  val isAvailable: Boolean
    get() = this is Alive

  /**
   * Returns true if there is a version mismatch
   */
  val isVersionMismatch: Boolean
    get() = this == Dead.VersionMismatch

  /**
   * The connection is alive
   */
  @JvmInline
  value class Alive(val responseTime: Duration) : HeartbeatState {
    override fun toString(): String {
      return "Alive(responseTime=$responseTime)"
    }
  }

  /**
   * No response - the connection is dead
   */
  sealed interface Dead : HeartbeatState {
    /**
     * Connection failed (or timed out)
     */
    data object ConnectionFailure : Dead

    /**
     * The client/server version do not match. The client should be updated.
     */
    data object VersionMismatch : Dead

    /**
     * The server responded with a non-success status code (>=400)
     */
    data class ErrorResponse(val statusCode: Int, val message: String?) : Dead

    /**
     * An exception has occurred
     */
    data class ExceptionOccurred(val exception: Throwable) : Dead
  }
}
