package components.project.configuration.discount

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct100
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

val EditDiscount: FC<EditDiscountProps> = fc("EditDiscount") { props ->
  val discountPercentageToSave = props::discountPercentageToSave.getNotNull()

  val subTotal = props::subTotal.safeGet()
  val editableStatus = props::editableStatus.safeGet()


  div("col-sm-6") {
    h2("mb-3") {
      +"Rabatt"
    }

    floatingDoubleInputField(
      valueAndSetter = discountPercentageToSave,
      fieldName = "discountPercentage100",
      title = "Rabatt",
      numberOfDecimals = 1,
      numberConstraint = CoerceIn(0, 5),
      editableStatus = editableStatus,
    ) {
      attrs {
        step = "1"
      }
    }

    subTotal?.let {
      p("form-text") {
        +"Rabatt entspricht: ${(-it * discountPercentageToSave.value.percent).dump()}"
      }
    }
  }

}


external interface EditDiscountProps : Props {
  var discountPercentageToSave: StateInstance<@pct100 Double>
  var subTotal: PriceWithProfit?
  var editableStatus: EditableStatus
}
