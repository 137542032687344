package it.neckar.lizergy.model.configuration.quote.economics.simple

import it.neckar.financial.currency.Money
import it.neckar.open.unit.other.pct
import kotlin.math.pow

/**
 * Money value that increases every year
 */
data class IncreasingMoney(
  val baseValue: Money,
  val increase: @pct Double,
) {

  /**
   * Calculates the resulting value
   */
  fun calculateValue(exponent: Int): Money {
    val factor = (1 + increase).pow(exponent)
    return baseValue * factor
  }
}

fun Money.increasingBy(increase: @pct Double): IncreasingMoney {
  return IncreasingMoney(this, increase)
}
