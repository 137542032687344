package it.neckar.financial.quote.flat

import it.neckar.open.collections.emptyIntArray
import it.neckar.open.formatting.format

/**
 * The indentation within a flat quote
 */
data class FlatQuoteIndentation(
  /**
   * Contains the indices for the indentation (e.g. [1,2,3] is represented as "1.2.3")
   */
  val indexes: IntArray,
) {
  /**
   * Formats the indentation
   */
  val format: String
    get() {
      return indexes.joinToString(".") {
        (it + 1).format()
      }
    }

  val depth: Int
    get() {
      return indexes.size
    }

  override fun equals(other: Any?): Boolean {
    if (this === other) return true
    if (other !is FlatQuoteIndentation) return false

    if (!indexes.contentEquals(other.indexes)) return false

    return true
  }

  override fun hashCode(): Int {
    return indexes.contentHashCode()
  }

  /**
   * Creates a child items with the given index
   */
  fun child(index: Int): FlatQuoteIndentation {
    return FlatQuoteIndentation(indexes + index)
  }

  companion object {
    val root: FlatQuoteIndentation = FlatQuoteIndentation(emptyIntArray())
  }
}
