package components.assemblyPortfolio

import components.form.commentSection
import components.project.configuration.ConfigurationPreviewWithComments
import components.project.editProcessStateEntry
import components.project.infiniteScrollingProcessStatesList
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.AssemblyBasementPreparationProcessStateEntry.AssemblyBasementPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry.AssemblyPortfolioProcessStates
import it.neckar.lizergy.model.project.process.state.AssemblyRoofPreparationProcessStateEntry.AssemblyRoofPreparationProcessStates
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toProcessStateEntry
import it.neckar.open.time.nowMillis
import it.neckar.processStatesClient.SendProcessStatesTuple
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import react.*
import react.dom.*
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser

val AssemblyPortfolioOverviewFromUrl: FC<Props> = fc("AssemblyPortfolioOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, null, allProcessStatesForComponents?.let { "Montagemappe erstellen - ${resolvedProject?.getDisplayName(it)}" })

  busyIfNull(resolvedProject) { loadedProject ->
    AssemblyPortfolioOverview {
      attrs {
        this.project = loadedProject
      }
    }
  }
}

private const val firstColumn = "col-lg-6"
private const val secondColumn = "col-lg-6"

val AssemblyPortfolioOverview: FC<AssemblyPortfolioOverviewProps> = fc("AssemblyPortfolioOverview") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val processStatesResolver = useLoadProcessStates()

  val project = props::project.safeGet()

  val currentConfiguration = processStatesResolver?.let { project.getCurrentQuoteConfiguration(it) }
  val assemblyPortfolio = project.assemblyPortfolio
  val assemblyBasement = project.assemblyBasement
  val assemblyRoof = project.assemblyRoof

  val assemblyPortfolioProcessStateEntry = useLoadProcessStatesForComponent(assemblyPortfolio.uuid)?.current()
  val assemblyPortfolioProcessState = assemblyPortfolioProcessStateEntry?.processState
  val assemblyBasementProcessState = useLoadProcessStatesForComponent(assemblyBasement.uuid)?.current()?.processState
  val assemblyRoofProcessState = useLoadProcessStatesForComponent(assemblyRoof.uuid)?.current()?.processState


  useMemo(assemblyPortfolioProcessState) {
    val automaticProcessStates = buildList {
      if (assemblyPortfolioProcessState == AssemblyPortfolioProcessStates.Accepted) {
        if (assemblyRoofProcessState == null) {
          add(SendProcessStatesTuple(assemblyRoof.uuid, AssemblyRoofPreparationProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }

        if (assemblyBasementProcessState == null) {
          add(SendProcessStatesTuple(assemblyBasement.uuid, AssemblyBasementPreparationProcessStates.Missing.toProcessStateEntry(user = loggedInUser, dueDate = null, assignedAt = nowMillis(), assignedBy = loggedInUser)))
        }
      }
    }

    if (automaticProcessStates.isNotEmpty()) UiActions.addProcessStates(automaticProcessStates)
  }


  div("row gy-4 my-5") {
    div("$firstColumn col-12") {
      editProcessStateEntry(assemblyPortfolio, AssemblyPortfolioProcessStates.entries, editableStatus = project.isProjectEditable())
    }

    infiniteScrollingProcessStatesList(assemblyPortfolio, secondColumn)

    if (assemblyPortfolioProcessState == AssemblyPortfolioProcessStates.Accepted) {
      if (assemblyRoofProcessState == null || assemblyRoofProcessState == AssemblyRoofPreparationProcessStates.Missing) {
        div("$firstColumn col-12") {
          editProcessStateEntry(assemblyRoof, listOf(AssemblyRoofPreparationProcessStates.Missing), editableStatus = project.isProjectEditable())
        }

        infiniteScrollingProcessStatesList(assemblyRoof, secondColumn)
      }

      if (assemblyBasementProcessState == null || assemblyBasementProcessState == AssemblyBasementPreparationProcessStates.Missing) {
        div("$firstColumn col-12") {
          editProcessStateEntry(assemblyBasement, listOf(AssemblyBasementPreparationProcessStates.Missing), editableStatus = project.isProjectEditable())
        }

        infiniteScrollingProcessStatesList(assemblyBasement, secondColumn)
      }
    }

    div("my-5") {
      h3("mb-3") {
        +"Bemerkungen Montagemappe"
      }

      commentSection(assemblyPortfolio)
    }
  }

  if (currentConfiguration != null) {
    AssemblyPortfolioConfigurationOverview {
      attrs {
        this.project = project
        this.currentConfiguration = currentConfiguration
      }
    }
  }


  val (downloadOfferBusy, setDownloadOfferBusy) = useState(false)

  actionButton(
    icon = ButtonIconCentered(if (downloadOfferBusy) "spinner-border spinner-border-sm" else FontAwesomeIcons.download),
    classes = "btn btn-primary ${if (currentConfiguration == null) "disabled" else ""}",
    action = {
      check(currentConfiguration != null) { "Configuration must not be null to create AssemblyPortfolio" }
      setDownloadOfferBusy(true)
      try {
        //Download the newly created quote offer snapshot
        UiActions.downloadAssemblyPortfolioPdf(project.projectId, currentConfiguration.configurationId)
      } finally {
        setDownloadOfferBusy(false)
      }
    }) {
    b("ms-2") {
      +"Montagemappe erstellen"
    }
  }


  busyIfNull(processStatesResolver) { loadedProcessStatesResolver ->
    project.getCurrentQuoteConfiguration(loadedProcessStatesResolver)?.let { currentQuoteConfiguration ->
      hr("my-3") {}
      ConfigurationPreviewWithComments {
        attrs {
          this.project = project
          this.quoteConfiguration = currentQuoteConfiguration
        }
      }
    }
  }
}

external interface AssemblyPortfolioOverviewProps : Props {
  var project: ResolvedProject
}
