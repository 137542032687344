package components.project.configuration.legalnotice

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.react.common.form.*
import react.*
import react.dom.*

fun RBuilder.legalNotesForm(
  dachfanggeruestGestellt: StateInstance<Boolean>,
  verstaerktesSchienensystem: StateInstance<Boolean>,
  satelitenschuesselDemontage: StateInstance<Boolean>,
  dachstaenderDemontage: StateInstance<Boolean>,
  erdarbeitenGestellt: StateInstance<Boolean>,
  zaehlerschrankVorbereitet: StateInstance<Boolean>,
  leerrohreVorbereitet: StateInstance<Boolean>,
  scaffoldingSupplied: Boolean,
  editableStatus: EditableStatus,
): Unit = child(LegalNotesForm) {
  attrs {
    this.dachfanggeruestGestellt = dachfanggeruestGestellt
    this.verstaerktesSchienensystem = verstaerktesSchienensystem
    this.satelitenschuesselDemontage = satelitenschuesselDemontage
    this.dachstaenderDemontage = dachstaenderDemontage
    this.erdarbeitenGestellt = erdarbeitenGestellt
    this.zaehlerschrankVorbereitet = zaehlerschrankVorbereitet
    this.leerrohreVorbereitet = leerrohreVorbereitet
    this.scaffoldingSupplied = scaffoldingSupplied
    this.editableStatus = editableStatus
  }
}

val LegalNotesForm: FC<LegalNotesFormProps> = fc("LegalNotesForm") { props ->
  val dachfanggeruestGestellt = props::dachfanggeruestGestellt.getNotNull()
  val verstaerktesSchienensystem = props::verstaerktesSchienensystem.getNotNull()
  val satelitenschuesselDemontage = props::satelitenschuesselDemontage.getNotNull()
  val dachstaenderDemontage = props::dachstaenderDemontage.getNotNull()
  val erdarbeitenGestellt = props::erdarbeitenGestellt.getNotNull()
  val zaehlerschrankVorbereitet = props::zaehlerschrankVorbereitet.getNotNull()
  val leerrohreVorbereitet = props::leerrohreVorbereitet.getNotNull()

  val scaffoldingSupplied = props::scaffoldingSupplied.safeGet()
  val editableStatus = props::editableStatus.safeGet()


  div {
    checkbox(
      valueAndSetter = dachfanggeruestGestellt,
      fieldName = "dachfanggeruestGestellt",
      title = LegalNote.DachfanggeruestGestellt.legalText,
      editableStatus = editableStatus.and(scaffoldingSupplied.not()),
    )
  }
  div {
    checkbox(
      valueAndSetter = verstaerktesSchienensystem,
      fieldName = "verstaerktesSchienensystem",
      title = LegalNote.VerstaerktesSchienensystem.legalText,
      editableStatus = editableStatus,
    )
  }
  div {
    checkbox(
      valueAndSetter = satelitenschuesselDemontage,
      fieldName = "satelitenschuesselDemontage",
      title = LegalNote.SatellitenschuesselDemontage.legalText,
      editableStatus = editableStatus,
    )
  }
  div {
    checkbox(
      valueAndSetter = dachstaenderDemontage,
      fieldName = "dachstaenderDemontage",
      title = LegalNote.DachstaenderDemontage.legalText,
      editableStatus = editableStatus,
    )
  }
  div {
    checkbox(
      valueAndSetter = erdarbeitenGestellt,
      fieldName = "erdarbeitenGestellt",
      title = LegalNote.ErdarbeitenGestellt.legalText,
      editableStatus = editableStatus,
    )
  }
  div {
    checkbox(
      valueAndSetter = zaehlerschrankVorbereitet,
      fieldName = "zaehlerschrankVorbereitet",
      title = LegalNote.ZaehlerschrankVorbereitet.legalText,
      editableStatus = editableStatus,
    )
  }
  div {
    checkbox(
      valueAndSetter = leerrohreVorbereitet,
      fieldName = "leerrohreVorbereitet",
      title = LegalNote.LeerrohreVorbereitet.legalText,
      editableStatus = editableStatus,
    )
  }

}

external interface LegalNotesFormProps : Props {
  var dachfanggeruestGestellt: StateInstance<Boolean>
  var verstaerktesSchienensystem: StateInstance<Boolean>
  var satelitenschuesselDemontage: StateInstance<Boolean>
  var dachstaenderDemontage: StateInstance<Boolean>
  var erdarbeitenGestellt: StateInstance<Boolean>
  var zaehlerschrankVorbereitet: StateInstance<Boolean>
  var leerrohreVorbereitet: StateInstance<Boolean>
  var scaffoldingSupplied: Boolean
  var editableStatus: EditableStatus
}
