package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.lizergy.model.assemblyPortfolio.ResolvedAssemblyPortfolio
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import store.PlannerClientState

/**
 * Updates a single project.
 * Does *not* navigate to the project
 */
data class UpdateAssemblyPortfolioAction(val projectId: PhotovoltaicsProjectId, val updatedAssemblyPortfolio: ResolvedAssemblyPortfolio) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    println("Updating AssemblyPortfolio <${updatedAssemblyPortfolio.uuid}> for project <${projectId}>")

    val resolvedProject = this.resolvedProjects[projectId]
    //val updatedProject = resolvedProject.copy(assemblyPortfolio = updatedAssemblyPortfolio)

    val updatedProjects = this.resolvedProjects//.withUpdated(updatedProject)

    return this.copy(resolvedProjects = updatedProjects)
  }
}
