package components.project

import components.sales.ClosureCountRequest
import components.sales.ClosureHomepage
import components.sales.ConstructionCountRequest
import components.sales.ConstructionHomepage
import components.sales.PlanningCountRequest
import components.sales.PlanningHomepage
import components.sales.SalesCountRequest
import components.sales.SalesHomepage
import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewProjectCount
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.react.common.*
import it.neckar.react.common.router.*
import it.neckar.react.common.table.*
import kotlinx.css.*
import kotlinx.html.BUTTON
import kotlinx.html.ButtonType
import kotlinx.html.classes
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import router.useDocumentTitle
import services.UiActions
import services.http.ProjectCountForPhase
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import styled.*

/**
 * Loads the company code and shows the projects for this company
 */
val HomepageFromUrl: FC<Props> = fc("HomepageFromUrl") {
  val loggedInUser = useRequireLoggedInUser()
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Home")

  val projectCountsForPhases: StateInstance<Map<ProjectQueryComponent?, ProjectCountForPhase>?> = useState(null)


  useEffect(loggedInUser) {
    projectCountsForPhases.setter(null)

    UiActions.countProjectPreviews(
      requestedProjectCountsForPhases = SalesCountRequest + PlanningCountRequest + ConstructionCountRequest + ClosureCountRequest,
      loggedInUser = loggedInUser.loginName,
    ) {
      projectCountsForPhases.setter(it?.data?.associateBy { countForPhase -> countForPhase.projectQueryComponent })
    }
  }


  div {

    div {
      div("row gx-5") {

        div("col-lg-3 col-md-6 col-12") {
          SalesHomepage {
            attrs {
              this.projectCountsForPhases = projectCountsForPhases.value
            }
          }
        }

        div("col-lg-3 col-md-6 col-12") {
          PlanningHomepage {
            attrs {
              this.projectCountsForPhases = projectCountsForPhases.value
            }
          }
        }

        div("col-lg-3 col-md-6 col-12") {
          ConstructionHomepage {
            attrs {
              this.projectCountsForPhases = projectCountsForPhases.value
            }
          }
        }

        div("col-lg-3 col-md-6 col-12") {
          ClosureHomepage {
            attrs {
              this.projectCountsForPhases = projectCountsForPhases.value
            }
          }
        }

      }
    }

    div("my-5") {
      h2 {
        +"Alle Projekte"
      }
      ProjectsOverview {
        attrs {
          this.defaultFilteredBy = null
          this.processStatesToFilter = LizergyProcessStates.allProcessStates
          this.processStatesToHide = listOf(ProjectProcessStates.Archived, ProjectProcessStates.Paused)
          this.projectQueryComponent = null
          this.sortedBy = SortedBy.SortedDescending
          this.includeArchived = true
          this.keyPrefix = LocalStorageKeyPrefix("HomePage")
        }
      }
    }

  }
}

fun RBuilder.homepageButton(url: NavigateUrl, content: RDOMBuilder<BUTTON>.() -> Unit) {
  homepageButton({
    invoke(url)
  }, content)
}

fun RBuilder.homepageButton(navigationAction: NavigationAction, content: RDOMBuilder<BUTTON>.() -> Unit) {
  val navigate = useNavigateUrl()

  styledButton(type = ButtonType.button) {
    attrs {
      classes = setOf("col", "btn", "btn-primary", "btn-lg")
      onClickFunction = {
        navigate.navigationAction()
      }
    }
    css {
      minHeight = 49.px
    }

    content(this)
  }
}

fun RBuilder.homepageButtonForPhase(url: NavigateUrl, title: String, projectCountForPhase: ProjectCountForPhase?) {
  HomepageButton {
    attrs {
      this.url = url
      this.title = title
      this.projectCountForPhase = projectCountForPhase
    }
  }
}

val HomepageButton: FC<HomepageButtonProps> = fc("HomepageButton") { props ->
  val loggedInUser = useRequireLoggedInUser()

  val url = props::url.safeGet()
  val title = props::title.safeGet()
  val projectCountForPhase = props::projectCountForPhase.safeGet()

  homepageButton(url) {
    b { +title }
    br { }
    i("fs-6 fw-lighter") {
      busyIfNull(projectCountForPhase?.projectCountForUser, small = true) { +"$it" }
      +" Projekte für ${loggedInUser.editorName}"
    }
    if (loggedInUser.accessRights.canAccess(ViewProjectCount)) {
      br { }
      i("fs-6 fw-lighter") {
        busyIfNull(projectCountForPhase?.totalProjectCount, small = true) { +"(von insgesamt $it)" }
      }
    }
  }
}

external interface HomepageButtonProps : Props {
  var url: NavigateUrl
  var title: String
  var projectCountForPhase: ProjectCountForPhase?
}

external interface HomepageProps : Props {
  var projectCountsForPhases: Map<ProjectQueryComponent?, ProjectCountForPhase?>?
}

