@file: UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.price

import com.meistercharts.charts.lizergy.roofPlanning.PerModule
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.PriceWithProfitAndWorkingTime
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lifeCycle.only
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration.BatteryConfigurationId
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType.IndependenceManagerId
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule.PvModuleId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor.MetalRoofTileColorId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile.RoofTileId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType.RoofTypeId
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty.AssemblyDifficultyId
import it.neckar.lizergy.model.configuration.quote.builder.BasicBatteryInverter
import it.neckar.lizergy.model.configuration.quote.builder.ConfigurableInverter
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod.HeaterRodId
import it.neckar.lizergy.model.configuration.quote.builder.HybridInverter
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.InverterType
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox.WallboxId
import it.neckar.lizergy.model.price.PriceList.PriceListId
import it.neckar.open.collections.fastForEach
import it.neckar.open.unit.si.cm
import it.neckar.open.unit.time.h
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import kotlin.time.Duration

/**
 * Static implementation that holds two price information objects
 */
@Serializable
data class StaticPriceList(

  override val priceListId: PriceListId,

  /**
   * Module prices
   */
  override val modulePrices: Prices<PvModule>,

  /**
   * Prices for inverters
   */
  override val inverterPrices: Prices<Inverter>,
  override val hybridInverterPrices: Prices<HybridInverter>,
  override val batteryInverterPrices: Prices<BasicBatteryInverter>,

  override val batteryConfigurationPrices: InfoForType<BatteryConfiguration, PriceWithProfitAndWorkingTime>,

  @PerModule
  override val assemblyCostsPerModule: Prices<RoofType>,

  /**
   * The metal roof tiles
   */
  override val metalRoofTilePrices: Prices<MetalRoofTile>,
  override val aufschlagColoredMetalPlatesPrices: Prices<MetalRoofTileColor>,

  override val assemblyDifficultyPrices: Prices<AssemblyDifficulty>,

  override val wallboxInstallationPrice: PriceWithProfitAndWorkingTime,
  override val wallBoxPrices: Prices<Wallbox>,

  override val monitoringPrices: MonitoringPrices,

  override val sitePreparation: PriceWithProfit,

  /**
   * "Gerüst" per m²
   */
  override val scaffolding: PriceWithProfitAndWorkingTime,

  /**
   * Discount to be applied to the assembly cost when the system will be self-assembled
   */
  override val optionalDiscountPrices: OptionalDiscountPrices,

  /**
   * Per "Dachständerisolierung"
   */
  override val dachStaenderIsolierung: PriceWithProfitAndWorkingTime,

  /**
   * "Überspannungsschutz" per unit
   */
  override val ueberSpannungsSchutz: PriceWithProfitAndWorkingTime,
  override val ueberSpannungsSchutzaWennExternerBlitzschutzVorhanden: PriceWithProfitAndWorkingTime,
  override val blitzschutzIntegrieren: PriceWithProfit,

  /**
   * Per "Satelliten-Schüssel"
   */
  override val moveSatelliteDish: PriceWithProfitAndWorkingTime,

  /**
   * Per "Antenne"
   */
  override val removeAntenna: PriceWithProfitAndWorkingTime,

  override val integrateInverterIntoNetwork: PriceWithProfitAndWorkingTime,

  override val workingHoursPerDay: @h Double,

  /**
   * "Fahrpauschale" per km
   */
  override val perKilometerTravelled: PriceWithProfit,

  /**
   * Per optimizer
   */
  override val optimizerPrice: PriceWithProfitAndWorkingTime,

  /**
   * "Kabelwegzuschlag" per meter
   */
  override val zuschlagKabelweg: PriceWithProfitAndWorkingTime,

  /**
   * "Aufschlag Kreuzverbund" per "Modul"
   */
  override val aufschlagKreuzverbund: @PerModule PriceWithProfitAndWorkingTime,

  /**
   * Price for starting up
   */
  override val startingUpPrice: PriceWithProfitAndWorkingTime,

  override val availableRoofTiles: AvailableRoofTiles,

  override val assemblyDifficultyWorkingTimes: InfoForType<AssemblyDifficulty, @Contextual Duration>,

  override val sunnyHomeManager: PriceWithProfitAndWorkingTime,

  override val heaterRodPrices: PricesAndWorkingTimes<HeaterRod>,

  override val waermepumpenanbindung: PriceWithProfitAndWorkingTime,

  override val smaEnergyMeter: PriceWithProfitAndWorkingTime,

  override val erdspiessSetzen: PriceWithProfitAndWorkingTime,

  override val slsNachruesten: PriceWithProfitAndWorkingTime,

  override val neuerZaehlerschrank: PriceWithProfitAndWorkingTime,

  override val zaehlerschrankZusammenlegen: PriceWithProfitAndWorkingTime,

  override val einbauUnterverteiler: PriceWithProfitAndWorkingTime,

  override val roofIsolationThicknessPrices: RoofIsolationPrices,

  override val einbauDigitalerZaehlerPrice: PriceWithProfitAndWorkingTime,

  override val aufpreisSchienensystemPrice: PriceWithProfit,

  override val aufpreisSchwarzeKlemmePrice: PriceWithProfit,

  override val independenceManagerTypePrices: PricesAndWorkingTimes<IndependenceManagerType>,

  ) : AvailableProducts, ProductResolver, PriceList {

  init {
    //Check that there is a price for every metal roof tile type for every roof tile type
    availableRoofTiles.available.fastForEach { roofTileType ->
      requireNotNull(metalRoofTilePrices.priceOrNull(roofTileType.metalRoofTile)) {
        "No price info available for metal roof tile <${roofTileType.metalRoofTile}> required for roof tile $roofTileType"
      }
    }
  }

  override val defaultAssemblyDifficulty: AssemblyDifficulty
    get() = availableAssemblyDifficulties().let {
      it.getOrNull(1) ?: it.first()
    }


  override fun availableRoofTiles(selector: ((LifeCycleState) -> Boolean)?): List<RoofTile> {
    return availableRoofTiles.available.only(selector)
  }

  /**
   * Returns the available module types
   */
  override fun availableModules(selector: ((LifeCycleState) -> Boolean)?): List<PvModule> {
    return modulePrices.available(selector)
  }

  override fun availableInverters(selector: ((LifeCycleState) -> Boolean)?): List<Inverter> {
    return inverterPrices.available(selector)
  }

  override fun availableWallBoxes(selector: ((LifeCycleState) -> Boolean)?): List<Wallbox> {
    return wallBoxPrices.available(selector)
  }

  /**
   * Returns the available roof types
   */
  override fun availableRoofTypes(selector: ((LifeCycleState) -> Boolean)?): List<RoofType> {
    return assemblyCostsPerModule.available(selector)
  }

  override fun availableMetalRoofTiles(selector: ((LifeCycleState) -> Boolean)?): List<MetalRoofTile> {
    return metalRoofTilePrices.available(selector)
  }

  override fun availableMetalRoofTileColors(selector: ((LifeCycleState) -> Boolean)?): List<MetalRoofTileColor> {
    return aufschlagColoredMetalPlatesPrices.available(selector)
  }

  override fun availableAssemblyDifficulties(selector: ((LifeCycleState) -> Boolean)?): List<AssemblyDifficulty> {
    return assemblyDifficultyPrices.available(selector)
  }

  /**
   * Returns the available battery configurations.
   * Does *NOT* include the empty configuration
   */
  override fun availableBatteryConfigurations(selector: ((LifeCycleState) -> Boolean)?): List<BatteryConfiguration> {
    return batteryConfigurationPrices.available(selector)
  }

  override fun availableHeaterRods(selector: ((LifeCycleState) -> Boolean)?): List<HeaterRod> {
    return heaterRodPrices.available(selector)
  }

  override fun availableIndependenceManagerTypes(selector: ((LifeCycleState) -> Boolean)?): List<IndependenceManagerType> {
    return independenceManagerTypePrices.available(selector)
  }

  /**
   * Returns the price for a module type
   */
  override operator fun get(moduleType: PvModule): PriceWithProfit {
    return modulePrices[moduleType]
  }

  override operator fun get(type: BatteryConfiguration): PriceWithProfitAndWorkingTime {
    return batteryConfigurationPrices[type]
  }

  /**
   * Returns the price
   */
  override operator fun get(metalRoofTile: MetalRoofTile): PriceWithProfit {
    return metalRoofTilePrices[metalRoofTile]
  }

  override operator fun get(assemblyDifficulty: AssemblyDifficulty): PriceWithProfit {
    return assemblyDifficultyPrices[assemblyDifficulty]
  }

  override operator fun get(inverter: InverterType): PriceWithProfit {
    return when (inverter) {
      is Inverter -> inverterPrices[inverter]
      is HybridInverter -> hybridInverterPrices[inverter]
      is BasicBatteryInverter -> batteryInverterPrices[inverter]
    }
  }

  override operator fun get(inverter: Inverter): PriceWithProfit {
    return inverterPrices[inverter]
  }

  override operator fun get(inverter: HybridInverter): PriceWithProfit {
    return hybridInverterPrices[inverter]
  }

  override operator fun get(inverter: BasicBatteryInverter): PriceWithProfit {
    return batteryInverterPrices[inverter]
  }

  override operator fun get(wallbox: Wallbox): PriceWithProfit {
    return wallBoxPrices[wallbox]
  }

  override fun get(heaterRod: HeaterRod): PriceWithProfitAndWorkingTime {
    return heaterRodPrices[heaterRod]
  }


  /**
   * Calculates the monitoring price - depending on the power rating and whether a battery is installed
   */
  override fun monitoringPrice(powerRating: PowerRating, includingBattery: Boolean): PriceWithProfit {
    return monitoringPrices[powerRating, includingBattery]
  }

  /**
   * Returns the extra charge *per module* depending on the roof type
   */
  override fun assemblyCostPerModule(roofType: RoofType): @PerModule PriceWithProfit {
    return assemblyCostsPerModule[roofType]
  }

  override fun roofIsolationCostPerModule(roofIsolation: @cm Int): @PerModule PriceWithProfit {
    return roofIsolationThicknessPrices[roofIsolation]
  }

  override fun getWorkingTime(assemblyDifficulty: AssemblyDifficulty): Duration {
    return assemblyDifficultyWorkingTimes[assemblyDifficulty]
  }


  /**
   * Returns the [PvModule] for the given id
   */
  override operator fun get(moduleTypeId: PvModuleId): PvModule {
    return modulePrices.available.find { moduleType ->
      moduleType.id == moduleTypeId
    } ?: throw NoSuchElementException("No PvModuleType for the ID $moduleTypeId")
  }

  /**
   * Returns the [RoofType] for the given id
   */
  override operator fun get(roofTypeId: RoofTypeId): RoofType {
    return assemblyCostsPerModule.available.find { roofType ->
      roofType.id == roofTypeId
    } ?: throw NoSuchElementException("No RoofType for the ID $roofTypeId")
  }

  /**
   * Returns the [RoofTile] for the given id
   */
  override operator fun get(roofTileId: RoofTileId): RoofTile {
    return availableRoofTiles.available.find { roofTileType ->
      roofTileType.id == roofTileId
    } ?: throw NoSuchElementException("No RoofTileType for the ID $roofTileId")
  }

  /**
   * Returns the [InverterType] for the given id
   */
  override operator fun get(inverterId: InverterId): Inverter {
    return inverterPrices.available.find { inverterType ->
      inverterType.id == inverterId
    } ?: throw NoSuchElementException("No InverterType for the ID $inverterId")
  }

  override fun getConfigurableInverter(inverterId: InverterId): ConfigurableInverter {
    return inverterPrices.available.find { inverterType ->
      inverterType.id == inverterId
    } ?: hybridInverterPrices.available.find { inverterType ->
      inverterType.id == inverterId
    } ?: throw NoSuchElementException("No ConfigurableInverter for the ID $inverterId")
  }

  /**
   * Returns the [Wallbox] for the given id
   */
  override operator fun get(wallboxId: WallboxId): Wallbox {
    return wallBoxPrices.available.find { wallBoxType ->
      wallBoxType.id == wallboxId
    } ?: throw NoSuchElementException("No WallboxType for the ID $wallboxId")
  }

  /**
   * Returns the [BatteryConfiguration] for the given id
   */
  override operator fun get(batteryConfigurationId: BatteryConfigurationId): BatteryConfiguration {
    return availableBatteryConfigurations(null).find { batteryConfiguration ->
      batteryConfiguration.id == batteryConfigurationId
    } ?: throw NoSuchElementException("No BatteryConfiguration for the ID $batteryConfigurationId")
  }

  /**
   * Returns the [AssemblyDifficulty] for the given id
   */
  override operator fun get(assemblyDifficultyId: AssemblyDifficultyId): AssemblyDifficulty {
    return availableAssemblyDifficulties(null).find { assemblyDifficulty ->
      assemblyDifficulty.id == assemblyDifficultyId
    } ?: throw NoSuchElementException("No AssemblyDifficulty for the ID $assemblyDifficultyId")
  }

  /**
   * Returns the [MetalRoofTileColor] for the given id
   */
  override operator fun get(metalRoofTileColorId: MetalRoofTileColorId): MetalRoofTileColor {
    return availableMetalRoofTileColors(null).find { metalRoofTileColor ->
      metalRoofTileColor.id == metalRoofTileColorId
    } ?: throw NoSuchElementException("No MetalRoofTileColor for the ID $metalRoofTileColorId")
  }

  /**
   * Returns the [HeaterRod] for the given id
   */
  override operator fun get(heaterRodId: HeaterRodId): HeaterRod {
    return availableHeaterRods(null).find { heaterRod ->
      heaterRod.id == heaterRodId
    } ?: throw NoSuchElementException("No HeaterRod for the ID $heaterRodId")
  }

  /**
   * Returns the [IndependenceManagerType] for the given id
   */
  override operator fun get(independenceManagerId: IndependenceManagerId): IndependenceManagerType {
    val availableIndependenceManagerTypes = availableIndependenceManagerTypes(null)
    return availableIndependenceManagerTypes.find { independenceManager ->
      independenceManager.id == independenceManagerId
    } ?: throw NoSuchElementException("No IndependenceManagerType for the ID $independenceManagerId in ${availableIndependenceManagerTypes.map { it.id.uuid }}")
  }


  /**
   * Dumps the price information as string
   */
  fun dump(): String {
    return buildString {
      appendLine("Module")
      appendLine("===============")
      availableModules(null).forEach { element ->
        append("  ${element.description.padEnd(40)}")
        appendLine(" ${get(element).dump().padStart(10)}")
      }

      appendLine()
      appendLine("Inverter")
      appendLine("===============")
      availableInverters(null).forEach { element ->
        append("  ${element.description.padEnd(40)}")
        appendLine(" ${get(element).dump().padStart(10)}")
      }

      appendLine()
      appendLine("Battery Configurations")
      appendLine("===============")
      availableBatteryConfigurations(null).forEach { element ->
        append("  ${element.format().padEnd(40)}")
        appendLine(" ${get(element).dump().padStart(10)}")
      }

      appendLine()
      appendLine("Substructure per RoofType")
      appendLine("===============")
      availableRoofTypes(null).forEach { element ->
        append("  ${element.description.padEnd(40)}")
        appendLine(" ${get(element).dump().padStart(10)}")
      }

      appendLine()
      appendLine("Metal RoofTiles")
      appendLine("===============")
      availableMetalRoofTiles(null).forEach { element ->
        append("  ${element.description.padEnd(40)}")
        appendLine(" ${get(element).dump().padStart(10)}")
      }

      appendLine()
      appendLine("assemblyDifficulties")
      appendLine("===============")
      availableAssemblyDifficulties(null).forEach { element ->
        append("  ${element.description.padEnd(40)}")
        appendLine(" ${get(element).dump().padStart(10)}")
      }

      appendLine()
      appendLine("Heizstäbe")
      appendLine("===============")
      availableHeaterRods(null).forEach { element ->
        append("  ${element.description.padEnd(40)}")
        appendLine(" ${get(element).dump().padStart(10)}")
      }

      appendLine()
      appendLine("wallBoxes")
      appendLine("===============")
      availableWallBoxes(null).forEach { element ->
        append("  ${element.description.padEnd(40)}")
        appendLine(" ${get(element).dump().padStart(10)}")
      }

      appendLine()
      appendLine("monitoringPrices")
      appendLine("===============")
      append("  ").append("<10 kWp".padEnd(40))
      appendLine(" ${monitoringPrices.priceBelow10kWp.dump().padStart(10)}")
      append("  ").append("<20 kWp".padEnd(40))
      appendLine(" ${monitoringPrices.priceBelow20kWp.dump().padStart(10)}")
      append("  ").append("<30 kWp".padEnd(40))
      appendLine(" ${monitoringPrices.priceBelow30kWp.dump().padStart(10)}")
      append("  ").append(">30 kWp".padEnd(40))
      appendLine(" ${monitoringPrices.priceElse.dump().padStart(10)}")
      append("  ").append("Zuschlag Batteriespeicher".padEnd(40))
      appendLine(" ${monitoringPrices.additionalCostsWithBattery.dump().padStart(10)}")

      appendLine()
      append("Gerüst: ".padEnd(42)).appendLine(" ${scaffolding.dump().padStart(10)}")
      append("dachStaenderIsolierung: ".padEnd(42)).appendLine(" ${dachStaenderIsolierung.dump().padStart(10)}")
      append("ueberSpannungsSchutz: ".padEnd(42)).appendLine(" ${ueberSpannungsSchutz.dump().padStart(10)}")
      append("moveSatelliteDish: ".padEnd(42)).appendLine(" ${moveSatelliteDish.dump().padStart(10)}")
      append("removeAntenna: ".padEnd(42)).appendLine(" ${removeAntenna.dump().padStart(10)}")
      append("perKilometerTravelled: ".padEnd(42)).appendLine(" ${perKilometerTravelled.dump().padStart(10)}")
      append("optimizerPrice: ".padEnd(42)).appendLine(" ${optimizerPrice.dump().padStart(10)}")
      append("zuschlagKabelweg: ".padEnd(42)).appendLine(" ${zuschlagKabelweg.dump().padStart(10)}")
      append("aufschlagKreuzverbund: ".padEnd(42)).appendLine(" ${aufschlagKreuzverbund.dump().padStart(10)}")
      append("startingUpBasePrice: ".padEnd(42)).appendLine(" ${startingUpPrice.dump().padStart(10)}")

      append("sunnyHomeManagerPrice: ".padEnd(42)).appendLine(" ${sunnyHomeManager.dump().padStart(10)}")
      append("waermepumpenanbindungPrice: ".padEnd(42)).appendLine(" ${waermepumpenanbindung.dump().padStart(10)}")
      append("smaEnergyMeterPrice: ".padEnd(42)).appendLine(" ${smaEnergyMeter.dump().padStart(10)}")
      append("erdspiessSetzenPrice: ".padEnd(42)).appendLine(" ${erdspiessSetzen.dump().padStart(10)}")
      append("slsNachruestenPrice: ".padEnd(42)).appendLine(" ${slsNachruesten.dump().padStart(10)}")
      append("neuerZaehlerschrankPrice: ".padEnd(42)).appendLine(" ${neuerZaehlerschrank.dump().padStart(10)}")
      append("zaehlerschrankZusammenlegenPrice: ".padEnd(42)).appendLine(" ${zaehlerschrankZusammenlegen.dump().padStart(10)}")
    }
  }

}
