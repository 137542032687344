package it.neckar.lizergy.model.configuration.quote.economics

import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.configuration.energy.AmountOfEnergy
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.quote.EnvironmentSavings
import it.neckar.lizergy.model.configuration.quote.economics.simple.SimpleProfitabilityTable
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable
import kotlin.math.pow

/**
 * Contains information about the economics report
 */
@Serializable
data class EconomicsReport(

  /**
   * The power usage scenario
   */
  val powerUsageScenario: PowerUsageScenario,

  /**
   * The assumed prices trend scenario
   */
  val pricesTrendScenario: PricesTrendScenario,

  /**
   * Yearly costs
   */
  val yearlyCostInformation: YearlyCostInformation,

  /**
   * How much money have been financed / invested
   */
  val finances: FinancesInformation,

  /**
   * Information about the environment savings
   */
  val environmentSavings: EnvironmentSavings,

  /**
   * The profitability table
   */
  val profitabilityTable: SimpleProfitabilityTable,

  ) {

  /**
   * The total (cumulated) profit at the end
   */
  val totalProfit: Money
    get() = profitabilityTable.columns.last().amortisationInformation.cashFlowCumulated

  /**
   * The savings at the first month
   */
  val savingsFirstMonth: Money
    get() = profitabilityTable.columns.first().cashFlow.cashFlow / 12.0

  /**
   * The degree of self-sufficiency ("Autarkiegrad")
   *
   * = own consumption / yearly power consumption
   */
  val degreeOfSelfSufficiency: @pct Double
    get() = if (powerUsageScenario.yearlyPowerConsumptionValue <= AmountOfEnergy(0.0)) {
      0.0
    } else {
      profitabilityTable.columns.first().energyInformation.ownConsumption / powerUsageScenario.yearlyPowerConsumptionValue
    }

  /**
   * The calculated profit per year
   */
  fun calculativeProfitPerYear(numberOfYears: Int = 20): @pct Double {
    require(profitabilityTable.columns.size == numberOfYears) {
      "unexpected size ${profitabilityTable.columns.size}"
    }

    val invest = finances.invest
    @pct val q = ((invest + totalProfit) / invest).pow(1 / numberOfYears.toDouble())
    return q - 1.0
  }

}
