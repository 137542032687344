package it.neckar.financial.quote

import it.neckar.commons.tags.Tag
import it.neckar.commons.tags.Tags
import it.neckar.financial.currency.Money
import it.neckar.open.collections.fastForEach

/**
 * Describes a query for relevance categories.
 * The query can be used to calculate sums.
 */
interface CalculationRelevanceQuery {
  /**
   * Returns true if the given quote element matches this query
   */
  fun matches(quoteElement: QuoteElement): Boolean

  /**
   * Creates a new query that connects both queries using "and"
   */
  operator fun plus(otherQuery: CalculationRelevanceQuery): CalculationRelevanceQuery {
    return AndCalculationRelevanceQuery(listOf(this, otherQuery))
  }


  companion object {
    /**
     * Returns the visible and mandatory items.
     */
    val allVisibleOnlyMandatory: CalculationRelevanceQuery = object : CalculationRelevanceQuery {
      override fun matches(quoteElement: QuoteElement): Boolean {
        return onlyMandatory.matches(quoteElement) && onlyPublic.matches(quoteElement)
      }
    }

    /**
     * Filters all visible elements - including optional elements.
     * This query should typically be used to decide which elements are shown
     */
    val onlyPublic: CalculationRelevanceQuery = object : CalculationRelevanceQuery {
      override fun matches(quoteElement: QuoteElement): Boolean {
        return quoteElement.visibility == Visibility.Public
      }
    }

    /**
     * Selects all items. Even the optional.
     * Must not be used for calculations.
     */
    val allIncludingOptional: CalculationRelevanceQuery = object : CalculationRelevanceQuery {
      override fun matches(quoteElement: QuoteElement): Boolean {
        return true
      }
    }

    val onlyOptional: CalculationRelevanceQuery = object : CalculationRelevanceQuery {
      override fun matches(quoteElement: QuoteElement): Boolean {
        return quoteElement.optionality == Optionality.Optional
      }
    }

    /**
     * Only mandatory items are returned (not optional)
     *
     *
     * This query should typically be used to calculate the sum
     */
    val onlyMandatory: CalculationRelevanceQuery = object : CalculationRelevanceQuery {
      override fun matches(quoteElement: QuoteElement): Boolean {
        return (quoteElement.optionality == Optionality.Mandatory)
      }
    }

    /**
     * Skips all items that have a sum of 0.0
     */
    val skipZero: CalculationRelevanceQuery = object : CalculationRelevanceQuery {
      override fun matches(quoteElement: QuoteElement): Boolean {
        val sums = quoteElement.sums(allIncludingOptional)
        return sums.net.sellingPrice.isZero().not()
      }
    }

    /**
     * Shows only public entries - skip elements with salces price of "0.0"
     */
    val onlyPublicSkipZero: CalculationRelevanceQuery = object : CalculationRelevanceQuery {
      override fun matches(quoteElement: QuoteElement): Boolean {
        return onlyPublic.matches(quoteElement) && skipZero.matches(quoteElement)
      }
    }


    /**
     * The default query for visualization for the consumer
     */
    val defaultForVisibility: CalculationRelevanceQuery = onlyPublic

    /**
     * The default query for calculations (only contains the mandatory items) - not the optional!
     */
    val defaultForSum: CalculationRelevanceQuery = onlyMandatory


    fun onlyMandatoryWithoutTag(tag: Tag): CalculationRelevanceQuery {
      return object : CalculationRelevanceQuery {
        override fun matches(quoteElement: QuoteElement): Boolean {
          return onlyMandatory.matches(quoteElement) && (quoteElement.relevanceOrNull()?.contains(tag)?.not() == true)
        }
      }
    }

  }
}

/**
 * Returns true if all the delegates returns true.
 * Returns false if any of the delegates returns false.
 */
class AndCalculationRelevanceQuery(
  val delegates: List<CalculationRelevanceQuery>,
) : CalculationRelevanceQuery {
  override fun matches(quoteElement: QuoteElement): Boolean {
    delegates.fastForEach {
      if (it.matches(quoteElement).not()) {
        return false
      }
    }

    return true
  }
}

/**
 * Returns true if all the delegates return false.
 * Returns false if at least one of the delegates returns true
 */
class NoneCalculationRelevanceQuery(
  val delegates: List<CalculationRelevanceQuery>,
) : CalculationRelevanceQuery {
  override fun matches(quoteElement: QuoteElement): Boolean {
    delegates.fastForEach {
      if (it.matches(quoteElement)) {
        return false
      }
    }

    return true
  }
}


/**
 * Returns a new query for this category
 */
fun Tag.asQuery(): CalculationRelevanceQuery {
  return QueryByCalculationRelevanceCategory(this)
}

class QueryByCalculationRelevanceCategory(val tag: Tag) : CalculationRelevanceQuery {
  override fun matches(quoteElement: QuoteElement): Boolean {
    return quoteElement.relevanceOrNull()?.contains(tag) ?: false
  }
}

fun Tags.asQuery(): CalculationRelevanceQuery {
  return QueryByCalculationRelevanceCategories(this)
}

class QueryByCalculationRelevanceCategories(val tags: Tags) : CalculationRelevanceQuery {
  override fun matches(quoteElement: QuoteElement): Boolean {
    if (tags.isEmpty()) {
      return quoteElement.relevanceOrNull()?.isEmpty() ?: false
    }

    return tags.tags.all {
      quoteElement.relevanceOrNull()?.contains(it) ?: false
    }
  }
}
