package store.actions

import it.neckar.common.redux.ChangeType
import store.PlannerClientState


/**
 * Logs out the user
 */
data class LogoutAction(val reason: LogoutReason) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return logout()
  }
}

/**
 * The reason for logging out (see [LogoutAction])
 */
enum class LogoutReason {
  /**
   * A user pressed the logout button
   */
  UserInteraction,

  /**
   * The JWS tokens could not be refreshed
   */
  TokenRefreshFailed,
}
