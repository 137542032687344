package it.neckar.lizergy.model.price

import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.IndependenceManagerType
import it.neckar.lizergy.model.configuration.moduleLayout.PvModule
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.Wallbox

interface AvailableProducts {

  val availableRoofTiles: AvailableRoofTiles

  /**
   * The roof tiles that are available - does *not* include prices.
   * For each roof tile there must be an entry in metalRoofTiles for the price information
   */
  fun availableRoofTiles(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<RoofTile>

  /**
   * Returns the available module types
   */
  fun availableModules(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<PvModule>

  fun availableInverters(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<Inverter>

  fun availableWallBoxes(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<Wallbox>

  /**
   * Returns the available roof types
   */
  fun availableRoofTypes(selector:((LifeCycleState)-> Boolean)? = LifeCycleState::isNotEndOfLife): List<RoofType>

  fun availableMetalRoofTiles(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<MetalRoofTile>
  fun availableMetalRoofTileColors(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<MetalRoofTileColor>

  fun availableAssemblyDifficulties(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<AssemblyDifficulty>

  /**
   * Returns the available battery configurations.
   * Does *NOT* include the empty configuration
   */
  fun availableBatteryConfigurations(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<BatteryConfiguration>

  fun availableHeaterRods(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<HeaterRod>

  fun availableIndependenceManagerTypes(selector: ((LifeCycleState) -> Boolean)? = LifeCycleState::isNotEndOfLife): List<IndependenceManagerType>

}
