package components.project.configuration.additional

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import kotlinx.html.role
import react.*
import react.dom.*

/**
 *
 */
fun RBuilder.einspeiseArtForm(
  einspeiseartToSave: StateInstance<Einspeiseart>,
  editableStatus: EditableStatus,
): Unit = child(EinspeiseArtForm) {
  attrs {
    this.einspeiseartToSave = einspeiseartToSave
    this.editableStatus = editableStatus
  }
}

val EinspeiseArtForm: FC<EinspeiseArtFormProps> = fc("EinspeiseArtForm") { props ->
  val einspeiseartToSave = props::einspeiseartToSave.getNotNull()
  val editableStatus = props::editableStatus.safeGet()


  div("row") {
    div("col-2") {
      b("me-3") { +"Einspeiseart:" }
    }

    div("col-10") {
      div(classes = "btn-group") {
        attrs {
          role = "group"
        }

        span("mx-3") {
          radioButton(
            buttonId = "ueberschuss",
            value = einspeiseartToSave.value == Einspeiseart.Ueberschuss,
            onChange = { einspeiseartToSave.setter(Einspeiseart.Ueberschuss) },
            inputClasses = "form-check-input",
            labelClasses = "form-check-label ms-2",
            editableStatus = editableStatus,
          ) {
            +"Überschuss"
          }
        }

        span("mx-3") {
          radioButton(
            buttonId = "volleinspeisung",
            value = einspeiseartToSave.value == Einspeiseart.Volleinspeisung,
            onChange = { einspeiseartToSave.setter(Einspeiseart.Volleinspeisung) },
            inputClasses = "form-check-input",
            labelClasses = "form-check-label ms-2",
            editableStatus = editableStatus,
          ) {
            +"Volleinspeisung"
          }
        }
      }
    }

  }

}

external interface EinspeiseArtFormProps : Props {
  var einspeiseartToSave: StateInstance<Einspeiseart>
  var editableStatus: EditableStatus
}
