package components.company.pricelist.tables

import it.neckar.lizergy.model.price.RoofIsolationPrices
import it.neckar.open.collections.fastForEachIndexed
import kotlinx.html.ThScope
import react.*
import react.dom.*
import store.hooks.useSelectPriceList

val RoofIsolationPriceTable: FC<Props> = fc("RoofIsolationPriceTable") {
  val priceList = useSelectPriceList()

  val roofIsolationPrices = priceList.roofIsolationThicknessPrices


  div("col") {
    h2 {
      +"Aufdachdämmungpreise (pro Modul)"
    }

    table("table table-responsive table-striped") {
      thead {
        tr {
          th(ThScope.col) {
            +"#"
          }
          th(ThScope.col) {
            +"Name"
          }
          th(ThScope.col, "text-end") {
            +"Kosten"
          }
          th(ThScope.col, "text-end") {
            +"Preis"
          }
          th(ThScope.col, "text-end") {
            +"Deckungsbeitrag"
          }
        }
      }

      tbody {
        roofIsolationPrices.asList().fastForEachIndexed { roofIsolationIndex, roofIsolationPrice ->
          val type = when (roofIsolationIndex) {
            0 -> "0-4 cm"
            1 -> "4-6 cm"
            2 -> "6-10 cm"
            3 -> "10-14 cm"
            4 -> "14-16 cm"
            5 -> "16-18 cm"
            6 -> "18-20 cm"
            7 -> "20-22 cm"
            else -> ">22 cm"
          }

          tr {
            th(ThScope.row) {
              +"${roofIsolationIndex + 1}"
            }
            td {
              +type
            }

            td("text-end") {
              +roofIsolationPrice.inputPrice.format()
            }
            td("text-end") {
              +roofIsolationPrice.sellingPrice.format()
            }
            td("text-end") {
              +roofIsolationPrice.profit.format()
            }
          }
        }
      }
    }
  }
}

external interface RoofIsolationPriceTableProps : Props {
  var roofIsolationPrices: RoofIsolationPrices
}
