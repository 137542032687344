package it.neckar.financial.currency

import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.time.h
import kotlinx.serialization.Serializable
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours

/**
 * Contains information about a selling price and profit
 */
@Serializable
data class WorkingTimesWithPrice(
  /**
   * The input price ("real" cost).
   *
   * This is the price:
   * * one is paying when buying by a third party.
   * * the calculatory, direct cost when producing/creating/working
   */
  val workingTime: Duration,
  /**
   * The selling price
   */
  val workingHourPrice: PricePerHour,

  ) {

  val priceWithProfit: PriceWithProfit
    get() = workingTime * workingHourPrice

  operator fun plus(other: WorkingTimesWithPrice): WorkingTimesWithPrice {
    require(workingHourPrice == other.workingHourPrice) { "workingHourPrices <$workingHourPrice> and <${other.workingHourPrice}> need to be identical to be added!" }
    return WorkingTimesWithPrice(this.workingTime + other.workingTime, workingHourPrice)
  }

  operator fun plus(other: Duration): WorkingTimesWithPrice {
    return WorkingTimesWithPrice(this.workingTime + other, workingHourPrice)
  }

  operator fun minus(other: WorkingTimesWithPrice): WorkingTimesWithPrice {
    require(workingHourPrice == other.workingHourPrice) { "workingHourPrices <$workingHourPrice> and <$workingHourPrice> need to be identical to be subtracted!" }
    return WorkingTimesWithPrice(this.workingTime - other.workingTime, workingHourPrice)
  }

  operator fun minus(other: Duration): WorkingTimesWithPrice {
    return WorkingTimesWithPrice(this.workingTime - other, workingHourPrice)
  }

  operator fun times(factor: Double): WorkingTimesWithPrice {
    return WorkingTimesWithPrice(this.workingTime * factor, workingHourPrice)
  }

  operator fun times(factor: Int): WorkingTimesWithPrice {
    return WorkingTimesWithPrice(this.workingTime * factor, workingHourPrice)
  }

  operator fun div(other: Double): @pct WorkingTimesWithPrice {
    return WorkingTimesWithPrice(this.workingTime / other, workingHourPrice)
  }

  operator fun unaryMinus(): WorkingTimesWithPrice {
    return WorkingTimesWithPrice(-workingTime, workingHourPrice)
  }


  companion object {

    val Zero: WorkingTimesWithPrice = WorkingTimesWithPrice(Duration.ZERO, PricePerHour.zero)

    operator fun invoke(workingTimeHours: @h Double, workingHourPriceEuros: @EUR Double): WorkingTimesWithPrice {
      return WorkingTimesWithPrice(workingTimeHours.hours, PricePerHour(workingHourPriceEuros.euro))
    }

  }
}
