@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.quote

import com.benasher44.uuid.Uuid
import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyInformation
import it.neckar.customer.company.PartnerCompanyProfile
import it.neckar.datetime.minimal.Year
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.sum
import it.neckar.financial.quote.CalculationRelevanceQuery
import it.neckar.financial.quote.Quote
import it.neckar.financial.quote.Quote.QuoteId
import it.neckar.financial.quote.QuoteCompanyInformation
import it.neckar.financial.quote.QuoteElements
import it.neckar.financial.quote.QuoteSources
import it.neckar.financial.quote.asQuery
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.PlannerConfiguration
import it.neckar.lizergy.model.configuration.ResolvedPhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.components.AssemblyTimeCalculator
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.components.Einspeiseart
import it.neckar.lizergy.model.configuration.components.ExistingFacilitiesConfiguration
import it.neckar.lizergy.model.configuration.components.FacilityOperatorInformation
import it.neckar.lizergy.model.configuration.components.LegalNote
import it.neckar.lizergy.model.configuration.components.ResolvedAssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedElectricityWorkConfiguration
import it.neckar.lizergy.model.configuration.components.ResolvedFacilityConfiguration
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.BatterySizeRecommendationCalculator
import it.neckar.lizergy.model.configuration.energy.selfsufficiency.ManualPowerConsumptionDistribution
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayouts
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ConfigurationItemsConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.LizergyCalculationCategories
import it.neckar.lizergy.model.configuration.quote.builder.LizergyQuotePositionsBuilder
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedWallboxSelection
import it.neckar.lizergy.model.configuration.quote.economics.EconomicsReport
import it.neckar.lizergy.model.configuration.quote.economics.FinancesInformation
import it.neckar.lizergy.model.configuration.quote.economics.FinancingType
import it.neckar.lizergy.model.configuration.quote.economics.YearlyCostInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.AmortisationInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.CashFlow
import it.neckar.lizergy.model.configuration.quote.economics.simple.EnergyInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.FinancingInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.IncreasingMoney
import it.neckar.lizergy.model.configuration.quote.economics.simple.SimpleProfitabilityTable
import it.neckar.lizergy.model.configuration.quote.economics.simple.TariffInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.increasingBy
import it.neckar.lizergy.model.income.IncomePercentage
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.lizergy.model.income.IncomePercentages
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.AvailableProducts
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.price.PriceList
import it.neckar.lizergy.model.price.ResolvedEarningsDistribution
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.previews.PreviewQuoteElements
import it.neckar.lizergy.version.LizergyVersions
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.kWp
import it.neckar.open.unit.si.km
import it.neckar.open.unit.si.ms
import it.neckar.open.unit.time.h
import it.neckar.user.UserLoginName
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.UseSerializers
import kotlin.time.Duration
import kotlin.time.times

/**
 * A QuoteConfiguration has been created from a [ResolvedPhotovoltaicsConfiguration] by calling [ResolvedPhotovoltaicsConfiguration.evaluate].
 * It contains additional inforamtion (quote snapshots and price information)
 *
 * A [PlannerConfiguration] with everything resolved and all prices calculated.
 *
 * This object is "resolved" and also contains price information
 */
data class QuoteConfiguration(

  override val configurationId: PhotovoltaicsConfigurationId,

  override val label: String?,

  override val description: String?,

  override val creationTime: Double,

  override val sellingCompanyInformation: PlannerCompanyInformation,
  override val monitoringCompanyInformation: PlannerCompanyInformation,

  @Deprecated("Replaced by new Process State Service")
  val editorInformation: UserInformation?,

  override val location: LocationInformation,

  /**
   * The [ResolvedModuleLayouts] of this [QuoteConfiguration]
   */
  override val moduleLayouts: ResolvedModuleLayouts,

  override val wallboxSelection: ResolvedWallboxSelection,

  override val powerUsageScenario: PowerUsageScenario,

  override val pricesTrendScenario: PricesTrendScenario,


  override val facilityConfiguration: ResolvedFacilityConfiguration,

  override val assemblyConfiguration: ResolvedAssemblyConfiguration,

  override val yearlyCosts: YearlyCostInformation,

  override val financing: FinancingType,

  override val shippingDistanceManual: Int?,

  override val shippingDistanceCalculated: Int?,

  override val electricityWorkConfiguration: ResolvedElectricityWorkConfiguration,

  override val additionalPositions: ConfigurationItemsConfiguration,

  override val existingFacilitiesConfiguration: ExistingFacilitiesConfiguration,

  override val discountPercentage: Double,

  override val zaehlerNummer: String,
  override val flurstueckNummer: String,

  override val facilityOperator1: FacilityOperatorInformation,
  override val facilityOperator2: FacilityOperatorInformation,

  override val einspeiseart: Einspeiseart,

  override val legalNoticeAdditionalLine: String?,

  override val legalNotes: List<LegalNote>,

  override val manualPowerConsumptionDistribution: ManualPowerConsumptionDistribution?,

  override val earningsDistribution: ResolvedEarningsDistribution,

  @Deprecated("No longer required")
  override val signedQuoteReceived: Boolean,

  override val manualQuoteElements: ManualQuoteElements,

  @Deprecated("Being replaced by new Process State Service")
  override val processState: PositionEditHistory<OLDProcessState>? = null,

  override val lifeCycleState: LifeCycleState,

  //Additional information that is *not* contained in [ResolvedConfiguration]

  val currentQuoteSnapshot: QuoteSnapshot?,

  val priceList: PriceList,

  val availableProducts: AvailableProducts,

  ) : PlannerConfiguration {

  override val sellingCompany: CompanyCode
    get() = sellingCompanyProfile.companyCode

  @Deprecated("Replaced by new Process State Service")
  override val editor: UserLoginName?
    get() = editorInformation?.loginName

  val workingTime: @h Duration = priceList.getWorkingTime(assemblyConfiguration.assemblyDifficulty)

  val totalDistanceTravelledForAssembly: @km Int = AssemblyTimeCalculator.estimatedTotalDistanceTravelledForAssembly(
    numberOfModules = moduleLayouts.totalNumberOfModules,
    timePerModule = workingTime,
    shippingDistance = shippingDistance ?: 0,
    workingHoursPerDay = priceList.workingHoursPerDay,
  )

  val calculatedQuoteElements: QuoteElements = LizergyQuotePositionsBuilder().buildQuoteElementsForConfiguration(this, totalDistanceTravelledForAssembly, priceList, sellingCompanyInformation, monitoringCompanyInformation)

  val quoteElements: QuoteElements = currentQuoteSnapshot?.quote?.quoteElements ?: calculatedQuoteElements

  /**
   * The selling price for this [QuoteConfiguration]
   * This value is useful when making suggestions for, for example, [BatteryConfiguration] regarding their price and eventual profit
   */
  val initialInvestment: @EUR Money = quoteElements.netPricesForVats(LizergyCalculationCategories.Query.EconomicsCalculationRelevance).total

  val operatingCosts: Money = yearlyCosts.operatingCosts ?: priceList.monitoringPrices[moduleLayouts.totalPowerRating, facilityConfiguration.batteryConfiguration != null].sellingPrice

  val financesInformation: FinancesInformation = FinancesInformation(initialInvestment, financing)

  val profitabilityTable: SimpleProfitabilityTable = SimpleProfitabilityTable.Calculator(
    baseYear = Year(2023),
    energyInformationCalculator = EnergyInformation.Calculator(initialProduction = totalAnnualProduction, ownConsumption = powerConsumptionProvidedByPv),
    cashFlowCalculator = CashFlow.Calculator(operatingCostsAsIncreasing()),
    tariffInformationCalculator = TariffInformation.Calculator(feedInGuaranteedPriceFor20Years = guaranteedFeedInPrice, consumptionPrice = pricesTrendScenario.consumptionPriceAsIncreasing()),
    financingInformationCalculator = FinancingInformation.Calculator.invoke(financesInformation),
    amortisationInformationCalculator = AmortisationInformation.Calculator(financesInformation.invest),
  ).calculateTable()

  val environmentSavings: EnvironmentSavings = EnvironmentSavings.calculate(profitabilityTable.firstYearProduction())

  val economicsReport: EconomicsReport = currentQuoteSnapshot?.economicsReport ?: EconomicsReport(
    powerUsageScenario = powerUsageScenario,
    pricesTrendScenario = pricesTrendScenario,
    yearlyCostInformation = yearlyCosts,
    finances = financesInformation,
    environmentSavings = environmentSavings,
    profitabilityTable = profitabilityTable,
  )

  val investmentWithoutBattery: @EUR Money = quoteElements.netPricesForVats(LizergyCalculationCategories.Query.EconomicsCalculationRelevance).total - quoteElements.netPricesForVats(LizergyCalculationCategories.BatteryStorage.asQuery()).total

  /**
   * The recommended [BatteryConfiguration] for this [QuoteConfiguration]
   * This recommendation is based on the projected profit made in the following 20 years and which [BatteryConfiguration] will result in the most
   */
  val recommendedBatteryConfiguration: BatteryConfiguration? = BatterySizeRecommendationCalculator.calculateRecommendedBatterySize(
    powerUsageScenario = powerUsageScenario,
    operatingCostsIncreasePercentage = yearlyCosts.operatingCostsIncreasePercentage,
    pricesTrendScenario = pricesTrendScenario,
    totalPowerRating = totalPowerRating,
    totalAnnualProduction = totalAnnualProduction,
    investmentWithoutBattery = investmentWithoutBattery,
    financing = financing,
    availableBatteryConfigurations = availableProducts.availableBatteryConfigurations(),
    priceList = priceList,
  )

  /**
   * The total assembly time that is required
   */
  val calculatedAssemblyTime: Duration = AssemblyTimeCalculator.estimateAssemblyTime(
    numberOfModules = totalNumberOfModules,
    timePerModule = workingTime,
  )

  val calculatedScaffoldingAssemblyTime: Duration
    get() = scaffoldingAreas().entries.sumOf { it.value } * priceList.scaffolding.workingTime

  val kiloWattPeak: @kWp PowerRating
    get() = moduleLayouts.totalPowerRating

  val kiloWattPeakPrice: Money
    get() {
      val position1Price = quoteElements.sections.first().sums(CalculationRelevanceQuery.defaultForSum).net
      val scaffoldingPrice = quoteElements.netPricesForVats(LizergyCalculationCategories.Scaffolding.asQuery()).total

      return if (kiloWattPeak.kiloWattPeak > 0.0) {
        (position1Price.sellingPrice - scaffoldingPrice) / kiloWattPeak.kiloWattPeak
      } else {
        Money.Zero
      }
    }

  val besonderheitenForAssemblyPortfolio: String
    get() = buildList {
      addAll(generateListOfLegalNotes())
      addAll(additionalPositions.configurationItems.filter { it.isEmpty.not() }.map { it.format() })
    }.joinToString(separator = "\n\n")

  val bestandsanlagesForAssemblyPortfolio: String
    get() = buildList {
      addAll(existingFacilitiesConfiguration.existingPVFacilities.map { it.format() })
      addAll(existingFacilitiesConfiguration.existingBHKWFacilities.map { it.format() })
    }.joinToString(separator = "\n\n")

  val sonstigesForAssemblyPortfolio: String
    get() = buildList {
      addAll(generateListOfLegalNotes(false))
      if (electricityWorkConfiguration.electricityWorkEffort.isEmpty.not()) add(electricityWorkConfiguration.electricityWorkEffort.format())
      addAll(additionalPositions.configurationItems.filter { it.isEmpty.not() }.map { it.format() })
      if (electricityWorkConfiguration.neuerZaehlerschrank) add("Neuer Zählerschrank EFH")
    }.joinToString(separator = "\n\n")


  /**
   * Returns the operating costs as increasing money object
   */
  fun operatingCostsAsIncreasing(): IncreasingMoney {
    return operatingCosts.increasingBy(yearlyCosts.operatingCostsIncreasePercentage)
  }

  val softwareIncomePercentage: IncomePercentage
    get() = if (earningsDistribution.includesPartnerCompanies) IncomePercentages.softwareWithPartner else IncomePercentages.softwareWithoutPartner

  fun getQuoteElements(): PreviewQuoteElements {
    return PreviewQuoteElements(
      sumsForTags = IncomePercentageCategory.entries.associateWith {
        manualQuoteElements.manualSumsForTags[it]?.currentValue ?: quoteElements.subTotalsForVATs(LizergyCalculationCategories.Query.Profits[it]).net.sellingPrice
      },
      discountPercentage = quoteElements.discountPercentage,
    )
  }

  fun getEarningsForCompany(forCompany: CompanyCode): Money {
    return buildList {
      IncomePercentages.allWithoutSoftware.fastForEachIndexed { index, incomePercentage ->
        val earningsCompanyEntry = earningsDistribution.all[index]
        val belongsToCompany = earningsCompanyEntry.company == forCompany
        if (belongsToCompany) add(earningsCompanyEntry.manualEarnings ?: getEarningsForIncomePercentage(incomePercentage))
      }
    }.sum()
  }

  fun getEarningsForMainCompany(): Money {
    val netPrices = quoteElements.netPricesForVats().total
    val totalEarningsForThirdPartyCompanies = getTotalEarningsForThirdPartyCompanies()
    return netPrices - totalEarningsForThirdPartyCompanies
  }

  fun getNeckarITEarnings(): Money {
    return earningsDistribution.neckarITAccountingStatus.manualEarnings ?: getEarningsForIncomePercentage(softwareIncomePercentage)
  }

  fun getEarningsForIncomePercentage(incomePercentage: IncomePercentage): Money {
    return IncomePercentageCategory.entries.map { category ->
      getQuoteElements().subTotals(category) * incomePercentage.getPercentage(category)
    }.sum()
  }

  fun getTotalEarningsForPartnerCompanies(): Money {
    return buildList {
      IncomePercentages.allWithoutSoftware.fastForEachIndexed { index, incomePercentage ->
        val earningsCompanyEntry = earningsDistribution.all[index]
        if (earningsCompanyEntry.companyProfile is PartnerCompanyProfile) {
          add(earningsCompanyEntry.manualEarnings ?: getEarningsForIncomePercentage(incomePercentage))
        }
      }
    }.sum()
  }

  fun getTotalEarningsForThirdPartyCompanies(): Money {
    val totalEarningsForPartnerCompanies = getTotalEarningsForPartnerCompanies()
    val totalNeckarITEarnings = getNeckarITEarnings()
    return totalEarningsForPartnerCompanies + totalNeckarITEarnings
  }

  /**
   * Converts a configuration to a quote snapshot within the context of a provided project.
   * Uses the values from the project to fill in the missing information.
   */
  fun toQuoteSnapshot(project: ResolvedProject, creationDate: @ms Double = nowMillis()): QuoteSnapshot {
    return toQuoteSnapshot(projectId = project.projectId, customer = project.customer, maintainer = project.maintainerInformation, creationDate = creationDate)
  }

  fun toQuoteSnapshot(
    projectId: PhotovoltaicsProjectId,
    customer: Customer,
    maintainer: UserInformation,
    sellingCompanyOverride: CompanyInformation? = null,
    creationDate: @ms Double = nowMillis(),
  ): QuoteSnapshot {
    return QuoteSnapshot(
      id = QuoteSnapshot.QuoteSnapshotId.random(),
      projectId = projectId,
      quote = generateUpToDateQuote(
        sellingCompany = sellingCompanyOverride ?: sellingCompanyInformation,
        customer = customer,
        maintainer = maintainer,
        creationDate = creationDate,
      ),
      economicsReport = economicsReport,
      configurationId = configurationId,
      resolvedModuleLayouts = moduleLayouts.copy(moduleLayouts = moduleLayouts.validElements),
      location = location,
      creatorName = maintainer.loginName,
      legalNotice = generateQuoteLegalNotice(),
    )
  }

  private fun generateUpToDateQuote(
    id: QuoteId = QuoteId.random(),
    sellingCompany: CompanyInformation,
    customer: Customer,
    maintainer: UserInformation,
    creationDate: @ms Double,
  ): Quote {
    return Quote(
      id = id,
      sellingCompany = sellingCompany.toQuoteCompanyInformation(),
      title = configurationName,
      maintainerName = maintainer.editorName,
      customer = customer,
      quoteElements = calculatedQuoteElements,
      sources = QuoteSources(
        softwareVersion = LizergyVersions.gitVersionAsStringVerbose,
        basePriceListId = priceList.uuid,
      ),
      creationDate = creationDate,
      legalNotice = generateQuoteLegalNotice(),
    )
  }

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): QuoteConfiguration {
    val newId = PhotovoltaicsConfigurationId.random()
    mapOfOldToNewUuids[configurationId.uuid] = newId.uuid
    return copy(
      configurationId = newId,
      label = "$configurationName (Kopie)",
      editorInformation = editorInformation,
      moduleLayouts = moduleLayouts.duplicate(false, mapOfOldToNewUuids),
      powerUsageScenario = powerUsageScenario.duplicate(mapOfOldToNewUuids),
      facilityConfiguration = facilityConfiguration.duplicate(mapOfOldToNewUuids),
      assemblyConfiguration = assemblyConfiguration.duplicate(mapOfOldToNewUuids),
      wallboxSelection = wallboxSelection.duplicate(mapOfOldToNewUuids),
      electricityWorkConfiguration = electricityWorkConfiguration.duplicate(mapOfOldToNewUuids),
      existingFacilitiesConfiguration = existingFacilitiesConfiguration.duplicate(mapOfOldToNewUuids),
      additionalPositions = additionalPositions.duplicate(mapOfOldToNewUuids),
      earningsDistribution = earningsDistribution.duplicate(mapOfOldToNewUuids),
      currentQuoteSnapshot = null,
    )
  }


  companion object {

    fun getEmpty(
      id: PhotovoltaicsConfigurationId = PhotovoltaicsConfigurationId.random(),
      sellingCompany: PlannerCompanyInformation,
      monitoringCompany: PlannerCompanyInformation,
      editor: UserInformation,
      resolvedModuleLayouts: ResolvedModuleLayouts,
      currentQuoteSnapshot: QuoteSnapshot?,
      availableProducts: AvailableProducts,
      priceList: PriceList,
      loggedInUser: UserLoginName,
      now: @ms Double = nowMillis(),
    ): QuoteConfiguration {
      val resolvedConfiguration = ResolvedPhotovoltaicsConfiguration.getEmpty(
        id = id,
        sellingCompany = sellingCompany,
        monitoringCompany = monitoringCompany,
        editor = editor,
        location = LocationInformation.empty,
        resolvedModuleLayouts = resolvedModuleLayouts,
        powerUsageScenario = PowerUsageScenario.typical(),
        facilityConfiguration = ResolvedFacilityConfiguration.getEmpty(),
        wallboxSelection = ResolvedWallboxSelection.getEmpty(),
        assemblyConfiguration = ResolvedAssemblyConfiguration.getEmpty(assemblyDifficulty = priceList.defaultAssemblyDifficulty),
        shippingDistanceManual = null,
        shippingDistanceCalculated = null,
        electricityWorkConfiguration = ResolvedElectricityWorkConfiguration.getEmpty(),
        additionalPositions = ConfigurationItemsConfiguration.getEmpty(),
        existingFacilitiesConfiguration = ExistingFacilitiesConfiguration.getEmpty(),
        discountPercentage = 0.0,
        zaehlerNummer = "",
        flurstueckNummer = "",
        facilityOperator1 = FacilityOperatorInformation(),
        facilityOperator2 = FacilityOperatorInformation(),
        einspeiseart = Einspeiseart.Ueberschuss,
        legalNoticeAdditionalLine = null,
        legalNotes = emptyList(),
        powerConsumptionDistribution = null,
        pricesTrendScenario = PricesTrendScenario.typical(),
        loggedInUser = loggedInUser,
        now = now,
      )

      return resolvedConfiguration.evaluate(currentQuoteSnapshot = currentQuoteSnapshot, availableProducts = availableProducts, priceList = priceList)
    }

  }

}


fun ResolvedPhotovoltaicsConfiguration.evaluate(currentQuoteSnapshot: QuoteSnapshot?, availableProducts: AvailableProducts, priceList: PriceList): QuoteConfiguration {
  return QuoteConfiguration(
    configurationId = configurationId,
    label = label,
    description = description,
    creationTime = creationTime,
    sellingCompanyInformation = sellingCompanyInformation,
    monitoringCompanyInformation = monitoringCompanyInformation,
    editorInformation = editorInformation,
    location = location,
    moduleLayouts = moduleLayouts,
    wallboxSelection = wallboxSelection,
    powerUsageScenario = powerUsageScenario,
    pricesTrendScenario = pricesTrendScenario,
    facilityConfiguration = facilityConfiguration,
    assemblyConfiguration = assemblyConfiguration,
    yearlyCosts = yearlyCosts,
    financing = financing,
    shippingDistanceManual = shippingDistanceManual,
    shippingDistanceCalculated = shippingDistanceCalculated,
    electricityWorkConfiguration = electricityWorkConfiguration,
    additionalPositions = additionalPositions,
    existingFacilitiesConfiguration = existingFacilitiesConfiguration,
    discountPercentage = discountPercentage,
    zaehlerNummer = zaehlerNummer,
    flurstueckNummer = flurstueckNummer,
    facilityOperator1 = facilityOperator1,
    facilityOperator2 = facilityOperator2,
    einspeiseart = einspeiseart,
    legalNoticeAdditionalLine = legalNoticeAdditionalLine,
    legalNotes = legalNotes,
    manualPowerConsumptionDistribution = manualPowerConsumptionDistribution,
    earningsDistribution = earningsDistribution,
    signedQuoteReceived = signedQuoteReceived,
    manualQuoteElements = manualQuoteElements,
    currentQuoteSnapshot = currentQuoteSnapshot,
    priceList = priceList,
    availableProducts = availableProducts,
    processState = processState,
    lifeCycleState = lifeCycleState,
  )
}

fun CompanyInformation.toQuoteCompanyInformation(): QuoteCompanyInformation {
  return QuoteCompanyInformation(
    companyProfile = companyProfile,
    name = name,
    address = address,
    contactInformation = contactInformation,
    bankInformation = bankInformation,
    legalInformation = legalInformation,
    lifeCycleState = lifeCycleState,
  )
}
