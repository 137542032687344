package services

import io.ktor.http.*
import it.neckar.commons.kotlin.js.BlobSupport
import org.w3c.files.Blob

/**
 * Creates a new blob
 */
fun BlobSupport.createBlob(content: ByteArray, contentType: ContentType): Blob {
  //The extension method is defined here, because of the ContentType import that is not available at BlobSupport
  return createBlob(content, "${contentType.contentType}/${contentType.contentSubtype}")
}
