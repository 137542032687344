@file:UseSerializers(UuidSerializer::class)

package it.neckar.processStatesClient

import it.neckar.rest.RestResponse
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers


/**
 * Response for
 */
@Serializable
sealed interface SendProcessStatesResponse : RestResponse<Boolean> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: Boolean) : SendProcessStatesResponse, RestResponse.SuccessResponse<Boolean>

  @Serializable
  @SerialName("failure")
  data object Failure : SendProcessStatesResponse, RestResponse.FailureResponse<Boolean>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(): SendProcessStatesResponse = Success(true)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): SendProcessStatesResponse = Failure
  }
}
