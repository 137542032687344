package it.neckar.lizergy.model.price

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.customer.company.NeckarITCompanyProfile
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.income.IncomePercentage
import it.neckar.lizergy.model.income.IncomePercentages
import it.neckar.lizergy.model.price.EarningsDistribution.EarningsDistributionId
import it.neckar.open.provider.DoubleProvider.Companion.nowMillis
import it.neckar.open.unit.si.ms
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

interface EarningsDistribution : HasUuid {
  val id: EarningsDistributionId
  val tippgeber: EarningsEntry
  val vertriebProjekterfassung: EarningsEntry
  val vertriebAngebotsvorstellung: EarningsEntry
  val technischePlanungDach: EarningsEntry
  val technischePlanungElektrik: EarningsEntry
  val montageDach: EarningsEntry
  val montageGeruest: EarningsEntry
  val elektroInstallation: EarningsEntry
  val netzvoranfrage: EarningsEntry
  val neckarITAccountingStatus: EarningsEntry
  val selectedForAccounting: Boolean

  override val uuid: Uuid
    get() = id.uuid

  val all: List<EarningsEntry>

  val allIncludingSoftware: List<EarningsEntry>


  @Serializable
  data class EarningsDistributionId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): EarningsDistributionId {
        return EarningsDistributionId(randomUuid4())
      }
    }
  }

}

@Serializable
enum class AccountingStatus {
  Pending,
  Accounted,
}

interface EarningsEntry {
  val company: CompanyCode
  val manualEarningsHistory: PositionEditHistory<Money?>
  val accountingStatusHistory: PositionEditHistory<AccountingStatus>
}


@Serializable
data class ResolvedEarningsDistribution(
  override val id: EarningsDistributionId,
  override val tippgeber: ResolvedCompanyEarningsEntry,
  override val vertriebProjekterfassung: ResolvedCompanyEarningsEntry,
  override val vertriebAngebotsvorstellung: ResolvedCompanyEarningsEntry,
  override val technischePlanungDach: ResolvedCompanyEarningsEntry,
  override val technischePlanungElektrik: ResolvedCompanyEarningsEntry,
  override val montageDach: ResolvedCompanyEarningsEntry,
  override val montageGeruest: ResolvedCompanyEarningsEntry,
  override val elektroInstallation: ResolvedCompanyEarningsEntry,
  override val netzvoranfrage: ResolvedCompanyEarningsEntry,
  override val neckarITAccountingStatus: ResolvedNeckarITEarningsEntry,
  override val selectedForAccounting: Boolean,
) : EarningsDistribution {

  override val all: List<ResolvedCompanyEarningsEntry>
    get() = listOf(
      tippgeber,
      vertriebProjekterfassung,
      vertriebAngebotsvorstellung,
      technischePlanungDach,
      technischePlanungElektrik,
      montageDach,
      montageGeruest,
      elektroInstallation,
      netzvoranfrage,
    )

  override val allIncludingSoftware: List<ResolvedEarningsEntry>
    get() = all + neckarITAccountingStatus

  val includesPartnerCompanies: Boolean
    get() = all.any { it.companyProfile !is MainCompanyProfile }

  val isPending: Boolean
    get() = allIncludingSoftware.any { it.accountingStatus == AccountingStatus.Pending }

  val isFullyAccounted: Boolean
    get() = allIncludingSoftware.all { it.accountingStatus == AccountingStatus.Accounted }

  val wasFullyAccountedAt: @ms Double?
    get() = if (isFullyAccounted) allIncludingSoftware.maxOf { it.accountingStatusHistory.lastEditedTime } else null

  val contributingCompanies: List<CompanyCode>
    get() = all.filter { it.companyProfile !is MainCompanyProfile }.map { it.company }.distinct()

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedEarningsDistribution {
    val newId = EarningsDistributionId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(id = newId)
  }


  companion object {
    fun getEmpty(id: EarningsDistributionId = EarningsDistributionId.random(), defaultCompany: CompanyProfile, loggedInUser: UserLoginName, now: @ms Double = nowMillis()): ResolvedEarningsDistribution {
      return ResolvedEarningsDistribution(
        id = id,
        tippgeber = ResolvedCompanyEarningsEntry(
          companyProfile = defaultCompany,
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
          incomePercentage = IncomePercentages.tippgeber,
        ),
        vertriebProjekterfassung = ResolvedCompanyEarningsEntry(
          companyProfile = defaultCompany,
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
          incomePercentage = IncomePercentages.vertriebProjekterfassung,
        ),
        vertriebAngebotsvorstellung = ResolvedCompanyEarningsEntry(
          companyProfile = defaultCompany,
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
          incomePercentage = IncomePercentages.vertriebAngebotsvorstellung,
        ),
        technischePlanungDach = ResolvedCompanyEarningsEntry(
          companyProfile = defaultCompany,
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
          incomePercentage = IncomePercentages.technischePlanungDach,
        ),
        technischePlanungElektrik = ResolvedCompanyEarningsEntry(
          companyProfile = defaultCompany,
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
          incomePercentage = IncomePercentages.technischePlanungElektrik,
        ),
        montageDach = ResolvedCompanyEarningsEntry(
          companyProfile = defaultCompany,
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
          incomePercentage = IncomePercentages.montageDach,
        ),
        montageGeruest = ResolvedCompanyEarningsEntry(
          companyProfile = defaultCompany,
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
          incomePercentage = IncomePercentages.montageGeruest,
        ),
        elektroInstallation = ResolvedCompanyEarningsEntry(
          companyProfile = defaultCompany,
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
          incomePercentage = IncomePercentages.elektroInstallation,
        ),
        netzvoranfrage = ResolvedCompanyEarningsEntry(
          companyProfile = defaultCompany,
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
          incomePercentage = IncomePercentages.netzvoranfrage,
        ),
        neckarITAccountingStatus = ResolvedNeckarITEarningsEntry(
          manualEarningsHistory = PositionEditHistory(null, loggedInUser, now),
          accountingStatusHistory = PositionEditHistory(AccountingStatus.Pending, loggedInUser, now),
        ),
        selectedForAccounting = false,
      )
    }
  }
}

@Serializable
sealed interface ResolvedEarningsEntry : EarningsEntry {
  val companyProfile: CompanyProfile
  val manualEarnings: Money?
  val accountingStatus: AccountingStatus

  override val company: CompanyCode
    get() = companyProfile.companyCode
}

@Serializable
@SerialName("Company")
data class ResolvedCompanyEarningsEntry(
  override val companyProfile: CompanyProfile,
  override val manualEarningsHistory: PositionEditHistory<Money?>,
  override val accountingStatusHistory: PositionEditHistory<AccountingStatus>,
  val incomePercentage: IncomePercentage,
) : ResolvedEarningsEntry {
  override val manualEarnings: Money?
    get() = when (companyProfile) {
      is MainCompanyProfile -> null
      else -> manualEarningsHistory.currentValue
    }

  override val accountingStatus: AccountingStatus
    get() = when (companyProfile) {
      is MainCompanyProfile -> AccountingStatus.Accounted
      else -> accountingStatusHistory.currentValue
    }
}

@Serializable
@SerialName("NeckarIT")
data class ResolvedNeckarITEarningsEntry(
  override val manualEarningsHistory: PositionEditHistory<Money?>,
  override val accountingStatusHistory: PositionEditHistory<AccountingStatus>,
) : ResolvedEarningsEntry {
  override val companyProfile: CompanyProfile
    get() = NeckarITCompanyProfile

  override val manualEarnings: Money?
    get() = manualEarningsHistory.currentValue

  override val accountingStatus: AccountingStatus
    get() = accountingStatusHistory.currentValue
}
