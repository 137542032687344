package services.storage.http

import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import serialized.SerializedSearchForProjectsResult

/**
 * Response for
 */
@Serializable
sealed interface FetchSearchForProjectsResponse : RestResponse<SerializedSearchForProjectsResult> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: SerializedSearchForProjectsResult) : FetchSearchForProjectsResponse, RestResponse.SuccessResponse<SerializedSearchForProjectsResult>

  @Serializable
  @SerialName("failure")
  data object Failure : FetchSearchForProjectsResponse, RestResponse.FailureResponse<SerializedSearchForProjectsResult>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: SerializedSearchForProjectsResult): FetchSearchForProjectsResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): FetchSearchForProjectsResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: SerializedSearchForProjectsResult?): FetchSearchForProjectsResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}
