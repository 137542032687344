package it.neckar.lizergy.model.configuration.quote.economics

import it.neckar.financial.currency.Money
import it.neckar.open.unit.currency.EUR
import kotlinx.serialization.Serializable

/**
 * Information about the invested sum and the type of financing
 */
@Serializable
data class FinancesInformation(
  /**
   * The invested sum
   */
  val invest: @EUR Money,

  /**
   * The financing type
   */
  val financing: FinancingType,
)
