import com.meistercharts.canvas.UrlConverter
import com.meistercharts.charts.lizergy.solar.LizergyDesign
import com.meistercharts.js.MeisterChartsPlatform
import it.neckar.common.featureflags.FeatureFlag
import it.neckar.common.featureflags.FeatureFlags
import it.neckar.common.featureflags.FeatureFlagsSupport
import it.neckar.common.featureflags.loadFeatureFlagsFromUrlOrLocalStorage
import it.neckar.common.featureflags.writeToLocalStorage
import it.neckar.common.featureflags.writeToUrl
import it.neckar.common.redux.dispatch
import it.neckar.commons.kotlin.js.exception.ConsoleJsErrorHandler
import it.neckar.commons.kotlin.js.exception.JsErrorHandler
import it.neckar.commons.kotlin.js.exception.JsErrorHandlerMultiplexer
import it.neckar.lizergy.PlannerClientDeploymentZone
import it.neckar.lizergy.version.LizergyVersions
import it.neckar.logging.Level
import it.neckar.logging.LogConfigurer
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.logging.console.ConsoleLogFunctionsSupport
import it.neckar.open.collections.fastForEach
import it.neckar.heartbeat.HeartbeatScope
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.guessEnvironmentMode
import it.neckar.projects.pvplanner.PvPlannerService
import it.neckar.react.common.*
import it.neckar.react.common.error.*
import it.neckar.react.common.featureflags.*
import it.neckar.react.common.redux.*
import kotlinx.coroutines.*
import react.*
import react.dom.client.*
import services.UiActions
import services.http.HeartbeatsSupport
import services.http.PlannerUiJwtTokenClientHandler
import services.http.PlannerUiServices
import services.http.getClientFromWindowLocation
import store.ServicesOnlineState
import store.actions.ErrorOccurredAction
import store.actions.LogoutReason
import store.actions.OnlineStateChangedAction
import store.initializeStore
import store.store
import store.toOnlineState
import web.html.HTMLElement

private val logger: Logger = LoggerFactory.getLogger("PlannerMain")


/**
 * Entry point for the application
 */
fun main() {
  //Register the error handler
  JsErrorHandler.registerWindowErrorHandler(
    JsErrorHandlerMultiplexer(listOf(
      ConsoleJsErrorHandler,

      //We do *not* want to show unknown exception as toasts
      //Instead we show them using an error page
      object : JsErrorHandler {
        override fun nullError(message: dynamic) {
          TODO("Not yet implemented")
        }

        override fun error(throwable: Throwable) {
          store.dispatch(ErrorOccurredAction(throwable))
        }

        override fun otherError(message: dynamic, error: Any) {
          store.dispatch(ErrorOccurredAction(RuntimeException(message.toString())))
        }
      }
    ))
  )

  LogConfigurer.initializeFromLocalStorage(Level.INFO)
  ConsoleLogFunctionsSupport.init("planner")

  logger.info("Starting Lizergy UI: ${LizergyVersions.gitVersionAsStringVerbose}")
  logger.info("Environment Mode: ${guessEnvironmentMode()}")

  //Initialize the deployment zone - first
  val deploymentZone: PlannerClientDeploymentZone = PlannerClientDeploymentZone.getClientFromWindowLocation()
  logger.info("Deployment Zone: $deploymentZone")
  initializeStore(deploymentZone)

  initializeFeatureFlags(deploymentZone)
  logger.debug("Active Feature Flags: ${FeatureFlagsSupport.flags}")


  PlannerUiServices.initialize(deploymentZone, PlannerUiJwtTokenClientHandler(store))


  logger.debug("DeploymentZone: ${deploymentZone.name}")
  logger.debug("Service URLs:")
  PvPlannerService.entriesInProduction.fastForEach { service ->
    logger.debug("\t$service: ${PlannerUiServices.urlSupport.getForService(service).baseUrl}")
  }

  logger.debug("Initializing MeisterCharts")
  MeisterChartsPlatform.init(
    theme = LizergyDesign,
    defaultI18nConfiguration = plannerI18nConfiguration,
    urlConverter = UrlConverter.MakeAbsolute
  )
  logger.debug("MeisterCharts initialized successfully")


  logger.info(
    """PV Planner:
        _______  __   __    _______  ___      _______  __    _  __    _  _______  ______
       |       ||  | |  |  |       ||   |    |   _   ||  |  | ||  |  | ||       ||    _ |
       |    _  ||  |_|  |  |    _  ||   |    |  |_|  ||   |_| ||   |_| ||    ___||   | ||
       |   |_| ||       |  |   |_| ||   |    |       ||       ||       ||   |___ |   |_||_
       |    ___||       |  |    ___||   |___ |       ||  _    ||  _    ||    ___||    __  |
       |   |     |     |   |   |    |       ||   _   || | |   || | |   ||   |___ |   |  | |
       |___|      |___|    |___|    |_______||__| |__||_|  |__||_|  |__||_______||___|  |_|
    """.trimIndent()
  )

  HeartbeatScope.launch {
    PvPlannerService.entriesInProduction.fastForEach { service ->
      val heartbeatSupport = HeartbeatsSupport[service]

      logger.debug("Initializing heartbeat service @ $service")

      //Initialize heartbeat jobs
      launch {
        heartbeatSupport.heartbeatFlow.collect { heartbeatState ->
          val oldOnlineState: ServicesOnlineState = store.state.onlineState
          val newOnlineState: ServicesOnlineState = oldOnlineState.with(service, heartbeatState.toOnlineState())

          //logger.debug {
          //  "Heartbeat state received for $service: ${heartbeatState}"
          //}

          if (oldOnlineState != newOnlineState) {
            //logger.debug("heartbeatState for $service: $heartbeatState -- ${heartbeatState.isServiceAvailable}, isVersionMismatch: ${heartbeatState.isVersionMismatch}")
            dispatch(OnlineStateChangedAction(newOnlineState))
          }
        }
      }
    }
  }

  //Start the React app
  val rootDiv: HTMLElement = web.dom.document.getElementById("root") ?: error("Element root not found")
  createRoot(rootDiv).render(createElement<Props> {
    PlannerRoot {}
  })
}

private fun initializeFeatureFlags(deploymentZone: PlannerClientDeploymentZone) {
  val loadedFeatureFlags: FeatureFlags = loadFeatureFlagsFromUrlOrLocalStorage() ?: FeatureFlags.empty
  logger.debug("loadedFeatureFlags: $loadedFeatureFlags")

  val featureFlags: FeatureFlags = if (deploymentZone.isLocalhost() && loadedFeatureFlags.flags.isEmpty()) {
    logger.debug("Adding ${FeatureFlag.disableVersionCheck}")

    // Add feature flag "disable version check" on localhost
    loadedFeatureFlags + FeatureFlag.disableVersionCheck
  } else {
    //Assign directly
    loadedFeatureFlags
  }

  //Set the feature flags to the global support
  FeatureFlagsSupport.flags = featureFlags
  FeatureFlagsSupport.flags.writeToUrl() //Update the URL (might be redundant)
  FeatureFlagsSupport.flags.writeToLocalStorage() //Update local storage (might be redundant)
}

val PlannerRoot: FC<Props> = fc("PlannerRoot") {
  logger.debug("Render PlannerRoot")

  //ATTENTION: Strict Mode results in double renders!!!
  //StrictMode {
  errorBoundary(
    errorComponentComponent = { throwable, errorInfo ->
      FallbackErrorPage.create {
        this.throwable = throwable
        this.errorInfo = errorInfo

        this.contactEmail = "planner-support@neckar.it"
        this.contactPhoneNumber = "07473 959 49 60"

        this.logoutAction = {
          UiActions.logout(LogoutReason.UserInteraction)
        }

      }
    }) {

    FeatureFlagsContextComponent {
      //This component throws an exception if the corresponding feature flag is set
      BuggyComponentFromFeatureFlags {
        attrs {
          featureFlag = FeatureFlag.throwException
        }
      }

      //Provides the store. Use the hook useSelector() to access the store
      provider(store) {
        PlannerMainComponent {}
      }

    }
  }
}

/**
 * The locale that should be used
 *
 * //TODO move to state(?)
 */
val plannerI18nConfiguration: I18nConfiguration = I18nConfiguration.Germany
