package it.neckar.financial.quote

import com.benasher44.uuid.Uuid
import it.neckar.commons.tags.Tag
import it.neckar.commons.tags.Tags
import it.neckar.commons.tags.toTagsOrEmpty
import it.neckar.financial.currency.PriceWithProfit
import it.neckar.financial.currency.PriceWithProfitAndWorkingTime
import it.neckar.financial.currency.ValueAddedTax
import it.neckar.uuid.randomUuid4
import kotlin.time.DurationUnit

/**
 * Builder for a quote section
 */
@QuoteDsl
class QuoteSectionBuilder(
  var headline: String,
  var visibility: Visibility = Visibility.Public,
  var amount: Amount? = null,
) : QuoteElementBuilder {
  var uuid: Uuid = randomUuid4()

  val children: MutableList<QuoteElementBuilder> = mutableListOf()

  var details: String? = null

  fun add(quoteElementBuilder: QuoteElementBuilder) {
    this.children.add(quoteElementBuilder)
  }

  /**
   * Allows addition of children using the DSL
   */
  @QuoteDsl
  operator fun invoke(configuration: QuoteSectionBuilder.() -> Unit) {
    this.apply(configuration)
  }

  override fun build(): QuoteSection {
    return QuoteSection(
      uuid = uuid,
      headline = headline,
      amount = amount,
      visibility = visibility,
      details = details,
      children = children.map { it.build() },
    )
  }


  /**
   * Creates a new [QuoteItemBuilder] - with the minimal fields
   */
  @QuoteDsl
  fun item(
    /**
     * Headline for the [QuoteItem]
     */
    headline: String,
    /**
     * Price for only the material
     */
    priceForOneElement: PriceWithProfit,

    amount: Double = 1.0,

    relevance: Tags,

    visibility: Visibility,

    valueAddedTax: ValueAddedTax,
    /**
     * The configuration for the builder
     */
    config: QuoteItemBuilder.() -> Unit = {},
  ): QuoteItemBuilder {
    return quoteItem(headline, priceForOneElement, amount, relevance, visibility, Optionality.Mandatory, valueAddedTax, config)
      .also {
        add(it)
      }
  }

  /**
   * Creates a new quote item builder - with the minimal fields
   */
  @QuoteDsl
  fun item(headline: String, priceForOneElement: PriceWithProfit, amount: Double = 1.0, relevance: Tag, visibility: Visibility, valueAddedTax: ValueAddedTax, config: QuoteItemBuilder.() -> Unit = {}): QuoteItemBuilder {
    return item(headline, priceForOneElement, amount, relevance.toTagsOrEmpty(), visibility, valueAddedTax, config)
  }

  /**
   * Creates an optional item - depending on the optional state
   */
  @QuoteDsl
  fun optionalItem(
    /**
     * If the item shall be:
     * * not be created
     * * mandatory
     * * optional
     */
    configuredOptionality: ConfiguredOptionality,

    /**
     * Headline for the [QuoteItem]
     */
    headline: String,
    /**
     * Price for only the material
     */
    priceForOneElement: PriceWithProfit,

    amount: Double = 1.0,

    relevance: Tags,

    visibility: Visibility,

    valueAddedTax: ValueAddedTax,
    /**
     * The configuration for the builder
     */
    config: QuoteItemBuilder.() -> Unit = {},
  ): QuoteItemBuilder? {

    if (configuredOptionality == ConfiguredOptionality.NotSelected) {
      return null
    }

    return quoteItem(
      headline = headline,
      priceForOneElement = priceForOneElement,
      amount = amount,
      relevance = relevance,
      visibility = visibility,
      optionality = configuredOptionality.toOptionality(),
      valueAddedTax = valueAddedTax,
      config = config,
    )
      .also {
        add(it)
      }
  }

  @QuoteDsl
  fun optionalItem(
    configuredOptionality: ConfiguredOptionality,
    headline: String,
    priceForOneElement: PriceWithProfit,
    amount: Double = 1.0,
    relevance: Tag,
    visibility: Visibility,
    valueAddedTax: ValueAddedTax,
    config: QuoteItemBuilder.() -> Unit = {},
  ): QuoteItemBuilder? {
    return optionalItem(configuredOptionality, headline, priceForOneElement, amount, relevance.toTagsOrEmpty(), visibility, valueAddedTax, config)
  }


  @QuoteDsl
  fun itemWithWork(
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Int = 1,
    materialRelevance: Tags,
    workRelevance: Tags,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    materialConfig: QuoteItemBuilder.() -> Unit = {},
    workConfig: QuoteItemBuilder.() -> Unit = {},
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return quoteSectionBuilder(
      headline = headline,
      amount = Amount(amount),
      visibility = visibility,
      config = config,
    ).also { sectionBuilder ->
      add(sectionBuilder)

      sectionBuilder {
        item(
          headline = "$headline: Materialkosten",
          priceForOneElement = priceForMaterialAndWork.materialPrice,
          relevance = materialRelevance,
          visibility = Visibility.Internal,
          valueAddedTax = materialValueAddedTax,
        ) {
          this.amount = Amount(amount)
          materialConfig(this)
        }

        item(
          headline = "$headline: Arbeitszeit",
          priceForOneElement = priceForMaterialAndWork.workingTimes.workingHourPrice.priceWithProfit,
          relevance = workRelevance,
          visibility = Visibility.Internal,
          valueAddedTax = workValueAddedTax,
        ) {
          val workingHour = Amount(priceForMaterialAndWork.workingTimes.workingTime.toDouble(DurationUnit.HOURS), AmountUnit.Hours)
          this.amount = workingHour * amount
          workConfig(this)
        }
      }

    }
  }

  @QuoteDsl
  fun itemWithWork(
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Double,
    materialRelevance: Tags,
    workRelevance: Tags,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    materialConfig: QuoteItemBuilder.() -> Unit = {},
    workConfig: QuoteItemBuilder.() -> Unit = {},
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return quoteSectionBuilder(
      headline = headline,
      visibility = visibility,
      amount = Amount(amount),
      config = config,
    ).also { sectionBuilder ->
      add(sectionBuilder)

      sectionBuilder {
        item(
          headline = "$headline: Materialkosten",
          priceForOneElement = priceForMaterialAndWork.materialPrice,
          relevance = materialRelevance,
          visibility = Visibility.Internal,
          valueAddedTax = materialValueAddedTax,
        ) {
          this.amount = Amount(amount)
          materialConfig(this)
        }

        priceForMaterialAndWork.workingTimes.let { timesWithPrice ->
          item(
            headline = "$headline: Arbeitszeit",
            priceForOneElement = timesWithPrice.workingHourPrice.priceWithProfit,
            relevance = workRelevance,
            visibility = Visibility.Internal,
            valueAddedTax = workValueAddedTax,
          ) {
            this.amount = Amount(timesWithPrice.workingTime.toDouble(DurationUnit.HOURS) * amount, AmountUnit.Hours)
            workConfig(this)
          }
        }
      }

    }
  }

  @QuoteDsl
  fun itemWithWork(
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Int = 1,
    materialRelevance: Tag,
    workRelevance: Tag,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    materialConfig: QuoteItemBuilder.() -> Unit = {},
    workConfig: QuoteItemBuilder.() -> Unit = {},
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return itemWithWork(headline, priceForMaterialAndWork, amount, materialRelevance.toTagsOrEmpty(), workRelevance.toTagsOrEmpty(), visibility, materialValueAddedTax, workValueAddedTax, materialConfig, workConfig, config)
  }

  @QuoteDsl
  fun itemWithWork(
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Double,
    materialRelevance: Tag,
    workRelevance: Tag,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    materialConfig: QuoteItemBuilder.() -> Unit = {},
    workConfig: QuoteItemBuilder.() -> Unit = {},
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return itemWithWork(headline, priceForMaterialAndWork, amount, materialRelevance.toTagsOrEmpty(), workRelevance.toTagsOrEmpty(), visibility, materialValueAddedTax, workValueAddedTax, materialConfig, workConfig, config)
  }

  @QuoteDsl
  fun itemWithWork(
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Int = 1,
    materialRelevance: Tag,
    workRelevance: Tags,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    materialConfig: QuoteItemBuilder.() -> Unit = {},
    workConfig: QuoteItemBuilder.() -> Unit = {},
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return itemWithWork(headline, priceForMaterialAndWork, amount, materialRelevance.toTagsOrEmpty(), workRelevance, visibility, materialValueAddedTax, workValueAddedTax, materialConfig, workConfig, config)
  }

  @QuoteDsl
  fun itemWithWork(
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Int = 1,
    materialRelevance: Tags,
    workRelevance: Tag,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    materialConfig: QuoteItemBuilder.() -> Unit = {},
    workConfig: QuoteItemBuilder.() -> Unit = {},
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return itemWithWork(headline, priceForMaterialAndWork, amount, materialRelevance, workRelevance.toTagsOrEmpty(), visibility, materialValueAddedTax, workValueAddedTax, materialConfig, workConfig, config)
  }

  @QuoteDsl
  fun optionalItemWithWork(
    configuredOptionality: ConfiguredOptionality,
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Int = 1,
    materialRelevance: Tags,
    workRelevance: Tags,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return quoteSectionBuilder(
      headline = headline,
      visibility = visibility,
      amount = Amount(amount),
      config = config,
    ).also { sectionBuilder ->
      add(sectionBuilder)

      sectionBuilder {
        optionalItem(
          configuredOptionality = configuredOptionality,
          headline = "$headline: Materialkosten",
          priceForOneElement = priceForMaterialAndWork.materialPrice,
          relevance = materialRelevance,
          visibility = Visibility.Internal,
          valueAddedTax = materialValueAddedTax,
        ) {
          this.amount = Amount(amount)
        }

        optionalItem(
          configuredOptionality = configuredOptionality,
          headline = "$headline: Arbeitszeit",
          priceForOneElement = priceForMaterialAndWork.workingTimes.workingHourPrice.priceWithProfit,
          relevance = workRelevance,
          visibility = Visibility.Internal,
          valueAddedTax = workValueAddedTax,
        ) {
          val workingHour = Amount(priceForMaterialAndWork.workingTimes.workingTime.toDouble(DurationUnit.HOURS), AmountUnit.Hours)
          this.amount = workingHour * amount
        }
      }
    }
  }

  @QuoteDsl
  fun optionalItemWithWork(
    configuredOptionality: ConfiguredOptionality,
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Int = 1,
    materialRelevance: Tag,
    workRelevance: Tag,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return optionalItemWithWork(configuredOptionality, headline, priceForMaterialAndWork, amount, materialRelevance.toTagsOrEmpty(), workRelevance.toTagsOrEmpty(), visibility, materialValueAddedTax, workValueAddedTax, config)
  }

  @QuoteDsl
  fun optionalItemWithWork(
    configuredOptionality: ConfiguredOptionality,
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Int = 1,
    materialRelevance: Tag,
    workRelevance: Tags,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return optionalItemWithWork(configuredOptionality, headline, priceForMaterialAndWork, amount, materialRelevance.toTagsOrEmpty(), workRelevance, visibility, materialValueAddedTax, workValueAddedTax, config)
  }

  @QuoteDsl
  fun optionalItemWithWork(
    configuredOptionality: ConfiguredOptionality,
    headline: String,
    priceForMaterialAndWork: PriceWithProfitAndWorkingTime,
    amount: Int = 1,
    materialRelevance: Tags,
    workRelevance: Tag,
    visibility: Visibility,
    materialValueAddedTax: ValueAddedTax,
    workValueAddedTax: ValueAddedTax,
    config: QuoteSectionBuilder.() -> Unit = {},
  ): QuoteSectionBuilder {
    return optionalItemWithWork(configuredOptionality, headline, priceForMaterialAndWork, amount, materialRelevance, workRelevance.toTagsOrEmpty(), visibility, materialValueAddedTax, workValueAddedTax, config)
  }


  /**
   * Adds a new quote section
   */
  @QuoteDsl
  fun section(headline: String, visibility: Visibility, config: QuoteSectionBuilder.() -> Unit): QuoteSectionBuilder {
    return quoteSectionBuilder(headline = headline, visibility = visibility, config = config).also {
      add(it)
    }
  }

}

private fun ConfiguredOptionality.toOptionality(): Optionality {
  return when (this) {
    ConfiguredOptionality.Selected -> Optionality.Mandatory
    ConfiguredOptionality.Optional -> Optionality.Optional
    ConfiguredOptionality.NotSelected -> error("Can not convert $this to Optionality")
  }
}

/**
 * Creates a new quote section builder.
 */
fun quoteSectionBuilder(headline: String, visibility: Visibility? = null, amount: Amount? = null, config: QuoteSectionBuilder.() -> Unit): QuoteSectionBuilder {
  return QuoteSectionBuilder(headline)
    .also { sectionBuilder ->
      visibility?.let { sectionBuilder.visibility = it }
      amount?.let { sectionBuilder.amount = it }
    }
    .also(config)
}
