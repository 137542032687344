package components.project.configuration.layout

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.configuration.isConfigurationEditable
import components.project.freezableEditButton
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.router.*
import kotlinx.html.title
import react.*
import react.dom.*
import react.router.dom.*
import router.RouterUrls
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useSelectAvailableProducts

val ConfigurationModuleLayoutsOverviewFromUrl: FC<Props> = fc("ConfigurationModuleLayoutsOverviewFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Modulflächen", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    ConfigurationModuleLayoutsOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val ConfigurationModuleLayoutsOverview: FC<QuoteConfigurationTabProps> = fc("ConfigurationModuleLayoutsOverview") { props ->
  val navigate = useNavigateUrl()
  val availableProducts = useSelectAvailableProducts()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()


  div("mb-5") {
    h3("mb-2") {
      +"Bemerkungen Dachflächen"
    }
    project.blueprint.roofsAnnotation?.let {
      p { +it }
    }
    commentSection(quoteConfiguration.moduleLayouts)
  }

  ModuleLayoutsOverview {
    attrs {
      this.project = project
      this.quoteConfiguration = quoteConfiguration
      this.editableStatus = editableStatus

      val projectUrl = RouterUrls.project(project)

      this.addAction = {
        UiActions.addModuleLayout(project, quoteConfiguration, availableProducts).also { newModuleLayout ->
          navigate(projectUrl.configuration(quoteConfiguration).moduleLayout(newModuleLayout.id))
        }
      }
      this.editAction = { moduleLayout ->
        navigate(projectUrl.configuration(quoteConfiguration).moduleLayout(moduleLayout.id))
      }
      this.copyAction = { moduleLayout ->
        UiActions.duplicateModuleLayout(project, quoteConfiguration, moduleLayout).also { newModuleLayout ->
          navigate(projectUrl.configuration(quoteConfiguration).moduleLayout(newModuleLayout.id))
        }
      }
      this.deleteAction = { moduleLayout ->
        UiActions.removeModuleLayout(project, quoteConfiguration, moduleLayout)
      }

    }
  }

}

val ModuleLayoutsOverview: FC<ModuleLayoutsOverviewProps> = fc("ModuleLayoutsOverview") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val addAction = props::addAction.safeGet()
  val editAction = props::editAction.safeGet()
  val copyAction = props::copyAction.safeGet()
  val deleteAction = props::deleteAction.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val moduleLayouts = quoteConfiguration.moduleLayouts


  div("my-3") {
    actionButtonWithConfirmationModal(
      icon = FontAwesomeIcons.add,
      buttonClasses = "btn btn-primary",
      buttonContent = {
        attrs {
          disabled = editableStatus == ReadOnly
        }
        span("ps-2") { +"Hinzufügen" }
      },
      modalTitle = "Neue Modulfläche anlegen?",
    ) {
      addAction()
    }
  }

  div("row row-cols-lg-2 row-cols-1 g-4") {
    moduleLayouts.validElements.fastForEach { moduleLayout ->

      div("col") {
        div("card h-100") {
          div("card-body") {
            attrs {
              if (editableStatus == ReadOnly) {
                title = "Dieses Angebot kann nicht weiter bearbeitet werden!"
              }
            }
            h2("card-title mt-0 mb-3") {
              Link {
                +moduleLayout.layoutName

                attrs {
                  toUrl = RouterUrls.project(project).configuration(quoteConfiguration).moduleLayout(moduleLayout)
                }
              }

              div(classes = "btn-group") {

                freezableEditButton(editableStatus) {
                  editAction(moduleLayout)
                }

                actionButtonWithConfirmationModal(
                  icon = FontAwesomeIcons.copy,
                  buttonContent = {
                    attrs {
                      disabled = editableStatus == ReadOnly
                    }
                  },
                  modalTitle = "Soll die Modulfläche dupliziert werden?",
                  modalContent = {
                    +"Die Dachausrichtung wird hierbei um 180° gedreht."
                  },
                ) {
                  copyAction(moduleLayout)
                }

                actionButtonWithConfirmationModal(
                  icon = FontAwesomeIcons.trash,
                  buttonContent = {
                    attrs {
                      disabled = editableStatus == ReadOnly
                    }
                  },
                  modalTitle = "Soll die Modulfläche gelöscht werden?",
                ) {
                  deleteAction(moduleLayout)
                }

              }
            }

            moduleLayoutPreview(moduleLayout)
          }
        }
      }
    }

  }

  p("form-text mt-3") {
    +"Gerüst für alle Dächer: ${moduleLayouts.scaffoldingAreas().values.sum()} m²"
  }

}


external interface ModuleLayoutsOverviewProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
  var addAction: () -> Unit
  var editAction: (resolvedModuleLayoutToSave: ResolvedModuleLayout) -> Unit
  var copyAction: (resolvedModuleLayoutToSave: ResolvedModuleLayout) -> Unit
  var deleteAction: (resolvedModuleLayoutToSave: ResolvedModuleLayout) -> Unit
  var editableStatus: EditableStatus
}
