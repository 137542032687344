package it.neckar.lizergy.model.configuration.energy.selfsufficiency

import it.neckar.datetime.minimal.Year
import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration
import it.neckar.lizergy.model.configuration.energy.AmountOfEnergy
import it.neckar.lizergy.model.configuration.energy.BatteryCapacity
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.power.PowerUsageScenario
import it.neckar.lizergy.model.configuration.energy.power.PricesTrendScenario
import it.neckar.lizergy.model.configuration.quote.EnvironmentSavings
import it.neckar.lizergy.model.configuration.quote.economics.EconomicsReport
import it.neckar.lizergy.model.configuration.quote.economics.FeedInPrices
import it.neckar.lizergy.model.configuration.quote.economics.FinancesInformation
import it.neckar.lizergy.model.configuration.quote.economics.FinancingType
import it.neckar.lizergy.model.configuration.quote.economics.YearlyCostInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.AmortisationInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.CashFlow
import it.neckar.lizergy.model.configuration.quote.economics.simple.EnergyInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.FinancingInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.SimpleProfitabilityTable
import it.neckar.lizergy.model.configuration.quote.economics.simple.TariffInformation
import it.neckar.lizergy.model.configuration.quote.economics.simple.increasingBy
import it.neckar.lizergy.model.price.PriceList
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import it.neckar.open.unit.time.pa


/**
 * Calculates the best battery configuration
 */
object BatterySizeRecommendationCalculator {
  /**
   * Returns the optimal battery configuration.
   * Will return null if no battery configuration is the best option.
   */
  fun calculateRecommendedBatterySize(
    powerUsageScenario: PowerUsageScenario,
    operatingCostsIncreasePercentage: @pct Double,
    pricesTrendScenario: PricesTrendScenario,
    totalPowerRating: PowerRating,
    totalAnnualProduction: @pa AmountOfEnergy,
    investmentWithoutBattery: @EUR Money,
    financing: FinancingType,
    availableBatteryConfigurations: List<BatteryConfiguration>,
    priceList: PriceList,
  ): BatteryConfiguration? {
    // Find the best result - *may* be a case *without* any battery configuration
    return availableBatteryConfigurations.plus(null)
      .maxByOrNull { batteryConfiguration: BatteryConfiguration? ->

        val calculatedPowerConsumptionDistribution = InterpolatedSelfSufficiencyCalculator.calculatePowerConsumptionDistribution(
          totalAnnualProduction = totalAnnualProduction,
          batteryCapacity = batteryConfiguration?.totalCapacity ?: BatteryCapacity.Zero,
          annualElectricityConsumption = powerUsageScenario.yearlyPowerConsumptionValue,
        ) ?: CalculatedPowerConsumptionDistribution(0.0, 0.0)

        val powerConsumptionProvidedByPv = powerUsageScenario.yearlyPowerConsumptionValue * calculatedPowerConsumptionDistribution.selfProducedPercentage
        val financesInformation = FinancesInformation(
          invest = batteryConfiguration?.let { priceList[it].sellingPrice }?.plus(investmentWithoutBattery) ?: investmentWithoutBattery,
          financing = financing,
        )

        val operatingCosts = priceList.monitoringPrices[totalPowerRating, batteryConfiguration != null].sellingPrice
        val guaranteedFeedInPrice = pricesTrendScenario.guaranteedFeedInPrice ?: FeedInPrices[totalPowerRating]
        val profitabilityTableCalculator = SimpleProfitabilityTable.Calculator(
          baseYear = Year(2023),
          energyInformationCalculator = EnergyInformation.Calculator(initialProduction = totalAnnualProduction, ownConsumption = powerConsumptionProvidedByPv),
          cashFlowCalculator = CashFlow.Calculator(operatingCosts.increasingBy(operatingCostsIncreasePercentage)),
          tariffInformationCalculator = TariffInformation.Calculator(feedInGuaranteedPriceFor20Years = guaranteedFeedInPrice, consumptionPrice = pricesTrendScenario.consumptionPriceAsIncreasing()),
          financingInformationCalculator = FinancingInformation.Calculator.invoke(financesInformation),
          amortisationInformationCalculator = AmortisationInformation.Calculator(financesInformation.invest),
        )

        val yearlyCostsWithNewBattery = YearlyCostInformation(
          operatingCosts = operatingCosts,
          operatingCostsIncreasePercentage = operatingCostsIncreasePercentage,
        )
        val profitabilityTable = profitabilityTableCalculator.calculateTable()
        val environmentSavings = EnvironmentSavings.calculate(profitabilityTable.firstYearProduction())

        val economicsReport = EconomicsReport(
          powerUsageScenario = powerUsageScenario,
          pricesTrendScenario = pricesTrendScenario,
          yearlyCostInformation = yearlyCostsWithNewBattery,
          finances = financesInformation,
          environmentSavings = environmentSavings,
          profitabilityTable = profitabilityTable,
        )

        economicsReport.totalProfit
      }
  }
}
