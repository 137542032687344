package components.project.configuration.profitability

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.economics.EconomicsReport
import it.neckar.open.formatting.formatAsPercentage
import plannerI18nConfiguration
import react.*
import react.dom.*

fun RBuilder.profitability(quoteConfiguration: QuoteConfiguration): Unit = child(Profitability) {
  attrs {
    this.quoteConfiguration = quoteConfiguration
  }
}


val Profitability: FC<ProfitabilityProps> = fc("Profitability") { props ->
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val economicsReport = quoteConfiguration.economicsReport
  val quoteElements = quoteConfiguration.quoteElements


  h5("mt-2") {
    +"Wirtschaftlichkeit"
  }

  p {
    +"Cashflow im ersten Monat: ${economicsReport.savingsFirstMonth.format()}"
  }
  p {
    +"Gewinn nach 20 Jahren: ${economicsReport.totalProfit.format()}"
  }

  hr("my-3") { }

  h5("mt-2") {
    +"Kosten"
  }

  p {
    +"Netto: ${quoteElements.netPricesForVats().total.format(plannerI18nConfiguration)}"
  }
  p {
    +"Brutto: ${quoteElements.grossPricesForVats().total.format(plannerI18nConfiguration)}"
  }

  profitabilityPercentage(quoteConfiguration)
}

/**
 * Short profitability
 */
fun RBuilder.profitabilityPercentage(quoteConfiguration: QuoteConfiguration): Unit = child(ProfitabilityPercentage) {
  attrs {
    this.quoteConfiguration = quoteConfiguration
  }
}

val ProfitabilityPercentage: FC<ProfitabilityProps> = fc("ProfitabilityPercentage") { props ->
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val economicsReport: EconomicsReport = quoteConfiguration.economicsReport
  val totalProfit = economicsReport.totalProfit
  val initialInvestment = quoteConfiguration.initialInvestment


  div("form-text") {

    p {
      b { +"Kilowatt Peak Preis: " }
      +quoteConfiguration.kiloWattPeakPrice.format()
    }

    p {
      b { +(totalProfit / initialInvestment).formatAsPercentage() }
      +" (Profit nach 20 Jahren: ${totalProfit.format()}, Investment: ${initialInvestment.format()})"
    }

  }
}

external interface ProfitabilityProps : Props {
  var quoteConfiguration: QuoteConfiguration
}
