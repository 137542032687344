package components.project.customer

import components.project.configuration.location.locationForm
import components.project.editor.editorForm
import it.neckar.customer.Address
import it.neckar.customer.Customer
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.configuration.energy.SpecificAnnualProduction
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.location.MapCoordinatesInformation
import it.neckar.lizergy.model.project.ResolvedBlueprint
import it.neckar.open.kotlin.lang.nullIfEmpty
import it.neckar.open.time.nowMillis
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.toHome
import router.useDocumentTitle
import services.UiActions
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectPriceList
import store.hooks.useSelectUsersAndCompanies

/**
 * A form that edits the customer detail of a project
 */
val CreateProject: FC<CreateCustomerProps> = fc("CreateProject") {
  val navigate = useNavigateUrl()
  val usersAndCompanies = useSelectUsersAndCompanies()
  val loggedInUser = useRequireLoggedInUser()
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()
  val companyNameSimple = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyNameSimple, "Kundenerfassung")

  val forCompanyState = useState(loggedInUser.company.companyCode)
  val defaultLoginName = usersAndCompanies.defaultUserForCompany(loggedInUser.company).loginName

  val firstName = useState("")
  val lastName = useState("")

  val addressStreet = useState("")
  val addressHouseNumber = useState("")
  val addressZipCode = useState("")
  val addressCity = useState("")

  val phone = useState("")
  val cellphone = useState("")
  val email = useState("")

  val companyName = useState("")

  val customerWish = useState("")

  val locationAddressStreet = useState("")
  val locationAddressHouseNumber = useState("")
  val locationAddressZipCode = useState("")
  val locationAddressCity = useState("")

  val editorLoginNameState = useState(defaultLoginName)

  val coordinatesFromService = useState<MapCoordinatesInformation?>(null)

  val acquisitionTimeState = useState(nowMillis())

  val addressToSave = useMemo(addressStreet.value, addressHouseNumber.value, addressZipCode.value, addressCity.value) {
    Address(
      street = addressStreet.value,
      houseNumber = addressHouseNumber.value,
      zipCode = addressZipCode.value,
      city = addressCity.value,
    )
  }

  val customerToSave = useMemo(firstName.value, lastName.value, addressToSave, phone.value, cellphone.value, email.value, companyName.value, customerWish.value) {
    Customer(
      id = Customer.CustomerId.random(),
      firstName = firstName.value,
      lastName = lastName.value,
      address = addressToSave,
      phone = phone.value.nullIfEmpty(),
      cellphone = cellphone.value.nullIfEmpty(),
      email = email.value.nullIfEmpty(),
      company = companyName.value.nullIfEmpty(),
      customerWish = customerWish.value.nullIfEmpty(),
    )
  }

  val locationAddressToSave = useMemo(locationAddressStreet.value, locationAddressHouseNumber.value, locationAddressZipCode.value, locationAddressCity.value) {
    Address(
      street = locationAddressStreet.value,
      houseNumber = locationAddressHouseNumber.value,
      zipCode = locationAddressZipCode.value,
      city = locationAddressCity.value,
    )
  }

  val locationInformationToSave = useMemo(locationAddressToSave, coordinatesFromService.value) {
    LocationInformation(
      address = locationAddressToSave,
      coordinates = coordinatesFromService.value,
      specificAnnualProduction = SpecificAnnualProduction.LizergyDefault,
    )
  }

  val maintainerToSave = useMemo(usersAndCompanies, editorLoginNameState.value) {
    usersAndCompanies[editorLoginNameState.value]
  }

  val blueprintToSave = useMemo(forCompanyState.value, locationInformationToSave, acquisitionTimeState.value) {
    ResolvedBlueprint.createDefault(
      sellingCompany = usersAndCompanies[forCompanyState.value],
      location = locationInformationToSave,
      moduleType = availableProducts.availableModules().first(),
      roofType = availableProducts.availableRoofTypes().first(),
      assemblyDifficulty = priceList.defaultAssemblyDifficulty,
      acquisitionDate = acquisitionTimeState.value,
    )
  }

  val okAction = useCallback(customerToSave, maintainerToSave, blueprintToSave) {
    UiActions.createProjectForCustomer(
      customer = customerToSave,
      maintainer = maintainerToSave,
      blueprint = blueprintToSave,
      loggedInUser = loggedInUser,
    )

    //navigate to home - not the project - workflow has finished.
    navigate.toHome()
  }


  useEffect(locationAddressToSave) {
    UiActions.coordinatesForAddress(locationAddressToSave) {
      coordinatesFromService.setter(it)
    }
  }


  div("container p-4") {
    div {
      onEnter(okAction)

      h1 {
        +"Kundenerfassung"
      }

      customerForm(
        firstName = firstName,
        lastName = lastName,
        addressStreet = addressStreet,
        addressHouseNumber = addressHouseNumber,
        addressZipCode = addressZipCode,
        addressCity = addressCity,
        phone = phone,
        cellphone = cellphone,
        email = email,
        companyName = companyName,
        editableStatus = Editable,
      )

      h2 {
        +"Objekt Standort"
      }

      locationForm(
        addressStreet = locationAddressStreet,
        addressHouseNumber = locationAddressHouseNumber,
        addressZipCode = locationAddressZipCode,
        addressCity = locationAddressCity,
        customerAddress = addressToSave,
        mapCoordinates = coordinatesFromService.value?.coords,
        editableStatus = Editable,
      )

      div("my-5") {
        floatingInputArea(
          valueAndSetter = customerWish,
          fieldName = "customerWish",
          title = "Kundenwunsch Zusammenfassung",
        ) {
          attrs {
            addClass("height-300px")
          }
        }
      }

      div("row my-3") {
        div("col-6") {
          h2("mb-4") {
            +"Erfassungsdatum"
          }
          datePicker(acquisitionTimeState)
        }
      }

      h1("mb-3") {
        +"Zuweisung Vertrieb"
      }

      div("col-6") {
      editorForm(
        editorTitle = "Vertriebler",
        forCompany = forCompanyState,
        loginName = editorLoginNameState,
        editableStatus = Editable,
      )
      }


      formButtons(
        okIcon = ButtonIcon.left(FontAwesomeIcons.addUser),
        okText = "Neuen Kunden anlegen",
        cancelAction = { navigate.toHome() },
        okAction = { okAction() },
      )
    }
  }

}

external interface CreateCustomerProps : Props {
  var forCompany: CompanyCode
}
