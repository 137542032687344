package services.auth.http

import it.neckar.user.UserLoginName
import kotlinx.serialization.Serializable

/**
 * The request to change the password for a user
 */
@Serializable
data class ChangePasswordRequest(
  val loginName: UserLoginName,
  //val oldPassword: String,
  val newPassword: String,
)
