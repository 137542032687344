package it.neckar.heartbeat

import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.logging.warn
import it.neckar.open.unit.si.ms
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.*
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

/**
 * Support heartbeat to a service
 */
class ServiceHeartbeatSupport(
  /**
   * Will be called regularly.
   */
  val heartbeatCheck: HeartbeatCheck,

  /**
   * The delay between two heartbeat checks
   */
  delayBetweenChecks: Duration = 30.seconds,

  /**
   * The timeout for each check. If the check takes longer than this, it is canceled and [HeartbeatState.Dead] emitted.
   */
  timeout: @ms Duration = 5.seconds,

  /**
   * Is used to with [flowOn] when creating the [heartbeatFlow]
   */
  private val heartbeatFlowDispatcher: CoroutineDispatcher = Dispatchers.Default,

  /**
   * Is used with [shareIn] when creating the [heartbeatFlow]
   */
  private val heartbeatFlowShareScope: CoroutineScope = CoroutineScope(Dispatchers.Default),

  ) {

  /**
   * The logger for this specific heartbeat instance!
   */
  val logger: Logger = LoggerFactory.getLogger("heartbeat-$heartbeatCheck.description")

  init {
    logger.info("Creating ServiceHeartbeatSupport for [$heartbeatCheck.description]")
  }

  /**
   * The heartbeat
   */
  internal val heartbeat: Heartbeat = Heartbeat(
    delayBetweenChecks = delayBetweenChecks,
    timeout = timeout,
  ) {
    logger.debug("Checking heartbeat for [$heartbeatCheck.description]")

    try {
      return@Heartbeat heartbeatCheck.check()
    } catch (e: CancellationException) {
      throw e
    } catch (e: Throwable) {
      logger.warn { "Exception $e\n - ${e.message}" }
      logger.warn { e.stackTraceToString() }

      return@Heartbeat HeartbeatState.Dead.ExceptionOccurred(e)
    }
  }

  /**
   * Contains a flow of the heartbeat state
   */
  val heartbeatFlow: SharedFlow<HeartbeatState> = heartbeat
    .createFlow()
    .flowOn(heartbeatFlowDispatcher)
    .shareIn(heartbeatFlowShareScope, SharingStarted.Lazily)
}
