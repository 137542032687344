package components.company

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.customer.Address
import it.neckar.customer.company.BankInformation
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyLegalInformation
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.ContactInformation
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.customer.company.NeckarITCompanyProfile
import it.neckar.customer.company.PartnerCompanyProfile
import it.neckar.customer.company.TestCompanyProfile
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.react.common.*
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import services.UiActions

/**
 * Change password form
 */
val ChangeCompanyInfoForm: FC<ChangeCompanyInfoFormProps> = fc("ChangeCompanyInfoForm") { props ->
  val forCompany = props::forCompany.safeGet()

  val companyCode = useState(forCompany.companyCode.value)
  val companyName = useState(forCompany.name)
  val originalCompanyTypeEnum = when (forCompany.companyProfile) {
    is MainCompanyProfile -> CompanyTypeEnum.Main
    is PartnerCompanyProfile -> CompanyTypeEnum.Partner
    is TestCompanyProfile -> CompanyTypeEnum.Test
    NeckarITCompanyProfile -> null
  }
  val companyType = originalCompanyTypeEnum?.let { useState(originalCompanyTypeEnum) }
  val defaultUser = useState(forCompany.defaultUser)

  val companyAddress = forCompany.address
  val street = useState(companyAddress.street)
  val houseNumber = useState(companyAddress.houseNumber)
  val zipCode = useState(companyAddress.zipCode)
  val city = useState(companyAddress.city)

  val companyContact = forCompany.contactInformation
  val mail = useState(companyContact.mail)
  val phone = useState(companyContact.phone)
  val fax = useState(companyContact.fax)
  val url = useState(companyContact.url)

  val companyBank = forCompany.bankInformation
  val bankName = useState(companyBank.name)
  val iban = useState(companyBank.iban)
  val bic = useState(companyBank.bic)

  val companyLegal = forCompany.legalInformation
  val director = useState(companyLegal.director)
  val registerCourt = useState(companyLegal.registerCourt)
  val taxId = useState(companyLegal.taxId)
  val registrationNumber = useState(companyLegal.registrationNumber)

  val address = useMemo(street.value, houseNumber.value, zipCode.value, city.value) {
    Address(
      street = street.value,
      houseNumber = houseNumber.value,
      zipCode = zipCode.value,
      city = city.value,
    )
  }

  val contactInformation = useMemo(mail.value, phone.value, fax.value, url.value) {
    ContactInformation(
      mail = mail.value,
      phone = phone.value,
      fax = fax.value,
      url = url.value,
    )
  }

  val bankInformation = useMemo(bankName.value, iban.value, bic.value) {
    BankInformation(
      name = bankName.value,
      iban = iban.value,
      bic = bic.value,
    )
  }

  val legalInformation = useMemo(director.value, registerCourt.value, taxId.value, registrationNumber.value) {
    CompanyLegalInformation(
      director = director.value,
      registerCourt = registerCourt.value,
      taxId = taxId.value,
      registrationNumber = registrationNumber.value,
    )
  }

  val plausible = companyCode.value.isNotBlank() && companyCode.value.contains(" ").not() && companyName.value.isNotBlank() &&
      address.isPlausible && contactInformation.isPlausible && bankInformation.isPlausible && legalInformation.isPlausible &&
      (companyCode.value != forCompany.companyCode.value || companyName.value != forCompany.name || companyType?.value != originalCompanyTypeEnum || defaultUser.value != forCompany.defaultUser || address != companyAddress || contactInformation != companyContact || bankInformation != companyBank || legalInformation != companyLegal)

  val (busy, setBusy) = useState(false)


  val changeCompanyInfoAction = useCallback(plausible, companyCode.value, companyName.value, address, contactInformation, bankInformation, legalInformation, companyType?.value, defaultUser.value) {
    if (plausible.not()) return@useCallback

    val relevantParentCompany = forCompany.companyProfile.relevantParentCompanyCode()

    val newCompanyCode = CompanyCode(companyCode.value)

    val newCompanyProfile: CompanyProfile = when (companyType?.value) {
      CompanyTypeEnum.Main -> MainCompanyProfile(newCompanyCode)
      CompanyTypeEnum.Partner -> PartnerCompanyProfile(newCompanyCode, relevantParentCompany)
      CompanyTypeEnum.Test -> TestCompanyProfile(newCompanyCode)
      null -> NeckarITCompanyProfile
    }

    launchAndNotify {
      setBusy(true)
      UiActions.changeCompanyInfo(
        updatedCompanyInformation = PlannerCompanyInformation(
          companyProfile = newCompanyProfile,
          name = companyName.value,
          address = address,
          contactInformation = contactInformation,
          bankInformation = bankInformation,
          legalInformation = legalInformation,
          defaultUser = defaultUser.value,
        )
      )
      setBusy(false)
    }
  }


  div {

    onEnter(changeCompanyInfoAction)

    companyForm(
      companyCode = companyCode,
      companyName = companyName,
      companyType = companyType,
      defaultUser = defaultUser,
      street = street,
      houseNumber = houseNumber,
      zipCode = zipCode,
      city = city,
      mail = mail,
      phone = phone,
      fax = fax,
      url = url,
      bankName = bankName,
      iban = iban,
      bic = bic,
      director = director,
      registerCourt = registerCourt,
      taxId = taxId,
      registrationNumber = registrationNumber,
      existingCompany = forCompany,
    )

    div("my-3") {
      button(classes = "btn btn-primary w-100 btn-lg", type = ButtonType.button) {
        +"Firmendaten ändern"

        attrs {
          disabled = plausible.not() || busy
          onClickFunction = { changeCompanyInfoAction() }
        }
      }
    }

  }

}

external interface ChangeCompanyInfoFormProps : Props {
  var forCompany: PlannerCompanyInformation
}
