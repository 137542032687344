package components.project.configuration.facility.assembly

import components.project.ensureElementInList
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.components.ResolvedAssemblyConfiguration
import it.neckar.lizergy.model.configuration.components.ScaffoldingArea
import it.neckar.lizergy.model.configuration.components.ScaffoldingProvided
import it.neckar.lizergy.model.configuration.components.SelfAssemblyConfiguration
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.AssemblyDifficulty
import it.neckar.lizergy.model.price.PriceList
import it.neckar.open.time.formatHourAndMinutes
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

val EditConfigurationAssembly: FC<EditConfigurationAssemblyProps> = fc("EditConfigurationAssembly") { props ->
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val assemblyConfigurationToSave = props::assemblyConfigurationToSave.getNotNull()
  val priceList = props::priceList.safeGet()

  val editableStatus = props::editableStatus.safeGet()

  val initialAssemblyConfiguration = assemblyConfigurationToSave.value
  val initialAssemblyDifficulty = initialAssemblyConfiguration.assemblyDifficulty

  val availableAssemblyDifficulties = props::availableAssemblyDifficulties.safeGet().ensureElementInList(initialAssemblyDifficulty)

  val assemblyDifficultyToSave = useState(initialAssemblyDifficulty)

  val manualNumberOfModulesKreuzverbundToSave = useState(initialAssemblyConfiguration.numberOfModulesKreuzverbund)

  val assemblyStatus = initialAssemblyConfiguration.assemblyStatus
  val willBeSelfAssembled = useState(
    when (assemblyStatus) {
      is ScaffoldingArea -> ScaffoldingStatus.ConstructionHandledByLizergy
      ScaffoldingProvided -> ScaffoldingStatus.OnlyScaffoldingProvided
      is SelfAssemblyConfiguration -> ScaffoldingStatus.SelfAssembled
    }
  )

  val dachhakenSetzen = useState(assemblyStatus.dachhakenSetzen ?: false)
  val dachhakenGestellt = useState(assemblyStatus.dachhakenGestellt ?: false)
  val schienenMontage = useState(assemblyStatus.schienenMontage ?: false)
  val kabelZiehenUndModuleMontieren = useState(assemblyStatus.kabelZiehenUndModuleMontieren ?: false)
  val scaffoldingArea = useState(initialAssemblyConfiguration.assemblyStatus.scaffoldingArea)

  val dachstaenderIsolierungToSave = useState(initialAssemblyConfiguration.dachstaenderIsolierung)
  val moveSatelliteDishToSave = useState(initialAssemblyConfiguration.moveSatelliteDish)
  val removeAntennaToSave = useState(initialAssemblyConfiguration.removeAntenna)
  val externerBlitzschutzVorhandenToSave = useState(initialAssemblyConfiguration.externerBlitzschutzVorhanden)


  useMemo(
    willBeSelfAssembled.value,
    dachhakenSetzen.value,
    schienenMontage.value,
    kabelZiehenUndModuleMontieren.value,
    dachhakenGestellt.value,
    scaffoldingArea.value,
    assemblyDifficultyToSave.value.id,
    manualNumberOfModulesKreuzverbundToSave.value,
    dachstaenderIsolierungToSave.value,
    moveSatelliteDishToSave.value,
    removeAntennaToSave.value,
    externerBlitzschutzVorhandenToSave.value,
  ) {
    val assemblyStatusToSave = when (willBeSelfAssembled.value) {
      ScaffoldingStatus.ConstructionHandledByLizergy -> ScaffoldingArea(scaffoldingArea.value)
      ScaffoldingStatus.OnlyScaffoldingProvided -> ScaffoldingProvided
      ScaffoldingStatus.SelfAssembled -> SelfAssemblyConfiguration(
        dachhakenSetzen = dachhakenSetzen.value,
        schienenMontage = schienenMontage.value,
        kabelZiehenUndModuleMontieren = kabelZiehenUndModuleMontieren.value,
        dachhakenGestellt = dachhakenGestellt.value,
      )
    }

    assemblyConfigurationToSave.setter(
      initialAssemblyConfiguration.copy(
        assemblyDifficulty = assemblyDifficultyToSave.value,
        numberOfModulesKreuzverbund = manualNumberOfModulesKreuzverbundToSave.value,
        dachstaenderIsolierung = dachstaenderIsolierungToSave.value,
        moveSatelliteDish = moveSatelliteDishToSave.value,
        removeAntenna = removeAntennaToSave.value,
        externerBlitzschutzVorhanden = externerBlitzschutzVorhandenToSave.value,
        assemblyStatus = assemblyStatusToSave,
      )
    )
  }


  div("my-5") {

    h2("mb-3") {
      +"Montage inkl. Unterkonstruktion"
    }

    floatingSelect(
      valueAndSetter = assemblyDifficultyToSave,
      formatter = { it.description },
      availableOptions = availableAssemblyDifficulties,
      fieldName = "assemblyDifficulty",
      title = "Montage-Schwierigkeit",
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedBelow()
      }
    }

    nullableFloatingIntInputField(
      valueAndSetter = manualNumberOfModulesKreuzverbundToSave,
      fieldName = "numberOfModulesKreuzverbund",
      title = "${manualNumberOfModulesKreuzverbundToSave.value ?: quoteConfiguration.calculatedOfModulesKreuzverbund} Module im Kreuzverbund",
      numberConstraint = ZeroOrPositive,
      editableStatus = editableStatus,
    ) {
      attrs {
        mergedAbove()
      }
    }

    p("form-text") {
      +"Montagedauer: ${(priceList.getWorkingTime(assemblyDifficultyToSave.value) * quoteConfiguration.totalNumberOfModules).formatHourAndMinutes()}"
    }

    assemblyForm(
      dachstaenderIsolierung = dachstaenderIsolierungToSave,
      moveSatelliteDish = moveSatelliteDishToSave,
      removeAntenna = removeAntennaToSave,
      willBeSelfAssembled = willBeSelfAssembled,
      dachhakenSetzen = dachhakenSetzen,
      schienenMontage = schienenMontage,
      kabelZiehenUndModuleMontieren = kabelZiehenUndModuleMontieren,
      dachhakenGestellt = dachhakenGestellt,
      scaffoldingArea = scaffoldingArea,
      scaffoldingAreaFromRoofs = quoteConfiguration.moduleLayouts.scaffoldingAreas().values.sum(),
      priceList = priceList,
      externerBlitzschutzVorhanden = externerBlitzschutzVorhandenToSave,
      editableStatus = editableStatus,
    )
  }

}

external interface EditConfigurationAssemblyProps : Props {
  var quoteConfiguration: QuoteConfiguration
  var assemblyConfigurationToSave: StateInstance<ResolvedAssemblyConfiguration>
  var availableAssemblyDifficulties: List<AssemblyDifficulty>
  var priceList: PriceList
  var editableStatus: EditableStatus
}
