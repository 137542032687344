package it.neckar.lizergy.model.price

import it.neckar.financial.currency.PriceWithProfit
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.energy.kiloWattPeak
import kotlinx.serialization.Serializable

/**
 * Contains the monitoring prices
 */
@Serializable
data class MonitoringPrices(
  val priceBelow10kWp: PriceWithProfit,
  val priceBelow20kWp: PriceWithProfit,
  val priceBelow30kWp: PriceWithProfit,
  val priceElse: PriceWithProfit,
  val additionalCostsWithBattery: PriceWithProfit,
) {

  operator fun get(powerRating: PowerRating, includingBattery: Boolean): PriceWithProfit {
    return when {
      powerRating < 10.0.kiloWattPeak -> priceBelow10kWp
      powerRating < 20.0.kiloWattPeak -> priceBelow20kWp
      powerRating < 30.0.kiloWattPeak -> priceBelow30kWp
      else -> priceElse
    } + if (includingBattery) {
      additionalCostsWithBattery
    } else {
      PriceWithProfit.Zero
    }
  }

  fun asList(): List<PriceWithProfit> {
    return listOf(
      priceBelow10kWp,
      priceBelow20kWp,
      priceBelow30kWp,
      priceElse,
    )
  }

  companion object {
    /**
     * Contains zero for all values
     */
    val zero: MonitoringPrices = MonitoringPrices(PriceWithProfit.Zero, PriceWithProfit.Zero, PriceWithProfit.Zero, PriceWithProfit.Zero, PriceWithProfit.Zero)
  }
}
