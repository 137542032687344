package serialized

import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.MppInputConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.lizergy.model.price.ProductResolver
import kotlinx.serialization.Serializable

@Serializable
data class SerializedInverterConfiguration(
  override val inverterId: InverterId,
  override val inverterIndex: Int,
  override val mppInputConfigurations: List<MppInputConfiguration>,
) : InverterConfiguration {

  fun resolve(productResolver: ProductResolver): ResolvedInverterConfiguration {
    return ResolvedInverterConfiguration(
      inverter = productResolver.getConfigurableInverter(inverterId),
      inverterIndex = inverterIndex,
      mppInputConfigurations = mppInputConfigurations
    )
  }
}

fun ResolvedInverterConfiguration.unResolve(): SerializedInverterConfiguration {
  return SerializedInverterConfiguration(
    inverterId = inverter.id,
    inverterIndex = inverterIndex,
    mppInputConfigurations = mppInputConfigurations
  )
}

