@file:Suppress("DuplicatedCode")

package components.project.configuration.layout.moduleLayout.roof

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileColor
import it.neckar.lizergy.model.configuration.moduleLayout.roof.MetalRoofTileCompatability
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedColoredMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedColoredOnlyWhereVisibleMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedMetalRoofTileConstructionType
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ResolvedNormalMetalRoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofTile
import it.neckar.lizergy.model.configuration.moduleLayout.roof.RoofType
import it.neckar.open.kotlin.lang.toIntCeil
import it.neckar.open.unit.si.cm
import it.neckar.open.unit.si.m2
import it.neckar.open.unit.si.mm
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleExclamation
import it.neckar.react.common.form.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.role
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.*

/**
 * Form for a roof
 */
fun RBuilder.roofForm(
  roofType: StateInstance<RoofType>,
  availableRoofTypes: List<RoofType>,

  roofTile: StateInstance<RoofTile?>,
  roofTileTypeOverride: StateInstance<String>,
  metalRoofTileConstructionType: StateInstance<ResolvedMetalRoofTileConstructionType?>,
  metalRoofTileColor: StateInstance<MetalRoofTileColor>,
  metalRoofTileAmount: StateInstance<Int>,
  availableTileTypes: List<RoofTile>,
  availableMetalRoofTileColors: List<MetalRoofTileColor>,

  roofIsolation: StateInstance<@mm Int?>,
  sparrenabstand: StateInstance<@cm Int?>,
  verstaerktesSchienensystem: StateInstance<Boolean>,
  scaffoldingArea: StateInstance<@m2 Int>,
  schwarzeKlemmen: StateInstance<Boolean>,

  totalModulesCount: Int,

  editableStatus: EditableStatus,
): Unit = child(RoofForm) {
  attrs {
    this.roofType = roofType
    this.availableRoofTypes = availableRoofTypes

    this.roofTile = roofTile
    this.roofTileTypeOverride = roofTileTypeOverride
    this.metalRoofTileConstructionType = metalRoofTileConstructionType
    this.metalRoofTileColor = metalRoofTileColor
    this.metalRoofTileAmount = metalRoofTileAmount
    this.availableTileTypes = availableTileTypes
    this.availableMetalRoofTileColors = availableMetalRoofTileColors

    this.roofIsolation = roofIsolation
    this.sparrenabstand = sparrenabstand
    this.verstaerktesSchienensystem = verstaerktesSchienensystem
    this.scaffoldingArea = scaffoldingArea
    this.schwarzeKlemmen = schwarzeKlemmen

    this.totalModulesCount = totalModulesCount

    this.editableStatus = editableStatus
  }
}

val RoofForm: FC<RoofFormProps> = fc("RoofForm") { props ->
  val roofType = props::roofType.getNotNull()
  val roofTileType = props::roofTile.getNotNull()
  val roofTileTypeOverride = props::roofTileTypeOverride.getNotNull()
  val metalRoofTileConstructionType = props::metalRoofTileConstructionType.getNotNull()
  val metalRoofTileColor = props::metalRoofTileColor.getNotNull()
  val metalRoofTileAmount = props::metalRoofTileAmount.getNotNull()
  val roofIsolation = props::roofIsolation.getNotNull()
  val sparrenabstand = props::sparrenabstand.getNotNull()
  val verstaerktesSchienensystem = props::verstaerktesSchienensystem.getNotNull()
  val scaffoldingArea = props::scaffoldingArea.getNotNull()
  val schwarzeKlemmen = props::schwarzeKlemmen.getNotNull()

  val availableRoofTypes = props::availableRoofTypes.safeGet()
  val availableTileTypes = props::availableTileTypes.safeGet()
  val availableMetalRoofTileColors = props::availableMetalRoofTileColors.safeGet()
  val totalModulesCount = props::totalModulesCount.safeGet()
  val editableStatus = props::editableStatus.safeGet()


  useMemo(metalRoofTileAmount.value) {
    metalRoofTileConstructionType.setter(
      when (val constructionType = metalRoofTileConstructionType.value) {
        is ResolvedColoredOnlyWhereVisibleMetalRoofTile -> constructionType.copy(amount = metalRoofTileAmount.value)
        null, ResolvedNormalMetalRoofTile, is ResolvedColoredMetalRoofTile -> constructionType
      }
    )
  }


  div("row my-3") {

    div("col-md-6") {

      floatingSelect(
        valueAndSetter = roofType,
        formatter = { it.description },
        availableOptions = availableRoofTypes,
        fieldName = "roofType",
        title = "Dachtyp",
        additionalOnChange = {
          if (it.metalRoofTileCompatability == MetalRoofTileCompatability.Required && metalRoofTileConstructionType.value == null) {
            metalRoofTileConstructionType.setter(ResolvedNormalMetalRoofTile)
          }
        },
        editableStatus = editableStatus,
      ) {
        attrs {
          addClassIf(CSS.mergedBelow) {
            roofType.value.metalRoofTileCompatability.compatible
          }
        }
      }

      div("collapse") {
        attrs {
          addClassIf("show") {
            roofType.value.metalRoofTileCompatability.compatible
          }
        }

        floatingSelectNullable(
          valueAndSetter = roofTileType,
          idProvider = { it?.id?.format() ?: "-" },
          formatter = { it?.description ?: "Unbekannt" },
          availableOptionsWithoutNull = availableTileTypes,
          fieldName = "roofTileType",
          title = "Ziegelart",
          editableStatus = editableStatus,
        ) {
          attrs {
            mergedAbove()
            mergedBelow()
          }
        }

        floatingInputField(
          valueAndSetter = roofTileTypeOverride,
          fieldName = "roofTileTypeOverride",
          title = "Manuelle Ziegelart",
          additionalOnChange = { roofTileType.setter(null) },
          editableStatus = editableStatus,
        ) {
          attrs {
            mergedAbove()
          }
        }

        roofTileType.value?.let {
          if (metalRoofTileConstructionType.value != null) {
            div("form-text") {
              +it.metalRoofTile.description
            }
          }
        }
      }

      div("my-3") {

        nullableFloatingIntInputField(
          valueAndSetter = sparrenabstand,
          fieldName = "sparrenabstand",
          title = buildString {
            if (roofType.value.hasSparrenabstand) append("Sparrenabstand")
            else append("Hochsickenabstand")
            append(" ")
            append("(cm)")
          },
          editableStatus = editableStatus,
        ) {
          attrs {
            if (roofType.value.withIsolation) {
              mergedBelow()
            }
          }
        }

        div("collapse") {
          attrs {
            addClassIf("show") {
              roofType.value.withIsolation
            }
          }
          nullableFloatingIntInputField(
            valueAndSetter = roofIsolation,
            fieldName = "roofIsolation",
            title = "Dachdämmung (cm)",
            editableStatus = editableStatus,
          ) {
            attrs {
              mergedAbove()
            }
          }
        }

      }

      div("collapse my-3") {
        attrs {
          addClassIf("show") {
            sparrenabstand.value?.let { it > 80 }
          }
        }
        checkbox(
          valueAndSetter = verstaerktesSchienensystem,
          fieldName = "verstaerktesSchienensystem",
          title = "Verstärktes Schienensystem",
          editableStatus = editableStatus,
        )
      }

      div("my-3") {
        checkbox(
          valueAndSetter = schwarzeKlemmen,
          fieldName = "schwarzeKlemmen",
          title = "Schwarze Klemmen",
          editableStatus = editableStatus,
        )
      }

      floatingIntInputField(
        valueAndSetter = scaffoldingArea,
        fieldName = "scaffoldingArea",
        title = "Gerüstgröße (m²)",
        editableStatus = editableStatus,
      )

    }

    div("col-md-6 collapse d-flex justify-content-start btn-group-vertical my-3") {
      attrs {
        role = "group"
        addClassIf("show") {
          roofType.value.metalRoofTileCompatability.compatible
        }
      }

      div("collapse") {
        attrs {
          role = "group"
          addClassIf("show") {
            roofType.value.metalRoofTileCompatability != MetalRoofTileCompatability.Required
          }
        }

        span {
          input(type = InputType.radio, classes = "form-check-input", name = "btnradio") {
            attrs {
              id = "btnradio0"
              defaultChecked = metalRoofTileConstructionType.value == null
              disabled = editableStatus == EditableStatus.ReadOnly

              onChangeFunction = {
                val inputElement = it.target as HTMLInputElement
                if (inputElement.checked) metalRoofTileConstructionType.setter(null)
              }
            }
          }
          label("form-check-label ms-1") {
            attrs {
              htmlForFixed = "btnradio0"
            }

            +"Ohne Metalldachziegel"
          }
        }
      }

      span {
        input(type = InputType.radio, classes = "form-check-input", name = "btnradio") {
          attrs {
            id = "btnradio1"
            defaultChecked = metalRoofTileConstructionType.value == ResolvedNormalMetalRoofTile
            disabled = editableStatus == EditableStatus.ReadOnly

            onChangeFunction = {
              val inputElement = it.target as HTMLInputElement
              if (inputElement.checked) metalRoofTileConstructionType.setter(ResolvedNormalMetalRoofTile)
            }
          }
        }
        label("form-check-label ms-1") {
          attrs {
            htmlForFixed = "btnradio1"
          }

          +"Metalldachziegel"
        }
      }

      span {
        input(type = InputType.radio, classes = "form-check-input", name = "btnradio") {
          attrs {
            id = "btnradio2"
            defaultChecked = metalRoofTileConstructionType.value is ResolvedColoredMetalRoofTile
            disabled = editableStatus == EditableStatus.ReadOnly

            onChangeFunction = {
              val inputElement = it.target as HTMLInputElement
              if (inputElement.checked) metalRoofTileConstructionType.setter(ResolvedColoredMetalRoofTile(availableMetalRoofTileColors.first()))
            }
          }
        }
        label("form-check-label ms-1") {
          attrs {
            htmlForFixed = "btnradio2"
          }

          +"Farbige Metalldachziegel"
        }
      }

      span {
        input(type = InputType.radio, classes = "form-check-input", name = "btnradio") {
          attrs {
            id = "btnradio3"
            defaultChecked = metalRoofTileConstructionType.value is ResolvedColoredOnlyWhereVisibleMetalRoofTile
            disabled = editableStatus == EditableStatus.ReadOnly

            onChangeFunction = {
              val inputElement = it.target as HTMLInputElement
              if (inputElement.checked) metalRoofTileConstructionType.setter(ResolvedColoredOnlyWhereVisibleMetalRoofTile(availableMetalRoofTileColors.first(), 10))
            }
          }
        }
        label("form-check-label ms-1") {
          attrs {
            htmlForFixed = "btnradio3"
          }

          +"Farbige Metalldachziegel im Sichtbereich"
        }
      }

      div("col-6 my-3") {

        div("collapse") {
          attrs {
            addClassIf("show") {
              metalRoofTileConstructionType.value?.isColored()
            }
          }

          floatingSelect(
            valueAndSetter = metalRoofTileColor,
            formatter = { it.name },
            availableOptions = availableMetalRoofTileColors,
            fieldName = "metalRoofTileColor",
            title = "MetalldachziegelFarbe",
            editableStatus = editableStatus,
            additionalOnChange = {
              metalRoofTileConstructionType.setter(
                when (val constructionType = metalRoofTileConstructionType.value) {
                  is ResolvedColoredMetalRoofTile -> constructionType.copy(color = it)
                  is ResolvedColoredOnlyWhereVisibleMetalRoofTile -> constructionType.copy(color = it)
                  null, ResolvedNormalMetalRoofTile -> constructionType
                }
              )
            },
          ) {
            attrs {
              if (metalRoofTileConstructionType.value is ResolvedColoredOnlyWhereVisibleMetalRoofTile) {
                mergedBelow()
              }
            }
          }
        }

        div("collapse") {
          attrs {
            addClassIf("show") {
              metalRoofTileConstructionType.value is ResolvedColoredOnlyWhereVisibleMetalRoofTile
            }
          }

          floatingIntInputField(
            valueAndSetter = metalRoofTileAmount,
            fieldName = "metalRoofTileAmount",
            title = "Anzahl farbige Metalldachziegel",
            numberConstraint = CoerceIn(0, (totalModulesCount * 3.5).toIntCeil()),
            editableStatus = editableStatus,
          ) {
            attrs {
              mergedAbove()
            }
          }
        }

      }

      if (metalRoofTileConstructionType.value?.color != null) {
        p("text-warning") {
          span("pe-2") { faCircleExclamation() }
          +"Achtung: Verfügbarkeit der Farbe der Metalldachziegel  muss geprüft werden"
        }
      }

    }

  }

}


external interface RoofFormProps : Props {
  var roofType: StateInstance<RoofType>
  var availableRoofTypes: List<RoofType>

  var roofTile: StateInstance<RoofTile?>
  var roofTileTypeOverride: StateInstance<String>
  var metalRoofTileConstructionType: StateInstance<ResolvedMetalRoofTileConstructionType?>
  var metalRoofTileColor: StateInstance<MetalRoofTileColor>
  var metalRoofTileAmount: StateInstance<Int>
  var availableTileTypes: List<RoofTile>
  var availableMetalRoofTileColors: List<MetalRoofTileColor>

  var roofIsolation: StateInstance<@cm Int?>
  var sparrenabstand: StateInstance<@cm Int?>
  var verstaerktesSchienensystem: StateInstance<Boolean>
  var scaffoldingArea: StateInstance<@m2 Int>
  var schwarzeKlemmen: StateInstance<Boolean>

  var totalModulesCount: Int

  var editableStatus: EditableStatus
}
