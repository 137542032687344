package it.neckar.common.auth.login.token

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class LoginWithTokenRequest(
  val token: LoginToken,
)
