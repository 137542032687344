package store.actions

import it.neckar.common.redux.ChangeType
import store.Essentials
import store.PlannerClientState

/**
 * Reloads the projects
 */
data class EssentialsLoadedAction(val loadedEssentials: Essentials) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun PlannerClientState.updateState(): PlannerClientState {
    return copy(essentials = loadedEssentials)
  }
}
