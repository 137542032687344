package components.project

import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.lizergy.model.project.previews.ProjectQueryComponent
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry.DocumentationProcessStates
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry.FinalAccountProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.react.common.table.*
import react.*
import react.dom.*
import router.useDocumentTitle
import store.hooks.useRequireCompanyForLoggedInUser

val FinalAccountsOverviewFromUrl: FC<Props> = fc("FinalAccountsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = FinalAccountProcessStates.entries

  useDocumentTitle(companyName, "Abschlussrechnungen")

  h1("mb-3") { +"Abschlussrechnungen" }

  ProjectsOverview {
    attrs {
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = listOf(FinalAccountProcessStates.Paid, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.FinalAccount
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("FinalAccounts")
    }
  }
}

val DocumentationsOverviewFromUrl: FC<Props> = fc("DocumentationsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = DocumentationProcessStates.entries

  useDocumentTitle(companyName, "Dokumentationen")

  h1("mb-3") { +"Dokumentationen" }

  ProjectsOverview {
    attrs {
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = listOf(DocumentationProcessStates.Done, ProjectProcessStates.Archived, ProjectProcessStates.Paused)
      this.projectQueryComponent = ProjectQueryComponent.Documentation
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("Documentations")
    }
  }
}

val VerificationsOverviewFromUrl: FC<Props> = fc("VerificationsOverviewFromUrl") {
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val relevantProcessStates = listOf(DocumentationProcessStates.Done, ProjectProcessStates.Archived)

  useDocumentTitle(companyName, "Überprüfung")

  h1("mb-3") { +"Überprüfung" }

  ProjectsOverview {
    attrs {
      this.processStatesToFilter = relevantProcessStates
      this.processStatesToHide = emptyList()
      this.projectQueryComponent = ProjectQueryComponent.Verification
      this.sortedBy = SortedBy.SortedDescending
      this.includeArchived = false
      this.keyPrefix = LocalStorageKeyPrefix("Verifications")
    }
  }
}
