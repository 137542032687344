package services.http

import it.neckar.heartbeat.ServiceHeartbeatSupport
import it.neckar.ktor.client.heartbeat.HttpClientHeartbeatCheck
import it.neckar.projects.pvplanner.PvPlannerService

/**
 * Contains the heartbeats for all services
 */
object HeartbeatsSupport {
  val authService: ServiceHeartbeatSupport = ServiceHeartbeatSupport(HttpClientHeartbeatCheck(PlannerUiServices.urlSupport.auth.heartbeat, PlannerUiServices.httpClientNoAuth))
  val commentsService: ServiceHeartbeatSupport = ServiceHeartbeatSupport(HttpClientHeartbeatCheck(PlannerUiServices.urlSupport.comments.heartbeat, PlannerUiServices.httpClientNoAuth))
  val geoCodeService: ServiceHeartbeatSupport = ServiceHeartbeatSupport(HttpClientHeartbeatCheck(PlannerUiServices.urlSupport.geoInformation.heartbeat, PlannerUiServices.httpClientNoAuth))
  val pdfPreviewService: ServiceHeartbeatSupport = ServiceHeartbeatSupport(HttpClientHeartbeatCheck(PlannerUiServices.urlSupport.pdfPreviewService.heartbeat, PlannerUiServices.httpClientNoAuth))
  val pdfReportService: ServiceHeartbeatSupport = ServiceHeartbeatSupport(HttpClientHeartbeatCheck(PlannerUiServices.urlSupport.pdf.heartbeat, PlannerUiServices.httpClientNoAuth))
  val processStatesService: ServiceHeartbeatSupport = ServiceHeartbeatSupport(HttpClientHeartbeatCheck(PlannerUiServices.urlSupport.processStates.heartbeat, PlannerUiServices.httpClientNoAuth))
  val projectQueryService: ServiceHeartbeatSupport = ServiceHeartbeatSupport(HttpClientHeartbeatCheck(PlannerUiServices.urlSupport.projectQuery.heartbeat, PlannerUiServices.httpClientNoAuth))
  val storageService: ServiceHeartbeatSupport = ServiceHeartbeatSupport(HttpClientHeartbeatCheck(PlannerUiServices.urlSupport.storage.heartbeat, PlannerUiServices.httpClientNoAuth))

  /**
   * Returns the online state for the provided service
   */
  operator fun get(service: PvPlannerService): ServiceHeartbeatSupport {
    return when (service) {
      PvPlannerService.AuthService -> authService
      PvPlannerService.CommentsService -> commentsService
      PvPlannerService.EuScienceHubService -> TODO("not implemented")
      PvPlannerService.GeoInformationService -> geoCodeService
      PvPlannerService.PdfPreviewService -> pdfPreviewService
      PvPlannerService.PdfReportService -> pdfReportService
      PvPlannerService.ProcessStatesService -> processStatesService
      PvPlannerService.ProjectQueryService -> projectQueryService
      PvPlannerService.StorageService -> storageService
    }
  }
}

