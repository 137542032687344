package components.project.searchbar

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.router.*
import react.*
import router.toProject
import store.hooks.useLoadProcessStates

/**
 * Shows a project search modal dialog
 */
val ProjectSearchModal: FC<ProjectSearchModalProps> = fc("ProjectSearchModal") { props ->
  val navigate = useNavigateUrl()
  val allProcessStatesForComponents = useLoadProcessStates()

  val idOfModal = props::idOfModal.safeGet()

  modal(
    id = idOfModal,
    title = "Projekt-Suche",
    size = ModalSize.Large,
    staticBackdrop = false,
  ) {
    busyIfNull(allProcessStatesForComponents) { loadedAllProcessStates ->
      ProjectSearch {
        attrs {
          this.processStatesResolver = loadedAllProcessStates
          this.selectProjectAction = { navigate.toProject(it) }
        }
      }
    }
  }
}

external interface ProjectSearchModalProps : Props {
  var idOfModal: String
}
