package services.http

import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import kotlinx.coroutines.*
import services.auth.http.FetchUsersAndCompaniesResponse
import services.storage.LoadEssentialsResponse
import services.storage.LoadedEssentials
import services.storage.http.FetchStaticPriceListResponse

/**
 * Supports loading of the essentials
 */
class EssentialsClientService(
  val authenticationService: AuthenticationClientService,
  val projectQueryService: ProjectQueryClientService,
) {
  /**
   * Loads the essentials
   */
  suspend fun loadEssentials(): LoadEssentialsResponse {
    logger.debug("Loading essentials")

    return withContext(Dispatchers.Default) {
      val usersAndCompaniesDeferred = async { authenticationService.fetchUsersAndCompanies() }
      val staticPriceListDeferred = async { projectQueryService.fetchStaticPriceList() }

      val usersAndCompaniesResult = usersAndCompaniesDeferred.await()
      val fetchStaticPriceListResponse = staticPriceListDeferred.await()

      val usersAndCompanies = when (usersAndCompaniesResult) {
        is FetchUsersAndCompaniesResponse.Success -> usersAndCompaniesResult.data
        is FetchUsersAndCompaniesResponse.Failure -> return@withContext LoadEssentialsResponse.failure()
      }

      when (fetchStaticPriceListResponse) {
        is FetchStaticPriceListResponse.Success -> {

          val fetchStaticPriceListInformation = fetchStaticPriceListResponse.data

          LoadEssentialsResponse.success(
            LoadedEssentials(
              usersAndCompanies = usersAndCompanies,
              availableProducts = fetchStaticPriceListInformation.availableProducts,
              productResolver = fetchStaticPriceListInformation.productResolver,
              priceList = fetchStaticPriceListInformation.priceList,
            )
          )
        }

        FetchStaticPriceListResponse.Failure -> return@withContext LoadEssentialsResponse.failure()
      }
    }
  }

  companion object {
    private val logger: Logger = LoggerFactory.getLogger("services.http.EssentialsClientService")
  }
}
